import React, { useState, useEffect, useLayoutEffect, useRef, Fragment, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { VariableSizeList, ListChildComponentProps, ListOnScrollProps } from 'react-window';

import { Autocomplete, InputAdornment, InputProps, Popper, TextField, Typography } from '@material-ui/core';
import { experimentalStyled as styled, makeStyles, Theme } from '@material-ui/core/styles';
import { Box } from '@mui/material';
import { v4 as uuid } from 'uuid';
import { Maybe } from 'yup/es/types';

import useItemMapping from 'src/components/SideBar/MappingSidebar/context/ItemMappingProvider';
import useResetCache from 'src/hooks/useResetChache';

import Hint from './Hint';
import Info from './Icon/info.svg';
import Iconography from './Iconography';
import Loader, { LoaderSize } from './Loader';
import { ChangeAction } from './SideBar/MappingSidebar/context/types';

export const StyledPopper = styled(Popper)(({ theme }) => ({
  padding: 0,
  borderRadius: 2,
  border: '1px solid',
  borderColor: theme.palette.secondary.main,
  background: theme.palette.common.white,
  boxSizing: 'border-box',

  '&.MuiAutocomplete-popper[data-popper-placement="top"]': {
    borderBottom: 'none',
    bottom: '-1px !important',
  },

  '&.MuiAutocomplete-popper[data-popper-placement="bottom"]': {
    top: '-2px !important',
  },

  '& .MuiAutocomplete-paper': {
    padding: 0,
    boxShadow: 'none',
    border: 0,
    boxSizing: 'border-box',
    margin: 0,
  },

  '& .MuiAutocomplete-noOptions': {
    fontSize: '15px',
    lineHeight: '18px',
    color: theme.palette.primary.light,
  },
  // TODO: scrollbar will be changed later
  '& .MuiAutocomplete-listbox': {
    margin: 5,
    padding: 0,
    borderRadius: 0,
    boxShadow: 'none',
    maxHeight: '167px',
    boxSizing: 'border-box',

    '& .MuiAutocomplete-option[aria-selected="true"], .MuiAutocomplete-option[aria-selected="true"][data-focus="true"], .MuiAutocomplete-option[aria-selected="true"][data-focus="true"]:hover':
      {
        background: theme.palette.secondary.main,
        color: theme.palette.common.white,
      },

    '&::-webkit-scrollbar': {
      width: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '5px',
      border: `1px solid ${theme.palette.action.selected}`,
      background: theme.palette.secondary.main,
      boxShadow: 'none',
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '5px',
      border: 'none',
      background: theme.palette.common.white,
      boxShadow: 'inset 0px 0px 15px rgba(46, 96, 170, 0.15)',
    },
  },
  '& .MuiAutocomplete-option': {
    height: '38px',
    margin: '0px 5px 5px',
    boxSizing: 'border-box',
    padding: '10px 15px',
    fontSize: '15px',
    lineHeight: '18px',
    background: theme.palette.common.white,
    color: theme.palette.primary.main,
    borderRadius: '5px',
    whiteSpace: 'nowrap',

    '&:hover': {
      background: theme.palette.background.default,
    },

    '&:last-of-type': {
      margin: '0px 5px',
    },
  },
  '& .MuiAutocomplete-option[data-focus="true"]:not(.MuiAutocomplete-option[aria-selected="true"])': {
    background: theme.palette.background.default,
    color: theme.palette.primary.light,
  },
}));

export const StyledPopperForVirtualizedScroll = styled(StyledPopper)(() => ({
  '&>div': {
    margin: 0,
  },

  '& .MuiAutocomplete-listbox': {
    overflowX: 'visible',
    overflowY: 'auto',
    border: 0,
    marginLeft: '10px',
    paddingRight: '5px',
  },

  '& .MuiAutocomplete-option': {
    margin: 0,

    '&:last-of-type': {
      margin: 0,
    },
  },
}));

export const Input = styled(TextField)(() => ({
  '& .MuiInputLabel-shrink.Mui-focused': {
    zIndex: 3000,
  },

  '& .MuiAutocomplete-endAdornment': {
    maxHeight: 24,
    marginTop: 2,

    '& .MuiButtonBase-root': {
      padding: 0,
      width: 24,
      height: 24,
    },
  },
}));

const useStyles = makeStyles<Theme, { disabled?: boolean; isFocused?: boolean }>((theme) => ({
  autocompleteOptionLabel: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    fontWeight: 400,
  },
  defaultValueFieldHint: {
    marginRight: '11px',
    marginTop: '1px',
  },
  noOptionsFound: {
    margin: '10px 15px',
    fontSize: '15px',
    lineHeight: '18px',
  },
  root: {
    '& ul': {
      display: 'flex',
      flexDirection: 'column',
      overflow: 'visible',
      padding: 0,
      margin: 0,
      position: 'relative',
    },
  },
  input: {
    '&:hover svg': {
      color: ({ disabled }) => (disabled ? theme.palette.text.disabled : theme.palette.secondary.main),
    },

    '& svg': {
      color: ({ isFocused }) => (isFocused ? theme.palette.secondary.main : theme.palette.text.disabled),
    },
  },
}));

export type ChangeSelectHandler = (option: SelectOptionItem) => void;

interface SelectProps {
  loading?: boolean;
  options: SelectOptionItem[];
  label: string;
  onChangeSelect?: ChangeSelectHandler;
  selectedOptionItem?: Maybe<SelectOptionItem>;
  isDefaultFieldValue?: boolean;
  isSelectedOptionReset?: boolean;
  disabled?: boolean;
  error?: boolean;
  required?: boolean;
  helperText?: string;
  autoFocus?: boolean;
  disablePortal?: boolean;
  handleTypeFieldError?: () => void;
  inputProps?: Partial<InputProps>;
  fullwidth?: boolean;
  emptyOptionPlaceholder?: string;
  pagination?: {
    loading: boolean;
    onFetchMore: () => void;
    hasMore: boolean;
  };
  customTestIdArrowDropDown?: string;
  integrationId?: string;
  optionsWithTooltips?: boolean;
  nonEditable?: boolean;
  tooltip?: React.ReactNode;
  generalOptionDataTestId?: string;
}

export interface SelectOptionItem {
  label: string;
  value: string | unknown;
  disabled?: boolean;
  noOptions?: boolean;
}

export interface SelectOptionItemStringValue {
  label: string;
  value: string;
}

export const defaultSelectValue = { label: '', value: '' };

const Select = ({
  loading,
  options,
  label,
  onChangeSelect,
  selectedOptionItem,
  isDefaultFieldValue,
  isSelectedOptionReset = true,
  disabled = false,
  disablePortal = true,
  error,
  helperText,
  autoFocus,
  required,
  handleTypeFieldError,
  inputProps,
  fullwidth,
  emptyOptionPlaceholder,
  pagination,
  customTestIdArrowDropDown,
  integrationId,
  optionsWithTooltips,
  nonEditable,
  tooltip,
  generalOptionDataTestId,
  ...props
}: SelectProps) => {
  const { t } = useTranslation();
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isEmptyError, setIsEmptyError] = useState<boolean>(!!required);
  const classes = useStyles({ disabled, isFocused });
  const { onChangeIntegrationInput } = useItemMapping();

  const defaultFieldHintText = t('fieldSettings.defaultFieldHint');
  const noOptionsFoundText = t('fieldSettings.noOptions');

  const allowableNumberOfItems = 20;
  const virtualizedScroll = options.length > allowableNumberOfItems && !pagination;

  const handleChangeAutocomplete = (selectedValue: SelectOptionItem) => {
    if (integrationId) {
      onChangeIntegrationInput({ integrationId, label }, ChangeAction[selectedValue.value ? 'ADD' : 'REMOVE']);
    }

    onChangeSelect?.(selectedValue);

    setIsEmptyError(!!required && !selectedValue);
  };

  useEffect(() => {
    if (disabled) {
      setIsFocused(false);
    }
  }, [disabled]);

  const selectOptionIdx = options.findIndex(({ value }) => value === selectedOptionItem?.value);

  const handleBlur = () => {
    setIsFocused(false);
    handleTypeFieldError?.();
  };

  const prevScrollTop = useRef(0);

  const optionDataTestId = (label?: string): string =>
    `${generalOptionDataTestId ? `${generalOptionDataTestId}` : ''}${label ? `${label}_` : ''}option`;

  // Adapter for react-window
  const ListboxComponentForVirtualizedScroll = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(
    function ListboxComponentForVirtualizedScroll(props, ref) {
      const { children, ...other } = props;

      const itemData: React.ReactChild[] = [];
      (children as React.ReactChild[]).forEach((item: React.ReactChild & { children?: React.ReactChild[] }) => {
        itemData.push(item);
        itemData.push(...(item.children || []));
      });
      const itemCount = itemData.length;
      //Indented List Item Size (px)
      const itemSize = 43;
      //item size when forming into groups
      const itemSizeIntoGroups = 48;
      const LIMIT_OF_ITEMS = 4;

      const offset = useRef(0);
      const gridRef = useResetCache(itemCount);
      const listRef = useRef<HTMLUListElement>(null);

      const noOptionsFoundText = t('fieldSettings.noOptions');

      const renderRow = ({ data, index, style }: ListChildComponentProps) => {
        const loaderOption = {
          label: 'loader',
          value: uuid(),
        };

        const dataSet = data[index];

        const { label, value, disabled, noOptions } = dataSet[1];

        const styles = {
          ...style,
          position: 'absolute',
        };

        if (disabled) {
          return <Box />;
        }

        if (noOptions) {
          return (
            <Box key={noOptionsFoundText} className={classes.noOptionsFound}>
              {noOptionsFoundText}
            </Box>
          );
        }

        if ((label as string) === loaderOption.label) {
          return (
            <li {...dataSet[0]} key={value} onClick={(e) => e.preventDefault()} data-testid={optionDataTestId(label)}>
              <Box margin="auto">
                <Loader size={LoaderSize.small} />
              </Box>
            </li>
          );
        } else {
          const optionBody = (
            <Typography
              key={String(value)}
              component="li"
              {...dataSet[0]}
              noWrap
              sx={styles}
              data-testid={optionDataTestId(label)}
            >
              {label}
            </Typography>
          );

          return optionsWithTooltips ? (
            <Hint type="hover" key={String(value)} title={label}>
              {optionBody}
            </Hint>
          ) : (
            optionBody
          );
        }
      };

      const getChildSize = (child: React.ReactChild) => {
        if (child.hasOwnProperty('group')) {
          return itemSizeIntoGroups;
        }

        return itemSize;
      };

      const getHeight = () => {
        if (itemCount > LIMIT_OF_ITEMS) {
          return LIMIT_OF_ITEMS * itemSize;
        }

        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
      };

      const OuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
        const propsWithoutStyles = { ...props, style: undefined };

        useEffect(() => {
          if (listRef.current) {
            listRef.current.scrollTop = offset.current;
          }
        }, []);

        return (
          <Box ref={ref}>
            <Box ref={listRef} {...propsWithoutStyles} {...other} data-testid="listConteiner" />
          </Box>
        );
      });

      const scrollItems = () => {
        gridRef.current?.scrollToItem(itemCount);
      };

      OuterElementType.displayName = 'OuterElementType';

      useEffect(() => {
        if (selectOptionIdx >= 0 && selectedOptionItem?.value && selectOptionIdx && listRef.current) {
          gridRef.current?.scrollToItem(selectOptionIdx);
          listRef.current.scrollTop = itemSize * selectOptionIdx;
        }
      }, [gridRef]);

      const InnerElementType = (props: React.HTMLAttributes<HTMLElement>) => {
        return <ul onScroll={scrollItems} {...props} data-testid="listItems" />;
      };

      InnerElementType.displayName = 'InnerElementType';

      const handleVariableSizeListScroll = ({ scrollOffset }: ListOnScrollProps) => {
        offset.current = scrollOffset;
      };

      return (
        <Box className={classes.root} ref={ref}>
          <VariableSizeList
            itemData={itemData}
            height={getHeight()}
            width="100%"
            ref={gridRef}
            outerElementType={OuterElementType}
            innerElementType={InnerElementType}
            itemSize={(index) => getChildSize(itemData[index])}
            overscanCount={5}
            itemCount={itemCount}
            onScroll={handleVariableSizeListScroll}
          >
            {renderRow}
          </VariableSizeList>
        </Box>
      );
    },
  );

  const ListboxComponent = (props: React.PropsWithChildren<React.HTMLAttributes<HTMLElement>>) => {
    const { children, ...otherProps } = props;
    const listRef = useRef<HTMLUListElement>(null);

    const handleFetchMore = ({
      scrollHeight,
      clientHeight,
      scrollTop,
    }: {
      scrollHeight: number;
      clientHeight: number;
      scrollTop: number;
    }) => {
      if (pagination) {
        const { hasMore, loading, onFetchMore } = pagination;
        const threshold = Math.round(clientHeight * 0.3);
        const isBottom = scrollHeight - (scrollTop + clientHeight) < threshold;

        if (hasMore && isBottom && !loading) {
          onFetchMore();
        }
      }
    };

    useLayoutEffect(() => {
      if (pagination && listRef.current) {
        const { scrollHeight, clientHeight, scrollTop } = listRef.current;

        // restore scroll position after re-rendering
        if (prevScrollTop.current > 0) {
          // 38 - loading option height
          listRef.current.scrollTop = pagination.loading ? prevScrollTop.current + 38 : prevScrollTop.current;
        }

        // init fetchMore if needed
        handleFetchMore({ scrollHeight, clientHeight, scrollTop });
      }
    }, []);

    const handleScroll = ({ target }: React.WheelEvent<HTMLUListElement>) => {
      const { scrollHeight, clientHeight, scrollTop } = target as HTMLUListElement;

      handleFetchMore({ scrollHeight, clientHeight, scrollTop });

      prevScrollTop.current = scrollTop;
    };

    return (
      <ul ref={listRef} onScroll={handleScroll} {...otherProps}>
        {children}
      </ul>
    );
  };

  const loaderOption = {
    label: 'loader',
    value: uuid(),
  };

  const renderOption = (
    props: React.HTMLAttributes<HTMLLIElement>,
    { label, value, disabled, noOptions }: SelectOptionItem,
  ) => {
    if (disabled) {
      return;
    }

    if (noOptions && !loading) {
      return (
        <div key={noOptionsFoundText} className={classes.noOptionsFound}>
          {noOptionsFoundText}
        </div>
      );
    }

    if (!label) {
      return null;
    }

    const optionBody = (
      <Typography className={classes.autocompleteOptionLabel} variant="subtitle2">
        {label}
      </Typography>
    );

    return (
      <Fragment key={uuid()}>
        {value === loaderOption.value ? (
          <li {...props} onClick={(e) => e.preventDefault()} data-testid={optionDataTestId(label)}>
            <Box display="inline-flex" margin="0 auto">
              <Loader size={LoaderSize.small} />
            </Box>
          </li>
        ) : (
          <li {...props} data-testid={optionDataTestId(label)}>
            {optionsWithTooltips ? (
              <Hint type="hover" key={String(value)} title={label}>
                {optionBody}
              </Hint>
            ) : (
              optionBody
            )}
          </li>
        )}
      </Fragment>
    );
  };

  const renderOptionForVirtualizedScroll = (props: React.HTMLAttributes<HTMLLIElement>, option: SelectOptionItem) => [
    props,
    option,
  ];

  const optionsToRender: SelectOptionItem[] = pagination?.loading || loading ? [...options, loaderOption] : options;

  return (
    <Hint placement="top" type="hover" title={tooltip ?? ''}>
      {nonEditable ? (
        <Input
          fullWidth={fullwidth}
          className={classes.input}
          label={selectedOptionItem?.label ? label : emptyOptionPlaceholder}
          disabled={disabled}
          value={selectedOptionItem?.label}
          inputProps={{
            'data-testid': optionDataTestId(selectedOptionItem?.label),
          }}
        />
      ) : (
        <Autocomplete
          fullWidth={fullwidth}
          disablePortal={disablePortal}
          disableClearable={isSelectedOptionReset}
          openOnFocus={autoFocus}
          PopperComponent={virtualizedScroll ? StyledPopperForVirtualizedScroll : StyledPopper}
          disabled={disabled}
          ListboxComponent={
            pagination ? ListboxComponent : virtualizedScroll ? ListboxComponentForVirtualizedScroll : undefined
          }
          noOptionsText={t('selectNoMatchesError')}
          popupIcon={
            <Iconography
              iconName="expand-chevron"
              data-testid={customTestIdArrowDropDown || `arrowDropDownIcon${label}`}
            />
          }
          clearIcon={
            selectedOptionItem?.value ? <Iconography iconName="cancel" fontSize="small" htmlColor="secondary" /> : null
          }
          value={selectedOptionItem}
          options={optionsToRender}
          onFocus={() => setIsFocused(true)}
          onBlur={handleBlur}
          getOptionSelected={(option, value) => option?.value === value?.value}
          onChange={(_, newValue) => {
            handleChangeAutocomplete(newValue || defaultSelectValue);
          }}
          onClose={() => {
            prevScrollTop.current = 0;
          }}
          renderOption={virtualizedScroll ? renderOptionForVirtualizedScroll : renderOption}
          renderInput={(params) => {
            return optionsWithTooltips ? (
              <Hint type="hover" title={(selectedOptionItem?.label as string) || ''} enterDelay={700}>
                <Input
                  className={classes.input}
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    'data-testid': optionDataTestId(selectedOptionItem?.label),
                  }}
                  InputProps={
                    inputProps || {
                      ...params.InputProps,
                      className: isDefaultFieldValue ? undefined : params.InputProps.className,
                      endAdornment: isDefaultFieldValue ? (
                        <InputAdornment position="end">
                          <Hint
                            placement="bottom"
                            isDefaultFieldValueHint
                            type="hover"
                            title={defaultFieldHintText}
                            data-testid="infoIconSelect"
                          >
                            <img
                              className={classes.defaultValueFieldHint}
                              src={Info}
                              alt="info"
                              data-testid="infoIcon"
                            />
                          </Hint>
                        </InputAdornment>
                      ) : (
                        params.InputProps.endAdornment
                      ),
                    }
                  }
                  label={selectedOptionItem?.label ? label : emptyOptionPlaceholder}
                  error={error || isEmptyError}
                  disabled={disabled}
                  helperText={
                    required ? helperText : isEmptyError ? t('bulkChangePopup.emptyFieldNameError') : helperText
                  }
                  autoFocus={autoFocus}
                />
              </Hint>
            ) : (
              <Input
                className={classes.input}
                {...params}
                inputProps={{
                  ...params.inputProps,
                  'data-testid': optionDataTestId(selectedOptionItem?.label),
                }}
                InputProps={
                  inputProps || {
                    ...params.InputProps,
                    className: isDefaultFieldValue ? undefined : params.InputProps.className,
                    endAdornment: isDefaultFieldValue ? (
                      <InputAdornment position="end">
                        <Hint
                          placement="bottom"
                          isDefaultFieldValueHint
                          type="hover"
                          title={defaultFieldHintText}
                          data-testid="infoIconSelect"
                        >
                          <img className={classes.defaultValueFieldHint} src={Info} alt="info" data-testid="infoIcon" />
                        </Hint>
                      </InputAdornment>
                    ) : (
                      params.InputProps.endAdornment
                    ),
                  }
                }
                label={selectedOptionItem?.label ? label : emptyOptionPlaceholder}
                error={error || isEmptyError}
                disabled={disabled}
                helperText={
                  required ? helperText : isEmptyError ? t('bulkChangePopup.emptyFieldNameError') : helperText
                }
                autoFocus={autoFocus}
              />
            );
          }}
          {...props}
        />
      )}
    </Hint>
  );
};

export default Select;
