import React, { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { useMutation } from '@apollo/client';
import { Box, styled, Typography } from '@material-ui/core';
import { Maybe } from 'graphql/jsutils/Maybe';

import CatalogCard from 'src/components/Card/CatalogCard';
import ExportCatalogPopup, { ExportPopupQueryType } from 'src/components/Popups/ExportCatalogPopup';
import TopBar from 'src/components/TopBar';
import {
  CATALOGS_PATH,
  CATALOG_SETTINGS,
  FIRST_DASHBOARD_PATHNAME,
  PRODUCT_TYPES_PATH,
} from 'src/constants/routeSources';
import { useCheckCatalogMappingErrors } from 'src/hooks/graphQLRequestsHooks';
import useCheckProposedMappings from 'src/hooks/useCheckProposedMappings';
import useControlSubscriptions from 'src/hooks/useControlSubscriptions';
import { useCatalogs } from 'src/providers/catalog';
import { useSnackbar } from 'src/providers/snackbar';
import { removeDuplicatedObjects } from 'src/utils/general';
import { getUnSyncableIntegrations } from 'src/utils/getUnSyncableIntegrations';
import {
  Catalog,
  exportCatalogToAllAttachedIntegrations,
  Mutation,
  MutationExportCatalogToAllAttachedIntegrationsArgs,
} from 'src/utils/gql';
import { ChooseExportPopupState, initialChooseExportPopupState, initialSyncPopupState } from 'src/views/Catalogs';
import { LinkToSettings } from 'src/views/Catalogs/styled';
import {
  INFINITE_SCROLL_LOADER_SKELETON_PRODUCT_TYPES_COUNT,
  SKELETON_SIDEBAR_CATALOGS_COUNT,
  SyncPopupState,
} from 'src/views/Catalogs/types';
import { calculateEnding } from 'src/views/Catalogs/utils';

import Popup from '../Popup';
import SubscriptionsPopup from '../Popups/SubscriptionsPopup';
import Scroll from '../Scroll';
import { SharedCataloguesSubscriptionsActions } from '../SharedCatalogsTable/types';
import SkeletonSmallCatalogCard, { SkeletonSmallCatalogCardWrapper } from '../Skeleton/SkeletonSmallCatalogCard';

import { CatalogListContainer, CatalogsSidebarList } from './styled';

const DescriptionContainer = styled(Box)(() => ({
  maxWidth: '396px',
  margin: '0 auto',
}));

interface ProductTypesSidebarProps {
  catalog: Maybe<Catalog>;
  onCatalogCardClick: () => void;
  loading?: boolean;
  userId?: string;
  catalogs: Maybe<Catalog[]>;
  onFetchMore: () => void;
  hasMore: boolean;
}
interface CatalogueState {
  id: string;
  name: string;
}

const ProductTypesSidebar = ({
  onCatalogCardClick,
  catalog,
  catalogs,
  userId,
  loading = false,
  onFetchMore,
  hasMore,
}: ProductTypesSidebarProps) => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const snackbar = useSnackbar();
  const { updateCurrentExport } = useCatalogs();

  const [chooseExportPopupState, setChooseExportPopupState] =
    useState<ChooseExportPopupState>(initialChooseExportPopupState);
  const [syncPopupState, setSyncPopupState] = useState<SyncPopupState>(initialSyncPopupState);
  const [catalogueState, setCatalogueState] = useState<CatalogueState>({
    id: '',
    name: '',
  });
  const [isSubscriptionPopupOpen, setIsSubscriptionPopupOpen] = useState<boolean>(false);
  const [subscriptionState, setSubscriptionState] = useState({
    catalogOwner: '',
    catalogName: '',
    subscriptionId: '',
  });

  const getPrice = (price?: Maybe<number>) => (price && Number(price) ? `$${price}, ` : '');

  const handleOpenSyncPopup = (e: SyntheticEvent, id?: string, isEmpty?: boolean) => {
    e.stopPropagation();
    if (!isEmpty && id) {
      setSyncPopupState({ open: true, selectedCatalogId: id });
    }
  };

  const [syncCatalog, { loading: syncCatalogLoading }] = useMutation<
    Pick<Mutation, 'exportCatalogToAllAttachedIntegrations'>,
    MutationExportCatalogToAllAttachedIntegrationsArgs
  >(exportCatalogToAllAttachedIntegrations);

  const { onCancelSubscription, loadingStatus } = useControlSubscriptions({
    subscriptionId: subscriptionState.subscriptionId,
  });

  const handleCloseSyncPopup = () => {
    setSyncPopupState((prev) => ({ ...prev, open: false }));
  };

  const { checkCatalogMappingsErrorsQuery, loading: loadingErrors } = useCheckCatalogMappingErrors(id);

  const { getListWithProposedMappings, getProposedMappingsLoading, renderMappingsSidebar } = useCheckProposedMappings({
    callbackFn: () => {
      updateCurrentExport(syncPopupState.selectedCatalogId);

      syncCatalog({ variables: { id: syncPopupState.selectedCatalogId } })
        .then(() => setSyncPopupState(initialSyncPopupState))
        .catch(({ message }) => {
          checkCatalogMappingsErrorsQuery();
          snackbar(message);
        });
    },
    closeCallbackFn: handleCloseSyncPopup,
    errorCallbackFn: checkCatalogMappingsErrorsQuery,
  });

  const handleExportToAttachedIntegrations = () => {
    getListWithProposedMappings(syncPopupState.selectedCatalogId);
  };

  const handleSubscriptionPopupOpen = (
    e: SyntheticEvent,
    subscriptionId: string,
    catalogName: string,
    catalogOwner: string,
  ) => {
    e.stopPropagation();

    setIsSubscriptionPopupOpen(true);

    setSubscriptionState((prevState) => ({
      ...prevState,
      subscriptionId,
      catalogName,
      catalogOwner,
    }));
  };

  const handleCatalogCardClick = (id: string) => {
    onCatalogCardClick();
    history.replace(`/${FIRST_DASHBOARD_PATHNAME}/${CATALOGS_PATH}/${id}/${PRODUCT_TYPES_PATH}`);
  };

  const onExportIconClick = (e: SyntheticEvent, id: string, name: string, isSkuDuplicated: boolean) => {
    e.stopPropagation();
    setCatalogueState((prevState: CatalogueState) => {
      return {
        ...prevState,
        id,
        name,
      };
    });
    setChooseExportPopupState({ open: true, isSkuDuplicated });
  };

  const handleCancelSubscription = async () => {
    await onCancelSubscription();

    setIsSubscriptionPopupOpen(false);
  };

  const selectedCatalogIntegrationsNotToSync = useMemo(() => {
    const selectedCatalog = catalogs?.find(({ id }) => id == syncPopupState.selectedCatalogId);
    if (selectedCatalog) {
      return getUnSyncableIntegrations(selectedCatalog);
    }
    return [];
  }, [catalogs, syncPopupState.selectedCatalogId]);

  const onSecondaryButtonSyncPopupClick = () => {
    if (selectedCatalogIntegrationsNotToSync?.length) {
      return handleCloseSyncPopup();
    }
    return history.push(
      `/${FIRST_DASHBOARD_PATHNAME}/${CATALOGS_PATH}/${syncPopupState.selectedCatalogId}/${CATALOG_SETTINGS}`,
    );
  };

  const ending = calculateEnding(selectedCatalogIntegrationsNotToSync);

  useEffect(() => {
    if (!loadingErrors) {
      setSyncPopupState(initialSyncPopupState);
    }
  }, [loadingErrors]);

  return (
    <Box display="flex" flexDirection="column" height="100%" padding="0 10px">
      <TopBar />

      <Typography variant="h2" mb="40px" ml="14px">
        {t('cataloguesPage.title')}
      </Typography>

      {loading ? (
        <SkeletonSmallCatalogCardWrapper>
          <SkeletonSmallCatalogCard cardsCount={SKELETON_SIDEBAR_CATALOGS_COUNT} />
        </SkeletonSmallCatalogCardWrapper>
      ) : (
        <CatalogListContainer id="catalogsList">
          <Scroll
            dataLength={catalogs?.length || 0}
            next={onFetchMore}
            hasMore={hasMore}
            loader={<SkeletonSmallCatalogCard cardsCount={INFINITE_SCROLL_LOADER_SKELETON_PRODUCT_TYPES_COUNT} />}
            scrollableTarget="catalogsList"
            pageId="catalogsList"
          >
            {catalogs && catalog && (
              <CatalogsSidebarList id="catalog-list-anchor">
                {[catalog, ...catalogs]?.map(
                  (
                    {
                      id,
                      isEmpty,
                      name,
                      source,
                      createdAt,
                      user,
                      config: { privacy, price, paymentFrequency },
                      subscriptions,
                      integrations,
                      mappingsErrors,
                      duplicatedSKUInfo,
                      syncable,
                    },
                    idx,
                  ) =>
                    (!idx || catalog.id !== id) && (
                      <CatalogCard
                        key={id}
                        id={id}
                        isEmpty={isEmpty}
                        syncable={syncable}
                        duplicatedSKUInfo={duplicatedSKUInfo}
                        size="small"
                        source={source}
                        headerTitle={name}
                        createdAt={createdAt}
                        catalogPrivacy={privacy}
                        isSharedCatalog={user.id !== userId}
                        subscriptionApprovalStatus={(subscriptions?.[0] && subscriptions[0].approvalStatus) || ''}
                        isChecked={!idx}
                        price={user.id !== userId ? { value: getPrice(price), frequency: paymentFrequency } : undefined}
                        onCardSelect={() => handleCatalogCardClick(id)}
                        hasAttachedIntegrations={Boolean(integrations?.length)}
                        onExportIconClick={(e) => onExportIconClick(e, id, name, duplicatedSKUInfo.isDuplicated)}
                        onSyncButtonClick={(e) => handleOpenSyncPopup(e, id, isEmpty)}
                        integrations={integrations}
                        mappingErrors={removeDuplicatedObjects(
                          [
                            ...(mappingsErrors || []),
                            ...(integrations?.flatMap(
                              ({ mappingsErrors }) =>
                                mappingsErrors?.filter(({ catalogId }) => !catalogId || catalogId === id) || [],
                            ) || []),
                          ],
                          'id',
                        )}
                        onUnsubscribeButtonClick={(e) =>
                          handleSubscriptionPopupOpen(e, subscriptions?.[0]?.id || '', name, user.name)
                        }
                      />
                    ),
                )}
              </CatalogsSidebarList>
            )}
          </Scroll>
        </CatalogListContainer>
      )}

      <Popup
        open={syncPopupState.open}
        mainTitle={t('cataloguesPage.syncPopup.title')}
        mainButtonText={t('cataloguesPage.syncPopup.mainButton')}
        secondaryButtonText={t(
          `cataloguesPage.syncPopup.${
            selectedCatalogIntegrationsNotToSync?.length ? 'secondaryButtonWithError' : 'secondaryButton'
          }`,
        )}
        onMainButtonClick={handleExportToAttachedIntegrations}
        loadingOnMainButton={syncCatalogLoading || getProposedMappingsLoading || loadingErrors}
        onSecondaryButtonClick={onSecondaryButtonSyncPopupClick}
        onClose={handleCloseSyncPopup}
      >
        <DescriptionContainer>
          <Typography color="text.secondary">
            {!!selectedCatalogIntegrationsNotToSync?.length ? (
              <Trans i18nKey="cataloguesPage.syncPopup.errorDescription">
                Cannot export to the
                <strong>
                  {{
                    integrations: selectedCatalogIntegrationsNotToSync
                      ?.map(({ settings }) => settings.integrationName)
                      .join(', '),
                  }}
                </strong>
                integration{{ ending }} because of errors. Visit
                <LinkToSettings
                  to={`/${FIRST_DASHBOARD_PATHNAME}/${CATALOGS_PATH}/${syncPopupState.selectedCatalogId}/${CATALOG_SETTINGS}`}
                >
                  catalog settings
                </LinkToSettings>
                to see the details. You can still export to other attached integrations.
              </Trans>
            ) : (
              t('cataloguesPage.syncPopup.description')
            )}
          </Typography>
        </DescriptionContainer>
      </Popup>

      <SubscriptionsPopup
        isOpen={isSubscriptionPopupOpen}
        handleClosePopup={() => setIsSubscriptionPopupOpen(false)}
        catalogName={subscriptionState.catalogName}
        catalogOwner={subscriptionState.catalogOwner}
        handleMainButtonClick={handleCancelSubscription}
        subscriptionAction={SharedCataloguesSubscriptionsActions.cancel}
        loadingStatus={loadingStatus}
      />

      <ExportCatalogPopup
        type={ExportPopupQueryType.CATALOG}
        name={catalogueState.name}
        id={catalogueState.id}
        chooseExportPopupState={chooseExportPopupState}
        setChooseExportPopupState={setChooseExportPopupState}
      />

      {renderMappingsSidebar()}
    </Box>
  );
};

export default ProductTypesSidebar;
