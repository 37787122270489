import React, { ChangeEvent, createRef, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { Box } from '@material-ui/core';
import clsx from 'clsx';
import { v4 as uuidv4 } from 'uuid';

import FullPageDropzone from 'src/components/Dropzone/FullPageDropzone';
import SkipImportPopup from 'src/components/Popups/SkipImportPopup';
import SideBar from 'src/components/SideBar';
import ImportFromCsvSidebar from 'src/components/SideBar/ImportFromCsvSidebar';
import { FILE_EXTENSION, PARSING_FILE_STATE } from 'src/constants';
import { CATALOGS_PATH, FIRST_DASHBOARD_PATHNAME } from 'src/constants/routeSources';
import useEmulateCsvParsing from 'src/hooks/useEmulateCsvParsing';
import useImportCsv from 'src/hooks/useImportCsv';
import LayoutContext from 'src/layouts/Dashboard/LayoutContext';
import useOnboarding from 'src/providers/OnboardingProvider/hooks/useOnboarding';
import { UploadingFile } from 'src/views/Catalogs/ProductItems/types';
import { onClickHandler } from 'src/views/types';

import PreviewField from './PreviewField';
import { Wrapper, CSVSidebarWrapper } from './styled';
import { ParsingTypesInfo } from './TypePreview';

const ImportFromCSV = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const inputFile = createRef<HTMLInputElement>();
  const { id: catalogId } = useParams<{ id: string; productTypeId: string }>();
  const { state } = useLocation<{ uploadingFiles: UploadingFile[] }>();
  const {
    onboardingState: { tourActive },
  } = useOnboarding();
  const { onboardingInfoValues, emulate } = useEmulateCsvParsing();

  const [viewAllItemsPath, setViewAllItemsPath] = useState<string>('');
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const [openSkipImportPopup, setOpenSkipImportPopup] = useState<boolean>(false);
  const [uploadingFiles, setUploadingFiles] = useState<UploadingFile[]>([]);
  const { upload, csvFilesState, deleteFile } = useImportCsv();
  const [selectedTypeId, setSelectedTypeId] = useState<string | null>(null);

  const handleReplayIconClick = async (id: string) => {
    const file = uploadingFiles.find((file) => file.id === id);

    if (selectedTypeId === id) {
      setSelectedTypeId(null);
    }

    if (file) {
      await upload([file], catalogId);
    }
  };

  const handleChange = async ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { files } = target;

    if (files) {
      const filesArray = Array.from(files);
      const newUploadingFiles = filesArray.map((file) => ({ file, id: uuidv4(), name: file.name }));

      setUploadingFiles((prevUploadingFiles) => [...prevUploadingFiles, ...newUploadingFiles]);

      await upload(newUploadingFiles, catalogId);
    }

    target.value = '';
  };

  const handleDropFiles = async (files: File[]) => {
    const filesArray = Array.from(files);
    const newUploadingFiles = filesArray.map((file) => ({ file, id: uuidv4(), name: file.name }));
    const csvFiles = [...uploadingFiles, ...newUploadingFiles];

    setUploadingFiles(csvFiles);
    await upload(newUploadingFiles, catalogId);
  };

  useEffect(() => {
    if (!tourActive) {
      if (state?.uploadingFiles) {
        setUploadingFiles(state.uploadingFiles);

        upload(state.uploadingFiles, catalogId, true).then(() => window.history.replaceState({}, ''));
      } else {
        history.push(`/${FIRST_DASHBOARD_PATHNAME}`);
      }
    } else {
      emulate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tourActive, state?.uploadingFiles]);

  const handleDeleteFile = useCallback(
    (id: string) => {
      setSelectedTypeId((prevSelectedType) => (prevSelectedType === id ? null : prevSelectedType));
      deleteFile(id);
    },
    [deleteFile],
  );

  const selectedType =
    tourActive && selectedTypeId
      ? onboardingInfoValues
      : selectedTypeId
      ? csvFilesState[selectedTypeId as string]
      : null;

  const handleAddButton: onClickHandler = (e) => {
    e.stopPropagation();
    inputFile.current?.click();
  };

  const handleExpandedButton: onClickHandler = (e) => {
    e.stopPropagation();
    setIsExpanded((prev) => !prev);
  };

  const { fullscreenComponentsHeight } = useContext(LayoutContext);

  const filesInfoValues = useMemo(
    () => (tourActive ? [onboardingInfoValues] : Object.values(csvFilesState)),
    [csvFilesState, onboardingInfoValues, tourActive],
  );

  const parsingTypesInfo: ParsingTypesInfo = useMemo(() => {
    const getFileNamesArrayByState = (baseState: PARSING_FILE_STATE) =>
      filesInfoValues.filter(({ state }) => state === baseState).map(({ name }) => name);

    const getFilesCountNotLessState = (baseState: PARSING_FILE_STATE) =>
      filesInfoValues.filter(({ state }) => state >= baseState).length;

    return {
      notParsedFiles: getFileNamesArrayByState(PARSING_FILE_STATE.ParsingError),
      notUploadedFiles: getFileNamesArrayByState(PARSING_FILE_STATE.UploadingError),
      parsedFilesCount: getFilesCountNotLessState(PARSING_FILE_STATE.Parsed),
      uploadedFilesCount: getFilesCountNotLessState(PARSING_FILE_STATE.Uploaded),
      totalFilesCount: filesInfoValues.length,
    };
  }, [filesInfoValues]);

  const title = t('importFromCsvPage.title');

  return (
    <Wrapper title={title} locationName={title}>
      <FullPageDropzone onDropFiles={handleDropFiles}>
        <Box position="relative" display="flex" width="100%" height={fullscreenComponentsHeight}>
          {tourActive && (
            <Box id="import-csv-anchor" position="absolute" top="74px" left="36px" right="50px" bottom="30px" />
          )}

          <CSVSidebarWrapper className={clsx(isExpanded && 'expanded-width')} onClick={() => setSelectedTypeId(null)}>
            <SideBar isExpanded={isExpanded} onClick={handleExpandedButton} width="100%">
              <ImportFromCsvSidebar
                onAddButtonClick={handleAddButton}
                onReplayIconClick={handleReplayIconClick}
                filesStateValues={filesInfoValues}
                deleteFile={handleDeleteFile}
                selectCard={setSelectedTypeId}
                viewAllItemsPath={viewAllItemsPath}
              />
            </SideBar>
          </CSVSidebarWrapper>

          <input
            type="file"
            accept={`${FILE_EXTENSION.csv}, .csv, ${FILE_EXTENSION.csvWin}`}
            multiple
            ref={inputFile}
            style={{ display: 'none' }}
            onChange={handleChange}
          />

          <Box width="100%">
            <PreviewField
              setViewAllItemsPath={setViewAllItemsPath}
              selectedTypeInfo={selectedType}
              parsingTypesInfo={parsingTypesInfo}
            />
          </Box>
        </Box>

        <SkipImportPopup
          open={openSkipImportPopup}
          onClose={() => setOpenSkipImportPopup(false)}
          onMainButtonClick={() => setOpenSkipImportPopup(false)}
          onSecondaryButtonClick={() => history.push(`/${FIRST_DASHBOARD_PATHNAME}/${CATALOGS_PATH}`)}
        />
      </FullPageDropzone>
    </Wrapper>
  );
};

export default ImportFromCSV;
