import React, { ChangeEvent, Fragment, ReactNode, useContext, useMemo, useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ApolloError, useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Checkbox, InputAdornment, Typography } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';

import MediaDropzone from 'src/components/Dropzone/MediaDropzone';
import DuplicatedSKUMessage, { DuplicatedSKUMessageType } from 'src/components/DuplicatedSKUMessage';
import { urlMediaSchema } from 'src/components/Forms/validationSchemas';
import GeneratingTextInputButton from 'src/components/GeneratingTextInputButton';
import Hint from 'src/components/Hint';
import Info from 'src/components/Icon/info.svg';
import MappingsButton from 'src/components/MappingsButton';
import SidebarMediaGallery from 'src/components/MediaGallery/ComonGallerySidebar';
import { MediaDataType } from 'src/components/MediaGallery/constants';
import MediaContent from 'src/components/MediaGallery/MediaContent';
import MediaMenu from 'src/components/MediaGallery/MediaMenu';
import MultiTypeInput, {
  MultiTypeInputAdditionalFieldType,
  MultiTypeInputFieldType,
} from 'src/components/MultiTypeInput';
import AddUrlPopup from 'src/components/Popups/AddUrlPopup';
import DeletePopup from 'src/components/Popups/DeletePopup';
import ImageEditorSidebar, { MediaForUpdate } from 'src/components/SideBar/ImageEditorSidebar/ImageEditorSidebar';
import MappingSidebar from 'src/components/SideBar/MappingSidebar';
import { MappingSidebarType } from 'src/components/SideBar/MappingSidebar/types';
import { SKU_PATTERN } from 'src/constants';
import useCustomEvent, { CustomEventName } from 'src/hooks/useCustomEvent';
import useUploadMedia from 'src/hooks/useUploadMedia';
import { MediaStateContext } from 'src/providers/MediaProvider/context';
import useOnboarding from 'src/providers/OnboardingProvider/hooks/useOnboarding';
import { useSnackbar } from 'src/providers/snackbar';
import {
  detachMediaFromProductItem,
  DuplicatedSkuInfo,
  FieldSystemName,
  GeneratedTextType,
  generateText,
  MediaType,
  Mutation,
  MutationGenerateTextArgs,
  ProductTypeField,
} from 'src/utils/gql';
import { disableChromeCacheForUrl } from 'src/utils/imageUtils';

import {
  ItemDetailsBodyWrapper,
  ItemDetailsSectorTitleWrapper,
  StyledCheckbox,
  StyledDeleteButton,
  StyledImg,
} from './styled';
import { UploadingMedia } from './types';

interface GeneratedFieldData {
  isLoading: boolean;
  isGenerated: boolean;
  previousValue: string;
}

interface GeneratedFieldsData {
  [key: string]: GeneratedFieldData;
}

interface ItemDetailsBodyProps {
  saveItemOnOpenMappingSidebar: () => void;
  fields: ProductTypeField[];
  saveButtonLoading: boolean;
  duplicatedSKUInfo?: DuplicatedSkuInfo;
  productItemId?: string;
  hideGeneratingTextButton?: boolean;
}

interface ProductTypeFieldPresentedData extends Omit<ProductTypeField, 'type'> {
  type: MultiTypeInputFieldType;
}

interface SectorData {
  sectorName: {
    i18nKey: string;
    options?: { [key: string]: string };
  };
  fields?: ProductTypeFieldPresentedData[];
  additionalElements?: ReactNode[];
  fullwidth?: boolean;
  body?: ReactNode;
  mediaBlock?: boolean;
  hasMappingButton?: boolean;
  createEndAdornment?: (name: string) => React.ReactNode | undefined;
  onManualChange?: (value: string, id: string) => void;
}

interface MediaToSet {
  altText?: string;
  isChangedAltText?: boolean;
  id: string;
  mediaUsageId?: string;
  uuid?: string;
  type?: MediaType;
  url?: string;
  name?: string;
  file?: File;
  expansion?: string;
  previewUrl?: string;
  createdAt?: string;
  typeAddedData?: MediaDataType;
  canBeReset?: boolean;
  orderNumber?: number;
  mediaId?: string;
}

export const isLastSingleField = (fieldIdx: number, fieldCount: number): boolean =>
  !(fieldIdx % 2) && fieldIdx === fieldCount - 1;

const ItemDetailsBody = ({
  fields,
  saveButtonLoading,
  productItemId,
  saveItemOnOpenMappingSidebar,
  duplicatedSKUInfo,
  hideGeneratingTextButton,
}: ItemDetailsBodyProps) => {
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const { productItemId: itemId, catalogId } = useParams<{
    productItemId: string;
    catalogId: string;
    productTypeId: string;
  }>();
  const { handleUploadMedia } = useUploadMedia();
  const {
    onboardingState: { tourActive },
  } = useOnboarding();
  const { watch, setValue: setItemValue, getValues: getItemValues, trigger } = useFormContext();

  const [isMediaSideBarPopupOpen, setIsMediaSideBarPopupOpen] = useState<boolean>(false);
  const [selectedMediaFilesIds, setSelectedMediaFilesIds] = useState<Array<string>>([]);
  const [openDeleteMediaPopup, isOpenDeleteMediaPopup] = useState<boolean>(false);
  const [addUrlPopup, setAddUrlPopup] = useState<boolean>(false);
  const [isImageEditorOpen, setIsImageEditorOpen] = useState<boolean>(false);
  const [editedImage, setEditedImage] = useState<UploadingMedia>();
  const [isMappingSidebarOpen, setIsMappingSidebarOpen] = useState<boolean>(false);
  const [isDiscardChangesPopupOpen, setIsDiscardChangesPopupOpen] = useState(false);

  const [generatedFieldsData, setGeneratedFieldsData] = useState<GeneratedFieldsData>({});

  const { mediaState, setMediaState, addMediaToDetach } = useContext(MediaStateContext);

  const [detachMediaFromItem, { loading }] = useMutation(detachMediaFromProductItem);
  const [generateItemFieldText] = useMutation<Pick<Mutation, 'generateText'>, MutationGenerateTextArgs>(generateText);

  const isSKUDuplicated = duplicatedSKUInfo?.isDuplicated;

  const formProps = useForm({
    mode: 'onChange',
    resolver: yupResolver(urlMediaSchema),
  });

  const {
    getValues,
    reset,
    formState: { isValid },
  } = formProps;

  const { dispatchCustomEvent } = useCustomEvent();

  const handleCloseMappingSidebar = (withCheck = true) => {
    if (withCheck) {
      const checkChanges = (
        event: CustomEvent<{
          hasChanges: boolean;
        }>,
      ) => {
        const hasChanges = event.detail.hasChanges;

        if (hasChanges) {
          setIsDiscardChangesPopupOpen(true);
        } else {
          setIsMappingSidebarOpen(false);
        }

        document.removeEventListener(
          CustomEventName.checkItemMappingsChanges,
          checkChanges as EventListenerOrEventListenerObject,
        );
      };

      document.addEventListener(
        CustomEventName.checkItemMappingsChanges,
        checkChanges as EventListenerOrEventListenerObject,
      );

      dispatchCustomEvent(CustomEventName.customCloseMappingsSidebar);
    } else {
      setIsDiscardChangesPopupOpen(false);
      setIsMappingSidebarOpen(false);
    }
  };

  const handleMappingsButtonClick = () => {
    saveItemOnOpenMappingSidebar();
    setIsMappingSidebarOpen(true);
  };

  const handleOpenAddUrlPopup = () => {
    reset();
    setAddUrlPopup(true);
  };

  const handleCloseAddUrlPopup = () => {
    setAddUrlPopup(false);
  };

  const handleCloseImageEditor = () => {
    setIsImageEditorOpen(false);
  };

  const handleOpenImageEditor = (image: UploadingMedia) => {
    if (image) {
      setEditedImage(image);

      checkUrl(image);
    }
  };

  const changeMedia = (image: UploadingMedia) => {
    checkUrl(image, true);
  };

  const urlData = getValues();

  const [fieldsMapBySystemName, customFields] = useMemo(() => {
    const fieldsWithoutSystemName: ProductTypeField[] = [];

    const fieldsMapBySystemNameAcc = fields.reduce((acc: Record<string, ProductTypeField>, field) => {
      if (field.systemName) {
        acc[field.systemName] = field;
      } else {
        fieldsWithoutSystemName.push(field);
      }

      return acc;
    }, {});

    return [fieldsMapBySystemNameAcc, fieldsWithoutSystemName];
  }, [fields]);

  const handleChangeCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    const { id, checked } = event.target;

    const idAllMedia = mediaState.map(({ uuid }) => uuid);

    id
      ? setSelectedMediaFilesIds((prevState) =>
          checked ? [...prevState, id] : prevState.filter((cardId) => cardId !== id),
        )
      : setSelectedMediaFilesIds(!checked && selectedMediaFilesIds.length === idAllMedia.length ? [] : idAllMedia);
  };

  const isAllFilesSelected = selectedMediaFilesIds.length === mediaState.length;

  const labelCheckbox = t(`productItemCreateEdit.mediaGallery.${isAllFilesSelected ? 'all' : 'files'}Selected`, {
    count: selectedMediaFilesIds.length,
  });

  const handleDeleteMedia = async (selectedMediaIds: Array<string>) => {
    const mediaIdsFromDatabase = mediaState.reduce((acc: string[], { typeAddedData, mediaUsageId, uuid }) => {
      if (typeAddedData === MediaDataType.FROM_DATABASE && selectedMediaIds.includes(uuid) && mediaUsageId) {
        acc.push(mediaUsageId);
      }

      return acc;
    }, []);

    const updateMediaState = mediaState?.filter(({ uuid }) => !selectedMediaIds.includes(uuid));

    if (mediaIdsFromDatabase.length) {
      try {
        await detachMediaFromItem({
          variables: {
            productItemId,
            mediaUsagesIds: mediaIdsFromDatabase,
          },
        });

        snackbar(t('productItemCreateEdit.mediaGallery.successDeleted'), 'success');
      } catch (error) {
        const { graphQLErrors, message: errorText } = error as ApolloError;
        const message = graphQLErrors && graphQLErrors.length ? graphQLErrors[0].message : errorText;

        if (error) {
          snackbar(message);
        }
      }
    }

    setMediaState(updateMediaState);

    setSelectedMediaFilesIds([]);

    isOpenDeleteMediaPopup(false);
  };

  const deleteMediaFilesButtonText = t(
    `productItemCreateEdit.mediaGallery.deleteFile${selectedMediaFilesIds.length > 1 ? 's' : ''}Button`,
  );

  const handleApplyImageChanges = (changedMediaArray: MediaForUpdate[]) => {
    let mediaForDetachArray: string[] = [];

    const arrayToSave = changedMediaArray.reduce((arrayToSave: MediaToSet[], changedMedia) => {
      let mediaToChange: MediaToSet = { id: changedMedia.mediaId };
      const mediaForDetach = changedMedia.mediaForDetach;

      if (changedMedia.isAltTextChanged && changedMedia.changedAltText) {
        mediaToChange = { ...mediaToChange, altText: changedMedia.changedAltText, isChangedAltText: true };
      }

      if (changedMedia.mediaForUpdate) {
        mediaToChange = { ...changedMedia.mediaForUpdate, ...mediaToChange, mediaId: changedMedia.mediaId };
        mediaForDetachArray = [...mediaForDetachArray, changedMedia.mediaId];
      }

      if (mediaForDetach) {
        mediaToChange = { ...changedMedia.mediaForUpdate, ...mediaToChange, mediaId: changedMedia.mediaId };
        mediaForDetachArray = [...mediaForDetachArray, mediaForDetach];
      }

      return [...arrayToSave, mediaToChange];
    }, []);

    const mediaStateToUpdateArray: UploadingMedia[] = mediaState.map((media) => {
      const newId = uuidv4();
      let changedMediaToSave = arrayToSave.find((changedMedia) => media.id === changedMedia.id);
      if (changedMediaToSave && changedMediaToSave.file) {
        changedMediaToSave = { ...changedMediaToSave, id: newId };
      }
      return changedMediaToSave ? { ...media, ...changedMediaToSave } : media;
    });

    setMediaState(mediaStateToUpdateArray);

    if (mediaForDetachArray) {
      addMediaToDetach(mediaForDetachArray);
    }
  };

  const checkUrl = async (image: UploadingMedia, otherMedia?: boolean) => {
    const startEditImage = () => (otherMedia ? setEditedImage(image) : setIsImageEditorOpen(true));

    if (!image.file && (image.url || image.previewUrl)) {
      fetch(disableChromeCacheForUrl(image.url || image.previewUrl || ''), {
        mode: 'cors',
      })
        .then(() => {
          startEditImage();
        })
        .catch(() => {
          snackbar(t('productItemCreateEdit.mediaGallery.imageEditorSideBar.errorSnackbar'));

          if (!otherMedia) {
            setIsImageEditorOpen(false);
          }
        });
    } else if (image.file) {
      startEditImage();
    }
  };

  const descriptionFieldId = useMemo(
    () => fields.find(({ systemName }) => systemName === FieldSystemName.Description)?.id || '',
    [fields],
  );
  const nameFieldId = useMemo(
    () => fields?.find(({ systemName }) => systemName === FieldSystemName.Name)?.id || '',
    [fields],
  );
  const defaultGeneratedFieldData = { isLoading: false, isGenerated: false, previousValue: '' };
  const { isGenerated: isNameGenerated, isLoading: isNameLoading } =
    generatedFieldsData[nameFieldId] || defaultGeneratedFieldData;
  const { isGenerated: isDescriptionGenerated, isLoading: isDescriptionLoading } =
    generatedFieldsData[descriptionFieldId] || defaultGeneratedFieldData;
  const hideGeneratingDescriptionButton = hideGeneratingTextButton && !watch(nameFieldId) && !isDescriptionGenerated;

  const handleRevert = (generatedFieldData: GeneratedFieldData, id: string) => {
    setItemValue(id, generatedFieldData.previousValue);
    trigger(id);
    setGeneratedFieldsData((prev) => ({ ...prev, [id]: { ...generatedFieldData, isGenerated: false } }));
  };

  const handleGenerate = async (generatedFieldData: GeneratedFieldData, id: string, type: GeneratedTextType) => {
    const idFieldNameMap: { [key: string]: string } = {};
    const itemValues = getItemValues();

    fields.forEach(({ id, name, systemName }) => {
      if (systemName !== FieldSystemName.Price && systemName !== FieldSystemName.Sku) {
        idFieldNameMap[id] = name;
      }
    });

    const productItemFieldsData = Object.keys(idFieldNameMap)
      .map((id) => ({
        fieldName: idFieldNameMap[id],
        fieldValue: itemValues[id] || '',
      }))
      .filter((field) => Object.values(field).every((value) => value !== ''));

    setGeneratedFieldsData((prev) => ({ ...prev, [id]: { ...generatedFieldData, isLoading: true } }));

    try {
      const { data } = await generateItemFieldText({
        variables: { productItemFieldsData, generatedTextType: type },
      });

      setGeneratedFieldsData((prev) => ({
        ...prev,
        [id]: { previousValue: itemValues[id] || '', isGenerated: true, isLoading: false },
      }));
      setItemValue(id, data?.generateText.generatedText);
      trigger(id);
    } catch (err) {
      snackbar((err as Error).message);
      setGeneratedFieldsData((prev) => ({ ...prev, [id]: { ...generatedFieldData, isLoading: false } }));
    }
  };

  const hendleClickGenerateButton = (generatedFieldData: GeneratedFieldData, id: string, type: GeneratedTextType) => {
    if (generatedFieldData?.isGenerated) {
      return handleRevert(generatedFieldData, id);
    }

    handleGenerate(generatedFieldData, id, type);
  };

  const handleManualChange = (value: string, id: string) => {
    if (id == descriptionFieldId || id == nameFieldId) {
      setGeneratedFieldsData({
        ...generatedFieldsData,
        [id]: {
          ...generatedFieldsData[id],
          isGenerated: false,
        },
      });
    }

    setItemValue(id, value);
    trigger(id);
  };

  return (
    <ItemDetailsBodyWrapper position="relative">
      {tourActive && (
        <>
          <Box id="name-price-sku-block-anchor" position="absolute" width="100%" height="104px" top="46px" />

          <Box id="media-description-block-anchor" position="absolute" width="100%" height="490px" top="185px" />
        </>
      )}

      {[
        {
          sectorName: { i18nKey: 'defaultFields' },
          hasMappingButton: true,
          fields: [
            fieldsMapBySystemName[FieldSystemName.Name],
            fieldsMapBySystemName[FieldSystemName.Price],
            fieldsMapBySystemName[FieldSystemName.Sku],
          ],
          createEndAdornment: (name: string) => {
            if (name === FieldSystemName.Name && !hideGeneratingTextButton) {
              return (
                <GeneratingTextInputButton
                  isLoading={isNameLoading}
                  isTextGenerated={isNameGenerated}
                  onClick={() =>
                    hendleClickGenerateButton(generatedFieldsData[nameFieldId], nameFieldId, GeneratedTextType.Name)
                  }
                />
              );
            }

            if (name === FieldSystemName.Sku && isSKUDuplicated) {
              return (
                <InputAdornment position="end">
                  <Hint
                    placement="top"
                    isDefaultFieldValueHint
                    type="hover"
                    tooltipWidth="188px"
                    title={
                      <DuplicatedSKUMessage
                        type={DuplicatedSKUMessageType.fieldHint}
                        duplicatedSKUInfo={duplicatedSKUInfo}
                        catalogId={catalogId}
                      />
                    }
                  >
                    <StyledImg src={Info} alt="info" width="20px" height="20px" />
                  </Hint>
                </InputAdornment>
              );
            }
          },
          onManualChange: handleManualChange,
        },
        {
          sectorName: { i18nKey: 'description' },
          fields: [
            {
              ...fieldsMapBySystemName[FieldSystemName.Description],
              type: MultiTypeInputAdditionalFieldType.Text,
            },
          ],
          fullwidth: true,
          createEndAdornment: (name: string) => {
            if (name === FieldSystemName.Description && !hideGeneratingDescriptionButton) {
              return (
                <GeneratingTextInputButton
                  isLoading={isDescriptionLoading}
                  isTextGenerated={isDescriptionGenerated}
                  onClick={() =>
                    hendleClickGenerateButton(
                      generatedFieldsData[descriptionFieldId],
                      descriptionFieldId,
                      GeneratedTextType.Description,
                    )
                  }
                />
              );
            }
          },
          onManualChange: handleManualChange,
        },
        {
          sectorName: { i18nKey: 'media' },
          additionalElements: [
            <Box height="24px" key={uuidv4()}>
              {selectedMediaFilesIds.length ? (
                <StyledDeleteButton onClick={() => isOpenDeleteMediaPopup(true)} data-testid="deleteMediaButton">
                  <Typography fontSize="15px" color="error" fontWeight="500">
                    {deleteMediaFilesButtonText}
                  </Typography>
                </StyledDeleteButton>
              ) : (
                <>
                  {!tourActive && (
                    <MediaMenu
                      handleOpenAddUrlPopup={handleOpenAddUrlPopup}
                      handleOpenMediaGallery={() => setIsMediaSideBarPopupOpen(true)}
                    />
                  )}
                </>
              )}
            </Box>,
          ],
          body: (
            <Box gridColumn="1 / -1">
              <Box sx={{ position: 'relative' }}>
                <ImageEditorSidebar
                  isOpen={isImageEditorOpen}
                  onApply={handleApplyImageChanges}
                  onCloseSidebar={handleCloseImageEditor}
                  editedImage={editedImage as UploadingMedia}
                  changeMedia={changeMedia}
                />

                <MediaDropzone handleUploadMedia={handleUploadMedia}>
                  <MediaContent
                    setAddUrlPopup={handleOpenAddUrlPopup}
                    handleChangeCheckbox={handleChangeCheckbox}
                    selectedMediaFiles={selectedMediaFilesIds}
                    handleOpenImageEditor={handleOpenImageEditor}
                  />
                </MediaDropzone>

                <MappingSidebar
                  sidebarType={MappingSidebarType.ItemMapping}
                  saveButtonLoading={saveButtonLoading}
                  isOpen={isMappingSidebarOpen}
                  isDiscardChangesPopupOpen={isDiscardChangesPopupOpen}
                  onCloseDiscardChangesPopup={() => setIsDiscardChangesPopupOpen(false)}
                  proposedMappings={[]}
                  selectedCatalogId={catalogId}
                  onClose={handleCloseMappingSidebar}
                />
              </Box>
              <DeletePopup
                open={openDeleteMediaPopup}
                onClose={() => isOpenDeleteMediaPopup(false)}
                onMainButtonClick={() => handleDeleteMedia(selectedMediaFilesIds)}
                onSecondaryButtonClick={() => isOpenDeleteMediaPopup(false)}
                descriptionText={t('deletePopup.item.text')}
                mainTitle={
                  <Typography marginLeft="60px" marginRight="60px" variant="h2">
                    {t('productItemCreateEdit.mediaGallery.titleDeletePopup')}
                  </Typography>
                }
                loadingOnMainButton={loading}
              />
              <FormProvider {...formProps}>
                <AddUrlPopup
                  urlData={urlData}
                  addUrlPopup={addUrlPopup}
                  isValid={isValid}
                  handleCloseAddUrlPopup={handleCloseAddUrlPopup}
                />
              </FormProvider>
              {isMediaSideBarPopupOpen && (
                <SidebarMediaGallery
                  setIsMediaSideBarPopupOpen={setIsMediaSideBarPopupOpen}
                  isMediaSideBarPopupOpen={isMediaSideBarPopupOpen}
                />
              )}
            </Box>
          ),
          mediaBlock: true,
        },
        ...(customFields.length ? [{ sectorName: { i18nKey: 'customFields' }, fields: customFields }] : []),
        // { sectorName: 'options' },
        // { sectorName: 'variants' },
      ].map(
        (
          {
            sectorName: { i18nKey, options },
            fields: sectorFields,
            additionalElements,
            fullwidth,
            body,
            mediaBlock,
            hasMappingButton,
            createEndAdornment,
            onManualChange,
          }: SectorData,
          idx,
        ) => (
          <Fragment key={`fragmentKey${idx}`}>
            <ItemDetailsSectorTitleWrapper key={uuidv4()} mt={idx ? '10px' : 0}>
              {mediaBlock ? (
                <>
                  {selectedMediaFilesIds.length ? (
                    <StyledCheckbox
                      label={labelCheckbox}
                      control={
                        <Checkbox
                          checked={!!selectedMediaFilesIds.length}
                          indeterminate={selectedMediaFilesIds.length !== mediaState.length}
                          onChange={handleChangeCheckbox}
                        />
                      }
                    />
                  ) : (
                    <Typography variant="subtitle2">{t(`productItemCreateEdit.body.${i18nKey}`, options)}</Typography>
                  )}
                </>
              ) : (
                <>
                  <Typography variant="subtitle2">{t(`productItemCreateEdit.body.${i18nKey}`, options)}</Typography>

                  {hasMappingButton && itemId && (
                    <MappingsButton testId="itemMappingsButton" onClick={handleMappingsButtonClick} />
                  )}
                </>
              )}

              {additionalElements}
            </ItemDetailsSectorTitleWrapper>

            {sectorFields?.map(({ name, type, id, required, systemName }, fieldIdx) => (
              <Box
                key={id}
                gridColumn={fullwidth || isLastSingleField(fieldIdx, sectorFields.length) ? '1 / -1' : undefined}
                mb="15px"
                mt="7px"
              >
                <MultiTypeInput
                  autoFocus={!(idx || fieldIdx)}
                  regex={
                    systemName === FieldSystemName.Sku
                      ? {
                          value: SKU_PATTERN,
                          message: t('productItemCreateEdit.sidebar.skuHelperText'),
                        }
                      : undefined
                  }
                  label={systemName ? t(`productType.systemNames.${systemName}`) : name}
                  shouldBeFilled={required && (systemName === FieldSystemName.Name || !watch('draft'))}
                  emptyErrorMessage={t('productItemCreateEdit.sidebar.helperText')}
                  type={type}
                  disabled={saveButtonLoading || generatedFieldsData[id]?.isLoading}
                  inputIdentifier={`${id}` as const}
                  productTypeFieldId={id}
                  onCreateEndAdornment={() => createEndAdornment?.(systemName || name)}
                  onManualChange={onManualChange ? (value) => onManualChange(value, id) : undefined}
                />
              </Box>
            ))}

            {body}
          </Fragment>
        ),
      )}
    </ItemDetailsBodyWrapper>
  );
};

export default ItemDetailsBody;
