import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';
import { Box, IconButton, makeStyles, Skeleton, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { isEqual } from 'lodash';

import Iconography from 'src/components/Iconography';
import Select, { SelectOptionItem } from 'src/components/Select';
import { PRODUCT_TYPES_DEFAULT_LIMIT } from 'src/constants';
import { EventsService, EventName } from 'src/services/Events';
import { getProductTypesByCatalogId, Query, QueryProductTypesArgs } from 'src/utils/gql';
import { FilterItemWithNewValues } from 'src/views/Catalogs/ProductItems/types';

import SkeletonFilters from '../Skeleton/SkeletonFilters';

import FilterItem from './FilterItem';
import { FilterButton, ProductTypeSelectWrapper, HeightSeparatorBlock, FilterItemsWrapper } from './styled';

const useStyles = makeStyles(() => ({
  filtersSidebar: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    zIndex: 1,
    position: 'relative',
    transition: 'max-width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, opacity 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    marginLeft: '8px',
    marginTop: '31px',
  },
  expandedSidebar: {
    maxWidth: '320px',
    opacity: 1,
  },
  collapsedSidebar: {
    maxWidth: '0',
    opacity: 0,
    marginLeft: 0,
    height: 0,
  },
}));

interface ProductItemsSidebarProps {
  filters: FilterItemWithNewValues[];
  showFilters: boolean;
  setLoading: (newValue: boolean) => void;
  onChangeSelect: (newValue?: SelectOptionItem) => void;
  onResetFilters: () => void;
  onChangeFilter: (values: string[], itemId: string, emptyValues: boolean) => void;
  filtersLoading?: boolean;
  resultsFound?: number;
  id?: string;
}

interface FiltersExpandedState {
  [productTypeFieldId: string]: boolean;
}

export default function ProductItemsSidebar({
  onChangeSelect,
  filtersLoading,
  onResetFilters,
  setLoading,
  resultsFound,
  filters,
  showFilters,
  onChangeFilter,
  id,
}: ProductItemsSidebarProps) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { id: catalogId, productTypeId } = useParams<{ id: string; productTypeId: string }>();
  const [hasMoreProductTypes, setHasMoreProductTypes] = useState(false);
  const [filtersExpandedState, setFiltersExpandedState] = useState<FiltersExpandedState>({});

  const handleUpdateProductTypes = (data: Pick<Query, 'productTypes'>) =>
    setHasMoreProductTypes(data.productTypes.length === PRODUCT_TYPES_DEFAULT_LIMIT);

  const hasFilters = filters.map(({ newValues }) => newValues).some((newValues) => newValues?.length);

  // TODO : should be refactored
  const {
    data: productTypesData,
    loading,
    fetchMore,
  } = useQuery<Pick<Query, 'productTypes'>, QueryProductTypesArgs>(getProductTypesByCatalogId, {
    fetchPolicy: 'cache-first',
    variables: {
      catalogId,
      limit: PRODUCT_TYPES_DEFAULT_LIMIT,
    },
    onCompleted: handleUpdateProductTypes,
  });

  const fetchMoreProductTypes = useCallback(async () => {
    const offset = productTypesData?.productTypes ? (productTypesData?.productTypes.length as number) : 0;

    handleUpdateProductTypes(
      (
        await fetchMore<'offset'>({
          variables: {
            offset,
          },
        })
      ).data,
    );
  }, [productTypesData?.productTypes, fetchMore]);

  useEffect(() => {
    if (hasMoreProductTypes) {
      fetchMoreProductTypes();
    }
  }, [hasMoreProductTypes, fetchMoreProductTypes]);

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  const options = useMemo(
    () =>
      productTypesData
        ? productTypesData.productTypes.map(({ name, id }) => ({
            label: name,
            value: id,
          }))
        : [],
    [productTypesData],
  );

  const autocompleteValue = useMemo(
    () => options.find(({ value }) => value === productTypeId),
    [options, productTypeId],
  );

  const isLoading = loading || !autocompleteValue;

  const handleFilterExpandStateChange = (filterId: string, expanded: boolean) => {
    setFiltersExpandedState((prev) => ({
      ...prev,
      [filterId]: expanded,
    }));
  };

  useEffect(() => {
    const areNewFilters = !isEqual(
      Object.keys(filtersExpandedState),
      filters.map(({ productTypeFieldId }) => productTypeFieldId),
    );

    if (areNewFilters) {
      const filtersExpandedInitState: FiltersExpandedState = {};

      const areAllFiltersShouldBeExpanded = !filters.some(({ newValues }) => newValues.length);

      filters.forEach(({ productTypeFieldId, newValues }) => {
        filtersExpandedInitState[productTypeFieldId] = areAllFiltersShouldBeExpanded || Boolean(newValues.length);
      });

      setFiltersExpandedState(filtersExpandedInitState);
    }
  }, [filters, filtersExpandedState]);

  useEffect(() => {
    const handleFiltersReset = () => {
      setFiltersExpandedState((prev) => {
        const newState = { ...prev };

        Object.keys(newState).forEach((key) => {
          newState[key] = true;
        });

        return newState;
      });
    };

    EventsService.subscribe(EventName.FiltersReset, handleFiltersReset);

    return () => {
      EventsService.unsubscribe(EventName.FiltersReset, handleFiltersReset);
    };
  }, []);

  return (
    <Box
      className={clsx(classes.filtersSidebar, showFilters ? classes.expandedSidebar : classes.collapsedSidebar)}
      id={id}
    >
      {isLoading ? (
        <Box mt="22px">
          <Skeleton variant="rectangular" width="100%" height="46px" />
        </Box>
      ) : (
        <ProductTypeSelectWrapper>
          <Select
            options={options}
            onChangeSelect={onChangeSelect}
            selectedOptionItem={autocompleteValue}
            label={t('productItemsPage.productType')}
            disabled={filtersLoading}
          />
        </ProductTypeSelectWrapper>
      )}

      {isLoading ? (
        <SkeletonFilters />
      ) : (
        <FilterItemsWrapper>
          {filters.map((item) => (
            <FilterItem
              key={item.productTypeFieldId}
              item={item}
              onChange={onChangeFilter}
              expanded={filtersExpandedState[item.productTypeFieldId] ?? true}
              onExpandedChange={(expanded) => handleFilterExpandStateChange(item.productTypeFieldId, expanded)}
            />
          ))}
          <HeightSeparatorBlock />
        </FilterItemsWrapper>
      )}

      <FilterButton>
        {filtersLoading ? (
          <Typography variant="subtitle2" color="inherit" mr="15px">
            {t('productItemsPage.loading')}
          </Typography>
        ) : (
          <Typography variant="subtitle2" color="inherit" mr="15px">
            {!hasFilters && resultsFound
              ? t('productItemsPage.itemsCount', { count: resultsFound })
              : resultsFound
              ? t('productItemsPage.itemsWereFound', { count: resultsFound })
              : t('productItemsPage.itemsNotFound')}
          </Typography>
        )}

        {hasFilters && (
          <IconButton onClick={onResetFilters} data-testid="resetFiltersButton">
            <Iconography iconName="cancel-circle" />
          </IconButton>
        )}
      </FilterButton>
    </Box>
  );
}
