import React, { memo } from 'react';

import { Box, useTheme } from '@material-ui/core';

import Iconography from '../Iconography';

import { StyledTypography } from './styled';

interface BreadCrumbBodyProps {
  text: string;
  iconName: string;
}

const BreadCrumbBody = ({ text, iconName }: BreadCrumbBodyProps) => {
  const theme = useTheme();

  return (
    <Box id={`breadcrumb-${iconName}-anchor`} data-testid={`breadCrumb${iconName}`} display="flex" alignItems="center">
      <Iconography iconName={iconName} htmlColor={theme.palette.action.disabled} />
      <StyledTypography color="action.disabled" variant="caption" maxWidth="168px">
        {text}
      </StyledTypography>
    </Box>
  );
};

export default memo(BreadCrumbBody);
