import React from 'react';

import { Box, Skeleton } from '@material-ui/core';
import { styled } from '@mui/material';
import { keyframes } from '@mui/system';

const opacity = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '500px',
  animation: `${opacity} 1s ease`,
});

const StyledSkeleton = styled(Skeleton)({
  borderRadius: '2px',
  height: '42px',
});

export const PaymentSkeleton = () => (
  <Container>
    <StyledSkeleton variant="rectangular" />

    <Box my="33px">
      <StyledSkeleton variant="rectangular" />
    </Box>

    <Box display="flex" justifyContent="space-between" width="100%" mb="33px">
      <StyledSkeleton variant="rectangular" width="244px" />

      <StyledSkeleton variant="rectangular" width="244px" />
    </Box>

    <StyledSkeleton variant="rectangular" />
  </Container>
);
