import React, { ReactNode, useMemo, useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useMutation, useQuery, ApolloError } from '@apollo/client';
import { Box, Collapse, DialogContent, Typography } from '@mui/material';
import { UseFormResetField, UseFormSetValue } from 'react-hook-form/dist/types/form';

import IntegrationInfoContainer from 'src/components/Mapping/IntegrationInfoContainer';
import Popup from 'src/components/Popup';
import ExportToIntegrationsPopup from 'src/components/Popups/ExportToIntegrationsPopup';
import {
  useCreateProductTypeFieldMappings,
  useCreateProductTypesMappings,
  useDeleteProductTypeFieldMappings,
  useDeleteProductTypeMapping,
  useUpdateProductTypeFieldMappings,
  useCheckCatalogMappingErrors,
  useCheckProductTypeMappingErrors,
} from 'src/hooks/graphQLRequestsHooks';
import { useCatalogs } from 'src/providers/catalog';
import useDataTransferStatus from 'src/providers/catalog/hooks/useDataTransferStatus';
import { useSnackbar } from 'src/providers/snackbar';
import { getMessageForSnackbarFromError } from 'src/utils/general';
import {
  CatalogJobType,
  CreateProductTypeFieldIntegrationMappingInput,
  exportProductTypeToAttachedIntegration,
  getIntegrationsRequiredFields,
  Integration,
  integrationProductTypes as integrationProductTypesQuery,
  IntegrationTypes,
  Maybe,
  Mutation,
  MutationExportProductTypeToAttachedIntegrationArgs,
  ProductType,
  ProductTypeFieldIntegrationMapping,
  ProductTypeIntegrationMapping,
  Query,
  QueryGetIntegrationsRequiredFieldsArgs,
  QueryIntegrationProductTypesArgs,
  UpdateProductTypeFieldIntegrationMappingInput,
  QueryGetUnavailableExternalTypesIdsArgs,
  getUnavailableExternalTypesIds,
} from 'src/utils/gql';

import ProductTypesInfo from './ProductTypesInfo';
import { getValueAccordingToIntegrationType, ValueAccordingToIntegrationType } from './utils';

interface IntegrationInfoProps {
  syncable?: boolean;
  productTypeMapping?: ProductTypeIntegrationMapping;
  fieldMappings?: ProductTypeFieldIntegrationMapping[];
  catalogId: string;
  integration: Integration;
  refetchAfterSave?: () => Promise<void>;
  productType?: ProductType;
  handleFormStateChange: (integrationId: string, isChanged: boolean) => void;
  integrations?: Maybe<Integration[]>;
  productTypeFieldMappings?: ProductTypeFieldIntegrationMapping[];
}

export interface FieldMappingsFormData {
  id: string;
  integrationId: string;
  fieldId?: Maybe<string>;
  externalFieldId?: string;
  toSync: boolean;
  softDeleted: boolean;
}

interface FieldForReset {
  fieldName: string;
  value: Maybe<FieldMappingsFormData>;
}

export const getFieldMappingKey = (number: number, fieldMappingPrefix?: string): string =>
  `${fieldMappingPrefix ? `${fieldMappingPrefix}_` : ''}field_mapping_${number}`;

const setAndResetValueForField = (
  newValue: Maybe<string | FieldMappingsFormData>,
  setValue: UseFormSetValue<Record<string, Maybe<string | FieldMappingsFormData>>>,
  resetField: UseFormResetField<Record<string, Maybe<string | FieldMappingsFormData>>>,
  key: string,
) => {
  const constKey = `${key}` as const;

  setValue(constKey, newValue, { shouldDirty: true });
  resetField(constKey, { defaultValue: newValue });
};

export const clearFieldsMappings = (
  productTypeFields: ProductTypeFieldIntegrationMapping[],
  setValue: UseFormSetValue<Record<string, Maybe<string | FieldMappingsFormData>>>,
  resetField: UseFormResetField<Record<string, Maybe<string | FieldMappingsFormData>>>,
  fieldMappingPrefix?: string,
) => {
  productTypeFields.forEach((_, idx) =>
    setAndResetValueForField(null, setValue, resetField, `${getFieldMappingKey(idx, fieldMappingPrefix)}` as const),
  );
};

const IntegrationInfo = ({
  syncable,
  catalogId,
  integration,
  productTypeMapping,
  fieldMappings,
  refetchAfterSave,
  productType,
  handleFormStateChange,
  productTypeFieldMappings = [],
}: IntegrationInfoProps) => {
  const { productTypeId } = useParams<{ productTypeId: string }>();
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const { updateCurrentExport } = useCatalogs();

  const { data: requiredIntegrationFields, loading: requiredIntegrationFieldsLoading } = useQuery<
    Pick<Query, 'getIntegrationsRequiredFields'>,
    QueryGetIntegrationsRequiredFieldsArgs
  >(getIntegrationsRequiredFields, {
    fetchPolicy: 'cache-first',
    variables: {
      type: integration.type,
    },
  });

  const [isExportPopupOpen, setIsExportPopupOpen] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [showAddButton, setShowAddButton] = useState<boolean>(false);
  const [isSavedChangesPopupOpen, setIsSavedChangesPopupOpen] = useState<boolean>(false);
  const [changedMetadataExternalId, setChangedMetadataExternalId] = useState<boolean>(false);
  const [loadingOnExport, setLoadingOnExport] = useState<boolean>(false);
  const [firstSubmittedChange, setFirstSubmittedChange] = useState<boolean>(false);

  const metadataExternalId = productTypeMapping?.metadataExternalId;

  const defaultValues: Record<string, Maybe<string | FieldMappingsFormData>> = useMemo(() => {
    const emptyFieldsLengthToAdd = (fieldMappings?.length || 0) - (productType?.fields?.length || 0);

    return {
      externalProductTypeId: metadataExternalId || null,
      ...(productType?.fields || [])
        .concat(Array(emptyFieldsLengthToAdd >= 0 ? emptyFieldsLengthToAdd : 0).fill(0))
        .reduce((acc: Record<string, FieldMappingsFormData | null>, _, idx) => {
          const mapping = fieldMappings?.[idx];

          acc[getFieldMappingKey(idx)] = mapping
            ? {
                fieldId: mapping.productTypeFieldId,
                externalFieldId: mapping.metadataExternalId,
                id: mapping.id,
                integrationId: mapping.integrationId,
                toSync: mapping.toSync,
                softDeleted: false,
              }
            : null;

          return acc;
        }, {}),
    };
  }, [fieldMappings, metadataExternalId, productType?.fields]);

  const formProps = useForm({
    mode: 'onChange',
    defaultValues,
  });

  const isUnattachedProductType = !formProps.watch('externalProductTypeId');

  const {
    data: integrationProductTypesData,
    loading: integrationProductTypesLoading,
    called: fetchIntegrationProductTypesCalled,
    refetch: refetchIntegrationProductTypes,
  } = useQuery<Pick<Query, 'integrationProductTypes'>, QueryIntegrationProductTypesArgs>(integrationProductTypesQuery, {
    variables: { id: integration.id },
    fetchPolicy: 'network-only',
  });

  const {
    data,
    loading: loadingExternalTypes,
    refetch: refetchExternalTypes,
  } = useQuery<Pick<Query, 'getUnavailableExternalTypesIds'>, QueryGetUnavailableExternalTypesIdsArgs>(
    getUnavailableExternalTypesIds,
    {
      fetchPolicy: 'no-cache',
      variables: {
        integrationId: integration.id,
        metadataExternalIds: [metadataExternalId as string],
      },
      skip: !metadataExternalId,
    },
  );

  const unavailableExternalType = data?.getUnavailableExternalTypesIds[0];

  const createProductTypeMapping = useCreateProductTypesMappings();

  const deleteProductTypeMapping = useDeleteProductTypeMapping();
  const createProductTypeFieldMappings = useCreateProductTypeFieldMappings();
  const updateProductTypeFieldMappings = useUpdateProductTypeFieldMappings();
  const deleteProductTypeFieldMappings = useDeleteProductTypeFieldMappings();
  const { checkCatalogMappingsErrorsQuery, loading: checkCatalogMappingsErrorsQueryLoading } =
    useCheckCatalogMappingErrors(catalogId);
  const { checkProductTypeMappingsErrorsQuery } = useCheckProductTypeMappingErrors(productTypeId);

  const hasMapping = !!productTypeMapping;
  const hasIntegrationProductTypes = !!integrationProductTypesData?.integrationProductTypes.length;

  const handleClick = () => {
    if (hasIntegrationProductTypes) {
      setIsOpen((prev) => !prev);
    }
  };

  const handleAddMappingButtonClick = () => {
    if (integration.type === IntegrationTypes.Ebay) {
      setShowAddButton(true);
    }
  };

  const resetFieldsAfterAction = (fields: FieldForReset[]) =>
    fields.forEach(({ fieldName, value }) => {
      formProps.resetField(`${fieldName}` as const, { defaultValue: value, keepDirty: false });
    });

  const handleSaveChanges = () => {
    if (!productType) return;

    setFirstSubmittedChange(true);

    const mappingIds: string[] = [];

    const notDeletedFields = productTypeFieldMappings.reduce((acc: FieldMappingsFormData[], _, idx) => {
      const currentValue = formProps.watch(
        `${getFieldMappingKey(idx)}` as const,
      ) as unknown as Maybe<FieldMappingsFormData>;

      if (currentValue?.id) {
        mappingIds.push(currentValue.id);
      }

      if (!currentValue || currentValue.softDeleted) {
        return acc;
      }

      acc.push(currentValue);

      return acc;
    }, []);

    const deletedFieldIds = productTypeFieldMappings.reduce((acc: string[], { metadataExternalId }) => {
      if (!notDeletedFields.map(({ externalFieldId }) => externalFieldId).includes(metadataExternalId)) {
        acc.push(metadataExternalId);
      }
      return acc;
    }, []);

    const deletedIds = productTypeFieldMappings.reduce((acc: string[], { id }) => {
      if (!notDeletedFields.map(({ id }) => id).includes(id)) {
        acc.push(id);
      }
      return acc;
    }, []);

    const notDeletedProductTypeFieldMappings = productTypeFieldMappings.reduce(
      (acc: ProductTypeFieldIntegrationMapping[], productTypeFieldMapping) => {
        if (notDeletedFields.map(({ id }) => id).includes(productTypeFieldMapping.id)) {
          acc.push(productTypeFieldMapping);
        }
        return acc;
      },
      [],
    );

    const fieldsWithoutExternalFieldId: string[] = [];
    const formFields = formProps.watch();

    for (const [key, value] of Object.entries(formFields)) {
      if (value && typeof value === 'object' && !value.externalFieldId) {
        fieldsWithoutExternalFieldId.push(key);
      }
    }

    const fieldValuesToArray = Object.entries(formProps.watch()).reduce(
      (acc: Maybe<string | FieldMappingsFormData>[], [key, value]) => {
        if (
          key.includes('field_mapping_') &&
          typeof value !== 'string' &&
          (value === null || (value?.externalFieldId && !deletedFieldIds.includes(value?.externalFieldId)))
        ) {
          acc.push(value);
        }
        return acc;
      },
      [],
    );

    const fieldsWithNullebleValue = [...deletedFieldIds, ...fieldsWithoutExternalFieldId];

    const allFieldsMapping = [...fieldValuesToArray, ...fieldsWithNullebleValue];

    allFieldsMapping.forEach((value, idx) => {
      formProps.setValue(`${getFieldMappingKey(idx)}` as const, typeof value === 'object' ? value : null, {
        shouldDirty: true,
      });
    });

    const fieldsAfterUpdate = Object.entries(formProps.watch()).filter(
      ([, value]) => typeof value === 'object' && value,
    );

    const onSubmitFieldMappigsForm = async (data: Record<string, Maybe<string | FieldMappingsFormData>>) => {
      const enrichCreatedFieldsWithIdsAndResetThem = (
        fieldsForReset: FieldForReset[],
        fieldsMappings: ProductTypeFieldIntegrationMapping[],
      ) => {
        fieldsForReset.forEach(({ value }) => {
          const currentMapping = fieldsMappings.find(
            ({ metadataExternalId }) => metadataExternalId === value?.externalFieldId,
          );

          if (currentMapping && value) {
            value.id = currentMapping.id;
          }
        });

        resetFieldsAfterAction(fieldsForReset);
      };

      const prepareFieldsForReset = (changedExternalProductTypeId: string) => {
        if (formProps.watch().externalProductTypeId) {
          fieldsAfterUpdate.map((item) => {
            const value = item[1] as FieldMappingsFormData;
            const key = item[0];
            const field = notDeletedProductTypeFieldMappings.find(({ id }) => id === value.id);

            if (
              (field?.id !== value.id ||
                field?.metadataExternalId !== value.externalFieldId ||
                field?.productTypeFieldId !== value.fieldId ||
                field?.toSync !== value.toSync) &&
              field &&
              !changedExternalProductTypeId
            ) {
              fieldMappingForUpdate.push({
                id: field.id,
                metadataExternalId: value.externalFieldId,
                productTypeFieldId: value.fieldId || null,
                toSync: value.toSync,
              });

              fieldsForResetAfterUpdate.push({ fieldName: key, value: { ...value, id: field.id, softDeleted: false } });
            } else if (changedExternalProductTypeId || !field) {
              fieldMappingForCreate.push({
                integrationId: integration.id,
                productTypeFieldId: value.fieldId || null,
                metadataExternalId: value.externalFieldId,
                productTypeId: productType.id,
                toSync: value.toSync,
              });

              fieldsForResetAfterCreate.push({
                fieldName: key,
                value: { ...value, softDeleted: false },
              });
            } else {
              fieldsForReset.push({ fieldName: key, value: { ...value, softDeleted: false } });
            }
          });

          if (fieldsWithNullebleValue.length && !changedExternalProductTypeId) {
            deletedIds.forEach((id) => fieldMappingForDelete.push(id));

            for (let i = fieldsWithNullebleValue.length; i > 0; i--) {
              const fieldName = getFieldMappingKey(fieldsAfterUpdate.length + i - 1);

              fieldsForResetAfterDelete.push({
                fieldName: `${fieldName}` as const,
                value: null,
              });
            }
          }
        }
      };

      const newExternalProductTypeId: string = (data.externalProductTypeId || '') as string;

      const fieldMappingForCreate: CreateProductTypeFieldIntegrationMappingInput[] = [];
      const fieldMappingForUpdate: UpdateProductTypeFieldIntegrationMappingInput[] = [];
      const fieldMappingForDelete: string[] = [];

      const fieldsForReset: FieldForReset[] = [];
      const fieldsForResetAfterCreate: FieldForReset[] = [];
      const fieldsForResetAfterUpdate: FieldForReset[] = [];
      const fieldsForResetAfterDelete: FieldForReset[] = [];

      const { externalProductTypeId } = formProps.formState.dirtyFields;

      prepareFieldsForReset(externalProductTypeId as string);

      if (externalProductTypeId) {
        if (productTypeMapping?.id) {
          await deleteProductTypeMapping({
            variables: {
              id: productTypeMapping.id,
            },
          });
        }

        if (newExternalProductTypeId) {
          const { data } = await createProductTypeMapping({
            variables: {
              mappings: [
                {
                  integrationId: integration.id,
                  fieldMappings: fieldMappingForCreate.map(
                    ({
                      metadataExternalId,
                      productTypeFieldId,
                      toSync,
                    }: CreateProductTypeFieldIntegrationMappingInput) => ({
                      metadataExternalId,
                      productTypeFieldId,
                      toSync,
                    }),
                  ),
                  metadataExternalId: newExternalProductTypeId,
                  productTypeId,
                  toSync: true,
                },
              ],
            },
          });

          if (data) {
            enrichCreatedFieldsWithIdsAndResetThem(
              fieldsForResetAfterCreate,
              data.bulkCreateProductTypeIntegrationMappings[0].fieldMappings,
            );
          }
        } else {
          allFieldsMapping.forEach((_, idx) => {
            formProps.resetField(`${getFieldMappingKey(idx)}` as const, { defaultValue: null, keepDirty: false });
          });
        }

        formProps.resetField('externalProductTypeId', { defaultValue: newExternalProductTypeId });
      } else {
        if (fieldsForReset.length) {
          resetFieldsAfterAction(fieldsForReset);
        }

        if (fieldMappingForCreate.length) {
          const { data } = await createProductTypeFieldMappings({
            variables: {
              mappings: fieldMappingForCreate,
            },
          });

          if (data) {
            enrichCreatedFieldsWithIdsAndResetThem(
              fieldsForResetAfterCreate,
              data.createProductTypeFieldIntegrationMappings,
            );
          }
        }

        // delete must be before update
        if (fieldMappingForDelete.length) {
          await deleteProductTypeFieldMappings({
            variables: {
              ids: fieldMappingForDelete,
            },
          }).then(checkProductTypeMappingsErrorsQuery);

          resetFieldsAfterAction(fieldsForResetAfterDelete);
        }

        if (fieldMappingForUpdate.length) {
          await updateProductTypeFieldMappings({
            variables: {
              mappings: fieldMappingForUpdate,
            },
          });

          resetFieldsAfterAction(fieldsForResetAfterUpdate);
        }
      }

      checkProductTypeMappingsErrorsQuery();
      checkCatalogMappingsErrorsQuery();

      await refetchAfterSave?.();
      await refetchExternalTypes();

      formProps.reset();
    };

    formProps.handleSubmit(onSubmitFieldMappigsForm)();
  };

  const syncItemsHintText = (
    <Trans
      i18nKey="productType.mapping.syncItems"
      defaults="<bold>Synс</bold>  items of your product type"
      components={{ bold: <strong /> }}
    />
  ) as ReactNode;

  const title =
    integration.settings.integrationName ||
    `${getValueAccordingToIntegrationType(integration.type, ValueAccordingToIntegrationType.Title, t)} ${t(
      'productType.mapping.integration',
    )}`;

  const [syncProductType, { loading: syncProductTypeLoading }] = useMutation<
    Pick<Mutation, 'exportProductTypeToAttachedIntegration'>,
    MutationExportProductTypeToAttachedIntegrationArgs
  >(exportProductTypeToAttachedIntegration);

  useEffect(() => {
    let inMount = true;

    const updateMetadataExternalId = async () => {
      formProps.setValue('externalProductTypeId', metadataExternalId as string);

      if (inMount) {
        setLoadingOnExport(false);
      }

      await refetchExternalTypes();

      if (inMount) {
        setChangedMetadataExternalId(false);
      }
    };

    try {
      if (changedMetadataExternalId) {
        updateMetadataExternalId();
      }
    } catch (e) {
      if (e) {
        if (inMount) {
          setLoadingOnExport(false);
          setChangedMetadataExternalId(false);
        }

        snackbar(getMessageForSnackbarFromError(e as ApolloError));
      }
    }

    return () => {
      inMount = false;
    };
  }, [changedMetadataExternalId, metadataExternalId, refetchExternalTypes, formProps, snackbar]);

  const { inProgress: isExportInProgress, endTime: lastExportTime } = useDataTransferStatus({
    catalogId,
    integrationId: integration.id,
    typeFilter: CatalogJobType.Export,
    onSuccess: async () => {
      try {
        if (unavailableExternalType) {
          setLoadingOnExport(true);

          await refetchAfterSave?.();
          await refetchIntegrationProductTypes();

          setChangedMetadataExternalId(true);
        }

        snackbar(t('productType.mapping.successfulExportToastMessage'), 'success');
      } catch (e) {
        if (e) {
          setLoadingOnExport(false);
          setChangedMetadataExternalId(false);

          snackbar(getMessageForSnackbarFromError(e as ApolloError));
        }
      }
    },
    onFailure: (error) => {
      snackbar(error, 'error');
    },
    onCompleted: () => {
      checkProductTypeMappingsErrorsQuery();
      checkCatalogMappingsErrorsQuery();
    },
  });

  const onProductTypeChange = (newValue: Maybe<string>) => {
    if (newValue === defaultValues.externalProductTypeId) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { externalProductTypeId, ...restFields } = defaultValues;

      Object.keys(restFields).forEach((key) =>
        setAndResetValueForField(restFields[key], formProps.setValue, formProps.resetField, key),
      );
    }
  };

  const handleExportProductType = async (closePopup: () => void) => {
    updateCurrentExport(catalogId, false);

    await syncProductType({ variables: { catalogId, productTypeId, integrationId: integration.id } })
      .then(() => closePopup())
      .catch(({ message }) => {
        checkCatalogMappingsErrorsQuery();
        snackbar(message);
      });
  };

  const collapseProductTypesInfo = hasIntegrationProductTypes && isOpen && !integrationProductTypesLoading;
  const showNoProductTypesMessage = !(hasIntegrationProductTypes || integrationProductTypesLoading);
  const showLastSyncButton = hasIntegrationProductTypes && !integrationProductTypesLoading;
  const showMappingErrorLink =
    fetchIntegrationProductTypesCalled && !(integrationProductTypesLoading || hasIntegrationProductTypes);
  const showAddMappingButton = !(showAddButton || hasMapping || fetchIntegrationProductTypesCalled);
  const showSaveButton = hasIntegrationProductTypes && !(isExportInProgress || integrationProductTypesLoading);
  const showLoader = integrationProductTypesLoading || isExportInProgress;
  const isHavignEmptyFields = Object.values(defaultValues).some(
    (value) => value !== null && typeof value !== 'string' && !value?.fieldId,
  );

  const handleSave = () => {
    const hasFieldToDelete = Object.values(formProps.getValues()).some(
      (field) => field !== null && typeof field !== 'string' && field?.softDeleted,
    );

    hasFieldToDelete ? setIsSavedChangesPopupOpen(true) : handleSaveChanges();
  };

  return (
    <FormProvider {...formProps}>
      <IntegrationInfoContainer
        syncable={syncable}
        integrationType={integration.type}
        productType={productType}
        title={title}
        hasHover={hasIntegrationProductTypes}
        isExpanded={hasIntegrationProductTypes}
        isOpen={isOpen}
        showLoader={showLoader}
        showSaveButton={showSaveButton}
        isIntegrationUpdated={formProps.formState.isDirty}
        showAddMappingButton={showAddMappingButton}
        integrationProductTypesLoading={integrationProductTypesLoading}
        showMappingErrorLink={showMappingErrorLink}
        isExportInProgress={isExportInProgress}
        showLastSyncButton={showLastSyncButton}
        lastSync={lastExportTime}
        syncItemsHintText={syncItemsHintText as string}
        onClick={handleClick}
        onSaveChanges={handleSave}
        onAddMappingButtonClick={handleAddMappingButtonClick}
        onSyncButtonClick={() => setIsExportPopupOpen(true)}
        integration={integration}
        mustDisableSyncButton={isUnattachedProductType}
        haveEmptyFields={isHavignEmptyFields}
        checkEmptyField
        requiredIntegrationFields={requiredIntegrationFields?.getIntegrationsRequiredFields || []}
        doNotUseDiscardChanges
        handleFormStateChange={handleFormStateChange}
      >
        <ExportToIntegrationsPopup
          open={isExportPopupOpen}
          loadingOnMainButton={syncProductTypeLoading || isExportInProgress || checkCatalogMappingsErrorsQueryLoading}
          handleExport={() => handleExportProductType(() => setIsExportPopupOpen(false))}
          handleClose={() => setIsExportPopupOpen(false)}
        />

        {showNoProductTypesMessage && (
          <Typography ml="24px" mt="22px" variant="subtitle2" color="text.secondary">
            {t('productType.mapping.noProductTypes')}
          </Typography>
        )}

        {integrationProductTypesData && (
          <Collapse in={collapseProductTypesInfo}>
            <Box ml="24px" mt="22px">
              <ProductTypesInfo
                integration={integration}
                integrationProductTypes={integrationProductTypesData.integrationProductTypes}
                productType={productType}
                onProductTypeChange={onProductTypeChange}
                unavailableExternalType={unavailableExternalType}
                loading={!firstSubmittedChange && (loadingExternalTypes || loadingOnExport)}
                requiredIntegrationFieldsLoading={requiredIntegrationFieldsLoading}
                requiredIntegrationFields={requiredIntegrationFields?.getIntegrationsRequiredFields}
              />
            </Box>
          </Collapse>
        )}

        <Popup
          open={isSavedChangesPopupOpen}
          mainTitle={t('productType.popupDeleteSelectedProductTypeFields.title')}
          mainButtonText={t('productType.popupDeleteSelectedProductTypeFields.mainButton')}
          onMainButtonClick={handleSaveChanges}
          secondaryButtonText={t('productType.popupDeleteSelectedProductTypeFields.secondaryButton')}
          onClose={() => setIsSavedChangesPopupOpen(false)}
          maxWidth="sm"
        >
          <DialogContent>
            <Typography ml="24px" mr="24px" variant="body1" color="text.secondary">
              {t('productType.popupDeleteSelectedProductTypeFields.contentText')}
            </Typography>
          </DialogContent>
        </Popup>
      </IntegrationInfoContainer>
    </FormProvider>
  );
};

export default IntegrationInfo;
