import React from 'react';
import { Translation } from 'react-i18next';

import { Box, Typography } from '@material-ui/core';

import Iconography from 'src/components/Iconography';
import Loader from 'src/components/Loader';

import { LoaderContainer } from './styled';
import { InstallingStatusInterface } from './types';

const InstallingStatus = ({ status, color, iconName, isLoading }: InstallingStatusInterface) => (
  <Box display="flex" alignItems="center" mb="40px">
    <Translation>
      {(t) => (
        <Typography variant="body2" mr="5px" color={color}>
          {t(`appInstallingPage.${status}.status`)}
        </Typography>
      )}
    </Translation>

    {isLoading ? (
      <LoaderContainer>
        <Loader size="button" />
      </LoaderContainer>
    ) : (
      <Iconography iconName={iconName} htmlColor={color} />
    )}
  </Box>
);

export default InstallingStatus;
