import React, { ChangeEvent, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { yupResolver } from '@hookform/resolvers/yup';
import { Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Card, styled } from '@mui/material';
import clsx from 'clsx';
import * as yup from 'yup';

import { getIntegrationTitleByType } from 'src/components/Card/IntegrationCard';
import EbayForm from 'src/components/Forms/EbayForm';
import ShopifyForm from 'src/components/Forms/ShopifyForm';
import {
  ebayIntegrationFormSchema,
  shopifyIntegrationFormSchema,
  wooCommerceFormSchema,
} from 'src/components/Forms/validationSchemas';
import WooCommerceForm from 'src/components/Forms/WooCommerceForm';
import Hint from 'src/components/Hint';
import { ReactComponent as EbayIcon } from 'src/components/Icon/ebay-circle.svg';
import { ReactComponent as FacebookAndInstagramIcon } from 'src/components/Icon/facebook-and-instagram.svg';
import { ReactComponent as ShopifyIcon } from 'src/components/Icon/shopify-circle.svg';
import { ReactComponent as WooCommerceIcon } from 'src/components/Icon/woo-commerce-circle.svg';
import Popup from 'src/components/Popup';
import { EbayDefaultSettings, ShopifyDefaultSettings, WooCommerceDefaultSettings } from 'src/constants';
import { HandleEbayIntegrationCreationInterruptionProps } from 'src/hooks/useEbayIntegrationCreationInterruption';
import { useEnterClick } from 'src/utils/enterEffectFactory';
import { IntegrationTypes } from 'src/utils/gql';
import { IntegrationAction } from 'src/views/UserSettings/types';

import BetaLabel from '../../BetaLabel';
import { IntegrationsContent } from '../styled';

import { IntegrationItemOmitType } from './types';

const useStyles = makeStyles((theme: Theme) => ({
  defaultCard: {
    width: 262,
    height: 70,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 20,
    marginBottom: 15,
    padding: '15px 30px 15px 25px',
    background: theme.palette.common.white,
  },
  selectedCard: {
    background: theme.palette.secondary.main,

    '& .MuiTypography-root#label': {
      color: theme.palette.common.white,
    },
  },
}));

const CardsWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 15,
  margin: '35px 0 10px',
}));

interface IntegrationsListPopupProps {
  open: boolean;
  hasIntegrations: boolean;
  onClose: () => void;
  onBackButtonClick: () => void;
  onCreateNewIntegration: (
    integration: IntegrationItemOmitType,
    integrationType: IntegrationTypes,
    resetStateCallback: CallableFunction,
  ) => void;
  loading?: boolean;
  authorizationReset?: () => void;
  createdIntegrationId?: string;
  handleEbayIntegrationCreationInterruption: (options: HandleEbayIntegrationCreationInterruptionProps) => Promise<void>;
  handleAttachEbayIntegration: (integration: IntegrationItemOmitType, type: IntegrationTypes) => Promise<void>;
  setIsCreatingNewIntegration: React.Dispatch<React.SetStateAction<boolean>>;
  isCreatingNewIntegration: boolean;
}

const CARDS = [
  { label: 'Shopify', icon: <ShopifyIcon />, type: IntegrationTypes.Shopify, testId: 'ShopifyCard' },
  { label: 'WooCommerce', icon: <WooCommerceIcon />, type: IntegrationTypes.Woocommerce, testId: 'WoocommerceCard' },
  { label: 'eBay', icon: <EbayIcon />, type: IntegrationTypes.Ebay, testId: 'EbayCard' },
  {
    label: 'Facebook + Instagram',
    icon: <FacebookAndInstagramIcon />,
    type: IntegrationTypes.Facebook,
    testId: 'FacebookCard',
  },
];

const initialFormState = { isDefault: false, settings: {} };

const getFormSchema = (type: IntegrationTypes) => {
  switch (type) {
    case IntegrationTypes.Shopify:
      return shopifyIntegrationFormSchema;
    case IntegrationTypes.Woocommerce:
      return wooCommerceFormSchema;
    case IntegrationTypes.Ebay:
      return ebayIntegrationFormSchema;
  }
};

const IntegrationsListPopup = ({
  open,
  hasIntegrations,
  onClose,
  onBackButtonClick,
  onCreateNewIntegration,
  loading = false,
  setIsCreatingNewIntegration,
  handleAttachEbayIntegration,
  handleEbayIntegrationCreationInterruption,
  createdIntegrationId,
  isCreatingNewIntegration,
}: IntegrationsListPopupProps) => {
  const { t } = useTranslation();
  const cardClasses = useStyles();

  const [selectedIntegrationType, setSelectedIntegrationType] = useState<IntegrationTypes | null>(null);
  const [formState, setFormState] = useState<IntegrationItemOmitType>(initialFormState);

  const formProps = useForm({
    mode: 'onChange',
    resolver: yupResolver(selectedIntegrationType ? getFormSchema(selectedIntegrationType) : yup.object()),
    defaultValues: {
      ...formState.settings,
    },
  });

  const {
    getValues,
    formState: { isValid },
  } = formProps;
  const formValues = getValues();
  const formValuesData = {
    settings: formValues,
    isDefault: formState.isDefault,
  } as IntegrationItemOmitType;

  const handleExited = () => {
    setSelectedIntegrationType(null);
    setIsCreatingNewIntegration(false);
    formProps.reset();
  };

  const handleBack = () => {
    setSelectedIntegrationType(null);
    onBackButtonClick();
    setFormState(initialFormState);
    formProps.reset();
  };

  const handleSelectIntegrationType = (type: IntegrationTypes) => {
    let settings;

    switch (type) {
      case IntegrationTypes.Shopify:
        settings = ShopifyDefaultSettings;
        break;
      case IntegrationTypes.Woocommerce:
        settings = WooCommerceDefaultSettings;
        break;
      case IntegrationTypes.Ebay:
        settings = EbayDefaultSettings;
        break;
      case IntegrationTypes.Facebook:
        settings = {};
        break;
      default:
        return;
    }

    setSelectedIntegrationType(type);
    setFormState({
      ...formState,
      settings,
    });
  };

  const handleCreateIntegration = async () => {
    if (selectedIntegrationType) {
      if (createdIntegrationId && selectedIntegrationType === IntegrationTypes.Ebay) {
        await handleAttachEbayIntegration(formValuesData, selectedIntegrationType);
      } else {
        onCreateNewIntegration(formValuesData, selectedIntegrationType, () => {
          setFormState(initialFormState);
          formProps.reset();
          setIsCreatingNewIntegration(false);
          setSelectedIntegrationType(null);
        });
      }
    }
  };

  const handleChangeDefault = ({ target: { checked } }: ChangeEvent<HTMLInputElement>) => {
    setFormState((prevState: IntegrationItemOmitType) => ({
      ...prevState,
      isDefault: checked,
    }));
  };

  const handleNextButtonClick = async () => {
    if (
      selectedIntegrationType &&
      [IntegrationTypes.Facebook, IntegrationTypes.Ebay].includes(selectedIntegrationType)
    ) {
      await handleCreateIntegration();
    } else {
      setIsCreatingNewIntegration(true);
    }
  };

  const mainTitle = useMemo(() => {
    if (isCreatingNewIntegration) {
      return t('userSettingsPage.integrations.popup.title', {
        integration: selectedIntegrationType && getIntegrationTitleByType(selectedIntegrationType),
      });
    } else {
      return t('settingsPage.attachedIntegrations.popup.titleCreate');
    }
  }, [isCreatingNewIntegration, selectedIntegrationType, t]);

  useEnterClick(() => isValid && isCreatingNewIntegration && handleCreateIntegration());

  const handleBackButtonClick = async () => {
    setIsCreatingNewIntegration(false);

    if (selectedIntegrationType === IntegrationTypes.Ebay) {
      await handleEbayIntegrationCreationInterruption({});
    }
    formProps.reset();
  };

  const createButtonsProps = {
    mainButtonDisabled: !isValid,
    loadingOnMainButton: loading,
    testMainButton: `${selectedIntegrationType}SaveButton`,
    onMainButtonClick: handleCreateIntegration,
    mainButtonText: t('settingsPage.attachedIntegrations.popup.saveButton'),
    testSecondaryButton: `${selectedIntegrationType}BackButton`,
    secondaryButtonText: t('settingsPage.attachedIntegrations.popup.backButton'),
    onSecondaryButtonClick: handleBackButtonClick,
  };

  const buttonsProps = {
    mainButtonDisabled: !selectedIntegrationType,
    testMainButton: 'nextButtonPopup',
    onMainButtonClick: handleNextButtonClick,
    mainButtonText: t('settingsPage.attachedIntegrations.popup.nextButton'),
    testSecondaryButton: hasIntegrations ? 'backButtonPopup' : undefined,
    secondaryButtonText: hasIntegrations ? t('settingsPage.attachedIntegrations.popup.backButton') : undefined,
    onSecondaryButtonClick: hasIntegrations ? handleBack : undefined,
    doNotCloseThePopupAfterMainButtonClick: true,
  };

  const otherButtonProps = isCreatingNewIntegration ? { ...createButtonsProps } : { ...buttonsProps };

  return (
    <Popup
      TransitionProps={{ onExited: handleExited }}
      headerMarginAbsence
      open={open}
      mainTitle={mainTitle}
      onClose={onClose}
      doNotCloseThePopupAfterSecondaryButtonClick
      actionsMarginTop={isCreatingNewIntegration ? '40px' : '0px'}
      {...otherButtonProps}
    >
      {isCreatingNewIntegration ? (
        <Box>
          <IntegrationsContent>
            <Box>
              <FormProvider {...formProps}>
                {selectedIntegrationType === IntegrationTypes.Shopify && (
                  <ShopifyForm
                    onChangeDefault={handleChangeDefault}
                    action={IntegrationAction.Add}
                    isDefaultIntegration={formState.isDefault}
                  />
                )}

                {selectedIntegrationType === IntegrationTypes.Woocommerce && (
                  <WooCommerceForm
                    onChangeDefault={handleChangeDefault}
                    action={IntegrationAction.Add}
                    isDefaultIntegration={formState.isDefault}
                  />
                )}

                {selectedIntegrationType === IntegrationTypes.Ebay && (
                  <EbayForm
                    integrationId={createdIntegrationId}
                    onChangeDefault={handleChangeDefault}
                    action={IntegrationAction.Add}
                    isDefaultIntegration={formState.isDefault}
                  />
                )}
              </FormProvider>
            </Box>
          </IntegrationsContent>
        </Box>
      ) : (
        <>
          <CardsWrapper>
            {CARDS.map(({ label, icon, type, testId }, idx) => {
              return (
                <Card
                  key={idx}
                  onClick={() => handleSelectIntegrationType(type)}
                  className={clsx({
                    [cardClasses.defaultCard]: true,
                    [cardClasses.selectedCard]: type === selectedIntegrationType,
                  })}
                  data-testid={testId}
                >
                  <Box display="flex" alignItems="center">
                    {icon}

                    <Hint
                      type="hover"
                      tooltipWidth="256px"
                      title={t('facebook.hint') || ''}
                      placement="top"
                      doNotShow={type !== IntegrationTypes.Facebook}
                      customTooltipMarginLeft="-38px"
                    >
                      <Typography id="label" variant="subtitle2" ml="10px" mr="5px">
                        {label}
                      </Typography>
                    </Hint>
                    {type === IntegrationTypes.Ebay && <BetaLabel />}
                  </Box>
                </Card>
              );
            })}
          </CardsWrapper>
        </>
      )}
    </Popup>
  );
};

export default IntegrationsListPopup;
