import React, { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { Box, Link, Typography } from '@material-ui/core';
import { Favorite } from '@material-ui/icons';

import Iconography from 'src/components/Iconography';
import { ReactComponent as LogoImage } from 'src/components/Images/Logo.svg';
import Page from 'src/components/Page';
import { EXTERNAL_URL } from 'src/constants';

import { Container, Footer, FooterText, StyledLinkA } from './styled';

const LoginLayout = ({ children, title }: HTMLAttributes<HTMLDivElement>) => {
  const { t } = useTranslation();
  // MOTHBALLED TO MVP

  // const [language, setLanguage] = useState<string>('en');

  // const handleChange = (event: ChangeEvent<{ value: string }>) => {
  //   i18n.changeLanguage(event.target.value);
  //   setLanguage(event.target.value);
  // };

  return (
    <Page title={title || 'Login'}>
      <Container>
        <Box zIndex="10" display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Link to="/" component={RouterLink} color="secondary" variant="h2" underline="none">
              <LogoImage />
            </Link>
          </Box>
          {/* 
          MOTHBALLED TO MVP
          */}
          {/* (
          <Select value={language} onChange={handleChange} disabled>
            <MenuItem value="en">EN</MenuItem>
          </Select>
          ) */}
        </Box>

        {children}

        <Footer>
          <Box display="flex" flexDirection="column" justifyContent="space-between">
            <Typography variant="caption">{t('landingPage.footer.copyright')}</Typography>
            <Box>
              <Typography variant="caption" color="primary">
                <StyledLinkA href={EXTERNAL_URL.PRIVACY_POLICY} data-testid="footerLinkPrivacyPolicy">
                  {t('footerText.privacyPolicy')}
                </StyledLinkA>

                {' & '}

                <StyledLinkA href={EXTERNAL_URL.TERMS_AND_CONDITIONS} data-testid="footerLinkTermsAndConditions">
                  {t('footerText.terms')}
                </StyledLinkA>
              </Typography>
            </Box>
          </Box>

          <Box display="flex" flexDirection="column" justifyContent="flex-end">
            <FooterText variant="caption">
              {`${t('footerText.madeWith')} `}

              <Iconography htmlColor="secondary">
                <Favorite />
              </Iconography>

              {` ${t('footerText.otomate')}`}
            </FooterText>
          </Box>
        </Footer>
      </Container>
    </Page>
  );
};

export default LoginLayout;
