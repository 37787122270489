import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import { ApolloProvider } from '@apollo/client';
import { CssBaseline, StylesProvider, ThemeProvider } from '@material-ui/core';
import { FlagProvider, IConfig, UnleashClient } from '@unleash/proxy-client-react';
import './index.css';

import { theme } from 'src/theme';

import './utils/i18n';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { client } from './utils/api';
import withGoogleOauth from './utils/withGoogleOauth';

const unleashConfig: IConfig = {
  url: process.env.REACT_APP_UNLEASH_PROXY_URL ?? 'http://localhost:3100/proxy',
  clientKey: process.env.REACT_APP_UNLEASH_SECRET_KEY ?? 'secret',
  refreshInterval: process.env.REACT_APP_UNLEASH_REFRESH_INTERVAL ?? 30,
  appName: process.env.REACT_APP_UNLEASH_APP_NAME ?? 'project-x',
};

const unleashClient = new UnleashClient(unleashConfig);

const startClient = process.env.NODE_ENV === 'production' || process.env.REACT_APP_UNLEASH_START_CLIENT === 'true';

// turn all features on for development
if (process.env.NODE_ENV !== 'production' && !startClient) {
  unleashClient.isEnabled = () => true;
}

ReactDOM.render(
  <React.StrictMode>
    <FlagProvider unleashClient={unleashClient} startClient={startClient}>
      <StylesProvider injectFirst>
        <CssBaseline />
        <ThemeProvider theme={theme}>
          <ApolloProvider client={client}>
            {withGoogleOauth(
              <Router>
                <App />
              </Router>,
            )}
          </ApolloProvider>
        </ThemeProvider>
      </StylesProvider>
    </FlagProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
