import React from 'react';

import Box from '@material-ui/core/Box';
import LinearProgress, { LinearProgressProps } from '@material-ui/core/LinearProgress';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    textAlign: 'end',
    '& .MuiLinearProgress-root': {
      background: theme.palette.background.default,
      borderRadius: '2px',
    },
    '& .MuiLinearProgress-bar': {
      background: theme.palette.secondary.contrastText,
      borderRadius: '2px',
    },
  },
}));

const ProgressBar = ({ value }: LinearProgressProps) => {
  const classes = useStyles();

  return (
    <Box width="100%" className={classes.root}>
      <LinearProgress variant="determinate" value={value} />
    </Box>
  );
};

export default ProgressBar;
