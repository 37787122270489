import React from 'react';

import { makeStyles, Theme, Box, Typography } from '@material-ui/core';
import Drawer from '@mui/material/Drawer';

import { theme } from 'src/theme';

import Button from '../Button';
import Hint from '../Hint';

const useStyles = makeStyles<Theme, { narrowSideBar?: boolean; changeButtonLocations?: boolean }>((theme) => ({
  root: {
    '& .MuiDrawer-paper': {
      boxShadow: '0 2px 10px rgba(46, 96, 170, 0.25)',
      background: theme.palette.common.white,
      position: 'fixed',
      maxWidth: ({ narrowSideBar }: { narrowSideBar: boolean }) => (narrowSideBar ? '552px' : '758px'),
      width: '100%',
      justifyContent: 'space-between',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#ffffff',
  },
  iconsStyles: {
    color: theme.palette.text.primary,
    padding: '0 10px',
    '& svg': {
      width: '20px',
      height: '20px',
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',

    '& h2': {
      overflowWrap: 'anywhere',
    },

    '&>span': {
      marginLeft: '10px',
    },
  },
  footerButtons: {
    display: 'flex',
    flexDirection: ({ changeButtonLocations }) => (changeButtonLocations ? 'row-reverse' : 'row'),
    marginLeft: '30px',
    '& button': {
      marginRight: '15px',
    },
  },
}));

interface SideBarDrawerProps {
  dataTestId?: string;
  changeButtonLocations?: boolean;
  titleName: string;
  primaryButtonTitle?: string;
  secondaryButtonTitle?: string;
  children: JSX.Element;
  narrowSideBar?: boolean;
  openSidebar: boolean;
  onPrimaryButtonClick: () => void;
  onSecondaryButtonClick?: () => void;
  handleCloseSidebarOnClickAway?: () => void;
  disabledSecondaryButton?: boolean;
  titleIcons?: JSX.Element;
  propsAdornment?: JSX.Element;
  dataTestIdPrimaryButton?: string;
  dataTestIdSecondaryButton?: string;
  openHint?: boolean;
  titleHint?: NonNullable<React.ReactNode>;
  error?: boolean;
  errorMessage?: string;
  form?: string;
  primaryButtonLoading?: boolean;
  secondaryButtonLoading?: boolean;
  subtitle?: string;
}

const SideBarDrawer = ({
  narrowSideBar,
  titleIcons,
  openSidebar,
  onSecondaryButtonClick,
  secondaryButtonTitle,
  titleName,
  primaryButtonTitle,
  onPrimaryButtonClick,
  disabledSecondaryButton,
  changeButtonLocations,
  propsAdornment,
  children,
  dataTestIdPrimaryButton,
  dataTestIdSecondaryButton,
  openHint,
  titleHint,
  error,
  errorMessage,
  form,
  handleCloseSidebarOnClickAway,
  dataTestId,
  primaryButtonLoading,
  secondaryButtonLoading,
  subtitle,
}: SideBarDrawerProps) => {
  const classes = useStyles({ narrowSideBar, changeButtonLocations });

  const secondaryButton = () => (
    <Button
      onClick={form ? undefined : onSecondaryButtonClick}
      disabled={disabledSecondaryButton}
      data-testid={dataTestIdSecondaryButton}
      variant="contained"
      type={form ? 'submit' : 'button'}
      form={form}
      loading={secondaryButtonLoading}
    >
      {secondaryButtonTitle}
    </Button>
  );

  return (
    <Drawer
      className={classes.root}
      anchor="right"
      open={openSidebar}
      onClose={handleCloseSidebarOnClickAway || onPrimaryButtonClick}
      data-testid={dataTestId}
      closeAfterTransition
    >
      <Box padding="43px 56px 10px 30px">
        <Box className={classes.header}>
          <Box display="flex" flexDirection="column" width="100%">
            <Box className={classes.title}>
              <Typography variant="h2">{titleName}</Typography>

              {titleIcons}
            </Box>
            <Typography
              sx={{
                overflowWrap: 'break-word',
                overflow: 'hidden',
                color: theme.palette.text.secondary,
                marginTop: subtitle ? '20px' : undefined,
              }}
            >
              {subtitle}
            </Typography>
          </Box>
          <Box>{propsAdornment}</Box>
        </Box>
        <Box mt="5px" height="16px">
          {error && (
            <Typography variant="h3" color="error" fontWeight="400">
              {errorMessage}
            </Typography>
          )}
        </Box>
      </Box>

      {children}

      <Box pb="40px" pt="10px" display="flex" position="sticky">
        <Box className={classes.footerButtons}>
          {primaryButtonTitle && (
            <Button
              onClick={onPrimaryButtonClick}
              data-testid={dataTestIdPrimaryButton}
              variant="outlined"
              loading={primaryButtonLoading}
            >
              {primaryButtonTitle}
            </Button>
          )}

          {secondaryButtonTitle &&
            (titleHint ? (
              <Hint type="hover" placement="top" title={titleHint} open={openHint}>
                <span>{secondaryButton()}</span>
              </Hint>
            ) : (
              secondaryButton()
            ))}
        </Box>
      </Box>
    </Drawer>
  );
};

export default SideBarDrawer;
