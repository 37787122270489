import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Box, Typography } from '@material-ui/core';

import Button from 'src/components/Button';
import UploadCsvMultiple from 'src/components/UploadFiles/UploadCsvMultiple';
import {
  CATALOGS_PATH,
  CREATION,
  FIRST_DASHBOARD_PATHNAME,
  IMPORT_FROM_CSV,
  PRODUCT_TYPES_PATH,
} from 'src/constants/routeSources';
import { CheckIntegrationStatusProps, IntegrationErrors } from 'src/hooks/useIntegrationHealthCheck';
import useOnboarding from 'src/providers/OnboardingProvider/hooks/useOnboarding';
import { useEnterClick } from 'src/utils/enterEffectFactory';
import { CatalogSource } from 'src/utils/gql';
import { UploadingFile } from 'src/views/Catalogs/ProductItems/types';

import CreateCatalogThirdStepBackground from './CreateCatalogThirdStepBackground';
import IntegrationThirdStep from './IntegrationThirdStep';
import { Container, Main, CatalogCreationSideContainer } from './styled';

interface ThirdStepProps {
  source: CatalogSource;
  onCreateCatalogue: (integrationId?: string) => Promise<string | undefined>;
  loading: boolean;
  handleBack: (id: number) => void;
  checkIntegrationStatus: ({
    integrationId,
    mainCallbackFn,
    additionalCallbackFn,
  }: CheckIntegrationStatusProps) => void;
  integrationErrors?: IntegrationErrors;
}

const ThirdStep = ({
  source,
  loading,
  handleBack,
  onCreateCatalogue,
  checkIntegrationStatus,
  integrationErrors,
}: ThirdStepProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    onboardingState: { tourActive },
    goNext,
  } = useOnboarding();

  const [isImageLoading, setIsImageLoading] = useState<boolean>(true);
  const [uploadCSVs, setUploadCSVs] = useState<UploadingFile[]>([]);

  const isIntegration = [CatalogSource.Shopify, CatalogSource.Woocommerce].includes(source);

  const createCatalogueAndRedirect = useCallback(
    async (action: string, integrationId?: string, event?: FormEvent) => {
      event && event.preventDefault();

      const catalogueId = await onCreateCatalogue(integrationId);

      if (catalogueId) {
        switch (action) {
          case CatalogSource.Manually:
            history.push(`/${FIRST_DASHBOARD_PATHNAME}/${CATALOGS_PATH}/${catalogueId}/${CREATION}`);
            break;

          case CatalogSource.Csv:
            history.push({
              pathname: `/${FIRST_DASHBOARD_PATHNAME}/${CATALOGS_PATH}/${catalogueId}/${IMPORT_FROM_CSV}`,
              state: {
                uploadingFiles: uploadCSVs,
              },
            });
            break;

          case CatalogSource.Shopify:
          case CatalogSource.Woocommerce:
            history.push(`/${FIRST_DASHBOARD_PATHNAME}/${CATALOGS_PATH}/${catalogueId}/${PRODUCT_TYPES_PATH}`);
            break;

          default:
            break;
        }

        if (tourActive) {
          setTimeout(
            () => {
              goNext();

              window.history.pushState({}, '', history.location.pathname);
            },
            action === CatalogSource.Manually ? 150 : 0,
          );
        }
      }
    },
    [goNext, history, onCreateCatalogue, tourActive, uploadCSVs],
  );

  useEnterClick(() => {
    if (isIntegration) {
      return;
    }
    source && createCatalogueAndRedirect(source);
  });

  useEffect(() => {
    !!uploadCSVs.length && createCatalogueAndRedirect(CatalogSource.Csv);
  }, [uploadCSVs.length]); // eslint-disable-line

  return (
    <Container>
      <Box position="relative">
        {tourActive && source === CatalogSource.Csv && (
          <Box
            id="select-csv-anchor"
            position="absolute"
            width="395px"
            height="260px"
            bottom="0px"
            sx={{ pointerEvents: 'none' }}
          />
        )}

        <Typography variant="body1" color="text.disabled">
          {t('catalogueCreationPage.thirdStep.step')}
        </Typography>

        <Main>
          {source === CatalogSource.Manually && (
            <Typography variant="subtitle1" my="40px" width="290px">
              {t('catalogueCreationPage.thirdStep.manually.title')}
            </Typography>
          )}

          {source === CatalogSource.Csv && (
            <Typography variant="subtitle1" mt="40px" mb="15px" width="408px">
              {t('catalogueCreationPage.thirdStep.csvFile.title')}
            </Typography>
          )}
        </Main>

        {source === CatalogSource.Manually && (
          <Button
            id="go-to-page-btn-anchor"
            onClick={() => createCatalogueAndRedirect(CatalogSource.Manually)}
            variant="contained"
            loading={loading}
            data-testid="goToPage"
          >
            {t('catalogueCreationPage.thirdStep.manually.button')}
          </Button>
        )}

        {isIntegration && (
          <IntegrationThirdStep
            loading={loading}
            onCreateCatalog={createCatalogueAndRedirect}
            source={source}
            handleBack={handleBack}
            integrationErrors={integrationErrors}
            checkIntegrationStatus={checkIntegrationStatus}
          />
        )}

        {source === CatalogSource.Csv && <UploadCsvMultiple setUploadCSVs={setUploadCSVs} />}
      </Box>

      <CatalogCreationSideContainer ml="148px">
        {isImageLoading && <CreateCatalogThirdStepBackground source={source} />}

        {(source === CatalogSource.Manually || isIntegration) && (
          <img
            src={`${process.env.PUBLIC_URL}/images/import.webp`}
            alt="Import"
            width="328px"
            height="281px"
            onLoad={() => setIsImageLoading(false)}
          />
        )}

        {source === CatalogSource.Csv && (
          <img
            src={`${process.env.PUBLIC_URL}/images/balloon.webp`}
            alt="CSV"
            width="436px"
            height="300px"
            onLoad={() => setIsImageLoading(false)}
          />
        )}
      </CatalogCreationSideContainer>
    </Container>
  );
};

export default ThirdStep;
