import { Box, Typography } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';

export const AuthContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '18px',
  margin: '40px auto 0',
  minWidth: '282px',
}));

export const DividerContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '14px',
  alignItems: 'center',
  color: theme.palette.action.disabledBackground,
}));

export const Divider = styled(Box)(({ theme }) => ({
  height: '1px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  gap: '14px',
  backgroundColor: theme.palette.action.disabledBackground,
}));

export const DividerText = styled(Typography)(({ theme }) => ({
  fontSize: '15px',
  color: theme.palette.action.disabledBackground,
}));
