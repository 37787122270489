import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Typography } from '@material-ui/core';

import SharedCatalogPreviewHeader from 'src/components/Headers/SharedCatalogPreviewHeader';
import SkeletonSharedCatalogProductTypesPreviews from 'src/components/Skeleton/SkeletonSharedCatalogProductTypesPreviews';
import { FIRST_DASHBOARD_PATHNAME, SHARED_CATALOGS } from 'src/constants/routeSources';
import useSharedCatalogs from 'src/providers/SharedCataloguesProvider';
import CreateProductTypeBackground from 'src/views/Catalogs/ProductTypes/CreateProductTypeBackground';
import { SKELETON_PRODUCT_TYPES_PREVIEW_COUNT } from 'src/views/SharedCatalogPreview/types';

import RestLabel, { RestObject } from '../RestLabel';

import {
  NoProductTypesWrapper,
  PageContentWrapper,
  PageWrapper,
  ProductTypeCard,
  ProductTypesList,
  ProductTypesWrapper,
  TypeCardWrapper,
} from './styled';

const ProductTypesPreview = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const {
    sharedCatalogProducts,
    loadingProducts,
    sharedCatalogInfo: { catalogId, catalogName },
  } = useSharedCatalogs();

  const [isImageLoading, setIsImageLoading] = useState<boolean>(true);

  const productTypes = sharedCatalogProducts?.productTypes;
  const [totalCount, shownCount] = [sharedCatalogProducts?.totalCount, productTypes?.length];

  const hasProducts = !!shownCount;

  const locationName = catalogName
    ? `${t('navBar.contextMenu.recentStepsTitles.sharedCatalogTypesPreview')} "${catalogName}"`
    : '';

  return (
    <PageWrapper title={catalogName} locationName={locationName}>
      <PageContentWrapper>
        <ProductTypesList>
          <SharedCatalogPreviewHeader
            title={catalogName}
            countInfo={
              totalCount === undefined
                ? undefined
                : t('previewCatalogPage.productTypes.productTypesCount', { count: totalCount })
            }
          />

          {loadingProducts ? (
            <ProductTypesWrapper>
              <SkeletonSharedCatalogProductTypesPreviews cardsCount={SKELETON_PRODUCT_TYPES_PREVIEW_COUNT} />
            </ProductTypesWrapper>
          ) : hasProducts ? (
            <ProductTypesWrapper>
              <TypeCardWrapper>
                {productTypes?.map(({ productType: { id, name }, productItemsCount }) => (
                  <ProductTypeCard
                    key={id}
                    onClick={() => history.push(`/${FIRST_DASHBOARD_PATHNAME}/${SHARED_CATALOGS}/${catalogId}/${id}`)}
                    data-testid={`productTypePreview${name}`}
                  >
                    <Typography
                      variant="subtitle1"
                      color="primary"
                      maxWidth="80%"
                      whiteSpace="pre"
                      textOverflow="ellipsis"
                      overflow="hidden"
                    >
                      {name}
                    </Typography>

                    <Typography variant="caption" color="primary">
                      {t('previewCatalogPage.productItems.count', { count: productItemsCount })}
                    </Typography>
                  </ProductTypeCard>
                ))}
              </TypeCardWrapper>

              <RestLabel object={RestObject.ProductTypes} totalCount={totalCount} shownCount={shownCount} />
            </ProductTypesWrapper>
          ) : (
            <NoProductTypesWrapper>
              {isImageLoading && <CreateProductTypeBackground />}
              <img
                src={`${process.env.PUBLIC_URL}/images/folder.webp`}
                alt="Folder"
                onLoad={() => setIsImageLoading(false)}
                width="400px"
                height="220px"
              />
              <Typography variant="body1" maxWidth="350px" width="100%" textAlign="center" mt="30px" mb="25px">
                {t('productTypesPage.noProductInSharedCatalog')}
              </Typography>
            </NoProductTypesWrapper>
          )}
        </ProductTypesList>
      </PageContentWrapper>
    </PageWrapper>
  );
};

export default ProductTypesPreview;
