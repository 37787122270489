import { createContext } from 'react';

import { OnboardingContextType } from './types';

const stub = (): never => {
  throw new Error('You forgot to wrap your component in <OnboardingProvider>.');
};

export const defaultValue: OnboardingContextType = {
  onboardingState: {
    run: false,
    stepIndex: 0,
    steps: [],
    tourActive: false,
    createdOnboardingCatalog: null,
    createdOnboardingProductType: null,
    createdOnboardingProductItem: null,
    createdOnboardingProductItems: null,
  },
  isDiscardChangesPopupBlocked: false,
  setOnboardingState: stub,
  blockDiscardChangesPopup: stub,
  getStepsByCatalogSource: stub,
  getUpdatedCsvSteps: stub,
  goNext: stub,
  goBack: stub,
  startOnboarding: stub,
  finishOnboarding: stub,
};

const OnboardingContext = createContext<OnboardingContextType>(defaultValue);

OnboardingContext.displayName = 'OnboardingContext';

export default OnboardingContext;
