import React from 'react';

import { CardProps } from '@material-ui/core';
import MuiCard from '@material-ui/core/Card';
import MuiCardContent from '@material-ui/core/CardContent';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '&:active': {
      background: theme.palette.secondary.main,
    },
    '&$cardChecked': {
      color: theme.palette.text.primary,
    },
  },
  sizeSmall: {
    minWidth: '568px',
    padding: '23px 25px 23px 35px',
  },
  sizeMedium: {
    minWidth: '600px',
    padding: '23px 25px 23px 35px',
  },
  sizeLarge: {
    width: '100%',
    padding: '23px 25px 23px 35px',
  },
  cardChecked: {
    background: theme.palette.secondary.main,
  },
}));

enum ProductTypeCardSizes {
  large = 'large',
  medium = 'medium',
  small = 'small',
}

interface ProductTypeCardProps extends CardProps {
  size: keyof typeof ProductTypeCardSizes;
  onCardSelect?: () => void;
  isChecked?: boolean;
  productTypeCardTestId?: string;
}

function ProductTypeCard(props: ProductTypeCardProps) {
  const classes = useStyles();

  const { size, onCardSelect, isChecked, productTypeCardTestId } = props;

  return (
    <>
      {size === ProductTypeCardSizes.large && (
        <MuiCard
          className={clsx(classes.root, classes.sizeLarge, {
            [classes.cardChecked]: isChecked,
          })}
          onClick={onCardSelect}
          tabIndex={0}
          data-testid={productTypeCardTestId}
        >
          <MuiCardContent>{props.children}</MuiCardContent>
        </MuiCard>
      )}
      {size === ProductTypeCardSizes.medium && (
        <MuiCard
          className={clsx(classes.root, classes.sizeMedium, {
            [classes.cardChecked]: isChecked,
          })}
          onClick={onCardSelect}
          tabIndex={0}
          data-testid={productTypeCardTestId}
        >
          <MuiCardContent>{props.children}</MuiCardContent>
        </MuiCard>
      )}
      {size === ProductTypeCardSizes.small && (
        <MuiCard
          className={clsx(classes.root, classes.sizeSmall, {
            [classes.cardChecked]: isChecked,
          })}
          onClick={onCardSelect}
          tabIndex={0}
          data-testid={productTypeCardTestId}
        >
          <MuiCardContent>{props.children}</MuiCardContent>
        </MuiCard>
      )}
    </>
  );
}

export default ProductTypeCard;
