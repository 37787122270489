import React, { ChangeEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Box, IconButton, InputAdornment, styled, TextField } from '@mui/material';

import Iconography from 'src/components/Iconography';
import useItemMapping from 'src/components/SideBar/MappingSidebar/context/ItemMappingProvider';
import { ChangeAction } from 'src/components/SideBar/MappingSidebar/context/types';
import { IntegrationTypes } from 'src/utils/gql';

export const InputButtonsWrapper = styled(Box)(() => ({
  display: 'flex',
  marginRight: '9px',

  '& .MuiIconButton-root': {
    padding: 0,

    '&#inputApplyButton': {
      marginRight: '5px',
    },
  },
}));

interface IntegrationUrlInputProps {
  label: string;
  testId: string;
  shouldFocus?: boolean;
  onClose: () => void;
  loading?: boolean;
  integrationId: string;
  integrationType: IntegrationTypes;
}

const IntegrationUrlInput = ({
  label,
  onClose,
  shouldFocus = true,
  testId,
  loading = false,
  integrationId,
  integrationType,
}: IntegrationUrlInputProps) => {
  const { t } = useTranslation();

  const {
    register,
    formState: { errors },
  } = useFormContext();
  const { onChangeIntegrationInput } = useItemMapping();

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    register('integrationUrl').onChange({ target });

    onChangeIntegrationInput({ integrationId, label }, ChangeAction[target.value ? 'ADD' : 'REMOVE']);
  };

  const handleClose = () => {
    onClose();
    onChangeIntegrationInput({ integrationId }, ChangeAction.REMOVE);
  };

  return (
    <TextField
      {...register('integrationUrl')}
      fullWidth
      label={label}
      error={!!errors?.integrationUrl}
      helperText={t(errors?.integrationUrl?.message as string)}
      onChange={handleChange}
      inputProps={{
        'data-testid': testId,
        autoFocus: shouldFocus,
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <InputButtonsWrapper>
              <IconButton
                id="inputApplyButton"
                size="small"
                disableTouchRipple
                type="submit"
                data-testid={`url_apply_button_${integrationType}_integration`}
                disabled={loading}
              >
                <Iconography iconName="check-outline" color={loading ? 'disabled' : 'secondary'} />
              </IconButton>

              <IconButton
                size="small"
                disableTouchRipple
                onClick={handleClose}
                data-testid={`url_close_button_${integrationType}_integration`}
              >
                <Iconography iconName="cancel" color="disabled" />
              </IconButton>
            </InputButtonsWrapper>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default IntegrationUrlInput;
