import { Box, Typography, IconButton } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';

export const Chip = styled(Box)`
  position: relative;
  width: 60px;
  height: 24px;
  padding: 2px;
  box-sizing: border-box;
  background: ${({ theme: { palette } }) => palette.common.white};
  box-shadow: 0px 2px 10px rgba(46, 96, 170, 0.25);
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-content: center;
  margin-top: 2px;
  margin-bottom: 2px;
  cursor: pointer;
  & svg {
    width: 20px;
    height: 20px;
  }
`;

export const Percentage = styled(Typography)(({ theme }) => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '12px',
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.text.disabled,
  width: '30px',

  '&.finished': {
    color: theme.palette.secondary.dark,
  },
}));

export const FieldText = styled(Typography)`
  color: ${({ theme: { palette } }) => palette.text.primary};
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  margin-left: 9px;
  margin-top: 4px;
`;

export const HeaderTitle = styled(Typography)`
  color: ${({ theme: { palette } }) => palette.text.primary};
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
`;

export const IconContainer = styled(Box)`
  width: 24px;
  height: 24px;
  margin-right: 5px;
`;

export const PopoverContentWrapper = styled(Box)`
  overflow-y: auto;
  height: 100%;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: ${({ theme: { palette } }) => palette.secondary.main};
    box-shadow: none;
  }
  &::-webkit-scrollbar-track {
    border-radius: 5px;
    border: none;
    background: ${({ theme: { palette } }) => palette.common.white};
    box-shadow: inset 0px 0px 15px rgba(46, 96, 170, 0.15);
  }
`;

export const Divider = styled(Box)`
  height: 1px;
  background: ${({ theme: { palette } }) => palette.background.default};
  margin-top: 3px;
  margin-bottom: 15px;
`;

export const StatusWrapper = styled(Box)`
  height: 4px;
  border-radius: 2px;
  background: ${({ theme: { palette } }) => palette.background.default};
`;

export const StatusContent = styled(Box)<{ width: number }>`
  width: ${({ width }) => width}%;
  background: ${({ theme: { palette } }) => palette.info.main};
  height: 4px;
  border-radius: 2px;
`;

export const ExpandButton = styled(IconButton)(() => ({
  width: '24px',
  height: '24px',
  padding: 0,
  transform: 'rotate(0deg)',
  transition: '0.5s',

  '&.expanded': {
    transform: 'rotate(180deg)',
  },
}));
