import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { useLocation } from 'react-router-dom';

import { closeWsConnection } from 'src/utils/api';
import { getToken } from 'src/utils/localStorage';

function PrivateRoute(props: RouteProps) {
  const isLoggedIn = !!getToken();
  const location = useLocation();

  if (!isLoggedIn) {
    closeWsConnection?.();

    return (
      <Redirect
        to={{
          pathname: '/auth/login',
          state: { from: location },
        }}
      />
    );
  }

  return <Route {...props} />;
}

export default PrivateRoute;
