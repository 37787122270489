export enum INSTALLING_STATUSES {
  installing = 'installing',
  pending = 'pending',
  success = 'success',
  error = 'error',
}

export interface InstallingStatusInterface {
  status: string;
  color: string;
  iconName?: string;
  isLoading?: boolean;
}

export interface FacebookBusiness {
  id: string;
  name: string;
}
