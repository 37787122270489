import { Box, Icon, IconButton, ListItem, Typography } from '@material-ui/core';
import MuiDrawer from '@material-ui/core/Drawer';
import { experimentalStyled as styled, Theme, makeStyles } from '@material-ui/core/styles';
import { CSSObject } from '@material-ui/core/styles/experimentalStyled';
import { ListItemButton } from '@mui/material';

export const NavBarContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  padding: 30px 28px 40px 28px;
  user-select: none;
`;

export const NavBarMain = styled(Box)`
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  align-items: center;
`;

export const AddButtonLink = styled(ListItem)(({ theme }) => ({
  padding: '0',
  marginBottom: '15px',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: '15px',
  lineHeight: '18px',
  color: theme.palette.background.default,

  '&.Mui-focusVisible': {
    textDecoration: 'underline',
  },
}));

export const NavBarFooter = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const NavBarOnBoardingItem = styled(Box)`
  font-size: 15px;
  font-family: Roboto;
  font-weight: 500;
  line-height: 18px;
  background: none;
  text-align: start;
  padding: 0;
  border: none;

  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const drawerWidth = 240;

const openedDrawer = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  border: 'none',
  overflowX: 'hidden',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
});

const closedDrawer = (theme: Theme): CSSObject => ({
  border: 'none',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(13)} + 1px)`,
  },
});

export const StyledDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedDrawer(theme),
    '& .MuiDrawer-paper': openedDrawer(theme),
  }),
  ...(!open && {
    ...closedDrawer(theme),
    '& .MuiDrawer-paper': closedDrawer(theme),
  }),
}));

export const RightSideContainer = styled(Box)(({ theme }) => ({
  zIndex: theme.zIndex.drawer,
  height: '100%',
  minWidth: '16px',
}));

export const DividerContainer = styled(Box)`
  position: fixed;
  width: 2px;
  height: 100%;

  hr {
    background: ${({ theme: { palette } }) => palette.secondary.dark};
  }
`;

export const OpenCloseIcon = styled(IconButton)`
  position: absolute;
  top: 135px;
  left: -12px;
  width: 24px;
  height: 24px;
  background: ${({ theme: { palette } }) => palette.secondary.dark};

  :hover {
    background: ${({ theme: { palette } }) => palette.secondary.dark};
  }
`;

export const CloseButton = styled(IconButton)`
  position: absolute;
  right: 9px;
  top: 9px;
`;

export const StyledIcon = styled(Icon)`
  svg {
    width: 24px;
    height: 24px;
  }
`;

export const StyledLinkA = styled('a')`
  margin-bottom: 15px;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  text-decoration: none;
  color: ${({ theme: { palette } }) => palette.text.secondary};
  :visited {
    text-decoration: none;
    color: ${({ theme: { palette } }) => palette.text.secondary};
  }
  :active {
    text-decoration: none;
    color: ${({ theme: { palette } }) => palette.text.secondary};
  }
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const StyledTypography = styled(Typography)`
  color: ${({ theme: { palette } }) => palette.text.secondary};
  cursor: pointer;
  margin-bottom: 15px;
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  height: '48px',
  padding: '15px',
  paddingLeft: '12px',
  marginTop: '10px',
  borderRadius: '50px',
  background: 'transparent',
  width: '100%',

  '& .MuiTypography-root': {
    color: theme.palette.background.default,
  },

  '&.Mui-focusVisible': {
    boxShadow: '0 0 15px #F16152',
  },

  '& .MuiSvgIcon-root': {
    fontSize: '24px',
    width: '24px',
    height: '24px',
    color: theme.palette.background.default,
  },

  '&.Mui-selected': {
    background: theme.palette.background.default,

    '&:hover': {
      background: theme.palette.background.default,
    },

    '&.Mui-focusVisible': {
      boxShadow: `0 0 15px ${theme.palette.secondary.main}`,
      background: theme.palette.background.default,
    },

    '& .MuiSvgIcon-root': {
      color: theme.palette.secondary.dark,
    },

    '& .MuiTypography-root': {
      color: theme.palette.secondary.dark,
    },
  },
}));

export const useNavbarListItemButtonStyles = makeStyles((theme: Theme) => ({
  navbarItem: {
    height: '48px',
    padding: '15px',
    paddingLeft: '12px',
    marginTop: '10px',
    borderRadius: '50px',
    background: 'transparent',
    width: '100%',

    '&.Mui-focusVisible': {
      boxShadow: '0 0 15px #F16152',
    },

    '& .MuiSvgIcon-root': {
      fontSize: '24px',
      width: '24px',
      height: '24px',
    },
  },
  navbarItemActive: {
    background: theme.palette.background.default,

    '&:hover': {
      background: theme.palette.background.default,
    },

    '&.Mui-focusVisible': {
      boxShadow: `0 0 15px ${theme.palette.secondary.main}`,
      background: theme.palette.background.default,
    },
  },
  navbarCompactItem: {
    justifyContent: 'center',
  },
}));

export const StyledUserName = styled(Typography)`
  margin-bottom: 10px;
  margin-top: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
`;

export const StyledRecentStepsText = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
`;
