import React, { ReactNode } from 'react';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';

import { Box, Theme, makeStyles, Backdrop } from '@material-ui/core';

import { UploadMedia } from '../MediaGallery/constants';
import { StyledMediaContainer, StyledMediaText } from '../MediaGallery/styled';

interface StyleMediaDropzoneProps {
  dropBlockHeight: string;
  dropBlockMaxWidth: string;
}

const DEFAULT_DROP_BLOCK_MAX_WIDTH = '860px';
const DEFAULT_DROP_BLOCK_HEIGHT = '348px';

const useStyles = makeStyles<Theme, StyleMediaDropzoneProps>((theme) => ({
  dropContainer: {
    zIndex: 1,
    position: 'absolute',
    backgroundColor: theme.palette.common.white,
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='2' ry='2' stroke='%23FFB472' stroke-width='2' stroke-dasharray='7' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e")`,
    height: ({ dropBlockHeight }) => dropBlockHeight,
    width: '100%',
    maxWidth: ({ dropBlockMaxWidth }) => dropBlockMaxWidth,
    top: 'auto',
    bottom: 'auto',
    left: 'auto',
    right: 'auto',
  },
}));

interface MediaDropzoneProps {
  mediaContainer?: string;
  dropzoneContainer?: string;
  children: ReactNode;
  dropzoneBlockHeight?: string;
  dropzoneBlockMaxWidth?: string;
  handleUploadMedia: ({ files, urls }: UploadMedia) => void;
  id?: string;
}

const MediaDropzone = ({
  children,
  dropzoneBlockHeight,
  dropzoneBlockMaxWidth,
  dropzoneContainer,
  mediaContainer,
  handleUploadMedia,
  id,
}: MediaDropzoneProps) => {
  const dropBlockMaxWidth = dropzoneBlockHeight || DEFAULT_DROP_BLOCK_MAX_WIDTH;
  const dropBlockHeight = dropzoneBlockMaxWidth || DEFAULT_DROP_BLOCK_HEIGHT;

  const { t } = useTranslation();
  const classes = useStyles({ dropBlockHeight, dropBlockMaxWidth });

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: [NativeTypes.URL, NativeTypes.FILE],
    drop(acceptedFiles: { files: File[]; urls: string[] }) {
      handleUploadMedia({ ...acceptedFiles });
    },
    collect: (monitor: { isOver: () => boolean; canDrop: () => boolean }) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const isDragActive = canDrop && isOver;

  return (
    <Box className={isDragActive ? dropzoneContainer : mediaContainer} ref={drop} data-testid="mediaDropZone" id={id}>
      <Backdrop open={isDragActive} className={classes.dropContainer}>
        <StyledMediaContainer>
          <StyledMediaText>{t('productItemCreateEdit.mediaGallery.mediaContainer.dropping')}</StyledMediaText>
        </StyledMediaContainer>
      </Backdrop>
      {children}
    </Box>
  );
};

export default MediaDropzone;
