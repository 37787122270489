import { Box } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';

export const RegisterContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginLeft: '120px',
  minWidth: '340px',

  [theme.breakpoints.down('lg')]: {
    marginLeft: 0,
  },
}));

export const SideContainer = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 378px;
  min-height: 272px;
`;

export const Image = styled('img')(({ theme }) => ({
  marginLeft: '77px',
  maxWidth: '662px',
  width: '100%',

  [theme.breakpoints.down('md')]: {
    margin: '40px 0 0',
  },
}));

export const StyledRegisterContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  maxWidth: '1200px',
  margin: '0 auto',
  padding: '77px 0 61px',

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));
