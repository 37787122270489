import { ApolloCache } from '@apollo/client';
import { Maybe } from 'yup/es/types';

import { Catalog, CatalogJob, CatalogJobStatus, CatalogSource, CatalogType, Mutation, Query } from 'src/utils/gql';

export type TCatalogsData = Pick<Query, 'getCatalogs'>;

export type CatalogsJobsMap = {
  [catalogId: string]: {
    [integrationId: string]: CatalogJob;
  };
};

export interface CatalogsContextType {
  catalogs: Catalog[];
  catalogsJobsMap: CatalogsJobsMap;
  loadingGet: boolean;
  loadingDelete: boolean;
  loadingCreate: boolean;
  loadingCreateOnboardingCatalog: boolean;
  createCatalog: (
    name: string,
    source: CatalogSource,
    integrationId?: string,
  ) => Promise<Maybe<Pick<Mutation, 'createCatalog'>>>;
  onCreateOnboardingCatalog: (
    name: string,
    source: CatalogSource,
  ) => Promise<Maybe<Pick<Mutation, 'createOnboardingCatalog'>>>;
  hasMore: boolean;
  handleFetchMore: () => Promise<void>;
  handleDelete: (id: string) => Promise<void>;
  setFilters: (filters: FiltersState) => void;
  getCatalogJob: ({ catalogId, integrationId }: CatalogueJobsProps) => CatalogJob | null;
  updateCatalogsJobsMap: (jobs: CatalogJob[]) => void;
  deleteCatalogsJobs: (params: { catalogId?: string; integrationId?: string }) => void;
  updateExportType: (id: string, complexExport?: boolean) => void;
  currentExportType: CurrentExportTypeProps[];
  updateCurrentExport: (id: string, complexExport?: boolean) => void;
}

export interface FiltersState {
  name?: string;
  types: CatalogType[];
}

export interface CatalogStatusMap {
  [id: string]: CatalogJobStatus;
}

export type CreateCatalogCacheType =
  | ApolloCache<Pick<Mutation, 'createCatalog'>>
  | ApolloCache<Pick<Mutation, 'createOnboardingCatalog'>>;

export interface CatalogueJobsProps {
  catalogId: string;
  integrationId?: string;
}

export enum ExportTypes {
  simple = 'simple',
  complex = 'complex',
}

export interface CurrentExportTypeProps {
  catalogId: string;
  exportType: ExportTypes;
}
