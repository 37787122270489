import React from 'react';

import { Box } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';

const MainBox = styled(Box)`
  position: absolute;
  width: 375px;
  height: 220px;
  opacity: 0.7;
  filter: blur(26px);

  & * {
    position: absolute;
    border-radius: 50%;
  }
`;

const PurpleBox = styled(Box)`
  background-color: #634df6;
  box-shadow: 0 0 0 10px #634df6;
`;

const YellowBox = styled(Box)`
  background-color: #f9c126;
  box-shadow: 0 0 0 10px #f9c126;
`;

const OrangeBox = styled(Box)`
  background-color: #f5794d;
  box-shadow: 0 0 0 10px #f5794d;
`;

const DarkOrangeBox = styled(Box)`
  background-color: #ea5c5c;
  box-shadow: 0 0 0 10px #ea5c5c;
`;

const WhiteBox = styled(Box)`
  background-color: #fff;
  box-shadow: 0 0 0 10px #fff;
`;

const CreateProductItemBackground = () => {
  return (
    <MainBox>
      <YellowBox width="86px" height="40px" top="58px" left="28px" />

      <PurpleBox width="130px" height="46px" top="64px" right="6px" />

      <PurpleBox width="114px" height="30px" top="40px" right="137px" />

      <PurpleBox width="76px" height="96px" bottom="42px" left="80px" />

      <PurpleBox width="20px" height="26px" bottom="21px" left="132px" />

      <OrangeBox width="102px" height="132px" bottom="37px" right="104px" />

      <DarkOrangeBox width="10px" height="18px" bottom="113px" right="60px" />

      <OrangeBox width="60px" height="30px" bottom="12px" right="128px" />

      <DarkOrangeBox width="32px" height="26px" bottom="36px" left="82px" />

      <PurpleBox width="4px" height="66px" bottom="98px" left="0" />

      <PurpleBox width="100px" height="2px" top="40px" left="10px" sx={{ transform: 'rotate(-12deg)' }} />

      <DarkOrangeBox width="132px" height="8px" top="10px" right="137px" />

      <DarkOrangeBox width="10px" height="18px" bottom="103px" left="60px" />

      <WhiteBox width="10px" height="18px" bottom="143px" left="60px" />

      <YellowBox width="118px" height="2px" top="46px" right="12px" sx={{ transform: 'rotate(12deg)' }} />

      <YellowBox width="6px" height="66px" bottom="88px" right="16px" />

      <YellowBox width="60px" height="2px" bottom="90px" right="12px" sx={{ transform: 'rotate(12deg)' }} />
    </MainBox>
  );
};

export default CreateProductItemBackground;
