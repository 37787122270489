import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { InputProps, TextField } from '@material-ui/core';

import { FieldType } from 'src/utils/gql';
import { priceProps } from 'src/utils/numberFieldValidator';

import { EXCEEDED_LIMIT_VALUE } from '../constants';

import AutoLoadingSingleSelect from './AutoLoadingSingleSelect';
import BooleanSelect from './BooleanSelect';
import NumberInput from './Forms/FormInputs/NumberInput';
import { FormField } from './Forms/styled';
import MultiSelectInput from './MultiSelectInput';
import SingleSelect from './SingleSelect';

export enum MultiTypeInputAdditionalFieldType {
  Text = 'text',
}

export type MultiTypeInputFieldType = FieldType | MultiTypeInputAdditionalFieldType;

export interface AbstractFieldTypeInputProps {
  /**
   * If true, the input element is focused during the first mount.
   */
  autoFocus?: boolean;

  /**
   * If true, the component is disabled.
   */
  disabled?: boolean;

  /**
   * It is used while previous flag is true, to write helperText while empty field
   */
  emptyErrorMessage?: string;

  /**
   * End InputAdornment for this component.
   */
  endAdornment?: React.ReactNode;

  /**
   * If true, the label is displayed in an error state.
   */
  error?: boolean;

  /**
   * The helper text content.
   */
  helperText?: string;

  /**
   * Identifier to work with react-hook-form library
   */
  inputIdentifier: string;

  /**
   * Description label content
   */
  label: string;

  /**
   * Change input handler
   */
  onChange: (value: string) => void;

  /**
   * If true, element should be filled (Maybe will be deleted in the future and will be used Boolean(emptyErrorMessage) for that)
   */
  shouldBeFilled?: boolean;

  /**
   * Current input value
   */
  value: string;

  testId?: string;
}

interface MultiTypeInputProps {
  regex?: { value: RegExp; message: string };
  manualValue?: string;
  onManualChange?: (value: string) => void;
  manualHelperText?: string;
  manualError?: boolean;
  touched?: boolean;
  setTouched?: (value: boolean) => void;
  label: string;
  type: MultiTypeInputFieldType;
  productTypeId?: string;
  productTypeFieldId?: string;
  inputProps?: Partial<InputProps>;
  isDefaultFieldValue?: boolean;
  disabled?: boolean;
  checkIsSpecialImportedField?: boolean;
  id?: string;
  shouldBeFilled?: boolean;
  emptyErrorMessage?: string;
  inputIdentifier: string;
  autoFocus?: boolean;
  onCreateEndAdornment?: () => React.ReactNode | undefined;
}

/**
 * REQUIREMENTS
 *
 * Each component should have simple interface value-onChange, based on string values
 * onChange should get ONLY valid values, which can be sent on backend side
 * All temporary states and validations should be INSIDE components
 */

const MultiTypeInput = ({
  regex,
  manualError,
  manualHelperText,
  onManualChange,
  manualValue,
  label,
  type,
  productTypeFieldId,
  productTypeId,
  inputProps,
  isDefaultFieldValue,
  disabled,
  checkIsSpecialImportedField,
  id,
  shouldBeFilled,
  emptyErrorMessage,
  inputIdentifier,
  autoFocus,
  onCreateEndAdornment,
}: MultiTypeInputProps) => {
  const { t } = useTranslation();

  const { control } = useFormContext();

  const generalRules = {
    ...(regex && { pattern: { value: regex.value, message: regex.message } }),
    required: shouldBeFilled ? emptyErrorMessage : undefined,
  };

  const generalRulesWith255Limit = {
    ...generalRules,
    maxLength: { value: EXCEEDED_LIMIT_VALUE, message: t('exceededLimitValue') },
  };

  switch (type) {
    case MultiTypeInputAdditionalFieldType.Text:
      return (
        <Controller
          name={`${inputIdentifier}` as const}
          control={control}
          rules={generalRules}
          render={({ field: { onChange, value }, fieldState: { error, invalid } }) => (
            <FormField
              autoComplete="off"
              multiline
              fullWidth
              label={label}
              error={manualError || invalid}
              disabled={disabled}
              value={manualValue || value || ''}
              helperText={manualHelperText || error?.message}
              variant="outlined"
              inputProps={{ 'data-testid': `textField${label}`, autoFocus }}
              onChange={({ target: { value: newValue } }) => (onManualChange || onChange)(newValue.trimStart())}
              InputProps={{ endAdornment: onCreateEndAdornment?.() }}
            />
          )}
        />
      );

    case FieldType.String:
      return (
        <Controller
          name={`${inputIdentifier}` as const}
          control={control}
          rules={generalRulesWith255Limit}
          render={({ field: { onChange, value }, fieldState: { error, invalid } }) => (
            <TextField
              autoFocus={autoFocus}
              fullWidth
              autoComplete="off"
              variant="outlined"
              label={label}
              error={manualError || invalid}
              disabled={disabled}
              value={manualValue || value || ''}
              onChange={({ target: { value: newValue } }) => (onManualChange || onChange)(newValue.trimStart())}
              helperText={manualHelperText || error?.message}
              InputProps={{
                name: inputIdentifier,
                endAdornment: onCreateEndAdornment?.(),
                ...inputProps,
              }}
              id={id}
              inputProps={{ 'data-testid': `stringField${label}`, 'aria-label': label, autoFocus }}
            />
          )}
        />
      );

    case FieldType.Number:
    case FieldType.Price:
      return (
        <Controller
          name={`${inputIdentifier}` as const}
          control={control}
          rules={generalRules}
          render={({ field: { onChange, value }, fieldState: { error, invalid } }) => (
            <NumberInput
              autoFocus={autoFocus}
              onChange={onManualChange || onChange}
              label={label}
              error={manualError || invalid}
              helperText={manualHelperText || error?.message}
              value={manualValue || value || ''}
              InputProps={inputProps}
              disabled={disabled}
              inputProps={{ 'data-testid': `numberField${label}`, 'aria-label': label }}
              id={id}
              inputIdentifier={inputIdentifier}
              {...(type === FieldType.Price ? priceProps : {})}
            />
          )}
        />
      );

    case FieldType.Boolean:
      return (
        <Controller
          name={`${inputIdentifier}` as const}
          control={control}
          rules={generalRules}
          render={({ field: { onChange, value }, fieldState: { error, invalid } }) => (
            <BooleanSelect
              value={manualValue || value || ''}
              onChange={onManualChange || onChange}
              autoFocus={autoFocus}
              label={label}
              error={manualError || invalid}
              helperText={manualHelperText || error?.message}
              disabled={disabled}
              isDefaultFieldValue={isDefaultFieldValue}
            />
          )}
        />
      );

    case FieldType.Multiselect:
      // negative margin is needed to correct showing helper text
      return (
        <Controller
          name={`${inputIdentifier}` as const}
          control={control}
          rules={{
            ...generalRulesWith255Limit,
            validate: shouldBeFilled
              ? (value) => {
                  try {
                    return Boolean(JSON.parse(value).length) || emptyErrorMessage;
                  } catch {
                    return String(t('multiselect.errorJSONFormat'));
                  }
                }
              : undefined,
          }}
          render={({ field: { onChange, value }, fieldState: { error, invalid } }) => (
            <MultiSelectInput
              autoFocus={autoFocus}
              value={manualValue || value || ''}
              onChange={onManualChange || onChange}
              label={label}
              error={manualError || invalid}
              helperText={manualHelperText || error?.message}
              disabled={disabled}
              data-testid={`selectField${label}`}
              shouldBeFilled={shouldBeFilled}
              emptyErrorMessage={emptyErrorMessage}
              endAdornment={inputProps?.endAdornment}
              inputIdentifier={inputIdentifier}
            />
          )}
        />
      );

    case FieldType.Singleselect:
      return (
        <Controller
          name={`${inputIdentifier}` as const}
          control={control}
          rules={generalRulesWith255Limit}
          render={({ field: { onChange, value }, fieldState: { error, invalid } }) =>
            productTypeId && productTypeFieldId ? (
              <AutoLoadingSingleSelect
                value={manualValue || value || ''}
                autoFocus={autoFocus}
                onChange={onManualChange || onChange}
                label={label}
                disablePortal={false}
                productTypeFieldId={productTypeFieldId}
                productTypeId={productTypeId}
                error={manualError || invalid}
                helperText={manualHelperText || error?.message}
                disabled={disabled}
                checkIsSpecialImportedField={checkIsSpecialImportedField}
                inputIdentifier={inputIdentifier}
                isDefaultFieldValue={isDefaultFieldValue}
              />
            ) : (
              <SingleSelect
                value={manualValue || value || ''}
                autoFocus={autoFocus}
                onChange={onManualChange || onChange}
                options={[]}
                label={label}
                disablePortal={false}
                error={manualError || invalid}
                helperText={manualHelperText || error?.message}
                disabled={disabled}
                checkIsSpecialImportedField={checkIsSpecialImportedField}
                inputIdentifier={inputIdentifier}
                isDefaultFieldValue={isDefaultFieldValue}
              />
            )
          }
        />
      );

    default:
      return <></>;
  }
};

export default MultiTypeInput;
