import React from 'react';

import { TableCell, useTheme } from '@material-ui/core';

import Iconography from 'src/components/Iconography';

import { ActionType } from '../types';

import { ActionIcon } from './styled';

interface ActionIconTableCellProps {
  action: string;
}

const ActionIconTableCell = ({ action }: ActionIconTableCellProps) => {
  const { palette } = useTheme();

  const renderActionIcon = () => {
    let bgColor;
    let iconName;

    switch (action) {
      case ActionType.Create:
        bgColor = '#7F6FDD';
        iconName = 'add';
        break;

      case ActionType.Update:
        bgColor = palette.info.main;
        iconName = 'refresh';
        break;

      case ActionType.Delete:
        bgColor = palette.error.main;
        iconName = 'trash-fill';
        break;

      case ActionType.Import:
        bgColor = palette.success.main;
        iconName = 'import';
        break;

      case ActionType.Export:
        bgColor = palette.success.main;
        iconName = 'share';
        break;
    }

    return (
      <ActionIcon sx={{ background: bgColor }}>
        <Iconography iconName={iconName} />
      </ActionIcon>
    );
  };

  return <TableCell>{renderActionIcon()}</TableCell>;
};

export default ActionIconTableCell;
