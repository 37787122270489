import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams, useLocation } from 'react-router';

import { ApolloError, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Box, Typography } from '@material-ui/core';
import { CircularProgress } from '@mui/material';

import Button from 'src/components/Button';
import { PaymentPlanCard } from 'src/components/Card/PaymentPlanCard';
import Iconography from 'src/components/Iconography';
import Loader from 'src/components/Loader';
import Page from 'src/components/Page';
import PaymentMethodStripeContainer from 'src/components/Stripe/PaymentMethodStripeContainer';
import { SuccessPaymentBackdrop } from 'src/components/Stripe/styled';
import { FIRST_DASHBOARD_PATHNAME, USER_SETTINGS } from 'src/constants/routeSources';
import { useSnackbar } from 'src/providers/snackbar';
import asMoney from 'src/utils/asMoney';
import {
  createStripeSubscription,
  getPlans,
  getProration,
  Mutation,
  MutationCreateStripeSubscriptionArgs,
  MutationUpgradePlanArgs,
  Plan,
  PlanPaymentFrequency,
  PlanType,
  Query,
  QueryGetPlansArgs,
  QueryGetProrationArgs,
  upgradePlan,
} from 'src/utils/gql';

import { ContinueLink, PageContainer, BillingInfoContainer, PaymentPlanWrapper, PageCloseButton } from './styled';

const initialSubscriptionData = {
  clientSecret: '',
  subscriptionId: '',
};

const PaymentPlan = () => {
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const history = useHistory();
  const { plan } = useParams<{ plan: string }>();
  const { pathname } = useLocation();

  const [plans, setPlans] = useState<Plan[]>([]);
  const [selectedPlan, setSelectedPlan] = useState<Plan | undefined>();
  const [proration, setProration] = useState<number | null>(null);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState<{ clientSecret: string; subscriptionId: string }>(
    initialSubscriptionData,
  );
  const [creationSubscriptionAvailable, setCreationSubscriptionAvailable] = useState<boolean>(false);

  const isUpgradePaidPlan = pathname.includes('upgrade');

  const [getProrationQuery, { loading: loadingGetProration }] = useLazyQuery<
    Pick<Query, 'getProration'>,
    QueryGetProrationArgs
  >(getProration, {
    fetchPolicy: 'network-only',
    onCompleted: ({ getProration }) => setProration(getProration),
    onError: (error) => {
      snackbar(error.message);
      history.replace(`/${FIRST_DASHBOARD_PATHNAME}/${USER_SETTINGS}`);
    },
  });

  const { loading } = useQuery<Pick<Query, 'getPlans'>, QueryGetPlansArgs>(getPlans, {
    fetchPolicy: 'cache-first',
    variables: {
      type: PlanType.Paid,
    },

    onCompleted: ({ getPlans }) => {
      const targetPlans = getPlans.filter(({ name }) => name.toLowerCase() === plan);
      const annualPlan = targetPlans.find(({ paymentFrequency }) => paymentFrequency === PlanPaymentFrequency.Annual);

      setPlans(targetPlans);

      if (annualPlan) {
        if (isUpgradePaidPlan) {
          getProrationData(annualPlan);
        } else {
          handleCreateSubscription(annualPlan).finally(() => setCreationSubscriptionAvailable(true));
        }
      }
    },
  });

  const [createSubscription, { loading: loadingCreateSubscription }] = useMutation<
    Pick<Mutation, 'createStripeSubscription'>,
    MutationCreateStripeSubscriptionArgs
  >(createStripeSubscription);

  const [upgradePlanMutation, { loading: loadingUpgradePlan }] = useMutation<
    Pick<Mutation, 'upgradePlan'>,
    MutationUpgradePlanArgs
  >(upgradePlan);

  const handleGoBack = () => history.goBack();

  const getProrationData = (plan: Plan) => {
    try {
      getProrationQuery({ variables: { planId: plan.id } });
      setSelectedPlan(plan);
    } catch (error) {
      const { graphQLErrors, message: errorText } = error as ApolloError;
      const message = graphQLErrors && graphQLErrors.length > 0 ? graphQLErrors[0].message : errorText;
      if (error) {
        snackbar(message);
      }
    }
  };

  const handleCreateSubscription = async (plan: Plan) => {
    setCreationSubscriptionAvailable(false);

    try {
      const { data } = await createSubscription({
        variables: {
          planId: plan.id,
        },
      });

      if (data) {
        const { createStripeSubscription } = data;
        setSubscriptionData(createStripeSubscription);

        setSelectedPlan(plan);
      }
    } catch (error) {
      const { graphQLErrors, message: errorText } = error as ApolloError;
      const message = graphQLErrors && graphQLErrors.length > 0 ? graphQLErrors[0].message : errorText;
      if (error) {
        snackbar(message);

        history.replace(`/${FIRST_DASHBOARD_PATHNAME}/${USER_SETTINGS}`);
      }
    }

    setCreationSubscriptionAvailable(true);
  };

  const handleUpgradePlan = async () => {
    if (selectedPlan) {
      try {
        await upgradePlanMutation({ variables: { planId: selectedPlan.id } });
        setShowBackdrop(true);

        setTimeout(() => {
          history.replace('/dashboard/user-settings');
        }, 6000);
      } catch (error) {
        const { graphQLErrors, message: errorText } = error as ApolloError;
        const message = graphQLErrors && graphQLErrors.length > 0 ? graphQLErrors[0].message : errorText;
        if (error) {
          snackbar(message);
          setShowBackdrop(false);
        }
      }
    }
  };

  const handleChangePlan = (plan?: Plan) => {
    if (plan) {
      setSubscriptionData(initialSubscriptionData);
      isUpgradePaidPlan ? getProrationData(plan) : handleCreateSubscription(plan);
    }
  };

  return (
    <>
      <Page title={t('paymentPage.pageTitle')}>
        <PageContainer>
          {loading ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
              <Loader />
            </Box>
          ) : (
            <>
              <PaymentPlanWrapper>
                <Typography variant="h2" textAlign="center" mb="30px">
                  {t('paymentPage.yourPlan')}
                </Typography>

                <PaymentPlanCard
                  plans={plans}
                  selectedPlan={selectedPlan}
                  onChangePlan={handleChangePlan}
                  changePlanAvailable={isUpgradePaidPlan || creationSubscriptionAvailable}
                />

                {isUpgradePaidPlan ? (
                  <Box height="48px" />
                ) : (
                  <Typography variant="subtitle2" fontWeight={400} color="primary" ml="40px" my="15px">
                    {t('paymentPage.or')}
                    <ContinueLink
                      color="secondary"
                      underline="always"
                      onClick={() => history.replace(`/${FIRST_DASHBOARD_PATHNAME}`)}
                    >
                      {t('paymentPage.continue')}
                    </ContinueLink>
                  </Typography>
                )}
              </PaymentPlanWrapper>

              {isUpgradePaidPlan ? (
                <BillingInfoContainer>
                  <Typography textAlign="center" mb="20px">
                    Upgrade to {selectedPlan?.name}
                  </Typography>

                  <Typography textAlign="center" mb="20px">
                    {loadingGetProration ? 'Please wait...' : proration && asMoney(proration, 'usd')}
                  </Typography>
                  <Button
                    variant="contained"
                    onClick={handleUpgradePlan}
                    loading={loadingUpgradePlan}
                    disabled={!selectedPlan || loadingGetProration}
                  >
                    Upgrade Plan
                  </Button>
                </BillingInfoContainer>
              ) : (
                <Box display="flex" flexDirection="column">
                  {subscriptionData.clientSecret && !loadingCreateSubscription ? (
                    <BillingInfoContainer>
                      <PaymentMethodStripeContainer clientSecret={subscriptionData.clientSecret} />
                    </BillingInfoContainer>
                  ) : (
                    <Box display="flex" justifyContent="center" alignItems="center" height="426px" width="580px">
                      <Loader />
                    </Box>
                  )}
                </Box>
              )}
            </>
          )}
          <PageCloseButton onClick={handleGoBack}>
            <Iconography iconName="cancel" htmlColor="secondary" />
          </PageCloseButton>
        </PageContainer>
      </Page>

      <SuccessPaymentBackdrop open={showBackdrop}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <CircularProgress color="inherit" size={30} />

          <Typography color="inherit" mt="10px" mb="2px">
            {t('paymentPage.billingInfo.successPayment')}
          </Typography>
          <Typography color="inherit">{t('paymentPage.billingInfo.redirectMessage')}</Typography>
        </Box>
      </SuccessPaymentBackdrop>
    </>
  );
};

export default PaymentPlan;
