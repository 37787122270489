export const MISSED_CAPITAL_LETTER = 0b00001;
export const MISSED_LOWER_LETTER = 0b00010;
export const MISSED_NUMBER = 0b00100;
export const MISSED_SPECIAL_SYMBOL = 0b01000;
export const MISSED_CORRECT_LENGTH = 1 << 4;

export function passwordValidator(newPassword: string): number {
  let result = 0;

  if (newPassword.length < 8 || newPassword.length >= 32) {
    result |= MISSED_CORRECT_LENGTH;
  }
  if (!newPassword.match(/[A-Z]/)) {
    result |= MISSED_CAPITAL_LETTER;
  }
  if (!newPassword.match(/[0-9]/)) {
    result |= MISSED_NUMBER;
  }
  if (!newPassword.match(/[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/)) {
    result |= MISSED_SPECIAL_SYMBOL;
  }
  return result;
}
