import { Box } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';

import Page from 'src/components/Page';

export const PageWrapper = styled(Page)`
  height: 100%;
  margin-left: -16px;
`;

export const PageContentWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

export const ProductTypesWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 50px 96px 40px 10px;
  margin: 0 56px 0 134px;
`;

export const TypeCardWrapper = styled(Box)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(496px, 1fr));
  grid-column-gap: 24px;
  grid-row-gap: 24px;
`;

export const ProductTypeCard = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 34px 30px 35px 35px;
  background: ${({ theme: { palette } }) => palette.background.default};
  border-radius: 5px;
  height: 90px;
  overflow: hidden;

  &:hover {
    box-shadow: 0 2px 15px rgba(241, 97, 82, 0.35);
    cursor: pointer;
  }
`;

export const MoreProductTypesWrapper = styled(Box)`
  margin-top: 30px;
  place-self: center;
  grid-column: 1 / -1;
`;

export const NoProductTypesWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
`;

export const ProductTypesList = styled(Box)`
  overflow-y: auto;
`;
