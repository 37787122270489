import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, DialogProps } from '@material-ui/core';
import MuiDialog from '@material-ui/core/Dialog';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import Close from 'src/components/Images/Close.svg';

const urlContactUsMessage = `${process.env.PUBLIC_URL}/images/contactUsMessage.webp`;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiPaper-root, & .MuiDialogContent-root': {
      height: '580px',
      width: '100%',
      maxWidth: '640px',
    },
  },
  closeButton: {
    position: 'absolute',
    zIndex: 1,
    top: '15px',
    right: '15px',
    width: '34px',
    height: '34px',
    padding: '7px',
    borderRadius: '50%',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: `0 0 15px ${theme.palette.success.contrastText}`,
    },
    '&:active': {
      boxShadow: 'none',
      outline: 'none',
    },
  },
  closeImg: {
    position: 'absolute',
    margin: 'auto',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  },
  popupDescription: {
    margin: '25px 0 40px',
    width: '100%',
    maxWidth: '311px',
  },
  imgWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  popupWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
}));

interface ContactUsPopupProps extends DialogProps {
  onCloseClick: () => void;
}

const ContactUsMessage = ({ onCloseClick, ...props }: ContactUsPopupProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <MuiDialog {...props} className={classes.root}>
      <Box className={classes.popupWrapper}>
        <Box className={classes.closeButton} onClick={onCloseClick} role="button" tabIndex={0}>
          <img className={classes.closeImg} src={Close} alt="close" />
        </Box>
        <Box className={classes.imgWrapper}>
          <img src={urlContactUsMessage} alt="ContactUsMessage" width="154" height="188" />
        </Box>
        <Typography color="text.secondary" variant="body1" className={classes.popupDescription}>
          {t('contactUsPage.popupMessage.description')}
        </Typography>
        <Button variant="outlined" onClick={onCloseClick}>
          {t('contactUsPage.popupMessage.buttonText')}
        </Button>
      </Box>
    </MuiDialog>
  );
};

export default ContactUsMessage;
