import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Popover } from '@material-ui/core';
import { alpha, Typography, styled } from '@mui/material';

import Button from '../Button';

const StyledPopover = styled(Popover)(({ theme }) => ({
  '& .MuiBackdrop-root': {
    background: alpha(theme.palette.primary.light, 0.4),
  },

  '& .MuiPaper-root': {
    boxShadow: `0 2px 10px ${theme.palette.error.dark}`,
    borderRadius: '20px',
    outline: 'none',
    zIndex: theme.zIndex.drawer + 2,
    userSelect: 'none',
    width: '387px',
  },

  '&.arrow': {
    '& .MuiPaper-root': {
      overflowX: 'unset',
      overflowY: 'unset',

      '&::before': {
        content: '""',
        position: 'absolute',
        left: -14,
        bottom: 54,
        width: '28.28px',
        height: '28.28px',
        zIndex: -1,
        background: theme.palette.common.white,
        borderRadius: '2px',
        boxShadow: `0 2px 10px ${theme.palette.error.dark}`,
        transform: 'rotate(45deg)',
      },
    },
  },
}));

const PopoverContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '40px 36px',
  borderRadius: '20px',
  background: theme.palette.common.white,
  zIndex: 0,
}));

export const OnboardingImage = styled('object')(() => ({
  margin: '-2px 0 30px',
  height: '159px',
}));

interface OnboardingPopoverProps {
  open: boolean;
  anchorEl: HTMLElement | null;
  onStart: () => void;
  onSkip: () => void;
}

enum PopupType {
  Welcome,
  Skip,
}

const OnboardingPopup = ({ open, anchorEl, onStart, onSkip }: OnboardingPopoverProps) => {
  const { t } = useTranslation();

  const [type, setType] = useState<PopupType>(PopupType.Welcome);

  const {
    title,
    primaryButtonLabel,
    secondaryButtonLabel,
    descriptionText,
    descriptionText2 = null,
    imageSrc,
    position,
    showArrow = false,
    onPrimaryBtnClick,
    onSecondaryBtnClick,
  } = useMemo(() => {
    if (type === PopupType.Skip) {
      return {
        title: t('onboarding.skipOnboarding.title'),
        primaryButtonLabel: t('onboarding.button.backToOnboarding'),
        secondaryButtonLabel: t('onboarding.button.skip'),
        descriptionText: t('onboarding.skipOnboarding.description'),
        descriptionText2: t('onboarding.skipOnboarding.description2'),
        imageSrc: `${process.env.PUBLIC_URL}/images/onboarding-skip.webp`,
        position: { left: 54, top: 46 },
        showArrow: true,
        onPrimaryBtnClick: () => setType(PopupType.Welcome),
        onSecondaryBtnClick: onSkip,
      };
    }

    return {
      title: t('onboarding.intro.title'),
      primaryButtonLabel: t('onboarding.button.letsGo'),
      secondaryButtonLabel: t('onboarding.button.skipOnboarding'),
      descriptionText: t('onboarding.intro.description'),
      imageSrc: `${process.env.PUBLIC_URL}/images/onboarding-welcome.webp`,
      position: { left: 54, top: 70 },
      onPrimaryBtnClick: onStart,
      onSecondaryBtnClick: () => setType(PopupType.Skip),
    };
  }, [onSkip, onStart, t, type]);

  useEffect(() => {
    const body = document.getElementsByTagName('body')[0];
    const navbarBtn = document.getElementById('navbar_footer_item3_id');

    const handleKeydownEvent = (e: KeyboardEvent) => {
      const { code } = e;
      const doesPrimaryBtnClick =
        (type === PopupType.Welcome && code === 'Enter') || (type === PopupType.Skip && code === 'Backspace');
      const doesSecondaryBtnClick =
        (type === PopupType.Welcome && code === 'Escape') ||
        (type === PopupType.Skip && ['Enter', 'Escape'].includes(code));

      if (code === 'Tab') {
        e.preventDefault();
      }

      if (doesPrimaryBtnClick) {
        onPrimaryBtnClick();
      } else if (doesSecondaryBtnClick) {
        onSecondaryBtnClick();
      }
    };

    if (open) {
      navbarBtn?.blur();
      body.addEventListener('keydown', handleKeydownEvent);
    }

    return () => {
      body.removeEventListener('keydown', handleKeydownEvent);
    };
  }, [onPrimaryBtnClick, onSecondaryBtnClick, open, type]);

  return (
    <StyledPopover
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      anchorEl={anchorEl}
      open={open}
      sx={position}
      className={showArrow ? 'arrow' : ''}
    >
      <PopoverContent display="flex" flexDirection="column" padding="25px " minWidth="240px">
        <Typography color="secondary.dark" variant="h2">
          {title}
        </Typography>

        <Box display="flex" flexDirection="column" alignItems="center" m="32px 0px 24px">
          {imageSrc && (
            <OnboardingImage type="image/webp" data={imageSrc}>
              Onboarding
            </OnboardingImage>
          )}

          <Typography textAlign="center">{descriptionText}</Typography>

          {descriptionText2 && <Typography textAlign="center">{descriptionText2}</Typography>}
        </Box>

        <Box display="flex" justifyContent="center" width="100%">
          <Button variant="outlined" onClick={onSecondaryBtnClick} sx={{ marginRight: '15px' }}>
            {secondaryButtonLabel}
          </Button>

          <Button variant="contained" onClick={onPrimaryBtnClick}>
            {primaryButtonLabel}
          </Button>
        </Box>
      </PopoverContent>
    </StyledPopover>
  );
};

export default OnboardingPopup;
