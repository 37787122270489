import { createContext } from 'react';

import { User } from 'src/utils/gql';

import { AccountContextType } from './types';

export const defaultValue = {
  id: '0',
  email: '',
} as User;

const AccountContext = createContext<AccountContextType>({
  user: defaultValue,
  update: () => {
    if (process.env.NODE_ENV !== 'production') {
      console.error('Called AccountContext.update. Did you forget to provide a provider?');
    }
  },
});

AccountContext.displayName = 'AccountContext';

export default AccountContext;
