import React, { ChangeEvent, SyntheticEvent, useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useQuery } from '@apollo/client';
import { Box, Typography } from '@material-ui/core';
import { capitalize } from 'validate.js';

import DashboardBreadCrumbs from 'src/components/Breadcrumds/DashboardBreadCrumbs';
import ButtonScrollToTop from 'src/components/ButtonScrollToTop';
import SearchHeader from 'src/components/Headers/SearchHeader';
import { ReactComponent as NoNameSharedCatalogues } from 'src/components/Icon/no-name-shared-catalogs.svg';
import { ReactComponent as NoSharedCataloguesIcon } from 'src/components/Icon/no-shared-catalogs.svg';
import CloseCross from 'src/components/Images/CloseCross.svg';
import Page from 'src/components/Page';
import Scroll from 'src/components/Scroll';
import SharedCatalogsTable from 'src/components/SharedCatalogsTable/SharedCatalogsTable';
import { SharedCatalogTableLoader } from 'src/components/Skeleton/SkeletonSharedCatalogRow';
import { StyledKeywordCard, StyledKeywordItemTypography } from 'src/components/styled/SearchFieldStyles';
import { DEFAULT_CATALOGUES_PAGE_LIMIT } from 'src/constants';
import { validateTextFieldValue } from 'src/helpers/validationCheck';
import useDebounceValue from 'src/hooks/useDebounceValue';
import LayoutContext from 'src/layouts/Dashboard/LayoutContext';
import { getSharedCatalogs, OrderBy, OrderDirection, Query } from 'src/utils/gql';

import { DEBOUNCED_SEARCH_DELAY, SEARCH_CATALOGS_OFFSET } from '../types';

import { ActiveKeywordsWrapper, NoSharedCataloguesWrapper, StyledKeywordDeleteIcon } from './styled';

export interface SortData {
  sortableField: OrderBy;
  sortableType: OrderDirection;
}

const SharedCatalogs = () => {
  const { t } = useTranslation();
  const { fullscreenComponentsHeight } = useContext(LayoutContext);
  const [searchFieldValue, setSearchFieldValue] = useState<string>('');
  const [sortData, setSortData] = useState<SortData>({
    sortableField: OrderBy.Default,
    sortableType: OrderDirection.Asc,
  });
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [keywords, setKeywords] = useState<string[]>([]);

  const handleUpdateCatalogs = (data: Pick<Query, 'getCatalogs'>) =>
    setHasMore(data.getCatalogs.length === DEFAULT_CATALOGUES_PAGE_LIMIT);

  const debouncedSearchValue = useDebounceValue(searchFieldValue, DEBOUNCED_SEARCH_DELAY);

  const { data, loading, fetchMore } = useQuery(getSharedCatalogs, {
    fetchPolicy: 'cache-first',
    variables: {
      name: debouncedSearchValue,
      orderBy: sortData.sortableField,
      orderDirection: sortData.sortableType,
      offset: SEARCH_CATALOGS_OFFSET,
      limit: DEFAULT_CATALOGUES_PAGE_LIMIT,
      keywords: useDebounceValue(keywords, DEBOUNCED_SEARCH_DELAY),
    },
    onCompleted: handleUpdateCatalogs,
  });

  const hasTableData = !!data?.getCatalogs.length;

  const handleClickKeyword = (keyword: string) => {
    if (keywords.includes(keyword)) {
      setKeywords((keywords) => keywords.filter((existingKeyword) => existingKeyword !== keyword));
    } else {
      setKeywords((keywords) => [...keywords, keyword]);
    }
  };

  const handleSort = (
    sortableField: OrderBy,
    orderDirection?: OrderDirection,
    e?: SyntheticEvent<HTMLButtonElement>,
  ) => {
    if (orderDirection) {
      setSortData(() => ({
        sortableField,
        sortableType: orderDirection,
      }));
    } else if (e) {
      e.stopPropagation();

      if (sortData.sortableField !== sortableField) {
        setSortData(() => ({
          sortableField,
          sortableType: OrderDirection.Desc,
        }));
      } else {
        setSortData(({ sortableType: prevSortableType }) => ({
          sortableField,
          sortableType: prevSortableType === OrderDirection.Asc ? OrderDirection.Desc : OrderDirection.Asc,
        }));
      }
    }
  };

  const handleChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setSearchFieldValue(validateTextFieldValue(value));
  };

  const fetchMoreTableRows = useCallback(async () => {
    const offset = data?.getCatalogs?.length || 0;

    handleUpdateCatalogs(
      (
        await fetchMore<'offset'>({
          variables: {
            offset,
          },
        })
      ).data,
    );
  }, [data?.getCatalogs, fetchMore]);

  const title = t('sharedCatalogsPage.title');

  return (
    <Page title={title} locationName={title}>
      <Box
        display="flex"
        flexDirection="column"
        height={fullscreenComponentsHeight}
        width="100%"
        id="scrollableContainer"
      >
        <Box>
          <Box ml="8px" mb="25px">
            <DashboardBreadCrumbs />
          </Box>

          <SearchHeader
            activeKeywords={keywords}
            handleResetKeywords={() => setKeywords([])}
            handleClickKeyword={handleClickKeyword}
            sharedCatalogsSearch
            title={t('sharedCatalogsPage.title')}
            searchFieldValue={searchFieldValue}
            handleChange={handleChange}
          />

          <Scroll
            dataLength={data?.getCatalogs.length || 0}
            next={fetchMoreTableRows}
            hasMore={hasMore}
            loader={<SharedCatalogTableLoader />}
            pageId="scrollableContainer"
          >
            {Boolean(keywords.length) && (
              <ActiveKeywordsWrapper>
                {keywords.map((keyword, id) => (
                  <StyledKeywordCard key={id}>
                    <Box display="flex" justifyContent="space-evenly" alignItems="center">
                      <StyledKeywordItemTypography>{`#${capitalize(keyword)}`}</StyledKeywordItemTypography>

                      <StyledKeywordDeleteIcon
                        onClick={() => handleClickKeyword(keyword)}
                        alt="delete"
                        src={CloseCross}
                      />
                    </Box>
                  </StyledKeywordCard>
                ))}
              </ActiveKeywordsWrapper>
            )}

            {hasTableData && (
              <Typography ml="8px" variant="caption" color="text.secondary">
                {t('previewCatalogPage.sharedCatalogsTableHint')}
              </Typography>
            )}

            {hasTableData || loading ? (
              <Box margin="8px 56px 24px 10px">
                <SharedCatalogsTable
                  loadingCatalogs={loading}
                  handleSort={handleSort}
                  sortData={sortData}
                  tableData={data?.getCatalogs}
                  onCatalogsDataClear={() => setHasMore(true)}
                />
              </Box>
            ) : (
              <NoSharedCataloguesWrapper>
                {keywords.length ? (
                  <NoSharedCataloguesIcon />
                ) : debouncedSearchValue ? (
                  <NoNameSharedCatalogues />
                ) : (
                  <NoSharedCataloguesIcon />
                )}
                <Typography
                  variant="body1"
                  color="text.secondary"
                  mt="34px"
                  maxWidth={keywords.length ? '510px' : debouncedSearchValue ? '420px' : '200px'}
                  textAlign="center"
                >
                  {t(
                    `settingsPage.sharedCataloguesTable.${
                      keywords.length
                        ? 'noCatalogsWithSelectedKeywords'
                        : debouncedSearchValue
                        ? 'sharedCataloguesNotFound'
                        : 'noSharedCatalogues'
                    }`,
                  )}
                </Typography>
              </NoSharedCataloguesWrapper>
            )}
          </Scroll>
          <ButtonScrollToTop intersection={document.documentElement.clientHeight} />
        </Box>
      </Box>
    </Page>
  );
};

export default SharedCatalogs;
