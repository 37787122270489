import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Link } from '@material-ui/core';

import Button from 'src/components/Button';
import useOnboarding from 'src/providers/OnboardingProvider/hooks/useOnboarding';
import { UploadingFile } from 'src/views/Catalogs/ProductItems/types';

import SmallDropzone from '../Dropzone/SmallDropzone';

interface UploadCsvMultipleProps {
  setUploadCSVs: (state: UploadingFile[]) => void;
}

const UploadCsvMultiple = ({ setUploadCSVs }: UploadCsvMultipleProps) => {
  const { t } = useTranslation();
  const {
    onboardingState: { tourActive },
    setOnboardingState,
    goNext,
    getUpdatedCsvSteps,
  } = useOnboarding();

  const handleDownloadButtonClick = () => {
    if (tourActive) {
      let hasDownloadExampleStep = false;

      setOnboardingState((prevState) => {
        hasDownloadExampleStep = prevState.steps.some(({ title }) => title === t('onboarding.downloadExample.title'));

        return hasDownloadExampleStep ? prevState : { ...prevState, steps: getUpdatedCsvSteps() };
      });

      if (!hasDownloadExampleStep) {
        setTimeout(() => {
          goNext();
        }, 0);
      }
    }
  };

  return (
    <>
      <SmallDropzone setUploadCSVs={setUploadCSVs} />

      <Box display="flex" alignItems="center" mt="40px">
        <Link href="/files/Otomate_Collection.csv" onClick={handleDownloadButtonClick} underline="none">
          <Button variant="outlined">{t('catalogueCreationPage.thirdStep.csvFile.downloadExampleButton')}</Button>
        </Link>
      </Box>
    </>
  );
};

export default UploadCsvMultiple;
