import React, { createRef } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { Box, makeStyles, Theme, Typography, Link } from '@material-ui/core';

import { AVAILABLE_FORMATS, UploadMediaProps } from 'src/components/MediaGallery/constants';
import useUploadMediaBrowseUrl from 'src/hooks/useUploadMediaBrowseUrl';

const useStyles = makeStyles<Theme>((theme) => ({
  uploadMedia: {
    fontWeight: 500,
    fontSize: '15px',
    lineHeight: '18px',
    color: theme.palette.text.disabled,
  },
  addMediaButton: {
    color: theme.palette.secondary.dark,
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const UploadMedia = ({ setUpload }: UploadMediaProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { handleUploadMediaBrowse } = useUploadMediaBrowseUrl(setUpload);

  const inputFile = createRef<HTMLInputElement>();

  return (
    <Box>
      <input
        type="file"
        hidden
        multiple
        ref={inputFile}
        accept={AVAILABLE_FORMATS.join(', ')}
        onChange={handleUploadMediaBrowse}
      />
      <Box>
        <Typography className={classes.uploadMedia} component="span">
          {t('productItemCreateEdit.mediaGallery.mediaContainer.drag')}{' '}
          <Trans i18nKey="productItemCreateEdit.mediaGallery.mediaContainer.browse">
            or
            <Link
              className={classes.addMediaButton}
              onClick={() => inputFile.current?.click()}
              data-testid="browseButton"
            >
              browse
            </Link>
            to upload media
          </Trans>
        </Typography>
      </Box>
    </Box>
  );
};

export default UploadMedia;
