import React from 'react';

import { Box, Skeleton } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { keyframes } from '@mui/system';

const opacity = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Container = styled(Box)({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: '20px 25px',
  borderRadius: 20,
  width: 392,
  height: 109,
  margin: '0 24px 24px 0',
  boxShadow: '0px 2px 10px rgba(46, 96, 170, 0.25)',
  animation: `${opacity} 1s ease`,
});

interface SkeletonPaymentMethodCardProps {
  cardsCount: number;
}

const SkeletonPaymentMethodCard = ({ cardsCount }: SkeletonPaymentMethodCardProps) => (
  <>
    {new Array(cardsCount).fill('card').map((_, idx) => (
      <Container key={idx}>
        <Box display="flex">
          <Box mr="15px">
            <Skeleton variant="circular" width="30px" height="30px" />
          </Box>

          <Skeleton variant="text" width="200px" height="30px" />
        </Box>

        <Skeleton variant="text" width="200px" height="30px" />
      </Container>
    ))}
  </>
);

export default SkeletonPaymentMethodCard;
