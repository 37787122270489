import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, useTheme, Collapse } from '@material-ui/core';

import { ReactComponent as EbayIcon } from 'src/components/Icon/ebay.svg';
import { ReactComponent as FacebookAndInstagramIcon } from 'src/components/Icon/facebook-and-instagram.svg';
import { ReactComponent as ShopifyIcon } from 'src/components/Icon/shopify.svg';
import { ReactComponent as WooCommerceIcon } from 'src/components/Icon/woo-commerce.svg';
import { IntegrationProductTypeField, IntegrationTypes } from 'src/utils/gql';

import Iconography from '../Iconography';

import {
  FieldText,
  HeaderTitle,
  IconContainer,
  Divider,
  StatusWrapper,
  StatusContent,
  Percentage,
  ExpandButton,
} from './styled';

interface CompletenessBodyProps {
  type: IntegrationTypes;
  completedFields: IntegrationProductTypeField[];
  inCompletedFields: IntegrationProductTypeField[];
  percentage: number;
  isInSidebar?: boolean;
}

export const CompletenessBody = ({
  type,
  completedFields,
  inCompletedFields,
  percentage,
  isInSidebar,
}: CompletenessBodyProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { t } = useTranslation();
  const theme = useTheme();

  const shouldBeExpanded = useMemo(() => {
    return isInSidebar ? isExpanded : true;
  }, [isInSidebar, isExpanded]);

  const margin = useMemo(() => {
    return isInSidebar ? '30px' : '0';
  }, [isInSidebar]);

  const getIcon = (type: IntegrationTypes) => {
    switch (type) {
      case IntegrationTypes.Shopify:
        return <ShopifyIcon />;
      case IntegrationTypes.Woocommerce:
        return <WooCommerceIcon />;
      case IntegrationTypes.Ebay:
        return <EbayIcon />;
      case IntegrationTypes.Facebook:
        return <FacebookAndInstagramIcon />;
    }
  };

  return (
    <Box display="flex" flexDirection="column" width="100%" boxSizing="border-box" maxWidth="482px">
      <Box display="flex" alignItems="center" justifyContent="space-between" mt="20px">
        <Box display="flex" alignItems="center">
          {isInSidebar && (
            <IconContainer>
              <ExpandButton
                data-testid={`${type}CompletenessDropdownButton`}
                onClick={() => setIsExpanded((prev) => !prev)}
                className={isExpanded ? 'expanded' : ''}
              >
                <Iconography iconName="expand-chevron" htmlColor={theme.palette.primary.light} />
              </ExpandButton>
            </IconContainer>
          )}
          <IconContainer>{getIcon(type)}</IconContainer>
          <HeaderTitle data-testid={`${type}CompletenessBodyTitle`}>{t(`integrationNames.${type}`)}</HeaderTitle>
        </Box>
        <Percentage
          data-testid={`${type}PercentageCompletenessBody`}
          className={percentage >= 100 ? 'finished' : ''}
          mr={margin}
        >
          {percentage}%
        </Percentage>
      </Box>
      <StatusWrapper mt="12px" mb="20px" ml={margin} mr={margin}>
        <StatusContent width={percentage} />
      </StatusWrapper>
      <Collapse in={shouldBeExpanded}>
        {inCompletedFields.map(({ id }) => (
          <Box
            key={id}
            display="flex"
            alignItems="flex-start"
            justifyContent="flex-start"
            mb="12px"
            ml={margin}
            mr={margin}
          >
            <Iconography iconName="cancel" htmlColor={theme.palette.text.disabled} />
            <FieldText>{t(`${type}.${id}`)}</FieldText>
          </Box>
        ))}
        {!!inCompletedFields.length && !!completedFields.length && <Divider ml={margin} mr={margin} />}
        {completedFields.map(({ id }) => (
          <Box
            key={id}
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            mb="12px"
            ml={margin}
            mr={margin}
          >
            <Iconography iconName="check-outline" htmlColor={theme.palette.info.main} />
            <FieldText>{t(`${type}.${id}`)}</FieldText>
          </Box>
        ))}
      </Collapse>
    </Box>
  );
};
