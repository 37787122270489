import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import MuiButton from '@material-ui/core/Button';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { Box, Tooltip, Typography } from '@mui/material';

import { IntegrationMappingError } from 'src/utils/gql';

import useDuplicatedSKUFromIntegrationMappingErrors from '../hooks/useDuplicatedSKUFromIntegrationMappingErrors';

import DuplicatedSKUMessage, { DuplicatedSKUMessageType } from './DuplicatedSKUMessage';
import Iconography from './Iconography';
import Loader from './Loader';

const useStyles = makeStyles<Theme, { fillColor: string; disabled?: boolean }>((theme) => ({
  root: {
    display: 'flex',
    cursor: ({ disabled }) => (disabled ? 'default' : 'pointer'),
    alignItems: 'center',

    '& .MuiButton-root': {
      padding: 0,
      boxShadow: 'none',
      background: 'inherit',
    },

    '& svg': {
      color: ({ fillColor }: { fillColor: string }) => fillColor,
    },
  },
  text: {
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '18px',
    color: ({ fillColor }) => fillColor,
    marginRight: '8px',
  },
  tooltip: {
    maxWidth: '230px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '400',
    boxSizing: 'border-box',
    backgroundColor: theme.palette.common.white,
    fontSize: '12px',
    lineHeight: '14px',
    color: theme.palette.text.primary,
    borderRadius: '5px',
    boxShadow: '0 2px 10px rgba(46, 96, 170, 0.25)',
    padding: '10px 20px',
  },
  arrow: {
    color: theme.palette.common.white,
  },
}));

interface MappingsButtonProps {
  onClick?: () => void;
  testId: string;
  isEmpty?: boolean;
  disabled?: boolean;
  loading?: boolean;
  errors?: IntegrationMappingError[];
}

const MappingsButton = ({ onClick, testId, disabled, loading, errors, isEmpty }: MappingsButtonProps) => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const theme = useTheme();

  const fillColor = disabled
    ? theme.palette.text.disabled
    : loading
    ? theme.palette.secondary.dark
    : errors?.length
    ? theme.palette.error.main
    : theme.palette.secondary.dark;

  const classes = useStyles({ fillColor, disabled });

  const tooltipTitle = disabled ? t('errorAboutEmptyCatalog') : errors?.[0]?.description;
  const duplicatedSKUInfo = useDuplicatedSKUFromIntegrationMappingErrors(errors);

  const title = isEmpty ? (
    t('settingsPage.attachedIntegrations.hintAtAnEmptyCatalog')
  ) : duplicatedSKUInfo.isDuplicated ? (
    <DuplicatedSKUMessage type={DuplicatedSKUMessageType.sync} catalogId={id} duplicatedSKUInfo={duplicatedSKUInfo} />
  ) : (
    tooltipTitle ?? ''
  );

  return (
    <Tooltip title={title} placement="left" classes={{ tooltip: classes.tooltip, arrow: classes.arrow }} arrow>
      <Box className={classes.root}>
        <MuiButton data-testid={testId} onClick={onClick} disabled={disabled}>
          <Typography variant="h2" className={classes.text}>
            {t('mappings')}
          </Typography>
          {loading ? (
            <Loader size="extraSmallIntegration" />
          ) : (
            <Iconography iconName={errors?.length && !isEmpty ? 'error-circle' : 'mappings'} />
          )}
        </MuiButton>
      </Box>
    </Tooltip>
  );
};

export default MappingsButton;
