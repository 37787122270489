import React, { useCallback } from 'react';

import { Box } from '@material-ui/core';

import ThreeStateToggle, { ToggleValue } from 'src/components/Toggle/ThreeStateToggle';
import { BOOLEAN_FIELD_SELECT_OPTIONS } from 'src/constants';

interface Props {
  values: string[];
  onChange: (values: string[]) => void;
}

enum BooleanValues {
  TRUE = 'true',
  FALSE = 'false',
}

const BooleanFilter = ({ values, onChange }: Props) => {
  let toggleValue = ToggleValue.DEFAULT;

  if (values.length) {
    switch (values[0]) {
      case BooleanValues.TRUE:
        toggleValue = ToggleValue.LEFT;
        break;
      case BooleanValues.FALSE:
        toggleValue = ToggleValue.RIGHT;
        break;
    }
  }

  const handleChange = useCallback(
    (value: ToggleValue) => {
      const selectedValue = value ? [BOOLEAN_FIELD_SELECT_OPTIONS[value - 1].value] : [];

      onChange(selectedValue);
    },
    [onChange],
  );

  return (
    <Box>
      <ThreeStateToggle value={toggleValue} onChange={handleChange} />
    </Box>
  );
};

export default BooleanFilter;
