import React from 'react';

import { Switch, SwitchProps } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';

const Toggle = styled((props: SwitchProps) => <Switch disableRipple {...props} />)(({ theme }) => ({
  width: 60,
  height: 28,
  padding: 0,
  overflow: 'unset',
  '& .MuiSwitch-switchBase': {
    padding: 4,
    '&.Mui-checked': {
      color: '#fff',
      transform: `translateX(32px)`,
      '& + .MuiSwitch-track': {
        background: theme.palette.secondary.contrastText,
        opacity: 1,
      },
      '& .MuiSwitch-thumb': {
        background: theme.palette.background.default,
      },
    },
    '&.Mui-disabled': {
      '& + .MuiSwitch-track': {
        background: theme.palette.background.default,
        opacity: 1,
      },
      '& .MuiSwitch-thumb': {
        background: theme.palette.common.white,
        boxShadow: `0px 1px 5px rgba(187, 201, 223, 0.5)`,
      },
    },
    '&:hover': {
      background: 'none',
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 20,
    height: 20,
  },
  '& .MuiSwitch-track': {
    borderRadius: 20,
    backgroundColor: theme.palette.text.disabled,
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
    boxSizing: 'border-box',
  },
}));

export default Toggle;
