import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

import { StyledList } from 'src/components/Forms/styled';
import { PasswordInputRequirementsProps } from 'src/components/Forms/types';
import {
  MISSED_CAPITAL_LETTER,
  MISSED_CORRECT_LENGTH,
  MISSED_NUMBER,
  MISSED_SPECIAL_SYMBOL,
  passwordValidator,
} from 'src/utils/passwordValidator';

function PasswordRequirements({ password, isTouched }: PasswordInputRequirementsProps) {
  const { t } = useTranslation();
  const checkResult = passwordValidator(password);

  const renderIcon = (positive: boolean) => {
    return (
      <ListItemIcon>
        {positive ? (
          <Box display="flex" justifyContent="center" alignItems="center" color="success.main">
            <CheckIcon />
          </Box>
        ) : (
          <Box display="flex" justifyContent="center" alignItems="center" color="error.main">
            <CloseIcon />
          </Box>
        )}
      </ListItemIcon>
    );
  };

  return (
    <StyledList>
      <ListItem>
        {isTouched && renderIcon(!(checkResult & MISSED_CORRECT_LENGTH))}
        <Typography variant="caption" color="textSecondary">
          {t('passwordValidationErrors.minLength')}
        </Typography>
      </ListItem>
      <ListItem>
        {isTouched && renderIcon(!(checkResult & MISSED_CAPITAL_LETTER))}
        <Typography variant="caption" color="textSecondary">
          {t('passwordValidationErrors.uppercase')}
        </Typography>
      </ListItem>
      <ListItem>
        {isTouched && renderIcon(!(checkResult & MISSED_NUMBER))}
        <Typography variant="caption" color="textSecondary">
          {t('passwordValidationErrors.number')}
        </Typography>
      </ListItem>
      <ListItem>
        {isTouched && renderIcon(!(checkResult & MISSED_SPECIAL_SYMBOL))}
        <Typography variant="caption" color="textSecondary">
          {t('passwordValidationErrors.specialSymbol')}
        </Typography>
      </ListItem>
    </StyledList>
  );
}

export default React.memo(PasswordRequirements);
