import React, { ReactNode, useLayoutEffect, useState } from 'react';

import { Box, Fade, FormControl, makeStyles, MenuItem, MenuList, Theme, Typography } from '@material-ui/core';
import Pagination from '@mui/material/Pagination';

import { ITEMS_PER_PAGE_MENU_LIST } from 'src/constants';

import Iconography from './Iconography';

interface MenuProps {
  onItemsPerPageClick: (event: React.MouseEvent<HTMLElement>) => void;
  itemsPerPage: number;
}

const menuStyles = makeStyles<Theme, { displayMenu: boolean }>((theme) => ({
  menuList: {
    minHeight: 'unset',
    '& .MuiList-root': {
      width: '150px',
      right: 0,
      position: 'absolute',
      borderRadius: '4px',
      border: `1px solid ${theme.palette.secondary.main}`,
      background: theme.palette.common.white,
      padding: '5px',
      zIndex: 2,
      display: ({ displayMenu }) => (displayMenu ? 'block' : 'none'),
      transform: 'translateY(-105%)',
    },

    '& .MuiListItem-root': {
      borderRadius: '5px',
      fontSize: '15px',
      lineHeight: '18px',
      height: '38px',

      '&:hover': {
        background: theme.palette.secondary.dark,
        color: theme.palette.common.white,
      },
    },
  },
  menu: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  mainBlockMenu: {
    cursor: 'pointer',
    height: '24px',
    fontSize: '15px',
  },
  arrow: {
    cursor: 'pointer',
  },
}));

const Menu = ({ itemsPerPage, onItemsPerPageClick }: MenuProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  const classes = menuStyles({ displayMenu: isMenuOpen });

  useLayoutEffect(() => {
    setIsMenuOpen(false);
  }, [itemsPerPage]);

  return (
    <FormControl className={classes.menuList}>
      <Box onClick={toggleMenu} role="button" className={classes.menu} data-testid="paginationMenuButton">
        <Typography className={classes.mainBlockMenu}>show {itemsPerPage}</Typography>
        <Iconography
          iconName="expand-chevron"
          sx={{ transform: isMenuOpen ? 'rotate(180deg)' : 'none', transition: '.2s' }}
          className={classes.arrow}
        />
      </Box>
      <Fade in={isMenuOpen}>
        <MenuList data-testid="menuList">
          {ITEMS_PER_PAGE_MENU_LIST.map((item) => (
            <MenuItem key={item} onClick={onItemsPerPageClick} data-testid={`menuItem${item}`}>
              {item}
            </MenuItem>
          ))}
        </MenuList>
      </Fade>
    </FormControl>
  );
};

const useStyles = makeStyles<Theme>((theme) => ({
  paginationControls: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    padding: '16px 0',

    '& .MuiPaginationItem-page, & .MuiPaginationItem-previousNext': {
      '&:hover': {
        background: theme.palette.info.main,
        color: theme.palette.common.white,
      },
    },

    '& .MuiPaginationItem-root.Mui-disabled': {
      opacity: '0.5',
    },
  },
  menuWrapper: {
    position: 'absolute',
    right: '0',
    top: '50%',
    transform: 'translateY(-50%)',
  },
}));

interface PaginationProps {
  pagesLength: number;
  onPageClick: (event: React.ChangeEvent<unknown>, value: number) => void;
  children: ReactNode;
  page: number;
  onItemsPerPageClick?: (event: React.MouseEvent<HTMLElement>) => void;
  itemsPerPage?: number;
}

const PaginationContainer = (props: PaginationProps) => {
  const classes = useStyles();
  const { pagesLength, onPageClick, children, onItemsPerPageClick, page, itemsPerPage } = props;

  return (
    <div>
      {children}
      <div className={classes.paginationControls}>
        <div>
          <Pagination page={page} count={pagesLength} onChange={onPageClick}></Pagination>
        </div>
        {itemsPerPage && onItemsPerPageClick && (
          <div className={classes.menuWrapper}>
            <Menu itemsPerPage={itemsPerPage} onItemsPerPageClick={onItemsPerPageClick} />
          </div>
        )}
      </div>
    </div>
  );
};

export default PaginationContainer;
