import React, { FunctionComponent, ReactNode, SVGProps, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Box, CircularProgress, IconButton, Typography } from '@mui/material';
import moment from 'moment';

import { arrayToMap } from 'src/utils/general';
import {
  IntegrationMappingError,
  Integration,
  IntegrationProductTypeField,
  IntegrationTypes,
  Maybe,
  ProductType,
  Scalars,
  Reason,
} from 'src/utils/gql';
import { getFieldMappingKey } from 'src/views/Catalogs/ProductTypes/ProductTypeSettings/MappingTabContent/IntegrationInfo';
import {
  getValueAccordingToIntegrationType,
  ValueAccordingToIntegrationType,
} from 'src/views/Catalogs/ProductTypes/ProductTypeSettings/MappingTabContent/utils';

import BetaLabel from '../BetaLabel';
import DuplicatedSKUMessage, { DuplicatedSKUMessageType } from '../DuplicatedSKUMessage';
import ErrorableSyncButton from '../ErrorableSyncButton';
import Hint from '../Hint';
import Iconography from '../Iconography';
import IntegrationUnavailableMessage from '../IntegrationUnavailableMessage';
import DiscardChangesPopup from '../Popups/DiscardChangesPopup';
import { PRODUCT_ITEM_MAPPING_EXTERNAL_PRODUCT_404 } from '../SideBar/MappingSidebar/ItemMapping/IntegrationInfo';

import {
  AddMappingButton,
  IntegrationInfoHeader,
  IntegrationInfoWrapper,
  IntegrationName,
  LastSync,
  MappingErrorLink,
} from './styled';

interface IntegrationInfoContainerProps {
  children: ReactNode;
  integrationType: IntegrationTypes;
  title: string;
  isOpen: boolean;
  showLastSyncButton?: boolean;
  syncItemsHintText?: string;
  onClick: () => void;
  onSyncButtonClick?: () => void;
  isIntegrationUpdated?: boolean;
  showSaveButton?: boolean;
  hasHover?: boolean;
  isExpanded?: boolean;
  showLoader?: boolean;
  showAddMappingButton?: boolean;
  integrationProductTypesLoading?: boolean;
  showMappingErrorLink?: boolean;
  isExportInProgress?: boolean;
  lastSync?: Maybe<Scalars['DateTime']>;
  itemMappingErrorMessage?: Maybe<string>;
  lastSyncErrorMessage?: string;
  onSaveChanges?: () => void;
  onAddMappingButtonClick?: () => void;
  integration?: Integration;
  productType?: ProductType;
  mustDisableSyncButton?: boolean;
  checkEmptyField?: boolean;
  doNotUseDiscardChanges?: boolean;
  requiredIntegrationFields: IntegrationProductTypeField[];
  integrationProductItemMappingErrors?: IntegrationMappingError[];
  haveEmptyFields?: boolean;
  isInItemMappings?: boolean;
  syncable?: boolean;
  handleFormStateChange?: (integrationId: string, isChanged: boolean) => void;
}

const IntegrationInfoContainer = ({
  syncable,
  children,
  title,
  integrationType,
  isOpen,
  showLastSyncButton,
  lastSync,
  syncItemsHintText,
  onClick,
  onSyncButtonClick,
  hasHover = true,
  isExpanded = true,
  showLoader = false,
  showSaveButton = false,
  isExportInProgress = false,
  showAddMappingButton = false,
  showMappingErrorLink = false,
  integrationProductTypesLoading = false,
  itemMappingErrorMessage,
  lastSyncErrorMessage,
  onSaveChanges,
  onAddMappingButtonClick,
  integration,
  productType,
  mustDisableSyncButton,
  checkEmptyField,
  doNotUseDiscardChanges,
  requiredIntegrationFields,
  integrationProductItemMappingErrors,
  haveEmptyFields = false,
  isInItemMappings = false,
  handleFormStateChange,
}: IntegrationInfoContainerProps) => {
  const { t } = useTranslation();
  const { id: catalogId } = useParams<{ id: string }>();

  const emptyProductTypeTitle = t('settingsPage.attachedIntegrations.hintAtAnEmptyProductType');

  const emptyProductType = productType?.isEmpty;
  const duplicatedSKU = productType?.duplicatedSKUInfo.isDuplicated;
  const unsyncable = !(syncable === undefined || syncable);

  const IntegrationIcon = getValueAccordingToIntegrationType(
    integrationType,
    ValueAccordingToIntegrationType.Icon,
  ) as FunctionComponent<SVGProps<SVGSVGElement>>;

  const {
    formState: { isSubmitting, dirtyFields, errors },
    watch,
    getValues,
  } = useFormContext();

  const returnUsedFields = (nonCheckingMappingNumber: number): { usedIntegrationFieldsIds: string[] } => {
    const fieldMapping = watch(`${getFieldMappingKey(nonCheckingMappingNumber)}` as const);

    if (fieldMapping) {
      const { usedIntegrationFieldsIds } = returnUsedFields(nonCheckingMappingNumber + 1);

      usedIntegrationFieldsIds.push(fieldMapping.externalFieldId);
      return { usedIntegrationFieldsIds };
    }

    return { usedIntegrationFieldsIds: [] };
  };

  const { usedIntegrationFieldsIds } = returnUsedFields(0);

  const unMappedRequiredFields = useMemo(() => {
    if (isInItemMappings && integration?.id) {
      const integrationMappings = productType?.fieldsMappings?.filter(
        ({ integrationId }) => integrationId == integration.id,
      );

      if (integrationMappings?.length) {
        const integrationMappingsMap = arrayToMap(integrationMappings, 'metadataExternalId');

        return requiredIntegrationFields.reduce((acc: IntegrationProductTypeField[], field) => {
          const otomateField = integrationMappingsMap[field.id];
          if (!otomateField) {
            acc.push(field);
          }
          return acc;
        }, []);
      }
      return [];
    }
    return requiredIntegrationFields.reduce((acc: IntegrationProductTypeField[], field) => {
      const otomateField = usedIntegrationFieldsIds.find((id) => id === field.id);
      if (!otomateField) {
        acc.push(field);
      }
      return acc;
    }, []);
  }, [
    requiredIntegrationFields,
    usedIntegrationFieldsIds,
    isInItemMappings,
    integration?.id,
    productType?.fieldsMappings,
  ]);

  const integrationProductTypeMappingErrors = useMemo(
    () => productType?.mappingsErrors.filter(({ integrationId }) => integrationId === integration?.id),
    [productType, integration],
  );

  const renderSyncDate = (): string | null => {
    if (lastSync) {
      const isJustNow = moment().isBetween(moment(lastSync), moment(lastSync).add(1, 'minute'));

      return isJustNow
        ? t('productType.mapping.syncedNow')
        : `${t('productType.mapping.synced')} ${moment(lastSync).format('DD.MM.YYYY')}`;
    }

    return null;
  };

  const dirtyKeys = Object.keys(dirtyFields);
  const formDirty = !!dirtyKeys.length;
  const hasErrors = !!Object.keys(errors).length;

  const disableSaveButtonInEbayCornerCases =
    integrationType === IntegrationTypes.Ebay &&
    dirtyKeys.length === 1 &&
    dirtyKeys[0] === 'marketplaceId' &&
    !watch('externalProductTypeId');

  const disableSaveButtonForEbayRequiredFieldsUnfilled =
    integrationType === IntegrationTypes.Ebay && !!unMappedRequiredFields.length;

  const hasEmptyField = Object.values(getValues()).find(
    (field) => field !== null && typeof field !== 'string' && field?.externalFieldId === undefined,
  );

  const disableSaveButton =
    !formDirty || hasErrors || disableSaveButtonInEbayCornerCases || Boolean(checkEmptyField && hasEmptyField);

  const shouldShowLoader = showLoader || isSubmitting;
  const shouldShowSaveButton = showSaveButton && !isSubmitting;
  const showWoocommerceMappingErrorLink =
    showMappingErrorLink && integration?.type === IntegrationTypes.Woocommerce && integration.settings?.url;

  const hintTitle = (syncItemsHintText: string) => {
    if (emptyProductType) {
      return emptyProductTypeTitle;
    } else if (formDirty) {
      return String(t('productType.mapping.shouldBeSaved'));
    } else if (mustDisableSyncButton) {
      return String(t('productType.mapping.notSelected'));
    } else if (disableSaveButtonForEbayRequiredFieldsUnfilled) {
      return String(t('productType.mapping.requiredFieldsMappingsLack'));
    } else if (integrationProductTypeMappingErrors?.length) {
      return String(t(`productType.mapping.${integrationProductTypeMappingErrors[0].reason}`));
    } else if (haveEmptyFields) {
      return String(t('productType.mapping.haveEmptyFields'));
    }

    return syncItemsHintText;
  };

  const disabledSyncButton =
    formDirty ||
    emptyProductType ||
    mustDisableSyncButton ||
    isSubmitting ||
    disableSaveButtonForEbayRequiredFieldsUnfilled ||
    duplicatedSKU ||
    unsyncable ||
    haveEmptyFields;

  const titleTooltipEmptyField = t('productType.mapping.errorWhenEmptyField');

  const isDisabledDueToErrors = useMemo(() => {
    return !!integrationProductTypeMappingErrors?.find(({ syncable }) => syncable === false);
  }, [integrationProductTypeMappingErrors]);
  const integrationUnavailableErrors = useMemo(
    () => integration?.mappingsErrors?.filter(({ reason }) => reason === Reason.IntegrationUnavailable),
    [integration],
  );
  const errorableSyncButtonErrors = [
    ...(integrationProductItemMappingErrors || []),
    ...(integrationProductTypeMappingErrors || []),
    ...(integrationUnavailableErrors || []),
  ];
  const errorableSyncButtonTooltipWidth = duplicatedSKU
    ? '231px'
    : integrationUnavailableErrors?.length
    ? '240px'
    : '305px';

  useEffect(() => {
    if (integration && handleFormStateChange) {
      handleFormStateChange(integration.id, formDirty);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formDirty, integration, integration?.id]);

  return (
    <IntegrationInfoWrapper>
      <IntegrationInfoHeader hover={hasHover}>
        <Box display="flex" minWidth="0px">
          <Box id="integration-name" onClick={onClick} data-testid={`expand_${integrationType}_integration_button`}>
            {isExpanded ? (
              <Iconography
                iconName={isOpen ? 'expand-chevron-up' : 'expand-chevron'}
                color="primary"
                data-testid="expandIcon"
              />
            ) : (
              <Box width="24px" />
            )}

            {<IntegrationIcon data-testid={`integrationIcon-${integrationType}`} />}

            <IntegrationName>{title}</IntegrationName>

            {integrationType === IntegrationTypes.Ebay && <BetaLabel />}

            {itemMappingErrorMessage && itemMappingErrorMessage !== PRODUCT_ITEM_MAPPING_EXTERNAL_PRODUCT_404 && (
              <Box ml="10px" display="flex">
                <Iconography iconName="cancel-circle" color="error" data-testid="errorIcon" />
              </Box>
            )}

            {shouldShowLoader && <CircularProgress size={18} color="info" data-testid="loaderMapping" />}
          </Box>

          {shouldShowSaveButton && (
            <Hint
              type="hover"
              title={`${t('productType.mapping.saveChanges')}`}
              placement="right"
              doNotShow={!formDirty}
            >
              <span>
                <IconButton
                  onClick={onSaveChanges}
                  id="save-button"
                  disabled={disableSaveButton}
                  data-testid={`save_changes_button_${integrationType}_integration`}
                >
                  <Iconography iconName="save" />
                </IconButton>
              </span>
            </Hint>
          )}
        </Box>
        <Box display="flex" alignItems="center">
          {showAddMappingButton && (
            <AddMappingButton
              onClick={onAddMappingButtonClick}
              data-testid={`add_mapping_button_${integrationType}_integration`}
            >
              <Iconography iconName="add" />

              <Typography variant="subtitle2">{t('productType.mapping.addMapProduct')}</Typography>
            </AddMappingButton>
          )}

          {integrationProductTypesLoading && (
            <Typography variant="subtitle2" color="text.secondary" sx={{ opacity: 0.3 }}>
              {t('productType.mapping.mappingProgress')}
            </Typography>
          )}

          {showWoocommerceMappingErrorLink && (
            <MappingErrorLink
              variant="subtitle2"
              href={`${integration?.settings.url}/wp-admin/edit-tags.php?taxonomy=product_cat&post_type=product`}
            >
              {t('productType.mapping.mappingErrorLink')}
            </MappingErrorLink>
          )}

          {lastSyncErrorMessage && (
            <Box width="100%">
              <Typography variant="body2" fontSize="15px" textAlign="right" mr="17px">
                {lastSyncErrorMessage}
              </Typography>
            </Box>
          )}

          {isExportInProgress ? (
            <Typography
              variant="caption"
              color="text.secondary"
              fontWeight={500}
              fontSize={15}
              sx={{ opacity: 0.3 }}
              data-testid={`exportProgressText${title}`}
            >
              {t('settingsPage.attachedIntegrations.exportProgress')}
            </Typography>
          ) : (
            showLastSyncButton && (
              <LastSync>
                {!lastSyncErrorMessage && <Typography variant="subtitle2">{renderSyncDate()}</Typography>}

                {syncItemsHintText && (
                  <ErrorableSyncButton
                    catalogId={catalogId}
                    errors={errorableSyncButtonErrors}
                    onClick={onSyncButtonClick}
                    tooltipParams={{
                      customTooltipContent:
                        hasEmptyField && checkEmptyField ? (
                          titleTooltipEmptyField
                        ) : duplicatedSKU ? (
                          <DuplicatedSKUMessage
                            type={DuplicatedSKUMessageType.basic}
                            catalogId={catalogId}
                            duplicatedSKUInfo={productType?.duplicatedSKUInfo}
                          />
                        ) : integrationUnavailableErrors?.length ? (
                          <IntegrationUnavailableMessage
                            integrations={[integration as Integration]}
                            integrationUnavailableErrors={integrationUnavailableErrors}
                            onTryAgainButtonClick={onSyncButtonClick}
                          />
                        ) : hintTitle(syncItemsHintText) !== t('productType.mapping.duplicatedSku') ? (
                          hintTitle(syncItemsHintText)
                        ) : null,
                    }}
                    disabled={disabledSyncButton}
                    dataTestId={`sync_button_${integrationType}_integration`}
                    tooltipWidth={errorableSyncButtonTooltipWidth}
                    isDisabledDueToErrors={isDisabledDueToErrors}
                  />
                )}
              </LastSync>
            )
          )}
        </Box>
      </IntegrationInfoHeader>

      {children}

      {doNotUseDiscardChanges ? null : <DiscardChangesPopup isOpen={formDirty} />}
    </IntegrationInfoWrapper>
  );
};

export default IntegrationInfoContainer;
