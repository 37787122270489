import React, { createRef, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@material-ui/core';

import { FILE_EXTENSION } from 'src/constants';
import { ImagesStateContext } from 'src/views/Catalogs/ProductItems/context';
import { handleUploadImage } from 'src/views/Catalogs/ProductItems/functions';

import { ImageCard, ImageCardFromDatabase } from '../Card/ImageCard';
import Iconography from '../Iconography';

import { AddMoreButton, AddMoreButtonTitle } from './styled';

const UploadImageMultiple = () => {
  const { t } = useTranslation();
  const { imagesState, setImagesState, filesUploadState } = useContext(ImagesStateContext);
  const inputFile = createRef<HTMLInputElement>();

  const imagesWithPercentage = useMemo(
    () =>
      imagesState.imagesForPreview.map((file) => ({
        ...file,
        ...Object.values(filesUploadState).find(({ name }) => file.name === name),
      })),
    [imagesState.imagesForPreview, filesUploadState],
  );

  return (
    <Box mb="22px">
      <Box width="120px" mb="15px">
        <input
          style={{ display: 'none' }}
          value=""
          type="file"
          accept={FILE_EXTENSION.image}
          multiple
          onChange={(event) => handleUploadImage(event, setImagesState)}
          ref={inputFile}
        />
        <AddMoreButton onClick={() => inputFile.current?.click()} type="button">
          <Iconography iconName="add" fontSize="small" />

          <AddMoreButtonTitle>{t('productItemCreateEdit.sidebar.addPhotos')}</AddMoreButtonTitle>
        </AddMoreButton>
      </Box>

      <Box display="flex" flexWrap="wrap">
        {imagesState.imagesUrlsFromDatabase?.map((url, idx) => (
          <ImageCardFromDatabase key={idx} imageUrl={url} />
        ))}
        {imagesWithPercentage.map((image, idx) => (
          <ImageCard key={idx} image={image} />
        ))}
      </Box>
    </Box>
  );
};

export default UploadImageMultiple;
