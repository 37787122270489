import { SyntheticEvent } from 'react';

import { OrderBy, OrderDirection, SubscriptionWithUser } from 'src/utils/gql';
import { SortData } from 'src/views/Catalogs/CatalogSettings';

export interface CatalogConfigTableProps {
  handleSort: (orderBy: OrderBy, orderDirection?: OrderDirection, event?: SyntheticEvent<HTMLButtonElement>) => void;
  sortData: SortData;
  subscriptionsData?: SubscriptionWithUser[];
  catalogName: string;
  loadingSubscriptions?: boolean;
  onFetchMoreTableRows: () => Promise<void>;
  hasMore: boolean;
}

export interface SubscriberProps {
  userName: string;
  avatar?: string;
}

export interface ActionButtonsProps {
  subscriptionId: string;
}

export interface ActionIconsProps {
  status: string;
  subscriptionId: string;
  userName: string;
  catalogName: string;
}

export interface SubscriptionStatusProps {
  status: string;
  classes: { [x: string]: string };
}

export enum StatusType {
  pending = 'pending',
  approved = 'approved',
  declined = 'declined',
}

export enum StatusColor {
  pending = 'yellow',
  approved = 'green',
  declined = 'red',
}
