import { useEffect, useState } from 'react';

import { ApolloError, ApolloQueryResult, useMutation } from '@apollo/client';

import { useSnackbar } from '../providers/snackbar';
import { clearCacheFactory } from '../utils/cacheUtils';
import {
  deleteIntegration,
  Integration,
  Mutation,
  MutationDeleteIntegrationArgs,
  Query,
  QueryGetIntegrationsArgs,
} from '../utils/gql';

export interface HandleEbayIntegrationCreationInterruptionProps {
  takeIdFromLocalStorage?: boolean;
  withRefetchAndCacheUpdating?: boolean;
}

const createdIntegrationIdLocalStorageKey = 'createdIntegrationId';

const useEbayIntegrationCreationInterruption = ({
  withIntegrationsRefetch,
  refetchIntegrations,
}: {
  withIntegrationsRefetch?: boolean;
  refetchIntegrations?: (
    variables?: Partial<QueryGetIntegrationsArgs> | undefined,
  ) => Promise<ApolloQueryResult<Pick<Query, 'getIntegrations'>>>;
}) => {
  const snackbar = useSnackbar();
  const [createdIntegration, setCreatedIntegration] = useState<Integration>();
  const [deleteIntegrationById, { loading: loadingDeleteIntegration }] = useMutation<
    Pick<Mutation, 'deleteIntegration'>,
    MutationDeleteIntegrationArgs
  >(deleteIntegration);

  const handleDeleteIntegration = async ({
    id,
    withRefetchAndCacheUpdating = true,
  }: {
    id: string;
    withRefetchAndCacheUpdating?: boolean;
  }): Promise<void> => {
    try {
      await deleteIntegrationById({
        variables: { id },
        ...(withRefetchAndCacheUpdating && {
          update: clearCacheFactory({ fieldNames: ['getIntegrations', 'checkIsLimitReached'] }),
          refetchQueries: ['planSubscriptionStats'],
        }),
      });

      if (withIntegrationsRefetch && withRefetchAndCacheUpdating) {
        await refetchIntegrations?.();
      }
    } catch (error) {
      const { graphQLErrors, message: errorText } = error as ApolloError;
      const message = graphQLErrors && graphQLErrors.length ? graphQLErrors[0].message : errorText;

      if (error) {
        snackbar(message);
      }
    }
  };

  const handleEbayIntegrationCreationInterruption = async ({
    takeIdFromLocalStorage,
    withRefetchAndCacheUpdating,
  }: HandleEbayIntegrationCreationInterruptionProps): Promise<void> => {
    const id = takeIdFromLocalStorage
      ? localStorage.getItem(createdIntegrationIdLocalStorageKey)
      : createdIntegration?.id;

    if (id) {
      await handleDeleteIntegration({ id, withRefetchAndCacheUpdating }).then(() => {
        setCreatedIntegration(undefined);
        localStorage.removeItem(createdIntegrationIdLocalStorageKey);
      });
    }
  };

  useEffect(() => {
    if (createdIntegration) {
      const handler = () =>
        handleEbayIntegrationCreationInterruption({
          takeIdFromLocalStorage: true,
          withRefetchAndCacheUpdating: false,
        });

      window.addEventListener('beforeunload', handler);

      return () => {
        window.removeEventListener('beforeunload', handler);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdIntegration]);

  return {
    handleEbayIntegrationCreationInterruption,
    loadingDeleteIntegration,
    createdIntegration,
    setCreatedIntegration,
  };
};

export default useEbayIntegrationCreationInterruption;
