import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useLazyQuery, useQuery } from '@apollo/client';
import { Collapse, IconButton, Typography } from '@material-ui/core';
import { Box, Checkbox } from '@mui/material';
import { useFlag } from '@unleash/proxy-client-react';

import Hint from 'src/components/Hint';
import HintLimitButton from 'src/components/HintLimitButton';
import Iconography from 'src/components/Iconography';
import { AddMappingButton } from 'src/components/Mapping/styled';
import Select, { defaultSelectValue, SelectOptionItem } from 'src/components/Select';
import { SkeletonMappingFields } from 'src/components/Skeleton/SkeletonProductMapping';
import { FeatureFlag, SHOPIFY_OPTION_NAME_REGEXP } from 'src/constants';
import {
  FieldMappingSuggestion,
  getFieldMappingSuggestions,
  getProductTypeById,
  Integration,
  IntegrationMappingError,
  IntegrationProductTypeField,
  integrationProductTypeFieldMappings as integrationProductTypeFieldMappingsQuery,
  integrationProductTypeFields,
  IntegrationTypes,
  Maybe,
  ProductTypeFieldIntegrationMapping,
  Query,
  QueryGetFieldMappingSuggestionsArgs,
  QueryGetProductTypeArgs,
  QueryIntegrationProductTypeFieldsArgs,
  QueryProductTypeFieldIntegrationMappingsArgs,
  Reason,
} from 'src/utils/gql';

import { FieldMappingsFormData, getFieldMappingKey } from './IntegrationInfo';
import { FieldsRowItem, MappingFieldsHeader, MappingFieldsWrapper, StyledErrorBox, StyledErrorIcon } from './styled';
import { getValueAccordingToIntegrationType, ValueAccordingToIntegrationType } from './utils';

export enum FieldType {
  Integration,
  Otomate,
}

interface Props {
  integration: Integration;
  externalProductTypeId: string;
  categoryName?: string;
  productTypeId: string;
  fieldMappingKeyPrefix?: string;
  defaultFieldMappings?: ProductTypeFieldIntegrationMapping[];
  arrowSpaceWidth?: number;
  arrowComponent?: React.ReactNode;
  addFieldHeaders?: boolean;
  loadingExternalType?: boolean;
  requiredIntegrationFields: IntegrationProductTypeField[];
}

const MappingFields = ({
  integration,
  externalProductTypeId,
  categoryName,
  productTypeId,
  fieldMappingKeyPrefix,
  defaultFieldMappings,
  arrowSpaceWidth,
  arrowComponent,
  addFieldHeaders,
  requiredIntegrationFields,
  loadingExternalType,
}: Props) => {
  const { t } = useTranslation();
  const isShopifyCustomOptionsMappingsActive = useFlag(FeatureFlag.SHOPIFY_CUSTOM_OPTIONS_MAPPINGS);

  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [suggestions, setSuggestions] = useState<FieldMappingSuggestion[] | undefined>();

  const { data: typeData } = useQuery<Pick<Query, 'getProductType'>, QueryGetProductTypeArgs>(getProductTypeById, {
    fetchPolicy: 'cache-first',
    variables: {
      id: productTypeId,
    },
  });

  const { watch, control, setValue, clearErrors, formState, setError } = useFormContext();

  const { data: fieldMappingsData, loading: fieldMappingsLoading } = useQuery<
    Pick<Query, 'productTypeFieldIntegrationMappings'>,
    QueryProductTypeFieldIntegrationMappingsArgs
  >(integrationProductTypeFieldMappingsQuery, {
    fetchPolicy: 'cache-first',
    variables: { productTypeId },
    skip: !!defaultFieldMappings,
  });

  const { data: integrationProductTypeFieldsData, loading: integrationProductTypeFieldsLoading } = useQuery<
    Pick<Query, 'integrationProductTypeFields'>,
    QueryIntegrationProductTypeFieldsArgs
  >(integrationProductTypeFields, {
    fetchPolicy: 'cache-first',
    variables: { id: integration.id, externalProductTypeId },
  });

  const { fieldMappings, otomateFields } = useMemo(() => {
    let fieldMappings: ProductTypeFieldIntegrationMapping[] =
      defaultFieldMappings || fieldMappingsData?.productTypeFieldIntegrationMappings || [];
    let otomateFields = typeData?.getProductType.fields || [];

    if (!isShopifyCustomOptionsMappingsActive && integration.type === IntegrationTypes.Shopify) {
      const otomateFieldIdsToRemove: string[] = [];

      fieldMappings = fieldMappings.filter((mapping) => {
        if (!mapping.metadataExternalId.match(SHOPIFY_OPTION_NAME_REGEXP)) {
          return true;
        }

        if (mapping.productTypeFieldId) {
          otomateFieldIdsToRemove.push(mapping.productTypeFieldId);
        }

        return false;
      });

      otomateFields = otomateFields.filter(({ id }) => !otomateFieldIdsToRemove.includes(id));
    }

    return { fieldMappings, otomateFields };
  }, [
    defaultFieldMappings,
    fieldMappingsData?.productTypeFieldIntegrationMappings,
    integration.type,
    isShopifyCustomOptionsMappingsActive,
    typeData?.getProductType.fields,
  ]);

  const integrationFields = useMemo(() => {
    if (!integrationProductTypeFieldsData) {
      return [];
    }

    if (!isShopifyCustomOptionsMappingsActive && integration.type === IntegrationTypes.Shopify) {
      return integrationProductTypeFieldsData.integrationProductTypeFields.filter(
        ({ id }) => !id.match(SHOPIFY_OPTION_NAME_REGEXP),
      );
    }

    return integrationProductTypeFieldsData.integrationProductTypeFields;
  }, [integration.type, integrationProductTypeFieldsData, isShopifyCustomOptionsMappingsActive]);

  const notSystemFieldsArray = otomateFields.filter(({ systemName }) => systemName === null);

  const returnFirstEmptyMappingNumberAndUsedFields = (
    nonCheckingMappingNumber: number,
  ): { fieldMappingsCount: number; usedOtomateFieldsIds: string[]; usedIntegrationFieldsIds: string[] } => {
    const fieldMapping = watch(`${getFieldMappingKey(nonCheckingMappingNumber, fieldMappingKeyPrefix)}` as const);

    // skip if there is no field, or it's Shopify option.[1,2,3] field
    const skip =
      !fieldMapping ||
      (!isShopifyCustomOptionsMappingsActive &&
        integration.type === IntegrationTypes.Shopify &&
        fieldMapping.externalFieldId.match(SHOPIFY_OPTION_NAME_REGEXP));

    if (!skip) {
      const { fieldMappingsCount, usedOtomateFieldsIds, usedIntegrationFieldsIds } =
        returnFirstEmptyMappingNumberAndUsedFields(nonCheckingMappingNumber + 1);

      usedOtomateFieldsIds.push(fieldMapping.fieldId);
      usedIntegrationFieldsIds.push(fieldMapping.externalFieldId);

      return {
        fieldMappingsCount,
        usedOtomateFieldsIds,
        usedIntegrationFieldsIds,
      };
    }

    return { fieldMappingsCount: nonCheckingMappingNumber, usedOtomateFieldsIds: [], usedIntegrationFieldsIds: [] };
  };

  const { fieldMappingsCount, usedOtomateFieldsIds, usedIntegrationFieldsIds } =
    returnFirstEmptyMappingNumberAndUsedFields(0);

  const [getSuggestions] = useLazyQuery<Pick<Query, 'getFieldMappingSuggestions'>, QueryGetFieldMappingSuggestionsArgs>(
    getFieldMappingSuggestions,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setSuggestions(data?.getFieldMappingSuggestions);
        addSuggestedFieldsMappings(data.getFieldMappingSuggestions);
      },
    },
  );

  const addSuggestedFieldsMappings = (fieldsMappingsForCreate: FieldMappingSuggestion[]) => {
    if (!fieldsMappingsForCreate.length) {
      return;
    }

    addFieldMappingRows(fieldsMappingsForCreate);
  };

  const { otomateFieldOptions, otomateFieldOptionsMap } = useMemo(
    () =>
      otomateFields.reduce(
        (
          acc: { otomateFieldOptions: SelectOptionItem[]; otomateFieldOptionsMap: Record<string, SelectOptionItem> },
          { id, name, systemName },
        ) => {
          const option = {
            label: name,
            value: id,
          };

          acc.otomateFieldOptionsMap[id] = option;

          if (!(usedOtomateFieldsIds.includes(id) || systemName)) {
            acc.otomateFieldOptions.push(option);
          }

          return acc;
        },
        { otomateFieldOptions: [], otomateFieldOptionsMap: {} },
      ),
    [otomateFields, usedOtomateFieldsIds],
  );

  const { integrationFieldOptions, integrationFieldOptionsMap } = useMemo(
    () =>
      integrationFields.reduce(
        (
          acc: {
            integrationFieldOptions: SelectOptionItem[];
            integrationFieldOptionsMap: Record<string, SelectOptionItem>;
          },
          { id, name },
        ) => {
          const label =
            integration.type === IntegrationTypes.Woocommerce && !isNaN(+id)
              ? name
              : integration.type === IntegrationTypes.Ebay
              ? t(name)
              : t(`${integration.type}.${name}`);
          const option = { label, value: id };

          acc.integrationFieldOptionsMap[id] = option;

          if (!usedIntegrationFieldsIds.includes(id)) {
            acc.integrationFieldOptions.push(option);
          }

          return acc;
        },
        { integrationFieldOptions: [], integrationFieldOptionsMap: {} },
      ),
    [integrationFields, usedIntegrationFieldsIds, t, integration.type],
  );

  const limitMappingFields = notSystemFieldsArray.length + requiredIntegrationFields.length;
  const loading = fieldMappingsLoading || integrationProductTypeFieldsLoading || loadingExternalType;
  const activeAddFieldMappingButton = !!(
    otomateFieldOptions.length &&
    externalProductTypeId &&
    fieldMappingsCount < limitMappingFields
  );

  const handleClick = () => {
    setIsOpen((prev) => !prev);
  };

  const addFieldMappingRows = (mappings: FieldMappingSuggestion[]) => {
    setIsOpen(true);
    mappings.forEach(({ internalId, externalId }, index) =>
      setValue(
        `${getFieldMappingKey(fieldMappingsCount + index, fieldMappingKeyPrefix)}` as const,
        {
          integrationId: integration.id,
          fieldId: internalId,
          externalFieldId: externalId,
          toSync: true,
        },
        { shouldDirty: true },
      ),
    );
  };

  const handleAddFieldMapping = () => {
    if (!activeAddFieldMappingButton) return;

    setIsOpen(true);

    setValue(
      `${getFieldMappingKey(fieldMappingsCount, fieldMappingKeyPrefix)}` as const,
      {
        integrationId: integration.id,
        fieldId: '',
        externalFieldId: '',
        toSync: true,
      },
      { shouldDirty: true },
    );
  };

  const inputLabel = String(
    getValueAccordingToIntegrationType(integration.type, ValueAccordingToIntegrationType.InputLabel, t),
  );

  const emptyOptionPlaceholder = String(
    getValueAccordingToIntegrationType(integration.type, ValueAccordingToIntegrationType.EmptyOptionPlaceholder, t),
  );

  const handleSoftDelete = (currentFieldMapping: FieldMappingsFormData, idx: number) => {
    if (currentFieldMapping) {
      setValue(
        `${getFieldMappingKey(idx, fieldMappingKeyPrefix)}` as const,
        { ...currentFieldMapping, softDeleted: !currentFieldMapping.softDeleted },
        { shouldDirty: true },
      );
    }
  };

  const handleHardDelete = (idx: number) => {
    const lastIndex = fieldMappingsCount - 1;

    for (let i = idx; i < lastIndex; i++) {
      setValue(
        `${getFieldMappingKey(i, fieldMappingKeyPrefix)}` as const,
        watch(`${getFieldMappingKey(i + 1, fieldMappingKeyPrefix)}` as const),
        {
          shouldDirty: true,
        },
      );

      const newFieldError = formState.errors[`${getFieldMappingKey(i + 1, fieldMappingKeyPrefix)}` as const];

      if (newFieldError) {
        setError(
          `${getFieldMappingKey(i, fieldMappingKeyPrefix)}` as const,
          { ...newFieldError },
          {
            shouldFocus: true,
          },
        );
      } else {
        clearErrors(`${getFieldMappingKey(i, fieldMappingKeyPrefix)}` as const);
      }
    }

    const lastMappingKey = `${getFieldMappingKey(lastIndex, fieldMappingKeyPrefix)}` as const;

    setValue(lastMappingKey, null, { shouldDirty: true });
    clearErrors(lastMappingKey);
  };

  const handleDelete = (idx: number) => {
    const currentFieldMapping = watch(`${getFieldMappingKey(idx, fieldMappingKeyPrefix)}` as const);

    currentFieldMapping?.id ? handleSoftDelete(currentFieldMapping, idx) : handleHardDelete(idx);
  };

  const getMappingErrorKey = (fieldId = '', integrationId: string, reason: Reason) => {
    return `${fieldId}${integrationId}${reason}`;
  };

  const mappingsErrorsMap = useMemo(
    () =>
      typeData?.getProductType?.mappingsErrors?.reduce((acc: Record<string, IntegrationMappingError>, mappingError) => {
        acc[
          getMappingErrorKey(mappingError.productTypeFieldId as string, mappingError.integrationId, mappingError.reason)
        ] = mappingError;

        return acc;
      }, {}) || {},
    [typeData?.getProductType?.mappingsErrors],
  );

  useEffect(() => {
    getSuggestions({
      variables: {
        externalProductTypeId: externalProductTypeId,
        integrationId: integration.id,
        internalProductTypeId: productTypeId,
      },
    });
  }, [externalProductTypeId, getSuggestions, integration.id, productTypeId]);

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between" ml="30px">
        {fieldMappingsCount ? (
          <MappingFieldsHeader
            onClick={handleClick}
            data-testid={`mappings_fields_block_${integration.type}_integration`}
          >
            <Iconography
              iconName={isOpen ? 'expand-chevron-up' : 'expand-chevron'}
              data-testid={`expandIcon${categoryName}`}
            />

            <Typography variant="subtitle2" color="inherit" ml="5px">
              {t('mappingSidebar.firstTimeMapping.fields')}
            </Typography>
          </MappingFieldsHeader>
        ) : null}

        {loading ? (
          <Typography variant="subtitle2" color="text.secondary" sx={{ opacity: 0.3 }}>
            {t('productType.mapping.fieldsMappingProgress')}
          </Typography>
        ) : (
          <Hint
            type="hover"
            title={`${t('productType.mapping.addedAll')}`}
            placement="left"
            doNotShow={activeAddFieldMappingButton}
          >
            <AddMappingButton
              marginLeft="auto"
              disabled={!activeAddFieldMappingButton}
              onClick={handleAddFieldMapping}
              data-testid={`add_fields_mapping_button_${integration.type}_integration`}
            >
              <Iconography iconName="add" />

              <Typography variant="subtitle2">{t('productType.mapping.addField')}</Typography>
            </AddMappingButton>
          </Hint>
        )}
      </Box>

      <Collapse in={isOpen}>
        <MappingFieldsWrapper arrowSpaceWidth={arrowSpaceWidth}>
          {loading ? (
            <SkeletonMappingFields rowsCount={3} />
          ) : (
            <>
              {addFieldHeaders && fieldMappingsCount ? (
                <>
                  <Box gridColumn={2}>
                    <Typography variant="subtitle2" fontWeight="400" color="text.secondary">{`Otomate ${t(
                      'productType.mapping.fields',
                    )}`}</Typography>
                  </Box>

                  <Box gridColumn={4}>
                    <Typography variant="subtitle2" fontWeight="400" color="text.secondary">
                      {`${t(`integrationNames.${integration.type}`)} ${t('productType.mapping.fields')}`}
                    </Typography>
                  </Box>
                </>
              ) : null}

              {Array.from({ length: fieldMappingsCount }).map((_, idx) => {
                const fields = watch(`${getFieldMappingKey(idx, fieldMappingKeyPrefix)}` as const);
                const isFieldForDelete = fields?.softDeleted;
                const otomateFieldType = otomateFields?.find(({ id }) => id === fields?.fieldId)?.type;
                const externalFieldType = integrationFields?.find(({ id }) => id === fields?.externalFieldId)?.type;
                const fieldsHasDifferentTypes = otomateFieldType !== externalFieldType;
                const errorTitle = t('productType.mapping.errorDifferentTypes', {
                  otomateFieldType: otomateFieldType || 'empty field',
                  externalFieldType: externalFieldType || 'empty field',
                });
                const fieldTypeMismatchError =
                  mappingsErrorsMap[getMappingErrorKey(fields?.fieldId, integration.id, Reason.FieldTypeMismatch)];

                return (
                  <Fragment key={idx}>
                    {idx === 0 && !fieldMappings?.length}
                    <Controller
                      name={`${getFieldMappingKey(idx, fieldMappingKeyPrefix)}` as const}
                      control={control}
                      rules={{
                        validate: (mapping) => !(mapping && !mapping.externalFieldId && mapping.fieldId),
                      }}
                      render={({
                        field: { onChange, value },
                        fieldState: { invalid },
                      }: {
                        field: {
                          onChange: (newValue: Maybe<FieldMappingsFormData>) => void;
                          value: FieldMappingsFormData | undefined;
                        };
                        fieldState: {
                          invalid: boolean;
                        };
                      }) => (
                        <>
                          {value && (
                            <>
                              <FieldsRowItem disabled={isFieldForDelete} gridColumn="1">
                                <StyledErrorBox>
                                  {(fieldsHasDifferentTypes || fieldTypeMismatchError) && (
                                    <HintLimitButton
                                      placement="top"
                                      tooltipWidth="240px"
                                      title={
                                        fieldTypeMismatchError
                                          ? String(t(`productType.mapping.${Reason.FieldTypeMismatch}`))
                                          : errorTitle
                                      }
                                    >
                                      <StyledErrorIcon>
                                        <Iconography
                                          iconName={!!fieldTypeMismatchError ? 'cancel-circle' : 'info-fill'}
                                          data-testid={`${idx}ErrorIcon`}
                                          className={!!fieldTypeMismatchError ? 'error' : ''}
                                        />
                                      </StyledErrorIcon>
                                    </HintLimitButton>
                                  )}
                                </StyledErrorBox>
                              </FieldsRowItem>

                              <FieldsRowItem disabled={isFieldForDelete}>
                                <Select
                                  isSelectedOptionReset={false}
                                  fullwidth
                                  options={otomateFieldOptions}
                                  onChangeSelect={(option) =>
                                    onChange({
                                      ...value,
                                      externalFieldId:
                                        value.externalFieldId !== ''
                                          ? value.externalFieldId
                                          : suggestions?.find(({ internalId }) => internalId === option.value)
                                              ?.externalId ?? value.externalFieldId,
                                      fieldId: option.value,
                                    } as FieldMappingsFormData)
                                  }
                                  label={`Otomate ${t('productType.mapping.field')}`}
                                  selectedOptionItem={
                                    (value?.fieldId && otomateFieldOptionsMap[value.fieldId]) || defaultSelectValue
                                  }
                                  emptyOptionPlaceholder={t('productType.mapping.chooseField')}
                                  disabled={isFieldForDelete}
                                  customTestIdArrowDropDown={`arrowDropDownIconOtomate${t(
                                    'productType.mapping.field',
                                  )}${value.fieldId && otomateFieldOptionsMap[value.fieldId]?.label}`}
                                  optionsWithTooltips
                                  generalOptionDataTestId={`otomate_field_№${idx}_for_${integration.type}_integration_`}
                                />
                              </FieldsRowItem>

                              <FieldsRowItem disabled={isFieldForDelete}>
                                {arrowComponent || (
                                  <Iconography id="arrow" iconName="vector" viewBox="0 0 62 8" color="disabled" />
                                )}
                              </FieldsRowItem>

                              <FieldsRowItem disabled={isFieldForDelete}>
                                <Select
                                  tooltip={
                                    requiredIntegrationFields.some(({ id }) => id === value.externalFieldId)
                                      ? t('productType.mapping.requiredMappingTooltip')
                                      : ''
                                  }
                                  nonEditable={requiredIntegrationFields.some(({ id }) => id === value.externalFieldId)}
                                  fullwidth
                                  options={integrationFieldOptions}
                                  onChangeSelect={(option) =>
                                    onChange({
                                      ...value,
                                      externalFieldId: option.value,
                                    } as FieldMappingsFormData)
                                  }
                                  label={inputLabel}
                                  selectedOptionItem={
                                    (value?.externalFieldId && integrationFieldOptionsMap[value.externalFieldId]) ||
                                    defaultSelectValue
                                  }
                                  emptyOptionPlaceholder={emptyOptionPlaceholder}
                                  error={invalid || !value?.externalFieldId}
                                  disabled={isFieldForDelete}
                                  customTestIdArrowDropDown={`arrowDropDownIcon${inputLabel}${
                                    value?.externalFieldId
                                      ? integrationFieldOptionsMap[value.externalFieldId]?.label
                                      : ''
                                  }`}
                                  optionsWithTooltips
                                  generalOptionDataTestId={`${integration.type}_integration_field_№${idx}_`}
                                />
                              </FieldsRowItem>

                              {!requiredIntegrationFields.some(({ id }) => id === value.externalFieldId) && (
                                <>
                                  <FieldsRowItem disabled={isFieldForDelete}>
                                    <Hint
                                      type="hover"
                                      title={
                                        value.toSync
                                          ? `${t('productType.mapping.exclude')}`
                                          : `${t('productType.mapping.include')}`
                                      }
                                      placement="left"
                                    >
                                      <Checkbox
                                        style={{ margin: 0 }}
                                        checked={value.toSync}
                                        disabled={isFieldForDelete}
                                        color="secondary"
                                        onChange={({ target: { checked: toSync } }) =>
                                          onChange({
                                            ...value,
                                            toSync,
                                          })
                                        }
                                        inputProps={
                                          {
                                            'data-testid': `${idx}_checkbox_${integration.type}_integration`,
                                          } as React.InputHTMLAttributes<HTMLInputElement>
                                        }
                                      />
                                    </Hint>
                                  </FieldsRowItem>

                                  <FieldsRowItem disabled={isFieldForDelete}>
                                    <IconButton
                                      onClick={() => handleDelete(idx)}
                                      data-testid={`${idx}_trash_button_${integration.type}_integration`}
                                    >
                                      <Iconography iconName={isFieldForDelete ? 'restore' : 'trash-fill'} />
                                    </IconButton>
                                  </FieldsRowItem>
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    />
                  </Fragment>
                );
              })}
            </>
          )}
        </MappingFieldsWrapper>
      </Collapse>
    </>
  );
};

export default MappingFields;
