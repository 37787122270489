import { Box, Typography } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';

export const KeywordsWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 552px;
  height: 100vh;
  background-color: ${({ theme: { palette } }) => palette.common.white};
  box-shadow: 0 2px 10px ${({ theme: { palette } }) => palette.error.dark};
  position: fixed;
  z-index: 1200;
  right: 0;
`;

export const StyledTitleTypography = styled(Typography)`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: ${({ theme: { palette } }) => palette.primary.light};
  margin-top: 50px;
  margin-left: 30px;
`;

export const StyledKeywordsTypography = styled(Typography)`
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  color: ${({ theme: { palette } }) => palette.primary.light};
`;

export const StyledKeywordsListHeaderContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  margin-left: 30px;
  margin-right: 54px;
  margin-top: 28px;
  width: 463px;
`;

export const StyledKeywordItemTypography = styled(Typography)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme: { palette } }) => palette.primary.light};
  max-width: 425px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledKeywordsNotFoundTypography = styled(Typography)`
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: ${({ theme: { palette } }) => palette.text.disabled};
`;
