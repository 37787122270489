import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';
import { Box } from '@mui/material';

import Loader from 'src/components/Loader';
import NoIntegrations from 'src/components/Mapping/NoIntegrations';
import DiscardChangesPopup from 'src/components/Popups/DiscardChangesPopup';
import { arrayToMap } from 'src/utils/general';
import {
  checkIsCatalogSyncable,
  getIntegrations,
  getProductTypeById,
  getProductTypeMappings,
  integrationProductTypeFieldMappings as integrationProductTypeFieldMappingsQuery,
  ProductTypeFieldIntegrationMapping,
  Query,
  QueryGetCatalogByIdArgs,
  QueryGetIntegrationsArgs,
  QueryGetProductTypeArgs,
  QueryGetProductTypeIntegrationMappingsArgs,
  QueryProductTypeFieldIntegrationMappingsArgs,
} from 'src/utils/gql';

import IntegrationInfo from './IntegrationInfo';
export interface IntegrationMappingsState {
  integrationId: string;
  isChanged: boolean;
}

const MappingTabContent = () => {
  const { id: catalogId, productTypeId } = useParams<{ id: string; productTypeId: string }>();
  const [hasFormChanges, setHasFormChanges] = useState<IntegrationMappingsState[]>([]);
  const openDiscardPopup = hasFormChanges.some((integration) => integration.isChanged);
  const handleFormStateChange = (integrationId: string, isFormChanged: boolean): void => {
    const isIntegrationAlreadyInArray = hasFormChanges.find((item) => item.integrationId === integrationId);

    let newArray = [...hasFormChanges];

    if (isIntegrationAlreadyInArray) {
      newArray = newArray.map((item) =>
        item.integrationId === integrationId ? { ...item, isChanged: isFormChanged } : item,
      );
    } else {
      newArray.push({ integrationId: integrationId, isChanged: isFormChanged });
    }
    setHasFormChanges(newArray);
  };

  const {
    data: typeMappingsData,
    loading: typeMappingsLoading,
    refetch: typeMappingsRefetch,
  } = useQuery<Pick<Query, 'getProductTypeIntegrationMappings'>, QueryGetProductTypeIntegrationMappingsArgs>(
    getProductTypeMappings,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      variables: {
        productTypeId,
      },
    },
  );

  const {
    data: fieldMappingsData,
    loading: fieldMappingsLoading,
    refetch: fieldsMappingsRefetch,
  } = useQuery<Pick<Query, 'productTypeFieldIntegrationMappings'>, QueryProductTypeFieldIntegrationMappingsArgs>(
    integrationProductTypeFieldMappingsQuery,
    {
      fetchPolicy: 'cache-first',
      variables: {
        productTypeId,
      },
    },
  );

  const { data: typeData, loading: typeLoading } = useQuery<Pick<Query, 'getProductType'>, QueryGetProductTypeArgs>(
    getProductTypeById,
    {
      fetchPolicy: 'cache-first',
      variables: {
        id: productTypeId,
      },
    },
  );

  const { data: getIntegrationsData, loading: getIntegrationsLoading } = useQuery<
    Pick<Query, 'getIntegrations'>,
    QueryGetIntegrationsArgs
  >(getIntegrations, {
    fetchPolicy: 'cache-first',
    variables: {
      catalogId,
    },
  });

  const { data: checkIsCatalogSyncableData, loading: checkIsCatalogSyncableLoading } = useQuery<
    Pick<Query, 'getCatalogById'>,
    QueryGetCatalogByIdArgs
  >(checkIsCatalogSyncable, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: catalogId,
    },
  });

  const productTypesMappingsMap = useMemo(
    () => typeMappingsData && arrayToMap(typeMappingsData.getProductTypeIntegrationMappings, 'integrationId'),
    [typeMappingsData],
  );

  const productTypesFieldsMap = useMemo(
    () =>
      fieldMappingsData?.productTypeFieldIntegrationMappings.reduce(
        (acc: Record<string, ProductTypeFieldIntegrationMapping[]>, fieldMapping) => {
          const { integrationId } = fieldMapping;

          if (integrationId) {
            if (!acc[integrationId]) {
              acc[integrationId] = [];
            }

            acc[integrationId].push(fieldMapping);
          }

          return acc;
        },
        {},
      ),
    [fieldMappingsData],
  );

  const loading =
    typeMappingsLoading ||
    fieldMappingsLoading ||
    typeLoading ||
    getIntegrationsLoading ||
    checkIsCatalogSyncableLoading;

  const refetch = async (): Promise<void> => {
    await Promise.all([typeMappingsRefetch(), fieldsMappingsRefetch()]);
  };

  return (
    <>
      {loading ? (
        <Loader fullAvailableScreen />
      ) : getIntegrationsData?.getIntegrations.length ? (
        <Box display="flex" flexDirection="column" mt="40px" mr="56px">
          {getIntegrationsData.getIntegrations.map((integration) => (
            <IntegrationInfo
              syncable={checkIsCatalogSyncableData?.getCatalogById.syncable}
              key={integration.id}
              integration={integration}
              catalogId={catalogId}
              productTypeMapping={productTypesMappingsMap?.[integration.id]}
              refetchAfterSave={refetch}
              fieldMappings={productTypesFieldsMap?.[integration.id]}
              productType={typeData?.getProductType}
              handleFormStateChange={handleFormStateChange}
              productTypeFieldMappings={fieldMappingsData?.productTypeFieldIntegrationMappings.filter(
                ({ integrationId }) => integrationId === integration.id,
              )}
            />
          ))}
        </Box>
      ) : (
        <NoIntegrations />
      )}
      <DiscardChangesPopup isOpen={openDiscardPopup} />
    </>
  );
};

export default MappingTabContent;
