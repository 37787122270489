import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Checkbox, Typography } from '@material-ui/core';

import TextInput from './FormInputs/TextInput';
import { AddEditIntegrationsProps } from './types';

const FacebookForm = ({ onChangeDefault, isDefaultIntegration }: AddEditIntegrationsProps) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column" width="288px">
      <Typography variant="subtitle1" mb="10px" mt="50px">
        {t('userSettingsPage.integrations.popup.contentTitle', { integration: 'Facebook + Instagram' })}
      </Typography>

      <Box>
        <form aria-label="form" autoComplete="off">
          <Box mt="15px" mb="25px" width="100%">
            <TextInput
              shouldFocus
              fullWidth
              label={t('userSettingsPage.integrations.inputLabel.shopName')}
              name="integrationName"
              inputNameTestId="integrationName"
            />
          </Box>

          <Box display="flex" textAlign="left" mt="25px" alignItems="center">
            <Checkbox onChange={onChangeDefault} name="isDefault" checked={isDefaultIntegration} />

            <Typography variant="caption" color="text.secondary" minWidth={266}>
              {t('userSettingsPage.integrations.popup.saveAsDefaultFacebook')}
            </Typography>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default FacebookForm;
