import React from 'react';

import moment from 'moment';

import DocExpansion from 'src/components/Images/DocExpansion.svg';
import DocxExpansion from 'src/components/Images/DocxExpansion.svg';
import GlbExpansion from 'src/components/Images/GlbExpansion.svg';
import M4aExpansion from 'src/components/Images/M4aExpansion.svg';
import Mp3Expansion from 'src/components/Images/Mp3Expansion.svg';
import OdtExpansion from 'src/components/Images/OdtExpansion.svg';
import OggExpansion from 'src/components/Images/OggExpansion.svg';
import PdfExpansion from 'src/components/Images/PdfExpansion.svg';
import PptExpansion from 'src/components/Images/PptExpansion.svg';
import PptxExpansion from 'src/components/Images/PptxExpansion.svg';
import PsdExpansion from 'src/components/Images/PsdExpansion.svg';
import UsdzExpansion from 'src/components/Images/UsdzExpansion.svg';
import WavExpansion from 'src/components/Images/WavExpansion.svg';
import XlsExpansion from 'src/components/Images/XlsExpansion.svg';
import XlsxExpansion from 'src/components/Images/XlsxExpansion.svg';
import {
  AVAILABLE_AUDIO_FORMATS,
  AVAILABLE_DOCUMENT_FORMATS,
  AVAILABLE_MODELS3D_EXTENSIONS,
  UploadingMedia,
} from 'src/views/Catalogs/ProductItems/types';

import { DataFilter } from './constants';

export const getFilterByDate = (type: string) => {
  let filter = moment();

  switch (type) {
    case DataFilter.today:
      break;
    case DataFilter.lastThreeDays:
      filter = filter.subtract(3, 'days');
      break;
    case DataFilter.lastWeek:
      filter = filter.subtract(1, 'week');
      break;
    case DataFilter.lastMonth:
      filter = filter.subtract(1, 'month');
      break;
    default:
      return undefined;
  }

  return filter.startOf('day').toString();
};

export const documentPicture = (expansion?: string) => {
  switch (expansion) {
    case AVAILABLE_AUDIO_FORMATS[0]:
      return <img draggable={false} src={Mp3Expansion} alt="Mp3Expansion" height="56px" width="56px" />;
    case AVAILABLE_AUDIO_FORMATS[1]:
      return <img draggable={false} src={M4aExpansion} alt="M4aExpansion" height="56px" width="56px" />;
    case AVAILABLE_AUDIO_FORMATS[2]:
      return <img draggable={false} src={OggExpansion} alt="OggExpansion" height="56px" width="56px" />;
    case AVAILABLE_AUDIO_FORMATS[3]:
      return <img draggable={false} src={WavExpansion} alt="WavExpansion" height="56px" width="56px" />;
    case AVAILABLE_DOCUMENT_FORMATS[0]:
      return <img draggable={false} src={PdfExpansion} alt="PdfExpansion" height="56px" width="56px" />;
    case AVAILABLE_DOCUMENT_FORMATS[1]:
      return <img draggable={false} src={DocExpansion} alt="DocExpansion" height="56px" width="56px" />;
    case AVAILABLE_DOCUMENT_FORMATS[2]:
      return <img draggable={false} src={PptExpansion} alt="PptExpansion" height="56px" width="56px" />;
    case AVAILABLE_DOCUMENT_FORMATS[3]:
      return <img draggable={false} src={OdtExpansion} alt="OdtExpansion" height="56px" width="56px" />;
    case AVAILABLE_DOCUMENT_FORMATS[4]:
      return <img draggable={false} src={XlsExpansion} alt="XlsExpansion" height="56px" width="56px" />;
    case AVAILABLE_DOCUMENT_FORMATS[5]:
      return <img draggable={false} src={PsdExpansion} alt="PsdExpansion" height="56px" width="56px" />;
    case AVAILABLE_DOCUMENT_FORMATS[6]:
      return <img draggable={false} src={DocxExpansion} alt="DocxExpansion" height="56px" width="56px" />;
    case AVAILABLE_DOCUMENT_FORMATS[7]:
      return <img draggable={false} src={XlsxExpansion} alt="XlsxExpansion" height="56px" width="56px" />;
    case AVAILABLE_DOCUMENT_FORMATS[8]:
      return <img draggable={false} src={PptxExpansion} alt="PptxExpansion" height="56px" width="56px" />;
    case AVAILABLE_MODELS3D_EXTENSIONS[0]:
      return <img draggable={false} src={GlbExpansion} alt="GlbExpansion" height="56px" width="56px" />;
    case AVAILABLE_MODELS3D_EXTENSIONS[1]:
      return <img draggable={false} src={UsdzExpansion} alt="UsdzExpansion" height="56px" width="56px" />;
  }
};

export const reorder = (list: UploadingMedia[], startIndex: number, endIndex: number) => {
  const result = [...list];
  const firstMediaOrder = result[startIndex].orderNumber || 0;
  const secondMediaOrder = result[endIndex].orderNumber || 0;
  result[startIndex].orderNumber = secondMediaOrder;
  result[endIndex].orderNumber = firstMediaOrder;
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};
