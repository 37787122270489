import React, { createRef, ChangeEvent, useContext, useCallback, useMemo } from 'react';
import { useDrop } from 'react-dnd';
import { useTranslation, Trans } from 'react-i18next';

import { Box, Theme, Typography, makeStyles } from '@material-ui/core';

import useUploadMediaBrowseUrl from 'src/hooks/useUploadMediaBrowseUrl';
import { MediaStateContext } from 'src/providers/MediaProvider/context';
import useOnboarding from 'src/providers/OnboardingProvider/hooks/useOnboarding';
import { UploadingMedia } from 'src/views/Catalogs/ProductItems/types';

import { AVAILABLE_FORMATS, MediaDataType, MediaTypes } from './constants';
import { reorder } from './functions';
import MediaCard from './MediaCard';
import { AddMediaButton, StyledMediaContainer, StyledMediaText, StyledTypography } from './styled';

const useStyles = makeStyles((theme: Theme) => ({
  mediaContainer: {
    padding: '10px',
    borderRadius: '2px',
    border: `1px solid ${theme.palette.action.disabled}`,
    background: theme.palette.common.white,
    height: '348px',
    overflow: 'auto',
    position: 'relative',
    display: 'grid',
    gridGap: '10px 12px',
    gridAutoRows: '1fr',
    gridAutoFlow: 'dense',
    counterReset: 'albumList',
    gridTemplateColumns: 'repeat(3, 1fr)',

    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
  },
  mediaContainerText: {
    fontWeight: 500,
    fontSize: '15px',
    lineHeight: '18px',
    color: theme.palette.text.disabled,
  },
  lightBackground: {
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='2' ry='2' stroke='%23BBC9DFFF' stroke-width='2' stroke-dasharray='7' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e")`,
  },
  textDropping: {
    color: theme.palette.secondary.main,
  },
  mainTextInDropzone: {
    color: theme.palette.text.disabled,
  },
  linkAddUrl: {
    textDecorationLine: 'underline',
    padding: '10px 0',
  },
  filledBlock: {
    height: '348px',
  },
}));

interface MediaContentProps {
  setAddUrlPopup: () => void;
  handleChangeCheckbox: (event: ChangeEvent<HTMLInputElement>) => void;
  selectedMediaFiles: string[];
  handleOpenImageEditor: (image: UploadingMedia) => void;
}

const MediaContent = ({
  setAddUrlPopup,
  handleChangeCheckbox,
  selectedMediaFiles,
  handleOpenImageEditor,
}: MediaContentProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    onboardingState: { tourActive, stepIndex },
  } = useOnboarding();

  const { mediaState, setMediaState } = useContext(MediaStateContext);
  const { handleUploadMediaBrowse } = useUploadMediaBrowseUrl();

  const selectedMediaIds = (id: string) => selectedMediaFiles.includes(id);

  const findCard = useCallback((id: string) => mediaState.findIndex(({ id: cardId }) => cardId === id), [mediaState]);

  const moveCard = useCallback(
    (id: string, startIndex: number) => {
      const index = findCard(id);
      const updateArray = reorder(mediaState, index, startIndex);

      setMediaState(updateArray);
    },
    [findCard, mediaState, setMediaState],
  );

  const sortedMedia = useMemo(() => {
    return mediaState.sort(
      ({ orderNumber: firstMediaOrder }, { orderNumber: secondMediaOrder }) =>
        (firstMediaOrder || 0) - (secondMediaOrder || 0),
    );
  }, [mediaState]);

  const [, drop] = useDrop(() => ({ accept: MediaTypes.CARD }));

  const inputFile = createRef<HTMLInputElement>();

  const getIdsUnloadedFiles = mediaState.reduce((acc: string[], { typeAddedData, id }) => {
    if (typeAddedData === MediaDataType.FROM_FILE) {
      acc.push(id);
    }

    return acc;
  }, []);

  return (
    <Box paddingBottom="10px">
      <input
        type="file"
        hidden
        multiple
        ref={inputFile}
        accept={AVAILABLE_FORMATS.join(', ')}
        onChange={handleUploadMediaBrowse}
      />

      {mediaState.length ? (
        <Box className={classes.mediaContainer} ref={drop} id="mediaContainer">
          {sortedMedia.map((media) => (
            <MediaCard
              key={media.id}
              media={media}
              handleChangeCheckbox={handleChangeCheckbox}
              isMediaSelected={selectedMediaIds(media.uuid)}
              moveCard={moveCard}
              findCard={findCard}
              unloadedFilesIds={getIdsUnloadedFiles}
              handleOpenImageEditor={handleOpenImageEditor}
            />
          ))}
        </Box>
      ) : (
        <StyledMediaContainer className={classes.lightBackground}>
          <StyledMediaText className={classes.mainTextInDropzone}>
            {!tourActive && t('productItemCreateEdit.mediaGallery.mediaContainer.drag')}

            <Typography
              className={classes.mediaContainerText}
              component="span"
              sx={{ marginBottom: tourActive ? '10px' : '0px', zIndex: tourActive && stepIndex === 12 ? 1402 : 0 }}
            >
              {tourActive ? (
                <Trans i18nKey="productItemCreateEdit.mediaGallery.mediaContainer.onlyBrowse">
                  <AddMediaButton onClick={() => inputFile.current?.click()} data-testid="addFileFromBrowseButton">
                    Browse
                  </AddMediaButton>
                  to upload media
                </Trans>
              ) : (
                <Trans i18nKey="productItemCreateEdit.mediaGallery.mediaContainer.browse">
                  or
                  <AddMediaButton onClick={() => inputFile.current?.click()} data-testid="addFileFromBrowseButton">
                    browse
                  </AddMediaButton>
                  to upload media
                </Trans>
              )}
            </Typography>

            {!tourActive && (
              <AddMediaButton
                onClick={setAddUrlPopup}
                className={classes.linkAddUrl}
                data-testid="addFileFromUrlButton"
              >
                {t('productItemCreateEdit.mediaGallery.mediaContainer.url')}
              </AddMediaButton>
            )}

            <StyledTypography>{t('productItemCreateEdit.mediaGallery.mediaContainer.sizeVideo')}</StyledTypography>

            <StyledTypography>{t('productItemCreateEdit.mediaGallery.mediaContainer.sizeDocument')}</StyledTypography>
          </StyledMediaText>
        </StyledMediaContainer>
      )}
    </Box>
  );
};

export default MediaContent;
