import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Checkbox, useTheme } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { FlexRowSpaceBetweenCenterWrapper, StyledDraftTypography } from './styled';

interface DraftCheckBoxProps {
  disabled: boolean;
}

const useStyles = makeStyles<Theme, { color: string }>(() => ({
  draftLabelWrapper: {
    marginRight: '25px',
    color: ({ color }) => color,
    '& svg': {
      color: ({ color }) => color,
    },
  },
}));

const DraftCheckBox = ({ disabled }: DraftCheckBoxProps) => {
  const { t } = useTranslation();
  const {
    palette: { secondary, text },
  } = useTheme();

  const color = disabled ? text.disabled : secondary.main;
  const classes = useStyles({ color });

  const { control, trigger } = useFormContext();

  return (
    <FlexRowSpaceBetweenCenterWrapper id="draft-checkbox-anchor" className={classes.draftLabelWrapper}>
      <Controller
        name="draft"
        control={control}
        render={({ field: { onChange, value } }) => (
          <Checkbox
            disabled={disabled}
            onChange={(e) => {
              onChange(e);
              trigger(undefined, { shouldFocus: true });
            }}
            checked={value || false}
            name="draftItem"
            inputProps={{ 'aria-label': 'checkbox' }}
            data-testid="checkboxDraftItem"
          />
        )}
      />

      <StyledDraftTypography color={color}>{t('draft')}</StyledDraftTypography>
    </FlexRowSpaceBetweenCenterWrapper>
  );
};

export default DraftCheckBox;
