import React from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js';

import PaymentForm from './PaymentForm';
import { appearance } from './styled';

interface PaymentMethodStripeContainerProps {
  clientSecret?: string;
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY as string);

const PaymentMethodStripeContainer = ({ clientSecret }: PaymentMethodStripeContainerProps) => {
  const options: StripeElementsOptions = {
    clientSecret,
    appearance,
    locale: 'en',
  };

  return (
    <Elements options={options} stripe={stripePromise}>
      <PaymentForm />
    </Elements>
  );
};

export default PaymentMethodStripeContainer;
