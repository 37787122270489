import React, { useMemo } from 'react';

import MultiSelect, { MultiSelectProps } from './MultiSelect';
import { AbstractFieldTypeInputProps } from './MultiTypeInput';

const MultiSelectInput = ({
  value,
  onChange,
  ...rest
}: Omit<MultiSelectProps, 'selectedItems' | 'onChange'> & AbstractFieldTypeInputProps) => {
  const selectedItems = useMemo<string[]>(() => {
    if (!value) return [];

    try {
      const items = JSON.parse(value);

      if (Array.isArray(items)) {
        return items.filter((item) => typeof item === 'string' && item);
      }

      return [];
    } catch (e) {
      return [];
    }
  }, [value]);

  return (
    <MultiSelect selectedItems={selectedItems} onChange={(values) => onChange(JSON.stringify(values))} {...rest} />
  );
};

export default MultiSelectInput;
