import React, { SyntheticEvent, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { Box, Table, TableCell, TableRow } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import { FIRST_DASHBOARD_PATHNAME, SHARED_CATALOGS } from 'src/constants/routeSources';
import { Catalog, OrderBy, OrderDirection } from 'src/utils/gql';
import { getPriceString } from 'src/views/Catalogs/utils';

import { SortData } from '../../views/SharedCatalogs';
import Iconography from '../Iconography';
import SkeletonSharedCatalogRow from '../Skeleton/SkeletonSharedCatalogRow';

import ActionsTableCell from './ActionsTableCell';
import { SHARED_CATALOGUES_TABLE_LABELS } from './SharedCatalogsTableHeadLabels';
import StatusTableCell from './StatusTableCell';
import {
  StyledCostCell,
  StyledHeaderLabelButton,
  StyledIconButton,
  StyledTableBody,
  StyledTableHead,
  StyledTextCell,
} from './styled';

interface SharedCataloguesTableProps {
  loadingCatalogs?: boolean;
  sortData: SortData;
  tableData?: Catalog[];
  handleSort: (sortableField: OrderBy, orderDirection?: OrderDirection, e?: SyntheticEvent<HTMLButtonElement>) => void;
  onCatalogsDataClear: () => void;
}

const SharedCatalogsTable = ({
  tableData,
  handleSort,
  sortData,
  loadingCatalogs = false,
  onCatalogsDataClear,
}: SharedCataloguesTableProps) => {
  const history = useHistory();
  const {
    palette: {
      text: { primary, disabled },
    },
  } = useTheme();

  const { sortableType: sortOrderDirection, sortableField: sortOrderBy } = sortData;

  const arrowButtonColor = (key: OrderBy, orderDirection: OrderDirection) =>
    sortOrderDirection === orderDirection && sortOrderBy === key ? primary : disabled;

  const goToProductTypesPreview = useCallback(
    (catalogId: string) => {
      history.push(`/${FIRST_DASHBOARD_PATHNAME}/${SHARED_CATALOGS}/${catalogId}`);
    },
    [history],
  );

  return (
    <Table>
      <StyledTableHead>
        <TableRow>
          {Object.entries(SHARED_CATALOGUES_TABLE_LABELS).map(([key, label], idx) => (
            <TableCell key={idx} align="left">
              {label === SHARED_CATALOGUES_TABLE_LABELS.default ? (
                <>{label}</>
              ) : (
                <Box display="flex" alignItems="center">
                  <StyledHeaderLabelButton onClick={(e) => handleSort(key as OrderBy, undefined, e)}>
                    {label}
                  </StyledHeaderLabelButton>
                  <Box display="flex" justifyContent="space-between" flexDirection="column" ml="11px">
                    <StyledIconButton onClick={() => handleSort(key as OrderBy, OrderDirection.Desc)} tabIndex={-1}>
                      <Iconography
                        iconName="unfold-arrow-up"
                        htmlColor={arrowButtonColor(key as OrderBy, OrderDirection.Desc)}
                      />
                    </StyledIconButton>
                    <StyledIconButton onClick={() => handleSort(key as OrderBy, OrderDirection.Asc)} tabIndex={-1}>
                      <Iconography
                        iconName="unfold-arrow-down"
                        htmlColor={arrowButtonColor(key as OrderBy, OrderDirection.Asc)}
                      />
                    </StyledIconButton>
                  </Box>
                </Box>
              )}
            </TableCell>
          ))}
        </TableRow>
      </StyledTableHead>

      {loadingCatalogs || !tableData ? (
        <StyledTableBody>
          <SkeletonSharedCatalogRow cardsCount={10} />
        </StyledTableBody>
      ) : (
        <StyledTableBody>
          {tableData.map(({ id, name, config: { paymentFrequency, price }, subscriptions, user }) => (
            <TableRow key={id}>
              <TableCell onClick={() => goToProductTypesPreview(id)}>
                <StyledTextCell>{name}</StyledTextCell>
              </TableCell>
              <TableCell>
                <StyledTextCell>{user.name}</StyledTextCell>
              </TableCell>
              <TableCell>
                <CatalogCost price={price ? String(price) : ''} frequency={paymentFrequency} />
              </TableCell>
              <TableCell>
                <StatusTableCell subscriptions={subscriptions ?? []} />
              </TableCell>
              <TableCell>
                <ActionsTableCell
                  catalogId={id}
                  subscriptions={subscriptions ?? []}
                  catalogName={name}
                  catalogOwner={user.name}
                  onCatalogsDataClear={onCatalogsDataClear}
                />
              </TableCell>
            </TableRow>
          ))}
        </StyledTableBody>
      )}
    </Table>
  );
};

// Custom cost table cell
const CatalogCost = (props: { price: string; frequency: string }) => {
  const { price, frequency } = props;
  return <StyledCostCell>{getPriceString(price, frequency)}</StyledCostCell>;
};

export default SharedCatalogsTable;
