import React from 'react';

import { makeStyles, StepIconProps, Theme, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { ReactComponent as ActiveStepIcon } from '../Icon/active-step.svg';

const useStepIconStyles = makeStyles((theme: Theme) => ({
  root: {
    background: theme.palette.action.disabled,
    color: theme.palette.common.white,
    width: 36,
    height: 36,
    display: 'flex',
    borderRadius: '50px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  completed: {
    zIndex: 1,
    background: theme.palette.secondary.dark,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  activeStepWrapper: {
    maxHeight: 53,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  activeStepText: {
    position: 'absolute',
  },
}));

interface CustomStepIconProps extends StepIconProps {
  handleSpecificStep: (step: number) => void;
  completedSteps?: Set<number>;
  active: boolean;
  completed: boolean;
}

const CustomStepIcon = (props: CustomStepIconProps) => {
  const classes = useStepIconStyles();
  const { active, completed, handleSpecificStep, completedSteps } = props;

  if (active) {
    return (
      <div className={classes.activeStepWrapper}>
        <ActiveStepIcon />
        <Typography className={classes.activeStepText} variant="subtitle1" color="common.white" fontWeight="normal">
          {props.icon}
        </Typography>
      </div>
    );
  }

  if (completed) {
    return (
      <div
        className={clsx(classes.root, classes.completed)}
        aria-hidden="true"
        onClick={() => handleSpecificStep(Number(props.icon))}
      >
        <Typography variant="subtitle1" color="common.white" fontWeight="normal">
          {props.icon}
        </Typography>
      </div>
    );
  }

  return (
    <div
      className={classes.root}
      aria-hidden="true"
      onClick={completedSteps?.has(Number(props.icon)) ? () => handleSpecificStep(Number(props.icon)) : () => false}
    >
      <Typography variant="subtitle1" color="common.white" fontWeight="normal">
        {props.icon}
      </Typography>
    </div>
  );
};

export default CustomStepIcon;
