import React, { PropsWithChildren, useCallback, useEffect, useMemo } from 'react';
import { Redirect } from 'react-router-dom';

import { useApolloClient, useQuery, gql, useMutation, ApolloError } from '@apollo/client';
import { useUnleashContext } from '@unleash/proxy-client-react';
import Cookies from 'js-cookie';

import Loader from 'src/components/Loader';
import { activateUser, me, Mutation, MutationActivateUserArgs, Query } from 'src/utils/gql';
import { getToken, removeToken } from 'src/utils/localStorage';

import { useSnackbar } from '../snackbar';

import AccountContext, { defaultValue } from './context';

export enum UserStatus {
  Pending = 'pending',
  EmailConfirmed = 'confirmed',
  Active = 'active',
  Inactive = 'inactive',
}

function AccountProvider({ children }: PropsWithChildren<Record<string, unknown>>) {
  const token = getToken();

  const client = useApolloClient();
  const updateUnleashContext = useUnleashContext();
  const snackbar = useSnackbar();

  const { data: { me: user } = { me: defaultValue }, loading, error } = useQuery<Pick<Query, 'me'>, never>(me);

  const update = useCallback(
    (patch) => {
      client.writeFragment({
        id: `User:${user.id}`,
        fragment: gql`
          fragment patchUser on User {
            ${Object.keys(patch).join('\n')}
          }
        `,
        data: {
          ...patch,
          __typename: 'User',
        },
      });
    },
    [user.id, client],
  );

  const [activate] = useMutation<Pick<Mutation, 'activateUser'>, MutationActivateUserArgs>(activateUser, {
    onCompleted: () => update({ status: UserStatus.Active }),
    onError: (error) => {
      const { graphQLErrors, message: errorText } = error as ApolloError;
      const message = graphQLErrors && graphQLErrors.length ? graphQLErrors[0].message : errorText;

      if (error) {
        snackbar(message);
      }
    },
  });

  useEffect(() => {
    if (user) {
      updateUnleashContext({
        userId: user.email,
      });

      if (user.email && user.status !== UserStatus.Active) {
        activate({ variables: { email: user.email } });
      }
    }

    if (!Cookies.get('changelogBannerClosed')) {
      Cookies.set('changelogBannerClosed', 'false');
    }
  }, [activate, updateUnleashContext, user]);

  const value = useMemo(() => ({ user, update }), [user, update]);

  if (error || !token) {
    removeToken();

    return <Redirect to="/auth/login" />;
  }

  if (loading) {
    return <Loader fullScreen />;
  }

  return <AccountContext.Provider value={value}>{children}</AccountContext.Provider>;
}

export default AccountProvider;
