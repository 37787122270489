import { useEffect, useRef } from 'react';

import { ExportTypes } from 'src/providers/catalog/types';
import { CatalogJob, CatalogJobStatus, CatalogJobType } from 'src/utils/gql';

import useCatalogs from './useCatalogs';

interface Params {
  catalogId: string;
  integrationId?: string;
  typeFilter?: CatalogJobType;
  onSuccess?: () => void;
  onFailure?: (error: string) => void;
  onCompleted?: (job: CatalogJob) => void;
}

interface Response {
  inProgress: boolean;
  type?: CatalogJobType;
  errorMessage?: string;
  startTime?: Date;
  endTime?: Date;
}

const useDataTransferStatus = ({
  catalogId,
  integrationId,
  typeFilter,
  onSuccess,
  onFailure,
  onCompleted,
}: Params): Response => {
  const { getCatalogJob, currentExportType, updateExportType } = useCatalogs();
  let lastSyncJob = getCatalogJob({ catalogId, integrationId });

  const catalogWithSimpleExport = currentExportType.some(({ catalogId: id, exportType }) => {
    return catalogId === id && exportType === ExportTypes.simple;
  });

  if (lastSyncJob && typeFilter && typeFilter !== lastSyncJob.type) {
    lastSyncJob = null;
  }

  const isDataTransferCompletedRef = useRef(true);
  const onSuccessRef = useRef(onSuccess);
  const onFailureRef = useRef(onFailure);
  const onCompletedRef = useRef(onCompleted);

  const inProgress =
    lastSyncJob?.status === CatalogJobStatus.Pause ||
    lastSyncJob?.status === CatalogJobStatus.Pending ||
    lastSyncJob?.status === CatalogJobStatus.Active;

  useEffect(() => {
    onSuccessRef.current = onSuccess;
  }, [onSuccess]);

  useEffect(() => {
    onFailureRef.current = onFailure;
  }, [onFailure]);

  useEffect(() => {
    onCompletedRef.current = onCompleted;
  }, [onCompleted]);

  useEffect(() => {
    isDataTransferCompletedRef.current = true;
  }, [catalogId, integrationId]);

  useEffect(() => {
    if (!lastSyncJob) return;

    if (isDataTransferCompletedRef.current) {
      if (inProgress) {
        updateExportType(catalogId, !catalogWithSimpleExport);

        isDataTransferCompletedRef.current = false;
      }
    } else {
      if (lastSyncJob.status === CatalogJobStatus.Success) {
        onSuccessRef.current?.();
      }

      if (lastSyncJob.status === CatalogJobStatus.Fail) {
        onFailureRef.current?.(lastSyncJob.error || 'unknown error');
      }

      if (lastSyncJob.status === CatalogJobStatus.Success || lastSyncJob.status === CatalogJobStatus.Fail) {
        onCompletedRef.current?.(lastSyncJob);
        isDataTransferCompletedRef.current = true;
      }
    }
  }, [inProgress, lastSyncJob, catalogId, catalogWithSimpleExport, updateExportType]);

  return {
    inProgress,
    errorMessage: lastSyncJob?.error as string | undefined,
    startTime: lastSyncJob?.startTime,
    endTime: lastSyncJob?.endTime,
    type: lastSyncJob?.type,
  };
};

export default useDataTransferStatus;
