import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { ApolloError, useMutation, useQuery } from '@apollo/client';

import Loader from 'src/components/Loader';
import Page from 'src/components/Page';
import {
  CATALOGS_PATH,
  CREATION,
  FIRST_DASHBOARD_PATHNAME,
  MAPPINGS,
  PRODUCT_TYPES_PATH,
} from 'src/constants/routeSources';
import { useSnackbar } from 'src/providers/snackbar';
import {
  getProductTypeById,
  Query,
  QueryGetProductTypeArgs,
  updateProductTypeById,
  integrationProductTypeFieldMappings,
} from 'src/utils/gql';
import { locationName } from 'src/utils/locationArrays';

import { popoverActions } from './SaveButtonWithMenu';
import { ProductTypeSettingsField, ProductTypeSettingsPage } from './types';

import ProductTypeSettings from '.';

interface ProductTypeSettingsEditingProps {
  type: ProductTypeSettingsPage;
}

const ProductTypeSettingsEditing = ({ type = ProductTypeSettingsPage.Fields }: ProductTypeSettingsEditingProps) => {
  const { t } = useTranslation();
  const { id, productTypeId } = useParams<{ id: string; productTypeId: string }>();
  const snackbar = useSnackbar();
  const history = useHistory();

  const [updateProductType, { loading: updateProductTypeLoading }] = useMutation(updateProductTypeById);

  const [isUpdateSuccessful, setIsUpdateSuccessful] = useState<boolean>(false);

  const refetchQueries = [
    { query: getProductTypeById, variables: { id: productTypeId } },
    { query: integrationProductTypeFieldMappings, variables: { productTypeId } },
  ];

  const { data, loading } = useQuery<Pick<Query, 'getProductType'>, QueryGetProductTypeArgs>(getProductTypeById, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: { id: productTypeId },
  });

  const initialState = useMemo(
    () =>
      data &&
      data.getProductType.fields.reduce(
        (acc: { customFields: ProductTypeSettingsField[]; defaultFields: ProductTypeSettingsField[] }, curr) => {
          if (curr.systemName) {
            acc.defaultFields.push(curr as ProductTypeSettingsField);
          } else {
            acc.customFields.push(curr as ProductTypeSettingsField);
          }

          return acc;
        },
        { customFields: [], defaultFields: [] },
      ),
    [data],
  );

  const handleUpdateProductType = async (
    action: popoverActions,
    fields: ProductTypeSettingsField[],
    productTypeName: string,
    fieldsIdsToDelete: string[] = [],
  ) => {
    let link = '';
    try {
      await updateProductType({
        variables: {
          data: {
            id: productTypeId,
            name: productTypeName,
            fields,
            fieldsIdsToDelete,
          },
        },
        refetchQueries,
      });

      setIsUpdateSuccessful(!isUpdateSuccessful);

      snackbar(t('productType.productTypeHasBeenUpdated'), 'success');

      switch (action) {
        case popoverActions.save:
          link = `/${FIRST_DASHBOARD_PATHNAME}/${CATALOGS_PATH}/${id}/${PRODUCT_TYPES_PATH}`;
          break;

        case popoverActions.saveAndAdd:
          link = `/${FIRST_DASHBOARD_PATHNAME}/${CATALOGS_PATH}/${id}/${PRODUCT_TYPES_PATH}/${productTypeId}/${CREATION}`;
          break;

        case popoverActions.saveAndGoMappings:
          link = `/${FIRST_DASHBOARD_PATHNAME}/${CATALOGS_PATH}/${id}/${PRODUCT_TYPES_PATH}/${productTypeId}/${MAPPINGS}`;
          break;
      }

      history.push(link);
    } catch (error) {
      const { graphQLErrors, message: errorText } = error as ApolloError;
      const message = graphQLErrors && graphQLErrors.length ? graphQLErrors[0].message : errorText;

      if (error) {
        snackbar(message);
      }
    }
  };

  const title = t(
    type === ProductTypeSettingsPage.Fields ? 'productTypeEditingPageTitle' : 'productTypeMappingsPageTitle',
  );

  const location = `navBar.contextMenu.recentStepsTitles.productType${
    type === ProductTypeSettingsPage.Fields ? 'Editing' : 'Mappings'
  }`;

  return loading || !initialState || !data ? (
    <Loader fullAvailableScreen />
  ) : (
    <Page title={title} locationName={locationName(t(location), data.getProductType.name)}>
      <ProductTypeSettings
        initialDefaultFields={initialState.defaultFields}
        initialCustomFields={initialState.customFields}
        initialProductTypeName={data.getProductType.name}
        onSave={handleUpdateProductType}
        shouldBreadcrumbsFollowName
        isSubmitSuccessful={isUpdateSuccessful}
        showProductItemLink
        type={type}
        updateProductTypeLoading={updateProductTypeLoading}
        productTypeErrors={data.getProductType?.mappingsErrors}
      />
    </Page>
  );
};

export default ProductTypeSettingsEditing;
