import React, { useContext, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation, useHistory } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, DialogContent, Typography } from '@material-ui/core';

import ButtonScrollToTop from 'src/components/ButtonScrollToTop';
import { productTypeNameFormSchema } from 'src/components/Forms/validationSchemas';
import Popup from 'src/components/Popup';
import DiscardChangesPopup from 'src/components/Popups/DiscardChangesPopup';
import SaveAndGoMappingsPopup from 'src/components/Popups/SaveAndGoMappingsPopup';
import { CATALOGS_PATH, FIRST_DASHBOARD_PATHNAME, MAPPINGS, PRODUCT_TYPES_PATH } from 'src/constants/routeSources';
import useDebounceValue from 'src/hooks/useDebounceValue';
import { MediaStateContext } from 'src/providers/MediaProvider/context';
import useOnboarding from 'src/providers/OnboardingProvider/hooks/useOnboarding';
import { FieldSystemName, FieldType, IntegrationMappingError } from 'src/utils/gql';
import { locationName, updateLocationsData } from 'src/utils/locationArrays';
import { popoverActions } from 'src/views/Catalogs/ProductTypes/ProductTypeSettings/SaveButtonWithMenu';

import FieldsTabContent from './FieldsTabContent';
import MappingTabContent from './MappingTabContent';
import ProductTypeFieldSettingsSidebar from './ProductTypeFieldSettingsSidebar';
import ProductTypeSettingsHeader from './ProductTypeSettingsHeader';
import {
  ProductTypeSettingsState,
  ProductTypeSettingsField,
  ProductTypeSettingsPage,
  CustomFieldsProps,
} from './types';

interface ProductTypeSettingsProps {
  initialDefaultFields: ProductTypeSettingsField[];
  initialCustomFields: ProductTypeSettingsField[];
  onSave: (
    action: popoverActions,
    fields: ProductTypeSettingsField[],
    productTypeName: string,
    fieldsIdsToDelete?: string[],
  ) => void;
  initialProductTypeName: string;
  shouldBreadcrumbsFollowName: boolean;
  isSubmitSuccessful: boolean;
  showProductItemLink?: boolean;
  type: ProductTypeSettingsPage;
  updateProductTypeLoading?: boolean;
  productTypeErrors?: IntegrationMappingError[];
}

const ProductTypeSettings = ({
  initialDefaultFields,
  initialCustomFields,
  onSave,
  initialProductTypeName,
  shouldBreadcrumbsFollowName,
  isSubmitSuccessful,
  showProductItemLink = false,
  type,
  updateProductTypeLoading,
  productTypeErrors,
}: ProductTypeSettingsProps) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const history = useHistory();
  const { id, productTypeId } = useParams<{ id: string; productTypeId: string }>();
  const {
    onboardingState: { tourActive },
  } = useOnboarding();

  const [activeFieldData, setActiveFieldData] = useState<ProductTypeSettingsField | null>(null);
  const [activeFieldId, setActiveFieldId] = useState<number | null>(null);
  const [selectedFields, setSelectedFields] = useState<string[]>([]);
  const [canBeDeleted, setCanBeDeleted] = useState<CustomFieldsProps[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { setMediaState } = useContext(MediaStateContext);

  const initialState = {
    productTypeName: initialProductTypeName,
    defaultFields: initialDefaultFields,
    customFields: initialCustomFields,
    isCreationSuccess: false,
    isUpdateSuccess: false,
    isFieldSettingsOpen: false,
    isDeleteFieldPopupOpen: false,
    hasRequiredRelations: false,
    action: popoverActions.save,
  };

  const [productTypeSettingsState, setProductTypeSettingsState] = useState<ProductTypeSettingsState>(initialState);

  const formProps = useForm<{ productName: string }>({
    mode: 'onChange',
    resolver: yupResolver(productTypeNameFormSchema),
    defaultValues: {
      productName: productTypeSettingsState.productTypeName,
    },
  });

  const debouncedProductTypeName = useDebounceValue(formProps.watch('productName'), 500);

  const hasPageDataChanges =
    (JSON.stringify(initialState) !== JSON.stringify(productTypeSettingsState) ||
      formProps.watch('productName') !== initialState.productTypeName) &&
    !isSubmitSuccessful;

  const onSaveButtonClick = (action: popoverActions) => {
    if (!!selectedFields.length) {
      setProductTypeSettingsState((prevState) => ({ ...prevState, isDeleteFieldPopupOpen: true, action }));

      const requiredFields = canBeDeleted.filter(({ hasFieldRequiredRelation }) => hasFieldRequiredRelation);
      const hasRequiredRelations = requiredFields.find(({ customFieldId }) => selectedFields.includes(customFieldId));

      if (hasRequiredRelations) {
        setProductTypeSettingsState((prevState) => ({ ...prevState, hasRequiredRelations: true, action }));
      }
    } else {
      handleSave(action);
      setMediaState([]);
    }
  };

  const goToMappings = () => {
    if (hasPageDataChanges || !!selectedFields.length) {
      return setIsOpen(true);
    }

    history.push(
      `/${FIRST_DASHBOARD_PATHNAME}/${CATALOGS_PATH}/${id}/${PRODUCT_TYPES_PATH}/${productTypeId}/${MAPPINGS}`,
    );
  };

  const handleSave = (action: popoverActions) => {
    const preparedProductTypeFields = [
      ...productTypeSettingsState.defaultFields,
      ...productTypeSettingsState.customFields,
    ].map(({ id, name, required, showInFilters, showInPreview, type, defaultValue, systemName }) => ({
      ...(id && { id }),
      name: name ? name.trim() : (systemName as FieldSystemName),
      systemName,
      showInFilters,
      showInPreview,
      type: type.toLowerCase() as FieldType,
      required,
      defaultValue,
    }));

    onSave(action, preparedProductTypeFields, formProps.watch('productName'), selectedFields);
    updateLocationsData(
      pathname,
      locationName(t('navBar.contextMenu.recentStepsTitles.productTypeEditing'), formProps.watch('productName')),
    );
  };

  const hasSameFieldName = (currentName: string, indexNumber: number | null) =>
    [...productTypeSettingsState.customFields, ...productTypeSettingsState.defaultFields].some((field, idx) => {
      if (indexNumber === null && field.name) {
        return currentName.toLowerCase().trim() === field.name.toLowerCase().trim();
      }

      if (indexNumber !== null) {
        return (
          indexNumber + productTypeSettingsState.defaultFields.length !== idx &&
          field.name?.toLowerCase().trim() === currentName?.toLowerCase().trim()
        );
      }
    });

  const handleAddCustomField = (customFieldData: ProductTypeSettingsField) => {
    setProductTypeSettingsState((prevState: ProductTypeSettingsState) => ({
      ...prevState,
      customFields: [...prevState.customFields, customFieldData],
    }));
  };

  const editFieldSettings = (field: ProductTypeSettingsField, fieldIndex: number) => {
    setActiveFieldId(fieldIndex);
    setActiveFieldData(field);
    handleOpenFieldSettings();
  };

  const handleChangeDefaultFieldShowInPreviewFlag = (showInPreview: boolean, index: number) => {
    const updatedDefaultFields = productTypeSettingsState.defaultFields.map((field, idx) => {
      if (idx === index) {
        return { ...field, showInPreview };
      }

      return field;
    });

    setProductTypeSettingsState((prevState: ProductTypeSettingsState) => ({
      ...prevState,
      defaultFields: updatedDefaultFields,
    }));
  };

  const handleEditCustomField = (field: ProductTypeSettingsField, index: number) => {
    const customFields = productTypeSettingsState.customFields.concat();
    customFields[index] = field;

    setProductTypeSettingsState((prevState: ProductTypeSettingsState) => ({
      ...prevState,
      customFields,
    }));
  };

  const handleOpenFieldSettings = () =>
    setProductTypeSettingsState((prevState) => ({ ...prevState, isFieldSettingsOpen: true }));

  const handleCloseFieldSettings = () => {
    setProductTypeSettingsState((prevState) => ({ ...prevState, isFieldSettingsOpen: false }));
    handleResetActiveFieldData();
  };

  const handleResetActiveFieldData = () => {
    setActiveFieldData(null);
    setActiveFieldId(null);
  };

  const handleSelectFieldForDeletion = (index: number) => {
    const fieldId = productTypeSettingsState.customFields[index].id;

    if (fieldId) {
      setSelectedFields((prev) => (prev.includes(fieldId) ? prev.filter((id) => id !== fieldId) : [...prev, fieldId]));
    } else {
      const customFields = productTypeSettingsState.customFields.concat();
      customFields.splice(index, 1);

      setProductTypeSettingsState((prevState) => ({
        ...prevState,
        customFields,
      }));
    }
  };

  const showInPreviewFieldsCount = useMemo(
    () =>
      [...productTypeSettingsState.customFields, ...productTypeSettingsState.defaultFields].reduce(
        (counter: number, { showInPreview }) => counter + Number(showInPreview),
        0,
      ),
    [productTypeSettingsState.customFields, productTypeSettingsState.defaultFields],
  );

  const fieldSettingsOpen = productTypeSettingsState.isFieldSettingsOpen;

  const handleCloseSavePopup = () =>
    setProductTypeSettingsState((prevState) => ({
      ...prevState,
      isDeleteFieldPopupOpen: false,
      hasRequiredRelations: false,
    }));

  return (
    <Box width="100%" height="100%">
      <FormProvider {...formProps}>
        <ProductTypeSettingsHeader
          catalogId={id}
          type={type}
          productTypeId={productTypeId}
          debouncedProductTypeName={debouncedProductTypeName}
          handleSaveProductType={onSaveButtonClick}
          shouldBreadcrumbsFollowName={shouldBreadcrumbsFollowName}
          initialProductTypeName={productTypeSettingsState.productTypeName}
          showProductItemLink={showProductItemLink}
          isSidebarOpen={productTypeSettingsState.isFieldSettingsOpen}
        />
      </FormProvider>

      {type === ProductTypeSettingsPage.Fields ? (
        <FieldsTabContent
          productTypeSettingsState={productTypeSettingsState}
          editFieldSettings={editFieldSettings}
          onOpenFieldSettings={handleOpenFieldSettings}
          onDeleteCustomField={handleSelectFieldForDeletion}
          selectedFieldsForDeletion={selectedFields}
          goToMappings={goToMappings}
          creatingProductType={!shouldBreadcrumbsFollowName}
          setCanBeDeleted={setCanBeDeleted}
          productTypeErrors={productTypeErrors}
        />
      ) : (
        <MappingTabContent />
      )}

      {fieldSettingsOpen && (
        <ProductTypeFieldSettingsSidebar
          openSidebar={fieldSettingsOpen}
          handleCloseSidebar={handleCloseFieldSettings}
          handleAddCustomField={handleAddCustomField}
          activeFieldData={activeFieldData}
          resetActiveFieldData={handleResetActiveFieldData}
          handleEditCustomField={handleEditCustomField}
          activeFieldId={activeFieldId}
          handleChangeDefaultFieldShowInPreviewFlag={handleChangeDefaultFieldShowInPreviewFlag}
          productTypeId={productTypeId}
          handleCheckDuplicates={hasSameFieldName}
          showInPreviewFieldsCount={showInPreviewFieldsCount}
        />
      )}

      <ButtonScrollToTop intersection={document.documentElement.clientHeight} />

      <Popup
        open={productTypeSettingsState.isDeleteFieldPopupOpen}
        mainTitle={t('productType.popupDeleteSelectedProductTypeFields.title')}
        mainButtonText={t('productType.popupDeleteSelectedProductTypeFields.mainButton')}
        onMainButtonClick={() => handleSave(productTypeSettingsState.action)}
        secondaryButtonText={t('productType.popupDeleteSelectedProductTypeFields.secondaryButton')}
        onClose={handleCloseSavePopup}
        maxWidth="sm"
      >
        <DialogContent>
          {productTypeSettingsState.hasRequiredRelations ? (
            <Typography margin="0 40px" variant="body1" color="text.secondary">
              {t('productType.popupDeleteSelectedProductTypeFields.contentTextRemoveRequiredFields')}
            </Typography>
          ) : (
            <Typography margin="0 40px" variant="body1" color="text.secondary">
              {t('productType.popupDeleteSelectedProductTypeFields.contentText')}
            </Typography>
          )}
        </DialogContent>
      </Popup>

      <SaveAndGoMappingsPopup
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onMainButtonClick={() => handleSave(popoverActions.saveAndGoMappings)}
        onSecondaryButtonClick={() => setIsOpen(false)}
        loadingOnMainButton={updateProductTypeLoading}
      />

      <DiscardChangesPopup isOpen={hasPageDataChanges && !tourActive} />
    </Box>
  );
};

export default ProductTypeSettings;
