import React from 'react';

import { makeStyles, Theme } from '@material-ui/core/styles';
import MuiTypogpaphy, { TypographyProps } from '@material-ui/core/Typography';
import clsx from 'clsx';

interface GradientTypographyProps extends TypographyProps {
  isHoverable?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  gradientText: {
    display: 'inline',
    backgroundColor: theme.palette.secondary.dark,
    WebkitTextFillColor: 'transparent',
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
  },
  hoverable: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

function GradientTypography({ isHoverable, ...props }: GradientTypographyProps) {
  const classes = useStyles();
  return (
    <MuiTypogpaphy className={clsx(classes.gradientText, isHoverable && classes.hoverable)} {...props}>
      {props.children}
    </MuiTypogpaphy>
  );
}

export default GradientTypography;
