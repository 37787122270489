import React from 'react';

import { Typography } from '@material-ui/core';
import { ListItemButton, ListItemButtonProps } from '@mui/material';
import clsx from 'clsx';

import palette from '../../theme/palette';
import GradientTypography from '../GradientTypography';
import Iconography from '../Iconography';

import { useNavbarListItemButtonStyles } from './styled';

interface NavBarListItem extends ListItemButtonProps {
  iconName: string;
  isActive: boolean;
  isExpanded: boolean;
  handleActiveItem: () => void;
  isCompact?: boolean;
  testId: string;
}

function NavBarListItem(props: NavBarListItem) {
  const classes = useNavbarListItemButtonStyles();
  const { iconName, children, isActive, isExpanded, handleActiveItem, isCompact, testId } = props;
  return (
    <ListItemButton
      disableRipple
      className={
        isActive
          ? clsx(classes.navbarItem, classes.navbarItemActive, isCompact ? classes.navbarCompactItem : '')
          : clsx(classes.navbarItem, isCompact ? classes.navbarCompactItem : '')
      }
      onClick={handleActiveItem}
      data-testid={testId}
    >
      <Iconography iconName={iconName} htmlColor={isActive ? palette.secondary.main : palette.background.default} />
      {isExpanded && (
        <>
          {isActive ? (
            <GradientTypography variant="h3" fontWeight="normal" ml="15px">
              {children}
            </GradientTypography>
          ) : (
            <Typography variant="h3" fontWeight="normal" ml="15px" color={palette.background.default}>
              {children}
            </Typography>
          )}
        </>
      )}
    </ListItemButton>
  );
}

export default NavBarListItem;
