export const getImagePreview = (file: File, callback: CallableFunction) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    return callback(reader.result);
  };
};

// https://www.hacksoft.io/blog/handle-images-cors-error-in-chrome
export const disableChromeCacheForUrl = (url: string): string => `${url}?slava-cheat-code`;
