import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';
import { Box, makeStyles, Theme, Typography, useTheme } from '@material-ui/core';

import Iconography from 'src/components/Iconography';
import SubscriptionsPopup from 'src/components/Popups/SubscriptionsPopup';
import { SharedCataloguesSubscriptionsActions } from 'src/components/SharedCatalogsTable/types';
import { linkPricingPlan, SHARED_CATALOGUES_SUBSCRIPTION_STATUSES } from 'src/constants';
import { FIRST_DASHBOARD_PATHNAME, SHARED_CATALOGS } from 'src/constants/routeSources';
import useControlSubscriptions from 'src/hooks/useControlSubscriptions';
import useSharedCatalogs from 'src/providers/SharedCataloguesProvider';
import { checkIsLimitReached, getCatalogInfo, LimitType } from 'src/utils/gql';

import Button from '../Button';
import HintLimitButton from '../HintLimitButton';

import {
  PreviewArrowIconButton,
  PreviewCatalogStatusContainer,
  PreviewClosePageButton,
  PreviewHeaderCore,
  StyledUpgradeLink,
} from './styled';

interface SharedCatalogPreviewHeaderProps {
  title: string;
  hasArrowBack?: boolean;
  countInfo?: string;
}

const useStyles = makeStyles<Theme>(() => ({
  header: {
    position: 'relative',
    padding: '15px 56px 30px 40px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const SharedCatalogPreviewHeader = ({ title, hasArrowBack, countInfo }: SharedCatalogPreviewHeaderProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const {
    sharedCatalogInfo: { catalogId, catalogName, catalogOwnerName },
  } = useSharedCatalogs();
  const params = useParams<{ catalogId: string }>();

  const { data: checkIsLimitReachedData } = useQuery(checkIsLimitReached, {
    fetchPolicy: 'network-only',
    variables: { type: LimitType.Subscriptions },
  });

  const { data: catalogData, loading: catalogDataLoading } = useQuery(getCatalogInfo, {
    fetchPolicy: 'cache-first',
    variables: { id: params.catalogId },
  });

  const subscription = catalogData?.getCatalogById.subscriptions[0];

  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);

  const subscriptionInfo = subscription && SHARED_CATALOGUES_SUBSCRIPTION_STATUSES[subscription.approvalStatus];

  const subscriptionAction =
    subscriptionInfo && subscriptionInfo.action
      ? subscriptionInfo.action
      : SharedCataloguesSubscriptionsActions.request;

  const { onRequestSubscription, onCancelSubscription, loadingStatus } = useControlSubscriptions({
    catalogId,
    subscriptionId: subscription ? subscription.id : null,
  });

  const handleSubscriptionStatus = async () => {
    if (subscriptionAction === SharedCataloguesSubscriptionsActions.request) {
      await onRequestSubscription();
    }

    if (subscriptionAction === SharedCataloguesSubscriptionsActions.cancel) {
      await onCancelSubscription();
    }

    handleClosePopup();
  };

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const limitReached = checkIsLimitReachedData?.checkIsLimitReached;

  const handleClosePopup = () => setIsPopupOpen(false);

  return (
    <>
      <Box className={classes.header}>
        <PreviewHeaderCore>
          {hasArrowBack && (
            <PreviewArrowIconButton onClick={() => history.goBack()} data-testid="backArrow">
              <Iconography iconName="arrow-left" htmlColor={theme.palette.text.primary} />
            </PreviewArrowIconButton>
          )}
          <Typography
            variant="h2"
            color="text.primary"
            whiteSpace="pre"
            maxWidth="320px"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {title}
          </Typography>
          {!catalogDataLoading &&
            (subscriptionInfo ? (
              <PreviewCatalogStatusContainer sx={{ bgcolor: subscriptionInfo?.textColor }}>
                <Typography variant="subtitle2" color="common.white">
                  {t(subscriptionInfo.statusText)}
                </Typography>
              </PreviewCatalogStatusContainer>
            ) : limitReached ? (
              <HintLimitButton
                tooltipWidth="340px"
                tooltipMarginLeft="13px"
                title={
                  <Trans i18nKey="sharedCatalogsPage.hint">
                    Subscription number has reached the limit.
                    <StyledUpgradeLink href={linkPricingPlan}>Upgrade your plan</StyledUpgradeLink>
                    to subscribe to an unlimited number of catalogs or unsubscribe from another catalog.
                  </Trans>
                }
              >
                <Box ml="25px">
                  <Button variant="outlined" disabled data-testid="subscribeDisabledButton">
                    {t('sharedCatalogsPage.subscribe')}
                  </Button>
                </Box>
              </HintLimitButton>
            ) : (
              <Box ml="25px">
                <Button variant="outlined" onClick={handleOpenPopup} data-testid="subscribeButton">
                  {t('sharedCatalogsPage.subscribe')}
                </Button>
              </Box>
            ))}
          {countInfo && (
            <Typography variant="subtitle2" color="text.disabled" ml="25px">
              {countInfo}
            </Typography>
          )}
        </PreviewHeaderCore>
        <PreviewClosePageButton
          onClick={() => history.push(`/${FIRST_DASHBOARD_PATHNAME}/${SHARED_CATALOGS}`)}
          data-testid="crossButton"
        >
          <Iconography iconName="cancel" htmlColor="secondary" />
        </PreviewClosePageButton>
      </Box>

      <SubscriptionsPopup
        isOpen={isPopupOpen}
        handleMainButtonClick={handleSubscriptionStatus}
        handleClosePopup={handleClosePopup}
        subscriptionAction={subscriptionAction as string}
        catalogOwner={catalogOwnerName}
        catalogName={catalogName}
        loadingStatus={loadingStatus}
      />
    </>
  );
};

export default SharedCatalogPreviewHeader;
