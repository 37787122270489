import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IconButton } from '@material-ui/core';
import { Box, capitalize, Typography } from '@mui/material';
import moment from 'moment';

import { ActionType, ActionTypeLabel, DetailsPopupState, EntityName, EntityType } from 'src/views/AuditLog/types';

import Iconography from '../../Iconography';
import Popup from '../../Popup';

import {
  CatalogDetails,
  CatalogImportExportDetails,
  IntegrationDetails,
  PlanSubscriptionDetails,
  ProductItemDetails,
  ProductTypeDetails,
  UserDetails,
} from './Details';
import { Details, MoreDetails, StyledCollapse } from './styled';

interface AuditLogDetailsPopupProps {
  state: DetailsPopupState;
  onClose: () => void;
}

const AuditLogDetailsPopup = ({ state: { isOpen, data }, onClose }: AuditLogDetailsPopupProps) => {
  const { t } = useTranslation();

  const { action, entity, metadata, createdAt } = data;

  const [isDetailsOpen, setIsDetailsOpen] = useState<boolean>(true);

  const mainDetails = [
    {
      label: 'auditLogPage.detailsPopup.event',
      value: t(ActionTypeLabel[action as keyof typeof ActionTypeLabel]),
    },
    {
      label: 'auditLogPage.detailsPopup.place',
      value: t(EntityName[entity as keyof typeof EntityName]),
    },
    { label: 'auditLogPage.detailsPopup.time', value: moment(createdAt).format('MMM DD, YYYY H:mm') },
  ];

  const parseDetails = () => {
    switch (entity) {
      case EntityType.User:
        return <UserDetails metadata={metadata} action={action} />;

      case EntityType.Catalog:
        if (action === ActionType.Import || action === ActionType.Export) {
          return <CatalogImportExportDetails metadata={metadata} action={action} />;
        }
        return <CatalogDetails metadata={metadata} action={action} />;

      case EntityType.Product:
        return <ProductTypeDetails metadata={metadata} action={action} />;

      case EntityType.Item:
        return <ProductItemDetails metadata={metadata} action={action} />;

      case EntityType.Integration:
        return <IntegrationDetails metadata={metadata} action={action} />;

      case EntityType.PlanSubscription:
        return <PlanSubscriptionDetails />;
    }
  };

  const handleClose = () => {
    onClose();
    setIsDetailsOpen(true);
  };

  return (
    <Popup
      maxWidth="lg"
      open={isOpen}
      mainTitle={t('auditLogPage.detailsPopup.title')}
      mainButtonText={t('auditLogPage.detailsPopup.mainButton')}
      onClose={handleClose}
      onMainButtonClick={handleClose}
    >
      <Box display="flex" justifyContent="center">
        <Box display="flex" flexDirection="column" alignItems="flex-start" width="90%">
          {mainDetails.map(({ label, value }, idx) => (
            <Typography key={idx} color="text.secondary" variant="subtitle1" mb="5px">
              {t(label)}
              <Typography component="span" color="text.secondary">
                {typeof value === 'string' ? capitalize(value) : value}
              </Typography>
            </Typography>
          ))}

          <MoreDetails open={isDetailsOpen}>
            <Typography color="inherit" variant="subtitle1">
              {t('auditLogPage.detailsPopup.moreDetails')}
            </Typography>

            <IconButton size="small" onClick={() => setIsDetailsOpen(!isDetailsOpen)}>
              <Iconography iconName="expand-chevron" color="inherit" />
            </IconButton>
          </MoreDetails>

          <StyledCollapse in={isDetailsOpen}>
            <Details>
              <Box display="flex" width="100%" flexDirection="column">
                {parseDetails()}
              </Box>
            </Details>
          </StyledCollapse>
        </Box>
      </Box>
    </Popup>
  );
};

export default AuditLogDetailsPopup;
