import React from 'react';

import { createStyles, Drawer, Fade, makeStyles, Theme, useTheme } from '@material-ui/core';

import Iconography from 'src/components/Iconography';

import { onClickHandler } from '../../views/types';

import { OpenCloseIcon, SideBarContainer, DrawerContentContainer } from './styled';

const useStyles = makeStyles<Theme, SideBarProps>((theme: Theme) =>
  createStyles({
    openedDrawer: {
      width: (props) => props.width,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    closedDrawer: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: `calc(${theme.spacing(7)} + 1px)`,
      [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(7)} + 1px)`,
      },
    },
  }),
);

interface SideBarProps {
  isExpanded?: boolean;
  title?: string;
  children?: React.ReactNode;
  onClick: onClickHandler;
  width: string;
  showExpandButton?: boolean;
}

const SideBar = (props: SideBarProps) => {
  const classes = useStyles(props);
  const theme = useTheme();
  const { isExpanded, onClick, children, showExpandButton = true } = props;

  return (
    <Drawer
      open={true}
      variant="persistent"
      PaperProps={{
        style: {
          border: 'none',
          marginLeft: '-16px',
          background: theme.palette.common.white,
          boxShadow: 'inset 0 -4px 15px rgba(46, 96, 170, 0.1)',
          height: '100vh',
          overflow: isExpanded ? '' : 'hidden',
          zIndex: 10,
        },
      }}
      className={isExpanded ? classes.openedDrawer : classes.closedDrawer}
    >
      <DrawerContentContainer className={isExpanded ? 'expanded' : ''} id="sideBarId">
        {showExpandButton && (
          <OpenCloseIcon onClick={onClick} className={isExpanded ? 'expanded' : ''}>
            <Iconography iconName="double-chevron-arrow-right" color="primary" />
          </OpenCloseIcon>
        )}
        <Fade in={isExpanded} {...(isExpanded ? { timeout: 1000 } : { timeout: 350 })}>
          <SideBarContainer id="sideBarContainer">{children}</SideBarContainer>
        </Fade>
      </DrawerContentContainer>
    </Drawer>
  );
};

export default SideBar;
