import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useQuery } from '@apollo/client';
import { Popover } from '@material-ui/core';

import Button from 'src/components/Button';
import Iconography from 'src/components/Iconography';
import { useLimitReached } from 'src/providers/limitReached';
import useOnboarding from 'src/providers/OnboardingProvider/hooks/useOnboarding';
import { useEnterClick } from 'src/utils/enterEffectFactory';
import { checkIsLimitReached, LimitType } from 'src/utils/gql';

import { CloseButton, SaveButtonLink, StyledBtnContainer, StyledMenuContainer } from './styled';

interface SaveButtonWithMenuProps {
  loading?: boolean;
  disabled: boolean;
  onClick: (action: popoverActions) => void;
  isSidebarOpen: boolean;
}

export enum popoverActions {
  save,
  saveAndAdd,
  saveAndGoMappings,
}

const SaveButtonWithMenu = ({ disabled, onClick, loading, isSidebarOpen }: SaveButtonWithMenuProps) => {
  const { t } = useTranslation();
  const limitReached = useLimitReached();
  const anchorElRef = useRef(null);
  const {
    onboardingState: { tourActive },
    goNext,
  } = useOnboarding();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isOpenPopover, setIsOpenPopover] = useState<boolean>(false);

  const { data: checkIsLimitReachedData } = useQuery(checkIsLimitReached, {
    fetchPolicy: 'network-only',
    variables: { type: LimitType.Items },
  });

  const handleClick = () => {
    setIsOpenPopover(true);

    if (tourActive) {
      setTimeout(() => {
        goNext();
      }, 150);
    }
  };

  const handleClose = () => {
    setIsOpenPopover(false);
  };

  const onClickSaveButtonLink = (text: popoverActions) => {
    if (text === popoverActions.saveAndAdd && checkIsLimitReachedData?.checkIsLimitReached) {
      limitReached({ limitReachedType: LimitType.Items });
    } else {
      onClick(text);
    }

    setIsOpenPopover(false);
  };

  useEnterClick(() => {
    if (!(disabled || isSidebarOpen)) {
      isOpenPopover && onClick(popoverActions.save);
      setIsOpenPopover(true);
    }
  });

  useEffect(() => {
    if (!anchorEl) {
      setAnchorEl(anchorElRef.current);
    }
  }, [anchorElRef, anchorEl]);

  return (
    <>
      <div ref={anchorElRef}>
        <Button
          id="save-btn-anchor"
          loading={loading}
          disabled={disabled}
          onClick={handleClick}
          variant="contained"
          data-testid="saveButton"
        >
          {t('productType.header.saveButton')}
        </Button>
      </div>

      <Popover
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={anchorEl}
        open={isOpenPopover}
        onClose={handleClose}
        style={{ left: '-10px' }}
      >
        <StyledMenuContainer>
          <StyledBtnContainer onClick={() => onClickSaveButtonLink(popoverActions.save)} data-testid="saveActionButton">
            <SaveButtonLink variant="subtitle2" color="text.secondary">
              {t('productType.header.saveAction')}
            </SaveButtonLink>
          </StyledBtnContainer>

          <StyledBtnContainer
            id="save-and-create-btn-anchor"
            onClick={() => onClickSaveButtonLink(popoverActions.saveAndAdd)}
            data-testid="saveAndAddActionButton"
          >
            <SaveButtonLink variant="subtitle2" color="text.secondary">
              {t('productType.header.saveAndAddAction')}
            </SaveButtonLink>
          </StyledBtnContainer>

          <CloseButton onClick={handleClose} tabIndex={0}>
            <Iconography iconName="cancel" color="primary" />
          </CloseButton>
        </StyledMenuContainer>
      </Popover>
    </>
  );
};

export default SaveButtonWithMenu;
