import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { IconButton, makeStyles, Theme, Typography, Box } from '@material-ui/core';
import { useScrollTrigger } from '@mui/material';

import {
  CATALOGS_PATH,
  EDITING,
  FIRST_DASHBOARD_PATHNAME,
  PRODUCT_ITEMS_PATH,
  PRODUCT_TYPES_PATH,
} from 'src/constants/routeSources';
import { ButtonWithMargin } from 'src/views/Catalogs/ProductItems/styled';

import DashboardBreadCrumbs from '../Breadcrumds/DashboardBreadCrumbs';
import Button from '../Button';
import Iconography from '../Iconography';

import DraftCheckBox from './DraftCheckBox';
import { FlexRowSpaceBetweenCenterWrapper, ItemDetailsHeaderWrapper, ItemDetailsMainHeaderWrapper } from './styled';

const useStyles = makeStyles<Theme>((theme) => ({
  icon: {
    color: theme.palette.text.primary,
  },
  showShadow: {
    boxShadow: '0 2px 10px rgba(46, 96, 170, 0.25)',
  },
  completenessText: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}));

interface ItemsDetailsHeaderProps {
  onDelete: () => void;
  onSave: () => void;
  saveButtonLoading: boolean;
  editingName?: string;
  onCompletenessTextClick: () => void;
}

const FIXED_HEADER_STARTING_POINT = 56;

const ItemsDetailsHeader = ({
  onSave,
  saveButtonLoading,
  editingName,
  onDelete,
  onCompletenessTextClick,
}: ItemsDetailsHeaderProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const { catalogId, productTypeId } = useParams<{
    catalogId: string;
    productTypeId: string;
  }>();
  const scrollTrigger = useScrollTrigger({
    threshold: FIXED_HEADER_STARTING_POINT,
  });

  const {
    formState: { isValid, errors },
  } = useFormContext();

  const handleCancelClick = () =>
    history.push(
      `/${FIRST_DASHBOARD_PATHNAME}/${CATALOGS_PATH}/${catalogId}/${PRODUCT_TYPES_PATH}/${productTypeId}/${PRODUCT_ITEMS_PATH}`,
    );

  return (
    <ItemDetailsHeaderWrapper className={scrollTrigger ? classes.showShadow : ''} top={-FIXED_HEADER_STARTING_POINT}>
      <DashboardBreadCrumbs editingName={editingName} />

      <ItemDetailsMainHeaderWrapper>
        <FlexRowSpaceBetweenCenterWrapper>
          <Typography variant="h2" height="28px">
            {t('productItemCreateEdit.sidebar.title')}
          </Typography>

          <IconButton data-testid="deleteButton" onClick={onDelete} href="" size="small" disabled={saveButtonLoading}>
            <Iconography iconName="trash-fill" className={classes.icon} />
          </IconButton>
        </FlexRowSpaceBetweenCenterWrapper>

        <FlexRowSpaceBetweenCenterWrapper>
          {history.location.pathname.includes(EDITING) && (
            <Box mr="25px" data-testid="itemCompletenessLink">
              <Typography
                fontSize="15px"
                color="secondary.dark"
                className={classes.completenessText}
                onClick={onCompletenessTextClick}
              >
                {t('productItemCreateEdit.sidebar.completeness')}
              </Typography>
            </Box>
          )}

          <DraftCheckBox disabled={saveButtonLoading} />

          <Button
            variant="outlined"
            disabled={saveButtonLoading}
            onClick={handleCancelClick}
            data-testid="cancelButtonItemCreation"
          >
            {t('cancel')}
          </Button>

          <ButtonWithMargin
            id="save-btn-anchor"
            disabled={!(isValid && !Object.values(errors).length)}
            loading={saveButtonLoading}
            onClick={onSave}
            variant="contained"
            data-testid="saveButtonItemCreation"
          >
            {t('productItemCreateEdit.sidebar.saveButton')}
          </ButtonWithMargin>
        </FlexRowSpaceBetweenCenterWrapper>
      </ItemDetailsMainHeaderWrapper>
    </ItemDetailsHeaderWrapper>
  );
};

export default ItemsDetailsHeader;
