import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Box, Typography, FormControlLabel, Checkbox, LinearProgress } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useFlag } from '@unleash/proxy-client-react';

import Button from 'src/components/Button';
import { FeatureFlag } from 'src/constants';
import { Maybe, PlanSubscriptionStatsResponse } from 'src/utils/gql';

enum PricingPlans {
  Basic = 'Basic',
  Otomatorling = 'Otomatorling',
  Otomator = 'Otomator',
  Superotomator = 'Superotomator',
}

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    marginBottom: '24px',
    padding: '30px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '20px',
    boxShadow: '0 2px 10px rgba(46, 96, 170, 0.25)',

    '&:hover': {
      boxShadow: '0 2px 15px rgba(241, 97, 82, 0.35)',
    },

    '&& .MuiFormControlLabel-label': {
      color: theme.palette.text.secondary,
    },

    '& .MuiLinearProgress-root': {
      height: '6px',
      background: theme.palette.background.default,
      borderRadius: '5px',
    },
    '& .MuiLinearProgress-bar': {
      background: theme.palette.secondary.contrastText,
    },
  },
  greyText: {
    color: theme.palette.text.disabled,
    fontSize: '18px',
  },
  checkBox: {
    height: '18px',
  },
}));

interface UserAccountStatusCardProps {
  planSubscriptionStats: PlanSubscriptionStatsResponse;
}

interface StatusProps {
  title: string;
  currentNumber: number;
  limit?: Maybe<number>;
}

const Status = ({ title, currentNumber, limit }: StatusProps) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" width="100%" alignItems="center" mt="20px">
      <Typography variant="subtitle1" minWidth="fit-content">
        {title}
      </Typography>
      <Box width="inherit" height="fit-content" mr="20px" ml="20px">
        <LinearProgress variant="determinate" value={limit ? (currentNumber / limit) * 100 : 100} />
      </Box>
      <Typography variant="subtitle1" minWidth="fit-content">
        {Number.isInteger(limit) ? `${currentNumber}/${limit}` : t('userSettingsPage.account.status.unlimited')}
      </Typography>
    </Box>
  );
};

const UserAccountStatusCard = ({ planSubscriptionStats }: UserAccountStatusCardProps) => {
  const history = useHistory();
  const { t } = useTranslation();
  const classes = useStyles();
  const isSharedCatalogsFeatureActive = useFlag(FeatureFlag.SHARED_CATALOGS);

  const plan = planSubscriptionStats.planSubscription.plan;

  const isPlanUpgradable = plan.name !== PricingPlans.Superotomator;
  const isBillingFeatureActive = useFlag(FeatureFlag.BILLING);

  return (
    <Box className={classes.root}>
      <Box display="flex" justifyContent="space-between" alignItems="center" height="42px">
        <Typography variant="caption" className={classes.greyText} mr="15px">
          {t('userSettingsPage.account.status.currentStatus')}
        </Typography>
        {isBillingFeatureActive && isPlanUpgradable && (
          <Button
            onClick={() => history.push('/upgrade-pricing-plan')}
            variant="outlined"
            data-testid="upgradePricingPlanButton"
          >
            {t('userSettingsPage.account.status.button')}
          </Button>
        )}
      </Box>
      <Box display="flex" flexDirection="column" flexWrap="wrap" alignItems="center" mb="28px">
        <Status
          title={t('userSettingsPage.account.status.catalogs')}
          currentNumber={planSubscriptionStats.catalogsNumber}
          limit={plan.catalogsLimit}
        />
        <Status
          title={t('userSettingsPage.account.status.items')}
          currentNumber={planSubscriptionStats.itemsNumber}
          limit={plan.itemsLimit}
        />
        <Status
          title={t('userSettingsPage.account.status.integrations')}
          currentNumber={planSubscriptionStats.integrationsNumber}
          limit={plan.integrationsLimit}
        />
        {isSharedCatalogsFeatureActive && (
          <>
            <Status
              title={t('userSettingsPage.account.status.sharedCatalogs')}
              currentNumber={planSubscriptionStats.sharedCatalogsNumber}
              limit={plan.sharedCatalogsLimit}
            />
            <Status
              title={t('userSettingsPage.account.status.subscriptions')}
              currentNumber={planSubscriptionStats.subscriptionsNumber}
              limit={plan.subscriptionsLimit}
            />
          </>
        )}
      </Box>
      {isBillingFeatureActive && (
        <FormControlLabel
          control={<Checkbox defaultChecked />}
          className={classes.checkBox}
          label={t('userSettingsPage.account.status.checkbox')}
        />
      )}
    </Box>
  );
};

export default UserAccountStatusCard;
