import React from 'react';

import { Box } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';

const MainBox = styled(Box)`
  position: absolute;
  top: 10px;
  left: 50px;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  filter: blur(16px);
`;

const LightPurpleBox = styled(Box)`
  position: absolute;
  z-index: 1;
  background-color: #5d70b6;
  box-shadow: 0 0 50px 50px #5d70b6;
`;

const BlackBox = styled(Box)`
  position: absolute;
  background-color: #101d3a;
  box-shadow: 0 0 50px 50px #101d3a;
`;

const OrangeBox = styled(Box)`
  position: absolute;
  background-color: #f4764e;
  box-shadow: 0 0 50px 50px #f4764e;
`;

const DarkBlueBox = styled(Box)`
  position: absolute;
  background-color: #2e40b7;
  box-shadow: 0 0 50px 50px #2e40b7;
`;

const GreyBox = styled(Box)`
  position: absolute;
  z-index: 2;
  background-color: #7c91c0;
  box-shadow: 0 0 50px 50px #7c91c0;
`;

const LightRedBox = styled(Box)`
  position: absolute;
  z-index: 3;
  background-color: #e84761;
  box-shadow: 0 0 10px 10px #e84761;
`;

const ForgotPasswordImageBackground = () => {
  return (
    <MainBox>
      <BlackBox width="450px" height="350px" left="50px" top="70px" borderRadius="50%" />

      <LightPurpleBox width="350px" height="200px" left="20px" top="190px" borderRadius="60%" />

      <GreyBox width="160px" height="40px" left="145apx" top="260px" borderRadius="20%" />

      <DarkBlueBox width="10px" height="110px" left="25px" top="102px" borderRadius="100%" />

      <OrangeBox width="20px" height="50px" left="225px" top="102px" borderRadius="40%" />

      <DarkBlueBox width="20px" height="100px" left="55px" top="112px" borderRadius="100%" />

      <LightRedBox width="5px" height="25px" left="140px" top="252px" borderRadius="100%" />

      <LightRedBox width="5px" height="55px" left="160px" top="232px" borderRadius="100%" />

      <LightRedBox width="5px" height="55px" left="160px" top="270px" borderRadius="100%" />

      <LightRedBox width="5px" height="25px" left="300px" top="252px" borderRadius="100%" />

      <LightRedBox width="5px" height="55px" left="280px" top="232px" borderRadius="100%" />

      <LightRedBox width="5px" height="55px" left="280px" top="270px" borderRadius="100%" />

      <DarkBlueBox zIndex="3" width="20px" height="80px" left="310px" top="400px" borderRadius="100%" />
    </MainBox>
  );
};

export default ForgotPasswordImageBackground;
