import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ApolloError } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@material-ui/core';

import Button from 'src/components/Button';
import EmailInput from 'src/components/Forms/FormInputs/EmailInput';
import PasswordInput from 'src/components/Forms/FormInputs/PasswordInput';
import { FormContainer } from 'src/components/Forms/styled';
import { SignUpFormProps } from 'src/components/Forms/types';
import { signUpFormSchema } from 'src/components/Forms/validationSchemas';
import { RegInput } from 'src/utils/gql';

import TextInput from './FormInputs/TextInput';

const SignUpForm = ({ onSubmit, onError, loading }: SignUpFormProps) => {
  const { t } = useTranslation();

  const formMethods = useForm<RegInput>({
    mode: 'onChange',
    resolver: yupResolver(signUpFormSchema),
    criteriaMode: 'all',
  });

  const {
    handleSubmit,
    formState: { isValid },
  } = formMethods;

  const onSubmitForm = handleSubmit(async ({ email, password, name }: RegInput) => {
    try {
      await onSubmit({
        email: email.trim(),
        password,
        name,
      });
    } catch (error) {
      const { graphQLErrors, message: errorText } = error as ApolloError;
      const message = graphQLErrors?.length ? graphQLErrors[0].message : errorText;
      onError?.(message);
    }
  });

  return (
    <FormProvider {...formMethods}>
      <form aria-label="form" onSubmit={onSubmitForm}>
        <FormContainer>
          <Box mt="30px" mb="15px" width="100%">
            <TextInput variant="standard" label={t('enterName')} name="name" isStandard inputNameTestId="name" />
          </Box>

          <Box mb="15px" width="100%">
            <EmailInput />
          </Box>

          <Box mb="2px" width="100%">
            <Box mb="10px">
              <PasswordInput hasRequirements />
            </Box>
          </Box>
          <Button
            color="primary"
            disabled={!isValid}
            type="submit"
            data-testid="submit"
            variant="contained"
            loading={loading}
          >
            {t('sendVerificationLink')}
          </Button>
        </FormContainer>
      </form>
    </FormProvider>
  );
};

export default SignUpForm;
