import React, { useState, createRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormHelperText, FormControl, InputLabel, OutlinedInput, makeStyles, Theme, Chip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import { v4 as uuidv4 } from 'uuid';

import { capitalize } from 'src/helpers/capitalize';

import { EXCEEDED_LIMIT_VALUE } from '../constants';

import { AbstractFieldTypeInputProps } from './MultiTypeInput';

interface StylesProps {
  chipMaxWidth: string;
  maxHeight?: number;
  isDefaultFieldValue?: boolean;
}

const useStyles = makeStyles<Theme, StylesProps>((theme: Theme) => ({
  root: {
    position: 'relative',

    '& .MuiFormHelperText-root': {
      position: 'absolute',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      maxWidth: 'calc(100% - 20px)',
    },

    '& .MuiOutlinedInput-root': {
      width: '100%',
      minHeight: '42px',
      padding: '5px 3px 9px 0',
    },

    '& .MuiOutlinedInput-input': {
      display: 'none',
    },

    '& .MuiChip-root': {
      background: theme.palette.action.disabledBackground,
      color: theme.palette.primary.main,
      borderRadius: '5px',
      height: '23px',
      marginLeft: '10px',
      marginTop: '5px',
      maxWidth: ({ chipMaxWidth }) => chipMaxWidth,

      '& .MuiChip-deleteIcon': {
        fontSize: '16px',
        color: theme.palette.primary.main,
        opacity: '1',

        '&:hover': {
          opacity: '0.7',
        },
      },

      '& .MuiChip-label': {
        paddingLeft: '10px',
      },
    },

    '& .Mui-disabled': {
      '& .MuiChip-label': {
        color: theme.palette.background.default,
      },

      '& .MuiChip-root .MuiChip-deleteIcon': {
        color: theme.palette.background.default,
      },
    },
  },

  highlightedInput: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.secondary.main,
    },

    '& .MuiInputLabel-root[data-shrink="true"]': {
      color: theme.palette.secondary.main,
    },
  },
  // TODO: scrollbar will be changed later
  itemsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    paddingRight: '2px',
    maxHeight: ({ maxHeight }) => (maxHeight ? `${maxHeight}px` : '90px'),
    overflow: 'auto',
    width: '100%',

    '&::-webkit-scrollbar': {
      width: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '5px',
      border: `1px solid ${theme.palette.action.selected}`,
      background: theme.palette.secondary.main,
      boxShadow: 'none',
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '5px',
      border: 'none',
      background: theme.palette.common.white,
      boxShadow: 'inset 0px 0px 15px rgba(46, 96, 170, 0.15)',
    },
  },

  input: {
    fontFamily: 'Roboto',
    width: '0px',
    minWidth: '30px',
    flexGrow: 1,
    marginLeft: '9px',
    marginRight: '9px',
    marginTop: '5px',
    border: 'none',
    outline: 'none',
    fontSize: '14px',
    background: 'transparent',
  },
  defaultValueFieldHint: {
    marginRight: '11px',
    marginTop: '5px',
  },
}));

export interface MultiSelectProps extends Omit<AbstractFieldTypeInputProps, 'value' | 'onChange'> {
  maxHeight?: number;
  chipMaxWidth?: string;
  selectedItems: string[];
  onChange: (selectedItems: string[]) => void;
  autoFocus?: boolean;
}

const wordSeparators = '[;,]';
const wordSeparatorsRegex = new RegExp(wordSeparators);

const MultiSelect = ({
  shouldBeFilled,
  maxHeight,
  label,
  chipMaxWidth = '130px',
  disabled,
  error,
  helperText,
  testId,
  selectedItems,
  onChange,
  emptyErrorMessage,
  endAdornment,
  inputIdentifier,
  autoFocus,
}: MultiSelectProps) => {
  const { t } = useTranslation();
  const classes = useStyles({ maxHeight, chipMaxWidth });
  const inputRef = createRef<HTMLInputElement>();
  const [currentValue, setCurrentValue] = useState<string>('');
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);
  const placeholderText = t('productItemCreateEdit.multiSelect.wordSeparators');

  const {
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const fieldName = `${inputIdentifier}` as const;

  const checkEmptyError = (checkedValues: string[], currentInputValue: string) => {
    if (shouldBeFilled && !checkedValues.length && !currentInputValue.trim()) {
      setError(fieldName, {
        message: emptyErrorMessage,
      });
    } else {
      clearErrors(fieldName);
    }
  };

  const handleChange = (newValues: string[]) => {
    checkEmptyError(newValues, currentValue);

    onChange(newValues);
  };

  const handleInputChange = ({ target: { value: current } }: { target: HTMLInputElement }, finishInput?: boolean) => {
    if (finishInput || current.match(wordSeparatorsRegex)) {
      const bareValue = current.replace(new RegExp(`${wordSeparators}$`), '');
      const trimmedValues = Array.from(new Set(bareValue.split(wordSeparatorsRegex).map((value) => value.trim())));

      const duplicatedValues = selectedItems.filter((item) => trimmedValues.includes(item));

      const duplicatedError = duplicatedValues.length
        ? t('multiselect.duplicateEntry', { options: duplicatedValues.map((value) => `"${value}"`).join(', ') })
        : '';

      if (duplicatedError) {
        setCurrentValue(bareValue);

        setError(fieldName, {
          message: duplicatedError,
        });

        return;
      }

      if (current.length > EXCEEDED_LIMIT_VALUE) {
        setCurrentValue(bareValue);

        setError(fieldName, {
          message: t('exceededLimitValue'),
        });

        return;
      }

      handleChange([...selectedItems, ...trimmedValues].filter((value) => value));

      setCurrentValue('');

      return;
    }

    checkEmptyError(selectedItems, current);

    setCurrentValue(current);
  };

  const handleDelete = (item: string) => {
    handleChange(selectedItems.filter((currentItem) => item !== currentItem));
  };

  const handleKeyPress = (e: { target: HTMLInputElement } & React.KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation();
    if (e.key === 'Enter') {
      handleInputChange(e, true);
      inputRef.current?.focus();
    }
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    handleInputChange(event, true);
    setIsInputFocused(false);
  };

  const showPlaceholder = !!(selectedItems.length || isInputFocused || currentValue.length);
  const currentError = errors[fieldName];
  const errorFlag = Boolean(currentError || error);
  const errorMessage = currentError?.message || helperText || '';

  return (
    <FormControl
      fullWidth
      className={isInputFocused ? clsx(classes.root, classes.highlightedInput) : classes.root}
      variant="outlined"
      onClick={() => inputRef.current?.focus()}
      data-testid={testId}
      error={errorFlag}
    >
      <InputLabel error={errorFlag} shrink={false} sx={{ display: showPlaceholder ? 'none' : 'block' }}>
        {placeholderText}
      </InputLabel>
      <InputLabel htmlFor="multiselectInput" error={errorFlag} shrink>
        {capitalize(label)}
      </InputLabel>
      <OutlinedInput
        notched
        label={capitalize(label)}
        disabled={disabled}
        error={errorFlag}
        startAdornment={
          <div className={classes.itemsContainer}>
            {selectedItems.map((item) => (
              <Chip
                key={`${item}_${uuidv4()}`}
                tabIndex={-1}
                label={item}
                disabled={disabled}
                onDelete={() => handleDelete(item)}
                deleteIcon={<CloseIcon data-testid={`closeIcon${item}`} />}
              />
            ))}
            <input
              autoFocus={autoFocus}
              name={fieldName}
              id="multiselectInput"
              className={classes.input}
              ref={inputRef}
              value={currentValue}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
              onFocus={() => setIsInputFocused(true)}
              onBlur={handleBlur}
              size={currentValue.length + 1}
              disabled={disabled}
              data-testid={`multiSelectField${label}`}
            />
          </div>
        }
        endAdornment={endAdornment}
      />
      {errorFlag && <FormHelperText data-testid="multiselect-error-message">{errorMessage}</FormHelperText>}
    </FormControl>
  );
};

export default MultiSelect;
