import React from 'react';

import Iconography from 'src/components/Iconography';
import { UploadingMedia } from 'src/views/Catalogs/ProductItems/types';

import { MediaForUpdate } from './ImageEditorSidebar';

export interface ImageEditorSidebarProps {
  isOpen: boolean;
  onCloseSidebar: () => void;
  editedImage: UploadingMedia;
  changeMedia: (image: UploadingMedia) => void;
  onApply: (changedMediaArray: MediaForUpdate[]) => void;
}

export interface ImageEditorState {
  resetImageChangesPopupOpen: boolean;
  discardChangesPopupOpen: boolean;
  isCropActive: boolean;
  isSidebarLoaded: boolean;
  activeCropModeIndex: number | null;
  isTouched: boolean;
}

export interface MediaForUpdateAltText {
  id: string;
  altText: string;
}

export enum FLIP_ORIENTATION {
  flipX = 'x',
  flipY = 'y',
}

export const CROP_PRESETS = [
  {
    presetName: 'Free',
    presetId: 'preset-free',
  },
  {
    presetName: '16:9',
    presetId: 'preset-16-9',
    presetValue: 1.77,
  },
  {
    presetName: '8:10',
    presetId: 'preset-8-10',
    presetValue: 0.8,
  },
  {
    presetName: '5:7',
    presetId: 'preset-5-7',
    presetValue: 0.71,
  },
  {
    presetName: '4:3',
    presetId: 'preset-4-3',
    presetValue: 1.33,
  },
  {
    presetName: '1:1',
    presetId: 'preset-1-1',
    presetValue: 1,
  },
];

export const IMAGE_EDITOR_CONTROLS = [
  {
    controlGroupId: 'flip-group',
    controlGroup: [
      {
        controlTestId: 'flip-x-button',
        controlIcon: <Iconography iconName="flip-x" color="primary" fontSize="small" />,
        controlHint: 'productItemCreateEdit.mediaGallery.imageEditorSideBar.flipHorizontal',
        controlValue: FLIP_ORIENTATION.flipX,
      },
      {
        controlTestId: 'flip-y-button',
        controlIcon: <Iconography iconName="flip-y" color="primary" fontSize="small" />,
        controlHint: 'productItemCreateEdit.mediaGallery.imageEditorSideBar.flipVertical',
        controlValue: FLIP_ORIENTATION.flipY,
      },
    ],
  },
  {
    controlGroupId: 'rotate-group',
    controlGroup: [
      {
        controlTestId: 'rotate-left-button',
        controlIcon: <Iconography iconName="rotate-left-90" color="primary" fontSize="small" />,
        controlHint: 'productItemCreateEdit.mediaGallery.imageEditorSideBar.rotateLeft',
        controlValue: '-90',
      },
      {
        controlTestId: 'rotate-right-button',
        controlIcon: <Iconography iconName="rotate-right-90" color="primary" fontSize="small" />,
        controlHint: 'productItemCreateEdit.mediaGallery.imageEditorSideBar.rotateRight',
        controlValue: '90',
      },
    ],
  },
];
