import React, { ChangeEvent, FormEvent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Box, Typography } from '@material-ui/core';
import { styled } from '@mui/material';

import Iconography from 'src/components/Iconography';
import {
  CatalogJob,
  CatalogJobStatus,
  CatalogJobType,
  DuplicatedSkuInfo,
  Integration,
  IntegrationMappingError,
  Maybe,
  Reason,
} from 'src/utils/gql';

import Button from '../Button';
import DuplicatedSKUMessage, { DuplicatedSKUMessageType } from '../DuplicatedSKUMessage';
import ErrorableSyncButton, { TooltipPlacements } from '../ErrorableSyncButton';
import { ErrorableSyncButtonVariants } from '../ErrorableSyncButton/ErrorableSyncButtonChild';
import IntegrationUnavailableMessage from '../IntegrationUnavailableMessage';
import Loader from '../Loader';
import SearchField from '../SearchField';

import { StyledIntegrationText } from './styled';

const CatalogName = styled(Typography)({
  whiteSpace: 'nowrap',
  maxWidth: '663px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

interface ProductTypesHeaderProps {
  syncable?: boolean;
  isCurrentCatalogShared: boolean;
  catalogId: string;
  onAddNewProductType?: () => void;
  catalogueName: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onSubmit?: (event: FormEvent) => void;
  searchInputValue?: string;
  hasProductTypes?: boolean;
  activeJob: CatalogJob | null;
  hasIntegrations?: boolean;
  onOpenExportPopup: () => void;
  isEmpty?: boolean;
  isJobInProgress?: boolean;
  mappingErrors?: IntegrationMappingError[];
  duplicatedSKUInfo?: DuplicatedSkuInfo;
  integrations?: Maybe<Integration[]>;
}

export default function ProductTypesHeader({
  duplicatedSKUInfo,
  isCurrentCatalogShared,
  onAddNewProductType,
  catalogueName,
  onChange,
  syncable,
  searchInputValue,
  hasProductTypes,
  activeJob,
  hasIntegrations,
  onOpenExportPopup,
  isEmpty,
  isJobInProgress,
  mappingErrors,
  integrations,
}: ProductTypesHeaderProps) {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();

  const activeJobLoading =
    isJobInProgress ||
    (activeJob && activeJob.status !== CatalogJobStatus.Success && activeJob.status !== CatalogJobStatus.Fail);
  const isJobTypeImport = activeJob && activeJob?.type === CatalogJobType.Import;
  const disabledControls = !(hasProductTypes || searchInputValue?.length);
  const unsyncable = !(syncable === undefined || syncable);
  const integrationUnavailableErrors = useMemo(
    () => mappingErrors?.filter(({ reason }) => reason === Reason.IntegrationUnavailable),
    [mappingErrors],
  );
  const titleHint = t('settingsPage.attachedIntegrations.hintAtAnEmptyCatalog');
  const errorableSyncButtonTooltipWidth =
    duplicatedSKUInfo?.isDuplicated || unsyncable
      ? '231px'
      : integrationUnavailableErrors?.length
      ? '240px'
      : undefined;

  const renderSearchAndButton = () => (
    <Box display="flex" justifyContent="space-between" alignItems="center" minHeight="42px">
      <SearchField
        label={t('cataloguesHeader.searchFieldLabel')}
        variant="outlined"
        onChange={onChange}
        value={searchInputValue}
        disabled={disabledControls}
        testId="searchFieldOnProductType"
      />

      {!isCurrentCatalogShared && (
        <Box display="flex">
          {hasIntegrations && !Boolean(activeJobLoading) && (
            <Box mr="15px">
              <ErrorableSyncButton
                catalogId={id}
                tooltipParams={{
                  customTooltipContent: isEmpty ? (
                    titleHint
                  ) : unsyncable ? (
                    t('cataloguesPage.catalogueCard.unsyncableErrorHint')
                  ) : duplicatedSKUInfo?.isDuplicated ? (
                    <DuplicatedSKUMessage
                      type={DuplicatedSKUMessageType.basic}
                      catalogId={id}
                      duplicatedSKUInfo={duplicatedSKUInfo}
                    />
                  ) : integrationUnavailableErrors?.length ? (
                    <IntegrationUnavailableMessage
                      integrationUnavailableErrors={integrationUnavailableErrors}
                      integrations={integrations}
                      onTryAgainButtonClick={onOpenExportPopup}
                    />
                  ) : null,
                  placement: TooltipPlacements.bottomEnd,
                }}
                disabled={isEmpty || duplicatedSKUInfo?.isDuplicated || unsyncable}
                errors={mappingErrors}
                onClick={onOpenExportPopup}
                tooltipWidth={errorableSyncButtonTooltipWidth}
                dataTestId="exportToAttachedIntegrationsButton"
                iconParams={{
                  variant: ErrorableSyncButtonVariants.ORANGE,
                  orangeIconParams: {
                    orangeButtonText: t('productItemsPage.exportAttachedIntegrations'),
                    showOrangeButtonText: !activeJobLoading,
                  },
                }}
              />
            </Box>
          )}

          {!disabledControls && (
            <Button
              disabled={Boolean(activeJobLoading)}
              onClick={onAddNewProductType}
              variant="contained"
              startIcon={<Iconography iconName="add" />}
            >
              {t('productTypesPage.button')}
            </Button>
          )}
        </Box>
      )}
    </Box>
  );

  return (
    <>
      {activeJobLoading ? (
        <Box>
          <Box display="flex" alignItems="center" justifyContent="space-between" mb="35px">
            <Box display="flex" alignItems="center">
              <CatalogName variant="h2" mr="15px" height="28px">
                {catalogueName}
              </CatalogName>

              <Loader size="extraSmallIntegration" />
            </Box>

            <StyledIntegrationText variant="subtitle2">
              {`${isJobTypeImport ? t('import') : t('export')} ${t('inProgress')}`}
            </StyledIntegrationText>
          </Box>

          {!isJobTypeImport && renderSearchAndButton()}
        </Box>
      ) : (
        <>
          <Box>
            <Box display="flex" alignItems="center" mb="34px">
              <CatalogName variant="h2">{catalogueName}</CatalogName>
            </Box>
          </Box>

          {renderSearchAndButton()}
        </>
      )}
    </>
  );
}
