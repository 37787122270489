import { SelectOptionItem } from 'src/components/Select';
import { AuditLog, AuditLogActionType, AuditLogEntity, Integration } from 'src/utils/gql';

export enum ActionType {
  Create = 'create',
  Update = 'update',
  Delete = 'delete',
  Import = 'import',
  Export = 'export',
}

export enum EntityType {
  User = 'user',
  Catalog = 'catalog',
  Product = 'productType',
  ProductField = 'productTypeField',
  Item = 'productItem',
  ItemValue = 'productItemValue',
  Integration = 'integration',
  PlanSubscription = 'planSubscription',
}

export enum ActionTypeLabel {
  create = 'auditLogPage.options.create',
  update = 'auditLogPage.options.update',
  delete = 'auditLogPage.options.delete',
  import = 'auditLogPage.options.import',
  export = 'auditLogPage.options.export',
}

export enum EntityName {
  user = 'auditLogPage.options.user',
  catalog = 'auditLogPage.options.catalog',
  productType = 'auditLogPage.options.productType',
  productTypeField = 'auditLogPage.options.productType',
  productItem = 'auditLogPage.options.productItem',
  productItemValue = 'auditLogPage.options.productItem',
  integration = 'auditLogPage.options.integration',
  planSubscription = 'auditLogPage.options.plan',
}

export enum Dates {
  Today,
  LastThreeDays,
  LastWeek,
  LastMonth,
}

export enum AuditLogFilterType {
  Date = 'date',
  Type = 'type',
  Place = 'place',
}

export interface AuditLogFilters {
  date: SelectOptionItem;
  type: SelectOptionItem;
  place: SelectOptionItem;
}

export interface AuditLogQueryState {
  from: string;
  to?: string;
  action?: AuditLogActionType;
  entity?: AuditLogEntity;
}

export interface DetailsPopupState {
  isOpen: boolean;
  data: AuditLog;
}

export interface MetadataDiff {
  propertyName: string;
  before: string;
  after: string;
}

export interface MetadataField {
  propertyName: string;
  propertyValue: string;
}

export type CatalogValueType = string | (string | Integration[]);

export interface CatalogParsedDiff {
  propertyName: string;
  before: CatalogValueType;
  after: CatalogValueType;
}

export interface CatalogParsedField {
  propertyName: string;
  propertyValue: CatalogValueType;
}

export interface ProductTypePropertyValue {
  name: string;
  type: string;
  required: boolean;
  defaultValue: string | number | boolean;
}

export interface ProductTypeParsedDiff {
  propertyName: string;
  fieldName: string;
  before: ProductTypePropertyValue;
  after: ProductTypePropertyValue;
}

export interface ProductTypeParsedField {
  propertyName: string;
  propertyValue: ProductTypePropertyValue;
}

export type ProductItemValueType = number | boolean | null | (string | string[]);

export interface ProductItemParsedDiff {
  propertyName: string;
  before: ProductItemValueType;
  after: ProductItemValueType;
}

export interface ProductItemParsedField {
  propertyName: string;
  propertyValue: number | boolean | (string | string[]);
}

export interface IntegrationParsedDiff {
  propertyName: string;
  before: string | { [x: string]: string };
  after: string | { [x: string]: string };
}
