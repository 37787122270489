import React from 'react';

import { Box, Skeleton } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { keyframes } from '@mui/system';

const opacity = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Container = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  minWidth: 600,
  height: 90,
  background: theme.palette.background.default,
  borderRadius: 4,
  boxShadow: '0px 2px 10px rgba(46, 96, 170, 0.25)',
  padding: '21px 50px 21px 35px',
  animation: `${opacity} 1s ease`,
  marginBottom: 20,
}));

interface SkeletonProductTypeCardProps {
  cardsCount: number;
}

const SkeletonProductTypeCard = ({ cardsCount }: SkeletonProductTypeCardProps) => (
  <>
    {new Array(cardsCount).fill('card').map((_, idx) => (
      <Container key={idx}>
        <Box display="flex" maxWidth="22%" flexGrow={1} mr="24px">
          <Skeleton variant="text" width="100%" height="30px" />
        </Box>

        <Box display="flex" flexGrow={1}>
          <Skeleton variant="text" width="130px" height="20px" />
        </Box>

        <Box display="flex" justifyContent="space-between" width="125px">
          <Skeleton variant="circular" width="30px" height="30px" />

          <Skeleton variant="circular" width="30px" height="30px" />

          <Skeleton variant="circular" width="30px" height="30px" />
        </Box>
      </Container>
    ))}
  </>
);

export default SkeletonProductTypeCard;
