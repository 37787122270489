import React, { ReactNode, useState, useMemo } from 'react';

import { Accordion, AccordionDetails, AccordionSummary, Typography, makeStyles, Theme } from '@material-ui/core';

import Iconography from 'src/components/Iconography';

interface FiltersAccordionProps {
  id: string;
  title: string;
  children: ReactNode;
  expanded?: boolean;
  onChange?: (expanded: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  filterAccordion: {
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.background.default}`,
    '&:before': {
      content: 'none',
    },
  },
  filterAccordionSummary: {
    minHeight: 'unset',
    padding: '20px 0px 20px 0px',
    '& .MuiAccordionSummary-content': {
      margin: 0,
    },
  },
  filterAccordionDetails: {
    padding: 0,
    paddingBottom: '20px',
  },
}));

const FiltersAccordion = ({ id, title, children, expanded, onChange }: FiltersAccordionProps) => {
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState<boolean>(expanded ?? false);

  const accordionHeaderId = `${id}-header`;
  const accordionContentId = `${id}-content`;

  const handleExpandFilter = () => {
    const newExpanded = !isExpanded;

    setIsExpanded(newExpanded);
    onChange?.(newExpanded);
  };

  const formattedTitle = useMemo(() => {
    const capitalizeFirstWord = (str: string) => {
      const [firstWord, ...otherWords] = str.split(' ');
      return `${firstWord.charAt(0).toUpperCase()}${firstWord.slice(1)} ${otherWords.join(' ')}`;
    };

    return capitalizeFirstWord(title);
  }, [title]);

  return (
    <Accordion className={classes.filterAccordion} disableGutters expanded={expanded ?? isExpanded}>
      <AccordionSummary
        onClick={handleExpandFilter}
        className={classes.filterAccordionSummary}
        expandIcon={<Iconography iconName="expand-chevron" color="primary" />}
        id={accordionHeaderId}
        aria-controls={accordionContentId}
        data-testid={`${title}ExpandedArrow`}
      >
        <Typography variant="subtitle2" color="text.primary">
          {formattedTitle}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.filterAccordionDetails}>{children}</AccordionDetails>
    </Accordion>
  );
};

export default FiltersAccordion;
