import React from 'react';

import { Box } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';

const MainBox = styled(Box)`
  position: absolute;
  width: 270px;
  height: 220px;
  opacity: 0.7;
  filter: blur(26px);

  & * {
    position: absolute;
    border-radius: 50%;
  }
`;

const DarkPurpleBox = styled(Box)`
  background-color: #3a3cc4;
  box-shadow: 0 0 0 20px #3a3cc4;
`;

const PurpleBox = styled(Box)`
  background-color: #5f60db;
`;

const YellowBox = styled(Box)`
  background-color: #ffb758;
  box-shadow: 0 0 0 20px #ffb758;
`;

const OrangeBox = styled(Box)`
  background-color: #f4724f;
  box-shadow: 0 0 0 20px #f4724f;
`;

const RedBox = styled(Box)`
  background-color: #c4266e;
  box-shadow: 0 0 0 20px #c4266e;
`;

const WhiteBox = styled(Box)`
  background-color: #fff;
  box-shadow: 0 0 0 20px #fff;
`;

const CreateProductTypeBackground = () => {
  return (
    <MainBox>
      <PurpleBox width="154px" height="154px" top="24px" right="36px" boxShadow="0px 0px 8px 12px #5f60db" />

      <PurpleBox width="138px" height="68px" bottom="16px" left="73px" boxShadow="0px 0px 8px 0 #5f60db" />

      <PurpleBox width="87px" height="12px" bottom="6px" left="60px" boxShadow="0px 0px 0 12px #5f60db" />

      <PurpleBox width="2px" height="2px" bottom="84px" left="8px" boxShadow="0px 0px 0 20px #5f60db" />

      <YellowBox width="8px" height="8px" bottom="24px" right="20px" />

      <OrangeBox width="10px" height="8px" top="11px" left="40px" />

      <RedBox width="30px" height="20px" top="94px" right="78px" />

      <RedBox width="17px" height="6px" top="112px" right="148px" />

      <WhiteBox width="14px" height="2px" top="88px" right="154px" />

      <WhiteBox width="8px" height="2px" top="82px" right="105px" />

      <DarkPurpleBox width="6px" height="6px" top="64px" left="50px" />

      <DarkPurpleBox width="2px" height="2px" top="86px" left="62px" />
    </MainBox>
  );
};

export default CreateProductTypeBackground;
