import React, { ChangeEvent, KeyboardEvent, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Box, InputAdornment, makeStyles, TextField, TextFieldProps, Theme, useTheme } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';

import { KeyboardKeyNames, NumberValueCalcActions } from 'src/components/Forms/types';
import Iconography from 'src/components/Iconography';
import { AbstractFieldTypeInputProps } from 'src/components/MultiTypeInput';
import { numberFieldPrepareMutator, simpleNumberValidator } from 'src/utils/numberFieldValidator';

const useStyles = makeStyles<Theme>((theme) => ({
  inputControlsBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginRight: '10px',
  },
  controlIconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '18px',
    height: '15px',
    borderRadius: '1px',

    '&:hover': {
      cursor: 'pointer',
      background: theme.palette.background.default,
    },

    '& svg': {
      width: '10px',
      height: '10px',
    },
  },
  mainInputComponent: {
    '& .arrows': {
      visibility: 'hidden',
    },
    '& input:focus+.arrows, &:hover .arrows': {
      visibility: 'visible',
    },
  },
}));

export interface NumberInputProps extends Omit<AbstractFieldTypeInputProps, 'shouldBeFilled' | 'emptyErrorMessage'> {
  numberValidator?: (value: string) => boolean;
  minValue?: number;
  maxValue?: number;
  afterDecimalPointLimit?: number;
}

const NumberInput = ({
  value,
  numberValidator = simpleNumberValidator,
  helperText,
  InputProps,
  onChange,
  minValue,
  maxValue,
  afterDecimalPointLimit,
  inputIdentifier,
  error,
  autoFocus,
  ...props
}: Omit<TextFieldProps, 'onChange'> & NumberInputProps) => {
  const { name, disabled } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [decimalPointInTheEnd, setDecimalPointInTheEnd] = useState<boolean>(false);
  const [minusInTheBegin, setMinusInTheBegin] = useState<boolean>(false);

  const {
    formState: { errors },
  } = useFormContext();

  const handleChange = (newValue: string) => {
    let preparedValue = numberFieldPrepareMutator(newValue);

    if (minValue !== undefined && minValue >= 0) {
      preparedValue = preparedValue.replace('-', '');
    }

    if (!numberValidator || numberValidator(preparedValue)) {
      const numPreparedValue = Number(preparedValue);

      if (minValue && minValue > numPreparedValue) {
        preparedValue = String(minValue);
      } else if (maxValue && maxValue < numPreparedValue) {
        preparedValue = String(maxValue);
      }

      if (afterDecimalPointLimit !== undefined) {
        const currentAfterDecimalPointLength = preparedValue.split('.')[1]?.length || 0;

        if (currentAfterDecimalPointLength > afterDecimalPointLimit) {
          preparedValue = preparedValue.slice(0, afterDecimalPointLimit - currentAfterDecimalPointLength);
        }
      }

      if (/\.$/.test(preparedValue)) {
        setDecimalPointInTheEnd(true);
        preparedValue = preparedValue.slice(0, -1);
      } else {
        setDecimalPointInTheEnd(false);
      }

      if (/^-(0*\.?0*)?$/.test(preparedValue)) {
        setMinusInTheBegin(true);
        preparedValue = preparedValue.slice(1);
      } else {
        setMinusInTheBegin(false);
      }

      onChange(preparedValue);
    }
  };

  const handleChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
    handleChange(e.target.value);
    e.preventDefault();
  };

  const handleCalcValue = (action: string) => {
    const currentValue = (value as string) || '0';
    const floatValue = Number(currentValue);

    const power = currentValue.split('.')[1]?.length || 0;
    const currentStep = Math.pow(10, power);
    const calculatedValue =
      Math.round(floatValue * currentStep + Number(action === NumberValueCalcActions.up || -1)) / currentStep;

    if (
      (minValue === undefined || calculatedValue >= minValue) &&
      (maxValue === undefined || calculatedValue <= maxValue)
    ) {
      handleChange(calculatedValue.toFixed(power));
    }
  };

  const handleInputControls = (e: KeyboardEvent<HTMLDivElement>) => {
    const keyCode = e.key;

    switch (keyCode) {
      case KeyboardKeyNames.ARROW_UP:
        e.preventDefault();
        handleCalcValue(NumberValueCalcActions.up);
        break;
      case KeyboardKeyNames.ARROW_DOWN:
        e.preventDefault();
        handleCalcValue(NumberValueCalcActions.down);
        break;
    }
  };

  const currentError = errors[inputIdentifier];
  const errorFlag = Boolean(currentError || error);
  const errorMessage = currentError?.message || helperText || '';

  return (
    <TextField
      fullWidth
      variant="outlined"
      autoComplete="off"
      value={`${minusInTheBegin ? '-' : ''}${value}${decimalPointInTheEnd ? '.' : ''}`}
      helperText={errorMessage}
      error={errorFlag}
      inputProps={{
        'data-testid': name,
        autoFocus,
        maxLength: 16,
      }}
      onKeyDown={handleInputControls}
      onChange={handleChangeValue}
      className={classes.mainInputComponent}
      InputProps={{
        name: inputIdentifier,
        ...(InputProps || {
          endAdornment: (
            <InputAdornment position="end" className="arrows">
              {!disabled && (
                <Box className={classes.inputControlsBox}>
                  {Object.values(NumberValueCalcActions).map((numberValueCalcActions) => {
                    const iconAndTestId = `arrow-${numberValueCalcActions}`;

                    return (
                      <Box
                        key={uuidv4()}
                        data-testid={iconAndTestId}
                        className={classes.controlIconWrapper}
                        onClick={() => handleCalcValue(numberValueCalcActions)}
                      >
                        <Iconography
                          iconName={iconAndTestId}
                          htmlColor={theme.palette.secondary.main}
                          fontSize="small"
                        />
                      </Box>
                    );
                  })}
                </Box>
              )}
            </InputAdornment>
          ),
        }),
      }}
      {...props}
    />
  );
};

export default NumberInput;
