import React from 'react';
import { TFunction } from 'react-i18next';
import { Step } from 'react-joyride';

import { Typography } from '@mui/material';

import { CatalogSource } from 'src/utils/gql';

import Button from '../Button';
import Iconography from '../Iconography';
import { OnboardingImage } from '../Popups/OnboardingPopup';

export const getCommonSteps = (t: TFunction<'translation'>): Step[] => [
  {
    // Catalogs intro step
    target: '#catalogs-anchor',
    disableBeacon: true,
    placement: 'top',
    spotlightPadding: 0,
    offset: 0,
    floaterProps: {
      hideArrow: true,
    },
    title: t('onboarding.catalogsIntro.title'),
    content: (
      <>
        <Typography>{t('onboarding.catalogsIntro.description')}</Typography>
        <Typography>{t('onboarding.catalogsIntro.description2')}</Typography>
      </>
    ),
    showSkipButton: true,
  },
  {
    // Catalogs start step
    target: '#create-catalog-btn-anchor',
    placement: 'left',
    title: t('onboarding.catalogCreationTitle'),
    content: <Typography>{t('onboarding.catalogsStart.description')}</Typography>,
    hideFooter: true,
  },
  {
    // Catalogs set name step
    target: '#set-name-block-anchor',
    placement: 'right',
    hideFooter: true,
    spotlightPadding: 20,
    offset: 20,
    title: t('onboarding.catalogCreationTitle'),
    content: <Typography>{t('onboarding.catalogsSetName.description')}</Typography>,
  },
  {
    // Catalogs select source step
    target: '#select-source-block-anchor',
    placement: 'right',
    spotlightPadding: 20,
    offset: 20,
    showSkipButton: true,
    title: t('onboarding.catalogCreationTitle'),
    content: <Typography>{t('onboarding.catalogsSelectSource.description')}</Typography>,
  },
];

export const getFinalSteps = (t: TFunction<'translation'>): Step[] => [
  {
    // ProductTypes product type list step
    target: '#product-type-list-anchor',
    placement: 'left',
    spotlightClicks: false,
    showSkipButton: true,
    title: t('onboarding.productTypesList.title'),
    content: <Typography>{t('onboarding.productTypesList.description')}</Typography>,
  },
  {
    // ProductTypes sync button step
    target: '#catalog-list-anchor',
    placement: 'bottom',
    offset: 0,
    floaterProps: { target: '#sync-btn-anchor' },
    spotlightClicks: false,
    hideBackButton: false,
    showSkipButton: false,
    title: t('onboarding.productTypesSyncButton.title'),
    content: <Typography>{t('onboarding.productTypesSyncButton.description')}</Typography>,
  },
  {
    // ProductTypes export button step
    target: '#catalog-list-anchor',
    placement: 'right',
    offset: 0,
    floaterProps: { target: '#export-btn-anchor' },
    spotlightClicks: false,
    hideBackButton: false,
    title: t('onboarding.productTypesExportButton.title'),
    content: <Typography>{t('onboarding.productTypesExportButton.description')}</Typography>,
  },
  {
    // ProductTypes settings button step
    target: '#catalog-list-anchor',
    placement: 'bottom',
    offset: 0,
    floaterProps: { target: '#settings-btn-anchor' },
    spotlightClicks: false,
    hideBackButton: false,
    title: t('onboarding.productTypesSettingsButton.title'),
    content: <Typography>{t('onboarding.productTypesSettingsButton.description')}</Typography>,
  },
  {
    // Account info step
    target: '#navbar_footer_item4_id',
    placement: 'right',
    styles: { tooltip: { top: '-70px' } },
    offset: 0,
    spotlightClicks: false,
    hideBackButton: false,
    title: t('onboarding.accountInfo.title'),
    content: <Typography>{t('onboarding.accountInfo.description')}</Typography>,
  },
  {
    // Help center step
    target: '#navbar_footer_item3_id',
    placement: 'right',
    styles: { tooltip: { top: '-130px' } },
    offset: 0,
    spotlightClicks: false,
    showSkipButton: false,
    title: t('onboarding.helpCenter.title'),
    content: (
      <>
        <OnboardingImage type="image/webp" data={`${process.env.PUBLIC_URL}/images/onboarding-finish.webp`}>
          Onboarding
        </OnboardingImage>
        <Typography>{t('onboarding.helpCenter.description')}</Typography>
      </>
    ),
  },
];

export const getDownloadExampleStep = (t: TFunction<'translation'>): Step => ({
  target: '#select-csv-anchor',
  placement: 'right',
  hideFooter: true,
  title: t('onboarding.downloadExample.title'),
  content: <Typography>{t('onboarding.downloadExample.description')}</Typography>,
});

export const getIntegrationSteps = (t: TFunction<'translation'>, onBack: () => void, source: string): Step[] => [
  {
    // Catalogs confirm source step
    target: '#select-source-next-btn-anchor',
    placement: 'right',
    title: t('onboarding.catalogCreationTitle'),
    hideFooter: true,
    content: (
      <>
        <Typography>{t('onboarding.catalogsConfirmSource.description', { source })}</Typography>

        <Button id="onboarding-back-btn" variant="outlined" sx={{ marginTop: '24px' }} onClick={onBack}>
          {t('onboarding.button.back')}
        </Button>
      </>
    ),
  },
  {
    // Catalogs create integration step
    target: '#add-integration-btn-anchor',
    placement: 'right',
    hideFooter: true,
    title: t('onboarding.catalogCreationTitle'),
    content: <Typography>{t('onboarding.catalogsCreateIntegration.description', { source })}</Typography>,
  },
  {
    // Catalogs create integration popup step
    target: '#integration-info-anchor',
    placement: 'right',
    hideFooter: true,
    title: t('onboarding.catalogCreationTitle'),
    content:
      source.toLowerCase() === CatalogSource.Shopify ? (
        <Typography>{t('onboarding.catalogsCreateIntegrationPopup.shopifyDescription')}</Typography>
      ) : (
        <>
          <Typography>{t('onboarding.catalogsCreateIntegrationPopup.wooDescription')}</Typography>
          <Typography>
            {t('onboarding.catalogsCreateIntegrationPopup.wooDescription2')}

            <Typography component="span">
              <Iconography style={{ width: 17, height: 17 }} iconName="help-fill" htmlColor="secondary" />

              {t('onboarding.catalogsCreateIntegrationPopup.wooDescription3')}
            </Typography>
          </Typography>
        </>
      ),
  },
  {
    // Catalogs integration list step
    target: '#integration-list-anchor',
    placement: 'right',
    hideFooter: true,
    title: t('onboarding.catalogCreationTitle'),
    content: <Typography>{t('onboarding.catalogsIntegrationList.description')}</Typography>,
  },
  {
    // Catalogs create catalog success step
    target: 'body',
    placement: 'center',
    floaterProps: {
      hideArrow: true,
    },
    spotlightClicks: false,
    showSkipButton: true,
    title: t('onboarding.catalogsCreateCatalogSuccess.title'),
    content: <Typography>{t('onboarding.catalogsCreateCatalogSuccess.description', { source })}</Typography>,
  },
  ...getFinalSteps(t),
];

export const getCsvSteps = (t: TFunction<'translation'>, onBack: () => void, source: string): Step[] => [
  {
    // Catalogs confirm source step
    target: '#select-source-next-btn-anchor',
    placement: 'right',
    title: t('onboarding.catalogCreationTitle'),
    hideFooter: true,
    content: (
      <>
        <Typography>{t('onboarding.catalogsConfirmSource.description', { source })}</Typography>

        <Button id="onboarding-back-btn" variant="outlined" sx={{ marginTop: '24px' }} onClick={onBack}>
          {t('onboarding.button.back')}
        </Button>
      </>
    ),
  },
  {
    // Select csv step
    target: '#select-csv-anchor',
    placement: 'right',
    hideFooter: true,
    title: t('onboarding.catalogCreationTitle'),
    content: <Typography>{t('onboarding.selectCsv.description')}</Typography>,
  },
  {
    // Import csv step
    target: '#import-csv-anchor',
    placement: 'left-end',
    styles: { tooltip: { left: '-140px', top: 80 } },
    offset: -300,
    floaterProps: {
      hideArrow: true,
    },
    hideFooter: true,
    title: t('onboarding.catalogCreationTitle'),
    content: <Typography>{t('onboarding.importCsv.description')}</Typography>,
  },
  {
    // Select csv step
    target: '#catalog-card-anchor',
    placement: 'right',
    hideFooter: true,
    title: t('onboarding.createdCsvCatalog.title'),
    content: <Typography>{t('onboarding.createdCsvCatalog.description')}</Typography>,
  },
  ...getFinalSteps(t),
];

export const getManuallySteps = (t: TFunction<'translation'>, onBack: () => void, source: string): Step[] => [
  {
    // Catalogs confirm source step
    target: '#select-source-next-btn-anchor',
    placement: 'right',
    title: t('onboarding.catalogCreationTitle'),
    hideFooter: true,
    content: (
      <>
        <Typography>{t('onboarding.catalogsConfirmSource.description', { source })}</Typography>

        <Button id="onboarding-back-btn" variant="outlined" sx={{ marginTop: '24px' }} onClick={onBack}>
          {t('onboarding.button.back')}
        </Button>
      </>
    ),
  },
  {
    // Go to create product type step
    target: '#go-to-page-btn-anchor',
    placement: 'right',
    hideFooter: true,
    title: t('onboarding.catalogCreationTitle'),
    content: <Typography>{t('onboarding.goToCreateProductTypeStep.description')}</Typography>,
  },
  {
    // Product type name step
    target: '#product-type-name-field-anchor',
    placement: 'right',
    styles: { tooltip: { top: 42 } },
    showSkipButton: true,
    title: t('onboarding.productTypeCreationTitle'),
    content: <Typography>{t('onboarding.productTypeNameStep.description')}</Typography>,
  },
  {
    // Product type fields step
    target: '#default-fields-anchor',
    placement: 'bottom-end',
    styles: { tooltip: { top: -40, left: 290 } },
    floaterProps: {
      hideArrow: true,
    },
    hideBackButton: false,
    title: t('onboarding.productTypeCreationTitle'),
    content: <Typography>{t('onboarding.productTypeFieldsStep.description')}</Typography>,
  },
  {
    // Product type add field step
    target: '#add-field-btn-anchor',
    placement: 'left',
    hideBackButton: false,
    spotlightClicks: false,
    title: t('onboarding.productTypeCreationTitle'),
    content: <Typography>{t('onboarding.productTypeAddFieldStep.description')}</Typography>,
  },
  {
    // Product type save button step
    target: '#save-btn-anchor',
    placement: 'bottom',
    styles: { tooltip: { left: -24 } },
    hideFooter: true,
    title: t('onboarding.productTypeCreationTitle'),
    content: <Typography>{t('onboarding.productTypeSaveButtonStep.description')}</Typography>,
  },
  {
    // Product type save list step
    target: '#save-and-create-btn-anchor',
    placement: 'left-start',
    styles: { tooltip: { top: -10 } },
    hideFooter: true,
    title: t('onboarding.itemCreationTitle'),
    content: <Typography>{t('onboarding.productTypeSaveListStep.description')}</Typography>,
  },
  {
    // Product item name/price/sku step
    target: '#name-price-sku-block-anchor',
    placement: 'bottom',
    showSkipButton: true,
    title: t('onboarding.itemCreationTitle'),
    content: <Typography>{t('onboarding.productItemNamePriceSkuStep.description')}</Typography>,
  },
  {
    // Product item media/description step
    target: '#media-description-block-anchor',
    placement: 'left',
    styles: { tooltip: { left: -310 } },
    offset: -445,
    floaterProps: {
      hideArrow: true,
    },
    hideBackButton: false,
    title: t('onboarding.itemCreationTitle'),
    content: <Typography>{t('onboarding.productItemMediaDescriptionStep.description')}</Typography>,
  },
  {
    // Product item draft step
    target: '#draft-checkbox-anchor',
    placement: 'bottom-end',
    hideBackButton: false,
    styles: { tooltip: { left: 10 } },
    title: t('onboarding.itemCreationTitle'),
    content: (
      <>
        <Typography>{t('onboarding.productItemDraftStep.description')}</Typography>
        <Typography>{t('onboarding.productItemDraftStep.description2')}</Typography>
      </>
    ),
  },
  {
    // Product item save step
    target: '#save-btn-anchor',
    placement: 'bottom-end',
    styles: { tooltip: { left: 10 } },
    title: t('onboarding.itemCreationTitle'),
    hideFooter: true,
    content: <Typography>{t('onboarding.productItemSaveStep.description')}</Typography>,
  },
  {
    // Product item save popup step
    target: '#popup-secondary-btn-anchor',
    placement: 'left',
    title: t('onboarding.itemCreationTitle'),
    spotlightPadding: 8,
    hideFooter: true,
    content: <Typography>{t('onboarding.productItemSavePopupStep.description')}</Typography>,
  },
  {
    // Product item list step
    target: 'body',
    placement: 'center',
    title: t('onboarding.productItemListStep.title'),
    spotlightClicks: false,
    showSkipButton: true,
    content: <Typography>{t('onboarding.productItemListStep.description')}</Typography>,
  },
  {
    // Catalog overview step
    target: '#breadcrumb-catalog-anchor',
    placement: 'bottom-start',
    styles: { tooltip: { left: -30 } },
    spotlightPadding: 8,
    title: t('onboarding.catalogOverviewStep.title'),
    hideFooter: true,
    content: <Typography>{t('onboarding.catalogOverviewStep.description')}</Typography>,
  },
  ...getFinalSteps(t),
];
