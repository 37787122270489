import React, { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useQuery } from '@apollo/client';
import { Box, Checkbox, Typography, Skeleton } from '@mui/material';

import Hint from 'src/components/Hint';
import Iconography from 'src/components/Iconography';
import Select, { defaultSelectValue, SelectOptionItem, SelectOptionItemStringValue } from 'src/components/Select';
import { arrayToMap } from 'src/utils/general';
import {
  getIntegrationsRequiredFields,
  Integration,
  IntegrationProductType,
  integrationProductTypes as integrationProductTypesQuery,
  IntegrationTypes,
  ProductType,
  ProductTypeFieldIntegrationMapping,
  Query,
  QueryGetIntegrationsRequiredFieldsArgs,
  QueryIntegrationProductTypesArgs,
} from 'src/utils/gql';
import { getFieldMappingKey } from 'src/views/Catalogs/ProductTypes/ProductTypeSettings/MappingTabContent/IntegrationInfo';
import MappingFields from 'src/views/Catalogs/ProductTypes/ProductTypeSettings/MappingTabContent/MappingFields';

import { FieldsMappingsContainer, IntegrationProductTypesTitle, OtomateProductTypeSelectWrapper } from './styled';

import { getFieldKeyForFirstTimeExportForm, TypeMappingData } from './index';

export interface ProductTypeMappingProps {
  integration: Integration;
  productType: ProductType;
  integrationTypesMap: Record<string, IntegrationProductType>;
  integrationTypesOptions: SelectOptionItemStringValue[];
  idx: number;
  defaultFieldMappings?: ProductTypeFieldIntegrationMapping[];
  loading?: boolean;
}

export const getSelectValueFromExternalType = ({ id, name }: IntegrationProductType): SelectOptionItemStringValue => ({
  label: name,
  value: id,
});

const ProductTypeMapping = ({
  productType,
  integration,
  loading,
  idx,
  defaultFieldMappings,
  integrationTypesMap,
  integrationTypesOptions,
}: ProductTypeMappingProps) => {
  const { t } = useTranslation();

  const selectedTypeOption = {
    label: productType.name,
    value: '',
  };

  const { control, setValue, getValues } = useFormContext();
  const currentKey = `${getFieldKeyForFirstTimeExportForm(integration.id, productType.id)}` as const;
  const isEbay = integration.type === IntegrationTypes.Ebay;

  const defaultTypeMappingData = control._defaultValues[currentKey] as TypeMappingData;

  const { data: integrationProductTypesData, loading: integrationProductTypesLoading } = useQuery<
    Pick<Query, 'integrationProductTypes'>,
    QueryIntegrationProductTypesArgs
  >(integrationProductTypesQuery, {
    variables: { id: integration.id },
    skip: !isEbay,
    fetchPolicy: 'cache-first',
  });

  const { data: requiredIntegrationFields, loading: requiredIntegrationFieldsLoading } = useQuery<
    Pick<Query, 'getIntegrationsRequiredFields'>,
    QueryGetIntegrationsRequiredFieldsArgs
  >(getIntegrationsRequiredFields, {
    fetchPolicy: 'cache-first',
    variables: { type: integration.type },
  });

  const currentIntegrationTypesMap = useMemo(
    () =>
      isEbay
        ? integrationProductTypesData
          ? arrayToMap(integrationProductTypesData.integrationProductTypes)
          : {}
        : integrationTypesMap,
    [integrationProductTypesData, integrationTypesMap, isEbay],
  );

  const allValues = Object.values(getValues());

  const usedEbayTypesIds = useMemo(
    () =>
      isEbay
        ? allValues.reduce((acc: string[], mappingData: TypeMappingData) => {
            if (mappingData?.metadataExternalId) {
              acc.push(mappingData.metadataExternalId);
            }

            return acc;
          }, [])
        : [],
    [allValues, isEbay],
  );

  const currentIntegrationTypesOptions = useMemo(
    () =>
      isEbay
        ? integrationProductTypesData
          ? integrationProductTypesData.integrationProductTypes.reduce(
              (acc: SelectOptionItemStringValue[], externalType) => {
                if (!usedEbayTypesIds.includes(externalType.id)) {
                  acc.push(getSelectValueFromExternalType(externalType));
                }

                return acc;
              },
              [],
            )
          : []
        : integrationTypesOptions,
    [integrationProductTypesData, integrationTypesOptions, isEbay, usedEbayTypesIds],
  );

  const selectLabelText = t('mappingSidebar.firstTimeMapping.productType');

  const showTitle = integration.type === IntegrationTypes.Ebay || idx === 0;

  return (
    <Controller
      name={`${currentKey}` as const}
      control={control}
      rules={{
        validate: ({ metadataExternalId, toSync }: TypeMappingData) => !(toSync && !metadataExternalId),
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        const currentExternalType = value?.metadataExternalId && currentIntegrationTypesMap[value?.metadataExternalId];

        const externalProductTypeIdValue = currentExternalType?.id;

        const currentExternalTypeValue = currentExternalType
          ? getSelectValueFromExternalType(currentExternalType)
          : defaultSelectValue;

        const options = currentIntegrationTypesOptions;

        const handleCheckBoxChange = ({ target }: React.ChangeEvent<HTMLInputElement>) =>
          onChange({
            ...value,
            toSync: target.checked,
          });

        const updateFieldMappings = (metadataExternalId?: string) => {
          const shouldSetDefault = defaultTypeMappingData.metadataExternalId === metadataExternalId;
          const fieldsToUpdate = defaultFieldMappings ? defaultFieldMappings : productType.fields;

          fieldsToUpdate.forEach((_, idx) => {
            const fieldMappingKey = getFieldMappingKey(idx, currentKey);

            setValue(`${fieldMappingKey}` as const, shouldSetDefault ? control._defaultValues[fieldMappingKey] : null);
          });
        };

        const handleExternalTypeChange = ({ value: metadataExternalId }: SelectOptionItem) => {
          updateFieldMappings(metadataExternalId as string);

          onChange({
            ...value,
            metadataExternalId,
            toSync: true,
          });
        };

        return (
          <>
            {showTitle && (
              <>
                <Typography variant="subtitle2" fontWeight="400" color="text.secondary">{`Otomate ${t(
                  'mappingSidebar.firstTimeMapping.productType',
                )}`}</Typography>

                <IntegrationProductTypesTitle variant="subtitle2" fontWeight="400" color="text.secondary">
                  {`${t(`integrationNames.${integration.type}`)} ${t(`${integration.type}.productType`)}`}
                </IntegrationProductTypesTitle>
              </>
            )}

            <OtomateProductTypeSelectWrapper>
              <Select
                fullwidth
                label={selectLabelText}
                options={[selectedTypeOption]}
                selectedOptionItem={selectedTypeOption}
                disabled
                optionsWithTooltips
                generalOptionDataTestId={`otomate_product_type_for_${integration.type}_integration_`}
              />
            </OtomateProductTypeSelectWrapper>

            <Box width="40px" height="42px" fontSize="40px" display="flex" alignItems="center">
              <Iconography
                id="arrow-right"
                iconName="mapping-arrow-right"
                viewBox="0 0 41 8"
                color="disabled"
                fontSize="inherit"
              />
            </Box>

            {integrationProductTypesLoading || loading ? (
              <Skeleton height="100%" variant="rectangular" />
            ) : (
              <Select
                fullwidth
                label={selectLabelText}
                emptyOptionPlaceholder={selectLabelText}
                options={options}
                selectedOptionItem={currentExternalTypeValue}
                onChangeSelect={handleExternalTypeChange}
                error={!!error}
                optionsWithTooltips
                generalOptionDataTestId={`${integration.type}_integration_product_type_`}
              />
            )}

            <Hint
              type="hover"
              title={t(`productType.mapping.${value?.toSync ? 'exclude' : 'include'}`) as string}
              placement="left"
            >
              <Box display="flex" alignItems="center">
                <Checkbox checked={value?.toSync} onChange={handleCheckBoxChange} />
              </Box>
            </Hint>

            {externalProductTypeIdValue && !requiredIntegrationFieldsLoading && (
              <FieldsMappingsContainer>
                <MappingFields
                  integration={integration}
                  externalProductTypeId={externalProductTypeIdValue}
                  requiredIntegrationFields={requiredIntegrationFields?.getIntegrationsRequiredFields ?? []}
                  categoryName={currentExternalType?.value ?? ''}
                  productTypeId={productType.id}
                  fieldMappingKeyPrefix={currentKey}
                  defaultFieldMappings={defaultFieldMappings}
                  arrowSpaceWidth={41}
                  arrowComponent={
                    <Box fontSize="41px" display="flex">
                      <Iconography
                        id="mapping-arrow-left-right"
                        iconName="mapping-arrow-left-right"
                        viewBox="0 0 43 8"
                        fontSize="inherit"
                      />
                    </Box>
                  }
                  addFieldHeaders
                />
              </FieldsMappingsContainer>
            )}
          </>
        );
      }}
    />
  );
};

export default ProductTypeMapping;
