import { Box, TableBody, TableHead, TextField, Typography } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { IconButton } from '@mui/material';

import LabeledToggle from 'src/components/Toggle/LabeledToggle';
import { theme } from 'src/theme';

export const ProductTypeFieldTableHead = styled(TableHead)(() => ({
  '& .MuiTableCell-root': {
    backgroundColor: theme.palette.common.white,
    fontWeight: 700,
    borderBottom: '1px solid',
    borderColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    padding: '16px 23px',

    '&:nth-of-type(1)': {
      width: '15%',
    },
    '&:nth-of-type(2)': {
      width: '15%',
    },
    '&:nth-of-type(3)': {
      width: '25%',
    },
    '&:nth-of-type(4)': {
      width: '10%',
    },
    '&:nth-of-type(5)': {
      width: '10%',
    },
    '&:nth-of-type(6)': {
      width: '15%',
      paddingLeft: '5%',

      [theme.breakpoints.up('xl')]: {
        paddingLeft: '6%',
      },
    },
  },
}));

export const ProductTypeFieldTableBody = styled(TableBody)`
  .MuiTableCell-root {
    border-bottom: 1px solid ${({ theme: { palette } }) => palette.background.default};
    font-weight: 500;
    font-size: 15px;
    color: ${({ theme }) => theme.palette.text.secondary};
    padding: 20px 23px;
    height: 59px;

    :nth-of-type(6) {
      padding: 10px 23px;
    }
  }

  .selectedForDeletionRow {
    .MuiTableCell-root {
      color: ${({ theme }) => theme.palette.text.disabled};
    }
  }
`;

export const ProductTypeDefaultFieldsTableWrapper = styled(Box)`
  margin-top: 30px;
  padding-right: 56px;
`;

export const ProductTypeCustomFieldsTableWrapper = styled(Box)`
  margin-top: 30px;
  padding-right: 56px;
`;

export const ProductTypeCustomFieldsHeadline = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 42px;
`;

export const FieldSettingsBottomContainer = styled(Box)`
  display: flex;
`;

export const CheckboxItemContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  margin-left: 30px;
  margin-top: 30px;
  margin-right: 46px;
  align-items: center;
`;

export const CheckboxTitle = styled(Typography)`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: ${({ theme: { palette } }) => palette.text.secondary};
`;

export const FieldSettingsInputContainer = styled(Box)`
  margin-top: 32px;
`;

export const FieldSettingsDisplayOptionsContainer = styled(Box)`
  max-width: 436px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledToggle = styled(LabeledToggle)`
  max-width: 60px;
  width: 100%;
`;

export const DisplayOptionsToggleContainer = styled(Box)`
  display: flex;

  label {
    min-width: 175px;
  }
`;

export const AddCustomFieldButtonContainer = styled(Box)`
  margin-right: 56px;
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`;

export const StyledAddFieldText = styled(Typography)`
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: ${({ theme: { palette } }) => palette.secondary.dark};
  margin-left: 8px;
`;

export const ProductTypeSettingsHeaderWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 56px 18px 0;
`;

export const ProductTypeSettingsHeaderInput = styled(TextField)`
  max-width: 392px;
  width: 100%;
`;

export const StyledProductTypeFieldName = styled(Box)`
  max-width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledActionButtonsBox = styled(Box)(() => ({
  display: 'inline-flex',
}));

export const StyledProductTypeNameInput = styled(TextField)`
  width: 100%;
  max-width: 392px;
`;

export const NoCustomFields = styled(Typography)`
  color: ${({ theme: { palette } }) => palette.text.secondary};
`;

export const CloseButton = styled(IconButton)`
  position: absolute;
  right: 0;
  top: 0;
`;

export const SaveButtonLink = styled(Typography)`
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;

export const StyledMenuContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 25px 48px 25px 25px;
  min-width: 214px;
  min-height: 101px;
  justify-content: space-between;
`;

export const StyledBtnContainer = styled('button')`
  box-sizing: border-box;
  cursor: pointer;
  text-align: left;
  border: none;
  background-color: transparent;
  padding: 0;
  height: 18px;
`;

export const NameContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  max-width: 624px;
  width: 100%;
`;

export const NamePartTypography = styled(Typography)`
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
`;

export const StyledErrorBox = styled(Box)`
  width: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;

  & .MuiTypography-root {
    height: 24px;
  }
`;

export const StyledErrorIcon = styled('span')(() => ({
  height: '24px',

  '&:hover': {
    cursor: 'pointer',
  },

  '& .MuiSvgIcon-root': {
    color: theme.palette.secondary.dark,
  },
}));
