import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ThemeProvider } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Moment } from 'moment';

import { datePickerTheme } from './DatePickerTheme';
import { StyledDatePickerField } from './styled';
import { DatePickerError, MultipleDatePickerState, MultipleDatePickerProps } from './types';

const MultipleDatePicker = ({ onSelectDate, label, type, value, minDate, maxDate }: MultipleDatePickerProps) => {
  const { t } = useTranslation();

  const initialState = { label, type, value: value ?? null, error: value ? '' : DatePickerError.Required };
  const DATE_FORMAT = 'DD/MM/yyyy';

  const [state, setState] = useState<MultipleDatePickerState>(initialState);

  const isValidationError = !!((state.value as Date) && state.error);
  const errorHelperText = isValidationError ? t(state.error) : '';

  const handleDateChange = (value: Moment | null) => {
    let object: MultipleDatePickerState;

    if (value) {
      const isDateInvalid =
        (value.isValid() && (value.unix() < minDate.unix() || value.unix() > maxDate.unix())) || !value.isValid();

      object = {
        ...state,
        value: value,
        error: isDateInvalid ? DatePickerError.Invalid : '',
      };
    } else {
      object = { ...state, value: null, error: DatePickerError.Required };
    }

    setState(object);
    onSelectDate(object);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <ThemeProvider theme={datePickerTheme}>
        <DatePicker
          minDate={minDate}
          maxDate={maxDate}
          value={state.value}
          onChange={handleDateChange}
          label={label}
          inputFormat={DATE_FORMAT}
          renderInput={(params) => (
            <StyledDatePickerField
              {...params}
              fullWidth
              error={isValidationError}
              helperText={errorHelperText}
              data-testid={`multipleDatePicker${label}`}
              inputProps={{ ...params.inputProps, 'data-testid': `inputMultipleDatePicker${label}` }}
            />
          )}
        />
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default MultipleDatePicker;
