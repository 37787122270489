import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';
import { Typography } from '@material-ui/core';

import ItemCard from 'src/components/Card/ItemCard';
import SharedCatalogPreviewHeader from 'src/components/Headers/SharedCatalogPreviewHeader';
import createItemIcon from 'src/components/Icon/create-item.webp';
import SkeletonProductItemCard, {
  SkeletonProductItemCardWrapper,
} from 'src/components/Skeleton/SkeletonProductItemCard';
import { FIRST_DASHBOARD_PATHNAME, SHARED_CATALOGS } from 'src/constants/routeSources';
import useSharedCatalogs from 'src/providers/SharedCataloguesProvider';
import {
  getProductItemsPreview,
  getProductTypeById,
  ProductItemValue,
  Query,
  QueryGetProductItemsPreviewArgs,
  QueryGetProductTypeArgs,
  FieldSystemName,
} from 'src/utils/gql';
import CreateProductItemBackground from 'src/views/Catalogs/ProductItems/CreateProductItemBackground';
import { SKELETON_PRODUCT_ITEMS_PREVIEW_COUNT } from 'src/views/SharedCatalogPreview/types';

import RestLabel, { RestObject } from '../RestLabel';

import {
  NoProductItemsWrapper,
  PageContentWrapper,
  PageWrapper,
  ProductItemsWrapper,
  ItemsCardWrapper,
  ProductItemsList,
} from './styled';

const ProductItemsListPreview = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { productTypeId } = useParams<{ productTypeId: string }>();
  const {
    sharedCatalogInfo: { catalogId },
    getProductName,
  } = useSharedCatalogs();

  const [isImageLoading, setIsImageLoading] = useState<boolean>(true);

  const { data: productItemsData, loading } = useQuery<
    Pick<Query, 'getProductItemsPreview'>,
    QueryGetProductItemsPreviewArgs
  >(getProductItemsPreview, {
    variables: {
      productTypeId,
    },
    onError: () => history.push(`/${FIRST_DASHBOARD_PATHNAME}/${SHARED_CATALOGS}`),
  });
  const { productItems, totalCount } = productItemsData?.getProductItemsPreview ?? {
    productItems: undefined,
    totalCount: undefined,
  };

  const { data: productTypeData } = useQuery<Pick<Query, 'getProductType'>, QueryGetProductTypeArgs>(
    getProductTypeById,
    {
      variables: {
        id: productTypeId,
      },
    },
  );

  const productType = productTypeData?.getProductType;
  const fieldsForShowInPreview = productType?.fields.filter(({ showInPreview }) => showInPreview);

  const hasItems = !!productItems?.length;

  const getValueByProductFieldName = (values: ProductItemValue[], fieldName: string): string =>
    values.find(({ productTypeField }) => productTypeField.systemName === fieldName)?.value || '';

  const productTypeName = getProductName(productTypeId);

  const locationName = productTypeName
    ? `${t('navBar.contextMenu.recentStepsTitles.sharedCatalogProductItemListPreview')} "${productTypeName}"`
    : '';

  return (
    <PageWrapper title={productTypeName} locationName={locationName}>
      <PageContentWrapper>
        <ProductItemsList>
          <SharedCatalogPreviewHeader
            title={getProductName(productTypeId)}
            countInfo={
              totalCount === undefined
                ? undefined
                : t('previewCatalogPage.productItems.count', {
                    count: totalCount,
                  })
            }
            hasArrowBack
          />

          {!(loading || hasItems) && (
            <NoProductItemsWrapper>
              {isImageLoading && <CreateProductItemBackground />}
              <img
                src={createItemIcon}
                width="316px"
                height="220px"
                alt="Items not found"
                onLoad={() => setIsImageLoading(false)}
              />
              <Typography variant="body1" maxWidth="350px" width="100%" textAlign="center" mt="30px" mb="25px">
                {t('productItemsPage.noItems')}
              </Typography>
            </NoProductItemsWrapper>
          )}

          {loading ? (
            <SkeletonProductItemCardWrapper>
              <SkeletonProductItemCard cardsCount={SKELETON_PRODUCT_ITEMS_PREVIEW_COUNT} />
            </SkeletonProductItemCardWrapper>
          ) : (
            <ProductItemsWrapper>
              <ItemsCardWrapper>
                {productItems?.map(({ id, values, mediaUsages }) => (
                  <ItemCard
                    productItemArray={values}
                    propertiesItem={fieldsForShowInPreview}
                    hasHover
                    mediaUsages={mediaUsages}
                    productItemName={getValueByProductFieldName(values, FieldSystemName.Name)}
                    productItemPrice={getValueByProductFieldName(values, FieldSystemName.Price)}
                    onItemCardClick={() =>
                      history.push(
                        `/${FIRST_DASHBOARD_PATHNAME}/${SHARED_CATALOGS}/${catalogId}/${productTypeId}/${id}`,
                      )
                    }
                    key={id}
                  />
                ))}
              </ItemsCardWrapper>

              <RestLabel object={RestObject.ProductItems} totalCount={totalCount} shownCount={productItems?.length} />
            </ProductItemsWrapper>
          )}
        </ProductItemsList>
      </PageContentWrapper>
    </PageWrapper>
  );
};

export default ProductItemsListPreview;
