import React from 'react';

import { Box, makeStyles, Skeleton, useTheme } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  cardWrapper: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '4px',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0px 2px 10px rgba(46, 96, 170, 0.25)',
  },
  infoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    borderRadius: '0 0 2px 2px',
    minWidth: '280px',
    width: '100%',
    height: '82px',
  },
  imageSkeleton: {
    width: '100%',
    height: '270px',
    minWidth: '280px',
    backgroundColor: theme.palette.text.disabled,
    borderRadius: '4px 4px 0 0',
    transform: '',
  },
  infoBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '220px',
  },
  nameSkeletonContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  longNameSkeleton: {
    width: '100px',
    height: '20px',
    backgroundColor: theme.palette.text.disabled,
    borderRadius: '4px',
  },
  shortNameSkeleton: {
    width: '70px',
    height: '20px',
    backgroundColor: theme.palette.text.disabled,
    borderRadius: '4px',
    marginTop: '2px',
  },
  priceSkeleton: {
    width: '50px',
    height: '20px',
    backgroundColor: theme.palette.text.disabled,
    borderRadius: '4px',
  },
}));

const SkeletonSingleCsvPreviewItemCard = () => {
  const theme = useTheme();
  const classes = useStyles();

  const imageSkeletonStyles = {
    bgcolor: theme.palette.text.disabled,
    borderRadius: '4px 4px 0 0',
    transform: '',
    minWidth: '280px',
    width: '100%',
    height: '270px',
  };

  return (
    <Box className={classes.cardWrapper}>
      <Skeleton sx={imageSkeletonStyles} variant="circular" />

      <Box className={classes.infoContainer}>
        <Box className={classes.infoBox}>
          <Box className={classes.nameSkeletonContainer}>
            <Skeleton className={classes.longNameSkeleton} variant="text" />
            <Skeleton className={classes.shortNameSkeleton} variant="text" />
          </Box>

          <Skeleton className={classes.priceSkeleton} variant="text" />
        </Box>
      </Box>
    </Box>
  );
};

export default SkeletonSingleCsvPreviewItemCard;
