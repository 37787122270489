import React from 'react';

import { CatalogSource } from 'src/utils/gql';

import CSVBlurBackground from './CSVBlurBackground';
import IntegrationBlurBackground from './IntegrationBlurBackground';
import { ImageBackground } from './styled';

interface BackgroundProps {
  source: string;
}

const CreateCatalogThirdStepBackground = ({ source }: BackgroundProps) => {
  switch (source) {
    case CatalogSource.Manually:
    case CatalogSource.Shopify:
    case CatalogSource.Woocommerce:
      return <IntegrationBlurBackground />;
    case CatalogSource.Csv:
      return <CSVBlurBackground />;
    default:
      return <ImageBackground />;
  }
};

export default CreateCatalogThirdStepBackground;
