import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { ApolloError, useMutation, useQuery } from '@apollo/client';

import Loader from 'src/components/Loader';
import Page from 'src/components/Page';
import { CATALOGS_PATH, CREATION, FIRST_DASHBOARD_PATHNAME, PRODUCT_TYPES_PATH } from 'src/constants/routeSources';
import { capitalize } from 'src/helpers/capitalize';
import useOnboarding from 'src/providers/OnboardingProvider/hooks/useOnboarding';
import { useSnackbar } from 'src/providers/snackbar';
import { createNewProductType, getDefaultFields, Query, RequiredProductTypeFieldData } from 'src/utils/gql';

import { popoverActions } from './SaveButtonWithMenu';
import { ProductTypeSettingsField, ProductTypeSettingsPage } from './types';

import ProductTypeSettings from '.';

interface ProductTypeSettingsCreationProps {
  type: ProductTypeSettingsPage;
}

const ProductTypeSettingsCreation = ({ type = ProductTypeSettingsPage.Fields }: ProductTypeSettingsCreationProps) => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string; productTypeId: string }>();
  const snackbar = useSnackbar();
  const history = useHistory();
  const {
    onboardingState: { tourActive },
    goNext,
  } = useOnboarding();

  const [isCreationSuccessful, setIsCreationSuccessful] = useState<boolean>(false);

  const [createProductType] = useMutation(createNewProductType);

  const { data, loading } = useQuery<Pick<Query, 'getDefaultFields'>, Array<RequiredProductTypeFieldData>>(
    getDefaultFields,
    {
      fetchPolicy: 'cache-first',
    },
  );

  const initialState = useMemo(
    () =>
      data?.getDefaultFields.map((field) => ({
        ...field,
        name: capitalize(field.systemName as string),
        type: field.type,
        showInPreview: true,
        showInFilters: true,
      })),
    [data?.getDefaultFields],
  );

  const handleSaveProductTypeSettings = async (
    action: popoverActions,
    fields: ProductTypeSettingsField[],
    productTypeName: string,
  ) => {
    try {
      const { data } = await createProductType({
        variables: {
          data: {
            name: productTypeName,
            fields,
          },
          catalogId: id,
        },

        update(cache, { data }) {
          const createdProductType = data?.createProductType;

          if (createdProductType) {
            cache.evict({ fieldName: 'productTypes' });
            cache.evict({ id: `ProductType:${createdProductType.id}` });
            cache.gc();
          }
        },
      });

      setIsCreationSuccessful(!isCreationSuccessful);

      const createdProductTypeId = data.createProductType.id;

      snackbar(t('productType.productTypeHasBeenCreated'), 'success');

      history.push(
        `/${FIRST_DASHBOARD_PATHNAME}/${CATALOGS_PATH}/${id}/${PRODUCT_TYPES_PATH}${
          action === popoverActions.save ? '' : `/${createdProductTypeId}/${CREATION}`
        }`,
      );

      if (tourActive) {
        setTimeout(() => {
          goNext();

          window.history.pushState({}, '', history.location.pathname);
        }, 150);
      }
    } catch (error) {
      const { graphQLErrors, message: errorText } = error as ApolloError;
      const message = graphQLErrors && graphQLErrors.length ? graphQLErrors[0].message : errorText;

      if (error) {
        snackbar(message);
      }
    }
  };

  return loading || !initialState ? (
    <Loader fullAvailableScreen />
  ) : (
    <Page title={t('productType.productTypeCreation')} locationName={t('productType.productTypeCreation')}>
      <ProductTypeSettings
        type={type}
        initialDefaultFields={initialState as ProductTypeSettingsField[]}
        initialCustomFields={[]}
        onSave={handleSaveProductTypeSettings}
        initialProductTypeName=""
        shouldBreadcrumbsFollowName={false}
        isSubmitSuccessful={isCreationSuccessful}
      />
    </Page>
  );
};

export default ProductTypeSettingsCreation;
