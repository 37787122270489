import React, { useState, useEffect } from 'react';
import { animateScroll as scroll } from 'react-scroll';

import { Fab } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';

import Iconography from 'src/components/Iconography';

export const StyledFab = styled(Fab)(({ theme }) => ({
  position: 'fixed',
  top: 'auto',
  left: 'auto',
  margin: 0,
  right: '10px',
  bottom: '10px',
  background: theme.palette.info.main,
  boxShadow: '0 2px 10px rgba(46, 96, 170, 0.25)',
  width: '40px',
  height: '40px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50px',
  outline: 'none',
  'z-index': 25,

  '&:hover': {
    background: theme.palette.info.main,
  },

  '&:active ': {
    boxShadow: '0 2px 10px rgba(46, 96, 170, 0.25)',
  },

  '& svg': {
    transform: 'rotate(-90deg)',
    color: theme.palette.common.white,
  },
}));

interface ButtonScrollToTopProps {
  intersection: number;
}

const ButtonScrollToTop = ({ intersection }: ButtonScrollToTopProps) => {
  const [showButtonUp, setShowButtonUp] = useState<number>(0);

  const handleScroll = () => setShowButtonUp(window.scrollY);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      {showButtonUp >= intersection && (
        <StyledFab onClick={() => scroll.scrollToTop()} disableRipple id="scroll-to-top-button">
          <Iconography iconName="chevron-arrow-right" />
        </StyledFab>
      )}
    </>
  );
};

export default ButtonScrollToTop;
