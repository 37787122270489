import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ApolloError, useQuery } from '@apollo/client';
import { Box, DialogContent, Typography } from '@mui/material';

import Popup from 'src/components/Popup';
import DiscardChangesPopup from 'src/components/Popups/DiscardChangesPopup';
import SideBarDrawer from 'src/components/SideBar/RightSidebarDrawer';
import {
  useCreateProductTypeFieldMappings,
  useCreateProductTypesMappings,
  useDeleteProductTypeFieldMappings,
  useUpdateProductTypeFieldMappings,
  useUpdateProductTypeMappings,
} from 'src/hooks/graphQLRequestsHooks';
import { useSnackbar } from 'src/providers/snackbar';
import { getMessageForSnackbarFromError } from 'src/utils/general';
import {
  getProductTypeIntegrationMappingsByCatalogId,
  Integration,
  ProductType,
  Query,
  QueryGetProductTypeIntegrationMappingsByCatalogIdArgs,
  QueryProductTypeFieldIntegrationMappingsArgs,
  integrationProductTypeFieldMappings,
  ProductTypeFieldIntegrationMapping,
  CreateProductTypeIntegrationMappingInput,
  CreateProductTypeFieldIntegrationMappingInput,
  UpdateProductTypeIntegrationMappingInput,
  CreateOrUpdateProductTypeFieldIntegrationMappingInput,
  UpdateProductTypeFieldIntegrationMappingInput,
  Catalog,
  QueryGetCatalogByIdArgs,
  getCatalogInfo,
} from 'src/utils/gql';
import { truncateText, MAX_SIDEBAR_SUBTITLE_LENGTH } from 'src/utils/truncateText';
import {
  FieldMappingsFormData,
  getFieldMappingKey,
} from 'src/views/Catalogs/ProductTypes/ProductTypeSettings/MappingTabContent/IntegrationInfo';

import { FirstTimeExportMappingSidebarProps, FirstTimeMappingsBodyProps } from '../index';
import { MappingSidebarContent } from '../styled';

import IntegrationMappings from './IntegrationMappings';

interface FirstTimeExportMappingProps {
  catalog: Catalog;
  integrations: Integration[];
  productTypes: ProductType[];
  defaultFieldMappings?: Record<string, Record<string, ProductTypeFieldIntegrationMapping[]>>;
}

export interface TypeMappingData {
  id?: string;
  metadataExternalId?: string;
  productTypeId: string;
  toSync: boolean;
}

export interface ProposedMappingsSidebarFormData {
  [key: string]: TypeMappingData | FieldMappingsFormData | undefined;
}

export interface IntegrationAndProductTypesIds {
  integrationId: string;
  productTypeId: string;
}

export type GeneralTypeMappingInputInterface =
  | CreateProductTypeIntegrationMappingInput
  | UpdateProductTypeIntegrationMappingInput;

export const getFieldKeyForFirstTimeExportForm = (integrationId: string, productTypeId: string): string =>
  `integrationId(${integrationId}),productTypeId(${productTypeId})`;

export const getIntegrationAndProductTypesIdsFromFieldKey = (fieldKey: string): IntegrationAndProductTypesIds => {
  const result = fieldKey.match(/\(\d+\)/g)?.map((s: string) => s.replace(/[()]/g, ''));

  if (!result) {
    return {
      integrationId: '',
      productTypeId: '',
    };
  }

  return {
    integrationId: result[0],
    productTypeId: result[1],
  };
};

export const fieldMappingsFormDataToCreateOrUpdateProductTypeFieldIntegrationMappingInput = ({
  id,
  fieldId,
  externalFieldId,
  toSync,
}: FieldMappingsFormData): CreateOrUpdateProductTypeFieldIntegrationMappingInput => ({
  id,
  metadataExternalId: externalFieldId || '',
  productTypeFieldId: fieldId || null,
  toSync,
});

export const fieldMappingsFormDataToCreateProductTypeFieldIntegrationMappingInput = (
  { fieldId, externalFieldId, toSync, integrationId }: FieldMappingsFormData,
  productTypeId: string,
): CreateProductTypeFieldIntegrationMappingInput => ({
  integrationId,
  metadataExternalId: externalFieldId || '',
  productTypeFieldId: fieldId,
  productTypeId,
  toSync,
});

const FirstTimeExportMappingsContent = ({
  integrations,
  productTypes,
  defaultFieldMappings,
}: FirstTimeExportMappingProps) => (
  <Box>
    {integrations.map((integration) => (
      <IntegrationMappings
        key={integration.id}
        integration={integration}
        productTypes={productTypes}
        defaultFieldMappings={defaultFieldMappings?.[integration.id]}
      />
    ))}
  </Box>
);

export const FirstTimeExportMappingSidebarBody = ({
  onClickExport,
  isOpen,
  catalog,
  integrations,
  productTypes,
  proposedMappings,
  existTypeMappings,
  existFieldMappings,
  onClose,
  secondaryButtonTitle,
  customSidebarTitle,
}: FirstTimeMappingsBodyProps) => {
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const [openDiscardChanges, setOpenDiscardChanges] = useState<boolean>(false);
  const [isSavedChangesPopupOpen, setIsSavedChangesPopupOpen] = useState<boolean>(false);
  const [exportButtonLoading, setExportButtonLoading] = useState<boolean>(false);
  const [closeDrawerPopup, setCloseDrawerPopup] = useState<boolean>(false);

  const createProductTypeMappings = useCreateProductTypesMappings();

  const updateProductTypeMappings = useUpdateProductTypeMappings();

  const createProductTypeFieldMappings = useCreateProductTypeFieldMappings();

  const updateProductTypeFieldMappings = useUpdateProductTypeFieldMappings();

  const deleteProductTypeFieldMappings = useDeleteProductTypeFieldMappings();

  const defaultFieldMappings: Record<string, Record<string, ProductTypeFieldIntegrationMapping[]>> = useMemo(
    () =>
      existFieldMappings.reduce(
        (acc: Record<string, Record<string, ProductTypeFieldIntegrationMapping[]>>, fieldMapping) => {
          const currentType = productTypes.find(({ id }) => id === fieldMapping.productTypeId);

          if (!currentType) {
            return acc;
          }

          const currentTypeId = Number(currentType.id);

          if (!acc[fieldMapping.integrationId]) {
            const defaultIntegrationMap = { [fieldMapping.integrationId]: {} };

            acc = { ...acc, ...defaultIntegrationMap };
          }

          if (!acc[fieldMapping.integrationId][currentTypeId]) {
            const defaultTypeMap = { [currentTypeId]: [] };

            acc[fieldMapping.integrationId] = { ...acc[fieldMapping.integrationId], ...defaultTypeMap };
          }

          acc[fieldMapping.integrationId][currentTypeId].push(fieldMapping);

          return acc;
        },
        {},
      ),
    [existFieldMappings, productTypes],
  );

  const defaultValues = useMemo(() => {
    const typeMappingsMap = proposedMappings.reduce(
      (acc: Record<string, Record<string, TypeMappingData>>, { integrationId, proposedMappings }) => {
        acc[integrationId] = proposedMappings.reduce(
          (integrationMappingsAcc: Record<string, TypeMappingData>, { productTypeId, metadataExternalId }) => {
            integrationMappingsAcc[productTypeId] = {
              productTypeId,
              metadataExternalId: metadataExternalId ?? undefined,
              toSync: !!metadataExternalId,
            };

            return integrationMappingsAcc;
          },
          {},
        );

        return acc;
      },
      {},
    );

    existTypeMappings.forEach(({ id, metadataExternalId, productTypeId, toSync, integrationId }) => {
      const mappingData = {
        id,
        metadataExternalId,
        productTypeId,
        toSync,
      };

      if (typeMappingsMap[integrationId]) {
        typeMappingsMap[integrationId][productTypeId] = mappingData;
      } else {
        typeMappingsMap[integrationId] = { [productTypeId]: mappingData };
      }
    });

    const existFieldMappingsMap = existFieldMappings.reduce(
      (
        acc: Record<string, FieldMappingsFormData[]>,
        { id, integrationId, productTypeFieldId, metadataExternalId, productTypeId, toSync },
      ) => {
        const currentType = productTypes.find(({ id }) => id === productTypeId);

        if (!currentType) {
          return acc;
        }

        const typeKey = getFieldKeyForFirstTimeExportForm(integrationId, currentType.id);

        if (!acc[typeKey]) {
          acc[typeKey] = [];
        }

        acc[typeKey].push({
          id,
          integrationId,
          fieldId: productTypeFieldId,
          externalFieldId: metadataExternalId,
          toSync,
          softDeleted: false,
        });

        return acc;
      },
      {},
    );

    const fieldMappingsFormData = Object.keys(existFieldMappingsMap).reduce(
      (acc: Record<string, FieldMappingsFormData>, key) => {
        existFieldMappingsMap[key].forEach((fieldMappingData, idx) => {
          acc[getFieldMappingKey(idx, key)] = fieldMappingData;
        });

        return acc;
      },
      {},
    );

    return integrations.reduce(
      (acc: ProposedMappingsSidebarFormData, { id: integrationId }) => ({
        ...acc,
        ...productTypes.reduce((productTypesAcc: ProposedMappingsSidebarFormData, { id: productTypeId }) => {
          productTypesAcc[getFieldKeyForFirstTimeExportForm(integrationId, productTypeId)] = typeMappingsMap[
            integrationId
          ]?.[productTypeId] || {
            productTypeId,
            toSync: false,
          };

          return productTypesAcc;
        }, fieldMappingsFormData),
      }),
      {},
    );
  }, [existFieldMappings, existTypeMappings, integrations, productTypes, proposedMappings]);

  const formProps = useForm<ProposedMappingsSidebarFormData>({
    mode: 'onSubmit',
    defaultValues,
  });

  const handleExportClick = () => {
    setExportButtonLoading(true);

    const fieldsWithoutExternalFieldId: string[] = [];
    const formFields = formProps.getValues();

    for (const [key, value] of Object.entries(formFields)) {
      if (value && typeof value === 'object' && 'externalFieldId' in value && !value?.externalFieldId) {
        fieldsWithoutExternalFieldId.push(key);
      }
    }

    formProps.unregister(fieldsWithoutExternalFieldId);

    formProps.handleSubmit(
      async (data) => {
        const productTypesMappingsForCreateData: Record<string, CreateProductTypeIntegrationMappingInput> = {};
        const productTypesMappingsForUpdateData: Record<string, UpdateProductTypeIntegrationMappingInput> = {};
        const productTypesMappingsWithoutUpdatesKeys: string[] = [];
        const fieldMappingsKeysMap: Record<string, string[]> = {};
        const softDeletedFieldMappingsKeysMap: Record<string, string[]> = {};

        try {
          Object.keys(data).forEach((key) => {
            const mappingData = data[key];

            if (!mappingData) {
              return;
            }

            const { integrationId, productTypeId } = getIntegrationAndProductTypesIdsFromFieldKey(key);

            // product type mappings
            if (key.endsWith(')')) {
              // create productType mappings
              const typeMappingData = mappingData as TypeMappingData;

              if (typeMappingData) {
                if (typeMappingData.id) {
                  // exist type mappings
                  if (formProps.formState.dirtyFields[key]) {
                    // for updating type mappings
                    productTypesMappingsForUpdateData[key] = {
                      id: typeMappingData.id,
                      metadata: {},
                      metadataExternalId: typeMappingData.metadataExternalId || '',
                      productTypeId: typeMappingData.productTypeId,
                      toSync: typeMappingData.toSync,
                      fieldMappings: [],
                    };
                  } else {
                    // for updating field mappings
                    productTypesMappingsWithoutUpdatesKeys.push(key);
                  }
                } else {
                  // new type mappings
                  productTypesMappingsForCreateData[key] = {
                    integrationId,
                    fieldMappings: [],
                    metadataExternalId: typeMappingData.metadataExternalId || '',
                    productTypeId: typeMappingData.productTypeId,
                    toSync: typeMappingData.toSync,
                  };
                }
              }
            } else {
              // fields mappings
              const fieldMappingData = mappingData as FieldMappingsFormData;

              const typeMappingKey = getFieldKeyForFirstTimeExportForm(integrationId, productTypeId);

              if (!softDeletedFieldMappingsKeysMap[typeMappingKey]) {
                softDeletedFieldMappingsKeysMap[typeMappingKey] = [];
              }

              if (!fieldMappingsKeysMap[typeMappingKey]) {
                fieldMappingsKeysMap[typeMappingKey] = [];
              }

              (fieldMappingData.softDeleted ? softDeletedFieldMappingsKeysMap : fieldMappingsKeysMap)[
                typeMappingKey
              ].push(key);
            }
          });

          const enrichTypeMappingsWithFieldMappings = <T extends GeneralTypeMappingInputInterface>(
            typeMappingDataObject: Record<string, T>,
            includeWithIds?: boolean,
          ): T[] =>
            Object.keys(typeMappingDataObject).map((typeMappingKey) => {
              const typeMappingData = typeMappingDataObject[typeMappingKey];

              (typeMappingData as T).fieldMappings =
                fieldMappingsKeysMap[typeMappingKey]?.reduce(
                  (acc: CreateOrUpdateProductTypeFieldIntegrationMappingInput[], formDataKey) => {
                    // for invalid DB data
                    if (data[formDataKey] && (includeWithIds || !data[formDataKey]?.id)) {
                      acc.push(
                        fieldMappingsFormDataToCreateOrUpdateProductTypeFieldIntegrationMappingInput(
                          data[formDataKey] as FieldMappingsFormData,
                        ),
                      );
                    }

                    return acc;
                  },
                  [],
                ) || [];

              return typeMappingData;
            });

          const productTypesMappingsForCreate = enrichTypeMappingsWithFieldMappings(productTypesMappingsForCreateData);

          const productTypesMappingsForUpdate = enrichTypeMappingsWithFieldMappings(
            productTypesMappingsForUpdateData,
            true,
          );
          const fieldsMappingsForOperations = productTypesMappingsWithoutUpdatesKeys.reduce(
            (
              acc: {
                fieldsForCreate: CreateProductTypeFieldIntegrationMappingInput[];
                fieldsForUpdate: UpdateProductTypeFieldIntegrationMappingInput[];
                fieldsIdsForDelete: string[];
              },
              nonUpdatedTypeKey,
            ) => {
              softDeletedFieldMappingsKeysMap[nonUpdatedTypeKey]?.forEach((keyForDelete) => {
                const idForDelete = data[keyForDelete]?.id;

                if (idForDelete) {
                  acc.fieldsIdsForDelete.push(idForDelete);
                }
              });

              fieldMappingsKeysMap[nonUpdatedTypeKey]?.forEach((nonDeletingFieldKey) => {
                const fieldMappingData = data[nonDeletingFieldKey] as FieldMappingsFormData;

                if (fieldMappingData && formProps.formState.dirtyFields[nonDeletingFieldKey]) {
                  if (fieldMappingData.id) {
                    acc.fieldsForUpdate.push(
                      fieldMappingsFormDataToCreateOrUpdateProductTypeFieldIntegrationMappingInput(
                        fieldMappingData,
                      ) as UpdateProductTypeFieldIntegrationMappingInput,
                    );
                  } else {
                    const { productTypeId } = getIntegrationAndProductTypesIdsFromFieldKey(nonUpdatedTypeKey);
                    acc.fieldsForCreate.push(
                      fieldMappingsFormDataToCreateProductTypeFieldIntegrationMappingInput(
                        fieldMappingData,
                        productTypeId,
                      ),
                    );
                  }
                }
              });

              return acc;
            },
            { fieldsForCreate: [], fieldsForUpdate: [], fieldsIdsForDelete: [] },
          );

          await Promise.all([
            productTypesMappingsForUpdate.length &&
              updateProductTypeMappings({
                variables: {
                  mappings: productTypesMappingsForUpdate,
                },
              }),
            productTypesMappingsForCreate.length &&
              createProductTypeMappings({
                variables: {
                  mappings: productTypesMappingsForCreate,
                },
              }),
            fieldsMappingsForOperations.fieldsIdsForDelete.length &&
              deleteProductTypeFieldMappings({ variables: { ids: fieldsMappingsForOperations.fieldsIdsForDelete } }),
            fieldsMappingsForOperations.fieldsForUpdate.length &&
              updateProductTypeFieldMappings({ variables: { mappings: fieldsMappingsForOperations.fieldsForUpdate } }),
            fieldsMappingsForOperations.fieldsForCreate.length &&
              createProductTypeFieldMappings({ variables: { mappings: fieldsMappingsForOperations.fieldsForCreate } }),
          ] as Promise<unknown>[]);
        } catch (e) {
          if (e) {
            snackbar(getMessageForSnackbarFromError(e as ApolloError));
          }
        }

        setExportButtonLoading(false);

        onClickExport?.();
      },
      () => setExportButtonLoading(false),
    )();
  };

  const handleCloseSidebarOnClickAway = () => {
    if (Object.keys(formProps.formState.dirtyFields).length) {
      setOpenDiscardChanges(true);
    } else {
      onClose();
    }
  };

  const handleDiscardChanges = () => {
    setOpenDiscardChanges(false);

    setCloseDrawerPopup(true);
  };

  useEffect(() => {
    if (closeDrawerPopup) {
      onClose();
    } else {
      return;
    }
  }, [onClose, closeDrawerPopup]);

  const handleSave = () => {
    const hasFieldToDelete = Object.values(formProps.getValues())?.some(
      (field: TypeMappingData | FieldMappingsFormData | undefined) => (field as FieldMappingsFormData)?.softDeleted,
    );

    hasFieldToDelete ? setIsSavedChangesPopupOpen(true) : handleExportClick();
  };

  return (
    <FormProvider {...formProps}>
      <SideBarDrawer
        primaryButtonTitle={t('mappingSidebar.closeButton')}
        onPrimaryButtonClick={handleCloseSidebarOnClickAway}
        secondaryButtonTitle={secondaryButtonTitle || t('mappingSidebar.exportButton')}
        onSecondaryButtonClick={handleSave}
        secondaryButtonLoading={formProps.formState.isSubmitting || exportButtonLoading}
        titleName={customSidebarTitle || t('mappingSidebar.firstTimeMapping.title')}
        subtitle={t('mappingSidebar.firstTimeMapping.pleaseMapText', {
          option: truncateText({ text: catalog.name, maxLength: MAX_SIDEBAR_SUBTITLE_LENGTH }),
        })}
        openSidebar={isOpen}
        dataTestIdPrimaryButton="closeMappingsButton"
        dataTestIdSecondaryButton="exportMappingsButton"
        dataTestId="mappingsSidebar"
        handleCloseSidebarOnClickAway={handleCloseSidebarOnClickAway}
      >
        <MappingSidebarContent>
          <FirstTimeExportMappingsContent
            catalog={catalog}
            integrations={integrations}
            productTypes={productTypes}
            defaultFieldMappings={defaultFieldMappings}
          />
        </MappingSidebarContent>
      </SideBarDrawer>

      <DiscardChangesPopup
        isOpen={openDiscardChanges}
        isManual={openDiscardChanges}
        onMainButtonClick={handleDiscardChanges}
        onSecondaryButtonClick={() => setOpenDiscardChanges(false)}
      />

      <Popup
        open={isSavedChangesPopupOpen}
        mainTitle={t('productType.popupDeleteSelectedProductTypeFields.title')}
        mainButtonText={t('productType.popupDeleteSelectedProductTypeFields.mainButton')}
        onMainButtonClick={handleExportClick}
        secondaryButtonText={t('productType.popupDeleteSelectedProductTypeFields.secondaryButton')}
        onClose={() => setIsSavedChangesPopupOpen(false)}
        maxWidth="sm"
      >
        <DialogContent>
          <Typography ml="24px" mr="24px" variant="body1" color="text.secondary">
            {t('productType.popupDeleteSelectedProductTypeFields.contentText')}
          </Typography>
        </DialogContent>
      </Popup>
    </FormProvider>
  );
};

export const FirstTimeExportMappingSidebar = (props: FirstTimeExportMappingSidebarProps) => {
  const { data: productTypeIntegrationMappingsData } = useQuery<
    Pick<Query, 'getProductTypeIntegrationMappingsByCatalogId'>,
    QueryGetProductTypeIntegrationMappingsByCatalogIdArgs
  >(getProductTypeIntegrationMappingsByCatalogId, {
    variables: {
      catalogId: props.currentCatalogId,
    },
    fetchPolicy: 'cache-first',
  });

  const { data: productTypeFieldIntegrationMappingsData } = useQuery<
    Pick<Query, 'productTypeFieldIntegrationMappings'>,
    QueryProductTypeFieldIntegrationMappingsArgs
  >(integrationProductTypeFieldMappings, {
    variables: {
      catalogId: props.currentCatalogId,
    },
    fetchPolicy: 'cache-first',
  });

  const { data: catalogData } = useQuery<Pick<Query, 'getCatalogById'>, QueryGetCatalogByIdArgs>(getCatalogInfo, {
    variables: {
      id: props.currentCatalogId,
    },
    fetchPolicy: 'cache-first',
  });

  const sidebarСanBeOpened =
    productTypeIntegrationMappingsData && productTypeFieldIntegrationMappingsData && catalogData;

  return sidebarСanBeOpened ? (
    <FirstTimeExportMappingSidebarBody
      {...props}
      catalog={catalogData.getCatalogById}
      existTypeMappings={productTypeIntegrationMappingsData.getProductTypeIntegrationMappingsByCatalogId}
      existFieldMappings={productTypeFieldIntegrationMappingsData.productTypeFieldIntegrationMappings}
    />
  ) : null;
};

export default FirstTimeExportMappingsContent;
