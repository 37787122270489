import React, { FormEvent, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import { CircularProgress, Link, Typography, Theme, makeStyles } from '@material-ui/core';
import { Box } from '@mui/material';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { StripeError, StripePaymentElementChangeEvent } from '@stripe/stripe-js';

import { useSnackbar } from 'src/providers/snackbar';

import Button from '../Button';
import TextInput from '../Forms/FormInputs/TextInput';
import { addPaymentCardFormSchema } from '../Forms/validationSchemas';
import { PaymentSkeleton } from '../Skeleton/PaymentSkeleton';

import { appearance, StyledPaymentForm, SuccessPaymentBackdrop } from './styled';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.palette.text.disabled,
    textDecoration: 'underline',
  },
  button: {
    background: theme.palette.secondary.dark,
  },
  cardholderInputMargin: {
    marginBottom: '14px',
  },
}));

interface PaymentFormProps {
  testMode?: {
    showCardHolderField: boolean;
    showSuccessPaymentBackdrop: boolean;
  };
}

export default function PaymentForm({ testMode }: PaymentFormProps) {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const stripe = useStripe();
  const elements = useElements();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [isFormReady, setIsFormReady] = useState<boolean>(false);
  const [isPayCardDataIncomplete, setIsPayCardDataIncomplete] = useState<boolean>(true);

  const formMethods = useForm({
    mode: 'onChange',
    resolver: yupResolver(addPaymentCardFormSchema),
  });

  const {
    formState: { isValid },
    getValues,
  } = formMethods;

  const submitButtonShouldBeDisabled = (isPayCardDataIncomplete || !isValid) && !testMode;

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    const cardholderName = getValues('cardholderName');

    if (!((stripe && elements) || testMode)) {
      return;
    }

    if (cardholderName && stripe && elements) {
      setIsLoading(true);

      const { paymentIntent, error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          payment_method_data: {
            billing_details: {
              name: cardholderName,
            },
          },
        },
        redirect: 'if_required',
      });

      if (paymentIntent && paymentIntent.status === 'succeeded') {
        elements.update({
          appearance: appearance,
        });

        setShowBackdrop(true);

        setTimeout(() => {
          history.replace('/dashboard/user-settings');
        }, 6000);
      }

      if (error) {
        elements.update({
          appearance: appearance,
        });

        const { message, type } = error as StripeError;

        if (type !== 'validation_error' && message) {
          snackbar(message);
        }
      }
    }

    setIsLoading(false);
  };

  useEffect(() => {
    elements
      ?.getElement('payment')
      ?.on('change', (elementsEvent: StripePaymentElementChangeEvent) =>
        setIsPayCardDataIncomplete(!elementsEvent.complete),
      );
  }, [elements]);

  return (
    <>
      <StyledPaymentForm onSubmit={handleSubmit} data-testid="stripe-payment-form">
        <Box minHeight="270px">
          {!isFormReady && <PaymentSkeleton />}

          {(isFormReady || testMode?.showCardHolderField) && (
            <FormProvider {...formMethods}>
              <TextInput
                name="cardholderName"
                shouldFocus
                fullWidth
                label={t('userSettingsPage.billing.addPaymentMethodPopup.cardholderName')}
                inputNameTestId="stripe-cardholder-field"
                className={classes.cardholderInputMargin}
              />
            </FormProvider>
          )}

          <PaymentElement
            onReady={() => setIsFormReady(true)}
            options={{
              terms: { card: 'never' },
              wallets: { applePay: 'never', googlePay: 'never' },
              readOnly: isLoading,
            }}
            data-testid="cardField"
          />
        </Box>

        <Typography data-testid="stripe-info-block" mt="15px" fontWeight={400} fontSize="14px" color="text.disabled">
          {t('paymentPage.billingInfo.formText')}
          <Link href="https://stage.otomate.shop/documents/#terms-and-conditions" className={classes.root}>
            {t('paymentPage.billingInfo.here')}
          </Link>
          . {t('paymentPage.billingInfo.questions')}
          <Link href="https://stage.otomate.shop/contact-us/" className={classes.root}>
            {t('paymentPage.billingInfo.support')}
          </Link>
          .
        </Typography>

        <Box display="flex" justifyContent="center" mt="40px">
          <Button
            className={classes.button}
            variant="contained"
            type="submit"
            fullWidth
            loading={isLoading}
            disabled={submitButtonShouldBeDisabled}
          >
            {t('paymentPage.billingInfo.submitButton')}
          </Button>
        </Box>
      </StyledPaymentForm>

      <SuccessPaymentBackdrop
        data-testid="stripe-success-backdrop"
        open={showBackdrop || Boolean(testMode?.showSuccessPaymentBackdrop)}
      >
        <Box display="flex" flexDirection="column" alignItems="center">
          <CircularProgress color="inherit" size={30} />

          <Typography color="inherit" mt="10px" mb="2px">
            {t('paymentPage.billingInfo.successPayment')}
          </Typography>
          <Typography color="inherit">{t('paymentPage.billingInfo.redirectMessage')}</Typography>
        </Box>
      </SuccessPaymentBackdrop>
    </>
  );
}
