import React from 'react';

import Box from '@material-ui/core/Box';
import CircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress';
import { makeStyles, Theme } from '@material-ui/core/styles';

import close from 'src/components/Images/CircularProgressClose.svg';

interface CircularProgressNewProps extends CircularProgressProps {
  onProgressButtonClick: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderRadius: '50px',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: `0 0 15px ${theme.palette.secondary.main}`,
    },
    '&:active': {
      boxShadow: 'none',
    },
    '&& .MuiCircularProgress-root': {
      color: theme.palette.background.default,
    },
  },
  container: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&& .MuiCircularProgress-root': {
      color: theme.palette.info.main,
    },
  },
}));

const CircularProgressButton = ({ value, onProgressButtonClick }: CircularProgressNewProps) => {
  const classes = useStyles();

  return (
    <Box position="relative" display="inline-flex" onClick={onProgressButtonClick} className={classes.root}>
      <CircularProgress variant="determinate" size={24} thickness={2} value={100} />
      <Box className={classes.container}>
        <CircularProgress variant="determinate" size={24} thickness={2} value={value} />
      </Box>
      <Box className={classes.container}>
        <img src={close} alt="close" width="10.5" height="10.5" />
      </Box>
    </Box>
  );
};

export default CircularProgressButton;
