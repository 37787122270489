import React from 'react';
import { useTranslation } from 'react-i18next';
import Joyride, { ACTIONS, CallBackProps, EVENTS, Locale, STATUS } from 'react-joyride';

import useOnboarding from 'src/providers/OnboardingProvider/hooks/useOnboarding';

import CustomOnboardingTooltip from './CustomOnboardingTooltip';
import { styles } from './styles';

const Onboarding = () => {
  const { t } = useTranslation();

  const {
    setOnboardingState,
    finishOnboarding,
    onboardingState: { run, stepIndex, steps },
  } = useOnboarding();

  const locale: Locale = {
    next: t('onboarding.button.next'),
    back: t('onboarding.button.back'),
    skip: t('onboarding.button.skip'),
    last: t('onboarding.button.last'),
    close: t('onboarding.button.close'),
  };

  const handleOnboardingCallback = ({ action, index, status, type }: CallBackProps) => {
    if ([EVENTS.STEP_AFTER as string].includes(type)) {
      setTimeout(() => {
        setOnboardingState((prevState) => ({
          ...prevState,
          stepIndex: index + (action === ACTIONS.PREV ? -1 : 1),
        }));
      }, 0);
    } else if ([STATUS.FINISHED as string, STATUS.SKIPPED as string].includes(status)) {
      finishOnboarding();
    }
  };

  return (
    <Joyride
      steps={steps}
      tooltipComponent={CustomOnboardingTooltip}
      hideCloseButton
      disableOverlayClose
      disableCloseOnEsc
      disableScrolling
      hideBackButton
      spotlightClicks
      spotlightPadding={10}
      callback={handleOnboardingCallback}
      locale={locale}
      continuous
      run={run}
      stepIndex={stepIndex}
      styles={styles.root}
    />
  );
};

export default Onboarding;
