import { Box, FormControl, IconButton, InputLabel, OutlinedInput, Typography } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';

export const StyledKeywordsDropdown = styled(Box)`
  border-left: 1px solid ${({ theme: { palette } }) => palette.secondary.main};
  border-right: 1px solid ${({ theme: { palette } }) => palette.secondary.main};
  border-bottom: 1px solid ${({ theme: { palette } }) => palette.secondary.main};
  position: relative;
  top: -42px;
  border-radius: 22px;
  background-color: ${({ theme: { palette } }) => palette.common.white};
  min-height: 115px;
  padding-bottom: 15px;
`;

export const StyledTypography = styled(Typography)`
  margin-top: 57px;
  margin-left: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: ${({ theme: { palette } }) => palette.text.disabled};
`;

export const StyledResetButton = styled(Typography)`
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: ${({ theme: { palette } }) => palette.text.secondary};
`;

export const StyledKeywordCard = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  min-width: 30px;
  width: auto;
  height: 24px;
  background: ${({ theme: { palette } }) => palette.background.default};
  border-radius: 5px;
`;

export const StyledKeywordCardWithHover = styled(StyledKeywordCard)`
  :hover {
    background: ${({ theme: { palette } }) => palette.secondary.main};
  }
`;

export const StyledKeywordItemTypography = styled(Typography)`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme: { palette } }) => palette.primary.light};
  padding: 5px 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 1200px;
`;

export const StyledClickToViewMoreTypography = styled(Typography)`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: ${({ theme: { palette } }) => palette.text.secondary};
  cursor: pointer;
`;

export const StyledForm = styled('form')`
  width: ${(props) => (props.defaultChecked ? '100%' : '')};
`;

export const StyledFormControl = styled(FormControl)`
  width: ${(props) => (props.fullWidth ? '100%' : '')};
`;

export const StyledInputLabel = styled(InputLabel)`
  font-size: 12px;
  line-height: 14px;
  &[data-shrink='false'] {
    margin-top: -2px;
  }
  &[data-shrink='true'] {
    margin-top: 3px;
    margin-left: -2px;
  }
`;

export const StyledOutlinedInput = styled(OutlinedInput)`
  width: ${(props) => (props.fullWidth ? '100%' : '184px')};
  box-sizing: border-box;
  border-radius: 25px;
  position: absolute;

  .MuiOutlinedInput-notchedOutline {
    top: -6px;
  }
  .MuiInputAdornment-root {
    height: 39px;
    align-items: stretch;
    max-height: 39px;
    button {
      display: flex;
      border-left: 1px solid;
      border-color: ${({ theme: { palette } }) => palette.text.disabled};
      border-radius: 0;
    }
  }
  &.Mui-focused {
    .MuiInputAdornment-root {
      button {
        border-color: ${({ theme: { palette } }) => palette.secondary.main};
      }
    }
  }
  &.Mui-disabled {
    .MuiInputAdornment-root {
      button {
        border-color: ${({ theme: { palette } }) => palette.text.disabled};
      }
    }
    :hover {
      .MuiInputAdornment-root {
        button {
          border-color: ${({ theme: { palette } }) => palette.text.disabled};
        }
      }
    }
  }
  :hover {
    .MuiInputAdornment-root {
      button {
        border-color: ${({ theme: { palette } }) => palette.secondary.main};
      }
    }
  }
  .MuiButtonBase-root {
    svg {
      color: ${({ theme: { palette } }) => palette.text.disabled};
      margin-left: -3px;
    }
  }
`;

export const StyledSearchButton = styled(IconButton)`
  &.Mui-focusVisible {
    box-shadow: none;
  }

  &.MuiButtonBase-root {
    padding: 8px;
  }
`;
