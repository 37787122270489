import { Box, Button, Link, Typography, experimentalStyled as styled, FormControl } from '@material-ui/core';

export const CloseButton = styled(Button)`
  margin-right: 15px;
  margin-left: 30px;
`;

export const StyledMediaContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 348px;
  border-radius: 2px;
`;

export const StyledMediaText = styled('span')`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: ${({ theme: { palette } }) => palette.secondary.main};
`;

export const StyledTypography = styled(Typography)`
  color: ${({ theme: { palette } }) => palette.text.disabled};
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
`;

export const AddMediaButton = styled(Link)`
  padding: 0 3px;

  :hover {
    cursor: pointer;
  }
`;

export const StyledSideBarContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 758px;
  width: 100%;
  height: 100vh;
  background-color: ${({ theme: { palette } }) => palette.common.white};
  color: ${({ theme: { palette } }) => palette.text.secondary};
  box-shadow: 0 2px 10px rgba(46, 96, 170, 0.25);
  position: absolute;
  z-index: 1200;
  right: 0;
  top: 0;
`;

export const StyledMediaMenu = styled(FormControl)`
  .MuiList-root {
    border-radius: 4px;
    border: 1px solid ${({ theme: { palette } }) => palette.secondary.main};
    background: ${({ theme: { palette } }) => palette.common.white};
    padding: 5px;
    z-index: 2;
  }

  .MuiListItem-root {
    border-radius: 5px;
    font-size: 15px;
    line-height: 18px;
    height: 38px;

    :hover {
      background: ${({ theme: { palette } }) => palette.secondary.dark};
      color: ${({ theme: { palette } }) => palette.common.white};
    }
  }
`;

export const Container = styled(Box)`
  position: relative;
  background-color: ${({ theme: { palette } }) => palette.common.white};
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 159px;
  min-width: 159px;
  border: 1px solid ${({ theme: { palette } }) => palette.action.disabled};
  border-radius: 2px;

  :hover div:first-of-type {
    transition: 0.2s;
    visibility: visible;
  }

  div img,
  video {
    object-fit: contain;
    width: 100%;
    max-height: 157px;
  }
`;

export const MediaContainer = styled(Box)`
  display: flex;
  width: 100%;
  z-index: 2;
  height: 100%;
  max-height: 157px;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;

  span {
    position: absolute;
  }
`;

export const LoaderContainer = styled(Box)`
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: space-evenly;
`;

export const StyledFileImg = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 105px;
  height: 91px;
`;

export const StyledFileName = styled(Typography)`
  padding-top: 13px;
  text-decoration-line: underline;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  max-width: 119px;
  max-height: 63px;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  webkit-box-orient: vertical;
  line-clamp: 2;
  box-orient: vertical;
`;

export const StyledFileContent = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const StyledUploadingContainer = styled(Box)`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const StyledCheckboxContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 12px;
  margin-left: 12px;
  position: absolute;
  z-index: 1;
  width: 24px;
  height: 24px;
  justify-content: center;

  span,
  button {
    width: 14px;
  }
`;
