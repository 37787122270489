import { Box } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';

export const NoSharedCataloguesWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 62px;
`;

export const StyledKeywordDeleteIcon = styled('img')`
  height: 9px;
  width: 9px;
  margin-right: 9px;
`;

export const ActiveKeywordsWrapper = styled(Box)`
  max-height: 64px;
  overflow: auto;
  margin-left: 8px;
  margin-bottom: 21px;
  margin-right: 56px;
  column-gap: 15px;
  row-gap: 10px;
  display: flex;
  flex-wrap: wrap;
`;
