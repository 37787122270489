import { Box, BoxProps, Link, styled, Typography } from '@mui/material';

interface IntegrationInfoHeaderProps extends BoxProps {
  hover: boolean;
}

interface AddMappingButtonProps extends BoxProps {
  disabled?: boolean;
}

export const NoIntegrationsContainer = styled(Box)(({ theme }) => ({
  marginTop: '112px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  '& .MuiTypography-root': {
    marginTop: '33px',
    textAlign: 'center',
    color: theme.palette.text.secondary,

    '& .MuiLink-root': {
      cursor: 'pointer',
    },
  },
}));

export const IntegrationInfoWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '50px',
  whiteSpace: 'nowrap',

  '&:last-of-type': {
    marginBottom: '25px',
  },
}));

export const IntegrationInfoHeader = styled(Box)<IntegrationInfoHeaderProps>(({ theme, hover }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  '& .MuiBox-root#integration-name': {
    display: 'flex',
    alignItems: 'center',
    minWidth: 0,
    maxWidth: '100%',

    '&:hover': {
      cursor: hover && 'pointer',
    },
  },

  '& .MuiButtonBase-root#save-button': {
    padding: 0,
    color: theme.palette.secondary.dark,
    marginRight: '10px',

    '&.Mui-disabled': {
      color: theme.palette.action.disabled,
    },

    '& .MuiSvgIcon-root': {
      height: '20px',
      width: '20px',
    },
  },

  '& .MuiCircularProgress-root': {
    marginLeft: '15px',
    marginRight: '10px',
  },
}));

export const AddMappingButton = styled(Box)<AddMappingButtonProps>(({ theme, disabled }) => ({
  display: 'flex',
  alignItems: 'center',
  color: disabled ? theme.palette.action.disabled : theme.palette.secondary.dark,
  background: 'none',
  border: 'none',

  '& .MuiCircularProgress-root': {
    color: theme.palette.action.disabled,
  },

  '& .MuiTypography-root': {
    color: 'inherit',
    marginLeft: '5px',
    fontWeight: 400,
  },

  '& .MuiSvgIcon-root': {
    height: '20px',
    width: '20px',
  },

  '&:hover': {
    cursor: 'pointer',

    '& .MuiTypography-root': {
      textDecoration: disabled ? 'none' : 'underline',
    },
  },
}));

export const MappingErrorLink = styled(Link)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontWeight: 400,

  '&:hover': {
    cursor: 'pointer',
  },
}));

export const LastSync = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',

  '& .MuiTypography-root': {
    fontWeight: 400,
    marginRight: '15px',
  },

  '& .MuiButtonBase-root': {
    color: theme.palette.primary.main,
    padding: 0,
  },
}));

export const IntegrationName = styled(Typography)(() => ({
  margin: '0px 5px',
  fontWeight: 500,
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  flex: 1,
}));
