import React, { memo } from 'react';
import { useHistory } from 'react-router-dom';

import useOnboarding from 'src/providers/OnboardingProvider/hooks/useOnboarding';

import BreadCrumbBody from './BreadCrumbBody';
import { StyledBreadcrumbBox, StyledBreadcrumbLink } from './styled';

export interface MainBreadCrumbsProps {
  last?: boolean;
}

interface ParentBreadCrumbProps extends MainBreadCrumbsProps {
  link?: string;
  text: string;
  iconName: string;
}

const BreadCrumb = ({ iconName, text, last, link }: ParentBreadCrumbProps) => {
  const history = useHistory();
  const {
    onboardingState: { tourActive },
    goNext,
  } = useOnboarding();

  const handleClick = () => {
    if (tourActive) {
      setTimeout(() => {
        goNext();

        window.history.pushState({}, '', history.location.pathname);
      }, 150);
    }
  };

  return last || !link ? (
    <StyledBreadcrumbBox>
      <BreadCrumbBody iconName={iconName} text={text} />
    </StyledBreadcrumbBox>
  ) : (
    <StyledBreadcrumbLink to={link} onClick={handleClick}>
      <BreadCrumbBody iconName={iconName} text={text} />
    </StyledBreadcrumbLink>
  );
};

export default memo(BreadCrumb);
