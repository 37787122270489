import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Box, Typography } from '@material-ui/core';

import Button from 'src/components/Button';
import WrapperTextInput from 'src/components/Forms/FormInputs/WrapperTextInput';
import Loader from 'src/components/Loader';
import useOnboarding from 'src/providers/OnboardingProvider/hooks/useOnboarding';

import { useEnterClick } from '../../../utils/enterEffectFactory';
import { STEPS } from '../types';

import CreateCatalogFirstStepBackground from './CreateCatalogFirstStepBackground';
import { Container, Main, CatalogCreationSideContainer } from './styled';

export interface FirstStepProps {
  handleNextStep: (nextStep: number) => void;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  name: string;
  error: JSX.Element | string | null;
  isButtonDisabled: boolean;
  loadingForEnterActionResult: boolean;
  isEnterActionBlocked?: boolean;
}

const FirstStep = ({
  handleNextStep,
  handleChange,
  name,
  error,
  isButtonDisabled,
  loadingForEnterActionResult,
  isEnterActionBlocked = false,
}: FirstStepProps) => {
  const [isImageLoading, setIsImageLoading] = useState(true);
  const [isEnterActionCalled, setIsEnterActionCalled] = useState<boolean>(false);
  const { t } = useTranslation();
  const history = useHistory();
  const {
    setOnboardingState,
    onboardingState: { tourActive },
  } = useOnboarding();

  useEnterClick(() => {
    setIsEnterActionCalled(true);
  });

  useEffect(() => {
    !loadingForEnterActionResult &&
      !isEnterActionBlocked &&
      isEnterActionCalled &&
      name &&
      !error &&
      handleNextStep(STEPS.secondStep);
    !loadingForEnterActionResult && !isEnterActionBlocked && isEnterActionCalled && setIsEnterActionCalled(false);
  }, [loadingForEnterActionResult, isEnterActionBlocked, isEnterActionCalled, name, error, handleNextStep]);

  useEffect(() => {
    if (tourActive) {
      setOnboardingState((prevState) => ({
        ...prevState,
        stepIndex: prevState.stepIndex + 1,
      }));

      window.history.pushState({}, '', history.location.pathname);
    }
  }, [history.location.pathname, setOnboardingState, tourActive]);

  return (
    <Container>
      <Box width="100%">
        <Typography variant="body1" color="text.disabled" mt="20px">
          {t('catalogueCreationPage.firstStep.step')}
        </Typography>

        <Box maxWidth="288px" id="set-name-block-anchor" mt="40px">
          <Main>
            <Typography variant="subtitle1" mb="15px">
              {t('catalogueCreationPage.firstStep.giveName')}
            </Typography>

            <Box width="288px" mb="40px">
              <WrapperTextInput
                shouldFocus
                label={t('catalogueCreationPage.firstStep.input')}
                value={name}
                error={!!error}
                name="name"
                onChange={handleChange}
                helperTextError={error as string}
                inputNameTestId="name"
              />
            </Box>
          </Main>

          <Button
            disabled={isButtonDisabled || loadingForEnterActionResult}
            onClick={() => handleNextStep(STEPS.secondStep)}
            variant="contained"
            data-testid="next"
          >
            {loadingForEnterActionResult && <Loader size="button" />}
            {t('catalogueCreationPage.firstStep.button')}
          </Button>
        </Box>
      </Box>

      <CatalogCreationSideContainer ml="148px">
        {isImageLoading && <CreateCatalogFirstStepBackground />}
        <img
          src={`${process.env.PUBLIC_URL}/images/edit.webp`}
          alt="Edit"
          onLoad={() => setIsImageLoading(false)}
          width="365"
          height="262"
        />
      </CatalogCreationSideContainer>
    </Container>
  );
};

export default FirstStep;
