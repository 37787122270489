import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { Box, Typography } from '@material-ui/core';

import Popup from 'src/components/Popup';
import { capitalize } from 'src/helpers/capitalize';

enum PricingPlans {
  Basic = 'Basic',
  Otomatorling = 'Otomatorling',
  Otomator = 'Otomator',
  Superotomator = 'Superotomator',
}
interface UpgradePlanPopupProps {
  open: boolean;
  onClose: () => void;
  planInfo: { name: string; price: string; title: string };
  currentPlanName: PricingPlans;
}

const UpgradePlanPopup = ({ open, onClose, planInfo, currentPlanName }: UpgradePlanPopupProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  const isPaymentPlan = [PricingPlans.Otomator, PricingPlans.Otomatorling].includes(currentPlanName);

  const onMainButtonClick = () => {
    if (isPaymentPlan) {
      history.push(`/auth/payment-plan/${planInfo.name.toLowerCase()}/upgrade`);
    } else {
      history.push(`/auth/payment-plan/${planInfo.name.toLowerCase()}`);
    }
  };

  return (
    <Popup
      open={open}
      mainTitle={t('upgradePlanPopup.title')}
      mainButtonText={t('upgradePlanPopup.nextButton')}
      onMainButtonClick={onMainButtonClick}
      onClose={onClose}
    >
      <Box padding="0 20px">
        <Typography color="text.secondary" m={0} gutterBottom>
          {t('upgradePlanPopup.text', { planName: capitalize(planInfo.title), planPrice: planInfo.price })}
        </Typography>
      </Box>
    </Popup>
  );
};

export default UpgradePlanPopup;
