import { ApolloError } from '@apollo/client';

export function arrayToMap<T>(arr: T[], key: keyof T = 'id' as keyof T): Record<string | number, T> {
  return arr.reduce((acc, item) => {
    acc[item[key] as never] = item;
    return acc;
  }, {} as Record<string | number, T>);
}

export function arrayToMapByGetKeyFunction<T>(
  arr: T[],
  getKey: (value: T) => string | number | string[],
): Record<string | number, T> {
  return arr.reduce((acc, item) => {
    acc[getKey(item) as string | number] = item;
    return acc;
  }, {} as Record<string | number, T>);
}

export const getArrayFromMultiSelectValue = (multiSelectValue?: string): string[] => {
  let value;

  if (!multiSelectValue) {
    return [];
  }

  try {
    value = JSON.parse(multiSelectValue);
    return Array.isArray(value) ? value : [];
  } catch {
    return [];
  }
};

export const getMessageForSnackbarFromError = (error: ApolloError): string => {
  const { graphQLErrors, message: errorText } = error;
  return graphQLErrors && graphQLErrors.length ? graphQLErrors[0].message : errorText;
};

export function removeDuplicatedObjects<T>(array: T[], key: keyof T): T[] {
  const uniqueProperties: never[] = [];

  return array.filter((element) => {
    const property = element[key as never];
    const isDuplicate = uniqueProperties.includes(property);

    if (!isDuplicate) {
      uniqueProperties.push(property);

      return true;
    }

    return false;
  });
}
