import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { Box, Link, Typography } from '@material-ui/core';

import Button from 'src/components/Button';
import SmallCard from 'src/components/Card/SmallCard';
import Popup from 'src/components/Popup';
import { useEnterClick } from 'src/utils/enterEffectFactory';
import { CatalogSource } from 'src/utils/gql';

import { CATALOGS_PATH, FIRST_DASHBOARD_PATHNAME } from '../../../constants/routeSources';
import { STEPS } from '../types';

import { CARDS } from './Cards';
import CreateCatalogSecondStepBackground from './CreateCatalogSecondStepBackground';
import { StyledIcon, Container, Main, StyledForwardButton, CatalogCreationSideContainer } from './styled';

interface SecondStepProps {
  handleNextStep: (nextStep: number) => void;
  selectedCard: string;
  handleSelectedCard: (source: CatalogSource) => void;
  handleBack: (id: number) => void;
  onCreateCatalog: () => Promise<string | void>;
  setIsModalOpen: (isOpen: boolean) => void;
  isModalOpen: boolean;
  loading?: boolean;
}

const SecondStep = ({
  handleNextStep,
  selectedCard,
  handleSelectedCard,
  handleBack,
  onCreateCatalog,
  isModalOpen,
  setIsModalOpen,
  loading,
}: SecondStepProps) => {
  const [isImageLoading, setIsImageLoading] = useState(true);
  const { t } = useTranslation();
  const history = useHistory();

  const onSecondaryButtonClick = () => {
    onCreateCatalog().then(() => history.push(`/${FIRST_DASHBOARD_PATHNAME}/${CATALOGS_PATH}`));
  };

  const handleClosePopup = () => {
    setIsModalOpen(false);
  };

  useEnterClick(() => selectedCard && handleNextStep(STEPS.thirdStep));

  return (
    <Container>
      <Box>
        <Typography variant="body1" color="text.disabled" mt="55px">
          {t('catalogueCreationPage.secondStep.step')}
        </Typography>

        <Box id="select-source-block-anchor">
          <Typography variant="subtitle1" mt="40px" mb="25px">
            {t('catalogueCreationPage.secondStep.selectSource')}
          </Typography>

          <Main>
            {CARDS.map(({ id, title, icon, source, isDisabled, testId, viewBox }) => (
              <SmallCard
                key={id}
                handleSelectedCard={() => handleSelectedCard(source)}
                isSelected={source === selectedCard}
                isDisabled={isDisabled}
                testId={testId}
              >
                <StyledIcon viewBox={viewBox} opacity={isDisabled ? '0.5' : '1'}>
                  {icon()}
                </StyledIcon>
                {isDisabled ? (
                  <Typography lineHeight="14px" variant="body2" color="text.disabled" mr="15px">
                    {title}
                    <br />
                    <Typography lineHeight="normal" variant="caption" mr="15px">
                      {t('catalogueCreationPage.secondStep.comingSoon')}
                    </Typography>
                  </Typography>
                ) : (
                  <Typography
                    variant={source === selectedCard ? 'h3' : 'body2'}
                    color={source === selectedCard ? 'common.white' : 'text.secondary'}
                    mr="15px"
                  >
                    {title}
                  </Typography>
                )}
              </SmallCard>
            ))}
          </Main>
        </Box>

        <Box display="flex" alignItems="center" mt="40px">
          <Button onClick={() => handleBack(STEPS.secondStep)} variant="outlined" data-testid="back">
            {t('catalogueCreationPage.secondStep.backButton')}
          </Button>

          <StyledForwardButton
            id="select-source-next-btn-anchor"
            disabled={!selectedCard}
            onClick={() => handleNextStep(STEPS.thirdStep)}
            variant="contained"
            data-testid="next"
          >
            {t('catalogueCreationPage.secondStep.forwardButton')}
          </StyledForwardButton>
          {selectedCard === CatalogSource.Manually && (
            <Box ml="20px">
              <Link component="button" color="secondary" variant="subtitle2" onClick={() => setIsModalOpen(true)}>
                {t('catalogueCreationPage.secondStep.link')}
              </Link>
            </Box>
          )}
        </Box>
      </Box>

      <CatalogCreationSideContainer ml="188px">
        {isImageLoading && <CreateCatalogSecondStepBackground />}
        <img
          src={`${process.env.PUBLIC_URL}/images/checklist.webp`}
          alt="Checklist"
          onLoad={() => setIsImageLoading(false)}
          width="320"
          height="278"
        />
      </CatalogCreationSideContainer>

      <Popup
        open={isModalOpen}
        mainTitle={t('catalogueCreationPage.secondStep.modal.title')}
        mainButtonText={t('catalogueCreationPage.secondStep.modal.mainButton')}
        onMainButtonClick={handleClosePopup}
        onSecondaryButtonClick={onSecondaryButtonClick}
        secondaryButtonText={t('catalogueCreationPage.secondStep.modal.secondaryButton')}
        onClose={handleClosePopup}
        loadingOnSecondaryButton={loading}
        maxWidth="sm"
        loadingOnMainButton={false}
      >
        <Box>
          <Typography color="text.secondary" fontSize="18px" lineHeight="21px">
            {t('catalogueCreationPage.secondStep.modal.text')}
          </Typography>
        </Box>
      </Popup>
    </Container>
  );
};

export default SecondStep;
