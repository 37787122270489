import React from 'react';

import { Box } from '@material-ui/core';

import { ProductItemValue, ProductTypeFieldIntegrationMapping, IntegrationTypes, MediaUsage } from 'src/utils/gql';

import { CompletenessChip } from './Chip';

export interface CompletenessIntegration {
  id?: string;
  type: IntegrationTypes;
}

interface ChipCompletenessProps {
  integrationsMap: { [key: string]: CompletenessIntegration };
  fieldsMapping: ProductTypeFieldIntegrationMapping[];
  fieldsValues: ProductItemValue[];
  isDataLoading: boolean;
  mediaUsages?: MediaUsage[];
}

export const ChipsCompleteness = ({
  integrationsMap,
  fieldsMapping,
  fieldsValues,
  isDataLoading,
  mediaUsages,
}: ChipCompletenessProps) => {
  return (
    <Box position="absolute" display="flex" flexDirection="column" padding="2px" top="10px" right="10px">
      {Object.values(IntegrationTypes).map(
        (integration, idx) =>
          integration !== IntegrationTypes.Csv && (
            <CompletenessChip
              integration={integrationsMap[integration] || { type: integration }}
              fieldsMapping={fieldsMapping}
              fieldsValues={fieldsValues}
              key={idx}
              isDataLoading={isDataLoading}
              mediaUsages={mediaUsages}
            />
          ),
      )}
    </Box>
  );
};
