import { ApolloCache } from '@apollo/client';

import { Mutation, ProductItemMapping } from 'src/utils/gql';

export type CreateItemMappingCacheType =
  | ApolloCache<Pick<Mutation, 'createItemMappingFromIntegration'>>
  | ApolloCache<Pick<Mutation, 'createItemMappingByExternalId'>>;

export const modifyCacheOnCreateItemMapping = (
  cache: CreateItemMappingCacheType,
  createdMapping: ProductItemMapping,
) => {
  cache.modify({
    fields: {
      productItemIntegrationMappings(existingMappsRef = [], { readField, toReference }) {
        const newItemMappingRef = toReference(createdMapping);

        if (existingMappsRef.some((ref: { __ref: string }) => readField('id', ref) === createdMapping.id)) {
          return existingMappsRef;
        }

        return [...existingMappsRef, newItemMappingRef];
      },
    },
  });
};

export const modifyCacheOnDetachItemMapping = (
  cache: ApolloCache<Pick<Mutation, 'deleteProductItemIntegrationMapping'>>,
  detachedMappingId: string,
) => {
  cache.modify({
    fields: {
      productItemIntegrationMappings(existingMappsRef = [], { readField }) {
        return existingMappsRef.filter((ref: { __ref: string }) => readField('id', ref) !== detachedMappingId);
      },
    },
  });
};
