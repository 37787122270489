import { Backdrop, TextField } from '@material-ui/core';
import { alpha, styled } from '@mui/material';
import { Appearance } from '@stripe/stripe-js';

import { theme } from 'src/theme';

export const StyledCreditCardForm = styled('form')({
  width: '376px',
  paddingTop: '5px',
  height: '196px',
});

export const StyledPaymentForm = styled('form')({
  width: '100%',
});

export const CardholderPaymentFormField = styled(TextField)({
  marginBottom: '14px',

  '& .MuiFormHelperText-root': {
    position: 'relative',
    bottom: 0,
  },
});

export const SuccessPaymentBackdrop = styled(Backdrop)(({ theme }) => ({
  color: theme.palette.common.white,
  zIndex: theme.zIndex.drawer + 1,
  background: alpha(theme.palette.primary.light, 0.8),
}));

export const appearance = {
  theme: 'stripe',

  variables: {
    colorDanger: theme.palette.error.main,
    colorDangerText: theme.palette.error.main,
    borderRadius: '2px',
  },

  rules: {
    '.Label': {
      fontWeight: '400',
      fontSize: '15px',
      lineHeight: '16px',
      color: theme.palette.text.disabled,
    },

    '.Label--invalid': {
      color: theme.palette.error.main,
    },

    '.Input': {
      padding: '12px 20px',
      fontWeight: '400',
      fontSize: '15px',
      lineHeight: '16px',
      color: theme.palette.text.primary,
      border: '1px solid',
      borderColor: theme.palette.text.disabled,
    },

    '.Input::placeholder': {
      color: theme.palette.text.disabled,
      fontWeight: '400',
      fontSize: '15px',
      lineHeight: '16px',
    },

    '.Input--invalid': {
      boxShadow: 'none',
      border: `1px solid ${theme.palette.error.main}`,
    },

    '.Input:hover': {
      border: `1px solid ${theme.palette.secondary.main}`,
    },

    '.Input:focus': {
      boxShadow: 'none',
      border: `1px solid ${theme.palette.secondary.main}`,
    },

    '.Error': {
      marginLeft: '20px',
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '14px',
    },
  },
} as Appearance;

export const disabledAppearance = {
  theme: 'stripe',

  variables: {
    borderRadius: '2px',
    colorBackground: theme.palette.background.default,
  },

  rules: {
    '.Label': {
      fontWeight: '400',
      fontSize: '15px',
      lineHeight: '16px',
      color: theme.palette.text.disabled,
    },

    '.Input': {
      padding: '12px 20px',
      fontWeight: '400',
      fontSize: '15px',
      lineHeight: '16px',
      color: theme.palette.text.disabled,
      backgroundColor: theme.palette.background.default,
      borderColor: theme.palette.text.disabled,
      border: '1px solid',
    },

    '.Input:hover': {
      boxShadow: 'none',
      color: theme.palette.text.disabled,
      backgroundColor: theme.palette.background.default,
      border: `1px solid ${theme.palette.text.disabled}`,
    },

    '.Input:focus': {
      color: theme.palette.text.disabled,
      boxShadow: 'none',
      backgroundColor: theme.palette.background.default,
      border: `1px solid ${theme.palette.text.disabled}`,
    },
  },
} as Appearance;
