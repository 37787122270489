import React, { ChangeEvent, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useApolloClient } from '@apollo/client';
import { Box, InputAdornment, makeStyles, Typography } from '@material-ui/core';

import { FormField } from 'src/components/Forms/styled';
import Hint, { HintType } from 'src/components/Hint';
import Info from 'src/components/Icon/info.svg';
import MultiTypeInput from 'src/components/MultiTypeInput';
import Select from 'src/components/Select';
import SideBarDrawer from 'src/components/SideBar/RightSidebarDrawer';
import LabeledToggle from 'src/components/Toggle/LabeledToggle';
import { MAX_SHOW_IN_PREVIEW_FIELDS } from 'src/constants';
import { capitalize } from 'src/helpers/capitalize';
import { validateTextFieldValue } from 'src/helpers/validationCheck';
import { clearCacheFactory } from 'src/utils/cacheUtils';
import { FieldType } from 'src/utils/gql';

import {
  FieldSettingsInputContainer,
  FieldSettingsDisplayOptionsContainer,
  DisplayOptionsToggleContainer,
} from './styled';
import { FieldSettingsFormData, ProductTypeSettingsField } from './types';

const useStyles = makeStyles(() => ({
  root: {
    '& input.MuiInputBase-input': {
      padding: '12px 20px',
    },
  },
  defaultValueFieldHint: {
    marginRight: '11px',
    marginTop: '6px',
  },
  defaultValueField: {
    marginTop: '22px',
    '& input.MuiInputBase-input': {
      paddingRight: '10px',
    },
  },
  form: {
    height: '100%',
  },
}));

interface ProductTypeFieldSettingsSidebarProps {
  openSidebar: boolean;
  handleCloseSidebar: () => void;
  handleAddCustomField: (customFieldData: ProductTypeSettingsField) => void;
  activeFieldData: ProductTypeSettingsField | null;
  resetActiveFieldData: () => void;
  handleEditCustomField: (field: ProductTypeSettingsField, index: number) => void;
  handleChangeDefaultFieldShowInPreviewFlag: (showInPreview: boolean, index: number) => void;
  activeFieldId: number | null;
  productTypeId?: string;
  handleCheckDuplicates: (currentName: string, indexNumber: number | null) => boolean;
  showInPreviewFieldsCount?: number;
}

const ProductTypeFieldSettingsSidebar = ({
  handleCloseSidebar,
  handleAddCustomField,
  activeFieldData,
  resetActiveFieldData,
  handleEditCustomField,
  handleChangeDefaultFieldShowInPreviewFlag,
  activeFieldId,
  productTypeId,
  handleCheckDuplicates,
  openSidebar,
  showInPreviewFieldsCount = 0,
}: ProductTypeFieldSettingsSidebarProps) => {
  const { cache } = useApolloClient();
  const { t } = useTranslation();
  const classes = useStyles();
  const [duplicatesErrorMessage, setDuplicatesErrorMessage] = useState<string>('');
  const [formIsClickedAway, setFormIsClickedAway] = useState<boolean>(false);

  const PRODUCT_FIELD_TYPE_SELECT_OPTIONS = [
    { label: t('fieldSettings.fieldTypeOptions.string'), value: FieldType.String },
    { label: t('fieldSettings.fieldTypeOptions.number'), value: FieldType.Number },
    { label: t('fieldSettings.fieldTypeOptions.price'), value: FieldType.Price },
    { label: t('fieldSettings.fieldTypeOptions.boolean'), value: FieldType.Boolean },
    {
      label: t('fieldSettings.fieldTypeOptions.multiselect'),
      value: FieldType.Multiselect,
    },
    {
      label: t('fieldSettings.fieldTypeOptions.singleselect'),
      value: FieldType.Singleselect,
    },
  ];

  const formMethods = useForm<FieldSettingsFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      fieldName: activeFieldData?.name || '',
      required: activeFieldData?.required || false,
      showInFilters: activeFieldData?.showInFilters ?? true,
      showInPreview: activeFieldData?.showInPreview || false,
      type:
        (activeFieldData?.type && {
          value: activeFieldData.type,
          label:
            activeFieldData.type !== 'boolean'
              ? capitalize(t(`fieldSettings.fieldTypeOptions.${activeFieldData.type}`))
              : t(`fieldSettings.fieldTypeOptions.${activeFieldData.type}`),
        }) ||
        PRODUCT_FIELD_TYPE_SELECT_OPTIONS[0],
      fieldSettingsDefaultValue: (activeFieldData?.defaultValue as string) || '',
    },
  });

  const {
    formState: { isValid, errors, dirtyFields },
    handleSubmit,
    reset: resetFormState,
    resetField,
    watch,
    control,
    unregister,
  } = formMethods;

  const defaultValueHintText = t('fieldSettings.defaultFieldHint');
  const saveChangesHintText = t('fieldSettings.saveChangesBeforeClosing');
  const isFieldRequired = watch('required');
  const fieldType = watch('type').value.toLowerCase();
  const hasError = !!(errors.fieldName?.message?.length || duplicatesErrorMessage.length);
  const formIsValid = !isValid || hasError;

  const showInFilterDisabled =
    !activeFieldData?.showInPreview && showInPreviewFieldsCount >= MAX_SHOW_IN_PREVIEW_FIELDS;

  const handleSaveSettings = (formData: FieldSettingsFormData) => {
    const customFieldData = {
      ...(activeFieldData?.id && { id: activeFieldData.id }),
      name: formData.fieldName,
      required: formData.required,
      type: formData.type.value as FieldType,
      showInFilters: formData.showInFilters,
      showInPreview: formData.showInPreview,
      defaultValue: formData.fieldSettingsDefaultValue || null,
      invariable: activeFieldData?.invariable,
    };

    if (activeFieldId !== null) {
      activeFieldData?.systemName
        ? handleChangeDefaultFieldShowInPreviewFlag(formData.showInPreview, activeFieldId)
        : handleEditCustomField(customFieldData, activeFieldId);
    } else {
      handleAddCustomField(customFieldData);
    }

    if (productTypeId) {
      clearCacheFactory({
        fieldNames: ['getProductItemsByProductTypeId'],
      })(cache);
    }

    resetFormState();
    resetActiveFieldData();
    handleCloseSidebar();
  };

  const handleCloseSidebarOnClickAway = () => {
    if (Object.values(dirtyFields).length) {
      setFormIsClickedAway(true);
    }

    if (hasError) {
      setFormIsClickedAway(true);
    }

    if (!Object.values(dirtyFields).length && !hasError) {
      handleCloseSidebar();
    }
  };

  const handleDuplicatesError = (currentValue: string) => {
    if (activeFieldData?.name.toLowerCase() !== currentValue.toLowerCase()) {
      handleCheckDuplicates(currentValue, activeFieldId)
        ? setDuplicatesErrorMessage(t('fieldSettings.nameIsTakenError'))
        : setDuplicatesErrorMessage('');
    }
  };

  const formId = 'productTypeSettingForm';

  const resetDefaultValueField = () => {
    const defaultValueFieldName = 'fieldSettingsDefaultValue';
    resetField(defaultValueFieldName);
    unregister(defaultValueFieldName);
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(handleSaveSettings)} className={classes.form} id={formId}>
        <SideBarDrawer
          dataTestId="fieldSettingsContainer"
          propsAdornment={
            <Controller
              name="required"
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <LabeledToggle
                  onChange={(e) => {
                    onChange(e.target.checked);
                    resetDefaultValueField();
                  }}
                  checked={value}
                  labelPlacement="start"
                  primary={t('productType.required')}
                  secondary={t('productType.required')}
                  disabled={!!activeFieldData?.systemName}
                  testId="isFieldRequiredToggle"
                  name={name}
                />
              )}
            />
          }
          narrowSideBar
          handleCloseSidebarOnClickAway={handleCloseSidebarOnClickAway}
          onPrimaryButtonClick={handleCloseSidebar}
          openSidebar={openSidebar}
          titleName={t('fieldSettings.title')}
          primaryButtonTitle={t('fieldSettings.cancelButton')}
          disabledSecondaryButton={formIsValid}
          secondaryButtonTitle={t('fieldSettings.save')}
          dataTestIdPrimaryButton="fieldSettingsCancelButton"
          dataTestIdSecondaryButton="fieldSettingsSaveButton"
          openHint={!formIsValid && dirtyFields && formIsClickedAway}
          titleHint={saveChangesHintText}
          error={formIsValid && formIsClickedAway}
          errorMessage={t('fieldSettings.validationError')}
          form={formId}
        >
          <Box pr="56px" pl="30px" display="flex" height="100%" justifyContent="space-between" flexDirection="column">
            <Box>
              <Typography variant="h3" color="primary" mb="32px">
                {t('fieldSettings.generalLabel')}
              </Typography>

              <Box mb="40px">
                <FieldSettingsInputContainer>
                  <Controller
                    name="fieldName"
                    control={control}
                    rules={{
                      required: t('fieldSettings.fieldIsRequired') as string,
                      maxLength: { value: 255, message: t('exceededLimitValue') },
                    }}
                    render={({ field: { onChange, value }, fieldState: { error, invalid } }) => (
                      <FormField
                        fullWidth
                        error={invalid || !!duplicatesErrorMessage.length}
                        helperText={error?.message || duplicatesErrorMessage}
                        variant="outlined"
                        inputProps={{
                          'data-testid': 'productTypeName',
                          'aria-label': 'fieldName',
                        }}
                        label={t('productType.nameOfField')}
                        onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                          const newValue = validateTextFieldValue(value);
                          onChange(newValue);
                          handleDuplicatesError(newValue);
                          setFormIsClickedAway(false);
                        }}
                        value={value}
                        disabled={!!activeFieldData?.systemName}
                        className={classes.root}
                        autoFocus
                      />
                    )}
                  />
                </FieldSettingsInputContainer>

                <FieldSettingsInputContainer>
                  <Controller
                    name="type"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        label={t('productType.valueType')}
                        selectedOptionItem={value}
                        options={PRODUCT_FIELD_TYPE_SELECT_OPTIONS}
                        onChangeSelect={(option) => {
                          onChange(option);
                          resetDefaultValueField();
                        }}
                        disabled={Boolean(activeFieldData?.invariable || activeFieldData?.systemName)}
                      />
                    )}
                  />
                </FieldSettingsInputContainer>

                {isFieldRequired && (
                  <FieldSettingsInputContainer>
                    <MultiTypeInput
                      label={t('productType.defaultValueField')}
                      type={fieldType as FieldType}
                      emptyErrorMessage={t('fieldSettings.defaultValueErrorMessage')}
                      productTypeId={productTypeId}
                      productTypeFieldId={activeFieldData?.id}
                      isDefaultFieldValue
                      shouldBeFilled={isFieldRequired}
                      inputIdentifier="fieldSettingsDefaultValue"
                      inputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Hint
                              placement="bottom"
                              isDefaultFieldValueHint
                              type="hover"
                              title={defaultValueHintText}
                              data-testid="infoIconInRequiredField"
                            >
                              <img
                                className={classes.defaultValueFieldHint}
                                src={Info}
                                alt="info"
                                width="20px"
                                height="20px"
                                data-testid="infoIcon"
                              />
                            </Hint>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FieldSettingsInputContainer>
                )}
              </Box>

              <Typography variant="h3" color="primary" mb="25px">
                {t('fieldSettings.displayOptionsLabel')}
              </Typography>

              <FieldSettingsDisplayOptionsContainer>
                <DisplayOptionsToggleContainer>
                  <Controller
                    name="showInFilters"
                    control={control}
                    render={({ field: { onChange, value, name } }) => (
                      <LabeledToggle
                        onChange={(e) => onChange(e.target.checked)}
                        checked={value}
                        labelPlacement="end"
                        primary={t('fieldSettings.showInFilters')}
                        secondary={t('fieldSettings.showInFilters')}
                        disabled={!!activeFieldData?.systemName}
                        name={name}
                        testId="showInFiltersToggle"
                      />
                    )}
                  />

                  <Hint
                    type={HintType.hover}
                    title={String(t('fieldSettings.showInPreviewLimit'))}
                    doNotShow={!showInFilterDisabled}
                  >
                    <Box>
                      <Controller
                        name="showInPreview"
                        control={control}
                        render={({ field: { onChange, value, name } }) => (
                          <LabeledToggle
                            onChange={(e) => onChange(e.target.checked)}
                            checked={value}
                            disabled={showInFilterDisabled}
                            labelPlacement="end"
                            primary={t('fieldSettings.showInPreview')}
                            secondary={t('fieldSettings.showInPreview')}
                            name={name}
                            testId="showInPreviewToggle"
                          />
                        )}
                      />
                    </Box>
                  </Hint>
                </DisplayOptionsToggleContainer>
              </FieldSettingsDisplayOptionsContainer>
            </Box>
          </Box>
        </SideBarDrawer>
      </form>
    </FormProvider>
  );
};

export default ProductTypeFieldSettingsSidebar;
