import React, { HTMLAttributes } from 'react';

import { Box } from '@material-ui/core';

import NavigationBar from 'src/components/NavBar';

import { SUBSCRIPTION_EXPIRED_BANNER_HEIGHT } from '../../constants';

import LayoutContext from './LayoutContext';
import { LayoutContainer } from './styled';

export interface MainLayoutProps extends HTMLAttributes<HTMLDivElement> {
  showBanner: boolean;
}

const MainLayout = ({ children, showBanner }: MainLayoutProps) => {
  const fullscreenComponentsHeight = showBanner
    ? `calc(100vh - ${+showBanner && SUBSCRIPTION_EXPIRED_BANNER_HEIGHT}px)`
    : '100vh';

  return (
    <LayoutContext.Provider value={{ fullscreenComponentsHeight }}>
      <Box display="flex" flexDirection="column">
        <LayoutContainer height={fullscreenComponentsHeight} display="flex">
          <NavigationBar />

          {children}
        </LayoutContainer>
      </Box>
    </LayoutContext.Provider>
  );
};

export default MainLayout;
