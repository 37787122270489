import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Chip,
  ClickAwayListener,
  Fade,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  MenuList,
  OutlinedInput,
} from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { useTheme } from '@mui/material';

import { BULK_SELECT_ACTIONS } from '../constants';

import Iconography from './Iconography';

const StyledBulkSelect = styled(FormControl)`
  position: relative;
  height: 42px;

  .MuiOutlinedInput-root {
    fieldset {
      border-color: ${({ theme: { palette } }) => palette.secondary.main};
    }

    :hover {
      cursor: default;
    }

    input {
      :hover {
        cursor: default;
      }
    }
  }

  .MuiInputLabel-root[data-shrink='true'] {
    color: ${({ theme: { palette } }) => palette.secondary.main};
  }

  .MuiList-root {
    border-radius: 2px;
    border: 1px solid ${({ theme: { palette } }) => palette.secondary.main};
    z-index: 5;
    background: ${({ theme: { palette } }) => palette.common.white};
    padding: 5px;
  }

  .MuiListItem-root {
    border-radius: 5px;
    font-size: 15px;
    line-height: 18px;
    height: 38px;

    :hover {
      background: ${({ theme: { palette } }) => palette.secondary.dark};
      color: ${({ theme: { palette } }) => palette.common.white};
    }
  }

  .MuiChip-root {
    height: 24px;
    width: 149px;
    font-size: 12px;
    border-radius: 5px;
    background: ${({ theme: { palette } }) => palette.secondary.dark};
    position: absolute;
    top: 9px;
    left: 10px;

    span {
      min-width: 100px;
      padding: 0;
      margin: 0 15px 0 8px;
    }

    svg {
      color: ${({ theme: { palette } }) => palette.primary.main};
      opacity: 0.5;
      margin: 0 5px 0 0;
      width: 16px;
      height: 16px;

      :hover {
        color: ${({ theme: { palette } }) => palette.primary.main};
        opacity: 1;
      }
    }
  }
`;

interface BulkSelectProps {
  setIsAllItemsSelectedSynthetic: (value: boolean) => void;
  isAllItemsSelected: boolean;
  isSharedCatalog: boolean;
  setSelectedItems: (items: string[]) => void;
  onClickBulkSelectMenuItems: (action: number) => void;
  onCloseBulkSelect: () => void;
  onClickBulkSelectIcon: () => void;
  isBulkSelectOpen: boolean;
  menuOptions: MenuOptions[];
  selectedItemsIds: string[];
}

export interface MenuOptions {
  action: number;
  label: string;
}

const BulkSelect = ({
  setIsAllItemsSelectedSynthetic,
  isSharedCatalog,
  isAllItemsSelected,
  setSelectedItems,
  onClickBulkSelectMenuItems,
  onCloseBulkSelect,
  onClickBulkSelectIcon,
  isBulkSelectOpen,
  menuOptions,
  selectedItemsIds,
}: BulkSelectProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const isActionDisabled = (action: BULK_SELECT_ACTIONS) =>
    BULK_SELECT_ACTIONS.clone === action && selectedItemsIds.length > 1;

  const disableMenuItemStyles = (action: BULK_SELECT_ACTIONS) =>
    isActionDisabled(action)
      ? {
          color: theme.palette.text.disabled,
          background: theme.palette.common.white,
        }
      : undefined;

  const handleDeleteChip = () => {
    setSelectedItems([]);
    setIsAllItemsSelectedSynthetic(false);
  };

  //MOTHBALLED TO MVP

  // const isActionAvailableForSharedCatalogs = (actionToCheck: number) =>
  //   BULK_SELECT_ACTIONS_FOR_SHARED_CATALOGS.includes(actionToCheck);

  return (
    <ClickAwayListener onClickAway={onCloseBulkSelect}>
      <StyledBulkSelect variant="outlined">
        <InputLabel color="secondary">{t('productItemsPage.bulkSelect.actions')}</InputLabel>
        <OutlinedInput
          value={t('productItemsPage.bulkSelect.actions')}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                onClick={onClickBulkSelectIcon}
                style={{ transform: isBulkSelectOpen ? 'rotate(180deg)' : 'rotate(0deg)' }}
                data-testid="actionButton"
              >
                <Iconography iconName="expand-chevron" htmlColor="secondary" />
              </IconButton>
            </InputAdornment>
          }
          label={t('productItemsPage.bulkSelect.actions')}
        />

        <Fade in={isBulkSelectOpen}>
          <MenuList>
            {menuOptions.map(
              ({ action, label }) =>
                //MOTHBALLED TO MVP

                // (!isSharedCatalog || isActionAvailableForSharedCatalogs(action))
                !isSharedCatalog && (
                  <MenuItem
                    style={disableMenuItemStyles(action)}
                    key={action}
                    onClick={() => !isActionDisabled(action) && onClickBulkSelectMenuItems(action)}
                    data-testid={`${label}Option`}
                  >
                    {t(label)}
                  </MenuItem>
                ),
            )}
          </MenuList>
        </Fade>

        <Chip
          label={
            isAllItemsSelected
              ? t('productItemsPage.bulkSelect.allItemsSelected')
              : t('productItemsPage.bulkSelect.selectedItemsWithCount', { count: selectedItemsIds.length })
          }
          onDelete={handleDeleteChip}
          deleteIcon={<Iconography iconName="cancel" fontSize="small" data-testid="closeBulkEditButton" />}
        />
      </StyledBulkSelect>
    </ClickAwayListener>
  );
};

export default BulkSelect;
