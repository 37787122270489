import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { makeStyles, Theme, Typography } from '@material-ui/core';
import { Box, Link } from '@mui/material';

import Button from 'src/components/Button';
import IntegrationCard from 'src/components/Card/IntegrationCard';
import { StyledUpgradeLink } from 'src/components/Headers/styled';
import Hint from 'src/components/Hint';
import Loader from 'src/components/Loader';
import { Integration, IntegrationTypes } from 'src/utils/gql';

import Popup from '../../Popup';

// TODO: scrollbar will be changed later
const useStyles = makeStyles((theme: Theme) => ({
  integrationCardsWrapper: {
    display: 'flex',
    justifyContent: 'center',
    maxHeight: '350px',
    width: '353px',
    padding: '12px 0 0',
    overflowY: 'auto',
    scrollbarWidth: 'thin',

    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '5px',
      border: `1px solid ${theme.palette.action.selected}`,
      background: theme.palette.secondary.main,
      boxShadow: 'none',
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '5px',
      border: 'none',
      background: theme.palette.common.white,
      boxShadow: 'inset 0 0  15px rgba(46, 96, 170, 0.15)',
    },
  },
}));

interface CatalogSettingsIntegrationPopupProps {
  open: boolean;
  onClose: () => void;
  onAttachIntegration: (integrationId: string) => void;
  onOpenIntegrationsListPopup: () => void;
  integrations?: Integration[];
  loading: boolean;
  loadingAddItegration: boolean;
  isLimit?: boolean;
}

const CatalogSettingsIntegrationPopup = ({
  open,
  onClose,
  onAttachIntegration,
  onOpenIntegrationsListPopup,
  integrations = [],
  loading = true,
  loadingAddItegration = false,
  isLimit,
}: CatalogSettingsIntegrationPopupProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();

  const [selectedIntegrationId, setSelectedIntegrationId] = useState<string>('');

  const handleClose = () => {
    setSelectedIntegrationId('');
    onClose();
  };

  const handleAddIntegration = async () => {
    await onAttachIntegration(selectedIntegrationId);
    setSelectedIntegrationId('');
  };

  const handleLinkClick = () => {
    history.push('/upgrade-pricing-plan');
  };

  const isLoading = loading || isLimit === undefined;
  const integrationsCount = integrations.length;

  return (
    <Popup
      hideOverflowY
      open={open}
      mainTitle={t('settingsPage.attachedIntegrations.popup.title')}
      onClose={handleClose}
    >
      <Box>
        <Typography mb="13px">{t('settingsPage.attachedIntegrations.popup.addFromSettings')}</Typography>

        {isLoading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="330px">
            <Loader />
          </Box>
        ) : (
          <>
            <Box height="257px" display="flex" justifyContent="center">
              <Box className={classes.integrationCardsWrapper}>
                <Box px="15px" width="100%">
                  <IntegrationCard
                    type={IntegrationTypes.Shopify}
                    selectedIntegrationId={selectedIntegrationId}
                    onSelectIntegration={setSelectedIntegrationId}
                    integrations={integrations.filter(({ type }) => type === IntegrationTypes.Shopify)}
                  />

                  <Box pb="12px">
                    <IntegrationCard
                      type={IntegrationTypes.Woocommerce}
                      selectedIntegrationId={selectedIntegrationId}
                      onSelectIntegration={setSelectedIntegrationId}
                      integrations={integrations.filter(({ type }) => type === IntegrationTypes.Woocommerce)}
                    />
                  </Box>

                  <Box pb="12px">
                    <IntegrationCard
                      type={IntegrationTypes.Ebay}
                      selectedIntegrationId={selectedIntegrationId}
                      onSelectIntegration={setSelectedIntegrationId}
                      integrations={integrations.filter(({ type }) => type === IntegrationTypes.Ebay)}
                    />
                  </Box>

                  <Box pb="12px">
                    <IntegrationCard
                      type={IntegrationTypes.Facebook}
                      selectedIntegrationId={selectedIntegrationId}
                      onSelectIntegration={setSelectedIntegrationId}
                      integrations={integrations.filter(({ type }) => type === IntegrationTypes.Facebook)}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box display="flex" justifyContent="center" alignItems="center" mb="40px" mt="13px">
              {isLimit ? (
                <Hint
                  isDefaultFieldValueHint
                  placement="top"
                  type="hover"
                  title={
                    <Typography variant="caption" color="text.primary">
                      {t('settingsPage.attachedIntegrations.popup.limitReached', { count: integrationsCount })}
                      <StyledUpgradeLink onClick={handleLinkClick} color="text.primary">
                        {t('settingsPage.attachedIntegrations.popup.upgradePlan')}
                      </StyledUpgradeLink>
                      .
                    </Typography>
                  }
                >
                  <Typography variant="body1" color="text.disabled" data-testid="createNewIntegrationDisabled">
                    {`${t('settingsPage.attachedIntegrations.popup.or')} ${t(
                      'settingsPage.attachedIntegrations.popup.createNewIntegration',
                    )}`}
                  </Typography>
                </Hint>
              ) : (
                <>
                  <Typography variant="body1" mr="5px">
                    {t('settingsPage.attachedIntegrations.popup.or')}
                  </Typography>

                  <Link
                    component="button"
                    onClick={onOpenIntegrationsListPopup}
                    variant="body1"
                    underline="hover"
                    color="secondary"
                    data-testid="createNewIntegration"
                  >
                    {t('settingsPage.attachedIntegrations.popup.createNewIntegration')}
                  </Link>
                </>
              )}
            </Box>
          </>
        )}

        <Box position="absolute" left="0" right="0">
          <Button
            variant="contained"
            loading={loadingAddItegration}
            onClick={handleAddIntegration}
            disabled={!selectedIntegrationId}
            data-testid="saveButtonPopup"
          >
            {t('settingsPage.attachedIntegrations.popup.saveButton')}
          </Button>
        </Box>
      </Box>
    </Popup>
  );
};

export default CatalogSettingsIntegrationPopup;
