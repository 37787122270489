export const getFacebookAuthUrl = ({
  reAuth,
  state,
}: {
  reAuth?: boolean;
  state?: Record<string, unknown>;
} = {}): string => {
  const url = new URL(process.env.REACT_APP_FACEBOOK_AUTH_URL);

  url.searchParams.append('client_id', process.env.REACT_APP_FACEBOOK_APP_ID);
  url.searchParams.append('redirect_uri', `https://${window.location.hostname}/facebook/auth/callback`);
  url.searchParams.append('scope', process.env.REACT_APP_FACEBOOK_SCOPE);
  url.searchParams.append('response_type', 'token');

  if (reAuth) {
    url.searchParams.append('auth_type', 'reauthenticate');
    url.searchParams.set('redirect_uri', `https://${window.location.hostname}/facebook/re-auth/callback`);
  }

  if (state) {
    url.searchParams.append('state', JSON.stringify(state));
  }

  return url.toString();
};
