import React from 'react';

import { Box, Checkbox, makeStyles, Theme } from '@material-ui/core';

import { StyledFormControlLabel } from 'src/components/SideBar/styled';

const useStyles = makeStyles((theme: Theme) => ({
  checkboxWrapper: {
    marginBottom: '15px',
    position: 'relative',
  },
  moreOptionsBtn: {
    width: '320px',
    borderRadius: '2px',
    border: `solid 1px ${theme.palette.secondary.main}`,
    padding: '12px 20px',
    boxSizing: 'border-box',
  },
  groupLetter: {
    position: 'absolute',
    left: '-24px',
    color: theme.palette.text.disabled,
    fontWeight: 700,
  },
  relativeContainer: {
    position: 'relative',
  },
}));

interface Props {
  selectedValues: string[];
  itemS: string[];
  handleChangeCheckbox: (values: string) => void;
  title?: string;
}

type Values = {
  [key: string]: string[];
};

const ExtraOptions = ({ itemS, selectedValues, handleChangeCheckbox, title }: Props) => {
  const classes = useStyles();
  const values: Values = React.useMemo(
    () =>
      itemS.reduce((acc: Values, item: string) => {
        const firstLetter: string = item[0];
        if (!acc[firstLetter]) {
          acc[firstLetter] = [];
        }
        acc[firstLetter].push(item);
        return acc;
      }, {}),
    [itemS],
  );

  return (
    <div>
      {Object.entries(values).map(([letter, items]: [string, string[]]) => (
        <div className={classes.relativeContainer} key={letter}>
          <div className={classes.groupLetter}>{letter}</div>
          {items.map((item: string, index: number) => (
            <Box className={classes.checkboxWrapper} key={index}>
              <StyledFormControlLabel
                control={
                  <Checkbox
                    onChange={() => handleChangeCheckbox(item as string)}
                    checked={selectedValues.includes(item as string)}
                    inputProps={
                      { 'data-testid': `${item}${title}Checkbox` } as React.InputHTMLAttributes<HTMLInputElement>
                    }
                  />
                }
                label={item as string}
              />
            </Box>
          ))}
        </div>
      ))}
    </div>
  );
};

export default ExtraOptions;
