import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, makeStyles } from '@material-ui/core';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';

import Button from 'src/components/Button';
import TextInput from 'src/components/Forms/FormInputs/TextInput';
import { theme } from 'src/theme';

import { addPaymentCardFormSchema } from '../Forms/validationSchemas';

import { StripeFieldCard } from './StripeCustomFields';
import { StyledCreditCardForm } from './styled';

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: '25px',
  },
}));

interface CreditCardFormProps {
  onCreatePaymentMethod: (id: string) => void;
}

const CreditCardForm = ({ onCreatePaymentMethod }: CreditCardFormProps) => {
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const classes = useStyles();

  const formMethods = useForm({
    mode: 'onChange',
    resolver: yupResolver(addPaymentCardFormSchema),
  });

  const {
    formState: { isValid },
    getValues,
  } = formMethods;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [cardState, setCardState] = useState<{ cardComplete: boolean; cardError: string }>({
    cardComplete: false,
    cardError: '',
  });

  const { cardError } = cardState;

  const onElementChange =
    (field: string, errorField: string) =>
    ({ complete = false, error = { message: '' } }) => {
      setCardState({ ...cardState, [field]: complete, [errorField]: error.message });
    };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const cardholderName = getValues('cardholderName');

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    if (!cardElement) {
      return;
    }

    setIsLoading(true);

    cardElement.update({
      disabled: true,
      style: {
        base: {
          color: theme.palette.text.disabled,
        },
      },
    });

    if (cardholderName) {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name: cardholderName,
        },
      });

      if (error && error.message) {
        cardElement.update({
          disabled: false,
          style: {
            base: {
              color: theme.palette.text.primary,
            },
          },
        });
      }

      if (paymentMethod?.id) {
        await onCreatePaymentMethod(paymentMethod.id);
      }
    } else {
      cardElement.update({
        disabled: false,
        style: {
          base: {
            color: theme.palette.text.primary,
          },
        },
      });
    }

    setIsLoading(false);
  };

  return (
    <FormProvider {...formMethods}>
      <StyledCreditCardForm onSubmit={handleSubmit}>
        <TextInput
          shouldFocus
          fullWidth
          label={t('userSettingsPage.billing.addPaymentMethodPopup.cardholderName')}
          name="cardholderName"
          disabled={isLoading}
          className={classes.root}
          inputNameTestId="cardholderName"
        />

        <StripeFieldCard
          error={Boolean(cardError)}
          labelErrorMessage={cardError}
          onChange={onElementChange('cardComplete', 'cardError')}
          loading={isLoading}
          data-testid="cardField"
        />

        <Box display="flex" justifyContent="center" mt="40px" position="absolute" left="0" right="0">
          <Button variant="contained" type="submit" loading={isLoading} disabled={!isValid || !cardState.cardComplete}>
            {t('userSettingsPage.billing.addPaymentMethodPopup.button')}
          </Button>
        </Box>
      </StyledCreditCardForm>
    </FormProvider>
  );
};

export default CreditCardForm;
