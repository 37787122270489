import React, { MouseEventHandler, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Box, Typography } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';

import Button from 'src/components/Button';
import ImportFromCsvCard from 'src/components/Card/ImportFromCsvCard';
import CancelCSVSidebarUploadPopup from 'src/components/Popups/CancelCSVSidebarUploadPopup';
import { PARSING_FILE_STATE } from 'src/constants';
import { CATALOGS_PATH, FIRST_DASHBOARD_PATHNAME } from 'src/constants/routeSources';
import { CsvFileInfo } from 'src/hooks/useImportCsv';
import useOnboarding from 'src/providers/OnboardingProvider/hooks/useOnboarding';

import DashboardBreadCrumbs from '../Breadcrumds/DashboardBreadCrumbs';
import Hint from '../Hint';
import SmallAddButton from '../SmallAddButton';

import { ImportFromCsvSidebarHeader } from './styled';

const FilesContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  padding-top: 10px;
  scrollbar-width: thin;

  .card-container:nth-last-of-type(1) {
    margin-bottom: 40px;
  }
`;

interface ImportFromCsvSidebarProps {
  onAddButtonClick: MouseEventHandler;
  onReplayIconClick: (id: string) => void;
  deleteFile: (id: string) => void;
  filesStateValues: CsvFileInfo[];
  selectCard: (id: string | null) => void;
  viewAllItemsPath: string;
}

const ImportFromCsvSidebar = ({
  onAddButtonClick,
  onReplayIconClick,
  deleteFile,
  filesStateValues,
  selectCard,
  viewAllItemsPath,
}: ImportFromCsvSidebarProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    onboardingState: { tourActive },
    goNext,
  } = useOnboarding();

  const [isSaveButtonClicked, setIsSaveButtonClicked] = useState(false);

  const disableButton = useMemo(() => {
    const arr = tourActive
      ? [PARSING_FILE_STATE.Uploading, PARSING_FILE_STATE.Uploaded, PARSING_FILE_STATE.Parsing]
      : [PARSING_FILE_STATE.Uploading];

    return filesStateValues.some((fileState) => arr.includes(fileState.state)) || !filesStateValues.length;
  }, [filesStateValues, tourActive]);

  const alertUser = (event: Event) => {
    event.preventDefault();
    event.returnValue = false;
  };

  useEffect(() => {
    if (isSaveButtonClicked) {
      history.push(`/${FIRST_DASHBOARD_PATHNAME}/${CATALOGS_PATH}`);

      if (tourActive) {
        goNext();

        window.history.pushState({}, '', history.location.pathname);
      }
    }
    if (viewAllItemsPath) {
      history.push(viewAllItemsPath);
    }
  }, [viewAllItemsPath, isSaveButtonClicked, history, tourActive, goNext]);

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser);

    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  }, [tourActive]);

  const discardChangesOpen = !(isSaveButtonClicked || viewAllItemsPath || !filesStateValues?.length);

  return (
    <Box ml="42px">
      <Box ml="17px" mb="25px">
        <DashboardBreadCrumbs />
      </Box>

      <ImportFromCsvSidebarHeader>
        <Box display="flex" justifyContent="space-between" alignItems="center" ml="8px">
          <Typography ml="5px" mr="15px" variant="h2">
            {t('importFromCsvPage.sidebar.title')}
          </Typography>

          <SmallAddButton disabled={tourActive} isNewDesign onClick={onAddButtonClick} />
        </Box>

        <Hint
          title={`${t('importFromCsvPage.sidebar.saveTooltip')}`}
          placement="left"
          type="hover"
          isCsvSidebarHint
          doNotShow={tourActive}
        >
          <Typography variant="caption" color="text.secondary" mr="24px">
            <Button
              sx={disableButton ? { visibility: tourActive ? 'visible' : 'hidden' } : { visibility: 'visible' }}
              type="button"
              variant="contained"
              onClick={() => setIsSaveButtonClicked(true)}
              loading={tourActive && disableButton}
              disabled={disableButton && !tourActive}
            >
              {t('importFromCsvPage.sidebar.saveButton')}
            </Button>
          </Typography>
        </Hint>
      </ImportFromCsvSidebarHeader>

      <FilesContainer>
        {filesStateValues.map((file) => (
          <Box className="card-container" width="100%" mb="20px" pl="15px" pr="24px" key={file.id}>
            <ImportFromCsvCard
              fileInfo={file}
              onReplayIconClick={() => onReplayIconClick(file.id)}
              onCancelIconClick={() => deleteFile(file.id)}
              onCardClick={() => selectCard(file.id)}
            />
          </Box>
        ))}
      </FilesContainer>

      <CancelCSVSidebarUploadPopup isOpen={discardChangesOpen} />
    </Box>
  );
};

export default ImportFromCsvSidebar;
