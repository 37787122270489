import React, { useState, useMemo, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useQuery, useMutation, ApolloError, NetworkStatus, Reference, StoreObject } from '@apollo/client';
import { IconButton, Box, makeStyles, Theme, Typography } from '@material-ui/core';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { v4 as uuidv4 } from 'uuid';

import MediaDropzone from 'src/components/Dropzone/MediaDropzone';
import useUploadMedia from 'src/hooks/useUploadMedia';
import { MediaStateContext } from 'src/providers/MediaProvider/context';
import { useSnackbar } from 'src/providers/snackbar';
import { deleteMedia as deleteMediaItem, getMedia, Query, MediaType, QueryGetMediaArgs } from 'src/utils/gql';
import { MEDIA_COUNT_LIMIT, UploadingMedia } from 'src/views/Catalogs/ProductItems/types';

import Iconography from '../Iconography';
import Loader from '../Loader';
import DeletePopup from '../Popups/DeletePopup';
import Scroll from '../Scroll';
import Select, { SelectOptionItem } from '../Select';
import SideBarDrawer from '../SideBar/RightSidebarDrawer';
import SkeletonMedia from '../Skeleton/SkeletonMedia';

import {
  MediaWithExpension,
  UploadingMediaWithLoadingStatus,
  LIMIT_MEDIA_ITEMS,
  ALL_MEDIA_TYPES,
  DataFilter,
  OptionsMediaFilter,
  SidebarMediaGalleryProps,
  MediaDataType,
} from './constants';
import { getFilterByDate } from './functions';
import ItemsSidebarMediaGallery from './ItemsSidebarMediaGallery';
import { LoaderContainer } from './styled';
import MediaFilter from './TabMediaFilterSidebar';
import UploadMedia from './UploadMediaToSidebar';

const useStyles = makeStyles<Theme, { numberOfMedia: number; selectedItems: number; loading?: boolean }>((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    padding: '0 56px 0 30px',
  },
  header: {
    display: 'flex',
    marginTop: '43px',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '33px',
  },
  checkBox: {
    display: 'flex',
    marginTop: '20px',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '15px',
  },
  deleteMedia: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    visibility: ({ selectedItems }) => (selectedItems ? 'visible' : 'hidden'),
  },
  formControl: {
    '& .MuiFormControlLabel-label': {
      fontSize: '15px',
    },
    '& span.MuiCheckbox-root': {
      background: theme.palette.common.white,
      color: ({ numberOfMedia, loading }: { numberOfMedia: number; loading?: boolean }) =>
        numberOfMedia && !loading ? theme.palette.secondary.dark : theme.palette.action.disabled,
      borderRadius: 0,
      transform: 'scale(0.7777)',
    },
    '& span.MuiCheckbox-root:hover': {
      background: theme.palette.common.white,
    },
    '& span svg': {
      fontSize: '1.7rem',
    },
  },

  gridContainer: {
    padding: '0 46px 0 30px',
    overflow: 'auto',
    height: '100vh',
    position: 'relative',

    '&>div:first-child': {
      display: 'none',
    },
    '&>div>div': {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(159px, 1fr))',
      gridTemplateRows: 'repeat(auto-fill, minmax(159px, 1fr))',
      gridColumnGap: '12px',
      gridRowGap: '10px',
    },
  },
  dropZone: {
    padding: '0 56px 0 30px',
    height: '100vh',
    display: 'flex',
    position: 'relative',
    overflow: 'auto',

    '&>div:not(:first-child)': {
      display: 'none',
    },

    '& .MuiBackdrop-root': {
      zIndex: 1300,
      position: 'initial',
      height: '100%',
      width: '100%',
      '& div': {
        height: 'auto',
      },
    },
  },
  iconButton: {
    padding: '0 0 0 10px',
    transform: 'scale(1.2)',
    opacity: 1,
  },
}));

const SidebarMediaGallery = ({ isMediaSideBarPopupOpen, setIsMediaSideBarPopupOpen }: SidebarMediaGalleryProps) => {
  const { t } = useTranslation();
  const snackbar = useSnackbar();

  const { mediaState, setMediaState, mediaLastOrder, setMediaLastOrder } = useContext(MediaStateContext);

  const createSelectOptionFromDataFilter = (dataFilter: DataFilter) => ({
    value: dataFilter,
    label: t(`productItemCreateEdit.mediaGallery.sidebar.selectionOptions.${dataFilter}`),
    'data-testid': `${dataFilter}Option`,
  });

  const dataMediaFilterOptions: Array<OptionsMediaFilter> = [
    { value: ALL_MEDIA_TYPES, title: t('productItemCreateEdit.mediaGallery.sidebar.tabFilterOptions.all') },
    { value: MediaType.Image, title: t('productItemCreateEdit.mediaGallery.sidebar.tabFilterOptions.images') },
    { value: MediaType.Video, title: t('productItemCreateEdit.mediaGallery.sidebar.tabFilterOptions.video') },
    { value: MediaType.Document, title: t('productItemCreateEdit.mediaGallery.sidebar.tabFilterOptions.documents') },
  ];

  const [typeFilter, setTypeFilter] = useState<MediaType | undefined>(ALL_MEDIA_TYPES);
  const [dateFilter, setDateFilter] = useState<DataFilter>(DataFilter.all);
  const [checkboxStateMediaIds, setCheckboxStateMediaIds] = useState<Array<string>>([]);
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [deleteItem, setDeleteItem] = useState<string>('');
  const [recedingMedia, setRecedingMedia] = useState<string[]>([]);
  const [mediaStateOnUpload, setMediaStateOnUpload] = useState<UploadingMediaWithLoadingStatus[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);

  const { handleUploadMedia } = useUploadMedia(setMediaStateOnUpload);

  const mediaType = useMemo(() => {
    if (typeFilter === MediaType.Video) {
      return [MediaType.ExternalVideo, MediaType.Video];
    }

    return typeFilter && [typeFilter];
  }, [typeFilter]);

  const handleUpdateMedia = (data: Pick<Query, 'getMedia'>) => {
    if (!data || data.getMedia.length > LIMIT_MEDIA_ITEMS) {
      return;
    }

    setHasMore(data.getMedia.length === LIMIT_MEDIA_ITEMS);
  };

  const deleteItemFromMediaState = (selectedMedia: string[]) => {
    setMediaState((prev) => [...prev.filter(({ id }) => !selectedMedia.includes(id))]);

    setCheckboxStateMediaIds([]);
  };

  const {
    data,
    loading: loadingMedia,
    error,
    fetchMore,
    networkStatus,
  } = useQuery<Pick<Query, 'getMedia'>, QueryGetMediaArgs>(getMedia, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    variables: {
      type: mediaType,
      limit: LIMIT_MEDIA_ITEMS,
      from: getFilterByDate(dateFilter),
    },
    onCompleted: handleUpdateMedia,
  });

  const mediaData: MediaWithExpension[] = useMemo(() => data?.getMedia || [], [data]);

  const fetchMoreMedia = async () => {
    const offset = mediaData.length;

    if (!fetchMore || networkStatus !== NetworkStatus.ready) return;

    handleUpdateMedia(
      (
        await fetchMore<'offset'>({
          variables: {
            offset,
          },
        })
      ).data,
    );
  };

  const numberOfMedia = mediaData.length;

  const selectedItems = checkboxStateMediaIds.length;

  const mediaIds = mediaData.map(({ id }) => id);

  const [deleteMedia, { loading: loadingAfterRemoval }] = useMutation(deleteMediaItem);

  const handleDeleteMedia = async (selectedMedia: Array<string>) => {
    try {
      setRecedingMedia(selectedMedia);

      await deleteMedia({
        variables: {
          ids: selectedMedia,
        },
        update(cache) {
          cache.modify({
            fields: {
              getMedia(mediaRef = [], { readField }) {
                return mediaRef.filter(
                  (ref: Reference | StoreObject | undefined) => !selectedMedia.includes(readField('id', ref) as string),
                );
              },
            },
          });
        },
      });

      deleteItemFromMediaState(selectedMedia);
    } catch (error) {
      const { graphQLErrors, message: errorText } = error as ApolloError;
      const message = graphQLErrors && graphQLErrors.length ? graphQLErrors[0].message : errorText;

      if (error) {
        snackbar(message);
      }
    }
  };

  const mediaStateForUploading = useMemo(
    () => mediaStateOnUpload.filter(({ loadingStatus }) => loadingStatus),
    [mediaStateOnUpload],
  );

  useEffect(() => {
    if (!mediaStateForUploading.length && mediaStateOnUpload.length) {
      setMediaStateOnUpload([]);
    }
  }, [mediaStateForUploading, mediaStateOnUpload]);

  const loading = loadingAfterRemoval || loadingMedia;

  const classes = useStyles({ numberOfMedia, selectedItems, loading });

  const checkItemStatus = (id: string) => checkboxStateMediaIds.includes(id);

  const toggleTabFilter = (value?: MediaType) => {
    if (value !== typeFilter) {
      setTypeFilter(value);

      changeActiveItems(false);
      setHasMore(true);
    }
  };

  const changeActiveItems = (stateCheckbox: boolean, id?: string) => {
    if (id) {
      setCheckboxStateMediaIds((prevState) =>
        stateCheckbox ? [...prevState, id] : prevState.filter((item) => item !== id),
      );
    } else {
      setCheckboxStateMediaIds(stateCheckbox ? mediaIds : []);
    }
  };

  const handleChangeItem = ({ target: { id, checked } }: React.ChangeEvent<HTMLInputElement>) => {
    changeActiveItems(checked, id);

    if (deleteItem) {
      setDeleteItem('');
    }
  };

  const toggleSelectFilter = ({ value }: SelectOptionItem) => {
    setDateFilter(value as DataFilter);

    changeActiveItems(false);
    setHasMore(true);
  };

  const deleteMediaByIds = (id?: string) => {
    handleDeleteMedia(id ? [id] : checkboxStateMediaIds);

    setIsPopupOpen(false);
    setHasMore(true);
  };

  const requestRemovalOfMediaItem = (id: string) => {
    setDeleteItem(id);
    setIsPopupOpen(true);
  };

  const insertMediaIntoProject = () => {
    const uploadMedia: UploadingMedia[] = mediaData
      .filter(({ id }) => checkboxStateMediaIds.includes(id))
      .map(({ type, id, url }, idx) => {
        const newMediaLastOrder = Number.isInteger(mediaLastOrder) ? (mediaLastOrder || 0) + idx + 1 : 0;
        setMediaLastOrder(newMediaLastOrder);
        return {
          type,
          id,
          url,
          previewUrl: url,
          typeAddedData: MediaDataType.FROM_MEDIA_GALLERY,
          orderNumber: newMediaLastOrder,
          uuid: uuidv4(),
          altText: '',
        };
      });

    if (mediaState.length + uploadMedia.length <= MEDIA_COUNT_LIMIT) {
      const availableQuantityToAdd = MEDIA_COUNT_LIMIT - mediaState.length;
      const correctUploadMedia = uploadMedia.slice(0, availableQuantityToAdd);
      setMediaState((prev) => [...prev, ...correctUploadMedia]);

      setIsMediaSideBarPopupOpen(false);
    } else {
      snackbar(t('uploadMediaErrors.exceededCount'));
    }
  };

  const formControlLabel = selectedItems
    ? t('productItemCreateEdit.mediaGallery.sidebar.selectedItem', { count: selectedItems })
    : t('productItemCreateEdit.mediaGallery.sidebar.checkboxLabelOfAllItems');

  const uniqueMedia = Object.values(
    mediaData.reduce((acc: MediaWithExpension[], media: MediaWithExpension) => {
      !acc[+media.id] && (acc[+media.id] = media);

      return acc;
    }, []),
  );

  const allMediaData = useMemo(
    () => (mediaStateForUploading.length ? [...mediaStateForUploading, ...uniqueMedia] : [...uniqueMedia]),
    [mediaStateForUploading, uniqueMedia],
  );

  const sortedMedia = useMemo(() => {
    const isVideo = typeFilter === MediaType.Video;

    return !typeFilter
      ? allMediaData
      : allMediaData.filter(({ type }) =>
          isVideo ? [MediaType.Video, MediaType.ExternalVideo].includes(type as MediaType) : type === typeFilter,
        );
  }, [allMediaData, typeFilter]);

  return (
    <SideBarDrawer
      propsAdornment={<UploadMedia setUpload={setMediaStateOnUpload} />}
      secondaryButtonTitle={
        selectedItems ? t('productItemCreateEdit.mediaGallery.sidebar.insertIntoProjectButton') : ''
      }
      onSecondaryButtonClick={insertMediaIntoProject}
      titleName={t('productItemCreateEdit.mediaGallery.sidebar.mediaLibrary')}
      openSidebar={isMediaSideBarPopupOpen}
      onPrimaryButtonClick={() => setIsMediaSideBarPopupOpen(false)}
      primaryButtonTitle={t('productItemCreateEdit.mediaGallery.sidebar.closeButton')}
      dataTestIdPrimaryButton="closeButton"
      dataTestIdSecondaryButton="insertIntoProductButton"
    >
      <>
        <Box className={classes.root}>
          <MediaFilter
            onFilterSelect={toggleTabFilter}
            typeFilter={typeFilter}
            dataFilterOptions={dataMediaFilterOptions}
          />
          <Select
            options={Object.values(DataFilter).map(createSelectOptionFromDataFilter)}
            onChangeSelect={toggleSelectFilter}
            label={t('productItemCreateEdit.mediaGallery.sidebar.selectLabel')}
            selectedOptionItem={createSelectOptionFromDataFilter(dateFilter)}
          />
          <Box className={classes.checkBox}>
            <FormControlLabel
              disabled={!Boolean(numberOfMedia) || loading}
              className={classes.formControl}
              label={
                selectedItems === numberOfMedia && numberOfMedia
                  ? (t('productItemCreateEdit.mediaGallery.sidebar.selectedAllItems') as string)
                  : formControlLabel
              }
              control={
                <Checkbox
                  disableRipple
                  disabled={!Boolean(numberOfMedia) || loading}
                  checked={!!selectedItems}
                  indeterminate={!!selectedItems && numberOfMedia !== selectedItems}
                  onChange={handleChangeItem}
                  inputProps={
                    { 'data-testid': 'selectAllMediaCheckbox' } as React.InputHTMLAttributes<HTMLInputElement>
                  }
                />
              }
            />
            <Box onClick={() => setIsPopupOpen(true)} className={classes.deleteMedia} data-testid="deleteMediaButton">
              <Typography fontSize="15px" fontWeight="400" lineHeight="18px">
                {t('productItemCreateEdit.mediaGallery.sidebar.deleteMedia')}
              </Typography>
              <IconButton className={classes.iconButton}>
                <Iconography iconName="trash-fill" fontSize="small" />
              </IconButton>
            </Box>
          </Box>
        </Box>
        {!(data || error) ? (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        ) : (
          <MediaDropzone
            mediaContainer={classes.gridContainer}
            dropzoneContainer={classes.dropZone}
            handleUploadMedia={handleUploadMedia}
            id="mediaScrollableTarget"
          >
            <Scroll
              dataLength={sortedMedia.length}
              hasMore={hasMore}
              loader={<SkeletonMedia />}
              next={fetchMoreMedia}
              scrollableTarget="mediaScrollableTarget"
            >
              {sortedMedia.map(({ type, id, previewUrl, createdAt, expansion, name }) => (
                <ItemsSidebarMediaGallery
                  key={id}
                  id={id}
                  href={previewUrl}
                  type={type}
                  created={!!createdAt}
                  changeCheckbox={handleChangeItem}
                  itemState={checkItemStatus(id)}
                  deleteItem={() => requestRemovalOfMediaItem(id)}
                  recedingMedia={recedingMedia.includes(id)}
                  expansion={expansion}
                  uploadMediaName={name}
                />
              ))}
            </Scroll>
          </MediaDropzone>
        )}

        <DeletePopup
          open={isPopupOpen}
          onClose={() => setIsPopupOpen(false)}
          onMainButtonClick={() => deleteMediaByIds(deleteItem)}
          onSecondaryButtonClick={() => setIsPopupOpen(false)}
          mainTitle={
            <Box>
              <Typography variant="h2">{t('popupDeleteText')}</Typography>
              <Typography variant="h2">{t('productItemCreateEdit.mediaGallery.sidebar.popupDeleteText')}</Typography>
            </Box>
          }
          descriptionText={
            <Box component="span">
              <Typography component="span" color="text.secondary" variant="body1">
                {t('productItemCreateEdit.mediaGallery.sidebar.popupDeleteTextDescription')}
              </Typography>
              <Typography component="span" color="text.secondary" variant="body1">
                {t('deletePopup.item.text')}
              </Typography>
            </Box>
          }
        />
      </>
    </SideBarDrawer>
  );
};

export default SidebarMediaGallery;
