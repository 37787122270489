import { Box } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';

import Page from 'src/components/Page';

export const PageWrapper = styled(Page)`
  height: 100%;
  margin-left: -16px;
`;

export const PageContentWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ProductItemWrapper = styled(Box)`
  overflow-y: auto;
  display: flex;
  scrollbar-width: thin;

  & > div:first-of-type {
    min-height: 100%;
    height: fit-content;
    padding-top: 20px;
    max-width: 664px;
    box-shadow: rgb(46 96 170 / 10%) 0 -4px 15px inset;
  }

  & > div:last-of-type {
    padding-top: 15px;
  }
`;
