import React, { Suspense } from 'react';
import { RouteConfig } from 'react-router-config';

import LinearProgress from '@material-ui/core/LinearProgress';

import RenderRoutes from 'src/utils/RenderRoutes';

import LoginLayout from './Login';

interface AuthProps {
  route: RouteConfig;
}

const Auth = ({ route }: AuthProps) => {
  return (
    <Suspense fallback={<LinearProgress />}>
      <LoginLayout>
        <RenderRoutes routes={route.routes} />
      </LoginLayout>
    </Suspense>
  );
};

export default Auth;
