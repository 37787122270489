import React, { SyntheticEvent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { arrayToMap } from '../utils/general';
import { Integration, IntegrationMappingError, Maybe } from '../utils/gql';

import { StyledBox } from './DuplicatedSKUMessage';

const useStyles = makeStyles(() => ({
  errorLink: {
    cursor: 'pointer',
    marginLeft: '2px',
    textDecoration: 'underline',
  },
}));

interface IntegrationUnavailableMessageProps {
  integrationUnavailableErrors: IntegrationMappingError[];
  onTryAgainButtonClick?: (e: SyntheticEvent<Element, Event>) => void;
  integrations?: Maybe<Integration[]>;
}

const IntegrationUnavailableMessage = ({
  integrationUnavailableErrors,
  onTryAgainButtonClick,
  integrations,
}: IntegrationUnavailableMessageProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const integrationsMap = useMemo(() => arrayToMap(integrations || []), [integrations]);

  return (
    <StyledBox>
      {`${t('integrationUnavailableError')} ${integrationUnavailableErrors.map(
        ({ integrationId }, idx) =>
          `${integrationsMap[integrationId]?.settings?.integrationName}${
            integrationUnavailableErrors.length === idx + 1 ? '.' : ', '
          }`,
      )}`}

      <Typography
        onClick={onTryAgainButtonClick}
        className={classes.errorLink}
        component="span"
        variant="inherit"
        color="inherit"
      >
        {t('cataloguesPage.chooseExportPopup.errorHintLink')}
      </Typography>
    </StyledBox>
  );
};

export default IntegrationUnavailableMessage;
