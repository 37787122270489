import React from 'react';

import { Box } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';

export enum HintType {
  click = 'click',
  hover = 'hover',
}

interface Props extends TooltipProps {
  type: keyof typeof HintType;
  isCsvSidebarHint?: boolean;
  isDefaultFieldValueHint?: boolean;
  doNotShow?: boolean;
  tooltipWidth?: string;
  customTooltipMarginLeft?: string;
}

interface StyleProps {
  tooltipWidth?: string;
  customTooltipMarginLeft?: string;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) =>
  createStyles({
    tooltipRoot: {
      maxWidth: ({ tooltipWidth }) => tooltipWidth ?? '300px',
      marginLeft: ({ customTooltipMarginLeft }) => customTooltipMarginLeft,
    },
    tooltipArrow: {
      backgroundColor: `${theme.palette.common.white} !important`,
      boxShadow: '0 2px 10px rgba(46, 96, 170, 0.25)',
      color: `${theme.palette.primary.light} !important`,
      padding: '10px 5px 10px 20px !important',
    },
    arrow: {
      fontSize: '15px !important',
      '&:before': {
        backgroundColor: `${theme.palette.common.white} !important`,
      },
    },
    csvHintWidth: {
      maxWidth: '230px',
    },
    defaultFieldValueWidth: {
      maxWidth: '374px',
    },
    childrenContainer: {
      maxHeight: '160px',
      overflow: 'auto',
      paddingRight: '11px',

      '&::-webkit-scrollbar': {
        width: '4px',
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '5px',
        border: `1px solid ${theme.palette.action.selected}`,
        background: theme.palette.secondary.main,
        boxShadow: 'none',
      },
      '&::-webkit-scrollbar-track': {
        borderRadius: '5px',
        border: 'none',
        background: theme.palette.common.white,
        boxShadow: 'inset 0px 0px 15px rgba(46, 96, 170, 0.15)',
      },
    },
  }),
);

export default function Hint({
  children,
  title,
  type,
  isCsvSidebarHint,
  isDefaultFieldValueHint,
  doNotShow,
  tooltipWidth,
  customTooltipMarginLeft,
  ...rest
}: Props) {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles({ tooltipWidth, customTooltipMarginLeft });
  const message = title ? (
    <Box className={classes.childrenContainer}>
      <Typography variant="caption" color="primary.main">
        {title}
      </Typography>
    </Box>
  ) : (
    ''
  );

  if (doNotShow) return children;

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const getHintByType = () => {
    if (type === HintType.click) {
      return (
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <Tooltip
            {...rest}
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            arrow
            classes={{
              tooltipArrow: classes.tooltipArrow,
              arrow: classes.arrow,
            }}
            title={message}
          >
            <div role="button" tabIndex={0} onClick={handleTooltipOpen}>
              {children}
            </div>
          </Tooltip>
        </ClickAwayListener>
      );
    }

    return (
      <Tooltip
        {...rest}
        disableFocusListener
        disableTouchListener
        describeChild
        classes={{
          tooltipArrow: classes.tooltipArrow,
          arrow: classes.arrow,
          tooltip: clsx(
            classes.tooltipRoot,
            isCsvSidebarHint
              ? classes.csvHintWidth
              : isDefaultFieldValueHint
              ? classes.defaultFieldValueWidth
              : undefined,
          ),
        }}
        title={message}
        arrow
      >
        {children}
      </Tooltip>
    );
  };

  return getHintByType();
}
