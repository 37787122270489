import moment from 'moment';

import { Dates } from './types';

export const getDate = (type: Dates): string => {
  switch (type) {
    case Dates.Today:
      return moment().startOf('day').toString();
    case Dates.LastThreeDays:
      return moment().subtract(3, 'days').startOf('day').toString();
    case Dates.LastWeek:
      return moment().subtract(1, 'week').startOf('day').toString();
    case Dates.LastMonth:
      return moment().subtract(1, 'month').startOf('day').toString();
  }
};
