import React from 'react';

import { FieldType } from 'src/utils/gql';

import BooleanToStringBox from './BooleanToStringBox';
import {
  MultiselectChipWrapper,
  StyledMultiselectChip,
  StyledMultiselectChipWrapper,
  StyledStringValueWrapper,
} from './styled';

interface ValuePresenterProps {
  type: FieldType;
  value: string;
  isSelectedForDeletion?: boolean;
}

const ValuePresenter = ({ type, value, isSelectedForDeletion = false }: ValuePresenterProps) => {
  switch (type) {
    case FieldType.Boolean:
      return <BooleanToStringBox fieldValue={value} />;

    case FieldType.Multiselect: {
      const multiselectValue = JSON.parse(String(value));
      return (
        <MultiselectChipWrapper>
          {multiselectValue.map((item: string, idx: number) => (
            <StyledMultiselectChipWrapper key={idx} className={isSelectedForDeletion ? 'disabled' : ''}>
              <StyledMultiselectChip>{item}</StyledMultiselectChip>
            </StyledMultiselectChipWrapper>
          ))}
        </MultiselectChipWrapper>
      );
    }

    default:
      return <StyledStringValueWrapper>{value}</StyledStringValueWrapper>;
  }
};

export default ValuePresenter;
