import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { ApolloError } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@material-ui/core';

import Button from 'src/components/Button';
import EmailInput from 'src/components/Forms/FormInputs/EmailInput';
import { FormContainer } from 'src/components/Forms/styled';
import { resendEmailFormSchema } from 'src/components/Forms/validationSchemas';

import { ResendEmailInput } from './types';

interface ResendEmailFormProps {
  onSubmit: (email: string) => Promise<void>;
  onError?: (message: string) => void;
  loading?: boolean;
}

const ResendEmailForm = ({ onSubmit, onError, loading }: ResendEmailFormProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  const formMethods = useForm<ResendEmailInput>({
    mode: 'onChange',
    resolver: yupResolver(resendEmailFormSchema),
  });

  const {
    handleSubmit,
    formState: { isValid },
  } = formMethods;

  const onSubmitForm = handleSubmit(async ({ email }) => {
    try {
      await onSubmit(email);
    } catch (error) {
      const { graphQLErrors, message: errorText } = error as ApolloError;
      const message = graphQLErrors?.length ? graphQLErrors[0].message : errorText;
      onError?.(message);
    }

    history.push({
      pathname: '/auth/check-email',
      state: {
        userEmail: email,
        isIntegration: false,
      },
    });
  });

  return (
    <FormProvider {...formMethods}>
      <form aria-label="form" onSubmit={onSubmitForm}>
        <FormContainer>
          <Box width="100%" mb="40px">
            <EmailInput shouldFocus />
          </Box>

          <Button
            color="primary"
            disabled={!isValid}
            type="submit"
            data-testid="submit"
            variant="contained"
            loading={loading}
          >
            {t('resendEmail.buttonText')}
          </Button>
        </FormContainer>
      </form>
    </FormProvider>
  );
};

export default ResendEmailForm;
