import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { ApolloError, Reference, StoreObject, useLazyQuery, useMutation } from '@apollo/client';
import { Typography } from '@material-ui/core';

import {
  CATALOGS_PATH,
  FIRST_DASHBOARD_PATHNAME,
  PRODUCT_ITEMS_PATH,
  PRODUCT_TYPES_PATH,
} from 'src/constants/routeSources';
import { useSnackbar } from 'src/providers/snackbar';
import {
  checkIsCatalogEmpty,
  deleteProductItemsById,
  Mutation,
  MutationDeleteProductItemsArgs,
  Query,
  QueryGetCatalogByIdArgs,
} from 'src/utils/gql';

import Popup from '../Popup';

import { ItemDefinitionPopupBody } from './styled';

interface ItemDefinitionDeletePopupProps {
  open: boolean;
  onClose: () => void;
  productItemId?: string;
}

const ItemDefinitionDeletePopup = ({ open, onClose, productItemId }: ItemDefinitionDeletePopupProps) => {
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const history = useHistory();
  const { catalogId, productTypeId } = useParams<{
    catalogId: string;
    productTypeId: string;
  }>();

  const [checkIsCatalogEmptyQuery, { loading: loadingCheck }] = useLazyQuery<
    Pick<Query, 'getCatalogById'>,
    QueryGetCatalogByIdArgs
  >(checkIsCatalogEmpty, {
    fetchPolicy: 'network-only',
    variables: { id: catalogId },
    onCompleted: () => {
      snackbar(t('productItemCreateEdit.snackbarUpdateItemSuccess'), 'success');
      history.push(
        `/${FIRST_DASHBOARD_PATHNAME}/${CATALOGS_PATH}/${catalogId}/${PRODUCT_TYPES_PATH}/${productTypeId}/${PRODUCT_ITEMS_PATH}`,
      );
    },
  });

  const [deleteProductItem, { loading: loadingDelete }] = useMutation<
    Pick<Mutation, 'deleteProductItems'>,
    MutationDeleteProductItemsArgs
  >(deleteProductItemsById, {
    onCompleted: () => {
      checkIsCatalogEmptyQuery();
    },
    onError: (error) => {
      const { graphQLErrors, message: errorText } = error as ApolloError;
      const message = graphQLErrors && graphQLErrors.length ? graphQLErrors[0].message : errorText;

      if (error) {
        snackbar(message);
      }
    },
  });

  const handleDelete = async () => {
    if (!productItemId) return;

    deleteProductItem({
      variables: {
        productItemIds: [productItemId],
      },
      update(cache) {
        cache.modify({
          fields: {
            getProductItemsByProductTypeId(productItemsRef = [], { readField }) {
              return productItemsRef.filter(
                (ref: Reference | StoreObject | undefined) => productItemId !== readField('id', ref),
              );
            },
          },
        });
      },
    });
  };

  return (
    <Popup
      open={open}
      mainTitle={[
        t('productItemCreateEdit.popupDeleteItem.title'),
        t('productItemCreateEdit.popupDeleteItem.titleContinue'),
      ]}
      onClose={onClose}
      mainButtonText={t('productItemCreateEdit.popupDeleteItem.mainButton')}
      secondaryButtonText={t('productItemCreateEdit.popupDeleteItem.secondaryButton')}
      onMainButtonClick={handleDelete}
      onSecondaryButtonClick={onClose}
      loadingOnMainButton={loadingCheck || loadingDelete}
    >
      <ItemDefinitionPopupBody>
        <Typography variant="body1">{t('productItemCreateEdit.popupDeleteItem.text')}</Typography>
      </ItemDefinitionPopupBody>
    </Popup>
  );
};

export default ItemDefinitionDeletePopup;
