import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@material-ui/core';

import Popup from '../Popup';

import CreditCardStripeContainer from './CreditCardStripeContainer';

interface AddPaymentMethodPopupProps {
  open: boolean;
  onClose: () => void;
  onCreatePaymentMethod: (id: string) => void;
}

const AddPaymentMethodPopup = ({ open, onClose, onCreatePaymentMethod }: AddPaymentMethodPopupProps) => {
  const { t } = useTranslation();

  return (
    <Popup
      open={open}
      mainTitle={t('userSettingsPage.billing.addPaymentMethodPopup.addNewCard')}
      onClose={onClose}
      mainButtonsMarginAbsence
    >
      <Box display="flex" justifyContent="center">
        <CreditCardStripeContainer onCreatePaymentMethod={onCreatePaymentMethod} />
      </Box>
    </Popup>
  );
};

export default AddPaymentMethodPopup;
