import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, capitalize, IconButton, Table, TableCell, TableRow } from '@mui/material';
import moment from 'moment';

import Iconography from 'src/components/Iconography';
import AuditLogDetailsPopup from 'src/components/Popups/AuditLogDetailsPopup';
import SkeletonAuditLogRow from 'src/components/Skeleton/SkeletonAuditLogRow';
import { AUDIT_LOG_TABLE_COLUMNS } from 'src/constants';
import { AuditLog } from 'src/utils/gql';

import { ActionTypeLabel, EntityName } from '../types';

import ActionIconTableCell from './ActionIconTableCell';
import DescriptionTableCell from './DescriptionTableCell';
import { StyledIconButton, StyledTableBody, StyledTableContainer, StyledTableHead } from './styled';

interface AuditLogTableProps {
  data?: AuditLog[];
  loading: boolean;
}

const initialPopupState = {
  isOpen: false,
  data: {} as AuditLog,
};

type Order = 'asc' | 'desc';

const AuditLogTable = ({ data, loading }: AuditLogTableProps) => {
  const { t } = useTranslation();

  const [popupState, setPopupState] = useState<{ isOpen: boolean; data: AuditLog }>(initialPopupState);
  const [order, setOrder] = useState<Order>('desc');

  const tableData = useMemo(
    () => data?.slice().sort((a, b) => (order === 'asc' ? a.id - b.id : b.id - a.id)),
    [data, order],
  );

  const handleOpenPopup = (data: AuditLog) => {
    setPopupState({
      isOpen: true,
      data,
    });
  };

  const sortByDate = (action: Order) => {
    setOrder(action);
  };

  return (
    <>
      <StyledTableContainer>
        <Table>
          {loading ? (
            <>
              <StyledTableHead>
                <SkeletonAuditLogRow cardsCount={1} />
              </StyledTableHead>

              <StyledTableBody>
                <SkeletonAuditLogRow cardsCount={6} />
              </StyledTableBody>
            </>
          ) : (
            <>
              <StyledTableHead>
                <TableRow>
                  {AUDIT_LOG_TABLE_COLUMNS.map(({ label, sortable }, idx) => (
                    <TableCell key={idx}>
                      <Box display="flex" alignItems="center">
                        {label && t(label)}

                        {sortable && (
                          <Box
                            display="flex"
                            height="20px"
                            justifyContent="space-between"
                            flexDirection="column"
                            ml="11px"
                          >
                            <StyledIconButton onClick={() => sortByDate('asc')}>
                              <Iconography iconName="unfold-arrow-up" />
                            </StyledIconButton>

                            <StyledIconButton onClick={() => sortByDate('desc')}>
                              <Iconography iconName="unfold-arrow-down" />
                            </StyledIconButton>
                          </Box>
                        )}
                      </Box>
                    </TableCell>
                  ))}
                </TableRow>
              </StyledTableHead>

              <StyledTableBody>
                {tableData?.map((el) => (
                  <TableRow key={el.id}>
                    <ActionIconTableCell action={el.action} />

                    <TableCell>{moment(el.createdAt).format('MMM DD, YYYY H:mm')}</TableCell>

                    <TableCell>{capitalize(t(ActionTypeLabel[el.action as keyof typeof ActionTypeLabel]))}</TableCell>

                    <DescriptionTableCell data={el} />

                    <TableCell>{capitalize(t(EntityName[el.entity as keyof typeof EntityName]))}</TableCell>

                    <TableCell align="center">
                      <IconButton size="small" color="primary" onClick={() => handleOpenPopup(el)}>
                        <Iconography iconName="dots" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </StyledTableBody>
            </>
          )}
        </Table>
      </StyledTableContainer>

      <AuditLogDetailsPopup state={popupState} onClose={() => setPopupState(initialPopupState)} />
    </>
  );
};

export default AuditLogTable;
