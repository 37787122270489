import React, { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useLazyQuery } from '@apollo/client';
import { Typography } from '@material-ui/core';

import {
  CATALOGS_PATH,
  CREATION,
  EDITING,
  FIRST_DASHBOARD_PATHNAME,
  PRODUCT_ITEMS_PATH,
  PRODUCT_TYPES_PATH,
} from 'src/constants/routeSources';
import { useLimitReached } from 'src/providers/limitReached';
import { MediaStateContext } from 'src/providers/MediaProvider/context';
import useOnboarding from 'src/providers/OnboardingProvider/hooks/useOnboarding';
import { checkIsLimitReached, LimitType } from 'src/utils/gql';

import Popup from '../Popup';

import { ItemDefinitionPopupBody } from './styled';

interface ItemDefinitionSavePopupProps {
  open: boolean;
  onClose: () => void;
  catalogId: string;
  productTypeId: string;
  productItemId?: string;
  customOnCreateMoreHandler?: () => void;
}

const ItemDefinitionSavePopup = ({
  open,
  onClose,
  catalogId,
  productTypeId,
  productItemId,
}: ItemDefinitionSavePopupProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const limitReached = useLimitReached();
  const {
    onboardingState: { tourActive },
    goNext,
  } = useOnboarding();

  const { reset } = useFormContext();
  const { setMediaState } = useContext(MediaStateContext);

  const [checkLimits, { loading }] = useLazyQuery(checkIsLimitReached, {
    fetchPolicy: 'network-only',
    variables: { type: LimitType.Items },
    onCompleted: (limitReachedData) => {
      if (limitReachedData?.checkIsLimitReached) {
        limitReached({ limitReachedType: LimitType.Items });

        history.push(
          `/${FIRST_DASHBOARD_PATHNAME}/${CATALOGS_PATH}/${catalogId}/${PRODUCT_TYPES_PATH}/${productTypeId}/${PRODUCT_ITEMS_PATH}`,
        );
      } else {
        reset();
        onClose();
        history.push(
          `/${FIRST_DASHBOARD_PATHNAME}/${CATALOGS_PATH}/${catalogId}/${PRODUCT_TYPES_PATH}/${productTypeId}/${CREATION}`,
        );
      }
    },
  });

  const handleClose = () => {
    onClose();
    history.push(
      `/${FIRST_DASHBOARD_PATHNAME}/${CATALOGS_PATH}/${catalogId}/${PRODUCT_TYPES_PATH}/${productTypeId}/${PRODUCT_ITEMS_PATH}${
        productItemId ? `/${productItemId}/${EDITING}` : ''
      }`,
    );
  };

  const handleViewItems = () => {
    reset();
    setMediaState([]);

    history.push(
      `/${FIRST_DASHBOARD_PATHNAME}/${CATALOGS_PATH}/${catalogId}/${PRODUCT_TYPES_PATH}/${productTypeId}/${PRODUCT_ITEMS_PATH}`,
    );

    if (tourActive) {
      setTimeout(() => {
        goNext();

        window.history.pushState({}, '', history.location.pathname);
      }, 0);
    }

    return false;
  };

  const handleCreateMore = () => {
    checkLimits();
    setMediaState([]);

    return false;
  };

  return (
    <Popup
      data-testid="itemDefinitionSavePopup"
      mainButtonText={t('productItemCreateEdit.popupSaveNewItem.mainButton')}
      open={open}
      secondaryButtonText={t('productItemCreateEdit.popupSaveNewItem.secondaryButton')}
      mainTitle={[t('productItemCreateEdit.popupSaveNewItem.title')]}
      onClose={handleClose}
      onSecondaryButtonClick={handleViewItems}
      onMainButtonClick={handleCreateMore}
      loadingOnMainButton={loading}
    >
      <ItemDefinitionPopupBody>
        <Typography color="text.secondary" variant="body1">
          {t('productItemCreateEdit.popupSaveNewItem.text')}
        </Typography>
      </ItemDefinitionPopupBody>
    </Popup>
  );
};

export default ItemDefinitionSavePopup;
