import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Checkbox } from '@material-ui/core';
import { capitalize } from 'validate.js';

import Button from 'src/components/Button';
import SearchField from 'src/components/SearchField';
import { StyledResetButton } from 'src/components/styled/SearchFieldStyles';

import {
  KeywordsWrapper,
  StyledKeywordItemTypography,
  StyledKeywordsListHeaderContainer,
  StyledKeywordsNotFoundTypography,
  StyledKeywordsTypography,
  StyledTitleTypography,
} from './KeywordsSidebarStyles';

interface KeywordsSidebarProps {
  handleResetKeywords?: () => void;
  searchValue: string;
  handleClickKeyword?: (keyword: string) => void;
  handleChangeSearch?: (value: string) => void;
  handleSave: () => void;
  handleClose: () => void;
  keywords?: string[];
  activeKeywords?: string[];
  open: boolean;
}

const KeywordsSidebar = ({
  open,
  keywords,
  handleSave,
  handleClose,
  searchValue,
  activeKeywords,
  handleChangeSearch,
  handleResetKeywords,
  handleClickKeyword,
}: KeywordsSidebarProps) => {
  const { t } = useTranslation();

  const onChangeSearchInput = ({ currentTarget: { value } }: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
    handleChangeSearch?.(value);

  return open ? (
    <KeywordsWrapper top="0">
      <Box sx={{ overflowX: 'hidden', overflowY: 'auto' }}>
        <StyledTitleTypography>{t('sharedCatalogsPage.keywordsList')}</StyledTitleTypography>

        <Box mr="54px" mt="30px" ml="30px">
          <SearchField
            value={searchValue}
            onChange={onChangeSearchInput}
            label={t('sharedCatalogsPage.searchKeywords')}
            searchPage
            variant="outlined"
            testId="searchFieldKeywordsSidebar"
          />
        </Box>

        <StyledKeywordsListHeaderContainer>
          <StyledKeywordsTypography>{t('sharedCatalogsPage.keywords')}</StyledKeywordsTypography>

          <StyledResetButton onClick={handleResetKeywords} data-testid="resetButtonKeywordsSidebar">
            {t('sharedCatalogsPage.reset')}
          </StyledResetButton>
        </StyledKeywordsListHeaderContainer>

        <Box justifyContent="center" mt="18px" ml="30px" mr="54px">
          {keywords?.map((keyword, id) => (
            <Box width="467px" display="flex" justifyContent="space-between" alignItems="center" mb="15px" key={id}>
              <StyledKeywordItemTypography>{`#${capitalize(keyword)}`}</StyledKeywordItemTypography>

              <Checkbox
                checked={activeKeywords?.includes(keyword)}
                onChange={() => handleClickKeyword?.(keyword)}
                inputProps={{ 'aria-label': 'checkbox' }}
                data-testid={`checkboxKeywordsList${id}`}
              />
            </Box>
          ))}
        </Box>

        {!keywords?.length && (
          <Box mt="45px" display="flex" justifyContent="center">
            <StyledKeywordsNotFoundTypography>
              {t('sharedCatalogsPage.keywordsNotFound')}
            </StyledKeywordsNotFoundTypography>
          </Box>
        )}
      </Box>

      <Box width="552px" height="92px">
        <Box mb="40px" mt="10px" ml="30px" display="flex">
          <Box mr="15px">
            <Button variant="outlined" onClick={handleClose} data-testid="backButtonKeywordsSidebar">
              {t('sharedCatalogsPage.back')}
            </Button>
          </Box>

          <Button variant="contained" onClick={handleSave} data-testid="saveButtonKeywordsSidebar">
            {t('sharedCatalogsPage.save')}
          </Button>
        </Box>
      </Box>
    </KeywordsWrapper>
  ) : null;
};

export default KeywordsSidebar;
