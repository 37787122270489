import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Card, CardContent, Typography, useTheme } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Cancel, Check, Replay } from '@material-ui/icons';
import clsx from 'clsx';

import CircularProgressButton from 'src/components/CircularProgress';
import Hint from 'src/components/Hint';
import arrowDown from 'src/components/Icon/arrow-down.svg';
import csv from 'src/components/Icon/format-csv.svg';
import Icon from 'src/components/Icon/Icon';
import underscore from 'src/components/Icon/underscore.svg';
import CancelCSVUploadPopup from 'src/components/Popups/CancelCSVUploadPopup';
import ProgressBar from 'src/components/ProgressBar';

import { PARSING_FILE_STATE } from '../../constants';
import { CsvFileInfo } from '../../hooks/useImportCsv';
import { CardIcon } from '../../views/Catalogs/ProductTypes/styled';
import Iconography from '../Iconography';

const useStyles = makeStyles((theme: Theme) => ({
  '@keyframes arrowDown': {
    '0%': {
      opacity: 0,
      transform: ' translateY(-15px)',
    },
    '50%': {
      opacity: 1,
    },
    '100%': {
      opacity: 0,
      transform: 'translateY(-5px)',
    },
  },
  root: {
    '& .MuiPaper-root': {
      background: theme.palette.common.white,
      height: '90px',
      borderRadius: '20px',
      padding: '20px 30px 20px 25px',
      position: 'relative',
    },
    '& .MuiCardContent-root': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  defaultfileName: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '300px',
  },
  fileNameInProcess: {
    maxWidth: '280px',
  },
  fileNameWithError: {
    maxWidth: '270px',
  },
  delete: {
    marginLeft: '18px',
    display: 'flex',
    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.main,
      width: '18px',
      height: '18px',
    },
  },
  parsingError: {
    display: 'flex',
    '& .MuiSvgIcon-root': {
      color: theme.palette.secondary.dark,
    },
  },
  uploadError: {
    display: 'flex',
    '& svg:first-child': {
      marginRight: '12px',
    },
    '& .MuiSvgIcon-root': {
      color: theme.palette.error.main,
    },
  },
  arrowDown: {
    animation: '$arrowDown 1s ease-in-out infinite',
  },
  trashIcon: {
    color: theme.palette.primary.main,
  },
  iconGroup: {
    display: 'flex',
    alignItems: 'center',
  },
  cardInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    paddingLeft: '10px',
  },
  checkIcon: {
    '& svg': {
      color: theme.palette.secondary.dark,
    },
  },
}));

interface ImportFromCsvCardProps {
  fileInfo: CsvFileInfo;
  onReplayIconClick: () => void;
  onCancelIconClick: () => void;
  onCardClick: () => void;
}

const ImportFromCsvCard = ({
  fileInfo: { name, parsingError, state, uploadPercentage, parsedProgressData, errorMessage },
  onReplayIconClick,
  onCancelIconClick,
  onCardClick,
}: ImportFromCsvCardProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();

  const [isCancelUploadPopupOpen, setIsCancelUploadPopupOpen] = useState(false);
  const handleOpenPopup = () => setIsCancelUploadPopupOpen(true);

  const FILE_EXTENSION = '.csv';

  const findFileNameWithoutExtension = (fileName: string) => {
    const fileExtPos = fileName.lastIndexOf('.');
    return fileName.slice(0, fileExtPos);
  };

  const progress = useMemo(
    () =>
      state === PARSING_FILE_STATE.Parsing
        ? parsedProgressData
          ? ((parsedProgressData.parsed + parsedProgressData.parseFailed) * 100) / parsedProgressData.inParsing
          : 0
        : uploadPercentage,
    [uploadPercentage, parsedProgressData, state],
  );

  const handleCardClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (![PARSING_FILE_STATE.Uploading, PARSING_FILE_STATE.Uploaded, PARSING_FILE_STATE.ParsingError].includes(state)) {
      onCardClick();
    }
  };

  const nothingParsed = parsedProgressData?.parsed === 0;
  const parsingItemFailed = !!parsedProgressData?.parseFailed;
  const showInfoIcon = !!parsingError || (parsingItemFailed && state === PARSING_FILE_STATE.Parsed);
  const showCheckIcon = state === PARSING_FILE_STATE.Parsed && !parsingItemFailed;
  const isFileProcessing = [PARSING_FILE_STATE.Parsing, PARSING_FILE_STATE.Uploading].includes(state);

  return (
    <Box className={classes.root} onClick={handleCardClick}>
      <Card>
        <CardContent>
          <Box>
            <img src={csv} alt="csv" width="50" height="50" />
          </Box>
          <Box className={classes.cardInfoContainer}>
            <Box display="flex" justifyContent="space-between" pb={isFileProcessing ? '10px' : '0'}>
              <Box display="flex">
                <Typography
                  className={clsx(
                    classes.defaultfileName,
                    (showInfoIcon || state === PARSING_FILE_STATE.UploadingError) && classes.fileNameWithError,
                    isFileProcessing && classes.fileNameInProcess,
                  )}
                  variant="subtitle2"
                  color="text.secondary"
                >
                  {findFileNameWithoutExtension(name)}
                </Typography>
                <Typography variant="subtitle2" color="text.secondary">
                  {FILE_EXTENSION}
                </Typography>
              </Box>
              {isFileProcessing && (
                <Typography variant="caption" fontSize="14px" lineHeight="21px" ml="10px">
                  {progress !== undefined && `${Math.round(progress)}%`}
                </Typography>
              )}
            </Box>
            {isFileProcessing && <ProgressBar value={progress} />}
          </Box>
          <Box ml="10px">
            {state === PARSING_FILE_STATE.Uploading && (
              <Box>
                <CircularProgressButton value={uploadPercentage} onProgressButtonClick={handleOpenPopup} />
              </Box>
            )}
            {state === PARSING_FILE_STATE.Parsing && (
              <Box display="flex" flexDirection="column" alignItems="center" mt="15px">
                <img className={classes.arrowDown} src={arrowDown} alt="arrow" />
                <img src={underscore} alt="underscore" />
              </Box>
            )}

            {state === PARSING_FILE_STATE.UploadingError && (
              <Box className={classes.uploadError}>
                <Hint
                  title={`${t('importFromCsvPage.uploadErrorImportFromCsvFileCardText')}`}
                  placement="top"
                  type="hover"
                >
                  <Replay onClick={onReplayIconClick} />
                </Hint>
                <Cancel onClick={handleOpenPopup} />
              </Box>
            )}

            {showInfoIcon && (
              <Box className={classes.iconGroup}>
                {parsingError && (
                  <CardIcon className={classes.trashIcon} onClick={handleOpenPopup}>
                    <Iconography iconName="trash-fill" />
                  </CardIcon>
                )}
                <Box className={classes.parsingError}>
                  <Hint
                    title={
                      parsingItemFailed && !nothingParsed
                        ? `${t('importFromCsvPage.parsingItemsErrorImportFromCsvFileCardText')} ${errorMessage}`
                        : `${t('importFromCsvPage.parsingErrorImportFromCsvFileCardText')} ${parsingError}`
                    }
                    placement="top"
                    type="hover"
                  >
                    <Box display="flex">
                      <Iconography iconName="info-fill" htmlColor={theme.palette.secondary.dark} />
                    </Box>
                  </Hint>
                </Box>
              </Box>
            )}

            {showCheckIcon && (
              <Icon className={classes.checkIcon}>
                <Check />
              </Icon>
            )}
          </Box>
        </CardContent>
      </Card>

      <CancelCSVUploadPopup
        isOpen={isCancelUploadPopupOpen}
        onMainButtonClick={onCancelIconClick}
        setIsModalOpen={setIsCancelUploadPopupOpen}
      />
    </Box>
  );
};

export default ImportFromCsvCard;
