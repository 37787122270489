import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useMutation } from '@apollo/client';

import Loader from 'src/components/Loader';
import { FACEBOOK_BROADCAST_CHANEL_NAME, FacebookEvent, FacebookHashParams } from 'src/constants';
import { useSnackbar } from 'src/providers/snackbar';
import {
  Mutation,
  MutationUpdateFacebookAccessTokenArgs,
  updateFacebookToken as updateFacebookTokenQuery,
} from 'src/utils/gql';

import { getFacebookValueFromHash } from './utils';

const FacebookReAuth = () => {
  const { hash } = useLocation();
  const snackbar = useSnackbar();

  const accessToken = getFacebookValueFromHash(hash, FacebookHashParams.AccessToken);
  const state = getFacebookValueFromHash(hash, FacebookHashParams.State);

  const [updateFacebookAuthToken] = useMutation<
    Pick<Mutation, 'updateFacebookAccessToken'>,
    MutationUpdateFacebookAccessTokenArgs
  >(updateFacebookTokenQuery);

  useEffect(() => {
    const businessId = state?.businessId;

    if (businessId && accessToken) {
      updateFacebookAuthToken({
        variables: {
          businessId,
          shortLivedAccessToken: accessToken,
        },
      })
        .then(() => {
          const bc = new BroadcastChannel(FACEBOOK_BROADCAST_CHANEL_NAME);
          bc.postMessage(FacebookEvent.TokenUpdated);
          bc.close();
        })
        .finally(close);
    }
  }, [accessToken, snackbar, state?.businessId, updateFacebookAuthToken]);

  return <Loader fullScreen />;
};

export default FacebookReAuth;
