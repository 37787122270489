import React, { SyntheticEvent, KeyboardEvent } from 'react';

import { theme } from 'src/theme';

import ErrorableSyncButtonNormal from './ErrorableSyncButtonNormal';
import ErrorableSyncButtonOrange, { OrangeIconParams } from './ErrorableSyncButtonOrange';

export enum ErrorableSyncButtonState {
  'ERROR',
  'NORMAL',
  'DISABLE',
}

export enum ErrorableSyncButtonVariants {
  'NORMAL',
  'ORANGE',
}

enum KeyboardKeyNames {
  ENTER = 'Enter',
}

export interface ErrorableSyncButtonParams {
  variant?: ErrorableSyncButtonVariants;
  width?: number;
  height?: number;
  orangeIconParams?: OrangeIconParams;
}

interface ErrorableSyncButtonChildProps {
  state?: ErrorableSyncButtonState;
  iconParams?: ErrorableSyncButtonParams;
  onClick?: (e: SyntheticEvent) => void;
  dataTestId?: string;
  isDisabledDueToErrors?: boolean;
}

const getFillColor = (state?: ErrorableSyncButtonState, variant?: ErrorableSyncButtonVariants) => {
  switch (state) {
    case ErrorableSyncButtonState.ERROR:
      return theme.palette.error.main;
    case ErrorableSyncButtonState.DISABLE:
      return theme.palette.action.disabled;
    default:
      return variant === ErrorableSyncButtonVariants.ORANGE
        ? theme.palette.secondary.dark
        : theme.palette.primary.light;
  }
};

const ErrorableSyncButtonChild = ({
  state,
  iconParams,
  onClick,
  dataTestId,
  isDisabledDueToErrors = false,
}: ErrorableSyncButtonChildProps) => {
  const fillColor = getFillColor(state, iconParams?.variant);
  const handleSyncIconPress = (event: KeyboardEvent<HTMLElement>) => {
    if (event.key === KeyboardKeyNames.ENTER) {
      onClick?.(event);
    }
  };

  return iconParams?.variant === ErrorableSyncButtonVariants.ORANGE ? (
    <ErrorableSyncButtonOrange
      onClick={onClick}
      onKeyboardButtonPressed={handleSyncIconPress}
      disabled={state === ErrorableSyncButtonState.DISABLE || isDisabledDueToErrors}
      dataTestId={dataTestId}
      fillColor={fillColor}
      orangeIconParams={iconParams?.orangeIconParams}
    />
  ) : (
    <ErrorableSyncButtonNormal
      onClick={onClick}
      onKeyboardButtonPressed={handleSyncIconPress}
      disabled={state === ErrorableSyncButtonState.DISABLE || isDisabledDueToErrors}
      dataTestId={dataTestId}
      fillColor={fillColor}
      width={iconParams?.width}
      height={iconParams?.height}
    />
  );
};

export default ErrorableSyncButtonChild;
