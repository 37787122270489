import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';

import Hint from './Hint';

const BetaLabelWrapper = styled(Box)`
  display: flex;
  min-width: 45px;
  height: 22px;
  background: ${({ theme: { palette } }) => palette.common.white};
  box-shadow: 0 2px 10px ${({ theme: { palette } }) => palette.error.dark};
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  z-index: 1;
  min-width: 45px;
  margin-right: 16px;
`;

const BetaText = styled(Typography)`
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme: { palette } }) => palette.secondary.dark};
`;

const BetaLabel = () => {
  const { t } = useTranslation();

  return (
    <BetaLabelWrapper>
      <Hint type="hover" title={t('ebay.betaHint') as string} arrow placement="top" tooltipWidth="344px">
        <BetaText>{t('beta')}</BetaText>
      </Hint>
    </BetaLabelWrapper>
  );
};

export default BetaLabel;
