import { Button, Typography } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';

export const GoogleBtn = styled(Button)(({ theme }) => ({
  height: '48px',
  borderRadius: '50px',
  border: `1px solid ${theme.palette.action.disabledBackground}`,
  cursor: 'pointer',

  '&:hover': {
    boxShadow: `box-shadow: 0px 0px 15px 0px ${theme.palette.success.contrastText}`,
    border: `1px solid ${theme.palette.secondary.dark}`,
  },

  '&:active, &:focus': {
    border: `1px solid ${theme.palette.secondary.dark}`,
  },

  '&:disabled': {
    backgroundColor: theme.palette.background.default,
  },

  '& > span': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
  },

  '&:disabled span p': {
    color: theme.palette.action.disabled,
  },
}));

export const GoogleBtnText = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  color: theme.palette.text.secondary,
}));
