import { Box, Typography } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';

export const TableWrapper = styled(Box)`
  margin: 30px 54px 0 0;
`;

export const KeywordsWrapper = styled(Box)`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  scrollbar-width: thin;
`;

export const KeywordsMultiSelectWrapper = styled(Box)`
  margin-top: 27px;
  margin-right: 56px;

  .MuiFormHelperText-root {
    position: initial;
  }
`;

export const KeywordTitleTypography = styled(Typography)`
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  margin-right: 15px;

  color: ${({ theme: { palette } }) => palette.primary.light};
`;

export const ClearAllTypography = styled(Typography)`
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-align: right;

  color: ${({ theme: { palette } }) => palette.text.disabled};
`;

export const LoaderWrapper = styled(Box)`
  position: absolute;
  top: calc(50% - 15px);
  left: calc(50% - 15px);
`;

export const CatalogSettingsLoaderWrapper = styled(Box)`
  width: 89%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BoxContainer = styled(Box)`
  overflow: auto;
  padding-left: 8px;
  display: flex;
  flex-direction: column;
`;
