import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, capitalize, ImageListItem, Typography } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import Avatar from 'src/components/Avatar';
import { Scalars } from 'src/utils/gql';
import separateStringByCapitalLetters from 'src/utils/separateStringByCapitalLetters';
import { actionInPerfect } from 'src/views/AuditLog/AuditLogTable/DescriptionTableCell';
import {
  ActionType,
  CatalogParsedDiff,
  CatalogParsedField,
  EntityName,
  IntegrationParsedDiff,
  MetadataDiff,
  MetadataField,
  ProductItemParsedDiff,
  ProductItemParsedField,
  ProductItemValueType,
  ProductTypeParsedDiff,
  ProductTypeParsedField,
} from 'src/views/AuditLog/types';

import { IntegrationDetailedInfo } from './IntegrationDetailedInfo';
import { DetailWrapper, StyledImageList } from './styled';

interface Props {
  metadata: Scalars['JSONObject'];
  action: string;
}

export const UserDetails = ({ metadata }: Props) => {
  return metadata?.diffs?.map(({ propertyName, before, after }: MetadataDiff, idx: number) => {
    const renderValue = (value: string) => (value.includes(':') ? value.split(':')[0] : value);

    return (
      <DetailWrapper key={idx} alignItems="center">
        <Typography mr="10px" fontWeight={500}>
          {separateStringByCapitalLetters(capitalize(propertyName))}:
        </Typography>
        {propertyName === 'avatar' ? (
          <Avatar size="large" src={before === 'null' ? '' : before} alt="avatar-before" />
        ) : (
          <Typography>{renderValue(before)}</Typography>
        )}
        <Typography mx="10px">{'>>>'}</Typography>
        {propertyName === 'avatar' ? (
          <Avatar size="large" src={after} alt="avatar-after" />
        ) : (
          <Typography>{renderValue(after)}</Typography>
        )}
      </DetailWrapper>
    );
  });
};

export const CatalogDetails = ({ metadata }: Props) => {
  const { t } = useTranslation();
  const isUpdateEvent = !!metadata?.diffs?.length;

  const parsedDiffs = metadata?.diffs?.map(({ propertyName, before, after }: MetadataDiff) => {
    let parsedBefore;
    let parsedAfter;

    try {
      parsedBefore = JSON.parse(before);
    } catch (error) {
      parsedBefore = before;
    }

    try {
      parsedAfter = JSON.parse(after);
    } catch (error) {
      parsedAfter = after;
    }
    return {
      propertyName,
      before: parsedBefore ?? 0,
      after: parsedAfter ?? 0,
    };
  });

  const parsedFields = metadata?.fields?.map(({ propertyName, propertyValue }: MetadataField) => {
    let parsedPropertyValue;

    try {
      parsedPropertyValue = JSON.parse(propertyValue);
    } catch (error) {
      parsedPropertyValue = propertyValue;
    }

    return {
      propertyName,
      propertyValue:
        !parsedPropertyValue || (typeof parsedPropertyValue === 'object' && !parsedPropertyValue.length)
          ? '0'
          : parsedPropertyValue,
    };
  });

  return (
    <Box>
      {isUpdateEvent ? (
        <>
          {parsedDiffs.map(({ propertyName, before, after }: CatalogParsedDiff, idx: number) => (
            <DetailWrapper key={idx} alignItems="center">
              <Typography fontWeight={500} mr="10px">
                {separateStringByCapitalLetters(capitalize(propertyName))}:
              </Typography>

              <IntegrationDetailedInfo changes={before} text={t('auditLogPage.added')} />

              <Typography mx="10px">{'>>>'}</Typography>

              <IntegrationDetailedInfo changes={after} text={t('auditLogPage.removed')} />
            </DetailWrapper>
          ))}
        </>
      ) : (
        <>
          {parsedFields.map(({ propertyName, propertyValue }: CatalogParsedField, idx: number) => (
            <DetailWrapper key={idx}>
              <Typography fontWeight={500} mr="10px">
                {separateStringByCapitalLetters(capitalize(propertyName))}:
              </Typography>

              {typeof propertyValue === 'object' && (
                <Box>
                  {propertyValue?.map((value, idx) => (
                    <Typography key={idx} whiteSpace="nowrap">
                      {propertyName === 'integrations' ? value.settings.integrationName : value}
                    </Typography>
                  ))}
                </Box>
              )}

              {(typeof propertyValue === 'string' || typeof propertyValue === 'number') && (
                <Typography>{propertyValue}</Typography>
              )}
            </DetailWrapper>
          ))}
        </>
      )}
    </Box>
  );
};

export const CatalogImportExportDetails = ({ metadata, action }: Props) => {
  const { t } = useTranslation();
  const { itemsCount, integration, catalog, dataSyncProviderName, dataSyncType } = metadata;

  const parsedIntegration = JSON.parse(integration);
  const parsedCatalog = JSON.parse(catalog);

  const entityString = t(EntityName.catalog);

  const renderActionString = () => {
    return (
      <Typography color="text.secondary" fontWeight={500}>
        {t('auditLogPage.detailedImportExportDescription', {
          itemsCount,
          perfectAction: t(actionInPerfect(action)),
          ...(action === ActionType.Import
            ? {
                sourceName: dataSyncProviderName,
                sourceType: dataSyncType,
                destinationName: parsedCatalog?.name,
                destinationType: entityString,
              }
            : {
                sourceName: parsedCatalog?.name,
                sourceType: entityString,
                destinationName: dataSyncProviderName,
                destinationType: dataSyncType,
              }),
        })}
      </Typography>
    );
  };

  return (
    <Box>
      {renderActionString()}

      {metadata.status && (
        <DetailWrapper key={uuidv4()} mt="5px">
          <Typography fontWeight={500} mr="5px">
            {t('auditLogPage.detailsPopup.status')}:
          </Typography>

          <Typography>{capitalize(metadata.status)}</Typography>
        </DetailWrapper>
      )}

      <Typography color="text.secondary" fontWeight={500} mt="5px">
        {capitalize(t('auditLogPage.catalog'))}:
      </Typography>

      <Box ml="25px">
        {Object.entries(parsedCatalog).map(([key, value], idx) => (
          <>
            {key === 'name' && (
              <DetailWrapper key={idx}>
                <Typography fontWeight={500} mr="5px">
                  {separateStringByCapitalLetters(capitalize(key))}:
                </Typography>

                <Typography>{String(value)}</Typography>
              </DetailWrapper>
            )}

            {key === 'source' && (
              <DetailWrapper key={idx}>
                <Typography fontWeight={500} mr="5px">
                  {separateStringByCapitalLetters(capitalize(key))}:
                </Typography>

                <Typography>{capitalize(String(value))}</Typography>
              </DetailWrapper>
            )}
          </>
        ))}
      </Box>

      <Typography color="text.secondary" fontWeight={500} mt="5px">
        {capitalize(t('auditLogPage.integration'))}:
      </Typography>

      <Box ml="25px">
        {Object.entries(parsedIntegration).map(([key, value], idx) => (
          <>
            {key === 'type' && (
              <DetailWrapper key={idx}>
                <Typography fontWeight={500} mr="5px">
                  {separateStringByCapitalLetters(capitalize(key))}:
                </Typography>

                <Typography>{capitalize(String(value))}</Typography>
              </DetailWrapper>
            )}

            {key === 'isDefault' && (
              <DetailWrapper key={idx}>
                <Typography fontWeight={500} mr="5px">
                  {separateStringByCapitalLetters(capitalize(key))}:
                </Typography>

                <Typography>{value ? t('auditLogPage.yes') : t('auditLogPage.no')}</Typography>
              </DetailWrapper>
            )}

            {key === 'settings' && (
              <>
                {Object.entries(parsedIntegration.settings).map(([key, value], idx) => (
                  <>
                    {key !== 'accessToken' && (
                      <DetailWrapper key={idx}>
                        <Typography fontWeight={500} mr="5px">
                          {separateStringByCapitalLetters(capitalize(key))}:
                        </Typography>

                        <Typography>{String(value)}</Typography>
                      </DetailWrapper>
                    )}
                  </>
                ))}
              </>
            )}
          </>
        ))}
      </Box>
    </Box>
  );
};

export const ProductTypeDetails = ({ metadata }: Props) => {
  const { t } = useTranslation();
  const isUpdateEvent = !!metadata?.diffs?.length;

  const parsedDiffs = metadata?.diffs?.map(({ propertyName, before, after }: MetadataDiff) => {
    let parsedBefore;
    let parsedAfter;

    try {
      parsedBefore = JSON.parse(before);
    } catch (error) {
      parsedBefore = before;
    }

    try {
      parsedAfter = JSON.parse(after);
    } catch (error) {
      parsedAfter = after;
    }

    const fieldName = parsedBefore?.name || parsedAfter?.name;

    return {
      propertyName,
      fieldName,
      before: parsedBefore,
      after: parsedAfter,
    };
  });

  const parsedFields = metadata?.fields?.map(({ propertyName, propertyValue }: MetadataField) => {
    let parsedPropertyValue;

    try {
      parsedPropertyValue = JSON.parse(propertyValue);
    } catch (error) {
      parsedPropertyValue = propertyValue;
    }

    return { propertyName, propertyValue: parsedPropertyValue };
  });

  return (
    <Box>
      {isUpdateEvent ? (
        <>
          {parsedDiffs.map(({ propertyName, fieldName, before, after }: ProductTypeParsedDiff, idx: number) => {
            return (
              <Fragment key={idx}>
                {(before && typeof before === 'object') || (after && typeof after === 'object') ? (
                  <DetailWrapper alignItems="center">
                    <Typography whiteSpace="nowrap" fontWeight={500} mr="10px">
                      {separateStringByCapitalLetters(capitalize(fieldName))}:
                    </Typography>

                    <Box>
                      {before ? (
                        <>
                          {Object.entries(before).map(([key, value]) => (
                            <DetailWrapper key={`before_${propertyName}_${key}`}>
                              <Typography mr="10px">{separateStringByCapitalLetters(capitalize(key))}:</Typography>

                              <Typography>{String(value)}</Typography>
                            </DetailWrapper>
                          ))}
                        </>
                      ) : (
                        <Box>
                          <Typography>{t('auditLogPage.added')}</Typography>
                        </Box>
                      )}
                    </Box>

                    <Typography mx="10px">{'>>>'}</Typography>

                    <Box>
                      {after ? (
                        <>
                          {Object.entries(after).map(([key, value]) => (
                            <DetailWrapper key={`after_${propertyName}_${key}`}>
                              <Typography mr="10px">{separateStringByCapitalLetters(capitalize(key))}:</Typography>

                              <Typography>{String(value)}</Typography>
                            </DetailWrapper>
                          ))}
                        </>
                      ) : (
                        <Box>
                          <Typography>{t('auditLogPage.removed')}</Typography>
                        </Box>
                      )}
                    </Box>
                  </DetailWrapper>
                ) : (
                  <DetailWrapper key={idx}>
                    <Typography fontWeight={500} mr="10px">
                      {separateStringByCapitalLetters(capitalize(propertyName))}:
                    </Typography>

                    <Typography whiteSpace="nowrap">{`${before}`}</Typography>

                    <Typography mx="10px">{'>>>'}</Typography>

                    <Typography whiteSpace="nowrap">{`${after}`}</Typography>
                  </DetailWrapper>
                )}
              </Fragment>
            );
          })}
        </>
      ) : (
        <>
          <DetailWrapper>
            <Typography fontWeight={500} mr="10px">
              {capitalize(t('auditLogPage.name'))}:
            </Typography>

            <Typography>{metadata.name}</Typography>
          </DetailWrapper>

          <DetailWrapper>
            <Typography fontWeight={500} mr="10px">
              {capitalize(t('auditLogPage.fields'))}:
            </Typography>

            <Box>
              {parsedFields.map(({ propertyName, propertyValue }: ProductTypeParsedField, idx: number) => (
                <Typography key={idx}>{propertyName !== 'name' && propertyValue.name}</Typography>
              ))}
            </Box>
          </DetailWrapper>
        </>
      )}
    </Box>
  );
};

export const ProductItemDetails = ({ metadata }: Props) => {
  const { t } = useTranslation();
  const isUpdateEvent = !!metadata?.diffs?.length;

  const parsedDiffs = metadata?.diffs?.map(({ propertyName, before, after }: MetadataDiff) => {
    let parsedBefore: ProductItemValueType;
    let parsedAfter: ProductItemValueType;

    try {
      parsedBefore = JSON.parse(before);
    } catch (error) {
      parsedBefore = before;
    }

    try {
      parsedAfter = JSON.parse(after);
    } catch (error) {
      parsedAfter = after;
    }

    return { propertyName, before: parsedBefore, after: parsedAfter };
  });

  const parsedFields = metadata?.fields?.map(({ propertyName, propertyValue }: MetadataField) => {
    let parsedPropertyValue;

    try {
      parsedPropertyValue = JSON.parse(propertyValue);
    } catch (error) {
      parsedPropertyValue = propertyValue;
    }

    return { propertyName, propertyValue: parsedPropertyValue };
  });

  return (
    <Box>
      {isUpdateEvent ? (
        <>
          {parsedDiffs
            .filter(({ propertyName }: ProductItemParsedDiff) => propertyName !== 'name')
            .map(({ propertyName, before, after }: ProductItemParsedDiff, idx: number) => (
              <DetailWrapper key={idx} alignItems="center">
                <Typography whiteSpace="nowrap" fontWeight={500} mr="10px">
                  {separateStringByCapitalLetters(capitalize(propertyName))}:
                </Typography>

                {before === null || (typeof before === 'object' && !before.length) ? (
                  <Typography>{t('auditLogPage.added')}</Typography>
                ) : (
                  <>
                    {typeof before === 'object' && propertyName !== 'Image' && (
                      <Box>
                        {before.map((value, idx) => (
                          <Typography key={idx} whiteSpace="nowrap">
                            {value}
                          </Typography>
                        ))}
                      </Box>
                    )}

                    {typeof before === 'object' && propertyName === 'Image' && (
                      <StyledImageList cols={3} rowHeight={50}>
                        {before.map((imageUrl, idx) => (
                          <ImageListItem key={idx}>
                            <img src={imageUrl} srcSet={imageUrl} alt={`${idx}`} loading="lazy" />
                          </ImageListItem>
                        ))}
                      </StyledImageList>
                    )}

                    {typeof before === 'boolean' && (
                      <Typography>{before ? t('auditLogPage.yes') : t('auditLogPage.no')}</Typography>
                    )}

                    {(typeof before === 'string' || typeof before === 'number') && (
                      <Typography>{propertyName === 'status' ? capitalize(String(before)) : before}</Typography>
                    )}
                  </>
                )}

                <Typography mx="10px">{'>>>'}</Typography>

                {after === null || (typeof after === 'object' && !after.length) ? (
                  <Typography>{t('auditLogPage.removed')}</Typography>
                ) : (
                  <>
                    {typeof after === 'object' && propertyName !== 'Image' && (
                      <Box>
                        {after.map((value, idx) => (
                          <Typography key={idx} whiteSpace="nowrap">
                            {value}
                          </Typography>
                        ))}
                      </Box>
                    )}

                    {typeof after === 'object' && propertyName === 'Image' && (
                      <StyledImageList cols={3} rowHeight={50}>
                        {after.map((imageUrl, idx) => (
                          <ImageListItem key={idx}>
                            <img src={imageUrl} srcSet={imageUrl} alt={`${idx}`} loading="lazy" />
                          </ImageListItem>
                        ))}
                      </StyledImageList>
                    )}

                    {typeof after === 'boolean' && (
                      <Typography>{after ? t('auditLogPage.yes') : t('auditLogPage.no')}</Typography>
                    )}

                    {(typeof after === 'string' || typeof after === 'number') && (
                      <Typography>{propertyName === 'status' ? capitalize(String(after)) : after}</Typography>
                    )}
                  </>
                )}
              </DetailWrapper>
            ))}
        </>
      ) : (
        <>
          {parsedFields
            .filter(({ propertyName }: ProductItemParsedField) => propertyName !== 'name')
            .map(({ propertyName, propertyValue }: ProductItemParsedField, idx: number) => (
              <DetailWrapper key={idx}>
                <Typography fontWeight={500} mr="10px">
                  {capitalize(propertyName)}:
                </Typography>

                {typeof propertyValue === 'object' && propertyName !== 'Image' && (
                  <Box>
                    {propertyValue.map((value, idx) => (
                      <Typography key={idx}>
                        {value}
                        &nbsp;
                      </Typography>
                    ))}
                  </Box>
                )}

                {typeof propertyValue === 'object' && propertyName === 'Image' && (
                  <StyledImageList cols={3} rowHeight={50}>
                    {propertyValue.map((imageUrl, idx) => (
                      <ImageListItem key={idx}>
                        <img src={imageUrl} srcSet={imageUrl} alt={`${idx}`} loading="lazy" />
                      </ImageListItem>
                    ))}
                  </StyledImageList>
                )}

                {typeof propertyValue === 'boolean' && (
                  <Typography>{propertyValue ? t('auditLogPage.yes') : t('auditLogPage.no')}</Typography>
                )}

                {(typeof propertyValue === 'string' || typeof propertyValue === 'number') && (
                  <Typography>
                    {propertyName === 'status' ? capitalize(String(propertyValue)) : propertyValue}
                  </Typography>
                )}
              </DetailWrapper>
            ))}
        </>
      )}
    </Box>
  );
};

export const IntegrationDetails = ({ metadata }: Props) => {
  const { t } = useTranslation();
  const isUpdateEvent = !!metadata?.diffs?.length;

  const parsedDiffs = metadata?.diffs?.map(({ propertyName, before, after }: MetadataDiff) => {
    let parsedBefore: string | { [x: string]: string };
    let parsedAfter: string | { [x: string]: string };

    try {
      parsedBefore = JSON.parse(before);
    } catch (error) {
      parsedBefore = before;
    }

    try {
      parsedAfter = JSON.parse(after);
    } catch (error) {
      parsedAfter = after;
    }

    if (parsedBefore && typeof parsedBefore === 'object' && parsedAfter && typeof parsedAfter === 'object') {
      Object.keys(parsedBefore).forEach((key) => {
        if (
          parsedBefore &&
          typeof parsedBefore === 'object' &&
          parsedAfter &&
          typeof parsedAfter === 'object' &&
          parsedBefore[key] === parsedAfter[key]
        ) {
          delete parsedBefore[key];
          delete parsedAfter[key];
        }
      });
    }

    return { propertyName, before: parsedBefore, after: parsedAfter };
  });

  const parsedFields = metadata?.fields?.map(({ propertyName, propertyValue }: MetadataField) => {
    let parsedPropertyValue;

    try {
      parsedPropertyValue = JSON.parse(propertyValue);
    } catch (error) {
      parsedPropertyValue = propertyValue;
    }

    return { propertyName, propertyValue: parsedPropertyValue };
  });

  return (
    <Box>
      {isUpdateEvent ? (
        <>
          {parsedDiffs.map(({ propertyName, before, after }: IntegrationParsedDiff, idx: number) => (
            <DetailWrapper key={idx}>
              {propertyName !== 'settings' && (
                <Typography fontWeight={500} mr="10px">
                  {capitalize(propertyName)}:
                </Typography>
              )}

              {typeof before === 'object' && (
                <Box>
                  {Object.entries(before).map(([key, value], idx) => (
                    <Fragment key={idx}>
                      {key !== 'accessToken' && key !== 'integrationName' && (
                        <DetailWrapper>
                          <Typography fontWeight={500} mr="5px">
                            {separateStringByCapitalLetters(capitalize(key))}:
                          </Typography>

                          <Typography>{String(value)}</Typography>
                        </DetailWrapper>
                      )}
                    </Fragment>
                  ))}
                </Box>
              )}

              {typeof before === 'boolean' && (
                <Typography>{before ? t('auditLogPage.yes') : t('auditLogPage.no')}</Typography>
              )}

              {(typeof before === 'string' || typeof before === 'number') && <Typography>{before}</Typography>}

              {!(typeof before === 'object' && before.integrationName) && <Typography mx="10px">{'>>>'}</Typography>}

              {typeof after === 'object' && (
                <Box>
                  {Object.entries(after).map(([key, value], idx) => (
                    <Fragment key={idx}>
                      {key !== 'accessToken' && key !== 'integrationName' && (
                        <DetailWrapper>
                          <Typography fontWeight={500} mr="5px">
                            {separateStringByCapitalLetters(capitalize(key))}:
                          </Typography>

                          <Typography>{String(value)}</Typography>
                        </DetailWrapper>
                      )}
                    </Fragment>
                  ))}
                </Box>
              )}

              {typeof after === 'boolean' && (
                <Typography>{after ? t('auditLogPage.yes') : t('auditLogPage.no')}</Typography>
              )}

              {(typeof after === 'string' || typeof after === 'number') && <Typography>{after}</Typography>}
            </DetailWrapper>
          ))}
        </>
      ) : (
        <>
          {parsedFields.map(({ propertyName, propertyValue }: MetadataField, idx: number) => (
            <DetailWrapper key={idx}>
              {propertyName !== 'settings' && (
                <Typography fontWeight={500} mr="10px">
                  {capitalize(propertyName)}:
                </Typography>
              )}

              {typeof propertyValue === 'object' && (
                <Box>
                  {Object.entries(propertyValue).map(([key, value], idx) => (
                    <Fragment key={idx}>
                      {key !== 'accessToken' && key !== 'integrationName' && (
                        <DetailWrapper>
                          <Typography fontWeight={500} mr="5px">
                            {separateStringByCapitalLetters(capitalize(key))}:
                          </Typography>

                          <Typography>{String(value)}</Typography>
                        </DetailWrapper>
                      )}
                    </Fragment>
                  ))}
                </Box>
              )}

              {typeof propertyValue === 'boolean' && (
                <Typography>{propertyValue ? t('auditLogPage.yes') : t('auditLogPage.no')}</Typography>
              )}

              {(typeof propertyValue === 'string' || typeof propertyValue === 'number') && (
                <Typography>{propertyValue}</Typography>
              )}
            </DetailWrapper>
          ))}
        </>
      )}
    </Box>
  );
};

export const PlanSubscriptionDetails = () => {
  return <Typography>=== NOT READY ===</Typography>;
};
