import React, { useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';

import { useMutation } from '@apollo/client';

import Loader from 'src/components/Loader';
import { PAID_PLAN_URL_PARAM } from 'src/constants';
import { FIRST_DASHBOARD_PATHNAME } from 'src/constants/routeSources';
import { verifyMailLink } from 'src/utils/gql';
import { setToken } from 'src/utils/localStorage';

import { LoaderWrapper } from './styled';

function VerifyMailToken() {
  const history = useHistory();
  const { search } = useLocation();

  const query = new URLSearchParams(search);
  const mailToken = query.get('token');
  const paidPlan = query.get(PAID_PLAN_URL_PARAM);

  const [verifyLink] = useMutation(verifyMailLink);

  const onVerifyLink = useCallback(async () => {
    try {
      const result = await verifyLink({
        variables: {
          token: mailToken,
        },
      });

      if (result.data) {
        const { verifyLink } = result.data;
        setToken(verifyLink.token);

        history.push(paidPlan ? `/auth/payment-plan/${paidPlan}` : `/${FIRST_DASHBOARD_PATHNAME}`);
      }
    } catch (error) {
      if (error) {
        history.push('/auth/register');
      }
    }
  }, [history, mailToken, paidPlan, verifyLink]);

  useEffect(() => {
    onVerifyLink();
  }, [onVerifyLink]);

  return (
    <LoaderWrapper>
      <Loader />
    </LoaderWrapper>
  );
}

export default VerifyMailToken;
