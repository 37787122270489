import React, { ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { makeStyles } from '@material-ui/core';

import { updateLocationsData } from 'src/utils/locationArrays';

import Loader from './Loader';
import ScrollToTop from './ScrollToTop';

const useStyles = makeStyles({
  root: {
    height: '100%',
  },
});

interface PageProps {
  presence?: boolean;
  title: string;
  locationName?: string;
  className?: string;
  children: ReactNode;
  id?: string;
  loading?: boolean;
}

const Page = ({ title, locationName, presence = true, children, loading, ...rest }: PageProps) => {
  const classes = useStyles();
  const { pathname: location } = useLocation<{ pathname: string }>();

  useEffect(() => {
    if (title) {
      document.title = title;
    }
  }, [title]);

  useEffect(() => {
    if (locationName) {
      updateLocationsData?.(location, locationName, presence);
    }
  }, [location, locationName, presence]);

  return (
    <div {...rest} className={classes.root}>
      <ScrollToTop />
      {loading ? <Loader fullAvailableScreen /> : children}
    </div>
  );
};

export default Page;
