import React from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js';

import CreditCardForm from './CreditCardForm';

interface CreditCardStripeContainerProps {
  onCreatePaymentMethod: (id: string) => void;
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY as string);

const CreditCardStripeContainer = ({ onCreatePaymentMethod }: CreditCardStripeContainerProps) => {
  const options: StripeElementsOptions = {
    locale: 'en',
  };

  return (
    <Elements options={options} stripe={stripePromise}>
      <CreditCardForm onCreatePaymentMethod={onCreatePaymentMethod} />
    </Elements>
  );
};

export default CreditCardStripeContainer;
