import React, { ReactNode, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { Box, Link, Typography } from '@material-ui/core';

import { EXTERNAL_URL, LINK_TO_SURVEY } from 'src/constants';

import IntegrationImageBackgroundRegister from './IntegrationImageBackgroundRegister';
import IntegrationImageBackgroundWelcome from './IntegrationImageBackgroundWelcome';
import {
  DescriptionTypography,
  IntegrationContainer,
  SideContainer,
  SignInTypography,
  TermsTypography,
} from './styled';

interface IntegrationScreenProps {
  children: ReactNode;
  isLoggedIn: boolean;
}

export const SHOPIFY_LOGIN = 'shopify_login';

const IntegrationSignUpScreen = ({ children, isLoggedIn }: IntegrationScreenProps) => {
  const { t } = useTranslation();
  const { search } = useLocation<{ search: string }>();

  const [isImageLoading, setIsImageLoading] = useState(true);

  const signInLink = `/auth/login${search}&${SHOPIFY_LOGIN}`;

  return (
    <Box display="flex" justifyContent="center" alignItems="center" ml="30px" width="100%">
      <IntegrationContainer>
        <Typography variant="h1">{isLoggedIn ? t('registerPage.createShopifyCatalog') : t('welcome')}</Typography>

        <DescriptionTypography variant="body2">
          {isLoggedIn ? (
            t('registerPage.inSystem')
          ) : (
            <Trans i18nKey="registerPage.importFromIntegration">
              To import your catalog from Shopify please enter your email address and store name below. Before
              proceeding, we kindly ask you to complete a quick
              <Link href={LINK_TO_SURVEY} target="_blank" rel="noopener noreferrer" underline="always">
                questionnaire
              </Link>
              . This helps us tailor our services to your needs.
            </Trans>
          )}
        </DescriptionTypography>

        {children}

        {!isLoggedIn && (
          <>
            <TermsTypography variant="caption">
              <Trans i18nKey="registerPage.termsAndConditions">
                By creating an account, you agree to the
                <Link href={EXTERNAL_URL.PRIVACY_POLICY} target="_blank" rel="noopener noreferrer">
                  Privacy Policy
                </Link>
                &
                <Link href={EXTERNAL_URL.TERMS_AND_CONDITIONS} target="_blank" rel="noopener noreferrer">
                  Terms and Conditions
                </Link>
              </Trans>
            </TermsTypography>

            <SignInTypography variant="caption">
              {t('registerPage.alreadySignUp')}
              <Link component={RouterLink} to={signInLink} ml={1}>
                {t('registerPage.signInText')}
              </Link>
            </SignInTypography>
          </>
        )}
      </IntegrationContainer>

      {isLoggedIn ? (
        <SideContainer ml="30px">
          {isImageLoading && <IntegrationImageBackgroundWelcome />}
          <img
            src={`${process.env.PUBLIC_URL}/images/welcome.webp`}
            alt="Welcome"
            onLoad={() => setIsImageLoading(false)}
            width="622px"
            height="578px"
          />
        </SideContainer>
      ) : (
        <SideContainer ml="100px">
          {isImageLoading && <IntegrationImageBackgroundRegister />}
          <img
            src={`${process.env.PUBLIC_URL}/images/register.webp`}
            alt="Register"
            onLoad={() => setIsImageLoading(false)}
            width="622px"
            height="578px"
          />
        </SideContainer>
      )}
    </Box>
  );
};

export default IntegrationSignUpScreen;
