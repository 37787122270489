import React from 'react';
import { useTranslation } from 'react-i18next';

import { IconButton, Typography } from '@mui/material';

import Iconography from 'src/components/Iconography';

import { NoMappingInfoBox } from './styled';

interface NoMappingInfoProps {
  onAttachButtonClick: () => void;
  showEbayText: boolean;
  isExportInProgress: boolean;
}

const NoMappingInfo = ({ onAttachButtonClick, showEbayText, isExportInProgress }: NoMappingInfoProps) => {
  const { t } = useTranslation();

  const handleAttachButtonClick = () => {
    if (!isExportInProgress) {
      onAttachButtonClick();
    }
  };

  const text = t(
    isExportInProgress
      ? 'mappingSidebar.itemMapping.content.exportInProgress'
      : `mappingSidebar.itemMapping.content.${showEbayText ? 'infoTextEbay' : 'infoText'}`,
  );

  return (
    <NoMappingInfoBox
      onClick={handleAttachButtonClick}
      display="flex"
      justifyContent="space-between"
      flexDirection="row"
      sx={{ cursor: isExportInProgress ? 'default' : 'pointer' }}
      data-testid="attachButton"
    >
      <Typography color="text.disabled" whiteSpace="initial">
        {text}
      </Typography>

      <IconButton id="attach-detach" size="small" disabled={isExportInProgress}>
        <Iconography iconName="attach-file" color="primary" />
      </IconButton>
    </NoMappingInfoBox>
  );
};

export default NoMappingInfo;
