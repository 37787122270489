import React from 'react';

import { ListItemButtonProps } from '@mui/material';

import useOnboarding from 'src/providers/OnboardingProvider/hooks/useOnboarding';

import { StyledListItemButton } from './styled';

interface NavBarListFooterItemProps extends ListItemButtonProps {
  testId: string;
}

const NavBarListFooterItem = (props: NavBarListFooterItemProps) => {
  const {
    onboardingState: { tourActive },
  } = useOnboarding();

  const { onClick, testId, id, children, selected } = props;

  return (
    <StyledListItemButton
      disableRipple
      id={id}
      disabled={tourActive}
      data-testid={testId}
      onClick={onClick}
      selected={selected}
    >
      {children}
    </StyledListItemButton>
  );
};

export default NavBarListFooterItem;
