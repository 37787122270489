import React, { KeyboardEvent, SyntheticEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Autocomplete, Typography, makeStyles, Theme } from '@material-ui/core';
import { experimentalStyled as styled, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { v4 as uuidv4 } from 'uuid';

import { validateTextFieldValue } from 'src/helpers/validationCheck';
import { addIconToEndAdornment } from 'src/utils/addIconToEndAdornment';

import Button from './Button';
import Hint from './Hint';
import Info from './Icon/info.svg';
import Iconography from './Iconography';
import { AbstractFieldTypeInputProps } from './MultiTypeInput';
import { Input, StyledPopper } from './Select';

const Popper = styled(StyledPopper)(() => ({
  '& .css-19fc07y-MuiAutocomplete-noOptions': {
    height: '38px',
    width: '286px',
    padding: 0,
    marginTop: '-1px',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  '& .MuiAutocomplete-listbox': {
    maxHeight: '172px',
  },
}));

const LargePopper = styled(Popper)(() => ({
  '& .css-19fc07y-MuiAutocomplete-noOptions': {
    width: '100%',
  },
}));

const useStyles = makeStyles<Theme>((theme) => ({
  addNewOptionButton: {
    width: '52px',
    height: '31px',
    borderRadius: '10px',
    padding: 0,
    minWidth: 0,
  },
  addButtonText: {
    fontWeight: 700,
    fontSize: 12,
    color: theme.palette.common.white,
  },
  addNewCustomOptionText: {
    fontWeight: 400,
    fontSize: 15,
    color: theme.palette.primary.light,
  },
  defaultValueFieldHint: {
    marginRight: '10px',
    marginLeft: '5px',
  },
  hintWrapper: {
    display: 'flex',
    width: '20px',
    height: '20px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  endAndormentWrapper: {
    '& .MuiAutocomplete-endAdornment': {
      display: 'flex',
      right: '9px',
      alignItems: 'center',
    },
  },
}));

export interface SingleSelectProps extends AbstractFieldTypeInputProps {
  options: string[];
  //maybe should be deleted
  isSelectedOptionReset?: boolean;
  disablePortal?: boolean;
  checkIsSpecialImportedField?: boolean;
  largePopper?: boolean;
  isDefaultFieldValue?: boolean;
}

const SingleSelect = ({
  checkIsSpecialImportedField,
  options,
  label,
  isSelectedOptionReset = false,
  disabled,
  disablePortal = true,
  error,
  helperText,
  autoFocus,
  onChange,
  largePopper,
  value,
  isDefaultFieldValue,
}: SingleSelectProps) => {
  const theme = useTheme();
  const {
    addNewCustomOptionText,
    addNewOptionButton,
    addButtonText,
    defaultValueFieldHint,
    hintWrapper,
    endAndormentWrapper,
  } = useStyles();
  const { t } = useTranslation();

  const [isActive, setIsActive] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [showPopper, setShowPopper] = useState<boolean>(false);

  const [inputValue, setInputValue] = useState<string>(value);
  const [newOption, setNewOption] = useState<string>('');

  const enableAddButton = !!(inputValue && inputValue !== newOption);

  const singleSelectOptions = useMemo(
    () => Array.from(new Set([...options, newOption])).filter((option) => option),
    [options, newOption],
  );

  const customSearchFilter = (allOptions: string[], { inputValue }: { inputValue: string }) =>
    inputValue && inputValue !== value
      ? allOptions.filter((currentValue) => currentValue.includes(inputValue))
      : allOptions;

  const onEnterKeyPress = (event: KeyboardEvent<HTMLDivElement>) => {
    event.stopPropagation();

    if (event.key === 'Enter' && enableAddButton) {
      onAddNewOptionButtonClick();
    }
  };

  const onAddNewOptionButtonClick = () => {
    if (enableAddButton) {
      setNewOption(inputValue);
      setShowPopper(false);
    }

    if (inputValue) {
      setInputValue(inputValue);
      onChange(inputValue.trim());
    }
  };

  const handleInputChange = (_: SyntheticEvent, newInputValue: string, reason: string) => {
    setInputValue(reason === 'clear' ? '' : validateTextFieldValue(newInputValue));
  };

  const defaultFieldHintText = t('fieldSettings.defaultFieldHint');

  const hint = (
    <Hint
      key={uuidv4()}
      className={hintWrapper}
      placement="bottom"
      isDefaultFieldValueHint
      type="hover"
      title={defaultFieldHintText}
    >
      <img className={defaultValueFieldHint} src={Info} alt="info" />
    </Hint>
  );

  const autocomplete = (
    <Autocomplete
      className={endAndormentWrapper}
      disablePortal={disablePortal}
      open={showPopper}
      onOpen={() => setShowPopper(true)}
      onClose={() => setShowPopper(false)}
      disabled={disabled}
      value={value}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      onKeyPress={onEnterKeyPress}
      options={singleSelectOptions}
      filterOptions={customSearchFilter}
      disableClearable={isSelectedOptionReset || (isDefaultFieldValue && !value)}
      getOptionSelected={(option) => !!option}
      popupIcon={
        <Iconography
          data-testid={`expand-chevron-${label}`}
          iconName="expand-chevron"
          htmlColor={(isActive || isFocused) && !disabled ? 'secondary' : theme.palette.text.disabled}
        />
      }
      clearIcon={
        value ? (
          <Iconography data-testid={`clear-cross-${label}`} iconName="cancel" fontSize="small" htmlColor="secondary" />
        ) : null
      }
      PopperComponent={largePopper ? LargePopper : Popper}
      onChange={(_, newValue) => onChange((newValue || '') as string)}
      noOptionsText={
        <>
          <Typography className={addNewCustomOptionText}>
            {t('multiTypeInputLabels.singleSelectCustomPaper.text')}
          </Typography>
          <Button
            className={addNewOptionButton}
            variant="contained"
            onClick={onAddNewOptionButtonClick}
            data-testid="singleselectAddButton"
          >
            <Typography className={addButtonText}>
              {t('multiTypeInputLabels.singleSelectCustomPaper.buttonText')}
            </Typography>
          </Button>
        </>
      }
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      onMouseEnter={() => setIsActive(true)}
      onMouseLeave={() => setIsActive(false)}
      renderInput={(params) => (
        <Input
          {...params}
          InputProps={{
            ...params.InputProps,
            className: clsx(!isDefaultFieldValue && params.InputProps.className),
            endAdornment: isDefaultFieldValue
              ? addIconToEndAdornment(params.InputProps.endAdornment, hint)
              : params.InputProps.endAdornment,
          }}
          autoFocus={autoFocus}
          label={label}
          error={error}
          disabled={disabled}
          helperText={helperText}
          inputProps={{
            ...params.inputProps,
            'data-testid': `singleSelectField${label}`,
            'aria-label': label,
          }}
        />
      )}
    />
  );

  return checkIsSpecialImportedField ? (
    <Hint
      type="hover"
      placement="top"
      title={t('userSettingsPage.integrations.weightUnitShopifyFieldHintTitle') as string}
    >
      {autocomplete}
    </Hint>
  ) : (
    autocomplete
  );
};

export default SingleSelect;
