import React, { SyntheticEvent, useState } from 'react';
import { Trans } from 'react-i18next';

import { Box, Grid, IconButton, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import ProductTypeCard from 'src/components/Card/ProductTypeCard';
import Iconography from 'src/components/Iconography';
import Popup from 'src/components/Popup';
import TruncatedPopupDeleteText from 'src/components/TruncatedPopupDeleteText';

import { CardIcon, ClippedTypography, StyledPopupContentText } from './styled';

const useStyles = makeStyles((theme: Theme) => ({
  iconsStyles: {
    color: theme.palette.text.primary,
    padding: '10px',
  },
}));

interface ProductTypeItemProps {
  name: string;
  onProductTypeClick: () => void;
  onAddProductItemClick?: (e: SyntheticEvent<HTMLButtonElement>) => void;
  onEditProductTypeClick?: (e: SyntheticEvent<HTMLButtonElement>) => void;
  onDeleteProductType: () => void;
  loading?: boolean;
  isProductTypeFromSharedCatalog: boolean;
  goToMappingsPage?: (e: SyntheticEvent<HTMLButtonElement>) => void;
  isCatalogJobLoading: boolean;
}

const ProductTypeItem = ({
  isProductTypeFromSharedCatalog,
  name,
  onProductTypeClick,
  onAddProductItemClick,
  onEditProductTypeClick,
  onDeleteProductType,
  goToMappingsPage,
  loading,
  isCatalogJobLoading,
}: ProductTypeItemProps) => {
  const classes = useStyles();
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const handleOpenModal = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsOpenModal(true);
  };

  return (
    <>
      <Box mb="20px">
        <ProductTypeCard
          size="large"
          onCardSelect={onProductTypeClick}
          productTypeCardTestId={`productTypeCard${name}`}
        >
          <Grid container flex="33%" alignItems="center" justifyContent="space-between">
            <Grid item flex="1" xs={5}>
              <ClippedTypography
                variant="subtitle2"
                padding={isProductTypeFromSharedCatalog ? '15px 0 15px 0' : undefined}
                noWrap
              >
                {name}
              </ClippedTypography>
            </Grid>
            <Grid item display="flex">
              {!isProductTypeFromSharedCatalog && (
                <>
                  <IconButton
                    disabled={isCatalogJobLoading}
                    className={classes.iconsStyles}
                    onClick={goToMappingsPage}
                    data-testid={`goToMappingsPage${name}`}
                  >
                    <Iconography iconName="mappings" />
                  </IconButton>
                  <IconButton
                    disabled={isCatalogJobLoading}
                    className={classes.iconsStyles}
                    onClick={onAddProductItemClick}
                    data-testid={`addButtonProductType${name}`}
                  >
                    <Iconography iconName="add" />
                  </IconButton>
                  <IconButton
                    disabled={isCatalogJobLoading}
                    className={classes.iconsStyles}
                    onClick={onEditProductTypeClick}
                    data-testid={`settingButtonProductType${name}`}
                  >
                    <Iconography iconName="settings" />
                  </IconButton>
                </>
              )}
              {!isProductTypeFromSharedCatalog && (
                <CardIcon
                  disabled={isCatalogJobLoading}
                  className={classes.iconsStyles}
                  onClick={(e: SyntheticEvent<HTMLButtonElement>) => handleOpenModal(e)}
                  data-testid={`removeButtonProductType${name}`}
                >
                  <Iconography iconName="trash-fill" />
                </CardIcon>
              )}
            </Grid>
          </Grid>
        </ProductTypeCard>
      </Box>

      <Popup
        open={isOpenModal}
        mainTitle={TruncatedPopupDeleteText(name)}
        mainButtonText="Delete"
        onMainButtonClick={onDeleteProductType}
        secondaryButtonText="Back"
        onClose={() => setIsOpenModal(false)}
        maxWidth="sm"
        loadingOnMainButton={loading}
      >
        <Typography ml="24px" mr="24px" variant="body1" color="text.secondary">
          <Trans i18nKey="productType.popupDeleteProductType.contentText" name={name}>
            Nested items of <StyledPopupContentText>{{ name }}</StyledPopupContentText> will also be deleted. This
            action cannot be undone.
          </Trans>
        </Typography>
      </Popup>
    </>
  );
};

export default ProductTypeItem;
