import { MediaType, Media } from 'src/utils/gql';
import {
  AVAILABLE_IMAGE_FORMATS,
  AVAILABLE_VIDEO_FORMATS,
  AVAILABLE_DOCUMENT_FORMATS,
  AVAILABLE_AUDIO_FORMATS,
  AVAILABLE_MODELS3D_EXTENSIONS,
  UploadingMedia,
} from 'src/views/Catalogs/ProductItems/types';

export const LIMIT_MEDIA_ITEMS = 50;
export const ALL_MEDIA_TYPES = undefined;

export enum DataFilter {
  all = 'all',
  today = 'today',
  lastThreeDays = 'lastThreeDays',
  lastWeek = 'lastWeek',
  lastMonth = 'lastMonth',
}

export enum MediaDataType {
  FROM_DATABASE = 'FROM_DATABASE',
  FROM_URL = 'FROM_URL',
  FROM_FILE = 'FROM_FILE',
  FROM_MEDIA_GALLERY = 'FROM_MEDIA_GALLERY',
}

export interface OptionsMediaFilter {
  value?: MediaType;
  title: string;
}
export interface MediaFilterProps {
  typeFilter?: string;
  onFilterSelect: (value?: MediaType) => void;
  dataFilterOptions: Array<OptionsMediaFilter>;
}
export interface SideBarProps {
  closeSidebar?: () => void;
}

export interface UploadMedia {
  files?: File[];
  urls?: string[];
  countLimit?: number;
}

export const AVAILABLE_FORMATS = [
  ...AVAILABLE_IMAGE_FORMATS,
  ...AVAILABLE_VIDEO_FORMATS,
  ...AVAILABLE_DOCUMENT_FORMATS,
  ...AVAILABLE_AUDIO_FORMATS,
  ...AVAILABLE_MODELS3D_EXTENSIONS,
];

export const MediaTypes = {
  CARD: 'card',
};

export interface ItemsMediaGalleryProps {
  id: string;
  changeCheckbox: (event: React.ChangeEvent<HTMLInputElement>) => void;
  type: string;
  href?: string;
  itemState?: boolean;
  deleteItem: () => void;
  created?: boolean;
  recedingMedia?: boolean;
  expansion?: string;
  uploadMediaName?: string;
}

export interface SidebarMediaGalleryProps {
  isMediaSideBarPopupOpen: boolean;
  setIsMediaSideBarPopupOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface UploadingMediaWithLoadingStatus extends UploadingMedia {
  loadingStatus?: boolean;
  expansion?: string;
}

export interface UploadMediaProps {
  setUpload: React.Dispatch<React.SetStateAction<UploadingMediaWithLoadingStatus[]>>;
}

export interface MediaWithExpension extends Media {
  expansion?: string;
  name?: string;
}
