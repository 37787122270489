import React, { useCallback, useMemo } from 'react';

import { InputAdornment } from '@material-ui/core';

import FiltersAccordion from 'src/components/FiltersAccordion';
import { FilterType, Maybe } from 'src/utils/gql';
import { FilterItemWithNewValues } from 'src/views/Catalogs/ProductItems/types';

import BooleanFilter from './BooleanFilter';
import DisplayEmptyCheckBox from './DisplayEmptyCheckBox';
import Options from './Options';
import Range from './Range';

interface FilterItemProps {
  item: FilterItemWithNewValues;
  onChange: (values: string[], itemId: string, emptyValues: boolean) => void;
  expanded?: boolean;
  onExpandedChange?: (expanded: boolean) => void;
}

const FilterItem = ({ item, onChange, expanded, onExpandedChange }: FilterItemProps) => {
  const { type, productTypeFieldId, productTypeFieldName, min, max, values, newValues, required, newEmptyValues } =
    item;

  const handleChangeFilter = useCallback(
    (values: string[]) => {
      onChange(values, productTypeFieldId, newEmptyValues);
    },
    [productTypeFieldId, onChange, newEmptyValues],
  );

  const handleEmptyValues = useCallback(
    (value: boolean) => {
      onChange(newValues as string[], productTypeFieldId, value);
    },
    [productTypeFieldId, onChange, newValues],
  );

  const inputAdornment =
    productTypeFieldName === 'Price' ? { startAdornment: <InputAdornment>$</InputAdornment> } : undefined;
  const newValuesString = useMemo(() => newValues as string[], [newValues]);

  return (
    <>
      {type === FilterType.Options && !!values?.length && (
        <FiltersAccordion
          id={productTypeFieldId}
          title={productTypeFieldName}
          expanded={expanded}
          onChange={onExpandedChange}
        >
          <Options
            title={productTypeFieldName}
            values={values.filter((value) => value)}
            selectedValues={newValuesString}
            onChange={handleChangeFilter}
          />
          {!required && (
            <DisplayEmptyCheckBox
              fieldName={productTypeFieldName}
              checked={newEmptyValues}
              onChange={handleEmptyValues}
            />
          )}
        </FiltersAccordion>
      )}

      {type === FilterType.Range && Number.isInteger(min) && Number.isInteger(max) && min !== max && (
        <FiltersAccordion
          id={productTypeFieldId}
          title={productTypeFieldName}
          expanded={expanded}
          onChange={onExpandedChange}
        >
          <Range
            inputProps={inputAdornment}
            min={min as number}
            max={max as number}
            values={newValues as Maybe<string>[]}
            onChange={handleChangeFilter as (values: (string | null)[]) => void}
            fieldName={productTypeFieldName}
          />
          {!required && (
            <DisplayEmptyCheckBox
              fieldName={productTypeFieldName}
              checked={newEmptyValues}
              onChange={handleEmptyValues}
            />
          )}
        </FiltersAccordion>
      )}

      {type === FilterType.Boolean && !!values?.length && (
        <FiltersAccordion
          id={productTypeFieldId}
          title={productTypeFieldName}
          expanded={expanded}
          onChange={onExpandedChange}
        >
          <BooleanFilter values={newValuesString} onChange={handleChangeFilter} />
          {!required && (
            <DisplayEmptyCheckBox
              fieldName={productTypeFieldName}
              checked={newEmptyValues}
              onChange={handleEmptyValues}
            />
          )}
        </FiltersAccordion>
      )}
    </>
  );
};

export default FilterItem;
