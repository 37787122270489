import React, { ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useLazyQuery } from '@apollo/client';
import { TableCell } from '@material-ui/core';
import { IconButton } from '@mui/material';

import Hint from 'src/components/Hint';
import HintLimitButton from 'src/components/HintLimitButton';
import Iconography from 'src/components/Iconography';
import Loader from 'src/components/Loader';
import { checkIfFieldCanBeDeleted, QueryCheckIfFieldCanBeDeletedArgs, Query } from 'src/utils/gql';

import { StyledActionButtonsBox, StyledErrorBox, StyledErrorIcon } from '../styled';
import { CustomFieldsProps } from '../types';

interface ActionsTableCellProps {
  onEditClick?: () => void;
  onDeleteClick?: () => void;
  isEditable?: boolean;
  isSelectedForDelete?: boolean;
  fieldNameForTest?: string;
  fieldId?: string;
  setCanBeDeleted?: (value: React.SetStateAction<CustomFieldsProps[]>) => void;
  index?: number;
}

const ActionsTableCell = ({
  onEditClick,
  onDeleteClick,
  isEditable,
  fieldNameForTest,
  isSelectedForDelete = false,
  fieldId,
  setCanBeDeleted,
  index,
}: ActionsTableCellProps) => {
  const { t } = useTranslation();

  const setFieldDeletingData = (data?: Pick<Query, 'checkIfFieldCanBeDeleted'>) => {
    if (data && fieldId) {
      setCanBeDeleted?.((prev) => [
        ...prev,
        { customFieldId: fieldId, hasFieldRequiredRelation: !data?.checkIfFieldCanBeDeleted },
      ]);
    }
  };

  const [isFieldCanBeDeleted, { loading, data }] = useLazyQuery<
    Pick<Query, 'checkIfFieldCanBeDeleted'>,
    QueryCheckIfFieldCanBeDeletedArgs
  >(checkIfFieldCanBeDeleted, {
    fetchPolicy: 'cache-first',
    onCompleted: setFieldDeletingData,
  });

  const handleClick = () => {
    if (fieldId) {
      isFieldCanBeDeleted({
        variables: {
          productTypeFieldId: fieldId,
        },
      });
    }

    onDeleteClick?.();
  };

  const errorHintText = t('productType.hintIntegrationRequiredField');
  const showErrorIcon = !loading && !data?.checkIfFieldCanBeDeleted && isSelectedForDelete;
  const hintText = (
    <Trans
      i18nKey="productType.clickToOpenMenu"
      defaults="<bold>Click</bold> to open menu"
      components={{ bold: <strong /> }}
    />
  ) as ReactNode;

  return (
    <TableCell data-testid="actions" align="center">
      <StyledActionButtonsBox justifyContent={onDeleteClick ? 'space-between' : 'center'}>
        {isEditable && (
          <Hint placement="bottom" type="hover" title={hintText as string}>
            <IconButton size="small" onClick={onEditClick} data-testid={`editActionButton${fieldNameForTest}`}>
              <Iconography iconName="edit" color={isSelectedForDelete ? 'disabled' : 'primary'} />
            </IconButton>
          </Hint>
        )}

        {onDeleteClick && (
          <StyledErrorBox>
            {loading ? (
              <Loader size="extraSmallIntegration" />
            ) : (
              <IconButton size="small" onClick={handleClick} data-testid={`deleteFieldButton${index}`}>
                <Iconography iconName={isSelectedForDelete ? 'restore' : 'trash-fill'} color="primary" />
              </IconButton>
            )}
          </StyledErrorBox>
        )}

        <StyledErrorBox>
          {showErrorIcon && (
            <HintLimitButton placement="top" tooltipWidth="200px" title={errorHintText}>
              <StyledErrorIcon>
                <Iconography iconName="info-fill" data-testid={`errorInfo${fieldNameForTest}`} />
              </StyledErrorIcon>
            </HintLimitButton>
          )}
        </StyledErrorBox>
      </StyledActionButtonsBox>
    </TableCell>
  );
};

export default ActionsTableCell;
