import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Box, Breadcrumbs, Typography } from '@material-ui/core';
import { DocumentNode } from 'graphql';

import {
  CATALOG_SETTINGS,
  CATALOGS_PATH,
  CREATION,
  FIRST_DASHBOARD_PATHNAME,
  IMPORT_FROM_CSV,
  PRODUCT_ITEMS_PATH,
  PRODUCT_TYPES_PATH,
  SHARED_CATALOGS,
  USER_SETTINGS,
  AUDIT_LOG,
} from 'src/constants/routeSources';
import { getCatalogInfo, getProductItemById, getProductTypeName } from 'src/utils/gql';

import { camelCaseFromDashSeparated } from '../../helpers/capitalize';

import BreadCrumb from './BreadCrumb';
import HomeBreadCrumb from './HomeBreadCrumb';
import QueryBreadCrumb from './QueryBreadCrumb';

interface ProductCrumbsProps {
  editingName?: string;
}

interface QueryBreadCrumbData {
  path: string;
  iconName: string;
  query?: DocumentNode;
}

interface FullQueryBreadCrumbData {
  path: string;
  query: DocumentNode;
  link: string;
  iconName: string;
  id: string;
}

const queryBreadCrumbsData: QueryBreadCrumbData[] = [
  {
    query: getCatalogInfo,
    iconName: 'catalog',
    path: CATALOGS_PATH,
  },
  {
    query: getProductTypeName,
    iconName: 'product-type',
    path: PRODUCT_TYPES_PATH,
  },
  {
    query: getProductItemById,
    iconName: 'item',
    path: PRODUCT_ITEMS_PATH,
  },
];

const DashboardBreadCrumbs = ({ editingName }: ProductCrumbsProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const pathNames = location.pathname.split('/').filter((x) => x);

  const breadcrumbNameMap: { [key: string]: { iconName: string; text: string } } = useMemo(
    () => ({
      [SHARED_CATALOGS]: { iconName: 'share-social', text: t('breadcrumbs.sharedCatalogs') },
      [CATALOG_SETTINGS]: { iconName: 'settings', text: t('breadcrumbs.catalogSettings') },
      [USER_SETTINGS]: { iconName: 'settings', text: t('breadcrumbs.settings') },
      [AUDIT_LOG]: { iconName: 'settings', text: t('breadcrumbs.auditLog') },
      [IMPORT_FROM_CSV]: { iconName: 'import', text: t('breadcrumbs.import') },
    }),
    [t],
  );

  const fullQueryBreadCrumbsData = useMemo(
    () =>
      queryBreadCrumbsData.reduce((accumulator: FullQueryBreadCrumbData[], { query, path, iconName }, index) => {
        const idIndex = pathNames.indexOf(path) + 1 || false;

        const id = idIndex && pathNames[idIndex];

        if (!(id && query)) return accumulator;

        const link =
          [...accumulator, { path, id }]
            .map(({ path: currentPath, id: currentId }) => `${currentPath}/${currentId}/`)
            .join('') + queryBreadCrumbsData[index + 1]?.path;

        return [
          ...accumulator,
          {
            query,
            path,
            link,
            iconName,
            id,
          },
        ];
      }, []),
    [pathNames],
  );

  const creationText = useMemo(() => {
    const creationIndex = pathNames.indexOf(CREATION);

    return (
      creationIndex !== -1 &&
      t(`breadcrumbs.${camelCaseFromDashSeparated(`new-${queryBreadCrumbsData[(creationIndex - 1) / 2]?.iconName}`)}`)
    );
  }, [t, pathNames]);

  const additionalBreadCrumbs = useMemo(
    () => Object.keys(breadcrumbNameMap).filter((key) => pathNames.includes(key)),
    [breadcrumbNameMap, pathNames],
  );

  if (pathNames.length < 2 || pathNames[0] !== FIRST_DASHBOARD_PATHNAME) {
    //Route to breadcrumbs contain page should start with /dashboard/*
    return null;
  }

  const breadcrumbTreeName = pathNames[1];

  return (
    <Box display="flex" alignItems="center" padding="25px 0 7px">
      <Breadcrumbs
        separator={
          <Typography color="action.disabled" variant="caption">
            /
          </Typography>
        }
        data-testid="breadCrumbs"
      >
        <HomeBreadCrumb last={pathNames.length === 2 && breadcrumbTreeName === CATALOGS_PATH} />

        {fullQueryBreadCrumbsData.map(({ iconName, query, id, link }, index) => (
          <QueryBreadCrumb
            key={index}
            iconName={iconName}
            query={query}
            variables={{ id }}
            link={`/${FIRST_DASHBOARD_PATHNAME}/${link}`}
            lastInGroup={index === fullQueryBreadCrumbsData.length - 1}
            last={!(additionalBreadCrumbs.length || creationText)}
            editing={editingName !== undefined}
            editingName={editingName}
          />
        ))}

        {creationText && <BreadCrumb iconName="queue" text={creationText} last={!additionalBreadCrumbs.length} />}

        {additionalBreadCrumbs.map((key, index) => (
          <BreadCrumb {...breadcrumbNameMap[key]} key={index} last={index === additionalBreadCrumbs.length - 1} />
        ))}
      </Breadcrumbs>
    </Box>
  );
};

export default DashboardBreadCrumbs;
