import React from 'react';

import { Box } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';

const MainBox = styled(Box)`
  position: absolute;
  right: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  filter: blur(31px);
`;

const OrangeBox = styled(Box)`
  position: absolute;
  z-index: 1;
  background-color: #df642b;
  box-shadow: 0px 0px 50px 50px #df642b;
`;

const YellowBox = styled(Box)`
  position: absolute;
  z-index: 1;
  background-color: #ffd065;
  box-shadow: 0px 0px 50px 50px #ffd065;
`;

const BlueBox = styled(Box)`
  z-index: 1;
  position: absolute;
  background-color: #111769;
  box-shadow: 0px 0px 50px 50px #111769;
`;

const LightBlueBox = styled(Box)`
  z-index: 1;
  position: absolute;
  background-color: #286aed;
  box-shadow: 0px 0px 50px 50px #286aed;
`;

const GreyBox = styled(Box)`
  z-index: 1;
  position: absolute;
  background-color: #e9f6ff;
  box-shadow: 0px 0px 50px 50px #e9f6ff;
`;

const GreenBox = styled(Box)`
  z-index: 2;
  position: absolute;
  background-color: #2bb987;
  box-shadow: 0px 0px 20px 20px #2bb987;
`;

const IntegrationImageBackground = () => {
  return (
    <MainBox>
      <GreyBox top="70px" left="30px" width="590px" height="390px" borderRadius="50%" />

      <YellowBox top="130px" left="260px" width="90px" height="70px" borderRadius="80%" />

      <LightBlueBox top="270px" left="50px" width="120px" height="90px" borderRadius="20%" />

      <LightBlueBox top="270px" left="440px" width="120px" height="90px" borderRadius="20%" />

      <GreenBox top="190px" left="130px" width="15px" height="15px" borderRadius="20%" />

      <BlueBox top="190px" left="120px" width="70px" height="70px" borderRadius="20%" />

      <BlueBox top="190px" left="420px" width="90px" height="70px" borderRadius="20%" />

      <GreyBox top="270px" left="195px" width="200px" height="100px" />

      <GreenBox top="350px" left="295px" width="10px" height="10px" />

      <OrangeBox top="310px" left="275px" width="40px" height="60px" borderRadius="20%" />
    </MainBox>
  );
};

export default IntegrationImageBackground;
