import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@material-ui/core';

import Popup from 'src/components/Popup';
import { CatalogPrivacy } from 'src/utils/gql';

export interface ChangeCatalogPrivacyModalProps {
  privacy: string;
  isModalOpen: boolean;
  loading?: boolean;
  onMainButtonClick: () => void;
  setIsModalOpen: (state: boolean) => void;
}

const ChangeCatalogPrivacyModal = ({
  privacy,
  isModalOpen,
  loading,
  setIsModalOpen,
  onMainButtonClick,
}: ChangeCatalogPrivacyModalProps) => {
  const { t } = useTranslation();

  const getPopupContent = (contentType: 'title' | 'info') => {
    let title;
    let info;

    switch (privacy) {
      case CatalogPrivacy.Public:
        title = t('settingsPage.changePrivacyPopup.public');
        info = t('settingsPage.changePrivacyPopup.publicText');
        break;

      case CatalogPrivacy.Protected:
        title = t('settingsPage.changePrivacyPopup.protected');
        info = t('settingsPage.changePrivacyPopup.protectedText');
        break;

      case CatalogPrivacy.Private:
        title = t('settingsPage.changePrivacyPopup.private');
        info = t('settingsPage.changePrivacyPopup.privateText');
        break;
    }

    return contentType === 'title' ? title : info;
  };

  return (
    <div>
      <Popup
        open={isModalOpen}
        mainTitleWidth="295px"
        mainTitle={[`${t('settingsPage.changePrivacyPopup.title')} ${getPopupContent('title')}?`]}
        mainButtonText={t('settingsPage.changePrivacyPopup.mainButton')}
        secondaryButtonText={t('settingsPage.changePrivacyPopup.secondaryButton')}
        onMainButtonClick={onMainButtonClick}
        onSecondaryButtonClick={() => setIsModalOpen(false)}
        onClose={() => setIsModalOpen(false)}
        loadingOnMainButton={loading}
      >
        <Box display="flex" justifyContent="center" alignItems="center" maxWidth={418} margin="0 auto">
          <Typography color="text.secondary" width="330px" variant="body1">
            {getPopupContent('info')}
          </Typography>
        </Box>
      </Popup>
    </div>
  );
};

export default ChangeCatalogPrivacyModal;
