import React from 'react';

import { Box } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';

const MainBox = styled(Box)`
  position: absolute;
  width: 457px;
  height: 244px;
  opacity: 0.7;
  filter: blur(27px);

  & * {
    position: absolute;
    border-radius: 50%;
  }
`;

const DarkBlueBox = styled(Box)`
  background-color: #1c0d84;
  box-shadow: 0 0 0 10px #1c0d84;
`;

const PurpleBox = styled(Box)`
  background-color: #613994;
  box-shadow: 0 0 0 20px #613994;
`;

const DarkYellowBox = styled(Box)`
  background-color: #bc866c;
  box-shadow: 0 0 20px 20px #bc866c;
`;

const YellowBox = styled(Box)`
  background-color: #f2cf86;
  box-shadow: 0 0 20px 20px #f2cf86;
`;

const OrangeBox = styled(Box)`
  background-color: #f26850;
  box-shadow: 0 0 0 10px #f26850;
`;

const GreyBox = styled(Box)`
  background-color: #45488d;
  box-shadow: 0 0 0 10px #45488d;
`;

const CatalogsNotFoundBackground = () => {
  return (
    <MainBox>
      <DarkBlueBox width="310px" height="195px" top="18px" left="78px" />

      <DarkBlueBox width="360px" height="92px" bottom="6px" left="26px" />

      <PurpleBox width="306px" height="46px" bottom="22px" right="20px" />

      <DarkYellowBox width="230px" height="120px" top="92px" left="110px" />

      <YellowBox width="44px" height="20px" top="66px" left="126px" />

      <GreyBox width="300px" height="18px" bottom="102px" right="40px" />

      <GreyBox width="280px" height="18px" bottom="30px" right="88px" />

      <GreyBox width="2px" height="80px" bottom="30px" right="60px" />

      <OrangeBox width="50px" height="82px" top="126px" right="162px" />
    </MainBox>
  );
};

export default CatalogsNotFoundBackground;
