import { ImageList } from '@material-ui/core';
import { Box, Collapse, styled } from '@mui/material';
import { BoxProps as MuiBoxProps } from '@mui/material/Box';

interface BoxProps extends MuiBoxProps {
  open: boolean;
}

export const StyledCollapse = styled(Collapse)(() => ({
  width: '100%',
}));

export const MoreDetails = styled(Box)<BoxProps>(({ theme, open }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  color: theme.palette.text.secondary,
  margin: '16px 0 10px',

  '& .MuiButtonBase-root': {
    color: theme.palette.text.secondary,
    transform: open && 'rotate(180deg)',
  },
}));

export const Details = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  maxHeight: 300,
  overflowY: 'auto',
  color: theme.palette.text.secondary,
  textAlign: 'start',

  '&::-webkit-scrollbar': {
    width: '4px',
    height: '4px',
  },

  '&::-webkit-scrollbar-track': {
    background: theme.palette.background.default,
    borderRadius: '5px',
  },

  '&::-webkit-scrollbar-thumb': {
    background: theme.palette.secondary.main,
    borderRadius: '5px',
  },
}));

export const DetailWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',

  '& .MuiTypography-root': {
    color: theme.palette.text.secondary,
  },
}));

export const StyledImageList = styled(ImageList)(() => ({
  margin: '0 15px 0 0',
  width: '150px',
}));
