import { Box, styled } from '@mui/material';

export const IntegrationContentWrapper = styled(Box)(({ theme }) => ({
  margin: '23px 0px 0px 24px',

  '& .MuiIconButton-root': {
    padding: 0,

    '&#attach-detach': {
      width: '20px',
      height: '20px',
    },

    '&:disabled .MuiSvgIcon-root': {
      color: theme.palette.text.disabled,
    },
  },

  '& .MuiLink-root#previewLink': {
    color: theme.palette.primary.main,
    fontSize: '15px',
    maxWidth: '600px',
  },

  '& .ellipsis': {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

export const ProductVariantsHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.text.secondary,
  width: 'fit-content',
  marginTop: '27px',

  '&:hover': {
    cursor: 'pointer',
  },
}));

export const ProductVariantRow = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '25px',

  '&:last-of-type': {
    marginBottom: 0,
  },

  '& .MuiTypography-root#title': {
    whiteSpace: 'nowrap',
    maxWidth: '450px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',

    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

export const NoMappingInfoBox = styled(Box)(({ theme }) => ({
  '& .MuiIconButton-root#attach-detach': {
    '&:disabled .MuiSvgIcon-root': {
      color: theme.palette.text.disabled,
    },
  },
}));
