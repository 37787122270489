import React, { useCallback } from 'react';

import { DialogProps, Theme, IconButton } from '@material-ui/core';
import MuiDialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import useOnboarding from 'src/providers/OnboardingProvider/hooks/useOnboarding';

import Button from './Button';
import Close from './Images/Close.svg';

interface StylesProps {
  descriptionTextMaxWidth?: string;
  mainButtonsMarginAbsence?: boolean;
  headerMarginAbsence?: boolean;
  height?: string;
  actionsMarginTop?: string;
  hideOverflowY?: boolean;
  marginContent?: boolean;
  bottomMarginAbsence?: boolean;
}

const useStyles = makeStyles<Theme, StylesProps>((theme) => ({
  root: {
    '& .MuiPaper-root, & .MuiDialogContent-root': {
      overflow: 'hidden',
      position: 'relative',
    },
    '& .MuiDialogContent-root': {
      padding: 0,
    },
    '& .MuiDialogContent-root:first-of-type': {
      paddingTop: '0px',
    },
    '& .MuiDialog-container, & .MuiDialog-scrollPaper': {
      backgroundColor: theme.palette.action.hover,
    },
    '& .MuiDialogActions-root, & .MuiDialogActions-spacing': {
      marginBottom: ({ bottomMarginAbsence }) => (bottomMarginAbsence ? 0 : '60px'),
      marginTop: ({ mainButtonsMarginAbsence, actionsMarginTop }) =>
        mainButtonsMarginAbsence ? undefined : actionsMarginTop,
      padding: 0,
    },
    '& .MuiDialog-paperScrollPaper': {
      height: ({ height }) => (height ? height : ''),
    },
  },
  header: {
    marginTop: '60px',
    marginBottom: ({ headerMarginAbsence }) => (headerMarginAbsence ? undefined : '50px'),
  },
  headerBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
  },
  overIconSpace: {
    height: '25px',
  },
  headerIcon: {
    position: 'absolute',
    left: '0',
    bottom: '0',
    right: '0',
    margin: 'auto',
  },
  closeButton: {
    position: 'absolute',
    zIndex: 2,
    top: '15px',
    right: '19px',
    width: '34px',
    height: '34px',
    padding: '7px',
    borderRadius: '50%',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '0px 0px 15px #F16152',
    },
    '&:active': {
      boxShadow: 'none',
      outline: 'none',
    },
  },
  closeImg: {
    position: 'absolute',
    margin: 'auto',
    left: '0',
    top: '0',
    bottom: '0',
    right: '0',
  },
  extraButton: {
    position: 'absolute',
    left: '42px',
    bottom: '40px',
  },
  // TODO: scrollbar will be changed later
  content: {
    overflowY: ({ hideOverflowY }) => (hideOverflowY ? 'hidden' : 'auto'),
    overflowX: 'hidden',
    margin: ({ marginContent }) => (marginContent ? '0 63px' : '0 16px'),

    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '5px',
      border: `1px solid ${theme.palette.action.selected}`,
      background: theme.palette.secondary.main,
      boxShadow: 'none',
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '5px',
      border: 'none',
      background: theme.palette.common.white,
      boxShadow: 'inset 0px 0px 15px rgba(46, 96, 170, 0.15)',
    },
  },
  title: {
    marginTop: '60px',
  },
  description: {
    display: 'flex',
    justifyContent: 'center',
    overflowY: 'auto',
    marginTop: '50px',
    maxHeight: '65px',
    textAlign: 'left',
  },
  descriptionText: {
    maxWidth: ({ descriptionTextMaxWidth }) => descriptionTextMaxWidth || '385px',
    lineBreak: 'anywhere',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

export interface PopupProps extends DialogProps {
  descriptionTextMaxWidth?: string;
  mainTitleWidth?: string;
  titleDescription?: string | JSX.Element;
  mainButtonText?: string | JSX.Element;
  secondaryButtonText?: string | JSX.Element;
  mainTitle?: string | string[] | JSX.Element;
  mainButtonsMarginAbsence?: boolean;
  bottomMarginAbsence?: boolean;
  headerMarginAbsence?: boolean;
  mainButtonDisabled?: boolean;
  loadingOnMainButton?: boolean;
  hideOverflowY?: boolean;
  marginContent?: boolean;
  loadingOnSecondaryButton?: boolean;
  height?: string;
  actionsMarginTop?: string;
  onMainButtonClick?: () => boolean | void | Promise<void>;
  onSecondaryButtonClick?: () => boolean | void | Promise<void>;
  testMainButton?: string;
  testSecondaryButton?: string;
  doNotCloseThePopupAfterMainButtonClick?: boolean;
  doNotCloseThePopupAfterSecondaryButtonClick?: boolean;
}

function Popup({
  doNotCloseThePopupAfterMainButtonClick,
  doNotCloseThePopupAfterSecondaryButtonClick,
  mainTitleWidth,
  descriptionTextMaxWidth,
  headerMarginAbsence,
  mainButtonsMarginAbsence,
  bottomMarginAbsence,
  mainTitle,
  titleDescription,
  mainButtonText,
  mainButtonDisabled,
  secondaryButtonText,
  onMainButtonClick,
  onSecondaryButtonClick,
  loadingOnMainButton,
  loadingOnSecondaryButton,
  height,
  hideOverflowY,
  marginContent,
  actionsMarginTop = '40px',
  testMainButton,
  testSecondaryButton,
  ...props
}: PopupProps) {
  const { root, header, headerBox, closeButton, closeImg, description, content, descriptionText } = useStyles({
    marginContent,
    hideOverflowY,
    height,
    mainButtonsMarginAbsence,
    headerMarginAbsence,
    actionsMarginTop,
    bottomMarginAbsence,
    descriptionTextMaxWidth,
  });

  const {
    onboardingState: { tourActive },
  } = useOnboarding();

  const { onClose, children } = props;

  const onCloseCallback = useCallback(() => (onClose ? onClose({}, 'escapeKeyDown') : {}), [onClose]);

  const onMainButtonClickCallback = useCallback(() => {
    if (onMainButtonClick && onMainButtonClick() === false) {
      return;
    }

    if (loadingOnMainButton === undefined && !doNotCloseThePopupAfterMainButtonClick) {
      onCloseCallback();
    }
  }, [loadingOnMainButton, onCloseCallback, onMainButtonClick, doNotCloseThePopupAfterMainButtonClick]);

  const onSecondaryButtonClickCallback = useCallback(() => {
    if (onSecondaryButtonClick && onSecondaryButtonClick() === false) {
      return;
    }

    if (loadingOnSecondaryButton === undefined && !doNotCloseThePopupAfterSecondaryButtonClick) {
      onCloseCallback();
    }
  }, [loadingOnSecondaryButton, onCloseCallback, onSecondaryButtonClick, doNotCloseThePopupAfterSecondaryButtonClick]);

  const titleStringsArray = mainTitle ? (Array.isArray(mainTitle) ? mainTitle : [mainTitle]) : null;

  return (
    <MuiDialog disableEscapeKeyDown={tourActive} {...props} className={root} disableRestoreFocus closeAfterTransition>
      <div className={header}>
        <div className={headerBox}>
          {titleStringsArray && (
            <div>
              {titleStringsArray.map((titleString, index) => (
                <Typography width={mainTitleWidth} variant="h2" key={index}>
                  {titleString}
                </Typography>
              ))}
            </div>
          )}
          {titleDescription && (
            <div className={description}>
              <Typography color="text.secondary" className={descriptionText} variant="body1" align="center">
                {titleDescription}
              </Typography>
            </div>
          )}
        </div>
      </div>
      <div className={content}>{children}</div>
      <DialogActions>
        {secondaryButtonText && (
          <div>
            <Button
              id="popup-secondary-btn-anchor"
              variant="outlined"
              onClick={onSecondaryButtonClickCallback}
              loading={loadingOnSecondaryButton}
              data-testid={testSecondaryButton}
            >
              {secondaryButtonText}
            </Button>
          </div>
        )}
        <div>
          {mainButtonText && (
            <Button
              variant="contained"
              onClick={onMainButtonClickCallback}
              disabled={mainButtonDisabled}
              loading={loadingOnMainButton}
              data-testid={testMainButton}
            >
              {mainButtonText}
            </Button>
          )}
        </div>
        <IconButton className={closeButton} onClick={onCloseCallback} data-testid="crossButtonPopup">
          <img className={closeImg} src={Close} alt="close" />
        </IconButton>
      </DialogActions>
    </MuiDialog>
  );
}

export default Popup;
