import { nanoid } from 'nanoid';

import { FIRST_DASHBOARD_PATHNAME, EDITING } from 'src/constants/routeSources';

const LOCATIONS_DATA = 'locationsData';
const MAX_LOCATIONS_VALUE = 3;

const parseLocationsData = () => {
  const localStorageData = localStorage.getItem(LOCATIONS_DATA);
  try {
    return localStorageData ? JSON.parse(localStorageData) : [];
  } catch (error) {
    return [];
  }
};

export let locationsData: Array<LocationData> = parseLocationsData();

interface LocationData {
  link: string;
  id: string;
  title?: string;
  presence?: boolean;
}

export const updateLocationsData = (link: string, title?: string, presence?: boolean) => {
  const id = nanoid();
  const lastData = locationsData[locationsData.length - 1];
  const linkMatches = [`/${FIRST_DASHBOARD_PATHNAME}`, lastData?.link, `${lastData?.link}/`].includes(link);

  if (title && link && !linkMatches) {
    locationsData.push({ title, id, link, presence });
  }

  //displaying the changed product or catalog name
  locationsData.forEach((location) => {
    if (location?.link === link) {
      location.title = title;
    }
  });

  if (locationsData.length > MAX_LOCATIONS_VALUE) {
    locationsData.shift();
  }

  localStorage.setItem(LOCATIONS_DATA, JSON.stringify(locationsData));
};

export const deleteItemDataLocations = (link: string, allProductItem = false, presence = false) => {
  locationsData.forEach((location) => {
    const linkSearch = location?.link.includes(link);
    if (linkSearch && (!allProductItem || (location?.link.includes(EDITING) && allProductItem))) {
      location.presence = presence;
    }
  });

  localStorage.setItem(LOCATIONS_DATA, JSON.stringify(locationsData));
};

export const resetRecentSteps = () => (locationsData = []);
export const locationName = (translation: string, name: string) => `${translation} "${name}"`;
