import React from 'react';

import { Box } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';

const MainBox = styled(Box)`
  position: absolute;
  width: 334px;
  height: 184px;
  opacity: 0.7;
  filter: blur(27px);

  & * {
    position: absolute;
    border-radius: 50%;
  }
`;

const DarkBlueBox = styled(Box)`
  background-color: #110f4f;
  box-shadow: 0 0 0 10px #110f4f;
`;

const PurpleBox = styled(Box)`
  background-color: #5d50dc;
  box-shadow: 0 0 0 10px #5d50dc;
`;

const LightPurpleBox = styled(Box)`
  background-color: #6b4368;
  box-shadow: 0 0 20px 20px #6b4368;
`;

const YellowBox = styled(Box)`
  background-color: #c28474;
  box-shadow: 0 0 20px 20px #c28474;
`;

const DarkOrangeBox = styled(Box)`
  background-color: #f37254;
  box-shadow: 0 0 0 10px #f37254;
`;

const OrangeBox = styled(Box)`
  background-color: #f9ac62;
  box-shadow: 0 0 0 10px #f9ac62;
`;

const RedBox = styled(Box)`
  background-color: #f74b64;
  box-shadow: 0 0 0 10px #f74b64;
`;

const CreateProductTypeBackground = () => {
  return (
    <MainBox>
      <DarkBlueBox width="118px" height="84px" bottom="8px" left="6px" />

      <DarkBlueBox width="100px" height="68px" bottom="8px" right="12px" />

      <YellowBox width="202px" height="112px" top="10px" left="64px" />

      <PurpleBox width="24px" height="40px" bottom="42px" left="56px" />

      <LightPurpleBox width="128px" height="2px" top="26px" left="80px" />

      <DarkOrangeBox width="104px" height="88px" bottom="8px" right="106px" />

      <DarkOrangeBox width="90px" height="4px" bottom="10px" left="20px" />

      <PurpleBox width="110px" height="32px" bottom="10px" right="108px" />

      <OrangeBox width="30px" height="30px" bottom="40px" right="144px" />

      <RedBox width="2px" height="2px" bottom="2px" right="124px" />

      <RedBox width="2px" height="2px" bottom="2px" left="124px" />
    </MainBox>
  );
};

export default CreateProductTypeBackground;
