import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';

import {
  Reference,
  StoreObject,
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
  useSubscription,
} from '@apollo/client';
import { useFlag } from '@unleash/proxy-client-react';
import moment from 'moment';

import { DEFAULT_CATALOGUES_PAGE_LIMIT, FeatureFlag } from 'src/constants';
import { EventName, EventsService } from 'src/services/Events';
import { clearCacheFactory } from 'src/utils/cacheUtils';
import {
  Catalog,
  CatalogJob,
  CatalogJobStatus,
  CatalogJobType,
  catalogJobUpdateSubscription,
  CatalogSource,
  CatalogType,
  checkIsCatalogEmpty,
  CreateCatalogInput,
  createCatalogQuery,
  createOnboardingCatalogMutation,
  deleteCatalogById,
  getCatalogInfo,
  getCatalogs,
  Maybe,
  Mutation,
  MutationCreateCatalogArgs,
  MutationCreateOnboardingCatalogArgs,
  Query,
  QueryGetCatalogByIdArgs,
  QueryGetCatalogsArgs,
  Subscription,
  SubscriptionCatalogJobUpdateArgs,
} from 'src/utils/gql';

import CatalogsContext from './context';
import {
  ExportTypes,
  CatalogueJobsProps,
  CatalogsJobsMap,
  CreateCatalogCacheType,
  FiltersState,
  TCatalogsData,
  CurrentExportTypeProps,
} from './types';

interface CataloguesProviderProps {
  initialOffset?: number;
  syncJobs?: boolean;
}

const SIMPLE_EXPORT_ARRAY = '_simpleExportArray';

const simpleExportArray = () => {
  const localStorageData = localStorage.getItem(SIMPLE_EXPORT_ARRAY);

  try {
    return localStorageData ? JSON.parse(localStorageData) : [];
  } catch (error) {
    return [];
  }
};

const getPrioritisedJob = (jobs: CatalogJob[], simpleExport?: boolean): Maybe<CatalogJob> => {
  if (!jobs.length) {
    return null;
  }

  let prioritisedJob: CatalogJob | null = null;

  const sortedJobs = [...jobs].sort((a, b) => moment(b.endTime).unix() - moment(a.endTime).unix());

  const catalogJobStatusArray = [
    CatalogJobStatus.Pending,
    CatalogJobStatus.Active,
    CatalogJobStatus.Pause,
    CatalogJobStatus.Fail,
    CatalogJobStatus.Success,
  ];

  (simpleExport ? catalogJobStatusArray.slice(0, 2) : catalogJobStatusArray).forEach((currentStatus) => {
    if (prioritisedJob) return;

    const job = sortedJobs.find(({ status }) => currentStatus === status);

    if (job) {
      prioritisedJob = job;
    }
  });

  if (!prioritisedJob && sortedJobs.length) {
    prioritisedJob = sortedJobs[0];
  }

  return prioritisedJob;
};

const updateCacheOnCreateCatalog = (cache: CreateCatalogCacheType, createdCatalog: Catalog) => {
  cache.writeQuery({
    query: getCatalogInfo,
    variables: { id: createdCatalog.id },
    data: { getCatalogById: { ...createdCatalog, mappingsErrors: [] } },
  });

  cache.evict({ fieldName: 'getCatalogs' });
};

const getCatalogsQueryName = 'getCatalogs';
export const clearLocalCatalogsCache = clearCacheFactory({
  fieldNames: [getCatalogsQueryName],
});

function CatalogsProvider({ initialOffset = 0, children }: PropsWithChildren<CataloguesProviderProps>) {
  const client = useApolloClient();

  const [hasMore, setHasMore] = useState<boolean>(false);
  const [filters, setFilters] = useState<FiltersState>({
    types: [CatalogType.My, CatalogType.Subscribed],
  });
  const [catalogsJobsMap, setCatalogsJobsMap] = useState<CatalogsJobsMap>({});
  const [simpleExport, setSimpleExport] = useState<string[]>(simpleExportArray());
  const [currentExportType, setCurrentExportType] = useState<CurrentExportTypeProps[]>([]);

  const isSharedCatalogsFeatureActive = useFlag(FeatureFlag.SHARED_CATALOGS);

  const initialVariables = useMemo(
    () => ({
      limit: DEFAULT_CATALOGUES_PAGE_LIMIT,
      offset: initialOffset,
      name: filters.name ?? '',
      types: isSharedCatalogsFeatureActive ? filters.types : [CatalogType.My],
    }),
    [filters.name, filters.types, initialOffset, isSharedCatalogsFeatureActive],
  );

  const updateCatalogsJobsMap = useCallback((jobs: CatalogJob[]) => {
    setCatalogsJobsMap((prevState) => {
      const updatedCatalogsJobMap = { ...prevState };

      jobs.forEach((newJob) => {
        const { catalogId, integrationId } = newJob;

        let shouldBeUpdated = true;

        const currentJob = updatedCatalogsJobMap[catalogId]?.[integrationId];

        if (currentJob?.createdAt && newJob?.createdAt) {
          shouldBeUpdated = moment(newJob.createdAt).unix() >= moment(currentJob.createdAt).unix();
        }

        if (shouldBeUpdated) {
          updatedCatalogsJobMap[catalogId] = {
            ...updatedCatalogsJobMap[catalogId],
            [integrationId]: newJob,
          };
        }
      });

      return updatedCatalogsJobMap;
    });
  }, []);

  const deleteCatalogsJobs = useCallback(
    ({ catalogId, integrationId }: { catalogId?: string; integrationId?: string }): void => {
      const deleteCatalogsJobsFromCache = (jobs: CatalogJob[]): void => {
        for (const { id } of jobs) {
          const normalizedId = client.cache.identify({
            id,
            __typename: 'CatalogJob',
          });

          client.cache.evict({ id: normalizedId });
        }

        client.cache.gc();
      };

      setCatalogsJobsMap((prevState) => {
        const updatedCatalogsJobsMap = { ...prevState };

        const jobsToDeleteFromCache: CatalogJob[] = [];

        if (catalogId && integrationId) {
          const job = updatedCatalogsJobsMap?.[catalogId]?.[integrationId];

          if (job) {
            jobsToDeleteFromCache.push(job);
            delete updatedCatalogsJobsMap[catalogId][integrationId];
          }
        } else if (catalogId) {
          const catalogJobs = updatedCatalogsJobsMap?.[catalogId];

          if (catalogJobs) {
            jobsToDeleteFromCache.push(...Object.values(catalogJobs));
            delete updatedCatalogsJobsMap[catalogId];
          }
        } else if (integrationId) {
          for (const catalogJobs of Object.values(updatedCatalogsJobsMap)) {
            const job = catalogJobs?.[integrationId];

            if (job) {
              jobsToDeleteFromCache.push(job);
              delete catalogJobs[integrationId];
            }
          }
        }

        if (jobsToDeleteFromCache.length) {
          deleteCatalogsJobsFromCache(jobsToDeleteFromCache);

          return updatedCatalogsJobsMap;
        }

        return prevState;
      });
    },
    [client.cache],
  );

  const handleUpdateCatalogs = useCallback(
    ({ getCatalogs }: Pick<Query, 'getCatalogs'>) => {
      setHasMore(getCatalogs.length === DEFAULT_CATALOGUES_PAGE_LIMIT);
      updateCatalogsJobsMap(getCatalogs.flatMap(({ lastIntegrationsJobs }) => lastIntegrationsJobs));
    },
    [updateCatalogsJobsMap],
  );

  const {
    data,
    loading: loadingGet,
    fetchMore: fetchNextPage,
  } = useQuery<TCatalogsData, QueryGetCatalogsArgs>(getCatalogs, {
    fetchPolicy: 'cache-first',
    variables: initialVariables,
  });

  const [checkIsCatalogEmptyQuery] = useLazyQuery<Pick<Query, 'getCatalogById'>, QueryGetCatalogByIdArgs>(
    checkIsCatalogEmpty,
    { fetchPolicy: 'network-only', nextFetchPolicy: 'standby' },
  );

  useEffect(() => {
    if (data?.getCatalogs) {
      handleUpdateCatalogs(data);
    }
  }, [data, handleUpdateCatalogs]);

  const [deleteCatalog, { loading: loadingDelete }] = useMutation(deleteCatalogById, {
    onCompleted: () => setHasMore(data?.getCatalogs.length === DEFAULT_CATALOGUES_PAGE_LIMIT),
  });

  const handleFetchMore = async () => {
    const offset = data?.getCatalogs?.length || 0;

    if (!fetchNextPage) return;

    handleUpdateCatalogs(
      (
        await fetchNextPage<'offset'>({
          variables: {
            offset,
          },
        })
      ).data,
    );
  };

  const handleDelete = useCallback(
    async (id: string) => {
      await deleteCatalog({
        variables: { id },

        update(cache, { data }) {
          data &&
            cache.modify({
              fields: {
                getCatalogs(existingCatalogsRef, { readField }) {
                  return existingCatalogsRef.filter(
                    (ref: Reference | StoreObject | undefined) => id !== readField('id', ref),
                  );
                },
              },
            });
        },
      });

      deleteCatalogsJobs({ catalogId: id });
    },
    [deleteCatalog, deleteCatalogsJobs],
  );

  const catalogs = useMemo(() => {
    return (data?.getCatalogs || []).map((catalog) => catalog);
  }, [data?.getCatalogs]);

  useSubscription<Pick<Subscription, 'catalogJobUpdate'>, SubscriptionCatalogJobUpdateArgs>(
    catalogJobUpdateSubscription,
    {
      onSubscriptionData: ({ subscriptionData }) => {
        const job = subscriptionData.data?.catalogJobUpdate;

        if (job) {
          EventsService.dispatch(EventName.CatalogJobUpdate, job);

          const jobCatalogId = job.catalogId;

          updateCatalogsJobsMap([job]);

          const isJobCompleted = job.status === CatalogJobStatus.Success || job.status === CatalogJobStatus.Fail;

          if (isJobCompleted && job.type === CatalogJobType.Import) {
            checkIsCatalogEmptyQuery({ variables: { id: jobCatalogId } });
          }
        }
      },
    },
  );

  const getCatalogJob = ({ catalogId, integrationId }: CatalogueJobsProps): CatalogJob | null => {
    if (integrationId) {
      return catalogsJobsMap?.[catalogId]?.[integrationId] ?? null;
    } else if (catalogsJobsMap[catalogId]) {
      return getPrioritisedJob(Object.values(catalogsJobsMap[catalogId]), simpleExport.includes(catalogId));
    }

    return null;
  };

  const [createCatalogMutation, { loading: loadingCreate }] = useMutation<
    Pick<Mutation, 'createCatalog'>,
    MutationCreateCatalogArgs
  >(createCatalogQuery, {
    onCompleted: () => setHasMore(data?.getCatalogs.length === DEFAULT_CATALOGUES_PAGE_LIMIT),
  });

  const [createOnboardingCatalog, { loading: loadingCreateOnboardingCatalog }] = useMutation<
    Pick<Mutation, 'createOnboardingCatalog'>,
    MutationCreateOnboardingCatalogArgs
  >(createOnboardingCatalogMutation, {
    onCompleted: () => setHasMore(data?.getCatalogs.length === DEFAULT_CATALOGUES_PAGE_LIMIT),
  });

  const createCatalog = async (name: string, source: CatalogSource, integrationId?: string) => {
    const newCatalogData: CreateCatalogInput = {
      name: name.trim(),
      source,
    };

    if (integrationId) {
      newCatalogData.integrationId = integrationId;
    }

    const { data } = await createCatalogMutation({
      variables: { data: newCatalogData },

      update(cache, { data }) {
        const createdCatalog = data?.createCatalog;

        if (createdCatalog) {
          updateCacheOnCreateCatalog(cache, createdCatalog);
        }
      },
    });

    return data;
  };

  const handleCreateOnboardingCatalog = async (name: string, source: CatalogSource) => {
    const { data } = await createOnboardingCatalog({
      variables: { data: { name, source } },

      update(cache, { data }) {
        const createdCatalog = data?.createOnboardingCatalog?.catalog;

        if (createdCatalog) {
          updateCacheOnCreateCatalog(cache, createdCatalog);
        }
      },
    });

    return data;
  };

  const updateExportType = (id: string, complexExport = true) => {
    if (complexExport) {
      setSimpleExport((prev) => prev.filter((catalogId) => catalogId !== id));
    } else {
      setSimpleExport((prev) => (prev.includes(id) ? prev : [...prev, id]));
    }
  };

  const updateCurrentExport = (id: string, complexExport = true) => {
    if (complexExport) {
      setCurrentExportType((prev) => prev.filter(({ catalogId }) => catalogId !== id));
    } else {
      setCurrentExportType((prev) => {
        const isSimple = prev.some(
          ({ catalogId, exportType }) => catalogId === id && exportType === ExportTypes.simple,
        );

        return isSimple ? prev : [...prev, { catalogId: id, exportType: ExportTypes.simple }];
      });
    }
  };

  useEffect(() => {
    localStorage.setItem(SIMPLE_EXPORT_ARRAY, JSON.stringify(simpleExport));
  }, [simpleExport]);

  return (
    <CatalogsContext.Provider
      value={{
        catalogs,
        catalogsJobsMap,
        loadingGet,
        loadingDelete,
        loadingCreate,
        loadingCreateOnboardingCatalog,
        hasMore,
        handleFetchMore,
        handleDelete,
        createCatalog,
        onCreateOnboardingCatalog: handleCreateOnboardingCatalog,
        setFilters,
        getCatalogJob,
        updateCatalogsJobsMap,
        deleteCatalogsJobs,
        updateExportType,
        currentExportType,
        updateCurrentExport,
      }}
    >
      {children}
    </CatalogsContext.Provider>
  );
}

export default CatalogsProvider;
