import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useIMask } from 'react-imask';

import { makeStyles, TextFieldProps } from '@material-ui/core';

import { FormField } from 'src/components/Forms/styled';
import { SHOP_URL_VALIDATION } from 'src/constants';
import useOnboarding from 'src/providers/OnboardingProvider/hooks/useOnboarding';
import { IntegrationTypes } from 'src/utils/gql';

import { TextInputProps } from '../types';

const maskOptions = {
  mask: 'https://STORE',
  lazy: false, // make placeholder always visible

  blocks: {
    STORE: {
      mask: (value: string) => {
        return /^[a-z0-9][a-z0-9\/.-]*$/.test(value) && !/^.*[\.\-]{2}$/.test(value);
      },
    },
  },
};

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiInputBase-input': {
      padding: '12px 20px',
    },
  },
}));

const ShopUrlInput = ({
  inputNameTestId,
  integrationType,
  readOnly = false,
  ...props
}: TextFieldProps & TextInputProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    formState: { errors },
    setError,
    setValue,
    clearErrors,
    trigger,
    getValues,
  } = useFormContext();
  const {
    onboardingState: { tourActive },
  } = useOnboarding();

  const { disabled } = props;
  const fieldName = props.name || 'shop';
  const defaultValue = getValues(fieldName);

  const [isTouched, setIsTouched] = useState<boolean>(false);
  const [isBlur, setIsBlur] = useState<boolean>(false);

  const {
    ref,
    value,
    setValue: setInputValue,
    setUnmaskedValue,
  } = useIMask(maskOptions, {
    onAccept(value, mask) {
      if (tourActive) {
        setInputValue(defaultValue);

        return mask.updateValue();
      }

      const protocol = 'https';
      if (disabled) {
        setInputValue(defaultValue.includes(protocol) ? defaultValue : `${protocol}://${defaultValue}`);
      } else {
        let store = value.split(`${protocol}://`)[1];
        store && setIsTouched(true);

        if (store.includes(protocol)) {
          store = store.split(protocol)[1];
        }

        setUnmaskedValue(store);
        setValue(fieldName, store);

        if (!(store && store.match(SHOP_URL_VALIDATION))) {
          setError(fieldName, { message: 'userSettingsPage.integrations.inputLabel.notValidURL' });
        } else {
          clearErrors(fieldName);
          trigger(fieldName);
        }
      }
    },
  });

  const hasError = !!errors[fieldName] && isTouched && isBlur;
  const errorMessage = errors[fieldName]?.message;

  return (
    <FormField
      inputRef={ref}
      fullWidth
      error={hasError}
      helperText={t(
        hasError
          ? `${errorMessage}`
          : `userSettingsPage.integrations.popup.${
              integrationType === IntegrationTypes.Shopify ? 'shopifyHelperText' : 'woocommerceHelperText'
            }`,
      )}
      variant="outlined"
      onFocus={() => setIsBlur(false)}
      onBlur={() => setIsBlur(true)}
      value={value}
      className={props.isStandard ? '' : classes.root}
      inputProps={{
        readOnly,
        'data-testid': inputNameTestId,
      }}
      {...props}
    />
  );
};

export default ShopUrlInput;
