import { ApolloCache } from '@apollo/client';

interface EvictByIdProps {
  id: string;
  type: string;
}

export const clearCacheFactory =
  (options: { fieldNames?: string[]; evictById?: EvictByIdProps[] }) => (cache: ApolloCache<unknown>) => {
    if (options.fieldNames?.length) {
      options.fieldNames.forEach((fieldName) => cache.evict({ fieldName }));
    }

    if (options.evictById?.length) {
      options.evictById.forEach(({ id, type }) =>
        cache.evict({
          id: cache.identify({
            id,
            __typename: type,
          }),
        }),
      );
    }

    if (options.fieldNames || options.evictById) {
      cache.gc();
    }
  };
