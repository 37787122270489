import { Box } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';

export const StyledStringValueWrapper = styled(Box)`
  max-width: 330px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const MultiselectChipWrapper = styled(Box)`
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  max-height: 53px;
  overflow-y: scroll;

  .disabled {
    background: ${({ theme: { palette } }) => palette.background.default};
    color: ${({ theme: { palette } }) => palette.text.disabled};
  }
`;

export const StyledMultiselectChipWrapper = styled(Box)`
  max-width: 51px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  background: ${({ theme: { palette } }) => palette.text.disabled};
  color: ${({ theme: { palette } }) => palette.text.primary};
  border-radius: 5px;
`;

export const StyledMultiselectChip = styled('span')`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
