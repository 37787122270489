import React from 'react';
import { useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import NoIntegrations from 'src/components/Mapping/NoIntegrations';
import DiscardChangesPopup from 'src/components/Popups/DiscardChangesPopup';
import SkeletonItemMapping from 'src/components/Skeleton/SkeletonItemMapping';
import { checkIsCatalogSyncable, Query, QueryGetCatalogByIdArgs } from 'src/utils/gql';

import useItemMapping from '../context/ItemMappingProvider';

import IntegrationInfo from './IntegrationInfo';

export interface ItemMappingProps {
  isTesting?: boolean;
  isDiscardChangesPopupOpen?: boolean;
  onCloseSidebar?: (withCheck?: boolean) => void;
  onCloseDiscardChangesPopup?: () => void;
  saveButtonLoading?: boolean;
}

const ItemMapping = ({
  isTesting = false,
  isDiscardChangesPopupOpen = false,
  onCloseSidebar,
  onCloseDiscardChangesPopup,
  saveButtonLoading,
}: ItemMappingProps) => {
  const { catalogId } = useParams<{ catalogId: string }>();
  const {
    integrations,
    itemMappings,
    activeIntegrationId,
    initialLoading,
    loadingCreateMapping,
    loadingCreateMappingByShopifyExternalId,
    loadingSyncItem,
    hasChanges,
  } = useItemMapping();
  const hasIntegrations = !!integrations?.length;
  const handleMainButtonClick = () => {
    if (isDiscardChangesPopupOpen) {
      onCloseSidebar?.(false);
    }
  };

  const { data: checkIsCatalogSyncableData, loading: checkIsCatalogSyncableLoading } = useQuery<
    Pick<Query, 'getCatalogById'>,
    QueryGetCatalogByIdArgs
  >(checkIsCatalogSyncable, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: catalogId,
    },
  });

  const loading = initialLoading || saveButtonLoading || checkIsCatalogSyncableLoading;

  return (
    <>
      {loading ? (
        <SkeletonItemMapping />
      ) : (
        <>
          {hasIntegrations ? (
            <>
              {integrations?.map((integration) => (
                <IntegrationInfo
                  syncable={checkIsCatalogSyncableData?.getCatalogById.syncable}
                  key={integration.id}
                  integration={integration}
                  mapping={itemMappings?.find(({ integration: { id } }) => id === integration.id)}
                  loadingCreateMapping={loadingCreateMapping && activeIntegrationId === integration.id}
                  loadingCreateMappingByShopifyExternalId={
                    loadingCreateMappingByShopifyExternalId && activeIntegrationId === integration.id
                  }
                  loadingSyncItemMapping={loadingSyncItem && activeIntegrationId === integration.id}
                  isTesting={isTesting}
                />
              ))}
            </>
          ) : (
            <NoIntegrations onCloseSidebar={onCloseSidebar} />
          )}
        </>
      )}

      <DiscardChangesPopup
        isManual={isDiscardChangesPopupOpen}
        isOpen={hasChanges}
        onSecondaryButtonClick={onCloseDiscardChangesPopup}
        onMainButtonClick={handleMainButtonClick}
      />
    </>
  );
};

export default ItemMapping;
