import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@material-ui/core';
import { styled } from '@mui/material';

import {
  CATALOGS_PATH,
  EDITING,
  FIRST_DASHBOARD_PATHNAME,
  PRODUCT_ITEMS_PATH,
  PRODUCT_TYPES_PATH,
} from '../constants/routeSources';
import { DuplicatedSkuInfo } from '../utils/gql';

const StyledLink = styled('a')`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme: { palette } }) => palette.primary.light};

  &:hover {
    cursor: pointer;
  }
`;

export const StyledBox = styled(Box)`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme: { palette } }) => palette.primary.light};
`;
const StyledUnorderedList = styled('ul')`
  margin-top: 2px;
  margin-bottom: 2px;
  padding-inline-start: 16px;
`;

export enum DuplicatedSKUMessageType {
  basic = 'basic',
  fieldHint = 'fieldHint',
  sync = 'sync',
  simpleExport = 'simpleExport',
}

export interface DuplicatedSKUMessageProps {
  type: DuplicatedSKUMessageType;
  duplicatedSKUInfo?: DuplicatedSkuInfo;
  catalogId: string;
}

const DuplicatedSKUMessage = ({ catalogId, duplicatedSKUInfo, type }: DuplicatedSKUMessageProps) => {
  const { t } = useTranslation();

  return (
    <StyledBox>
      {`${t(`duplicatedSKU.${type}`)}: `}
      <StyledUnorderedList>
        {duplicatedSKUInfo?.duplicatedSKU.map(({ itemId, name, productTypeId }) => (
          <li key={itemId}>
            <StyledLink
              target="_blank"
              rel="noopener"
              href={`/${FIRST_DASHBOARD_PATHNAME}/${CATALOGS_PATH}/${catalogId}/${PRODUCT_TYPES_PATH}/${productTypeId}/${PRODUCT_ITEMS_PATH}/${itemId}/${EDITING}`}
            >
              {name}
            </StyledLink>
            ;
          </li>
        ))}
      </StyledUnorderedList>
      {![DuplicatedSKUMessageType.fieldHint, DuplicatedSKUMessageType.sync].includes(type) &&
        t('duplicatedSKU.secondPart')}
    </StyledBox>
  );
};

export default DuplicatedSKUMessage;
