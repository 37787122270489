import React from 'react';

import { Box, makeStyles, Theme } from '@material-ui/core';
import CircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress';
import clsx from 'clsx';

import LoaderIcon from 'src/components/Icon/loader.svg';

export enum LoaderSize {
  button = 'button',
  extraSmall = 'extraSmall',
  small = 'small',
  medium = 'medium',
  smallIntegration = 'smallIntegration',
  extraSmallIntegration = 'extraSmallIntegration',
}

const useStyles = makeStyles((theme: Theme) => ({
  customLoader: {
    background: `url(${LoaderIcon}) no-repeat`,
    '&&  .MuiCircularProgress-circle': {
      color: 'transparent',
    },
  },
  buttonSize: {
    position: 'absolute',
    top: '12px',
    color: theme.palette.info.main,
  },
  extraSmallSize: {
    backgroundSize: '18.5px',
  },
  smallSize: {
    backgroundSize: '30.5px',
  },
  mediumSize: {
    backgroundSize: '60.6px',
  },
  fullScreen: {
    backgroundSize: '110.6px',
  },
  integrationSize: {
    color: theme.palette.info.main,
  },
}));

interface CircularProgressNewProps extends CircularProgressProps {
  size?: keyof typeof LoaderSize;
  fullScreen?: boolean;
  fullAvailableScreen?: boolean;
  outlined?: boolean;
}

const Loader = ({ size, fullScreen, fullAvailableScreen, outlined }: CircularProgressNewProps) => {
  const classes = useStyles();

  if (fullScreen) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height={'100vh'} width={'100vw'}>
        <CircularProgress className={clsx(classes.customLoader, classes.fullScreen)} size={110} />
      </Box>
    );
  }

  if (fullAvailableScreen) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height={'100%'} width={'100%'}>
        <CircularProgress className={clsx(classes.customLoader, classes.fullScreen)} size={110} />
      </Box>
    );
  }

  if (size === LoaderSize.button) {
    return outlined ? (
      <Box display="flex" justifyContent="center" alignItems="center">
        <CircularProgress className={classes.buttonSize} size={18} />
      </Box>
    ) : (
      <CircularProgress className={classes.buttonSize} size={18} />
    );
  }

  if (size === LoaderSize.extraSmall) {
    return <CircularProgress className={clsx(classes.customLoader, classes.extraSmallSize)} size={18} />;
  }

  if (size === LoaderSize.small) {
    return <CircularProgress className={clsx(classes.customLoader, classes.smallSize)} size={30} />;
  }

  if (size === LoaderSize.medium) {
    return <CircularProgress className={clsx(classes.customLoader, classes.mediumSize)} size={60} />;
  }

  if (size === LoaderSize.smallIntegration) {
    return <CircularProgress className={classes.integrationSize} size={24} />;
  }

  if (size === LoaderSize.extraSmallIntegration) {
    return <CircularProgress className={classes.integrationSize} size={18} />;
  }

  return <CircularProgress className={clsx(classes.customLoader, classes.fullScreen)} size={110} />;
};

export default Loader;
