import { CatalogSource } from 'src/utils/gql';

export const PREVIEW_ITEMS_BOX_COUNT = 30;

export const SKELETON_PRODUCT_TYPES_COUNT = 8;
export const SKELETON_SIDEBAR_CATALOGS_COUNT = 6;
export const INFINITE_SCROLL_LOADER_SKELETON_PRODUCT_TYPES_COUNT = 1;

export enum STEPS {
  firstStep = 1,
  secondStep = 2,
  thirdStep = 3,
}

export type CatalogIdState = { previousCatalogId: string; currentCatalogId: string };

export enum SOURCES {
  otherSystems = 'Other',
  noOption = 'No Option',
}

export interface CatalogueStateInterface {
  step: number;
  name: string;
  source: CatalogSource;
  touched: boolean | null;
  sameNameError: boolean;
  changeInProcess: boolean;
}

export interface SyncPopupState {
  open: boolean;
  selectedCatalogId: string;
}
