import { IntegrationTypes } from 'src/utils/gql';

export interface IntegrationItem {
  id: string;
  type: IntegrationTypes;
  settings: { [x: string]: string };
  isDefault: boolean;
}

export type PricingPlansCardsData = {
  firstBlock: {
    pricingPlanTitle: string;
    price: string;
    validity: string;
    description: string | string[];
  };
  secondBlock: {
    buttonName: string;
    listItems: {
      listItemText: string | string[];
    }[];
    customPlanDescription?: string;
  };
};

export interface UserSettingsTab {
  label: string;
  testId: string;
}

export enum UserSettingsPageTab {
  GENERAL = 'generalTab',
  BILLING = 'billingTab',
  PUBLIC_API = 'publicApiTab',
}

export enum IntegrationAction {
  Add,
  Edit,
}
