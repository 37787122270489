import React from 'react';
import { Trans } from 'react-i18next';

import { OrderBy } from '../../utils/gql';

export const CATALOG_CONFIG_TABLE_LABELS: { [key: string]: JSX.Element } = {
  [OrderBy.Subscriber]: <Trans i18nKey="settingsPage.table.labels.subscribers">Subscribers</Trans>,
  [OrderBy.Status]: <Trans i18nKey="settingsPage.table.labels.status">Status</Trans>,
  [OrderBy.Email]: <Trans i18nKey="settingsPage.table.labels.email">Email</Trans>,
  [OrderBy.SubscriptionDate]: <Trans i18nKey="settingsPage.table.labels.subscriptionnDate">Subscription date</Trans>,
};
