import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CardProps, Typography, CardHeader, Collapse, Box } from '@material-ui/core';
import MuiCard from '@material-ui/core/Card';
import MuiCardActions from '@material-ui/core/CardActions';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';

import { IntegrationTypes } from '../../utils/gql';
import BetaLabel from '../BetaLabel';
import Iconography from '../Iconography';
import RadioButton from '../RadioButton';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '&$radioBtn': {
      position: 'absolute',
    },
  },
  cardWrapper: {
    padding: '15px 30px 15px 30px',
    position: 'relative',
    '& .MuiCardHeader-title': {
      marginRight: '20px',
      textAlign: 'start',
    },
  },
  expandControl: {
    padding: '0px',
    marginTop: '10px',
    maxWidth: '105px',
  },
  expandIcon: {
    transition: 'all ease 200ms',
    transform: 'rotate(0deg)',
    color: theme.palette.text.secondary,
  },
  expandIconOpen: {
    transition: 'all ease 200ms',
    transform: 'rotate(180deg)',
    color: theme.palette.secondary.main,
  },
  radioBtn: {
    pointerEvents: 'none',
    top: '35px',
    right: '30px',
  },
}));

interface ExportCardProps extends CardProps {
  integrationType?: IntegrationTypes;
  headerTitle: string;
  isChecked: boolean;
  disabled?: boolean;
  onClickCard: () => void;
  testId?: string;
}

const ExportCard = ({
  headerTitle,
  isChecked,
  onClickCard,
  children,
  testId,
  integrationType,
  disabled,
}: ExportCardProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState<boolean>(false);

  const handleExpandClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setExpanded(!expanded);
  };

  return (
    <MuiCard className={classes.cardWrapper} onClick={onClickCard}>
      <CardHeader
        title={
          <Box display="flex" justifyContent="left" alignItems="center">
            <Typography variant="subtitle1" mr="5px">
              {headerTitle}
            </Typography>

            {integrationType === IntegrationTypes.Ebay && <BetaLabel />}
          </Box>
        }
      />
      <RadioButton
        disabled={disabled}
        className={clsx(classes.root, classes.radioBtn)}
        checked={isChecked}
        data-testid={`radioButton${testId}`}
      />
      <MuiCardActions
        className={classes.expandControl}
        disableSpacing
        onClick={handleExpandClick}
        aria-expanded={expanded}
        aria-label="show more"
        data-testid={`learnMore${testId}`}
      >
        <Typography variant="caption" color={expanded ? 'secondary.main' : 'text.secondary'}>
          {t('exportCard.text')}
        </Typography>
        <Iconography
          iconName="expand-chevron"
          className={clsx(expanded ? [classes.expandIconOpen] : [classes.expandIcon])}
        />
      </MuiCardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </MuiCard>
  );
};

export default ExportCard;
