import React, { Suspense } from 'react';
import ReactGA from 'react-ga4';

import { LinearProgress } from '@material-ui/core';

import { SnackbarProvider } from './providers/snackbar';
import routes from './routes';
import RenderRoutes from './utils/RenderRoutes';

if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID as string);
}

function App(): React.ReactElement {
  return (
    <Suspense fallback={<LinearProgress />}>
      <SnackbarProvider>
        <RenderRoutes routes={routes} />
      </SnackbarProvider>
    </Suspense>
  );
}

export default App;
