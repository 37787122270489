import { useMemo } from 'react';

import { DuplicatedSku, DuplicatedSkuInfo, IntegrationMappingError, Reason } from '../utils/gql';

const useDuplicatedSKUFromIntegrationMappingErrors = (errors?: IntegrationMappingError[]): DuplicatedSkuInfo => {
  const duplicatedSKU: DuplicatedSku[] = useMemo(
    () =>
      errors?.reduce((acc: DuplicatedSku[], { productTypeId, productItemId, metadata, reason }) => {
        if (reason === Reason.DuplicatedSku && productItemId && productTypeId && metadata?.name) {
          acc.push({ itemId: productItemId, productTypeId, name: metadata?.name });
        }

        return acc;
      }, []) || [],
    [errors],
  );

  return {
    isDuplicated: Boolean(duplicatedSKU.length),
    duplicatedSKU,
  };
};

export default useDuplicatedSKUFromIntegrationMappingErrors;
