import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Checkbox, makeStyles, Theme, Tooltip, Typography } from '@material-ui/core';

import useOnboarding from 'src/providers/OnboardingProvider/hooks/useOnboarding';
import { IntegrationTypes } from 'src/utils/gql';
import { IntegrationAction } from 'src/views/UserSettings/types';

import { WOOCOMMERCE_API_LINK } from '../../constants';
import Iconography from '../Iconography';

import ShopUrlInput from './FormInputs/ShopUrlInput';
import TextInput from './FormInputs/TextInput';
import { StyledFormControlLabel } from './styled';
import { AddEditIntegrationsProps } from './types';

const useStyles = makeStyles((theme: Theme) => ({
  tooltip: {
    width: '193px',
    padding: '10px 18px',
    fontFamily: 'Roboto',
    boxSizing: 'border-box',
    backgroundColor: theme.palette.background.default,
    fontSize: '12px',
    lineHeight: '14px',
    color: theme.palette.text.secondary,
  },
  arrow: {
    color: theme.palette.background.default,
  },
  iconWrapper: {
    cursor: 'pointer',
    zIndex: 1,
  },
}));

const WooCommerceForm = ({ action, onChangeDefault, isDefaultIntegration }: AddEditIntegrationsProps) => {
  const { t } = useTranslation();
  const tooltipText = t('userSettingsPage.integrations.popup.tooltip');
  const muiClasses = useStyles();
  const {
    onboardingState: { tourActive },
  } = useOnboarding();

  return (
    <Box display="flex" alignItems="center" flexDirection="column" width="496px">
      <Box display="flex" justifyContent="center" alignItems="center" mb="15px" mt="50px">
        <Typography variant="subtitle1" mr="10px">
          {t('userSettingsPage.integrations.popup.contentTitle', { integration: 'WooCommerce' })}
        </Typography>

        {tourActive && <Box position="absolute" top="45px" width="340px" height="400px" id="integration-info-anchor" />}

        <Tooltip
          title={tooltipText}
          arrow
          placement="top"
          classes={{ tooltip: muiClasses.tooltip, arrow: muiClasses.arrow }}
        >
          <a className={muiClasses.iconWrapper} href={WOOCOMMERCE_API_LINK} target="_blank" rel="noreferrer">
            <Iconography iconName="help-fill" htmlColor="secondary" />
          </a>
        </Tooltip>
      </Box>

      <Box width="288px">
        <form aria-label="form" autoComplete="off">
          <Box mt="15px" mb="25px" width="100%">
            <TextInput
              label={t('userSettingsPage.integrations.inputLabel.name')}
              name="integrationName"
              shouldFocus
              variant="outlined"
              inputNameTestId="integrationNameWooField"
              readOnly={tourActive}
            />
          </Box>

          <Box mb="25px" width="100%">
            <ShopUrlInput
              label={t('userSettingsPage.integrations.inputLabel.url')}
              name="url"
              disabled={action === IntegrationAction.Edit}
              inputNameTestId="urlWooField"
              readOnly={tourActive}
              integrationType={IntegrationTypes.Woocommerce}
            />
          </Box>

          <Box mb="25px" width="100%">
            <TextInput
              label={t('userSettingsPage.integrations.inputLabel.apiKey')}
              name="apiKey"
              inputNameTestId="apiKeyWooField"
              readOnly={tourActive}
            />
          </Box>

          <Box mb={tourActive ? '0px' : '10px'} width="100%">
            <TextInput
              label={t('userSettingsPage.integrations.inputLabel.apiSecret')}
              name="apiSecret"
              inputNameTestId="apiSecretWooField"
              readOnly={tourActive}
            />
          </Box>

          {!tourActive && (
            <Box display="flex" textAlign="left" mt="26px" alignItems="center">
              <StyledFormControlLabel
                control={<Checkbox name="isDefault" onChange={onChangeDefault} checked={isDefaultIntegration} />}
                label={t('userSettingsPage.integrations.popup.saveAsDefaultWoo')}
              />
            </Box>
          )}
        </form>
      </Box>
    </Box>
  );
};

export default WooCommerceForm;
