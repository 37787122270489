import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Card, IconButton, Link, Typography } from '@material-ui/core';
import { makeStyles, Theme, experimentalStyled as styled } from '@material-ui/core/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

import { StripeCard } from 'src/utils/gql';

import Iconography from '../Iconography';
import { points } from '../UserSetttingsTable';

interface PaymentMethodCardStyleProps {
  error: boolean;
}

const StyledCard = styled(Card)({
  '& .MuiIconButton-root': {
    display: 'none',
  },

  '&:hover': {
    cursor: 'initial',

    '& .MuiIconButton-root': {
      display: 'initial',
    },
  },
});

const useStyles = makeStyles<Theme, PaymentMethodCardStyleProps>((theme: Theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '25px 30px',
    borderRadius: 20,
    width: 392,
    height: 109,
    margin: '0 24px 24px 0',
    background: theme.palette.common.white,
    border: '1px solid',
    borderColor: ({ error }) => (error ? theme.palette.error.main : theme.palette.common.white),
  },
  checkIcon: {
    width: 20,
    height: 20,
  },
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: 15,
  right: 15,

  svg: {
    height: 20,
    width: 20,
  },

  '&:hover': {
    background: theme.palette.background.default,
  },
}));

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: theme.palette.error.light,
    borderRadius: 5,
    padding: '10px 20px',
    width: 270,
  },

  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.error.light,
  },
}));

interface PaymentMethodCardProps {
  cardInfo: StripeCard;
  defaultPaymentMethod: boolean;
  error?: boolean;
  paymentMethodId: string;
  onDeletePaymentMethod: (id: string) => void;
  onChangeDefaultPaymentMethod: (id: string) => void;
}

const PaymentMethodCard = ({
  paymentMethodId,
  cardInfo: { brand, last4 },
  defaultPaymentMethod = false,
  error = false,
  onDeletePaymentMethod,
  onChangeDefaultPaymentMethod,
}: PaymentMethodCardProps) => {
  const { t } = useTranslation();
  const classes = useStyles({ error });

  return (
    <StyledCard className={classes.root}>
      <Box display="flex" alignItems="center">
        <img src={`${process.env.PUBLIC_URL}/images/cards/${brand}.svg`} alt="cardIcon" />

        <Typography variant="subtitle2" ml="15px" mr="7px">
          {`${points}${last4}`}
        </Typography>

        {error && (
          <StyledTooltip
            arrow
            placement="top"
            title={
              <Typography color="error" fontSize="12px" lineHeight="14px">
                {t('userSettingsPage.billing.paymentMethodCard.errorMessage')}
                <Link href="#" color="error" underline="always">
                  {t('userSettingsPage.billing.paymentMethodCard.support')}
                </Link>
                .
              </Typography>
            }
          >
            <Box display="flex">
              <Iconography iconName="cancel-circle" color="secondary" />
            </Box>
          </StyledTooltip>
        )}
      </Box>

      {defaultPaymentMethod ? (
        <Box display="flex" alignItems="center">
          <Iconography className={classes.checkIcon} iconName="check-outline" color="secondary" />

          <Typography variant="subtitle2" ml="7px">
            {t('userSettingsPage.billing.paymentMethodCard.default')}
          </Typography>
        </Box>
      ) : (
        <Box>
          <Link
            component="button"
            variant="subtitle2"
            color="text.disabled"
            underline="hover"
            onClick={() => onChangeDefaultPaymentMethod(paymentMethodId)}
          >
            {t('userSettingsPage.billing.paymentMethodCard.makeDefault')}
          </Link>
        </Box>
      )}

      <CloseButton
        size="small"
        onClick={() => onDeletePaymentMethod(paymentMethodId)}
        data-testid="deleteButtonPaymentMethodCard"
      >
        <Iconography iconName="cancel" htmlColor="secondary" />
      </CloseButton>
    </StyledCard>
  );
};

export default PaymentMethodCard;
