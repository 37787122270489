import { Catalog, Integration, IntegrationMappingError } from './gql';

export const getUnSyncableIntegrations = (catalog: Catalog) => {
  const unSyncableErrors = catalog.mappingsErrors?.reduce((acc: { [id: string]: IntegrationMappingError }, error) => {
    if (!error.syncable) {
      acc[error.integrationId] = error;
    }
    return acc;
  }, {});
  const unSyncableIntegrations = catalog.integrations?.reduce((acc: Integration[], integration) => {
    if (unSyncableErrors?.[integration.id]) {
      acc.push(integration);
    }
    return acc;
  }, []);
  return unSyncableIntegrations;
};
