import React, { useEffect, useState, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useApolloClient } from '@apollo/client';
import { Box, IconButton, Tooltip, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { linkPricingPlan } from 'src/constants';
import useIntegrationHealthCheck, { IntegrationErrors } from 'src/hooks/useIntegrationHealthCheck';
import { clearLocalCatalogsCache } from 'src/providers/catalog/provider';
import { CatalogsJobsMap } from 'src/providers/catalog/types';
import { CatalogJobStatus, Integration, IntegrationTypes, Reason } from 'src/utils/gql';
import { IntegrationAction } from 'src/views/UserSettings/types';

import BetaLabel from '../BetaLabel';
import { StyledUpgradeLink } from '../Headers/styled';
import Hint from '../Hint';
import Iconography from '../Iconography';
import Loader, { LoaderSize } from '../Loader';
import Popup from '../Popup';
import { DeletePopupState, initialDeletePopupState } from '../Popups/IntegrationPopup/types';
import RadioButton from '../RadioButton';
import SmallAddButton from '../SmallAddButton';

import {
  EbayBackground,
  ShopifyBackground,
  WooCommerceBackground,
  FacebookAndInstagramBackground,
} from './IntegrationBackgrounds';

interface StyleProps {
  isOpen: boolean;
}

const integrationBackground = (type: string, classes: Record<string, string>, isOpen: boolean) => {
  switch (type) {
    case IntegrationTypes.Shopify:
      return (
        <ShopifyBackground
          fill={`rgba(85, 139, 47, ${isOpen ? '0.08' : '0.05'})`}
          className={classes.integrationBgImage}
        />
      );
    case IntegrationTypes.Woocommerce:
      return (
        <WooCommerceBackground
          fill={`rgb(123, 81, 173${isOpen ? '' : ', 0.7'}`}
          className={classes.integrationBgImage}
        />
      );
    case IntegrationTypes.Ebay:
      return <EbayBackground opacity={isOpen ? 0.2 : 0.1} className={classes.integrationBgImage} />;
    case IntegrationTypes.Facebook:
      return <FacebookAndInstagramBackground opacity={isOpen ? 0.2 : 0.1} className={classes.integrationBgImage} />;
    default:
      return null;
  }
};

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: {
    borderRadius: '20px',
    width: '100%',
    boxShadow: '0 2px 10px rgba(46, 96, 170, 0.25)',
    maxHeight: ({ isOpen }) => (isOpen ? 'none' : '109px'),
    overflow: 'hidden',

    '&:hover': {
      boxShadow: '0 2px 15px rgba(241, 97, 82, 0.35)',
    },
  },
  card: {
    display: 'flex',
    minWidth: 280,
    height: 109,
    position: 'relative',
    cursor: 'pointer',

    borderRadius: '20px',
    borderBottomRightRadius: ({ isOpen }) => (isOpen ? 0 : '20px'),
    borderBottomLeftRadius: ({ isOpen }) => (isOpen ? 0 : '20px'),
    borderBottom: ({ isOpen }) => (isOpen ? 'solid 1px #F2F2F2' : 'none'),
    background: theme.palette.common.white,
    padding: '26px 19px 23px 25px',
  },
  integrationsCount: {
    fontSize: '15px',
    lineHeight: '17px',
    color: ({ isOpen }) => (isOpen ? theme.palette.text.primary : theme.palette.text.disabled),
    position: 'relative',
    zIndex: 1,
  },
  integrationsList: {
    background: theme.palette.common.white,
    borderBottomRightRadius: '20px',
    borderBottomLeftRadius: '20px',
  },
  integrationItem: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    padding: '17px 18px 20px 25px',
    borderBottom: '1px solid',
    borderBottomColor: '#f2f2f2',

    '&:last-child': {
      borderBottomColor: 'transparent',
    },
  },
  integrationName: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '15px',
    maxWidth: '230px',
    marginRight: '5px',
  },
  expandIcon: {
    color: ({ isOpen }) => (isOpen ? theme.palette.text.primary : theme.palette.text.disabled),
    transform: ({ isOpen }) => (isOpen ? 'rotate(180deg)' : ''),
  },
  integrationBgImage: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    zIndex: 0,
    pointerEvents: 'none',
  },
  tooltip: {
    top: '5px',
    maxWidth: '362px',
    padding: '10px 20px',
    fontFamily: 'Roboto',
    fontWeight: '400',
    backgroundColor: theme.palette.common.white,
    fontSize: '12px',
    lineHeight: '14px',
    color: theme.palette.primary.light,
    boxShadow: '0 2px 10px rgba(46, 96, 170, 0.25)',
  },
  arrow: {
    color: theme.palette.common.white,
    fontSize: '21px',
  },
  integrationNamePopup: {
    maxWidth: '183px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'inline-block',
  },
  popupDescription: {
    maxWidth: '296px',
    margin: '0 auto 40px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '& .MuiTypography-root': {
      color: theme.palette.text.secondary,
    },
  },
  disabledDeleteIcon: {
    '& svg': {
      color: theme.palette.action.disabled,
    },
  },
  errorLink: {
    cursor: 'pointer',
    marginLeft: '2px',
    textDecoration: 'underline',
  },
  error: {
    display: 'flex',
  },
}));

interface IntegrationCardProps {
  setDeletePopupState?: (state: DeletePopupState) => void;
  deletePopupState?: DeletePopupState;
  integrations: Integration[];
  type: IntegrationTypes;
  onAddEditButtonClick?: (integrationType: IntegrationTypes, action: number, integration?: Integration) => void;
  onDeleteButtonClick?: (id: string) => void;
  onClickRadioButton?: (integrationId: string) => void;
  loadingDeleteIntegration?: boolean;
  loadingChangeDefaultIntegration?: boolean;
  editableCard?: boolean;
  hasMarginBottom?: boolean;
  selectedIntegrationId?: string;
  onSelectIntegration?: (state: string) => void;
  isLimit?: boolean;
  addNewIntegrationButton?: string;
  catalogsJobs?: CatalogsJobsMap;
  refetchIntegrations?: () => Promise<void>;
  refetchIntegrationsLoading?: boolean;
}

export const getIntegrationTitleByType = (type: IntegrationTypes) => {
  switch (type) {
    case IntegrationTypes.Shopify:
      return 'Shopify';
    case IntegrationTypes.Woocommerce:
      return 'WooCommerce';
    case IntegrationTypes.Ebay:
      return 'eBay';
    case IntegrationTypes.Facebook:
      return 'Facebook + Instagram';
  }
};

export const getIntegrationUrl = (type: IntegrationTypes, settings: { [x: string]: string }) => {
  switch (type) {
    case IntegrationTypes.Shopify:
      return settings.shop;
    case IntegrationTypes.Woocommerce:
      return settings.url;
    case IntegrationTypes.Ebay:
      return ''; // TODO: add ebay url from integration when it is implemented
  }
};

const IntegrationCard = ({
  refetchIntegrations,
  refetchIntegrationsLoading,
  setDeletePopupState,
  deletePopupState,
  integrations,
  type,
  onAddEditButtonClick,
  onDeleteButtonClick,
  onClickRadioButton,
  loadingDeleteIntegration,
  loadingChangeDefaultIntegration,
  editableCard = false,
  hasMarginBottom = true,
  selectedIntegrationId = '',
  onSelectIntegration,
  isLimit,
  addNewIntegrationButton,
  catalogsJobs,
}: IntegrationCardProps) => {
  const { cache } = useApolloClient();
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const classes = useStyles({ isOpen });

  const handleClosePopup = () => setDeletePopupState?.(initialDeletePopupState);

  const { checkIntegrationStatus, integrationErrors, loadingIntegrationHealthCheck } = useIntegrationHealthCheck();

  const integrationUnavailableErrors = useMemo(
    () =>
      integrations.reduce((acc: IntegrationErrors, { id, mappingsErrors }) => {
        const integrationUnavailableError = mappingsErrors?.find(
          ({ reason }) => reason === Reason.IntegrationUnavailable,
        );

        if (integrationUnavailableError) {
          acc[id] = true;
        }

        return acc;
      }, {}),
    [integrations],
  );

  const errorsMap = { ...integrationErrors, ...integrationUnavailableErrors };

  const errorHintTitle = (integrationId: string) => (
    <Typography component="span" variant="inherit" color="inherit">
      {t('cataloguesPage.chooseExportPopup.errorHint')}
      <Typography
        onClick={() =>
          checkIntegrationStatus({
            integrationId,
            mainCallbackFn: () => {
              clearLocalCatalogsCache(cache);
              refetchIntegrations?.();
            },
          })
        }
        className={classes.errorLink}
        component="span"
        variant="inherit"
        color="inherit"
      >
        {t('cataloguesPage.chooseExportPopup.errorHintLink')}
      </Typography>
    </Typography>
  );

  const checkJobsProgress = useMemo(() => {
    return (integrationId: string) => {
      if (catalogsJobs) {
        const allCatalogsJobs = Object.values(catalogsJobs);
        const jobInProgressArray = [CatalogJobStatus.Active, CatalogJobStatus.Pause, CatalogJobStatus.Pending];

        return allCatalogsJobs.some(
          (job) => job[integrationId] && jobInProgressArray.includes(job[integrationId].status),
        );
      }

      return false;
    };
  }, [catalogsJobs]);

  useEffect(() => {
    if (!integrations.length) {
      setIsOpen(false);
    }
  }, [integrations.length]);

  const handleOpen = () => (integrations.length ? setIsOpen((prev) => !prev) : null);

  return (
    <Box className={classes.root} mb={hasMarginBottom ? '15px' : '0'}>
      <Box onClick={handleOpen} className={classes.card} data-testid={`${type}Сard`}>
        <Box display="flex" flexDirection="column" justifyContent="space-between" width="100%">
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex" alignItems="center">
              {integrationBackground(type, classes, isOpen)}
              <Hint
                type="hover"
                tooltipWidth="256px"
                title={t('facebook.hint') || ''}
                placement="top"
                doNotShow={type !== IntegrationTypes.Facebook}
              >
                <Typography fontSize="15px" fontWeight="500" lineHeight="18px" mr="5px">
                  {getIntegrationTitleByType(type)}
                </Typography>
              </Hint>
              {type === IntegrationTypes.Ebay && <BetaLabel />}
            </Box>

            {editableCard && (
              <Tooltip
                title={
                  isLimit ? (
                    <Trans i18nKey="userSettingsPage.integrations.popup.limitReachedTooltip">
                      Integration number has reached the limit.
                      <StyledUpgradeLink href={linkPricingPlan}>Upgrade your plan</StyledUpgradeLink>
                      to create an unlimited number of integration or delete some integrations to create new ones.
                    </Trans>
                  ) : (
                    ''
                  )
                }
                arrow
                placement="top"
                classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
              >
                <Box
                  mr="3px"
                  role="button"
                  tabIndex={0}
                  onClick={(e: React.MouseEvent<HTMLDivElement | HTMLAnchorElement>) => e.stopPropagation()}
                  data-testid={addNewIntegrationButton}
                >
                  <SmallAddButton
                    isNewDesign
                    onClick={() => onAddEditButtonClick?.(type, IntegrationAction.Add)}
                    disabled={isLimit}
                  />
                </Box>
              </Tooltip>
            )}
          </Box>

          <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" height="18px">
            <Typography className={classes.integrationsCount}>
              {!!integrations.length
                ? t('userSettingsPage.integrations.card.integrationsCount', {
                    integration: getIntegrationTitleByType(type),
                    count: integrations.length,
                  })
                : t('userSettingsPage.integrations.card.noIntegrations')}
            </Typography>

            {!!integrations.length && (
              <IconButton size="small">
                <Iconography className={classes.expandIcon} iconName="expand-chevron" />
              </IconButton>
            )}
          </Box>
        </Box>
      </Box>

      {isOpen && (
        <Box className={classes.integrationsList}>
          {editableCard ? (
            <>
              {integrations.map((integration) => (
                <Box
                  key={integration.id}
                  className={classes.integrationItem}
                  data-testid={`${integration.settings.integrationName}IntegrationBlock`}
                >
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box display="flex" justifyContent="center" alignItems="center">
                      <Typography variant="body2" className={classes.integrationName}>
                        {integration.settings.integrationName}
                      </Typography>

                      {loadingIntegrationHealthCheck || refetchIntegrationsLoading ? (
                        <Loader size={LoaderSize.extraSmall} />
                      ) : (
                        errorsMap?.[integration.id] && (
                          <Hint
                            type="hover"
                            title={errorHintTitle(integration.id)}
                            tooltipWidth="168px"
                            placement="left"
                          >
                            <span className={classes.error}>
                              <Iconography iconName="cancel-circle" color="error" data-testid="errorIcon" />
                            </span>
                          </Hint>
                        )
                      )}
                    </Box>

                    <Box color="primary" display="flex">
                      <IconButton
                        size="small"
                        onClick={() => onAddEditButtonClick?.(type, IntegrationAction.Edit, integration)}
                        sx={{ marginRight: '10px' }}
                        data-testid={`settingsIntegrationButton${integration.settings.integrationName}`}
                      >
                        <Iconography iconName="settings" color="primary" />
                      </IconButton>
                      <Hint
                        title={`${t('userSettingsPage.integrations.popup.warningTooltip')}`}
                        placement="top"
                        type="hover"
                        doNotShow={!checkJobsProgress(integration.id)}
                      >
                        <Box>
                          <IconButton
                            size="small"
                            disabled={loadingDeleteIntegration || checkJobsProgress(integration.id)}
                            className={checkJobsProgress(integration.id) ? classes.disabledDeleteIcon : undefined}
                            onClick={() =>
                              setDeletePopupState?.({
                                integrationId: integration?.id,
                                isOpen: true,
                                name: integration?.settings.integrationName,
                                buttonLoading: false,
                              })
                            }
                            data-testid={`deleteIntegrationButton${integration.settings.integrationName}`}
                          >
                            <Iconography iconName="trash-fill" color="primary" />
                          </IconButton>
                        </Box>
                      </Hint>
                    </Box>
                  </Box>

                  <Box display="flex" alignItems="center" mt="4px">
                    <RadioButton
                      checked={integration.isDefault}
                      disabled={loadingChangeDefaultIntegration}
                      onClick={() => onClickRadioButton?.(integration.id)}
                      data-testid={`radioButtonMakeDefault${integration.settings.integrationName}`}
                    />

                    <Typography variant="caption" color="secondary" ml="10px" mt="2px">
                      {t('userSettingsPage.integrations.card.makeDefault')}
                    </Typography>
                  </Box>

                  {getIntegrationUrl(type, integration.settings) && (
                    <Typography variant="body2" color="text.secondary" mt="11px">
                      {getIntegrationUrl(type, integration.settings)}
                    </Typography>
                  )}
                </Box>
              ))}
            </>
          ) : (
            <>
              {integrations.map(({ id, settings, type }) => (
                <Box onClick={() => onSelectIntegration?.(id)} key={id} className={classes.integrationItem}>
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box display="flex" flexDirection="column" alignItems="flex-start">
                      <Typography variant="body2" className={classes.integrationName}>
                        {settings.integrationName}
                      </Typography>

                      {getIntegrationUrl(type, settings) && (
                        <Typography variant="body2" className={classes.integrationName} color="text.secondary" mt="9px">
                          {getIntegrationUrl(type, settings)}
                        </Typography>
                      )}
                    </Box>

                    <Box mr="7px">
                      <RadioButton
                        checked={selectedIntegrationId === id}
                        onChange={() => onSelectIntegration?.(id)}
                        data-testid={`radioButtonCheck${settings.integrationName}`}
                      />
                    </Box>
                  </Box>
                </Box>
              ))}
            </>
          )}
        </Box>
      )}

      <Popup
        mainButtonsMarginAbsence
        open={Boolean(deletePopupState?.isOpen)}
        mainTitle={t('userSettingsPage.integrations.deletePopup.mainTitle')}
        mainButtonText={t('userSettingsPage.integrations.deletePopup.mainButtonText')}
        secondaryButtonText={t('userSettingsPage.integrations.deletePopup.secondaryButtonText')}
        loadingOnMainButton={deletePopupState?.buttonLoading}
        onMainButtonClick={() => onDeleteButtonClick?.(deletePopupState?.integrationId as string)}
        onSecondaryButtonClick={handleClosePopup}
        onClose={handleClosePopup}
      >
        <Box className={classes.popupDescription}>
          <Typography>{t('userSettingsPage.integrations.deletePopup.firstPartDescription')}</Typography>
          <Typography display="flex">
            <Typography component="span" className={classes.integrationNamePopup}>
              {t('userSettingsPage.integrations.deletePopup.integrationName', { name: deletePopupState?.name || '' })}
            </Typography>
            <Typography component="span">
              {t('userSettingsPage.integrations.deletePopup.secondPartDescription')}
            </Typography>
          </Typography>
          <Typography>{t('userSettingsPage.integrations.deletePopup.warningDescription')}</Typography>
        </Box>
      </Popup>
    </Box>
  );
};

export default IntegrationCard;
