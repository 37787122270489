import * as yup from 'yup';

import {
  EMAIL_PATTERN,
  EXCEEDED_LIMIT_VALUE,
  MEDIA_URL_VALIDATION,
  SHOP_URL_VALIDATION,
  SKU_PATTERN,
} from 'src/constants';

const passwordWithRequirements = yup
  .string()
  .required()
  .matches(/[A-Z]/)
  .matches(/[0-9]/)
  .matches(/[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/)
  .matches(/^(?=.{8,})/)
  .max(EXCEEDED_LIMIT_VALUE, 'exceededLimitValue');

const email = yup
  .string()
  .required('enterEmailError')
  .matches(EMAIL_PATTERN, 'enterValidEmailError')
  .max(EXCEEDED_LIMIT_VALUE, 'exceededLimitValue');

const integrationName = yup
  .string()
  .required('catalogueCreationPage.thirdStep.wooCommerce.enterIntegrationNameError')
  .max(EXCEEDED_LIMIT_VALUE, 'exceededLimitValue');

export const signInSchema = yup
  .object()
  .shape({
    email,
    password: yup.string().required('enterPasswordError').max(EXCEEDED_LIMIT_VALUE, 'exceededLimitValue'),
    rememberMe: yup.bool(),
  })
  .required();

export const signUpFormSchema = yup
  .object()
  .shape({
    email,
    password: passwordWithRequirements,
    name: yup.string().required('enterNameError').max(EXCEEDED_LIMIT_VALUE, 'exceededLimitValue'),
  })
  .required();

export const setNewPasswordFormSchema = yup
  .object()
  .shape({
    password: passwordWithRequirements,
  })
  .required();

export const forgotPasswordFormSchema = yup
  .object()
  .shape({
    email,
  })
  .required();

export const resendEmailFormSchema = forgotPasswordFormSchema;

export const shopifySignupForm = yup
  .object()
  .shape({
    email,
    storeName: yup.string().required('enterShopifyStoreError'),
  })
  .required();

export const copyProductItemForm = yup.object().shape({
  sku: yup
    .string()
    .nullable()
    .test('is-sku-valid', 'productItemCreateEdit.sidebar.skuHelperText', (value) => !value || SKU_PATTERN.test(value)),
});

export const wooCommerceFormSchema = yup
  .object()
  .shape({
    integrationName,
    url: yup
      .string()
      .required('catalogueCreationPage.thirdStep.wooCommerce.enterURLerror')
      .matches(SHOP_URL_VALIDATION, 'userSettingsPage.integrations.inputLabel.notValidURL')
      .max(EXCEEDED_LIMIT_VALUE, 'exceededLimitValue'),
    apiKey: yup
      .string()
      .required('catalogueCreationPage.thirdStep.wooCommerce.enterAPIKeyError')
      .max(EXCEEDED_LIMIT_VALUE, 'exceededLimitValue'),
    apiSecret: yup
      .string()
      .required('catalogueCreationPage.thirdStep.wooCommerce.enterAPISecretError')
      .max(EXCEEDED_LIMIT_VALUE, 'exceededLimitValue'),
  })
  .required();

export const shopifyIntegrationFormSchema = yup
  .object()
  .shape({
    integrationName,
    shop: yup.string().required('').matches(SHOP_URL_VALIDATION).max(EXCEEDED_LIMIT_VALUE, 'exceededLimitValue'),
  })
  .required();

export const facebookIntegrationFormSchema = yup
  .object()
  .shape({
    integrationName,
  })
  .required();

export const ebayIntegrationFormSchema = yup
  .object()
  .shape({
    integrationName,
    marketplaceId: yup.string().required(),
    paymentPolicyId: yup.string().required(),
    returnPolicyId: yup.string().required(),
    fulfillmentPolicyId: yup.string().required(),
    merchantLocationKey: yup.string().required(),
  })
  .required();

export const userNameFormSchema = yup
  .object()
  .shape({
    userName: yup
      .string()
      .required('userSettingsPage.account.profile.emptyNameError')
      .max(EXCEEDED_LIMIT_VALUE, 'exceededLimitValue'),
  })
  .required();

export const integrationUrlFormSchema = yup
  .object()
  .shape({
    integrationUrl: yup.string().required('mappingSidebar.itemMapping.content.integrationUrlRequired'),
  })
  .required();

export const itemMappingEbayFormSchema = yup
  .object()
  .shape({
    siteId: yup.string().required('mappingSidebar.itemMapping.content.siteId'),
    integrationUrl: yup.string().required('mappingSidebar.itemMapping.content.integrationUrlRequired'),
  })
  .required();

export const addPaymentCardFormSchema = yup
  .object()
  .shape({
    cardholderName: yup
      .string()
      .required('userSettingsPage.billing.addPaymentMethodPopup.cardholderNameError')
      .max(255, 'exceededLimitValue'),
  })
  .required();

export const apiKeyNameFormSchema = yup.object().shape({
  apiKeyName: yup
    .string()
    .required('userSettingsPage.account.profile.emptyNameError')
    .max(EXCEEDED_LIMIT_VALUE, 'exceededLimitValue'),
});

export const urlMediaSchema = yup.object().shape({
  urlMedia: yup
    .string()
    .matches(MEDIA_URL_VALIDATION, 'productItemCreateEdit.mediaGallery.addUrlPopup.invalidUrl')
    .max(EXCEEDED_LIMIT_VALUE, 'exceededLimitValue'),
});

export const productTypeNameFormSchema = yup
  .object()
  .shape({
    productName: yup
      .string()
      .required('userSettingsPage.account.profile.emptyNameError')
      .max(EXCEEDED_LIMIT_VALUE, 'exceededLimitValue'),
  })
  .required();
