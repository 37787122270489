import { Box, styled, Typography } from '@mui/material';

export const ProductTypesMappingsContainer = styled(Box)`
  margin-top: 25px;
  display: grid;
  grid-template-columns: 1fr 40px 1fr 16px;
  grid-column-gap: 10px;
  grid-row-gap: 20px;
`;

export const IntegrationProductTypesTitle = styled(Typography)`
  grid-column-start: 3;
`;

export const OtomateProductTypeSelectWrapper = styled(Box)`
  grid-column-start: 1;
`;

export const StyledMarketPlaceWrapper = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 23px;
`;

export const FieldsMappingsContainer = styled(Box)`
  display: grid;
  grid-column-start: 1;
  grid-column-end: 5;
  margin-bottom: 10px;
`;
