import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import { useMutation } from '@apollo/client';
import { Typography } from '@material-ui/core';

import ResendEmailForm from 'src/components/Forms/ResendEmailForm';
import Page from 'src/components/Page';
import { useSnackbar } from 'src/providers/snackbar';
import { resendConfirmation } from 'src/utils/gql';

import ResendEmailImageBackground from './ResendEmailImageBackground';
import { CheckEmailContainer, ResendScreenSideContainer, StyledResendContainer } from './styled';

const ResendScreen = () => {
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const [isImageLoading, setIsImageLoading] = useState(true);
  const [resendRegistrationConfirmation, { loading }] = useMutation(resendConfirmation);
  const { state } = useLocation<{ userEmail: string }>();
  const userEmail = state?.userEmail ?? localStorage.getItem('_email') ?? '';

  const handleSubmit = async (email: string) => {
    await resendRegistrationConfirmation({ variables: { email } });
  };

  return (
    <Page title={t('resendEmail.title')}>
      <StyledResendContainer>
        <CheckEmailContainer>
          {['titlePart1', 'titlePart2'].map((title, idx) => (
            <Typography variant="h1" textAlign="center" width="537px" key={idx}>
              {t(`resendEmail.${title}`)}
            </Typography>
          ))}

          {userEmail && (
            <Typography variant="body1" color="textSecondary" textAlign="center" mt="40px" mb="10px">
              {t('resendEmail.part1')}
              <Typography component="span" variant="subtitle1" color="textSecondary">
                {userEmail}
              </Typography>
            </Typography>
          )}

          <Typography
            variant="body1"
            color="textSecondary"
            textAlign="center"
            mt={userEmail ? '0' : '40px'}
            mb="40px"
            maxWidth="456px"
          >
            {t('resendEmail.part2')}
          </Typography>

          <ResendEmailForm onError={snackbar} loading={loading} onSubmit={handleSubmit} />
        </CheckEmailContainer>
        <ResendScreenSideContainer>
          {isImageLoading && <ResendEmailImageBackground />}
          <img
            src={`${process.env.PUBLIC_URL}/images/resendEmail.webp`}
            alt="ResendEmail"
            onLoad={() => setIsImageLoading(false)}
            width="687"
            height="451"
          />
        </ResendScreenSideContainer>
      </StyledResendContainer>
    </Page>
  );
};

export default ResendScreen;
