import React, { useRef, useState } from 'react';
import ReactPlayer from 'react-player';

import { Box, makeStyles, Theme, IconButton, Typography, CardMedia } from '@material-ui/core';
import Checkbox from '@mui/material/Checkbox';

import { useCustomMediaPlayerButtons } from 'src/hooks/useCustomMediaPlayerButtons';
import { MediaType } from 'src/utils/gql';
import { getFileExpansion, getFileNameFromUrl } from 'src/views/Catalogs/ProductItems/functions';

import Iconography from '../Iconography';
import Loader from '../Loader';

import { ItemsMediaGalleryProps } from './constants';
import { documentPicture } from './functions';
import { Container, MediaContainer } from './styled';
import Video from './Video';

interface ItemsMediaGalleryStyledProps {
  itemState?: boolean;
  isVideoType: boolean;
  created?: boolean;
  showControls: boolean;
  playing?: boolean;
}

const useStyles = makeStyles<Theme, ItemsMediaGalleryStyledProps>((theme) => ({
  header: {
    cursor: 'pointer',

    '&:hover img': {
      transition: '0.2s',
      opacity: ({ isVideoType, created }: { isVideoType: boolean; created?: boolean }) =>
        isVideoType && created ? 0.9 : 0.7,
    },
    '&:hover #videoContainer': {
      transition: '0.2s',
      opacity: ({ showControls, itemState, playing }: ItemsMediaGalleryStyledProps) =>
        (showControls || playing) && !itemState ? 1 : 0.3,
    },
    '&:hover p': {
      opacity: 0.7,
    },
  },
  checkBoxContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '12px',
    marginLeft: '12px',
    position: 'absolute',
    zIndex: 1,
    visibility: ({ itemState }) => (itemState ? 'visible' : 'hidden'),
    '& span,button': {
      width: '16px',
    },
  },
  checkBox: {
    background: theme.palette.common.white,
    color: theme.palette.secondary.dark,
    borderRadius: 0,
    transform: 'scale(0.889)',
    margin: 0,

    '&:hover': {
      background: theme.palette.common.white,
    },

    '&.Mui-checked': {
      color: theme.palette.secondary.dark,
    },

    '&:hover.MuiCheckbox-root.Mui-checked': {
      background: theme.palette.common.white,
    },

    '& svg': {
      fontSize: '1.7rem',
    },
  },

  image: {
    transition: '0.2s',
    textAlign: 'center',
    opacity: ({ itemState, created }) => (itemState && created ? 0.7 : 1),
    height: '100%',
  },
  document: {
    display: 'flex',
    justifyContent: 'space-evenly',
    height: '100%',
    flexDirection: 'column',

    '& img': {
      transition: '0.2s',
      opacity: ({ itemState, created }: { itemState?: boolean; created?: boolean }) => (itemState && created ? 0.7 : 1),
    },
  },
  documentName: {
    transition: '0.2s',
    opacity: ({ itemState, created }) => (itemState && created ? 0.7 : 1),
    textDecorationLine: 'underline',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '14px',
    maxWidth: '119px',
    maxHeight: '63px',
    wordBreak: 'break-all',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    mozBoxOrient: 'vertical',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    webkitBoxOrient: 'vertical',
    'line-clamp': 2,
    boxOrient: 'vertical',
    color: theme.palette.text.primary,
    '&:hover': {
      opacity: 0.7,
    },
  },
  iconButton: {
    padding: '4px 0 0',
    visibility: ({ itemState }) => (itemState ? 'hidden' : 'inherit'),
  },
  media: {
    opacity: 0.7,
  },
}));

const ItemsMediaGallery = ({
  itemState,
  id,
  changeCheckbox,
  type,
  href,
  deleteItem,
  created,
  recedingMedia,
  expansion,
  uploadMediaName,
}: ItemsMediaGalleryProps) => {
  const isVideoType = [MediaType.Video, MediaType.ExternalVideo].includes(type as MediaType);

  const nameMedia = getFileNameFromUrl(href);
  const video = useRef<ReactPlayer>(null);
  const mediaContainer = useRef<HTMLElement>(null);
  const videoMethodsAndProps = useCustomMediaPlayerButtons();
  const { showControls } = videoMethodsAndProps;

  const [playing, setPlaying] = useState<boolean>(false);

  const classes = useStyles({ itemState, isVideoType, created, showControls, playing });

  const mediaTypeCard = (type: string) => {
    switch (type) {
      case MediaType.Image:
        return <CardMedia draggable={false} className={classes.image} src={href} component="img" />;
      case MediaType.Video:
      case MediaType.ExternalVideo:
        return (
          <Video
            url={href}
            video={video}
            methodAndProps={videoMethodsAndProps}
            uploadingOrDeleting={!created || recedingMedia}
            itemState={itemState}
            created={created}
            setPlaying={setPlaying}
          />
        );
      case MediaType.Document:
      case MediaType.Model3D:
        return (
          <Box draggable={false} className={classes.document}>
            {documentPicture(expansion || getFileExpansion(href))}
            <Typography className={classes.documentName}>{uploadMediaName || nameMedia}</Typography>
          </Box>
        );
    }
  };

  return (
    <Container className={created ? classes.header : undefined} data-testid={`${nameMedia}Card`}>
      {created && !recedingMedia && (
        <Box className={classes.checkBoxContainer}>
          <Checkbox
            disableRipple
            className={classes.checkBox}
            id={id}
            size="medium"
            checked={!!itemState}
            onChange={changeCheckbox}
            inputProps={{ 'data-testid': `${nameMedia}Сheckbox` } as React.InputHTMLAttributes<HTMLInputElement>}
          />
          <IconButton className={classes.iconButton} onClick={deleteItem} data-testid={`${nameMedia}DeleteButton`}>
            <Iconography iconName="trash-fill" fontSize="medium" />
          </IconButton>
        </Box>
      )}
      <MediaContainer ref={mediaContainer} className={created && !recedingMedia ? undefined : classes.media}>
        {(!created || recedingMedia) && <Loader size="small" />}
        {mediaTypeCard(type)}
      </MediaContainer>
    </Container>
  );
};

export default ItemsMediaGallery;
