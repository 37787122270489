import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ApolloError, useMutation } from '@apollo/client';
import { Box, Typography } from '@material-ui/core';

import ButtonScrollToTop from 'src/components/ButtonScrollToTop';
import PaymentMethodCard from 'src/components/Card/PaymentMethodCard';
import Iconography from 'src/components/Iconography';
import DeletePopup from 'src/components/Popups/DeletePopup';
import SkeletonPaymentMethodCard from 'src/components/Skeleton/SkeletonPaymentMethodCard';
import AddPaymentMethodPopup from 'src/components/Stripe/AddPaymentMethodPopup';
import BillingInvoicesTable from 'src/components/UserSetttingsTable';
import { useSnackbar } from 'src/providers/snackbar';
import {
  changeDefaultStripePaymentMethod,
  createStripePaymentMethod,
  deleteStripePaymentMethod,
  Mutation,
  MutationChangeDefaultStripePaymentMethodArgs,
  MutationCreateStripePaymentMethodArgs,
  MutationDeleteStripePaymentMethodArgs,
  StripeBalanceWithNextPayment,
  StripeInvoice,
  StripePaymentMethod,
} from 'src/utils/gql';

import Ballance from './Ballance';
import { AddPaymentMethodButton } from './styled';

interface BillingProps {
  paymentMethods?: StripePaymentMethod[];
  balance?: StripeBalanceWithNextPayment;
  invoices?: StripeInvoice[];
  loadingBalance: boolean;
  loading: boolean;
}

const Billing = ({ paymentMethods, invoices, loading = true, balance, loadingBalance = true }: BillingProps) => {
  const { t } = useTranslation();
  const snackbar = useSnackbar();

  const [openPopup, setOpenPopup] = useState(false);
  const [openAddPaymentMethodPopup, setOpenAddPaymentMethodPopup] = useState<boolean>(false);
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState<string>('');

  const [createPaymentMethod] = useMutation<
    Pick<Mutation, 'createStripePaymentMethod'>,
    MutationCreateStripePaymentMethodArgs
  >(createStripePaymentMethod);

  const [deletePaymentMethod, { loading: loadingDeletePaymentMethod }] = useMutation<
    Pick<Mutation, 'deleteStripePaymentMethod'>,
    MutationDeleteStripePaymentMethodArgs
  >(deleteStripePaymentMethod);

  const [changeDefaultPaymentMethod] = useMutation<
    Pick<Mutation, 'deleteStripePaymentMethod'>,
    MutationChangeDefaultStripePaymentMethodArgs
  >(changeDefaultStripePaymentMethod);

  const creditCardError = false; //Temp

  const showSkeletons = loading && !paymentMethods?.length;

  const selectedPaymentMethodLast4 = useMemo(
    () => paymentMethods?.find(({ id }) => id === selectedPaymentMethodId)?.card.last4,
    [paymentMethods, selectedPaymentMethodId],
  );

  const handleOpenAddPaymentMethodPopup = () => {
    setOpenAddPaymentMethodPopup(true);
  };

  const handleCloseAddPaymentMethodPopup = () => {
    setOpenAddPaymentMethodPopup(false);
  };

  const handleOpenDeletePaymentMethodPopup = (id: string) => {
    setSelectedPaymentMethodId(id);
    setOpenPopup(true);
  };

  const handleCreatePaymentMethod = async (id: string) => {
    try {
      await createPaymentMethod({
        variables: {
          id,
        },

        refetchQueries: ['getStripePaymentMethods'],
      });

      snackbar(t('userSettingsPage.billing.createPaymentMethodSuccess'), 'success');

      handleCloseAddPaymentMethodPopup();
    } catch (error) {
      const { graphQLErrors, message: errorText } = error as ApolloError;
      const message = graphQLErrors && graphQLErrors.length > 0 ? graphQLErrors[0].message : errorText;
      if (error) {
        snackbar(message);
      }
    }
  };

  const handleChangeDefaultPaymentMethod = async (id: string) => {
    try {
      await changeDefaultPaymentMethod({
        variables: {
          id,
        },

        refetchQueries: ['getStripePaymentMethods'],
      });

      snackbar(t('userSettingsPage.billing.changePaymentMethodSuccess'), 'success');
    } catch (error) {
      const { graphQLErrors, message: errorText } = error as ApolloError;
      const message = graphQLErrors && graphQLErrors.length > 0 ? graphQLErrors[0].message : errorText;
      if (error) {
        snackbar(message);
      }
    }
  };

  const handleDeletePaymentMethod = async () => {
    try {
      await deletePaymentMethod({
        variables: {
          id: selectedPaymentMethodId,
        },

        refetchQueries: ['getStripePaymentMethods'],
      });

      snackbar(t('userSettingsPage.billing.deletePaymentMethodSuccess'), 'success');

      setOpenPopup(false);
    } catch (error) {
      const { graphQLErrors, message: errorText } = error as ApolloError;
      const message = graphQLErrors && graphQLErrors.length > 0 ? graphQLErrors[0].message : errorText;
      if (error) {
        snackbar(message);
      }
    }
  };

  return (
    <>
      <Ballance data={balance} loading={loadingBalance} />

      <Box display="flex" pr="10px ">
        <Box mr="20px">
          <Typography variant="subtitle1">{t('userSettingsPage.billing.paymentMethods.title')}</Typography>

          <Typography color="text.secondary" mt="10px" fontSize="12px" lineHeight="14px" width="264px">
            {t('userSettingsPage.billing.paymentMethods.description')}
          </Typography>

          <AddPaymentMethodButton component="span" mt="21px">
            <Iconography iconName="add" fontSize="small" />

            <Typography variant="caption" color="secondary" ml="10px" onClick={handleOpenAddPaymentMethodPopup}>
              {t('userSettingsPage.billing.addNewPaymentMethod')}
            </Typography>
          </AddPaymentMethodButton>
        </Box>

        <Box display="flex" flexWrap="wrap" minHeight="133px">
          {showSkeletons ? (
            <SkeletonPaymentMethodCard cardsCount={2} />
          ) : (
            <>
              {paymentMethods?.map(({ id, card, defaultPaymentMethod }) => (
                <PaymentMethodCard
                  key={id}
                  error={creditCardError}
                  paymentMethodId={id}
                  cardInfo={card}
                  defaultPaymentMethod={defaultPaymentMethod}
                  onDeletePaymentMethod={handleOpenDeletePaymentMethodPopup}
                  onChangeDefaultPaymentMethod={handleChangeDefaultPaymentMethod}
                />
              ))}
            </>
          )}
        </Box>
        <ButtonScrollToTop intersection={document.documentElement.clientHeight} />
      </Box>

      <Box mt="16px">
        <Typography variant="subtitle1" mb="25px">
          {t('userSettingsPage.billing.invoices.title')}
        </Typography>

        <BillingInvoicesTable data={invoices} paymentMethods={paymentMethods} />
      </Box>

      <DeletePopup
        open={openPopup}
        onClose={() => setOpenPopup(false)}
        mainTitle={t('userSettingsPage.billing.deletePopup.title')}
        descriptionText={
          <Typography component="span" variant="body1" color="text.secondary">
            {t('userSettingsPage.billing.deletePopup.description')}
            <Typography component="span" variant="subtitle1" color="text.secondary">
              {`**** **** **** ${selectedPaymentMethodLast4}`}
            </Typography>
            ?
          </Typography>
        }
        onMainButtonClick={handleDeletePaymentMethod}
        onSecondaryButtonClick={() => setOpenPopup(false)}
        loadingOnMainButton={loadingDeletePaymentMethod}
      />

      <AddPaymentMethodPopup
        open={openAddPaymentMethodPopup}
        onClose={handleCloseAddPaymentMethodPopup}
        onCreatePaymentMethod={handleCreatePaymentMethod}
      />
    </>
  );
};

export default Billing;
