import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Typography, useTheme } from '@material-ui/core';

import Button from 'src/components/Button';
import ItemCard from 'src/components/Card/ItemCard';
import SkeletonSingleCsvPreviewItemCard from 'src/components/Skeleton/SkeletonSingleCsvPreviewItemCard';
import {
  CATALOGS_PATH,
  FIRST_DASHBOARD_PATHNAME,
  PRODUCT_ITEMS_PATH,
  PRODUCT_TYPES_PATH,
} from 'src/constants/routeSources';
import { CsvFileInfo } from 'src/hooks/useImportCsv';
import { FieldSystemName, ProductItemValue } from 'src/utils/gql';

import { ItemsWrapper, MainContainer, Header, ViewAllItemsButtonWrapper, PreviewItemName } from './styled';

const PREVIEW_CSV_CARD_COUNT = 30;

const ItemsPreview = ({
  selectedTypeInfo: { parsedProgressData, productType, productItems },
  setViewAllItemsPath,
}: {
  selectedTypeInfo: CsvFileInfo;
  setViewAllItemsPath: (viewAllItemsPath: string) => void;
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { id: catalogId } = useParams<{ id: string; productTypeId: string }>();

  const handleRedirect = () => {
    window.open(
      `/${FIRST_DASHBOARD_PATHNAME}/${CATALOGS_PATH}/${catalogId}/${PRODUCT_TYPES_PATH}/${productType?.id}/${PRODUCT_ITEMS_PATH}`,
      '_blank',
      'noopener, noreferrer',
    );
    setViewAllItemsPath('');
  };

  if (!parsedProgressData) {
    return null;
  }

  const { parsed, inParsing, parseFailed } = parsedProgressData;

  const getValueByProductFieldName = (values: ProductItemValue[], fieldName: string): string =>
    values.find(({ productTypeField }) => productTypeField.name.toLowerCase() === fieldName)?.value || '';

  const fieldsForPreview = productType?.fields.filter(({ showInPreview }) => showInPreview);
  const itemsToShow = productItems.slice(0, PREVIEW_CSV_CARD_COUNT + 1);
  const showViewAllItemsButton = parsed + parseFailed === inParsing && parsed > PREVIEW_CSV_CARD_COUNT;
  const parsingStatus = `${parsed === inParsing ? '' : `${parsed}/`}${t('itemPreviewCurrentStatus', {
    inParsing,
  })}`;

  return (
    <MainContainer>
      <ItemsWrapper>
        <Header>
          <PreviewItemName variant="h2" overflow="hidden" maxWidth="450px" height="24px">
            {productType?.name}
          </PreviewItemName>
          <Typography color={theme.palette.text.disabled} variant="subtitle2" whiteSpace="nowrap">
            {parsingStatus}
          </Typography>
        </Header>
        {itemsToShow.map(({ id, values, mediaUsages }) => (
          <ItemCard
            key={id}
            mediaUsages={mediaUsages}
            productItemName={getValueByProductFieldName(values, FieldSystemName.Name)}
            productItemPrice={getValueByProductFieldName(values, FieldSystemName.Price)}
            productItemArray={values}
            propertiesItem={fieldsForPreview}
          />
        ))}

        {parsed < Math.min(inParsing - parseFailed, PREVIEW_CSV_CARD_COUNT) && <SkeletonSingleCsvPreviewItemCard />}
      </ItemsWrapper>

      {showViewAllItemsButton && (
        <ViewAllItemsButtonWrapper>
          <Button type="button" variant="contained" onClick={handleRedirect}>
            View all items
          </Button>
        </ViewAllItemsButtonWrapper>
      )}
    </MainContainer>
  );
};

export default ItemsPreview;
