import { Theme } from '@material-ui/core/styles';

import { CatalogCardBackgrounds } from 'src/constants';
import { capitalize } from 'src/helpers/capitalize';
import { Integration, ProductItem } from 'src/utils/gql';

export const getPriceString = (price: string, paymentFrequency: string) =>
  `${+price ? `$${price}, ` : ''}${capitalize(paymentFrequency)}`;

// TODO : should be refactor
export const detectUniqueSingleSelectOptions = (productTypeFieldId: string, data: ProductItem[]) =>
  Array.from(
    new Set(
      data
        .map(({ values }) => values.find(({ productTypeField: { id } }) => id === productTypeFieldId)?.value || '')
        .filter((value) => value),
    ),
  );

// define field margin bottom depending on whether this field has settings
export const defineFieldsWithoutSettingsMarginBottom = (fieldIndex: number): string | undefined =>
  fieldIndex === 0 ? '35px' : fieldIndex === 1 ? '20px' : undefined;

export const getUrlForCatalogCardBackground =
  (theme: Theme) =>
  ({ source, size }: { source: string; size: string }) => {
    const backgroundName = CatalogCardBackgrounds[source]?.[size];

    if (backgroundName) {
      return `url(${process.env.PUBLIC_URL}/images/${backgroundName}.svg) no-repeat bottom left ${theme.palette.background.default}`;
    }
  };

export const calculateEnding = (array: Integration[] | undefined): string => (!array || array.length <= 1 ? '' : 's');
