import React from 'react';

import { Box, Skeleton } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { keyframes } from '@mui/system';

const opacity = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Container = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: 90,
  background: theme.palette.background.default,
  borderRadius: 4,
  boxShadow: '0px 2px 10px rgba(46, 96, 170, 0.25)',
  padding: '34px 30px 35px 35px',
  animation: `${opacity} 1s ease`,
  marginBottom: '24px',
}));

interface SkeletonProductTypeCardProps {
  cardsCount: number;
}

const SkeletonSharedCatalogProductTypesPreviews = ({ cardsCount }: SkeletonProductTypeCardProps) => (
  <>
    {new Array(cardsCount).fill('card').map((_, idx) => (
      <Container key={idx}>
        <Skeleton variant="text" width="80px" height="21px" />
        <Skeleton variant="text" width="50px" height="14px" />
      </Container>
    ))}
  </>
);

export default SkeletonSharedCatalogProductTypesPreviews;
