import { SelectOptionItem } from 'src/components/Select';
import { CatalogPrivacy } from 'src/utils/gql';

export enum CatalogPrivacyPriority {
  public,
  protected,
  private,
}

export interface CatalogSettingsState {
  paymentFrequency: SelectOptionItem;
  price: string;
  extraChargePercentage: string;
  privacy: CatalogPrivacy;
  catalogName: string;
  keywords: string[];
}
