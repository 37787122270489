import React, { memo, MouseEvent, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { useQuery } from '@apollo/client';
import { Box, Divider, Link, makeStyles, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useFlag } from '@unleash/proxy-client-react';
import clsx from 'clsx';

import Avatar from 'src/components/Avatar';
import LogoIcon from 'src/components/Icon/logo-icon.svg';
import Iconography from 'src/components/Iconography';
import Logo from 'src/components/Images/Logo.svg';
import { DEFAULT_USER_NAME, FeatureFlag } from 'src/constants';
import {
  AUDIT_LOG,
  CATALOGS_PATH,
  CREATION,
  FIRST_DASHBOARD_PATHNAME,
  PRODUCT_ITEMS_PATH,
  PRODUCT_TYPES_PATH,
  SHARED_CATALOGS,
  USER_SETTINGS,
} from 'src/constants/routeSources';
import { useAccount } from 'src/providers/account';
import { useCatalogs } from 'src/providers/catalog';
import useOnboarding from 'src/providers/OnboardingProvider/hooks/useOnboarding';
import { CatalogJobStatus, checkIsLimitReached, getCatalogInfo, LimitType, Maybe } from 'src/utils/gql';

import { useLimitReached } from '../../providers/limitReached';
import OnboardingPopup from '../Popups/OnboardingPopup';

import ContextMenu from './ContextMenu';
import { ADD_BUTTON_NAV_ITEMS, FOOTER_NAV_ITEMS, MAIN_NAV_ITEMS, NavItemId } from './NavBarItems';
import NavBarListFooterItem from './NavBarListFooterItem';
import NavBarListItem from './NavBarListItem';
import {
  AddButtonLink,
  DividerContainer,
  NavBarContainer,
  NavBarFooter,
  NavBarMain,
  OpenCloseIcon,
  RightSideContainer,
  StyledDrawer,
  StyledIcon,
} from './styled';

const useStyles = makeStyles((theme) => ({
  navBarFixed: {
    '& .MuiDrawer-paper': {
      position: 'fixed',
    },
  },
  availableAddButtonLink: {
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.secondary.dark,
    },
  },
  disableAddButtonLink: {
    color: theme.palette.text.disabled,
  },
  activeItem: {
    color: theme.palette.secondary.dark,
  },
  avatarContainer: {
    marginLeft: '-11px',
  },
  navBarDemo: {
    color: theme.palette.background.default,
    display: 'flex',
    whiteSpace: 'normal',
    fontSize: '15px',
    fontWeight: '400',
    padding: '0 20px',
  },
}));

enum ADD_BUTTON_NAVBAR_ITEMS {
  catalog = 0,
  productType = 1,
  item = 2,
}

enum GET_ID_FROM_URL_ACTIONS {
  catalogId,
  productTypeId,
}

const getActiveItemIdFromLocation = (location: string): Maybe<NavItemId> =>
  [
    {
      insertions: [CREATION],
      id: NavItemId.ADD,
    },
    {
      insertions: [USER_SETTINGS, AUDIT_LOG],
      id: NavItemId.AVATAR,
    },
    {
      insertions: [],
      id: NavItemId.HELP,
    },
    {
      insertions: [SHARED_CATALOGS],
      id: NavItemId.SHARED_CATALOGS,
    },
    {
      insertions: [CATALOGS_PATH],
      id: NavItemId.CATALOGS,
    },
  ].find(({ insertions }) => insertions.some((insertion) => location.includes(`/${insertion}`)))?.id ?? null;

const NavBar = () => {
  const classes = useStyles();

  const theme = useTheme();
  const limitReached = useLimitReached();
  const history = useHistory();
  const {
    user: { id: userId, avatar, name, isOnboardingPassed },
  } = useAccount();
  const { catalogsJobsMap } = useCatalogs();
  const { startOnboarding, finishOnboarding, blockDiscardChangesPopup } = useOnboarding();
  const { pathname: location } = useLocation<{ pathname: string }>();

  const productTypesLocation = location.includes(`/${PRODUCT_TYPES_PATH}`);
  const itemsLocation = location.includes(`/${PRODUCT_ITEMS_PATH}`);

  const currentCreationItem = useMemo(() => {
    if (location.includes(CREATION)) {
      if (location.includes(CATALOGS_PATH)) {
        if (location.includes(PRODUCT_TYPES_PATH)) {
          return ADD_BUTTON_NAVBAR_ITEMS.item;
        }
        return ADD_BUTTON_NAVBAR_ITEMS.productType;
      }
      return ADD_BUTTON_NAVBAR_ITEMS.catalog;
    }
  }, [location]);

  const { palette } = theme;

  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isHover, setIsHover] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<Maybe<HTMLElement>>(null);
  const [showItemContextMenuId, setShowItemContextMenuId] = useState<Maybe<NavItemId>>(null);
  const [isOnboardingPopupOpen, setIsOnboardingPopupOpen] = useState<boolean>(false);

  const isSharedCatalogsFeatureActive = useFlag(FeatureFlag.SHARED_CATALOGS);

  const idsFromUrl = location.match(/-?\d+(\.\d+)?/g);
  const userName = name || DEFAULT_USER_NAME;

  const { data: checkIsLimitReachedCatalogs } = useQuery(checkIsLimitReached, {
    fetchPolicy: 'network-only',
    variables: { type: LimitType.Catalogs },
  });

  const { data: checkIsLimitReachedItems } = useQuery(checkIsLimitReached, {
    fetchPolicy: 'network-only',
    variables: { type: LimitType.Items },
  });

  const handleOpenOnboardingPopup = () => {
    blockDiscardChangesPopup(true);
    setShowItemContextMenuId(null);

    setTimeout(() => {
      history.push(`/${FIRST_DASHBOARD_PATHNAME}/${CATALOGS_PATH}`);
      window.history.pushState({}, '', history.location.pathname);
    }, 0);

    setTimeout(() => {
      setIsOnboardingPopupOpen(true);
    }, 0);
  };

  const handleCloseOnboardingPopup = () => {
    setShowItemContextMenuId(null);
    setAnchorEl(null);
    setIsOnboardingPopupOpen(false);
  };

  const handleStartOnboarding = () => {
    handleCloseOnboardingPopup();

    startOnboarding();
    setIsExpanded(false);
  };

  const handleSkipOnboarding = async () => {
    handleCloseOnboardingPopup();

    finishOnboarding();
  };

  const getIdFromUrl = (action: GET_ID_FROM_URL_ACTIONS) =>
    (action === GET_ID_FROM_URL_ACTIONS.catalogId && idsFromUrl && idsFromUrl.length && +idsFromUrl[0]) ||
    (action === GET_ID_FROM_URL_ACTIONS.productTypeId && idsFromUrl && idsFromUrl.length && +idsFromUrl[1]);

  const catalogId = getIdFromUrl(GET_ID_FROM_URL_ACTIONS.catalogId);

  const { data: catalogData, loading } = useQuery(getCatalogInfo, {
    fetchPolicy: 'cache-first',
    variables: { id: String(catalogId) },
    skip: !(userId && catalogId && productTypesLocation),
  });

  const isShared = loading || !catalogData || catalogData.getCatalogById.user.id !== userId;

  const checkIsNotDisableLink = (id: ADD_BUTTON_NAVBAR_ITEMS) =>
    ADD_BUTTON_NAVBAR_ITEMS.catalog === id ||
    (productTypesLocation &&
      !isShared &&
      (ADD_BUTTON_NAVBAR_ITEMS.productType === id || (ADD_BUTTON_NAVBAR_ITEMS.item === id && itemsLocation)));

  const handleOpenContextMenu = (event: MouseEvent<HTMLElement>, id: NavItemId) => {
    setShowItemContextMenuId(id);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseContextMenu = () => {
    setShowItemContextMenuId(null);
    setAnchorEl(null);
  };

  const onChevronClick = () => {
    if (isExpanded) {
      setShowItemContextMenuId(null);
      setIsHover(false);
    }

    setIsExpanded((prev) => !prev);
  };

  const handleSetActiveItem = (id: NavItemId) => {
    switch (id) {
      case NavItemId.CATALOGS:
      case NavItemId.SHARED_CATALOGS:
        history.push(
          `/${FIRST_DASHBOARD_PATHNAME}/${id === NavItemId.SHARED_CATALOGS ? SHARED_CATALOGS : CATALOGS_PATH}`,
        );

        break;
      case NavItemId.ADD:
        if (!isExpanded) {
          setIsExpanded(true);
        }
    }

    setShowItemContextMenuId((prev) => (prev === id ? null : id));
  };

  const checkIsCatalogInExport = (id: ADD_BUTTON_NAVBAR_ITEMS): boolean => {
    const catalogId = String(getIdFromUrl(GET_ID_FROM_URL_ACTIONS.catalogId));
    const integrationIdToJobMap = catalogsJobsMap[catalogId];
    const status = integrationIdToJobMap?.[Object.keys(integrationIdToJobMap)[0]].status;

    return (
      (id === ADD_BUTTON_NAVBAR_ITEMS.item || id === ADD_BUTTON_NAVBAR_ITEMS.productType) &&
      [CatalogJobStatus.Active, CatalogJobStatus.Pending, CatalogJobStatus.Pause].includes(status)
    );
  };

  const checkIsAddButtonSubItemDisabled = (id: ADD_BUTTON_NAVBAR_ITEMS): boolean =>
    !checkIsNotDisableLink(id) || checkIsCatalogInExport(id);

  const handleActiveAddButtonItem = (id: ADD_BUTTON_NAVBAR_ITEMS) => {
    if (ADD_BUTTON_NAVBAR_ITEMS.catalog === id) {
      if (checkIsLimitReachedCatalogs?.checkIsLimitReached) {
        limitReached({ limitReachedType: LimitType.Catalogs });
      } else {
        history.push(`/${FIRST_DASHBOARD_PATHNAME}/${CREATION}`);
      }
    }

    ADD_BUTTON_NAVBAR_ITEMS.productType === id &&
      productTypesLocation &&
      history.push(
        `/${FIRST_DASHBOARD_PATHNAME}/${CATALOGS_PATH}/${getIdFromUrl(GET_ID_FROM_URL_ACTIONS.catalogId)}/${CREATION}`,
      );

    if (ADD_BUTTON_NAVBAR_ITEMS.item === id && itemsLocation) {
      if (checkIsLimitReachedItems?.checkIsLimitReached) {
        limitReached({ limitReachedType: LimitType.Items });
      } else {
        history.push(
          `/${FIRST_DASHBOARD_PATHNAME}/${CATALOGS_PATH}/${getIdFromUrl(
            GET_ID_FROM_URL_ACTIONS.catalogId,
          )}/${PRODUCT_TYPES_PATH}/${getIdFromUrl(GET_ID_FROM_URL_ACTIONS.productTypeId)}/${CREATION}`,
        );
      }
    }
  };

  const activeItem = useMemo(
    () => showItemContextMenuId || getActiveItemIdFromLocation(location),
    [location, showItemContextMenuId],
  );

  const showAddItems = NavItemId.ADD === activeItem && isExpanded;

  useEffect(() => {
    if (!isOnboardingPassed) {
      const infoButtonRef = document.getElementById('navbar_footer_item3_id');

      setTimeout(() => {
        setAnchorEl(infoButtonRef);
        setIsOnboardingPopupOpen(true);
      }, 0);
    }
  }, [history, isOnboardingPassed]);

  return (
    <>
      <StyledDrawer className={classes.navBarFixed} open={isExpanded} variant="permanent">
        <NavBarContainer onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
          <Box mb="8px">
            {isExpanded ? (
              <Link
                component={RouterLink}
                to={`/${FIRST_DASHBOARD_PATHNAME}`}
                onClick={() => handleSetActiveItem(NavItemId.CATALOGS)}
                data-testid="navBarLogoLink"
              >
                <Typography variant="h2" color="secondary" ml="16px" height="30px">
                  <img src={Logo} alt="logo" />
                </Typography>
              </Link>
            ) : (
              <Link
                component={RouterLink}
                to={`/${FIRST_DASHBOARD_PATHNAME}`}
                onClick={() => handleSetActiveItem(NavItemId.CATALOGS)}
                data-testid="navBarLogoIconLink"
              >
                <Typography variant="h2" color="secondary" textAlign="center" height="30px">
                  <Box>
                    <StyledIcon>
                      <img src={LogoIcon} alt="logo-icon" />
                    </StyledIcon>
                  </Box>
                </Typography>
              </Link>
            )}

            <NavBarMain>
              {MAIN_NAV_ITEMS.map(({ id, iconName, title, testId }) => {
                if (!isSharedCatalogsFeatureActive && id === NavItemId.SHARED_CATALOGS) {
                  return null;
                }

                return (
                  <NavBarListItem
                    iconName={iconName}
                    key={id}
                    isActive={id === activeItem}
                    isExpanded={isExpanded}
                    handleActiveItem={() => handleSetActiveItem(id)}
                    testId={testId}
                  >
                    {title}
                  </NavBarListItem>
                );
              })}
            </NavBarMain>

            <Box display="flex" flexDirection="column" mt="15px" ml="50.5px">
              {showAddItems &&
                ADD_BUTTON_NAV_ITEMS.map(({ id, title, testId }) => (
                  <AddButtonLink
                    className={clsx(
                      checkIsAddButtonSubItemDisabled(id)
                        ? classes.disableAddButtonLink
                        : classes.availableAddButtonLink,
                      id === currentCreationItem && classes.activeItem,
                    )}
                    onClick={() => handleActiveAddButtonItem(id)}
                    key={id}
                    button
                    disabled={checkIsAddButtonSubItemDisabled(id) || id === currentCreationItem}
                    disableRipple
                    data-testid={testId}
                  >
                    {title}
                  </AddButtonLink>
                ))}
            </Box>
          </Box>

          <NavBarFooter>
            <Divider light />

            {FOOTER_NAV_ITEMS.map(({ id, iconName, title, testId }) => (
              <NavBarListFooterItem
                key={id}
                testId={testId}
                id={`navbar_footer_item${id}_id`}
                onClick={(event) => handleOpenContextMenu(event, id)}
                selected={id === activeItem}
              >
                {id === NavItemId.AVATAR && avatar ? (
                  <div className={classes.avatarContainer}>
                    <Avatar src={avatar || ''} size="small" width="47px" height="47px" />
                  </div>
                ) : (
                  <Iconography iconName={iconName} />
                )}

                {isExpanded && (
                  <Typography variant="h3" fontWeight="normal" ml="15px" overflow="hidden" textOverflow="ellipsis">
                    {title || userName}
                  </Typography>
                )}
              </NavBarListFooterItem>
            ))}
          </NavBarFooter>
        </NavBarContainer>
      </StyledDrawer>

      <RightSideContainer onMouseLeave={() => setIsHover(false)} onMouseEnter={() => setIsHover(true)}>
        {isHover && (
          <DividerContainer>
            <Divider orientation="vertical" />

            <OpenCloseIcon
              data-testid="openCloseButtonNavBar"
              onClick={onChevronClick}
              style={{ transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)' }}
            >
              <Iconography iconName="chevron-arrow-right" htmlColor={palette.background.default} />
            </OpenCloseIcon>
          </DividerContainer>
        )}
      </RightSideContainer>

      {anchorEl && showItemContextMenuId ? (
        <ContextMenu
          anchorEl={anchorEl}
          isLimitReached={Boolean(checkIsLimitReachedCatalogs?.checkIsLimitReached)}
          onClose={handleCloseContextMenu}
          userName={userName}
          onBoardingClick={handleOpenOnboardingPopup}
        />
      ) : null}

      {anchorEl && (
        <OnboardingPopup
          anchorEl={anchorEl}
          open={isOnboardingPopupOpen}
          onStart={handleStartOnboarding}
          onSkip={handleSkipOnboarding}
        />
      )}
    </>
  );
};

export default memo(NavBar);
