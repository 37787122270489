import React, { ChangeEvent, FormEvent, KeyboardEvent, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Box, Typography } from '@material-ui/core';
import { useFlag } from '@unleash/proxy-client-react';

import Button from 'src/components/Button';
import Iconography from 'src/components/Iconography';
import { FeatureFlag, linkPricingPlan } from 'src/constants';
import { CatalogType, LimitType } from 'src/utils/gql';

import GradientTypography from '../GradientTypography';
import HintLimitButton from '../HintLimitButton';
import SearchField from '../SearchField';

import { CATALOGUES_INFO_BUTTON_ITEMS } from './CatalogsInfoButtonItems';
import { StyledCatalogsTypeLink, StyledUpgradeLink } from './styled';

interface CatalogueHeaderProps {
  checkIsLimitReachedLoading?: boolean;
  hasCatalogsInAllCatalogsLink?: boolean;
  mainCataloguesPage: boolean;
  getCataloguesType?: (types: CatalogType[]) => void;
  title?: string;
  hasCatalogs?: boolean;
  searchFieldValue?: string;
  searchRequest?: string;
  onAddCatalogClick?: () => void;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onSubmit?: (event: FormEvent) => void;
  checkIsLimitReachedVariables?: { type: LimitType.Catalogs };
  isSharedCatalog?: boolean;
}

export default function CatalogsHeader({
  checkIsLimitReachedLoading,
  mainCataloguesPage,
  hasCatalogsInAllCatalogsLink,
  getCataloguesType,
  title,
  onAddCatalogClick,
  onChange,
  hasCatalogs,
  searchRequest,
  searchFieldValue,
  checkIsLimitReachedVariables,
  isSharedCatalog,
}: CatalogueHeaderProps) {
  const { t } = useTranslation();
  const isSharedCatalogsFeatureActive = useFlag(FeatureFlag.SHARED_CATALOGS);

  const [activeInfoItem, setActiveInfoItem] = useState<CatalogType[]>([CatalogType.My, CatalogType.Subscribed]);

  const showCreateCatalogButton = hasCatalogs || isSharedCatalog || searchRequest;

  const showSearchField = searchRequest || hasCatalogsInAllCatalogsLink;

  const checkIsActiveItemEqualToNew = (newType: CatalogType[] = [CatalogType.My, CatalogType.Subscribed]) => {
    let result = activeInfoItem.length === newType.length;
    result &&
      activeInfoItem.forEach((itemInNewType, index) => {
        result = itemInNewType !== newType[index] ? false : result;
      });
    return result;
  };

  enum KeyboardKeyNames {
    enter = 'Enter',
  }

  const onEnterKeyPres = (event: KeyboardEvent<HTMLDivElement>, id: CatalogType[] | undefined) => {
    event.stopPropagation();
    if (event.key === KeyboardKeyNames.enter) {
      handleActiveInfoItem(id);
    }
  };

  const handleActiveInfoItem = (type: CatalogType[] = [CatalogType.My, CatalogType.Subscribed]) => {
    !checkIsActiveItemEqualToNew(type) && getCataloguesType && getCataloguesType(type);
    setActiveInfoItem(type);
  };

  useEffect(() => {
    if (!isSharedCatalogsFeatureActive) {
      setActiveInfoItem([CatalogType.My]);
    }
  }, [isSharedCatalogsFeatureActive]);

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
        <Typography variant="h2">{title}</Typography>
      </Box>

      {showSearchField && (
        <Box display="flex" justifyContent="space-between" width="100%" mt="20px">
          <SearchField
            disabled={!showCreateCatalogButton}
            variant="outlined"
            label={`${t('cataloguesHeader.searchFieldLabel')}`}
            onChange={onChange}
            value={searchFieldValue}
            testId="searchFieldCatalogsPage"
          />

          {showCreateCatalogButton && (
            <Box display="flex" id="create-catalog-btn-anchor">
              {checkIsLimitReachedVariables ? (
                <HintLimitButton
                  tooltipWidth="333px"
                  tooltipMarginLeft="0"
                  title={
                    <Trans i18nKey="cataloguesHeader.hint">
                      Catalogs number has reached the limit.
                      <StyledUpgradeLink href={linkPricingPlan}>Upgrade your plan</StyledUpgradeLink>
                      to create an unlimited number of catalogs or delete some catalogs to create new ones.
                    </Trans>
                  }
                >
                  <Button
                    disabled
                    variant="contained"
                    startIcon={<Iconography iconName="add" />}
                    data-testid="createNewCatalogButtonDisabled"
                  >
                    {t('cataloguesHeader.createCatalogButton')}
                  </Button>
                </HintLimitButton>
              ) : (
                <Button
                  disabled={checkIsLimitReachedLoading}
                  onClick={onAddCatalogClick}
                  variant="contained"
                  startIcon={<Iconography iconName="add" />}
                  data-testid="createNewCatalogButton"
                >
                  {t('cataloguesHeader.createCatalogButton')}
                </Button>
              )}
            </Box>
          )}
        </Box>
      )}

      {mainCataloguesPage && hasCatalogsInAllCatalogsLink && (
        <Box display="flex" justifyContent="start" alignItems="center" width="100%" mt="40px">
          {isSharedCatalogsFeatureActive
            ? CATALOGUES_INFO_BUTTON_ITEMS.map(({ id, title, testId }) => {
                return id.length == activeInfoItem.length && id.every((v, i) => v === activeInfoItem[i]) ? (
                  <GradientTypography
                    mr="30px"
                    variant="subtitle1"
                    onClick={() => handleActiveInfoItem(id)}
                    onKeyPress={(e: KeyboardEvent<HTMLDivElement>) => onEnterKeyPres(e, id)}
                    key={id.join()}
                    isHoverable
                    tabIndex={0}
                    data-testid={testId}
                  >
                    {title}
                  </GradientTypography>
                ) : (
                  <StyledCatalogsTypeLink
                    variant="subtitle1"
                    onClick={() => handleActiveInfoItem(id)}
                    onKeyPress={(e: KeyboardEvent<HTMLDivElement>) => onEnterKeyPres(e, id)}
                    key={id.join()}
                    tabIndex={0}
                    data-testid={testId}
                  >
                    {title}
                  </StyledCatalogsTypeLink>
                );
              })
            : null}
        </Box>
      )}
    </Box>
  );
}
