import { Box } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';

export const ForgotPasswordContainer = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const SideContainer = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 676px;
  height: 100%;
  margin-left: 64px;
  margin-right: -64px;
`;

export const TopContainer = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 229px;
  min-width: 229px;
  width: 563px;
  margin-left: 40px;
`;

export const BottomContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 287px;
`;

export const FormContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-width: 282px;
`;

export const StyledForgotPasswordContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  [theme.breakpoints.up('xl')]: {
    justifyContent: 'center',
  },
}));
