import React from 'react';

import MuiButton, { ButtonProps } from '@material-ui/core/Button';

import Loader from './Loader';

interface Props extends ButtonProps {
  loading?: boolean;
}

function Button({ loading, ...props }: Props) {
  const { disabled, children } = props;

  return (
    <MuiButton {...props} disabled={disabled || loading}>
      {loading && !disabled && <Loader size="button" />}

      {children}
    </MuiButton>
  );
}

export default Button;
