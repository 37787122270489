import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { ApolloError } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@material-ui/core';

import PasswordInput from 'src/components/Forms/FormInputs/PasswordInput';
import { FormContainer, SaveButton } from 'src/components/Forms/styled';
import { SetNewPasswordFormProps, SetNewPasswordInput } from 'src/components/Forms/types';
import { setNewPasswordFormSchema } from 'src/components/Forms/validationSchemas';
import { UpdatePasswordInput } from 'src/utils/gql';

const SetNewPasswordForm = ({ onSubmit, onError, loading }: SetNewPasswordFormProps) => {
  const { t } = useTranslation();
  const location = useLocation();

  const formMethods = useForm<SetNewPasswordInput>({
    mode: 'onChange',
    resolver: yupResolver(setNewPasswordFormSchema),
  });

  const {
    handleSubmit,
    formState: { isValid },
  } = formMethods;

  const query = new URLSearchParams(location.search);
  const mailToken = query.get('token');

  const onSubmitForm = handleSubmit(async ({ password }: SetNewPasswordInput) => {
    const data = {
      token: mailToken,
      newPassword: password,
    } as UpdatePasswordInput;

    try {
      await onSubmit(data);
    } catch (error) {
      const { graphQLErrors, message: errorText } = error as ApolloError;
      const message = graphQLErrors?.length ? graphQLErrors[0].message : errorText;
      onError?.(message);
    }
  });

  return (
    <FormProvider {...formMethods}>
      <form aria-label="form" onSubmit={onSubmitForm}>
        <FormContainer>
          <Box mb="15px" mt="30px" width="100%">
            <Box mb="10px">
              <PasswordInput hasRequirements shouldFocus />
            </Box>
          </Box>

          <SaveButton
            color="primary"
            disabled={!isValid}
            type="submit"
            variant="contained"
            loading={loading}
            data-testid="submit"
          >
            {t('setNewPasswordPage.saveButton')}
          </SaveButton>
        </FormContainer>
      </form>
    </FormProvider>
  );
};

export default SetNewPasswordForm;
