import React, { useEffect, useMemo, useState } from 'react';

import { Box } from '@material-ui/core';
import { debounce } from 'lodash';

import SliderWithFields from 'src/components/SliderWithFields';
import { EventsService, EventName } from 'src/services/Events';
import { Maybe } from 'src/utils/gql';

interface Props {
  inputProps: { startAdornment: JSX.Element } | undefined;
  min: number;
  max: number;
  values: Maybe<string>[];
  onChange: (values: Maybe<string>[]) => void;
  fieldName: string;
}

export const sortMaybeStringNumericArray = (a: Maybe<string>, b: Maybe<string>) => {
  if (![a, b].every((num) => num)) {
    return 1;
  }

  const numA = Number(a);
  const numB = Number(b);

  if ([numA, numB].some((num) => isNaN(num))) {
    return 1;
  }

  return numA - numB;
};

export const normalizeValues = (values: Maybe<string>[], minMax: number[]): Maybe<string>[] =>
  values.sort(sortMaybeStringNumericArray).map((value, idx) => {
    if (!value) return null;

    let numValue = Number(value);

    minMax.forEach((border, borderIndex) => {
      if (numValue < border === !borderIndex) {
        numValue = border;
      }
    });

    if (numValue === minMax[idx]) {
      return null;
    }

    return String(numValue);
  });

const Range = ({ min, max, values, onChange, inputProps, fieldName }: Props) => {
  const [sliderValues, setSliderValues] = useState<(string | null)[]>(values.length ? values : [null, null]);
  const minMaxArr = useMemo(() => [min, max], [min, max]);

  const debouncedOnChange = useMemo(() => debounce(onChange, 1000), [onChange]);

  const handleChange = (values: Maybe<string>[]) => {
    const numeric = values.map((value) => {
      if (value === null) {
        return null;
      } else {
        const numValue = Number(value);

        if (isNaN(numValue)) {
          return null;
        } else {
          return numValue;
        }
      }
    });

    const normalizedValues = numeric.map((value, idx) => {
      const normalized = value && Math.max(Math.min(value, max), min);

      return normalized === minMaxArr[idx] ? null : normalized === null ? normalized : String(normalized);
    });

    debouncedOnChange(normalizedValues);
    setSliderValues(normalizedValues);
  };

  useEffect(() => {
    const handleFiltersReset = () => {
      setSliderValues([null, null]);
    };

    EventsService.subscribe(EventName.FiltersReset, handleFiltersReset);

    return () => {
      EventsService.unsubscribe(EventName.FiltersReset, handleFiltersReset);
    };
  }, []);

  const stringSliderValues = useMemo<string[]>(
    () => normalizeValues(sliderValues, minMaxArr).map((value, idx) => value ?? String(minMaxArr[idx]), minMaxArr),
    [minMaxArr, sliderValues],
  );

  return (
    <Box>
      <SliderWithFields
        inputProps={inputProps}
        defaultValues={[min, max]}
        onChange={handleChange}
        values={stringSliderValues}
        fieldName={fieldName}
      />
    </Box>
  );
};

export default Range;
