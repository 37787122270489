import React from 'react';

import { Box } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';

const MainBox = styled(Box)`
  position: absolute;
  width: 316px;
  height: 220px;
  opacity: 0.7;
  filter: blur(28px);

  & * {
    position: absolute;
    border-radius: 50%;
  }
`;

const LightPurpleBox = styled(Box)`
  background-color: #8d8dff;
  box-shadow: 0 0 0 20px #8d8dff;
`;

const PurpleBox = styled(Box)`
  background-color: #6c5ef7;
  box-shadow: 0 0 0 10px #6c5ef7;
`;

const DarkPurpleBox = styled(Box)`
  background-color: #413bab;
  box-shadow: 0 0 0 10px #413bab;
`;

const YellowBox = styled(Box)`
  background-color: #f4a62a;
  box-shadow: 0 0 0 10px #f4a62a;
`;

const OrangeBox = styled(Box)`
  background-color: #f78b49;
  box-shadow: 0 0 0 10px #f78b49;
`;

const DarkOrangeBox = styled(Box)`
  background-color: #f26751;
  box-shadow: 0 0 0 10px #f26751;
`;

const ProductItemsNotFoundBackground = () => {
  return (
    <MainBox>
      <PurpleBox width="204px" height="112px" top="78px" right="24px" />

      <PurpleBox width="56px" height="42px" bottom="34px" left="46px" />

      <LightPurpleBox width="146px" height="42px" bottom="30px" right="28px" />

      <LightPurpleBox width="18px" height="20px" top="110px" left="12px" />

      <YellowBox width="24px" height="24px" top="28px" right="58px" />

      <OrangeBox width="88px" height="68px" top="88px" right="54px" />

      <DarkOrangeBox width="104px" height="54px" top="138px" right="50px" />

      <DarkOrangeBox width="34px" height="32px" top="178px" right="60px" />

      <DarkOrangeBox width="56px" height="70px" top="138px" left="84px" />

      <DarkPurpleBox width="58px" height="88px" top="42px" left="54px" />

      <DarkPurpleBox width="80px" height="28px" top="37px" left="-19px" sx={{ transform: 'rotate(55deg)' }} />

      <DarkPurpleBox width="80px" height="20px" top="37px" left="106px" sx={{ transform: 'rotate(-55deg)' }} />
    </MainBox>
  );
};

export default ProductItemsNotFoundBackground;
