import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useQuery } from '@apollo/client';

import Button from 'src/components/Button';
import SubscriptionsPopup from 'src/components/Popups/SubscriptionsPopup';
import { linkPricingPlan, SHARED_CATALOGUES_SUBSCRIPTION_STATUSES } from 'src/constants';
import useControlSubscriptions from 'src/hooks/useControlSubscriptions';
import { CatalogSubscription, checkIsLimitReached, LimitType } from 'src/utils/gql';

import { StyledUpgradeLink } from '../Headers/styled';
import HintLimitButton from '../HintLimitButton';

import { StyledActionButton } from './styled';
import { SharedCataloguesSubscriptionsActions, SharedCatalogueSubscription } from './types';

interface ActionsTableCellProps {
  catalogId: string;
  subscriptions: CatalogSubscription[];
  catalogName: string;
  catalogOwner: string;
  onCatalogsDataClear: () => void;
}

const ActionsTableCell = ({
  catalogId,
  subscriptions,
  catalogOwner,
  catalogName,
  onCatalogsDataClear,
}: ActionsTableCellProps) => {
  const { t } = useTranslation();

  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [statusState, setStatusState] = useState<SharedCatalogueSubscription>();

  const subscriptionData = subscriptions[0];
  const subscriptionAction =
    subscriptionData && statusState ? statusState.action : SharedCataloguesSubscriptionsActions.request;

  const { onRequestSubscription, onCancelSubscription, loadingStatus } = useControlSubscriptions({
    catalogId,
    subscriptionId: subscriptionData ? subscriptionData.id : null,
  });

  const { data: checkIsLimitReachedData } = useQuery(checkIsLimitReached, {
    fetchPolicy: 'network-only',
    variables: { type: LimitType.Subscriptions },
  });

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const checkLimit = checkIsLimitReachedData?.checkIsLimitReached;

  const handleSubscribeButtonClick = () => !checkLimit && handleOpenPopup();

  const handleClosePopup = () => setIsPopupOpen(false);

  const handleSubscriptionStatus = async () => {
    if (subscriptionAction === SharedCataloguesSubscriptionsActions.request) {
      await onRequestSubscription();
    }

    if (subscriptionAction === SharedCataloguesSubscriptionsActions.cancel) {
      await onCancelSubscription();
    }

    onCatalogsDataClear();
    handleClosePopup();
  };

  useEffect(() => {
    subscriptionData && setStatusState(SHARED_CATALOGUES_SUBSCRIPTION_STATUSES[subscriptionData.approvalStatus]);
  }, [subscriptionData]);

  return (
    <>
      {subscriptionAction === SharedCataloguesSubscriptionsActions.request && (
        <StyledActionButton>
          {checkLimit ? (
            <HintLimitButton
              tooltipWidth="340px"
              tooltipMarginLeft="0"
              title={
                <Trans i18nKey="sharedCatalogsPage.hint">
                  Subscription number has reached the limit.
                  <StyledUpgradeLink href={linkPricingPlan}>Upgrade your plan</StyledUpgradeLink>
                  to subscribe to an unlimited number of catalogs or unsubscribe from another catalog.
                </Trans>
              }
            >
              <Button variant="outlined" disabled data-testid={`subscriptionButtonDisabled${catalogName}`}>
                {t('sharedCatalogsPage.subscribe')}
              </Button>
            </HintLimitButton>
          ) : (
            <Button
              onClick={handleSubscribeButtonClick}
              variant="outlined"
              data-testid={`subscriptionButton${catalogName}`}
            >
              {t('sharedCatalogsPage.subscribe')}
            </Button>
          )}
        </StyledActionButton>
      )}

      {subscriptionAction === SharedCataloguesSubscriptionsActions.cancel && (
        <StyledActionButton>
          <Button onClick={handleOpenPopup} variant="outlined" data-testid={`cancelButton${catalogName}`}>
            {t('cancel')}
          </Button>
        </StyledActionButton>
      )}

      <SubscriptionsPopup
        isOpen={isPopupOpen}
        handleMainButtonClick={handleSubscriptionStatus}
        handleClosePopup={handleClosePopup}
        subscriptionAction={subscriptionAction as string}
        catalogOwner={catalogOwner}
        catalogName={catalogName}
        loadingStatus={loadingStatus}
      />
    </>
  );
};

export default ActionsTableCell;
