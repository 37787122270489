import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useLazyQuery, useQuery } from '@apollo/client';
import { Box, Typography } from '@material-ui/core';
import { useFlag } from '@unleash/proxy-client-react';

import DashboardBreadCrumbs from 'src/components/Breadcrumds/DashboardBreadCrumbs';
import Button from 'src/components/Button';
import Iconography from 'src/components/Iconography';
import Loader from 'src/components/Loader';
import Page from 'src/components/Page';
import Tabs from 'src/components/Tabs';
import { FeatureFlag, USER_SETTINGS_TABS } from 'src/constants';
import LayoutContext from 'src/layouts/Dashboard/LayoutContext';
import {
  getStripeBalanceWithNextPayment,
  getStripeInvoices,
  getStripePaymentMethods,
  planSubscriptionStats,
  PlanSubscriptionStatsResponse,
  publicApiKeys,
  Query,
} from 'src/utils/gql';
import { LoaderWrapper } from 'src/views/Catalogs/styled';

import Billing from './Billing';
import { linkToPublicApiDoc } from './constants';
import PublicApi from './PublicApi';
import CreatePublicApiKeyPopup from './PublicApi/CreatePublicApiKeyPopup';
import NoPublicApiKey from './PublicApi/NoPublicApiKey';
import { StyledBox, StyledLink, UserSettingsContent, UserSettingsHeader, UserSettingsPage } from './styled';
import { UserSettingsPageTab, UserSettingsTab } from './types';
import UserAccount from './UserAccount';
import UserIntegrations from './UserIntegrations';

const UserSettings = () => {
  const { t } = useTranslation();
  const { fullscreenComponentsHeight } = useContext(LayoutContext);

  const isBillingFeatureActive = useFlag(FeatureFlag.BILLING);
  const isPublicApiFeatureActive = useFlag(FeatureFlag.PUBLIC_API);

  const [isBillingTouched, setIsBillingTouched] = useState(false);
  const [activeTab, setActiveTab] = useState<UserSettingsPageTab>(UserSettingsPageTab.GENERAL);
  const [isCreatePublicApiPopupOpened, setIsCreatePublicApiPopupOpened] = useState<boolean>(false);

  const {
    data: planData,
    loading,
    refetch: refetchPlanStats,
  } = useQuery<Pick<Query, 'planSubscriptionStats'>, PlanSubscriptionStatsResponse>(planSubscriptionStats, {
    fetchPolicy: 'network-only',
  });

  const [getPaymentMethods, { data: paymentMethods, loading: loadingPaymentMethods }] = useLazyQuery<
    Pick<Query, 'getStripePaymentMethods'>
  >(getStripePaymentMethods, {
    fetchPolicy: 'no-cache',
  });

  const [getBalance, { data: balance, loading: loadingBalance }] = useLazyQuery<
    Pick<Query, 'getStripeBalanceWithNextPayment'>
  >(getStripeBalanceWithNextPayment, {
    fetchPolicy: 'no-cache',
  });

  const [getInvoices, { data: invoices }] = useLazyQuery<Pick<Query, 'getStripeInvoices'>>(getStripeInvoices, {
    fetchPolicy: 'no-cache',
  });

  const { data: publicApiKeysData, loading: loadingPublicApi } = useQuery<Pick<Query, 'publicApiKeys'>>(publicApiKeys, {
    skip: !isPublicApiFeatureActive,
  });

  const hasPublicApiKeys = Boolean(publicApiKeysData?.publicApiKeys.length);

  const handleTabClick = (activeTab: UserSettingsPageTab) => {
    if (activeTab === UserSettingsPageTab.BILLING && !isBillingTouched) {
      getBalance();
      getPaymentMethods();
      getInvoices();
      setIsBillingTouched(true);
    }

    setActiveTab(activeTab);
  };

  const title = t('pageTitles.userSettings');

  const tabs = USER_SETTINGS_TABS.reduce((acc: UserSettingsTab[], tab: UserSettingsTab) => {
    const isPublicApiTab = tab.testId === 'publicApiTab';
    const isBillingTab = tab.testId === 'billingTab';

    if (
      (!isPublicApiTab && !isBillingTab) ||
      (isPublicApiTab && isPublicApiFeatureActive) ||
      (isBillingTab && isBillingFeatureActive)
    ) {
      acc.push(tab);
    }

    return acc;
  }, []);

  return (
    <Page title={title} locationName={title}>
      <Box display="flex" flexDirection="column" height={fullscreenComponentsHeight}>
        <UserSettingsPage>
          <Box padding="0 56px 30px 8px">
            <Box mb="25px">
              <DashboardBreadCrumbs />
            </Box>
            <UserSettingsHeader>
              <Typography variant="h2">{t('userSettingsPage.labels.settings')}</Typography>
              <Box display="flex">
                {activeTab === UserSettingsPageTab.PUBLIC_API && isPublicApiFeatureActive && (
                  <StyledBox>
                    <StyledLink
                      href={linkToPublicApiDoc}
                      target="_blank"
                      rel="noreferrer"
                      data-testid="testPublicApiLink"
                    >
                      {t('userSettingsPage.publicApiKeys.linkToPublicApiDoc')}
                    </StyledLink>
                    <Box ml="20px">
                      {hasPublicApiKeys && (
                        <Button
                          variant="contained"
                          startIcon={<Iconography iconName="add" />}
                          onClick={() => setIsCreatePublicApiPopupOpened(true)}
                        >
                          {t('userSettingsPage.createKeyButton')}
                        </Button>
                      )}
                    </Box>
                  </StyledBox>
                )}
              </Box>
            </UserSettingsHeader>

            {tabs.length > 1 && <Tabs tabs={tabs} activeTab={activeTab} onTabClick={handleTabClick} />}
          </Box>
          {loading ? (
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          ) : (
            <UserSettingsContent>
              {activeTab === UserSettingsPageTab.GENERAL && (
                <>
                  <UserAccount planData={planData?.planSubscriptionStats} setActiveTab={handleTabClick} />
                  <UserIntegrations refetchPlanStats={refetchPlanStats} />
                </>
              )}

              {activeTab === UserSettingsPageTab.BILLING && (
                <Billing
                  paymentMethods={paymentMethods?.getStripePaymentMethods}
                  invoices={invoices?.getStripeInvoices}
                  balance={balance?.getStripeBalanceWithNextPayment}
                  loadingBalance={loadingBalance}
                  loading={loadingPaymentMethods}
                />
              )}

              {activeTab === UserSettingsPageTab.PUBLIC_API && isPublicApiFeatureActive && (
                <>
                  {hasPublicApiKeys || loadingPublicApi ? (
                    <PublicApi />
                  ) : (
                    <NoPublicApiKey openPopupCreateApiKey={() => setIsCreatePublicApiPopupOpened(true)} />
                  )}
                </>
              )}
            </UserSettingsContent>
          )}
        </UserSettingsPage>
      </Box>

      {isCreatePublicApiPopupOpened && (
        <CreatePublicApiKeyPopup
          open={isCreatePublicApiPopupOpened}
          onClose={() => {
            setIsCreatePublicApiPopupOpened(false);
          }}
        />
      )}
    </Page>
  );
};

export default UserSettings;
