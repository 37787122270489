import React from 'react';

import { Box, capitalize, Typography } from '@material-ui/core';

import separateStringByCapitalLetters from 'src/utils/separateStringByCapitalLetters';
import { CatalogValueType } from 'src/views/AuditLog/types';

import { DetailWrapper } from './styled';

interface Props {
  changes: CatalogValueType;
  text: string;
}

interface Settings {
  url: string;
  apiKey: string;
  apiSecret: string;
  integrationName: string;
}

export interface ObjectToRender {
  id: string;
  type: string;
  settings: Settings;
  isDefault: boolean;
}

export const IntegrationDetailedInfo = ({ changes, text }: Props) => {
  const prepareIntegrationUpdateDetails = (objectToRender: ObjectToRender) =>
    Object.entries(objectToRender).reduce(
      (acc: [string, string][], [key, value]) =>
        acc.concat(typeof value == 'object' ? Object.entries(value) : [[key, value]]),
      [],
    );

  return (
    <Box>
      {typeof changes === 'object' && !changes.length ? (
        <Typography>{text}</Typography>
      ) : (
        <>
          {typeof changes === 'object' && typeof changes[0] === 'object' ? (
            <Box>
              {prepareIntegrationUpdateDetails(changes[0]).map(([key, value], idx) => (
                <DetailWrapper key={idx}>
                  <Typography mr="10px">{separateStringByCapitalLetters(capitalize(key))}:</Typography>
                  <Typography>{String(value)}</Typography>
                </DetailWrapper>
              ))}
            </Box>
          ) : (
            <Typography>{String(changes)}</Typography>
          )}
        </>
      )}
    </Box>
  );
};
