import React from 'react';
import { Trans } from 'react-i18next';

import { CatalogType } from 'src/utils/gql';

export const CATALOGUES_INFO_BUTTON_ITEMS = [
  {
    id: [CatalogType.My, CatalogType.Subscribed],
    title: <Trans i18nKey="cataloguesInfoButton.allCatalogues">All catalogs</Trans>,
    iconName: 'allCatalogues',
  },
  {
    id: [CatalogType.My],
    title: <Trans i18nKey="cataloguesInfoButton.myCatalogues">My catalogs</Trans>,
    iconName: 'myCatalogues',
    testId: 'tabMyCatalogs',
  },
  {
    id: [CatalogType.Subscribed],
    title: <Trans i18nKey="cataloguesInfoButton.sharedCatalogues">Shared catalogs</Trans>,
    iconName: 'sharedCatalogues',
    testId: 'tabSharedCatalogs',
  },
];
