import { useTranslation } from 'react-i18next';

import { useMutation, ApolloError } from '@apollo/client';

import { UploadingMediaWithLoadingStatus } from 'src/components/MediaGallery/constants';
import { useSnackbar } from 'src/providers/snackbar';
import { createMedia, MediaType } from 'src/utils/gql';
import { getImagePreview } from 'src/utils/imageUtils';
import { getFileExpansion } from 'src/views/Catalogs/ProductItems/functions';
import { UploadingMedia } from 'src/views/Catalogs/ProductItems/types';

import useUpload from './useUpload';

const useCreateMedia = () => {
  const { upload } = useUpload();
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const [creacteMediaItem] = useMutation(createMedia);

  const createMediaFile = async (
    result: string,
    fileOrUrl: File,
    uploadingMedia: UploadingMedia,
    type: MediaType,
    setUpload: React.Dispatch<React.SetStateAction<UploadingMediaWithLoadingStatus[]>>,
  ) => {
    const expansionFile = getFileExpansion(fileOrUrl.name);
    const createdMedia = { ...uploadingMedia, name: fileOrUrl.name };
    getImagePreview(fileOrUrl, (result: string) => {
      const startUploadMedia = {
        ...createdMedia,
        previewUrl: result,
        loadingStatus: true,
        expansion: expansionFile,
      };

      setUpload((prev) => [...prev, startUploadMedia]);
    });

    const finishUploadMedia = {
      ...createdMedia,
      previewUrl: result,
      expansion: expansionFile,
      loadingStatus: false,
    };

    const { loadedFilesUrls, unloadedFiles } = await upload([
      {
        ...createdMedia,
        file: fileOrUrl,
        imagePreview: result,
      },
    ]);

    if (loadedFilesUrls.length) {
      try {
        await creacteMediaItem({
          variables: {
            data: { type: type, url: loadedFilesUrls[0] },
          },

          update(cache, { data }) {
            const createdMedia = data?.createMedia;

            if (createdMedia) {
              cache.modify({
                fields: {
                  getMedia(existingMediaRef = [], { readField, toReference }) {
                    const createdMediaRef = toReference(createdMedia);
                    const isMediaAlreadyInCache = existingMediaRef.some(
                      (ref: { __ref: string }) => readField('id', ref) === createdMedia.id,
                    );

                    return isMediaAlreadyInCache ? existingMediaRef : [...existingMediaRef, createdMediaRef];
                  },
                },
              });
            }
          },
        });

        snackbar(t('productItemCreateEdit.mediaGallery.sidebar.addingMedia'), 'success');
      } catch (error) {
        const { graphQLErrors, message: errorText } = error as ApolloError;
        const message = graphQLErrors && graphQLErrors.length ? graphQLErrors[0].message : errorText;

        if (error) {
          snackbar(message);
        }
      }
    }

    if (unloadedFiles.length) {
      snackbar(`${unloadedFiles.length} ${t('productItemCreateEdit.mediaGallery.sidebar.unploadFiles')}`, 'info');
    }

    setUpload((prev) => [...prev.filter(({ id }) => id !== uploadingMedia.id), finishUploadMedia]);
  };

  return { createMediaFile };
};

export default useCreateMedia;
