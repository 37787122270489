export const getEbayAuthUrl = () => {
  const url = new URL(`https://${process.env.REACT_APP_EBAY_AUTH_BASE_URL}/oauth2/authorize`);
  const scopes = [
    'https://api.ebay.com/oauth/api_scope',
    'https://api.ebay.com/oauth/api_scope/sell.marketing.readonly',
    'https://api.ebay.com/oauth/api_scope/sell.marketing',
    'https://api.ebay.com/oauth/api_scope/sell.inventory.readonly',
    'https://api.ebay.com/oauth/api_scope/sell.inventory',
    'https://api.ebay.com/oauth/api_scope/sell.account.readonly',
    'https://api.ebay.com/oauth/api_scope/sell.account',
    'https://api.ebay.com/oauth/api_scope/sell.fulfillment.readonly',
    'https://api.ebay.com/oauth/api_scope/sell.fulfillment',
    'https://api.ebay.com/oauth/api_scope/sell.analytics.readonly',
    'https://api.ebay.com/oauth/api_scope/sell.finances',
    'https://api.ebay.com/oauth/api_scope/sell.payment.dispute',
    'https://api.ebay.com/oauth/api_scope/commerce.identity.readonly',
    'https://api.ebay.com/oauth/api_scope/commerce.notification.subscription',
    'https://api.ebay.com/oauth/api_scope/commerce.notification.subscription.readonly',
  ];

  url.searchParams.append('client_id', process.env.REACT_APP_EBAY_CLIENT_ID);
  url.searchParams.append('redirect_uri', process.env.REACT_APP_EBAY_REDIRECT_URL_NAME);
  url.searchParams.append('response_type', 'code');

  const scopeQueryParam = `&scope=${scopes.join('%20')}`;

  return url.toString() + scopeQueryParam;
};
