import React, { useEffect } from 'react';
import InfiniteScroll, { Props } from 'react-infinite-scroll-component';

interface ScrollProps extends Props {
  // for cases when screen is bigger, than first loaded content
  pageId?: string;
}

const Scroll = (props: ScrollProps) => {
  const { hasMore, pageId, next, dataLength } = props;

  useEffect(() => {
    if (!hasMore) return;

    const list = pageId && document.getElementById(pageId);

    if (!list) return;

    if (list.scrollHeight <= list.clientHeight) {
      next();
    }
  }, [dataLength, hasMore, next, pageId]);

  return <InfiniteScroll {...props} />;
};

export default Scroll;
