import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, useTheme } from '@material-ui/core';
import { experimentalStyled as styled, Theme } from '@material-ui/core/styles';

import ItemEmptyBackgroundImg from 'src/components/Images/ItemEmptyBackground.svg';
import { MAX_PREVIEW_ITEM_CAROUSEL_IMAGES } from 'src/constants';

import { MediaItemWithFallback } from './MediaItemWithFallback';
import { ItemPreviewCarouselIndicator } from './styled';
import { ItemPreviewCarouselState } from './types';

const ItemPreviewCarouselWrapper = styled('div')`
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;

  &:hover {
    cursor: pointer;
  }
`;

const ItemPreviewCarouselItemBlock = styled('div')`
  z-index: 3;
  height: 100%;
  width: ${({ itemwidth }: { itemwidth: number; theme?: Theme }) => `${itemwidth}px`};
`;

interface ItemPreviewCarouselProps {
  cardImages?: string[];
}

const ItemPreviewCarousel = ({ cardImages }: ItemPreviewCarouselProps) => {
  const theme = useTheme();
  const [itemPreviewCarouselCurrentIndex, seItemPreviewCarouselCurrentIndex] = useState<number>(0);
  const [itemPreviewCarouselState, setItemPreviewCarouselState] = useState<ItemPreviewCarouselState>({
    carouselWidth: 0,
    carouselItemWidth: 0,
    showIndicators: false,
  });

  const preparedCarouselImages = useMemo(() => cardImages?.slice(0, MAX_PREVIEW_ITEM_CAROUSEL_IMAGES), [cardImages]);

  const onCarouselWrapperMouseEnter = useCallback(() => {
    const showIndicators = cardImages && cardImages?.length > 1;
    setItemPreviewCarouselState((prevState) => ({ ...prevState, showIndicators }));
  }, [cardImages]);

  const onCarouselWrapperMouseLeave = useCallback(() => {
    seItemPreviewCarouselCurrentIndex(0);
    setItemPreviewCarouselState((prevState) => ({ ...prevState, showIndicators: false }));
  }, []);

  const onCarouselItemMouseEnter = useCallback((e: React.SyntheticEvent<HTMLDivElement>) => {
    if (e.currentTarget.dataset.idx) {
      seItemPreviewCarouselCurrentIndex(Number(e.currentTarget.dataset.idx));
    }
  }, []);

  useEffect(() => {
    const carouselWrapperEl = document.getElementById('carousel_wrapper');

    if (carouselWrapperEl && preparedCarouselImages) {
      setItemPreviewCarouselState((prevState) => {
        return {
          ...prevState,
          carouselWidth: carouselWrapperEl.offsetWidth,
          carouselItemWidth: itemPreviewCarouselState.carouselWidth / preparedCarouselImages.length,
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemPreviewCarouselState.carouselWidth, preparedCarouselImages?.length]);

  return (
    <ItemPreviewCarouselWrapper
      id="carousel_wrapper"
      onMouseEnter={onCarouselWrapperMouseEnter}
      onMouseLeave={onCarouselWrapperMouseLeave}
    >
      {!!preparedCarouselImages?.length ? (
        preparedCarouselImages.map((imgSource, idx) => (
          <React.Fragment key={idx}>
            <ItemPreviewCarouselItemBlock
              data-idx={idx}
              onMouseEnter={onCarouselItemMouseEnter}
              itemwidth={itemPreviewCarouselState.carouselItemWidth}
            >
              <ItemPreviewCarouselIndicator
                display={itemPreviewCarouselState.showIndicators ? 'block' : 'none'}
                sx={{
                  bgcolor:
                    idx === itemPreviewCarouselCurrentIndex
                      ? theme.palette.secondary.dark
                      : theme.palette.background.default,
                }}
              />
            </ItemPreviewCarouselItemBlock>
            <MediaItemWithFallback
              idx={idx}
              imgSource={imgSource}
              itemPreviewCarouselCurrentIndex={itemPreviewCarouselCurrentIndex}
            />
          </React.Fragment>
        ))
      ) : (
        <Box display="flex" justifyContent="center" alignItems="center" width="100%">
          <img src={ItemEmptyBackgroundImg} alt="item" />
        </Box>
      )}
    </ItemPreviewCarouselWrapper>
  );
};

export default ItemPreviewCarousel;
