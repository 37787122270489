import { useMutation, useLazyQuery, useApolloClient } from '@apollo/client';

import { clearCacheFactory } from '../utils/cacheUtils';
import {
  bulkCreateProductTypeMappings,
  bulkUpdateProductTypeMappings,
  createProductTypeFieldMappings as createProductTypeFieldMappingsQuery,
  deleteProductTypeFieldMappings as deleteProductTypeFieldMappingsQuery,
  deleteProductTypeIntegrationMapping as deleteProductTypeIntegrationMappingQuery,
  Mutation,
  Query,
  MutationBulkCreateProductTypeIntegrationMappingsArgs,
  MutationBulkUpdateProductTypeIntegrationMappingsArgs,
  MutationCreateProductTypeFieldIntegrationMappingsArgs,
  MutationDeleteProductTypeFieldIntegrationMappingsArgs,
  MutationDeleteProductTypeIntegrationMappingArgs,
  MutationUpdateProductTypeFieldIntegrationMappingsArgs,
  updateProductTypeFieldMappings as updateProductTypeFieldMappingsQuery,
  checkCatalogMappingsErrors,
  QueryGetCatalogByIdArgs,
  QueryGetProductTypeArgs,
  checkProductTypeMappingsErrors,
} from '../utils/gql';
import { clearRequiredRelationsFields } from '../views/Catalogs/ProductTypes/ProductTypeSettings/MappingTabContent/constants';

const afterFieldMappingChangeCacheRecords = ['checkIfFieldCanBeDeleted', 'productTypeFieldIntegrationMappings'];

const afterTypeMappingChangeCacheRecords = [
  'getProductTypeIntegrationMappingsByCatalogId',
  ...afterFieldMappingChangeCacheRecords,
];

export const clearCacheAfterFieldMappingChange = clearCacheFactory({
  fieldNames: afterFieldMappingChangeCacheRecords,
});

export const clearCacheAfterTypeMappingChange = clearCacheFactory({
  fieldNames: afterTypeMappingChangeCacheRecords,
});

export const useCreateProductTypesMappings = () => {
  const [createProductTypeMappings] = useMutation<
    Pick<Mutation, 'bulkCreateProductTypeIntegrationMappings'>,
    MutationBulkCreateProductTypeIntegrationMappingsArgs
  >(bulkCreateProductTypeMappings, {
    update: clearCacheAfterTypeMappingChange,
  });

  return createProductTypeMappings;
};

export const useUpdateProductTypeMappings = () => {
  const [updateProductTypeMappings] = useMutation<
    Pick<Mutation, 'bulkUpdateProductTypeIntegrationMappings'>,
    MutationBulkUpdateProductTypeIntegrationMappingsArgs
  >(bulkUpdateProductTypeMappings, {
    update: clearCacheAfterFieldMappingChange,
  });

  return updateProductTypeMappings;
};

export const useDeleteProductTypeMapping = () => {
  const [deleteProductTypeMapping] = useMutation<
    Pick<Mutation, 'deleteProductTypeIntegrationMapping'>,
    MutationDeleteProductTypeIntegrationMappingArgs
  >(deleteProductTypeIntegrationMappingQuery, {
    update: clearCacheAfterTypeMappingChange,
  });

  return deleteProductTypeMapping;
};

export const useCreateProductTypeFieldMappings = () => {
  const [createProductTypeFieldMappings] = useMutation<
    Pick<Mutation, 'createProductTypeFieldIntegrationMappings'>,
    MutationCreateProductTypeFieldIntegrationMappingsArgs
  >(createProductTypeFieldMappingsQuery, {
    update: clearCacheAfterFieldMappingChange,
  });

  return createProductTypeFieldMappings;
};

export const useUpdateProductTypeFieldMappings = () => {
  const [updateProductTypeFieldMappings] = useMutation<
    Pick<Mutation, 'updateProductTypeFieldIntegrationMappings'>,
    MutationUpdateProductTypeFieldIntegrationMappingsArgs
  >(updateProductTypeFieldMappingsQuery, {
    update: clearRequiredRelationsFields,
  });

  return updateProductTypeFieldMappings;
};

export const useDeleteProductTypeFieldMappings = () => {
  const [deleteProductTypeFieldMappings] = useMutation<
    Pick<Mutation, 'deleteProductTypeFieldIntegrationMappings'>,
    MutationDeleteProductTypeFieldIntegrationMappingsArgs
  >(deleteProductTypeFieldMappingsQuery, {
    update: clearCacheAfterFieldMappingChange,
  });

  return deleteProductTypeFieldMappings;
};

export const useCheckCatalogMappingErrors = (catalogId: string) => {
  const { cache } = useApolloClient();
  const [checkCatalogMappingsErrorsQuery, { loading }] = useLazyQuery<
    Pick<Query, 'getCatalogById'>,
    QueryGetCatalogByIdArgs
  >(checkCatalogMappingsErrors, {
    fetchPolicy: 'network-only',
    variables: { id: catalogId },
    onCompleted: () => {
      clearCacheFactory({ fieldNames: ['getProductType'] })(cache);
    },
  });

  return { checkCatalogMappingsErrorsQuery, loading };
};

export const useCheckProductTypeMappingErrors = (productTypeId: string) => {
  const [checkProductTypeMappingsErrorsQuery] = useLazyQuery<Pick<Query, 'getProductType'>, QueryGetProductTypeArgs>(
    checkProductTypeMappingsErrors,
    {
      fetchPolicy: 'cache-and-network',
      variables: { id: productTypeId },
    },
  );

  return { checkProductTypeMappingsErrorsQuery };
};
