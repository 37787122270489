import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import Hint from 'src/components/Hint';
import Iconography from 'src/components/Iconography';
import MappingsButton from 'src/components/MappingsButton';
import useOnboarding from 'src/providers/OnboardingProvider/hooks/useOnboarding';
import { IntegrationMappingError } from 'src/utils/gql';

import ProductTypeFieldsTable from './ProductTypeFieldsTables/ProductTypeFieldsTable';
import {
  ProductTypeDefaultFieldsTableWrapper,
  ProductTypeCustomFieldsHeadline,
  AddCustomFieldButtonContainer,
  StyledAddFieldText,
  ProductTypeCustomFieldsTableWrapper,
  NoCustomFields,
} from './styled';
import { CustomFieldsProps, ProductTypeSettingsField, ProductTypeSettingsState } from './types';

interface FieldsTabContentProps {
  productTypeSettingsState: ProductTypeSettingsState;
  editFieldSettings: (field: ProductTypeSettingsField, fieldIndex: number) => void;
  onOpenFieldSettings: () => void;
  onDeleteCustomField: (index: number) => void;
  selectedFieldsForDeletion?: string[];
  goToMappings: () => void;
  creatingProductType: boolean;
  setCanBeDeleted: (value: React.SetStateAction<CustomFieldsProps[]>) => void;
  productTypeErrors?: IntegrationMappingError[];
}

const FieldsTabContent = ({
  productTypeSettingsState,
  editFieldSettings,
  onOpenFieldSettings,
  onDeleteCustomField,
  selectedFieldsForDeletion = [],
  goToMappings,
  creatingProductType,
  setCanBeDeleted,
  productTypeErrors,
}: FieldsTabContentProps) => {
  const { t } = useTranslation();
  const {
    onboardingState: { tourActive },
  } = useOnboarding();

  const showCustomFieldsTable = productTypeSettingsState.customFields.length;

  return (
    <Box position="relative">
      {tourActive && <Box id="default-fields-anchor" position="absolute" height="336px" width="77%" />}

      <Box display="flex" mt="26px" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Typography variant="subtitle1" color="text.primary" mr="17px">
            {t('productType.defaultFields')}
          </Typography>

          <Hint
            type="hover"
            title={t('fieldSettings.defaultFieldsTableHint') as string}
            arrow
            placement="right"
            isDefaultFieldValueHint
          >
            <Box display="flex">
              <Iconography iconName="help-fill" htmlColor="secondary" />
            </Box>
          </Hint>
        </Box>

        {!creatingProductType && (
          <Box mr="56px">
            <MappingsButton testId="mappingButton" onClick={goToMappings} errors={productTypeErrors} />
          </Box>
        )}
      </Box>

      <ProductTypeDefaultFieldsTableWrapper>
        <ProductTypeFieldsTable fields={productTypeSettingsState.defaultFields} onEditClick={editFieldSettings} />
      </ProductTypeDefaultFieldsTableWrapper>

      <ProductTypeCustomFieldsHeadline>
        <Typography variant="subtitle1" color="text.primary" mr="17px">
          {t('productType.customFields')}
        </Typography>

        <AddCustomFieldButtonContainer
          id="add-field-btn-anchor"
          onClick={onOpenFieldSettings}
          data-testid="addCustomFieldButton"
        >
          <Iconography iconName="add" htmlColor="secondary" fontSize="small" />

          <StyledAddFieldText variant="subtitle1">{t('productType.addFieldButton')}</StyledAddFieldText>
        </AddCustomFieldButtonContainer>
      </ProductTypeCustomFieldsHeadline>

      <ProductTypeCustomFieldsTableWrapper>
        {showCustomFieldsTable ? (
          <ProductTypeFieldsTable
            fields={productTypeSettingsState.customFields}
            onEditClick={editFieldSettings}
            onDeleteClick={onDeleteCustomField}
            isDeletable
            selectedFieldsForDeletion={selectedFieldsForDeletion}
            setCanBeDeleted={setCanBeDeleted}
          />
        ) : (
          <Box display="flex">
            <NoCustomFields margin="auto">{t('productType.emptyCustomFields')}</NoCustomFields>
          </Box>
        )}
      </ProductTypeCustomFieldsTableWrapper>
    </Box>
  );
};

export default FieldsTabContent;
