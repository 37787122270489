import { Dialog } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';

import { theme } from 'src/theme';

export const CheckEmailDialog = styled(Dialog)(() => ({
  '& .MuiBackdrop-root': {
    background: 'rgba(241, 245, 249, 0.7)',
  },

  '& .MuiPaper-root': {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: 704,
    height: 546,
    boxShadow: '0 2px 10px rgba(46, 96, 170, 0.25)',
  },

  '& .MuiButtonBase-root': {
    position: 'absolute',
    top: 15,
    right: 15,
    width: 34,
    height: 34,
    background: theme.palette.background.default,

    svg: {
      width: 20,
      height: 20,
    },
  },

  img: {
    width: 314,
    height: 240,
  },
}));
