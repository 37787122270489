import React from 'react';
import { Trans } from 'react-i18next';

export enum NavItemId {
  CATALOGS,
  SHARED_CATALOGS,
  ADD,
  HELP,
  AVATAR,
  SUPPORT,
}

export const MAIN_NAV_ITEMS = [
  {
    id: NavItemId.CATALOGS,
    title: <Trans i18nKey="navBar.catalogues">Catalogues</Trans>,
    iconName: 'catalog',
    testId: 'navbarCatalogButton',
  },
  {
    id: NavItemId.SHARED_CATALOGS,
    title: <Trans i18nKey="navBar.sharedCatalogs">Shared catalogs</Trans>,
    iconName: 'share-social',
    testId: 'navbarSharedCatalogsButton',
  },
  {
    id: NavItemId.ADD,
    title: <Trans i18nKey="navBar.add">Add</Trans>,
    iconName: 'add',
    testId: 'navbarAddButton',
  },
];

export const ADD_BUTTON_NAV_ITEMS = [
  {
    id: 0,
    title: <Trans i18nKey="navBar.addButtonMenu.catalog">Catalog</Trans>,
    testId: 'listNavBarCatalogButton',
  },
  {
    id: 1,
    title: <Trans i18nKey="navBar.addButtonMenu.productItem">Product Type</Trans>,
    testId: 'listNavBarProductTypeButton',
  },
  {
    id: 2,
    title: <Trans i18nKey="navBar.addButtonMenu.item">Item</Trans>,
    testId: 'listNavBarItemButton',
  },
];

export const FOOTER_NAV_ITEMS = [
  {
    id: NavItemId.SUPPORT,
    title: <Trans i18nKey="navBar.support">Support</Trans>,
    iconName: 'support',
    testId: 'navBarSupportButton',
  },
  {
    id: NavItemId.HELP,
    title: <Trans i18nKey="navBar.help">Help</Trans>,
    iconName: 'help-fill',
    testId: 'navBarHelpButton',
  },
  {
    id: NavItemId.AVATAR,
    iconName: 'profile-alt',
    testId: 'navBarAccountBlockButton',
  },
];
