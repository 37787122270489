import { useContext } from 'react';

import AccountContext from '../context';
import { AccountContextType } from '../types';

const useAccount = (): AccountContextType => {
  return useContext(AccountContext);
};

export default useAccount;
