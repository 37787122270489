import { CatalogueCardSizes } from 'src/components/Card/CatalogCard';
import {
  SharedCataloguesSubscriptions,
  SharedCataloguesSubscriptionsActions,
} from 'src/components/SharedCatalogsTable/types';
import { capitalize } from 'src/helpers/capitalize';
import palette from 'src/theme/palette';
import { CatalogSource, IntegrationTypes, PaymentFrequency } from 'src/utils/gql';

export const DEFAULT_USER_NAME = 'Otomate User';

export const SUBSCRIPTION_EXPIRED_BANNER_HEIGHT = 76;

export const MAX_SHOW_IN_PREVIEW_FIELDS = 7;

export const WOOCOMMERCE_API_LINK = 'https://docs.woocommerce.com/document/woocommerce-rest-api';

export const EMAIL_PATTERN = new RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/,
);

/*
 The regex pattern allows:
 - Special symbols: !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~
 - Latin letters (both cases): A-Za-z
 - Numbers: 0-9
 - Max length - 50
 - Min length - 1
 */
export const SKU_PATTERN = /^[A-Za-z0-9!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{1,50}$/;

export const SHOP_URL_VALIDATION = /.(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})\s*(?=\/?$)/;
export const MEDIA_URL_VALIDATION =
  /^(https?:\/\/(www\.)?)?[a-z0-9]+([\-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;

export const EXCEEDED_LIMIT_VALUE = 255;

export const NUMBER_OF_MONTHS_PER_YEAR = 12;

export const PRODUCT_TYPES_DEFAULT_LIMIT = 8;
export const DEFAULT_CATALOGUES_PAGE_LIMIT = 12;
export const PRODUCT_ITEMS_DEFAULT_LIMIT = 9;
export const SUBSCRIPTIONS_DEFAULT_LIMIT = 10;

export const NOT_FOUND_404 = / ((not exist)|(is missing))/g;
export const FORBIDDEN_403 = /^You do not have access to this /g;
export const FACEBOOK_401 = /facebook oauthexception/gi;
export const FACEBOOK_AUTH_ERROR_CODE = 190;
export const COUNT_RENDERS_NEED_TO_WAIT_WHILE_MUI_POPUP_CLOSING = 10;

export const ITEMS_PER_PAGE_MENU_LIST = [25, 50, 75, 100];

export const BOOLEAN_FIELD_SELECT_OPTIONS = [
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
];

export const EXPORT_CARDS_DATA: {
  [key: string]: { headerTitle: string; type: IntegrationTypes; text: string; testId: string };
} = {
  csv: {
    headerTitle: 'exportCardCsv.title',
    type: IntegrationTypes.Csv,
    text: 'exportCardCsv.description',
    testId: 'CSVCard',
  },
  wooCommerce: {
    headerTitle: 'exportCardWooCommerce.title',
    type: IntegrationTypes.Woocommerce,
    text: 'exportCardWooCommerce.description',
    testId: 'WooCard',
  },
  shopify: {
    headerTitle: 'exportCardShopify.title',
    type: IntegrationTypes.Shopify,
    text: 'exportCardShopify.description',
    testId: 'ShopifyCard',
  },
  eBay: {
    headerTitle: 'exportCardEbay.title',
    type: IntegrationTypes.Ebay,
    text: 'exportCardEbay.description',
    testId: 'EbayCard',
  },
  facebook: {
    headerTitle: 'exportCardFacebook.title',
    type: IntegrationTypes.Facebook,
    text: 'exportCardFacebook.description',
    testId: 'FacebookCard',
  },
};

export const CATALOG_PRIVACY_DATA = [
  {
    value: 'private',
    title: 'settingsPage.sharingOptions.catalogPrivacy.status.private',
    description: 'settingsPage.sharingOptions.catalogPrivacy.description.private',
    testId: 'radioButtonCatalogPrivate',
  },
  {
    value: 'protected',
    title: 'settingsPage.sharingOptions.catalogPrivacy.status.protected',
    description: 'settingsPage.sharingOptions.catalogPrivacy.description.protected',
    testId: 'radioButtonCatalogProtected',
  },
  {
    value: 'public',
    title: 'settingsPage.sharingOptions.catalogPrivacy.status.public',
    description: 'settingsPage.sharingOptions.catalogPrivacy.description.public',
    testId: 'radioButtonCatalogPublic',
  },
];

export const PAYMENT_FREQUENCY_OPTIONS = [
  {
    label: capitalize(PaymentFrequency.Free),
    value: PaymentFrequency.Free,
  },
  {
    label: capitalize(PaymentFrequency.Once),
    value: PaymentFrequency.Once,
  },
  {
    label: capitalize(PaymentFrequency.Daily),
    value: PaymentFrequency.Daily,
  },
  {
    label: capitalize(PaymentFrequency.Weekly),
    value: PaymentFrequency.Weekly,
  },
  {
    label: capitalize(PaymentFrequency.Monthly),
    value: PaymentFrequency.Monthly,
  },
  {
    label: capitalize(PaymentFrequency.Annually),
    value: PaymentFrequency.Annually,
  },
];

export enum BULK_SELECT_ACTIONS {
  edit = 1,
  export = 2,
  delete = 3,
  deleteSelectedItems = 4,
  deleteAllItems = 5,
  editSelectedItems = 6,
  editAllItems = 7,
  exportSelectedItems = 8,
  exportAllItems = 9,
  clone = 10,
  makeDraft = 11,
}

export const INITIAL_BULK_SELECT_OPTIONS = [
  { action: BULK_SELECT_ACTIONS.edit, label: 'productItemsPage.bulkSelect.bulkEdit' },
  { action: BULK_SELECT_ACTIONS.clone, label: 'productItemsPage.bulkSelect.clone' },
  { action: BULK_SELECT_ACTIONS.makeDraft, label: 'productItemsPage.bulkSelect.makeDraft' },
  // MOTHBALLED TO MVP

  // { action: BULK_SELECT_ACTIONS.export, label: 'productItemsPage.bulkSelect.export' },
  { action: BULK_SELECT_ACTIONS.delete, label: 'productItemsPage.bulkSelect.delete' },
];

// MOTHBALLED TO MVP

// export const BULK_SELECT_ACTIONS_FOR_SHARED_CATALOGS = [
//   BULK_SELECT_ACTIONS.export,
//   BULK_SELECT_ACTIONS.exportSelectedItems,
//   BULK_SELECT_ACTIONS.exportAllItems,
// ];

export const SHARED_CATALOGUES_SUBSCRIPTION_STATUSES: SharedCataloguesSubscriptions = {
  pending: {
    statusText: 'settingsPage.table.statuses.pending',
    textColor: 'warning.main',
    action: SharedCataloguesSubscriptionsActions.cancel,
  },
  approved: {
    statusText: 'settingsPage.table.statuses.approved',
    textColor: 'success.main',
    action: SharedCataloguesSubscriptionsActions.cancel,
  },
  declined: {
    statusText: 'settingsPage.table.statuses.declined',
    textColor: 'secondary.main',
    action: SharedCataloguesSubscriptionsActions.request,
  },
};

export const USER_SETTINGS_TABS = [
  { label: 'userSettingsPage.tabs.general', testId: 'generalTab' },
  { label: 'userSettingsPage.tabs.billing', testId: 'billingTab' },
  { label: 'userSettingsPage.tabs.publicApi', testId: 'publicApiTab' },
];

export enum FontWeightType {
  Fat = 'fat',
  Middle = 'middle',
  Small = 'small',
}

export enum OnboardingCategory {
  CreatingCatalog,
  SharingCatalog,
  ImportFromStores,
  ExportToStores,
  ProductTypesAndItems,
  FilterForItems,
}

export const FILE_EXTENSION = {
  image: 'image/*',
  csv: 'text/csv',
  csvWin: 'application/vnd.ms-excel',
};

export enum ShopifyUrlParams {
  Shop = 'shop',
  Code = 'code',
}

export enum EbayUrlParams {
  Code = 'code',
}

export enum FacebookHashParams {
  Code = 'code',
  Error = 'error',
  ErrorMessage = 'error_message',
  State = 'state',
  AccessToken = 'access_token',
}

export const PAID_PLAN_URL_PARAM = 'paid_plan';

export enum OnboardingImageTypes {
  activeCategory = 'activeCategory',
  welcome = 'welcome',
  skip = 'skip',
}

export enum LandingAnchorNames {
  about = '#about',
  access = '#access',
  faq = '#faq',
}

export enum PARSING_FILE_STATE {
  Uploading = 0,
  UploadingError = 1,
  Uploaded = 2,
  Parsing = 3,
  ParsingError = 4,
  Parsed = 5,
}

export const NON_AUTH_QUERIES = ['signIn', 'signUp', 'sendContactUsEmail', 'getPlans'];

export const BILLING_INVOICES_TABLE_LABELS = [
  'userSettingsPage.billing.invoices.number',
  'userSettingsPage.billing.invoices.card',
  'userSettingsPage.billing.invoices.date',
  'userSettingsPage.billing.invoices.amount',
  'userSettingsPage.billing.invoices.status',
];

export const AUDIT_LOG_TABLE_COLUMNS = [
  { label: '' },
  { label: 'auditLogPage.date', sortable: true },
  { label: 'auditLogPage.type' },
  { label: 'auditLogPage.description' },
  { label: 'auditLogPage.place' },
  { label: 'auditLogPage.details' },
];

const documentsRootUrl = process.env.REACT_APP_DOCUMENTS_ROOT_URL;
const changelogRootUrl = process.env.REACT_APP_CHANGELOG_ROOT_URL;

export const EXTERNAL_URL = {
  PRIVACY_POLICY: `${documentsRootUrl}#privacy-policy`,
  TERMS_AND_CONDITIONS: `${documentsRootUrl}#terms-and-conditions`,
  CHANGELOG: `${changelogRootUrl}`,
  HELP_CENTER: process.env.REACT_APP_HELP_CENTER_URL,
};

export const MAX_PREVIEW_ITEM_CAROUSEL_IMAGES = 4;

enum ToastMessageTypes {
  Success = 'success',
  Info = 'info',
  Error = 'error',
}

export const TOAST_MESSAGES = {
  [ToastMessageTypes.Success]: {
    iconName: 'check-circle',
    type: 'generalSnackbarMessageTypes.success',
    color: palette.success.main as string,
  },
  [ToastMessageTypes.Info]: {
    iconName: 'info-fill',
    type: 'generalSnackbarMessageTypes.info',
    color: palette.secondary.dark as string,
  },
  [ToastMessageTypes.Error]: {
    iconName: 'cancel-circle',
    type: 'generalSnackbarMessageTypes.error',
    color: palette.error.main as string,
  },
};

export const EmptyValuesUrlParamsMarker = {
  value: '$',
  regex: /\$$/g,
};

export enum FeatureFlag {
  SHARED_CATALOGS = 'shared_catalogs',
  AUDIT_LOG = 'audit_log',
  ONBOARDING = 'onboarding',
  RECENT_STEPS = 'recent_steps',
  SIGN_UP = 'sign_up',
  PUBLIC_API = 'public_api',
  BILLING = 'billing',
  CHANGELOG = 'changelog',
  SHOPIFY_CUSTOM_OPTIONS_MAPPINGS = 'shopify_custom_options_mappings',
  GOOGLE_AUTH = 'google_auth',
}

export const ShopifyDefaultSettings = { integrationName: '', shop: '' };
export const WooCommerceDefaultSettings = { integrationName: '', url: '', apiSecret: '', apiKey: '' };
export const EbayDefaultSettings = {
  integrationName: '',
  marketplaceId: '',
  paymentPolicyId: '',
  returnPolicyId: '',
  fulfillmentPolicyId: '',
  merchantLocationKey: '',
};

export const linkPricingPlan = '/upgrade-pricing-plan';
export const FacebookDefaultSettings = { integrationName: '', userId: '', businessId: '', accessToken: '' };

export const CatalogCardBackgrounds: { [x: string]: { [y: string]: string } } = {
  [IntegrationTypes.Shopify]: {
    [CatalogueCardSizes.medium]: 'CatalogCardShopifyBackground',
    [CatalogueCardSizes.small]: 'CatalogSmallCardShopifyBackground',
  },
  [IntegrationTypes.Woocommerce]: {
    [CatalogueCardSizes.medium]: 'CatalogCardWoocommerceBackground',
    [CatalogueCardSizes.small]: 'CatalogSmallCardWoocommerceBackground',
  },
  [CatalogSource.Csv]: {
    [CatalogueCardSizes.medium]: 'CatalogCardCsvBackground',
    [CatalogueCardSizes.small]: 'CatalogSmallCardCsvBackground',
  },
};

export const FACEBOOK_BROADCAST_CHANEL_NAME = 'Facebook';
export enum FacebookEvent {
  TokenUpdated = 'TokenUpdated',
}

export const ONBOARDING_CSV_HASH = 'f1449a75ac0a273b7ff3739bb0a3928881abb00e';

export const LINK_TO_SURVEY =
  'https://docs.google.com/forms/d/e/1FAIpQLSfaG51CmHgyS6qtAoN0RDtAvvFRBUqXUTcIbuRCL1fwMe4N1Q/viewform';

export const SHOPIFY_OPTION_NAME_REGEXP = /^option\.[1-3]$/m;
