import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useLazyQuery } from '@apollo/client';
import { Box, Link, makeStyles, Typography } from '@material-ui/core';
import { validate } from 'validate.js';

import Button from 'src/components/Button';
import { EMAIL_PATTERN, EXCEEDED_LIMIT_VALUE, EXTERNAL_URL } from 'src/constants';
import { validateTextFieldValue } from 'src/helpers/validationCheck';
import { FormStateInterface } from 'src/views/types';

import { sendContactUsEmail } from '../../utils/gql';
import WrapperTextInput from '../Forms/FormInputs/WrapperTextInput';

import { FormWrapper, SendButtonWrapper, ContactFieldsWrapper } from './styled';

export const useStyles = makeStyles(() => ({
  singleLineTextField: {
    height: '42px',
  },
  textFieldMargin: {
    marginBottom: '27px',
  },
  form: {
    maxWidth: '580px',
    width: '100%',
  },
}));

const schema = {
  userName: {
    presence: {
      allowEmpty: false,
      message: <Trans i18nKey="enterNameError">Please, enter the name</Trans>,
    },
  },
  email: {
    presence: {
      allowEmpty: false,
      message: <Trans i18nKey="enterEmailError">Please, enter email</Trans>,
    },
    format: {
      pattern: EMAIL_PATTERN,
      message: <Trans i18nKey="enterValidEmailError">Please, enter valid email</Trans>,
    },
  },
  companyWebsite: {
    presence: {
      allowEmpty: true,
    },
  },
  description: {
    presence: {
      allowEmpty: false,
    },
  },
};

type FormState = FormStateInterface<Record<keyof typeof schema, string>>;

interface ContactUsFormProps {
  onCompleted: () => void;
}

const ContactUsForm = ({ onCompleted }: ContactUsFormProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [formState, setFormState] = useState<FormState>({
    isValid: false,
    values: {
      userName: '',
      email: '',
      companyWebsite: '',
      description: '',
    },
    touched: {},
    errors: {},
  });

  const [sendSupportEmail, { loading }] = useLazyQuery(sendContactUsEmail, {
    fetchPolicy: 'network-only',
    onCompleted: onCompleted,
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setFormState((prevFormState: FormState) => {
      return {
        ...prevFormState,
        values: {
          ...prevFormState.values,
          [name]: validateTextFieldValue(value),
        },
        touched: {
          ...prevFormState.touched,
          [name]: true,
        },
      };
    });
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    if (!formState.isValid) return;

    const { email, userName: name, companyWebsite: company_name, description } = formState.values;

    const data = {
      email,
      name,
      company_name,
      description,
    };

    sendSupportEmail({
      variables: {
        data,
      },
    });
  };

  const hasError = (field: string) => Boolean(formState.touched[field] && formState.errors[field]);
  const isLongUserName = formState.values.userName.length > EXCEEDED_LIMIT_VALUE;
  const isLongEmail = formState.values.email.length > EXCEEDED_LIMIT_VALUE;
  const isLongDescription = formState.values.description.length > EXCEEDED_LIMIT_VALUE;
  const isLongCompanyWebsite = formState.values.companyWebsite.length > EXCEEDED_LIMIT_VALUE;
  const helperTextUserName = isLongUserName ? t('exceededLimitValue') : '';
  const helperTextEmail = isLongEmail ? t('exceededLimitValue') : '';
  const helperTextCompanyWebsite = isLongCompanyWebsite ? t('exceededLimitValue') : '';
  const helperTextDescription = isLongDescription ? t('exceededLimitValue') : '';

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((prevFormState) => ({
      ...prevFormState,
      isValid: !errors,
      errors: errors || {},
    }));
  }, [formState.values]);

  const nameInputMessage = hasError('userName') ? formState.errors.userName[0] : '';
  const emailInputMessage = hasError('email') ? formState.errors.email[0] : '';

  return (
    <form onSubmit={handleSubmit} className={classes.form} autoComplete="off">
      <FormWrapper>
        <WrapperTextInput
          shouldFocus
          onChange={handleChange}
          label={t('contactUsPage.form.fullName')}
          value={formState.values.userName}
          name="userName"
          error={hasError('userName') || isLongUserName}
          helperTextError={helperTextUserName || nameInputMessage}
          className={classes.textFieldMargin}
        />
        <ContactFieldsWrapper>
          <Box width="282px">
            <WrapperTextInput
              label={t('contactUsPage.form.email')}
              value={formState.values.email}
              error={hasError('email') || isLongEmail}
              name="email"
              onChange={handleChange}
              helperTextError={emailInputMessage || helperTextEmail}
            />
          </Box>
          <Box width="282px">
            <WrapperTextInput
              label={t('contactUsPage.form.companyWebsite')}
              value={formState.values.companyWebsite}
              name="companyWebsite"
              onChange={handleChange}
              helperTextError={helperTextCompanyWebsite}
              error={isLongCompanyWebsite}
            />
          </Box>
        </ContactFieldsWrapper>
        <WrapperTextInput
          name="description"
          placeholder={t('contactUsPage.form.descriptionPlaceholder')}
          multiline
          minRows="4"
          label={t('contactUsPage.form.description')}
          onChange={handleChange}
          value={formState.values.description}
          error={isLongDescription}
          helperTextError={helperTextDescription}
        />
        <Typography
          color="text.primary"
          variant="h6"
          height="18px"
          fontSize="15px"
          fontWeight="400"
          mt="15px"
          textAlign="left"
        >
          {t('contactUsPage.form.privacyAndTermConditionFirst')}
          <Link underline="always" color="secondary.main" href={EXTERNAL_URL.PRIVACY_POLICY}>
            {t('contactUsPage.form.privacyPolicy')}
          </Link>
          {t('contactUsPage.form.privacyAndTermConditionSecond')}
          <Link underline="always" color="secondary.main" href={EXTERNAL_URL.TERMS_AND_CONDITIONS}>
            {t('contactUsPage.form.termsAndConditions')}
          </Link>
          {t('contactUsPage.form.privacyAndTermConditionThird')}
        </Typography>
        <SendButtonWrapper>
          <Button
            fullWidth
            variant="contained"
            disabled={!(formState.isValid && formState.values)}
            type="submit"
            loading={loading}
            onClick={onCompleted}
          >
            {t('contactUsPage.form.sendButton')}
          </Button>
        </SendButtonWrapper>
      </FormWrapper>
    </form>
  );
};

export default ContactUsForm;
