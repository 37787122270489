import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography, withStyles } from '@material-ui/core';

import SubscriptionsPopup from 'src/components/Popups/SubscriptionsPopup';
import { StyledTextCell } from 'src/components/SharedCatalogsTable/styled';
import { SharedCataloguesSubscriptionsActions } from 'src/components/SharedCatalogsTable/types';
import useControlSubscriptions from 'src/hooks/useControlSubscriptions';

import Avatar from '../Avatar';
import Button from '../Button';

import { styles } from './styled';
import { ActionButtonsProps, ActionIconsProps, StatusType, SubscriberProps, SubscriptionStatusProps } from './types';

export const Subscriber = ({ userName, avatar }: SubscriberProps) => {
  return (
    <Box display="flex" alignItems="center">
      <Avatar size="small" src={avatar} alt="avatar" />
      <StyledTextCell ml="10px">{userName}</StyledTextCell>
    </Box>
  );
};

export const ActionButtons = ({ subscriptionId }: ActionButtonsProps) => {
  const { t } = useTranslation();

  const { onUpdateSubscription: handleAcceptSubscription } = useControlSubscriptions({
    subscriptionId,
    status: StatusType.approved,
  });

  const { onUpdateSubscription: handleDeclineSubscription } = useControlSubscriptions({
    subscriptionId,
    status: StatusType.declined,
  });

  return (
    <>
      <Box>
        <Button onClick={handleAcceptSubscription} variant="contained" sx={{ marginBottom: '10px' }}>
          {t('settingsPage.table.buttons.accept')}
        </Button>

        <Button onClick={handleDeclineSubscription} variant="outlined">
          {t('settingsPage.table.buttons.decline')}
        </Button>
      </Box>
    </>
  );
};

export const ActionIcons = ({ status, subscriptionId, userName, catalogName }: ActionIconsProps) => {
  const { t } = useTranslation();
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const { onUpdateSubscription } = useControlSubscriptions({
    subscriptionId,
    status: StatusType.pending,
  });

  const { onCancelSubscription, loadingStatus } = useControlSubscriptions({
    subscriptionId,
  });

  const handleCancelSubscription = () => {
    onCancelSubscription();
    handleClosePopup();
  };

  return (
    <>
      {status === StatusType.approved ? (
        <Box display="flex" justifyContent="center">
          <Button variant="outlined" onClick={handleOpenPopup}>
            {t('settingsPage.table.buttons.decline')}
          </Button>
        </Box>
      ) : (
        <Box display="flex" justifyContent="center">
          <Button onClick={onUpdateSubscription} variant="outlined">
            {t('settingsPage.table.buttons.return')}
          </Button>
        </Box>
      )}
      <SubscriptionsPopup
        isOpen={isPopupOpen}
        catalogOwner={userName}
        handleMainButtonClick={handleCancelSubscription}
        handleClosePopup={handleClosePopup}
        subscriptionAction={SharedCataloguesSubscriptionsActions.deleteUser}
        catalogName={catalogName}
        loadingStatus={loadingStatus}
      />
    </>
  );
};

export const SubscriptionStatus = withStyles(styles)(({ classes, status }: SubscriptionStatusProps) => {
  const { t } = useTranslation();

  return (
    <Typography variant="subtitle2" component="span" classes={{ root: classes.root }}>
      {status === StatusType.pending && t('settingsPage.table.statuses.pending')}
      {status === StatusType.approved && t('settingsPage.table.statuses.approved')}
      {status === StatusType.declined && t('settingsPage.table.statuses.declined')}
    </Typography>
  );
});
