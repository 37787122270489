import { IntegrationTypes } from 'src/utils/gql';
import { IntegrationAction, IntegrationItem } from 'src/views/UserSettings/types';

export type IntegrationItemOmitType = Omit<IntegrationItem, 'id' | 'type'>;

export interface IntegrationsPopupState {
  isOpen: boolean;
  integrationType: IntegrationTypes;
  action: number;
  data: IntegrationItem;
}

export interface DeletePopupState {
  name?: string;
  integrationId?: string;
  isOpen: boolean;
  buttonLoading: boolean;
}

export const initialPopupState = {
  isOpen: false,
  integrationType: '' as IntegrationTypes,
  action: IntegrationAction.Add,
  data: { id: '', type: '' as IntegrationTypes, isDefault: false, settings: {} },
} as IntegrationsPopupState;

export const initialDeletePopupState = {
  name: undefined,
  integrationId: undefined,
  isOpen: false,
  buttonLoading: false,
};

export interface ExportToIntegrationPopupState {
  isOpen: boolean;
  integrationType: IntegrationTypes;
  data: IntegrationItem[];
}

export const initialExportToIntegrationPopupState = {
  isOpen: false,
  integrationType: '' as IntegrationTypes,
  data: [],
} as ExportToIntegrationPopupState;
