import React from 'react';

import ProductTypeSettingsEditing from '../ProductTypeSettingsEditing';
import { ProductTypeSettingsPage } from '../types';

const ProductTypeSettingsMappings = () => {
  return <ProductTypeSettingsEditing type={ProductTypeSettingsPage.Mapping} />;
};

export default ProductTypeSettingsMappings;
