import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@material-ui/core';

import Button from 'src/components/Button';
import Iconography from 'src/components/Iconography';

import CreateProductTypeBackground from './CreateProductTypeBackground';
import { NoProductTypes } from './styled';

interface NoProductTypesComponentProps {
  isImageLoading: boolean;
  isCurrentCatalogShared: boolean;
  activeJobLoading: boolean;
  setIsImageLoading: (loading: boolean) => void;
  onAddNewProductType: () => void;
}

const NoProductTypesComponent = ({
  isImageLoading,
  setIsImageLoading,
  isCurrentCatalogShared,
  activeJobLoading,
  onAddNewProductType,
}: NoProductTypesComponentProps) => {
  const { t } = useTranslation();

  return (
    <NoProductTypes>
      {isImageLoading && <CreateProductTypeBackground />}
      <img
        src={`${process.env.PUBLIC_URL}/images/folder.webp`}
        alt="Folder"
        onLoad={() => setIsImageLoading(false)}
        width="400px"
        height="220px"
      />

      {isCurrentCatalogShared ? (
        <Typography variant="body1" width="344px" textAlign="center" mt="30px" mb="25px">
          {t('productTypesPage.noProductInSharedCatalog')}
        </Typography>
      ) : (
        <>
          {activeJobLoading ? (
            <Typography variant="body1" mt="30px" color="text.secondary">
              {t('productTypesPage.importInProgress')}
            </Typography>
          ) : (
            <>
              <Typography width="347px" textAlign="center" mt="30px" mb="25px" color="text.secondary">
                {t('productTypesPage.noProduct')}
              </Typography>
              <Button
                sx={{ marginBottom: '15px' }}
                onClick={onAddNewProductType}
                variant="contained"
                startIcon={<Iconography iconName="add" />}
              >
                {t('productTypesPage.button')}
              </Button>
            </>
          )}
        </>
      )}
    </NoProductTypes>
  );
};

export default NoProductTypesComponent;
