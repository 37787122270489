import React, { ChangeEvent, useState, useMemo, useCallback } from 'react';

import { Box, Slider, TextField, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';

import { Maybe } from '../utils/gql';

interface StyleProps {
  isThumbHidden: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>(({ palette }) => ({
  root: {
    position: 'relative',
    zIndex: '2',

    '& .MuiSlider-thumb': {
      display: ({ isThumbHidden }) => (isThumbHidden ? 'none' : 'default'),
      '&.Mui-focusVisible': {
        outline: `2px solid ${palette.common.black}`,
      },
    },

    '& .MuiSlider-root': {
      cursor: ({ isThumbHidden }) => (isThumbHidden ? 'default' : 'pointer'),

      '& .MuiSlider-rail': {
        background: ({ isThumbHidden }) => (isThumbHidden ? palette.secondary.main : palette.text.disabled),
      },

      '& .MuiSlider-mark': {
        height: 22,
        width: 22,
        background: palette.background.default,
        marginTop: -9,
        borderRadius: '50%',
        opacity: 1,
        '&[data-index="0"]': {
          left: '-4.8% !important',
        },
        '&[data-index="1"]': {
          left: '96.1% !important',
        },
        '&::after': {
          content: '""',
          position: 'absolute',
          top: -2,
          bottom: -2,
          left: -2,
          right: -2,
          background: palette.secondary.main,
          borderRadius: '50%',
          zIndex: -1,
        },
      },
    },
  },
  fieldsContainer: {
    position: 'relative',
    '& >div:last-child': {
      left: '-1px',
      '& .MuiOutlinedInput-notchedOutline': {
        borderRadius: '0 2px 2px 0',
      },
    },
    '& >div:first-child': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderRadius: '2px 0 0 2px',
      },
      '&:hover, & .Mui-focused .MuiOutlinedInput-notchedOutline': {
        zIndex: 3,
      },
    },
  },
  priceFieldAdorement: {
    '& .MuiInputBase-input': { paddingLeft: '0' },
    '& .MuiInputAdornment-root': { marginLeft: '15px', color: 'red' },
    '& .MuiTypography-root': { color: palette.text.secondary },
  },
}));

interface SliderWithFieldsProps {
  inputProps: { startAdornment: JSX.Element } | undefined;
  defaultValues: number[];
  values?: string[];
  onChange?: (values: string[]) => void;
  disabled?: boolean;
  fieldName: string;
}

const SliderWithFields = ({
  defaultValues,
  values,
  onChange,
  disabled = false,
  inputProps,
  fieldName,
}: SliderWithFieldsProps) => {
  const defaultValuesString = useMemo(() => defaultValues.map(String), [defaultValues]);
  const [internalValues, setInternalValues] = useState<string[]>(values ?? defaultValuesString);
  const activeValues = values ?? internalValues;
  const handleValues = useMemo(() => activeValues.map((value) => +value), [activeValues]);
  const areSliderValuesTheSame = defaultValues[0] === defaultValues[1];
  const [localStringValues, setLocalStringValues] = useState<Maybe<string>[]>(activeValues);

  const styleProps = {
    isThumbHidden: areSliderValuesTheSame,
  };
  const { root, fieldsContainer, priceFieldAdorement } = useStyles(styleProps);

  const handleChange = useCallback(
    (maybeValues: Maybe<string>[]) => {
      const values = maybeValues.map((currentValue, idx) => currentValue ?? activeValues[idx]);
      //synchronize values between each other if max < min || min > max or we got only max or we got only min
      if (
        +values[1] < +values[0] &&
        !((values[1] === '' && +values[0] !== 0) || (+values[1] !== 0 && values[0] === ''))
      ) {
        values[0] = values[1];
      }

      setInternalValues(values);
      onChange?.(values);
      setLocalStringValues([null, null]);
    },
    [activeValues, onChange],
  );

  const handleChangeSlider = (event: Event | null, newValue: number | number[]) => {
    if (Array.isArray(newValue) && !areSliderValuesTheSame) {
      const stringValues = newValue.map((value) => String(value));

      handleChange(stringValues);
    }
  };

  const handleChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    // TODO : unify regular expression
    const isAllowed = /^\d+(\.\d+)?$/.test(value) && +value > defaultValues[0];

    const valuesArr = [...localStringValues];
    valuesArr[+name] = value;
    setLocalStringValues(valuesArr);
    if (isAllowed) {
      handleChange(valuesArr);
    }
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" className={root}>
      <Slider
        value={handleValues}
        min={defaultValues[0]}
        max={defaultValues[1]}
        marks={areSliderValuesTheSame && [{ value: 0 }, { value: 1 }]}
        step={1}
        onChange={handleChangeSlider}
        disabled={disabled}
      />

      <Box display="flex" mt="15px" className={fieldsContainer}>
        {localStringValues.map((value, idx) => (
          <TextField
            key={idx}
            name={String(idx)}
            variant="outlined"
            onChange={handleChangeInput}
            value={value ?? activeValues[idx]}
            onBlur={() => handleChange(localStringValues)}
            disabled={disabled}
            className={clsx(inputProps && priceFieldAdorement)}
            InputProps={inputProps}
            inputProps={{ 'data-testid': `${fieldName}Field${idx}` }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default SliderWithFields;
