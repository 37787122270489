import {
  CATALOGS_PATH,
  EDITING,
  FIRST_DASHBOARD_PATHNAME,
  PRODUCT_ITEMS_PATH,
  PRODUCT_TYPES_PATH,
} from '../constants/routeSources';

export const productPathGeneration = (catalogId: string, productTypeId?: string, itemId?: string): string => {
  if (catalogId) {
    const pathCatalog = `/${FIRST_DASHBOARD_PATHNAME}/${CATALOGS_PATH}/${catalogId}`;

    if (productTypeId) {
      const pathProductType = `${pathCatalog}/${PRODUCT_TYPES_PATH}/${productTypeId}`;

      if (itemId) {
        return `${pathProductType}/${PRODUCT_ITEMS_PATH}/${itemId}/${EDITING}`;
      }
      return pathProductType;
    }
    return pathCatalog;
  }

  return '';
};
