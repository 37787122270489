import React, { useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Box, IconButton } from '@material-ui/core';
import { Collapse, Typography } from '@mui/material';

import Hint from 'src/components/Hint';
import Iconography from 'src/components/Iconography';
import Select, { defaultSelectValue, SelectOptionItem, SelectOptionItemStringValue } from 'src/components/Select';
import { SkeletonMappingSelectProduct } from 'src/components/Skeleton/SkeletonProductMapping';
import { Integration, IntegrationProductType, Maybe, ProductType, IntegrationProductTypeField } from 'src/utils/gql';

import { clearFieldsMappings } from './IntegrationInfo';
import MappingFields from './MappingFields';
import { ProductTypeInfoHeader, ProductTypeSelectWrapper } from './styled';
import { getValueAccordingToIntegrationType, ValueAccordingToIntegrationType } from './utils';

interface Props {
  integration: Integration;
  integrationProductTypes: IntegrationProductType[];
  productType?: ProductType;
  onProductTypeChange: (newValue: Maybe<string>) => void;
  metadataExternalId?: string;
  requiredIntegrationFieldsLoading: boolean;
  requiredIntegrationFields?: IntegrationProductTypeField[];
  unavailableExternalType?: string;
  loading?: boolean;
}

const ProductTypesInfo = ({
  integration,
  integrationProductTypes,
  productType,
  onProductTypeChange,
  unavailableExternalType,
  loading,
  requiredIntegrationFieldsLoading,
  requiredIntegrationFields,
}: Props) => {
  const { productTypeId } = useParams<{ productTypeId: string }>();
  const { t } = useTranslation();

  const {
    control,
    watch,
    formState: { dirtyFields, isSubmitting },
    setValue,
    resetField,
  } = useFormContext();

  const [isOpen, setIsOpen] = useState<boolean>(true);

  const [options, optionsMap] = useMemo(() => {
    const map: Record<string, SelectOptionItemStringValue> = {};

    const optionsArray = integrationProductTypes.map(({ id, name }) => {
      const option = {
        label: name,
        value: id,
      };

      map[id] = option;

      return option;
    });

    return [optionsArray, map];
  }, [integrationProductTypes]);

  const isAttached = !(!watch('externalProductTypeId') || dirtyFields.externalProductTypeId);

  const handleClick = () => {
    setIsOpen((prev) => !prev);
  };

  const headerTitle = getValueAccordingToIntegrationType(
    integration.type,
    ValueAccordingToIntegrationType.HeaderTitle,
    t,
  );

  const selectLabel = String(
    getValueAccordingToIntegrationType(integration.type, ValueAccordingToIntegrationType.SelectLabel, t),
  );

  const handleIntegrationProductTypeChange = (
    value: Maybe<string>,
    onChange: (newValue: Maybe<string>) => void,
  ): void => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { externalProductTypeId, ...restFields } = watch();

    const fieldsKeys = Object.entries(restFields).reduce((acc: string[], [key, value]) => {
      if (value) {
        acc.push(key);
      }

      return acc;
    }, []);

    if (productType && productType.fieldsMappings?.length) {
      clearFieldsMappings(productType.fieldsMappings, setValue, resetField);
    } else if (fieldsKeys.length) {
      fieldsKeys.forEach((key) => {
        resetField(`${key}` as const, { defaultValue: null, keepDirty: false });
      });
    }

    onProductTypeChange(value);

    onChange(value);
  };

  const displayUnavailableExternalType = unavailableExternalType && isAttached && !isSubmitting;

  return (
    <>
      <ProductTypeInfoHeader onClick={handleClick}>
        <Iconography
          iconName={isOpen ? 'expand-chevron-up' : 'expand-chevron'}
          data-testid={`expand_icon_${integration.type}_integration`}
        />

        <Typography variant="subtitle2" color="inherit" ml="5px">
          {headerTitle}
        </Typography>
      </ProductTypeInfoHeader>

      <Collapse in={isOpen}>
        <Controller
          name="externalProductTypeId"
          control={control}
          render={({
            field: { onChange, value: externalProductTypeIdValue },
          }: {
            field: {
              value: Maybe<string>;
              onChange: (newValue: Maybe<string>) => void;
            };
          }) => (
            <>
              <ProductTypeSelectWrapper justifyContent="space-between">
                {loading ? (
                  <SkeletonMappingSelectProduct />
                ) : displayUnavailableExternalType ? (
                  <Box maxWidth="643px" width="100%" maxHeight="42px" marginLeft="29px">
                    <Typography whiteSpace="normal" width="100%">
                      {t('productType.mapping.unavailableExternalType')}
                    </Typography>
                  </Box>
                ) : (
                  <Select
                    fullwidth
                    options={options}
                    onChangeSelect={({ value: newValue }: SelectOptionItem) =>
                      handleIntegrationProductTypeChange(newValue as string, onChange)
                    }
                    label={selectLabel}
                    emptyOptionPlaceholder={selectLabel}
                    selectedOptionItem={
                      (externalProductTypeIdValue && optionsMap[externalProductTypeIdValue]) || defaultSelectValue
                    }
                    disabled={isAttached || isSubmitting}
                    customTestIdArrowDropDown={`arrowDropDownIcon${selectLabel}${
                      (externalProductTypeIdValue && optionsMap[externalProductTypeIdValue]?.label) || ''
                    }`}
                    generalOptionDataTestId={`${integration.type}_integration_product_type_`}
                  />
                )}

                {isAttached && (
                  <Hint type="hover" title={`${t('productType.mapping.detachProduct')}`} placement="left">
                    <IconButton
                      onClick={() => handleIntegrationProductTypeChange(null, onChange)}
                      data-testid={`detach_product_type_button_${integration.type}_integration`}
                    >
                      <Iconography iconName="detach-file" />
                    </IconButton>
                  </Hint>
                )}
              </ProductTypeSelectWrapper>
              {externalProductTypeIdValue && !requiredIntegrationFieldsLoading && (
                <Box mt="22px" display={displayUnavailableExternalType ? 'none' : 'block'}>
                  <MappingFields
                    integration={integration}
                    requiredIntegrationFields={requiredIntegrationFields ?? []}
                    externalProductTypeId={externalProductTypeIdValue}
                    categoryName={optionsMap[externalProductTypeIdValue]?.label}
                    productTypeId={productTypeId}
                    arrowSpaceWidth={88}
                    loadingExternalType={loading}
                    addFieldHeaders
                  />
                </Box>
              )}
            </>
          )}
        />
      </Collapse>
    </>
  );
};

export default ProductTypesInfo;
