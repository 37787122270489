import { Box } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';

export const LoginContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: '120px',

  [theme.breakpoints.down('lg')]: {
    marginLeft: 0,
  },
}));

export const SideContainer = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 378px;
  min-width: 378px;
`;

export const Image = styled('img')(({ theme }) => ({
  maxWidth: '622px',
  width: '100%',
  maxHeight: '578px',
  marginLeft: '150px',

  [theme.breakpoints.down('md')]: {
    margin: '40px 0 0',
  },
}));

export const StyledSingInContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  maxWidth: '1200px',
  margin: '0 auto',
  padding: '48px 0 36px',

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));
