import React from 'react';
import { useTranslation } from 'react-i18next';

import { Skeleton } from '@material-ui/core';
import { Box, Typography } from '@mui/material';
import moment from 'moment';

import asMoney from 'src/utils/asMoney';
import { StripeBalanceWithNextPayment } from 'src/utils/gql';

interface BallanceProps {
  data?: StripeBalanceWithNextPayment;
  loading: boolean;
}

const Ballance = ({ data, loading = true }: BallanceProps) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" mb="40px">
      <Box mr="49px">
        <Typography variant="subtitle1" mb="15px">
          {t('userSettingsPage.billing.balance')}
        </Typography>
        {loading ? (
          <Skeleton variant="rectangular" height={28} />
        ) : (
          <Typography variant="h2">{data && asMoney(data.balance, 'usd')}</Typography>
        )}
      </Box>

      <Box>
        <Typography variant="subtitle1" mb="15px">
          {t('userSettingsPage.billing.nextPayment')}
        </Typography>
        {loading ? (
          <Skeleton variant="rectangular" height={28} />
        ) : (
          <Typography variant="h2">
            {data?.nextPaymentDate ? moment(data.nextPaymentDate).format('DD MMMM, YYYY') : '-----'}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default Ballance;
