import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Checkbox, Typography } from '@material-ui/core';

import useOnboarding from 'src/providers/OnboardingProvider/hooks/useOnboarding';
import { IntegrationTypes } from 'src/utils/gql';
import { IntegrationAction } from 'src/views/UserSettings/types';

import ShopUrlInput from './FormInputs/ShopUrlInput';
import TextInput from './FormInputs/TextInput';
import { AddEditIntegrationsProps } from './types';

const ShopifyForm = ({ action, onChangeDefault, isDefaultIntegration }: AddEditIntegrationsProps) => {
  const { t } = useTranslation();
  const {
    onboardingState: { tourActive },
  } = useOnboarding();

  return (
    <Box display="flex" flexDirection="column" width="288px">
      <Typography variant="subtitle1" mb="10px" mt="50px">
        {t('userSettingsPage.integrations.popup.contentTitle', { integration: 'Shopify' })}
      </Typography>

      {tourActive && <Box position="absolute" top="45px" width="288px" height="180px" id="integration-info-anchor" />}

      <Box>
        <form aria-label="form" autoComplete="off">
          <Box mt="15px" width="100%">
            <TextInput
              shouldFocus
              fullWidth
              label={t('userSettingsPage.integrations.inputLabel.shopName')}
              name="integrationName"
              inputNameTestId="integrationName"
              readOnly={tourActive}
            />
          </Box>

          {!tourActive && (
            <>
              <ShopUrlInput
                fullWidth
                label={t('userSettingsPage.integrations.inputLabel.shop')}
                disabled={action === IntegrationAction.Edit}
                name="shop"
                inputNameTestId="shop"
                sx={{ marginTop: '25px' }}
                integrationType={IntegrationTypes.Shopify}
              />

              <Box display="flex" textAlign="left" mt="45px" alignItems="center">
                <Checkbox onChange={onChangeDefault} name="isDefault" checked={isDefaultIntegration} />

                <Typography variant="caption" color="text.secondary">
                  {t('userSettingsPage.integrations.popup.saveAsDefaultShopify')}
                </Typography>
              </Box>
            </>
          )}
        </form>
      </Box>
    </Box>
  );
};

export default ShopifyForm;
