import React, { ChangeEvent, useContext, useState, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';

import { Box, Card, CardMedia, IconButton, InputAdornment, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Tooltip } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

import Iconography from 'src/components/Iconography';
import { useCustomMediaPlayerButtons } from 'src/hooks/useCustomMediaPlayerButtons';
import { MediaStateContext } from 'src/providers/MediaProvider/context';
import { useSnackbar } from 'src/providers/snackbar';
import { theme } from 'src/theme';
import { copyToClipboard } from 'src/utils/copyToClipboard';
import { MediaType } from 'src/utils/gql';
import { getFileNameFromUrl, getFileExpansion } from 'src/views/Catalogs/ProductItems/functions';
import { UploadingMedia } from 'src/views/Catalogs/ProductItems/types';

import TextInput from '../Forms/FormInputs/TextInput';
import Loader from '../Loader';

import { MediaDataType, MediaTypes } from './constants';
import { documentPicture } from './functions';
import {
  StyledFileImg,
  StyledFileName,
  StyledFileContent,
  StyledUploadingContainer,
  StyledCheckboxContainer,
} from './styled';
import Video from './Video';

interface StyleMediaCardProps {
  isSelected: boolean;
  isDragging: boolean;
  noOpocity: boolean;
}

const useStyles = makeStyles<Theme, StyleMediaCardProps>((theme) => ({
  mediaCard: {
    display: 'flex',
    minHeight: '158px',
    position: 'relative',
    background: ({ isSelected }) => (isSelected ? theme.palette.background.default : theme.palette.common.white),
    border: `1px solid ${theme.palette.action.disabled}`,
    borderRadius: '2px',
    counterIncrement: 'albumList',
    boxShadow: 'none',
    opacity: ({ isSelected, isDragging }) => (isSelected ? 0.7 : isDragging ? 0.2 : 1),

    '&:first-child': {
      gridArea: 'span 2 / span 1',
    },

    '&:nth-child(5n + 6)': {
      gridColumn: 1,
    },

    '& .MuiCardMedia-root': {
      maxWidth: '100%',
      height: '100%',
      objectFit: 'contain',
    },

    '& .crop-button': {
      position: 'absolute',
      top: '27px',
      opacity: 0,
      margin: '12px',
      padding: 0,
    },

    '&:hover': {
      background: theme.palette.background.default,
      opacity: ({ noOpocity, isSelected }) => (noOpocity && !isSelected ? 1 : 0.7),
      boxShadow: 'none',
      '& .crop-button': {
        opacity: 1,
      },
      '& .checkbox': {
        opacity: 1,
        background: theme.palette.common.white,
      },

      '& .MuiCheckbox-root.Mui-checked': {
        background: theme.palette.common.white,
      },
    },

    '& .checkbox': {
      opacity: 0,
      background: theme.palette.common.white,
      borderRadius: '2px',
      padding: 0,
      margin: 0,
      width: '23px',
      height: '23px',
    },

    '& .MuiCheckbox-root.Mui-checked': {
      opacity: 1,
    },
  },
  iconCrop: {
    width: '24px',
    height: '24px',
  },
  iconCopy: {
    marginTop: '7px',
    cursor: 'pointer',
    '& svg': {
      width: '20px',
      height: '20px',
    },
  },
  tooltip: {
    padding: '20px 10px',
    backgroundColor: theme.palette.common.white,
    boxShadow: '0 2px 10px rgba(46, 96, 170, 0.25)',
    width: '416px',
    height: '76px',
    margin: 0,
  },
  arrow: {
    color: theme.palette.common.white,
    fontSize: '21px',
  },
  hoverInputUrl: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',

    '& .MuiFormControl-root label[data-shrink="true"]': {
      marginTop: '15px',
    },

    '& .MuiOutlinedInput-root.Mui-disabled': {
      background: 'none',
      height: '42px',
      padding: '0 20px',
    },

    '& .MuiOutlinedInput-input.Mui-disabled': {
      color: theme.palette.text.primary,
      '-webkit-text-fill-color': theme.palette.text.primary,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },

    '&:hover': {
      color: theme.palette.secondary.main,

      '& .MuiInputLabel-root[data-shrink="true"]': {
        color: theme.palette.secondary.main,
      },

      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.secondary.main,
      },

      '& .MuiInputBase-input': {
        textOverflow: 'clip',
        direction: 'rtl',
      },
    },
  },
}));

const ELEMENT_OVER_CONTAINER = 50;

interface MediaCardProps {
  media: UploadingMedia;
  handleChangeCheckbox: (event: ChangeEvent<HTMLInputElement>) => void;
  isMediaSelected: boolean;
  moveCard: (id: string, startIndex: number) => void;
  findCard: (id: string) => number;
  unloadedFilesIds: string[];
  handleOpenImageEditor: (image: UploadingMedia) => void;
}

const MediaCard = ({
  media,
  handleChangeCheckbox,
  isMediaSelected,
  moveCard,
  findCard,
  unloadedFilesIds,
  handleOpenImageEditor,
}: MediaCardProps) => {
  const { t } = useTranslation();
  const snackbar = useSnackbar();

  const { uploading } = useContext(MediaStateContext);

  const video = useRef<ReactPlayer>(null);

  const { id, url, name, type, expansion, typeAddedData, uuid } = media;

  const videoMethodsAndProps = useCustomMediaPlayerButtons();
  const { showControls } = videoMethodsAndProps;

  const [isHover, setIsHover] = useState<boolean>(false);

  const originalIndex = findCard(id);

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: MediaTypes.CARD,
      item: { id, originalIndex },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const { id: droppedId, originalIndex } = item;
        if (!monitor.didDrop()) {
          moveCard(droppedId, originalIndex);
        }
      },
    }),
    [id, originalIndex, moveCard],
  );

  const [, drop] = useDrop(
    () => ({
      accept: MediaTypes.CARD,
      hover({ id: draggedId }: { id: string }, monitor) {
        const scrollableBlock = document.getElementById('mediaContainer');
        const scrollableBlockLocation = scrollableBlock?.getBoundingClientRect();
        const positionHoverCard = monitor?.getClientOffset()?.y;

        if (scrollableBlockLocation && positionHoverCard !== undefined) {
          if (positionHoverCard - scrollableBlockLocation?.y < ELEMENT_OVER_CONTAINER) {
            scrollableBlock?.scrollTo({ top: 0, behavior: 'smooth' });
          }

          if (
            scrollableBlockLocation?.y + scrollableBlockLocation?.height - positionHoverCard <
            ELEMENT_OVER_CONTAINER
          ) {
            scrollableBlock?.scrollTo({ top: scrollableBlock.scrollHeight, behavior: 'smooth' });
          }
        }

        if (draggedId !== id) {
          const overIndex = findCard(id);

          moveCard(draggedId, overIndex);
        }
      },
    }),
    [findCard, moveCard],
  );

  const classes = useStyles({
    isSelected: uploading || isMediaSelected,
    isDragging,
    noOpocity: showControls,
  });

  const isUnloadedFiles = unloadedFilesIds.includes(id);

  const nameMediaFile = expansion === undefined ? getFileNameFromUrl(url) : name;

  const amazonLink = url?.includes('amazonaws.com');

  const mediaCard = () => (
    <Card
      className={classes.mediaCard}
      ref={(card: HTMLDivElement | null) => drag(drop(card))}
      data-testid={`mediaCard${nameMediaFile}`}
    >
      {uploading && isUnloadedFiles && (
        <StyledUploadingContainer>
          <Loader size="smallIntegration" />
          <Typography variant="body2" mt="15px">
            {t('productItemCreateEdit.mediaGallery.uploading')}
          </Typography>
        </StyledUploadingContainer>
      )}
      <StyledCheckboxContainer>
        <Checkbox
          className="checkbox"
          icon={<Iconography iconName="checkbox" htmlColor={theme.palette.secondary.dark} />}
          checkedIcon={<Iconography iconName="checkboxChecked" htmlColor={theme.palette.secondary.dark} />}
          onChange={handleChangeCheckbox}
          id={uuid}
          checked={!!isMediaSelected}
          disableRipple
          inputProps={{ 'data-testid': `checkbox${nameMediaFile}` } as React.InputHTMLAttributes<HTMLInputElement>}
        />
      </StyledCheckboxContainer>
      {type === MediaType.Image && (
        <>
          <CardMedia src={url} title={name} component="img" />
          <IconButton
            className="crop-button"
            onClick={() => handleOpenImageEditor(media)}
            data-testid="cropImageButton"
          >
            <Iconography iconName="crop" htmlColor={theme.palette.secondary.dark} className={classes.iconCrop} />
          </IconButton>
        </>
      )}

      {[MediaType.Video, MediaType.ExternalVideo].includes(type) && (
        <Video
          irreproducible={!url?.includes('https://')}
          methodAndProps={videoMethodsAndProps}
          url={url}
          video={video}
        />
      )}

      {[MediaType.Document, MediaType.Model3D].includes(type) && (
        <StyledFileContent>
          <StyledFileImg>{documentPicture(expansion === undefined ? getFileExpansion(url) : expansion)}</StyledFileImg>
          <StyledFileName>{nameMediaFile}</StyledFileName>
        </StyledFileContent>
      )}
    </Card>
  );

  return (
    <>
      {typeAddedData === MediaDataType.FROM_URL || (typeAddedData === MediaDataType.FROM_DATABASE && !amazonLink) ? (
        <Tooltip
          title={
            <Box id="copyUrl">
              <TextInput
                name="hoverUrl"
                disabled
                label={t('productItemCreateEdit.mediaGallery.addUrlPopup.label')}
                defaultValue={url}
                className={classes.hoverInputUrl}
                inputProps={{ 'data-testid': 'copyUrlInput' }}
                onMouseLeave={() => setIsHover(false)}
                onMouseEnter={() => setIsHover(true)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Box
                        data-testid="copyContentButton"
                        className={classes.iconCopy}
                        onClick={() =>
                          copyToClipboard(`${url}`, document.getElementById('copyUrl') || document.body, () =>
                            snackbar(t('productItemCreateEdit.mediaGallery.copyUrlToClipboardSuccess'), 'success'),
                          )
                        }
                      >
                        <Iconography
                          iconName="content-copy"
                          htmlColor={isHover ? theme.palette.secondary.main : theme.palette.text.disabled}
                          data-testid="copyUrlButton"
                        />
                      </Box>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          }
          placement="bottom"
          arrow
          classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
        >
          {mediaCard()}
        </Tooltip>
      ) : (
        mediaCard()
      )}
    </>
  );
};

export default MediaCard;
