export const copyToClipboard = (text: string, parent: HTMLElement, toast: () => void) => {
  if (navigator.clipboard) {
    return navigator.clipboard.writeText(text).then(toast);
  }

  const textarea = document.createElement('textarea');

  textarea.textContent = text;
  textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page in Microsoft Edge.
  parent.appendChild(textarea);
  textarea.select();

  try {
    document.execCommand('copy'); // Security exception may be thrown by some browsers.
    toast();
  } catch (ex) {
    prompt('Copy to clipboard: Ctrl+C, Enter', text);
  } finally {
    parent.removeChild(textarea);
  }
};
