import { Link } from 'react-router-dom';

import { Box, Typography } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';

export const StyledBreadcrumb = styled(Box)`
  display: flex;
  align-items: center;
`;

export const StyledBreadcrumbLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
`;

export const StyledBreadcrumbBox = styled(Box)`
  display: flex;
  align-items: center;
  text-decoration: none;
`;
export const StyledTypography = styled(Typography)`
  margin-left: 6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
