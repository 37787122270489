import { Box, Typography } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px 120px;
  min-height: 100vh;
  background: ${({ theme: { palette } }) => palette.common.white};
  user-select: none;
  overflow: auto;
  scrollbar-width: thin;
`;

export const Footer = styled(Box)`
  display: flex;
  justify-content: space-between;
  min-height: 35px;
  z-index: 10;
`;

export const FooterText = styled(Typography)`
  .MuiSvgIcon-root {
    position: relative;
    top: 3px;
    width: 16px;
    height: 16px;
  }
`;

export const StyledLinkA = styled('a')`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme: { palette } }) => palette.secondary.dark};
  :visited {
    text-decoration: none;
    color: ${({ theme: { palette } }) => palette.secondary.dark};
  }
  :active {
    text-decoration: none;
    color: ${({ theme: { palette } }) => palette.secondary.dark};
  }
  :hover {
    cursor: pointer;
    border-bottom: 1px solid ${({ theme: { palette } }) => palette.info.contrastText};
  }
`;
