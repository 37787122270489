import React, { useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { NetworkStatus, useQuery } from '@apollo/client';
import { Box, Checkbox, Typography } from '@material-ui/core';

import { arrayToMap } from 'src/utils/general';
import {
  EbayPolicies,
  getEbayMerchantLocations,
  getEbayPolicies,
  Maybe,
  Query,
  QueryGetEbayMerchantLocationsArgs,
  QueryGetEbayPoliciesArgs,
} from 'src/utils/gql';
import { EBAY_MARKETPLACES_IDS_SELECT_OPTIONS } from 'src/views/Catalogs/ProductTypes/ProductTypeSettings/MappingTabContent/constants';
import { IntegrationAction } from 'src/views/UserSettings/types';

import Select, { SelectOptionItem } from '../Select';
import { FieldSkeleton } from '../Skeleton/SkeletonProductMapping';

import TextInput from './FormInputs/TextInput';
import { StyledFormControlLabel } from './styled';
import { AddEditIntegrationsProps } from './types';

const EBAY_MERCHANT_LOCATIONS_LIMIT = 10;

enum EBAY_INTEGRATION_SPECIFIC_FIELD {
  FULFILLMENT_POLICY = 'fulfillmentPolicyId',
  PAYMENT_POLICY = 'paymentPolicyId',
  RETURN_POLICY = 'returnPolicyId',
  MERCHANT_LOCATION_KEY = 'merchantLocationKey',
}

const EbaySettingsSkeletons = () => (
  <Box>
    {new Array(6).fill('row').map((_, idx) => (
      <Box key={idx} {...(!idx && { mt: '15px' })} mb="17px" width="100%">
        <FieldSkeleton variant="rectangular" />
      </Box>
    ))}
  </Box>
);

const merchantLocationKeyLabelKey = 'merchantLocationKeyLabel';

const EbayForm = ({ onChangeDefault, isDefaultIntegration, action, integrationId = '' }: AddEditIntegrationsProps) => {
  const { t } = useTranslation();
  const { control, watch, setValue, getValues } = useFormContext();
  const [hasMore, setHasMore] = useState(true);
  const marketplaceIdOptionsMap = useMemo(() => arrayToMap(EBAY_MARKETPLACES_IDS_SELECT_OPTIONS, 'value'), []);

  const marketplaceId: string = watch('marketplaceId');

  const { data: ebayPoliciesData, loading: fetchEbayPoliciesLoading } = useQuery<
    Pick<Query, 'getEbayPolicies'>,
    QueryGetEbayPoliciesArgs
  >(getEbayPolicies, {
    variables: { integrationId, marketplaceId },
    skip: !(integrationId && marketplaceId),
  });
  const {
    data: ebayMerchantLocationsData,
    fetchMore,
    loading: fetchEbayMerchantLocationsLoading,
    networkStatus,
  } = useQuery<Pick<Query, 'getEbayMerchantLocations'>, QueryGetEbayMerchantLocationsArgs>(getEbayMerchantLocations, {
    notifyOnNetworkStatusChange: true,
    variables: { integrationId, limit: EBAY_MERCHANT_LOCATIONS_LIMIT },
    skip: !(integrationId && marketplaceId),
  });

  const policies = ebayPoliciesData?.getEbayPolicies;
  const merchantLocations = ebayMerchantLocationsData?.getEbayMerchantLocations;
  const policiesMap = arrayToMap(policies || [], 'id');
  const merchantLocationsMap = arrayToMap(merchantLocations || [], 'key');

  const fetchMoreEbayMerchantLocations = async () => {
    const offset = ebayMerchantLocationsData?.getEbayMerchantLocations.length || 0;

    if (!fetchMore || networkStatus !== NetworkStatus.ready) return;

    setHasMore(
      (await fetchMore<'offset'>({ variables: { offset } })).data?.getEbayMerchantLocations.length ===
        EBAY_MERCHANT_LOCATIONS_LIMIT,
    );
  };

  const getSelectOptions = (id: EBAY_INTEGRATION_SPECIFIC_FIELD): SelectOptionItem[] => {
    const getPoliciesSelectOptionsByPolicyType = (policyType: EbayPolicies): SelectOptionItem[] =>
      policies?.reduce((acc: SelectOptionItem[], { type, id, name }) => {
        if (type === policyType) {
          acc.push({ value: id, label: name });
        }

        return acc;
      }, []) || [];

    switch (id) {
      case EBAY_INTEGRATION_SPECIFIC_FIELD.FULFILLMENT_POLICY:
        return getPoliciesSelectOptionsByPolicyType(EbayPolicies.Fulfillment);

      case EBAY_INTEGRATION_SPECIFIC_FIELD.PAYMENT_POLICY:
        return getPoliciesSelectOptionsByPolicyType(EbayPolicies.Payment);

      case EBAY_INTEGRATION_SPECIFIC_FIELD.RETURN_POLICY:
        return getPoliciesSelectOptionsByPolicyType(EbayPolicies.Return);

      case EBAY_INTEGRATION_SPECIFIC_FIELD.MERCHANT_LOCATION_KEY:
        return merchantLocations?.map(({ key, name }) => ({ value: key, label: name })) || [];

      default:
        return [];
    }
  };

  const getSelectedOption = (fieldName: EBAY_INTEGRATION_SPECIFIC_FIELD, value: string): Maybe<SelectOptionItem> => {
    const isMerchantLocationKey = fieldName === EBAY_INTEGRATION_SPECIFIC_FIELD.MERCHANT_LOCATION_KEY;

    if (isMerchantLocationKey) {
      const merchantLocation = merchantLocationsMap[value];

      if (merchantLocation) {
        const { key, name } = merchantLocation;
        return { value: key, label: name };
      } else if (action === IntegrationAction.Edit) {
        const formValues = getValues();
        return { value: formValues['merchantLocationKey'], label: formValues[merchantLocationKeyLabelKey] };
      }
    }

    const policy = policiesMap[value];

    if (policy) {
      const { id, name } = policy;
      return { value: id, label: name };
    }

    return null;
  };

  const handleChangeSpecificField = (
    newValue: string,
    fieldName: EBAY_INTEGRATION_SPECIFIC_FIELD,
    onChange: (value: string) => void,
  ) => {
    if (fieldName === EBAY_INTEGRATION_SPECIFIC_FIELD.MERCHANT_LOCATION_KEY) {
      setValue(merchantLocationKeyLabelKey, merchantLocationsMap[newValue as string].name);
    }

    onChange(newValue);
  };

  return (
    <Box display="flex" alignItems="center" flexDirection="column" width="496px" zIndex={1}>
      <Box display="flex" justifyContent="center" alignItems="center" mb="17px" mt="50px">
        <Typography variant="subtitle1" mr="10px">
          {t('userSettingsPage.integrations.popup.contentTitle', { integration: 'eBay' })}
        </Typography>
      </Box>

      <Box width="288px">
        <form aria-label="form" autoComplete="off">
          {action === IntegrationAction.Edit &&
          ((fetchEbayMerchantLocationsLoading && !ebayMerchantLocationsData?.getEbayMerchantLocations) ||
            fetchEbayPoliciesLoading) ? (
            <EbaySettingsSkeletons />
          ) : (
            <Box>
              <Box mt="15px" mb="20px" width="100%">
                <TextInput
                  label={t('userSettingsPage.integrations.inputLabel.name')}
                  name="integrationName"
                  shouldFocus
                  variant="outlined"
                  inputNameTestId="integrationNameEbayField"
                />
              </Box>

              <Box mb="20px">
                <Controller
                  name="marketplaceId"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      fullwidth
                      options={EBAY_MARKETPLACES_IDS_SELECT_OPTIONS}
                      onChangeSelect={({ value: newValue }) => onChange(newValue)}
                      disabled={action === IntegrationAction.Edit}
                      selectedOptionItem={marketplaceIdOptionsMap[value as string] || null}
                      disablePortal={false}
                      label={t('productType.mapping.chooseMarketPlace')}
                      emptyOptionPlaceholder={t('productType.mapping.chooseMarketPlace')}
                      optionsWithTooltips
                    />
                  )}
                />
              </Box>

              {Object.values(EBAY_INTEGRATION_SPECIFIC_FIELD).map((fieldName) => (
                <Box mb="20px" key={fieldName}>
                  <Controller
                    name={fieldName}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        loading={fetchEbayMerchantLocationsLoading || fetchEbayPoliciesLoading}
                        pagination={
                          fieldName === EBAY_INTEGRATION_SPECIFIC_FIELD.MERCHANT_LOCATION_KEY
                            ? {
                                loading: fetchEbayMerchantLocationsLoading,
                                onFetchMore: fetchMoreEbayMerchantLocations,
                                hasMore,
                              }
                            : undefined
                        }
                        disablePortal={false}
                        disabled={!marketplaceId}
                        selectedOptionItem={getSelectedOption(fieldName, value)}
                        onChangeSelect={({ value: newValue }) =>
                          handleChangeSpecificField(newValue as string, fieldName, onChange)
                        }
                        options={getSelectOptions(fieldName)}
                        label={t(`productItemCreateEdit.body.ebayIntegrationSpecificFields.${fieldName}`)}
                        emptyOptionPlaceholder={t(
                          `productItemCreateEdit.body.ebayIntegrationSpecificFields.${fieldName}`,
                        )}
                        optionsWithTooltips
                      />
                    )}
                  />
                </Box>
              ))}
            </Box>
          )}

          <Box display="flex" textAlign="left" mt="26px" alignItems="center">
            <StyledFormControlLabel
              control={<Checkbox name="isDefault" onChange={onChangeDefault} checked={isDefaultIntegration} />}
              label={t('userSettingsPage.integrations.popup.saveAsDefaultEbay')}
            />
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default EbayForm;
