import React from 'react';

import { Box, Skeleton } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { keyframes } from '@mui/system';

const opacity = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Container = styled(Box)({
  margin: '40px 0px 25px 0px',
  animation: `${opacity} 1s ease`,
});

const SkeletonFilters = () => (
  <Container>
    <Box display="flex" flexDirection="column" justifyContent="space-between" mb="30px">
      <Skeleton variant="text" width="150px" height="30px" />

      <Skeleton variant="text" width="100%" height="30px" />

      <Skeleton variant="text" width="100%" height="30px" />
    </Box>

    <Box display="flex" flexDirection="column" justifyContent="space-between" mb="30px">
      <Skeleton variant="text" width="100px" height="30px" />

      <Skeleton variant="text" width="100%" height="30px" />
    </Box>

    <Box display="flex" flexDirection="column" justifyContent="space-between" mb="30px">
      <Skeleton variant="text" width="100px" height="30px" />

      <Skeleton variant="text" width="100%" height="30px" />
    </Box>

    <Box display="flex" flexDirection="column" justifyContent="space-between">
      <Skeleton variant="text" width="100px" height="30px" />

      {new Array(4).fill('filter').map((_, idx) => (
        <Box key={idx} display="flex" alignItems="center" justifyContent="space-between" width="135px">
          <Skeleton variant="text" width="20px" height="30px" />

          <Skeleton variant="text" width="100px" height="30px" />
        </Box>
      ))}
      <Box mt="5px">
        <Skeleton variant="text" width="100%" height="30px" />
      </Box>
    </Box>
  </Container>
);

export default SkeletonFilters;
