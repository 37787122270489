export const FIRST_DASHBOARD_PATHNAME = 'dashboard';
export const CATALOGS_PATH = 'catalogs';
export const PRODUCT_TYPES_PATH = 'product-types';
export const PRODUCT_ITEMS_PATH = 'product-items';
export const CREATION = 'creation';
export const DETAILS = 'details';
export const NEW = 'new';
export const CATALOG_SETTINGS = 'settings';
export const USER_SETTINGS = 'user-settings';
export const AUDIT_LOG = 'audit-log';
export const SHARED_CATALOGS = 'shared-catalogs';
export const IMPORT_FROM_CSV = 'import-from-csv';
export const EDITING = 'editing';
export const MAPPINGS = 'mappings';
export const REGISTER = 'register';
export const BILLING = 'upgrade-pricing-plan';
export const CHANGELOG = 'changelog';

export enum ERROR_PATHS {
  ERROR_404 = '/errors/error-404',
}
