import React, { ChangeEvent, SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { ApolloError, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Box, Button, Grid, Typography } from '@material-ui/core';

import DashboardBreadCrumbs from 'src/components/Breadcrumds/DashboardBreadCrumbs';
import ButtonScrollToTop from 'src/components/ButtonScrollToTop';
import CatalogCard from 'src/components/Card/CatalogCard';
import CatalogsHeader from 'src/components/Headers/CatalogsHeader';
import Portal from 'src/components/HOC/Portal';
import cataloguesNotFoundIcon from 'src/components/Icon/catalogues-not-found.webp';
import createCatalogIcon from 'src/components/Icon/create-catalog.webp';
import Iconography from 'src/components/Iconography';
import Page from 'src/components/Page';
import Popup from 'src/components/Popup';
import CheckEmailPopup from 'src/components/Popups/CheckEmailPopup';
import ExportCatalogPopup, { ExportPopupQueryType } from 'src/components/Popups/ExportCatalogPopup';
import SubscriptionsPopup from 'src/components/Popups/SubscriptionsPopup';
import Scroll from 'src/components/Scroll';
import { SharedCataloguesSubscriptionsActions } from 'src/components/SharedCatalogsTable/types';
import SkeletonCatalogCard, { SkeletonCatalogCardWrapper } from 'src/components/Skeleton/SkeletonCatalogCard';
import TruncatedPopupDeleteText from 'src/components/TruncatedPopupDeleteText';
import { PRODUCT_TYPES_DEFAULT_LIMIT } from 'src/constants';
import {
  CATALOGS_PATH,
  CATALOG_SETTINGS,
  CREATION,
  FIRST_DASHBOARD_PATHNAME,
  PRODUCT_TYPES_PATH,
} from 'src/constants/routeSources';
import { validateTextFieldValue } from 'src/helpers/validationCheck';
import { useCheckCatalogMappingErrors } from 'src/hooks/graphQLRequestsHooks';
import useCheckProposedMappings from 'src/hooks/useCheckProposedMappings';
import useControlSubscriptions from 'src/hooks/useControlSubscriptions';
import useDebounceValue from 'src/hooks/useDebounceValue';
import { useAccount } from 'src/providers/account';
import { useCatalogs } from 'src/providers/catalog';
import { useLimitReached } from 'src/providers/limitReached';
import useOnboarding from 'src/providers/OnboardingProvider/hooks/useOnboarding';
import { useSnackbar } from 'src/providers/snackbar';
import { removeDuplicatedObjects } from 'src/utils/general';
import { getUnSyncableIntegrations } from 'src/utils/getUnSyncableIntegrations';
import {
  CatalogType,
  checkIsLimitReached,
  exportCatalogToAllAttachedIntegrations,
  getProductTypesByCatalogId,
  LimitType,
  Maybe,
  Mutation,
  MutationExportCatalogToAllAttachedIntegrationsArgs,
} from 'src/utils/gql';
import { deleteItemDataLocations } from 'src/utils/locationArrays';
import { productPathGeneration } from 'src/utils/productPathGeneration';

import CatalogsNotFoundBackground from './CatalogsNotFoundBackground';
import CreateCatalogBackground from './CreateCatalogBackground';
import { CatalogueList, CatalogueListWrapper, LinkToSettings } from './styled';
import { SyncPopupState } from './types';
import { calculateEnding } from './utils';

interface CatalogueState {
  id: string;
  name: string;
}

export type ChooseExportPopupState = { open: boolean; isSkuDuplicated: boolean };

export const initialSyncPopupState = {
  open: false,
  selectedCatalogId: '',
};

export const initialChooseExportPopupState = {
  open: false,
  isSkuDuplicated: false,
};

const Catalogs = () => {
  const history = useHistory();
  const snackbar = useSnackbar();
  const { t } = useTranslation();
  const {
    user: { id: userId },
  } = useAccount();
  const limitReached = useLimitReached();
  const {
    onboardingState: { tourActive, createdOnboardingCatalog },
    goNext,
  } = useOnboarding();

  const catalogListRef = useRef<HTMLElement>();

  const initialCataloguesType = [CatalogType.My, CatalogType.Subscribed];
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [syncPopupState, setSyncPopupState] = useState<SyncPopupState>(initialSyncPopupState);
  const [chooseExportPopupState, setChooseExportPopupState] =
    useState<ChooseExportPopupState>(initialChooseExportPopupState);
  const [isSubscriptionPopupOpen, setIsSubscriptionPopupOpen] = useState<boolean>(false);
  const [isImageLoading, setIsImageLoading] = useState<boolean>(true);
  const [searchFieldValue, setSearchFieldValue] = useState<string>('');
  const [cataloguesType, setCataloguesType] = useState<CatalogType[]>(initialCataloguesType);
  const [hasCatalogsInAllCatalogsLink, setHasCatalogsInAllCatalogsLink] = useState<boolean>(false);

  const [catalogueState, setCatalogueState] = useState<CatalogueState>({
    id: '',
    name: '',
  });

  const [subscriptionState, setSubscriptionState] = useState({
    catalogOwner: '',
    catalogName: '',
    subscriptionId: '',
  });

  const checkIsLimitReachedVariables = { type: LimitType.Catalogs };

  const {
    catalogs,
    setFilters,
    hasMore: hasMoreCatalogs,
    handleFetchMore: fetchMoreCatalogs,
    loadingGet: loadingCatalogs,
    loadingDelete: loadingDeleteCatalog,
    handleDelete: deleteCatalog,
    updateCurrentExport,
  } = useCatalogs();

  const debouncedSearchValue = useDebounceValue(searchFieldValue, 1000);

  const getPrice = (price?: Maybe<number>) => (price && Number(price) ? `$${price}, ` : '');

  const getCataloguesType = (types: CatalogType[] = [CatalogType.My, CatalogType.Subscribed]) =>
    setCataloguesType(types);

  const {
    data: checkIsLimitReachedData,
    loading: checkIsLimitReachedLoading,
    refetch: refetchCheckIsLimitReached,
  } = useQuery(checkIsLimitReached, {
    fetchPolicy: 'network-only',
    variables: checkIsLimitReachedVariables,
  });

  const [syncCatalog, { loading: syncCatalogLoading }] = useMutation<
    Pick<Mutation, 'exportCatalogToAllAttachedIntegrations'>,
    MutationExportCatalogToAllAttachedIntegrationsArgs
  >(exportCatalogToAllAttachedIntegrations);

  const [redirectToNextPage] = useLazyQuery(getProductTypesByCatalogId, {
    fetchPolicy: 'cache-first',
    onCompleted: () => {
      history.push(`/${FIRST_DASHBOARD_PATHNAME}/${CATALOGS_PATH}/${catalogueState.id}/${PRODUCT_TYPES_PATH}`);

      if (tourActive) {
        setTimeout(() => {
          goNext();

          window.history.pushState({}, '', history.location.pathname);
        }, 0);
      }
    },
  });

  const sharedCatalogsLinkActive = cataloguesType[0] === CatalogType.Subscribed;

  const allCatalogsLinkActive = cataloguesType.length === initialCataloguesType.length;

  const handleCheckIsCatalogShared = (idToCheck: string) => (userId && idToCheck !== userId) as boolean;

  const hasCatalogs = tourActive ? !!createdOnboardingCatalog : !!catalogs.length;
  const existingCatalogs = tourActive ? (createdOnboardingCatalog ? [createdOnboardingCatalog] : []) : catalogs;

  const { onCancelSubscription, loadingStatus } = useControlSubscriptions({
    subscriptionId: subscriptionState.subscriptionId,
  });

  const handleCardSelect = (id: string) => {
    setCatalogueState((prevState: CatalogueState) => ({ ...prevState, id }));

    redirectToNextPage({
      variables: {
        catalogId: id,
        limit: PRODUCT_TYPES_DEFAULT_LIMIT,
        subName: '',
      },
    });
  };

  const handleAddCatalogClick = useCallback(() => {
    if (checkIsLimitReachedData?.checkIsLimitReached) {
      limitReached({ limitReachedType: LimitType.Catalogs });
    } else {
      history.push(`/${FIRST_DASHBOARD_PATHNAME}/${CREATION}`);
    }
  }, [checkIsLimitReachedData?.checkIsLimitReached, limitReached, history]);

  const handleOpenSyncPopup = (e: SyntheticEvent, id?: string, isEmpty?: boolean) => {
    if (tourActive) return;

    e.stopPropagation();
    if (id && !isEmpty) {
      setSyncPopupState({ open: true, selectedCatalogId: id });
    }
  };

  const handleCloseSyncPopup = () => {
    setSyncPopupState((prev) => ({ ...prev, open: false }));
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handleDeleteIconClick = (e: SyntheticEvent, id: string, name: string) => {
    if (tourActive) return;

    e.stopPropagation();

    setIsPopupOpen(true);

    setCatalogueState((prevState: CatalogueState) => ({
      ...prevState,
      id,
      name,
    }));
  };

  const handleDeleteCatalogue = async () => {
    catalogListRef?.current?.scrollTo({ top: 0 });

    try {
      await deleteCatalog(catalogueState.id);
      await refetchCheckIsLimitReached(checkIsLimitReachedVariables);
      setIsPopupOpen(false);
      snackbar(t('cataloguesPage.deleteCatalogSuccess'), 'success');
      deleteItemDataLocations(productPathGeneration(catalogueState.id));
    } catch (error) {
      const { graphQLErrors, message: errorText } = error as ApolloError;
      const message = graphQLErrors && graphQLErrors.length ? graphQLErrors[0].message : errorText;

      if (error) {
        snackbar(message);
      }
    }
  };

  const handleChangeSearchField = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchFieldValue(validateTextFieldValue(value));
  };

  const handleSubscriptionPopupOpen = (
    e: SyntheticEvent,
    subscriptionId: string,
    catalogName: string,
    catalogOwner: string,
  ) => {
    e.stopPropagation();

    setIsSubscriptionPopupOpen(true);

    setSubscriptionState((prevState) => ({
      ...prevState,
      subscriptionId,
      catalogName,
      catalogOwner,
    }));
  };

  const handleCancelSubscription = async () => {
    await onCancelSubscription();

    setIsSubscriptionPopupOpen(false);
  };

  const handleExportIconClick = (e: SyntheticEvent, id: string, name: string, isSkuDuplicated: boolean) => {
    if (tourActive) return;

    e.stopPropagation();
    setCatalogueState((prevState: CatalogueState) => {
      return {
        ...prevState,
        id,
        name,
      };
    });

    setChooseExportPopupState({ open: true, isSkuDuplicated });
  };

  const { checkCatalogMappingsErrorsQuery, loading: loadingErrors } = useCheckCatalogMappingErrors(
    syncPopupState.selectedCatalogId,
  );

  const { getListWithProposedMappings, getProposedMappingsLoading, renderMappingsSidebar } = useCheckProposedMappings({
    callbackFn: () => {
      updateCurrentExport(syncPopupState.selectedCatalogId);

      syncCatalog({ variables: { id: syncPopupState.selectedCatalogId } })
        .then(() => setSyncPopupState(initialSyncPopupState))
        .catch(({ message }) => {
          checkCatalogMappingsErrorsQuery();
          snackbar(message);
        });
    },
    closeCallbackFn: handleCloseSyncPopup,
    errorCallbackFn: checkCatalogMappingsErrorsQuery,
    shouldWaitAnotherPopupClosing: true,
  });

  const handleExportToAttachedIntegrations = () => {
    getListWithProposedMappings(syncPopupState.selectedCatalogId);
  };

  const handleTryAgainLinkClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    // TODO: Add import integration to catalog
  };

  useEffect(() => {
    if (sharedCatalogsLinkActive && searchFieldValue) {
      setSearchFieldValue('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cataloguesType]);

  useEffect(() => {
    if (allCatalogsLinkActive) {
      setHasCatalogsInAllCatalogsLink(hasCatalogs);
    }
  }, [allCatalogsLinkActive, hasCatalogs]);

  useEffect(() => {
    setFilters({ name: debouncedSearchValue.trim(), types: cataloguesType });
  }, [cataloguesType, debouncedSearchValue, setFilters]);

  useEffect(() => {
    if (!loadingErrors) {
      setSyncPopupState(initialSyncPopupState);
    }
  }, [loadingErrors]);

  const title = t('cataloguesPage.title');

  const selectedCatalogIntegrationsNotToSync = useMemo(() => {
    const selectedCatalog = catalogs.find(({ id }) => id == syncPopupState.selectedCatalogId);
    if (selectedCatalog) {
      return getUnSyncableIntegrations(selectedCatalog);
    }
    return [];
  }, [catalogs, syncPopupState.selectedCatalogId]);

  const onSecondaryButtonSyncPopupClick = () => {
    if (selectedCatalogIntegrationsNotToSync?.length) {
      return handleCloseSyncPopup();
    }
    return history.push(
      `/${FIRST_DASHBOARD_PATHNAME}/${CATALOGS_PATH}/${syncPopupState.selectedCatalogId}/${CATALOG_SETTINGS}`,
    );
  };

  const ending = calculateEnding(selectedCatalogIntegrationsNotToSync);

  return (
    <Page title={title} locationName={title} id="catalogPage">
      <Box display="flex" flexDirection="column">
        <Box padding="0 56px 30px 8px">
          <Box mb="25px">
            <DashboardBreadCrumbs />
          </Box>
          <CatalogsHeader
            mainCataloguesPage
            checkIsLimitReachedLoading={checkIsLimitReachedLoading}
            hasCatalogsInAllCatalogsLink={hasCatalogsInAllCatalogsLink}
            getCataloguesType={getCataloguesType}
            title={t('cataloguesPage.title')}
            onAddCatalogClick={handleAddCatalogClick}
            hasCatalogs={hasCatalogs}
            onChange={handleChangeSearchField}
            searchFieldValue={searchFieldValue}
            searchRequest={debouncedSearchValue}
            isSharedCatalog={sharedCatalogsLinkActive}
            checkIsLimitReachedVariables={checkIsLimitReachedData?.checkIsLimitReached}
          />
        </Box>

        {!loadingCatalogs &&
          !hasCatalogs &&
          (debouncedSearchValue ? (
            <>
              <Grid container direction="column" justifyContent="center" alignItems="center">
                <Grid item marginTop="45px">
                  {isImageLoading && <CatalogsNotFoundBackground />}
                  <img
                    src={cataloguesNotFoundIcon}
                    width="457px"
                    height="244px"
                    alt="CataloguesNotFound"
                    onLoad={() => setIsImageLoading(false)}
                  />
                </Grid>
                <Grid item marginTop="30px">
                  <Typography variant="subtitle1" fontWeight="normal">
                    {t('cataloguesPage.cataloguesNotFoundTop')}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1" fontWeight="normal">
                    {t('cataloguesPage.cataloguesNotFound')}
                  </Typography>
                </Grid>
              </Grid>
            </>
          ) : sharedCatalogsLinkActive ? (
            <Grid container direction="column" justifyContent="center" alignItems="center">
              <Grid item marginTop="45px">
                {isImageLoading && <CreateCatalogBackground />}
                <img
                  src={createCatalogIcon}
                  alt="CreateCatalog"
                  onLoad={() => setIsImageLoading(false)}
                  width="412px"
                  height="222px"
                />
              </Grid>
              <Grid item marginTop="43px">
                <Typography variant="subtitle1" fontWeight="normal">
                  {t('cataloguesPage.sharedCataloguesNotFound')}
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Grid container direction="column" justifyContent="center" alignItems="center">
              <Grid item marginTop="45px">
                {isImageLoading && <CreateCatalogBackground />}
                <img
                  src={createCatalogIcon}
                  width="412px"
                  height="222px"
                  alt="CreateCatalog"
                  onLoad={() => setIsImageLoading(false)}
                />
              </Grid>
              <Grid item marginTop="43px">
                <Typography variant="subtitle1" fontWeight="normal">
                  {t('cataloguesPage.noCatalogsYet')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1" fontWeight="normal">
                  {t('cataloguesPage.createNewCatalogHeadline')}
                </Typography>
              </Grid>
              <Grid item marginTop="25px">
                <Button
                  id="create-catalog-btn-anchor"
                  onClick={handleAddCatalogClick}
                  variant="contained"
                  startIcon={<Iconography iconName="add" />}
                  data-testid="createNewCatalogButton"
                >
                  {t('cataloguesPage.createCatalogButton')}
                </Button>
              </Grid>
            </Grid>
          ))}

        {loadingCatalogs || loadingDeleteCatalog ? (
          <CatalogueListWrapper>
            <SkeletonCatalogCardWrapper>
              <SkeletonCatalogCard cardsCount={12} />
            </SkeletonCatalogCardWrapper>
          </CatalogueListWrapper>
        ) : (
          <CatalogueList ref={catalogListRef}>
            <Scroll
              dataLength={existingCatalogs?.length || 0}
              next={fetchMoreCatalogs}
              hasMore={!tourActive && hasMoreCatalogs}
              loader={<SkeletonCatalogCard cardsCount={3} />}
              pageId="catalogPage"
            >
              {existingCatalogs?.map(
                ({
                  id,
                  name,
                  createdAt,
                  source,
                  config: { price, paymentFrequency, privacy },
                  user,
                  subscriptions,
                  integrations,
                  isEmpty,
                  duplicatedSKUInfo,
                  mappingsErrors,
                  syncable,
                }) => (
                  <Box key={id}>
                    <CatalogCard
                      syncable={syncable}
                      subscriptionApprovalStatus={(subscriptions?.[0] && subscriptions[0].approvalStatus) || ''}
                      isSharedCatalog={handleCheckIsCatalogShared(user.id)}
                      price={
                        handleCheckIsCatalogShared(user.id)
                          ? { value: getPrice(price), frequency: paymentFrequency }
                          : undefined
                      }
                      headerTitle={name}
                      size="medium"
                      onDeleteIconClick={(e) => handleDeleteIconClick(e, id, name)}
                      onCardSelect={() => handleCardSelect(id)}
                      onExportIconClick={(e) => handleExportIconClick(e, id, name, duplicatedSKUInfo.isDuplicated)}
                      onUnsubscribeButtonClick={(e) =>
                        handleSubscriptionPopupOpen(e, subscriptions?.[0]?.id || '', name, user.name)
                      }
                      onSyncButtonClick={(e) => handleOpenSyncPopup(e, id, isEmpty)}
                      createdAt={createdAt}
                      source={source}
                      onTryAgainLinkClick={handleTryAgainLinkClick}
                      id={id}
                      hasAttachedIntegrations={Boolean(integrations?.length)}
                      catalogPrivacy={privacy}
                      isEmpty={isEmpty}
                      duplicatedSKUInfo={duplicatedSKUInfo}
                      integrations={integrations}
                      mappingErrors={removeDuplicatedObjects(
                        [
                          ...(mappingsErrors || []),
                          ...(integrations?.flatMap(
                            ({ mappingsErrors }) =>
                              mappingsErrors?.filter(({ catalogId }) => !catalogId || catalogId === id) || [],
                          ) || []),
                        ],
                        'id',
                      )}
                    />
                  </Box>
                ),
              )}
            </Scroll>
            <ButtonScrollToTop intersection={document.documentElement.clientHeight} />
          </CatalogueList>
        )}
      </Box>

      <SubscriptionsPopup
        isOpen={isSubscriptionPopupOpen}
        handleClosePopup={() => setIsSubscriptionPopupOpen(false)}
        catalogName={subscriptionState.catalogName}
        catalogOwner={subscriptionState.catalogOwner}
        handleMainButtonClick={handleCancelSubscription}
        subscriptionAction={SharedCataloguesSubscriptionsActions.cancel}
        loadingStatus={loadingStatus}
      />

      <ExportCatalogPopup
        id={catalogueState.id}
        name={catalogueState.name}
        chooseExportPopupState={chooseExportPopupState}
        setChooseExportPopupState={setChooseExportPopupState}
        type={ExportPopupQueryType.CATALOG}
      />

      <Popup
        mainButtonText="Delete"
        open={isPopupOpen}
        secondaryButtonText="Back"
        mainTitle={TruncatedPopupDeleteText(catalogueState.name)}
        onClose={handleClosePopup}
        onSecondaryButtonClick={handleClosePopup}
        onMainButtonClick={handleDeleteCatalogue}
        loadingOnMainButton={loadingDeleteCatalog}
      >
        <Box maxWidth={418} margin="0 auto">
          <Typography color="text.secondary" variant="body1">
            {t('cataloguesPage.deleteCatalogueWarning')}
          </Typography>
        </Box>
      </Popup>

      <Portal condition={syncPopupState.open}>
        <Popup
          open={syncPopupState.open}
          mainTitle={t('cataloguesPage.syncPopup.title')}
          mainButtonText={t('cataloguesPage.syncPopup.mainButton')}
          secondaryButtonText={t(
            `cataloguesPage.syncPopup.${
              selectedCatalogIntegrationsNotToSync?.length ? 'secondaryButtonWithError' : 'secondaryButton'
            }`,
          )}
          onMainButtonClick={handleExportToAttachedIntegrations}
          loadingOnMainButton={syncCatalogLoading || getProposedMappingsLoading || loadingErrors}
          onSecondaryButtonClick={onSecondaryButtonSyncPopupClick}
          onClose={handleCloseSyncPopup}
        >
          <Box maxWidth={396} margin="0 auto">
            <Typography color="text.secondary">
              {!!selectedCatalogIntegrationsNotToSync?.length ? (
                <Trans i18nKey="cataloguesPage.syncPopup.errorDescription">
                  Cannot export to the
                  <strong>
                    {{
                      integrations: selectedCatalogIntegrationsNotToSync
                        ?.map(({ settings }) => settings.integrationName)
                        .join(', '),
                    }}
                  </strong>
                  integration{{ ending }} because of errors. Visit
                  <LinkToSettings
                    to={`/${FIRST_DASHBOARD_PATHNAME}/${CATALOGS_PATH}/${syncPopupState.selectedCatalogId}/${CATALOG_SETTINGS}`}
                  >
                    catalog settings
                  </LinkToSettings>
                  to see the details. You can still export to other attached integrations.
                </Trans>
              ) : (
                t('cataloguesPage.syncPopup.description')
              )}
            </Typography>
          </Box>
        </Popup>
      </Portal>

      <CheckEmailPopup />

      {renderMappingsSidebar()}

      <Box id="catalogs-anchor" position="absolute" bottom="16px" width="100%" />
    </Page>
  );
};

export default Catalogs;
