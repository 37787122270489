import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';

import { useMutation } from '@apollo/client';
import { Typography, Link } from '@material-ui/core';
import { useFlag } from '@unleash/proxy-client-react';

import SignInForm from 'src/components/Forms/SignInForm';
import GoogleAuth from 'src/components/GoogleAuth/GoogleAuth';
import Page from 'src/components/Page';
import { FeatureFlag } from 'src/constants';
import { FIRST_DASHBOARD_PATHNAME } from 'src/constants/routeSources';
import { useSnackbar } from 'src/providers/snackbar';
import {
  AuthInput,
  AuthResponse,
  GoogleLogin,
  Mutation,
  MutationGoogleLoginArgs,
  MutationSignInArgs,
  userGoogleLogin,
  userSignIn,
} from 'src/utils/gql';
import { HistoryState } from 'src/utils/history';
import { getToken, setToken } from 'src/utils/localStorage';

import { SHOPIFY_LOGIN } from '../ShopifyIntegration/IntergationScreen';

import LoginImageBackground from './LoginImageBackgroung';
import { LoginContainer, SideContainer, Image, StyledSingInContainer } from './styled';

const Login = () => {
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const history = useHistory();
  const location = useLocation<HistoryState>();
  const isSignUpFeatureActive = useFlag(FeatureFlag.SIGN_UP);

  const [isImageLoading, setIsImageLoading] = useState(true);

  const [signIn, { loading }] = useMutation<Pick<Mutation, 'signIn'>, MutationSignInArgs>(userSignIn);
  const [googleLogin, { loading: googleLoading }] = useMutation<Pick<Mutation, 'googleLogin'>, MutationGoogleLoginArgs>(
    userGoogleLogin,
  );

  const isLoggedIn = !!getToken();
  const isShopifyLogin = location.search.includes(SHOPIFY_LOGIN);
  const shopifyLoginRedirectLink = `/connect/shopify/auth/callback${location.search}`;

  const handleRedirect = useCallback(
    async ({ token }: AuthResponse) => {
      setToken(token);

      history.push(
        location.state?.from
          ? location.state.from
          : isShopifyLogin
          ? shopifyLoginRedirectLink
          : `/${FIRST_DASHBOARD_PATHNAME}`,
      );
    },
    [history, location.state, isShopifyLogin, shopifyLoginRedirectLink],
  );

  const handleGoogleLogin = useCallback(
    async (code: GoogleLogin) => {
      const { data: googleLoginData } = await googleLogin({ variables: { data: code } });

      if (googleLoginData) {
        handleRedirect(googleLoginData.googleLogin);
      }
    },
    [googleLogin, handleRedirect],
  );

  const handleSignIn = useCallback(
    async (data: AuthInput) => {
      const { data: signInData } = await signIn({ variables: { data } });

      if (signInData) {
        handleRedirect(signInData.signIn);
      }
    },
    [signIn, handleRedirect],
  );

  useEffect(() => {
    if (isLoggedIn) {
      history.push(`/${FIRST_DASHBOARD_PATHNAME}`);
    }
  }, [isLoggedIn, history]);

  return (
    <Page title={t('Login')}>
      <StyledSingInContainer>
        <LoginContainer>
          <Typography variant="h1">{t('welcome')}</Typography>

          <GoogleAuth onSubmit={handleGoogleLogin} onError={snackbar} loading={googleLoading} />

          <SignInForm onSubmit={handleSignIn} loading={loading} onError={snackbar} />

          {isSignUpFeatureActive && (
            <Typography variant="caption" mt="40px" color="textSecondary">
              {t('account')}
              <Link
                color="secondary"
                component={RouterLink}
                to="/auth/register"
                underline="hover"
                variant="h4"
                ml={1}
                data-testid="linkSingUp"
              >
                {t('signUp')}
              </Link>
            </Typography>
          )}
        </LoginContainer>

        <SideContainer>
          {isImageLoading && <LoginImageBackground />}
          <Image
            src={`${process.env.PUBLIC_URL}/images/welcome.webp`}
            alt="Welcome"
            onLoad={() => setIsImageLoading(false)}
          />
        </SideContainer>
      </StyledSingInContainer>
    </Page>
  );
};

export default Login;
