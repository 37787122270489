import { Link } from 'react-router-dom';

import { Box, Typography } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';

import Iconography from 'src/components/Iconography';

export const CardCaption = styled(Box)`
  font-size: 12px;
  line-height: 14px;
  font-weight: normal;
`;

export const StyledIcon = styled(Iconography)`
  margin-right: 24px;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
`;

export const LoaderWrapper = styled(Box)`
  width: 91%;
  height: 51vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PriceBackground = styled(Box)`
  max-width: 111px;
  padding: 8px 15px;
  height: auto;
  background: ${({ theme: { palette } }) => palette.secondary.main};
  border-radius: 10px;
  text-align: center;
`;

export const ButtonPriceText = styled(Typography)`
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: ${({ theme: { palette } }) => palette.common.white};
  padding: 8px 15px;
`;

export const CatalogueListWrapper = styled(Box)`
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
`;

export const CatalogueList = styled(Box)`
  .infinite-scroll-component {
    margin-right: 46px;
    padding: 10px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(356px, 1fr));
    grid-gap: 24px;
  }
`;

export const LinkToSettings = styled(Link)`
  color: ${({ theme: { palette } }) => palette.text.secondary};
`;
