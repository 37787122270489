import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useQuery } from '@apollo/client';
import { Box, makeStyles, Typography } from '@material-ui/core';

import Close from 'src/components/Images/Close.svg';
import Loader from 'src/components/Loader';
import Page from 'src/components/Page';
import ContactUsMessage from 'src/components/Popups/ContactUsMessage';
import ContactUsPopup from 'src/components/Popups/ContactUsPopup';
import UpgradePlanPopup from 'src/components/Popups/UpgradePlanPopup';
import { currentUserSubscriptionPlan, getPlans, PlanPaymentFrequency, Query, QueryGetPlansArgs } from 'src/utils/gql';

import PricingPlansBlock from './PricingPlansBlock';

const useStyles = makeStyles((theme) => ({
  page: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    background: `linear-gradient(to bottom, ${theme.palette.common.white} 39%, ${theme.palette.primary.light} 39%)`,
  },
  headerTitle: {
    marginTop: '50px',
    fontWeight: 'bold',
    fontSize: '42px',
    lineHeight: '49px',
    color: theme.palette.primary.main,
  },
  headerDescription: {
    marginTop: '20px',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '21px',
    color: theme.palette.primary.main,
  },
  closeButton: {
    position: 'absolute',
    top: '15px',
    right: '56px',
    width: '34px',
    height: '34px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    borderRadius: '50%',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: `0 0 15px ${theme.palette.secondary.main}`,
    },
    '&:active': {
      boxShadow: 'none',
      outline: 'none',
    },
  },
}));

const UpgradePricingPlansScreen = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const [openContactUsPopup, setOpenContactUsPopup] = useState<boolean>(false);
  const [openUpgradePopup, setOpenUpgradePopup] = useState<boolean>(false);
  const [openInformationPopup, setOpenInformationPopup] = useState<boolean>(false);
  const [planToUpgrade, setPlanToUpgrade] = useState<{ name: string; price: string; title: string } | null>(null);

  const { data: planData, loading: planLoading } = useQuery<Pick<Query, 'getPlans'>, QueryGetPlansArgs>(getPlans, {
    fetchPolicy: 'cache-and-network',
    variables: {
      paymentFrequency: PlanPaymentFrequency.Monthly,
    },
  });

  const { data: currentUserSubscriptionPlanData, loading: currentUserSubscriptionPlanLoading } =
    useQuery(currentUserSubscriptionPlan);

  const currentUserSubscriptionPlanName = useMemo(
    () => currentUserSubscriptionPlanData?.planSubscriptionStats?.planSubscription?.plan?.name,
    [currentUserSubscriptionPlanData],
  );

  const closeContactUsAndOpenInformationPopup = () => {
    setOpenContactUsPopup(false);
    setOpenInformationPopup(true);
  };

  const closePlanUpgradePopup = () => {
    setPlanToUpgrade(null);
    setOpenUpgradePopup(false);
  };

  return (
    <Page title={t('upgradePricingPlanPage.pageTitle')}>
      {planLoading || currentUserSubscriptionPlanLoading ? (
        <Loader fullScreen />
      ) : (
        <Box className={classes.page}>
          <Box onClick={history.goBack} className={classes.closeButton}>
            <img src={Close} alt="close" />
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <Typography className={classes.headerTitle}>{t('upgradePricingPlanPage.headerTitle')}</Typography>
            <Typography className={classes.headerDescription}>
              {t('upgradePricingPlanPage.headerDescription')}
            </Typography>
          </Box>
          <Box mt="92px">
            <PricingPlansBlock
              currentUserSubscriptionPlanName={currentUserSubscriptionPlanName}
              planData={planData}
              onContactUsClick={() => setOpenContactUsPopup(true)}
              onUpgradePlanClick={(planInfo) => {
                setPlanToUpgrade(planInfo);
                setOpenUpgradePopup(true);
              }}
            />
          </Box>

          <ContactUsPopup
            open={openContactUsPopup}
            onCloseCallback={() => setOpenContactUsPopup(false)}
            onSendCallback={closeContactUsAndOpenInformationPopup}
          />
          <ContactUsMessage open={openInformationPopup} onCloseClick={() => setOpenInformationPopup(false)} />

          {planToUpgrade && (
            <UpgradePlanPopup
              open={openUpgradePopup}
              onClose={closePlanUpgradePopup}
              planInfo={planToUpgrade}
              currentPlanName={currentUserSubscriptionPlanName}
            />
          )}
        </Box>
      )}
    </Page>
  );
};

export default UpgradePricingPlansScreen;
