import Resizer from 'react-image-file-resizer';

const CompressFormatType = 'JPEG' || 'PNG' || 'WEBP';
const OutputType = 'base64' || 'blob' || 'file';

export default (
  file: File,
  maxWidth?: number,
  maxHeight?: number,
  format?: typeof CompressFormatType,
  quality?: number,
  outputType?: typeof OutputType,
) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      maxWidth ?? 300,
      maxHeight ?? 300,
      format ?? 'JPEG',
      quality ?? 100,
      0,
      (uri) => {
        resolve(uri);
      },
      outputType ?? 'file',
    );
  });
