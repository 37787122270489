import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@material-ui/core';

import Popup, { PopupProps } from 'src/components/Popup';

interface DeletePopupProps extends PopupProps {
  open: boolean;
  onClose: () => void;
  onMainButtonClick: () => void;
  onSecondaryButtonClick: () => void;
  loadingOnMainButton?: boolean;
  mainTitle: string | JSX.Element;
  descriptionText: string | JSX.Element;
  mainTitleWidth?: string;
  mainButtonText?: string;
  secondaryButtonText?: string;
}

const DeletePopup = ({
  open,
  onClose,
  onMainButtonClick,
  onSecondaryButtonClick,
  mainTitle,
  mainTitleWidth,
  descriptionText,
  loadingOnMainButton,
  mainButtonText,
  secondaryButtonText,
  ...props
}: DeletePopupProps) => {
  const { t } = useTranslation();

  return (
    <Popup
      {...props}
      open={open}
      mainTitle={mainTitle}
      mainTitleWidth={mainTitleWidth}
      onClose={onClose}
      mainButtonText={mainButtonText ?? t('deletePopup.mainButton')}
      secondaryButtonText={secondaryButtonText ?? t('deletePopup.secondaryButton')}
      loadingOnMainButton={loadingOnMainButton}
      onMainButtonClick={onMainButtonClick}
      onSecondaryButtonClick={onSecondaryButtonClick}
      testMainButton="deleteButtonPopup"
      testSecondaryButton="backButtonPopup"
    >
      <Box maxWidth={400} margin="0 auto">
        <Typography color="text.secondary" variant="body1">
          {descriptionText}
        </Typography>
      </Box>
    </Popup>
  );
};

export default DeletePopup;
