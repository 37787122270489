import React, { ChangeEvent, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';
import { Box } from '@material-ui/core';

import DashboardBreadCrumbs from 'src/components/Breadcrumds/DashboardBreadCrumbs';
import Button from 'src/components/Button';
import {
  CATALOGS_PATH,
  EDITING,
  FIRST_DASHBOARD_PATHNAME,
  PRODUCT_ITEMS_PATH,
  PRODUCT_TYPES_PATH,
} from 'src/constants/routeSources';
import { validateTextFieldValue } from 'src/helpers/validationCheck';
import useOnboarding from 'src/providers/OnboardingProvider/hooks/useOnboarding';
import { ProductType, ProductTypeField, checkProductTypeNameAvailability } from 'src/utils/gql';
import SaveButtonWithMenu, {
  popoverActions,
} from 'src/views/Catalogs/ProductTypes/ProductTypeSettings/SaveButtonWithMenu';
import { StyledLink } from 'src/views/UserSettings/styled';

import {
  NameContainer,
  NamePartTypography,
  ProductTypeSettingsHeaderWrapper,
  StyledProductTypeNameInput,
} from './styled';
import { ProductTypeSettingsPage } from './types';

interface ProductTypeSettingsHeaderProps {
  handleSaveProductType: (action: popoverActions) => void;
  shouldBreadcrumbsFollowName: boolean;
  catalogId: string;
  debouncedProductTypeName: string;
  initialProductTypeName: string;
  sameNameErrorForTest?: boolean;
  productTypeId: string;
  showProductItemLink: boolean;
  isSidebarOpen: boolean;
  type: ProductTypeSettingsPage;
}

const ProductTypeSettingsHeader = ({
  handleSaveProductType,
  shouldBreadcrumbsFollowName,
  catalogId,
  debouncedProductTypeName,
  initialProductTypeName,
  sameNameErrorForTest,
  productTypeId,
  showProductItemLink,
  isSidebarOpen,
  type,
}: ProductTypeSettingsHeaderProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const {
    onboardingState: { tourActive },
    setOnboardingState,
  } = useOnboarding();

  const {
    watch,
    control,
    setError,
    formState: { errors, isValid },
  } = useFormContext();

  const productTypeName = watch('productName');

  const errorMessageText = errors.productName?.message
    ? t(errors.productName.message as string)
    : sameNameErrorForTest
    ? t('productType.header.productTypeNameExistsError')
    : '';

  useQuery(checkProductTypeNameAvailability, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      catalogId,
      name: debouncedProductTypeName.trim(),
    },
    onCompleted: (data) => {
      if (!data.checkProductTypeNameAvailability && productTypeName !== initialProductTypeName) {
        setError('productName', { message: t('productType.header.productTypeNameExistsError') });
      }
    },
    skip: !productTypeName || tourActive,
  });

  const handleCreateOrUpdateProductTypeSettings = (action: popoverActions) => handleSaveProductType(action);

  const handleCancelClick = () => {
    history.push(
      `/${FIRST_DASHBOARD_PATHNAME}/${CATALOGS_PATH}/${
        type === ProductTypeSettingsPage.Fields
          ? `${catalogId}/${PRODUCT_TYPES_PATH}`
          : `${id}/${PRODUCT_TYPES_PATH}/${productTypeId}/${EDITING}`
      }`,
    );
  };

  const handleProductItemLinkClick = () =>
    history.push({
      pathname: `/${FIRST_DASHBOARD_PATHNAME}/${CATALOGS_PATH}/${catalogId}/${PRODUCT_TYPES_PATH}/${productTypeId}/${PRODUCT_ITEMS_PATH}`,
    });

  useEffect(() => {
    if (tourActive) {
      setOnboardingState((prevState) => ({
        ...prevState,
        createdOnboardingProductType: {
          id: '1',
          name: productTypeName,
          fields: [] as ProductTypeField[],
        } as ProductType,
      }));
    }
  }, [productTypeName, setOnboardingState, tourActive]);

  return (
    <Box display="flex" flexDirection="column">
      <DashboardBreadCrumbs {...(shouldBreadcrumbsFollowName && { editingName: productTypeName })} />

      <ProductTypeSettingsHeaderWrapper mt="30px">
        {type === ProductTypeSettingsPage.Fields ? (
          <Controller
            name="productName"
            control={control}
            render={({ field: { onChange, value } }) => (
              <StyledProductTypeNameInput
                id="product-type-name-field-anchor"
                error={!!errors.productName}
                helperText={errorMessageText}
                variant="outlined"
                inputProps={{
                  'data-testid': 'productName',
                  'aria-label': 'enterProductTypeName',
                }}
                label={t('productType.header.productTypeInput')}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  onChange(validateTextFieldValue(e.target.value));
                }}
                value={value || ''}
              />
            )}
          />
        ) : (
          <NameContainer>
            <NamePartTypography variant="h2" whiteSpace="pre" overflow="hidden" textOverflow="ellipsis">
              {productTypeName}&nbsp;
            </NamePartTypography>

            <NamePartTypography variant="h2" overflow="visible">{` ${t('mappings')}`}</NamePartTypography>
          </NameContainer>
        )}

        <Box display="flex" alignItems="center">
          {showProductItemLink && (
            <Box mr="20px">
              <StyledLink onClick={handleProductItemLinkClick}>{t('productType.header.productItemsLink')}</StyledLink>
            </Box>
          )}

          <Button onClick={handleCancelClick} variant="outlined">
            {t(
              type === ProductTypeSettingsPage.Fields
                ? 'cancel'
                : 'userSettingsPage.integrations.deletePopup.secondaryButtonText',
            )}
          </Button>

          {type !== ProductTypeSettingsPage.Mapping && (
            <Box ml="15px">
              <SaveButtonWithMenu
                isSidebarOpen={isSidebarOpen}
                onClick={handleCreateOrUpdateProductTypeSettings}
                disabled={!isValid || Boolean(sameNameErrorForTest)}
              />
            </Box>
          )}
        </Box>
      </ProductTypeSettingsHeaderWrapper>
    </Box>
  );
};

export default ProductTypeSettingsHeader;
