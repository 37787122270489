import React, { ChangeEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Divider, FormControl, FormControlLabel, Link, Radio, RadioGroup, Typography } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import moment from 'moment';

import { Plan, PlanPaymentFrequency } from 'src/utils/gql';

import Loader from '../Loader';

const CardContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  maxWidth: 580,
  padding: 40,
  borderRadius: 20,
  boxShadow: '0 2px 10px rgba(46, 96, 170, 0.25)',
  background: theme.palette.secondary.main,

  '& .MuiTypography-root': {
    color: theme.palette.common.white,
  },

  '& .MuiDivider-root': {
    margin: '20px 0',
    background: theme.palette.common.white,
  },
}));

const StyledFormControl = styled(FormControl)(() => ({
  position: 'relative',
  marginTop: 20,
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  '& .MuiFormControlLabel-label': {
    fontSize: 15,
    lineHeight: '18px',
    color: theme.palette.common.white,
    marginLeft: 10,

    '&.Mui-disabled': {
      color: theme.palette.common.white,
    },
  },

  '& .MuiButtonBase-root': {
    color: theme.palette.common.white,

    '&.Mui-checked': {
      color: theme.palette.common.white,
    },
    '&.Mui-disabled': {
      color: theme.palette.common.white,
    },
  },
}));

const Discount = styled(Box)(() => ({
  position: 'absolute',
  left: 95,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 104,
  height: 24,
  borderRadius: 20,
  background: 'rgba(113, 111, 234, 1)',
}));

interface PaymentPlanCardProps {
  plans: Plan[];
  selectedPlan?: Plan;
  onChangePlan: (plan?: Plan) => void;
  changePlanAvailable: boolean;
}

export const PaymentPlanCard = ({ plans, selectedPlan, onChangePlan, changePlanAvailable }: PaymentPlanCardProps) => {
  const { t } = useTranslation();

  const [value, setValue] = useState<string>(selectedPlan?.paymentFrequency ?? PlanPaymentFrequency.Annual);

  const isAnnualFrequency = selectedPlan?.paymentFrequency === PlanPaymentFrequency.Annual;
  const monthlyPlan = useMemo(
    () => plans?.find(({ paymentFrequency }) => paymentFrequency === PlanPaymentFrequency.Monthly),
    [plans],
  );
  const yearlyPlan = useMemo(
    () => plans?.find(({ paymentFrequency }) => paymentFrequency === PlanPaymentFrequency.Annual),
    [plans],
  );

  const monthsPerYear = 12;

  const getNextPaymentData = () => {
    const today = new Date();
    let nextPayment;

    isAnnualFrequency
      ? (nextPayment = today.setFullYear(today.getFullYear() + 1))
      : (nextPayment = today.setMonth(today.getMonth() + 1));

    return moment(nextPayment).format('DD MMMM, YYYY');
  };

  const totalPrice = useMemo(
    () => (selectedPlan?.price && isAnnualFrequency ? selectedPlan.price * monthsPerYear : selectedPlan?.price),
    [selectedPlan, isAnnualFrequency],
  );

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setValue(target.value);
    onChangePlan(plans.find(({ paymentFrequency }) => paymentFrequency === target.value));
  };

  return (
    <CardContainer>
      <Box display="flex" flexDirection="column">
        <Typography variant="h2">{monthlyPlan?.name}</Typography>

        <Divider />

        <Typography variant="subtitle1">{t('paymentPage.paymentCard.billedPeriod')}</Typography>

        <StyledFormControl fullWidth>
          <RadioGroup value={value} onChange={handleChange}>
            <Box display="flex" justifyContent="space-between" mb="12px">
              <StyledFormControlLabel
                value={PlanPaymentFrequency.Annual}
                control={<Radio disabled={!changePlanAvailable} />}
                label={t('paymentPage.paymentCard.yearly')}
              />

              <Typography variant="subtitle1">{`$${yearlyPlan?.price}`}</Typography>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <StyledFormControlLabel
                value={PlanPaymentFrequency.Monthly}
                control={<Radio disabled={!changePlanAvailable} />}
                label={t('paymentPage.paymentCard.monthly')}
              />

              <Typography variant="subtitle1">{`$${monthlyPlan?.price}`}</Typography>
            </Box>
          </RadioGroup>

          <Discount>
            <Typography variant="body2">{t('paymentPage.paymentCard.discount')}</Typography>
          </Discount>
        </StyledFormControl>

        <Divider />

        <Typography variant="subtitle1">{t('paymentPage.paymentCard.total')}</Typography>

        {totalPrice && changePlanAvailable ? (
          <Box display="flex" alignItems="center" justifyContent="space-between" mt="15px">
            <Box>
              <Typography variant="body2">
                {t('paymentPage.paymentCard.nextPayment')} {getNextPaymentData()}
              </Typography>

              <Typography variant="body2" maxWidth="290px">
                {t('paymentPage.paymentCard.contactUs')}
                <Link href="mailto:support@otomate.shop">support@otomate.shop</Link>
              </Typography>
            </Box>

            <Typography variant="h1">
              {`$${totalPrice}`}
              <Typography component="span" variant="body1">
                {t(`paymentPage.paymentCard.${isAnnualFrequency ? 'year' : 'month'}`)}
              </Typography>
            </Typography>
          </Box>
        ) : (
          <Box display="flex" justifyContent="center" alignItems="center" height="71px">
            <Loader size="medium" />
          </Box>
        )}
      </Box>
    </CardContainer>
  );
};
