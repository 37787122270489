import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Box } from '@material-ui/core';

import useUploadMediaBrowseUrl from 'src/hooks/useUploadMediaBrowseUrl';

import TextInput from '../Forms/FormInputs/TextInput';
import Popup from '../Popup';

interface AddUrlPopup {
  urlData: {
    [x: string]: string;
  };
  handleCloseAddUrlPopup: () => void;
  addUrlPopup: boolean;
  isValid: boolean;
}

const inputName = 'urlMedia';

const AddUrlPopup = ({ urlData, handleCloseAddUrlPopup, addUrlPopup, isValid }: AddUrlPopup) => {
  const { t } = useTranslation();

  const { handleUploadMediaFromUrl } = useUploadMediaBrowseUrl();

  const { watch } = useFormContext();

  const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();

      if (isValid) {
        handleUploadMediaFromUrl(urlData);
        handleCloseAddUrlPopup();
      }
    }
  };

  return (
    <form onKeyDown={handleKeyDown}>
      <Popup
        open={addUrlPopup}
        onClose={handleCloseAddUrlPopup}
        mainTitle={t('productItemCreateEdit.mediaGallery.addUrlPopup.title')}
        mainButtonText={t('productItemCreateEdit.mediaGallery.addUrlPopup.add')}
        secondaryButtonText={t('productItemCreateEdit.mediaGallery.addUrlPopup.cancel')}
        onMainButtonClick={() => handleUploadMediaFromUrl(urlData)}
        mainButtonsMarginAbsence
        onSecondaryButtonClick={handleCloseAddUrlPopup}
        mainButtonDisabled={!isValid}
        testMainButton="addUrlButtonPopup"
        testSecondaryButton="cancelButtonPopup"
      >
        <Box margin="0 55px" padding="7px 0 40px">
          <TextInput
            autoComplete="off"
            name={inputName}
            inputNameTestId="urlMediaInput"
            label={t('productItemCreateEdit.mediaGallery.addUrlPopup.label')}
            value={watch(inputName) || ''}
          />
        </Box>
      </Popup>
    </form>
  );
};

export default AddUrlPopup;
