import * as React from 'react';
import { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';

import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import { CardElement } from '@stripe/react-stripe-js';

import StripeInput from './StripeInput';

type StripeElement = typeof CardElement;

interface StripeCustomFieldProps<T extends StripeElement>
  extends Omit<TextFieldProps, 'onChange' | 'inputComponent' | 'inputProps'> {
  inputProps?: ComponentProps<T>;
  labelErrorMessage?: string;
  onChange?: ComponentProps<T>['onChange'];
  stripeElement?: T;
  loading: boolean;
}

export const StripeCustomField = <T extends StripeElement>(props: StripeCustomFieldProps<T>) => {
  const {
    helperText,
    InputLabelProps,
    InputProps = {},
    inputProps,
    error,
    loading,
    labelErrorMessage,
    stripeElement,
    ...other
  } = props;

  return (
    <TextField
      fullWidth
      InputLabelProps={{
        ...InputLabelProps,
        shrink: true,
      }}
      disabled={loading}
      error={error}
      InputProps={{
        ...InputProps,
        inputProps: {
          ...inputProps,
          ...InputProps.inputProps,
          component: stripeElement,
        },
        inputComponent: StripeInput,
      }}
      inputProps={{ 'data-testid': 'customCardField' }}
      helperText={error ? labelErrorMessage : helperText}
      {...(other as any)} // eslint-disable-line
    />
  );
};

export function StripeFieldCard(props: StripeCustomFieldProps<typeof CardElement>) {
  const { t } = useTranslation();
  return (
    <StripeCustomField
      label={t('userSettingsPage.billing.addPaymentMethodPopup.cardDetails')}
      stripeElement={CardElement}
      {...props}
    />
  );
}
