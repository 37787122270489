import React, { useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ApolloError, useMutation } from '@apollo/client';
import { DialogContent } from '@material-ui/core';
import { Box } from '@mui/material';

import { useSnackbar } from 'src/providers/snackbar';
import { ProductItem, ProductType, ProductTypeField, updateProductItems, FieldSystemName } from 'src/utils/gql';
import { clearProductFiltersCache } from 'src/views/Catalogs/ProductItems';

import MultiTypeInput from '../MultiTypeInput';
import Popup, { PopupProps } from '../Popup';
import Select, { ChangeSelectHandler } from '../Select';

import ApprovePopup from './ApprovePopup';
import { BulkEditPopupLayout } from './styled';

interface BulkEditPopupProps extends PopupProps {
  setIsAllItemsSelectedSynthetic: (value: boolean) => void;
  setSelectedItemsIds: (value: string[]) => void;
  isAllItemsSelected: boolean;
  productType: ProductType;
  onClose: () => void;
  selectedItems: ProductItem[];
}

const BulkEditPopup = ({
  open,
  onClose,
  productType,
  selectedItems,
  isAllItemsSelected,
  setSelectedItemsIds,
  setIsAllItemsSelectedSynthetic,
}: BulkEditPopupProps) => {
  const { t } = useTranslation();
  const snackbar = useSnackbar();

  const [updateAll, setUpdateAll] = useState<boolean>(false);
  const [selectedField, setSelectedField] = useState<ProductTypeField | null>(null);
  const [isApprovePopupOpen, setIsApprovePopupOpen] = useState<boolean>(false);
  const [updateLoading, setUpdateLoading] = useState<boolean>(false);
  const [dataForUpdate, setDataForUpdate] = useState<{
    field: ProductTypeField;
    newValue: string;
  } | null>(null);

  const [updateProductItemsMutation] = useMutation(updateProductItems, {
    update: clearProductFiltersCache,
  });

  const formMethods = useForm({
    mode: 'all',
    reValidateMode: 'onSubmit',
  });

  const {
    formState: { errors },
    reset,
  } = formMethods;

  const fieldsOptions = useMemo(
    () => productType.fields.map((productTypeField) => ({ label: productTypeField.name, value: productTypeField })),
    [productType.fields],
  );

  const isButtonDisabled = !(selectedField && !Object.values(errors).length);

  const handleChangeFieldNameSelect = ({ value }: { value: ProductTypeField }) => {
    reset();
    setSelectedField(value);
  };

  const handleClose = () => {
    reset();
    setSelectedField(null);
    onClose();
  };

  const onValidSubmit = () => {
    const currentData = selectedField
      ? {
          field: selectedField,
          newValue: formMethods.watch('bulkEditValue'),
        }
      : null;
    setDataForUpdate(currentData);

    if (currentData) {
      if (isAllItemsSelected) {
        setUpdateAll(true);
      }

      setIsApprovePopupOpen(true);
    }

    handleClose();
  };

  const handleMainButtonClick = () => {
    formMethods.handleSubmit(onValidSubmit)();

    return false;
  };

  const handleCloseApprovePopup = () => {
    setIsApprovePopupOpen(false);
  };

  const handleEditItems = async () => {
    setUpdateLoading(true);

    if (dataForUpdate) {
      try {
        await updateProductItemsMutation({
          variables: {
            productTypeId: productType.id,
            changingValue: {
              productTypeFieldId: +dataForUpdate.field.id,
              value: dataForUpdate.newValue,
            },
            ids: updateAll ? [] : selectedItems.map(({ id }) => id),
          },
        });
      } catch (error) {
        const { graphQLErrors, message: errorText } = error as ApolloError;
        const message = graphQLErrors && graphQLErrors.length ? graphQLErrors[0].message : errorText;

        if (error) {
          snackbar(message);
        }
      }
    } else {
      snackbar(t('unexpectedError'));
    }

    setUpdateLoading(false);
    setIsApprovePopupOpen(false);
    setSelectedItemsIds([]);
  };

  const itemsNames = useMemo(
    () =>
      selectedItems.reduce((acc: string[], item) => {
        const itemName = item?.values?.find(
          ({ productTypeField }) => productTypeField.systemName === FieldSystemName.Name,
        )?.value;

        return itemName ? [...acc, itemName] : acc;
      }, []),

    [selectedItems],
  );

  const emptyTextFieldErrorMessage = String(t('productItemsPage.bulkSelect.emptyTextFieldError') || '');

  return (
    <>
      <Popup
        headerMarginAbsence
        open={open}
        mainTitle={t('bulkChangePopup.titleText')}
        titleDescription={t(`bulkChangePopup.${isAllItemsSelected ? 'editAllTitleDescription' : 'titleDescription'}`)}
        mainButtonText={t('bulkChangePopup.mainButtonText')}
        mainButtonDisabled={isButtonDisabled}
        TransitionProps={{
          onExited: () => setIsAllItemsSelectedSynthetic(false),
        }}
        onMainButtonClick={handleMainButtonClick}
        onClose={handleClose}
        actionsMarginTop="20px"
      >
        <DialogContent>
          <FormProvider {...formMethods}>
            <BulkEditPopupLayout>
              <Select
                onChangeSelect={handleChangeFieldNameSelect as ChangeSelectHandler}
                options={fieldsOptions}
                label={t('bulkChangePopup.fieldNameSelectLabel')}
                disablePortal={false}
                autoFocus
                required
              />

              {selectedField && (
                <Box mt="17px">
                  <MultiTypeInput
                    label={t('bulkChangePopup.itemValueInputLabel')}
                    type={selectedField.type}
                    productTypeFieldId={selectedField.id}
                    productTypeId={productType.id}
                    emptyErrorMessage={emptyTextFieldErrorMessage}
                    shouldBeFilled
                    inputIdentifier="bulkEditValue"
                  />
                </Box>
              )}
            </BulkEditPopupLayout>
          </FormProvider>
        </DialogContent>
      </Popup>

      <ApprovePopup
        TransitionProps={{
          onExited: () => setUpdateAll(false),
        }}
        updateAll={updateAll}
        open={isApprovePopupOpen}
        onClose={handleCloseApprovePopup}
        onMainButtonClick={handleEditItems}
        fieldName={dataForUpdate?.field.name || ''}
        itemValue={dataForUpdate?.newValue || ''}
        loadingOnMainButton={updateLoading}
        itemsNames={itemsNames}
      />
    </>
  );
};
export default BulkEditPopup;
