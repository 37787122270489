import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { gql, useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Theme, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@mui/material';
import moment, { Moment } from 'moment';

import Button from 'src/components/Button';
import SingleDatePicker from 'src/components/DatePickers/SingleDatePicker';
import TextInput from 'src/components/Forms/FormInputs/TextInput';
import { apiKeyNameFormSchema } from 'src/components/Forms/validationSchemas';
import Popup from 'src/components/Popup';
import { useSnackbar } from 'src/providers/snackbar';
import { copyToClipboard } from 'src/utils/copyToClipboard';
import { createPublicApiKey, Mutation, MutationCreatePublicApiKeyArgs } from 'src/utils/gql';

import { StyledFirstStepPopup, StyledSecondStepPopup, StyledTextPopup, StyledBoxButton } from './styled';

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    '&.MuiOutlinedInput-input, .Mui-disabled': {
      background: theme.palette.common.white,
    },
  },
  copyButton: {
    padding: 0,
    paddingRight: '18px',
  },
  boldText: {
    fontWeight: '700',
  },
  breakName: {
    wordBreak: 'break-all',
  },
}));

const newPublicApiKeyFragment = gql`
  fragment NewPublicApiKey on PublicApiKey {
    id
    createdAt
    name
    expirationDate
  }
`;

interface Props {
  open: boolean;
  onClose?: () => void;
}

const CreatePublicApiKeyPopup = ({ open, onClose }: Props) => {
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const classes = useStyles();

  const [newKeyData, setNewKeyData] = useState<{
    name: string;
    date: Moment | null;
  }>({
    name: '',
    date: null,
  });
  const [newKeyError, setNewKeyError] = useState<boolean>(false);
  const [createdKey, setCreatedKey] = useState('');

  const formProps = useForm({
    mode: 'onChange',
    resolver: yupResolver(apiKeyNameFormSchema),
  });

  const {
    watch,
    formState: { isValid },
  } = formProps;

  const [createPublicApiKeyMethod, { loading }] = useMutation<
    Pick<Mutation, 'createPublicApiKey'>,
    MutationCreatePublicApiKeyArgs
  >(createPublicApiKey, {
    update(cache, { data }) {
      const newPublicApiKey = data?.createPublicApiKey;

      if (newPublicApiKey) {
        cache.modify({
          fields: {
            publicApiKeys(existingKeysRef = [], { readField }) {
              const newPublicApiKeyRef = cache.writeFragment({
                data: {
                  ...newPublicApiKey,
                  __typename: 'PublicApiKey',
                },
                fragment: newPublicApiKeyFragment,
              });

              if (existingKeysRef.some((ref: { __ref: string }) => readField('id', ref) === newPublicApiKey.id)) {
                return existingKeysRef;
              }

              return [...existingKeysRef, newPublicApiKeyRef];
            },
          },
        });

        // remove because contains sensitive data
        cache.evict({
          id: cache.identify({ id: newPublicApiKey.id, __typename: 'CreatePublicApiKeyResponse' }),
        });

        cache.gc();
      }
    },
  });

  const handleCreateKey = async () => {
    const variables: MutationCreatePublicApiKeyArgs = {
      name: watch('apiKeyName'),
    };

    if (newKeyData.date) {
      variables.expirationDate = newKeyData.date;
    }

    try {
      const response = await createPublicApiKeyMethod({
        variables,
      });

      if (response.data) {
        setCreatedKey(response.data.createPublicApiKey.key);
      }
    } catch (err) {
      snackbar((err as Error).message);
    }
  };

  const handleDateChange = (date: Moment | null) => {
    const today = moment();

    if (date && (!date?.isValid() || date < today)) {
      setNewKeyError(true);

      return;
    }

    setNewKeyData((prev) => ({
      ...prev,
      date,
    }));
    setNewKeyError(false);
  };

  const minDate = moment().add(1, 'day');
  const helperTextDateField = newKeyError ? t('userSettingsPage.publicApiKeys.invalidDateFormatError') : '';

  return (
    <>
      {createdKey ? (
        <Popup
          open={Boolean(handleCreateKey)}
          onClose={onClose}
          mainTitle={t('userSettingsPage.publicApiKeys.createPublicApiKeyPopup.title')}
          mainButtonsMarginAbsence
          bottomMarginAbsence
          testMainButton={'createButtonPopup'}
        >
          <StyledSecondStepPopup>
            <StyledTextPopup>
              <Trans
                i18nKey="userSettingsPage.publicApiKeys.createPublicApiKeyPopup.descriptionCopyKey"
                components={{ bold: <strong /> }}
              />
            </StyledTextPopup>
            <Box marginTop="25px" id="textarea-wrapper">
              <TextField fullWidth multiline disabled value={createdKey} className={classes.root} />
            </Box>
          </StyledSecondStepPopup>
          <StyledBoxButton>
            <Button variant="outlined" onClick={onClose} loading={loading}>
              {t('userSettingsPage.publicApiKeys.createPublicApiKeyPopup.closeCreatePopupButton')}
            </Button>
            <Button
              variant="contained"
              onClick={() =>
                copyToClipboard(`${createdKey}`, document.getElementById('textarea-wrapper') || document.body, () =>
                  snackbar(t('copyTextToClipboardSuccess'), 'success'),
                )
              }
            >
              {t('userSettingsPage.publicApiKeys.createPublicApiKeyPopup.copyKeyButton')}
            </Button>
          </StyledBoxButton>
        </Popup>
      ) : (
        <Popup
          open={open}
          onClose={onClose}
          mainButtonsMarginAbsence
          mainTitle={t('userSettingsPage.publicApiKeys.createPublicApiKeyPopup.titleCreateNewApiKey')}
          mainButtonText={t('userSettingsPage.publicApiKeys.createPublicApiKeyPopup.createKeyButton')}
          onMainButtonClick={handleCreateKey}
          loadingOnMainButton={loading}
          mainButtonDisabled={!isValid || newKeyError}
          testMainButton={'createButtonPopup'}
        >
          <StyledFirstStepPopup>
            <Box mb="22px">
              <FormProvider {...formProps}>
                <TextInput
                  shouldFocus
                  autoComplete="off"
                  name="apiKeyName"
                  label={t('userSettingsPage.publicApiKeys.createPublicApiKeyPopup.nameInputLabel')}
                  inputNameTestId="apiKeyName"
                />
              </FormProvider>
            </Box>

            <SingleDatePicker
              onSelectDate={handleDateChange}
              value={newKeyData.date}
              minDate={minDate}
              label={t('userSettingsPage.publicApiKeys.createPublicApiKeyPopup.dateInputLabel')}
              disabled={!!createdKey}
              hasError={newKeyError}
              helperText={helperTextDateField}
            />
          </StyledFirstStepPopup>
        </Popup>
      )}
    </>
  );
};

export default CreatePublicApiKeyPopup;
