import { clearCacheFactory } from 'src/utils/cacheUtils';

export const validationUrl = (value: string) => {
  const hit = value?.match(/https?:\/\//);
  return !value || (!!hit && !hit.index);
};

const defaultIntegrationsQueries = ['getIntegrations', 'checkIsLimitReached'];
export const clearIntegrationsCache = clearCacheFactory({ fieldNames: defaultIntegrationsQueries });
export const clearIntegrationsWithMappingsCache = clearCacheFactory({
  fieldNames: [
    ...defaultIntegrationsQueries,
    'productTypeFieldIntegrationMappings',
    'getProductTypeIntegrationMappings',
    'getProductTypeIntegrationMappingsByCatalogId',
    'productItemIntegrationMappings',
    'getProductItem',
  ],
});
