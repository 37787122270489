import React, { MouseEventHandler } from 'react';

import { Box, IconButton, makeStyles } from '@material-ui/core';
import { experimentalStyled as styled, Theme } from '@material-ui/core/styles';

import Button from './Button';
import Iconography from './Iconography';

const useStyles = makeStyles((theme: Theme) => ({
  disabledBtn: {
    background: theme.palette.text.disabled,
  },
  plusIcon: {
    width: '14px',
    height: '14px',
  },
}));

const StyledIconButton = styled(IconButton)(() => ({
  width: '30px',
  height: '30px',
  position: 'relative',
}));

const GradientBorder = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: '24px',
  height: '24px',
  background: theme.palette.secondary.dark,
  borderRadius: '50%',

  '&:before': {
    content: '""',
    position: 'absolute',
    width: '22px',
    height: '22px',
    borderRadius: '50%',
    top: 1,
    left: 1,
    background: theme.palette.common.white,
    zIndex: 1,
  },

  '& .MuiSvgIcon-root': {
    position: 'absolute',
    width: 16,
    height: 16,
    top: 4,
    left: 4,
    zIndex: 1,
  },
}));

const WhiteBorder = styled(Box)(({ theme }) => ({
  width: '24px',
  height: '24px',
  border: '1px solid',
  borderColor: theme.palette.common.white,
  borderRadius: '50%',

  '& .MuiSvgIcon-root': {
    width: 16,
    height: 16,
    position: 'relative',
    top: 3,
  },
}));

interface SmallAddButtonProps {
  onClick: MouseEventHandler;
  isGradiented?: boolean;
  isNewDesign?: boolean; //temporary while new designs aren't implemeted
  disabled?: boolean;
  testId?: string;
}

const SmallAddButton = ({ onClick, isGradiented, isNewDesign, disabled, testId }: SmallAddButtonProps) => {
  const classes = useStyles();
  return (
    <>
      {isGradiented ? (
        <StyledIconButton disabled={disabled} size="small" onClick={onClick} data-testid={testId}>
          <GradientBorder className={disabled ? classes.disabledBtn : ''}>
            <Iconography iconName="add" htmlColor={disabled ? '#BBC9DF' : 'secondary'} />
          </GradientBorder>
        </StyledIconButton>
      ) : isNewDesign ? (
        <Button variant="outlined" size="small" disabled={disabled} onClick={onClick}>
          <Iconography iconName="add" className={classes.plusIcon} />
        </Button>
      ) : (
        <StyledIconButton size="small" onClick={onClick}>
          <WhiteBorder>
            <Iconography iconName="add" htmlColor="white" />
          </WhiteBorder>
        </StyledIconButton>
      )}
    </>
  );
};

export default SmallAddButton;
