import { Box, FormControlLabel } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';

import Button from 'src/components/Button';

export const ProductItemsList = styled(Box)`
  margin: 15px 24px 0 14px;

  .infinite-scroll-component {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    grid-column-gap: 24px;
    grid-row-gap: 25px;
    padding: 10px;
  }
`;

export const ButtonWithMargin = styled(Button)`
  margin-left: 15px;
`;

export const Container = styled(Box)`
  display: flex;
  align-items: center;
`;

export const SideContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-height: 288px;
  min-width: 232px;
  margin-left: 88px;
`;

export const Checklick = styled('img')`
  position: absolute;
  top: 65px;
  left: 86px;
`;

export const Edit = styled('img')`
  position: absolute;
  top: 72px;
  left: 77px;
`;

export const StyledButton = styled(Button)`
  width: 204px;
`;

export const PreviewContainer = styled(Box)`
  overflow-y: auto;
`;

export const StyledFiltersWrapper = styled(Box)`
  display: flex;
  max-width: 320px;
  width: 100%;
  flex-direction: column;
  z-index: 1;
  position: relative;
  height: 100vh;
`;

export const ProductItemsWrapper = styled(Box)`
  overflow-y: auto;
  width: 100%;
  scrollbar-width: thin;
`;

export const ItemDetailsWrapper = styled(Box)`
  min-width: 560px;
`;

export const ItemDetailsContentWrapper = styled(Box)`
  padding: 0 56px 0 8px;
`;

export const ItemDetailsBodyWrapper = styled(Box)`
  padding: 0;
  margin: 0 auto;
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, 418px);
  grid-column-gap: 24px;
  // 418 + 24 + 418
  max-width: 860px;
`;

export const ItemDetailsSectorTitleWrapper = styled(Box)`
  padding: 5px 0;
  grid-column: 1 / -1;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const StyledCheckbox = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    font-weight: 500;
    font-size: 15px;

    svg {
      font-size: 15px;
    }
  }
  .Mui-checked {
    margin-right: 5px;
    color: ${({ theme }) => theme.palette.secondary.dark};
  }
  .Mui-disabled {
    color: ${({ theme }) => theme.palette.text.primary};
  }
`;

export const StyledDeleteButton = styled(Box)`
  cursor: pointer;
`;

export const StyledImg = styled('img')`
  margin-right: 11px;
  margin-top: 2px;
`;
