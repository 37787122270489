import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@material-ui/core';
import MuiCard, { CardProps } from '@material-ui/core/Card';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';

import { ReactComponent as EbayBackground } from 'src/components/Images/EbayBackground.svg';
import { ReactComponent as FacebookAndInstagramBackground } from 'src/components/Images/FacebookAndInstagramBg.svg';
import { ReactComponent as ShopifyBackground } from 'src/components/Images/ShopifyBackground.svg';
import { ReactComponent as SmallWooCommerceBackground } from 'src/components/Images/SmallWooCommerceBackground.svg';
import { IntegrationTypes } from 'src/utils/gql';

import BetaLabel from '../BetaLabel';
import Hint from '../Hint';
import Iconography from '../Iconography';
import RadioButton from '../RadioButton';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '323px',
    height: '94px',
    boxShadow: '0 2px 10px rgba(46, 96, 170, 0.25)',
    borderColor: theme.palette.text.disabled,
    borderRadius: '20px',
    background: 'none',
    marginBottom: '15px',
    position: 'relative',
  },
  integrationBgImage: {
    position: 'absolute',
    left: 0,
    bottom: 0,
  },
  bgOpacity: {
    opacity: 0.1,
  },
  containerText: {
    display: 'flex',
    height: '100%',
    justifyContent: 'space-between',
    padding: '25px 30px 25px 25px',
  },
  error: {
    position: 'absolute',
    top: 37,
    right: 26,
    padding: 0,
  },
  errorLink: {
    cursor: 'pointer',
    marginLeft: '2px',
    textDecoration: 'underline',
  },
}));

interface SmallIntegrationCardProps extends CardProps {
  name: string;
  type: string;
  url: string;
  isSelected: boolean;
  onClick: () => void;
  testId?: string;
  hasError?: boolean;
  onErrorLinkClick?: () => void;
}

function SmallIntegrationCard({
  isSelected,
  onClick,
  name,
  type,
  url,
  testId,
  hasError,
  onErrorLinkClick,
}: SmallIntegrationCardProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  const getCardBackground = (type: string) => {
    switch (type) {
      case IntegrationTypes.Shopify:
        return <ShopifyBackground className={classes.integrationBgImage} />;

      case IntegrationTypes.Woocommerce:
        return <SmallWooCommerceBackground className={classes.integrationBgImage} />;

      case IntegrationTypes.Ebay:
        return <EbayBackground className={clsx(classes.integrationBgImage, classes.bgOpacity)} />;

      case IntegrationTypes.Facebook:
        return <FacebookAndInstagramBackground className={clsx(classes.integrationBgImage)} />;
    }
  };

  const handleClick = () => {
    !hasError && onClick();
  };

  const errorHintTitle = (
    <Typography component="span" variant="inherit" color="inherit">
      {t('cataloguesPage.chooseExportPopup.errorHint')}
      <Typography
        onClick={onErrorLinkClick}
        className={classes.errorLink}
        component="span"
        variant="inherit"
        color="inherit"
      >
        {t('cataloguesPage.chooseExportPopup.errorHintLink')}
      </Typography>
    </Typography>
  );

  return (
    <MuiCard className={classes.root} onClick={handleClick}>
      <Box className={classes.containerText}>
        <Box display="flex" flexDirection="column" justifyContent="center" maxWidth="100%">
          <Box display="flex" justifyContent="left" alignItems="center">
            <Typography
              textAlign="left"
              variant="subtitle2"
              mr="5px"
              textOverflow="ellipsis"
              overflow="hidden"
              whiteSpace="nowrap"
              maxWidth="245px"
            >
              {name}
            </Typography>

            {type === IntegrationTypes.Ebay && <BetaLabel />}
          </Box>

          {url && (
            <Typography variant="body2" color="text.secondary" mt="10px" textAlign="left">
              {url}
            </Typography>
          )}
        </Box>

        {hasError ? (
          <Hint type="hover" title={errorHintTitle} tooltipWidth="168px" placement="left">
            <span className={classes.error}>
              <Iconography iconName="cancel-circle" color="error" data-testid="errorIcon" />
            </span>
          </Hint>
        ) : (
          <RadioButton
            checked={isSelected}
            data-testid={testId}
            inputProps={{ 'data-testid': `radioButton${name}` } as React.InputHTMLAttributes<HTMLInputElement>}
          />
        )}
      </Box>

      <Box display="flex" height="87px" width="123">
        {getCardBackground(type)}
      </Box>
    </MuiCard>
  );
}

export default SmallIntegrationCard;
