import { SelectOptionItem } from 'src/components/Select';
import { ChangedIntegrationsProps } from 'src/components/SideBar/MappingSidebar/context/ItemMappingProvider';
import { Integration, Maybe, ProductItemMapping, ProductVariant } from 'src/utils/gql/types';

export enum ChangeAction {
  ADD,
  REMOVE,
}

export type ItemMappingContextType = {
  integrations?: Maybe<Integration[]>;
  itemMappings: ProductItemMapping[];
  activeIntegrationId: string;
  initialLoading: boolean;
  loadingCreateMapping: boolean;
  loadingCreateMappingByShopifyExternalId: boolean;
  loadingSyncItem: boolean;
  ebaySiteIds: SelectOptionItem[];
  changedIntegrations: ChangedIntegrationsProps[];
  hasChanges: boolean;
  onCreateMappingByUrl: (
    url: string,
    integrationId: string,
    siteId?: string,
  ) => Promise<Maybe<ProductVariant[]> | undefined>;
  onCreateMappingByExternalId: (integrationId: string, metadataExternalId: string) => void;
  onOpenDetachPopup: (mappingId: string) => void;
  onSyncProductItem: (integrationId: string) => void;
  onRefetchItemMappings: () => void;
  onResetSyncLoading: () => void;
  onChangeIntegrationInput: ({ integrationId, label }: ChangedIntegrationsProps, action: ChangeAction) => void;
};
