import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Autocomplete } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { v4 as uuidv4 } from 'uuid';

import { BOOLEAN_FIELD_SELECT_OPTIONS } from 'src/constants';
import { addIconToEndAdornment } from 'src/utils/addIconToEndAdornment';

import Hint from './Hint';
import Info from './Icon/info.svg';
import Iconography from './Iconography';
import { AbstractFieldTypeInputProps } from './MultiTypeInput';
import { defaultSelectValue, Input, StyledPopper } from './Select';

const useStyles = makeStyles(() => ({
  defaultValueFieldHint: {
    marginRight: '10px',
    marginLeft: '5px',
  },
  endAndormentWrapper: {
    '& .MuiAutocomplete-endAdornment': {
      display: 'flex',
      right: '9px',
      alignItems: 'center',
    },
  },
  hintWrapper: {
    display: 'flex',
    width: '20px',
    height: '20px',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

interface BooleanSelectProps extends Omit<AbstractFieldTypeInputProps, 'shouldBeFilled' | 'inputIdentifier'> {
  isDefaultFieldValue?: boolean;
}

const BooleanSelect = ({
  label,
  value,
  onChange,
  isDefaultFieldValue,
  error,
  helperText,
  disabled,
  autoFocus,
  ...props
}: BooleanSelectProps) => {
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const getOptionFromValue = (value: string) =>
    value
      ? {
          label: t(`multiTypeInputLabels.booleanOptionsLabels.${value}`),
          value,
        }
      : {
          label: '',
          value: '',
        };
  // TODO : check, how to process empty autocomplete values correctly

  const defaultFieldHintText = t('fieldSettings.defaultFieldHint');

  const hint = (
    <Hint
      key={uuidv4()}
      className={classes.hintWrapper}
      placement="bottom"
      isDefaultFieldValueHint
      type="hover"
      title={defaultFieldHintText}
    >
      <img className={classes.defaultValueFieldHint} src={Info} alt="info" data-testid="infoIconBooleanSelect" />
    </Hint>
  );

  return (
    <Autocomplete
      className={classes.endAndormentWrapper}
      data-testid="boolean-select"
      disablePortal
      disableClearable={!value}
      disabled={disabled}
      PopperComponent={StyledPopper}
      popupIcon={
        <Iconography
          iconName="expand-chevron"
          htmlColor={(isActive || isFocused) && !disabled ? 'secondary' : theme.palette.text.disabled}
          data-testid={`expandChevronIcon${label}`}
        />
      }
      clearIcon={
        <Iconography
          iconName="cancel"
          fontSize="small"
          htmlColor="secondary"
          data-testid={`clearIconBooleanSelect${label}`}
        />
      }
      value={getOptionFromValue(value)}
      options={[defaultSelectValue, ...BOOLEAN_FIELD_SELECT_OPTIONS]}
      getOptionSelected={(option, { value }) => option.value === value}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      onMouseEnter={() => setIsActive(true)}
      onMouseLeave={() => setIsActive(false)}
      onChange={(_, newValue) => onChange?.(newValue?.value || '')}
      renderInput={(params) => (
        <Input
          {...params}
          InputProps={{
            ...params.InputProps,
            readOnly: true,
            className: clsx(!isDefaultFieldValue && params.InputProps.className),
            endAdornment: isDefaultFieldValue
              ? addIconToEndAdornment(params.InputProps.endAdornment, hint)
              : params.InputProps.endAdornment,
          }}
          autoFocus={autoFocus}
          label={label}
          error={error}
          helperText={helperText}
          inputProps={{ ...params.inputProps, 'data-testid': `booleanField${value}`, 'aria-label': label }}
        />
      )}
      renderOption={(props, { label }) => {
        if (!label) {
          return null;
        }

        return (
          <li key={label} {...props} data-testid={`${label}Option`}>
            <span>{label}</span>
          </li>
        );
      }}
      {...props}
    />
  );
};

export default BooleanSelect;
