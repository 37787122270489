import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ApolloError, ApolloQueryResult, useApolloClient, useMutation, useQuery } from '@apollo/client';
import { Box, Typography } from '@material-ui/core';

import IntegrationCard from 'src/components/Card/IntegrationCard';
import Loader from 'src/components/Loader';
import IntegrationsPopup from 'src/components/Popups/IntegrationPopup';
import { clearIntegrationsCache } from 'src/components/Popups/IntegrationPopup/functions';
import {
  DeletePopupState,
  initialDeletePopupState,
  initialPopupState,
  IntegrationsPopupState,
} from 'src/components/Popups/IntegrationPopup/types';
import {
  EbayDefaultSettings,
  FacebookDefaultSettings,
  ShopifyDefaultSettings,
  WooCommerceDefaultSettings,
} from 'src/constants';
import { IntegrationCardsData } from 'src/constants/integrationsConstant';
import useEbayIntegrationCreationInterruption from 'src/hooks/useEbayIntegrationCreationInterruption';
import { useCatalogs } from 'src/providers/catalog';
import { clearLocalCatalogsCache } from 'src/providers/catalog/provider';
import { useSnackbar } from 'src/providers/snackbar';
import { clearCacheFactory } from 'src/utils/cacheUtils';
import {
  changeDefaultIntegration,
  checkIsLimitReached,
  createIntegration,
  deleteIntegration,
  getIntegrations,
  Integration,
  IntegrationTypes,
  LimitType,
  Mutation,
  MutationChangeDefaultIntegrationArgs,
  MutationCreateIntegrationArgs,
  MutationDeleteIntegrationArgs,
  MutationUpdateIntegrationArgs,
  Query,
  QueryGetIntegrationsArgs,
  updateIntegration,
} from 'src/utils/gql';
import { LoaderWrapper } from 'src/views/Catalogs/styled';

import { installIntegrationApp } from '../AppInstalling/utils';

import { InstallingBackdrop, IntegrationsCardsWrapper, IntegrationSecondaryText } from './styled';
import { IntegrationAction, IntegrationItem } from './types';

interface UserIntegrationsProps {
  refetchPlanStats: () => Promise<ApolloQueryResult<Pick<Query, 'planSubscriptionStats'>>>;
}

const createdIntegrationIdLocalStorageKey = 'createdIntegrationId';

const UserIntegrations = ({ refetchPlanStats }: UserIntegrationsProps) => {
  const { cache } = useApolloClient();
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const { deleteCatalogsJobs, catalogsJobsMap } = useCatalogs();

  const [state, setState] = useState<Integration[]>([]);
  const [popupState, setPopupState] = useState<IntegrationsPopupState>(initialPopupState);
  const [deletePopupState, setDeletePopupState] = useState<DeletePopupState>(initialDeletePopupState);
  const [isInstalling, setIsInstalling] = useState<boolean>(false);
  const [refetching, setRefetching] = useState<boolean>(false);
  const { handleEbayIntegrationCreationInterruption, createdIntegration, setCreatedIntegration } =
    useEbayIntegrationCreationInterruption({});

  const { data: checkIsLimitReachedData, refetch: checkIsLimitReachedRefetch } = useQuery(checkIsLimitReached, {
    fetchPolicy: 'cache-first',
    variables: { type: LimitType.Integrations },
  });

  const {
    data,
    loading: getIntegrationsLoading,
    refetch,
  } = useQuery<Pick<Query, 'getIntegrations'>, QueryGetIntegrationsArgs>(getIntegrations, {
    fetchPolicy: 'cache-first',
    variables: {
      type: '',
    },
  });

  const refetchIntegrations = async () => {
    setRefetching(true);
    await refetch();
    setRefetching(false);
  };

  const [addIntegration, { loading: loadingAddIntegration }] = useMutation<
    Pick<Mutation, 'createIntegration'>,
    MutationCreateIntegrationArgs
  >(createIntegration, {
    update: clearIntegrationsCache,
  });

  const [updateIntegrationById, { loading: loadingUpdateIntegration }] = useMutation<
    Pick<Mutation, 'updateIntegration'>,
    MutationUpdateIntegrationArgs
  >(updateIntegration);

  const [deleteIntegrationById, { loading: loadingDeleteIntegration }] = useMutation<
    Pick<Mutation, 'deleteIntegration'>,
    MutationDeleteIntegrationArgs
  >(deleteIntegration);

  const [changeDefaultIntegrationById, { loading: loadingChangeDefaultIntegration }] = useMutation<
    Pick<Mutation, 'changeDefaultIntegration'>,
    MutationChangeDefaultIntegrationArgs
  >(changeDefaultIntegration);

  const handleCloseIntegrationPopup = async (withRefetchAndCacheUpdating?: boolean) => {
    await handleEbayIntegrationCreationInterruption({ withRefetchAndCacheUpdating });
    setPopupState({ ...popupState, isOpen: false });
  };

  const isLimit = checkIsLimitReachedData?.checkIsLimitReached;
  const integrations = data?.getIntegrations;

  const handleDeleteIntegration = async (id: string) => {
    setDeletePopupState((prevState) => ({ ...prevState, buttonLoading: true }));

    try {
      await deleteIntegrationById({
        variables: { id },
        update: clearCacheFactory({ fieldNames: ['getIntegrations', 'checkIsLimitReached'] }),
        refetchQueries: ['planSubscriptionStats'],
      });

      deleteCatalogsJobs({ integrationId: id });

      await Promise.all([checkIsLimitReachedRefetch(), refetch()]);

      clearLocalCatalogsCache(cache);

      setDeletePopupState(initialDeletePopupState);

      snackbar(t('userSettingsPage.integrations.snackbar.deleted'), 'success');
    } catch (error) {
      const { graphQLErrors, message: errorText } = error as ApolloError;
      const message = graphQLErrors && graphQLErrors.length ? graphQLErrors[0].message : errorText;

      if (error) {
        snackbar(message);
      }
    }
  };

  const handleAddEditIntegration = async (integration: IntegrationItem, action: number) => {
    const {
      id,
      type,
      settings: {
        integrationName,
        shop,
        url,
        apiKey,
        apiSecret,
        redirectUrl,
        clientId,
        clientSecret,
        marketplaceId,
        paymentPolicyId,
        returnPolicyId,
        fulfillmentPolicyId,
        merchantLocationKey,
      },
      isDefault,
    } = integration;

    if (action === IntegrationAction.Edit || createdIntegration) {
      try {
        await updateIntegrationById({
          variables: {
            data: { id, type, isDefault, settings: integration.settings },
          },
        }).then(async () => {
          if (type === IntegrationTypes.Ebay) {
            localStorage.removeItem(createdIntegrationIdLocalStorageKey);
            setCreatedIntegration(undefined);
            await refetchPlanStats();
          }
        });

        await Promise.all([checkIsLimitReachedRefetch(), refetch()]);
        snackbar(t('userSettingsPage.integrations.snackbar.updated'), 'success');
      } catch (error) {
        if (type === IntegrationTypes.Ebay) {
          await handleCloseIntegrationPopup(true);
        }

        if (error) {
          const { graphQLErrors, message: errorText } = error as ApolloError;
          const message = graphQLErrors && graphQLErrors.length ? graphQLErrors[0].message : errorText;

          snackbar(message);
        }
      }
    } else {
      switch (type) {
        case IntegrationTypes.Woocommerce:
          {
            try {
              await addIntegration({
                variables: {
                  data: {
                    type,
                    isDefault,
                    settings: { integrationName, url, apiKey, apiSecret },
                  },
                },
                refetchQueries: ['planSubscriptionStats'],
              });

              await Promise.all([checkIsLimitReachedRefetch(), refetch()]);

              snackbar(t('userSettingsPage.integrations.snackbar.created'), 'success');
            } catch (error) {
              const { graphQLErrors, message: errorText } = error as ApolloError;
              const message = graphQLErrors && graphQLErrors.length ? graphQLErrors[0].message : errorText;

              if (error) {
                snackbar(message);
              }
            }
          }
          break;

        case IntegrationTypes.Ebay:
        case IntegrationTypes.Shopify:
        case IntegrationTypes.Facebook:
          {
            setIsInstalling(true);

            await installIntegrationApp({
              type,
              settings: {
                shop,
                integrationName,
                isDefault,
                clientSecret,
                clientId,
                redirectUrl,
                marketplaceId,
                paymentPolicyId,
                returnPolicyId,
                fulfillmentPolicyId,
                merchantLocationKey,
              },
              onSuccess: async () => {
                if (type !== IntegrationTypes.Ebay) {
                  await Promise.all([checkIsLimitReachedRefetch(), refetch(), refetchPlanStats()]);
                }
              },
              onComplete: () => {
                setIsInstalling(false);
              },
            });
          }
          break;
      }
    }
  };

  const handleOpenIntegrationPopup = async (
    integrationType: IntegrationTypes,
    action: IntegrationAction,
    integration?: Integration,
  ) => {
    let defaultSettings;

    const setPopupStateAfterCreation = (action: IntegrationAction, integrations: Integration[], id: string) => {
      const newIntegration = integrations.find((integration) => integration.id === id);

      if (newIntegration) {
        if (newIntegration.type === IntegrationTypes.Ebay) {
          setCreatedIntegration(newIntegration);
        }

        setPopupState({
          isOpen: true,
          integrationType: newIntegration.type,
          action,
          data: {
            id: newIntegration.id,
            type: newIntegration.type,
            isDefault: newIntegration.isDefault,
            settings: newIntegration.settings,
          },
        });
      }
    };

    switch (integrationType) {
      case IntegrationTypes.Shopify:
        defaultSettings = ShopifyDefaultSettings;
        break;
      case IntegrationTypes.Woocommerce:
        defaultSettings = WooCommerceDefaultSettings;
        break;
      case IntegrationTypes.Ebay: {
        if (action === IntegrationAction.Add) {
          setIsInstalling(true);

          await installIntegrationApp({
            type: integrationType,
            onSuccess: async (id) => {
              localStorage.setItem(createdIntegrationIdLocalStorageKey, id);

              const { data: refetchedIntegrations } = await refetch();

              setPopupStateAfterCreation(IntegrationAction.Add, refetchedIntegrations.getIntegrations, id);
            },
            onComplete: () => {
              setIsInstalling(false);
            },
          });
          return;
        }
        defaultSettings = EbayDefaultSettings;
        break;
      }

      case IntegrationTypes.Facebook: {
        if (action === IntegrationAction.Add) {
          setIsInstalling(true);

          await installIntegrationApp({
            type: integrationType,
            onSuccess: async (id) => {
              await Promise.all([checkIsLimitReachedRefetch(), refetchPlanStats()]);

              const { data: refetchedIntegrations } = await refetch();

              setPopupStateAfterCreation(IntegrationAction.Edit, refetchedIntegrations.getIntegrations, id);
            },
            onComplete: () => {
              setIsInstalling(false);
            },
          });
          return;
        }
        defaultSettings = FacebookDefaultSettings;
        break;
      }
      default:
        return;
    }

    const data: IntegrationItem = integration || {
      id: '',
      type: integrationType,
      settings: defaultSettings,
      isDefault: false,
    };

    setPopupState({ isOpen: true, integrationType, action, data });
  };

  const handleClickRadioButton = async (id: string) => {
    const prevDefaultIntegrationId = state.find(({ isDefault }) => isDefault)?.id;
    if (prevDefaultIntegrationId !== id) {
      try {
        await changeDefaultIntegrationById({
          variables: {
            id,
          },
        });

        await Promise.all([checkIsLimitReachedRefetch(), refetch()]);

        snackbar(t('userSettingsPage.integrations.snackbar.changed'), 'success');
      } catch (error) {
        const { graphQLErrors, message: errorText } = error as ApolloError;
        const message = graphQLErrors && graphQLErrors.length ? graphQLErrors[0].message : errorText;

        if (error) {
          snackbar(message);
        }
      }
    }
  };

  useEffect(() => {
    if (integrations) {
      setState(integrations);
    }

    return () => {
      setState([]);
    };
  }, [integrations]);

  return (
    <>
      {getIntegrationsLoading ? (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      ) : (
        <Box display="flex">
          <Box width="100%" maxWidth="264px" minWidth="124px" mr="24px">
            <Typography variant="subtitle1">{t('userSettingsPage.labels.integrations')}</Typography>
            <IntegrationSecondaryText color="text.secondary" my="10px">
              {t('userSettingsPage.integrations.description')}
            </IntegrationSecondaryText>
          </Box>
          <IntegrationsCardsWrapper>
            {IntegrationCardsData.map(({ type, addNewIntegrationButton }) => {
              const integrations = state.filter(({ type: currentType }) => type === currentType);

              return (
                <Box key={type}>
                  <IntegrationCard
                    refetchIntegrationsLoading={refetching}
                    refetchIntegrations={refetchIntegrations}
                    editableCard
                    type={type}
                    integrations={integrations}
                    onDeleteButtonClick={handleDeleteIntegration}
                    onAddEditButtonClick={handleOpenIntegrationPopup}
                    onClickRadioButton={handleClickRadioButton}
                    loadingDeleteIntegration={loadingDeleteIntegration}
                    deletePopupState={deletePopupState}
                    setDeletePopupState={setDeletePopupState}
                    loadingChangeDefaultIntegration={loadingChangeDefaultIntegration}
                    isLimit={!!isLimit}
                    addNewIntegrationButton={addNewIntegrationButton}
                    catalogsJobs={catalogsJobsMap}
                  />
                </Box>
              );
            })}
          </IntegrationsCardsWrapper>
        </Box>
      )}

      <IntegrationsPopup
        popupState={popupState}
        setPopupState={setPopupState}
        onClosePopup={handleCloseIntegrationPopup}
        onAddEdit={handleAddEditIntegration}
        loading={loadingAddIntegration || loadingUpdateIntegration || isInstalling}
      />

      <InstallingBackdrop open={isInstalling}>
        <Loader size="medium" />
        <Typography mt="20px">{t('userSettingsPage.waiting')}</Typography>
      </InstallingBackdrop>
    </>
  );
};

export default UserIntegrations;
