import { CatalogSubscription, ProductTypesWithCount, ProductItem } from 'src/utils/gql';

export const stub = (): never => {
  throw new Error('You forgot to wrap your component in <SharedCatalogsProvider>.');
};

export interface SharedCatalogInfo {
  catalogId: string;
  catalogName: string;
  catalogOwnerId: string;
  catalogOwnerName: string;
  subscription?: CatalogSubscription;
}

export type SharedCatalogsContextType = {
  sharedCatalogInfo: SharedCatalogInfo;
  sharedCatalogProducts?: ProductTypesWithCount;
  loadingProducts: boolean;
  getProductName: (productTypeId: string) => string;
  getProductItem: (productTypeId: string, productItemId: string) => ProductItem | undefined;
  catalogDataLoading: boolean;
};
