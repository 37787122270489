import React from 'react';

import { Box, Skeleton, TableCell, TableRow } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { keyframes } from '@mui/system';

const opacity = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Container = styled(TableRow)({
  animation: `${opacity} 1s ease`,
  height: '74px',
});

interface SkeletonCatalogSettingsRowProps {
  cardsCount: number;
}

const LoaderContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '74px',
  animation: `${opacity} 1s ease`,
});

export const CatalogSettingsTableLoader = () => (
  <LoaderContainer>
    <Box width="25%" p="16px">
      <Skeleton variant="text" width="80%" height="30px" />
    </Box>

    <Box width="20%" p="16px">
      <Skeleton variant="text" width="80%" height="30px" />
    </Box>

    <Box width="25%" p="16px">
      <Skeleton variant="text" width="80%" height="30px" />
    </Box>

    <Box width="15%" p="16px">
      <Skeleton variant="text" width="80%" height="30px" />
    </Box>

    <Box display="flex" width="15%" justifyContent="center" p="16px">
      <Skeleton variant="text" width="80%" height="30px" />
    </Box>
  </LoaderContainer>
);

const SkeletonCatalogSettingsRow = ({ cardsCount }: SkeletonCatalogSettingsRowProps) => (
  <>
    {new Array(cardsCount).fill('card').map((_, idx) => (
      <Container key={idx}>
        <TableCell>
          <Box display="flex">
            <Skeleton variant="circular" width="30px" height="30px" sx={{ marginRight: '10px' }} />

            <Skeleton variant="text" width="80%" height="30px" />
          </Box>
        </TableCell>
        <TableCell>
          <Skeleton variant="text" width="80%" height="30px" />
        </TableCell>
        <TableCell>
          <Skeleton variant="text" width="80%" height="30px" />
        </TableCell>
        <TableCell>
          <Skeleton variant="text" width="80%" height="30px" />
        </TableCell>
        <TableCell>
          <Box display="flex" width="100%" justifyContent="center">
            <Skeleton variant="text" width="80%" height="30px" />
          </Box>
        </TableCell>
      </Container>
    ))}
  </>
);

export default SkeletonCatalogSettingsRow;
