import React, { useContext, useState } from 'react';

import {
  Box,
  Card,
  CardMedia,
  CardProps,
  CircularProgress,
  CircularProgressProps,
  IconButton,
  Typography,
} from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Error } from '@material-ui/icons';

import Iconography from 'src/components/Iconography';
import { ImagesStateContext } from 'src/views/Catalogs/ProductItems/context';
import { handleDeleteImage, handleDeleteImageFromDatabase } from 'src/views/Catalogs/ProductItems/functions';
import { UploadingFile } from 'src/views/Catalogs/ProductItems/types';

interface ImageCardProps {
  image: UploadingFile;
}

interface ImageCardFromDatabaseProps {
  imageUrl?: string;
}

interface CardExtendedProps extends CardProps {
  uploadPercentage?: boolean;
}

interface CircularProgressExtendedProps extends CircularProgressProps {
  error?: boolean;
  isHover: boolean;
  imageId: string;
}

const StyledImageCard = styled<React.ComponentType<CardExtendedProps>>(Card)`
  position: relative;
  max-width: 104px;
  margin: 0 10px 10px 0;
  box-shadow: none;

  :hover {
    box-shadow: none;
    cursor: default;
    opacity: ${({ uploadPercentage }) => (uploadPercentage ? 1 : 0.7)};
  }

  .MuiCardMedia-root {
    height: 80px;
  }

  button {
    position: absolute;
    color: ${({ theme: { palette } }) => palette.secondary.main};
    top: 3px;
    right: 3px;
    width: 20px;
    height: 20px;
    background: ${({ theme: { palette } }) => palette.common.white};

    :hover {
      background: ${({ theme: { palette } }) => palette.common.white};
    }

    svg {
      width: 16px;
      height: 16px;
    }
  }
`;

const ProgressWrapper = styled(Box)`
  position: relative;
  width: 100%;
  height: 100%;
  display: inline-flex;
  color: ${({ theme: { palette } }) => palette.common.white};
  background: rgba(0, 0, 0, 0.5);

  .MuiCircularProgress-root {
    position: absolute;
    top: 20px;
    left: 31px;
  }

  div {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .MuiSvgIcon-root {
    position: absolute;
    top: 22px;
    left: 33px;
  }
`;

function CircularProgressWithLabel({ error, isHover, imageId, ...props }: CircularProgressExtendedProps) {
  const { value } = props;

  const { imagesState, onReloadImage, setImagesState } = useContext(ImagesStateContext);

  return (
    <>
      {value && (
        <ProgressWrapper>
          {value === 100 && error !== undefined ? (
            <>
              {!error && <Iconography iconName="check-outline" fontSize="large" />}

              {error && !isHover && <Error fontSize="large" />}

              {error && isHover && (
                <>
                  <Box onClick={() => onReloadImage(imageId)}>
                    <Iconography iconName="rotate-left" fontSize="large" sx={{ cursor: 'pointer' }} />
                  </Box>
                  <IconButton size="small" onClick={() => handleDeleteImage(imageId, imagesState, setImagesState)}>
                    <Iconography iconName="cancel" style={{ top: 2, left: 2 }} />
                  </IconButton>
                </>
              )}
            </>
          ) : (
            <>
              <CircularProgress variant="determinate" {...props} color="inherit" />

              <Box>
                <Typography variant="caption" component="div" color="inherit">
                  {`${value}%`}
                </Typography>
              </Box>
            </>
          )}
        </ProgressWrapper>
      )}
    </>
  );
}

export const ImageCard = ({ image }: ImageCardProps) => {
  const { id, imagePreview, uploadPercentage, error } = image;
  const { imagesState, setImagesState } = useContext(ImagesStateContext);

  const [isHover, setIsHover] = useState<boolean>(false);

  return (
    <>
      <StyledImageCard
        uploadPercentage={!!uploadPercentage}
        onMouseLeave={() => setIsHover(false)}
        onMouseEnter={() => setIsHover(true)}
      >
        <CardMedia image={imagePreview} title="image">
          <CircularProgressWithLabel
            variant="determinate"
            value={uploadPercentage}
            error={error}
            imageId={id}
            isHover={isHover}
          />
        </CardMedia>

        {isHover && !uploadPercentage && (
          <IconButton size="small" onClick={() => handleDeleteImage(id, imagesState, setImagesState)}>
            <Iconography iconName="cancel" />
          </IconButton>
        )}
      </StyledImageCard>
    </>
  );
};

export const ImageCardFromDatabase = ({ imageUrl }: ImageCardFromDatabaseProps) => {
  const [isHover, setIsHover] = useState<boolean>(false);
  const { imagesState, setImagesState } = useContext(ImagesStateContext);

  return (
    <>
      <StyledImageCard onMouseLeave={() => setIsHover(false)} onMouseEnter={() => setIsHover(true)}>
        <CardMedia image={imageUrl} title="image" />

        {isHover && (
          <IconButton size="small" onClick={() => handleDeleteImageFromDatabase(imagesState, setImagesState, imageUrl)}>
            <Iconography iconName="cancel" />
          </IconButton>
        )}
      </StyledImageCard>
    </>
  );
};
