import { useEffect } from 'react';

export const useEnterClick = (onPressEnterKey: () => void) => {
  useEffect(() => {
    const eventFunction = (event: KeyboardEvent) => event.key === 'Enter' && onPressEnterKey();
    document.addEventListener('keypress', eventFunction);
    return () => {
      document.removeEventListener('keypress', eventFunction);
    };
  });
};
