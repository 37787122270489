import { MediaDataType } from 'src/components/MediaGallery/constants';
import { SelectOptionItem } from 'src/components/Select';
import { capitalize } from 'src/helpers/capitalize';
import {
  FieldSystemName,
  FieldType,
  MediaType,
  ProductItemValueInput,
  ProductTypeField,
  ProductTypeFilterItem,
  UpdateProductItemInput,
  UpdateProductTypeFieldInput,
  UpdateProductTypeInput,
} from 'src/utils/gql';

export const mockedProductTypeFields = [
  {
    __typename: 'ProductTypeField',
    id: '1',
    name: capitalize(FieldSystemName.Name),
    systemName: FieldSystemName.Name,
    type: FieldType.String,
    required: true,
    defaultValue: null,
    showInFilters: true,
    showInPreview: true,
  },
  {
    __typename: 'ProductTypeField',
    id: '2',
    name: capitalize(FieldSystemName.Price),
    systemName: FieldSystemName.Price,
    type: FieldType.Price,
    required: true,
    defaultValue: null,
    showInFilters: true,
    showInPreview: true,
  },
  {
    __typename: 'ProductTypeField',
    id: '3',
    name: FieldSystemName.Sku.toUpperCase(),
    systemName: FieldSystemName.Sku,
    type: FieldType.String,
    required: false,
    defaultValue: null,
    showInFilters: false,
    showInPreview: true,
  },
  {
    __typename: 'ProductTypeField',
    id: '4',
    name: capitalize(FieldSystemName.Description),
    systemName: FieldSystemName.Description,
    type: FieldType.String,
    required: false,
    defaultValue: null,
    showInFilters: true,
    showInPreview: false,
  },
  {
    __typename: 'ProductTypeField',
    id: '5',
    name: 'Image',
    type: FieldType.String,
    required: false,
    defaultValue: null,
    showInFilters: true,
    showInPreview: true,
  },
  {
    __typename: 'ProductTypeField',
    id: '6',
    name: 'custom',
    type: FieldType.String,
    required: false,
    defaultValue: null,
    showInFilters: true,
    showInPreview: false,
  },
];

export interface FilterItemWithNewValues extends ProductTypeFilterItem {
  newValues: (string | null)[];
  newEmptyValues: boolean;
}

export enum ShopifySupportedFields {
  WeightUnit = 'weightUnit',
  Weight = 'weight',
  Taxable = 'taxable',
  TaxCode = 'taxCode',
  Sku = 'sku',
  CompareAtPrice = 'compareAtPrice',
  Barcode = 'barcode',
  Vendor = 'vendor',
  Status = 'status',
}

export const UNSELECT_ITEMS_TIMEOUT = 700;

export enum FieldsNames {
  Image = 'Image',
}

export interface FilterState {
  selectedProductType?: SelectOptionItem;
  filterItemsMap: { [fieldId: string]: FilterItemWithNewValues };
}

export const MEDIA_SIZE_LIMIT = 10_000_000;
export const MEDIA_COUNT_LIMIT = 10;

export const AVAILABLE_IMAGE_FORMATS = ['.jpg', '.png', '.gif', '.jpeg', '.ico', '.webp', '.svg'];
export const AVAILABLE_VIDEO_FORMATS = ['.mp4', '.mov', '.avi', '.mpg', '.ogv', '.3gp', '.3g2'];
export const AVAILABLE_AUDIO_FORMATS = ['.mp3', '.m4a', '.ogg', '.wav'];
export const AVAILABLE_DOCUMENT_FORMATS = ['.pdf', '.doc', '.ppt', '.odt', '.xls', '.psd', '.docx', '.xlsx', '.pptx'];
export const AVAILABLE_MODELS3D_EXTENSIONS = ['.glb', '.usdz'];

export interface UploadingMedia {
  id: string;
  mediaUsageId?: string;
  uuid: string;
  type: MediaType;
  url?: string;
  name?: string;
  file?: File;
  expansion?: string;
  previewUrl?: string;
  createdAt?: string;
  typeAddedData?: MediaDataType;
  isChangedAltText?: boolean;
  altText: string;
  canBeReset?: boolean;
  orderNumber?: number;
  mediaId?: string;
}

export interface UploadingFile {
  file?: File;
  id: string;
  name?: string;
  imagePreview?: string;
  uploadPercentage?: number;
  error?: boolean;
  type?: string;
  orderNumber?: number;
}

export interface Fields extends ProductTypeField {
  value: string;
  error?: boolean;
}

export interface ProductTypeState extends UpdateProductTypeInput {
  fields: Fields[];
}

export interface Field extends UpdateProductTypeFieldInput {
  id: string;
  error?: boolean;
}

export interface Value extends ProductItemValueInput {
  productTypeField: Field;
}

export interface ProductItemState extends UpdateProductItemInput {
  values: Value[];
}

export interface ImagesState {
  imagesForPreview: UploadingFile[];
  imagesForUploading: UploadingFile[];
  loadedImagesUrls: string[];
  imagesUrlsFromDatabase?: string[];
  imageFromDatabaseWillBeDeleted?: boolean;
}

export interface MediaOrdersBeforeUpdate {
  uuid: string;
  orderNumber: number;
}

export interface MediaInput {
  mediaId?: string;
  previewUrl?: string;
  type?: MediaType;
  url?: string;
  orderNumber?: number;
  mediaUsageId?: string;
}
