import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Box, makeStyles, Theme, Typography } from '@material-ui/core';

import { FIRST_DASHBOARD_PATHNAME, USER_SETTINGS } from 'src/constants/routeSources';

import Close from './Images/Close.svg';

const useStyles = makeStyles<Theme, { show: boolean; isDemoShown: boolean }>((theme) => ({
  box: {
    display: ({ show }) => (show ? 'flex' : 'none'),
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '76px',
    background: 'linear-gradient(90deg, #FFA399 0%, #FFC18E 100%)',
    borderRadius: '5px',
    marginTop: '10px',
    position: 'absolute',
    top: ({ isDemoShown }) => (isDemoShown ? '86px' : '0'),
  },

  closeButton: {
    position: 'absolute',
    right: '56px',
    width: '34px',
    height: '34px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    borderRadius: '50%',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: `0 0 15px ${theme.palette.secondary.main}`,
    },
    '&:active': {
      boxShadow: 'none',
      outline: 'none',
    },
  },
  typographyLink: {
    fontSize: '14px',
    borderBottom: '1px solid',
    textDecoration: 'none',
    display: 'inline-block',
    lineHeight: '10px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  typography: {
    fontSize: '14px',
    lineHeight: '16px',
    color: theme.palette.primary.light,
  },
}));

interface SubscriptionExpiredBannerProps {
  show: boolean;
  isDemoShown: boolean;
  handleClose: () => void;
}

const SubscriptionExpiredBanner = ({ show, isDemoShown, handleClose }: SubscriptionExpiredBannerProps) => {
  const classes = useStyles({ show, isDemoShown });
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Box className={classes.box}>
      <Box onClick={handleClose} className={classes.closeButton}>
        <img src={Close} alt="close" />
      </Box>
      <Typography className={classes.typography}>
        {t('subscriptionExpiredBanner.firstPart')}
        <Typography
          component="span"
          onClick={() => history.push(`/${FIRST_DASHBOARD_PATHNAME}/${USER_SETTINGS}`)}
          className={classes.typographyLink}
        >
          {t('subscriptionExpiredBanner.secondPart')}
        </Typography>
        &nbsp;
        {t('subscriptionExpiredBanner.thirdPart')}
      </Typography>
    </Box>
  );
};

export default SubscriptionExpiredBanner;
