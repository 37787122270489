import React, { ReactElement } from 'react';

import { useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MuiSvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import clsx from 'clsx';

import GradientIconographySprite from 'src/components/Iconography/gradient-iconography.svg';
import IconographySprite from 'src/components/Iconography/iconography.svg';

interface IconographyProps extends SvgIconProps {
  iconName?: string;
}

export const useStyles = makeStyles(() => ({
  root: {
    width: '24px',
    height: '24px',
    fontSize: '24px',
  },
}));

const gradientBackgroundUrl = 'url(#paint0_linear)';

function Iconography({ iconName, ...props }: IconographyProps) {
  const theme = useTheme();
  const { root } = useStyles();

  return (
    <>
      {props.htmlColor === 'secondary' ? (
        <MuiSvgIcon {...props} className={clsx(!props.fontSize && root, props.className)}>
          {iconName && (
            <svg>
              <defs>
                <linearGradient id="paint13_linear" gradientUnits="userSpaceOnUse">
                  <stop stopColor={theme.palette.secondary.dark} stopOpacity={1} />
                  <stop offset="1" stopColor={theme.palette.secondary.dark} stopOpacity={1} />
                </linearGradient>
              </defs>
              <use href={`${GradientIconographySprite}#${iconName}`} fill="#F4794D" />
              <use href={`${GradientIconographySprite}#${iconName}`} fill="url(#paint13_linear)" />
            </svg>
          )}
          {!iconName && props.children && React.isValidElement(props.children) && (
            <>
              {React.cloneElement(props.children as ReactElement, { style: { fill: gradientBackgroundUrl } })}
              <defs>
                <linearGradient id="paint0_linear" gradientUnits="userSpaceOnUse">
                  <stop stopColor={theme.palette.secondary.dark} />
                  <stop offset="1" stopColor={theme.palette.secondary.dark} />
                </linearGradient>
              </defs>
            </>
          )}
        </MuiSvgIcon>
      ) : (
        <MuiSvgIcon {...props} className={clsx(!props.fontSize && root, props.className)}>
          {!!iconName?.length && <use href={`${IconographySprite}#${iconName}`} />}
          {!iconName && props.children && <>{props.children}</>}
        </MuiSvgIcon>
      )}
    </>
  );
}

export default Iconography;
