import React from 'react';

import { DatePicker } from '@mui/lab';
import { ThemeProvider } from '@mui/material';

import { datePickerTheme } from './DatePickerTheme';
import { StyledDatePickerField } from './styled';
import { SingleDatePickerProps } from './types';

const SingleDatePicker = ({
  onSelectDate,
  label,
  value,
  minDate,
  disabled,
  hasError,
  helperText,
}: SingleDatePickerProps) => {
  const DATE_FORMAT = 'DD/MM/yyyy';

  return (
    <ThemeProvider theme={datePickerTheme}>
      <DatePicker
        value={value}
        onChange={onSelectDate}
        label={label}
        disabled={disabled}
        inputFormat={DATE_FORMAT}
        minDate={minDate}
        renderInput={(params) => (
          <StyledDatePickerField
            fullWidth
            {...params}
            error={hasError}
            helperText={helperText}
            data-testid="singleDatePicker"
            inputProps={{ ...params.inputProps, 'data-testid': 'inputSingleDatePicker' }}
          />
        )}
      />
    </ThemeProvider>
  );
};

export default SingleDatePicker;
