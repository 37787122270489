import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@material-ui/core';

interface BooleanToStringBoxProps {
  fieldValue: string;
}

const BooleanToStringBox = ({ fieldValue }: BooleanToStringBoxProps) => {
  const { t } = useTranslation();

  return <Box>{t(fieldValue === 'true' ? 'yes' : 'no')}</Box>;
};

export default BooleanToStringBox;
