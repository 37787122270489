import { Box, Dialog } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';

export const CustomDialog = styled(Dialog)`
  min-width: 912px;
  min-height: 546px;
  & .MuiDialog-paper {
    overflow-y: unset;
  }
  & .MuiBackdrop-root {
    background-color: ${({ theme }) => theme.palette.background.default};
    opacity: 0.7 !important;
  }
  & .MuiPaper-root {
    box-shadow: 0 2px 10px rgba(46, 96, 170, 0.25);
  }
`;

export const ItemPreviewCarouselIndicator = styled(Box)`
  position: relative;
  width: 90%;
  border-radius: 5px;
  height: 3px;
  margin: 0 auto;
  top: 5px;
`;

export const ItemPreviewCarouselImageWrapper = styled(Box)`
  position: absolute;
  width: 100%;
  height: 100%;
`;
