import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { Maybe } from 'graphql/jsutils/Maybe';

import DeletePopup from 'src/components/Popups/DeletePopup';
import { useCheckCatalogMappingErrors } from 'src/hooks/graphQLRequestsHooks';
import useCustomEvent, { CustomEventName } from 'src/hooks/useCustomEvent';
import { useCatalogs } from 'src/providers/catalog';
import { useSnackbar } from 'src/providers/snackbar';
import {
  createProductItemMapping,
  createProductItemMappingByExternalId,
  deleteProductItemIntegrationMapping,
  exportProductItemToIntegration,
  getEbaySiteIds,
  getProductItemMappings,
  Integration,
  IntegrationTypes,
  Mutation,
  MutationCreateItemMappingByExternalIdArgs,
  MutationCreateItemMappingFromIntegrationArgs,
  MutationDeleteProductItemIntegrationMappingArgs,
  MutationExportProductItemToIntegrationArgs,
  ProductItemMapping,
  Query,
  QueryProductItemIntegrationMappingsArgs,
} from 'src/utils/gql';

import { modifyCacheOnCreateItemMapping, modifyCacheOnDetachItemMapping } from './functions';
import { ChangeAction, ItemMappingContextType } from './types';

const stub = (): never => {
  throw new Error('You forgot to wrap your component in <ItemMappingProvider>.');
};

const defaultValue: ItemMappingContextType = {
  integrations: [],
  itemMappings: [],
  ebaySiteIds: [],
  changedIntegrations: [],
  activeIntegrationId: '',
  initialLoading: true,
  loadingCreateMapping: false,
  loadingCreateMappingByShopifyExternalId: false,
  loadingSyncItem: false,
  hasChanges: false,
  onCreateMappingByUrl: stub,
  onCreateMappingByExternalId: stub,
  onOpenDetachPopup: stub,
  onSyncProductItem: stub,
  onRefetchItemMappings: stub,
  onResetSyncLoading: stub,
  onChangeIntegrationInput: stub,
};

export const ItemMappingContext = createContext(defaultValue);

ItemMappingContext.displayName = 'ItemMappingContext';

interface ItemMappingProviderProps {
  children: ReactNode;
  integrations?: Maybe<Integration[]>;
  getIntegrationsLoading: boolean;
  isTesting?: boolean;
}

export interface ChangedIntegrationsProps {
  integrationId: string;
  label?: string;
}

export const ItemMappingProvider = ({
  children,
  integrations,
  getIntegrationsLoading,
  isTesting,
}: ItemMappingProviderProps) => {
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const { dispatchCustomEvent } = useCustomEvent();
  const { updateCurrentExport } = useCatalogs();
  const { productItemId, catalogId } = useParams<{ productItemId: string; catalogId: string }>();

  const initialDetachPopupState = { isOpen: false, mappingId: '' };

  const [loadingSyncItem, setLoadingSyncItem] = useState<boolean>(false);
  const [activeIntegrationId, setActiveIntegrationId] = useState<string>('');
  const [itemMappings, setItemMappings] = useState<ProductItemMapping[]>([]);
  const [detachPopupState, setDetachPopupState] = useState<{ isOpen: boolean; mappingId: string }>(
    initialDetachPopupState,
  );
  const [changedIntegrations, setChangedIntegrations] = useState<ChangedIntegrationsProps[]>([]);

  const { checkCatalogMappingsErrorsQuery } = useCheckCatalogMappingErrors(catalogId);

  const hasIntegrations = !!integrations?.length;
  const hasChanges = !!changedIntegrations.length;

  const {
    data: mappings,
    loading: getMappingsLoading,
    refetch: refetchItemMappings,
  } = useQuery<Pick<Query, 'productItemIntegrationMappings'>, QueryProductItemIntegrationMappingsArgs>(
    getProductItemMappings,
    {
      fetchPolicy: isTesting ? undefined : 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      variables: { data: { productItemId, withExternalProductInfo: true } },
      skip: !hasIntegrations,
    },
  );

  const [createMappingByUrl, { loading: loadingCreateMapping }] = useMutation<
    Pick<Mutation, 'createItemMappingFromIntegration'>,
    MutationCreateItemMappingFromIntegrationArgs
  >(createProductItemMapping, {
    update(cache, { data }) {
      if (data?.createItemMappingFromIntegration.createdMapping) {
        modifyCacheOnCreateItemMapping(cache, data.createItemMappingFromIntegration.createdMapping);
      }
    },
  });

  const [createMappingByShopifyExternalId, { loading: loadingCreateMappingByShopifyExternalId }] = useMutation<
    Pick<Mutation, 'createItemMappingByExternalId'>,
    MutationCreateItemMappingByExternalIdArgs
  >(createProductItemMappingByExternalId, {
    update(cache, { data }) {
      if (data?.createItemMappingByExternalId) {
        modifyCacheOnCreateItemMapping(cache, data.createItemMappingByExternalId);
      }
    },
  });

  const [detachMapping, { loading: loadingDetachMapping }] = useMutation<
    Pick<Mutation, 'deleteProductItemIntegrationMapping'>,
    MutationDeleteProductItemIntegrationMappingArgs
  >(deleteProductItemIntegrationMapping, {
    update(cache, { data }) {
      if (data?.deleteProductItemIntegrationMapping) {
        modifyCacheOnDetachItemMapping(cache, data.deleteProductItemIntegrationMapping);
      }
    },
  });

  const [syncProductItem] = useMutation<
    Pick<Mutation, 'exportProductItemToIntegration'>,
    MutationExportProductItemToIntegrationArgs
  >(exportProductItemToIntegration);

  const hasEbayIntegration = integrations?.some((integration) => integration.type === IntegrationTypes.Ebay);
  const { data } = useQuery<Pick<Query, 'getEbaySiteIds'>>(getEbaySiteIds, { skip: !hasEbayIntegration });
  const ebaySiteIds = data?.getEbaySiteIds || [];

  const initialLoading = getIntegrationsLoading || (getMappingsLoading && !activeIntegrationId);

  const handleChangeIntegrationInput = ({ integrationId, label }: ChangedIntegrationsProps, action: ChangeAction) => {
    const integrationInputCallback = (integrationInput: ChangedIntegrationsProps) =>
      (integrationInput.integrationId === integrationId && integrationInput.label !== label) ||
      integrationInput.integrationId !== integrationId;

    setChangedIntegrations((prevState) =>
      action === ChangeAction.ADD
        ? [...prevState.filter(integrationInputCallback), { integrationId, label }]
        : [...prevState.filter(label ? integrationInputCallback : (int) => int.integrationId !== integrationId)],
    );
  };

  const handleOpenDetachPopup = (mappingId: string) => {
    setDetachPopupState({ isOpen: true, mappingId });
  };

  const handleCloseDetachPopup = () => {
    setDetachPopupState(initialDetachPopupState);
  };

  const handleCreateMappingByUrl = async (url: string, integrationId: string, siteId?: string) => {
    setActiveIntegrationId(integrationId);
    let metadata;

    if (siteId) {
      metadata = { siteId };
    }

    try {
      const { data } = await createMappingByUrl({
        variables: { url, integrationId, productItemId, metadata },
      });

      if (data) {
        const { createdMapping, productVariants } = data.createItemMappingFromIntegration;

        if (createdMapping) {
          setItemMappings((prev) => [
            ...prev,
            { ...createdMapping, integration: { id: integrationId } as Integration },
          ]);

          snackbar(t('mappingSidebar.itemMapping.createItemMappingSnackbar'), 'success');
        } else {
          return productVariants;
        }
      }
    } catch (error) {
      const { graphQLErrors, message: errorText } = error as ApolloError;
      const message = graphQLErrors && !!graphQLErrors.length ? graphQLErrors[0].message : errorText;
      if (error) {
        snackbar(message);
      }
    }

    handleChangeIntegrationInput({ integrationId }, ChangeAction.REMOVE);
  };

  const handleCreateMappingByExternalId = async (integrationId: string, metadataExternalId: string) => {
    setActiveIntegrationId(integrationId);

    try {
      const { data } = await createMappingByShopifyExternalId({
        variables: { productItemId, integrationId, metadataExternalId },
      });

      if (data) {
        const createdMapping = data?.createItemMappingByExternalId;

        setItemMappings((prev) => [...prev, { ...createdMapping, integration: { id: integrationId } as Integration }]);

        snackbar(t('mappingSidebar.itemMapping.createItemMappingSnackbar'), 'success');
      }
    } catch (error) {
      const { graphQLErrors, message: errorText } = error as ApolloError;
      const message = graphQLErrors && !!graphQLErrors.length ? graphQLErrors[0].message : errorText;
      if (error) {
        snackbar(message);
      }
    }

    handleChangeIntegrationInput({ integrationId }, ChangeAction.REMOVE);
  };

  const handleDetachMapping = async (id: string) => {
    const resetItemContentState = new CustomEvent('resetState');

    try {
      const { data } = await detachMapping({
        variables: { id },
      });

      if (data) {
        const deletetMappingId = data.deleteProductItemIntegrationMapping;

        setItemMappings((prev) => prev.filter(({ id }) => id !== deletetMappingId));

        document.dispatchEvent(resetItemContentState);

        snackbar(t('mappingSidebar.itemMapping.detachItemMappingSuccess'), 'success');
      }
    } catch (error) {
      const { graphQLErrors, message: errorText } = error as ApolloError;
      const message = graphQLErrors && !!graphQLErrors.length ? graphQLErrors[0].message : errorText;
      if (error) {
        snackbar(message);
      }
    }

    handleCloseDetachPopup();
  };

  const handleRefetchItemMappings = async () => {
    try {
      await refetchItemMappings();

      snackbar(t('mappingSidebar.itemMapping.exportItemSuccess'), 'success');

      setLoadingSyncItem(false);
    } catch (error) {
      setLoadingSyncItem(false);
    }
  };

  const handleSyncProductItem = async (integrationId: string) => {
    setLoadingSyncItem(true);
    setActiveIntegrationId(integrationId);

    if (isTesting) {
      handleRefetchItemMappings();
    } else {
      try {
        updateCurrentExport(catalogId, false);

        await syncProductItem({
          variables: { productItemId, integrationId },
        });
      } catch (error) {
        const { graphQLErrors, message: errorText } = error as ApolloError;
        const message = graphQLErrors && !!graphQLErrors.length ? graphQLErrors[0].message : errorText;
        checkCatalogMappingsErrorsQuery();
        if (error) {
          snackbar(message);
        }

        setLoadingSyncItem(false);
      }
    }
  };

  useEffect(() => {
    if (mappings) {
      setItemMappings(mappings.productItemIntegrationMappings);
    }

    return () => {
      setItemMappings([]);
    };
  }, [mappings]);

  useCustomEvent(CustomEventName.customCloseMappingsSidebar, () =>
    dispatchCustomEvent(CustomEventName.checkItemMappingsChanges, { detail: { hasChanges } }),
  );

  return (
    <ItemMappingContext.Provider
      value={{
        integrations,
        itemMappings,
        activeIntegrationId,
        initialLoading,
        loadingCreateMapping,
        loadingCreateMappingByShopifyExternalId,
        loadingSyncItem,
        ebaySiteIds,
        changedIntegrations,
        hasChanges,
        onCreateMappingByUrl: handleCreateMappingByUrl,
        onCreateMappingByExternalId: handleCreateMappingByExternalId,
        onOpenDetachPopup: handleOpenDetachPopup,
        onSyncProductItem: handleSyncProductItem,
        onRefetchItemMappings: handleRefetchItemMappings,
        onResetSyncLoading: () => setLoadingSyncItem(false),
        onChangeIntegrationInput: handleChangeIntegrationInput,
      }}
    >
      {children}

      <DeletePopup
        open={detachPopupState.isOpen}
        onClose={handleCloseDetachPopup}
        onMainButtonClick={() => handleDetachMapping(detachPopupState.mappingId)}
        onSecondaryButtonClick={handleCloseDetachPopup}
        mainTitle={t('mappingSidebar.itemMapping.detachPopup.title')}
        descriptionText={t('mappingSidebar.itemMapping.detachPopup.description')}
        loadingOnMainButton={loadingDetachMapping}
        mainTitleWidth="276px"
        mainButtonText={t('mappingSidebar.itemMapping.detachPopup.yes')}
        secondaryButtonText={t('mappingSidebar.itemMapping.detachPopup.no')}
      />
    </ItemMappingContext.Provider>
  );
};

const useItemMapping = () => {
  return useContext(ItemMappingContext);
};

export default useItemMapping;
