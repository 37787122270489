import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@material-ui/core';

import { SHARED_CATALOGUES_SUBSCRIPTION_STATUSES } from 'src/constants';
import { CatalogSubscription } from 'src/utils/gql';

import { StyledStatusCell } from './styled';
import { SharedCatalogueSubscription } from './types';

const StatusTableCell = (props: { subscriptions: CatalogSubscription[] }) => {
  const { t } = useTranslation();
  const { subscriptions } = props;
  const [statusState, setStatusState] = useState<SharedCatalogueSubscription>();

  const subscriptionData = subscriptions[0];
  const statusText = subscriptionData ? t(statusState?.statusText as string) : '';

  useEffect(() => {
    subscriptionData && setStatusState(SHARED_CATALOGUES_SUBSCRIPTION_STATUSES[subscriptionData.approvalStatus]);
  }, [subscriptionData]);

  return (
    <StyledStatusCell>
      <Typography color={statusState?.textColor}>{statusText}</Typography>
    </StyledStatusCell>
  );
};

export default StatusTableCell;
