import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';

import { useMutation } from '@apollo/client';

import ShopifySignUpForm from 'src/components/Forms/ShopifySignupForm';
import { ShopifySignupInput } from 'src/components/Forms/types';
import Page from 'src/components/Page';
import { ShopifyUrlParams } from 'src/constants';
import { CATALOGS_PATH, FIRST_DASHBOARD_PATHNAME } from 'src/constants/routeSources';
import { AccountProvider } from 'src/providers/account';
import { useSnackbar } from 'src/providers/snackbar';
import { integrationUserSignUp, Mutation, MutationIntegrationSignUpArgs, CatalogSource } from 'src/utils/gql';
import { getToken } from 'src/utils/localStorage';

import IntegrationScreen from './IntergationScreen';

const ShopifyIntegration = () => {
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const { search } = useLocation<{ search: string }>();
  const history = useHistory();

  const token = getToken();
  const isLoggedIn = !!token;

  const shop = new URLSearchParams(search).get(ShopifyUrlParams.Shop) || '';
  const code = new URLSearchParams(search).get(ShopifyUrlParams.Code) || '';

  const [integrationSignUp, { loading }] = useMutation<
    Pick<Mutation, 'integrationSignUp'>,
    MutationIntegrationSignUpArgs
  >(integrationUserSignUp, {
    onCompleted: ({
      integrationSignUp: {
        user: { email },
      },
    }) => {
      history.replace(
        isLoggedIn ? `/${FIRST_DASHBOARD_PATHNAME}/${CATALOGS_PATH}` : '/connect/shopify/check-email',
        isLoggedIn ? null : { userEmail: email, isIntegration: true },
      );
    },
    onError: (error) => {
      snackbar(error.message);
    },
  });

  const handleSubmit = async (data: ShopifySignupInput) => {
    const values = {
      email: data.email.trim(),
      storeName: data.storeName.trim(),
      integrationType: CatalogSource.Shopify,
      shop: shop.replace(/^https:\/\//, ''),
      code,
    };

    const dataValues = isLoggedIn ? { ...values, token } : values;

    await integrationSignUp({
      variables: {
        data: dataValues,
      },
    });
  };

  const page = (
    <Page title={isLoggedIn ? t('registerPage.createShopifyCatalog') : t('welcome')}>
      <IntegrationScreen isLoggedIn={isLoggedIn}>
        <ShopifySignUpForm onSubmit={handleSubmit} onError={snackbar} loading={loading} isLoggedIn={isLoggedIn} />
      </IntegrationScreen>
    </Page>
  );

  return isLoggedIn ? <AccountProvider>{page}</AccountProvider> : page;
};

export default ShopifyIntegration;
