import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';

import { useMutation } from '@apollo/client';
import { Typography, Link, Box } from '@material-ui/core';

import SignUpForm from 'src/components/Forms/SignupForm';
import GoogleAuth from 'src/components/GoogleAuth/GoogleAuth';
import Page from 'src/components/Page';
import { PAID_PLAN_URL_PARAM, EXTERNAL_URL } from 'src/constants';
import { FIRST_DASHBOARD_PATHNAME } from 'src/constants/routeSources';
import { useSnackbar } from 'src/providers/snackbar';
import {
  RegInput,
  Mutation,
  MutationSignUpArgs,
  userSignUp,
  GoogleLogin,
  userGoogleLogin,
  MutationGoogleLoginArgs,
} from 'src/utils/gql';
import { HistoryState } from 'src/utils/history';
import { setToken } from 'src/utils/localStorage';

import { SHOPIFY_LOGIN } from '../ShopifyIntegration/IntergationScreen';

import RegisterImageBackground from './RegisterImageBackground';
import { RegisterContainer, SideContainer, Image, StyledRegisterContainer } from './styled';

const Register = () => {
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const history = useHistory();
  const location = useLocation<HistoryState>();
  const { search } = useLocation<{ search: string }>();

  const [isImageLoading, setIsImageLoading] = useState(true);

  const [signUp, { loading }] = useMutation<Pick<Mutation, 'signUp'>, MutationSignUpArgs>(userSignUp);
  const [googleLogin, { loading: googleLoading }] = useMutation<Pick<Mutation, 'googleLogin'>, MutationGoogleLoginArgs>(
    userGoogleLogin,
  );

  const isShopifyLogin = location.search.includes(SHOPIFY_LOGIN);
  const paidPlan = new URLSearchParams(search).get(PAID_PLAN_URL_PARAM) || '';
  const shopifyLoginRedirectLink = `/connect/shopify/auth/callback${location.search}`;

  const handleGoogleLogin = useCallback(
    async (data: GoogleLogin) => {
      const { data: googleLoginData } = await googleLogin({ variables: { data } });

      if (googleLoginData) {
        const { token } = googleLoginData.googleLogin;

        setToken(token);

        history.push(
          location.state?.from
            ? location.state.from
            : isShopifyLogin
            ? shopifyLoginRedirectLink
            : `/${FIRST_DASHBOARD_PATHNAME}`,
        );
      }
    },
    [googleLogin, history, location.state, isShopifyLogin, shopifyLoginRedirectLink],
  );

  const signUpAndRedirect = useCallback(
    async (data: RegInput) => {
      const { data: signUpData } = await signUp({ variables: paidPlan ? { data: { ...data, paidPlan } } : { data } });

      if (signUpData) {
        const userEmail = signUpData.signUp.email;
        localStorage.setItem('_email', userEmail);

        history.push({
          pathname: '/auth/check-email',
          state: {
            userEmail,
            isIntegration: false,
          },
        });
      }
    },
    [history, paidPlan, signUp],
  );

  return (
    <Page title={t('pageTitles.CreateAccount')}>
      <StyledRegisterContainer>
        <RegisterContainer>
          <Typography variant="h1" textAlign="center">
            {t('pageTitles.CreateAccount')}
          </Typography>

          <GoogleAuth onSubmit={handleGoogleLogin} onError={snackbar} loading={googleLoading} />

          <SignUpForm onSubmit={signUpAndRedirect} onError={snackbar} loading={loading} />

          <Box maxWidth="330px" textAlign="center" lineHeight="0.8" mt="40px">
            <Typography variant="caption" mt="40px" color="textSecondary">
              {t('registerPage.creatingAccountText')}
              <div>
                <Link
                  href={EXTERNAL_URL.PRIVACY_POLICY}
                  color="secondary"
                  underline="hover"
                  variant="h4"
                  data-testid="linkPrivacyPolicy"
                >
                  {t('registerPage.privacyPolicy')}
                </Link>
                <span> & </span>
                <Link
                  href={EXTERNAL_URL.TERMS_AND_CONDITIONS}
                  color="secondary"
                  underline="hover"
                  variant="h4"
                  data-testid="linkTermsAndConditions"
                >
                  {t('registerPage.termsAndConditionsLinkText')}
                </Link>
              </div>
            </Typography>
          </Box>

          <Typography variant="caption" mt="40px" color="textSecondary">
            {t('registerPage.haveAccountText')}
            <Link
              color="secondary"
              component={RouterLink}
              to="/auth/login"
              underline="hover"
              variant="h4"
              ml="5px"
              data-testid="linkLogIn"
            >
              {t('registerPage.logInText')}
            </Link>
          </Typography>
        </RegisterContainer>
        <SideContainer>
          {isImageLoading && <RegisterImageBackground />}
          <Image
            src={`${process.env.PUBLIC_URL}/images/register.webp`}
            alt="Register"
            onLoad={() => setIsImageLoading(false)}
          />
        </SideContainer>
      </StyledRegisterContainer>
    </Page>
  );
};

export default Register;
