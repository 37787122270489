import React from 'react';

import { Box, Skeleton } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { keyframes } from '@mui/system';

export const SkeletonCatalogCardWrapper = styled(Box)`
  margin-right: 10px;
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(356px, 1fr));
  min-width: 100%;
  grid-gap: 24px;
`;

const opacity = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Container = styled(Box)({
  marginBottom: '15px',
  animation: `${opacity} 1s ease`,

  ':last-of-type': {
    margin: '0px',
  },
});

const FieldSkeleton = styled(Skeleton)({
  width: '100%',
  height: '42px',
  borderRadius: '5px',
});

interface SkeletonItemMappingProps {
  rowsCount?: number;
}

const SkeletonItemMapping = ({ rowsCount = 3 }: SkeletonItemMappingProps) => (
  <>
    {new Array(rowsCount).fill('row').map((_, idx) => (
      <Container key={idx}>
        <FieldSkeleton variant="rectangular" data-testid={`sceletonItemMapping-${idx}`} />
      </Container>
    ))}
  </>
);

export default SkeletonItemMapping;
