import React, { ChangeEvent, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Typography } from '@material-ui/core';

import Button from 'src/components/Button';
import EbayForm from 'src/components/Forms/EbayForm';
import ShopifyForm from 'src/components/Forms/ShopifyForm';
import WooCommerceForm from 'src/components/Forms/WooCommerceForm';
import HintLimitButton from 'src/components/HintLimitButton';
import Popup from 'src/components/Popup';
import { linkPricingPlan } from 'src/constants';
import useCheckProposedMappings from 'src/hooks/useCheckProposedMappings';
import { CheckIntegrationStatusProps, IntegrationErrors } from 'src/hooks/useIntegrationHealthCheck';
import { checkIsLimitReached, IntegrationTypes, LimitType } from 'src/utils/gql';
import { IntegrationAction, IntegrationItem } from 'src/views/UserSettings/types';

import Portal from '../../HOC/Portal';
import { ExportToIntegrationsContent, ExportToIntegrationUpgradePlanLink } from '../styled';
import { getParametersByIntegrationType } from '../utils';

import IntegrationsList from './IntegrationsList';
import { ExportToIntegrationPopupState, initialExportToIntegrationPopupState, initialPopupState } from './types';

interface ExportToIntegrationPopupProps {
  createdIntegrationId?: string;
  isAddition: boolean;
  setIsAddition: (value: boolean) => void;
  popupState: ExportToIntegrationPopupState;
  onBack: () => void;
  onCloseExportToIntegrationPopup: () => void;
  onAddNewIntegration: (integration: IntegrationItem, callback: CallableFunction) => void;
  setExportToIntegrationPopup: (value: ExportToIntegrationPopupState) => void;
  handleEbayIntegrationCreationInterruption: ({
    takeIdFromLocalStorage,
    withRefetchAndCacheUpdating,
  }: {
    takeIdFromLocalStorage?: boolean;
    withRefetchAndCacheUpdating?: boolean;
  }) => Promise<void>;
  onExportToIntegration: (id: string, callback: CallableFunction) => void;
  loading: boolean;
  testMainButton?: string;
  testSecondaryButton?: string;
  catalogId?: string;
  integrationErrors?: IntegrationErrors;
  onCheckIntegration: ({ integrationId, mainCallbackFn, additionalCallbackFn }: CheckIntegrationStatusProps) => void;
}

const ExportToIntegrationPopup = ({
  createdIntegrationId,
  isAddition,
  setIsAddition,
  popupState,
  onCloseExportToIntegrationPopup,
  handleEbayIntegrationCreationInterruption,
  setExportToIntegrationPopup,
  onBack,
  onAddNewIntegration,
  onExportToIntegration,
  testMainButton,
  testSecondaryButton,
  loading = true,
  catalogId = '',
  integrationErrors,
  onCheckIntegration,
}: ExportToIntegrationPopupProps) => {
  const { t } = useTranslation();

  const [formState, setFormState] = useState<IntegrationItem>(initialPopupState.data);
  const [selectedCard, setSelectedCard] = useState<string>('');

  const { formSchema, integrationSettings, popupTitle } = getParametersByIntegrationType(popupState.integrationType);

  const hasIntegrations = !!popupState.data.length;

  const formProps = useForm({
    mode: 'onChange',
    resolver: yupResolver(formSchema),
    defaultValues: formState.settings,
  });

  const {
    getValues,
    formState: { isValid },
    reset,
  } = formProps;

  const { data: checkIsIntegrationsLimitReached } = useQuery(checkIsLimitReached, {
    fetchPolicy: 'cache-first',
    variables: { type: LimitType.Integrations },
  });

  const isIntegrationsLimitReached = checkIsIntegrationsLimitReached?.checkIsLimitReached;
  const isButtonDisabled = isAddition ? !isValid : !selectedCard && hasIntegrations;

  const handleChangeDefault = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { checked } = target;

    setFormState((prevState: IntegrationItem) => ({
      ...prevState,
      isDefault: checked,
    }));
  };

  const handleSelectCard = (id: string) => {
    setSelectedCard(id);
  };

  const handleClickAddNewIntegration = () => {
    setFormState((prevState: IntegrationItem) => ({
      ...prevState,
      type: popupState.integrationType,
      settings: integrationSettings,
    }));

    if ([IntegrationTypes.Facebook, IntegrationTypes.Ebay].includes(popupState.integrationType)) {
      handleMainButtonClick();
    } else {
      setIsAddition(true);
    }

    setSelectedCard('');
  };

  const { getListWithProposedMappings, getProposedMappingsLoading, renderMappingsSidebar } = useCheckProposedMappings({
    callbackFn: () => onExportToIntegration(selectedCard, onCloseExportToIntegrationPopup),
    closeCallbackFn: onCloseExportToIntegrationPopup,
    shouldWaitAnotherPopupClosing: true,
  });

  const handleMainButtonClick = () => {
    const formValues = getValues();
    const type = [IntegrationTypes.Facebook, IntegrationTypes.Ebay].includes(popupState.integrationType)
      ? popupState.integrationType
      : formState.type;

    const data = {
      id: formState.id,
      type,
      settings: formValues,
      isDefault: formState.isDefault,
    } as IntegrationItem;

    if (selectedCard) {
      onCheckIntegration({
        integrationId: selectedCard,
        mainCallbackFn: () => getListWithProposedMappings(catalogId, selectedCard),
        additionalCallbackFn: () => setSelectedCard(''),
      });
    } else {
      onAddNewIntegration(data, onCloseExportToIntegrationPopup);
    }
  };

  const IntegrationForm = [
    { component: ShopifyForm, type: IntegrationTypes.Shopify },
    { component: WooCommerceForm, type: IntegrationTypes.Woocommerce },
    { component: EbayForm, type: IntegrationTypes.Ebay },
  ].find(({ type }) => type === popupState.integrationType && isAddition)?.component;

  const handleErrorLinkClick = (integrationId: string) => {
    setSelectedCard(integrationId);
    onCheckIntegration({
      integrationId,
      mainCallbackFn: () => getListWithProposedMappings(catalogId, integrationId),
      additionalCallbackFn: () => setSelectedCard(''),
    });
  };

  const handleClose = async () => {
    await handleEbayIntegrationCreationInterruption({});
    onCloseExportToIntegrationPopup();

    if (isAddition) setIsAddition(false);
  };

  const handleExited = () => {
    setExportToIntegrationPopup(initialExportToIntegrationPopupState);
    setIsAddition(false);
    setFormState(initialPopupState.data);
    reset();
  };

  return (
    <>
      <Portal condition={popupState.isOpen}>
        <Popup
          {...(isAddition ? null : { mainButtonsMarginAbsence: true })}
          headerMarginAbsence
          open={popupState.isOpen}
          mainTitle={t('cataloguesPage.exportToIntegrationPopup.title', { integration: popupTitle })}
          mainButtonText={
            hasIntegrations || isAddition ? t('cataloguesPage.exportToIntegrationPopup.export') : undefined
          }
          secondaryButtonText={
            hasIntegrations || isAddition ? t('cataloguesPage.exportToIntegrationPopup.back') : undefined
          }
          mainButtonDisabled={isButtonDisabled}
          loadingOnMainButton={loading || getProposedMappingsLoading}
          onMainButtonClick={handleMainButtonClick}
          onSecondaryButtonClick={onBack}
          onClose={handleClose}
          TransitionProps={{ onExited: handleExited }}
          testMainButton={testMainButton}
          testSecondaryButton={testSecondaryButton}
        >
          <ExportToIntegrationsContent>
            {hasIntegrations && !isAddition && (
              <IntegrationsList
                popupState={popupState}
                onSelectCard={handleSelectCard}
                onClickAddNewIntegration={handleClickAddNewIntegration}
                selectedCard={selectedCard}
                isIntegrationsLimitReached={isIntegrationsLimitReached}
                integrationErrors={integrationErrors}
                onErrorLinkClick={handleErrorLinkClick}
              />
            )}

            {!(hasIntegrations || isAddition) && (
              <Box>
                <Typography mt="50px" mb="40px" width="319px">
                  {t('cataloguesPage.exportToIntegrationPopup.youDontHaveSaved', { integration: popupTitle })}
                </Typography>
                <Box mb="14px">
                  <HintLimitButton
                    tooltipWidth="330px"
                    placement="top"
                    disableHoverListener={!isIntegrationsLimitReached}
                    title={
                      <Trans i18nKey="catalogueCreationPage.thirdStep.shopify.hint">
                        Integration number has reached the limit.
                        <ExportToIntegrationUpgradePlanLink href={linkPricingPlan}>
                          Upgrade your plan
                        </ExportToIntegrationUpgradePlanLink>
                        to create an unlimited number of integration or delete some integrations to create new ones.
                      </Trans>
                    }
                  >
                    <Button
                      variant="contained"
                      onClick={handleClickAddNewIntegration}
                      disabled={isIntegrationsLimitReached}
                    >
                      {t('cataloguesPage.exportToIntegrationPopup.createNewIntegration')}
                    </Button>
                  </HintLimitButton>
                </Box>
              </Box>
            )}

            <FormProvider {...formProps}>
              {IntegrationForm && (
                <Box>
                  <IntegrationForm
                    integrationId={createdIntegrationId}
                    onChangeDefault={handleChangeDefault}
                    action={IntegrationAction.Add}
                    isDefaultIntegration={formState.isDefault}
                  />
                </Box>
              )}
            </FormProvider>
          </ExportToIntegrationsContent>
        </Popup>
      </Portal>

      {renderMappingsSidebar()}
    </>
  );
};

export default ExportToIntegrationPopup;
