import { Moment } from 'moment';

export type DateType = 'startDate' | 'endDate';

export interface MultipleDatePickerState {
  label: string;
  type: DateType;
  value: Moment | Date | null;
  error: string;
}

export interface MultipleDatePickerProps {
  label: string;
  type: DateType;
  value?: Moment | Date | null;
  onSelectDate: (state: MultipleDatePickerState) => void;
  showErrors?: boolean;
  minDate: Moment;
  maxDate: Moment;
}

export interface SingleDatePickerProps {
  value: Moment | null;
  label: string;
  hasError: boolean;
  minDate: Moment;
  disabled: boolean;
  helperText: string;
  onSelectDate: (date: Moment | null) => void;
}

export enum DatePickerError {
  Required = 'datePicker.errorRequired',
  Invalid = 'datePicker.errorInvalid',
}
