import React from 'react';

import MuiCard, { CardProps } from '@material-ui/core/Card';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: '208px',
    height: '64px',
    boxShadow: '0 2px 10px rgba(46, 96, 170, 0.25)',
    borderColor: theme.palette.text.disabled,
    borderRadius: '20px',
    background: 'none',
    '&$cardChecked': {
      color: theme.palette.text.primary,
      border: 'none',
    },
  },
  cardChecked: {
    background: theme.palette.secondary.main,
  },
  cardDisabled: {
    background: theme.palette.background.default,
    '&:hover': {
      boxShadow: 'none',
    },
  },
}));

interface SmallCardProps extends CardProps {
  isSelected?: boolean;
  handleSelectedCard: () => void;
  isDisabled: boolean | undefined;
  testId?: string;
}

function SmallCard(props: SmallCardProps) {
  const classes = useStyles();
  const { isSelected, handleSelectedCard, isDisabled, testId, children } = props;

  if (isDisabled) {
    return <MuiCard className={clsx(classes.root, classes.cardDisabled)}>{children}</MuiCard>;
  }

  return (
    <MuiCard
      className={clsx(classes.root, {
        [classes.cardChecked]: isSelected === true,
      })}
      onClick={handleSelectedCard}
      data-testid={testId}
    >
      {children}
    </MuiCard>
  );
}

export default SmallCard;
