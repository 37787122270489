import { ChangeEvent } from 'react';

import { UploadingMediaWithLoadingStatus } from 'src/components/MediaGallery/constants';

import useUploadMedia from './useUploadMedia';

const useUploadMediaBrowseUrl = (
  setUpload?: React.Dispatch<React.SetStateAction<UploadingMediaWithLoadingStatus[]>>,
) => {
  const { handleUploadMedia } = useUploadMedia(setUpload);

  const handleUploadMediaBrowse = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;

    if (files) {
      const filesArray = Array.from(files);

      handleUploadMedia({ files: filesArray });
    }

    event.target.value = '';
  };

  const handleUploadMediaFromUrl = (urlData: { [x: string]: string }) => {
    const url = Object.values(urlData).join();

    handleUploadMedia({ urls: [url] });
  };

  return { handleUploadMediaBrowse, handleUploadMediaFromUrl };
};

export default useUploadMediaBrowseUrl;
