import React from 'react';

export function addIconToEndAdornment(endAdornment: React.ReactNode, hint: JSX.Element) {
  if (!React.isValidElement(endAdornment)) {
    return endAdornment;
  }

  const children = React.Children.toArray(endAdornment.props.children);

  children.unshift(hint);

  return React.cloneElement(endAdornment, {}, children);
}
