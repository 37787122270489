import React from 'react';

import { Theme, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  '@keyframes lineShort': {
    '0%': {
      width: '0px',
      left: '25px',
      top: '45px',
    },
    '50%': {
      width: '20px',
      left: '32px',
      top: '57px',
    },
    '100%': {
      width: '20px',
      left: '32px',
      top: '57px',
    },
  },
  '@keyframes lineLong': {
    '0%': {
      width: '0px',
      right: '63px',
      top: '61px',
    },
    '65%': {
      width: '0px',
      right: '63px',
      top: '61px',
    },
    '84%': {
      width: '43px',
      right: '21px',
      top: '48px',
    },
    '100%': {
      width: '35px',
      right: '29px',
      top: '51px',
    },
  },
  '@keyframes crossLineLeft': {
    '0%': {
      width: '0px',
      left: '40px',
      top: '41px',
    },
    '50%': {
      width: '35px',
      left: '36px',
      top: '53px',
    },
    '100%': {
      width: '35px',
      left: '36px',
      top: '53px',
    },
  },
  '@keyframes crossLineRight': {
    '0%': {
      width: '0px',
      right: '63px',
      top: '59px',
    },
    '65%': {
      width: '0px',
      right: '63px',
      top: '59px',
    },
    '84%': {
      width: '35px',
      right: '36px',
      top: '53px',
    },
    '100%': {
      width: '35px',
      right: '36px',
      top: '53px',
    },
  },
  loader: {
    position: 'relative',
    width: '110px',
    height: '110px',
    borderRadius: '50%',
  },
  loading: {
    border: `2px solid ${theme.palette.background.default}`,
    borderLeftColor: theme.palette.info.main,
    animation: '$spin 1.2s infinite linear',
  },
  loadCompleted: {
    border: ({ isError }) =>
      isError ? `2px solid ${theme.palette.error.main}` : `2px solid ${theme.palette.info.main}`,
    transition: 'border 500ms ease-out',
  },
  checkLine: {
    position: 'absolute',
    height: '4px',
    backgroundColor: theme.palette.info.main,
    borderRadius: '2px',
  },
  lineShort: {
    top: '57px',
    left: '32px',
    width: '20px',
    transform: 'rotate(45deg)',
    animation: '$lineShort 0.35s',
  },
  lineLong: {
    top: '51px',
    right: '29px',
    width: '35px',
    transform: 'rotate(-45deg)',
    animation: '$lineLong 0.35s',
  },
  crossLine: {
    position: 'absolute',
    top: '53px',
    height: '4px',
    width: '35px',
    backgroundColor: theme.palette.error.main,
    borderRadius: '2px',
  },
  crossLineLeft: {
    left: '36px',
    animation: '$crossLineLeft 0.35s',
    transform: 'rotate(45deg)',
  },
  crossLineRight: {
    right: '36px',
    animation: '$crossLineRight 0.35s',
    transform: 'rotate(-45deg)',
  },
}));

interface StyleProps {
  isError: boolean;
}

interface ExportCsvLoaderProps {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
}

const ExportLoader = ({ isLoading, isSuccess, isError }: ExportCsvLoaderProps) => {
  const props = {
    isError,
  };
  const classes = useStyles(props);

  return (
    <div className={isLoading ? clsx(classes.loader, classes.loading) : clsx(classes.loader, classes.loadCompleted)}>
      {isSuccess && (
        <>
          <div className={clsx(classes.checkLine, classes.lineShort)} />
          <div className={clsx(classes.checkLine, classes.lineLong)} />
        </>
      )}

      {isError && (
        <>
          <div className={clsx(classes.crossLine, classes.crossLineLeft)} />
          <div className={clsx(classes.crossLine, classes.crossLineRight)} />
        </>
      )}
    </div>
  );
};

export default ExportLoader;
