import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@material-ui/core';

const TruncatedPopupDeleteText = (name: string) => {
  const { t } = useTranslation();
  return (
    <Box>
      <Typography variant="h2">{t('popupDeleteText')}</Typography>
      <Box display="flex" justifyContent="center">
        <Typography
          maxWidth="350px"
          whiteSpace="nowrap"
          ml="20px"
          overflow="hidden"
          textOverflow="ellipsis"
          variant="h2"
        >
          {name}
        </Typography>
        <Typography mr="20px" variant="h2">
          ?
        </Typography>
      </Box>
    </Box>
  );
};

export default TruncatedPopupDeleteText;
