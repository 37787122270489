import { Box, DialogContent, Divider, Typography } from '@material-ui/core';
import { experimentalStyled as styled, makeStyles } from '@material-ui/core/styles';

export const ExportPopupDialog = styled(DialogContent)`
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: auto;
  margin: 0 auto;

  & .MuiTypography-root {
    white-space: pre-line;
  }
`;

export const ExportCsvStatusDialog = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  & .MuiTypography-root {
    padding-bottom: 25px;
    white-space: pre-line;
  }
  & .MuiDialogContent-root {
    padding-bottom: 0;
  }
`;

export const ExportWooCommerceStatusDialog = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  padding: 7px 88px 6px;
  & .MuiFormControl-root {
    margin: 7px 0 10px;
  }
`;

export const ExportCardWrapper = styled(Box)`
  margin: 5px 0 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 323px;
  width: 100%;

  .export_csv_card:nth-of-type(1) {
    margin: 0 auto 0;
  }
`;

export const StyledExportCardItem = styled(Box)`
  margin: 15px auto 0;
  width: 100%;
  max-width: 323px;
`;

export const IntegrationsContent = styled(DialogContent)`
  display: flex;
  justify-content: center;
`;

export const ExportToIntegrationsContent = styled(DialogContent)`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const ExportCardDescription = styled(Typography)`
  text-align: left;
  margin-top: 20px;
  font-weight: 400;
`;

export const ExportPopupDivider = styled(Divider)(({ theme }) => ({
  borderWidth: 1,
  width: 303,
  borderColor: theme.palette.background.default,
}));

export const ApprovePopupDescription = styled(Typography)`
  overflow: hidden;
  max-width: 385px;
  width: 100%;
  line-break: anywhere;
  text-overflow: ellipsis;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: ${({ theme: { palette } }) => palette.text.primary};
  margin: 0 auto;
  margin-bottom: 25px;
`;

export const useInputStyles = makeStyles(() => ({
  input: {
    marginTop: 17,
  },
}));

export const BulkEditPopupLayout = styled(Box)`
  min-height: 101px;
  display: flex;
  flex-direction: column;
  margin: 32px 88px 20px;
`;

export const ExportToIntegrationUpgradePlanLink = styled('a')`
  color: ${({ theme: { palette } }) => palette.primary.light};
  border-bottom: 1px solid;
  text-decoration: none;
  display: inline-block;
  line-height: 10px;
  &:hover {
    cursor: pointer;
  }
`;

export const CreateNewIntegrationLinkDisabled = styled(Typography)`
  color: ${({ theme: { palette } }) => palette.text.disabled};
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 40px;
  margin-top: 10px;

  &:hover {
    cursor: default;
  }
`;

export const ItemDefinitionPopupBody = styled(Box)`
  max-width: 418px;
  margin: 0 auto;
`;
