import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { useApolloClient } from '@apollo/client';
import { Box, Link, Popover, Typography, makeStyles, Theme } from '@material-ui/core';
import { useFlag } from '@unleash/proxy-client-react';

import Iconography from 'src/components/Iconography';
import { EXTERNAL_URL, FeatureFlag } from 'src/constants';
import { FIRST_DASHBOARD_PATHNAME, USER_SETTINGS, AUDIT_LOG } from 'src/constants/routeSources';
import { closeWsConnection } from 'src/utils/api';
import { clearStorage } from 'src/utils/localStorage';
import { locationsData, resetRecentSteps } from 'src/utils/locationArrays';

import RecentSteps from './RecentSteps';
import { CloseButton, NavBarOnBoardingItem, StyledLinkA, StyledUserName } from './styled';

interface ContextMenuProps {
  isLimitReached: boolean;
  onClose: () => void;
  anchorEl: HTMLElement | null;
  userName: string;
  onBoardingClick: () => void;
}

enum MENUS {
  help = 'navbar_footer_item3_id',
  avatar = 'navbar_footer_item4_id',
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.palette.text.secondary,
    '&:not(:last-of-type)': {
      marginBottom: '15px',
    },
  },
}));

const ContextMenu = ({ anchorEl, onClose, userName, onBoardingClick }: ContextMenuProps) => {
  const { t } = useTranslation();
  const client = useApolloClient();

  const isAuditLogFeatureActive = useFlag(FeatureFlag.AUDIT_LOG);
  const isOnboardingFeatureActive = useFlag(FeatureFlag.ONBOARDING);
  const isRecentStepsFeatureActive = useFlag(FeatureFlag.RECENT_STEPS);

  const menu = anchorEl?.id;
  const havingRecentSteps = locationsData.length > 1;

  const handleLogout = () => {
    clearStorage();
    resetRecentSteps();
    closeWsConnection?.();

    return client.clearStore();
  };

  const classes = useStyles();

  return (
    <>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        anchorEl={anchorEl}
        open={menu === MENUS.help}
        onClose={onClose}
        style={{ left: '10px' }}
      >
        <Box display="flex" flexDirection="column" padding="25px " minWidth="240px">
          <StyledLinkA href={EXTERNAL_URL.HELP_CENTER} data-testid="navBarhelpcenter">
            {t('navBar.contextMenu.helpcenter')}
          </StyledLinkA>

          {isOnboardingFeatureActive ? (
            <NavBarOnBoardingItem
              color="text.secondary"
              component="button"
              onClick={onBoardingClick}
              data-testid="navBarStartOnboarding"
            >
              {t('onboarding.start')}
            </NavBarOnBoardingItem>
          ) : null}

          <CloseButton onClick={onClose} size="small">
            <Iconography fontSize="small" iconName="cancel" color="secondary" />
          </CloseButton>
        </Box>
      </Popover>

      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        anchorEl={anchorEl}
        open={menu === MENUS.avatar}
        onClose={onClose}
        style={{ left: '10px' }}
      >
        <Box display="flex" flexDirection="column" padding="25px" width="240px">
          {isRecentStepsFeatureActive && havingRecentSteps && (
            <>
              <Typography variant="caption" mb="10px">
                {t('navBar.contextMenu.recentSteps')}
              </Typography>

              <RecentSteps onClose={onClose} />
            </>
          )}

          <StyledUserName variant="caption">{userName}</StyledUserName>

          <Link
            to={`/${FIRST_DASHBOARD_PATHNAME}/${USER_SETTINGS}`}
            component={RouterLink}
            onClick={onClose}
            variant="subtitle2"
            className={classes.root}
            data-testid="navBarProfileSettings"
          >
            {t('navBar.contextMenu.profileSettings')}
          </Link>

          {isAuditLogFeatureActive && (
            <Link
              to={`/${FIRST_DASHBOARD_PATHNAME}/${AUDIT_LOG}`}
              component={RouterLink}
              onClick={onClose}
              variant="subtitle2"
              className={classes.root}
              data-testid="navBarAuditLogLink"
            >
              {t('navBar.contextMenu.auditLog')}
            </Link>
          )}

          <Link
            to="/auth/login"
            component={RouterLink}
            onClick={handleLogout}
            variant="subtitle2"
            className={classes.root}
            data-testid="navBarLogoutLink"
          >
            {t('navBar.contextMenu.logout')}
          </Link>

          <CloseButton onClick={onClose} size="small">
            <Iconography fontSize="small" iconName="cancel" color="secondary" />
          </CloseButton>
        </Box>
      </Popover>
    </>
  );
};

export default ContextMenu;
