import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TooltipRenderProps } from 'react-joyride';
import { useHistory } from 'react-router-dom';

import { Box, styled } from '@material-ui/core';
import { Typography } from '@mui/material';

import useOnboarding from 'src/providers/OnboardingProvider/hooks/useOnboarding';
import { CatalogSource } from 'src/utils/gql';

import Button from '../Button';

const enum IntegrationStepIndex {
  AccountInfoStep = 13,
}

const enum CsvStepIndex {
  SelectCsvStep = 6,
  AccountInfoStep = 12,
}

const enum ManuallyStepIndex {
  ProductTypeNameStep = 6,
  ProductItemRequiredFieldsStep = 11,
  ProductItemAdditionalFieldsStep = 12,
  AccountInfoStep = 22,
}

const TooltipBody = styled(Box)(({ theme }) => ({
  width: 387,
  padding: '40px 38px',
  boxShadow: '0px 2px 10px rgba(46, 96, 170, 0.25)',
  borderRadius: '20px',
  userSelect: 'none',
  background: theme.palette.common.white,

  '& .MuiButton-root.Mui-focusVisible:not(:hover)': {
    boxShadow: 'none',
  },
}));

const TooltipTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.dark,
  marginBottom: '32px',
  textAlign: 'center',
}));

const TooltipContent = styled(Box)(() => ({
  textAlign: 'center',
}));

const TooltipFooter = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: '24px',
}));

const CustomOnboardingTooltip = (props: TooltipRenderProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    onboardingState: { createdOnboardingCatalog, createdOnboardingProductType, createdOnboardingProductItem, steps },
    goNext,
    goBack,
    finishOnboarding,
  } = useOnboarding();

  const [tooltipState, setTooltipState] = useState<{ isConfirmation: boolean; props: TooltipRenderProps }>({
    isConfirmation: false,
    props,
  });

  const {
    index,
    step: { title, content, hideBackButton, hideCloseButton, hideFooter, showSkipButton, styles },
    continuous,
    isLastStep,
    primaryProps: { title: nextButtonTitle, onClick: onNext },
    backProps: { title: backButtonTitle, onClick: onBack },
    closeProps: { title: closeButtonTitle, onClick: onClose },
    skipProps: { title: skipButtonTitle, onClick: onSkip },
    tooltipProps,
  } = tooltipState.props;

  const catalogSource = createdOnboardingCatalog?.source;

  const isNextButtonDisabled = useMemo(
    () =>
      catalogSource === CatalogSource.Manually &&
      ((index === ManuallyStepIndex.ProductTypeNameStep &&
        (!createdOnboardingProductType?.name || createdOnboardingProductType?.name.length > 255)) ||
        (index === ManuallyStepIndex.ProductItemRequiredFieldsStep && !createdOnboardingProductItem)),
    [catalogSource, createdOnboardingProductItem, createdOnboardingProductType?.name, index],
  );

  const hasDownloadExampleStep = steps.some(({ title }) => title === t('onboarding.downloadExample.title'));

  const getTopTooltipPosition = useCallback(() => {
    let topTooltipPosition = styles?.tooltip?.top;

    const getStep = (stepIndex: CsvStepIndex) => (hasDownloadExampleStep ? stepIndex + 1 : stepIndex);

    if (
      catalogSource &&
      (([CatalogSource.Shopify, CatalogSource.Woocommerce].includes(catalogSource) &&
        index === IntegrationStepIndex.AccountInfoStep) ||
        (catalogSource === CatalogSource.Csv && index === getStep(CsvStepIndex.AccountInfoStep)) ||
        (catalogSource === CatalogSource.Manually && index === ManuallyStepIndex.AccountInfoStep))
    ) {
      topTooltipPosition = -140;
    }

    if (catalogSource && catalogSource === CatalogSource.Csv && index === getStep(CsvStepIndex.SelectCsvStep)) {
      topTooltipPosition = -14;
    }

    if (isLastStep) {
      topTooltipPosition = 35;
    }

    return topTooltipPosition;
  }, [catalogSource, hasDownloadExampleStep, index, isLastStep, styles?.tooltip?.top]);

  const setConfirmationScreen = useCallback(() => {
    setTooltipState((prevState) => ({
      props: {
        ...prevState.props,
        step: {
          ...prevState.props.step,
          hideFooter: false,
          title: t('onboarding.skipExploreYourOwn.title'),
          content: <Typography>{t('onboarding.skipExploreYourOwn.description')}</Typography>,
          styles: {
            ...prevState.props.step.styles,
            tooltip: {
              ...prevState.props.step.styles?.tooltip,
              top: getTopTooltipPosition(),
            },
          },
        },
      },
      isConfirmation: true,
    }));
  }, [getTopTooltipPosition, t]);

  const handleConfirmBackClick = useCallback(() => {
    setTooltipState({ props, isConfirmation: false });
  }, [props]);

  useEffect(() => {
    const handlePopState = () => {
      window.history.pushState({}, '', history.location.pathname);

      setConfirmationScreen();
    };

    window.addEventListener('popstate', handlePopState);

    return () => window.removeEventListener('popstate', handlePopState);
  }, [history.location.pathname, setConfirmationScreen, t]);

  useEffect(() => {
    const body = document.getElementsByTagName('body')[0];
    const nextBtn = document.getElementById('onboarding-next-btn');
    const backBtn = document.getElementById('onboarding-back-btn');
    const descriptionField = document.getElementsByClassName('MuiInputBase-multiline')[0];

    const handleKeydownEvent = ({ code }: KeyboardEvent) => {
      if (!tooltipState.isConfirmation) {
        if (code === 'Enter' && nextBtn) {
          goNext();
        }

        if (code === 'Backspace' && backBtn) {
          if (
            ManuallyStepIndex.ProductItemAdditionalFieldsStep &&
            descriptionField?.classList.contains('Mui-focused')
          ) {
            return;
          }

          goBack();
        }

        if (code === 'Escape') {
          setConfirmationScreen();
        }
      } else {
        if (code === 'Backspace') {
          handleConfirmBackClick();
        }

        if (['Enter', 'Escape'].includes(code)) {
          finishOnboarding();
        }
      }
    };

    body.addEventListener('keydown', handleKeydownEvent);

    return () => body.removeEventListener('keydown', handleKeydownEvent);
  }, [finishOnboarding, goBack, goNext, handleConfirmBackClick, setConfirmationScreen, tooltipState.isConfirmation]);

  return (
    <TooltipBody id="onboarding-popup" sx={styles?.tooltip} {...tooltipProps}>
      {title && <TooltipTitle variant="h2">{title}</TooltipTitle>}

      <TooltipContent>{content}</TooltipContent>

      {!hideFooter && (
        <TooltipFooter>
          {tooltipState.isConfirmation ? (
            <>
              <Button variant="outlined" onClick={handleConfirmBackClick} sx={{ marginRight: '15px' }}>
                {t('onboarding.button.back')}
              </Button>

              <Button variant="contained" onClick={onSkip}>
                {t('onboarding.button.skipOnboarding')}
              </Button>
            </>
          ) : (
            <>
              {showSkipButton && (
                <Button
                  id="onboarding-skip-btn"
                  variant="outlined"
                  onClick={setConfirmationScreen}
                  sx={{ marginRight: '15px' }}
                >
                  {skipButtonTitle}
                </Button>
              )}

              {index > 0 && !hideBackButton && (
                <Button id="onboarding-back-btn" variant="outlined" onClick={onBack} sx={{ marginRight: '15px' }}>
                  {backButtonTitle}
                </Button>
              )}

              {continuous && nextButtonTitle && (
                <Button id="onboarding-next-btn" variant="contained" disabled={isNextButtonDisabled} onClick={onNext}>
                  {nextButtonTitle}
                </Button>
              )}

              {!continuous && !hideCloseButton && (
                <Button id="onboarding-close-btn" variant="contained" onClick={onClose}>
                  {closeButtonTitle}
                </Button>
              )}
            </>
          )}
        </TooltipFooter>
      )}
    </TooltipBody>
  );
};

export default CustomOnboardingTooltip;
