import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ApolloError } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@material-ui/core';

import Button from 'src/components/Button';
import EmailInput from 'src/components/Forms/FormInputs/EmailInput';
import { FormContainer } from 'src/components/Forms/styled';
import { ForgotPasswordFormProps, ForgotPasswordInput } from 'src/components/Forms/types';
import { forgotPasswordFormSchema } from 'src/components/Forms/validationSchemas';

const ForgotPasswordForm = ({ onSubmit, onError, loading }: ForgotPasswordFormProps) => {
  const { t } = useTranslation();

  const formMethods = useForm<ForgotPasswordInput>({
    mode: 'onChange',
    resolver: yupResolver(forgotPasswordFormSchema),
  });

  const {
    handleSubmit,
    formState: { isValid },
  } = formMethods;

  const onSubmitForm = handleSubmit(async ({ email }: ForgotPasswordInput) => {
    try {
      await onSubmit(email);
    } catch (error) {
      const { graphQLErrors, message: errorText } = error as ApolloError;
      const message = graphQLErrors?.length ? graphQLErrors[0].message : errorText;
      onError?.(message);
    }
  });

  return (
    <FormProvider {...formMethods}>
      <form aria-label="form" onSubmit={onSubmitForm}>
        <FormContainer>
          <Box mb="30px" mt="30px" width="100%">
            <Box mb="10px">
              <EmailInput shouldFocus />
            </Box>
          </Box>

          <Button
            color="primary"
            disabled={!isValid}
            type="submit"
            data-testid="submit"
            variant="contained"
            loading={loading}
          >
            {t('forgotPasswordPage.passwordScreen.resetYourPassword')}
          </Button>
        </FormContainer>
      </form>
    </FormProvider>
  );
};

export default ForgotPasswordForm;
