import { createContext } from 'react';

import { MediaStateType } from './types';

export const defaultValue: MediaStateType = {
  mediaState: [],
  mediaForDetach: [],
  mediaOrdersBeforeUpdate: [],
  addMediaToDetach: () => {
    if (process.env.NODE_ENV !== 'production') {
      console.error('Called MediaStateContext.addMediaToDetach. Did you forget to provide a provider?');
    }
  },
  setMediaState: () => {
    if (process.env.NODE_ENV !== 'production') {
      console.error('Called MediaStateContext.setMediaState. Did you forget to provide a provider?');
    }
  },
  isUploading: () => {
    if (process.env.NODE_ENV !== 'production') {
      console.error('Called MediaStateContext.isUploading. Did you forget to provide a provider?');
    }
  },
  setMediaLastOrder: () => {
    if (process.env.NODE_ENV !== 'production') {
      console.error('Called MediaStateContext.setMediaLastOrder. Did you forget to provide a provider?');
    }
  },
  changeMediaOrdersBeforeUpdate: () => {
    if (process.env.NODE_ENV !== 'production') {
      console.error('Called MediaStateContext.changeMediaOrdersBeforeUpdate. Did you forget to provide a provider?');
    }
  },
  uploading: false,
};

export const MediaStateContext = createContext<MediaStateType>(defaultValue);
