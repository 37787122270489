import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@material-ui/core';

import { IntegrationTypes, ProductTypeFieldIntegrationMapping, ProductItemValue, MediaUsage } from 'src/utils/gql';

import SideBarDrawer from '../SideBar/RightSidebarDrawer';

import { CompletenessIntegration } from './ChipsCompleteness';
import { SidebarCompletenessItem } from './SidebarCompletenessItem';

interface SidebarProps {
  onClose: () => void;
  isOpen: boolean;
  integrationsMap: { [key: string]: CompletenessIntegration };
  fieldsMapping: ProductTypeFieldIntegrationMapping[];
  fieldsValues: ProductItemValue[];
  isDataLoading: boolean;
  mediaUsages?: MediaUsage[];
}

export const SidebarCompleteness = ({
  onClose,
  isOpen,
  integrationsMap,
  fieldsMapping,
  fieldsValues,
  isDataLoading,
  mediaUsages,
}: SidebarProps) => {
  const { t } = useTranslation();

  return (
    <SideBarDrawer
      titleName={t('productItemCreateEdit.sidebar.completeness')}
      openSidebar={isOpen}
      onPrimaryButtonClick={onClose}
      handleCloseSidebarOnClickAway={onClose}
      narrowSideBar
      primaryButtonTitle="Cancel"
      dataTestIdPrimaryButton="completenessSidebarCloseButton"
    >
      <Box pl="30px" pr="30px" flexGrow={1}>
        {Object.values(IntegrationTypes).map(
          (integration, idx) =>
            integration !== IntegrationTypes.Csv && (
              <SidebarCompletenessItem
                key={idx}
                integration={integrationsMap[integration] || { type: integration }}
                fieldsMapping={fieldsMapping}
                fieldsValues={fieldsValues}
                isDataLoading={isDataLoading}
                mediaUsages={mediaUsages}
              />
            ),
        )}
      </Box>
    </SideBarDrawer>
  );
};
