import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  IntegrationProductTypeField,
  IntegrationTypes,
  MediaUsage,
  ProductItemValue,
  ProductTypeFieldIntegrationMapping,
} from 'src/utils/gql';

import { CompletenessIntegration } from './ChipsCompleteness';

interface UseCheckFieldsProps {
  systemFields?: IntegrationProductTypeField[];
  fieldsValues: ProductItemValue[];
  fieldsMapping: ProductTypeFieldIntegrationMapping[];
  integration: CompletenessIntegration;
  mediaUsages?: MediaUsage[];
}

const systemAutoMappedFieldsToCheckValue = ['name', 'price', 'description', 'sku'];
const systemAutoMappedFieldsNotToCheckValue = ['status', 'title'];
const ebayTitleField = 'title';
// todo: when currency will be not auto filled delete it and rewrite logic
const facebookAutoMappedFieldNotToCheckValue = 'currency';
const facebookImageField = 'image_url';

export const useCheckFields = ({
  systemFields,
  fieldsValues,
  fieldsMapping,
  integration,
  mediaUsages,
}: UseCheckFieldsProps) => {
  const { t } = useTranslation();
  const mappedFields = useCallback(
    (id: string) => {
      return fieldsMapping.filter(({ integrationId }) => integrationId === id);
    },
    [fieldsMapping],
  );

  return useMemo(() => {
    let percentage = 0;

    const completedFields: IntegrationProductTypeField[] = [];
    const inCompletedFields: IntegrationProductTypeField[] = [];

    const checkFacebookSpecialCompletenessField = (field: IntegrationProductTypeField) => {
      if (
        field.id === facebookAutoMappedFieldNotToCheckValue ||
        (field.id === facebookImageField && mediaUsages?.length)
      ) {
        return true;
      }
      return false;
    };

    if (systemFields?.length) {
      const valuesMap: { [id: string]: ProductItemValue } = {};
      const valuesMapWithProductTypeName: { [id: string]: ProductItemValue } = {};
      fieldsValues.forEach((value) => {
        valuesMap[value.productTypeField.id] = value;
        valuesMapWithProductTypeName[value.productTypeField.name.toLowerCase()] = value;
      });
      if (integration?.id) {
        const fieldsMapByMetadataExternalId: { [id: string]: ProductTypeFieldIntegrationMapping } = {};
        mappedFields(integration.id).forEach((field) => {
          fieldsMapByMetadataExternalId[field.metadataExternalId] = field;
        });
        systemFields.forEach((field) => {
          const mappedField = fieldsMapByMetadataExternalId[field.id];
          if (mappedField && mappedField.productTypeFieldId) {
            const mappedFieldValue = valuesMap[mappedField.productTypeFieldId];
            if (mappedFieldValue?.value) {
              completedFields.push(field);
            } else {
              inCompletedFields.push(field);
            }
          } else if (
            // filling completeness field if fields are system
            systemAutoMappedFieldsToCheckValue.includes(field.id) &&
            valuesMapWithProductTypeName[field.id]?.value
          ) {
            completedFields.push(field);
          } else if (systemAutoMappedFieldsNotToCheckValue.includes(field.id)) {
            completedFields.push(field);
          } else if (
            // filling completeness field for facebook integration special fields
            integration.type === IntegrationTypes.Facebook &&
            checkFacebookSpecialCompletenessField(field)
          ) {
            completedFields.push(field);
          } else {
            inCompletedFields.push(field);
          }
        });
      } else {
        systemFields.forEach((field) => {
          const matchedField = valuesMapWithProductTypeName[t(`${integration.type}.${field.name}`).toLowerCase()];
          if (matchedField?.value) {
            completedFields.push(field);
          } else if (integration.type === IntegrationTypes.Facebook && checkFacebookSpecialCompletenessField(field)) {
            completedFields.push(field);
          } else if (field.name === ebayTitleField) {
            completedFields.push(field);
          } else {
            inCompletedFields.push(field);
          }
        });
      }
      percentage =
        completedFields.length && systemFields.length
          ? +Number((completedFields.length / systemFields.length) * 100).toFixed(0)
          : 0;
    }
    return {
      completedFields,
      inCompletedFields,
      percentage,
    };
  }, [systemFields, mappedFields, fieldsValues, integration?.id, mediaUsages?.length, integration.type, t]);
};
