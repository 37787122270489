import React, { forwardRef, useImperativeHandle, useState } from 'react';

import { InputBaseComponentProps } from '@material-ui/core/InputBase';
import { useTheme } from '@material-ui/core/styles';

const StripeInput = forwardRef<InputBaseComponentProps, keyof InputBaseComponentProps>(function StripeInput(
  { component: Component, options, ...props }: InputBaseComponentProps,
  ref,
) {
  const theme = useTheme();
  const [mountNode, setMountNode] = useState<typeof Component | null>(null);

  useImperativeHandle(
    ref,
    () => ({
      focus: () => mountNode.focus(),
    }),
    [mountNode],
  );

  return (
    <Component
      onReady={setMountNode}
      options={{
        ...options,
        hidePostalCode: true,
        style: {
          base: {
            fontWeight: '300',
            color: theme.palette.text.primary,
            '::placeholder': {
              color: theme.palette.text.disabled,
            },
          },
          invalid: {
            color: theme.palette.error.main,
          },
        },
      }}
      {...props}
    />
  );
});

export default StripeInput;
