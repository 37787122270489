import React, { KeyboardEvent, SyntheticEvent } from 'react';

import { makeStyles, Theme } from '@material-ui/core';

import Button from '../Button';
import Iconography from '../Iconography';

const useStyles = makeStyles<Theme, { showIconText: boolean; fillColor: string }>(() => ({
  buttonStyles: {
    width: '42px',
    minWidth: '42px',
    height: '42px',
    boxSizing: 'border-box',
    padding: '12px 0',
    border: ({ fillColor }) => `1px solid ${fillColor}`,
    '& .MuiButton-label': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      lineHeight: '42px',
    },
    '& .syncBtnText': {
      display: 'none',
    },
    '&:hover': {
      width: ({ showIconText }) => (showIconText ? '271px' : '42px'),
      border: ({ fillColor }) => `1px solid ${fillColor}`,
    },

    '&:hover .syncBtnText': {
      display: ({ showIconText }) => (showIconText ? 'inline-block' : 'none'),
      color: ({ fillColor }) => fillColor,
      marginLeft: '25px',
    },
  },
  iconStyles: {
    position: 'absolute',
    top: '8px',
    left: '8px',
    color: ({ fillColor }) => fillColor,
  },
}));

export interface OrangeIconParams {
  orangeButtonText?: string;
  showOrangeButtonText?: boolean;
}

interface ErrorableSyncButtonOrangeProps {
  disabled: boolean;
  onClick?: (e: SyntheticEvent) => void;
  onKeyboardButtonPressed: (e: KeyboardEvent<HTMLElement>) => void;
  dataTestId?: string;
  orangeIconParams?: OrangeIconParams;
  fillColor: string;
}

const ErrorableSyncButtonOrange = ({
  disabled,
  onClick,
  onKeyboardButtonPressed,
  dataTestId,
  orangeIconParams,
  fillColor,
}: ErrorableSyncButtonOrangeProps) => {
  const classes = useStyles({
    showIconText: !!(orangeIconParams?.showOrangeButtonText && orangeIconParams.orangeButtonText),
    fillColor,
  });
  return (
    <Button
      id="sync-btn-anchor"
      variant="outlined"
      data-testid={dataTestId}
      disabled={disabled}
      onClick={onClick}
      onKeyPress={onKeyboardButtonPressed}
      className={classes.buttonStyles}
    >
      <Iconography className={classes.iconStyles} iconName="sync" />
      {orangeIconParams?.showOrangeButtonText && (
        <span className="syncBtnText">{orangeIconParams?.orangeButtonText}</span>
      )}
    </Button>
  );
};

export default ErrorableSyncButtonOrange;
