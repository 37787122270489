import React, { useState } from 'react';

import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { BOOLEAN_FIELD_SELECT_OPTIONS } from 'src/constants';

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  toggleWrapper: {
    display: 'flex',
    maxWidth: '160px',
    width: '100%',
    height: '42px',
    borderRadius: '2px',
    border: `1px solid ${theme.palette.text.disabled}`,
    borderWidth: '1px 0',
    boxSizing: 'border-box',
    alignItems: 'center',
  },
  button: {
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '50%',
    height: 'calc(100% + 2px)',
    color: theme.palette.text.primary,
    cursor: ({ disabled }) => (disabled ? 'default' : 'pointer'),
    '&:first-of-type': {
      borderLeft: `1px solid ${theme.palette.text.disabled}`,
    },
    '&:last-of-type': {
      borderRight: `1px solid ${theme.palette.text.disabled}`,
    },
  },
  activePosition: {
    background: theme.palette.secondary.main,
    '& h6': {
      color: theme.palette.common.white,
    },
    width: 'calc(50% + 2px)',
    '&:first-of-type': {
      borderLeft: 'none',
    },
    '&:last-of-type': {
      borderRight: 'none',
    },
  },
  noSelected: {
    '&:first-of-type': {
      borderRight: `0.5px solid ${theme.palette.text.disabled}`,
    },
    '&:last-of-type': {
      borderLeft: `0.5px solid ${theme.palette.text.disabled}`,
    },
  },
}));

interface StyleProps {
  disabled?: boolean;
}

export enum ToggleValue {
  DEFAULT = 0,
  LEFT = 1,
  RIGHT = 2,
}

interface Props {
  onChange: (newPosition: ToggleValue) => void;
  leftLabel?: string;
  rightLabel?: string;
  value?: ToggleValue;
  disabled?: boolean;
}

const ThreeStateToggle = ({ value, onChange, disabled, leftLabel, rightLabel }: Props) => {
  const classes = useStyles({ disabled });

  const [internalValue, setInternalValue] = useState<ToggleValue>(value ?? ToggleValue.DEFAULT);

  const handleClick = (value: number) => {
    const newValue = (internalValue & value) ^ value;

    setInternalValue(newValue);
    onChange(newValue);
  };

  const togglePosition = value ?? internalValue;
  const labels =
    leftLabel && rightLabel ? [leftLabel, rightLabel] : BOOLEAN_FIELD_SELECT_OPTIONS.map(({ label }) => label);

  return (
    <Box className={clsx(classes.toggleWrapper)}>
      {labels.map((value, idx) => (
        <Box
          key={idx}
          className={clsx(
            classes.button,
            togglePosition === idx + 1 && classes.activePosition,
            !togglePosition && classes.noSelected,
          )}
          role="button"
          tabIndex={0}
          onClick={() => handleClick(idx + 1)}
          data-testid={`${value}Button`}
        >
          <Typography variant="subtitle2" color="text.primary" fontWeight="400">
            {value}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default ThreeStateToggle;
