import {
  FACEBOOK_401,
  FACEBOOK_AUTH_ERROR_CODE,
  FACEBOOK_BROADCAST_CHANEL_NAME,
  FacebookEvent,
  FORBIDDEN_403,
  NOT_FOUND_404,
} from '../constants';
import { ERROR_PATHS } from '../constants/routeSources';

import { getFacebookAuthUrl } from './getFacebookAuthUrl';

export interface CustomGraphQLErrorsHandler {
  graphQLErrorMessage: RegExp;
  graphQLErrorCode?: string | number;
  handler: (args?: Record<string, unknown>) => void | Promise<void>;
}

const handler404: CustomGraphQLErrorsHandler = {
  graphQLErrorMessage: NOT_FOUND_404,
  handler: () => {
    location.replace(ERROR_PATHS.ERROR_404);
  },
};

const handler403: CustomGraphQLErrorsHandler = {
  graphQLErrorMessage: FORBIDDEN_403,
  handler: () => {
    location.replace(ERROR_PATHS.ERROR_404);
  },
};

const handlerFacebook401: CustomGraphQLErrorsHandler = {
  graphQLErrorMessage: FACEBOOK_401,
  graphQLErrorCode: FACEBOOK_AUTH_ERROR_CODE,
  handler: async (args) => {
    const businessId = args?.businessId;

    if (businessId) {
      const reAuthUrl = getFacebookAuthUrl({
        reAuth: true,
        state: {
          businessId,
        },
      });

      return new Promise<void>((resolve) => {
        open(reAuthUrl);

        const bc = new BroadcastChannel(FACEBOOK_BROADCAST_CHANEL_NAME);

        bc.addEventListener('message', (e) => {
          if (e.data === FacebookEvent.TokenUpdated) {
            resolve();
          }

          bc.close();
        });
      });
    }
  },
};

export default [handler403, handler404, handlerFacebook401];
