import React from 'react';

import { Box } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';

const MainBox = styled(Box)`
  position: absolute;
  width: 365px;
  height: 262px;
  top: 30px;
  left: 10px;
  opacity: 0.7;
  filter: blur(25px);

  & * {
    position: absolute;
    border-radius: 50%;
  }
`;

const PurpleBox = styled(Box)`
  background-color: #6c69e6;
  box-shadow: 0 0 0 10px #6c69e6;
  transform: translate(-50%, -50%) rotate(-5deg);
  border-radius: 20%;
`;

const LightBlueBox = styled(Box)`
  background-color: #adafee;
  box-shadow: 0 0 0 10px #adafee;
`;

const CreateCatalogFirstStepBackground = () => {
  return (
    <MainBox>
      <LightBlueBox width="60px" height="90px" top="66px" />

      <LightBlueBox width="70px" height="80px" right="0px" top="50px" />

      <PurpleBox width="275px" height="180px" top="160px" left="50%" />

      <LightBlueBox width="120px" height="65px" top="120px" right="130px" />
    </MainBox>
  );
};

export default CreateCatalogFirstStepBackground;
