import React, { ChangeEvent, SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { Box, Typography } from '@material-ui/core';
import { useFlag } from '@unleash/proxy-client-react';

import DashboardBreadCrumbs from 'src/components/Breadcrumds/DashboardBreadCrumbs';
import ButtonScrollToTop from 'src/components/ButtonScrollToTop';
import CatalogsSettingsHeader from 'src/components/Headers/CatalogsSettingsHeader';
import Loader from 'src/components/Loader';
import MultiSelect from 'src/components/MultiSelect';
import Page from 'src/components/Page';
import Popup from 'src/components/Popup';
import ChangeCatalogPrivacyModal from 'src/components/Popups/ChangeCatalogPrivacyModal';
import DiscardChangesPopup from 'src/components/Popups/DiscardChangesPopup';
import { SelectOptionItem } from 'src/components/Select';
import SmallAddButton from 'src/components/SmallAddButton';
import CatalogConfigTable from 'src/components/Table/CatalogConfigTable';
import { FeatureFlag, PAYMENT_FREQUENCY_OPTIONS, SUBSCRIPTIONS_DEFAULT_LIMIT } from 'src/constants';
import { FIRST_DASHBOARD_PATHNAME } from 'src/constants/routeSources';
import { useCheckCatalogMappingErrors } from 'src/hooks/graphQLRequestsHooks';
import useCheckProposedMappings from 'src/hooks/useCheckProposedMappings';
import { useCatalogs } from 'src/providers/catalog';
import useDataTransferStatus from 'src/providers/catalog/hooks/useDataTransferStatus';
import { clearLocalCatalogsCache } from 'src/providers/catalog/provider';
import { useLimitReached } from 'src/providers/limitReached';
import { useSnackbar } from 'src/providers/snackbar';
import { removeDuplicatedObjects } from 'src/utils/general';
import {
  checkIsLimitReached,
  getSubscriptionsByCatalogId,
  Maybe,
  OrderBy,
  OrderDirection,
  Query,
  QueryGetSubscriptionsArgs,
  updateCatalog,
  Mutation,
  exportCatalogToIntegration,
  MutationExportCatalogArgs,
  getCatalogInfo,
  QueryGetCatalogByIdArgs,
  LimitType,
  CatalogPrivacy,
  Catalog,
} from 'src/utils/gql';
import { truncateText, MAX_SIDEBAR_SUBTITLE_LENGTH } from 'src/utils/truncateText';
import {
  BoxContainer,
  ClearAllTypography,
  KeywordsMultiSelectWrapper,
  KeywordsWrapper,
  KeywordTitleTypography,
  TableWrapper,
} from 'src/views/Catalogs/CatalogSettings/styled';

import AttachedIntegrations from './AttachedIntegrations';
import CatalogPrivacyBlock from './CatalogPrivacyBlock';
import SharedOptions from './SharedOptions';
import { CatalogPrivacyPriority, CatalogSettingsState } from './types';

export interface SortData {
  orderBy?: Maybe<OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
}

interface CatalogueSettingsPage {
  initialCatalogData: Catalog;
}

const CatalogueSettingsPage = ({ initialCatalogData }: CatalogueSettingsPage) => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const snackbar = useSnackbar();
  const { updateCurrentExport } = useCatalogs();
  const limitReached = useLimitReached();
  const isSharedCatalogsFeatureActive = useFlag(FeatureFlag.SHARED_CATALOGS);

  const { checkCatalogMappingsErrorsQuery, loading: loadingErrors } = useCheckCatalogMappingErrors(id);

  const { inProgress: isLoading } = useDataTransferStatus({
    catalogId: id,
    onCompleted: () => checkCatalogMappingsErrorsQuery(),
  });

  const [isChangeCatalogPrivacyModalOpen, setIsChangeCatalogPrivacyModalOpen] = useState<boolean>(false);
  const [isUpdateSuccess, setIsUpdateSuccess] = useState<boolean>(false);
  const [isAddKeywordFieldOpen, setIsAddKeywordFieldOpen] = useState<boolean>(false);
  const [sortData, setSortData] = useState<SortData>({
    orderBy: null,
    orderDirection: null,
  });
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [updateLoading, setUpdateLoading] = useState<boolean>(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState<boolean>(false);
  const [targetIntegration, setTargetIntegration] = useState<string>('');
  const [mappingLoading, setMappingLoading] = useState<boolean>(false);

  const initialData: CatalogSettingsState = useMemo(() => {
    const {
      name: catalogName,
      keywords,
      config: { paymentFrequency, privacy, price, extraChargePercentage },
    } = initialCatalogData;

    return {
      paymentFrequency: {
        label: t(`settingsPage.sharingOptions.catalogOptions.paymentFrequency.${paymentFrequency}`),
        value: paymentFrequency,
      },
      price: price === null ? '0' : String(price),
      extraChargePercentage: extraChargePercentage === null ? '0' : String(extraChargePercentage),
      privacy,
      catalogName,
      keywords,
    };
  }, [t, initialCatalogData]);

  const [settingsState, setSettingsState] = useState<CatalogSettingsState>(initialData);

  const [catalogUpdate, { loading: loadingUpdateCatalog }] = useMutation(updateCatalog, {
    update: clearLocalCatalogsCache,
  });

  const initialKeywordsLength = Boolean(initialCatalogData.keywords.length);
  const settingsStateKeywordsLength = Boolean(settingsState.keywords.length);

  const showMultiSelectField = initialKeywordsLength || isAddKeywordFieldOpen;
  const showClearAllKeywordsButton = initialKeywordsLength || settingsStateKeywordsLength;

  const handleUpdateSubscriptions = (data: Pick<Query, 'getSubscriptions'>) =>
    setHasMore(data.getSubscriptions.length === SUBSCRIPTIONS_DEFAULT_LIMIT);

  const {
    data: subscriptions,
    loading: loadingSubscriptions,
    fetchMore,
  } = useQuery<Pick<Query, 'getSubscriptions'>, QueryGetSubscriptionsArgs>(getSubscriptionsByCatalogId, {
    fetchPolicy: 'network-only',
    variables: {
      catalogId: id,
      orderBy: sortData.orderBy,
      orderDirection: sortData.orderDirection,
      limit: SUBSCRIPTIONS_DEFAULT_LIMIT,
    },
    onCompleted: handleUpdateSubscriptions,
  });

  const { data: checkIsLimitReachedData } = useQuery(checkIsLimitReached, {
    fetchPolicy: 'network-only',
    variables: { type: LimitType.SharedCatalogs },
  });

  const {
    getListWithProposedMappings,
    getProposedMappingsLoading,
    renderMappingsSidebar,
    justOpenFirstTimeExportSidebar,
  } = useCheckProposedMappings({
    callbackFn: () => handleExportToIntegration(targetIntegration),
    closeCallbackFn: () => setMappingLoading(false),
    errorCallbackFn: checkCatalogMappingsErrorsQuery,
    secondaryButtonTitle: targetIntegration ? undefined : t('mappingSidebar.saveButton'),
    customSidebarTitle: targetIntegration
      ? undefined
      : t('mappingSidebar.catalogMappingsTitle', {
          catalogName: truncateText({ text: settingsState.catalogName, maxLength: MAX_SIDEBAR_SUBTITLE_LENGTH }),
        }),
  });

  const mappingsErrors = useMemo(
    () =>
      removeDuplicatedObjects(
        [
          ...(initialCatalogData.mappingsErrors || []),
          ...(initialCatalogData.integrations?.flatMap(
            ({ mappingsErrors }) => mappingsErrors.filter(({ catalogId }) => !catalogId || catalogId === id) || [],
          ) || []),
        ],
        'id',
      ),
    [id, initialCatalogData.integrations, initialCatalogData.mappingsErrors],
  );

  const showLimitReachedPopup = (value: CatalogPrivacy) =>
    Boolean(
      ![CatalogPrivacy.Protected, CatalogPrivacy.Public].includes(
        initialCatalogData.config.privacy as CatalogPrivacy,
      ) &&
        [CatalogPrivacy.Protected, CatalogPrivacy.Public].includes(value) &&
        checkIsLimitReachedData?.checkIsLimitReached,
    );

  const handleMainButtonPrivacyModalClick = async () => {
    await handleUpdateCatalogSettings();

    setIsChangeCatalogPrivacyModalOpen(false);
  };

  const onClickClearAllKeywordsPopup = () => setIsDeletePopupOpen(true);

  const handleCloseDeletePopup = () => setIsDeletePopupOpen(false);

  const isCatalogPrivate = settingsState.privacy === CatalogPrivacy.Private;

  const noSubscriptionsText = isCatalogPrivate
    ? t('settingsPage.noSubscriptionsPrivateCatalog')
    : t('settingsPage.noSubscriptions');

  const hasTableData = !!subscriptions?.getSubscriptions.length;

  const translatedPaymentFrequency = useMemo(
    () =>
      PAYMENT_FREQUENCY_OPTIONS.map((option) => {
        option.label = t(`settingsPage.sharingOptions.catalogOptions.paymentFrequency.${option.value}`);
        return option;
      }),
    [t],
  );

  const isShowModal = useMemo(
    () =>
      CatalogPrivacyPriority[initialCatalogData.config.privacy as keyof typeof CatalogPrivacyPriority] <
        CatalogPrivacyPriority[settingsState.privacy as keyof typeof CatalogPrivacyPriority] ||
      (initialCatalogData.config.privacy === CatalogPrivacy.Protected &&
        settingsState.privacy === CatalogPrivacy.Public),
    [initialCatalogData.config.privacy, settingsState.privacy],
  );

  const isDiscardChangesOpen = useMemo(() => {
    return JSON.stringify(settingsState) !== JSON.stringify(initialData) && !isUpdateSuccess;
  }, [settingsState, isUpdateSuccess, initialData]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;

    setSettingsState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleUpdatePriceValue = (value: string) => {
    setSettingsState((prevState) => ({
      ...prevState,
      price: value,
    }));
  };

  const handleUpdateExtraChargePercentageValue = (value: string) => {
    setSettingsState((prevState) => ({
      ...prevState,
      extraChargePercentage: value,
    }));
  };

  const setKeywordsState = (keywords: string[]) => {
    const setFuncStateValue = (prev: CatalogSettingsState) => ({
      ...prev,
      keywords,
    });

    setSettingsState(setFuncStateValue);
  };

  const handleChangeMultiSelect = (items: string[]) => {
    if (!items.length) setIsAddKeywordFieldOpen(false);

    setKeywordsState(items);
  };

  const handleChangeSelect = (option: SelectOptionItem) => {
    setSettingsState((prevState) => {
      return {
        ...prevState,
        paymentFrequency: {
          value: option.value,
          label: option.label,
        },
      };
    });
  };

  const onClickClearAllKeywords = () => {
    setKeywordsState([]);

    setIsAddKeywordFieldOpen(false);
  };

  const handleChangeRadio = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    if (showLimitReachedPopup(value as CatalogPrivacy)) {
      limitReached({ limitReachedType: LimitType.SharedCatalogs });
    } else {
      setSettingsState((prevState) => ({
        ...prevState,
        privacy: value as CatalogPrivacy,
      }));
    }
  };

  const handleSaveButtonClick = () => {
    isShowModal ? setIsChangeCatalogPrivacyModalOpen(true) : handleUpdateCatalogSettings();
  };

  const handleUpdateCatalogSettings = async () => {
    const { paymentFrequency, price, extraChargePercentage, privacy, catalogName } = settingsState;

    setUpdateLoading(true);

    try {
      await catalogUpdate({
        variables: {
          data: {
            keywords: settingsState.keywords || [],
            catalogId: id,
            catalogName: catalogName.trimEnd(),
            config: {
              privacy,
              price: Number(price),
              paymentFrequency: paymentFrequency.value,
              extraChargePercentage: Number(extraChargePercentage),
            },
          },
        },
      });

      setIsUpdateSuccess(true);

      snackbar(t('settingsPage.settingsUpdateSuccess'), 'success');
      history.push(`/${FIRST_DASHBOARD_PATHNAME}`);
    } catch (error) {
      const { graphQLErrors, message: errorText } = error as ApolloError;
      const message = graphQLErrors && graphQLErrors.length ? graphQLErrors[0].message : errorText;

      if (error) {
        snackbar(message);
      }
    }

    setUpdateLoading(false);
  };

  const onCancelButtonClick = () => history.push(`/${FIRST_DASHBOARD_PATHNAME}`);

  const fetchMoreTableRows = useCallback(async () => {
    const offset = subscriptions?.getSubscriptions.length || 0;

    if (!fetchMore) return;

    handleUpdateSubscriptions(
      (
        await fetchMore<'offset'>({
          variables: {
            offset,
          },
        })
      ).data,
    );
  }, [fetchMore, subscriptions?.getSubscriptions.length]);

  const handleSort = (orderBy: OrderBy, orderDirection?: OrderDirection, e?: SyntheticEvent<HTMLButtonElement>) => {
    if (orderDirection) {
      setSortData(({ orderDirection: prevOrderDirection, orderBy: prevOrderBy }: SortData) => {
        const sortCondition = prevOrderDirection === orderDirection && prevOrderBy === orderBy;

        return {
          orderBy: sortCondition ? null : orderBy,
          orderDirection: sortCondition ? null : orderDirection,
        };
      });
    } else if (e) {
      e.stopPropagation();

      if (sortData.orderBy !== orderBy) {
        setSortData(() => ({
          orderBy,
          orderDirection: OrderDirection.Desc,
        }));
      } else {
        setSortData(({ orderDirection: prevOrderDirection }: SortData) => ({
          orderBy: orderBy,
          orderDirection: prevOrderDirection === OrderDirection.Asc ? OrderDirection.Desc : OrderDirection.Asc,
        }));
      }
    }
  };

  const [exportCatalog] = useMutation<Pick<Mutation, 'exportCatalog'>, MutationExportCatalogArgs>(
    exportCatalogToIntegration,
  );

  const handleSyncIntegration = (integrationId: string) => {
    if (!initialCatalogData.isEmpty) {
      setTargetIntegration(integrationId);

      getListWithProposedMappings(id, integrationId);
    }
  };

  const handleClickMappings = () => {
    if (!mappingLoading) {
      setTargetIntegration('');

      setMappingLoading(true);

      justOpenFirstTimeExportSidebar(id);
    }
  };

  const handleExportToIntegration = async (integrationId: string) => {
    setMappingLoading(false);

    try {
      if (integrationId) {
        updateCurrentExport(id, false);

        await exportCatalog({ variables: { integrationId, id } });
      } else {
        checkCatalogMappingsErrorsQuery();
      }
    } catch (error) {
      checkCatalogMappingsErrorsQuery();
      const { graphQLErrors, message: errorText } = error as ApolloError;
      const message = graphQLErrors && graphQLErrors.length ? graphQLErrors[0].message : errorText;
      if (error) {
        snackbar(message);
      }
    }
  };

  const catalogName = initialCatalogData.name;
  const locationName = catalogName ? `${t('settingsPage.title')} "${catalogName}"` : '';

  const formMethods = useForm({
    mode: 'onChange',
  });

  const mappingButtonLoading = isLoading || mappingLoading || loadingErrors;

  return (
    <Page title={t('settingsPage.title')} id="catalogSettingPageId" locationName={locationName}>
      <BoxContainer>
        <FormProvider {...formMethods}>
          <Box mb="25px">
            <DashboardBreadCrumbs editingName={settingsState?.catalogName} />
          </Box>

          <CatalogsSettingsHeader
            onChange={handleChange}
            title={t('settingsPage.title')}
            settingsState={settingsState}
            updateLoading={updateLoading}
            onSaveButtonClick={handleSaveButtonClick}
            onCancelButtonClick={onCancelButtonClick}
            onMappingsButtonClick={handleClickMappings}
            startCatalogName={initialCatalogData.name}
            initialCatalogData={initialCatalogData}
            mappingButtonLoading={mappingButtonLoading}
          />

          {isSharedCatalogsFeatureActive && (
            <KeywordsWrapper>
              <Box display="flex" alignItems="center">
                <KeywordTitleTypography>{t('settingsPage.keywordsFieldTitle')}</KeywordTitleTypography>

                <Box>
                  {showClearAllKeywordsButton && (
                    <ClearAllTypography onClick={onClickClearAllKeywordsPopup} data-testid="clearAllKeywords">
                      {t('settingsPage.clearAllKeywords')}
                    </ClearAllTypography>
                  )}

                  {!showMultiSelectField && (
                    <SmallAddButton
                      testId="smallAddButtonKeywords"
                      isGradiented
                      onClick={() => setIsAddKeywordFieldOpen(true)}
                    />
                  )}
                </Box>
              </Box>

              {showMultiSelectField && (
                <KeywordsMultiSelectWrapper>
                  <MultiSelect
                    testId="keywordsMultiSelect"
                    chipMaxWidth="410px"
                    maxHeight={58}
                    selectedItems={settingsState.keywords}
                    onChange={handleChangeMultiSelect}
                    label={t('settingsPage.keywordsFieldTitle')}
                    inputIdentifier={'keywords'}
                  />
                </KeywordsMultiSelectWrapper>
              )}
            </KeywordsWrapper>
          )}

          <AttachedIntegrations
            isEmptyCatalog={initialCatalogData.isEmpty}
            duplicatedSKUInfo={initialCatalogData.duplicatedSKUInfo}
            integrations={initialCatalogData.integrations || []}
            mappingErrors={mappingsErrors}
            syncIntegration={handleSyncIntegration}
            getProposedMappingsLoading={getProposedMappingsLoading}
            targetIntegration={targetIntegration}
          />

          {isSharedCatalogsFeatureActive && (
            <>
              <CatalogPrivacyBlock
                settingsState={settingsState}
                onChangeRadio={handleChangeRadio}
                checkLimit={!!checkIsLimitReachedData?.checkIsLimitReached}
                currentPrivacy={initialCatalogData.config.privacy}
              />

              {!isCatalogPrivate && (
                <SharedOptions
                  settingsState={settingsState}
                  translatedPaymentFrequency={translatedPaymentFrequency}
                  setSettingsState={setSettingsState}
                  onChangeSelect={handleChangeSelect}
                  onUpdatePriceValue={handleUpdatePriceValue}
                  onUpdateExtraChargePercentageValue={handleUpdateExtraChargePercentageValue}
                />
              )}

              {hasTableData && !isCatalogPrivate ? (
                <TableWrapper>
                  <CatalogConfigTable
                    handleSort={handleSort}
                    sortData={sortData}
                    subscriptionsData={subscriptions?.getSubscriptions}
                    loadingSubscriptions={loadingSubscriptions}
                    catalogName={settingsState.catalogName}
                    onFetchMoreTableRows={fetchMoreTableRows}
                    hasMore={hasMore}
                  />
                </TableWrapper>
              ) : (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height="100hv"
                  marginTop={isCatalogPrivate ? '98px' : '40px'}
                >
                  <Typography variant="body1" maxWidth={isCatalogPrivate ? '544px' : '250px'} textAlign="center">
                    {noSubscriptionsText}
                  </Typography>
                </Box>
              )}
            </>
          )}
        </FormProvider>

        <ButtonScrollToTop intersection={document.documentElement.clientHeight} />
      </BoxContainer>

      <ChangeCatalogPrivacyModal
        privacy={settingsState.privacy}
        isModalOpen={isChangeCatalogPrivacyModalOpen}
        setIsModalOpen={setIsChangeCatalogPrivacyModalOpen}
        onMainButtonClick={handleMainButtonPrivacyModalClick}
        loading={loadingUpdateCatalog}
      />

      <DiscardChangesPopup isOpen={isDiscardChangesOpen} />

      {isDeletePopupOpen && (
        <Popup
          open={isDeletePopupOpen}
          mainButtonText={t('settingsPage.deleteAllKeywordsPopup.buttonDelete')}
          secondaryButtonText={t('settingsPage.deleteAllKeywordsPopup.buttonClose')}
          onClose={handleCloseDeletePopup}
          onSecondaryButtonClick={handleCloseDeletePopup}
          onMainButtonClick={onClickClearAllKeywords}
          mainTitle={t('settingsPage.deleteAllKeywordsPopup.title')}
          mainTitleWidth="336px"
          titleDescription={t('settingsPage.deleteAllKeywordsPopup.description')}
          headerMarginAbsence
        />
      )}

      {renderMappingsSidebar()}
    </Page>
  );
};

const CatalogueSettings = () => {
  const { id } = useParams<{ id: string }>();
  const { updateCatalogsJobsMap } = useCatalogs();

  const { data, loading } = useQuery<Pick<Query, 'getCatalogById'>, QueryGetCatalogByIdArgs>(getCatalogInfo, {
    fetchPolicy: 'cache-first',
    variables: { id },
    onCompleted: (data) => {
      updateCatalogsJobsMap(data.getCatalogById.lastIntegrationsJobs);
    },
  });

  return loading || !data ? (
    <Loader fullAvailableScreen />
  ) : (
    <CatalogueSettingsPage initialCatalogData={data.getCatalogById} />
  );
};

export default CatalogueSettings;
