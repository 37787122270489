import React from 'react';
import { Trans } from 'react-i18next';

import { Box, makeStyles, Theme, Typography } from '@material-ui/core';

import { EXTERNAL_URL } from 'src/constants';

import { StyledUpgradeLink } from './Headers/styled';
import Close from './Images/Close.svg';

const useStyles = makeStyles<Theme, { showBanner: boolean }>((theme) => ({
  box: {
    display: ({ showBanner }) => (showBanner ? 'flex' : 'none'),
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '76px',
    background: theme.palette.background.default,
    borderRadius: '5px',
    marginTop: '10px',
    position: 'absolute',
  },

  closeButton: {
    position: 'absolute',
    right: '56px',
    width: '34px',
    height: '34px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    borderRadius: '50%',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: `0 0 15px ${theme.palette.secondary.main}`,
    },
    '&:active': {
      boxShadow: 'none',
      outline: 'none',
    },
  },
  typographyLink: {
    fontSize: '14px',
    lineHeight: '16px',
    color: theme.palette.primary.light,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  typography: {
    fontSize: '14px',
    lineHeight: '16px',
    color: theme.palette.primary.light,
  },
}));

interface DemoStandBannerProps {
  showBanner: boolean;
  handleClose: () => void;
}

const DemoStandBanner = ({ showBanner, handleClose }: DemoStandBannerProps) => {
  const classes = useStyles({ showBanner });

  return (
    <Box className={classes.box}>
      <Box onClick={handleClose} className={classes.closeButton} data-testid="closeBannerButton">
        <img src={Close} alt="close" />
      </Box>
      <Typography className={classes.typography}>
        <Trans i18nKey="demoStandBanner.text">
          Go to our
          <StyledUpgradeLink href={EXTERNAL_URL.CHANGELOG}>changelog page</StyledUpgradeLink>
          to see the updates.
        </Trans>
      </Typography>
    </Box>
  );
};

export default DemoStandBanner;
