import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { Link, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';

import { theme } from 'src/theme';
import { locationsData } from 'src/utils/locationArrays';

import { StyledRecentStepsText } from './styled';

const COUNT_RECENT_STEPS = 2;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.palette.text.secondary,
    marginBottom: '15px',
  },
  disabled: {
    color: theme.palette.action.disabled,
    pointerEvents: 'none',
    cursor: 'default',
  },
}));

interface RecentStepsProps {
  onClose: () => void;
}

const RecentSteps = ({ onClose }: RecentStepsProps) => {
  const resultsSteps = locationsData?.slice(-1 - COUNT_RECENT_STEPS, -1);
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      {resultsSteps?.map(({ link, title, presence, id }) => {
        const name = title?.match(/".+/g);
        const location = title?.match(/^([\w ]+)/g);

        return (
          <Link
            key={id}
            to={link}
            component={RouterLink}
            onClick={onClose}
            variant="subtitle2"
            className={clsx(!presence && classes.disabled, classes.root)}
          >
            {location}
            <StyledRecentStepsText
              sx={{
                color: presence ? theme.palette.text.secondary : theme.palette.action.disabled,
              }}
            >
              {presence ? name : `${name} (${t('navBar.contextMenu.recentStepsTitles.itemDeleted')})`}
            </StyledRecentStepsText>
          </Link>
        );
      })}
    </>
  );
};

export default RecentSteps;
