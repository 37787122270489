import { useTranslation } from 'react-i18next';

import { AlertProps } from '@mui/material';

import { useSnackbar } from 'src/providers/snackbar';
import { CatalogJob, CatalogJobType } from 'src/utils/gql';

const useDataTransferCompletedSnackbar = (productTypeExport?: boolean) => {
  const { t } = useTranslation();
  const snackbar = useSnackbar();

  const showSnackbar = (job: CatalogJob) => {
    let message = '';

    switch (job.type) {
      case CatalogJobType.Import:
        message = t(`activeJobSnackbar.${job.error ? 'importError' : 'importSuccess'}`);
        break;

      case CatalogJobType.Export:
        message =
          job.error || t(`activeJobSnackbar.${productTypeExport ? 'productTypeExportSuccess' : 'exportSuccess'}`);
        break;
    }

    const severity: AlertProps['severity'] = job.error ? 'error' : 'success';

    snackbar(message, severity);
  };

  return { showDataTransferCompletedSnackbar: showSnackbar };
};

export default useDataTransferCompletedSnackbar;
