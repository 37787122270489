import React, { SyntheticEvent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, makeStyles, Theme } from '@material-ui/core';

import useDuplicatedSKUFromIntegrationMappingErrors from 'src/hooks/useDuplicatedSKUFromIntegrationMappingErrors';
import { IntegrationMappingError } from 'src/utils/gql';

import DuplicatedSKUMessage, { DuplicatedSKUMessageType } from '../DuplicatedSKUMessage';
import Hint, { HintType } from '../Hint';

import ErrorableSyncButtonChild, {
  ErrorableSyncButtonState,
  ErrorableSyncButtonParams,
} from './ErrorableSyncButtonChild';

const useStyles = makeStyles<Theme>(() => ({
  iconContainer: {
    display: 'flex',
    '& .MuiButtonBase-root': {
      padding: 0,
    },
  },
}));

export enum TooltipPlacements {
  bottomEnd = 'bottom-end',
  bottomStart = 'bottom-start',
  bottom = 'bottom',
  leftEnd = 'left-end',
  leftStart = 'left-start',
  left = 'left',
  rightEnd = 'right-end',
  rightStart = 'right-start',
  right = 'right',
  topEnd = 'top-end',
  topStart = 'top-start',
  top = 'top',
}

const DEFAULT_TOOLTIP_WIDTH = '170px';

interface TooltipParams {
  customTooltipContent?: React.ReactNode;
  placement?: TooltipPlacements;
}

interface ErrorableSyncButtonProps {
  catalogId: string;
  disabled?: boolean;
  onClick?: (e: SyntheticEvent) => void;
  errors?: IntegrationMappingError[];
  tooltipParams?: TooltipParams;
  iconParams?: ErrorableSyncButtonParams;
  dataTestId?: string;
  tooltipWidth?: string;
  isDisabledDueToErrors?: boolean;
}

const generateTooltipText = (customTooltipContent?: React.ReactNode, errorText?: string) =>
  customTooltipContent ?? errorText ?? '';

const ErrorableSyncButton = ({
  catalogId,
  errors,
  tooltipParams,
  iconParams,
  disabled,
  onClick,
  dataTestId,
  tooltipWidth,
  isDisabledDueToErrors,
}: ErrorableSyncButtonProps) => {
  const { t } = useTranslation();
  const styles = useStyles();

  const btnState = disabled
    ? ErrorableSyncButtonState.DISABLE
    : errors?.length
    ? ErrorableSyncButtonState.ERROR
    : ErrorableSyncButtonState.NORMAL;
  const errorText = useMemo(() => {
    return errors?.length ? t(`productType.mapping.${errors?.[0].reason}`) : '';
  }, [errors, t]);
  const duplicatedSKUInfo = useDuplicatedSKUFromIntegrationMappingErrors(errors);
  const message =
    duplicatedSKUInfo.isDuplicated && !tooltipParams?.customTooltipContent ? (
      <DuplicatedSKUMessage
        type={DuplicatedSKUMessageType.sync}
        catalogId={catalogId}
        duplicatedSKUInfo={duplicatedSKUInfo}
      />
    ) : (
      generateTooltipText(tooltipParams?.customTooltipContent, errorText)
    );

  return (
    <Hint
      title={message}
      arrow
      tooltipWidth={tooltipWidth || DEFAULT_TOOLTIP_WIDTH}
      type={HintType.hover}
      placement={tooltipParams?.placement ?? 'left'}
      classes={{ tooltip: styles.tooltip, arrow: styles.arrow }}
    >
      <Box className={styles.iconContainer}>
        <ErrorableSyncButtonChild
          iconParams={iconParams}
          onClick={onClick}
          state={btnState}
          dataTestId={dataTestId}
          isDisabledDueToErrors={isDisabledDueToErrors}
        />
      </Box>
    </Hint>
  );
};

export default ErrorableSyncButton;
