import React from 'react';

import MuiSvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const gradientBackgroundUrl = 'url(#paint0_linear)';

function Icon(props: SvgIconProps) {
  if (props.htmlColor === 'secondary' && React.isValidElement(props.children)) {
    return (
      <MuiSvgIcon {...props}>
        {React.cloneElement(props.children as React.ReactElement, { style: { fill: gradientBackgroundUrl } })}
        <defs>
          <linearGradient id="paint0_linear" gradientUnits="userSpaceOnUse">
            <stop stopColor="#F16152" />
            <stop offset="1" stopColor="#F99746" />
          </linearGradient>
        </defs>
      </MuiSvgIcon>
    );
  } else {
    return <MuiSvgIcon {...props}>{props.children}</MuiSvgIcon>;
  }
}

export default Icon;
