import React, { createRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  MenuItem,
  Typography,
  makeStyles,
  MenuList,
  Fade,
  ClickAwayListener,
  FormControl,
  Theme,
} from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';

import Iconography from 'src/components/Iconography';
import useUploadMediaBrowseUrl from 'src/hooks/useUploadMediaBrowseUrl';

import { AVAILABLE_FORMATS } from './constants';

const useStyles = makeStyles<Theme, { displayMenu: boolean }>((theme) => ({
  menu: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingBottom: '5px',
    width: '209px',
  },
  mainBlockMenu: {
    cursor: 'pointer',
    height: '24px',
    fontSize: '15px',
  },
  arrow: {
    cursor: 'pointer',
  },
  menuList: {
    '& .MuiList-root': {
      borderRadius: '4px',
      border: `1px solid ${theme.palette.secondary.main}`,
      background: theme.palette.common.white,
      padding: '5px',
      zIndex: 2,
      display: ({ displayMenu }) => (displayMenu ? 'block' : 'none'),
    },

    '& .MuiListItem-root': {
      borderRadius: '5px',
      fontSize: '15px',
      lineHeight: '18px',
      height: '38px',

      '&:hover': {
        background: theme.palette.secondary.dark,
        color: theme.palette.common.white,
      },
    },
  },
}));

interface MediaMenu {
  handleOpenAddUrlPopup: () => void;
  handleOpenMediaGallery: () => void;
}

const MediaMenu = ({ handleOpenAddUrlPopup, handleOpenMediaGallery }: MediaMenu) => {
  const { t } = useTranslation();
  const { handleUploadMediaBrowse } = useUploadMediaBrowseUrl();

  const [isMediaMenuOpen, setIsMediaMenuOpen] = useState<boolean>(false);

  const classes = useStyles({ displayMenu: isMediaMenuOpen });

  const inputFile = createRef<HTMLInputElement>();

  const handleCloseMenu = () => {
    setIsMediaMenuOpen(false);
  };

  const handleOpenBrowse = () => {
    inputFile.current?.click();
    setIsMediaMenuOpen(false);
  };

  const handleOpenMenu = () => {
    setIsMediaMenuOpen(!isMediaMenuOpen);
  };

  const menuList = [
    {
      action: handleOpenMediaGallery,
      label: 'productItemCreateEdit.mediaGallery.mediaMenuList.linkAddFromLibrary',
      id: uuidv4(),
    },
    {
      action: handleOpenBrowse,
      label: 'productItemCreateEdit.mediaGallery.mediaMenuList.linkAddFromBrowse',
      id: uuidv4(),
    },
    {
      action: handleOpenAddUrlPopup,
      label: 'productItemCreateEdit.mediaGallery.mediaMenuList.linkAddFromURL',
      id: uuidv4(),
    },
  ];

  return (
    <ClickAwayListener onClickAway={handleCloseMenu}>
      <Box display="flex" flexDirection="column" data-testid="mediaMenu">
        <input
          type="file"
          hidden
          multiple
          ref={inputFile}
          accept={AVAILABLE_FORMATS.join(', ')}
          onChange={handleUploadMediaBrowse}
        />

        <FormControl className={classes.menuList}>
          <Box onClick={handleOpenMenu} role="button" className={classes.menu} data-testid="mediaMenuButton">
            <Typography className={classes.mainBlockMenu}>
              {t('productItemCreateEdit.mediaGallery.mediaMenu')}
            </Typography>
            <Iconography iconName="expand-chevron" className={classes.arrow} />
          </Box>
          <Fade in={isMediaMenuOpen}>
            <MenuList data-testid="mediaMenuList">
              {menuList.map(({ action, label, id }) => (
                <MenuItem key={id} onClick={action} data-testid={`menuItem${label}`}>
                  {t(label)}
                </MenuItem>
              ))}
            </MenuList>
          </Fade>
        </FormControl>
      </Box>
    </ClickAwayListener>
  );
};

export default MediaMenu;
