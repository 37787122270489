import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Checkbox, makeStyles, Button, Theme, Popper, Paper, ClickAwayListener } from '@material-ui/core';

import Iconography from 'src/components/Iconography';
import { StyledFormControlLabel } from 'src/components/SideBar/styled';

import ExtraOptions from './ExtraOptions';

const VALUES_OFFSET = 4;
const MIN_VALUES_TO_SPLIT = 6;

const useStyles = makeStyles((theme: Theme) => ({
  dropdownDefault: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    '& .MuiFormControlLabel-label': {
      wordBreak: 'break-all',
      textTransform: 'none',
      '&::first-letter': {
        textTransform: 'capitalize',
      },
    },
  },
  checkboxWrapper: {
    marginBottom: '15px',
    position: 'relative',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  moreOptionsBtn: {
    width: '320px',
    borderRadius: '2px',
    border: `solid 1px ${theme.palette.secondary.main}`,
    padding: '12px 20px',
    boxSizing: 'border-box',
  },
  btnLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  menuRoot: {
    marginLeft: '15px',
  },
  checkboxesContainer: {
    display: 'flex',
    width: '360px',
    height: '308px',
    overflowY: 'auto',
    padding: '30px 30px 30px 54px',
    boxSizing: 'border-box',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  paper: {
    marginLeft: '15px',
    '&.MuiPaper-root': {
      boxShadow: '0 2px 10px rgba(46, 96, 170, 0.25)',
    },
  },
}));

interface Props {
  title: string;
  selectedValues: string[];
  values: string[];
  onChange: (values: string[]) => void;
}

const Options = ({ title, values, selectedValues, onChange }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState<boolean>(false);

  const handleClick = () => {
    setOpen((prevState) => !prevState);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeCheckbox = (value: string) => {
    const newValues = [...selectedValues];

    const valueIndex = newValues.indexOf(value);

    if (valueIndex === -1) {
      newValues.push(value);
    } else {
      newValues.splice(newValues.indexOf(value), 1);
    }

    onChange(newValues);
  };

  const valuesLength = values.length;
  const valuesAfter = values.slice(VALUES_OFFSET);
  const valuesAfterLength = valuesAfter?.length;
  const splitIndex = valuesAfterLength > MIN_VALUES_TO_SPLIT ? Math.ceil(valuesAfterLength / 2) : valuesAfterLength;

  return (
    <Box className={classes.dropdownDefault}>
      {values.slice(0, VALUES_OFFSET).map((value, idx) => (
        <Box className={classes.checkboxWrapper} key={idx}>
          <StyledFormControlLabel
            control={
              <Checkbox
                onChange={() => handleChangeCheckbox(value)}
                checked={selectedValues.includes(value)}
                inputProps={
                  { 'data-testid': `${value}${title}Checkbox` } as React.InputHTMLAttributes<HTMLInputElement>
                }
              />
            }
            label={value}
          />
        </Box>
      ))}
      {valuesLength > VALUES_OFFSET && (
        <>
          <Button
            variant="outlined"
            classes={{ root: classes.moreOptionsBtn }}
            onClick={handleClick}
            ref={anchorRef}
            data-testid={`more${title}OptionsButton`}
          >
            <div className={classes.btnLabel}>
              <span>
                {t('productItemsPage.other')} {valuesAfterLength} {title.toLowerCase()}
              </span>
              <Iconography iconName="chevron-arrow-right" htmlColor="secondary" />
            </div>
          </Button>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            placement="right-end"
            disablePortal
          >
            <Paper classes={{ root: classes.paper }}>
              <ClickAwayListener onClickAway={handleClose}>
                <div className={classes.checkboxesContainer}>
                  <ExtraOptions
                    itemS={valuesAfter.slice(0, splitIndex)}
                    handleChangeCheckbox={handleChangeCheckbox}
                    selectedValues={selectedValues}
                  />
                  <ExtraOptions
                    itemS={valuesAfter.slice(splitIndex)}
                    handleChangeCheckbox={handleChangeCheckbox}
                    selectedValues={selectedValues}
                    title={title}
                  />
                </div>
              </ClickAwayListener>
            </Paper>
          </Popper>
        </>
      )}
    </Box>
  );
};

export default Options;
