import React, { KeyboardEvent, SyntheticEvent } from 'react';

import { IconButton } from '@mui/material';

import SyncIcon from '../Icon/SyncIcon';

interface ErrorableSyncButtonNormalProps {
  disabled: boolean;
  width?: number;
  height?: number;
  onClick?: (e: SyntheticEvent) => void;
  onKeyboardButtonPressed: (e: KeyboardEvent<HTMLElement>) => void;
  dataTestId?: string;
  fillColor: string;
}

const ErrorableSyncButtonNormal = ({
  disabled,
  width,
  height,
  onClick,
  onKeyboardButtonPressed,
  dataTestId,
  fillColor,
}: ErrorableSyncButtonNormalProps) => (
  <IconButton
    id="sync-btn-anchor"
    data-testid={dataTestId}
    disabled={disabled}
    onClick={onClick}
    onKeyPress={onKeyboardButtonPressed}
  >
    <SyncIcon fillColor={fillColor} width={width} height={height} />
  </IconButton>
);

export default ErrorableSyncButtonNormal;
