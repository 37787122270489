import { useState } from 'react';

import { ApolloCache, ApolloError, Reference, StoreObject, useMutation } from '@apollo/client';

import {
  cancelSubscription,
  checkIsLimitReached,
  createSubscription,
  getCatalogInfo,
  LimitType,
  updateSubscription,
} from 'src/utils/gql';

import { useSnackbar } from '../providers/snackbar';

interface useControlSubscriptionsProps {
  catalogId?: string;
  subscriptionId?: string | null;
  status?: string;
}

const clearCatalogsCacheBesidesShared = (cache: ApolloCache<unknown>) => {
  cache.modify({
    fields: {
      getCatalogs: (fieldValue, { storeFieldName, DELETE }) => {
        if (storeFieldName.match(/^getCatalogs:{.*"types":\["shared"].*}$/)) {
          return fieldValue;
        }

        return DELETE;
      },
    },
  });
};

const useControlSubscriptions = (props: useControlSubscriptionsProps) => {
  const snackbar = useSnackbar();

  const { catalogId, subscriptionId, status } = props;
  const [loading, setLoading] = useState<boolean>(false);

  const [cancelUserSubscription] = useMutation(cancelSubscription, {
    onCompleted: () => {
      setLoading(false);
    },
  });

  const [requestSubscription] = useMutation(createSubscription, {
    onCompleted: () => {
      setLoading(false);
    },
  });

  const [updateSubscriptionStatus] = useMutation(updateSubscription);

  const checkIsLimitReachedQueryRefetchVariables = {
    query: checkIsLimitReached,
    variables: { type: LimitType.Subscriptions },
  };

  const refetchQueries = [
    checkIsLimitReachedQueryRefetchVariables,
    {
      query: getCatalogInfo,
      variables: { id: catalogId },
    },
  ];

  return {
    onCancelSubscription: async () => {
      setLoading((prevStatus) => !prevStatus);

      await cancelUserSubscription({
        variables: {
          subscriptionId,
        },
        refetchQueries,

        update(cache) {
          cache.modify({
            fields: {
              getSubscriptions(existingSubsRef, { readField }) {
                return existingSubsRef.filter(
                  (ref: Reference | StoreObject | undefined) => subscriptionId !== readField('id', ref),
                );
              },
            },
          });

          clearCatalogsCacheBesidesShared(cache);
        },
      });
    },

    onRequestSubscription: async () => {
      try {
        setLoading((prevStatus) => !prevStatus);

        await requestSubscription({
          variables: {
            catalogId,
          },
          refetchQueries,
          update: clearCatalogsCacheBesidesShared,
        });
      } catch (error) {
        const { graphQLErrors, message: errorText } = error as ApolloError;
        const message = graphQLErrors && graphQLErrors.length ? graphQLErrors[0].message : errorText;

        if (error) {
          snackbar(message);
        }
      }
    },

    onUpdateSubscription: async () => {
      await updateSubscriptionStatus({
        variables: {
          data: {
            subscriptionId,
            status,
          },
        },
        refetchQueries: [checkIsLimitReachedQueryRefetchVariables],

        update(cache) {
          cache.evict({ fieldName: 'getSubscriptions' });
        },
      });
    },
    loadingStatus: loading,
  };
};

export default useControlSubscriptions;
