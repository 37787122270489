import { Box, DialogContentText, Typography } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';

import Page from 'src/components/Page';

export const Wrapper = styled(Page)`
  && #sideBarContainer {
    padding: 0 32px;
  }
`;

export const ProductItemsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  margin-left: 40px;
  margin-right: 56px;
  height: 77vh;
  scrollbar-width: thin;
`;

export const CSVSidebarWrapper = styled(Box)`
  max-width: 616px;

  &.expanded-width {
    width: 100%;
  }
`;

export const ItemsWrapper = styled(Box)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  grid-column-gap: 24px;
  grid-row-gap: 25px;
  padding: 0px 56px 0px 42px;
`;

export const ItemsWrapperWithHiddenScroll = styled(ItemsWrapper)`
  overflow: hidden;
  padding: 0 17px 10px 10px;
  margin: 0 40px 0 30px;
`;

export const Header = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 71px 0 30px 0;
  grid-column-start: 1;
  grid-column-end: -1;
  min-width: 308px;
`;

export const MainContainer = styled(Box)`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

export const InitialPreviewWrapper = styled(Box)`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const InitialPreviewHeader = styled(Box)`
  margin: 71px 0 30px 0;
  background: ${({ theme: { palette } }) => palette.text.disabled};
  box-shadow: 0 2px 10px rgba(46, 96, 170, 0.25);
  border-radius: 5px;
  max-width: 280px;
  width: 100%;
  min-height: 23px;
  opacity: 0.25;
  grid-column-start: 1;
  grid-column-end: -1;
`;

export const InitialPreviewItem = styled(Box)`
  min-width: 280px;
  height: 351px;
  background: ${({ theme: { palette } }) => palette.text.disabled};
  box-shadow: 0 2px 10px rgba(46, 96, 170, 0.25);
  opacity: 0.25;
  border-radius: 5px;
`;

export const InitialPreviewInfoWrapper = styled(Box)`
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  padding-left: 10px;
  padding-right: 17px;
  justify-content: center;
`;

export const InitialPreviewInfoContainer = styled(Box)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  max-width: 436px;
  width: 100%;
`;

export const InitialPreviewInfoContainerResult = styled(InitialPreviewInfoContainer)`
  position: absolute;
  max-width: 532px;
  bottom: 60px;
`;

export const ViewAllItemsButtonWrapper = styled(Box)`
  text-align: center;
  position: inherit;
  left: 0;
  right: 0;
  bottom: 20px;
`;

export const StyledDialogText = styled(DialogContentText)`
  white-space: pre;
  max-width: 328px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 0 !important; // To not break styles for every popup
`;

export const PreviewItemName = styled(Typography)`
  word-break: break-all;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
`;
