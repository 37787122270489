import React, { ChangeEvent } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { RadioGroup, Box, Typography, Tooltip } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import { StyledRadioButton, StyledUpgradeLink } from 'src/components/Headers/styled';
import { CATALOG_PRIVACY_DATA, linkPricingPlan } from 'src/constants';
import { CatalogPrivacy } from 'src/utils/gql';

import { CatalogSettingsState } from './types';

const useStyles = makeStyles((theme) =>
  createStyles({
    arrow: {
      fontSize: '21px !important',
      '&:before': {
        backgroundColor: `${theme.palette.background.default} !important`,
        boxShadow: '0 2px 10px rgba(46, 96, 170, 0.25)',
      },
    },
    tooltip: {
      maxWidth: '357px',
      top: '-23px',
      padding: '10px 20px',
      fontFamily: 'Roboto',
      fontWeight: '400',
      backgroundColor: theme.palette.common.white,
      boxShadow: '0 2px 10px rgba(46, 96, 170, 0.25)',
      fontSize: '12px',
      lineHeight: '14px',
      color: theme.palette.primary.light,
    },
  }),
);

interface CatalogPrivacyBlockProps {
  settingsState: CatalogSettingsState;
  checkLimit?: boolean;
  onChangeRadio: (event: ChangeEvent<HTMLInputElement>) => void;
  currentPrivacy?: string;
}

const CatalogPrivacyBlock = ({
  settingsState,
  onChangeRadio,
  checkLimit,
  currentPrivacy,
}: CatalogPrivacyBlockProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const shouldBeDisabled = checkLimit && currentPrivacy === CatalogPrivacy.Private;

  return (
    <RadioGroup value={settingsState?.privacy} onChange={onChangeRadio}>
      <Box display="flex" flexWrap="wrap" mt="20px">
        {CATALOG_PRIVACY_DATA.map(({ value, title, description, testId }) => (
          <Box display="flex" mr="24px" key={value} minWidth="184px">
            {shouldBeDisabled ? (
              <>
                {value === CatalogPrivacy.Private ? (
                  <StyledRadioButton data-testid={testId} value={value} name="catalog-privacy" />
                ) : (
                  <Tooltip
                    classes={{
                      arrow: classes.arrow,
                      tooltip: classes.tooltip,
                    }}
                    title={
                      <Trans i18nKey="settingsPage.hint">
                        Shared catalog number has reached the limit.
                        <StyledUpgradeLink href={linkPricingPlan}>Upgrade your plan</StyledUpgradeLink>
                        to make an unlimited number of shared catalogs or change access settings in other catalogs.
                      </Trans>
                    }
                    placement="right"
                    arrow
                  >
                    <Box display="flex">
                      <StyledRadioButton disabled data-testid={testId} value={value} name="catalog-privacy" />
                    </Box>
                  </Tooltip>
                )}
              </>
            ) : (
              <StyledRadioButton data-testid={testId} value={value} name="catalog-privacy" />
            )}
            <Box ml="15px" maxWidth="258px">
              <Typography variant="subtitle2">{t(title)}</Typography>
              <Typography variant="caption" display="block" color="text.primary" mt="5px">
                {t(description)}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </RadioGroup>
  );
};

export default CatalogPrivacyBlock;
