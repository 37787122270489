import {
  EbayDefaultSettings,
  FacebookDefaultSettings,
  ShopifyDefaultSettings,
  WooCommerceDefaultSettings,
} from 'src/constants';
import { IntegrationTypes } from 'src/utils/gql';

import {
  ebayIntegrationFormSchema,
  facebookIntegrationFormSchema,
  shopifyIntegrationFormSchema,
  wooCommerceFormSchema,
} from '../Forms/validationSchemas';

export function getParametersByIntegrationType(type: IntegrationTypes) {
  switch (type) {
    case IntegrationTypes.Shopify:
      return {
        integrationSettings: ShopifyDefaultSettings,
        popupTitle: 'Shopify',
        formSchema: shopifyIntegrationFormSchema,
      };
    case IntegrationTypes.Woocommerce:
      return {
        integrationSettings: WooCommerceDefaultSettings,
        popupTitle: 'WooCommerce',
        formSchema: wooCommerceFormSchema,
      };
    case IntegrationTypes.Facebook:
      return {
        formSchema: facebookIntegrationFormSchema,
        integrationSettings: FacebookDefaultSettings,
        popupTitle: 'Facebook + Instagram',
      };
    default:
      return {
        integrationSettings: EbayDefaultSettings,
        popupTitle: 'eBay',
        formSchema: ebayIntegrationFormSchema,
      };
  }
}
