import { useState } from 'react';

import { ApolloError, useLazyQuery } from '@apollo/client';

import { useSnackbar } from 'src/providers/snackbar';
import { integrationHealthCheck, Query, QueryIntegrationHealthCheckArgs } from 'src/utils/gql';

export interface CheckIntegrationStatusProps {
  integrationId: string;
  mainCallbackFn: CallableFunction;
  additionalCallbackFn?: CallableFunction;
}

export interface IntegrationErrors {
  [x: string]: boolean;
}

const useIntegrationHealthCheck = (checkCatalogMappingsErrorsQuery?: CallableFunction) => {
  const snackbar = useSnackbar();

  const [currentId, setCurrentId] = useState<string>('');
  const [integrationErrors, setIntegrationErrors] = useState<IntegrationErrors>();
  const [callbacks, setCallbacks] = useState<{
    mainCallbackFn: CallableFunction;
    additionalCallbackFn?: CallableFunction;
  }>();

  const [checkIntegration, { loading }] = useLazyQuery<
    Pick<Query, 'integrationHealthCheck'>,
    QueryIntegrationHealthCheckArgs
  >(integrationHealthCheck, {
    fetchPolicy: 'network-only',
    onCompleted: ({ integrationHealthCheck }) => {
      checkCatalogMappingsErrorsQuery?.();

      if (integrationHealthCheck) {
        callbacks?.mainCallbackFn();

        setIntegrationErrors((prevState) => {
          delete prevState?.currentId;
          return prevState;
        });
      } else {
        currentId &&
          setIntegrationErrors((prevState) => ({
            ...prevState,
            [currentId]: true,
          }));

        callbacks?.additionalCallbackFn?.();
      }
    },
    onError: (error) => {
      checkCatalogMappingsErrorsQuery?.();

      const { graphQLErrors, message: errorText } = error as ApolloError;
      const message = graphQLErrors && graphQLErrors.length ? graphQLErrors[0].message : errorText;

      if (error) {
        snackbar(message);
      }
    },
  });

  const checkIntegrationStatus = ({
    integrationId,
    mainCallbackFn,
    additionalCallbackFn,
  }: CheckIntegrationStatusProps) => {
    setCallbacks({
      mainCallbackFn,
      additionalCallbackFn,
    });

    setCurrentId(integrationId);
    checkIntegration({ variables: { id: integrationId } });
  };

  return {
    checkIntegrationStatus,
    integrationErrors,
    loadingIntegrationHealthCheck: loading,
  };
};

export default useIntegrationHealthCheck;
