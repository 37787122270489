import React, { useEffect, useState } from 'react';

import { ApolloError, useLazyQuery } from '@apollo/client';

import MappingSidebar from 'src/components/SideBar/MappingSidebar';
import { MappingSidebarType } from 'src/components/SideBar/MappingSidebar/types';
import { useSnackbar } from 'src/providers/snackbar';
import {
  getIntegrationsListWithProposedMappings,
  IntegrationWithUndefinedMappings,
  Query,
  QueryGetIntegrationsListWithProposedMappingsArgs,
} from 'src/utils/gql';

import { COUNT_RENDERS_NEED_TO_WAIT_WHILE_MUI_POPUP_CLOSING } from '../constants';

interface CheckProposedMappingsProps {
  callbackFn: () => Promise<void> | void;
  errorCallbackFn?: () => void;
  closeCallbackFn?: () => void;
  shouldWaitAnotherPopupClosing?: boolean;
  secondaryButtonTitle?: string;
  customSidebarTitle?: string;
}

interface FirstTimeExportState {
  catalogId: string;
  integrationId?: string;
}

const useCheckProposedMappings = ({
  callbackFn,
  closeCallbackFn,
  errorCallbackFn,
  shouldWaitAnotherPopupClosing,
  secondaryButtonTitle,
  customSidebarTitle,
}: CheckProposedMappingsProps) => {
  const snackbar = useSnackbar();

  const [proposedMappings, setProposedMappings] = useState<IntegrationWithUndefinedMappings[]>([]);
  const [manualOpened, setManualOpened] = useState<boolean>(false);

  const [renderCounter, setRenderCounter] = useState(0);

  const [currentFirstTimeState, setCurrentFirstTimeState] = useState<FirstTimeExportState | null>(null);

  const shouldSidebarOpen = !!proposedMappings.length || manualOpened;

  const isSidebarOpen =
    shouldSidebarOpen &&
    (!shouldWaitAnotherPopupClosing || renderCounter > COUNT_RENDERS_NEED_TO_WAIT_WHILE_MUI_POPUP_CLOSING);

  // need to avoid problems, while another modal is closing and produce bugs with interaction
  // if bug will be produced again, try to increase COUNT_RENDERS_NEED_TO_WAIT_WHILE_MUI_POPUP_CLOSING constant
  useEffect(() => {
    if (!shouldWaitAnotherPopupClosing) {
      return;
    }

    if (shouldSidebarOpen) {
      if (renderCounter <= COUNT_RENDERS_NEED_TO_WAIT_WHILE_MUI_POPUP_CLOSING) {
        // do not change to "setRenderCounter((prev) => prev + 1);", it changes logic
        setRenderCounter(renderCounter + 1);
      }
    } else {
      setRenderCounter(0);
    }
  }, [renderCounter, shouldSidebarOpen, shouldWaitAnotherPopupClosing]);

  const [getListWithProposedMappings, { loading: getProposedMappingsLoading }] = useLazyQuery<
    Pick<Query, 'getIntegrationsListWithProposedMappings'>,
    QueryGetIntegrationsListWithProposedMappingsArgs
  >(getIntegrationsListWithProposedMappings, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'standby',
    onCompleted: ({ getIntegrationsListWithProposedMappings }) => {
      if (getIntegrationsListWithProposedMappings.length) {
        setProposedMappings(getIntegrationsListWithProposedMappings);

        closeCallbackFn?.();
      } else {
        callbackFn();
      }
    },
    onError: (error) => {
      const { graphQLErrors, message: errorText } = error as ApolloError;
      const message = graphQLErrors && graphQLErrors.length ? graphQLErrors[0].message : errorText;

      errorCallbackFn?.();

      if (error) {
        snackbar(message);
      }
    },
  });

  const justOpenFirstTimeExportSidebar = (catalogId: string, integrationId?: string) => {
    setCurrentFirstTimeState({ catalogId, integrationId });

    setManualOpened(true);
  };

  const handleGetListWithProposedMappings = (catalogId: string, integrationId?: string) => {
    setCurrentFirstTimeState({ catalogId, integrationId });

    getListWithProposedMappings({
      variables: { catalogId, options: { integrationId } },
    });
  };

  const handleCloseSidebar = () => {
    setProposedMappings([]);

    setManualOpened(false);

    closeCallbackFn?.();
  };

  const handleClickExport = () => {
    setProposedMappings([]);

    setCurrentFirstTimeState(null);

    setManualOpened(false);

    callbackFn();
  };

  const renderMappingsSidebar = () =>
    proposedMappings &&
    currentFirstTimeState &&
    isSidebarOpen && (
      <MappingSidebar
        isOpen={isSidebarOpen}
        onClickExport={handleClickExport}
        sidebarType={MappingSidebarType.FirstTimeMapping}
        proposedMappings={proposedMappings}
        selectedCatalogId={currentFirstTimeState.catalogId}
        onClose={handleCloseSidebar}
        currentIntegrationId={currentFirstTimeState.integrationId}
        secondaryButtonTitle={secondaryButtonTitle}
        customSidebarTitle={customSidebarTitle}
      />
    );

  return {
    getListWithProposedMappings: handleGetListWithProposedMappings,
    getProposedMappingsLoading,
    renderMappingsSidebar,
    justOpenFirstTimeExportSidebar,
  };
};

export default useCheckProposedMappings;
