import React, { memo } from 'react';

import { Box } from '@material-ui/core';

interface TopBarProps {
  children?: React.ReactNode;
}

const TopBar = ({ children }: TopBarProps) => {
  return (
    <Box display="flex" alignItems="center" minHeight="64px">
      {children}
    </Box>
  );
};

export default memo(TopBar);
