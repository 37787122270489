import React, { ChangeEvent, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { yupResolver } from '@hookform/resolvers/yup';

import EbayForm from 'src/components/Forms/EbayForm';
import FacebookForm from 'src/components/Forms/FacebookForm';
import ShopifyForm from 'src/components/Forms/ShopifyForm';
import WooCommerceForm from 'src/components/Forms/WooCommerceForm';
import Popup from 'src/components/Popup';
import useOnboarding from 'src/providers/OnboardingProvider/hooks/useOnboarding';
import { useEnterClick } from 'src/utils/enterEffectFactory';
import { IntegrationTypes } from 'src/utils/gql';
import { IntegrationItem } from 'src/views/UserSettings/types';

import { IntegrationsContent } from '../styled';
import { getParametersByIntegrationType } from '../utils';

import { initialPopupState, IntegrationsPopupState } from './types';

interface IntegrationsPopupProps {
  setPopupState?: React.Dispatch<React.SetStateAction<IntegrationsPopupState>>;
  popupState: IntegrationsPopupState;
  onAddEdit: (integration: IntegrationItem, action: number) => Promise<void>;
  onClosePopup: () => void;
  loading: boolean;
}

const IntegrationsPopup = ({
  popupState,
  onAddEdit,
  onClosePopup,
  setPopupState,
  loading = true,
}: IntegrationsPopupProps) => {
  const { t } = useTranslation();
  const {
    onboardingState: { tourActive },
  } = useOnboarding();

  const [formDefaultValues, setFormDefaultValues] = useState<IntegrationItem>(popupState.data);

  const { formSchema, popupTitle } = getParametersByIntegrationType(popupState.integrationType);

  const formProps = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(formSchema),
    defaultValues: formDefaultValues.settings,
  });

  const {
    reset,
    getValues,
    formState: { isValid },
  } = formProps;

  const handleChangeDefault = ({ target: { checked } }: ChangeEvent<HTMLInputElement>) => {
    setFormDefaultValues((prevState: IntegrationItem) => ({
      ...prevState,
      isDefault: checked,
    }));
  };

  const closePopupAndResetForm = () => {
    onClosePopup();
    setFormDefaultValues(popupState.data);
  };

  const handleMainButtonClick = async () => {
    const values = getValues();

    const data = {
      id: formDefaultValues.id,
      type: formDefaultValues.type,
      settings: values.shop && values.shop.slice(-1) === '/' ? { ...values, shop: values.shop.slice(0, -1) } : values,
      isDefault: formDefaultValues.isDefault,
    } as IntegrationItem;

    await onAddEdit(data, popupState.action);
    setPopupState?.((prev) => ({ ...prev, isOpen: false }));
  };

  useEnterClick(() => {
    if (isValid && !loading) {
      return handleMainButtonClick();
    }
  });

  useEffect(() => {
    reset(popupState.data.settings);
    setFormDefaultValues(popupState.data);
  }, [popupState.data, reset]);

  const IntegrationForm = [
    { component: ShopifyForm, type: IntegrationTypes.Shopify },
    { component: WooCommerceForm, type: IntegrationTypes.Woocommerce },
    { component: EbayForm, type: IntegrationTypes.Ebay },
    { component: FacebookForm, type: IntegrationTypes.Facebook },
  ].find(({ type }) => type === popupState.integrationType)?.component;

  const mainTitle = popupState.integrationType
    ? t('userSettingsPage.integrations.popup.title', { integration: popupTitle })
    : '';

  const handleExited = () => {
    setPopupState?.(initialPopupState);
  };

  return (
    <Popup
      headerMarginAbsence
      TransitionProps={{ onExited: handleExited }}
      open={popupState.isOpen}
      mainTitle={mainTitle}
      mainButtonText={t('userSettingsPage.integrations.popup.mainButton')}
      loadingOnMainButton={loading}
      mainButtonDisabled={tourActive ? false : !isValid}
      onMainButtonClick={handleMainButtonClick}
      onClose={closePopupAndResetForm}
      testSecondaryButton="backButton"
      secondaryButtonText={t('settingsPage.attachedIntegrations.popup.backButton')}
      onSecondaryButtonClick={closePopupAndResetForm}
      testMainButton="submit"
    >
      <IntegrationsContent>
        <FormProvider {...formProps}>
          {IntegrationForm && (
            <IntegrationForm
              integrationId={popupState.data.id}
              action={popupState.action}
              onChangeDefault={handleChangeDefault}
              isDefaultIntegration={formDefaultValues.isDefault}
            />
          )}
        </FormProvider>
      </IntegrationsContent>
    </Popup>
  );
};

export default IntegrationsPopup;
