import React, { ChangeEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import {
  ApolloError,
  gql,
  NetworkStatus,
  Reference,
  StoreObject,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { debounce, isEqual } from 'lodash';

import DashboardBreadCrumbs from 'src/components/Breadcrumds/DashboardBreadCrumbs';
import BulkSelect, { MenuOptions } from 'src/components/BulkSelect';
import ItemCard from 'src/components/Card/ItemCard';
import { ChipsCompleteness, CompletenessIntegration } from 'src/components/Completeness/ChipsCompleteness';
import { copyProductItemForm } from 'src/components/Forms/validationSchemas';
import ProductItemsHeader from 'src/components/Headers/ProductItemsHeader';
import createItemIcon from 'src/components/Icon/create-item.webp';
import productItemsNotFound from 'src/components/Icon/product-items-not-found.webp';
import Iconography from 'src/components/Iconography';
import Page from 'src/components/Page';
import Pagination from 'src/components/Pagination';
import BulkEditPopup from 'src/components/Popups/BulkEditPopup';
import CopyProductItemPopup from 'src/components/Popups/CopyProductItemPopup';
import DeleteItemPopup from 'src/components/Popups/DeletePopup';
import ExportToIntegrationsPopup from 'src/components/Popups/ExportToIntegrationsPopup';
import { SelectOptionItem } from 'src/components/Select';
import { sortMaybeStringNumericArray } from 'src/components/SideBar/FilterItem/Range';
import ProductItemsSidebar from 'src/components/SideBar/ProductItemsSidebar';
import SkeletonProductItemCard, {
  SkeletonProductItemCardWrapper,
} from 'src/components/Skeleton/SkeletonProductItemCard';
import {
  BULK_SELECT_ACTIONS,
  EmptyValuesUrlParamsMarker,
  INITIAL_BULK_SELECT_OPTIONS,
  ITEMS_PER_PAGE_MENU_LIST,
} from 'src/constants';
import {
  CATALOGS_PATH,
  CREATION,
  EDITING,
  FIRST_DASHBOARD_PATHNAME,
  PRODUCT_ITEMS_PATH,
  PRODUCT_TYPES_PATH,
} from 'src/constants/routeSources';
import { validateTextFieldValue } from 'src/helpers/validationCheck';
import { useCheckCatalogMappingErrors, useCheckProductTypeMappingErrors } from 'src/hooks/graphQLRequestsHooks';
import useCheckProposedMappings from 'src/hooks/useCheckProposedMappings';
import useDataTransferCompletedSnackbar from 'src/hooks/useDataTransferCompletedSnackbar';
import { useAccount } from 'src/providers/account';
import { useCatalogs } from 'src/providers/catalog';
import useDataTransferStatus from 'src/providers/catalog/hooks/useDataTransferStatus';
import { useLimitReached } from 'src/providers/limitReached';
import { MediaStateContext } from 'src/providers/MediaProvider/context';
import { useSnackbar } from 'src/providers/snackbar';
import { EventName, EventsService } from 'src/services/Events';
import { clearCacheFactory } from 'src/utils/cacheUtils';
import { useEscapeClick } from 'src/utils/escapeEffectFactory';
import { arrayToMap } from 'src/utils/general';
import { getUnSyncableIntegrations } from 'src/utils/getUnSyncableIntegrations';
import {
  checkIsCatalogEmpty,
  checkIsLimitReached,
  copyProductItemById,
  deleteProductItemsById,
  deleteProductItemsByProductTypeId,
  exportProductTypeToAllAttachedIntegrations,
  FieldSystemName,
  FilterItem,
  FilterType,
  getCatalogInfo,
  getProductItems,
  getProductTypeById,
  getProductTypeFiltersById,
  integrationProductTypeFieldMappings,
  ItemStatus,
  LimitType,
  makeProductItemsDraft,
  Mutation,
  MutationDeleteProductItemsArgs,
  MutationDeleteProductItemsByProductTypeIdArgs,
  MutationExportProductTypeToAllAttachedIntegrationsArgs,
  ProductItem,
  ProductItemValue,
  ProductTypeFiltersWithItemsCount,
  Query,
  QueryGetCatalogByIdArgs,
  QueryGetProductItemsByProductTypeIdArgs,
  QueryGetProductTypeArgs,
  QueryGetProductTypeFiltersWithItemsCountArgs,
  QueryProductTypeFieldIntegrationMappingsArgs,
} from 'src/utils/gql';
import { deleteItemDataLocations } from 'src/utils/locationArrays';
import { productPathGeneration } from 'src/utils/productPathGeneration';

import CreateProductItemBackground from './CreateProductItemBackground';
import ProductItemsNotFoundBackground from './ProductItemsNotFoundBackground';
import { ProductItemsList, StyledButton } from './styled';
import { FilterItemWithNewValues, FilterState, UNSELECT_ITEMS_TIMEOUT } from './types';

const useStyles = makeStyles<Theme>({
  hasNotFoundItemsImage: {
    width: '316px',
    height: '220px',
  },
  hasFoundItemsImage: {
    width: '375px',
    height: '220px',
  },
  collapsedSidebarItemListMargin: {
    marginLeft: 0,
  },
  itemsContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(280px, 1fr))',
    gridColumnGap: '24px',
    gridRowGap: '25px',
    padding: '10px',
  },
});

const productTypeFiltersFieldName = 'getProductTypeFiltersWithItemsCount';

export const clearProductFiltersCache = clearCacheFactory({ fieldNames: [productTypeFiltersFieldName] });

const parseFiltersForRequest = (filtersMap: { [id: string]: FilterItemWithNewValues }) =>
  Object.values(filtersMap)
    .filter(({ newValues, newEmptyValues }) => Boolean(newValues?.length || newEmptyValues))
    .map(({ productTypeFieldId, type, newValues = [], newEmptyValues }) => ({
      productTypeFieldId,
      type,
      values: newValues,
      empty: newEmptyValues || undefined,
    })) as FilterItem[];

const createFilterSearchParams = (filters: FilterItemWithNewValues[]): string => {
  const url = new URLSearchParams();

  filters.forEach(({ productTypeFieldName, type, newValues, newEmptyValues }) => {
    if (newValues.length || newEmptyValues) {
      let searchValues = '';

      if (type === FilterType.Boolean) {
        searchValues = newValues.toString();
      }

      if (type === FilterType.Options) {
        searchValues = newValues.join(',');
      }

      if (type === FilterType.Range && newValues.some(Boolean)) {
        searchValues = newValues.map((value) => value || '').join(',');
      }

      if (!(newEmptyValues || searchValues)) {
        return;
      }

      searchValues = searchValues.replace(EmptyValuesUrlParamsMarker.regex, '');

      if (newEmptyValues) {
        searchValues += EmptyValuesUrlParamsMarker.value;
      }

      url.set(productTypeFieldName, searchValues);
    }
  });

  return url.toString();
};

const ProductItems = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const snackbar = useSnackbar();
  const { user } = useAccount();
  const history = useHistory();
  const location = useLocation();
  const { updateCatalogsJobsMap, updateCurrentExport } = useCatalogs();
  const limitReached = useLimitReached();
  const { id, productTypeId } = useParams<{ id: string; productTypeId: string }>();
  const defaultBulkChangeValue: SelectOptionItem = { label: '', value: '' };

  const { showDataTransferCompletedSnackbar } = useDataTransferCompletedSnackbar(true);
  const { checkCatalogMappingsErrorsQuery, loading: checkCatalogMappingsErrorsQueryLoading } =
    useCheckCatalogMappingErrors(id);
  const { checkProductTypeMappingsErrorsQuery } = useCheckProductTypeMappingErrors(productTypeId);
  const { setMediaState, setMediaLastOrder } = useContext(MediaStateContext);

  const [isImageLoading, setIsImageLoading] = useState<boolean>(true);
  const [isAllItemsSelectedSynthetic, setIsAllItemsSelectedSynthetic] = useState<boolean>(false);
  const [isDeleteItemPopupOpen, setIsDeleteItemPopupOpen] = useState<boolean>(false);
  const [isCopyItemPopupOpen, setIsCopyItemPopupOpen] = useState<boolean>(false);
  const [isBulkEditPopupOpen, setIsBulkEditPopupOpen] = useState<boolean>(false);
  const [isBulkSelectOpen, setIsBulkSelectOpen] = useState<boolean>(false);
  const [productTypeFiltersLoading, setProductTypeFiltersLoading] = useState<boolean>(false);
  const [selectedItemsIds, setSelectedItemsIds] = useState<string[]>([]);
  const [bulkSelectOptions, setBulkSelectOptions] = useState<MenuOptions[]>(INITIAL_BULK_SELECT_OPTIONS);
  const [searchInputValue, setSearchInputValue] = useState<string>('');
  const [fetchItemsLoading, setFetchItemsLoading] = useState(true);
  const [isExportPopupOpen, setIsExportPopupOpen] = useState<boolean>(false);
  const [showCompleteness, setShowCompleteness] = useState(localStorage.getItem('_completenessState') === 'true');
  const [itemsPerPage, setItemsPerPage] = useState(ITEMS_PER_PAGE_MENU_LIST[0]);
  const [page, setPage] = useState<number>(1);
  const [prevItems, setPrevItems] = useState<ProductItem[]>([]);

  const { inProgress: isCatalogJobLoading } = useDataTransferStatus({
    catalogId: id,
    onCompleted: (job) => {
      showDataTransferCompletedSnackbar(job);
      checkCatalogMappingsErrorsQuery();
      checkProductTypeMappingsErrorsQuery();
    },
  });

  // MOTHBALLED TO MVP

  // const [isExportFilesPopupOpen, setIsExportFilesPopupOpen] = useState<boolean>(false);
  // const [isExportToCsvPopupOpen, setIsExportToCsvPopupOpen] = useState<boolean>(false);

  const [filterState, setFilterState] = useState<FilterState>({
    selectedProductType: defaultBulkChangeValue,
    filterItemsMap: {},
  });

  // MOTHBALLED TO MVP

  // const [currentExportCardId, setCurrentExportCardId] = useState<string>('');

  const handleUpdateItems = () => {
    setFetchItemsLoading(false);
  };

  const [checkIsCatalogEmptyQuery] = useLazyQuery<Pick<Query, 'getCatalogById'>, QueryGetCatalogByIdArgs>(
    checkIsCatalogEmpty,
    {
      fetchPolicy: 'network-only',
      variables: { id },
    },
  );

  useEscapeClick(() => setSelectedItemsIds([]));

  const refetchQueries = useMemo(
    () => [
      {
        query: checkIsLimitReached,
        variables: { type: LimitType.Items },
      },
    ],
    [],
  );

  const { data: productTypeData } = useQuery<Pick<Query, 'getProductType'>, QueryGetProductTypeArgs>(
    getProductTypeById,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        id: productTypeId,
      },
    },
  );

  const productType = productTypeData?.getProductType;

  const { data: checkIsLimitReachedData, loading: checkIsLimitReachedLoading } = useQuery(checkIsLimitReached, {
    fetchPolicy: 'cache-first',
    variables: { type: LimitType.Items },
  });

  const [fetchItems, { data: itemsData, fetchMore, networkStatus, loading: itemsDataLoading, refetch }] = useLazyQuery<
    Pick<Query, 'getProductItemsByProductTypeId'>,
    QueryGetProductItemsByProductTypeIdArgs
  >(getProductItems, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    onCompleted: handleUpdateItems,
  });

  const copyProductItemFormProps = useForm({
    mode: 'onChange',
    resolver: yupResolver(copyProductItemForm),
    defaultValues: { sku: '' },
  });

  const fieldsForShowInPreview = useMemo(
    () => productType?.fields.filter(({ showInPreview }) => showInPreview),
    [productType],
  );

  const getParsedFiltersForRequest = (
    getProductTypeFiltersWithItemsCount: ProductTypeFiltersWithItemsCount,
  ): { parsedFiltersForRequest: FilterItem[]; newFilterItemsMap: { [id: string]: FilterItemWithNewValues } } => {
    const urlSearchParams = new URLSearchParams(location.search);

    const productTypeFilters = getProductTypeFiltersWithItemsCount.filters;

    const newFilterItemsMap: { [id: string]: FilterItemWithNewValues } = {};

    productTypeFilters.forEach((item) => {
      const { productTypeFieldName, type, values, min, max } = item;
      const searchValues = urlSearchParams.get(productTypeFieldName);

      let newValues: (string | null)[] = [];

      const newEmptyValues = !!searchValues?.match(EmptyValuesUrlParamsMarker.regex);

      if (searchValues) {
        const newValuesString = searchValues.replace(EmptyValuesUrlParamsMarker.regex, '');
        newValues = newValuesString ? newValuesString.split(',') : [];
      }

      // check if values are out of possible range
      if (item.type === FilterType.Range) {
        newValues = newValues.sort(sortMaybeStringNumericArray).map((value, idx) => {
          if (!value) return null;

          let numValue = Number(value);
          const minMax = [min, max];
          const currentBorder = minMax[Number(!idx)] as number;

          if (!!idx === numValue < currentBorder) {
            numValue = currentBorder;
          }

          if (min && numValue < min) return String(min);
          if (max && numValue > max) return String(max);

          return value;
        });
      }

      // check if values are out of possible range
      if (type === FilterType.Options && values) {
        newValues = newValues.filter((val) => !val || values.includes(val));
      }

      newFilterItemsMap[item.productTypeFieldId] = { ...item, newValues, newEmptyValues };
    });

    history.replace({ search: createFilterSearchParams(Object.values(newFilterItemsMap)) });

    return { newFilterItemsMap, parsedFiltersForRequest: parseFiltersForRequest(newFilterItemsMap) };
  };

  const fetchItemsVariables = useMemo(() => {
    return {
      id: productTypeId,
      limit: itemsPerPage,
      offset: (page - 1) * itemsPerPage,
      search: searchInputValue,
      filter: parseFiltersForRequest(filterState.filterItemsMap),
      status: [ItemStatus.Active, ItemStatus.Draft],
    };
  }, [productTypeId, itemsPerPage, page, searchInputValue, filterState]);

  const refetchItems = async () => {
    if (productTypeFiltersData?.getProductTypeFiltersWithItemsCount) {
      const { parsedFiltersForRequest } = getParsedFiltersForRequest(
        productTypeFiltersData?.getProductTypeFiltersWithItemsCount,
      );

      await refetch?.({
        ...fetchItemsVariables,
        filter: parsedFiltersForRequest,
      });
    }
  };

  const handleProductTypeFiltersRequestCompleted = async ({
    getProductTypeFiltersWithItemsCount,
  }: {
    getProductTypeFiltersWithItemsCount: ProductTypeFiltersWithItemsCount;
  }) => {
    setFetchItemsLoading(true);

    const { parsedFiltersForRequest, newFilterItemsMap } = getParsedFiltersForRequest(
      getProductTypeFiltersWithItemsCount,
    );

    try {
      const variables = { ...fetchItemsVariables, filter: parsedFiltersForRequest };

      if (refetch) {
        await refetch({
          ...variables,
        });
      } else {
        fetchItems({
          variables,
        });
      }

      setFetchItemsLoading(false);

      // fetch new filters if there were saved params in url
      if (
        !Object.keys(filterState.filterItemsMap).length &&
        Object.values(newFilterItemsMap).some(({ newValues }) => newValues.length)
      ) {
        await refetchFilters({
          filter: parsedFiltersForRequest,
        });
      }

      setFilterState((prevState) => ({
        ...prevState,
        filterItemsMap: newFilterItemsMap,
      }));
    } catch (error) {
      setFetchItemsLoading(false);

      const { graphQLErrors, message: errorText } = error as ApolloError;
      const message = graphQLErrors && graphQLErrors.length ? graphQLErrors[0].message : errorText;

      if (error) {
        snackbar(message);
      }
    }
  };

  const {
    data: productTypeFiltersData,
    loading: fetchFiltersLoading,
    refetch: refetchFilters,
  } = useQuery<Pick<Query, 'getProductTypeFiltersWithItemsCount'>, QueryGetProductTypeFiltersWithItemsCountArgs>(
    getProductTypeFiltersById,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      notifyOnNetworkStatusChange: true,
      variables: {
        id: productTypeId,
      },
      onCompleted: (data) => handleProductTypeFiltersRequestCompleted(data),
    },
  );

  const debouncedRefetchFilters = useMemo(() => {
    if (refetchFilters) {
      return debounce(refetchFilters, 500);
    }
  }, [refetchFilters]);

  const [bulkItemsToDraft, { loading: bulkItemsToDraftLoading }] = useMutation(makeProductItemsDraft);

  const [syncProductType] = useMutation<
    Pick<Mutation, 'exportProductTypeToAllAttachedIntegrations'>,
    MutationExportProductTypeToAllAttachedIntegrationsArgs
  >(exportProductTypeToAllAttachedIntegrations);

  const [deleteSelectedProductItems, { loading: deleteSelectedItemsLoading }] = useMutation<
    Pick<Mutation, 'deleteProductItems'>,
    MutationDeleteProductItemsArgs
  >(deleteProductItemsById, {
    refetchQueries: [...refetchQueries, { query: getProductTypeById, variables: { id: productTypeId } }],
  });

  const [deleteAllProductItems, { loading: deleteAllItemsLoading }] = useMutation<
    Pick<Mutation, 'deleteProductItemsByProductTypeId'>,
    MutationDeleteProductItemsByProductTypeIdArgs
  >(deleteProductItemsByProductTypeId, {
    refetchQueries: [...refetchQueries, { query: getProductTypeById, variables: { id: productTypeId } }],
  });

  const [copyProductItem, { loading: copyItemLoading }] = useMutation(copyProductItemById, {
    refetchQueries: [
      { query: checkIsLimitReached, variables: { type: LimitType.Items } },
      { query: getProductTypeById, variables: { id: productTypeId } },
      { query: getCatalogInfo, variables: { id } },
    ],
  });

  const handleChangeSearchValue = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const inputValue = validateTextFieldValue(value);

    setSearchInputValue(inputValue);

    if (debouncedRefetchFilters) {
      setFetchItemsLoading(true);
      debouncedRefetchFilters({
        search: inputValue,
      });
    }
  };

  const { data: currentCatalogInfo, loading: currentCatalogLoading } = useQuery<
    Pick<Query, 'getCatalogById'>,
    QueryGetCatalogByIdArgs
  >(getCatalogInfo, {
    variables: { id },
    onCompleted: (data) => {
      const jobs = data.getCatalogById.lastIntegrationsJobs;

      updateCatalogsJobsMap(jobs);
    },
  });

  const { data: fieldMappingsData, loading: fieldsMappingLoading } = useQuery<
    Pick<Query, 'productTypeFieldIntegrationMappings'>,
    QueryProductTypeFieldIntegrationMappingsArgs
  >(integrationProductTypeFieldMappings, {
    fetchPolicy: 'cache-first',
    variables: {
      productTypeId,
    },
    skip: !showCompleteness,
  });

  const catalogOwnerId = currentCatalogInfo?.getCatalogById.user.id;
  const isCatalogShared = user.id !== catalogOwnerId;

  const handleChangeSelect = useCallback(
    (newProductTypeOption?: SelectOptionItem) => {
      if (newProductTypeOption) {
        setFilterState((prevState: FilterState) => ({
          ...prevState,
          selectedProductType: newProductTypeOption,
        }));

        setSelectedItemsIds([]);

        history.push(
          `/${FIRST_DASHBOARD_PATHNAME}/${CATALOGS_PATH}/${id}/${PRODUCT_TYPES_PATH}/${newProductTypeOption.value}/${PRODUCT_ITEMS_PATH}`,
        );
      }
    },
    [history, id],
  );

  const hasSelectedItems = !!selectedItemsIds.length;
  const hasItems = !!itemsData?.getProductItemsByProductTypeId.length || !!prevItems?.length;

  const selectedItemsCount = selectedItemsIds.length;

  const productItemMap = useMemo(
    () => (itemsData ? arrayToMap(itemsData.getProductItemsByProductTypeId) : {}),
    [itemsData],
  );

  const productItemsCount = productTypeFiltersData?.getProductTypeFiltersWithItemsCount?.itemsCount || 0;

  const selectedItems = useMemo(
    () => selectedItemsIds.map((id) => productItemMap[id]).filter((item) => item),
    [productItemMap, selectedItemsIds],
  );

  const selectedItemSkuValue = useMemo(
    () =>
      selectedItemsIds.length === 1
        ? productItemMap[selectedItemsIds[0]]?.values.find(
            ({ productTypeField }) => productTypeField.systemName === FieldSystemName.Sku,
          )?.value
        : '',

    [productItemMap, selectedItemsIds],
  );

  const isAllItemsSelectedReal = !!selectedItemsIds.length && selectedItemsIds.length === productItemsCount;
  const isAllItemsSelected = isAllItemsSelectedReal || isAllItemsSelectedSynthetic;

  const hasFilters = useMemo(
    () =>
      Object.values(filterState.filterItemsMap).some(
        ({ newValues, newEmptyValues }) => newValues?.length || newEmptyValues,
      ),
    [filterState],
  );

  const hasNotFoundedItems = !(!(hasFilters || searchInputValue) || hasItems);

  const ProductItemsBlur = useMemo(
    () => (hasNotFoundedItems ? <ProductItemsNotFoundBackground /> : <CreateProductItemBackground />),
    [hasNotFoundedItems],
  );

  const showCreateItemButton = !(hasNotFoundedItems || isCatalogShared);

  // MOTHBALLED TO MVP

  // const { onExportToCsv, isExportSuccess, isExporting, isExportError } = useExportToCsv(
  //   isAllItemsSelected,
  //   productTypeId,
  //   selectedItems,
  // );

  const handleChangeFilterItem = useCallback(
    (values: (string | null)[], itemId: string, emptyValues: boolean) => {
      const item = filterState.filterItemsMap[itemId];

      if (!item || (isEqual(item.newValues, values) && item.newEmptyValues === emptyValues)) return;

      const isFilterChanged = !(isEqual(item.newValues, values) && item.newEmptyValues === emptyValues);

      if (isFilterChanged) {
        const newFilterItemsMap = {
          ...filterState.filterItemsMap,
          [item.productTypeFieldId]: {
            ...item,
            newValues: values,
            newEmptyValues: emptyValues,
          },
        };

        history.push({ search: createFilterSearchParams(Object.values(newFilterItemsMap)) });
        setFilterState((prevState: FilterState) => ({
          ...prevState,
          filterItemsMap: newFilterItemsMap,
        }));

        setFetchItemsLoading(true);
        debouncedRefetchFilters?.({
          filter: parseFiltersForRequest(newFilterItemsMap),
        });
      }
    },
    [debouncedRefetchFilters, filterState.filterItemsMap, history],
  );

  const handleExportProductType = () => {
    getListWithProposedMappings(id);
  };

  const handleCloseExportPopup = () => setIsExportPopupOpen(false);

  const { getListWithProposedMappings, getProposedMappingsLoading, renderMappingsSidebar } = useCheckProposedMappings({
    callbackFn: () => {
      updateCurrentExport(id);

      syncProductType({ variables: { catalogId: id, productTypeId } })
        .then(handleCloseExportPopup)
        .catch(({ message }) => {
          checkCatalogMappingsErrorsQuery();
          snackbar(message);
        });
    },
    closeCallbackFn: handleCloseExportPopup,
    errorCallbackFn: checkCatalogMappingsErrorsQuery,
  });

  const handleSelectCard = useCallback((itemId: string) => {
    setSelectedItemsIds((prevState) => [...prevState, itemId]);
  }, []);

  const handleUnselectCard = useCallback(
    (itemId: string) => setSelectedItemsIds(selectedItemsIds.filter((id) => id !== itemId)),
    [selectedItemsIds],
  );

  const handleResetFilters = () => {
    history.push({ search: '' });

    const newFilterItemsMap = {
      ...filterState.filterItemsMap,
    };

    Object.keys(newFilterItemsMap).forEach((key) => {
      newFilterItemsMap[key].newValues = [];
    });

    setFilterState((prevState: FilterState) => ({
      ...prevState,
      filterItems: newFilterItemsMap,
    }));

    if (debouncedRefetchFilters) {
      setFetchItemsLoading(true);
      debouncedRefetchFilters({
        filter: parseFiltersForRequest(newFilterItemsMap),
      });
    }

    EventsService.dispatch(EventName.FiltersReset, null);
  };

  const handleAddNewItem = () => {
    if (checkIsLimitReachedData?.checkIsLimitReached) {
      limitReached({ limitReachedType: LimitType.Items });
    } else {
      setMediaState([]);
      setMediaLastOrder(0);

      history.push(
        `/${FIRST_DASHBOARD_PATHNAME}/${CATALOGS_PATH}/${id}/${PRODUCT_TYPES_PATH}/${productTypeId}/${CREATION}`,
      );
    }
  };

  const handleItemCardClick = (productItemId: string) => {
    setMediaState([]);

    history.push({
      pathname: `/${FIRST_DASHBOARD_PATHNAME}/${CATALOGS_PATH}/${id}/${PRODUCT_TYPES_PATH}/${productTypeId}/${PRODUCT_ITEMS_PATH}/${productItemId}/${EDITING}`,
    });
  };

  const handleSetLoading = (newState: boolean) => setProductTypeFiltersLoading(newState);

  const handleCloseDeleteItemPopup = () => {
    setIsDeleteItemPopupOpen(false);
  };

  const handleCloseBulkEditPopup = () => {
    setIsBulkEditPopupOpen(false);
  };

  const handleDeleteItems = async (action: BULK_SELECT_ACTIONS) => {
    try {
      if (action === BULK_SELECT_ACTIONS.deleteSelectedItems) {
        await deleteSelectedProductItems({
          variables: {
            productItemIds: selectedItemsIds,
          },
          update(cache) {
            cache.modify({
              fields: {
                getProductItemsByProductTypeId(productItemsRef = [], { readField }) {
                  refetchFilters();
                  checkIsCatalogEmptyQuery();
                  refetchItems();

                  return productItemsRef.filter(
                    (ref: Reference | StoreObject | undefined) =>
                      !selectedItemsIds.includes(readField('id', ref) as string),
                  );
                },
              },
            });
          },
        });

        selectedItemsIds.forEach((itemId) => {
          deleteItemDataLocations(productPathGeneration(id, productTypeId, itemId));
        });
      } else {
        await deleteAllProductItems({
          variables: {
            productTypeId,
            search: searchInputValue,
            filter: parseFiltersForRequest(filterState.filterItemsMap),
          },

          update(cache) {
            cache.modify({
              fields: {
                getProductItemsByProductTypeId(productItemsRef = [], { readField }) {
                  refetchFilters({ filter: [] });
                  checkIsCatalogEmptyQuery();

                  const targetIds = itemsData?.getProductItemsByProductTypeId.map(({ id }) => id) || [];
                  return productItemsRef.filter(
                    (ref: Reference | StoreObject | undefined) => !targetIds.includes(readField('id', ref) as string),
                  );
                },
              },
            });
          },
        });
        deleteItemDataLocations(productPathGeneration(id, productTypeId), true);
      }

      setFilterState((prev) => ({
        ...prev,
        filterItemsMap: {},
      }));
      setSelectedItemsIds([]);
      setIsDeleteItemPopupOpen(false);

      snackbar(
        t(`productItemsPage.${isAllItemsSelected ? 'snackbarDeleteAllItemsSuccess' : 'snackbarDeleteItemsSuccess'}`, {
          count: selectedItemsCount,
        }),
        'success',
      );
    } catch (error) {
      const { graphQLErrors, message: errorText } = error as ApolloError;
      const message = graphQLErrors && graphQLErrors.length ? graphQLErrors[0].message : errorText;

      if (error) {
        snackbar(message);
      }
    }
  };

  const fetchPageItems = useCallback(async () => {
    if (!page || !fetchMore || networkStatus !== NetworkStatus.ready) return;

    await fetchItems({
      variables: {
        ...fetchItemsVariables,
        offset: (page - 1) * itemsPerPage,
        limit: itemsPerPage,
      },
    });
  }, [fetchItems, fetchItemsVariables, fetchMore, itemsPerPage, networkStatus, page]);

  useEffect(() => {
    if ((productTypeFiltersData?.getProductTypeFiltersWithItemsCount?.itemsCount || 0) <= (page - 1) * itemsPerPage) {
      setPage(1);
    } else {
      fetchPageItems();
    }
  }, [page, itemsPerPage, fetchPageItems, productTypeFiltersData?.getProductTypeFiltersWithItemsCount?.itemsCount]);

  const getValueByProductFieldName = (values: ProductItemValue[], fieldName?: string): string =>
    values.find(({ productTypeField }) => productTypeField.name.toLowerCase() === fieldName)?.value || '';

  const makeItemDraft = async () => {
    setIsBulkSelectOpen(false);

    try {
      await bulkItemsToDraft({
        variables: { productTypeId, ids: selectedItemsIds },

        update(cache) {
          selectedItemsIds.forEach((itemId) =>
            cache.writeFragment({
              id: `ProductItem:${itemId}`,
              fragment: gql`
                fragment MyProductItem on ProductItem {
                  status
                }
              `,
              data: {
                status: ItemStatus.Draft,
              },
            }),
          );
        },
      });
    } catch (error) {
      const { graphQLErrors, message: errorText } = error as ApolloError;
      const message = graphQLErrors && graphQLErrors.length ? graphQLErrors[0].message : errorText;

      if (error) {
        snackbar(message);
      }
    }

    setSelectedItemsIds([]);
  };

  const copyItem = async (sku?: string) => {
    setIsBulkSelectOpen(false);

    try {
      const itemId = selectedItemsIds[0];

      await copyProductItem({
        variables: { productItemId: itemId, sku },

        update(cache, { data }) {
          const clonedItem = data?.copyProductItem;
          const itemsCount = productTypeFiltersData?.getProductTypeFiltersWithItemsCount.itemsCount;

          if (clonedItem) {
            cache.modify({
              fields: {
                getProductItemsByProductTypeId(existingProductItemsRef = [], { readField, toReference }) {
                  const clonedProductItemRef = toReference(clonedItem.id);
                  const isItemAlreadyInCache = existingProductItemsRef.some(
                    (ref: { __ref: string }) => readField('id', ref) === clonedItem.id,
                  );

                  return isItemAlreadyInCache
                    ? existingProductItemsRef
                    : [...existingProductItemsRef, clonedProductItemRef];
                },
              },
            });

            if (itemsCount) {
              cache.writeFragment({
                id: `ProductTypeFiltersWithItemsCount:${productTypeId}`,
                fragment: gql`
                  fragment MyProductTypeFiltersWithItemsCount on ProductTypeFiltersWithItemsCount {
                    itemsCount
                  }
                `,
                data: {
                  itemsCount: itemsCount + 1,
                },
              });
            }
          }
        },
      });

      refetchItems();
    } catch (error) {
      const { graphQLErrors, message: errorText } = error as ApolloError;
      const message = graphQLErrors && graphQLErrors.length ? graphQLErrors[0].message : errorText;

      if (error) {
        snackbar(message);
      }
    }

    setSelectedItemsIds([]);
  };

  const handleClickBulkSelectMenuItem = async (action: number) => {
    switch (action) {
      case BULK_SELECT_ACTIONS.edit:
        setBulkSelectOptions([
          {
            action: BULK_SELECT_ACTIONS.editSelectedItems,
            label: 'productItemsPage.bulkSelect.editSelectedItems',
          },
          { action: BULK_SELECT_ACTIONS.editAllItems, label: 'productItemsPage.bulkSelect.editAllItems' },
        ]);
        break;
      case BULK_SELECT_ACTIONS.clone:
        if (checkIsLimitReachedData?.checkIsLimitReached) {
          limitReached({ limitReachedType: LimitType.Items });
        } else if (selectedItemSkuValue) {
          setIsCopyItemPopupOpen(true);
        } else {
          await copyItem();
        }
        break;
      case BULK_SELECT_ACTIONS.makeDraft:
        await makeItemDraft();
        break;
      case BULK_SELECT_ACTIONS.editSelectedItems:
        setIsBulkEditPopupOpen(true);
        setIsBulkSelectOpen(false);
        break;
      case BULK_SELECT_ACTIONS.editAllItems:
        setIsAllItemsSelectedSynthetic(true);
        setIsBulkEditPopupOpen(true);
        setIsBulkSelectOpen(false);
        break;
      case BULK_SELECT_ACTIONS.delete:
        setBulkSelectOptions([
          {
            action: BULK_SELECT_ACTIONS.deleteSelectedItems,
            label: 'productItemsPage.bulkSelect.deleteSelectedItems',
          },
          { action: BULK_SELECT_ACTIONS.deleteAllItems, label: 'productItemsPage.bulkSelect.deleteAllItems' },
        ]);
        break;
      case BULK_SELECT_ACTIONS.deleteSelectedItems:
        setIsDeleteItemPopupOpen(true);
        setIsBulkSelectOpen(false);
        break;
      case BULK_SELECT_ACTIONS.deleteAllItems:
        setIsAllItemsSelectedSynthetic(true);
        setIsDeleteItemPopupOpen(true);
        setIsBulkSelectOpen(false);
        break;

      //MOTHBALLED TO MVP

      // case BULK_SELECT_ACTIONS.export:
      //   setBulkSelectOptions([
      //     {
      //       action: BULK_SELECT_ACTIONS.exportSelectedItems,
      //       label: t('productItemsPage.bulkSelect.exportSelectedItems'),
      //     },
      //     { action: BULK_SELECT_ACTIONS.exportAllItems, label: t('productItemsPage.bulkSelect.exportAllItems') },
      //   ]);
      //   break;
      // case BULK_SELECT_ACTIONS.exportSelectedItems:
      //   setIsExportFilesPopupOpen(true);
      //   setIsBulkSelectOpen(false);
      //   break;
      // case BULK_SELECT_ACTIONS.exportAllItems:
      //   setIsExportFilesPopupOpen(true);
      //   setIsBulkSelectOpen(false);
      //   setSelectedItems(productItemIds);
      //   break;

      default:
        break;
    }
  };

  //MOTHBALLED TO MVP

  // const handleClickExportFilesPopup = () => {
  //   if (currentExportCardId === EXPORT_CARDS_DATA.csv.id) {
  //     setIsExportToCsvPopupOpen(true);
  //     onExportToCsv();
  //   }
  //
  //   setIsExportFilesPopupOpen(false);
  //   setCurrentExportCardId('');
  // };

  useEffect(() => {
    if (!isBulkSelectOpen) {
      setTimeout(() => {
        setBulkSelectOptions(INITIAL_BULK_SELECT_OPTIONS);
      }, 200);
    }
  }, [isBulkSelectOpen]);

  const [showFilters, setShowFilters] = useState<boolean>(true);

  const handleShowFilters = () => setShowFilters(!showFilters);

  const bulkSelectComponent = (
    <BulkSelect
      setIsAllItemsSelectedSynthetic={setIsAllItemsSelectedSynthetic}
      isSharedCatalog={isCatalogShared}
      isAllItemsSelected={isAllItemsSelectedReal}
      setSelectedItems={setSelectedItemsIds}
      onClickBulkSelectMenuItems={handleClickBulkSelectMenuItem}
      onCloseBulkSelect={() => setIsBulkSelectOpen(false)}
      onClickBulkSelectIcon={() => setIsBulkSelectOpen(!isBulkSelectOpen)}
      isBulkSelectOpen={isBulkSelectOpen}
      menuOptions={bulkSelectOptions}
      selectedItemsIds={selectedItemsIds}
    />
  );

  const isLastPage =
    itemsPerPage * page >= (productTypeFiltersData?.getProductTypeFiltersWithItemsCount?.itemsCount || 0);

  const itemsListLoading =
    (!itemsData && !prevItems && (fetchItemsLoading || fetchFiltersLoading || itemsDataLoading)) ||
    copyItemLoading ||
    bulkItemsToDraftLoading ||
    fetchItemsLoading ||
    fetchFiltersLoading ||
    (itemsPerPage !== prevItems?.length && !isLastPage) ||
    (selectedItemsCount === itemsData?.getProductItemsByProductTypeId.length &&
      networkStatus === NetworkStatus.refetch);

  const pageId = 'page';
  const hideExportToAttachedButton = Boolean(selectedItemsIds.length);
  const productTypeDataName = productTypeData?.getProductType.name;
  const locationName = productTypeDataName
    ? `${t('navBar.contextMenu.recentStepsTitles.productType')} "${productTypeDataName}"`
    : '';

  useEffect(() => {
    setTimeout(() => setSelectedItemsIds([]), UNSELECT_ITEMS_TIMEOUT);
  }, [searchInputValue, hasFilters]);

  useEffect(() => {
    if (!checkCatalogMappingsErrorsQueryLoading) {
      setIsExportPopupOpen(false);
    }
  }, [checkCatalogMappingsErrorsQueryLoading]);

  useEffect(() => {
    if (itemsData) {
      setPrevItems(itemsData.getProductItemsByProductTypeId);
    }
  }, [itemsData]);

  const handleCompletenessClick = () => {
    setShowCompleteness((prev) => !prev);
    localStorage.setItem('_completenessState', `${!showCompleteness}`);
  };

  const integrationsMap = useMemo(
    () =>
      currentCatalogInfo?.getCatalogById?.integrations?.reduce(
        (acc: Record<string, CompletenessIntegration>, integration) => {
          acc[integration.type] = { id: integration.id, type: integration.type };

          return acc;
        },
        {},
      ) || {},
    [currentCatalogInfo?.getCatalogById?.integrations],
  );

  const selectedCatalogIntegrationsNotToSync = useMemo(() => {
    if (currentCatalogInfo?.getCatalogById) {
      return getUnSyncableIntegrations(currentCatalogInfo?.getCatalogById);
    }
    return [];
  }, [currentCatalogInfo]);

  const handleItemsPerPage = (event: React.MouseEvent<HTMLElement>) => {
    const target = event.target as HTMLElement;

    if (target && target.textContent) {
      setItemsPerPage(+target.textContent);
    }
  };

  const handlePageClick = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  return (
    <Page title={productType?.name || ''} id={pageId} locationName={locationName}>
      <Box mb="25px">
        <DashboardBreadCrumbs editingName={productType?.name} />
      </Box>
      <Box>
        <ProductItemsHeader
          activeJobLoading={isCatalogJobLoading}
          checkIsLimitReachedLoading={checkIsLimitReachedLoading}
          checkIsCatalogShared={isCatalogShared}
          title={productType?.name || ''}
          hasItems={hasItems}
          onAddButtonClick={handleAddNewItem}
          selectedItems={selectedItemsIds}
          onChange={handleChangeSearchValue}
          searchInputValue={searchInputValue}
          isSearchBarDisabled={fetchFiltersLoading || fetchItemsLoading || productTypeFiltersLoading}
          handleShowFilters={handleShowFilters}
          showFilters={showFilters}
          bulkSelectComponent={bulkSelectComponent}
          hideExportToAttachedButton={hideExportToAttachedButton}
          checkIsLimitReachedVariables={checkIsLimitReachedData?.checkIsLimitReached}
          integrations={currentCatalogInfo?.getCatalogById?.integrations}
          showItemsControlElements={hasItems || hasNotFoundedItems}
          onOpenExportPopup={() => setIsExportPopupOpen(true)}
          showCompleteness={showCompleteness}
          onShowCompletenessChange={handleCompletenessClick}
          productType={productType}
          syncable={currentCatalogInfo?.getCatalogById?.syncable}
        />
        <Box display="flex" overflow="hidden">
          <ProductItemsSidebar
            onChangeSelect={handleChangeSelect}
            filtersLoading={fetchFiltersLoading}
            onResetFilters={handleResetFilters}
            setLoading={handleSetLoading}
            resultsFound={productTypeFiltersData?.getProductTypeFiltersWithItemsCount.itemsCount}
            filters={Object.values(filterState.filterItemsMap)}
            showFilters={showFilters}
            onChangeFilter={handleChangeFilterItem}
            id="filters"
          />
          <Box display="flex" flexDirection="column" width="100%">
            {itemsListLoading ? (
              <SkeletonProductItemCardWrapper>
                <SkeletonProductItemCard cardsCount={6} />
              </SkeletonProductItemCardWrapper>
            ) : hasItems ? (
              <ProductItemsList className={clsx(!showFilters && classes.collapsedSidebarItemListMargin)} id="items">
                <Pagination
                  pagesLength={Math.ceil(
                    (productTypeFiltersData?.getProductTypeFiltersWithItemsCount?.itemsCount || 0) / itemsPerPage,
                  )}
                  onPageClick={handlePageClick}
                  onItemsPerPageClick={handleItemsPerPage}
                  itemsPerPage={itemsPerPage}
                  page={page}
                >
                  <div className={classes.itemsContainer}>
                    {prevItems?.map(({ id, values, status, mediaUsages, cloneParentId }) => {
                      return (
                        <Box key={id} position="relative">
                          <ItemCard
                            propertiesItem={fieldsForShowInPreview}
                            productItemArray={values}
                            status={status as ItemStatus}
                            isCatalogShared={isCatalogShared}
                            productItemName={getValueByProductFieldName(values, FieldSystemName.Name)}
                            productItemPrice={getValueByProductFieldName(values, FieldSystemName.Price)}
                            onItemCardClick={() => !hasSelectedItems && handleItemCardClick(id)}
                            onSelectCard={() => handleSelectCard(id)}
                            onUnselectCard={() => handleUnselectCard(id)}
                            hasSelectedItems={hasSelectedItems}
                            isAllItemsSelected={isAllItemsSelectedReal}
                            mediaUsages={mediaUsages}
                            cloneCard={!!cloneParentId}
                          />
                          {showCompleteness && (
                            <ChipsCompleteness
                              integrationsMap={integrationsMap}
                              fieldsMapping={fieldMappingsData?.productTypeFieldIntegrationMappings || []}
                              fieldsValues={values}
                              isDataLoading={fieldsMappingLoading || currentCatalogLoading}
                              mediaUsages={mediaUsages}
                            />
                          )}
                        </Box>
                      );
                    })}
                  </div>
                </Pagination>
              </ProductItemsList>
            ) : (
              <Grid container direction="column" justifyContent="center" alignItems="center">
                <Grid item marginTop="54px" maxHeight="220px">
                  {isImageLoading && ProductItemsBlur}
                  <img
                    src={hasNotFoundedItems ? productItemsNotFound : createItemIcon}
                    className={hasNotFoundedItems ? classes.hasNotFoundItemsImage : classes.hasFoundItemsImage}
                    alt="CreateItem"
                    onLoad={() => setIsImageLoading(false)}
                  />
                </Grid>

                <Grid item marginTop="30px">
                  <Typography variant="subtitle1" fontWeight="normal">
                    {t(`productItemsPage.noItems${hasNotFoundedItems ? 'FoundTop' : ''}`)}
                  </Typography>
                </Grid>

                <Grid item>
                  {hasNotFoundedItems && (
                    <Typography variant="subtitle1" fontWeight="normal">
                      {t('productItemsPage.noItemsFound')}
                    </Typography>
                  )}

                  {showCreateItemButton && (
                    <Typography variant="subtitle1" fontWeight="normal">
                      {t('productItemsPage.createItemHeadline')}
                    </Typography>
                  )}
                </Grid>

                <Grid item marginTop="25px">
                  {showCreateItemButton && (
                    <StyledButton
                      variant="contained"
                      onClick={handleAddNewItem}
                      startIcon={<Iconography iconName="add" />}
                    >
                      {t('productItemsPage.createItemButton')}
                    </StyledButton>
                  )}
                </Grid>
              </Grid>
            )}
          </Box>
        </Box>
      </Box>
      <DeleteItemPopup
        open={isDeleteItemPopupOpen}
        onClose={handleCloseDeleteItemPopup}
        onMainButtonClick={() =>
          handleDeleteItems(
            isAllItemsSelected ? BULK_SELECT_ACTIONS.deleteAllItems : BULK_SELECT_ACTIONS.deleteSelectedItems,
          )
        }
        onSecondaryButtonClick={handleCloseDeleteItemPopup}
        TransitionProps={{
          onExited: () => setIsAllItemsSelectedSynthetic(false),
        }}
        loadingOnMainButton={deleteSelectedItemsLoading || deleteAllItemsLoading}
        mainTitle={
          <Box>
            <Typography variant="h2">{t('deletePopup.item.areYouSure')}</Typography>

            <Typography variant="h2">
              {isAllItemsSelected
                ? t('deletePopup.item.deleteAllTitle')
                : t('deletePopup.item.title', { count: selectedItemsCount })}
            </Typography>
          </Box>
        }
        descriptionText={t(`deletePopup.item.${isAllItemsSelected ? 'deleteAllItemsDescription' : 'text'}`)}
      />

      {productType && (
        <BulkEditPopup
          isAllItemsSelected={isAllItemsSelected}
          open={isBulkEditPopupOpen}
          onClose={handleCloseBulkEditPopup}
          productType={productType}
          setSelectedItemsIds={setSelectedItemsIds}
          selectedItems={selectedItems}
          setIsAllItemsSelectedSynthetic={setIsAllItemsSelectedSynthetic}
        />
      )}

      {isExportPopupOpen && (
        <ExportToIntegrationsPopup
          open={isExportPopupOpen}
          loadingOnMainButton={getProposedMappingsLoading || checkCatalogMappingsErrorsQueryLoading}
          handleExport={handleExportProductType}
          handleClose={handleCloseExportPopup}
          selectedCatalogIntegrationsNotToSync={selectedCatalogIntegrationsNotToSync}
          selectedCatalogId={currentCatalogInfo?.getCatalogById.id}
        />
      )}

      <FormProvider {...copyProductItemFormProps}>
        <CopyProductItemPopup
          initialValue={selectedItemSkuValue}
          open={isCopyItemPopupOpen}
          copyProductItem={copyItem}
          loadingOnMainButton={copyItemLoading}
          onClose={() => setIsCopyItemPopupOpen(false)}
        />
      </FormProvider>

      {renderMappingsSidebar()}

      {/*MOTHBALLED TO MVP*/}
      {/*<ExportFilesPopup*/}
      {/*  titleText={t('exportFilesPopup.title')}*/}
      {/*  open={isExportFilesPopupOpen}*/}
      {/*  onClose={() => {*/}
      {/*    setIsExportFilesPopupOpen(false);*/}
      {/*    setCurrentExportCardId('');*/}
      {/*  }}*/}
      {/*  onMainButtonClick={handleClickExportFilesPopup}*/}
      {/*  mainButtonDisabled={!currentExportCardId}*/}
      {/*  descriptionText={*/}
      {/*    isAllItemsSelected*/}
      {/*      ? t('exportFilesPopup.titleDescriptionAll')*/}
      {/*      : t('exportFilesPopup.titleDescription', { count: selectedItemsCount })*/}
      {/*  }*/}
      {/*  currentCardId={currentExportCardId}*/}
      {/*  onClickExportCard={setCurrentExportCardId}*/}
      {/*/>*/}
      {/*<ExportToCsvPopup*/}
      {/*  open={isExportToCsvPopupOpen}*/}
      {/*  onMainButtonClick={() => setIsExportToCsvPopupOpen(false)}*/}
      {/*  onClose={() => setIsExportToCsvPopupOpen(false)}*/}
      {/*  isExporting={isExporting}*/}
      {/*  isExportSuccess={isExportSuccess}*/}
      {/*  isExportError={isExportError}*/}
      {/*/>*/}
    </Page>
  );
};

export default ProductItems;
