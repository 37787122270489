import React, { useCallback, useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Collapse } from '@mui/material';

import IntegrationUrlInput from 'src/components/Forms/FormInputs/IntegrationUrlInput';
import { integrationUrlFormSchema, itemMappingEbayFormSchema } from 'src/components/Forms/validationSchemas';
import Select from 'src/components/Select';
import SkeletonItemMapping from 'src/components/Skeleton/SkeletonItemMapping';
import { IntegrationTypes, ProductItemMapping, ProductVariant } from 'src/utils/gql';

import useItemMapping from '../context/ItemMappingProvider';

import NoMappingInfo from './NoMappingInfo';
import ProductInfo from './ProductInfo';
import ProductVariantsList from './ProductVariantsList';
import { IntegrationContentWrapper } from './styled';

export interface IntegrationContentProps {
  integrationType: IntegrationTypes;
  integrationId: string;
  mapping?: ProductItemMapping;
  loadingCreateMapping: boolean;
  loadingCreateMappingByShopifyExternalId: boolean;
  loadingSyncItemMapping: boolean;
  loadingExport: boolean;
  integrationName?: string;
}

const IntegrationContent = ({
  integrationType,
  integrationId,
  mapping,
  loadingCreateMapping,
  loadingCreateMappingByShopifyExternalId,
  loadingExport,
  integrationName,
}: IntegrationContentProps) => {
  const { t } = useTranslation();

  const isEbayIntegration = integrationType === IntegrationTypes.Ebay;
  const formValidationSchema = isEbayIntegration ? itemMappingEbayFormSchema : integrationUrlFormSchema;

  const methods = useForm<{ integrationUrl: string; siteId?: string }>({
    mode: 'onChange',
    resolver: yupResolver(formValidationSchema),
  });

  const {
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const { onCreateMappingByUrl, onOpenDetachPopup, onCreateMappingByExternalId, ebaySiteIds } = useItemMapping();

  const productInfo = mapping?.externalProductInfo;

  const [showUrlField, setShowUrlField] = useState<boolean>(false);
  const [productVariants, setProductVariants] = useState<ProductVariant[]>([]);
  const [activeMetadataExternalId, setActiveMetadataExternalId] = useState<string>('');

  const handleApply = handleSubmit(async ({ integrationUrl, siteId }) => {
    // TODO: Add url checks by integration type before mutation (integrationType)

    if (!!productVariants.length) {
      setProductVariants([]);
    }

    const productVariantsData = await onCreateMappingByUrl(integrationUrl, integrationId, siteId);

    if (productVariantsData) {
      setProductVariants(productVariantsData);
    }
  });

  const handleCreateMappingButtonClick = async (integrationId: string, metadataExternalId: string) => {
    setActiveMetadataExternalId(metadataExternalId);

    await onCreateMappingByExternalId(integrationId, metadataExternalId);

    setActiveMetadataExternalId('');
  };

  const handleClose = useCallback(() => {
    setProductVariants([]);
    setShowUrlField(false);
    reset();
  }, [reset]);

  useEffect(() => {
    document.addEventListener('resetState', handleClose);

    return () => {
      document.removeEventListener('resetState', handleClose);
    };
  }, [handleClose]);

  return (
    <IntegrationContentWrapper>
      {productInfo ? (
        <ProductInfo
          integrationType={integrationType}
          productInfo={productInfo}
          mapping={mapping}
          onOpenDetachPopup={() => onOpenDetachPopup(mapping.id)}
          isExportInProgress={loadingExport}
        />
      ) : (
        <>
          {showUrlField ? (
            <>
              <FormProvider {...methods}>
                <form
                  data-testid={`integrationUrlForm-${integrationId}`}
                  noValidate
                  autoComplete="off"
                  onSubmit={handleApply}
                >
                  {isEbayIntegration && (
                    <Controller
                      name="siteId"
                      control={methods.control}
                      render={({ field: { onChange } }) => (
                        <Collapse in={isEbayIntegration}>
                          <Box mb="24px">
                            <Select
                              fullwidth
                              error={!!errors.siteId}
                              helperText={errors.siteId ? t('mappingSidebar.itemMapping.content.siteId') : ''}
                              options={ebaySiteIds}
                              onChangeSelect={({ value }) => onChange(value)}
                              disabled={false}
                              label={t('mappingSidebar.itemMapping.chooseSiteId')}
                              emptyOptionPlaceholder={t('mappingSidebar.itemMapping.chooseSiteId')}
                              data-testid={`siteIdInput-${integrationId}`}
                              integrationId={integrationId}
                            />
                          </Box>
                        </Collapse>
                      )}
                    />
                  )}
                  <IntegrationUrlInput
                    label={t('mappingSidebar.itemMapping.content.integrationUrl')}
                    onClose={handleClose}
                    shouldFocus={!isEbayIntegration}
                    testId={`${integrationName}_integration_url_input`}
                    loading={loadingCreateMapping || loadingCreateMappingByShopifyExternalId}
                    integrationId={integrationId}
                    integrationType={integrationType}
                  />
                </form>
              </FormProvider>

              {loadingCreateMapping && (
                <Box mt="27px">
                  <SkeletonItemMapping />
                </Box>
              )}

              {!!productVariants.length && (
                <ProductVariantsList
                  productVariants={productVariants}
                  integrationId={integrationId}
                  activeMetadataExternalId={activeMetadataExternalId}
                  loadingCreateMappingByShopifyExternalId={loadingCreateMappingByShopifyExternalId}
                  onCreateMappingButtonClick={handleCreateMappingButtonClick}
                />
              )}
            </>
          ) : (
            <NoMappingInfo
              showEbayText={isEbayIntegration}
              onAttachButtonClick={() => setShowUrlField(true)}
              isExportInProgress={loadingExport}
            />
          )}
        </>
      )}
    </IntegrationContentWrapper>
  );
};

export default IntegrationContent;
