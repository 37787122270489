import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

interface PortalProps {
  condition: boolean;
  children: React.ReactNode;
}

const Portal = ({ condition, children }: PortalProps) => {
  const portalElementRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    portalElementRef.current = document.createElement('div');
    document.body.appendChild(portalElementRef.current);

    return () => {
      if (portalElementRef.current) {
        document.body.removeChild(portalElementRef.current);
      }
    };
  }, []);

  return <>{condition && portalElementRef.current && createPortal(children, portalElementRef.current)}</>;
};

export default Portal;
