import React, {
  ChangeEvent,
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import { ApolloError, useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { Box } from '@material-ui/core';

import DashboardBreadCrumbs from 'src/components/Breadcrumds/DashboardBreadCrumbs';
import { StyledFab } from 'src/components/ButtonScrollToTop';
import ProductTypesHeader from 'src/components/Headers/ProductTypesHeader';
import Iconography from 'src/components/Iconography';
import Page from 'src/components/Page';
import Pagination from 'src/components/Pagination';
import ExportToIntegrationsPopup from 'src/components/Popups/ExportToIntegrationsPopup';
import SideBar from 'src/components/SideBar';
import ProductTypesSidebar from 'src/components/SideBar/ProductTypesSidebar';
import { PRODUCT_TYPES_DEFAULT_LIMIT } from 'src/constants';
import {
  CATALOGS_PATH,
  CREATION,
  EDITING,
  FIRST_DASHBOARD_PATHNAME,
  MAPPINGS,
  PRODUCT_ITEMS_PATH,
  PRODUCT_TYPES_PATH,
} from 'src/constants/routeSources';
import { validateTextFieldValue } from 'src/helpers/validationCheck';
import { useCheckCatalogMappingErrors } from 'src/hooks/graphQLRequestsHooks';
import useCheckProposedMappings from 'src/hooks/useCheckProposedMappings';
import useDebounceValue from 'src/hooks/useDebounceValue';
import { useAccount } from 'src/providers/account';
import { useCatalogs } from 'src/providers/catalog';
import useDataTransferStatus from 'src/providers/catalog/hooks/useDataTransferStatus';
import { useLimitReached } from 'src/providers/limitReached';
import { MediaStateContext } from 'src/providers/MediaProvider/context';
import useOnboarding from 'src/providers/OnboardingProvider/hooks/useOnboarding';
import { useSnackbar } from 'src/providers/snackbar';
import { removeDuplicatedObjects } from 'src/utils/general';
import { getUnSyncableIntegrations } from 'src/utils/getUnSyncableIntegrations';
import {
  CatalogJobType,
  checkIsCatalogEmpty,
  checkIsLimitReached,
  deleteProductTypeById,
  exportCatalogToAllAttachedIntegrations,
  getCatalogInfo,
  getProductTypesByCatalogId,
  LimitType,
  Mutation,
  MutationExportCatalogToAllAttachedIntegrationsArgs,
  ProductType,
  Query,
  QueryGetCatalogByIdArgs,
  QueryProductTypesArgs,
  getProductTypesCountByCatalogId,
} from 'src/utils/gql';
import { deleteItemDataLocations } from 'src/utils/locationArrays';
import { productPathGeneration } from 'src/utils/productPathGeneration';

import NoProductTypesComponent from './NoProductTypesComponent';
import NoProductTypesFoundComponent from './NoProductTypesFoundComponent';
import ProductTypeItem from './ProductTypeItem';
import ProductTypesLoadingComponent from './ProductTypesLoadingComponent';
import { ProductTypesList, ProductTypesWrapper } from './styled';

//MOTHBALLED TO MVP

// interface ProductTypeState {
//   id: string;
//   name: string;
// }

const ProductTypesPage = () => {
  const history = useHistory();
  const snackbar = useSnackbar();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const {
    user: { id: userId },
  } = useAccount();
  const limitReached = useLimitReached();
  const productTypesListRef = useRef<HTMLElement>();
  const {
    catalogs,
    loadingGet: loadingCatalogs,
    hasMore: hasMoreCatalogs,
    handleFetchMore: fetchMoreCatalogs,
    getCatalogJob,
    updateCatalogsJobsMap,
    updateCurrentExport,
  } = useCatalogs();
  const {
    onboardingState: { tourActive, createdOnboardingCatalog, createdOnboardingProductType },
  } = useOnboarding();

  const lastCatalogJob = getCatalogJob({ catalogId: id });
  const { setMediaState } = useContext(MediaStateContext);

  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const [isImageLoading, setIsImageLoading] = useState(true);
  const [searchInputValue, setSearchInputValue] = useState<string>('');
  const [showButtonUp, setShowButtonUp] = useState<boolean>(false);
  const [isExportPopupOpen, setIsExportPopupOpen] = useState<boolean>(false);
  const [updateAfterDeletingProductType, setUpdateAfterDeletingProductType] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);

  const { checkCatalogMappingsErrorsQuery, loading: loadingErrors } = useCheckCatalogMappingErrors(id);

  const debouncedSearchValue = useDebounceValue(searchInputValue, 1000).trim();

  //MOTHBALLED TO MVP

  // const [isChooseExportPopupOpen, setIsChooseExportPopupOpen] = useState<boolean>(false);
  // const [productTypeState, setProductTypeState] = useState<ProductTypeState>({
  //   id: '',
  //   name: '',
  // });

  const { data: checkIsLimitReachedData } = useQuery(checkIsLimitReached, {
    fetchPolicy: 'network-only',
    variables: { type: LimitType.Items },
  });

  const { data: catalogData, loading: catalogInfoLoading } = useQuery<
    Pick<Query, 'getCatalogById'>,
    QueryGetCatalogByIdArgs
  >(getCatalogInfo, {
    fetchPolicy: 'cache-first',
    variables: { id },
    onCompleted: (data) => {
      const jobs = data.getCatalogById.lastIntegrationsJobs;

      updateCatalogsJobsMap(jobs);
    },
    skip: tourActive,
  });

  const { inProgress: isCatalogJobLoading, type: dataTransferType } = useDataTransferStatus({
    catalogId: id,
    onSuccess: () => {
      handleRefetchCount();
      refetch();
    },
    onCompleted: () => {
      setUpdateAfterDeletingProductType(false);
      checkCatalogMappingsErrorsQuery();
    },
  });

  const skipProductTypesFetch = isCatalogJobLoading && dataTransferType === CatalogJobType.Import;

  const {
    data: productTypesData,
    loading,
    refetch,
  } = useQuery<Pick<Query, 'productTypes'>, QueryProductTypesArgs>(getProductTypesByCatalogId, {
    variables: {
      catalogId: id,
      limit: PRODUCT_TYPES_DEFAULT_LIMIT,
      offset: (page - 1) * PRODUCT_TYPES_DEFAULT_LIMIT,
      subName: debouncedSearchValue,
    },
    fetchPolicy: 'cache-first',
    skip: Boolean(skipProductTypesFetch) || tourActive,
  });

  const { data: totalProductTypes, refetch: refetchCount } = useQuery(getProductTypesCountByCatalogId, {
    variables: {
      catalogId: id,
      subName: debouncedSearchValue,
    },
  });

  const [deleteProductType, { loading: loadingDeleteProductType }] = useMutation(deleteProductTypeById, {
    onCompleted: () => {
      setUpdateAfterDeletingProductType(true);
      checkCatalogMappingsErrorsQuery();
    },
  });

  const [checkIsCatalogEmptyQuery] = useLazyQuery<Pick<Query, 'getCatalogById'>, QueryGetCatalogByIdArgs>(
    checkIsCatalogEmpty,
    { fetchPolicy: 'network-only', variables: { id } },
  );

  const [syncCatalog, { loading: syncCatalogLoading }] = useMutation<
    Pick<Mutation, 'exportCatalogToAllAttachedIntegrations'>,
    MutationExportCatalogToAllAttachedIntegrationsArgs
  >(exportCatalogToAllAttachedIntegrations);

  const hasProductTypes = tourActive ? true : !!productTypesData?.productTypes.length;
  const productTypes = tourActive ? ([createdOnboardingProductType] as ProductType[]) : productTypesData?.productTypes;

  const onAddNewProductType = useCallback(() => {
    history.push(`/${FIRST_DASHBOARD_PATHNAME}/${CATALOGS_PATH}/${id}/${CREATION}`);
  }, [history, id]);

  const handleClickButtonUp = () => productTypesListRef?.current?.scrollTo({ top: 0, behavior: 'smooth' });

  const handleExportToAttachedIntegrations = () => {
    getListWithProposedMappings(id);
  };

  const handleCloseExportPopup = () => setIsExportPopupOpen(false);

  const { getListWithProposedMappings, getProposedMappingsLoading, renderMappingsSidebar } = useCheckProposedMappings({
    callbackFn: () => {
      updateCurrentExport(id);

      syncCatalog({ variables: { id } })
        .then(handleCloseExportPopup)
        .catch(({ message }) => {
          checkCatalogMappingsErrorsQuery();
          snackbar(message);
        });
    },
    errorCallbackFn: checkCatalogMappingsErrorsQuery,
    closeCallbackFn: handleCloseExportPopup,
  });

  const handleDeleteProductType = async (productTypeId: string) => {
    try {
      await deleteProductType({
        variables: {
          id: productTypeId,
        },
        update(cache) {
          cache.modify({
            fields: {
              productTypes(existingProductTypesRefs: { __ref: string }[], { readField }) {
                return existingProductTypesRefs.filter(
                  (productTypeRef) => productTypeId !== readField('id', productTypeRef),
                );
              },
            },
          });

          checkIsCatalogEmptyQuery();
        },
      });
      handleRefetchCount();
      refetch();

      snackbar(t('productTypesPage.snackbarSuccess'), 'success');
      deleteItemDataLocations(productPathGeneration(id, productTypeId));
    } catch (error) {
      const { graphQLErrors, message: errorText } = error as ApolloError;
      const message = graphQLErrors && (graphQLErrors.length ? graphQLErrors[0].message : errorText);

      if (error) {
        snackbar(message);
      }
    }
  };

  const currentCatalog = tourActive ? createdOnboardingCatalog : catalogData?.getCatalogById;
  const isCurrentCatalogShared = currentCatalog?.user.id !== userId;
  const catalogName = currentCatalog?.name || '';

  const locationName = catalogName ? `${t('navBar.contextMenu.recentStepsTitles.catalog')} "${catalogName}"` : '';

  const onProductTypeClick = (productTypeId: string) => {
    history.push({
      pathname: `/${FIRST_DASHBOARD_PATHNAME}/${CATALOGS_PATH}/${id}/${PRODUCT_TYPES_PATH}/${productTypeId}/${PRODUCT_ITEMS_PATH}`,
      state: {
        isCurrentCatalogShared,
      },
    });
  };

  const onAddProductItemClick = (e: SyntheticEvent<HTMLButtonElement>, productTypeId: string) => {
    e.stopPropagation();

    if (checkIsLimitReachedData?.checkIsLimitReached) {
      limitReached({ limitReachedType: LimitType.Items });
    } else {
      setMediaState([]);

      history.push(
        `/${FIRST_DASHBOARD_PATHNAME}/${CATALOGS_PATH}/${id}/${PRODUCT_TYPES_PATH}/${productTypeId}/${CREATION}`,
      );
    }
  };

  const onEditProductTypeClick = (e: SyntheticEvent<HTMLButtonElement>, productTypeId: string) => {
    e.stopPropagation();
    history.push(
      `/${FIRST_DASHBOARD_PATHNAME}/${CATALOGS_PATH}/${id}/${PRODUCT_TYPES_PATH}/${productTypeId}/${EDITING}`,
    );
  };

  const goToMappingsPage = (e: SyntheticEvent<HTMLButtonElement>, productTypeId: string) => {
    e.stopPropagation();
    history.push(
      `/${FIRST_DASHBOARD_PATHNAME}/${CATALOGS_PATH}/${id}/${PRODUCT_TYPES_PATH}/${productTypeId}/${MAPPINGS}`,
    );
  };

  //MOTHBALLED TO MVP

  // const onExportIconClick = (e: SyntheticEvent<HTMLButtonElement, MouseEvent>, id: string, name: string) => {
  //   e.stopPropagation();
  //   setProductTypeState((prevState: ProductTypeState) => {
  //     return {
  //       ...prevState,
  //       id,
  //       name,
  //     };
  //   });
  //   setIsChooseExportPopupOpen(true);
  // };

  const handleChangeSearchValue = ({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
    setSearchInputValue(validateTextFieldValue(value));

  const handleShowButtonUp = () => {
    setShowButtonUp(Number(productTypesListRef?.current?.scrollTop) >= document.documentElement.clientHeight);
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'visible';
    };
  }, []);

  useEffect(() => {
    if (!loadingErrors) {
      setIsExportPopupOpen(false);
    }
  }, [loadingErrors]);

  const handleRefetchCount = useCallback(() => {
    refetchCount({
      catalogId: id,
      subName: debouncedSearchValue,
    });
  }, [id, debouncedSearchValue, refetchCount]);

  useEffect(() => {
    if (debouncedSearchValue) {
      handleRefetchCount();
    }
  }, [debouncedSearchValue, handleRefetchCount]);

  useEffect(() => {
    handleRefetchCount();
  }, [handleRefetchCount]);

  useEffect(() => {
    setPage(1);
  }, [totalProductTypes]);

  useLayoutEffect(() => {
    refetch({ limit: PRODUCT_TYPES_DEFAULT_LIMIT, offset: (page - 1) * PRODUCT_TYPES_DEFAULT_LIMIT });
  }, [page, refetch]);

  const handlePageClick = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const selectedCatalogIntegrationsNotToSync = useMemo(() => {
    if (currentCatalog) {
      return getUnSyncableIntegrations(currentCatalog);
    }
    return [];
  }, [currentCatalog]);

  const mappingsErrors = useMemo(
    () =>
      removeDuplicatedObjects(
        [
          ...(currentCatalog?.mappingsErrors || []),
          ...(currentCatalog?.integrations?.flatMap(
            ({ mappingsErrors }) => mappingsErrors?.filter(({ catalogId }) => !catalogId || catalogId === id) || [],
          ) || []),
        ],
        'id',
      ),
    [currentCatalog?.integrations, currentCatalog?.mappingsErrors, id],
  );

  const renderNoProductTypes = () => {
    return debouncedSearchValue ? (
      <NoProductTypesFoundComponent isImageLoading={isImageLoading} setIsImageLoading={setIsImageLoading} />
    ) : (
      <NoProductTypesComponent
        isImageLoading={isImageLoading}
        setIsImageLoading={setIsImageLoading}
        isCurrentCatalogShared={isCurrentCatalogShared}
        activeJobLoading={Boolean(isCatalogJobLoading)}
        onAddNewProductType={onAddNewProductType}
      />
    );
  };

  return (
    <Page title={catalogName} locationName={locationName}>
      <Box display="flex" height="100vh">
        <SideBar isExpanded={isExpanded} title="Catalogs" onClick={() => setIsExpanded(!isExpanded)} width="334px">
          <ProductTypesSidebar
            onCatalogCardClick={() => productTypesListRef?.current?.scrollTo(0, 0)}
            loading={!tourActive && (loadingCatalogs || catalogInfoLoading)}
            userId={userId}
            catalog={currentCatalog}
            catalogs={tourActive ? [] : catalogs}
            onFetchMore={fetchMoreCatalogs}
            hasMore={!tourActive && hasMoreCatalogs}
          />
        </SideBar>

        <ProductTypesWrapper onScroll={handleShowButtonUp} ref={productTypesListRef} id="productTypesList">
          <Box display="flex" flexDirection="column" width="100%">
            <Box padding="0 56px 45px 24px">
              <Box mb="25px">
                <DashboardBreadCrumbs />
              </Box>

              <ProductTypesHeader
                isCurrentCatalogShared={isCurrentCatalogShared}
                catalogueName={catalogName}
                catalogId={id}
                onAddNewProductType={onAddNewProductType}
                onChange={handleChangeSearchValue}
                searchInputValue={searchInputValue}
                hasProductTypes={hasProductTypes}
                activeJob={lastCatalogJob}
                isJobInProgress={isCatalogJobLoading || (!updateAfterDeletingProductType && loadingErrors)}
                isEmpty={currentCatalog?.isEmpty}
                syncable={currentCatalog?.syncable}
                mappingErrors={mappingsErrors}
                duplicatedSKUInfo={currentCatalog?.duplicatedSKUInfo}
                hasIntegrations={Boolean(currentCatalog?.integrations?.length && hasProductTypes)}
                onOpenExportPopup={() => setIsExportPopupOpen(true)}
                integrations={currentCatalog?.integrations}
              />
            </Box>

            {loading ? (
              <ProductTypesLoadingComponent />
            ) : hasProductTypes ? (
              <Pagination
                pagesLength={Math.ceil(
                  (totalProductTypes?.getProductTypesCountByCatalogId || 0) / PRODUCT_TYPES_DEFAULT_LIMIT,
                )}
                onPageClick={handlePageClick}
                page={page}
              >
                <ProductTypesList sx={{ position: tourActive ? 'relative' : 'static' }}>
                  {tourActive && <Box id="product-type-list-anchor" position="absolute" width="100%" height="90px" />}

                  {productTypes?.map(({ id, name }) => (
                    <ProductTypeItem
                      key={id}
                      name={name}
                      isProductTypeFromSharedCatalog={isCurrentCatalogShared}
                      onProductTypeClick={() => onProductTypeClick(id)}
                      onAddProductItemClick={(e: SyntheticEvent<HTMLButtonElement>) => onAddProductItemClick(e, id)}
                      onEditProductTypeClick={(e) => onEditProductTypeClick(e, id)}
                      onDeleteProductType={() => handleDeleteProductType(id)}
                      goToMappingsPage={(e) => goToMappingsPage(e, id)}
                      isCatalogJobLoading={isCatalogJobLoading}
                      loading={loadingDeleteProductType}
                    />
                  ))}
                </ProductTypesList>
              </Pagination>
            ) : (
              renderNoProductTypes()
            )}
          </Box>

          {showButtonUp && (
            <StyledFab onClick={handleClickButtonUp} disableRipple id="scroll-to-top-button">
              <Iconography iconName="chevron-arrow-right" fontSize="large" />
            </StyledFab>
          )}
        </ProductTypesWrapper>
      </Box>

      <ExportToIntegrationsPopup
        loadingOnMainButton={getProposedMappingsLoading || syncCatalogLoading}
        handleExport={handleExportToAttachedIntegrations}
        open={isExportPopupOpen}
        handleClose={handleCloseExportPopup}
        selectedCatalogIntegrationsNotToSync={selectedCatalogIntegrationsNotToSync}
        selectedCatalogId={currentCatalog?.id}
      />

      {renderMappingsSidebar()}
      {/*MOTHBALLED TO MVP*/}

      {/*<ExportCatalogPopup*/}
      {/*  id={productTypeState.id}*/}
      {/*  name={productTypeState.name}*/}
      {/*  isChooseExportPopupOpen={isChooseExportPopupOpen}*/}
      {/*  closeExportPopupCallback={() => setIsChooseExportPopupOpen(false)}*/}
      {/*  type={ExportPopupQueryType.PRODUCT_TYPE}*/}
      {/*/>*/}
    </Page>
  );
};

export default ProductTypesPage;
