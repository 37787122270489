import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { Box, Typography } from '@material-ui/core';
import axios from 'axios';

import Button from 'src/components/Button';
import { ReactComponent as LogoImage } from 'src/components/Images/Logo.svg';
import Page from 'src/components/Page';
import { clearIntegrationsCache } from 'src/components/Popups/IntegrationPopup/functions';
import Select from 'src/components/Select';
import { EbayUrlParams, FacebookHashParams, ShopifyUrlParams } from 'src/constants';
import LoginLayout from 'src/layouts/Auth/Login';
import { useSnackbar } from 'src/providers/snackbar';
import palette from 'src/theme/palette';
import {
  createIntegration,
  getIntegrations,
  IntegrationTypes,
  Maybe,
  Mutation,
  MutationCreateIntegrationArgs,
  Query,
  QueryGetIntegrationsArgs,
} from 'src/utils/gql';

import InstallingStatus from './InstallingStatus';
import { ModalContainer, ConnectButton } from './styled';
import { FacebookBusiness, INSTALLING_STATUSES } from './types';
import { getFacebookValueFromHash } from './utils';

const fetchFacebookBusinesses = async (accessToken: string): Promise<FacebookBusiness[]> => {
  const fields = ['id', 'name'];

  return (
    await axios.get<{ data: FacebookBusiness[] }>(`${process.env.REACT_APP_FACEBOOK_API_URL}/me/businesses`, {
      params: {
        fields: fields.join(','),
        access_token: accessToken,
      },
    })
  ).data.data;
};

const AppInstalling = () => {
  const snackbar = useSnackbar();
  const { t } = useTranslation();
  const { search, hash } = useLocation<{ search: string }>();
  const isDefault = opener?.history?.state?.isDefault;
  const integrationType = opener?.history?.state?.integrationType;
  const urlSearchParams = new URLSearchParams(search);

  const facebookAuthError =
    urlSearchParams.get(FacebookHashParams.Error) ?? urlSearchParams.get(FacebookHashParams.ErrorMessage);
  const facebookAccessToken = getFacebookValueFromHash(hash, FacebookHashParams.AccessToken);
  const [facebookBusinesses, setFacebookBusinesses] = useState<FacebookBusiness[]>([]);
  const [selectedFacebookBusiness, setSelectedFacebookBusiness] = useState<FacebookBusiness>();
  const [isFacebookBusinessSelectPopupOpened, setIsFacebookBusinessSelectPopupOpened] = useState(false);

  const [status, setStatus] = useState<string>(INSTALLING_STATUSES.installing);
  const [integrationId, setIntegrationId] = useState<string>('');

  const formProps = useForm({
    mode: 'onChange',
  });

  const [addIntegrationCallback, { loading }] = useMutation<
    Pick<Mutation, 'createIntegration'>,
    MutationCreateIntegrationArgs
  >(createIntegration, {
    update: clearIntegrationsCache,
  });

  const createIntegrationSettings = (): Record<string, string> => {
    const urlSearchParams = new URLSearchParams(search);
    const integrationName = opener?.history?.state?.integrationName;

    switch (integrationType) {
      case IntegrationTypes.Shopify: {
        const shop = urlSearchParams.get(ShopifyUrlParams.Shop) ?? '';
        const code = urlSearchParams.get(ShopifyUrlParams.Code) ?? '';

        return { shop, code, integrationName };
      }

      case IntegrationTypes.Ebay: {
        const code = urlSearchParams.get(EbayUrlParams.Code) ?? '';

        return { code, integrationName };
      }

      case IntegrationTypes.Facebook: {
        if (facebookAccessToken && selectedFacebookBusiness) {
          return {
            integrationName: selectedFacebookBusiness.name,
            businessId: selectedFacebookBusiness.id,
            shortLivedAccessToken: facebookAccessToken,
          };
        }

        return {};
      }

      default:
        return {};
    }
  };

  const verifyIntegrationSettings = (settings: Record<string, string>): boolean => {
    const { integrationName } = settings;

    switch (integrationType) {
      case IntegrationTypes.Shopify: {
        const { code, shop } = settings;

        return !!(shop && code && integrationName);
      }

      case IntegrationTypes.Ebay: {
        return !!settings.code;
      }

      case IntegrationTypes.Facebook: {
        return !!(
          facebookAccessToken &&
          integrationName &&
          selectedFacebookBusiness?.id &&
          // to prevent auto installation if the business must be selected
          facebookBusinesses.length === 1
        );
      }

      default:
        return false;
    }
  };

  const settings = createIntegrationSettings();
  const canInstallApp = verifyIntegrationSettings(settings);

  console.log('settings: ', settings);
  console.log('canInstallApp: ', canInstallApp);

  const addIntegration = async () => {
    if (facebookAuthError) {
      snackbar(facebookAuthError);
      return;
    }

    try {
      const payload = {
        type: integrationType,
        isDefault,
        settings,
      };

      const { data } = await addIntegrationCallback({
        variables: {
          data: payload,
        },
      });

      if (data) {
        setIntegrationId(data.createIntegration.id);
      }

      localStorage.setItem('_installingState', INSTALLING_STATUSES.success);
      setStatus(INSTALLING_STATUSES.success);
    } catch (error) {
      const { graphQLErrors, message: errorText } = error as ApolloError;
      const message = graphQLErrors && graphQLErrors.length ? graphQLErrors[0].message : errorText;

      if (error) {
        localStorage.setItem('_installingState', INSTALLING_STATUSES.error);
        setStatus(INSTALLING_STATUSES.error);

        snackbar(message);
      }
    }
  };

  const handleInstallingError = () => {
    if (integrationType === IntegrationTypes.Facebook) {
      setIsFacebookBusinessSelectPopupOpened(true);
    } else {
      addIntegration();
    }
  };

  const handleButtonClick = () => {
    switch (status) {
      case INSTALLING_STATUSES.installing:
        opener?.history.replaceState({ success: false }, '');
        close();
        break;

      case INSTALLING_STATUSES.success:
        opener?.history.replaceState({ success: true, id: integrationId }, '');
        close();
        break;

      case INSTALLING_STATUSES.error:
        handleInstallingError();
        break;

      default:
        break;
    }
  };

  const handleCloseButtonOnError = () => {
    opener?.history.replaceState({ success: false }, '');
    close();
  };

  const handleSelectFacebookBusiness = (selectedBusinessId: string, onChange: (newValue: Maybe<string>) => void) => {
    const business = facebookBusinesses.find(({ id }) => id === selectedBusinessId);

    if (business) {
      setSelectedFacebookBusiness(business);
    }
    onChange(selectedBusinessId);
  };

  const handleSubmitButtonClick = async () => {
    setIsFacebookBusinessSelectPopupOpened(false);
    setStatus(INSTALLING_STATUSES.installing);

    await addIntegration();
  };

  const handleCancelButtonClick = () => {
    setIsFacebookBusinessSelectPopupOpened(false);
    setStatus(INSTALLING_STATUSES.error);
  };

  const showSelect = isFacebookBusinessSelectPopupOpened;

  const transformArrayToSelectOption = (arrayToTransform: FacebookBusiness[]) => {
    return arrayToTransform.map(({ id, name }) => ({ label: name, value: id }));
  };

  const transformToSelectOption = (objToTransform: FacebookBusiness | undefined) => {
    return { label: objToTransform ? objToTransform.name : '', value: objToTransform ? objToTransform.id : '' };
  };

  const skipIntegrationsRequest = integrationType !== IntegrationTypes.Facebook;

  useQuery<Pick<Query, 'getIntegrations'>, QueryGetIntegrationsArgs>(getIntegrations, {
    variables: {
      type: integrationType,
    },
    skip: skipIntegrationsRequest,
    onCompleted: async ({ getIntegrations: existingIntegrations }) => {
      if (facebookAccessToken) {
        try {
          const businesses = await fetchFacebookBusinesses(facebookAccessToken);

          const newBusinesses = businesses.filter(
            ({ id }) => !existingIntegrations?.find((integration) => integration.settings.businessId === id),
          );

          if (newBusinesses.length) {
            setFacebookBusinesses(newBusinesses);
            setSelectedFacebookBusiness(newBusinesses[0]);

            if (newBusinesses.length > 1) {
              setIsFacebookBusinessSelectPopupOpened(true);
            }
          } else {
            snackbar(t('appInstallingPage.facebookBusinessNotFound'));
            setStatus(INSTALLING_STATUSES.error);
          }
        } catch (err) {
          snackbar((err as Error).message);
          setStatus(INSTALLING_STATUSES.error);
        }
      }
    },
    onError: (err) => {
      snackbar(err.message);
      setStatus(INSTALLING_STATUSES.error);
    },
  });

  window.onbeforeunload = (event: BeforeUnloadEvent) => {
    event.preventDefault();

    switch (status) {
      case INSTALLING_STATUSES.installing:
        return confirm('');

      case INSTALLING_STATUSES.success:
        return opener?.history.replaceState({ success: true, id: integrationId }, '');

      case INSTALLING_STATUSES.error:
        return opener?.history.replaceState({ success: false }, '');

      default:
        break;
    }
  };

  useEffect(() => {
    const installingStatus = localStorage.getItem('_installingState');

    if (installingStatus) {
      setStatus(installingStatus);
    }

    if (canInstallApp && status === INSTALLING_STATUSES.installing) {
      addIntegration();
    }
  }, [canInstallApp]); // eslint-disable-line

  useEffect(() => {
    if (facebookAuthError) {
      localStorage.setItem('_installingState', INSTALLING_STATUSES.error);
      setStatus(INSTALLING_STATUSES.error);

      snackbar(facebookAuthError);
    }
  }, [facebookAuthError, snackbar]);

  return (
    <Page title={t('pageTitles.appInstallingPage')}>
      <LoginLayout>
        <Box display="flex" justifyContent="center" alignItems="center" textAlign="center" height="100vh" width="100%">
          <ModalContainer>
            <LogoImage />
            {showSelect ? (
              <Box>
                <Typography variant="subtitle1" mt="40px" mb="32px">
                  {t('appInstallingPage.selectFacebookPage')}
                </Typography>

                <Box width="248px" margin="auto">
                  <Controller
                    name="Facebook Integrations"
                    control={formProps.control}
                    render={({ field: { onChange } }) => (
                      <Select
                        options={transformArrayToSelectOption(facebookBusinesses)}
                        onChangeSelect={({ value: newValue }) =>
                          handleSelectFacebookBusiness(newValue as string, onChange)
                        }
                        selectedOptionItem={transformToSelectOption(selectedFacebookBusiness)}
                        disabled={false}
                        label={t('appInstallingPage.facebookPage')}
                        data-testid={`siteIdInput-${integrationId}`}
                      />
                    )}
                  />
                </Box>
                <Box mt="40px">
                  <Button variant="outlined" onClick={handleCancelButtonClick}>
                    {t('appInstallingPage.installingPopup.secondaryButton')}
                  </Button>
                  <ConnectButton variant="contained" onClick={handleSubmitButtonClick} loading={loading}>
                    {t('appInstallingPage.installingPopup.mainButton')}
                  </ConnectButton>
                </Box>
              </Box>
            ) : (
              <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                <Typography variant="subtitle1" mt="40px" mb="20px">
                  {t(`appInstallingPage.${status}.message`)}
                </Typography>

                {status === INSTALLING_STATUSES.installing && (
                  <InstallingStatus status={status} color={palette.info.main} isLoading={true} />
                )}
                {status === INSTALLING_STATUSES.success && (
                  <InstallingStatus status={status} color={palette.success.main} iconName="check-circle" />
                )}
                {status === INSTALLING_STATUSES.error && (
                  <InstallingStatus status={status} color={palette.error.main} iconName="cancel-circle" />
                )}

                <Box
                  display="flex"
                  minWidth="230px"
                  justifyContent={status === INSTALLING_STATUSES.error ? 'space-between' : 'center'}
                >
                  {status === INSTALLING_STATUSES.error && (
                    <Button variant="outlined" onClick={handleCloseButtonOnError}>
                      {t(`appInstallingPage.errorCloseButton`)}
                    </Button>
                  )}

                  {status !== INSTALLING_STATUSES.installing && (
                    <Button variant="contained" onClick={handleButtonClick} loading={loading}>
                      {t(`appInstallingPage.${status}.button`)}
                    </Button>
                  )}
                </Box>
              </Box>
            )}
          </ModalContainer>
        </Box>
      </LoginLayout>
    </Page>
  );
};

export default AppInstalling;
