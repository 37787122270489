import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useHistory } from 'react-router-dom';

import { Box, Link, Typography } from '@material-ui/core';
import { Favorite } from '@material-ui/icons';

import Button from 'src/components/Button';
import Iconography from 'src/components/Iconography';
import { ReactComponent as LogoImage } from 'src/components/Images/otomate-logo.svg';
import { EXTERNAL_URL } from 'src/constants';

import {
  StyledWrapperPageNotFound,
  StyledBlockPosition,
  StyledContentWrapper,
  StyledTitle,
  StyledSubtitle,
  StyledLink,
  StyledFooterText,
  StyledImageError404,
  StyledImageError500,
  StyledImageError401,
  StyledFooter,
  StyledFooterLinkBlock,
} from './styled';

interface LayoutProps {
  isNotFoundPage?: boolean;
  isUnexpectedPage?: boolean;
  textTitle: string;
  textSubTitle: string;
  children: JSX.Element;
}

const Layout = ({ isNotFoundPage, isUnexpectedPage, textTitle, textSubTitle, children }: LayoutProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleGoHomeClick = () => {
    history.push('/');
  };

  return (
    <StyledWrapperPageNotFound>
      <Box>
        <Link to="/" component={RouterLink} color="secondary" variant="h2" underline="none">
          <LogoImage />
        </Link>
      </Box>
      <StyledBlockPosition>
        <Box display="flex" alignItems="center" justifyContent="center">
          {isNotFoundPage ? (
            <StyledImageError404 />
          ) : isUnexpectedPage ? (
            <StyledImageError500 />
          ) : (
            <StyledImageError401 />
          )}
        </Box>
        <StyledContentWrapper>
          <StyledTitle variant="h1">{textTitle}</StyledTitle>
          <StyledSubtitle variant="h1">{textSubTitle}</StyledSubtitle>
          {children}
          <Box mt="40px">
            <Button variant="contained" onClick={handleGoHomeClick}>
              {t('pageNotFound.buttonText')}
            </Button>
          </Box>
        </StyledContentWrapper>
      </StyledBlockPosition>
      <StyledFooter>
        <StyledFooterLinkBlock>
          <Typography variant="caption">{t('landingPage.footer.copyright')}</Typography>
          <Box>
            <StyledLink href={EXTERNAL_URL.PRIVACY_POLICY}>{t('contactUsPage.form.privacyPolicy')}</StyledLink>
            <Typography variant="caption" color="text.primary">
              {t('contactUsPage.form.privacyAndTermConditionSecond')}
            </Typography>
            <StyledLink href={EXTERNAL_URL.TERMS_AND_CONDITIONS}>
              {t('contactUsPage.form.termsAndConditions')}
            </StyledLink>
          </Box>
        </StyledFooterLinkBlock>
        <StyledFooterText>
          <Typography variant="caption">{t('footerText.madeWith')}</Typography>
          <Iconography htmlColor="secondary">
            <Favorite />
          </Iconography>
          <Typography variant="caption">{t('footerText.otomate')}</Typography>
        </StyledFooterText>
      </StyledFooter>
    </StyledWrapperPageNotFound>
  );
};

export default Layout;
