import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@material-ui/core';

export enum RestObject {
  ProductTypes = 'productTypes',
  ProductItems = 'productItems',
}

export interface RestLabelProps {
  object: RestObject;
  shownCount?: number;
  totalCount?: number;
}

const RestLabel = ({ object, shownCount, totalCount }: RestLabelProps) => {
  const { t } = useTranslation();

  if (!shownCount || !totalCount) return null;

  const restCount = totalCount - shownCount;

  return restCount ? (
    <Typography mt="50px" textAlign="center">
      {t(`previewCatalogPage.${object}.restCount`, { count: restCount })}
    </Typography>
  ) : null;
};

export default RestLabel;
