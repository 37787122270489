import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { Typography, Link, Box } from '@material-ui/core';

import { TopContainer, BottomContainer } from './styled';
import SuccesScreenImageBackground from './SuccesScreenImageBackground';

interface SuccessScreenProps {
  email: string;
}

const SuccessScreen = ({ email }: SuccessScreenProps) => {
  const [isImageLoading, setIsImageLoading] = useState(true);
  const { t } = useTranslation();

  return (
    <Box display="flex" alignItems="center" width="100%" height="100%">
      <BottomContainer>
        <Typography variant="h1">{t('forgotPasswordPage.successScreen.hepIsOnTheWay')}</Typography>

        <Typography variant="body1" color="textSecondary" textAlign="center" maxWidth="477px">
          {t('forgotPasswordPage.successScreen.textPart1')}
          <Typography component="span" variant="subtitle1" color="textSecondary" textAlign="center">
            {email}
          </Typography>
        </Typography>

        <Typography variant="body1" color="textSecondary" textAlign="center" maxWidth="597px">
          {t('forgotPasswordPage.successScreen.textPart2')}
          <br />
          {t('forgotPasswordPage.successScreen.textPart3')}
        </Typography>

        <Link color="secondary" component={RouterLink} to="/auth/login" underline="hover" variant="h3">
          {t('forgotPasswordPage.link')}
        </Link>
      </BottomContainer>
      <TopContainer>
        {isImageLoading && <SuccesScreenImageBackground />}
        <img
          src={`${process.env.PUBLIC_URL}/images/support.webp`}
          alt="Support"
          onLoad={() => setIsImageLoading(false)}
          width="563"
          height="350"
        />
      </TopContainer>
    </Box>
  );
};

export default SuccessScreen;
