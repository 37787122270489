import React, { useState, useMemo, useEffect, ChangeEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useLazyQuery } from '@apollo/client';
import { Box, Typography } from '@material-ui/core';

import Button from 'src/components/Button';
import { PAYMENT_FREQUENCY_OPTIONS } from 'src/constants';
import useDebounceValue from 'src/hooks/useDebounceValue';
import { isSameName, CatalogPrivacy, Catalog } from 'src/utils/gql';
import { getCatalogNameError } from 'src/views/Catalogs/CatalogCreation';
import { CatalogSettingsState } from 'src/views/Catalogs/CatalogSettings/types';

import WrapperTextInput from '../Forms/FormInputs/WrapperTextInput';
import MappingsButton from '../MappingsButton';

interface CataloguesSettingsHeaderProps {
  title?: string;
  settingsState: CatalogSettingsState;
  onSaveButtonClick: () => void;
  onCancelButtonClick: () => void;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  startCatalogName: string;
  updateLoading?: boolean;
  onMappingsButtonClick?: () => void;
  initialCatalogData: Catalog;
  mappingButtonLoading: boolean;
}

export default function CatalogsSettingsHeader({
  title,
  settingsState,
  onSaveButtonClick,
  onCancelButtonClick,
  onChange,
  startCatalogName,
  updateLoading,
  onMappingsButtonClick,
  initialCatalogData,
  mappingButtonLoading,
}: CataloguesSettingsHeaderProps) {
  const { t } = useTranslation();
  const [sameNameError, setSameNameError] = useState<boolean>(false);

  const debouncedSearchTerm = useDebounceValue(settingsState.catalogName, 500);
  const [checkIsSameName] = useLazyQuery(isSameName, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => setSameNameError(!data.checkNameAvailability),
  });

  const isPaymentFree = settingsState?.paymentFrequency.label === PAYMENT_FREQUENCY_OPTIONS[0].label;
  const isPriceCorrect = parseInt(settingsState.price) > 0 || isPaymentFree;

  useEffect(() => {
    if (debouncedSearchTerm !== startCatalogName) {
      checkIsSameName({
        variables: {
          name: debouncedSearchTerm,
        },
      });
    } else {
      setSameNameError(false);
    }
  }, [debouncedSearchTerm, checkIsSameName, startCatalogName, settingsState.catalogName]);

  const error = useMemo(
    () => getCatalogNameError(settingsState.catalogName, true, sameNameError),
    [sameNameError, settingsState.catalogName],
  );

  const {
    formState: { errors },
  } = useFormContext();

  const disabledSaveButton = !!(
    error ||
    (!isPriceCorrect && settingsState?.privacy !== CatalogPrivacy.Private) ||
    Object.values(errors).length
  );

  const hasAttachedIntegrations = Boolean(initialCatalogData.integrations?.length);

  return (
    <Box display="flex" flexDirection="column" mr="56px">
      <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
        <Typography variant="h2">{title}</Typography>
        <Box display="flex">
          <Box mr="15px">
            <Button onClick={onCancelButtonClick} variant="outlined">
              {t('cancel')}
            </Button>
          </Box>
          <Box>
            <Button
              onClick={onSaveButtonClick}
              variant="contained"
              loading={updateLoading}
              disabled={disabledSaveButton}
            >
              {t('settingsPage.saveButton')}
            </Button>
          </Box>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="space-between" mt="13px">
        <Box width="288px" mr="24px">
          <WrapperTextInput
            shouldFocus
            name="catalogName"
            value={settingsState.catalogName}
            label={t('catalogueCreationPage.firstStep.input')}
            error={!!error}
            helperTextError={error as JSX.Element}
            onChange={onChange}
          />
        </Box>
        {hasAttachedIntegrations && (
          <MappingsButton
            onClick={onMappingsButtonClick}
            testId="mappingsButton"
            disabled={initialCatalogData.isEmpty}
            loading={mappingButtonLoading}
            errors={initialCatalogData.mappingsErrors}
            isEmpty={initialCatalogData.isEmpty}
          />
        )}
      </Box>
    </Box>
  );
}
