import { Box } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';

import Button from 'src/components/Button';
import Icon from 'src/components/Icon/Icon';

export const Container = styled(Box)`
  display: flex;
  align-items: center;
`;

export const Main = styled(Box)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 10px 20px;
  max-height: 296px;
`;

export const CatalogCreationSideContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-height: 288px;
  min-width: 232px;
  margin-left: 148px;
`;

export const StyledIcon = styled(Icon)`
  width: 34px;
  height: 34px;
  margin: 0 15px 0 20px;
`;

export const StyledForwardButton = styled(Button)`
  margin-left: 15px;
`;

export const ImageBackground = styled(Box)`
  position: absolute;
  box-shadow: 0 0 110px 7em #7175e5, 80px 80px 110px 9em #b0b1ee;
  height: 1px;
  width: 20px;
  border-radius: 1%;
  background: #7175e5;
  transform: scale(0.5);
  margin-left: 150px;
`;

export const IntegrationCardsContainer = styled(Box)`
  padding: 10px;
  max-height: 215px;
  max-width: 370px;
  scrollbar-width: thin;

  & .MuiPaper-root:last-child {
    margin-bottom: 5px;
  }
`;
