import { ChangeEvent } from 'react';

import { v4 as uuidv4 } from 'uuid';

import { MediaType, ProductItem, ProductItemValueInput, ProductTypeField } from 'src/utils/gql';
import { getImagePreview } from 'src/utils/imageUtils';

import {
  AVAILABLE_MODELS3D_EXTENSIONS,
  AVAILABLE_AUDIO_FORMATS,
  AVAILABLE_DOCUMENT_FORMATS,
  AVAILABLE_IMAGE_FORMATS,
  AVAILABLE_VIDEO_FORMATS,
  ImagesState,
  UploadingFile,
  Value,
} from '../types';

export const handleUploadImage = (
  event: ChangeEvent<HTMLInputElement>,
  setImagesState: (
    prevState: (prevState: ImagesState) => {
      imagesForPreview: UploadingFile[];
      imagesForUploading: UploadingFile[];
      loadedImagesUrls: string[];
    },
  ) => void,
) => {
  const { files } = event.target;

  if (files) {
    const filesArray = Array.from(files);
    filesArray.map((file) => {
      const id = uuidv4();

      getImagePreview(file, (result: string) => {
        setImagesState((prevState: ImagesState) => ({
          ...prevState,
          imagesForPreview: [...prevState.imagesForPreview, { file, id, name: file.name, imagePreview: result }],
          imagesForUploading: [...prevState.imagesForUploading, { file, id, name: file.name }],
        }));
      });
    });
  }
};

export const handleDeleteImage = (
  imageId: string,
  imagesState: ImagesState,
  setImagesState: (
    prevState: (prevState: ImagesState) => {
      imagesForPreview: UploadingFile[];
      imagesForUploading: UploadingFile[];
      loadedImagesUrls: string[];
    },
  ) => void,
) => {
  const imagesForPreviewArr = [...imagesState.imagesForPreview];
  const imagesForUploadingArr = [...imagesState.imagesForUploading];

  const previewIndex = imagesForPreviewArr.findIndex(({ id }) => id === imageId);
  const uploadingIndex = imagesForUploadingArr.findIndex(({ id }) => id === imageId);

  imagesForPreviewArr.splice(previewIndex, 1);
  imagesForUploadingArr.splice(uploadingIndex, 1);

  setImagesState((prevState: ImagesState) => ({
    ...prevState,
    imagesForPreview: [...imagesForPreviewArr],
    imagesForUploading: [...imagesForUploadingArr],
  }));
};

export const handleDeleteImageFromDatabase = (
  imagesState: ImagesState,
  setImagesState: (
    prevState: (prevState: ImagesState) => {
      imagesForPreview: UploadingFile[];
      imagesForUploading: UploadingFile[];
      loadedImagesUrls: string[];
      imagesUrlsFromDatabase?: string[];
      imageFromDatabaseWillBeDeleted?: boolean;
    },
  ) => void,
  imageUrl?: string,
) => {
  const imagesUrlsFromDatabaseArr = [...(imagesState.imagesUrlsFromDatabase as string[])];
  const previewIndex = imagesUrlsFromDatabaseArr.findIndex((url) => url === imageUrl);
  imagesUrlsFromDatabaseArr.splice(previewIndex, 1);

  setImagesState((prevState: ImagesState) => ({
    ...prevState,
    imagesUrlsFromDatabase: [...imagesUrlsFromDatabaseArr],
    imageFromDatabaseWillBeDeleted: true,
  }));
};

export const prepareData = (data: ProductItem, productTypeFields: ProductTypeField[]) => ({
  ...data,
  values: [
    ...data.values.map(({ value, productTypeField }) => ({
      productTypeFieldId: Number(productTypeField.id),
      value: value.trimEnd(),
      productTypeField: productTypeField.required
        ? { ...productTypeField, error: productTypeField.required && !value }
        : productTypeField,
    })),
    ...productTypeFields.map((field) =>
      field.required
        ? {
            productTypeFieldId: Number(field.id),
            value: field.defaultValue ? field.defaultValue : '',
            productTypeField: { ...field },
            error: !field.defaultValue,
          }
        : { productTypeFieldId: Number(field.id), value: '', productTypeField: { ...field } },
    ),
  ].filter(
    (value, idx, arr) =>
      arr.findIndex(({ productTypeField }) => productTypeField.id === value.productTypeField.id) === idx,
  ),
});

export const prepareDataForUpdateItem = (itemStateValues: Value[], imageField: ProductItemValueInput) => ({
  values: [
    ...itemStateValues
      .filter(({ productTypeFieldId, value }) => value && productTypeFieldId !== imageField.productTypeFieldId)
      .map(({ productTypeFieldId, value }) => ({
        productTypeFieldId,
        value,
      })),
    imageField,
  ],
});

export const mapImagesFromDatabase = (cardImage?: UploadingFile[]): string[] => {
  try {
    return cardImage?.map(({ imagePreview }) => imagePreview) as string[];
  } catch {}
  return [];
};

export const validateVideoLink = (link: string): boolean => {
  const videoRegExp = /^(https?\:\/\/)?(www\.)?(youtube\.com\/|youtu\.be\/|vimeo\.com\/).+$/;

  return videoRegExp.test(link);
};

export const getMediaType = (fileExtensionOrUrl: string): MediaType | undefined => {
  const convertedFileExtensionOrUrl = fileExtensionOrUrl.toLowerCase();
  const url = decodeURIComponent(convertedFileExtensionOrUrl);
  const urlExpansion = url.substring(url.lastIndexOf('.')).split('?')[0];

  for (const { formats, type } of [
    { formats: AVAILABLE_IMAGE_FORMATS, type: MediaType.Image },
    { formats: AVAILABLE_VIDEO_FORMATS, type: MediaType.Video },
    { formats: [...AVAILABLE_DOCUMENT_FORMATS, ...AVAILABLE_AUDIO_FORMATS], type: MediaType.Document },
    { formats: AVAILABLE_MODELS3D_EXTENSIONS, type: MediaType.Model3D },
  ]) {
    if (convertedFileExtensionOrUrl.includes('http')) {
      if (formats.includes(urlExpansion)) {
        return type;
      }
    } else if (formats.includes(convertedFileExtensionOrUrl)) {
      return type;
    }
  }

  if (validateVideoLink(convertedFileExtensionOrUrl)) {
    return MediaType.ExternalVideo;
  }
};

export const getFileExpansion = (file?: string) => '.' + file?.match(/\.([^.]+)$|$/)?.[1]?.toLowerCase();

export const getFileNameFromUrl = (url?: string) => {
  const invalidName = decodeURI(url?.split('/').pop() as string);

  return invalidName.substring(invalidName.indexOf('_') + 1);
};
