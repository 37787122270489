import React from 'react';

import { Box } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';

const MainBox = styled(Box)`
  position: absolute;
  top: -45px;
  left: 80px;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  filter: blur(12px);
`;

const OrangeBox = styled(Box)`
  position: absolute;
  z-index: 1;
  background-color: #df642b;
  box-shadow: 0 0 50px 50px #df642b;
`;

const DarkBlue = styled(Box)`
  position: absolute;
  z-index: 1;
  background-color: #2e38af;
  box-shadow: 0 0 50px 50px #2e38af;
`;

const DarkPurpleBox = styled(Box)`
  position: absolute;
  background-color: #6c5ef8;
  box-shadow: 0 0 50px 50px #6c5ef8;
`;

const PurpleBox = styled(Box)`
  position: absolute;
  background-color: #8b78f9;
  box-shadow: 0 0 50px 50px #8b78f9;
`;

const YellowBox = styled(Box)`
  position: absolute;
  background-color: #e69918;
  box-shadow: 0 0 50px 50px #e69918;
`;

const DarkRedBox = styled(Box)`
  position: absolute;
  z-index: 1;
  background-color: #97094f;
  box-shadow: 0 0 50px 50px #97094f;
`;

const WhiteBox = styled(Box)`
  position: absolute;
  z-index: 1;
  background-color: #f4f4f2;
  box-shadow: 0 0 50px 50px #f4f4f2;
`;

const ResendEmailImageBackground = () => {
  return (
    <MainBox>
      <DarkPurpleBox width="260px" height="90px" top="240px" left="200px" borderRadius="30%" />

      <DarkPurpleBox width="50px" height="80px" top="200px" left="300px" borderRadius="30%" />

      <DarkPurpleBox width="50px" height="80px" top="185px" left="400px" borderRadius="30%" />

      <PurpleBox width="250px" height="170px" top="160px" borderRadius="30%" />

      <PurpleBox width="50px" height="60px" left="50px" top="120px" borderRadius="30%" />

      <PurpleBox width="50px" height="60px" left="150px" top="120px" borderRadius="30%" />

      <PurpleBox width="50px" height="60px" left="-40px" top="220px" borderRadius="30%" />

      <DarkBlue width="10px" height="20px" left="30px" top="290px" />

      <DarkRedBox width="50px" height="110px" left="130px" top="290px" />

      <DarkRedBox width="110px" height="50px" left="110px" top="390px" borderRadius="30%" />

      <OrangeBox width="30px" height="30px" top="185px" left="135px" borderRadius="30%" />

      <WhiteBox width="25px" height="5px" top="145px" left="135px" borderRadius="30%" />

      <OrangeBox
        style={{ boxShadow: '0 0 30px 30px #df642b' }}
        width="10px"
        height="10px"
        top="285px"
        left="235px"
        borderRadius="30%"
      />

      <YellowBox width="50px" height="50px" left="330px" top="290px" sx={{ transform: 'rotate(16deg)' }} />

      <YellowBox width="50px" height="20px" left="300px" top="330px" sx={{ transform: 'rotate(-16deg)' }} />
    </MainBox>
  );
};

export default ResendEmailImageBackground;
