import { createContext } from 'react';

import { ImagesStateType } from './types';

const getDefaultHandler = (handlerName: string) => () => {
  if (process.env.NODE_ENV !== 'production') {
    console.error(`Called ImagesStateContext.${handlerName}. Did you forget to provide a provider?`);
  }
};

export const defaultValue: ImagesStateType = {
  filesUploadState: {},
  imagesState: { imagesForPreview: [], imagesForUploading: [], loadedImagesUrls: [], imagesUrlsFromDatabase: [] },
  onReloadImage: getDefaultHandler('onReloadImage'),
  setImagesState: getDefaultHandler('setImagesState'),
};

export const ImagesStateContext = createContext<ImagesStateType>(defaultValue);

ImagesStateContext.displayName = 'ImagesStateContext';
