import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Box, Table, TableCell, TableRow } from '@material-ui/core';

import BooleanToStringBox from 'src/components/ValuePresenter/BooleanToStringBox';
import ValuePresenter from 'src/components/ValuePresenter/ValuePresenter';
import { capitalize } from 'src/helpers/capitalize';
import { FieldSystemName } from 'src/utils/gql';

import { ProductTypeFieldTableBody, StyledProductTypeFieldName } from '../styled';
import { CustomFieldsProps, ProductTypeSettingsField } from '../types';

import ActionsTableCell from './ActionsTableCell';
import ProductTypeSettingsTableHeader from './ProductTypeSettingsTableHeader';

export const TRANSLATED_SYSTEM_NAMES = {
  [FieldSystemName.Name]: <Trans i18nKey="productType.systemNames.name" key="name" />,
  [FieldSystemName.Price]: <Trans i18nKey="productType.systemNames.price" key="price" />,
  [FieldSystemName.Sku]: <Trans i18nKey="productType.systemNames.sku" key="sku" />,
  [FieldSystemName.Description]: <Trans i18nKey="productType.systemNames.description" key="description" />,
};

interface ProductTypeFieldsTableProps {
  fields: ProductTypeSettingsField[];
  onEditClick: (field: ProductTypeSettingsField, fieldIndex: number) => void;
  onDeleteClick?: (index: number) => void;
  isDeletable?: boolean;
  selectedFieldsForDeletion?: string[];
  setCanBeDeleted?: (value: React.SetStateAction<CustomFieldsProps[]>) => void;
}

const ProductTypeFieldsTable = ({
  fields,
  onEditClick,
  onDeleteClick,
  isDeletable,
  selectedFieldsForDeletion = [],
  setCanBeDeleted,
}: ProductTypeFieldsTableProps) => {
  const { t } = useTranslation();

  const handleEditButtonClick = (field: ProductTypeSettingsField, idx: number) => {
    return !selectedFieldsForDeletion.includes(String(field.id)) ? () => onEditClick(field, idx) : undefined;
  };

  return (
    <Table data-testid="productTypeFieldsTable">
      <ProductTypeSettingsTableHeader />
      <ProductTypeFieldTableBody>
        {fields.map((field, idx) => (
          <TableRow
            key={field.name}
            className={selectedFieldsForDeletion.includes(String(field.id)) ? 'selectedForDeletionRow' : ''}
          >
            <TableCell data-testid={`fieldName${field.name}`}>
              <StyledProductTypeFieldName>
                {field.systemName ? TRANSLATED_SYSTEM_NAMES[field.systemName as FieldSystemName] : field.name}
              </StyledProductTypeFieldName>
            </TableCell>
            <TableCell data-testid={`fieldType${field.name}${field.type}`}>
              {field.type !== 'boolean'
                ? capitalize(t(`fieldSettings.fieldTypeOptions.${field.type}`))
                : t(`fieldSettings.fieldTypeOptions.${field.type}`)}
            </TableCell>
            <TableCell data-testid={`defaultFieldValue${field.name}${field.defaultValue}`}>
              <Box display="flex" alignItems="center" width="100%">
                {field.defaultValue && (
                  <ValuePresenter
                    type={field.type}
                    value={String(field.defaultValue)}
                    isSelectedForDeletion={selectedFieldsForDeletion.includes(String(field.id))}
                  />
                )}
              </Box>
            </TableCell>
            <TableCell data-testid={`fieldRequired${field.name}${field.required}`}>
              <BooleanToStringBox fieldValue={String(field.required)} />
            </TableCell>
            <TableCell data-testid={`showPreview${field.name}${field.showInPreview}`}>
              <BooleanToStringBox fieldValue={String(field.showInPreview)} />
            </TableCell>
            <ActionsTableCell
              onEditClick={handleEditButtonClick(field, idx)}
              isEditable={
                ![FieldSystemName.Name, FieldSystemName.Price].includes(field.name.toLowerCase() as FieldSystemName)
              }
              {...(isDeletable && onDeleteClick && { onDeleteClick: () => onDeleteClick(idx) })}
              isSelectedForDelete={selectedFieldsForDeletion.includes(String(field.id))}
              fieldNameForTest={field.name}
              fieldId={field.id}
              setCanBeDeleted={setCanBeDeleted}
              index={idx}
            />
          </TableRow>
        ))}
      </ProductTypeFieldTableBody>
    </Table>
  );
};

export default ProductTypeFieldsTable;
