import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { DialogProps, Box } from '@material-ui/core';
import MuiDialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import Button from 'src/components/Button';
import Close from 'src/components/Images/Close.svg';
import { CATALOGS_PATH, CATALOG_SETTINGS, FIRST_DASHBOARD_PATHNAME } from 'src/constants/routeSources';
import { Integration } from 'src/utils/gql';
import { LinkToSettings } from 'src/views/Catalogs/styled';
import { calculateEnding } from 'src/views/Catalogs/utils';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiDialog-scrollPaper': {
      backgroundColor: 'rgba(16, 16, 45, 0.8)',
    },
    '& .MuiPaper-root, & .MuiDialogContent-root': {
      overflow: 'hidden',
      width: '496px',
      padding: '40px 26px',
      maxWidth: '496px',
    },
  },
  closeButton: {
    position: 'absolute',
    zIndex: 2,
    top: '15px',
    right: '15px',
    width: '34px',
    height: '34px',
    padding: '7px',
    borderRadius: '50%',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '0 0 15px #F16152',
    },
    '&:active': {
      boxShadow: 'none',
      outline: 'none',
    },
  },
  closeImg: {
    position: 'absolute',
    margin: 'auto',
    left: '0',
    top: '0',
    bottom: '0',
    right: '0',
  },
  popupTitle: {
    textAlign: 'center',
    fontSize: '24px',
    lineHeight: '24px',
    fontWeight: 700,
    marginBottom: '55px',
    marginTop: '20px',
  },
  popupDescription: {
    textAlign: 'center',
    marginBottom: '40px',
    fontSize: '18px',
    lineHeight: '21px',
    fontWeight: 'normal',
  },
  btnsContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    marginBottom: '20px',
  },
}));

interface ExportToIntegrationsPopupProps extends DialogProps {
  handleClose: () => void;
  handleExport: () => void;
  loadingOnMainButton?: boolean;
  selectedCatalogIntegrationsNotToSync?: Integration[];
  selectedCatalogId?: string;
}

const ExportToIntegrationsPopup = ({
  handleClose,
  handleExport,
  loadingOnMainButton,
  selectedCatalogIntegrationsNotToSync = [],
  selectedCatalogId,
  ...props
}: ExportToIntegrationsPopupProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const ending = calculateEnding(selectedCatalogIntegrationsNotToSync);

  return (
    <MuiDialog {...props} className={classes.root}>
      <div className={classes.closeButton} onClick={handleClose} role="button" tabIndex={0}>
        <img className={classes.closeImg} src={Close} alt="close" />
      </div>
      <Typography className={classes.popupTitle} color="text.primary" variant="h1">
        {t('cataloguesPage.syncPopup.title')}
      </Typography>

      <Typography className={classes.popupDescription} color="text.secondary" variant="h4">
        {!!selectedCatalogIntegrationsNotToSync?.length ? (
          <Trans i18nKey="cataloguesPage.syncPopup.errorDescription">
            Cannot export to the
            <strong>
              {{
                integrations: selectedCatalogIntegrationsNotToSync
                  ?.map(({ settings }) => settings.integrationName)
                  .join(', '),
              }}
            </strong>
            integration{{ ending }} because of errors. Visit
            <LinkToSettings
              to={`/${FIRST_DASHBOARD_PATHNAME}/${CATALOGS_PATH}/${selectedCatalogId}/${CATALOG_SETTINGS}`}
            >
              catalog settings
            </LinkToSettings>
            to see the details. You can still export to other attached integrations.
          </Trans>
        ) : (
          t('productItemsPage.popup.description')
        )}
      </Typography>

      <Box className={classes.btnsContainer}>
        <Box mr="15px">
          <Button variant="outlined" onClick={handleClose} data-testid="backButtonPopup">
            {t('productItemsPage.popup.backBtn')}
          </Button>
        </Box>
        <Button
          onClick={handleExport}
          loading={loadingOnMainButton}
          variant="contained"
          data-testid="exportButtonPopup"
        >
          {t('productItemsPage.popup.exportBtn')}
        </Button>
      </Box>
    </MuiDialog>
  );
};

export default ExportToIntegrationsPopup;
