import React from 'react';
import { RouteConfig } from 'react-router-config';
import { RouteProps } from 'react-router-dom';

import RenderRoutes from 'src/utils/RenderRoutes';

import { SharedCatalogsProvider } from '../../providers/SharedCataloguesProvider';

interface SharedCatalogsLayoutProps extends RouteProps {
  route?: RouteConfig;
}

const SharedCatalogsLayout = ({ route }: SharedCatalogsLayoutProps) => {
  return <SharedCatalogsProvider>{route && <RenderRoutes routes={route.routes} />}</SharedCatalogsProvider>;
};

export default SharedCatalogsLayout;
