import React, { PropsWithChildren, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Popup from '../../components/Popup';
import { LimitType } from '../../utils/gql';

import LimitReachedContext from './context';
import { ShowPopupInterface } from './types';

const useStyles = makeStyles(() => ({
  descriptionContainer: {
    maxWidth: '444px',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const LimitReachedProvider = ({ children }: PropsWithChildren<Record<string, unknown>>) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { descriptionContainer } = useStyles();

  const [open, setOpen] = useState<boolean>(false);
  const [limitReachedType, setLimitReachedType] = useState<LimitType>(LimitType.Catalogs);

  const showPopup = ({ limitReachedType }: ShowPopupInterface) => {
    setLimitReachedType(limitReachedType);
    setOpen(true);
  };

  return (
    <>
      <LimitReachedContext.Provider value={showPopup}>{children}</LimitReachedContext.Provider>

      <Popup
        open={open}
        mainTitle={t('limitReached.titleText')}
        mainButtonText={t('limitReached.mainButtonText')}
        secondaryButtonText={t('limitReached.secondaryButtonText')}
        onClose={() => setOpen(false)}
        onSecondaryButtonClick={() => setOpen(false)}
        onMainButtonClick={() => history.push('/upgrade-pricing-plan')}
      >
        <Box className={descriptionContainer}>
          <Typography color="text.secondary" variant="body1">
            {t(`limitReached.${limitReachedType}.firstPart`)}
          </Typography>

          <Typography color="text.secondary" mt="15px" variant="body1">
            {t(`limitReached.${limitReachedType}.secondPart`)}
          </Typography>
        </Box>
      </Popup>
    </>
  );
};

export default LimitReachedProvider;
