import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@material-ui/core';

import Popup from 'src/components/Popup';

interface SaveAndGoMappingsPopupProps {
  open: boolean;
  onClose: () => void;
  onMainButtonClick: () => void;
  onSecondaryButtonClick: () => void;
  loadingOnMainButton?: boolean;
}

const SaveAndGoMappingsPopup = ({
  open,
  onClose,
  onMainButtonClick,
  onSecondaryButtonClick,
  loadingOnMainButton,
}: SaveAndGoMappingsPopupProps) => {
  const { t } = useTranslation();

  return (
    <Popup
      open={open}
      mainTitle={t('saveAndGoMappingsPopup.mainTitle')}
      onClose={onClose}
      mainButtonText={t('saveAndGoMappingsPopup.mainButton')}
      secondaryButtonText={t('saveAndGoMappingsPopup.secondaryButton')}
      loadingOnMainButton={loadingOnMainButton}
      onMainButtonClick={onMainButtonClick}
      onSecondaryButtonClick={onSecondaryButtonClick}
      testMainButton="deleteButtonPopup"
      testSecondaryButton="backButtonPopup"
    >
      <Box maxWidth={400} margin="0 auto">
        <Typography color="text.secondary" variant="body1">
          {t('saveAndGoMappingsPopup.description')}
        </Typography>
      </Box>
    </Popup>
  );
};

export default SaveAndGoMappingsPopup;
