import React, { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Prompt, useHistory, useLocation } from 'react-router';

import { Box, Typography } from '@material-ui/core';
import { Location } from 'history';

import Popup from 'src/components/Popup';
import { MediaStateContext } from 'src/providers/MediaProvider/context';
import useOnboarding from 'src/providers/OnboardingProvider/hooks/useOnboarding';

const translation = {
  message: <Trans i18nKey="userConfirmation.message">Do you want to discard the changes?</Trans>,
  mainButton: <Trans i18nKey="userConfirmation.mainButton">Yes</Trans>,
  secondaryButton: <Trans i18nKey="userConfirmation.secondaryButton">No</Trans>,
};

interface DiscardChangesPopupProps {
  isOpen: boolean;
  isManual?: boolean;
  onMainButtonClick?: () => void;
  onSecondaryButtonClick?: () => void;
  setIsEnterActionBlocked?: (isEnterActionAvailable: boolean) => void;
}

const DiscardChangesPopup = ({
  isOpen,
  isManual,
  onMainButtonClick,
  onSecondaryButtonClick,
  setIsEnterActionBlocked,
}: DiscardChangesPopupProps) => {
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const { isDiscardChangesPopupBlocked } = useOnboarding();

  const [lastLocation, setLastLocation] = useState<Location>(location);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [shouldUnload, setShouldUnload] = useState<boolean>(false);
  const [isDiscardConfirmed, setIsDiscardConfirmed] = useState<boolean>(false);
  const { setMediaState } = useContext(MediaStateContext);

  const { secondaryButton, mainButton, message } = translation;

  const closeModal = () => {
    setIsModalOpen(false);
    setIsEnterActionBlocked?.(false);
    setShouldUnload(false);
    onSecondaryButtonClick?.();
  };

  const openModal = () => {
    setIsModalOpen(true);
    setIsEnterActionBlocked?.(true);
  };

  const showModal = (nextLocation: Location) => {
    openModal();
    setLastLocation(nextLocation);
  };

  const handleBlockRoute = (nextLocation: Location) => {
    if (!isDiscardConfirmed && isOpen && !isDiscardChangesPopupBlocked) {
      showModal(nextLocation);
      return false;
    }

    return true;
  };

  const handleConfirmDiscard = () => {
    closeModal();
    setIsDiscardConfirmed(true);
    onMainButtonClick?.();
    setMediaState([]);
  };

  useEffect(() => {
    if (isDiscardConfirmed && lastLocation) {
      setShouldUnload(true);
      history.push(lastLocation.pathname);
    }
  }, [isDiscardConfirmed, lastLocation, history]);

  useEffect(() => {
    const unload = (event: BeforeUnloadEvent) => {
      if (isOpen && shouldUnload) {
        event.returnValue = message;
      }
      if (shouldUnload) {
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', unload);

    return () => window.removeEventListener('beforeunload', unload);
  }, [isOpen, message, shouldUnload]);

  return (
    <>
      {isOpen && <Prompt when message={handleBlockRoute} />}
      {(isModalOpen || isManual) && (
        <Popup
          mainButtonsMarginAbsence
          open={true}
          mainTitle={
            <Box>
              <Typography variant="h2">{t('discardChangesPopup.dialogContentTextTop')}</Typography>
              <Typography variant="h2">{t('discardChangesPopup.dialogContentText')}</Typography>
            </Box>
          }
          mainButtonText={mainButton}
          onMainButtonClick={handleConfirmDiscard}
          onSecondaryButtonClick={closeModal}
          secondaryButtonText={secondaryButton}
          onClose={closeModal}
          maxWidth="sm"
        />
      )}
    </>
  );
};

export default DiscardChangesPopup;
