import { Box, IconButton, styled, TableBody, TableCell, TableContainer, TableHead } from '@mui/material';

export const StyledTableContainer = styled(TableContainer)`
  padding-right: 10px;
  overflow-x: visible;
`;

export const StyledTableHead = styled(TableHead)`
  .MuiTableCell-root {
    font-weight: 700;
    padding: 16px 10px;
    border-bottom: 1px solid;
    border-color: ${({ theme: { palette } }) => palette.background.default};
    color: ${({ theme: { palette } }) => palette.text.primary};
    background: ${({ theme: { palette } }) => palette.common.white};

    :nth-of-type(1) {
      width: 0%;
    }
    :nth-of-type(2) {
      width: 13%;
    }
    :nth-of-type(3) {
      width: 7%;
    }
    :nth-of-type(4) {
      width: 65%;
    }
    :nth-of-type(5) {
      width: 10%;
    }
    :nth-of-type(6) {
      width: 5%;
    }
  }
`;

export const StyledTableBody = styled(TableBody)`
  .MuiTableRow-root {
    :hover {
      background: ${({ theme: { palette } }) => palette.background.default};
    }
  }

  .MuiTableCell-root {
    border: none;
    font-size: 15px;
    padding: 25px 10px;
    color: ${({ theme: { palette } }) => palette.text.secondary};

    :first-of-type {
      padding: 16px 0 16px 10px;
    }
  }
`;

export const StyledIconButton = styled(IconButton)`
  padding: 0;
  color: ${({ theme: { palette } }) => palette.text.disabled};

  :hover {
    color: ${({ theme: { palette } }) => palette.text.primary};
  }

  svg {
    width: 8px;
    height: 8px;
  }
`;

export const ActionIcon = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: 30,
  width: 30,
  borderRadius: '50%',

  '& .MuiSvgIcon-root': {
    color: theme.palette.common.white,
    height: 20,
    width: 20,
  },
}));

export const StyledDescriptionTableCell = styled(TableCell)`
  word-break: break-word;
`;
