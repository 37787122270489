/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy, Suspense } from 'react';
import { RouteConfig, RouteConfigComponentProps } from 'react-router-config';
import { Redirect } from 'react-router-dom';

import { LinearProgress } from '@material-ui/core';

import PrivateRoute from './components/PrivateRoute';
import {
  AUDIT_LOG,
  CATALOG_SETTINGS,
  CATALOGS_PATH,
  CREATION,
  EDITING,
  ERROR_PATHS,
  FIRST_DASHBOARD_PATHNAME,
  IMPORT_FROM_CSV,
  PRODUCT_ITEMS_PATH,
  PRODUCT_TYPES_PATH,
  SHARED_CATALOGS,
  USER_SETTINGS,
  MAPPINGS,
} from './constants/routeSources';
import AuthLayout from './layouts/Auth';
import { DashboardProvider } from './layouts/Dashboard';
import ErrorLayout from './layouts/Error';
import SharedCatalogsLayout from './layouts/SharedCatalogs';
import AppInstalling from './views/AppInstalling';
import FacebookReAuth from './views/AppInstalling/FacebookReAuth';
import PaymentPlan from './views/PaymentPlan';
import UpgradePricingPlans from './views/UserSettings/UpgradePricingPlans';
import VerifyMailToken from './views/VerifyMailToken';

export default [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to={`/${FIRST_DASHBOARD_PATHNAME}/${CATALOGS_PATH}`} />,
  },
  {
    path: '/login',
    exact: true,
    component: () => <Redirect to="/auth/login" />,
  },
  {
    path: '/verify',
    exact: true,
    component: VerifyMailToken,
  },
  {
    path: '/connect/shopify/install/auth/callback',
    exact: true,
    component: () => (
      <Suspense fallback={<LinearProgress />}>
        <AppInstalling />
      </Suspense>
    ),
  },
  {
    path: '/ebay/auth/callback',
    exact: true,
    component: () => (
      <Suspense fallback={<LinearProgress />}>
        <AppInstalling />
      </Suspense>
    ),
  },
  {
    path: '/facebook/auth/callback',
    exact: true,
    component: () => (
      <Suspense fallback={<LinearProgress />}>
        <AppInstalling />
      </Suspense>
    ),
  },
  {
    path: '/facebook/re-auth/callback',
    exact: true,
    component: () => (
      <Suspense fallback={<LinearProgress />}>
        <FacebookReAuth />
      </Suspense>
    ),
  },
  {
    path: '/upgrade-pricing-plan',
    exact: true,
    component: () => (
      <Suspense fallback={<LinearProgress />}>
        <UpgradePricingPlans />
      </Suspense>
    ),
  },
  {
    path: '/connect/shopify',
    component: AuthLayout,
    routes: [
      {
        path: '/connect/shopify/auth/callback',
        exact: true,
        component: lazy(
          () =>
            import(
              /* webpackPrefetch: true */
              /* webpackMode: "eager" */
              'src/views/ShopifyIntegration'
            ),
        ),
      },
      {
        path: '/connect/shopify/check-email',
        exact: true,
        component: lazy(
          () =>
            import(
              /* webpackPrefetch: true */
              /* webpackMode: "eager" */
              'src/views/CheckEmail'
            ),
        ),
      },
      {
        path: '/connect/shopify/check-email/resend',
        exact: true,
        component: lazy(
          () =>
            import(
              /* webpackPrefetch: true */
              /* webpackMode: "eager" */
              'src/views/CheckEmail/ResendScreen'
            ),
        ),
      },
    ],
  },
  {
    path: '/auth',
    component: AuthLayout,
    routes: [
      {
        path: '/auth/login',
        exact: true,
        component: lazy(
          () =>
            import(
              /* webpackPrefetch: true */
              /* webpackMode: "eager" */
              'src/views/Login'
            ),
        ),
      },
      {
        path: '/auth/register',
        exact: true,
        component: lazy(
          () =>
            import(
              /* webpackPrefetch: true */
              /* webpackMode: "eager" */
              'src/views/Register'
            ),
        ),
      },
      {
        path: '/auth/forgot-password',
        exact: true,
        component: lazy(
          () =>
            import(
              /* webpackPrefetch: true */
              /* webpackMode: "eager" */
              'src/views/ForgotPassword'
            ),
        ),
      },
      {
        path: '/auth/check-email',
        exact: true,
        component: lazy(
          () =>
            import(
              /* webpackPrefetch: true */
              /* webpackMode: "eager" */
              'src/views/CheckEmail'
            ),
        ),
      },
      {
        path: '/auth/check-email/resend',
        exact: true,
        component: lazy(
          () =>
            import(
              /* webpackPrefetch: true */
              /* webpackMode: "eager" */
              'src/views/CheckEmail/ResendScreen'
            ),
        ),
      },
      {
        path: '/auth/reset-password',
        exact: true,
        component: lazy(
          () =>
            import(
              /* webpackPrefetch: true */
              /* webpackMode: "eager" */
              'src/views/SetNewPassword'
            ),
        ),
      },
      {
        path: '/auth/payment-plan/:plan',
        exact: true,
        component: () => (
          <Suspense fallback={<LinearProgress />}>
            <PaymentPlan />
          </Suspense>
        ),
      },
      {
        path: '/auth/payment-plan/:plan/upgrade',
        exact: true,
        component: () => (
          <Suspense fallback={<LinearProgress />}>
            <PaymentPlan />
          </Suspense>
        ),
      },
      {
        component: () => <Redirect to={ERROR_PATHS.ERROR_404} />,
      },
    ],
  },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(
          () =>
            import(
              /* webpackPrefetch: true */
              /* webpackMode: "eager" */
              'src/views/Errors/Error401'
            ),
        ),
      },
      {
        path: ERROR_PATHS.ERROR_404,
        exact: true,
        component: lazy(
          () =>
            import(
              /* webpackPrefetch: true */
              /* webpackMode: "eager" */
              'src/views/Errors/Error404'
            ),
        ),
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(
          () =>
            import(
              /* webpackPrefetch: true */
              /* webpackMode: "eager" */
              'src/views/Errors/Error500'
            ),
        ),
      },
      {
        component: () => <Redirect to={ERROR_PATHS.ERROR_404} />,
      },
    ],
  },
  {
    route: '*',
    component: (props: RouteConfigComponentProps) => <PrivateRoute render={() => <DashboardProvider {...props} />} />,
    routes: [
      {
        path: `/${FIRST_DASHBOARD_PATHNAME}`,
        exact: true,
        component: () => <Redirect to={`/${FIRST_DASHBOARD_PATHNAME}/${CATALOGS_PATH}`} />,
      },
      {
        path: `/${FIRST_DASHBOARD_PATHNAME}/${CATALOGS_PATH}`,
        exact: true,
        component: lazy(
          () =>
            import(
              /* webpackPrefetch: true */
              /* webpackMode: "eager" */
              'src/views/Catalogs'
            ),
        ),
      },
      {
        path: `/${FIRST_DASHBOARD_PATHNAME}/${CATALOGS_PATH}/:id/${PRODUCT_TYPES_PATH}`,
        exact: true,
        component: lazy(
          () =>
            import(
              /* webpackPrefetch: true */
              /* webpackMode: "eager" */
              'src/views/Catalogs/ProductTypes'
            ),
        ),
      },
      {
        path: `/${FIRST_DASHBOARD_PATHNAME}/${CATALOGS_PATH}/:id/${CREATION}`,
        exact: true,
        component: lazy(
          () =>
            import(
              /* webpackPrefetch: true */
              /* webpackMode: "eager" */
              'src/views/Catalogs/ProductTypes/ProductTypeSettings/ProductTypeSettingsCreation'
            ),
        ),
      },
      {
        path: `/${FIRST_DASHBOARD_PATHNAME}/${CATALOGS_PATH}/:id/${PRODUCT_TYPES_PATH}/:productTypeId/${EDITING}`,
        exact: true,
        component: lazy(
          () =>
            import(
              /* webpackPrefetch: true */
              /* webpackMode: "eager" */
              'src/views/Catalogs/ProductTypes/ProductTypeSettings/ProductTypeSettingsEditing'
            ),
        ),
      },
      {
        path: `/${FIRST_DASHBOARD_PATHNAME}/${CATALOGS_PATH}/:id/${PRODUCT_TYPES_PATH}/:productTypeId/${MAPPINGS}`,
        exact: true,
        component: lazy(
          () =>
            import(
              /* webpackPrefetch: true */
              /* webpackMode: "eager" */
              'src/views/Catalogs/ProductTypes/ProductTypeSettings/ProductTypeFieldsTables/ProductTypeSettingsMappings'
            ),
        ),
      },
      {
        path: `/${FIRST_DASHBOARD_PATHNAME}/${CATALOGS_PATH}/:id/${PRODUCT_TYPES_PATH}/:productTypeId/${PRODUCT_ITEMS_PATH}`,
        exact: true,
        component: lazy(
          () =>
            import(
              /* webpackPrefetch: true */
              /* webpackMode: "eager" */
              'src/views/Catalogs/ProductItems'
            ),
        ),
      },
      {
        path: `/${FIRST_DASHBOARD_PATHNAME}/${CATALOGS_PATH}/:catalogId/${PRODUCT_TYPES_PATH}/:productTypeId/${CREATION}`,
        exact: true,
        component: lazy(
          () =>
            import(
              /* webpackPrefetch: true */
              /* webpackMode: "eager" */
              'src/views/Catalogs/ProductItems/ItemCreation'
            ),
        ),
      },
      {
        path: `/${FIRST_DASHBOARD_PATHNAME}/${CATALOGS_PATH}/:catalogId/${PRODUCT_TYPES_PATH}/:productTypeId/${PRODUCT_ITEMS_PATH}/:productItemId/${EDITING}`,
        exact: true,
        component: lazy(
          () =>
            import(
              /* webpackPrefetch: true */
              /* webpackMode: "eager" */
              'src/views/Catalogs/ProductItems/ItemEditing'
            ),
        ),
      },
      {
        path: `/${FIRST_DASHBOARD_PATHNAME}/${CREATION}`,
        exact: true,
        component: lazy(
          () =>
            import(
              /* webpackPrefetch: true */
              /* webpackMode: "eager" */
              'src/views/Catalogs/CatalogCreation'
            ),
        ),
      },
      {
        path: `/${FIRST_DASHBOARD_PATHNAME}/${CATALOGS_PATH}/:id/${IMPORT_FROM_CSV}`,
        exact: true,
        component: lazy(
          () =>
            import(
              /* webpackPrefetch: true */
              /* webpackMode: "eager" */
              'src/views/Catalogs/ImportFromCSV'
            ),
        ),
      },
      {
        path: `/${FIRST_DASHBOARD_PATHNAME}/${CATALOGS_PATH}/:id/${CATALOG_SETTINGS}`,
        exact: true,
        component: lazy(
          () =>
            import(
              /* webpackPrefetch: true */
              /* webpackMode: "eager" */
              'src/views/Catalogs/CatalogSettings'
            ),
        ),
      },
      {
        path: `/${FIRST_DASHBOARD_PATHNAME}/${SHARED_CATALOGS}`,
        exact: true,
        component: lazy(
          () =>
            import(
              /* webpackPrefetch: true */
              /* webpackMode: "eager" */
              'src/views/SharedCatalogs'
            ),
        ),
      },
      {
        path: `/${FIRST_DASHBOARD_PATHNAME}/${SHARED_CATALOGS}/:catalogId`,
        component: SharedCatalogsLayout,
        routes: [
          {
            path: `/${FIRST_DASHBOARD_PATHNAME}/${SHARED_CATALOGS}/:catalogId`,
            exact: true,
            component: lazy(
              () =>
                import(
                  /* webpackPrefetch: true */
                  /* webpackMode: "eager" */
                  'src/views/SharedCatalogPreview/ProductTypesPreview'
                ),
            ),
          },
          {
            path: `/${FIRST_DASHBOARD_PATHNAME}/${SHARED_CATALOGS}/:catalogId/:productTypeId`,
            exact: true,
            component: lazy(
              () =>
                import(
                  /* webpackPrefetch: true */
                  /* webpackMode: "eager" */
                  'src/views/SharedCatalogPreview/ProductItemsListPreview'
                ),
            ),
          },
          {
            path: `/${FIRST_DASHBOARD_PATHNAME}/${SHARED_CATALOGS}/:catalogId/:productTypeId/:productItemId`,
            exact: true,
            component: lazy(
              () =>
                import(
                  /* webpackPrefetch: true */
                  /* webpackMode: "eager" */
                  'src/views/SharedCatalogPreview/ProductItemPreview'
                ),
            ),
          },
        ],
      },
      {
        path: `/${FIRST_DASHBOARD_PATHNAME}/${USER_SETTINGS}`,
        exact: true,
        component: lazy(
          () =>
            import(
              /* webpackPrefetch: true */
              /* webpackMode: "eager" */
              'src/views/UserSettings'
            ),
        ),
      },
      {
        path: `/${FIRST_DASHBOARD_PATHNAME}/${AUDIT_LOG}`,
        exact: true,
        component: lazy(
          () =>
            import(
              /* webpackPrefetch: true */
              /* webpackMode: "eager" */
              'src/views/AuditLog'
            ),
        ),
      },
      {
        component: () => <Redirect to={ERROR_PATHS.ERROR_404} />,
      },
    ],
  },
] as RouteConfig[];
