import { Box, Link } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';

export const PageContainer = styled(Box)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 10px 3px;

  @media (min-width: 1440px) {
    flex-direction: row;
  }
`;

export const PaymentPlanWrapper = styled(Box)(() => ({
  width: '100%',
  maxWidth: '580px',
  display: 'flex',
  flexDirection: 'column',

  ['@media (min-width:1440px)']: {
    marginRight: 24,
  },
}));

export const BillingInfoContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: 580,
  padding: 40,
  boxShadow: `0 2px 10px rgba(46, 96, 170, 0.25)`,
  borderRadius: 20,
  marginBottom: 20,

  ['@media (min-width:1440px)']: {
    marginBottom: 0,
  },
}));

export const ContinueLink = styled(Link)`
  :hover {
    cursor: pointer;
  }
`;

export const PageCloseButton = styled(Box)`
  position: absolute;
  top: 15px;
  right: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  border-radius: 100%;
  background: ${({ theme: { palette } }) => palette.background.default};

  &:hover {
    cursor: pointer;
  }

  svg {
    width: 18px;
    height: 18px;
  }
`;
