import { Box, IconButton, Typography } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';

export const ProductTypesWrapper = styled(Box)`
  scrollbar-width: thin;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
`;

export const NoProductTypes = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 106px;
`;

export const CardIcon = styled(IconButton)`
  color: ${({ theme: { palette } }) => palette.text.secondary};
`;

export const ClippedTypography = styled(Typography)`
  margin-right: 24px;
  max-width: 466px;
`;

export const StyledPopupContentText = styled('span')`
  word-break: break-all;
  white-space: normal;
  font-weight: 500;
  color: ${({ theme: { palette } }) => palette.text.secondary};
`;

export const ProductTypesList = styled(Box)`
  margin: 0 56px 0 24px;
`;
