import { TextField, Typography, Box, IconButton } from '@material-ui/core';
import { experimentalStyled as styled, makeStyles } from '@material-ui/core/styles';

import RadioButton from 'src/components/RadioButton';

export const StyledRadioButton = styled(RadioButton)`
  margin-top: 1px;
  align-self: flex-start;
`;

export const StyledTextField = styled(TextField)`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const StyledCatalogsTypeLink = styled(Typography)`
  margin-right: 30px;
  &:hover {
    cursor: pointer;
  }
`;

export const PreviewHeaderCore = styled(Box)`
  display: flex;
  margin-top: 15px;
  align-items: center;
  overflow: hidden;
  padding: 16px;
  height: 42px;
  box-sizing: content-box;
`;

export const PreviewCatalogStatusContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin-left: 10px;
  color: ${({ theme: { palette } }) => palette.common.white};
  padding: 0 15px;
  height: 34px;
`;

export const PreviewClosePageButton = styled(Box)`
  width: 34px;
  height: 34px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme: { palette } }) => palette.background.default};

  &:hover {
    cursor: pointer;
  }

  svg {
    width: 18px;
    height: 18px;
  }
`;

export const PreviewArrowIconButton = styled(IconButton)`
  padding: 5px 0;
  margin-right: 15px;
`;

export const PreviewPopupIconButton = styled(IconButton)`
  padding: 5px 0;
  margin-left: 15px;
`;

export const SearchFieldWrapper = styled(Box)`
  max-width: 410px;
  flex-grow: 1;
  & .MuiFormControl-root {
    width: 100%;

    & .MuiInputBase-root {
      width: 100%;
    }
  }
`;

export const StyledUpgradeLink = styled('a')`
  color: ${({ theme: { palette } }) => palette.primary.light};
  border-bottom: 1px solid;
  text-decoration: none;
  display: inline-block;
  line-height: 10px;
  &:hover {
    cursor: pointer;
  }
`;

export const StyledIntegrationText = styled(Typography)`
  opacity: 0.3;
  color: ${({ theme: { palette } }) => palette.text.secondary};
`;

export const StyledHeaderWithoutTitle = styled(Box)`
  display: flex;
  justify-content: space-between;
  flex: 1;
`;

export const StyledShowHideFilterWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledHeaderFixed = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const useStickyHeaderStyles = makeStyles(() => ({
  stickyHeader: {
    marginLeft: '-16px',
    padding: '20px 33px 20px 19px',
  },
}));

export const ItemDetailsHeaderWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: sticky;
  z-index: 25;
  margin-left: -16px;
  padding: 0 56px 0 24px;
  background: ${({ theme: { palette } }) => palette.common.white};
`;

export const ItemDetailsMainHeaderWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 82px;
`;

export const FlexRowSpaceBetweenCenterWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const StyledDraftTypography = styled(Typography)`
  font-weight: normal;
  font-size: 15px;
`;
