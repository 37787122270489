import React from 'react';

import { Box } from '@material-ui/core';

import UserAccountStatusCard from 'src/components/Card/UserAccountStatusCard';
import UserDataCard from 'src/components/Card/UserDataCard';
import UserPlanCard from 'src/components/Card/UserPlanCard';
import { PlanSubscriptionStatsResponse } from 'src/utils/gql';

import { UserSettingsPageTab } from './types';

export interface UserAccountProps {
  setActiveTab: (activeTab: UserSettingsPageTab) => void;
  planData?: PlanSubscriptionStatsResponse;
}

const UserAccount = ({ planData, setActiveTab }: UserAccountProps) => {
  return (
    <>
      <Box display="flex" mb="16px">
        <Box width="100%" mr="24px" pb="24px">
          <UserDataCard />
        </Box>

        <Box display="flex" flexDirection="column" width="100%">
          {planData && (
            <>
              <UserPlanCard setActiveTab={setActiveTab} planSubscription={planData.planSubscription} />

              <UserAccountStatusCard planSubscriptionStats={planData} />
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default UserAccount;
