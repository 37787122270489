import { Box, FormControlLabel, IconButton } from '@material-ui/core';
import { styled } from '@mui/material';

export const SideBarContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  min-width: 72px;
  height: 100%;
  color: ${({ theme: { palette } }) => palette.primary.main};
`;

export const OpenCloseIcon = styled(IconButton)(() => ({
  position: 'absolute',
  top: '8px',
  right: '11px',
  width: 'fit-cntent',
  zIndex: 1,

  '&.expanded': {
    transform: 'rotate(180deg)',
    right: '22px',
  },
}));

export const FiltersWrapper = styled(Box)`
  margin: 40px 0 25px 0;
  overflow-y: auto;
  padding-right: 5px;
  max-height: calc(100vh - 64px - 25px - 28px - 40px - 47px - 66px);
  scrollbar-width: thin;
`;

export const FilterButton = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: '10px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '66px',
  width: '320px',
  borderRadius: '20px',
  boxShadow: '0 2px 10px rgba(46, 96, 170, 0.25)',
  zIndex: 2,
  background: theme.palette.background.default,
  color: theme.palette.text.disabled,

  '& .MuiButtonBase-root': {
    position: 'absolute',
    right: '15px',
    color: theme.palette.text.disabled,

    '&:hover': {
      svg: {
        color: theme.palette.text.secondary,
      },
    },
  },
}));

export const CatalogsSidebarWrapper = styled(Box)`
  margin-right: 2px;
  scrollbar-width: thin;
  width: 344px;
`;

export const CatalogListContainer = styled(Box)(() => ({
  overflowY: 'auto',
  overflowX: 'hidden',
}));

export const CatalogsSidebarList = styled(Box)`
  margin: 0 20px 0 13px;
`;

export const LoaderWrapper = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ItemsSideBarContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  color: ${({ theme: { palette } }) => palette.primary.main};
`;

export const ImportFromCsvSidebarHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 7px;
  margin-bottom: 35px;

  & .MuiTooltip-tooltip {
    max-width: 230px;
  }
`;

export const DrawerContentContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  overflowX: 'hidden',
  overflowY: 'hidden',
  color: theme.palette.primary.main,
  scrollbarWidth: 'thin',

  '&.expanded': {
    overflowY: 'auto',
  },
}));

export const StyledBox = styled(Box)`
  order: 2;
`;

export const StyledFormBox = styled(Box)`
  order: 3;
  padding: 0 24px;
  width: 925px;
  margin: 0 auto;
`;

export const StyledBreadCrumbsBox = styled(Box)`
  order: 1;
  padding: 0 24px;
  margin-bottom: 18px;
`;

export const ProductTypeSelectWrapper = styled(Box)`
  padding-bottom: 20px;
  border-bottom: ${({ theme: { palette } }) => `1px solid ${palette.background.default}`};
`;

export const HeightSeparatorBlock = styled(Box)`
  height: 70px;
  visibility: hidden;
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  text-transform: capitalize;
`;

export const FilterItemsWrapper = styled(Box)`
  .MuiAccordion-root:nth-last-of-type(2) {
    border-bottom: none;
  }
`;
