import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography, Box } from '@material-ui/core';

import { UserSettingsPageTab, UserSettingsTab } from 'src/views/UserSettings/types';

import GradientTypography from '../GradientTypography';

interface ScrollToElementProps {
  tabs: UserSettingsTab[];
  activeTab: UserSettingsPageTab;
  onTabClick: (activeTab: UserSettingsPageTab) => void;
}

const Tabs = ({ tabs, activeTab, onTabClick }: ScrollToElementProps) => {
  const { t } = useTranslation();

  return (
    <Box display="flex">
      {tabs.map(({ label, testId }, idx) => (
        <Fragment key={idx}>
          {testId === activeTab ? (
            <GradientTypography
              fontWeight="500"
              variantMapping={{ h3: 'h3', button: 'button' }}
              onClick={() => onTabClick(testId)}
              mr="30px"
              sx={{ cursor: 'pointer' }}
              tabIndex={0}
              data-testid={testId}
            >
              {t(label)}
            </GradientTypography>
          ) : (
            <Typography
              color="text.secondary"
              variantMapping={{ h3: 'h3', button: 'button' }}
              onClick={() => onTabClick(testId as UserSettingsPageTab)}
              mr="30px"
              sx={{ cursor: 'pointer' }}
              tabIndex={0}
              data-testid={testId}
            >
              {t(label)}
            </Typography>
          )}
        </Fragment>
      ))}
    </Box>
  );
};

export default Tabs;
