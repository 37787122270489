import React from 'react';

import { Box } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';

const MainBox = styled(Box)`
  position: absolute;
  top: 30px;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  filter: blur(29px);
`;

const OrangeBox = styled(Box)`
  position: absolute;
  z-index: 2;
  background-color: #df642b;
  box-shadow: 0 0 20px 20px #df642b;
`;

const BlueBox = styled(Box)`
  position: absolute;
  background-color: #111769;
  box-shadow: 0 0 50px 50px #111769;
`;

const LightPurpleBox = styled(Box)`
  position: absolute;
  background-color: #7375cf;
  box-shadow: 0 0 50px 50px #7375cf;
`;

const PurpleBox = styled(Box)`
  position: absolute;
  background-color: #511686;
  box-shadow: 0 0 50px 50px #511686;
`;

const PinkBox = styled(Box)`
  z-index: 1;
  position: absolute;
  background-color: #ec5073;
  box-shadow: 0 0 10px 10px #ec5073;
`;

const LoginImageBackground = () => {
  return (
    <MainBox>
      <BlueBox width="300px" height="300px" top="30px" left="70px" borderRadius="50%" />

      <BlueBox
        width="70px"
        height="70px"
        top="-30px"
        left="190px"
        boxShadow="0 0 20px 20px #dd4d71"
        borderRadius="50%"
      />

      <BlueBox
        width="70px"
        height="70px"
        top="10px"
        left="130px"
        boxShadow="0 0 20px 20px #dd4d71"
        borderRadius="50%"
      />

      <BlueBox
        width="50px"
        height="50px"
        top="20px"
        left="310px"
        boxShadow="0 0 20px 20px #dd4d71"
        borderRadius="50%"
      />

      <BlueBox width="50px" height="50px" top="10px" left="60px" boxShadow="0 0 20px 20px #dd4d71" borderRadius="50%" />

      <BlueBox
        width="50px"
        height="50px"
        top="190px"
        left="350px"
        boxShadow="0 0 20px 20px #dd4d71"
        borderRadius="50%"
      />

      <BlueBox
        width="70px"
        height="70px"
        top="110px"
        left="15px"
        boxShadow="0 0 20px 20px #dd4d71"
        borderRadius="50%"
      />

      <BlueBox
        width="170px"
        height="170px"
        top="150px"
        left="5px"
        boxShadow="0 0 20px 20px #dd4d71"
        borderRadius="50%"
      />

      <LightPurpleBox
        width="320px"
        height="20px"
        bottom="280px"
        left="120px"
        borderRadius="100%"
        top="110px"
        sx={{ transform: 'rotate(-16deg)' }}
      />

      <PurpleBox width="150px" height="90px" bottom="70px" left="120px" top="300px" borderRadius="50%" />

      <OrangeBox
        width="20px"
        height="30px"
        top="10px"
        left="155px"
        borderRadius="40%"
        sx={{ transform: 'rotate(-16deg)' }}
      />

      <OrangeBox
        width="40px"
        height="50px"
        top="230px"
        left="135px"
        borderRadius="40%"
        sx={{ transform: 'rotate(16deg)' }}
      />

      <OrangeBox
        width="50px"
        height="60px"
        top="190px"
        left="405px"
        borderRadius="40%"
        sx={{ transform: 'rotate(16deg)' }}
      />

      <PinkBox
        width="20px"
        height="20px"
        top="200px"
        boxShadow="0 0 20px 20px #ec5073"
        left="70px"
        borderRadius="45%"
      />

      <PinkBox
        width="20px"
        height="20px"
        top="220px"
        boxShadow="0 0 20px 20px #ec5073"
        left="70px"
        borderRadius="45%"
      />

      <PinkBox
        width="270px"
        height="20px"
        top="240px"
        boxShadow="0 0 20px 20px #ec5073"
        left="70px"
        borderRadius="45%"
        sx={{ transform: 'rotate(-10deg)' }}
      />

      <PinkBox
        width="20px"
        height="20px"
        top="260px"
        boxShadow="0 0 20px 20px #ec5073"
        left="330px"
        borderRadius="45%"
      />

      <PinkBox
        width="20px"
        height="20px"
        top="280px"
        boxShadow="0 0 20px 20px #ec5073"
        left="320px"
        borderRadius="45%"
      />

      <PinkBox
        width="20px"
        height="20px"
        top="300px"
        boxShadow="0 0 20px 20px #ec5073"
        left="290px"
        borderRadius="45%"
      />

      <PinkBox
        width="20px"
        height="20px"
        top="300px"
        boxShadow="0 0 20px 20px #ec5073"
        left="270px"
        borderRadius="45%"
      />

      <PinkBox
        width="20px"
        height="20px"
        top="250px"
        boxShadow="0 0 20px 20px #ec5073"
        left="330px"
        borderRadius="45%"
      />

      <PinkBox
        width="20px"
        height="20px"
        top="310px"
        boxShadow="0 0 20px 20px #ec5073"
        left="250px"
        borderRadius="45%"
      />

      <PinkBox
        width="20px"
        height="20px"
        top="320px"
        boxShadow="0 0 20px 20px #ec5073"
        left="230px"
        borderRadius="45%"
      />

      <PinkBox
        width="50px"
        height="20px"
        top="330px"
        boxShadow="0 0 20px 20px #ec5073"
        left="180px"
        borderRadius="45%"
        sx={{ transform: 'rotate(-10deg)' }}
      />
    </MainBox>
  );
};

export default LoginImageBackground;
