import React from 'react';
import { useTranslation } from 'react-i18next';

import { useFlag } from '@unleash/proxy-client-react';

import { GenericFormProps } from 'src/components/Forms/types';
import { FeatureFlag } from 'src/constants';
import { GoogleLogin } from 'src/utils/gql/types';

import GoogleAuthButton from './GoogleAuthButton/GoogleAuthButton';
import { AuthContainer, DividerContainer, Divider, DividerText } from './styled';

const GoogleAuth = ({ onSubmit, onError, loading }: GenericFormProps<GoogleLogin>) => {
  const { t } = useTranslation();

  const googleClientId = process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID;
  const isGoogleAuthActive = useFlag(FeatureFlag.GOOGLE_AUTH);

  if (!googleClientId || !isGoogleAuthActive) {
    {
      return null;
    }
  }

  return (
    <AuthContainer>
      <GoogleAuthButton onSubmit={onSubmit} onError={onError} loading={loading} />

      <DividerContainer>
        <Divider />
        <DividerText>{t('or')}</DividerText>
        <Divider />
      </DividerContainer>
    </AuthContainer>
  );
};

export default GoogleAuth;
