import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { v4 as uuidv4 } from 'uuid';

import {
  UploadingMediaWithLoadingStatus,
  AVAILABLE_FORMATS,
  UploadMedia,
  MediaDataType,
} from 'src/components/MediaGallery/constants';
import { MediaStateContext } from 'src/providers/MediaProvider/context';
import { useSnackbar } from 'src/providers/snackbar';
import { getImagePreview } from 'src/utils/imageUtils';
import { getMediaType, getFileExpansion } from 'src/views/Catalogs/ProductItems/functions';
import { MEDIA_SIZE_LIMIT, MEDIA_COUNT_LIMIT, UploadingMedia } from 'src/views/Catalogs/ProductItems/types';

import useCreateMedia from './useCreateMedia';

const useUploadMedia = (setUpload?: React.Dispatch<React.SetStateAction<UploadingMediaWithLoadingStatus[]>>) => {
  const { t } = useTranslation();
  const snackbar = useSnackbar();

  const { createMediaFile } = useCreateMedia();

  const { setMediaState, mediaState, mediaLastOrder, setMediaLastOrder } = useContext(MediaStateContext);

  const handleUploadMedia = async ({
    files: uploadedFiles,
    urls: uploadedUrls,
    countLimit = MEDIA_COUNT_LIMIT,
  }: UploadMedia) => {
    const uploadedMedia: (File | string)[] = [];

    const filesWithValidExtensionAndSize = uploadedFiles?.filter(
      ({ name, size }) => AVAILABLE_FORMATS.includes(getFileExpansion(name)) && size <= MEDIA_SIZE_LIMIT,
    );

    if (uploadedFiles && filesWithValidExtensionAndSize !== undefined) {
      uploadedMedia.push(
        ...(filesWithValidExtensionAndSize.length > countLimit && !setUpload
          ? filesWithValidExtensionAndSize
          : uploadedFiles),
      );
    }

    if (uploadedUrls) {
      uploadedMedia.push(...uploadedUrls);
    }

    const availableFilesCount = countLimit - mediaState.length;
    const slicedRemainingAvailableFilesOrUrls = setUpload ? uploadedMedia : uploadedMedia.slice(0, availableFilesCount);

    if (availableFilesCount > 0 || setUpload) {
      let fileIndex = (mediaLastOrder || 0) + 1;
      for (const fileOrUrl of slicedRemainingAvailableFilesOrUrls) {
        const isUrl = typeof fileOrUrl === 'string';

        if (uploadedMedia.length > availableFilesCount && !setUpload) {
          snackbar(t('uploadMediaErrors.limitReached'), 'info');
        }

        if (!isUrl && (fileOrUrl as File).size > MEDIA_SIZE_LIMIT) {
          snackbar(t('uploadMediaErrors.sizeLimitReached'));

          continue;
        }

        const type = getMediaType(isUrl ? (fileOrUrl as string) : getFileExpansion((fileOrUrl as File).name));

        if (!type && isUrl) {
          snackbar(t('uploadMediaErrors.unsupportedSource'));

          continue;
        } else if (!type) {
          snackbar(t('uploadMediaErrors.unsupportedExtension'));

          continue;
        }

        const uploadingMedia: UploadingMedia = {
          id: uuidv4(),
          type,
          altText: '',
          uuid: uuidv4(),
        };

        if (typeof fileOrUrl !== 'string') {
          const expansionFile = getFileExpansion(fileOrUrl.name);
          const fileId = fileIndex;
          getImagePreview(fileOrUrl, async (result: string) => {
            if (!setUpload) {
              setMediaState((prev) => [
                ...prev,
                {
                  ...uploadingMedia,
                  url: result,
                  expansion: expansionFile,
                  name: fileOrUrl.name,
                  file: fileOrUrl,
                  typeAddedData: MediaDataType.FROM_FILE,
                  orderNumber: fileId,
                },
              ]);
            } else {
              await createMediaFile(result, fileOrUrl, uploadingMedia, type, setUpload);
            }
          });
        } else {
          setMediaState((prev) => [
            ...prev,
            {
              ...uploadingMedia,
              url: fileOrUrl,
              typeAddedData: MediaDataType.FROM_URL,
              orderNumber: fileIndex,
            },
          ]);
        }
        setMediaLastOrder(fileIndex);
        fileIndex += 1;
      }
    } else {
      snackbar(t('uploadMediaErrors.exceededCount'));
    }
  };

  return { handleUploadMedia };
};

export default useUploadMedia;
