import React, { ChangeEvent, createRef, useEffect, useState } from 'react';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';

import { Box } from '@material-ui/core';
import { capitalize } from '@mui/material';
import { sha1 } from 'crypto-hash';
import { v4 as uuidv4 } from 'uuid';

import { FILE_EXTENSION, ONBOARDING_CSV_HASH } from 'src/constants';
import useOnboarding from 'src/providers/OnboardingProvider/hooks/useOnboarding';
import fileToBase64 from 'src/utils/fileToBase64';

import { Browse, ErrorMessage, SmallDropzoneContainer, SmallDropzoneText } from './styled';
import { SmallDropzoneProps } from './types';

const SmallDropzone = ({ setUploadCSVs }: SmallDropzoneProps) => {
  const { t } = useTranslation();
  const {
    onboardingState: { tourActive },
  } = useOnboarding();

  const inputFile = createRef<HTMLInputElement>();

  const [error, setError] = useState('');

  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;

    if (files) {
      if (tourActive) {
        const targetFile = files[0];

        const base64 = await fileToBase64(targetFile);
        const hash = await sha1(base64 as string);

        if (hash !== ONBOARDING_CSV_HASH) return setError(t('dropzone.incorrectCsvFile'));
      }

      const filesArray = Array.from(files);
      const uploadingFiles = filesArray.map((file) => ({ file, id: uuidv4(), name: file.name }));

      setUploadCSVs(uploadingFiles);
    }
  };

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: [NativeTypes.FILE],
    drop(acceptedFiles: { files: File[] }) {
      const hasIncorrectFiles = !acceptedFiles.files.every((file) =>
        [FILE_EXTENSION.csv, FILE_EXTENSION.csvWin].includes(file.type),
      );

      if (hasIncorrectFiles) {
        setError(t('dropzone.onlyCsvError'));
      } else {
        setUploadCSVs(acceptedFiles.files.map((file) => ({ file, id: uuidv4(), name: file.name })));
      }
    },
    collect: (monitor: { isOver: () => boolean; canDrop: () => boolean }) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  useEffect(() => {
    canDrop && setError('');
  }, [canDrop]);

  const isDragActive = canDrop && isOver;

  return (
    <Box>
      <SmallDropzoneContainer ref={drop}>
        <input
          type="file"
          accept={`${FILE_EXTENSION.csv}, .csv, ${FILE_EXTENSION.csvWin}`}
          multiple
          hidden
          ref={inputFile}
          onChange={handleChange}
        />

        {isDragActive ? (
          <SmallDropzoneText variant="caption">{t('dropzone.dropHere')}</SmallDropzoneText>
        ) : (
          <SmallDropzoneText variant="caption">
            {!tourActive && t('dropzone.dragAndDrop')}
            <Browse onClick={() => inputFile.current?.click()}>
              {tourActive ? capitalize(t('dropzone.browse')) : t('dropzone.browse')}
            </Browse>
            {tourActive ? t('dropzone.fileImporting') : t('dropzone.filesImporting')}
          </SmallDropzoneText>
        )}
      </SmallDropzoneContainer>

      <ErrorMessage variant="caption" textAlign="center" color="secondary" mt="3px">
        {error}
      </ErrorMessage>
    </Box>
  );
};

export default SmallDropzone;
