import React from 'react';

import { makeStyles, Radio, RadioProps, Theme } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    width: 15,
    height: 15,
    border: `1px solid ${theme.palette.secondary.main}`,
    backgroundImage: theme.palette.common.white,
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: theme.palette.background.default,
      border: `1px solid ${theme.palette.action.disabled}`,
    },
  },
  checkedIcon: {
    backgroundColor: theme.palette.common.white,
    '&:before': {
      display: 'block',
      width: 9,
      height: 9,
      padding: '3px',
      backgroundColor: theme.palette.secondary.main,
      content: '""',
      borderRadius: '50%',
    },
  },
}));

function RadioButton(props: RadioProps) {
  const classes = useStyles();

  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

export default RadioButton;
