import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';

import { Typography, Link } from '@material-ui/core';

import Page from 'src/components/Page';

import CheckEmailImageBackground from './CheckEmailImageBackground';
import { CheckEmailContainer, Image, CheckEmailSideContainer, StyledCheckEmailContainer } from './styled';

const CheckEmail = () => {
  const { t } = useTranslation();
  const { integration } = useParams<{ integration: string }>();
  const { state: { userEmail = '', isIntegration = false } = {} } = useLocation<{
    userEmail: string;
    isIntegration: boolean;
  }>();

  const [isImageLoading, setIsImageLoading] = useState(true);

  return (
    <Page title={t('checkYourEmail.pageTitle')}>
      <StyledCheckEmailContainer>
        <CheckEmailContainer>
          <Typography variant="h1">{t('checkYourEmail.title')}</Typography>

          {isIntegration ? (
            <Typography variant="body1" textAlign="center" color="textSecondary" width="400px" mt="59px">
              <Trans
                i18nKey="checkYourEmail.checkEmailIntegration"
                defaults="<bold>{{userEmail}}</bold>"
                values={{ userEmail }}
                components={{ bold: <strong /> }}
              />
            </Typography>
          ) : (
            <>
              <Typography variant="body1" color="textSecondary" textAlign="center" mt="40px" mb="10px">
                {t('checkYourEmail.part1')}
                <Typography component="span" variant="subtitle1" color="textSecondary">
                  {userEmail}
                </Typography>
              </Typography>

              <Typography variant="body1" color="textSecondary" textAlign="center" maxWidth="379px">
                {t('checkYourEmail.part2')}
              </Typography>

              <Typography variant="subtitle1" color="textSecondary" textAlign="center" mt="30px" mb="10px">
                {t('checkYourEmail.question')}
              </Typography>

              <Typography variant="body2" color="textSecondary" textAlign="center">
                {t('checkYourEmail.checkYourSpamFolder')}
                <Link
                  color="secondary"
                  component={RouterLink}
                  to={{
                    pathname: isIntegration ? `/connect/${integration}/check-email/resend` : '/auth/check-email/resend',
                    state: { userEmail },
                  }}
                  underline="hover"
                  variant="h4"
                  mt="40px"
                >
                  {t('clickHere')}
                </Link>
              </Typography>
            </>
          )}
        </CheckEmailContainer>

        <CheckEmailSideContainer>
          {isImageLoading && <CheckEmailImageBackground />}
          <Image
            src={`${process.env.PUBLIC_URL}/images/checkEmail.webp`}
            alt="CheckEmail"
            onLoad={() => setIsImageLoading(false)}
          />
        </CheckEmailSideContainer>
      </StyledCheckEmailContainer>
    </Page>
  );
};

export default CheckEmail;
