import { gql } from '@apollo/client';

import { CatalogType } from './types';

const userFields = `
  id
  email
  status
  name
  avatar
  isOnboardingPassed 
`;

const mappingsErrors = `
  id
  reason
  description
  syncable
  catalogId
  integrationId
  productTypeFieldId
  productItemId
  productTypeId
  metadata
`;

const duplicatedSKUInfoFields = `
  duplicatedSKUInfo {
    isDuplicated
    duplicatedSKU {
      itemId
      productTypeId
      name
    }
  }
`;

// should be synchronized with tests
const catalogFields = `
  id
  name
  source
  status
  createdAt
  keywords
  isEmpty
  subscriptions {
    id
    approvalStatus
    active
  }
  user {
    id
    name
  }
  config {
    id
    price
    paymentFrequency
    privacy
    extraChargePercentage
  }
  integrations {
    id
    type
    settings
    mappingsErrors {
     ${mappingsErrors}
    }
  }
  lastIntegrationsJobs {
    createdAt
    id
    catalogId
    integrationId
    type
    status
    enqueueTime
    startTime
    endTime
    error
  }
`;

const productItemFields = `
  id
  status
  cloneParentId
  catalog {
    user {
      id
    }
  }
  values {
    id
    value
    productTypeField {
      id
      name
      systemName
      type
    }
  }
  mediaUsages {
    id
    orderNumber
    media {
      id
      type
      previewUrl
      url
    }
  }
`;

const mappingFields = `
  id
  metadataExternalId
  toSync
  metadata
  integrationId
  productTypeId
`;

export const me = gql`
  query me {
   me {
      ${userFields}
    }
  }
`;

export const userSignIn = gql`
  mutation signIn($data: AuthInput!) {
    signIn(data: $data) {
      token
      user {
        ${userFields}
      }
    }
  }
`;

export const userSignUp = gql`
  mutation signUp($data: RegInput!) {
    signUp(data: $data) {
      ${userFields}
    }
  }
`;

export const userGoogleLogin = gql`
  mutation googleLogin($data: GoogleLoginInput!) {
    googleLogin(data: $data) {
      token
      user {
        ${userFields}
      }
    }
  }
`;

export const completeUserOnboarding = gql`
  mutation completeOnboarding {
    completeOnboarding
  }
`;

export const integrationUserSignUp = gql`
  mutation integrationSignUp($data: IntegrationRegInput!) {
    integrationSignUp(data: $data) {
      token
      user {
        ${userFields}
      }
    }
  }
`;

export const createCatalogQuery = gql`
  mutation createCatalog($data: CreateCatalogInput!) {
    createCatalog(data: $data) {
      ${catalogFields}
    }
  }
`;

export const isSKUDuplicated = gql`
  query isSKUDuplicated($data: IsSKUDuplicatedInput!) {
    isSKUDuplicated(data: $data) {
      isDuplicated
      duplicatedSKU {
        itemId
        productTypeId
        name
      }
    }
  }
`;

export const createOnboardingCatalogMutation = gql`
  mutation createOnboardingCatalog($data: CreateOnboardingCatalogInput!) {
    createOnboardingCatalog(data: $data) {
      catalog {
        ${catalogFields}
      }
      productType {
        id
        name
        createdAt
        fields {
          id
          name
          systemName
          type
          required
          defaultValue
        }
      }
      productItems {
        ${productItemFields}
      }
    }
  }
`;

export const getCatalogInfo = gql`
  query getCatalogById($id: ID!) {
    getCatalogById(id: $id) {
      ${duplicatedSKUInfoFields}
      ${catalogFields}
      syncable
      mappingsErrors {
        ${mappingsErrors}
      }
    }
  }
`;

export const checkIsCatalogEmpty = gql`
  query getCatalogById($id: ID!) {
    getCatalogById(id: $id) {
      id
      isEmpty
      ${duplicatedSKUInfoFields}
    }
  }
`;

export const checkIsCatalogSyncable = gql`
  query getCatalogById($id: ID!) {
    getCatalogById(id: $id) {
      id
      syncable
    }
  }
`;

export const checkCatalogMappingsErrors = gql`
  query getCatalogById($id: ID!) {
    getCatalogById(id: $id) {
      id
      syncable
      mappingsErrors {
        ${mappingsErrors}
      }
      integrations {
      id
      type
      settings
        mappingsErrors {
          ${mappingsErrors}
        }
      }
    }
  }
`;

export const getEbayMerchantLocations = gql`
  query getEbayMerchantLocations($offset: Int, $limit: Int, $integrationId: ID!) {
    getEbayMerchantLocations(offset: $offset, limit: $limit, integrationId: $integrationId) {
      name
      key
    }
  }
`;

export const getEbayPolicies = gql`
  query getEbayPolicies($integrationId: ID!, $marketplaceId: ID!) {
    getEbayPolicies(integrationId: $integrationId, marketplaceId: $marketplaceId) {
      name
      id
      type
    }
  }
`;

export const getCatalogs = gql`
  query getCatalogs(
    $ids: [String!]
    $name: String
    $status: CatalogStatus
    $source: CatalogSource
    $offset: Int
    $limit: Int
    $types: [CatalogType!]
    $orderBy: OrderBy
    $orderDirection: OrderDirection
  ) {
    getCatalogs(
      ids: $ids
      name: $name
      status: $status
      source: $source
      offset: $offset
      limit: $limit
      types: $types
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      ${duplicatedSKUInfoFields}
      ${catalogFields}
      syncable
      mappingsErrors {
        ${mappingsErrors}
      }
    }
  }
`;

export const getSharedCatalogs = gql`
  query getSharedCatalogs(
    $name: String
    $status: CatalogStatus
    $source: CatalogSource
    $offset: Int
    $limit: Int!
    $orderBy: OrderBy
    $orderDirection: OrderDirection
    $keywords: [String!]
  ) {
    getCatalogs(
      name: $name
      status: $status
      source: $source
      offset: $offset
      limit: $limit
      types: [${CatalogType.Shared}]
      orderBy: $orderBy
      orderDirection: $orderDirection
      keywords: $keywords
    ) {
      ${catalogFields}
    }
  }
`;

export const getCatalogsJobs = gql`
  query getCatalogs(
    $ids: [String!]
    $name: String
    $status: CatalogStatus
    $source: CatalogSource
    $offset: Int
    $limit: Int
    $types: [CatalogType!]
  ) {
    getCatalogs(
      ids: $ids
      name: $name
      status: $status
      source: $source
      offset: $offset
      limit: $limit
      types: $types
    ) {
      ${catalogFields}
    }
  }
`;

export const checkCatalogOwnership = gql`
  query checkCatalogAccess($catalogId: ID!) {
    checkCatalogAccess(catalogId: $catalogId)
  }
`;

export const isSameName = gql`
  query isSameName($name: String!) {
    checkNameAvailability(name: $name)
  }
`;

export const checkProductTypeNameAvailability = gql`
  query checkProductTypeNameAvailability($name: String!, $catalogId: ID!) {
    checkProductTypeNameAvailability(name: $name, catalogId: $catalogId)
  }
`;

export const deleteCatalogById = gql`
  mutation deleteCatalog($id: ID!) {
    deleteCatalog(id: $id)
  }
`;

export const exportCatalogToIntegration = gql`
  mutation exportCatalog($integrationId: ID!, $id: ID!) {
    exportCatalog(integrationId: $integrationId, id: $id) {
      ${catalogFields}
    }
  }
`;

export const exportCatalogToAllAttachedIntegrations = gql`
  mutation exportCatalogToAllAttachedIntegrations($id: ID!) {
    exportCatalogToAllAttachedIntegrations(id: $id)
  }
`;

export const exportProductTypeToAllAttachedIntegrations = gql`
  mutation exportProductTypeToAllAttachedIntegrations($catalogId: ID!, $productTypeId: ID!) {
    exportProductTypeToAllAttachedIntegrations(catalogId: $catalogId, productTypeId: $productTypeId)
  }
`;

export const exportProductTypeToAttachedIntegration = gql`
  mutation exportProductTypeToAttachedIntegration($catalogId: ID!, $productTypeId: ID!, $integrationId: ID!) {
    exportProductTypeToAttachedIntegration(
      catalogId: $catalogId
      productTypeId: $productTypeId
      integrationId: $integrationId
    )
  }
`;

export const getProductTypesByCatalogId = gql`
  query productTypes($catalogId: ID!, $offset: Int, $limit: Int!, $subName: String) {
    productTypes(catalogId: $catalogId, offset: $offset, limit: $limit, subName: $subName) {
      id
      name
      isEmpty
      fields {
        id
        name
        type
        required
        defaultValue
      }
      mappingsErrors {
        ${mappingsErrors}
      }
    }
  }
`;

export const getProductTypesCountByCatalogId = gql`
  query getProductTypesCountByCatalogId($catalogId: ID!, $subName: String) {
    getProductTypesCountByCatalogId(catalogId: $catalogId, subName: $subName)
  }
`;

export const getProductTypeById = gql`
  query getProductTypeById($id: ID!) {
    getProductType(id: $id) {
      id
      name
      isEmpty
      ${duplicatedSKUInfoFields}
      fields {
        id
        name
        systemName
        type
        required
        defaultValue
        showInFilters
        showInPreview
        invariable
      }
      fieldsMappings {
        id
        productTypeFieldId
        metadataExternalId
        integrationId
      }
      mappingsErrors {
        ${mappingsErrors}
      }
    }
  }
`;

export const checkProductTypeMappingsErrors = gql`
  query getProductTypeById($id: ID!) {
    getProductType(id: $id) {
      id
      mappingsErrors {
        ${mappingsErrors}
      }
    }
  }
`;

export const getProductTypeName = gql`
  query getProductType($id: ID!) {
    getProductType(id: $id) {
      name
    }
  }
`;

export const createNewProductType = gql`
  mutation createProductType($data: ProductTypeInput!, $catalogId: ID!) {
    createProductType(data: $data, catalogId: $catalogId) {
      id
      name
      createdAt
      fields {
        id
        name
        systemName
        type
        required
        defaultValue
      }
    }
  }
`;

export const copyProductItemById = gql`
  mutation copyProductItem($productItemId: ID!, $sku: String) {
    copyProductItem(productItemId: $productItemId, sku: $sku) {
      ${productItemFields}
    }
  }
`;

export const bulkCreateIntegrationSpecificItemData = gql`
  mutation createIntegrationsSpecificItemData($data: CreateIntegrationsSpecificItemData!) {
    createIntegrationsSpecificItemData(data: $data) {
      id
      metadata
      integrationId
    }
  }
`;

export const getUnavailableExternalTypesIds = gql`
  query getUnavailableExternalTypesIds($integrationId: ID!, $metadataExternalIds: [ID!]!) {
    getUnavailableExternalTypesIds(integrationId: $integrationId, metadataExternalIds: $metadataExternalIds)
  }
`;

export const bulkUpdateIntegrationSpecificItemData = gql`
  mutation updateIntegrationsSpecificItemData($data: UpdateIntegrationsSpecificItemData!) {
    updateIntegrationsSpecificItemData(data: $data) {
      id
      metadata
      integrationId
    }
  }
`;

export const updateProductTypeById = gql`
  mutation updateProductType($data: UpdateProductTypeInput!) {
    updateProductType(data: $data) {
      id
      name
      fields {
        id
        name
        type
        required
      }
    }
  }
`;

export const deleteProductTypeById = gql`
  mutation deleteProductType($id: ID!) {
    deleteProductType(id: $id)
  }
`;

export const deleteProductTypeFieldById = gql`
  mutation deleteProductTypeField($fieldId: ID!) {
    deleteProductTypeField(fieldId: $fieldId)
  }
`;

export const deleteProductTypes = gql`
  mutation deleteProductTypes($ids: [ID!]!) {
    deleteProductTypes(ids: $ids)
  }
`;

export const getProductItems = gql`
  query getProductItemsByProductTypeId(
    $id: ID!
    $offset: Int
    $limit: Int!
    $search: String
    $filter: [FilterItem!]
    $status: [ItemStatus!]
  ) {
    getProductItemsByProductTypeId(
      id: $id
      offset: $offset
      limit: $limit
      search: $search
      filter: $filter
      status: $status
    ) {
      ${productItemFields}
      mappingsErrors {
        ${mappingsErrors}
      }
    }
  }
`;

export const getProductItemById = gql`
  query getProductItem($id: ID!) {
    getProductItem(id: $id) {
      id
      status
      integrationsSpecificItemData {
        id
        metadata
        integrationId
      }
      catalog {
        id
        user {
          id
        }
        integrations {
          id
          type
          settings
        }
      }
      values {
        id
        value
        productTypeField {
          id
          name
          systemName
          type
          required
        }
      }
      mediaUsages {
        id
        orderNumber
        media {
          id
          type
          previewUrl
          url
          altText
        }
      }
      mappingsErrors {
        ${mappingsErrors}
      }
    }
  }
`;

export const getProductItemMappingErrors = gql`
  query getProductItem($id: ID!) {
    getProductItem(id: $id) {
      id
      mappingsErrors {
        ${mappingsErrors}
      }
    }
  }
`;

export const createNewProductItem = gql`
  mutation createProductItem($data: CreateProductItemInput!, $catalogId: ID!) {
    createProductItem(data: $data, catalogId: $catalogId) {
      id
      values {
        id
        value
        productTypeField {
          id
          name
          systemName
          required
          type
        }
      }
    }
  }
`;

export const updateProductItemById = gql`
  mutation updateProductItem($data: UpdateProductItemInput!, $id: ID!) {
    updateProductItem(data: $data, id: $id) {
      id
      values {
        id
        value
        productTypeField {
          id
          name
          systemName
          required
          type
        }
      }
      status
      mediaUsages {
        id
        orderNumber
        media {
          id
          type
          previewUrl
          url
          altText
        }
      }
      integrationsSpecificItemData {
        id
        metadata
        integrationId
      }
    }
  }
`;

export const updateProductItems = gql`
  mutation updateProductItems($ids: [ID!]!, $changingValue: ProductItemValueInput!, $productTypeId: ID!) {
    updateProductItems(ids: $ids, changingValue: $changingValue, productTypeId: $productTypeId) {
      id
      values {
        id
        value
      }
    }
  }
`;

export const deleteProductItemsById = gql`
  mutation deleteProductItems($productItemIds: [ID!]!) {
    deleteProductItems(productItemIds: $productItemIds)
  }
`;

export const makeProductItemsDraft = gql`
  mutation makeProductItemsDraft($productTypeId: ID!, $ids: [ID!]!) {
    makeProductItemsDraft(productTypeId: $productTypeId, ids: $ids) {
      status
    }
  }
`;

export const deleteProductItemsByProductTypeId = gql`
  mutation deleteProductItemsByProductTypeId($productTypeId: ID!, $search: String!, $filter: [FilterItem!]!) {
    deleteProductItemsByProductTypeId(productTypeId: $productTypeId, search: $search, filter: $filter)
  }
`;

export const verifyMailLink = gql`
  mutation verifyMailLink($token: String!) {
    verifyLink(token: $token) {
      token
      user {
        email
      }
    }
  }
`;

export const resetUserPassword = gql`
  mutation sendEmailForResetPassword($email: String!) {
    sendEmailForResetPassword(email: $email)
  }
`;

export const resendConfirmation = gql`
  mutation resendRegistrationConfirmation($email: String!) {
    resendRegistrationConfirmation(email: $email)
  }
`;

export const activateUser = gql`
  mutation activateUser($email: String!) {
    activateUser(email: $email)
  }
`;

export const updatePassword = gql`
  mutation updatePassword($data: UpdatePasswordInput!) {
    updatePassword(data: $data) {
      id
    }
  }
`;

export const validatePasswordToken = gql`
  query validatePasswordToken($token: String!) {
    validatePasswordToken(token: $token)
  }
`;

export const getProductTypeFiltersById = gql`
  query getProductTypeFiltersWithItemsCount($id: ID!, $search: String, $filter: [FilterItem!]) {
    getProductTypeFiltersWithItemsCount(id: $id, search: $search, filter: $filter) {
      id
      filters {
        productTypeFieldId
        productTypeFieldName
        type
        min
        max
        values
        required
      }
      itemsCount
    }
  }
`;

export const getSignedUrlsFromAws = gql`
  mutation getSignedUrls($files: [UploadFileInput!]!) {
    getSignedUrls(files: $files)
  }
`;

export const getDefaultFields = gql`
  query {
    getDefaultFields {
      systemName
      required
      showInPreview
      type
    }
  }
`;

export const getEbayMarketplaceIds = gql`
  query {
    getEbayMarketplaceIds {
      label
      value
    }
  }
`;

export const getEbaySiteIds = gql`
  query {
    getEbaySiteIds {
      label
      value
    }
  }
`;

export const getSubscriptionsByCatalogId = gql`
  query getSubscriptions(
    $catalogId: String!
    $orderBy: OrderBy
    $orderDirection: OrderDirection
    $offset: Int
    $limit: Int!
  ) {
    getSubscriptions(
      catalogId: $catalogId
      orderBy: $orderBy
      orderDirection: $orderDirection
      offset: $offset
      limit: $limit
    ) {
      id
      approvalStatus
      active
      createdAt
      subscriptionDate
      user {
        id
        name
        email
        avatar
      }
    }
  }
`;

export const planSubscriptionStats = gql`
  query planSubscriptionStats {
    planSubscriptionStats {
      catalogsNumber
      itemsNumber
      integrationsNumber
      subscriptionsNumber
      sharedCatalogsNumber
      planSubscription {
        subscriptionStartsAt
        subscriptionEndsAt
        status
        invoiceUrl
        plan {
          catalogsLimit
          itemsLimit
          integrationsLimit
          sharedCatalogsLimit
          subscriptionsLimit
          name
          price
          paymentFrequency
          type
        }
      }
    }
  }
`;

export const currentUserSubscriptionPlan = gql`
  query planSubscriptionStats {
    planSubscriptionStats {
      planSubscription {
        plan {
          name
        }
      }
    }
  }
`;

export const exportToCSVByItemsIds = gql`
  query exportToCSVByItemsIds($productTypeId: ID!, $ids: [ID!]!) {
    exportToCSVByItemsIds(productTypeId: $productTypeId, ids: $ids)
  }
`;

export const exportToCSVByProductTypeId = gql`
  query exportToCSVByProductTypeId($productTypeId: ID!) {
    exportToCSVByProductTypeId(productTypeId: $productTypeId)
  }
`;

export const exportToCSVZipByCatalogId = gql`
  query exportToCSVZipByCatalogId($id: ID!) {
    exportToCSVZipByCatalogId(catalogId: $id)
  }
`;

export const updateCatalog = gql`
  mutation updateCatalog($data: UpdateCatalogInput!) {
    updateCatalog(data: $data) {
      ${catalogFields}
    }
  }
`;

export const getSignedUrlsForCsv = gql`
  mutation getSignedUrlsForCsv($files: [UploadFileCsvInput!]!) {
    getSignedUrlsForCsv(files: $files) {
      url
      id
    }
  }
`;

export const getParsedCsvStatusByIds = gql`
  subscription getParseCSVStatus($ids: [ID!]!) {
    getParseCSVStatus(ids: $ids) {
      id
      status
      error
      data {
        inParsing
        parsed
        parseFailed
        errorMessage
        updatedDataInfo {
          type
          productType {
            id
            name
            fields {
              id
              name
              type
              required
              systemName
              showInPreview
            }
          }
          productItem {
            id
            values {
              id
              productTypeField {
                id
                type
                name
                systemName
                required
                showInPreview
              }
              value
            }
            mediaUsages {
              id
              media {
                id
                type
                previewUrl
                url
              }
            }
          }
        }
      }
    }
  }
`;

export const createSubscription = gql`
  mutation createSubscription($catalogId: ID!) {
    createSubscription(catalogId: $catalogId) {
      id
    }
  }
`;

export const updateSubscription = gql`
  mutation updateSubscription($data: UpdateSubscriptionsInput!) {
    updateSubscription(data: $data) {
      id
    }
  }
`;

export const cancelSubscription = gql`
  mutation cancelSubscription($subscriptionId: ID!) {
    cancelSubscription(subscriptionId: $subscriptionId)
  }
`;

export const getIntegrations = gql`
  query getIntegrations($catalogId: ID, $type: String) {
    getIntegrations(catalogId: $catalogId, type: $type) {
      id
      type
      settings
      isDefault
      createdAt
      mappingsErrors {
        ${mappingsErrors}
      }
    }
  }
`;

export const createIntegration = gql`
  mutation createIntegration($data: CreateIntegrationInput!) {
    createIntegration(data: $data) {
      id
    }
  }
`;

export const deleteIntegration = gql`
  mutation deleteIntegration($id: ID!) {
    deleteIntegration(id: $id)
  }
`;

export const updateIntegration = gql`
  mutation updateIntegration($data: UpdateIntegrationInput!) {
    updateIntegration(data: $data)
  }
`;

export const changeDefaultIntegration = gql`
  mutation changeDefaultIntegration($id: ID!) {
    changeDefaultIntegration(id: $id)
  }
`;

export const updateUserInfo = gql`
  mutation updateUserInfo($data: UpdateUserInfo!) {
    updateUserInfo(data: $data) {
      email
      name
      avatar
    }
  }
`;

export const getPlans = gql`
  query getPlans($type: PlanType, $paymentFrequency: PlanPaymentFrequency) {
    getPlans(type: $type, paymentFrequency: $paymentFrequency) {
      id
      name
      catalogsLimit
      itemsLimit
      integrationsLimit
      sharedCatalogsLimit
      subscriptionsLimit
      price
      paymentFrequency
    }
  }
`;

export const updatePlanSubscription = gql`
  mutation updatePlanSubscription($planName: String!) {
    updatePlanSubscription(planName: $planName) {
      id
    }
  }
`;

export const getPlansPaymentDetails = gql`
  query getPlans($paymentFrequency: PlanPaymentFrequency) {
    getPlans(paymentFrequency: $paymentFrequency) {
      id
      name
      price
      paymentFrequency
    }
  }
`;

export const sendEmailToSupport = gql`
  query sendEmailToSupport($data: EmailToSupportInput!) {
    sendEmailToSupport(data: $data)
  }
`;

export const sendContactUsEmail = gql`
  query sendContactUsEmail($data: EmailToSupportInput!) {
    sendContactUsEmail(data: $data)
  }
`;

export const createStripeSubscription = gql`
  mutation createStripeSubscription($planId: ID!) {
    createStripeSubscription(planId: $planId) {
      subscriptionId
      clientSecret
    }
  }
`;

export const getStripePaymentMethods = gql`
  query getStripePaymentMethods {
    getStripePaymentMethods {
      id
      card {
        brand
        last4
      }
      defaultPaymentMethod
    }
  }
`;

export const deleteStripePaymentMethod = gql`
  mutation deleteStripePaymentMethod($id: ID!) {
    deleteStripePaymentMethod(id: $id)
  }
`;

export const changeDefaultStripePaymentMethod = gql`
  mutation changeDefaultStripePaymentMethod($id: ID!) {
    changeDefaultStripePaymentMethod(id: $id)
  }
`;

export const createStripePaymentMethod = gql`
  mutation createStripePaymentMethod($id: ID!) {
    createStripePaymentMethod(id: $id)
  }
`;

export const getStripeInvoices = gql`
  query getStripeInvoices {
    getStripeInvoices {
      id
      number
      createdAt
      amount
      currency
      status
      url
      pdf
      toUseDefaultPaymentMethod
      card {
        brand
        last4
      }
    }
  }
`;

export const checkIsLimitReached = gql`
  query checkIsLimitReached($type: LimitType!) {
    checkIsLimitReached(type: $type)
  }
`;

export const getStripeBalanceWithNextPayment = gql`
  query getStripeBalanceWithNextPayment {
    getStripeBalanceWithNextPayment {
      balance
      nextPaymentDate
    }
  }
`;

export const getProductTypesPreview = gql`
  query getProductTypesPreview($catalogId: ID!) {
    getProductTypesPreview(catalogId: $catalogId) {
      productTypes {
        productType {
          id
          name
        }
        productItemsCount
      }
      totalCount
    }
  }
`;

export const getProductItemsPreview = gql`
  query getProductItemsPreview($productTypeId: ID!) {
    getProductItemsPreview(productTypeId: $productTypeId) {
      productItems {
        id
        values {
          id
          value
          productTypeField {
            id
            name
            systemName
            type
            required
          }
        }
        mediaUsages {
          id
          media {
            id
            type
            previewUrl
            url
          }
        }
      }
      totalCount
    }
  }
`;

export const getProductItemPreview = gql`
  query getProductItemPreview($productTypeId: Int!, $productItemId: Int!) {
    getProductItemPreview(productTypeId: $productTypeId, productItemId: $productItemId) {
      id
      values {
        id
        value
        productTypeField {
          id
          name
          systemName
          type
          required
        }
      }
      mediaUsages {
        id
        media {
          id
          type
          previewUrl
          url
        }
      }
    }
  }
`;

export const getSubscriptionOnCatalog = gql`
  query getSubscriptionOnCatalog($catalogId: ID!) {
    getSubscriptionOnCatalog(catalogId: $catalogId) {
      id
      approvalStatus
    }
  }
`;

export const checkIsActivePlanSubscription = gql`
  query checkIsActivePlanSubscription {
    checkIsActivePlanSubscription
  }
`;

export const addIntegrationToCatalog = gql`
  mutation addIntegrationToCatalog($data: UpdateCatalogIntegrationInput!) {
    addIntegrationToCatalog(data: $data) {
      id
      integrations {
        id
      }
    }
  }
`;

export const deleteIntegrationFromCatalog = gql`
  mutation deleteIntegrationFromCatalog($data: UpdateCatalogIntegrationInput!) {
    deleteIntegrationFromCatalog(data: $data)
  }
`;

export const getAuditLog = gql`
  query getAuditLog($from: DateTime, $to: DateTime, $entity: AuditLogEntity, $action: AuditLogActionType) {
    getAuditLog(from: $from, to: $to, entity: $entity, action: $action) {
      id
      createdAt
      action
      metadata
      entity
    }
  }
`;

export const publicApiKeys = gql`
  query publicApiKeys($limit: Int, $offset: Int, $sort: SortArg) {
    publicApiKeys(limit: $limit, offset: $offset, sort: $sort) {
      id
      createdAt
      name
      expirationDate
    }
  }
`;

export const createPublicApiKey = gql`
  mutation createPublicApiKey($name: String!, $expirationDate: DateTime) {
    createPublicApiKey(name: $name, expirationDate: $expirationDate) {
      id
      createdAt
      name
      expirationDate
      key
    }
  }
`;

export const deletePublicApiKey = gql`
  mutation deletePublicApiKey($id: ID!) {
    deletePublicApiKey(id: $id)
  }
`;

export const checkIsSpecialImportedField = gql`
  query checkIsSpecialImportedField($name: String!, $productTypeId: ID!) {
    checkIsSpecialImportedField(name: $name, productTypeId: $productTypeId)
  }
`;

export const getMostPopularKeywords = gql`
  query getMostPopularKeywords($limit: Int!, $name: String) {
    getMostPopularKeywords(limit: $limit, name: $name)
  }
`;

export const getProration = gql`
  query getProration($planId: ID!) {
    getProration(planId: $planId)
  }
`;

export const upgradePlan = gql`
  mutation upgradePlan($planId: ID!) {
    upgradePlan(planId: $planId)
  }
`;

export const catalogJobUpdateSubscription = gql`
  subscription catalogJobUpdate($catalogIds: [ID!], $integrationIds: [ID!]) {
    catalogJobUpdate(catalogIds: $catalogIds, integrationIds: $integrationIds) {
      createdAt
      id
      catalogId
      integrationId
      type
      status
      startTime
      endTime
      error
    }
  }
`;

export const createMedia = gql`
  mutation createMedia($data: CreateMediaInput!) {
    createMedia(data: $data) {
      url
      type
      id
      previewUrl
      createdAt
    }
  }
`;

export const getMedia = gql`
  query getMedia($productItemId: ID, $offset: Int, $limit: Int, $type: [MediaType!], $from: DateTime, $to: DateTime) {
    getMedia(productItemId: $productItemId, offset: $offset, limit: $limit, type: $type, from: $from, to: $to) {
      url
      type
      id
      previewUrl
      createdAt
    }
  }
`;

export const updateMediaAltText = gql`
  mutation updateMediaAltText($data: [UpdateAltTextInput!]!) {
    updateMediaAltText(data: $data) {
      id
      altText
    }
  }
`;

export const deleteMedia = gql`
  mutation deleteMedia($ids: [ID!]!) {
    deleteMedia(ids: $ids)
  }
`;

export const detachMediaFromProductItem = gql`
  mutation detachMediaFromProductItem($productItemId: ID!, $mediaUsagesIds: [ID!]!) {
    detachMediaFromProductItem(productItemId: $productItemId, mediaUsagesIds: $mediaUsagesIds) {
      id
      mediaUsages {
        id
        orderNumber
      }
    }
  }
`;

export const createProductTypeFieldMappings = gql`
  mutation createProductTypeFieldIntegrationMappings($mappings: [CreateProductTypeFieldIntegrationMappingInput!]!) {
    createProductTypeFieldIntegrationMappings(mappings: $mappings) {
      id
      productTypeFieldId
      metadataExternalId
      toSync
    }
  }
`;

export const updateProductTypeFieldMappings = gql`
  mutation updateProductTypeFieldIntegrationMappings($mappings: [UpdateProductTypeFieldIntegrationMappingInput!]!) {
    updateProductTypeFieldIntegrationMappings(mappings: $mappings) {
      id
      productTypeFieldId
      metadataExternalId
      toSync
    }
  }
`;

export const deleteProductTypeFieldMappings = gql`
  mutation deleteProductTypeFieldIntegrationMappings($ids: [ID!]!) {
    deleteProductTypeFieldIntegrationMappings(ids: $ids)
  }
`;

export const integrationProductTypeFields = gql`
  query integrationProductTypeFields($id: ID!, $externalProductTypeId: ID!) {
    integrationProductTypeFields(id: $id, externalProductTypeId: $externalProductTypeId) {
      id
      name
      type
    }
  }
`;

export const integrationProductTypeFieldMappings = gql`
  query productTypeFieldIntegrationMappings($productTypeId: ID, $catalogId: ID) {
    productTypeFieldIntegrationMappings(productTypeId: $productTypeId, catalogId: $catalogId) {
      id
      productTypeFieldId
      productTypeId
      metadataExternalId
      integrationId
      toSync
    }
  }
`;

export const integrationProductTypes = gql`
  query integrationProductTypes($id: ID!) {
    integrationProductTypes(id: $id) {
      id
      name
      integrationId
    }
  }
`;

export const bulkCreateProductTypeMappings = gql`
  mutation bulkCreateProductTypeIntegrationMappings($mappings: [CreateProductTypeIntegrationMappingInput!]!) {
    bulkCreateProductTypeIntegrationMappings(mappings: $mappings) {
      id
      productTypeId
      metadataExternalId
      integrationId
      toSync
      metadata
      fieldMappings {
        id
        productTypeFieldId
        metadataExternalId
        toSync
      }
    }
  }
`;

export const bulkUpdateProductTypeMappings = gql`
  mutation bulkUpdateProductTypeIntegrationMappings($mappings: [UpdateProductTypeIntegrationMappingInput!]!) {
    bulkUpdateProductTypeIntegrationMappings(mappings: $mappings) {
      typeMappings {
        id
        productTypeId
        metadataExternalId
        toSync
        metadata
      }
      fieldMappings {
        id
        productTypeFieldId
        metadataExternalId
        toSync
      }
    }
  }
`;

export const deleteProductTypeIntegrationMapping = gql`
  mutation deleteProductTypeIntegrationMapping($id: ID!) {
    deleteProductTypeIntegrationMapping(id: $id)
  }
`;

export const getProductTypeMappings = gql`
  query getProductTypeIntegrationMappings($productTypeId: ID!) {
    getProductTypeIntegrationMappings(productTypeId: $productTypeId) {
      ${mappingFields}
    }
  }
`;

export const getProductTypeIntegrationMappingsByCatalogId = gql`
  query getProductTypeIntegrationMappingsByCatalogId($catalogId: ID!) {
    getProductTypeIntegrationMappingsByCatalogId(catalogId: $catalogId) {
      ${mappingFields}
    }
  }
`;

export const updateFacebookToken = gql`
  mutation updateFacebookAccessToken($businessId: ID!, $shortLivedAccessToken: String!) {
    updateFacebookAccessToken(businessId: $businessId, shortLivedAccessToken: $shortLivedAccessToken)
  }
`;

export const getProductItemMappings = gql`
  query productItemIntegrationMappings($data: GetProductItemIntegrationMappingsArgs!) {
    productItemIntegrationMappings(data: $data) {
      id
      metadataExternalId
      metadata
      lastSyncedAt
      integration {
        id
      }
      externalProductInfo {
        productName
        productPreviewUrl
        productVariantName
      }
      errorMessage
    }
  }
`;

export const createProductItemMapping = gql`
  mutation createItemMappingFromIntegration(
    $url: String!
    $productItemId: ID!
    $integrationId: ID!
    $metadata: JSONObject
  ) {
    createItemMappingFromIntegration(
      url: $url
      productItemId: $productItemId
      integrationId: $integrationId
      metadata: $metadata
    ) {
      createdMapping {
        id
        metadata
        metadataExternalId
        lastSyncedAt
        externalProductInfo {
          productName
          productPreviewUrl
          productVariantName
        }
      }
      productVariants {
        metadataExternalId
        title
        productVariantPreviewUrl
        hasMapping
      }
    }
  }
`;

export const createProductItemMappingByExternalId = gql`
  mutation createItemMappingByExternalId($productItemId: ID!, $integrationId: ID!, $metadataExternalId: String!) {
    createItemMappingByExternalId(
      productItemId: $productItemId
      integrationId: $integrationId
      metadataExternalId: $metadataExternalId
    ) {
      id
      metadataExternalId
      lastSyncedAt
      externalProductInfo {
        productName
        productPreviewUrl
        productVariantName
      }
    }
  }
`;

export const deleteProductItemIntegrationMapping = gql`
  mutation deleteProductItemIntegrationMapping($id: ID!) {
    deleteProductItemIntegrationMapping(id: $id)
  }
`;

export const exportProductItemToIntegration = gql`
  mutation exportProductItemToIntegration($productItemId: ID!, $integrationId: ID!) {
    exportProductItemToIntegration(productItemId: $productItemId, integrationId: $integrationId)
  }
`;

export const getIntegrationsListWithProposedMappings = gql`
  query getIntegrationsListWithProposedMappings(
    $catalogId: ID!
    $options: getIntegrationsListWithProposedMappingsOptions
  ) {
    getIntegrationsListWithProposedMappings(catalogId: $catalogId, options: $options) {
      integrationId
      proposedMappings {
        productTypeId
        metadataExternalId
      }
    }
  }
`;

export const getIntegrationsSystemFields = gql`
  query getIntegrationsSystemFields($type: IntegrationTypes!) {
    getIntegrationsSystemFields(type: $type) {
      id
      name
      type
    }
  }
`;

export const getIntegrationsRequiredFields = gql`
  query getIntegrationsRequiredFields($type: IntegrationTypes!) {
    getIntegrationsRequiredFields(type: $type) {
      id
      name
      type
    }
  }
`;

export const checkIfFieldCanBeDeleted = gql`
  query checkIfFieldCanBeDeleted($productTypeFieldId: ID!) {
    checkIfFieldCanBeDeleted(productTypeFieldId: $productTypeFieldId)
  }
`;

export const integrationHealthCheck = gql`
  query integrationHealthCheck($id: ID!) {
    integrationHealthCheck(id: $id)
  }
`;
export const getFieldMappingSuggestions = gql`
  query getFieldMappingSuggestions($externalProductTypeId: ID!, $integrationId: ID!, $internalProductTypeId: ID!) {
    getFieldMappingSuggestions(
      externalProductTypeId: $externalProductTypeId
      integrationId: $integrationId
      internalProductTypeId: $internalProductTypeId
    ) {
      internalId
      externalId
    }
  }
`;

export const generateText = gql`
  mutation generateText($productItemFieldsData: [GeneratedTextInput!]!, $generatedTextType: GeneratedTextType!) {
    generateText(productItemFieldsData: $productItemFieldsData, generatedTextType: $generatedTextType) {
      generatedText
    }
  }
`;
