export function truncateText(options: { text?: string; maxLength: number }) {
  const { text, maxLength } = options;

  if (!text) return;

  if (text.length > maxLength) {
    return text.slice(0, maxLength - 3) + '...';
  }
  return text;
}

export const MAX_SIDEBAR_SUBTITLE_LENGTH = 82;
