import React, { ChangeEvent, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormField } from 'src/components/Forms/styled';
import { EmailInputProps } from 'src/components/Forms/types';
import { validateTextFieldValue } from 'src/helpers/validationCheck';

const EmailInput = ({ shouldFocus }: EmailInputProps) => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext();

  const [isBlur, setIsBlur] = useState<boolean>(false);
  const [readOnly, setReadOnly] = useState<boolean>(true);

  return (
    <FormField
      {...register('email', {
        onChange: (e: ChangeEvent<HTMLInputElement>) => {
          setValue('email', validateTextFieldValue(e.target.value));
        },
      })}
      fullWidth
      label={t('enterEmail')}
      variant="standard"
      autoComplete="email"
      error={!!errors.email}
      helperText={isBlur ? t(errors?.email?.message as string) : ''}
      onFocus={() => {
        setIsBlur(false);
        setReadOnly(false);
      }}
      onBlur={() => {
        setIsBlur(true);
        setReadOnly(false);
      }}
      inputProps={{
        readOnly,
        'data-testid': 'email',
        autoFocus: shouldFocus,
      }}
      name="email"
    />
  );
};

export default EmailInput;
