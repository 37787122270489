import React, { useState, ChangeEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { makeStyles, TextFieldProps } from '@material-ui/core';

import { FormField } from 'src/components/Forms/styled';
import { TextInputProps } from 'src/components/Forms/types';
import { validateTextFieldValue } from 'src/helpers/validationCheck';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiInputBase-input': {
      padding: '12px 20px',
    },
  },
}));

const TextInput = ({
  isStandard,
  shouldFocus,
  helperTextError,
  readOnly = false,
  ...props
}: TextFieldProps & TextInputProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext();

  const { error, name, inputNameTestId, ...other } = props;

  const [isBlur, setIsBlur] = useState<boolean>(false);

  const errorMessage = (name && t(errors[name]?.message as string)) || helperTextError;
  const hasError = (name && !!errors[name]) || error;
  const fieldName = `${name}` as const; // Field name have to be passed like this because of TS restriction for register method in react-hook-form lib

  return (
    <FormField
      {...register(fieldName, {
        onChange: (e: ChangeEvent<HTMLInputElement>) => {
          setValue(fieldName, validateTextFieldValue(e.target.value));
        },
      })}
      fullWidth
      error={Boolean(hasError)}
      helperText={isBlur ? errorMessage : ''}
      variant={isStandard ? 'standard' : 'outlined'}
      onFocus={() => setIsBlur(false)}
      onBlur={() => setIsBlur(true)}
      inputProps={{
        'data-testid': inputNameTestId,
        autoFocus: shouldFocus,
        readOnly,
      }}
      className={isStandard ? '' : classes.root}
      {...other}
    />
  );
};

export default TextInput;
