import { Box, IconButton, TableBody, TableContainer, TableHead } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';

import Button from 'src/components/Button';
import { theme } from 'src/theme';

import Iconography from '../Iconography';

import { statusType } from './functions';

export const StyledTableContainer = styled(TableContainer)`
  display: table;
  width: 100%;
`;

export const StyledTableHead = styled(TableHead)`
  .MuiTableCell-root {
    background-color: ${({ theme: { palette } }) => palette.common.white};
    font-weight: 700;
    border-bottom: 1px solid;
    border-color: ${({ theme: { palette } }) => palette.background.default};
    color: ${({ theme: { palette } }) => palette.text.primary};

    :nth-of-type(1) {
      width: 25%;
    }
    :nth-of-type(2) {
      width: 20%;
    }
    :nth-of-type(3) {
      width: 25%;
    }
    :nth-of-type(4) {
      width: 22%;
    }
    :nth-of-type(5) {
      width: 8%;
    }
  }
`;

export const StyledTableBody = styled(TableBody)`
  .MuiTableCell-root {
    border-bottom: 1px solid ${({ theme: { palette } }) => palette.background.default};
    font-weight: 500;
    font-size: 15px;
    color: ${({ theme: { palette } }) => palette.text.secondary};
  }
`;

export const StyledIconButton = styled(IconButton)`
  padding: 0;
  svg {
    width: 7px;
    height: 10px;

    &:hover {
      fill: ${({ theme }) => theme.palette.text.primary};
    }
  }
`;

export const UnfoldIcon = styled(Iconography)`
  width: 19px;
  height: 19px;
  margin-left: 5px;
  color: ${({ theme: { palette } }) => palette.text.disabled};

  :hover {
    cursor: pointer;
    color: ${({ theme: { palette } }) => palette.primary.main};
  }
`;

export const TableLoaderWrapper = styled(Box)`
  position: fixed;
  bottom: 28px;
  display: flex;
  justify-content: center;
  width: calc(100% - 200px);
`;

export const StyledHeaderLabelButton = styled(Button)`
  font-size: 14px;
  line-height: 15px;
  font-weight: 700;
  color: ${({ theme: { palette } }) => palette.text.primary};
  min-width: min-content;
  padding: 0;

  &:hover {
    box-shadow: none;
    background-color: transparent;
  }

  &.Mui-focusVisible {
    border: ${({ theme: { palette } }) => `1px solid ${palette.text.primary}`};
    border-radius: 3px;
    box-shadow: none;
  }
`;

export const styles = {
  root: {
    color: statusType('status', {
      green: theme.palette.success.main,
      red: theme.palette.secondary.main,
      yellow: theme.palette.warning.main,
    }),
  },
};
