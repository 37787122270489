import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Box, experimentalStyled as styled } from '@material-ui/core';
import { v4 as uuid } from 'uuid';

import { FieldSystemName, FieldType } from 'src/utils/gql';

import MultiTypeInput from '../MultiTypeInput';
import Popup, { PopupProps } from '../Popup';

const SkuContainer = styled(Box)`
  margin: 31px auto 0;
  width: 386px;
  height: 91px;

  .MuiFormControl-root {
    height: 57px;
  }
`;

interface CopyProductItemPopupProps extends PopupProps {
  onClose: () => void;
  initialValue?: string;
  copyProductItem: (sku: string) => Promise<void>;
}

const CopyProductItemPopup = ({
  open,
  onClose,
  copyProductItem,
  initialValue,
  loadingOnMainButton,
}: CopyProductItemPopupProps) => {
  const { t } = useTranslation();
  const {
    control,
    reset,
    watch,
    setValue,
    formState: { isValid },
  } = useFormContext();
  const sku = watch('sku');

  const handleClose = () => {
    onClose();
    reset();
  };

  const handleMainButtonClick = async () => {
    await copyProductItem(sku).then(handleClose);
  };

  useEffect(() => {
    if (open) {
      setValue('sku', `${initialValue}_[duplicated]`, { shouldValidate: true });
    }
  }, [initialValue, setValue, open]);

  return (
    <Popup
      open={open}
      mainTitle={t('productItemsPage.popupCloneItem.title')}
      titleDescription={t('productItemsPage.popupCloneItem.contentText')}
      mainButtonText={t('productItemsPage.popupCloneItem.mainButtonText')}
      secondaryButtonText={t('productItemsPage.popupCloneItem.secondaryButtonText')}
      onMainButtonClick={handleMainButtonClick}
      onSecondaryButtonClick={handleClose}
      onClose={handleClose}
      loadingOnMainButton={loadingOnMainButton}
      mainButtonDisabled={!isValid}
      actionsMarginTop="0"
      headerMarginAbsence
      descriptionTextMaxWidth="390px"
    >
      <SkuContainer>
        <Controller
          name="sku"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <MultiTypeInput
              onManualChange={onChange}
              manualValue={value}
              label={t(`productType.systemNames.${FieldSystemName.Sku}`)}
              type={FieldType.String}
              manualError={!!error}
              manualHelperText={t(error?.message as string)}
              inputIdentifier={uuid()}
            />
          )}
        />
      </SkuContainer>
    </Popup>
  );
};

export default CopyProductItemPopup;
