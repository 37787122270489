import React from 'react';
import { useTranslation } from 'react-i18next';

import { PARSING_FILE_STATE } from 'src/constants';
import { CsvFileInfo } from 'src/hooks/useImportCsv';

import InitialItemsPreviewScreen from './InitialItemsPreviewScreen';
import ItemsPreview from './ItemsPreview';
import TypesPreview, { ParsingTypesInfo } from './TypePreview';

export interface PreviewFieldProps {
  setViewAllItemsPath: (viewAllItemsPath: string) => void;
  parsingTypesInfo: ParsingTypesInfo;
  selectedTypeInfo?: CsvFileInfo | null;
}

const PreviewField = ({ selectedTypeInfo, parsingTypesInfo, setViewAllItemsPath }: PreviewFieldProps) => {
  const { t } = useTranslation();

  if (selectedTypeInfo?.state === PARSING_FILE_STATE.UploadingError) {
    return <InitialItemsPreviewScreen description={t(`initialItemsPreviewScreen.uploadingErrorDescription`)} />;
  }

  return selectedTypeInfo ? (
    <ItemsPreview selectedTypeInfo={selectedTypeInfo} setViewAllItemsPath={setViewAllItemsPath} />
  ) : (
    <TypesPreview parsingTypesInfo={parsingTypesInfo} />
  );
};

export default PreviewField;
