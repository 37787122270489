import React from 'react';

import { Box } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';

const MainBox = styled(Box)`
  position: absolute;
  top: 20px;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  filter: blur(24px);
`;

const OrangeBox = styled(Box)`
  position: absolute;
  z-index: 2;
  background-color: #f4764e;
  box-shadow: 0px 0px 20px 20px #f4764e;
`;

const CoralBox = styled(Box)`
  z-index: 1;
  position: absolute;
  background-color: #ed868b;
  box-shadow: 0px 0px 10px 10px #ed868b;
`;

const BlueBox = styled(Box)`
  position: absolute;
  background-color: #6369fd;
  box-shadow: 0px 0px 20px 20px #6369fd;
`;

const YellowBox = styled(Box)`
  position: absolute;
  z-index: 3;
  background-color: #efa105;
  box-shadow: 0px 0px 10px 10px #efa105;
`;

const GreyBox = styled(Box)`
  position: absolute;
  z-index: 2;
  background-color: #8390aa;
  box-shadow: 0px 0px 10px 10px #8390aa;
`;

const RedBox = styled(Box)`
  position: absolute;
  z-index: 2;
  background-color: #e24b4b;
  box-shadow: 0px 0px 20px 20px #e24b4b;
`;

const SetNewPasswordImagebackground = () => {
  return (
    <MainBox>
      <BlueBox width="70px" top="130px" height="120px" borderRadius="50%" />

      <BlueBox width="70px" top="120px" left="50px" height="140px" borderRadius="50%" />

      <BlueBox width="70px" top="110px" left="90px" height="140px" borderRadius="50%" />

      <BlueBox width="70px" top="100px" left="130px" height="140px" borderRadius="50%" />

      <BlueBox width="70px" top="90px" left="170px" height="140px" borderRadius="50%" />

      <BlueBox width="70px" top="80px" left="230px" height="90px" borderRadius="50%" />

      <BlueBox width="70px" top="80px" left="270px" height="200px" borderRadius="50%" />

      <BlueBox width="70px" top="90px" left="350px" height="240px" borderRadius="50%" />

      <BlueBox width="70px" top="80px" left="390px" height="240px" borderRadius="50%" />

      <BlueBox width="70px" top="80px" left="430px" height="240px" borderRadius="50%" />

      <BlueBox width="140px" top="140px" left="410px" height="140px" borderRadius="50%" />

      <BlueBox width="90px" top="170px" left="175px" height="90px" borderRadius="50%" />

      <OrangeBox width="110px" top="70px" left="345px" height="150px" borderRadius="50%" />

      <OrangeBox width="30px" top="105px" left="5px" height="50px" borderRadius="50%" />

      <OrangeBox width="40px" top="200px" left="445px" height="30px" borderRadius="50%" />

      <YellowBox width="40px" top="40px" left="375px" height="30px" borderRadius="50%" />

      <CoralBox
        width="290px"
        top="190px"
        left="225px"
        height="100px"
        borderRadius="40%"
        sx={{ transform: 'rotate(16deg)' }}
      />

      <GreyBox width="20px" top="220px" left="485px" height="90px" borderRadius="50%" />

      <RedBox width="100px" top="200px" left="5px" height="80px" sx={{ transform: 'rotate(-16deg)' }} />

      <RedBox width="120px" top="245px" left="35px" height="30px" />
    </MainBox>
  );
};

export default SetNewPasswordImagebackground;
