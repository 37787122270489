import React from 'react';

import { Box, Skeleton } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { keyframes } from '@mui/system';

const DEFAULT_MEDIA_SKELETONS_COUNT = 7;

const opacity = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Container = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  background: theme.palette.background.default,
  borderRadius: 2,
  boxShadow: '0px 2px 10px rgba(46, 96, 170, 0.25)',
  animation: `${opacity} 1s ease`,
  minHeight: '159px',
}));

interface SkeletonMediaProps {
  mediaCount?: number;
}

const SkeletonMedia = ({ mediaCount }: SkeletonMediaProps) => (
  <>
    {new Array(mediaCount ?? DEFAULT_MEDIA_SKELETONS_COUNT).map((_, idx) => (
      <Container key={idx} data-testid="media-skeleton">
        <Skeleton className="rect" variant="rectangular" />
      </Container>
    ))}
  </>
);

export default SkeletonMedia;
