import React, { useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useLazyQuery } from '@apollo/client';
import { Box } from '@material-ui/core';

import { checkIsCatalogEmpty, Query, QueryGetCatalogByIdArgs } from 'src/utils/gql';

import InitialItemsPreviewScreen from './InitialItemsPreviewScreen';

export interface ParsingTypesInfo {
  totalFilesCount: number;
  uploadedFilesCount: number;
  parsedFilesCount: number;
  notUploadedFiles: string[];
  notParsedFiles: string[];
}

const TypePreview = ({
  parsingTypesInfo: { totalFilesCount, parsedFilesCount, uploadedFilesCount, notParsedFiles, notUploadedFiles },
}: {
  parsingTypesInfo: ParsingTypesInfo;
}) => {
  const { t } = useTranslation();
  const { id: catalogId } = useParams<{ id: string }>();

  const parsingCompleted = useMemo(
    () => totalFilesCount === parsedFilesCount + notUploadedFiles.length + notParsedFiles.length,
    [notParsedFiles.length, notUploadedFiles.length, parsedFilesCount, totalFilesCount],
  );
  const parsingStarted = !!uploadedFilesCount;
  const parsingSuccess = parsingCompleted && parsedFilesCount === totalFilesCount;

  const [checkIsCatalogEmptyQuery] = useLazyQuery<Pick<Query, 'getCatalogById'>, QueryGetCatalogByIdArgs>(
    checkIsCatalogEmpty,
    { fetchPolicy: 'network-only' },
  );

  useEffect(() => {
    if (parsingCompleted && parsedFilesCount) {
      checkIsCatalogEmptyQuery({
        variables: {
          id: catalogId,
        },
      });
    }
  }, [parsedFilesCount, checkIsCatalogEmptyQuery, catalogId, parsingCompleted]);

  const startedScreenLabel = parsingCompleted
    ? parsingSuccess
      ? 'successScreen'
      : 'completedScreen'
    : parsingStarted
    ? 'parsingStartedScreen'
    : 'uploadStartedScreen';

  const currentResult: string[] = [];

  if (!parsingCompleted) {
    currentResult.push(
      t('initialItemsPreviewScreen.uploadingResult', {
        uploaded: uploadedFilesCount,
        uploading: totalFilesCount,
      }),
    );

    if (parsingStarted) {
      currentResult.push(
        t('initialItemsPreviewScreen.parsingStartedScreen.parsingResult', {
          parsed: parsedFilesCount,
          inParsing: totalFilesCount,
        }),
      );
    }
  }

  return (
    <Box height="100%">
      <InitialItemsPreviewScreen
        title={t(`initialItemsPreviewScreen.${startedScreenLabel}.title`)}
        description={t(`initialItemsPreviewScreen.${startedScreenLabel}.description`)}
        currentResult={currentResult}
        notUploadedFiles={notUploadedFiles}
        notParsedFiles={notParsedFiles}
      />
    </Box>
  );
};

export default TypePreview;
