export interface SharedCatalogueSubscription {
  statusText: string;
  textColor: string;
  action?: string;
}

export interface SharedCataloguesSubscriptions {
  [key: string]: SharedCatalogueSubscription;
}

export enum SharedCataloguesSubscriptionsActions {
  request = 'request',
  cancel = 'cancel',
  deleteUser = 'delete user',
}

export enum SORT_TYPE {
  asc = 'ASC',
  desc = 'DESC',
}
