import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { FIRST_DASHBOARD_PATHNAME, CATALOGS_PATH } from '../../constants/routeSources';

import BreadCrumb, { MainBreadCrumbsProps } from './BreadCrumb';

const HomeBreadCrumb = ({ last }: MainBreadCrumbsProps) => {
  const { t } = useTranslation();

  return (
    <BreadCrumb
      iconName="home"
      link={`/${FIRST_DASHBOARD_PATHNAME}/${CATALOGS_PATH}`}
      last={last}
      text={t('breadcrumbs.home')}
    />
  );
};

export default memo(HomeBreadCrumb);
