import { useState } from 'react';

import { useMutation } from '@apollo/client';
import axios, { AxiosRequestConfig } from 'axios';

import { getSignedUrlsFromAws, Mutation, MutationGetSignedUrlsArgs } from 'src/utils/gql';
import { UploadingFile } from 'src/views/Catalogs/ProductItems/types';

export interface FilesUploadState {
  [x: string]: { name: string; uploadPercentage: number; error?: boolean };
}

export interface UploadFileResponseBody {
  fileId: string;
  config: AxiosRequestConfig;
  status: number;
  statusText: string;
  request?: XMLHttpRequest;
  headers: {
    'content-length': string;
  };
}

export interface UploadFileResponse {
  isError: boolean;
  response: UploadFileResponseBody;
}

const useUpload = () => {
  const [getSignedUrls] = useMutation<Pick<Mutation, 'getSignedUrls'>, MutationGetSignedUrlsArgs>(getSignedUrlsFromAws);

  const [loading, setLoading] = useState<boolean>(false);
  const [filesUploadState, setFilesUploadState] = useState<FilesUploadState>({});

  const uploadFilesToS3 = async (file: File, id: string, signedUrl: string): Promise<UploadFileResponse> => {
    const options = {
      onUploadProgress: (progressEvent: ProgressEvent) => {
        setLoading(true);
        const { loaded, total } = progressEvent;
        const percent = Math.floor((loaded * 100) / total);

        setFilesUploadState((prevState: FilesUploadState) => ({
          ...prevState,
          [file.name]: { name: file.name, uploadPercentage: percent },
        }));
      },
      headers: {
        'Content-Type': file.type,
      },
    };

    try {
      const success = await axios.put(signedUrl, file, options);
      setFilesUploadState((prevState: FilesUploadState) => ({
        ...prevState,
        [file.name]: { ...prevState[file.name], error: false },
      }));

      return { isError: false, response: { ...success, fileId: id } };
    } catch (error) {
      setFilesUploadState((prevState: FilesUploadState) => ({
        ...prevState,
        [file.name]: { ...prevState[file.name], error: true },
      }));

      return { isError: true, response: { ...(error as UploadFileResponseBody), fileId: id } };
    }
  };

  const upload = async (
    uploadingFiles: UploadingFile[],
  ): Promise<{ loadedFilesUrls: string[]; unloadedFiles: UploadingFile[] }> => {
    const { data } = await getSignedUrls({
      variables: {
        files: uploadingFiles.map(({ name, file }) => ({
          filename: name || '',
          filetype: file?.type || 'image/jpeg',
        })),
      },
    });

    let loadedFilesUrls: string[] = [];
    let unloadedFiles: UploadingFile[] = [];

    if (data) {
      const signedUrls = data.getSignedUrls;
      const filesWithSignedUrls = uploadingFiles.map((uploadingFileData, idx) => ({
        file: uploadingFileData.file,
        id: uploadingFileData.id,
        signedUrl: signedUrls[idx],
        uploadingFileData,
      }));

      const responses = await Promise.all(
        filesWithSignedUrls
          .reduce(
            (
              accumulator: { responsePromise: Promise<UploadFileResponse>; uploadingFileData: UploadingFile }[],
              { file, id, signedUrl, uploadingFileData },
            ) => {
              if (file && signedUrl) {
                return [...accumulator, { responsePromise: uploadFilesToS3(file, id, signedUrl), uploadingFileData }];
              }
              unloadedFiles.push(uploadingFileData);

              return accumulator;
            },
            [],
          )
          .map(async ({ responsePromise, uploadingFileData }) => ({
            responseBody: await responsePromise,
            uploadingFileData,
          })),
      );

      loadedFilesUrls = responses
        .filter(({ responseBody }) => !responseBody.isError)
        .map(({ responseBody }) => responseBody.response.config.url?.split('?')[0] || '');

      const errorUploadedFiles = responses
        .filter(({ responseBody }) => responseBody.isError)
        .map(({ uploadingFileData }) => uploadingFileData);

      unloadedFiles = unloadedFiles.concat(errorUploadedFiles);
    }

    setLoading(false);

    return { loadedFilesUrls, unloadedFiles };
  };

  return { upload, loading, filesUploadState };
};

export default useUpload;
