import { createContext } from 'react';

import { CatalogsContextType } from './types';

const defaultValue: CatalogsContextType = {
  catalogs: [],
  catalogsJobsMap: {},
  loadingGet: false,
  loadingDelete: false,
  loadingCreate: false,
  loadingCreateOnboardingCatalog: false,
  hasMore: false,
  createCatalog: () => Promise.resolve(null),
  onCreateOnboardingCatalog: () => Promise.resolve(null),
  handleFetchMore: () => Promise.resolve(),
  handleDelete: () => Promise.resolve(),
  setFilters: () => null,
  getCatalogJob: () => null,
  updateCatalogsJobsMap: () => null,
  deleteCatalogsJobs: () => null,
  updateExportType: () => null,
  currentExportType: [],
  updateCurrentExport: () => null,
};

const CatalogsContext = createContext<CatalogsContextType>(defaultValue);

CatalogsContext.displayName = 'CatalogsContext';

export default CatalogsContext;
