import React, { createContext, ReactNode, useCallback, useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useLazyQuery, useQuery } from '@apollo/client';

import { FIRST_DASHBOARD_PATHNAME, SHARED_CATALOGS } from 'src/constants/routeSources';
import {
  getCatalogInfo,
  getProductItemsPreview,
  getProductTypesPreview,
  Query,
  QueryGetProductItemsPreviewArgs,
  QueryGetProductTypesPreviewArgs,
} from 'src/utils/gql';

import { SharedCatalogInfo, SharedCatalogsContextType, stub } from './types';

const defaultValue: SharedCatalogsContextType = {
  sharedCatalogInfo: {
    catalogId: '',
    catalogName: '',
    catalogOwnerId: '',
    catalogOwnerName: '',
  },
  sharedCatalogProducts: {
    productTypes: [],
    totalCount: 0,
  },
  loadingProducts: false,
  getProductName: stub,
  getProductItem: stub,
  catalogDataLoading: false,
};

export const SharedCatalogsContext = createContext(defaultValue);

SharedCatalogsContext.displayName = 'SharedCatalogsContext';

interface SharedCatalogsProviderProps {
  children: ReactNode;
}

export const SharedCatalogsProvider = ({ children }: SharedCatalogsProviderProps) => {
  const history = useHistory();
  const { catalogId } = useParams<{
    catalogId: string;
  }>();

  const [sharedCatalogInfo, setSharedCatalogInfo] = useState<SharedCatalogInfo>(defaultValue.sharedCatalogInfo);

  const { loading: catalogDataLoading } = useQuery<Pick<Query, 'getCatalogById'>>(getCatalogInfo, {
    variables: {
      id: catalogId,
    },
    fetchPolicy: 'cache-first',
    onCompleted: ({ getCatalogById: { id, name, user, subscriptions } }) => {
      setSharedCatalogInfo({
        catalogId: id,
        catalogName: name,
        catalogOwnerId: user.id,
        catalogOwnerName: user.name,
        subscription: subscriptions?.[0],
      });
    },
    onError: () => history.push(`/${FIRST_DASHBOARD_PATHNAME}/${SHARED_CATALOGS}`),
  });

  const { data: sharedCatalogProducts, loading: loadingProducts } = useQuery<
    Pick<Query, 'getProductTypesPreview'>,
    QueryGetProductTypesPreviewArgs
  >(getProductTypesPreview, {
    fetchPolicy: 'cache-first',
    variables: {
      catalogId,
    },
  });

  const [getItems, { data: sharedCatalogItems }] = useLazyQuery<
    Pick<Query, 'getProductItemsPreview'>,
    QueryGetProductItemsPreviewArgs
  >(getProductItemsPreview, {
    onError: () => history.push(`/${FIRST_DASHBOARD_PATHNAME}/${SHARED_CATALOGS}`),
  });

  const getProductItem = useCallback(
    (productTypeId: string, productItemId: string) => {
      getItems({
        variables: {
          productTypeId,
        },
      });

      return sharedCatalogItems?.getProductItemsPreview.productItems.find(({ id }) => id === productItemId);
    },
    [sharedCatalogItems, getItems],
  );

  const getProductName = (productTypeId: string) => {
    return (
      sharedCatalogProducts?.getProductTypesPreview.productTypes.find(
        ({ productType }) => productType.id === productTypeId,
      )?.productType.name || ''
    );
  };

  return (
    <SharedCatalogsContext.Provider
      value={{
        sharedCatalogInfo,
        sharedCatalogProducts: sharedCatalogProducts?.getProductTypesPreview,
        loadingProducts,
        getProductName,
        getProductItem,
        catalogDataLoading,
      }}
    >
      {children}
    </SharedCatalogsContext.Provider>
  );
};

const useSharedCatalogs = () => {
  return useContext(SharedCatalogsContext);
};

export default useSharedCatalogs;
