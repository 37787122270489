import { Box, TextField } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';

export const FormWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledTextField = styled(TextField)`
  max-width: 278px;
  width: 100%;

  @media (max-width: 580px) {
    max-width: none;
  }
`;

export const SendButtonWrapper = styled(Box)`
  margin-top: 40px;
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const ContactFieldsWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 27px;

  @media (max-width: 580px) {
    flex-direction: column;
  }
`;
