import { ChangeEvent } from 'react';

import { AuthInput, RegInput, UpdatePasswordInput, IntegrationTypes } from 'src/utils/gql';
import { IntegrationAction } from 'src/views/UserSettings/types';

export interface ShopifySignupInput {
  email: string;
  storeName: string;
}

export interface ForgotPasswordInput {
  email: string;
}

export interface ResendEmailInput {
  email: string;
}

export interface SetNewPasswordInput {
  password: string;
}

export interface PasswordInputProps {
  hasRequirements?: boolean;
  shouldFocus?: boolean;
}

export interface TextInputProps {
  integrationType?: IntegrationTypes.Woocommerce | IntegrationTypes.Shopify;
  shouldFocus?: boolean;
  isStandard?: boolean;
  helperTextError?: string | JSX.Element;
  inputNameTestId?: string;
  readOnly?: boolean;
}

export interface EmailInputProps {
  shouldFocus?: boolean;
}

export interface PasswordInputRequirementsProps {
  password: string;
  isTouched?: boolean;
}

export interface GenericFormProps<VariableType> {
  onSubmit: (variables: VariableType) => Promise<void>;
  onError?: (message: string) => void;
  loading?: boolean;
}

export type ForgotPasswordFormProps = GenericFormProps<string>;

export type SignInFormProps = GenericFormProps<AuthInput>;

export type SetNewPasswordFormProps = GenericFormProps<UpdatePasswordInput>;

export type SignUpFormProps = GenericFormProps<RegInput>;

export interface ShopifySignupFormProps extends GenericFormProps<ShopifySignupInput> {
  isLoggedIn?: boolean;
}

export interface AddEditIntegrationsProps {
  integrationId?: string;
  action: IntegrationAction;
  onChangeDefault: (event: ChangeEvent<HTMLInputElement>) => void;
  isDefaultIntegration: boolean;
}

export enum NumberValueCalcActions {
  up = 'up',
  down = 'down',
}

export enum KeyboardKeyNames {
  ARROW_UP = 'ArrowUp',
  ARROW_DOWN = 'ArrowDown',
}
