import React, { ReactNode } from 'react';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@material-ui/core';

import CSVFilesImg from 'src/components/Images/CSVFiles.svg';
import { FILE_EXTENSION } from 'src/constants';
import { useSnackbar } from 'src/providers/snackbar';

import { InnerBox, StyledBackdrop } from './styled';

interface FullPageDropzoneProps {
  children: ReactNode;
  onDropFiles: (files: File[]) => void;
}

const FullPageDropzone = ({ children, onDropFiles }: FullPageDropzoneProps) => {
  const { t } = useTranslation();
  const snackbar = useSnackbar();

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: [NativeTypes.FILE],
    drop(acceptedFiles: { files: File[] }) {
      const hasIncorrectFiles = !acceptedFiles.files.every((file) =>
        [FILE_EXTENSION.csv, FILE_EXTENSION.csvWin].includes(file.type),
      );
      if (hasIncorrectFiles) {
        snackbar(t('dropzone.onlyCsvError'), 'error');
      } else {
        onDropFiles(acceptedFiles.files);
      }
    },
    collect: (monitor: { isOver: () => boolean; canDrop: () => boolean }) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const isDragActive = canDrop && isOver;

  return (
    <Box ref={drop}>
      <StyledBackdrop open={isDragActive}>
        <InnerBox>
          <img src={CSVFilesImg} alt="files" />
          <Typography variant="h2" mt="33px" color="common.white">
            {t('dropzone.uploadFiles')}
          </Typography>
        </InnerBox>
      </StyledBackdrop>
      {children}
    </Box>
  );
};

export default FullPageDropzone;
