import { IconButton, TableBody, TableContainer, TableHead, Typography } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';

export const NoInvoicesTypography = styled(Typography)`
  margin-top: 50px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;

  color: ${({ theme: { palette } }) => palette.text.secondary};
`;

export const StyledTableContainer = styled(TableContainer)`
  height: 100%;
  padding-right: 8px;
  scrollbar-width: thin;

  .MuiTable-root {
    border-collapse: collapse;
  }
`;

export const StyledTableHead = styled(TableHead)`
  .MuiTableCell-root {
    font-weight: 700;
    padding: 16px 30px;
    border-bottom: 1px solid;
    border-color: ${({ theme: { palette } }) => palette.background.default};
    color: ${({ theme: { palette } }) => palette.text.primary};
    background: ${({ theme: { palette } }) => palette.common.white};

    :nth-of-type(1) {
      width: 25%;
    }
    :nth-of-type(2) {
      width: 25%;
    }
    :nth-of-type(3) {
      width: 20%;
    }
    :nth-of-type(4) {
      width: 10%;
    }
    :nth-of-type(5) {
      width: 10%;
    }
    :nth-of-type(6) {
      width: 10%;
    }
  }
`;

export const StyledTableBody = styled(TableBody)`
  .MuiTableRow-root {
    border-bottom: 1px solid;
    border-color: ${({ theme: { palette } }) => palette.background.default};

    :last-of-type {
      border-bottom: none;
    }
  }

  .MuiTableCell-root {
    border: none;
    font-weight: 500;
    font-size: 15px;
    padding: 25px 30px;
    color: ${({ theme: { palette } }) => palette.text.secondary};

    :nth-of-type(5) {
      .MuiButtonBase-root {
        color: ${({ theme: { palette } }) => palette.text.secondary};

        :hover {
          color: ${({ theme: { palette } }) => palette.text.primary};
        }
      }
    }
  }
`;

export const StyledIconButton = styled(IconButton)`
  padding: 0;
  color: ${({ theme: { palette } }) => palette.text.disabled};

  :hover {
    color: ${({ theme: { palette } }) => palette.text.primary};
  }

  svg {
    width: 8px;
    height: 8px;
  }
`;

export const CapitalizedStatus = styled(Typography)`
  text-transform: capitalize;
`;
