import { useState } from 'react';
import ReactPlayer from 'react-player';

export interface VideoMethodsAndProps {
  playVideo: (video: ReactPlayer | null) => void;
  showControls: boolean;
  changeControlsState: () => void;
}

export const useCustomMediaPlayerButtons = (): VideoMethodsAndProps => {
  const [showControls, setShowsControls] = useState<boolean>(false);

  const playVideo = (video: ReactPlayer | null) => {
    video?.getInternalPlayer()?.play();
    video?.getInternalPlayer('hls')?.play();
    video?.getInternalPlayer('dash')?.play();

    if (!showControls) setShowsControls(true);
  };

  const changeControlsState = () => {
    setShowsControls(false);
  };

  return { playVideo, showControls, changeControlsState };
};
