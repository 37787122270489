import { Button, Typography } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';

export const AddMoreButton = styled(Button)`
  display: flex;
  align-items: center;
  color: ${({ theme: { palette } }) => palette.secondary.main};

  :hover {
    box-shadow: none;
    background-color: transparent;
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: ${({ theme: { palette } }) => palette.secondary.main};
  }
  :focus {
    text-decoration: underline;
    text-decoration-color: ${({ theme: { palette } }) => palette.secondary.main};
  }
`;

export const AddMoreButtonTitle = styled(Typography)`
  color: ${({ theme: { palette } }) => palette.secondary.main};
  font-size: 15px;
  line-height: 18px;
`;
