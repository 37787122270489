export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JSONObject: any;
};

export type AddMedia = {
  altText?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['ID']>;
  mediaUsageId?: Maybe<Scalars['ID']>;
  orderNumber: Scalars['Float'];
  previewUrl: Scalars['String'];
  productItemId?: Maybe<Scalars['Int']>;
  type: MediaType;
  url: Scalars['String'];
};

export type AddProductTypeFieldInput = {
  defaultValue?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  required?: Maybe<Scalars['Boolean']>;
  showInFilters?: Maybe<Scalars['Boolean']>;
  showInPreview?: Maybe<Scalars['Boolean']>;
  systemName?: Maybe<Scalars['String']>;
  type?: Maybe<FieldType>;
};

export enum ApprovalStatus {
  Approved = 'approved',
  Declined = 'declined',
  Pending = 'pending'
}

export type AuditLog = {
  __typename?: 'AuditLog';
  action: Scalars['String'];
  createdAt: Scalars['DateTime'];
  entity: Scalars['String'];
  id: Scalars['Float'];
  metadata: Scalars['JSONObject'];
};

export enum AuditLogActionType {
  Create = 'CREATE',
  Delete = 'DELETE',
  Export = 'EXPORT',
  Import = 'IMPORT',
  Update = 'UPDATE'
}

export enum AuditLogEntity {
  Catalog = 'catalog',
  Integration = 'integration',
  PlanSubscription = 'planSubscription',
  ProductItem = 'productItem',
  ProductType = 'productType',
  User = 'user'
}


export type GoogleLogin = {
  code: Scalars['String'];
};

export type AuthInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  rememberMe?: Maybe<Scalars['Boolean']>;
};

export type AuthResponse = {
  __typename?: 'AuthResponse';
  token: Scalars['String'];
  user: User;
};

export type Base = {
  __typename?: 'Base';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
};

export type Catalog = {
  __typename?: 'Catalog';
  config: CatalogConfig;
  createdAt: Scalars['DateTime'];
  duplicatedSKUInfo: DuplicatedSkuInfo;
  id: Scalars['ID'];
  integrations?: Maybe<Array<Integration>>;
  isEmpty: Scalars['Boolean'];
  jobs: Array<CatalogJob>;
  keywords: Array<Scalars['String']>;
  lastIntegrationsJobs: Array<CatalogJob>;
  mappingsErrors: Array<IntegrationMappingError>;
  name: Scalars['String'];
  source: CatalogSource;
  status: Scalars['String'];
  subscriptions?: Maybe<Array<CatalogSubscription>>;
  syncable: Scalars['Boolean'];
  user: User;
};

export type CatalogConfig = {
  __typename?: 'CatalogConfig';
  catalog: Catalog;
  createdAt: Scalars['DateTime'];
  extraChargePercentage?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  paymentFrequency: Scalars['String'];
  price?: Maybe<Scalars['Float']>;
  privacy: CatalogPrivacy;
};

export type CatalogConfigInput = {
  extraChargePercentage?: Maybe<Scalars['Float']>;
  paymentFrequency: PaymentFrequency;
  price?: Maybe<Scalars['Float']>;
  privacy: CatalogPrivacy;
};

export type CatalogJob = {
  __typename?: 'CatalogJob';
  attempts?: Maybe<Scalars['Float']>;
  catalog: Catalog;
  catalogId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  endTime?: Maybe<Scalars['DateTime']>;
  enqueueTime: Scalars['DateTime'];
  error?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  integrationId: Scalars['ID'];
  startTime?: Maybe<Scalars['DateTime']>;
  status: CatalogJobStatus;
  type: CatalogJobType;
};

export enum CatalogJobStatus {
  Active = 'active',
  Fail = 'fail',
  Pause = 'pause',
  Pending = 'pending',
  Success = 'success'
}

export enum CatalogJobType {
  Export = 'export',
  Import = 'import'
}

export enum CatalogPrivacy {
  Private = 'private',
  Protected = 'protected',
  Public = 'public'
}

export enum CatalogSource {
  Csv = 'csv',
  Manually = 'manually',
  Shopify = 'shopify',
  Woocommerce = 'woocommerce',
  Facebook = 'Facebook + Instagram'
}

export enum CatalogStatus {
  Active = 'active',
  Inactive = 'inactive'
}

export type CatalogSubscription = {
  __typename?: 'CatalogSubscription';
  active: Scalars['Boolean'];
  approvalStatus: ApprovalStatus;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  subscriptionDate?: Maybe<Scalars['DateTime']>;
};

export enum CatalogType {
  My = 'my',
  Shared = 'shared',
  Subscribed = 'subscribed'
}

export type CreateAccountInquiryData = {
  description?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  name: Scalars['String'];
};

export type CreateCatalogInput = {
  integrationId?: Maybe<Scalars['ID']>;
  keywords?: Maybe<Array<Scalars['String']>>;
  name: Scalars['String'];
  source: CatalogSource;
};

export type CreateIntegrationInput = {
  isDefault: Scalars['Boolean'];
  settings: Scalars['JSONObject'];
  type: IntegrationTypes;
};

export type CreateIntegrationSpecificItemDataInput = {
  integrationId: Scalars['ID'];
  metadata: Scalars['JSONObject'];
};

export type CreateIntegrationsSpecificItemData = {
  createIntegrationSpecificItemDataInputs: Array<CreateIntegrationSpecificItemDataInput>;
  itemId: Scalars['ID'];
};

export type CreateItemMappingFromIntegration = {
  __typename?: 'CreateItemMappingFromIntegration';
  createdMapping?: Maybe<ProductItemMapping>;
  productVariants?: Maybe<Array<ProductVariant>>;
};

export type CreateMediaInput = {
  altText?: Maybe<Scalars['String']>;
  previewUrl?: Maybe<Scalars['String']>;
  productItemId?: Maybe<Scalars['Int']>;
  type: MediaType;
  url: Scalars['String'];
};

export type CreateMediaWithProductItemInput = {
  altText?: Maybe<Scalars['String']>;
  orderNumber: Scalars['Float'];
  previewUrl: Scalars['String'];
  productItemId?: Maybe<Scalars['Int']>;
  type: MediaType;
  url: Scalars['String'];
};

export type CreateOnboardingCatalog = {
  __typename?: 'CreateOnboardingCatalog';
  catalog: Catalog;
  productItems: Array<ProductItem>;
  productType: ProductType;
};

export type CreateOnboardingCatalogInput = {
  name: Scalars['String'];
  source: CatalogSource;
};

export type CreateOrUpdateProductTypeFieldInput = {
  defaultValue?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  required?: Maybe<Scalars['Boolean']>;
  showInFilters?: Maybe<Scalars['Boolean']>;
  showInPreview?: Maybe<Scalars['Boolean']>;
  systemName?: Maybe<Scalars['String']>;
  type?: Maybe<FieldType>;
};

export type CreateOrUpdateProductTypeFieldIntegrationMappingInput = {
  id?: Maybe<Scalars['ID']>;
  metadataExternalId: Scalars['ID'];
  productTypeFieldId?: Maybe<Scalars['ID']>;
  toSync: Scalars['Boolean'];
};

export type CreateProductFieldIntegrationMappingInput = {
  metadataExternalId?: Maybe<Scalars['ID']>;
  productTypeFieldId?: Maybe<Scalars['ID']>;
  toSync?: Maybe<Scalars['Boolean']>;
};

export type CreateProductItemInput = {
  mediaToAdd?: Maybe<Array<AddMedia>>;
  mediaToCreate?: Maybe<Array<CreateMediaWithProductItemInput>>;
  productTypeId: Scalars['Int'];
  status?: Maybe<ItemStatus>;
  values: Array<ProductItemValueInput>;
};

export type CreateProductTypeFieldIntegrationMappingInput = {
  integrationId: Scalars['ID'];
  metadataExternalId?: Maybe<Scalars['ID']>;
  productTypeFieldId?: Maybe<Scalars['ID']>;
  productTypeId: Scalars['ID'];
  toSync?: Maybe<Scalars['Boolean']>;
};

export type CreateProductTypeIntegrationMappingInput = {
  fieldMappings: Array<CreateProductFieldIntegrationMappingInput>;
  integrationId: Scalars['ID'];
  metadata?: Maybe<Scalars['JSONObject']>;
  metadataExternalId: Scalars['ID'];
  productTypeId: Scalars['ID'];
  toSync: Scalars['Boolean'];
};

export type CreatePublicApiKeyResponse = {
  __typename?: 'CreatePublicApiKeyResponse';
  createdAt: Scalars['DateTime'];
  expirationDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  key: Scalars['String'];
  name: Scalars['String'];
};

export type CreateStripeSubscriptionResponse = {
  __typename?: 'CreateStripeSubscriptionResponse';
  clientSecret: Scalars['String'];
  subscriptionId: Scalars['String'];
};

export enum CsvParseStatus {
  Completed = 'completed',
  Error = 'error',
  InProgress = 'inProgress',
  Waiting = 'waiting'
}


export type DuplicatedSku = {
  __typename?: 'DuplicatedSKU';
  itemId: Scalars['ID'];
  name: Scalars['String'];
  productTypeId: Scalars['ID'];
};

export type DuplicatedSkuInfo = {
  __typename?: 'DuplicatedSKUInfo';
  duplicatedSKU: Array<DuplicatedSku>;
  isDuplicated: Scalars['Boolean'];
};

export type EbayMerchantLocation = {
  __typename?: 'EbayMerchantLocation';
  key: Scalars['String'];
  name: Scalars['String'];
};

export enum EbayPolicies {
  Fulfillment = 'fulfillment',
  Payment = 'payment',
  Return = 'return'
}

export type EbayPolicy = {
  __typename?: 'EbayPolicy';
  id: Scalars['String'];
  name: Scalars['String'];
  type: EbayPolicies;
};

export type EmailToSupportInput = {
  companyName?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  email: Scalars['String'];
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
};

export type ExternalProductInfo = {
  __typename?: 'ExternalProductInfo';
  listingId?: Maybe<Scalars['ID']>;
  productName: Scalars['String'];
  productPreviewUrl: Scalars['String'];
  productVariantName?: Maybe<Scalars['String']>;
};

export type FieldMappingSuggestion = {
  __typename?: 'FieldMappingSuggestion';
  externalId: Scalars['ID'];
  internalId: Scalars['ID'];
};

export enum FieldSystemName {
  Description = 'description',
  Name = 'name',
  Price = 'price',
  Sku = 'sku'
}

export enum FieldType {
  Boolean = 'boolean',
  Multiselect = 'multiselect',
  Number = 'number',
  Price = 'price',
  Singleselect = 'singleselect',
  String = 'string'
}

export type FilterItem = {
  empty?: Maybe<Scalars['Boolean']>;
  productTypeFieldId: Scalars['ID'];
  type: FilterType;
  values: Array<Maybe<Scalars['String']>>;
};

export enum FilterType {
  Boolean = 'boolean',
  Options = 'options',
  Range = 'range'
}

export type GeneratedTextInput = {
  fieldName: Scalars['String'];
  fieldValue: Scalars['String'];
};

export type GeneratedTextResponse = {
  __typename?: 'GeneratedTextResponse';
  generatedText: Scalars['String'];
};

export enum GeneratedTextType {
  Description = 'DESCRIPTION',
  Name = 'NAME'
}

export type GetProductItemIntegrationMappingsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  productItemId: Scalars['ID'];
  withExternalProductInfo?: Maybe<Scalars['Boolean']>;
};

export type Integration = {
  __typename?: 'Integration';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  isDefault: Scalars['Boolean'];
  mappingsErrors: Array<IntegrationMappingError>;
  settings: Scalars['JSONObject'];
  type: IntegrationTypes;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type IntegrationMappingError = {
  __typename?: 'IntegrationMappingError';
  catalogId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['ID'];
  integrationId: Scalars['ID'];
  metadata?: Maybe<Scalars['JSONObject']>;
  productItemId?: Maybe<Scalars['ID']>;
  productTypeFieldId?: Maybe<Scalars['ID']>;
  productTypeId?: Maybe<Scalars['ID']>;
  reason: Reason;
  syncable: Scalars['Boolean'];
};

export type IntegrationProductType = {
  __typename?: 'IntegrationProductType';
  id: Scalars['ID'];
  integrationId: Scalars['ID'];
  name: Scalars['String'];
};

export type IntegrationProductTypeField = {
  __typename?: 'IntegrationProductTypeField';
  id: Scalars['ID'];
  name: Scalars['String'];
  type: FieldType;
};

export type IntegrationRegInput = {
  code: Scalars['String'];
  email: Scalars['String'];
  integrationType: CatalogSource;
  shop: Scalars['String'];
  storeName: Scalars['String'];
  token?: Maybe<Scalars['String']>;
};

export type IntegrationSpecificItemData = {
  __typename?: 'IntegrationSpecificItemData';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  integrationId: Scalars['ID'];
  metadata: Scalars['JSONObject'];
};

export enum IntegrationTypes {
  Csv = 'csv',
  Ebay = 'ebay',
  Facebook = 'facebook',
  Shopify = 'shopify',
  Woocommerce = 'woocommerce'
}

export type IntegrationWithUndefinedMappings = {
  __typename?: 'IntegrationWithUndefinedMappings';
  integrationId: Scalars['ID'];
  proposedMappings: Array<ProposedMapping>;
};

export type IsSkuDuplicatedInput = {
  id: Scalars['ID'];
  productItemId?: Maybe<Scalars['ID']>;
  value: Scalars['String'];
};

export enum ItemStatus {
  Active = 'active',
  Draft = 'draft'
}


export enum LimitType {
  Catalogs = 'catalogs',
  Integrations = 'integrations',
  Items = 'items',
  SharedCatalogs = 'sharedCatalogs',
  Subscriptions = 'subscriptions'
}

export type Media = {
  __typename?: 'Media';
  altText?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  previewUrl: Scalars['String'];
  type: MediaType;
  url: Scalars['String'];
};

export enum MediaType {
  Document = 'document',
  ExternalVideo = 'externalVideo',
  Image = 'image',
  Model3D = 'model3D',
  Video = 'video'
}

export type MediaUsage = {
  __typename?: 'MediaUsage';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  media: Media;
  orderNumber?: Maybe<Scalars['Float']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  activateUser: Scalars['Boolean'];
  addIntegrationToCatalog: Catalog;
  attachMediaToProductItem: ProductItem;
  bulkCreateProductTypeIntegrationMappings: Array<ProductTypeIntegrationMappingResponse>;
  bulkUpdateProductTypeIntegrationMappings: UpdateProductTypeIntegrationMappingResponse;
  cancelSubscription: Scalars['Boolean'];
  changeDefaultIntegration: Scalars['Boolean'];
  changeDefaultStripePaymentMethod: Scalars['Boolean'];
  completeOnboarding: Scalars['Boolean'];
  copyProductItem: ProductItem;
  createCatalog: Catalog;
  createIntegration: Integration;
  createIntegrationsSpecificItemData: Array<IntegrationSpecificItemData>;
  createItemMappingByExternalId: ProductItemMapping;
  createItemMappingFromIntegration: CreateItemMappingFromIntegration;
  createMedia: Media;
  createOnboardingCatalog: CreateOnboardingCatalog;
  createProductItem: ProductItem;
  createProductType: ProductType;
  createProductTypeFieldIntegrationMappings: Array<ProductTypeFieldIntegrationMapping>;
  createPublicApiKey: CreatePublicApiKeyResponse;
  createStripePaymentMethod: Scalars['Boolean'];
  createStripeSubscription: CreateStripeSubscriptionResponse;
  createSubscription: CatalogSubscription;
  deleteCatalog: Scalars['Boolean'];
  deleteIntegration: Scalars['Boolean'];
  deleteIntegrationFromCatalog: Scalars['Boolean'];
  deleteMedia: Scalars['Boolean'];
  deleteProductItemIntegrationMapping: Scalars['ID'];
  deleteProductItems: Scalars['Boolean'];
  deleteProductItemsByProductTypeId: Scalars['Boolean'];
  deleteProductType: Scalars['Boolean'];
  deleteProductTypeField: Scalars['Boolean'];
  deleteProductTypeFieldIntegrationMappings: Scalars['Boolean'];
  deleteProductTypeIntegrationMapping: Scalars['Boolean'];
  deleteProductTypes: Scalars['Boolean'];
  deletePublicApiKey: Scalars['ID'];
  deleteStripePaymentMethod: Scalars['Boolean'];
  detachMediaFromProductItem: ProductItem;
  exportCatalog: Catalog;
  exportCatalogToAllAttachedIntegrations: Scalars['Boolean'];
  exportProductItemToIntegration: Scalars['Boolean'];
  exportProductTypeToAllAttachedIntegrations: Scalars['Boolean'];
  exportProductTypeToAttachedIntegration: Scalars['Boolean'];
  generateText: GeneratedTextResponse;
  getSignedUrls: Array<Scalars['String']>;
  getSignedUrlsForCsv: Array<SignedUrlForCsvResponse>;
  integrationSignUp: AuthResponse;
  makeProductItemsDraft: Array<ProductItem>;
  resendRegistrationConfirmation: Scalars['Boolean'];
  sendEmailForResetPassword: Scalars['Boolean'];
  signIn: AuthResponse;
  signUp: User;
  googleLogin: AuthResponse;
  updateCatalog: Catalog;
  updateFacebookAccessToken: Scalars['Boolean'];
  updateIntegration: Scalars['Boolean'];
  updateIntegrationsSpecificItemData: Array<IntegrationSpecificItemData>;
  updateMediaAltText: Array<Media>;
  updatePassword: User;
  updatePlanSubscription: PlanSubscription;
  updateProductItem: ProductItem;
  updateProductItems: Array<ProductItem>;
  updateProductItemsByProductTypeId: Array<ProductItem>;
  updateProductType: ProductType;
  updateProductTypeField: ProductTypeField;
  updateProductTypeFieldIntegrationMappings: Array<ProductTypeFieldIntegrationMapping>;
  updateSubscription: CatalogSubscription;
  updateUserInfo: User;
  upgradePlan: Scalars['Boolean'];
  verifyLink: AuthResponse;
};


export type MutationActivateUserArgs = {
  email: Scalars['String'];
};


export type MutationAddIntegrationToCatalogArgs = {
  data: UpdateCatalogIntegrationInput;
};


export type MutationAttachMediaToProductItemArgs = {
  mediaIds: Array<Scalars['ID']>;
  productItemId: Scalars['ID'];
};


export type MutationBulkCreateProductTypeIntegrationMappingsArgs = {
  mappings: Array<CreateProductTypeIntegrationMappingInput>;
};


export type MutationBulkUpdateProductTypeIntegrationMappingsArgs = {
  mappings: Array<UpdateProductTypeIntegrationMappingInput>;
};


export type MutationCancelSubscriptionArgs = {
  subscriptionId: Scalars['ID'];
};


export type MutationChangeDefaultIntegrationArgs = {
  id: Scalars['ID'];
};


export type MutationChangeDefaultStripePaymentMethodArgs = {
  id: Scalars['ID'];
};


export type MutationCopyProductItemArgs = {
  productItemId: Scalars['ID'];
  sku?: Maybe<Scalars['String']>;
};


export type MutationCreateCatalogArgs = {
  data: CreateCatalogInput;
};


export type MutationCreateIntegrationArgs = {
  data: CreateIntegrationInput;
};


export type MutationCreateIntegrationsSpecificItemDataArgs = {
  data: CreateIntegrationsSpecificItemData;
};


export type MutationCreateItemMappingByExternalIdArgs = {
  integrationId: Scalars['ID'];
  metadataExternalId: Scalars['String'];
  productItemId: Scalars['ID'];
};


export type MutationCreateItemMappingFromIntegrationArgs = {
  integrationId: Scalars['ID'];
  metadata?: Maybe<Scalars['JSONObject']>;
  productItemId: Scalars['ID'];
  url: Scalars['String'];
};


export type MutationCreateMediaArgs = {
  data: CreateMediaInput;
};


export type MutationCreateOnboardingCatalogArgs = {
  data: CreateOnboardingCatalogInput;
};


export type MutationCreateProductItemArgs = {
  catalogId: Scalars['ID'];
  data: CreateProductItemInput;
};


export type MutationCreateProductTypeArgs = {
  catalogId: Scalars['ID'];
  data: ProductTypeInput;
};


export type MutationCreateProductTypeFieldIntegrationMappingsArgs = {
  mappings: Array<CreateProductTypeFieldIntegrationMappingInput>;
};


export type MutationCreatePublicApiKeyArgs = {
  expirationDate?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
};


export type MutationCreateStripePaymentMethodArgs = {
  id: Scalars['ID'];
};


export type MutationCreateStripeSubscriptionArgs = {
  planId: Scalars['ID'];
};


export type MutationCreateSubscriptionArgs = {
  catalogId: Scalars['ID'];
};


export type MutationDeleteCatalogArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteIntegrationArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteIntegrationFromCatalogArgs = {
  data: UpdateCatalogIntegrationInput;
};


export type MutationDeleteMediaArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationDeleteProductItemIntegrationMappingArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteProductItemsArgs = {
  productItemIds: Array<Scalars['ID']>;
};


export type MutationDeleteProductItemsByProductTypeIdArgs = {
  filter: Array<FilterItem>;
  productTypeId: Scalars['ID'];
  search: Scalars['String'];
};


export type MutationDeleteProductTypeArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteProductTypeFieldArgs = {
  fieldId: Scalars['ID'];
};


export type MutationDeleteProductTypeFieldIntegrationMappingsArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationDeleteProductTypeIntegrationMappingArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteProductTypesArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationDeletePublicApiKeyArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteStripePaymentMethodArgs = {
  id: Scalars['ID'];
};


export type MutationDetachMediaFromProductItemArgs = {
  mediaUsagesIds: Array<Scalars['ID']>;
  productItemId: Scalars['ID'];
};


export type MutationExportCatalogArgs = {
  id: Scalars['ID'];
  integrationId: Scalars['ID'];
};


export type MutationExportCatalogToAllAttachedIntegrationsArgs = {
  id: Scalars['ID'];
};


export type MutationExportProductItemToIntegrationArgs = {
  integrationId: Scalars['ID'];
  productItemId: Scalars['ID'];
};


export type MutationExportProductTypeToAllAttachedIntegrationsArgs = {
  catalogId: Scalars['ID'];
  productTypeId: Scalars['ID'];
};


export type MutationExportProductTypeToAttachedIntegrationArgs = {
  catalogId: Scalars['ID'];
  integrationId: Scalars['ID'];
  productTypeId: Scalars['ID'];
};


export type MutationGenerateTextArgs = {
  generatedTextType: GeneratedTextType;
  productItemFieldsData: Array<GeneratedTextInput>;
};


export type MutationGetSignedUrlsArgs = {
  files: Array<UploadFileInput>;
};


export type MutationGetSignedUrlsForCsvArgs = {
  files: Array<UploadFileCsvInput>;
};


export type MutationIntegrationSignUpArgs = {
  data: IntegrationRegInput;
};


export type MutationMakeProductItemsDraftArgs = {
  ids: Array<Scalars['ID']>;
  productTypeId: Scalars['ID'];
};


export type MutationResendRegistrationConfirmationArgs = {
  email: Scalars['String'];
};


export type MutationSendEmailForResetPasswordArgs = {
  email: Scalars['String'];
};


export type MutationSignInArgs = {
  data: AuthInput;
};

export type MutationGoogleLoginArgs = {
  data: GoogleLogin;
};


export type MutationSignUpArgs = {
  data: RegInput;
};


export type MutationUpdateCatalogArgs = {
  data: UpdateCatalogInput;
};


export type MutationUpdateFacebookAccessTokenArgs = {
  businessId: Scalars['ID'];
  shortLivedAccessToken: Scalars['String'];
};


export type MutationUpdateIntegrationArgs = {
  data: UpdateIntegrationInput;
};


export type MutationUpdateIntegrationsSpecificItemDataArgs = {
  data: UpdateIntegrationsSpecificItemData;
};


export type MutationUpdateMediaAltTextArgs = {
  data: Array<UpdateAltTextInput>;
};


export type MutationUpdatePasswordArgs = {
  data: UpdatePasswordInput;
};


export type MutationUpdatePlanSubscriptionArgs = {
  planName: Scalars['String'];
};


export type MutationUpdateProductItemArgs = {
  data: UpdateProductItemInput;
  id: Scalars['ID'];
};


export type MutationUpdateProductItemsArgs = {
  changingValue: ProductItemValueInput;
  ids: Array<Scalars['ID']>;
  productTypeId: Scalars['ID'];
};


export type MutationUpdateProductItemsByProductTypeIdArgs = {
  changingValue: ProductItemValueInput;
  productTypeId: Scalars['ID'];
};


export type MutationUpdateProductTypeArgs = {
  data: UpdateProductTypeInput;
};


export type MutationUpdateProductTypeFieldArgs = {
  data: UpdateProductTypeFieldInput;
};


export type MutationUpdateProductTypeFieldIntegrationMappingsArgs = {
  mappings: Array<UpdateProductTypeFieldIntegrationMappingInput>;
};


export type MutationUpdateSubscriptionArgs = {
  data: UpdateSubscriptionsInput;
};


export type MutationUpdateUserInfoArgs = {
  data: UpdateUserInfo;
};


export type MutationUpgradePlanArgs = {
  planId: Scalars['ID'];
};


export type MutationVerifyLinkArgs = {
  token: Scalars['String'];
};

export enum OrderBy {
  Company = 'company',
  Cost = 'cost',
  Default = 'default',
  Email = 'email',
  Name = 'name',
  Status = 'status',
  Subscriber = 'subscriber',
  SubscriptionDate = 'subscriptionDate'
}

export enum OrderDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type ParseCsvStatus = {
  __typename?: 'ParseCSVStatus';
  data?: Maybe<ParsedInfo>;
  error?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  status: CsvParseStatus;
};

export type ParsedInfo = {
  __typename?: 'ParsedInfo';
  errorMessage?: Maybe<Scalars['String']>;
  inParsing: Scalars['Float'];
  parseFailed: Scalars['Float'];
  parsed: Scalars['Float'];
  updatedDataInfo?: Maybe<UpdatedDataInfo>;
};

export enum ParsedType {
  ProductItem = 'ProductItem',
  ProductType = 'ProductType'
}

export enum PaymentFrequency {
  Annually = 'annually',
  Daily = 'daily',
  Free = 'free',
  Monthly = 'monthly',
  Once = 'once',
  Weekly = 'weekly'
}

export type Plan = {
  __typename?: 'Plan';
  catalogsLimit?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  integrationsLimit?: Maybe<Scalars['Float']>;
  itemsLimit?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  paymentFrequency: PlanPaymentFrequency;
  price?: Maybe<Scalars['Float']>;
  privacy: PlanPrivacy;
  sharedCatalogsLimit?: Maybe<Scalars['Float']>;
  stripeId?: Maybe<Scalars['String']>;
  subscriptionsLimit?: Maybe<Scalars['Float']>;
  support: PlanSupport;
  type: PlanType;
};

export enum PlanPaymentFrequency {
  Annual = 'Annual',
  Free = 'Free',
  Monthly = 'Monthly'
}

export enum PlanPrivacy {
  Private = 'Private',
  Public = 'Public'
}

export type PlanSubscription = {
  __typename?: 'PlanSubscription';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  invoiceId?: Maybe<Scalars['String']>;
  invoiceUrl?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  plan: Plan;
  status: PlanSubscriptionStatus;
  stripeId?: Maybe<Scalars['String']>;
  subscriptionEndsAt?: Maybe<Scalars['DateTime']>;
  subscriptionStartsAt?: Maybe<Scalars['DateTime']>;
};

export type PlanSubscriptionStatsResponse = {
  __typename?: 'PlanSubscriptionStatsResponse';
  catalogsNumber: Scalars['Int'];
  integrationsNumber: Scalars['Int'];
  itemsNumber: Scalars['Int'];
  planSubscription: PlanSubscription;
  sharedCatalogsNumber: Scalars['Int'];
  subscriptionsNumber: Scalars['Int'];
};

export enum PlanSubscriptionStatus {
  Active = 'active',
  Canceled = 'canceled',
  Disabled = 'disabled',
  Incomplete = 'incomplete',
  IncompleteExpired = 'incompleteExpired',
  PastDue = 'pastDue',
  Trialing = 'trialing',
  Unpaid = 'unpaid',
  WaitingPayment = 'waitingPayment'
}

export enum PlanSupport {
  Basic = 'Basic',
  Personal = 'Personal'
}

export enum PlanType {
  Free = 'Free',
  Paid = 'Paid'
}

export type ProductItem = {
  __typename?: 'ProductItem';
  catalog: Catalog;
  cloneParentId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['DateTime'];
  groupName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  integrationsSpecificItemData?: Maybe<Array<IntegrationSpecificItemData>>;
  mappingsErrors: Array<IntegrationMappingError>;
  mediaUsages: Array<MediaUsage>;
  status?: Maybe<ItemStatus>;
  values: Array<ProductItemValue>;
};

export type ProductItemIntegrationMapping = {
  __typename?: 'ProductItemIntegrationMapping';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  integration: Integration;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  metadata: Scalars['JSONObject'];
  metadataExternalId: Scalars['String'];
};

export type ProductItemMapping = {
  __typename?: 'ProductItemMapping';
  createdAt: Scalars['DateTime'];
  errorMessage?: Maybe<Scalars['String']>;
  externalProductInfo?: Maybe<ExternalProductInfo>;
  id: Scalars['ID'];
  integration: Integration;
  lastSyncedAt?: Maybe<Scalars['DateTime']>;
  metadata?: Maybe<Scalars['JSONObject']>;
  metadataExternalId: Scalars['String'];
};

export type ProductItemValue = {
  __typename?: 'ProductItemValue';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  productTypeField: ProductTypeField;
  value: Scalars['String'];
};

export type ProductItemValueInput = {
  productTypeFieldId: Scalars['Int'];
  value: Scalars['String'];
};

export type ProductItemsWithCount = {
  __typename?: 'ProductItemsWithCount';
  productItems: Array<ProductItem>;
  totalCount: Scalars['Float'];
};

export type ProductType = {
  __typename?: 'ProductType';
  catalog: Catalog;
  createdAt: Scalars['DateTime'];
  duplicatedSKUInfo: DuplicatedSkuInfo;
  fields: Array<ProductTypeField>;
  fieldsMappings?: Maybe<Array<ProductTypeFieldIntegrationMapping>>;
  id: Scalars['ID'];
  isEmpty: Scalars['Boolean'];
  items: Array<ProductItem>;
  mappingsErrors: Array<IntegrationMappingError>;
  name: Scalars['String'];
};

export type ProductTypeField = {
  __typename?: 'ProductTypeField';
  createdAt: Scalars['DateTime'];
  defaultValue?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  invariable: Scalars['Boolean'];
  mappingsErrors: Array<IntegrationMappingError>;
  name: Scalars['String'];
  required: Scalars['Boolean'];
  showInFilters: Scalars['Boolean'];
  showInPreview: Scalars['Boolean'];
  systemName?: Maybe<FieldSystemName>;
  type: FieldType;
};

export type ProductTypeFieldIntegrationMapping = {
  __typename?: 'ProductTypeFieldIntegrationMapping';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  integrationId: Scalars['ID'];
  metadataExternalId: Scalars['String'];
  productTypeFieldId?: Maybe<Scalars['ID']>;
  productTypeId: Scalars['ID'];
  toSync: Scalars['Boolean'];
};

export type ProductTypeFilterItem = {
  __typename?: 'ProductTypeFilterItem';
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  productTypeFieldId: Scalars['ID'];
  productTypeFieldName: Scalars['String'];
  required: Scalars['Boolean'];
  type: FilterType;
  values?: Maybe<Array<Scalars['String']>>;
};

export type ProductTypeFiltersWithItemsCount = {
  __typename?: 'ProductTypeFiltersWithItemsCount';
  filters: Array<ProductTypeFilterItem>;
  id: Scalars['ID'];
  itemsCount: Scalars['Float'];
};

export type ProductTypeInput = {
  fields: Array<AddProductTypeFieldInput>;
  name: Scalars['String'];
};

export type ProductTypeIntegrationMapping = {
  __typename?: 'ProductTypeIntegrationMapping';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  integration: Integration;
  integrationId: Scalars['ID'];
  metadata?: Maybe<Scalars['JSONObject']>;
  metadataExternalId: Scalars['String'];
  productType: ProductType;
  productTypeId: Scalars['ID'];
  toSync: Scalars['Boolean'];
};

export type ProductTypeIntegrationMappingResponse = {
  __typename?: 'ProductTypeIntegrationMappingResponse';
  createdAt: Scalars['DateTime'];
  fieldMappings: Array<ProductTypeFieldIntegrationMapping>;
  id: Scalars['ID'];
  integration: Integration;
  integrationId: Scalars['ID'];
  metadata?: Maybe<Scalars['JSONObject']>;
  metadataExternalId: Scalars['String'];
  productType: ProductType;
  productTypeId: Scalars['ID'];
  toSync: Scalars['Boolean'];
};

export type ProductTypeWithCount = {
  __typename?: 'ProductTypeWithCount';
  productItemsCount: Scalars['Float'];
  productType: ProductType;
};

export type ProductTypesWithCount = {
  __typename?: 'ProductTypesWithCount';
  productTypes: Array<ProductTypeWithCount>;
  totalCount: Scalars['Float'];
};

export type ProductVariant = {
  __typename?: 'ProductVariant';
  hasMapping: Scalars['Boolean'];
  metadataExternalId: Scalars['ID'];
  productVariantPreviewUrl: Scalars['String'];
  title: Scalars['String'];
};

export type ProposedMapping = {
  __typename?: 'ProposedMapping';
  metadataExternalId?: Maybe<Scalars['ID']>;
  productTypeId: Scalars['ID'];
};

export type PublicApiKey = {
  __typename?: 'PublicApiKey';
  createdAt: Scalars['DateTime'];
  expirationDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  checkCatalogAccess: Scalars['Boolean'];
  checkIfFieldCanBeDeleted: Scalars['Boolean'];
  checkIsActivePlanSubscription: Scalars['Boolean'];
  checkIsLimitReached: Scalars['Boolean'];
  checkIsSpecialImportedField: Scalars['Boolean'];
  checkNameAvailability: Scalars['Boolean'];
  checkProductTypeNameAvailability: Scalars['Boolean'];
  createAccountInquiry: Scalars['Boolean'];
  createAccountInquiryBeforeConfirmation: Scalars['Boolean'];
  exportToCSVByItemsIds: Scalars['String'];
  exportToCSVByProductTypeId: Scalars['String'];
  exportToCSVZipByCatalogId: Scalars['String'];
  getAuditLog: Array<AuditLog>;
  getCatalogById: Catalog;
  getCatalogs: Array<Catalog>;
  getDefaultFields: Array<RequiredProductTypeFieldData>;
  getEbayMarketplaceIds: Array<SelectOption>;
  getEbayMerchantLocations: Array<EbayMerchantLocation>;
  getEbayPolicies: Array<EbayPolicy>;
  getEbaySiteIds: Array<SelectOption>;
  getFieldMappingSuggestions: Array<FieldMappingSuggestion>;
  getIntegrations: Array<Integration>;
  getIntegrationsListWithProposedMappings: Array<IntegrationWithUndefinedMappings>;
  getIntegrationsRequiredFields: Array<IntegrationProductTypeField>;
  getIntegrationsSystemFields: Array<IntegrationProductTypeField>;
  getMedia: Array<Media>;
  getMostPopularKeywords: Array<Scalars['String']>;
  getPlans: Array<Plan>;
  getProductItem: ProductItem;
  getProductItemPreview: ProductItem;
  getProductItemsByProductTypeId: Array<ProductItem>;
  getProductItemsPreview: ProductItemsWithCount;
  getProductType: ProductType;
  getProductTypeFiltersWithItemsCount: ProductTypeFiltersWithItemsCount;
  getProductTypeIntegrationMappings: Array<ProductTypeIntegrationMapping>;
  getProductTypeIntegrationMappingsByCatalogId: Array<ProductTypeIntegrationMapping>;
  getProductTypesPreview: ProductTypesWithCount;
  getProration: Scalars['Float'];
  getStripeBalanceWithNextPayment: StripeBalanceWithNextPayment;
  getStripeInvoices: Array<StripeInvoice>;
  getStripePaymentMethods: Array<StripePaymentMethod>;
  getSubscriptionOnCatalog?: Maybe<CatalogSubscription>;
  getSubscriptions: Array<SubscriptionWithUser>;
  getUnavailableExternalTypesIds: Array<Scalars['String']>;
  integrationHealthCheck: Scalars['Boolean'];
  integrationProductTypeFields: Array<IntegrationProductTypeField>;
  integrationProductTypes: Array<IntegrationProductType>;
  isSKUDuplicated: DuplicatedSkuInfo;
  me: User;
  planSubscriptionStats: PlanSubscriptionStatsResponse;
  productItemIntegrationMappings: Array<ProductItemMapping>;
  productTypeFieldIntegrationMappings: Array<ProductTypeFieldIntegrationMapping>;
  productTypes: Array<ProductType>;
  publicApiKeys: Array<PublicApiKey>;
  sendContactUsEmail: Scalars['Boolean'];
  sendEmailToSupport: Scalars['Boolean'];
  validatePasswordToken: Scalars['Boolean'];
};


export type QueryCheckCatalogAccessArgs = {
  catalogId: Scalars['ID'];
};


export type QueryCheckIfFieldCanBeDeletedArgs = {
  productTypeFieldId: Scalars['ID'];
};


export type QueryCheckIsLimitReachedArgs = {
  type: LimitType;
};


export type QueryCheckIsSpecialImportedFieldArgs = {
  name: Scalars['String'];
  productTypeId: Scalars['ID'];
};


export type QueryCheckNameAvailabilityArgs = {
  name: Scalars['String'];
};


export type QueryCheckProductTypeNameAvailabilityArgs = {
  catalogId: Scalars['ID'];
  name: Scalars['String'];
};


export type QueryCreateAccountInquiryArgs = {
  data: CreateAccountInquiryData;
};


export type QueryCreateAccountInquiryBeforeConfirmationArgs = {
  data: CreateAccountInquiryData;
};


export type QueryExportToCsvByItemsIdsArgs = {
  ids: Array<Scalars['ID']>;
  productTypeId: Scalars['ID'];
};


export type QueryExportToCsvByProductTypeIdArgs = {
  productTypeId: Scalars['ID'];
};


export type QueryExportToCsvZipByCatalogIdArgs = {
  catalogId: Scalars['ID'];
};


export type QueryGetAuditLogArgs = {
  action?: Maybe<AuditLogActionType>;
  entity?: Maybe<AuditLogEntity>;
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
};


export type QueryGetCatalogByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetCatalogsArgs = {
  ids?: Maybe<Array<Scalars['String']>>;
  keywords?: Maybe<Array<Scalars['String']>>;
  limit?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
  source?: Maybe<CatalogSource>;
  status?: Maybe<CatalogStatus>;
  types?: Maybe<Array<CatalogType>>;
};


export type QueryGetEbayMerchantLocationsArgs = {
  integrationId: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryGetEbayPoliciesArgs = {
  integrationId: Scalars['ID'];
  marketplaceId: Scalars['ID'];
};


export type QueryGetFieldMappingSuggestionsArgs = {
  externalProductTypeId: Scalars['ID'];
  integrationId: Scalars['ID'];
  internalProductTypeId: Scalars['ID'];
};


export type QueryGetIntegrationsArgs = {
  catalogId?: Maybe<Scalars['ID']>;
  type?: Maybe<Scalars['String']>;
};


export type QueryGetIntegrationsListWithProposedMappingsArgs = {
  catalogId: Scalars['ID'];
  options?: Maybe<GetIntegrationsListWithProposedMappingsOptions>;
};


export type QueryGetIntegrationsRequiredFieldsArgs = {
  type: IntegrationTypes;
};


export type QueryGetIntegrationsSystemFieldsArgs = {
  type: IntegrationTypes;
};


export type QueryGetMediaArgs = {
  from?: Maybe<Scalars['DateTime']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  productItemId?: Maybe<Scalars['ID']>;
  to?: Maybe<Scalars['DateTime']>;
  type?: Maybe<Array<MediaType>>;
};


export type QueryGetMostPopularKeywordsArgs = {
  limit: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
};


export type QueryGetPlansArgs = {
  paymentFrequency?: Maybe<PlanPaymentFrequency>;
  type?: Maybe<PlanType>;
};


export type QueryGetProductItemArgs = {
  id: Scalars['ID'];
};


export type QueryGetProductItemPreviewArgs = {
  productItemId: Scalars['Int'];
  productTypeId: Scalars['Int'];
};


export type QueryGetProductItemsByProductTypeIdArgs = {
  filter?: Maybe<Array<FilterItem>>;
  id: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  status?: Maybe<Array<ItemStatus>>;
};


export type QueryGetProductItemsPreviewArgs = {
  productTypeId: Scalars['ID'];
};


export type QueryGetProductTypeArgs = {
  id: Scalars['ID'];
};


export type QueryGetProductTypeFiltersWithItemsCountArgs = {
  filter?: Maybe<Array<FilterItem>>;
  id: Scalars['ID'];
  search?: Maybe<Scalars['String']>;
  status?: Maybe<Array<ItemStatus>>;
};


export type QueryGetProductTypeIntegrationMappingsArgs = {
  productTypeId: Scalars['ID'];
};


export type QueryGetProductTypeIntegrationMappingsByCatalogIdArgs = {
  catalogId: Scalars['ID'];
};


export type QueryGetProductTypesPreviewArgs = {
  catalogId: Scalars['ID'];
};


export type QueryGetProrationArgs = {
  planId: Scalars['ID'];
};


export type QueryGetSubscriptionOnCatalogArgs = {
  catalogId: Scalars['ID'];
};


export type QueryGetSubscriptionsArgs = {
  catalogId: Scalars['String'];
  limit: Scalars['Int'];
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<OrderBy>;
  orderDirection?: Maybe<OrderDirection>;
};


export type QueryGetUnavailableExternalTypesIdsArgs = {
  integrationId: Scalars['ID'];
  metadataExternalIds: Array<Scalars['ID']>;
};


export type QueryIntegrationHealthCheckArgs = {
  id: Scalars['ID'];
};


export type QueryIntegrationProductTypeFieldsArgs = {
  externalProductTypeId: Scalars['ID'];
  id: Scalars['ID'];
};


export type QueryIntegrationProductTypesArgs = {
  id: Scalars['ID'];
};


export type QueryIsSkuDuplicatedArgs = {
  data: IsSkuDuplicatedInput;
};


export type QueryProductItemIntegrationMappingsArgs = {
  data: GetProductItemIntegrationMappingsArgs;
};


export type QueryProductTypeFieldIntegrationMappingsArgs = {
  catalogId?: Maybe<Scalars['ID']>;
  productTypeId?: Maybe<Scalars['ID']>;
};


export type QueryProductTypesArgs = {
  catalogId: Scalars['ID'];
  limit: Scalars['Int'];
  offset?: Maybe<Scalars['Int']>;
  subName?: Maybe<Scalars['String']>;
};


export type QueryPublicApiKeysArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<SortArg>;
};


export type QuerySendContactUsEmailArgs = {
  data: EmailToSupportInput;
};


export type QuerySendEmailToSupportArgs = {
  data: EmailToSupportInput;
};


export type QueryValidatePasswordTokenArgs = {
  token: Scalars['String'];
};

export enum Reason {
  DuplicatedSku = 'duplicatedSku',
  FieldTypeMismatch = 'fieldTypeMismatch',
  IntegrationUnavailable = 'integrationUnavailable',
  ItemNotFound = 'itemNotFound',
  ProductTypeNotFound = 'productTypeNotFound',
  RequiredFieldsMappingsLack = 'requiredFieldsMappingsLack'
}

export type RegInput = {
  email: Scalars['String'];
  name: Scalars['String'];
  paidPlan?: Maybe<Scalars['String']>;
  password: Scalars['String'];
};

export type RequiredProductTypeFieldData = {
  __typename?: 'RequiredProductTypeFieldData';
  required: Scalars['Boolean'];
  showInPreview?: Maybe<Scalars['Boolean']>;
  systemName?: Maybe<Scalars['String']>;
  type: FieldType;
};

export type SelectOption = {
  __typename?: 'SelectOption';
  label: Scalars['String'];
  value: Scalars['String'];
};

export type SignedUrlForCsvResponse = {
  __typename?: 'SignedUrlForCSVResponse';
  id: Scalars['String'];
  url: Scalars['String'];
};

export type SortArg = {
  field: SortablePublicApiKeyField;
  order: OrderDirection;
};

export enum SortablePublicApiKeyField {
  CreatedAt = 'createdAt',
  ExpirationDate = 'expirationDate',
  Name = 'name'
}

export type StripeBalanceWithNextPayment = {
  __typename?: 'StripeBalanceWithNextPayment';
  balance: Scalars['Float'];
  nextPaymentDate?: Maybe<Scalars['Float']>;
};

export type StripeCard = {
  __typename?: 'StripeCard';
  brand: Scalars['String'];
  last4: Scalars['String'];
};

export type StripeInvoice = {
  __typename?: 'StripeInvoice';
  amount: Scalars['Float'];
  card: StripeCard;
  createdAt: Scalars['Float'];
  currency: Scalars['String'];
  id: Scalars['String'];
  number: Scalars['String'];
  pdf: Scalars['String'];
  status: Scalars['String'];
  toUseDefaultPaymentMethod: Scalars['Boolean'];
  url: Scalars['String'];
};

export type StripePaymentMethod = {
  __typename?: 'StripePaymentMethod';
  card: StripeCard;
  defaultPaymentMethod: Scalars['Boolean'];
  id: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  catalogJobUpdate?: Maybe<CatalogJob>;
  getParseCSVStatus: ParseCsvStatus;
};


export type SubscriptionCatalogJobUpdateArgs = {
  catalogIds?: Maybe<Array<Scalars['ID']>>;
  integrationIds?: Maybe<Array<Scalars['ID']>>;
};


export type SubscriptionGetParseCsvStatusArgs = {
  ids: Array<Scalars['ID']>;
};

export type SubscriptionWithUser = {
  __typename?: 'SubscriptionWithUser';
  active: Scalars['Boolean'];
  approvalStatus: ApprovalStatus;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  subscriptionDate?: Maybe<Scalars['DateTime']>;
  user: User;
};

export type UpdateAltTextInput = {
  altText: Scalars['String'];
  id: Scalars['ID'];
};

export type UpdateCatalogInput = {
  catalogId: Scalars['ID'];
  catalogName?: Maybe<Scalars['String']>;
  config: CatalogConfigInput;
  keywords?: Maybe<Array<Scalars['String']>>;
};

export type UpdateCatalogIntegrationInput = {
  catalogId: Scalars['ID'];
  integrationId: Scalars['ID'];
};

export type UpdateIntegrationInput = {
  id: Scalars['ID'];
  isDefault: Scalars['Boolean'];
  settings: Scalars['JSONObject'];
  type: IntegrationTypes;
};

export type UpdateIntegrationsSpecificItemData = {
  itemId: Scalars['ID'];
  updateIntegrationsSpecificItemDataInputs: Array<UpdateIntegrationsSpecificItemDataInput>;
};

export type UpdateIntegrationsSpecificItemDataInput = {
  id: Scalars['ID'];
  metadata: Scalars['JSONObject'];
};

export type UpdatePasswordInput = {
  newPassword: Scalars['String'];
  token: Scalars['String'];
};

export type UpdateProductItemInput = {
  media?: Maybe<Array<AddMedia>>;
  status?: Maybe<ItemStatus>;
  values?: Maybe<Array<ProductItemValueInput>>;
};

export type UpdateProductTypeFieldInput = {
  defaultValue?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  required?: Maybe<Scalars['Boolean']>;
  showInFilters?: Maybe<Scalars['Boolean']>;
  showInPreview?: Maybe<Scalars['Boolean']>;
  systemName?: Maybe<Scalars['String']>;
  type?: Maybe<FieldType>;
};

export type UpdateProductTypeFieldIntegrationMappingInput = {
  id: Scalars['ID'];
  metadataExternalId?: Maybe<Scalars['ID']>;
  productTypeFieldId?: Maybe<Scalars['ID']>;
  toSync?: Maybe<Scalars['Boolean']>;
};

export type UpdateProductTypeInput = {
  fields: Array<CreateOrUpdateProductTypeFieldInput>;
  fieldsIdsToDelete?: Maybe<Array<Scalars['ID']>>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdateProductTypeIntegrationMappingInput = {
  fieldMappings?: Maybe<Array<CreateOrUpdateProductTypeFieldIntegrationMappingInput>>;
  id: Scalars['ID'];
  metadata: Scalars['JSONObject'];
  metadataExternalId: Scalars['ID'];
  productTypeId: Scalars['ID'];
  toSync: Scalars['Boolean'];
};

export type UpdateProductTypeIntegrationMappingResponse = {
  __typename?: 'UpdateProductTypeIntegrationMappingResponse';
  fieldMappings: Array<ProductTypeFieldIntegrationMapping>;
  typeMappings: Array<ProductTypeIntegrationMapping>;
};

export type UpdateSubscriptionsInput = {
  status: ApprovalStatus;
  subscriptionId: Scalars['ID'];
};

export type UpdateUserInfo = {
  avatar?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type UpdatedDataInfo = {
  __typename?: 'UpdatedDataInfo';
  productItem?: Maybe<ProductItem>;
  productType?: Maybe<ProductType>;
  type: ParsedType;
};

export type UploadFileCsvInput = {
  catalogId: Scalars['ID'];
  fileId: Scalars['String'];
  filename: Scalars['String'];
  filetype: Scalars['String'];
};

export type UploadFileInput = {
  filename: Scalars['String'];
  filetype: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  avatar?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  id: Scalars['ID'];
  isOnboardingPassed: Scalars['Boolean'];
  keys: Array<PublicApiKey>;
  name: Scalars['String'];
  planSubscriptions: PlanSubscription;
  status: Scalars['String'];
  stripeId?: Maybe<Scalars['String']>;
};

export type GetIntegrationsListWithProposedMappingsOptions = {
  addMapping?: Maybe<Scalars['Boolean']>;
  integrationId?: Maybe<Scalars['ID']>;
};
