import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { IconButton, Typography } from '@material-ui/core';

import GradientTypography from 'src/components/GradientTypography';
import Iconography from 'src/components/Iconography';

import CheckEmailImageBackground from './CheckEmailImageBackground';
import { CheckEmailDialog } from './styled';

const CheckEmailPopup = () => {
  const { t } = useTranslation();
  const userEmail = localStorage.getItem('_email');

  const [isNewUser, setIsNewUser] = useState<boolean>(!!localStorage.getItem('_new_user'));
  const [isImageLoading, setIsImageLoading] = useState(true);

  const handleClose = () => {
    localStorage.removeItem('_new_user');
    localStorage.removeItem('_email');

    setIsNewUser(false);
  };

  return (
    <CheckEmailDialog open={isNewUser} onClose={handleClose}>
      <IconButton onClick={handleClose}>
        <Iconography iconName="cancel" htmlColor="secondary" />
      </IconButton>

      <GradientTypography variant="h1" mt="60px" mb="20px">
        {t('checkYourEmail.title')}
      </GradientTypography>

      {isImageLoading && <CheckEmailImageBackground />}
      <img
        src={`${process.env.PUBLIC_URL}/images/checkEmail.webp`}
        alt="CheckEmail"
        onLoad={() => setIsImageLoading(false)}
      />

      <Typography variant="body1" textAlign="center" color="textSecondary" width="375px" mt="25px">
        <Trans
          i18nKey="checkYourEmail.checkEmailIntegration"
          defaults="<bold>{{userEmail}}</bold>"
          values={{ userEmail }}
          components={{ bold: <strong /> }}
        />
      </Typography>
    </CheckEmailDialog>
  );
};

export default CheckEmailPopup;
