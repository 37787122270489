import { useContext } from 'react';

import { MediaDataType } from 'src/components/MediaGallery/constants';
import useUpload from 'src/hooks/useUpload';
import { MediaStateContext } from 'src/providers/MediaProvider/context';
import { getFileNameFromUrl, getMediaType } from 'src/views/Catalogs/ProductItems/functions';
import { UploadingFile, UploadingMedia } from 'src/views/Catalogs/ProductItems/types';

const useUploadMediaDropzone = () => {
  const { upload } = useUpload();

  const { mediaState, setMediaState } = useContext(MediaStateContext);

  const uploadingFiles = async () => {
    const uploadingFiles = mediaState.reduce(
      (acc: UploadingFile[], { typeAddedData, id, name, file, orderNumber }: UploadingMedia) => {
        if (typeAddedData === MediaDataType.FROM_FILE) {
          acc.push({ id, name, file, orderNumber });
        }

        return acc;
      },
      [],
    );

    const { loadedFilesUrls, unloadedFiles } = await upload(uploadingFiles);

    let uploadedFilesUrls;

    const mediaFromUrl = mediaState.filter(({ typeAddedData }) => typeAddedData === MediaDataType.FROM_URL);

    if (loadedFilesUrls.length || mediaFromUrl.length) {
      const loadedFiles = loadedFilesUrls?.map((item: string, idx) => {
        return {
          url: item,
          type: getMediaType(item),
          previewUrl: item,
          orderNumber: uploadingFiles[idx].orderNumber,
          altText: mediaState.find(({ name }) => name === getFileNameFromUrl(item))?.altText || '',
        };
      });

      const urls = mediaFromUrl.map(({ type, url, orderNumber, altText }) => ({
        type,
        url,
        previewUrl: url,
        orderNumber,
        altText: altText || '',
      }));

      uploadedFilesUrls = { mediaToCreate: [...loadedFiles, ...urls] };
    }

    setMediaState((prev) => [...prev, ...(unloadedFiles as UploadingMedia[])]);

    return { uploadedFilesUrls, unloadedFiles };
  };
  return { uploadingFiles };
};

export default useUploadMediaDropzone;
