import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ApolloError, gql, Reference, StoreObject, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Box, Typography } from '@mui/material';

import AttachedIntegrationCard from 'src/components/Card/AttachedIntegrationCard';
import Hint from 'src/components/Hint';
import Loader from 'src/components/Loader';
import DeletePopup from 'src/components/Popups/DeletePopup';
import CatalogSettingsIntegrationPopup from 'src/components/Popups/IntegrationPopup/CatalogSettingsIntegrationPopup';
import {
  clearIntegrationsCache,
  clearIntegrationsWithMappingsCache,
} from 'src/components/Popups/IntegrationPopup/functions';
import IntegrationsListPopup from 'src/components/Popups/IntegrationPopup/IntegrationsListPopup';
import { IntegrationItemOmitType } from 'src/components/Popups/IntegrationPopup/types';
import SmallAddButton from 'src/components/SmallAddButton';
import { useCheckCatalogMappingErrors } from 'src/hooks/graphQLRequestsHooks';
import useEbayIntegrationCreationInterruption from 'src/hooks/useEbayIntegrationCreationInterruption';
import { useCatalogs } from 'src/providers/catalog';
import { useSnackbar } from 'src/providers/snackbar';
import {
  addIntegrationToCatalog,
  checkIsLimitReached,
  createIntegration,
  deleteIntegrationFromCatalog,
  DuplicatedSkuInfo,
  getIntegrations,
  Integration,
  IntegrationMappingError,
  IntegrationTypes,
  LimitType,
  Mutation,
  MutationCreateIntegrationArgs,
  MutationUpdateIntegrationArgs,
  Query,
  QueryGetIntegrationsArgs,
  updateIntegration,
} from 'src/utils/gql';
import { installIntegrationApp } from 'src/views/AppInstalling/utils';
import { InstallingBackdrop } from 'src/views/UserSettings/styled';

interface AttachedIntegrationsProps {
  integrations?: Integration[];
  syncIntegration: (integrationId: string) => void;
  getProposedMappingsLoading: boolean;
  targetIntegration: string;
  isEmptyCatalog?: boolean;
  mappingErrors?: IntegrationMappingError[];
  duplicatedSKUInfo?: DuplicatedSkuInfo;
}

const AttachedIntegrations = ({
  integrations,
  syncIntegration,
  getProposedMappingsLoading,
  targetIntegration,
  isEmptyCatalog,
  mappingErrors,
  duplicatedSKUInfo,
}: AttachedIntegrationsProps) => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const snackbar = useSnackbar();
  const { deleteCatalogsJobs } = useCatalogs();
  const { checkCatalogMappingsErrorsQuery } = useCheckCatalogMappingErrors(id);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isCreatingNewIntegration, setIsCreatingNewIntegration] = useState(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState<boolean>(false);
  const [isIntegrationsListOpen, setIsIntegrationsListOpen] = useState<boolean>(false);
  const [isInstalling, setIsInstalling] = useState<boolean>(false);
  const [deletableIntegrationId, setDeletableIntegrationId] = useState<string>('');
  const { handleEbayIntegrationCreationInterruption, createdIntegration, setCreatedIntegration } =
    useEbayIntegrationCreationInterruption({});

  const createdIntegrationIdLocalStorageKey = 'createdIntegrationId';

  const { data, loading, refetch } = useQuery<Pick<Query, 'getIntegrations'>, QueryGetIntegrationsArgs>(
    getIntegrations,
    {
      fetchPolicy: 'cache-first',
      variables: {
        type: '',
      },
    },
  );

  const [checkLimit, { data: checkLimitData, refetch: refetchCheckLimit }] = useLazyQuery<
    Pick<Query, 'checkIsLimitReached'>
  >(checkIsLimitReached, {
    variables: { type: LimitType.Integrations },
  });

  const [createNewIntegration, { loading: loadingCreateIntegration }] = useMutation<
    Pick<Mutation, 'createIntegration'>,
    MutationCreateIntegrationArgs
  >(createIntegration, {
    update: clearIntegrationsCache,
    onCompleted: () => checkCatalogMappingsErrorsQuery(),
  });

  const [updateIntegrationById, { loading: loadingUpdateIntegration }] = useMutation<
    Pick<Mutation, 'updateIntegration'>,
    MutationUpdateIntegrationArgs
  >(updateIntegration);

  const [attachIntegration, { loading: loadingAttachIntegration }] = useMutation<
    Pick<Mutation, 'addIntegrationToCatalog'>
  >(addIntegrationToCatalog, {
    update: clearIntegrationsCache,
    onCompleted: () => checkCatalogMappingsErrorsQuery(),
  });

  const [detachIntegration, { loading: loadingDetachIntegration }] = useMutation<
    Pick<Mutation, 'deleteIntegrationFromCatalog'>
  >(deleteIntegrationFromCatalog, {
    update: clearIntegrationsWithMappingsCache,
    onCompleted: () => checkCatalogMappingsErrorsQuery(),
  });

  const refetchData = useCallback(async () => {
    if (refetch && refetchCheckLimit) {
      await refetch();
      await refetchCheckLimit();
    }
  }, [refetch, refetchCheckLimit]);

  const handleOpenIntegrationsListPopup = () => {
    setIsOpen(false);
    setIsIntegrationsListOpen(true);
  };

  const handleBackIntegrationsListPopup = () => {
    setIsOpen(true);
    setIsIntegrationsListOpen(false);
  };

  const handleCloseIntegrationsListPopup = async () => {
    setIsIntegrationsListOpen(false);
    await handleEbayIntegrationCreationInterruption({});
  };

  const handleCloseDeletePopup = () => {
    setDeletableIntegrationId('');
    setIsDeletePopupOpen(false);
  };

  const handleOpenDeletePopup = (id: string) => {
    setDeletableIntegrationId(id);
    setIsDeletePopupOpen(true);
  };

  const handleCloseCatalogSettingsIntegrationPopupAndIntegrationsListPopup = () => {
    setIsOpen(false);
    setIsIntegrationsListOpen(false);
  };

  const handleAttachIntegration = async (integrationId: string) => {
    try {
      await attachIntegration({
        variables: { data: { catalogId: id, integrationId } },

        update(cache, { data }) {
          data &&
            cache.modify({
              id: cache.identify(data.addIntegrationToCatalog),
              fields: {
                integrations(existingIntegrationsRef, { readField }) {
                  const newIntegrationRef = cache.writeFragment({
                    data: data.addIntegrationToCatalog.integrations?.find(({ id }) => id === integrationId),
                    fragment: gql`
                      fragment NewIntegration on Integration {
                        id
                      }
                    `,
                  });

                  if (
                    existingIntegrationsRef.some(
                      (ref: Reference | StoreObject | undefined) => readField('id', ref) === integrationId,
                    )
                  ) {
                    return existingIntegrationsRef;
                  }

                  return [...existingIntegrationsRef, newIntegrationRef];
                },
              },
            });
          clearIntegrationsCache(cache);
        },
      });

      await refetch();

      handleCloseCatalogSettingsIntegrationPopupAndIntegrationsListPopup();
      snackbar(t('settingsPage.attachedIntegrations.attachIntegrationSnackbar'), 'success');
    } catch (error) {
      const { graphQLErrors, message: errorText } = error as ApolloError;
      const message = graphQLErrors && graphQLErrors.length ? graphQLErrors[0].message : errorText;

      if (error) {
        snackbar(message);
      }
    }
  };

  const handleDetachIntegration = async () => {
    try {
      await detachIntegration({
        variables: { data: { catalogId: id, integrationId: deletableIntegrationId } },

        update(cache, { data }) {
          if (data) {
            cache.modify({
              id: cache.identify({
                id,
                __typename: 'Catalog',
              }),
              fields: {
                integrations(existingIntegrationsRef, { readField }) {
                  return existingIntegrationsRef.filter(
                    (ref: Reference | StoreObject | undefined) => deletableIntegrationId !== readField('id', ref),
                  );
                },
              },
            });
          }

          clearIntegrationsWithMappingsCache(cache);
        },
      });

      deleteCatalogsJobs({ catalogId: id, integrationId: deletableIntegrationId });

      handleCloseDeletePopup();
      snackbar(t('settingsPage.attachedIntegrations.detachIntegrationSnackbar'), 'success');
    } catch (error) {
      const { graphQLErrors, message: errorText } = error as ApolloError;
      const message = graphQLErrors && graphQLErrors.length ? graphQLErrors[0].message : errorText;

      if (error) {
        snackbar(message);
      }
    }
  };

  const handleCreateNewIntegration = async (
    integration: IntegrationItemOmitType,
    type: IntegrationTypes,
    resetStateCallback: CallableFunction,
  ) => {
    const {
      settings: { integrationName, shop, url, apiKey, apiSecret, redirectUrl, clientId, clientSecret, marketplaceId },
      isDefault,
    } = integration;

    if (type === IntegrationTypes.Woocommerce) {
      try {
        const { data } = await createNewIntegration({
          variables: {
            data: {
              type,
              isDefault,
              settings: { integrationName, url, apiKey, apiSecret },
            },
          },
        });

        if (data) {
          await Promise.all([refetchData(), handleAttachIntegration(data.createIntegration.id)]);
          resetStateCallback();
        }
      } catch (error) {
        const { graphQLErrors, message: errorText } = error as ApolloError;
        const message = graphQLErrors && graphQLErrors.length ? graphQLErrors[0].message : errorText;

        if (error) {
          snackbar(message);
        }
      }
    } else if (type === IntegrationTypes.Ebay) {
      setIsInstalling(true);

      const onSuccess = async (integrationId: string) => {
        localStorage.setItem(createdIntegrationIdLocalStorageKey, integrationId);
        const { data } = await refetch();
        const targetIntegration = data.getIntegrations.find(({ id }) => id === integrationId);

        setCreatedIntegration(targetIntegration);
        setIsCreatingNewIntegration(true);
      };

      await installIntegrationApp({
        type,
        settings: {
          integrationName,
          clientId,
          clientSecret,
          redirectUrl,
          isDefault,
          marketplaceId,
        },
        onSuccess,
        onComplete: async () => {
          setIsInstalling(false);
        },
      });
    } else {
      setIsInstalling(true);

      const onSuccess = async (integrationId: string) => {
        await Promise.all([refetchData(), handleAttachIntegration(integrationId)]);
        resetStateCallback();
      };

      await installIntegrationApp({
        type,
        settings: {
          shop,
          integrationName,
          isDefault,
        },
        onSuccess,
        onComplete: async () => {
          setIsInstalling(false);
        },
      });
    }
  };

  const handleAttachEbayIntegration = async (
    integration: IntegrationItemOmitType,
    type: IntegrationTypes,
  ): Promise<void> => {
    if (createdIntegration) {
      const { id, settings, isDefault } = createdIntegration;

      await updateIntegrationById({
        variables: {
          data: {
            id,
            type,
            isDefault,
            settings: {
              // order of destructing objects is important
              ...settings,
              ...integration.settings,
            },
          },
        },
      })
        .then(async () => {
          localStorage.removeItem(createdIntegrationIdLocalStorageKey);
          setCreatedIntegration(undefined);
          handleCloseCatalogSettingsIntegrationPopupAndIntegrationsListPopup();
          await Promise.all([refetchData(), handleAttachIntegration(id)]);
        })
        .catch(async (error) => {
          await handleEbayIntegrationCreationInterruption({});
          handleCloseCatalogSettingsIntegrationPopupAndIntegrationsListPopup();

          if (error) {
            const { graphQLErrors, message: errorText } = error as ApolloError;
            const message = graphQLErrors && graphQLErrors.length ? graphQLErrors[0].message : errorText;

            snackbar(message);
          }
        });
    }
  };

  useEffect(() => {
    if (isOpen) {
      checkLimit();
    }
  }, [checkLimit, isOpen]);

  return (
    <>
      <Box mt="40px">
        <Box display="flex" alignItems="center" mb="22px">
          <Typography variant="h3" fontSize="18px" fontStyle="normal" fontWeight="500" mr="15px">
            {t('settingsPage.attachedIntegrations.title')}
          </Typography>

          <Hint type="hover" title={`${t('settingsPage.attachedIntegrations.hint')}`} placement="right">
            <Box>
              <SmallAddButton
                onClick={() => (data?.getIntegrations.length ? setIsOpen(true) : setIsIntegrationsListOpen(true))}
                isGradiented
                testId="smallAddButtonAttachedIntegrations"
              />
            </Box>
          </Hint>
        </Box>

        <Box display="flex" flexWrap="wrap">
          {integrations?.map((integration) => (
            <AttachedIntegrationCard
              isEmpty={isEmptyCatalog}
              duplicatedSKUInfo={duplicatedSKUInfo}
              key={integration.id}
              integrationId={integration.id}
              integrationMappingErrors={mappingErrors?.filter(({ integrationId }) => integrationId === integration.id)}
              catalogId={id}
              type={integration.type}
              title={integration.settings.integrationName}
              onSyncIntegrationClick={syncIntegration}
              checkMappingsLoading={getProposedMappingsLoading && targetIntegration === integration.id}
              onOpenDeletePopup={handleOpenDeletePopup}
            />
          ))}
        </Box>
      </Box>

      <CatalogSettingsIntegrationPopup
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onAttachIntegration={handleAttachIntegration}
        onOpenIntegrationsListPopup={handleOpenIntegrationsListPopup}
        integrations={data?.getIntegrations}
        loading={loading}
        loadingAddItegration={loadingAttachIntegration}
        isLimit={checkLimitData?.checkIsLimitReached}
      />

      <IntegrationsListPopup
        hasIntegrations={Boolean(data?.getIntegrations.length)}
        open={isIntegrationsListOpen}
        onClose={handleCloseIntegrationsListPopup}
        onBackButtonClick={handleBackIntegrationsListPopup}
        onCreateNewIntegration={handleCreateNewIntegration}
        loading={loadingCreateIntegration || loadingUpdateIntegration || loadingAttachIntegration}
        isCreatingNewIntegration={isCreatingNewIntegration}
        setIsCreatingNewIntegration={setIsCreatingNewIntegration}
        handleAttachEbayIntegration={handleAttachEbayIntegration}
        handleEbayIntegrationCreationInterruption={handleEbayIntegrationCreationInterruption}
        createdIntegrationId={createdIntegration?.id}
      />

      <DeletePopup
        open={isDeletePopupOpen}
        onClose={handleCloseDeletePopup}
        onMainButtonClick={handleDetachIntegration}
        onSecondaryButtonClick={handleCloseDeletePopup}
        loadingOnMainButton={loadingDetachIntegration}
        mainTitle={t('settingsPage.attachedIntegrations.popup.deletePopupTitle')}
        descriptionText={t('settingsPage.attachedIntegrations.popup.deletePopupDescription')}
      />

      <InstallingBackdrop open={isInstalling}>
        <Loader size="medium" />
        <Typography mt="20px">{t('userSettingsPage.waiting')}</Typography>
      </InstallingBackdrop>
    </>
  );
};

export default AttachedIntegrations;
