import { Box } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Typography } from '@mui/material';

export const IntegrationContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SideContainer = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 486px;
  min-width: 486px;
  width: 622px;
  height: 578px;
`;

export const FormContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-width: 282px;
`;

export const DescriptionTypography = styled(Typography)(({ theme }) => ({
  lineHeight: '16px',
  margin: '32px 0px 40px',
  textAlign: 'center',
  color: theme.palette.text.secondary,
  maxWidth: 390,

  '& .MuiLink-root': {
    color: theme.palette.text.secondary,
  },
}));

export const TermsTypography = styled(Typography)(({ theme }) => ({
  marginTop: 28,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  maxWidth: 250,

  '& .MuiLink-root': {
    fontWeight: 700,
    color: theme.palette.secondary.dark,
  },
}));

export const SignInTypography = styled(Typography)(({ theme }) => ({
  marginTop: 16,
  color: theme.palette.text.secondary,

  '& .MuiLink-root': {
    fontWeight: 700,
    color: theme.palette.secondary.dark,
  },
}));
