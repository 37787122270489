import React from 'react';

interface SyncIconProps {
  width?: number;
  height?: number;
  fillColor?: string;
}

const SyncIcon = ({ width, height, fillColor }: SyncIconProps) => (
  <svg width={width || 24} height={height || 24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.9994 14.3887V20.2663C22.0002 20.3652 21.9718 20.4622 21.9179 20.5445C21.8639 20.6268 21.7869 20.6907 21.6969 20.7278C21.6068 20.7649 21.5079 20.7737 21.413 20.7528C21.318 20.7319 21.2314 20.6824 21.1644 20.6108L19.1438 18.5523C19.1318 18.54 19.1175 18.5302 19.1017 18.5235C19.086 18.5168 19.0691 18.5134 19.052 18.5134C19.0349 18.5134 19.018 18.5168 19.0022 18.5235C18.9865 18.5302 18.9722 18.54 18.9601 18.5523C17.4865 20.1138 15.5457 21.1328 13.4423 21.4496C11.3389 21.7663 9.19175 21.3628 7.3377 20.3023C5.48365 19.2419 4.02756 17.5845 3.19781 15.5901C2.48211 13.8699 4.61026 13.4032 5.28275 14.6879C5.92054 16.2133 7.03871 17.4789 8.46085 18.2851C9.88299 19.0913 11.5281 19.3921 13.1367 19.1402C14.7453 18.8882 16.2258 18.0978 17.3445 16.8937C17.368 16.8684 17.3811 16.8349 17.3811 16.8001C17.3811 16.7653 17.368 16.7318 17.3445 16.7066L15.3908 14.7204C15.3201 14.652 15.2714 14.5633 15.2511 14.4662C15.2307 14.369 15.2396 14.2679 15.2766 14.176C15.3136 14.0841 15.3769 14.0057 15.4584 13.951C15.5398 13.8964 15.6356 13.8681 15.7331 13.8699H21.5151C21.5808 13.8698 21.6459 13.8833 21.7064 13.9097C21.7668 13.9361 21.8214 13.9747 21.8667 14.0233C21.912 14.0718 21.9471 14.1292 21.9699 14.1921C21.9927 14.2549 22.0028 14.3218 21.9994 14.3887Z"
      fill={fillColor}
    />
    <path
      d="M2.00065 9.16597V3.28842C1.99981 3.18946 2.0282 3.09253 2.08214 3.01022C2.13608 2.92792 2.21307 2.86404 2.30311 2.82689C2.39315 2.78974 2.49208 2.78103 2.58704 2.80191C2.682 2.82278 2.7686 2.87227 2.83559 2.94393L4.85616 5.00235C4.86819 5.0147 4.8825 5.02449 4.89827 5.03118C4.91403 5.03787 4.93093 5.04131 4.94801 5.04131C4.96508 5.04131 4.98199 5.03787 4.99775 5.03118C5.01351 5.02449 5.02782 5.0147 5.03985 5.00235C6.51351 3.44086 8.45425 2.42185 10.5577 2.10514C12.6611 1.78843 14.8082 2.19194 16.6623 3.25238C18.5163 4.31282 19.9724 5.97021 20.8022 7.96458C21.5179 9.68483 19.3897 10.1514 18.7172 8.86675C18.0795 7.34139 16.9613 6.07578 15.5391 5.2696C14.117 4.46342 12.4719 4.16257 10.8633 4.41452C9.25468 4.66646 7.77422 5.45685 6.65547 6.66099C6.63198 6.68627 6.61889 6.71975 6.61889 6.75456C6.61889 6.78937 6.63198 6.82285 6.65547 6.84812L8.60925 8.83424C8.67987 8.90273 8.72856 8.99135 8.74893 9.0885C8.7693 9.18565 8.76041 9.2868 8.72342 9.37871C8.68643 9.47063 8.62306 9.54902 8.54161 9.60364C8.46016 9.65826 8.36442 9.68656 8.26692 9.68483H2.48492C2.41916 9.68492 2.35407 9.67136 2.29362 9.64499C2.23317 9.61862 2.17863 9.57998 2.13332 9.53143C2.08801 9.48288 2.05287 9.42544 2.03007 9.36261C2.00726 9.29978 1.99725 9.23287 2.00065 9.16597Z"
      fill={fillColor}
    />
  </svg>
);

export default SyncIcon;
