import React from 'react';

import { TooltipProps } from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import Hint, { HintType } from './Hint';

const DEFAULT_TOOLTIP_MAX_WIDTH = '100%';
const DEFAULT_TOOLTIP_MARGIN_LEFT = '0px';

interface Hint extends TooltipProps {
  tooltipWidth?: string;
  tooltipMarginLeft?: string;
}

export default function HintLimitButton({ title, children, tooltipWidth, tooltipMarginLeft, ...props }: Hint) {
  const tooltipMaxWidth = tooltipWidth || DEFAULT_TOOLTIP_MAX_WIDTH;
  const customTooltipMarginLeft = tooltipMarginLeft || DEFAULT_TOOLTIP_MARGIN_LEFT;

  return (
    <Hint
      customTooltipMarginLeft={customTooltipMarginLeft}
      tooltipWidth={tooltipMaxWidth}
      type={HintType.hover}
      title={
        <Typography variant="caption" color="primary.light">
          {title}
        </Typography>
      }
      {...props}
    >
      <Typography variant="h3">{children}</Typography>
    </Hint>
  );
}
