import { Box, Typography } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';

import Image404Page from './error-404.webp';
import Image500Page from './error-500.webp';

export const StyledWrapperPageNotFound = styled(Box)(() => ({
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  margin: '0 auto',
  maxWidth: '1248px',
  padding: '40px 24px',
}));

export const StyledBlockPosition = styled(Box)(({ theme }) => ({
  flex: '1 0 auto',
  margin: '90px 0 83px',
  display: 'flex',
  justifyContent: 'space-between',

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export const StyledTitle = styled(Typography)(({ theme }) => ({
  fontSize: '120px',
  lineHeight: '141px',
  paddingBottom: '5px',
  color: theme.palette.text.secondary,
}));

export const StyledSubtitle = styled(Typography)(({ theme }) => ({
  paddingBottom: '40px',
  color: theme.palette.text.secondary,
}));

export const StyledContentWrapper = styled(Box)(({ theme }) => ({
  maxWidth: '510px',
  width: '100%',
  margin: '49px 0 0',
  height: 'fit-content',
  paddingLeft: '20px',

  [theme.breakpoints.down('sm')]: {
    textAlign: 'center',
  },
}));

export const StyledLink = styled('a')`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme: { palette } }) => palette.secondary.dark};
  :visited {
    text-decoration: none;
    color: ${({ theme: { palette } }) => palette.secondary.dark};
  }
  :active {
    text-decoration: none;
    color: ${({ theme: { palette } }) => palette.secondary.dark};
  }
  :hover {
    cursor: pointer;
    border-bottom: 1px solid ${({ theme: { palette } }) => palette.info.contrastText};
  }
`;

export const StyledFooterText = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-end',

  svg: {
    width: '14px',
    height: '14px',
    margin: '0 6px',
  },

  [theme.breakpoints.down('sm')]: {
    paddingTop: '5px',
  },
}));

export const StyledImageError404 = styled(Box)(({ theme }) => ({
  background: `url(${Image404Page}) no-repeat`,
  backgroundSize: 'contain',
  width: '612px',
  height: '485px',

  [theme.breakpoints.down('lg')]: {
    height: '385px',
    width: '512px',
  },

  [theme.breakpoints.down('md')]: {
    height: '285px',
    width: '295px',
  },
}));

export const StyledImageError500 = styled(Box)(({ theme }) => ({
  background: `url(${Image500Page}) no-repeat`,
  backgroundSize: 'contain',
  width: '612px',
  height: '511px',

  [theme.breakpoints.down('lg')]: {
    height: '411px',
    width: '512px',
  },

  [theme.breakpoints.down('md')]: {
    height: '311px',
    width: '302px',
  },
}));

export const StyledImageError401 = styled(Box)(({ theme }) => ({
  background: `url(${Image404Page}) no-repeat`,
  backgroundSize: 'contain',
  width: '612px',
  height: '511px',

  [theme.breakpoints.down('lg')]: {
    height: '411px',
    width: '512px',
  },

  [theme.breakpoints.down('md')]: {
    height: '311px',
    width: '265px',
  },

  [theme.breakpoints.down('sm')]: {
    height: '251px',
    width: '300px',
  },
}));

export const StyledFooter = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',

  [theme.breakpoints.down('sm')]: {
    alignItems: 'center',
    flexDirection: 'column',
  },
}));

export const StyledFooterLinkBlock = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoints.down('sm')]: {
    alignItems: 'center',
  },
}));
