import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useMutation, useQuery } from '@apollo/client';
import { Box, makeStyles, Table, TableCell, TableRow, Typography } from '@material-ui/core';
import clsx from 'clsx';
import moment from 'moment';

import Button from 'src/components/Button';
import ButtonScrollToTop from 'src/components/ButtonScrollToTop';
import Iconography from 'src/components/Iconography';
import Popup from 'src/components/Popup';
import { StyledHeaderLabelButton } from 'src/components/SharedCatalogsTable/styled';
import SkeletonSharedCatalogRow from 'src/components/Skeleton/SkeletonSharedCatalogRow';
import { StyledIconButton, StyledTableBody, StyledTableContainer } from 'src/components/UserSetttingsTable/styled';
import { useSnackbar } from 'src/providers/snackbar';
import {
  deletePublicApiKey,
  Mutation,
  MutationDeletePublicApiKeyArgs,
  OrderDirection,
  publicApiKeys as publicApiKeysQuery,
  Query,
  SortablePublicApiKeyField,
  SortArg,
} from 'src/utils/gql';

import { NameApiKeyStyled, PopupTextWrapper, StyledTableHead } from './styled';

const useStyles = makeStyles(() => ({
  breakName: {
    maxHeight: '63px',
    wordBreak: 'break-all',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    MozBoxOrient: 'vertical',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    WebkitBoxOrient: 'vertical',
    'line-clamp': 2,
    boxOrient: 'vertical',
  },
  sizeBlock: {
    maxWidth: '473px',
  },
}));

const TABLE_CELL_NAMES = {
  [SortablePublicApiKeyField.Name]: 'userSettingsPage.publicApiKeys.keysTableColumns.name',
  [SortablePublicApiKeyField.CreatedAt]: 'userSettingsPage.publicApiKeys.keysTableColumns.creationDate',
  [SortablePublicApiKeyField.ExpirationDate]: 'userSettingsPage.publicApiKeys.keysTableColumns.expirationDate',
};

export const DATE_FORMAT = 'MMMM DD, YYYY';

const PublicApi = () => {
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const classes = useStyles();

  const [isDeletePopupOpened, setIsDeletePopupOpened] = useState<boolean>(false);
  const [publicApiKey, setPublicApiKey] = useState<string>('');
  const [publicApiKeyName, setPublicApiKeyName] = useState<string>('');
  const [sortArg, setSortArg] = useState<SortArg>({
    field: SortablePublicApiKeyField.CreatedAt,
    order: OrderDirection.Asc,
  });

  const skeletonsCount = 10;

  const {
    data: publicApiKeysData,
    loading: loadingPublicApi,
    refetch: refetchPublicApiKeys,
  } = useQuery<Pick<Query, 'publicApiKeys'>>(publicApiKeysQuery);

  const publicApiKeys = publicApiKeysData?.publicApiKeys;

  const showSkeleton = loadingPublicApi || !publicApiKeysData;

  const handleCloseDeletePopup = () => {
    setIsDeletePopupOpened(false);
  };

  const [deletePublicApiKeyMethod, { loading }] = useMutation<
    Pick<Mutation, 'deletePublicApiKey'>,
    MutationDeletePublicApiKeyArgs
  >(deletePublicApiKey);

  const handleOpenPopupOnDelete = (id: string, name: string) => {
    setIsDeletePopupOpened(true);
    setPublicApiKey(id);
    setPublicApiKeyName(name);
  };

  const handleSortKeys = ({ field, order }: { field: SortablePublicApiKeyField; order?: OrderDirection }) => {
    const newSortArg = order
      ? { field, order }
      : {
          field: sortArg.field,
          order: sortArg.order === OrderDirection.Asc ? OrderDirection.Desc : OrderDirection.Asc,
        };

    refetchPublicApiKeys({
      sort: newSortArg,
    });
    setSortArg(newSortArg);
  };

  const handleDeleteKey = useCallback(async () => {
    try {
      await deletePublicApiKeyMethod({
        variables: {
          id: publicApiKey,
        },
        update: (cache) => {
          cache.modify({
            fields: {
              publicApiKeys(existingKeysRefs: { __ref: string }[], { readField }) {
                return existingKeysRefs.filter((keyRef) => publicApiKey !== readField('id', keyRef));
              },
            },
          });

          cache.evict({
            id: cache.identify({
              id: publicApiKey,
              __typename: 'PublicApiKey',
            }),
          });

          cache.gc();
        },
      });
      snackbar(t('userSettingsPage.publicApiKeys.deleteApiKeySuccessSnackbar'), 'success');
      setIsDeletePopupOpened(false);
    } catch (err) {
      snackbar((err as Error).message);
    }
  }, [deletePublicApiKeyMethod, snackbar, publicApiKey, t]);

  return (
    <StyledTableContainer>
      <Table>
        <StyledTableHead>
          <TableRow>
            {Object.entries(TABLE_CELL_NAMES).map(([sortBy, label]) => (
              <TableCell key={label}>
                <Box display="flex" alignItems="center">
                  <StyledHeaderLabelButton
                    onClick={() => handleSortKeys({ field: sortBy as SortablePublicApiKeyField })}
                  >
                    {t(label)}
                  </StyledHeaderLabelButton>

                  <Box display="flex" height="20px" justifyContent="space-between" flexDirection="column" ml="11px">
                    <StyledIconButton
                      onClick={() =>
                        handleSortKeys({ field: sortBy as SortablePublicApiKeyField, order: OrderDirection.Asc })
                      }
                    >
                      <Iconography iconName="unfold-arrow-up" />
                    </StyledIconButton>
                    <StyledIconButton
                      onClick={() =>
                        handleSortKeys({ field: sortBy as SortablePublicApiKeyField, order: OrderDirection.Desc })
                      }
                    >
                      <Iconography iconName="unfold-arrow-down" />
                    </StyledIconButton>
                  </Box>
                </Box>
              </TableCell>
            ))}
            <TableCell align="center">{t('userSettingsPage.publicApiKeys.keysTableColumns.actions')}</TableCell>
          </TableRow>
        </StyledTableHead>
        <StyledTableBody>
          {showSkeleton ? (
            <SkeletonSharedCatalogRow cardsCount={skeletonsCount} />
          ) : (
            <>
              {publicApiKeys?.map(({ id, name, createdAt, expirationDate }) => (
                <TableRow key={id}>
                  <TableCell className={clsx(classes.sizeBlock, classes.breakName)}>{name}</TableCell>
                  <TableCell>{moment(createdAt).format(DATE_FORMAT)}</TableCell>
                  <TableCell>
                    {expirationDate
                      ? moment(expirationDate).format(DATE_FORMAT)
                      : t('userSettingsPage.publicApiKeys.noExpired')}
                  </TableCell>
                  <TableCell>
                    <Box display="flex" justifyContent="center">
                      <Button
                        variant="outlined"
                        onClick={() => handleOpenPopupOnDelete(id, name)}
                        data-testid={`deleteButton${name}`}
                      >
                        {t('userSettingsPage.publicApiKeys.deleteButtonTitle')}
                      </Button>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </>
          )}
        </StyledTableBody>
      </Table>
      <ButtonScrollToTop intersection={document.documentElement.clientHeight} />
      {isDeletePopupOpened && (
        <Popup
          open={isDeletePopupOpened}
          mainButtonText={t('userSettingsPage.publicApiKeys.deletePublicApiKeyPopup.textMainButton')}
          secondaryButtonText={t('userSettingsPage.publicApiKeys.deletePublicApiKeyPopup.textSecondaryButton')}
          onClose={handleCloseDeletePopup}
          onSecondaryButtonClick={handleCloseDeletePopup}
          onMainButtonClick={handleDeleteKey}
          loadingOnMainButton={loading}
          headerMarginAbsence
          testMainButton="deleteButtonPopup"
          testSecondaryButton="backButtonPopup"
        >
          <PopupTextWrapper>
            <Typography variant="h2" mr="6px">
              {t('userSettingsPage.publicApiKeys.deletePublicApiKeyPopup.title')}
            </Typography>
            <NameApiKeyStyled variant="h2">{publicApiKeyName}</NameApiKeyStyled>
            <Typography ml="6px" variant="h2">
              {t('userSettingsPage.publicApiKeys.deletePublicApiKeyPopup.titleEnd')}
            </Typography>
          </PopupTextWrapper>
          <Box maxWidth={496} margin="0 auto">
            <Typography color="text.secondary" variant="body1" margin="0 70px">
              {t('userSettingsPage.publicApiKeys.deletePublicApiKeyPopup.beforeDeleteWarning')}
            </Typography>
          </Box>
        </Popup>
      )}
    </StyledTableContainer>
  );
};

export default PublicApi;
