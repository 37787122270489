import { Box, IconButton, TableBody, TableContainer, TableHead } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';

import Button from 'src/components/Button';
import Iconography from 'src/components/Iconography';

export const StyledCatalogSettingsWrapper = styled(TableContainer)`
  scrollbar-width: thin;
  margin: 2px 0;

  .infinite-scroll-component {
    padding-left: 8px;
  }
`;

export const StyledTableContainer = styled(TableContainer)`
  height: 100%;
  padding-right: 10px;
  scrollbar-width: thin;

  .infinite-scroll-component {
    display: table;
    width: 100%;
  }
  display: table;
  width: 100%;
`;

export const StyledTableHead = styled(TableHead)`
  .MuiTableCell-root {
    background-color: ${({ theme: { palette } }) => palette.common.white};
    font-weight: 700;
    border-bottom: 1px solid;
    border-color: ${({ theme: { palette } }) => palette.background.default};
    color: ${({ theme: { palette } }) => palette.text.primary};
    padding: 10px 15px;
    :nth-of-type(1) {
      width: 25%;
    }
    :nth-of-type(2) {
      width: 20%;
    }
    :nth-of-type(3) {
      width: 20%;
    }
    :nth-of-type(4) {
      width: 20%;
    }
    :nth-of-type(5) {
      width: 15%;
      text-align: center;
    }
  }
`;

export const StyledTableBody = styled(TableBody)`
  .MuiTableRow-root {
    &:hover {
      cursor: pointer;
      background: ${({ theme: { palette } }) => palette.background.default};
    }
  }

  .MuiTableCell-root {
    border-bottom: 1px solid ${({ theme: { palette } }) => palette.background.default};
    font-weight: 500;
    font-size: 15px;
    color: ${({ theme }) => theme.palette.text.secondary};
    height: 74px;
  }
`;

export const UnfoldIcon = styled(Iconography)`
  width: 19px;
  height: 19px;
  margin-left: 5px;
  color: ${({ theme }) => theme.palette.text.disabled};

  :hover {
    cursor: pointer;
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const StyledTextCell = styled(Box)`
  max-width: 270px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledCostCell = styled(Box)`
  background: ${({ theme }) => theme.palette.secondary.main};
  background-clip: border-box;
  border: solid 0 transparent;
  border-radius: 10px;
  color: ${({ theme }) => theme.palette.common.white};
  padding: 8px 15px;
  width: max-content;
`;

export const StyledStatusCell = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  > p {
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
  }
`;

export const StyledActionButton = styled(Box)`
  display: flex;
  justify-content: center;
`;

export const StyledIconButton = styled(IconButton)`
  padding: 0;
  svg {
    width: 7px;
    height: 10px;

    &:hover {
      fill: ${({ theme }) => theme.palette.text.primary};
    }
  }
`;

export const LoaderWraper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const StyledHeaderLabelButton = styled(Button)`
  font-size: 14px;
  line-height: 15px;
  font-weight: 700;
  color: ${({ theme: { palette } }) => palette.text.primary};
  min-width: min-content;
  padding: 0;

  &:hover {
    box-shadow: none;
    background-color: transparent;
  }

  &.Mui-focusVisible {
    border: ${({ theme: { palette } }) => `1px solid ${palette.text.primary}`};
    border-radius: 3px;
    box-shadow: none;
  }
`;
