import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { gql, useMutation } from '@apollo/client';

import Onboarding from 'src/components/Onboarding';
import {
  getCommonSteps,
  getCsvSteps,
  getDownloadExampleStep,
  getIntegrationSteps,
  getManuallySteps,
} from 'src/components/Onboarding/steps';
import { CATALOGS_PATH, FIRST_DASHBOARD_PATHNAME } from 'src/constants/routeSources';
import { CatalogSource, completeUserOnboarding } from 'src/utils/gql';

import { useAccount } from '../account';

import OnboardingContext, { defaultValue } from './context';
import { OnboardingState } from './types';

interface OnboardingProviderProps {
  children: ReactNode;
}

const OnboardingProvider = ({ children }: OnboardingProviderProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    user: { id, isOnboardingPassed },
  } = useAccount();

  const [onboardingState, setOnboardingState] = useState<OnboardingState>(defaultValue.onboardingState);
  const [isDiscardChangesPopupBlocked, blockDiscardChangesPopup] = useState<boolean>(false);

  const [completeOnboarding] = useMutation(completeUserOnboarding, {
    update: (cache) => {
      cache.writeFragment({
        id: `User:${id}`,
        fragment: gql`
          fragment MyUser on User {
            isOnboardingPassed
          }
        `,
        data: {
          isOnboardingPassed: true,
        },
      });
    },
  });

  const handleChangeStep = useCallback(
    (action: 'next' | 'back') =>
      setOnboardingState((prevState) => ({
        ...prevState,
        stepIndex: action === 'next' ? prevState.stepIndex + 1 : prevState.stepIndex - 1,
      })),
    [setOnboardingState],
  );

  const getStepsByCatalogSource = useCallback(
    (source: CatalogSource) => {
      const commonSteps = getCommonSteps(t);

      switch (source) {
        case CatalogSource.Shopify:
        case CatalogSource.Woocommerce:
          return [
            ...commonSteps,
            ...getIntegrationSteps(
              t,
              () => handleChangeStep('back'),
              source === CatalogSource.Shopify ? 'Shopify' : 'WooCommerce',
            ),
          ];

        case CatalogSource.Csv:
          return [...commonSteps, ...getCsvSteps(t, () => handleChangeStep('back'), 'CSV')];

        case CatalogSource.Manually:
          return [...commonSteps, ...getManuallySteps(t, () => handleChangeStep('back'), 'Manual')];

        default:
          return commonSteps;
      }
    },
    [handleChangeStep, t],
  );

  const getUpdatedCsvSteps = useCallback(() => {
    const commonSteps = getCommonSteps(t);
    const csvSteps = getCsvSteps(t, () => handleChangeStep('back'), 'CSV');
    csvSteps.splice(2, 0, getDownloadExampleStep(t));

    return [...commonSteps, ...csvSteps];
  }, [handleChangeStep, t]);

  const handleStartOnboarding = useCallback(
    () =>
      setOnboardingState((prevState) => ({
        ...prevState,
        tourActive: true,
        run: true,
        steps: getStepsByCatalogSource(CatalogSource.Shopify),
      })),
    [getStepsByCatalogSource, setOnboardingState],
  );

  const handleFinishOnboarding = useCallback(() => {
    setOnboardingState(defaultValue.onboardingState);
    blockDiscardChangesPopup(false);

    history.replace(`/${FIRST_DASHBOARD_PATHNAME}/${CATALOGS_PATH}`);

    !isOnboardingPassed && completeOnboarding();
  }, [completeOnboarding, history, isOnboardingPassed, setOnboardingState]);

  useEffect(() => {
    const body = document.getElementsByTagName('body')[0];
    const forbiddenKeys = ['Enter', 'Escape', 'Tab'];

    const handleKeydownEvent = (e: KeyboardEvent) => {
      if (forbiddenKeys.includes(e.code)) {
        e.preventDefault();
      }
    };

    if (onboardingState.tourActive) {
      body.addEventListener('keydown', handleKeydownEvent);
    } else {
      body.removeEventListener('keydown', handleKeydownEvent);
    }
  }, [onboardingState.tourActive]);

  const value = useMemo(
    () => ({
      onboardingState,
      isDiscardChangesPopupBlocked,
      setOnboardingState,
      blockDiscardChangesPopup,
      getStepsByCatalogSource,
      getUpdatedCsvSteps,
      goNext: () => handleChangeStep('next'),
      goBack: () => handleChangeStep('back'),
      startOnboarding: handleStartOnboarding,
      finishOnboarding: handleFinishOnboarding,
    }),
    [
      onboardingState,
      isDiscardChangesPopupBlocked,
      getStepsByCatalogSource,
      getUpdatedCsvSteps,
      handleStartOnboarding,
      handleFinishOnboarding,
      handleChangeStep,
    ],
  );

  return (
    <OnboardingContext.Provider value={value}>
      <Onboarding />

      {children}
    </OnboardingContext.Provider>
  );
};

export default OnboardingProvider;
