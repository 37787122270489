import { getEbayAuthUrl } from 'src/utils/getEbayAuthUrl';
import { getFacebookAuthUrl } from 'src/utils/getFacebookAuthUrl';
import { getShopifyAuthUrl } from 'src/utils/getShopifyAuthUrl';
import { IntegrationTypes } from 'src/utils/gql';

import { FacebookHashParams } from '../../constants';

export const getFacebookValueFromHash = (hash: string, key: FacebookHashParams) => {
  const hashParts = hash.split('&');
  const keyValuePair = hashParts.find((str) => str.includes(`${key}=`));

  if (keyValuePair) {
    const value = keyValuePair.replace(`${key}=`, '');

    if (key === 'state') {
      return JSON.parse(decodeURIComponent(value));
    }

    if (key === 'access_token') {
      return value.replace('#', '');
    }

    return value;
  }
};

export const installIntegrationApp = async ({
  type,
  settings: {
    shop = '',
    clientId = '',
    redirectUrl = '',
    integrationName = '',
    marketplaceId = '',
    paymentPolicyId = '',
    returnPolicyId = '',
    fulfillmentPolicyId = '',
    merchantLocationKey = '',
    clientSecret = '',
    isDefault = false,
  } = {},
  onSuccess,
  onFailure,
  onComplete,
}: {
  type: IntegrationTypes;
  settings?: {
    shop?: string;
    clientId?: string;
    redirectUrl?: string;
    integrationName?: string;
    marketplaceId?: string;
    paymentPolicyId?: string;
    returnPolicyId?: string;
    fulfillmentPolicyId?: string;
    merchantLocationKey?: string;
    clientSecret?: string;
    isDefault?: boolean;
  };
  onSuccess: (integrationId: string) => void | Promise<void>;
  onFailure?: (integrationId: string) => void | Promise<void>;
  onComplete: () => void | Promise<void>;
}): Promise<void> => {
  const getAuthUrl = (): string => {
    switch (type) {
      case IntegrationTypes.Shopify:
        return getShopifyAuthUrl(shop);
      case IntegrationTypes.Ebay:
        return getEbayAuthUrl();
      case IntegrationTypes.Facebook:
        return getFacebookAuthUrl();
      default:
        return '';
    }
  };

  history.pushState(
    {
      integrationName,
      integrationType: type,
      isDefault,
      clientSecret,
      clientId,
      redirectUrl,
      marketplaceId,
      paymentPolicyId,
      returnPolicyId,
      fulfillmentPolicyId,
      merchantLocationKey,
    },
    '',
  );

  const installWindow = open(getAuthUrl());

  const timer = setInterval(async () => {
    if (installWindow?.closed) {
      clearInterval(timer);

      const { success, id } = history.state;

      if (success) {
        await onSuccess(id);
      } else {
        await onFailure?.(id);
      }

      localStorage.removeItem('_installingState');
      await onComplete();
    }
  }, 500);
};
