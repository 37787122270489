import React from 'react';
import { useTranslation } from 'react-i18next';

import { useQuery } from '@apollo/client';
import { DocumentNode } from 'graphql';

import { camelCaseFromDashSeparated } from 'src/helpers/capitalize';

import ParentBreadCrumb from './BreadCrumb';

export interface MainBreadCrumbsProps {
  last?: boolean;
}

interface QueryBreadCrumbProps extends MainBreadCrumbsProps {
  iconName: string;
  query: DocumentNode;
  variables: {
    id: string;
  };
  link: string;
  editing: boolean;
  editingName?: string;
  lastInGroup: boolean;
}

const QueryBreadCrumb = ({
  iconName,
  query,
  variables,
  last,
  link,
  editingName,
  editing,
  lastInGroup,
}: QueryBreadCrumbProps) => {
  const { t } = useTranslation();
  const { data } = useQuery(query, {
    fetchPolicy: 'cache-first',
    variables,
  });

  const queryName = (query.definitions[0] as { name?: { value?: string } }).name?.value;

  const text = data && queryName && data[queryName]?.name;

  return (
    <>
      {data && (
        <ParentBreadCrumb
          iconName={iconName}
          link={link}
          text={
            (lastInGroup && (editingName || (editing && t(`breadcrumbs.${camelCaseFromDashSeparated(iconName)}`)))) ||
            text
          }
          last={last && lastInGroup}
        />
      )}
    </>
  );
};

export default QueryBreadCrumb;
