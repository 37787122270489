import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CardMedia, Typography } from '@material-ui/core';
import MuiCard, { CardProps } from '@material-ui/core/Card';
import MuiCardContent from '@material-ui/core/CardContent';
import { experimentalStyled as styled, makeStyles, Theme } from '@material-ui/core/styles';
import { Box } from '@mui/material';
import clsx from 'clsx';

import ItemPreviewCarousel from 'src/components/Carousel/ItemPreviewCarousel';
import { arrayToMapByGetKeyFunction, getArrayFromMultiSelectValue } from 'src/utils/general';
import {
  FieldSystemName,
  FieldType,
  ItemStatus,
  MediaType,
  MediaUsage,
  ProductItemValue,
  ProductTypeField,
} from 'src/utils/gql';

import Iconography from '../Iconography';

const ItemCheckbox = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 1px;
  background: ${({ ischecked, theme }: { ischecked: number; theme?: Theme }) =>
    ischecked ? theme?.palette.secondary.main : 'transparent'};
`;

const ItemCheckboxWrapper = styled('div')(() => ({
  position: 'absolute',
  top: 20,
  left: 20,
  zIndex: 1,
}));

const StyledMultiSelect = styled('span')(({ theme }) => ({
  background: theme.palette.text.disabled,
  borderRadius: '5px',
  margin: '0 5px 5px 0',
  fontSize: '12px',
  lineHeight: '14px',
  fontWeight: 400,
  color: theme.palette.primary.light,
  padding: '5px 10px',
  display: 'inline-flex',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
}));

const DraftLabel = styled('span')(({ theme }) => ({
  color: theme.palette.text.disabled,
  marginRight: '5px',
  fontWeight: 500,
}));

interface StyleProps {
  hasHoverStyles?: boolean;
  isFixed?: boolean;
  isChecked?: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: {
    border: '1px solid',
    borderColor: theme.palette.text.disabled,
    boxShadow: 'none',

    '&:hover': {
      borderColor: theme.palette.secondary.main,
      boxShadow: ({ hasHoverStyles }) =>
        hasHoverStyles ? '0px 0px 15px rgba(248, 144, 72, 0.35)' : '0 2px 10px rgba(46, 96, 170, 0.25)',
      cursor: ({ hasHoverStyles }) => (hasHoverStyles ? 'pointer' : 'initial'),
    },

    '& .MuiCardContent-root': {
      padding: '19px 20px 10px',
      height: '100%',
      background: theme.palette.common.white,
    },
  },
  mediaWrapper: {
    position: 'relative',
    display: 'flex',
    height: '270px',
    backgroundColor: theme.palette.common.white,
    zIndex: 0,
  },
  cardWrapper: {
    position: 'relative',
    width: ({ isFixed }) => (isFixed ? '288px' : 'auto'),
    minWidth: '288px',
    height: '100%',

    '&:hover': {
      '& .item-checkbox__wrapper': {
        border: '1px solid',
        borderColor: theme.palette.secondary.main,
        background: ({ isChecked }) => (isChecked ? theme.palette.secondary.main : theme.palette.common.white),
      },
    },
  },
  itemsWrapper: {
    padding: '19px 20px 20px',
  },
  price: {
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
  cutText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    mozBoxOrient: 'vertical',
    webkitBoxOrient: 'vertical',
    boxOrient: 'vertical',
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
  cutName: {
    lineClamp: 2,
    '-webkit-line-clamp': 2,
    marginRight: '10px',
  },
  cutStringValue: {
    lineClamp: 4,
    '-webkit-line-clamp': 4,
  },
  cutMultiSelectList: {
    lineClamp: 3,
    '-webkit-line-clamp': 3,
  },
  cutSingleSelect: {
    lineClamp: 1,
    '-webkit-line-clamp': 1,
  },
  cardTypography: {
    color: theme.palette.text.secondary,
    wordBreak: 'break-all',
    display: 'flex',
    alignItems: 'center',
  },
  cardChecked: {
    border: '2px solid',
    borderColor: theme.palette.secondary.main,

    '& .item-checkbox__wrapper svg': {
      opacity: 1,
    },
  },
  hoverIcon: {
    color: theme.palette.common.white,
    opacity: 0,
    height: '16px',
    width: '16px',
  },
}));

interface ItemCardProps extends CardProps {
  status?: ItemStatus;
  productItemName?: string;
  productItemPrice?: string;
  isCatalogShared?: boolean;
  hasSelectedItems?: boolean;
  isAllItemsSelected?: boolean;
  isItemCardClickable?: boolean;
  isFixed?: boolean;
  hasHover?: boolean;
  multiSelectTextOverflow?: string;
  onItemCardClick?: () => void;
  onSelectCard?: () => void;
  onUnselectCard?: () => void;
  productItemArray?: ProductItemValue[];
  propertiesItem?: ProductTypeField[];
  mediaUsages?: MediaUsage[];
  cloneCard?: boolean;
}

function ItemCard({
  status,
  productItemName,
  productItemPrice,
  onItemCardClick,
  onSelectCard,
  onUnselectCard,
  isCatalogShared,
  hasSelectedItems,
  isAllItemsSelected,
  isFixed,
  hasHover,
  productItemArray,
  propertiesItem,
  mediaUsages,
  cloneCard,
}: ItemCardProps) {
  const { t } = useTranslation();

  const [isChecked, setIsChecked] = useState<boolean>(false);

  const hasCheckbox = Boolean(!isCatalogShared && onSelectCard);
  const hasHoverStyles = hasCheckbox || hasHover;

  const classes = useStyles({ hasHoverStyles, isFixed, isChecked });

  const handleSelectCard = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setIsChecked(!isChecked);

    !isChecked ? onSelectCard?.() : onUnselectCard?.();
  };

  const onClickItemCard = (e: React.MouseEvent<HTMLDivElement>) => {
    if (onItemCardClick) {
      isChecked || hasSelectedItems ? handleSelectCard(e) : onItemCardClick();
    }
  };

  const fieldsForShowInPreview = useMemo(
    () =>
      propertiesItem?.filter(
        ({ systemName }) => ![FieldSystemName.Price, FieldSystemName.Name].includes(systemName as FieldSystemName),
      ),
    [propertiesItem],
  );

  const filteredProductItemArray = useMemo(
    () => productItemArray && arrayToMapByGetKeyFunction(productItemArray, ({ productTypeField: { id } }) => id),
    [productItemArray],
  );

  useEffect(() => {
    if (!hasSelectedItems) {
      setIsChecked(false);
    }
    if (isAllItemsSelected) {
      setIsChecked(true);
    }
  }, [hasSelectedItems, setIsChecked, isAllItemsSelected]);

  const cardImages = useMemo(() => {
    const media = [...(mediaUsages || [])];
    const sortedMediaUsages = media?.sort(
      ({ orderNumber: firstMediaOrder }, { orderNumber: secondMediaOrder }) =>
        (firstMediaOrder || 0) - (secondMediaOrder || 0),
    );
    return (
      sortedMediaUsages?.reduce((acc: string[], { media: { previewUrl, type } }) => {
        if (type === MediaType.Image) {
          acc.push(previewUrl);
        }

        return acc;
      }, []) || []
    );
  }, [mediaUsages]);

  const dataTestId = cloneCard ? `cloneItemCard${productItemName}` : `itemCard${productItemName}`;

  return (
    <MuiCard
      className={clsx(classes.root, classes.cardWrapper, {
        [classes.cardChecked]: isChecked,
      })}
      onClick={onClickItemCard}
      tabIndex={0}
      data-testid={dataTestId}
    >
      {hasCheckbox && (
        <ItemCheckboxWrapper onClick={handleSelectCard} data-testid={`itemCheckbox${productItemName}`}>
          <ItemCheckbox ischecked={Number(isChecked)} className="item-checkbox__wrapper">
            <Iconography iconName="check-outline" className={classes.hoverIcon} />
          </ItemCheckbox>
        </ItemCheckboxWrapper>
      )}

      <CardMedia className={classes.mediaWrapper}>
        <ItemPreviewCarousel cardImages={cardImages} />
      </CardMedia>

      <MuiCardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb="10px" height="42px">
          <Box className={classes.cardTypography} maxWidth="217px">
            <Typography className={clsx(classes.cutName, classes.cutText)}>
              {status === ItemStatus.Draft && <DraftLabel>{t('productItemsPage.draftInBraces')}</DraftLabel>}

              {productItemName}
            </Typography>
          </Box>

          <Typography className={classes.price}>{productItemPrice && `$${productItemPrice}`}</Typography>
        </Box>

        {fieldsForShowInPreview?.map(({ name, id, type, systemName }) => (
          <Box key={id}>
            <Typography variant="h3" color="text.secondary" fontWeight="400">
              {systemName ? t(`productType.systemNames.${systemName}`) : name}
            </Typography>

            <Box padding="10px 0 20px" minHeight="21px">
              {filteredProductItemArray?.[id]?.value ? (
                <>
                  {type === FieldType.String && (
                    <Box className={classes.cardTypography}>
                      <Typography className={clsx(classes.cutStringValue, classes.cutText)}>
                        {filteredProductItemArray?.[id]?.value}
                      </Typography>
                    </Box>
                  )}

                  {type === FieldType.Singleselect && (
                    <Box className={classes.cardTypography}>
                      <Typography className={clsx(classes.cutSingleSelect, classes.cutText)}>
                        {filteredProductItemArray?.[id]?.value}
                      </Typography>
                    </Box>
                  )}

                  {type === FieldType.Multiselect && (
                    <Box className={classes.cardTypography}>
                      <Typography className={clsx(classes.cutMultiSelectList, classes.cutText)}>
                        {getArrayFromMultiSelectValue(filteredProductItemArray?.[id]?.value).map((value, idx) => (
                          <StyledMultiSelect key={idx}>{value}</StyledMultiSelect>
                        ))}
                      </Typography>
                    </Box>
                  )}

                  {(type === FieldType.Number || type === FieldType.Price) && (
                    <Typography className={classes.cutText}>{filteredProductItemArray?.[id]?.value}</Typography>
                  )}

                  {type === FieldType.Boolean && (
                    <Typography className={classes.cutText}>
                      {t(filteredProductItemArray?.[id]?.value === 'true' ? 'yes' : 'no')}
                    </Typography>
                  )}
                </>
              ) : (
                <Typography>-</Typography>
              )}
            </Box>
          </Box>
        ))}
      </MuiCardContent>
    </MuiCard>
  );
}

export default ItemCard;
