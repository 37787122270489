import React, { useState, useRef } from 'react';

import { useQuery } from '@apollo/client';
import { Popover, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { ReactComponent as EbayIcon } from 'src/components/Icon/ebay.svg';
import { ReactComponent as FacebookAndInstagramIcon } from 'src/components/Icon/facebook-and-instagram.svg';
import { ReactComponent as ShopifyIcon } from 'src/components/Icon/shopify.svg';
import { ReactComponent as WooCommerceIcon } from 'src/components/Icon/woo-commerce.svg';
import {
  ProductItemValue,
  ProductTypeFieldIntegrationMapping,
  getIntegrationsSystemFields,
  IntegrationTypes,
  QueryGetIntegrationsSystemFieldsArgs,
  Query,
  MediaUsage,
} from 'src/utils/gql';

import Loader from '../Loader';

import { CompletenessIntegration } from './ChipsCompleteness';
import { CompletenessBody } from './CompletenessBody';
import { Chip, PopoverContentWrapper, Percentage } from './styled';
import { useCheckFields } from './useCheckFields';

const popoverWidth = 288;

const useStyles = makeStyles(() => ({
  popover: {
    pointerEvents: 'none',
  },
  popoverContent: {
    pointerEvents: 'auto',
    height: 500,
    width: popoverWidth,
    paddingRight: 3,
    paddingTop: 3,
    paddingBottom: 3,
    boxSizing: 'border-box',
  },
}));

interface CompletenessChipProps {
  integration: CompletenessIntegration;
  fieldsMapping: ProductTypeFieldIntegrationMapping[];
  fieldsValues: ProductItemValue[];
  isDataLoading: boolean;
  mediaUsages?: MediaUsage[];
}

export const CompletenessChip = ({
  integration,
  fieldsMapping,
  fieldsValues,
  isDataLoading,
  mediaUsages,
}: CompletenessChipProps) => {
  const [openedPopover, setOpenedPopover] = useState(false);
  const [isChipLastInTheRow, setIsChipLastInTheRow] = useState(false);
  const popoverAnchor = useRef<HTMLDivElement>();

  const classes = useStyles();

  const { data: integrationFields, loading: integrationFieldsLoading } = useQuery<
    Pick<Query, 'getIntegrationsSystemFields'>,
    QueryGetIntegrationsSystemFieldsArgs
  >(getIntegrationsSystemFields, {
    fetchPolicy: 'cache-first',
    variables: {
      type: integration.type,
    },
  });

  const { completedFields, inCompletedFields, percentage } = useCheckFields({
    systemFields: integrationFields?.getIntegrationsSystemFields,
    fieldsValues,
    fieldsMapping,
    integration,
    mediaUsages,
  });

  const getIcon = (type: IntegrationTypes) => {
    switch (type) {
      case IntegrationTypes.Shopify:
        return <ShopifyIcon />;
      case IntegrationTypes.Woocommerce:
        return <WooCommerceIcon />;
      case IntegrationTypes.Ebay:
        return <EbayIcon />;
      case IntegrationTypes.Facebook:
        return <FacebookAndInstagramIcon />;
    }
  };

  const popoverEnter = () => {
    const bodyRect = document.body.getBoundingClientRect();
    const chipRect = popoverAnchor?.current?.getBoundingClientRect();
    setOpenedPopover(true);
    if (chipRect) {
      setIsChipLastInTheRow(bodyRect.width - chipRect.x - chipRect.width - popoverWidth <= 0);
    }
  };

  const popoverLeave = () => {
    setOpenedPopover(false);
  };

  return (
    <Chip
      ref={popoverAnchor}
      aria-owns="mouse-over-popover"
      aria-haspopup="true"
      onMouseEnter={popoverEnter}
      onMouseLeave={popoverLeave}
      data-testid={`${integration.type}CompletenessChipCard`}
    >
      {integrationFieldsLoading || isDataLoading ? (
        <Box display="flex" justifyContent="center" width="100%">
          <Loader size="extraSmall" />
        </Box>
      ) : (
        <>
          {getIcon(integration.type)}
          <Percentage
            data-testid={`${integration.type}PercentageCompleteness`}
            className={percentage >= 100 ? 'finished' : ''}
          >
            {percentage}%
          </Percentage>
          <Popover
            id="mouse-over-popover"
            open={openedPopover}
            className={classes.popover}
            classes={{
              paper: classes.popoverContent,
            }}
            anchorEl={popoverAnchor.current}
            anchorOrigin={{
              vertical: 0,
              horizontal: isChipLastInTheRow ? -5 : 75,
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: isChipLastInTheRow ? 'right' : 'left',
            }}
            PaperProps={{ onMouseEnter: popoverEnter, onMouseLeave: popoverLeave }}
            closeAfterTransition
          >
            <PopoverContentWrapper pr="20px" pl="20px">
              <CompletenessBody
                type={integration.type}
                completedFields={completedFields}
                inCompletedFields={inCompletedFields}
                percentage={percentage}
              />
            </PopoverContentWrapper>
          </Popover>
        </>
      )}
    </Chip>
  );
};
