import React, { FormEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@material-ui/core';
import { capitalize } from '@mui/material';
import moment, { Moment } from 'moment';

import Button from 'src/components/Button';
import { DatePickerError, MultipleDatePickerState } from 'src/components/DatePickers/types';
import Hint from 'src/components/Hint';
import Iconography from 'src/components/Iconography';
import AuditLogCustomDatePopup from 'src/components/Popups/AuditLogCustomDatePopup';
import { SelectOptionItem } from 'src/components/Select';
import { AuditLogActionType, AuditLogEntity } from 'src/utils/gql';

import { getDate } from './functions';
import { AuditLogResetButton, Form, StyledSelect } from './styled';
import { AuditLogFilters, AuditLogFilterType, AuditLogQueryState, Dates } from './types';

interface AuditLogSearchFormProps {
  setQueryState: (state: AuditLogQueryState) => void;
  places?: string[];
}

const AuditLogSearchForm = ({ setQueryState }: AuditLogSearchFormProps) => {
  const { t } = useTranslation();

  const initialFilterState: AuditLogFilters = {
    date: { label: t('auditLogPage.options.today'), value: getDate(Dates.Today) },
    type: { label: t('auditLogPage.options.allTypes'), value: '' },
    place: { label: t('auditLogPage.options.allPlaces'), value: '' },
  };

  const initialCustomDatesState: MultipleDatePickerState[] = [
    { label: t('datePicker.start'), type: 'startDate', value: null, error: DatePickerError.Required },
    { label: t('datePicker.end'), type: 'endDate', value: null, error: DatePickerError.Required },
  ];

  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [filterState, setFilterState] = useState<AuditLogFilters>(initialFilterState);
  const [customDates, setCustomDates] = useState<MultipleDatePickerState[]>(initialCustomDatesState);

  const hasCustomDates = customDates[0].value && customDates[1].value;

  const isStartDateLessEndDate = useMemo(() => {
    return moment(customDates[0].value).startOf('day').unix() > moment(customDates[1].value).endOf('day').unix();
  }, [customDates]);

  const SELECTS = [
    {
      label: 'auditLogPage.date',
      type: AuditLogFilterType.Date,
      options: [
        { label: t('auditLogPage.options.today'), value: getDate(Dates.Today) },
        { label: t('auditLogPage.options.days'), value: getDate(Dates.LastThreeDays) },
        { label: t('auditLogPage.options.week'), value: getDate(Dates.LastWeek) },
        { label: t('auditLogPage.options.month'), value: getDate(Dates.LastMonth) },
        { label: t('auditLogPage.options.custom'), value: '' },
      ],
      testId: 'dateInputAuditLog',
    },
    {
      label: 'auditLogPage.type',
      type: AuditLogFilterType.Type,
      options: [
        { label: t('auditLogPage.options.allTypes'), value: '' },
        { label: capitalize(t('auditLogPage.options.create')), value: AuditLogActionType.Create },
        { label: capitalize(t('auditLogPage.options.update')), value: AuditLogActionType.Update },
        { label: capitalize(t('auditLogPage.options.delete')), value: AuditLogActionType.Delete },
        { label: capitalize(t('auditLogPage.options.export')), value: AuditLogActionType.Export },
        { label: capitalize(t('auditLogPage.options.import')), value: AuditLogActionType.Import },
      ],
      testId: 'typeInputAuditLog',
    },
    {
      label: 'auditLogPage.place',
      type: AuditLogFilterType.Place,
      options: [
        { label: t('auditLogPage.options.allPlaces'), value: '' },
        { label: capitalize(t('auditLogPage.options.user')), value: AuditLogEntity.User },
        { label: capitalize(t('auditLogPage.options.catalog')), value: AuditLogEntity.Catalog },
        { label: capitalize(t('auditLogPage.options.productType')), value: AuditLogEntity.ProductType },
        { label: capitalize(t('auditLogPage.options.productItem')), value: AuditLogEntity.ProductItem },
        { label: capitalize(t('auditLogPage.options.integration')), value: AuditLogEntity.Integration },
        { label: capitalize(t('auditLogPage.options.plan')), value: AuditLogEntity.PlanSubscription },
      ],
      testId: 'placeInputAuditLog',
    },
  ];

  const handleClosePopup = () => {
    setIsPopupOpen(false);

    if (!hasCustomDates) {
      setFilterState((prevState: AuditLogFilters) => ({
        ...prevState,
        date: initialFilterState.date,
      }));

      setCustomDates(initialCustomDatesState);
    }
  };

  const handleChangeSelect = (option: SelectOptionItem, type: AuditLogFilterType) => {
    if (type === AuditLogFilterType.Date) {
      !option.value ? setIsPopupOpen(true) : setCustomDates(initialCustomDatesState);
    }

    setFilterState((prevState: AuditLogFilters) => ({
      ...prevState,
      [type]: option,
    }));
  };

  const handleReset = () => {
    setFilterState(initialFilterState);
    setCustomDates(initialCustomDatesState);

    setQueryState({
      from: initialFilterState.date.value as string,
      to: undefined,
      action: undefined,
      entity: undefined,
    });
  };

  const handleSelectCustomDates = (dates: (Moment | Date | null)[]) => {
    setCustomDates([
      { ...customDates[0], value: moment(dates[0]).startOf('day').toDate() },
      { ...customDates[1], value: moment(dates[1]).endOf('day').toDate() },
    ]);

    setIsPopupOpen(false);
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    const {
      date: { value: from },
      type: { value: action },
      place: { value: entity },
    } = filterState;

    const fromTo =
      customDates[0].value && customDates[1].value
        ? { from: customDates[0].value.toString(), to: customDates[1].value.toString() }
        : { from: from as string, to: undefined };

    setQueryState({
      ...fromTo,
      action: action ? (action as AuditLogActionType) : undefined,
      entity: entity ? (entity as AuditLogEntity) : undefined,
    });
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Box display="flex" flexWrap="wrap">
          {SELECTS.map(({ label, options, type, testId }, idx) => (
            <Box key={idx}>
              <StyledSelect
                options={options}
                label={t(label)}
                selectedOptionItem={filterState[type]}
                onChangeSelect={(option) => handleChangeSelect(option, type)}
                data-testid={testId}
              />
            </Box>
          ))}

          <Hint type="hover" title={`${t('auditLogPage.resetFilters')}`} placement="right" data-testid="reset-hint">
            <AuditLogResetButton onClick={handleReset} type="button" disableTouchRipple data-testid="reset">
              <Iconography iconName="refresh" color="inherit" />
            </AuditLogResetButton>
          </Hint>
        </Box>

        <Box display="flex" justifyContent="space-between" minWidth="140px" height="42px">
          <Button variant="contained" type="submit" data-testid="viewEventsButton">
            {t('auditLogPage.viewEvents')}
          </Button>
        </Box>
      </Form>

      <AuditLogCustomDatePopup
        isOpen={isPopupOpen}
        onSelectCustomDates={handleSelectCustomDates}
        onClose={handleClosePopup}
        customDates={customDates}
        setCustomDates={setCustomDates}
        isStartDateLessEndDate={isStartDateLessEndDate}
      />
    </>
  );
};

export default AuditLogSearchForm;
