import React from 'react';

import { Box } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';

const MainBox = styled(Box)`
  position: absolute;
  left: 35px;
  top: -20px;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  filter: blur(30px);
  transform: scale(0.4);
`;

const OrangeBox = styled(Box)`
  position: absolute;
  z-index: 2;
  background-color: #fc797a;
  box-shadow: 0px 0px 50px 50px #fc797a;
`;

const LightOrangeBox = styled(Box)`
  position: absolute;
  z-index: 2;
  background-color: #f4754e;
  box-shadow: 0px 0px 50px 50px #f4754e;
`;

const YellowBox = styled(Box)`
  position: absolute;
  z-index: 2;
  background-color: #fac025;
  box-shadow: 0px 0px 50px 50px #fac025;
`;

const BlueBox = styled(Box)`
  z-index: 1;
  position: absolute;
  background-color: #231f87;
  box-shadow: 0px 0px 50px 50px #231f87;
`;

const LightBlueBox = styled(Box)`
  z-index: 2;
  position: absolute;
  background-color: #4874e0;
  box-shadow: 0px 0px 50px 50px #4874e0;
`;

const PurpleBox = styled(Box)`
  z-index: 1;
  position: absolute;
  background-color: #7375cf;
  box-shadow: 0px 0px 50px 50px #7375cf;
`;

const PinkBox = styled(Box)`
  z-index: 1;
  position: absolute;
  background-color: #dd4d71;
  box-shadow: 0px 0px 30px 30px #dd4d71;
`;

const CheckEmailImageBackground = () => {
  return (
    <MainBox>
      <BlueBox top="50px" width="330px" height="450px" bottom="280px" left="45px" borderRadius="40%" />

      <BlueBox width="130px" height="130px" left="330px" borderRadius="40%" top="350px" />

      <PurpleBox left="-40px" width="170px" height="160px" borderRadius="40%" top="310px" />

      <PurpleBox width="12px" height="12px" top="135px" left="405px" borderRadius="100%" />

      <YellowBox
        width="1px"
        height="1px"
        top="435px"
        left="485px"
        borderRadius="100%"
        style={{ boxShadow: '0px 0px 35px 35px #fac025' }}
      />

      <OrangeBox
        width="1px"
        height="1px"
        top="455px"
        left="425px"
        borderRadius="100%"
        style={{ boxShadow: '0px 0px 30px 30px #fc797a' }}
      />

      <LightBlueBox
        width="1px"
        height="1px"
        top="540px"
        left="290px"
        borderRadius="100%"
        style={{ boxShadow: '0px 0px 30px 30px #4874e0' }}
      />

      <PinkBox width="1px" height="1px" top="485px" left="20px" borderRadius="100%" />

      <LightOrangeBox
        width="5px"
        height="5px"
        top="50px"
        left="194px"
        borderRadius="20%"
        style={{ boxShadow: '0px 0px 15px 15px #f4754e' }}
      />

      <LightOrangeBox width="60px" height="85px" top="160px" left="170px" borderRadius="20%" />

      <OrangeBox width="30px" height="90px" top="305px" left="85px" borderRadius="50%" />

      <LightBlueBox width="30px" height="130px" top="285px" left="185px" borderRadius="50%" />

      <YellowBox width="30px" height="80px" top="325px" left="285px" borderRadius="50%" />
    </MainBox>
  );
};

export default CheckEmailImageBackground;
