import { Box } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';

export const CheckEmailContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ResendScreenSideContainer = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 486px;
  margin-left: 90px;
  width: 762px;
`;

export const CheckEmailSideContainer = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 486px;
  min-width: 486px;
  width: 500px;
  height: 600px;
  margin-left: 100px;
`;

export const FormContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-width: 282px;
`;

export const Image = styled('img')`
  width: 762px;
  height: 581px;
`;

export const StyledCheckEmailContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  width: '100%',
  maxWidth: '1200px',
  margin: '0 auto',
  gap: '30px',
}));

export const StyledResendContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  marginLeft: '40px',
  [theme.breakpoints.up('xl')]: {
    justifyContent: 'center',
  },
}));
