import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, CircularProgress, Collapse, Typography } from '@material-ui/core';

import Iconography from 'src/components/Iconography';
import { AddMappingButton } from 'src/components/Mapping/styled';
import { ProductVariant } from 'src/utils/gql';

import { ProductVariantRow, ProductVariantsHeader } from './styled';

interface ProductVariantsListProps {
  productVariants: ProductVariant[];
  integrationId: string;
  activeMetadataExternalId: string;
  loadingCreateMappingByShopifyExternalId: boolean;
  onCreateMappingButtonClick: (integrationId: string, metadataExternalId: string) => void;
}

const ProductVariantsList = ({
  productVariants,
  integrationId,
  activeMetadataExternalId,
  loadingCreateMappingByShopifyExternalId,
  onCreateMappingButtonClick,
}: ProductVariantsListProps) => {
  const { t } = useTranslation();
  const [isVariantsOpen, setIsVariantsOpen] = useState<boolean>(true);

  const handleTitleClick = (productVariantPreviewUrl: string) => {
    window.open(productVariantPreviewUrl, '_blank', 'noopener, noreferrer');
  };

  return (
    <>
      <ProductVariantsHeader onClick={() => setIsVariantsOpen((prev) => !prev)}>
        <Iconography iconName={isVariantsOpen ? 'expand-chevron-up' : 'expand-chevron'} />

        <Typography variant="subtitle1" color="inherit" ml="5px">
          {t('mappingSidebar.itemMapping.content.productVariants')}
        </Typography>
      </ProductVariantsHeader>

      <Collapse in={isVariantsOpen}>
        <Box mt="21px">
          {productVariants.map(({ metadataExternalId, title, productVariantPreviewUrl, hasMapping }) => (
            <ProductVariantRow key={metadataExternalId}>
              <Typography
                id="title"
                component="a"
                variant="subtitle1"
                color="text.secondary"
                onClick={() => handleTitleClick(productVariantPreviewUrl)}
              >
                {title}
              </Typography>

              {hasMapping ? (
                <Typography variant="body2" fontSize="15px" color="text.disabled">
                  {t('mappingSidebar.itemMapping.content.alreadyMapped')}
                </Typography>
              ) : (
                <AddMappingButton
                  component="button"
                  data-testid="createMappingButton"
                  disabled={loadingCreateMappingByShopifyExternalId}
                  onClick={() => onCreateMappingButtonClick(integrationId, metadataExternalId)}
                >
                  {loadingCreateMappingByShopifyExternalId && activeMetadataExternalId === metadataExternalId ? (
                    <CircularProgress size={20} data-testid="createMappingButtonLoader" />
                  ) : (
                    <Iconography iconName="add" />
                  )}

                  <Typography variant="subtitle2">{t('mappingSidebar.itemMapping.content.createMapping')}</Typography>
                </AddMappingButton>
              )}
            </ProductVariantRow>
          ))}
        </Box>
      </Collapse>
    </>
  );
};

export default ProductVariantsList;
