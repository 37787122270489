import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, IconButton, Link, Table, TableCell, TableRow, Typography } from '@material-ui/core';
import moment from 'moment';

import { BILLING_INVOICES_TABLE_LABELS } from 'src/constants';
import asMoney from 'src/utils/asMoney';
import { StripeInvoice, StripePaymentMethod } from 'src/utils/gql';
import { DATE_FORMAT } from 'src/views/UserSettings/PublicApi';

import Iconography from '../Iconography';

import {
  CapitalizedStatus,
  NoInvoicesTypography,
  StyledIconButton,
  StyledTableBody,
  StyledTableContainer,
  StyledTableHead,
} from './styled';
import { InvoiceStatus } from './types';

interface BillingInvoicesTableProps {
  data?: StripeInvoice[];
  paymentMethods?: StripePaymentMethod[];
}

export const points = `${'\u2022'.repeat(4)} `.repeat(3);

const BillingInvoicesTable = ({ data, paymentMethods }: BillingInvoicesTableProps) => {
  const { t } = useTranslation();

  const hasData = Boolean(data?.length);
  const mainPaymentMethod = paymentMethods?.find(({ defaultPaymentMethod }) => defaultPaymentMethod);

  const renderCardNumber = (last4: string, brand: string): JSX.Element => {
    return (
      <Box display="flex" minWidth="210px">
        <img src={`${process.env.PUBLIC_URL}/images/cards/${brand}.svg`} alt="cardIcon" width="40px" />
        <Typography ml="15px" color="text.secondary">{`${points}${last4}`}</Typography>
      </Box>
    );
  };

  return (
    <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
      <StyledTableContainer>
        <Table stickyHeader>
          <StyledTableHead>
            <TableRow>
              {BILLING_INVOICES_TABLE_LABELS.map((label, idx) => (
                <TableCell key={idx}>
                  <Box display="flex" alignItems="center">
                    {t(label)}

                    <Box display="flex" height="20px" justifyContent="space-between" flexDirection="column" ml="11px">
                      <StyledIconButton>
                        <Iconography iconName="unfold-arrow-up" />
                      </StyledIconButton>

                      <StyledIconButton>
                        <Iconography iconName="unfold-arrow-down" />
                      </StyledIconButton>
                    </Box>
                  </Box>
                </TableCell>
              ))}

              <TableCell>{t('userSettingsPage.billing.invoices.actions')}</TableCell>
            </TableRow>
          </StyledTableHead>

          {hasData && (
            <StyledTableBody>
              {data?.map(
                ({ id, number, createdAt, amount, currency, status, url, pdf, card, toUseDefaultPaymentMethod }) => (
                  <TableRow key={id}>
                    <TableCell>
                      <Link href={url} target="_blank" rel="noreferrer" color="text.secondary">
                        {number}
                      </Link>
                    </TableCell>

                    {toUseDefaultPaymentMethod ? (
                      <TableCell>
                        {mainPaymentMethod &&
                          renderCardNumber(mainPaymentMethod.card.last4, mainPaymentMethod.card.brand)}
                      </TableCell>
                    ) : (
                      <TableCell>{renderCardNumber(card.last4, card.brand)}</TableCell>
                    )}

                    <TableCell>{moment(createdAt).format(DATE_FORMAT)}</TableCell>

                    <TableCell>{asMoney(amount, currency)}</TableCell>

                    <TableCell>
                      <CapitalizedStatus
                        variant="subtitle2"
                        color={status === InvoiceStatus.Paid ? 'success.main' : 'error'}
                      >
                        {status}
                      </CapitalizedStatus>
                    </TableCell>

                    <TableCell>
                      <Link href={pdf}>
                        <IconButton size="small">
                          <Iconography iconName="share" />
                        </IconButton>
                      </Link>
                    </TableCell>
                  </TableRow>
                ),
              )}
            </StyledTableBody>
          )}
        </Table>
      </StyledTableContainer>

      {!hasData && (
        <NoInvoicesTypography>{t('userSettingsPage.billing.invoices.haveNotInvoices')}</NoInvoicesTypography>
      )}
    </Box>
  );
};

export default BillingInvoicesTable;
