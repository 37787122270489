import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Box, Link, Typography } from '@material-ui/core';

import { getIntegrationTitleByType } from 'src/components/Card/IntegrationCard';
import SmallIntegrationCard from 'src/components/Card/SmallIntegrationCard';
import HintLimitButton from 'src/components/HintLimitButton';
import { ExportToIntegrationPopupState } from 'src/components/Popups/IntegrationPopup/types';
import { CreateNewIntegrationLinkDisabled, ExportToIntegrationUpgradePlanLink } from 'src/components/Popups/styled';
import { linkPricingPlan } from 'src/constants';
import { IntegrationErrors } from 'src/hooks/useIntegrationHealthCheck';
import { IntegrationCardsContainer } from 'src/views/Catalogs/CatalogCreation/styled';

interface IntegrationsListProps {
  popupState: ExportToIntegrationPopupState;
  selectedCard: string;
  onSelectCard: (id: string) => void;
  onClickAddNewIntegration: () => void;
  isIntegrationsLimitReached?: boolean;
  integrationErrors?: IntegrationErrors;
  onErrorLinkClick?: (integrationId: string) => void;
}

const IntegrationsList = ({
  onSelectCard,
  selectedCard,
  popupState,
  onClickAddNewIntegration,
  isIntegrationsLimitReached,
  integrationErrors,
  onErrorLinkClick,
}: IntegrationsListProps) => {
  const { t } = useTranslation();

  const minIntegrationCards = 2;

  return (
    <Box mt="50px" mb="15px">
      <Typography mb="10px">
        {t('cataloguesPage.exportToIntegrationPopup.youHaveSaved', {
          integration: getIntegrationTitleByType(popupState.integrationType),
        })}
      </Typography>
      <Box display="flex" flexDirection="column" alignItems="center" gap="15px">
        <IntegrationCardsContainer overflow={popupState.data.length > minIntegrationCards ? 'auto' : 'hidden'}>
          {popupState.data.map(({ id, type, settings }) => (
            <SmallIntegrationCard
              key={id}
              type={type}
              name={settings.integrationName}
              url={settings.shop || settings.url}
              isSelected={selectedCard === id}
              onClick={() => onSelectCard(id)}
              hasError={!!integrationErrors?.[id]}
              onErrorLinkClick={() => onErrorLinkClick?.(id)}
            />
          ))}
        </IntegrationCardsContainer>
      </Box>

      {isIntegrationsLimitReached ? (
        <HintLimitButton
          tooltipWidth="330px"
          placement="top"
          title={
            <Trans i18nKey="catalogueCreationPage.thirdStep.shopify.hint">
              Integration number has reached the limit.
              <ExportToIntegrationUpgradePlanLink href={linkPricingPlan}>
                Upgrade your plan
              </ExportToIntegrationUpgradePlanLink>
              to create an unlimited number of integration or delete some integrations to create new ones.
            </Trans>
          }
        >
          <CreateNewIntegrationLinkDisabled
            variant="body2"
            color="text.disabled"
            data-testid="createNewIntegrationButtonDisabled"
          >
            {t('catalogueCreationPage.thirdStep.shopify.createNewIntegration')}
          </CreateNewIntegrationLinkDisabled>
        </HintLimitButton>
      ) : (
        <Link
          component="button"
          variant="body2"
          color="secondary"
          underline="hover"
          onClick={onClickAddNewIntegration}
          marginBottom="40px"
          data-testid="createNewIntegrationButton"
        >
          {t('catalogueCreationPage.thirdStep.shopify.createNewIntegration')}
        </Link>
      )}
    </Box>
  );
};

export default IntegrationsList;
