import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@material-ui/core';

import Button from 'src/components/Button';
import { ReactComponent as NoSharedCataloguesIcon } from 'src/components/Icon/create-key.svg';
import Iconography from 'src/components/Iconography';

import { StyledBox } from './styled';

interface NoPublicApiKeyProps {
  openPopupCreateApiKey?: () => void;
}

const NoPublicApiKey = ({ openPopupCreateApiKey }: NoPublicApiKeyProps) => {
  const { t } = useTranslation();

  return (
    <StyledBox>
      <NoSharedCataloguesIcon />
      <Typography variant="body1" color="text.secondary" width="334px" textAlign="center" mt="30px" mb="25px">
        {t('userSettingsPage.publicApiKeys.noPublicApiKey')}
      </Typography>
      <Button onClick={openPopupCreateApiKey} variant="contained" startIcon={<Iconography iconName="add" />}>
        {t('userSettingsPage.createKeyButton')}
      </Button>
    </StyledBox>
  );
};

export default NoPublicApiKey;
