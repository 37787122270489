import { Styles } from 'react-joyride';

import { alpha } from '@mui/material';

import { theme } from 'src/theme';

export const styles: Record<string, Styles> = {
  root: {
    beacon: {
      display: 'none',
    },
    options: {
      overlayColor: alpha(theme.palette.primary.light, 0.4),
      zIndex: theme.zIndex.modal + 1,
    },
    tooltip: {
      width: 387,
      padding: '40px 38px',
      boxShadow: '0px 2px 10px rgba(46, 96, 170, 0.25)',
      borderRadius: '20px',
      userSelect: 'none',
      background: theme.palette.common.white,
    },
  },
};
