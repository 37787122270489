import React, { useEffect, useState } from 'react';

import { Box } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';

import ItemEmptyBackgroundImg from 'src/components/Images/ItemEmptyBackground.svg';

import { ItemPreviewCarouselImageWrapper } from './styled';

interface MediaItemWithFallbackProps {
  imgSource: string;
  itemPreviewCarouselCurrentIndex: number;
  idx: number;
}

const ItemImage = styled('img')`
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center center;
`;

export const MediaItemWithFallback = ({
  imgSource,
  itemPreviewCarouselCurrentIndex,
  idx,
}: MediaItemWithFallbackProps) => {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const image = new Image();
    image.src = imgSource;
    image.onerror = () => {
      setHasError(true);
    };
  }, [imgSource]);

  return hasError ? (
    <ItemPreviewCarouselImageWrapper display={idx === itemPreviewCarouselCurrentIndex ? 'flex' : 'none'}>
      <Box display="flex" justifyContent="center" alignItems="center" width="100%">
        <img src={ItemEmptyBackgroundImg} alt="item" />
      </Box>
    </ItemPreviewCarouselImageWrapper>
  ) : (
    <ItemPreviewCarouselImageWrapper display={idx === itemPreviewCarouselCurrentIndex ? 'flex' : 'none'}>
      <ItemImage src={imgSource} data-testid="img" alt="item" />
    </ItemPreviewCarouselImageWrapper>
  );
};
