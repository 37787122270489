import React from 'react';
import { Trans } from 'react-i18next';

import { Box, Table, TableCell, TableRow } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import moment from 'moment';

import { ApprovalStatus, OrderBy, OrderDirection } from '../../utils/gql';
import Iconography from '../Iconography';
import Scroll from '../Scroll';
import SkeletonCatalogSettingsRow, { CatalogSettingsTableLoader } from '../Skeleton/SkeletonCatalogSettingsRow';

import { ActionButtons, ActionIcons, Subscriber, SubscriptionStatus } from './CustomTableCells';
import { CATALOG_CONFIG_TABLE_LABELS } from './Labels';
import {
  StyledIconButton,
  StyledTableBody,
  StyledTableHead,
  StyledHeaderLabelButton,
  StyledTableContainer,
} from './styled';
import { CatalogConfigTableProps } from './types';

const CatalogConfigTable = ({
  handleSort,
  sortData,
  subscriptionsData,
  catalogName,
  loadingSubscriptions = false,
  onFetchMoreTableRows,
  hasMore,
}: CatalogConfigTableProps) => {
  const {
    palette: {
      text: { primary, disabled },
    },
  } = useTheme();
  const tableCellLabelStyles = { cursor: 'pointer' };

  const { orderDirection: sortOrderDirection, orderBy: sortOrderBy } = sortData;

  const arrowButtonColor = (key: OrderBy, orderDirection: OrderDirection) =>
    sortOrderDirection === orderDirection && sortOrderBy === key ? primary : disabled;

  return (
    <StyledTableContainer>
      <Scroll
        dataLength={subscriptionsData?.length || 0}
        next={onFetchMoreTableRows}
        hasMore={hasMore}
        loader={<CatalogSettingsTableLoader />}
        pageId="catalogSettingPageId"
      >
        <Table stickyHeader>
          <StyledTableHead>
            <TableRow>
              {Object.entries(CATALOG_CONFIG_TABLE_LABELS).map(([key, label], idx) => (
                <TableCell key={idx} align="left">
                  <Box display="flex" alignItems="center">
                    <StyledHeaderLabelButton
                      sx={tableCellLabelStyles}
                      onClick={(e) => handleSort(key as OrderBy, undefined, e)}
                    >
                      {label}
                    </StyledHeaderLabelButton>
                    <Box display="flex" justifyContent="space-between" flexDirection="column" ml="11px">
                      <StyledIconButton onClick={() => handleSort(key as OrderBy, OrderDirection.Asc)} tabIndex={-1}>
                        <Iconography
                          iconName="unfold-arrow-up"
                          htmlColor={arrowButtonColor(key as OrderBy, OrderDirection.Asc)}
                        />
                      </StyledIconButton>
                      <StyledIconButton onClick={() => handleSort(key as OrderBy, OrderDirection.Desc)} tabIndex={-1}>
                        <Iconography
                          iconName="unfold-arrow-down"
                          htmlColor={arrowButtonColor(key as OrderBy, OrderDirection.Desc)}
                        />
                      </StyledIconButton>
                    </Box>
                  </Box>
                </TableCell>
              ))}
              <TableCell align="center">
                <Trans i18nKey="settingsPage.table.labels.actions">Actions</Trans>
              </TableCell>
            </TableRow>
          </StyledTableHead>

          {loadingSubscriptions ? (
            <StyledTableBody>
              <SkeletonCatalogSettingsRow cardsCount={10} />
            </StyledTableBody>
          ) : (
            <StyledTableBody>
              {subscriptionsData?.map(({ id, user: { name, email, avatar }, approvalStatus, subscriptionDate }) => (
                <TableRow key={id}>
                  <TableCell>
                    <Subscriber userName={name} avatar={avatar || ''} />
                  </TableCell>

                  <TableCell>
                    <SubscriptionStatus status={approvalStatus} />
                  </TableCell>

                  <TableCell>{email}</TableCell>

                  <TableCell>{subscriptionDate ? moment(subscriptionDate).format('DD.MM.YYYY') : ''}</TableCell>

                  <TableCell align="center">
                    {approvalStatus === ApprovalStatus.Pending ? (
                      <Box display="flex" justifyContent="center">
                        <ActionButtons subscriptionId={id} />
                      </Box>
                    ) : (
                      <ActionIcons
                        status={approvalStatus}
                        subscriptionId={id}
                        userName={name}
                        catalogName={catalogName}
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </StyledTableBody>
          )}
        </Table>
      </Scroll>
    </StyledTableContainer>
  );
};

export default CatalogConfigTable;
