import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ApolloError, gql, useMutation, useQuery } from '@apollo/client';

import Loader from 'src/components/Loader';
import { MediaDataType } from 'src/components/MediaGallery/constants';
import useUploadMediaDropzone from 'src/hooks/useUploadMediaDropzone';
import { MediaStateContext } from 'src/providers/MediaProvider/context';
import { useSnackbar } from 'src/providers/snackbar';
import {
  checkIsLimitReached,
  createNewProductItem,
  getCatalogInfo,
  getProductTypeById,
  LimitType,
  Query,
  QueryGetProductTypeArgs,
  UpdateProductItemInput,
} from 'src/utils/gql';

import ItemDetails from './ItemDetails';

const ItemCreation = () => {
  const { catalogId, productTypeId } = useParams<{
    catalogId: string;
    productTypeId: string;
  }>();
  const snackbar = useSnackbar();
  const { t } = useTranslation();
  const { uploadingFiles } = useUploadMediaDropzone();

  const { isUploading, mediaState, setMediaState } = useContext(MediaStateContext);

  const [createdItemId, setCreatedItemId] = useState<string | undefined>(undefined);

  const { data: productTypeData, loading: productTypeDataLoading } = useQuery<
    Pick<Query, 'getProductType'>,
    QueryGetProductTypeArgs
  >(getProductTypeById, { variables: { id: productTypeId } });

  const [createProductItem] = useMutation(createNewProductItem, {
    notifyOnNetworkStatusChange: true,
  });

  const handleSave = async (itemData: UpdateProductItemInput) => {
    try {
      isUploading(true);

      let isUnloadedFiles;

      const saveNewMedia = mediaState.find(({ typeAddedData }) =>
        [MediaDataType.FROM_FILE, MediaDataType.FROM_URL].includes(typeAddedData as MediaDataType),
      );

      if (saveNewMedia) {
        const { uploadedFilesUrls, unloadedFiles } = await uploadingFiles();

        Object.assign(itemData, uploadedFilesUrls);

        isUnloadedFiles = Boolean(unloadedFiles.length);
      }

      isUploading(false);

      const mediaFromGallery = mediaState.filter(
        ({ typeAddedData }) => typeAddedData === MediaDataType.FROM_MEDIA_GALLERY,
      );

      if (mediaFromGallery.length) {
        const reusedMedia = {
          mediaToAdd: mediaFromGallery.map(({ id, previewUrl, type, url, orderNumber }) => ({
            mediaId: id,
            previewUrl,
            type,
            url,
            orderNumber,
          })),
        };

        Object.assign(itemData, reusedMedia);
      }

      if (isUnloadedFiles) {
        snackbar(t('uploadMediaErrors.unsupportedExtension'));
      } else {
        const { data: newProductTypeData } = await createProductItem({
          variables: {
            data: {
              productTypeId: Number(productTypeId),
              ...itemData,
            },
            catalogId,
          },
          refetchQueries: [
            { query: checkIsLimitReached, variables: { type: LimitType.Items } },
            { query: getProductTypeById, variables: { id: productTypeId } },
            { query: getCatalogInfo, variables: { id: catalogId } },
          ],

          update(cache, { data }) {
            const createdItem = data?.createProductItem;

            cache.evict({ fieldName: 'getProductItemsByProductTypeId' });
            cache.evict({ id: `ProductItem:${createdItem.id}` });
            cache.gc();

            cache.writeFragment({
              id: `Catalog:${catalogId}`,
              fragment: gql`
                fragment MyCatalog on Catalog {
                  isEmpty
                }
              `,
              data: {
                isEmpty: false,
              },
            });
          },
        });

        setCreatedItemId(newProductTypeData?.createProductItem.id || null);
        setMediaState([]);
      }

      return true;
    } catch (error) {
      const { graphQLErrors, message: errorText } = error as ApolloError;
      const message = graphQLErrors && graphQLErrors.length ? graphQLErrors[0].message : errorText;

      if (error) {
        snackbar(message);
      }

      return false;
    }
  };

  const productTypeName = productTypeData?.getProductType.name;

  const initialItemEditingFormData = useMemo(
    () =>
      productTypeData?.getProductType.fields.reduce(
        (currentFormData: Record<string, string>, { id, required, defaultValue }) => {
          if (required && id && defaultValue) {
            currentFormData[id] = defaultValue;
          }

          return currentFormData;
        },
        {},
      ),
    [productTypeData],
  );

  const locationName = productTypeName
    ? `${t('navBar.contextMenu.recentStepsTitles.productItemCreation')} "${productTypeName}"`
    : '';

  const title = t('productItemCreateEdit.itemCreationTitle');
  const parentLoading = productTypeDataLoading;

  return parentLoading || !initialItemEditingFormData ? (
    <Loader fullAvailableScreen />
  ) : (
    <ItemDetails
      onSave={handleSave}
      title={title}
      locationName={locationName}
      parentLoading={parentLoading}
      currentItemId={createdItemId}
      initialItemEditingFormData={initialItemEditingFormData}
      hideGeneratingTextButton
    />
  );
};

export default ItemCreation;
