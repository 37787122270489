import { Box, styled } from '@mui/material';

import { theme } from 'src/theme';

interface FieldsRowProps {
  disabled: boolean;
}

export const ProductTypeInfoHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.text.secondary,
  width: 'fit-content',

  '&:hover': {
    cursor: 'pointer',
  },
}));

export const ProductTypeSelectWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: '23px',

  '& .MuiButtonBase-root': {
    marginLeft: '10px',
    padding: 0,
    color: theme.palette.primary.main,
  },
}));

export const MappingFieldsHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.text.secondary,
  width: 'fit-content',

  '&:hover': {
    cursor: 'pointer',
  },
}));

export const MappingFieldsWrapper = styled(Box)((props: { arrowSpaceWidth?: number }) => ({
  display: 'grid',
  gridTemplateColumns: `20px 1fr ${props.arrowSpaceWidth || 60}px 1fr 16px 18px`,
  gridColumnGap: '10px',
  gridRowGap: '22px',
  marginTop: '17px',
  alignItems: 'center',
}));
export const MappingFieldsSubheader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '22px',

  '& .MuiBox-root': {
    width: '100%',

    '&:nth-of-type(2)': {
      width: '108px',
    },
  },

  '& .MuiTypography-root': {
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
}));

export const FieldsRowItem = styled(Box)<FieldsRowProps>(({ theme, disabled }) => ({
  display: 'flex',
  justifyContent: 'center',

  '& .MuiIconButton-root': {
    color: theme.palette.primary.light,
    padding: 0,

    '& .MuiSvgIcon-root': {
      width: '18px',
      height: '18px',
    },
  },

  '& .MuiCheckbox-root': {
    color: disabled ? theme.palette.action.disabled : theme.palette.secondary.main,

    '& .MuiSvgIcon-root': {
      height: '21px',
      width: '21px',
    },
  },

  '& .MuiSvgIcon-root#arrow': {
    width: '60px',
  },
}));

export const StyledErrorBox = styled(Box)`
  width: 24px;
  height: 24px;
`;

export const StyledErrorIcon = styled('span')(() => ({
  '&:hover': {
    cursor: 'pointer',
  },

  '& .MuiSvgIcon-root': {
    color: theme.palette.secondary.dark,
  },

  '& .error': {
    color: theme.palette.error.main,
  },
}));
