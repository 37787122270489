import React from 'react';

import { Box } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';

const MainBox = styled(Box)`
  position: absolute;
  width: 328px;
  height: 281px;
  left: 10px;
  opacity: 0.7;
  filter: blur(25px);

  & * {
    position: absolute;
    border-radius: 50%;
  }
`;

const DarkBlueBox = styled(Box)`
  background-color: #6c6ce5;
  box-shadow: 0 0 0 10px #6c6ce5;
`;

const LightBlueBox = styled(Box)`
  background-color: #e3e3fb;
  box-shadow: 0 0 0 10px #e3e3fb;
`;

const IntegrationBlurBackground = () => {
  return (
    <MainBox>
      <DarkBlueBox width="65px" height="65px" right="43px" />

      <DarkBlueBox width="110px" height="105px" top="75px" />

      <DarkBlueBox width="173px" height="150px" bottom="0" right="45px" />

      <DarkBlueBox width="110px" height="100px" right="140px" top="110px" />

      <LightBlueBox width="35px" height="35px" right="130px" top="180px" />

      <DarkBlueBox width="175px" height="100px" right="0" top="70px" />
    </MainBox>
  );
};

export default IntegrationBlurBackground;
