import React from 'react';

import { GoogleOAuthProvider } from '@react-oauth/google';

const withGoogleOauth = (component: React.ReactNode) => {
  const googleOAuthClientId = process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID;

  if (googleOAuthClientId) {
    return <GoogleOAuthProvider clientId={googleOAuthClientId}>{component}</GoogleOAuthProvider>;
  }
  return component;
};

export default withGoogleOauth;
