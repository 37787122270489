import React from 'react';

import { Box } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';

const MainBox = styled(Box)`
  position: absolute;
  top: -100px;
  left: 60px;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  filter: blur(16px);
`;

const DarkBlueBox = styled(Box)`
  position: absolute;
  z-index: 1;
  background-color: #2c2c96;
  box-shadow: 0 0 10px 10px #2c2c96;
`;

const BlackBox = styled(Box)`
  z-index: 2;
  position: absolute;
  background-color: #101d3a;
  box-shadow: 0 0 10px 10px #101d3a;
`;

const OrangeBox = styled(Box)`
  position: absolute;
  background-color: #f4764e;
  box-shadow: 0 0 20px 20px #f4764e;
`;

const GreyBox = styled(Box)`
  z-index: 3;
  position: absolute;
  background-color: #8b96bc;
  box-shadow: 0 0 20px 20px #8b96bc;
`;

const BlueBox = styled(Box)`
  position: absolute;
  background-color: #5e5efc;
  box-shadow: 0 0 20px 20px #5e5efc;
`;

const LightRedBox = styled(Box)`
  position: absolute;
  z-index: 1;
  background-color: #e74a68;
  box-shadow: 0 0 10px 10px #e74a68;
`;

const SuccesScreenImageBackground = () => {
  return (
    <MainBox>
      <GreyBox width="80px" height="30px" left="348px" top="53px" borderRadius="50%" />

      <OrangeBox
        zIndex="1"
        width="60px"
        height="130px"
        top="50px"
        left="130px"
        borderRadius="30%"
        sx={{ transform: 'rotate(-56deg)' }}
      />

      <OrangeBox
        zIndex="1"
        width="10px"
        height="90px"
        top="60px"
        left="310px"
        borderRadius="50%"
        sx={{ transform: 'rotate(-56deg)' }}
      />

      <OrangeBox
        zIndex="1"
        width="1px"
        height="20px"
        top="90px"
        left="60px"
        borderRadius="50%"
        sx={{ transform: 'rotate(-26deg)' }}
      />

      <OrangeBox
        zIndex="1"
        width="10px"
        height="90px"
        top="120px"
        left="470px"
        borderRadius="50%"
        sx={{ transform: 'rotate(-56deg)' }}
      />

      <OrangeBox
        zIndex="2"
        width="90px"
        height="160px"
        top="80px"
        left="350px"
        borderRadius="30%"
        sx={{ transform: 'rotate(-12deg)' }}
      />

      <BlueBox
        width="300px"
        height="90px"
        left="20px"
        top="170px"
        borderRadius="50%"
        sx={{ transform: 'rotate(24deg)' }}
      />

      <BlueBox
        width="300px"
        height="90px"
        left="200px"
        top="170px"
        borderRadius="70%"
        sx={{ transform: 'rotate(24deg)' }}
      />

      <BlueBox
        width="300px"
        height="50px"
        left="-100px"
        top="210px"
        borderRadius="70%"
        sx={{ transform: 'rotate(24deg)' }}
      />

      <LightRedBox
        width="260px"
        height="70px"
        left="123px"
        top="220px"
        borderRadius="20%"
        sx={{ transform: 'rotate(24deg)' }}
      />

      <DarkBlueBox
        width="260px"
        height="50px"
        left="150px"
        top="190px"
        borderRadius="20%"
        sx={{ transform: 'rotate(24deg)' }}
      />

      <BlackBox width="40px" height="40px" left="160px" top="240px" borderRadius="40%" />

      <BlackBox width="40px" height="40px" left="280px" top="290px" borderRadius="40%" />
    </MainBox>
  );
};

export default SuccesScreenImageBackground;
