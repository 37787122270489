import { TFunction } from 'react-i18next';

import { ReactComponent as EbayIcon } from 'src/components/Icon/ebay.svg';
import { ReactComponent as FacebookAndInstagramIcon } from 'src/components/Icon/facebook-and-instagram.svg';
import { ReactComponent as ShopifyIcon } from 'src/components/Icon/shopify.svg';
import { ReactComponent as WooCommerceIcon } from 'src/components/Icon/woo-commerce.svg';
import { IntegrationTypes } from 'src/utils/gql';

export enum ValueAccordingToIntegrationType {
  Icon = 'Icon',
  Title = 'Title',
  HeaderTitle = 'HeaderTitle',
  SelectLabel = 'SelectLabel',
  InputLabel = 'InputLabel',
  EmptyOptionPlaceholder = 'EmptyOptionPlaceholder',
  SubheaderTitle = 'SubheaderTitle',
}

export function getValueAccordingToIntegrationType(
  type: IntegrationTypes,
  returningValue: ValueAccordingToIntegrationType,
  t?: TFunction<'translation'>,
) {
  function typeSwitchCase<T>({
    shopify,
    woocommerce,
    eBay,
    facebook,
  }: {
    shopify: T;
    woocommerce: T;
    eBay: T;
    facebook: T;
  }): T | undefined {
    switch (type) {
      case IntegrationTypes.Shopify:
        return shopify;
      case IntegrationTypes.Woocommerce:
        return woocommerce;
      case IntegrationTypes.Ebay:
        return eBay;
      case IntegrationTypes.Facebook:
        return facebook;
    }
  }

  switch (returningValue) {
    case ValueAccordingToIntegrationType.Icon: {
      return typeSwitchCase({
        shopify: ShopifyIcon,
        woocommerce: WooCommerceIcon,
        eBay: EbayIcon,
        facebook: FacebookAndInstagramIcon,
      });
    }

    case ValueAccordingToIntegrationType.Title: {
      return typeSwitchCase({
        shopify: t?.('productType.mapping.integrationName.shopify'),
        woocommerce: t?.('productType.mapping.integrationName.woocommerce'),
        eBay: t?.('productType.mapping.integrationName.eBay'),
        facebook: t?.('productType.mapping.integrationName.facebook'),
      });
    }

    case ValueAccordingToIntegrationType.HeaderTitle: {
      return typeSwitchCase({
        shopify: `Shopify ${t?.('productType.mapping.type')}`,
        woocommerce: `WooCommerce ${t?.('productType.mapping.category')}`,
        eBay: `eBay ${t?.('productType.mapping.type')}`,
        facebook: `Facebook + Instagram ${t?.('productType.mapping.type')}`,
      });
    }

    case ValueAccordingToIntegrationType.SelectLabel: {
      return typeSwitchCase({
        shopify: t?.('productType.mapping.productTypeName'),
        woocommerce: t?.('productType.mapping.categoryName'),
        eBay: t?.('productType.mapping.productTypeName'),
        facebook: t?.('productType.mapping.productTypeName'),
      });
    }

    case ValueAccordingToIntegrationType.InputLabel: {
      return typeSwitchCase({
        shopify: `${t?.('integrationNames.shopify')} ${t?.('productType.mapping.field')}`,
        woocommerce: `${t?.('integrationNames.woocommerce')} ${t?.('productType.mapping.field')}`,
        eBay: `${t?.('integrationNames.ebay')} ${t?.('productType.mapping.field')}`,
        facebook: `${t?.('integrationNames.facebook')} ${t?.('productType.mapping.field')}`,
      });
    }

    case ValueAccordingToIntegrationType.EmptyOptionPlaceholder: {
      return typeSwitchCase({
        shopify: t?.('productType.mapping.chooseField'),
        woocommerce: t?.('productType.mapping.chooseField'),
        eBay: t?.('productType.mapping.chooseField'),
        facebook: t?.('productType.mapping.chooseField'),
      });
    }

    case ValueAccordingToIntegrationType.SubheaderTitle: {
      return typeSwitchCase({
        shopify: `Shopify ${t?.('productType.mapping.fields').toLowerCase()}`,
        woocommerce: `WooCommerce ${t?.('productType.mapping.categories')}`,
        eBay: `eBay ${t?.('productType.mapping.fields').toLowerCase()}`,
        facebook: `Facebook + Instagram ${t?.('productType.mapping.fields').toLowerCase()}`,
      });
    }
  }
}
