import React, { useEffect } from 'react';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ApolloError, useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@material-ui/core';

import Button from 'src/components/Button';
import EmailInput from 'src/components/Forms/FormInputs/EmailInput';
import TextInput from 'src/components/Forms/FormInputs/TextInput';
import { FormContainer } from 'src/components/Forms/styled';
import { ShopifySignupFormProps, ShopifySignupInput } from 'src/components/Forms/types';
import { shopifySignupForm } from 'src/components/Forms/validationSchemas';
import useDebounceValue from 'src/hooks/useDebounceValue';
import { useAccount } from 'src/providers/account';
import { isSameName, Query, QueryCheckNameAvailabilityArgs } from 'src/utils/gql';

const ShopifySignUpForm = ({ onSubmit, onError, loading, isLoggedIn }: ShopifySignupFormProps) => {
  const { t } = useTranslation();

  const {
    user: { email },
  } = useAccount();

  const formMethods = useForm<ShopifySignupInput>({
    mode: 'onChange',
    resolver: yupResolver(shopifySignupForm),
  });

  const {
    handleSubmit,
    watch,
    setValue,
    formState: { isValid },
    control,
  } = formMethods;

  const currentStoreName = watch('storeName')?.trimEnd?.() ?? '';

  const debouncedSearchTerm = useDebounceValue(currentStoreName, 500);

  const {
    data: sameNameData,
    loading: sameNameLoading,
    called: sameNameCalled,
  } = useQuery<Pick<Query, 'checkNameAvailability'>, QueryCheckNameAvailabilityArgs>(isSameName, {
    fetchPolicy: 'cache-first',
    variables: {
      name: debouncedSearchTerm,
    },
    skip: !(debouncedSearchTerm && isLoggedIn),
  });

  const onSubmitForm = handleSubmit(async ({ email, storeName }: ShopifySignupInput) => {
    try {
      await onSubmit({ email, storeName });
    } catch (error) {
      const { graphQLErrors, message: errorText } = error as ApolloError;
      const message = graphQLErrors?.length ? graphQLErrors[0].message : errorText;
      onError?.(message);
    }
  });

  useEffect(() => {
    if (isLoggedIn) {
      setValue('email', email);
    }
  }, [setValue, email, isLoggedIn]);

  const nameWasChecked =
    !isLoggedIn || (currentStoreName === debouncedSearchTerm && sameNameCalled && !sameNameLoading && sameNameData);
  const sameNameError = isLoggedIn && nameWasChecked && !sameNameData?.checkNameAvailability;

  return (
    <FormProvider {...formMethods}>
      <form aria-label="form" onSubmit={onSubmitForm}>
        <FormContainer>
          {!isLoggedIn && (
            <Box width="100%" mb="25px">
              <EmailInput shouldFocus />
            </Box>
          )}

          <Box width="100%" mb="40px">
            <Controller
              name="storeName"
              control={control}
              render={({ fieldState: { error } }) => (
                <TextInput
                  name="storeName"
                  label={t('enterStore')}
                  isStandard
                  inputNameTestId="storeName"
                  error={sameNameError || !!error}
                  helperText={
                    sameNameError
                      ? t('catalogueCreationPage.sameNameErrorMessage')
                      : error?.message && t(error?.message)
                  }
                />
              )}
            />
          </Box>

          <Button
            color="primary"
            disabled={!(isValid && nameWasChecked) || sameNameError}
            type="submit"
            data-testid="submit"
            variant="contained"
            loading={loading}
          >
            {t(isLoggedIn ? 'registerPage.createButton' : 'signUp')}
          </Button>
        </FormContainer>
      </form>
    </FormProvider>
  );
};

export default ShopifySignUpForm;
