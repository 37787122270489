import { Box, Backdrop, Typography } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';

export const UserSettingsPage = styled(Box)`
  scrollbar-width: thin;
`;

export const UserSettingsContent = styled(Box)`
  padding: 10px 0 0 24px;
  margin-right: 56px;
`;

export const UserSettingsHeader = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  marginBottom: 20,
  height: 40,
}));

export const InstallingBackdrop = styled(Backdrop)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  background: 'rgba(241, 245, 249, 0.7)',
  zIndex: theme.zIndex.tooltip + 1,
}));

export const AddPaymentMethodButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.secondary.main,
  width: 180,

  '&:hover': {
    cursor: 'pointer',
    textDecoration: 'underline',
    textDecorationColor: theme.palette.secondary.main,
  },
}));

export const IntegrationSecondaryText = styled(Typography)({
  fontSize: '12px',
  lineHeight: '14px',
});

export const StyledLink = styled('a')(({ theme }) => ({
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '15px',
  lineHeight: '18px',
  alignItems: 'center',
  textDecoration: 'underline',
  color: theme.palette.text.secondary,

  '&:hover': {
    cursor: 'pointer',
  },
}));

export const StyledBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

export const IntegrationsCardsWrapper = styled(Box)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  grid-column-gap: 24px;
  width: 100%;
`;
