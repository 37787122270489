import React, { useMemo } from 'react';

import { useQuery } from '@apollo/client';

import { getProductTypeFiltersById, Query, QueryGetProductTypeFiltersWithItemsCountArgs } from '../utils/gql';

import SingleSelect, { SingleSelectProps } from './SingleSelect';

interface AutoLoadingSingleSelectProps extends Omit<SingleSelectProps, 'options'> {
  productTypeId: string;
  productTypeFieldId: string;
}

const AutoLoadingSingleSelect = ({ productTypeFieldId, productTypeId, ...rest }: AutoLoadingSingleSelectProps) => {
  const { data } = useQuery<
    Pick<Query, 'getProductTypeFiltersWithItemsCount'>,
    QueryGetProductTypeFiltersWithItemsCountArgs
  >(getProductTypeFiltersById, {
    fetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    variables: {
      id: productTypeId,
    },
  });

  const options = useMemo(
    () =>
      data?.getProductTypeFiltersWithItemsCount.filters.find(({ productTypeFieldId: id }) => id === productTypeFieldId)
        ?.values || [],
    [data, productTypeFieldId],
  );

  return <SingleSelect options={options} {...rest} />;
};

export default AutoLoadingSingleSelect;
