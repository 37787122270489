import React, { ChangeEvent, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Box } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';

import PasswordInputRequirements from 'src/components/Forms/FormInputs/PasswordInputRequirements';
import { FormField } from 'src/components/Forms/styled';
import { PasswordInputProps } from 'src/components/Forms/types';
import Iconography from 'src/components/Iconography';
import { validateTextFieldValue } from 'src/helpers/validationCheck';

const PasswordInput = ({ hasRequirements, shouldFocus }: PasswordInputProps) => {
  const { t } = useTranslation();
  const {
    watch,
    register,
    formState: { errors, dirtyFields },
    setValue,
  } = useFormContext();

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isBlur, setIsBlur] = useState<boolean>(false);

  const passwordValue = watch('password');
  const passwordErrorMessage = hasRequirements ? '' : errors?.password?.message;

  const handleClickShowPassword = () => {
    setValue('password', passwordValue);
    setShowPassword(!showPassword);
  };

  return (
    <>
      <FormField
        {...register('password', {
          onChange: (e: ChangeEvent<HTMLInputElement>) => {
            setValue('password', validateTextFieldValue(e.target.value.trimEnd()));
          },
        })}
        label={t('enterPassword')}
        type={showPassword ? 'text' : 'password'}
        variant="standard"
        error={!!errors.password}
        helperText={isBlur ? t(passwordErrorMessage as string) : ''}
        fullWidth
        onFocus={() => setIsBlur(false)}
        onBlur={() => setIsBlur(true)}
        autoComplete="current-password"
        inputProps={{
          'data-testid': 'password',
          autoFocus: shouldFocus,
        }}
        name="password"
        InputProps={{
          endAdornment: (
            <>
              {passwordValue && (
                <InputAdornment position="end" data-testid="buttonRevealEye">
                  <IconButton onClick={handleClickShowPassword} disableRipple>
                    <Iconography iconName={`visibility-${showPassword ? 'on' : 'off'}`} htmlColor="secondary" />
                  </IconButton>
                </InputAdornment>
              )}
            </>
          ),
        }}
      />

      {hasRequirements && (
        <Box mt="15px">
          <PasswordInputRequirements password={passwordValue || ''} isTouched={dirtyFields?.password} />
        </Box>
      )}
    </>
  );
};

export default PasswordInput;
