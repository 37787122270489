import React, { Suspense, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { RouteProps } from 'react-router';
import { RouteConfig } from 'react-router-config';

import { useQuery } from '@apollo/client';
import { Box } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import AdapterMoment from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { useFlag } from '@unleash/proxy-client-react';
import Cookies from 'js-cookie';

import DemoStandBanner from 'src/components/DemoStandBanner';
import SubscriptionExpiredBanner from 'src/components/SubscriptionExpiredBanner';
import { FeatureFlag } from 'src/constants';
import { AccountProvider } from 'src/providers/account';
import CatalogsProvider from 'src/providers/catalog/provider';
import LimitReachedProvider from 'src/providers/limitReached/provider';
import MediaProvider from 'src/providers/MediaProvider/provider';
import useOnboarding from 'src/providers/OnboardingProvider/hooks/useOnboarding';
import OnboardingProvider from 'src/providers/OnboardingProvider/provider';
import { checkIsActivePlanSubscription, Query } from 'src/utils/gql';
import RenderRoutes from 'src/utils/RenderRoutes';

import MainLayout from './Main';

interface DashboardProps extends RouteProps {
  route?: RouteConfig;
}

export function DashboardProvider(props: DashboardProps) {
  return (
    <AccountProvider>
      <Suspense fallback={<LinearProgress />}>
        <DndProvider backend={HTML5Backend}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <LimitReachedProvider>
              <CatalogsProvider>
                <MediaProvider>
                  <OnboardingProvider>
                    <Dashboard {...props} />
                  </OnboardingProvider>
                </MediaProvider>
              </CatalogsProvider>
            </LimitReachedProvider>
          </LocalizationProvider>
        </DndProvider>
      </Suspense>
    </AccountProvider>
  );
}

function Dashboard({ route }: DashboardProps) {
  const {
    onboardingState: { tourActive },
  } = useOnboarding();

  const changelogBannerClosed = Cookies.get('changelogBannerClosed');

  const isChangelogFeatureActive = useFlag(FeatureFlag.CHANGELOG);
  const [showExpirePlanBanner, setShowExpirePlanBanner] = useState<boolean>(false);
  const [showDemoBanner, setShowDemoBanner] = useState<boolean>(changelogBannerClosed === 'false');

  useQuery<Pick<Query, 'checkIsActivePlanSubscription'>>(checkIsActivePlanSubscription, {
    fetchPolicy: 'network-only',
    onCompleted: ({ checkIsActivePlanSubscription }) => {
      setShowExpirePlanBanner(!checkIsActivePlanSubscription);
    },
  });

  const handleDemoBannerClose = () => {
    Cookies.set('changelogBannerClosed', 'true');
    setShowDemoBanner(false);
  };

  const handleBannerClose = () => {
    setShowExpirePlanBanner(false);
  };

  useEffect(() => {
    if (tourActive) {
      setShowDemoBanner(false);
    }

    if (!tourActive && changelogBannerClosed === 'false') {
      setShowDemoBanner(true);
    }
  }, [tourActive, changelogBannerClosed]);

  return (
    <Box data-testid="content">
      <Suspense fallback={<LinearProgress />}>
        <MainLayout showBanner={showExpirePlanBanner}>
          <Box width="100%" position="relative" minWidth="0px" id="page-anchor">
            <Box position="relative" width="calc(100% - 10px)" zIndex="1300">
              {isChangelogFeatureActive && (
                <DemoStandBanner showBanner={showDemoBanner} handleClose={handleDemoBannerClose} />
              )}

              <SubscriptionExpiredBanner
                show={showExpirePlanBanner}
                isDemoShown={showDemoBanner}
                handleClose={handleBannerClose}
              />
            </Box>

            {route && <RenderRoutes routes={route.routes} />}
          </Box>
        </MainLayout>
      </Suspense>
    </Box>
  );
}

export default Dashboard;
