import React from 'react';
import { useTranslation } from 'react-i18next';

import { ApolloError } from '@apollo/client';
import { CodeResponse, useGoogleLogin } from '@react-oauth/google';

import { GenericFormProps } from 'src/components/Forms/types';
import GoogleLogo from 'src/components/Icon/google-logo.svg';
import Loader from 'src/components/Loader';
import { GoogleLogin } from 'src/utils/gql/types';

import { GoogleBtn, GoogleBtnText } from './styled';

const GoogleAuthButton = ({ onSubmit, onError, loading }: GenericFormProps<GoogleLogin>) => {
  const { t } = useTranslation();

  const onSubmitForm = async (code: string) => {
    try {
      await onSubmit({
        code,
      });
    } catch (error) {
      const { graphQLErrors, message: errorText } = error as ApolloError;
      const message = graphQLErrors?.length ? graphQLErrors[0].message : errorText;
      onError?.(message);
    }
  };

  const handleLogin = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: ({ code }: Omit<CodeResponse, 'error_description' | 'error' | 'error_uri'>) => onSubmitForm(code),
    onError: ({ error_description }: Pick<CodeResponse, 'error_description' | 'error' | 'error_uri'>) => {
      const message: string = error_description ?? t('unexpectedError');
      onError?.(message);
    },
  });

  return (
    <GoogleBtn onClick={handleLogin} disabled={loading} data-testid="googleBtn">
      {loading && <Loader size="button" />}
      <img src={GoogleLogo} alt="google" />
      <GoogleBtnText>{t('continueWithGoogle')}</GoogleBtnText>
    </GoogleBtn>
  );
};

export default GoogleAuthButton;
