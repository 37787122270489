import React from 'react';

import { Box } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';

const MainBox = styled(Box)`
  position: absolute;
  width: 320px;
  height: 250px;
  left: 10px;
  opacity: 0.7;
  filter: blur(25px);

  & * {
    position: absolute;
    border-radius: 50%;
  }
`;

const PurpleBox = styled(Box)`
  background-color: #6c6ce5;
  box-shadow: 0 0 0 10px #6c6ce5;
`;

const LightPurpleBox = styled(Box)`
  background-color: #adafee;
  box-shadow: 0 0 0 20px #adafee;
`;

const LightBlueBox = styled(Box)`
  background-color: #e3e3fb;
  box-shadow: 0 0 0 20px #e3e3fb;
`;

const CreateCatalogSecondStepBackground = () => {
  return (
    <MainBox>
      <LightBlueBox width="125px" height="140px" right="0" top="30%" />

      <LightPurpleBox width="69px" height="76px" right="107px" top="130px" />

      <PurpleBox width="75%" height="60%" left="17%" />

      <PurpleBox width="50%" height="40%" top="40px" />

      <LightPurpleBox width="35px" height="45px" right="120px" top="17px" />

      <LightPurpleBox width="205px" height="50px" right="50px" top="197px" />
    </MainBox>
  );
};

export default CreateCatalogSecondStepBackground;
