import React, { KeyboardEvent, SyntheticEvent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { Box, CardProps, Grid, IconButton, Typography, Link } from '@material-ui/core';
import MuiCard from '@material-ui/core/Card';
import MuiCardContent from '@material-ui/core/CardContent';
import MuiCardHeader from '@material-ui/core/CardHeader';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { useFlag } from '@unleash/proxy-client-react';
import clsx from 'clsx';
import moment from 'moment';
import { capitalize } from 'validate.js';

import Iconography from 'src/components/Iconography';
import { FeatureFlag } from 'src/constants';
import { CATALOG_SETTINGS, CATALOGS_PATH, FIRST_DASHBOARD_PATHNAME } from 'src/constants/routeSources';
import { useCheckCatalogMappingErrors } from 'src/hooks/graphQLRequestsHooks';
import useDataTransferCompletedSnackbar from 'src/hooks/useDataTransferCompletedSnackbar';
import useDataTransferStatus from 'src/providers/catalog/hooks/useDataTransferStatus';
import useOnboarding from 'src/providers/OnboardingProvider/hooks/useOnboarding';
import {
  ApprovalStatus,
  CatalogJobType,
  CatalogPrivacy,
  DuplicatedSkuInfo,
  Integration,
  IntegrationMappingError,
  Maybe,
  Reason,
} from 'src/utils/gql';
import { CardCaption, PriceBackground } from 'src/views/Catalogs/styled';
import { getUrlForCatalogCardBackground } from 'src/views/Catalogs/utils';

import DuplicatedSKUMessage, { DuplicatedSKUMessageType } from '../DuplicatedSKUMessage';
import ErrorableSyncButton from '../ErrorableSyncButton';
import Hint, { HintType } from '../Hint';
import { ReactComponent as SharedIcon } from '../Icon/shared-icon.svg';
import IntegrationUnavailableMessage from '../IntegrationUnavailableMessage';
import Loader from '../Loader';

interface StyleProps {
  source: string;
  size: keyof typeof CatalogueCardSizes;
  isChecked?: boolean;
  isEmpty?: boolean;
  duplicatedSKU?: boolean;
}

export enum CatalogueCardSizes {
  large = 'large',
  medium = 'medium',
  small = 'small',
}

enum KeyboardKeyNames {
  enter = 'Enter',
}

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: theme.palette.error.light,
    borderRadius: 5,
    padding: '10px 20px',
    width: 185,
  },

  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.error.light,
  },
}));

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  root: {
    position: 'relative',
    background: getUrlForCatalogCardBackground(theme),
    '&$cardChecked': {
      color: theme.palette.text.primary,
      border: `1px solid ${theme.palette.secondary.main}`,
      boxShadow: '0 2px 15px rgba(241, 97, 82, 0.35);',
    },
  },
  mediumCardExportIconStyles: {
    padding: 0,
  },
  smallCardExportIconStyles: {
    padding: '12px 19px 12px 0',
  },
  smallCardSettingsIconStyles: {
    left: ({ isChecked }) => (isChecked ? '12px' : '11px'),
  },
  sizeSmall: {
    width: '296px',
    height: '137px',
    padding: '18px 20px 20px 25px',
    marginBottom: 20,
  },
  sizeMedium: {
    minWidth: '356px',
    padding: '30px 30px 30px 25px',
  },
  sizeLarge: {
    maxWidth: '1120px',
    padding: '35px 40px 35px 35px',
  },
  cardTitle: {
    whiteSpace: 'nowrap',
    fontSize: '15px',
    lineHeight: '18px',
    fontWeight: 500,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  cardChecked: {
    border: `1px solid ${theme.palette.secondary.main}`,
  },
  iconsStyles: {
    color: theme.palette.text.primary,
  },
  subscribeText: {
    fontSize: '12px',
    padding: '0 10px',
    height: '24px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      color: theme.palette.background.default,
    },
  },
  subscribeButton: {
    background: theme.palette.text.disabled,
    height: '24px',
    borderRadius: '5px',
    marginTop: '10px',
    '&:hover': {
      background: theme.palette.text.secondary,
      cursor: 'pointer',
    },
  },
  priceBackgroundOpacity: {
    opacity: 0.5,
  },
  sharedIconMargin: {
    marginLeft: '7px',
    marginBottom: '7px',
  },
  iconButtonSettings: {
    padding: '0',
    marginRight: '12px',
  },
  titleText: {
    height: '52px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: theme.palette.text.primary,
  },
  priceWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  priceText: {
    fontSize: '15px',
    fontWeight: '500',
    lineHeight: '18px',
    color: theme.palette.common.white,
  },
  priceTextSmall: {
    fontSize: '12px',
    fontWeight: '700',
    lineHeight: '14px',
    color: theme.palette.common.white,
  },
  priceCap: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  colorChecked: {
    color: ({ isChecked }) => (isChecked ? theme.palette.text.primary : theme.palette.text.secondary),
  },
  date: {
    position: 'absolute',
    left: 25,
  },
  tooltipBody: {
    maxWidth: ({ duplicatedSKU }) => (duplicatedSKU ? '231px' : '195px'),
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '400',
    boxSizing: 'border-box',
    backgroundColor: theme.palette.common.white,
    fontSize: '12px',
    lineHeight: '14px',
    color: theme.palette.text.primary,
    borderRadius: '5px',
    boxShadow: '0 2px 10px rgba(46, 96, 170, 0.25)',
    padding: '10px 20px',
  },
  arrow: {
    color: theme.palette.common.white,
  },
  errorLink: {
    cursor: 'pointer',
    marginLeft: '2px',
    textDecoration: 'underline',
  },
}));

interface CatalogCardProps extends CardProps {
  id: string;
  hasAttachedIntegrations?: boolean;
  syncable?: boolean;
  size: keyof typeof CatalogueCardSizes;
  headerTitle: string;
  source: string;
  catalogPrivacy: string;
  subscriptionApprovalStatus?: string;
  createdAt?: string;
  price?: {
    value: string;
    frequency: string;
  };
  isSharedCatalog?: boolean;
  isChecked?: boolean;
  isDeletable?: boolean;
  onCardSelect?: () => void;
  onUnsubscribeButtonClick?: (e: SyntheticEvent) => void;
  onDeleteIconClick?: (e: SyntheticEvent) => void;
  onExportIconClick?: (e: SyntheticEvent) => void;
  onSyncButtonClick?: (e: SyntheticEvent) => void;
  onTryAgainLinkClick?: (e: SyntheticEvent) => void;
  isEmpty?: boolean;
  duplicatedSKUInfo?: DuplicatedSkuInfo;
  mappingErrors?: IntegrationMappingError[];
  integrations?: Maybe<Integration[]>;
}

function CatalogCard({
  hasAttachedIntegrations,
  syncable,
  headerTitle,
  onCardSelect,
  onDeleteIconClick,
  isDeletable,
  onExportIconClick,
  onUnsubscribeButtonClick,
  onSyncButtonClick,
  onTryAgainLinkClick,
  catalogPrivacy,
  size,
  isChecked,
  source,
  createdAt,
  isEmpty,
  duplicatedSKUInfo,
  id,
  isSharedCatalog,
  price,
  subscriptionApprovalStatus,
  mappingErrors,
  integrations,
  children,
}: CatalogCardProps) {
  const duplicatedSKU = duplicatedSKUInfo?.isDuplicated;
  const classes = useStyles({ source, size, isChecked, isEmpty, duplicatedSKU });
  const history = useHistory();
  const { t } = useTranslation();
  const {
    onboardingState: { tourActive },
  } = useOnboarding();
  const isSharedCatalogsFeatureActive = useFlag(FeatureFlag.SHARED_CATALOGS);

  const { checkCatalogMappingsErrorsQuery, loading: loadingErrors } = useCheckCatalogMappingErrors(id);

  const { showDataTransferCompletedSnackbar } = useDataTransferCompletedSnackbar();
  const {
    inProgress: isLoading,
    type: dataTransferType,
    errorMessage: dataTransferErrorMessage,
    endTime: dataTransferEndTime,
  } = useDataTransferStatus({
    catalogId: id,
    onCompleted: (job) => {
      checkCatalogMappingsErrorsQuery();
      showDataTransferCompletedSnackbar(job);
    },
  });

  const integrationUnavailableErrors = useMemo(
    () => mappingErrors?.filter(({ reason }) => reason === Reason.IntegrationUnavailable),
    [mappingErrors],
  );
  const isSharedCatalogNotAvailable = isSharedCatalog && subscriptionApprovalStatus === ApprovalStatus.Pending;
  const showSharedCatalogIcon = !isSharedCatalog && catalogPrivacy !== CatalogPrivacy.Private;
  const hasImportJobError = dataTransferType === CatalogJobType.Import && dataTransferErrorMessage;
  const hasExportJobError = dataTransferType === CatalogJobType.Export && dataTransferErrorMessage;
  const unsyncable = !(syncable === undefined || syncable);
  const errorableSyncButtonDisabled = isEmpty || duplicatedSKU || unsyncable;
  const errorableSyncButtonTooltipWidth =
    duplicatedSKU || unsyncable ? '231px' : integrationUnavailableErrors?.length ? '240px' : undefined;

  const titleHint = t('settingsPage.attachedIntegrations.hintAtAnEmptyCatalog');
  const tooltipTitle = t('cataloguesPage.catalogueCard.errorAboutEmptyCatalog');
  const unsyncableHint = t('cataloguesPage.catalogueCard.unsyncableErrorHint');
  const title = isEmpty ? (
    tooltipTitle
  ) : duplicatedSKU ? (
    <DuplicatedSKUMessage
      type={DuplicatedSKUMessageType.simpleExport}
      catalogId={id}
      duplicatedSKUInfo={duplicatedSKUInfo}
    />
  ) : (
    ''
  );
  const customTooltipContent = isEmpty ? (
    titleHint
  ) : unsyncable ? (
    unsyncableHint
  ) : duplicatedSKU ? (
    <DuplicatedSKUMessage type={DuplicatedSKUMessageType.basic} catalogId={id} duplicatedSKUInfo={duplicatedSKUInfo} />
  ) : integrationUnavailableErrors?.length ? (
    <IntegrationUnavailableMessage
      integrations={integrations}
      onTryAgainButtonClick={onSyncButtonClick}
      integrationUnavailableErrors={integrationUnavailableErrors}
    />
  ) : null;
  const hintWidth = duplicatedSKU ? '231px' : '195px';

  const handleCardSelect = () => {
    if (!isSharedCatalogNotAvailable && onCardSelect) {
      onCardSelect();
    }
  };

  const onEnterPress = ({ key }: KeyboardEvent<HTMLElement>) => {
    if (key === KeyboardKeyNames.enter && onCardSelect) {
      handleCardSelect();
    }
  };

  const onEnterUnsubscribePress = (event: KeyboardEvent<HTMLElement>) => {
    if (event.key === KeyboardKeyNames.enter && onUnsubscribeButtonClick) {
      onUnsubscribeButtonClick(event);
    }
  };

  const onDeleteIconPress = (event: KeyboardEvent<HTMLElement>) => {
    if (event.key === KeyboardKeyNames.enter && onDeleteIconClick) {
      onDeleteIconClick(event);
    }
  };

  const onExportIconPress = (event: KeyboardEvent<HTMLElement>) => {
    if (event.key === KeyboardKeyNames.enter && onExportIconClick) {
      onExportIconClick(event);
    }
  };

  const handleTryAgainLinkPress = (event: KeyboardEvent<HTMLElement>) => {
    if (event.key === KeyboardKeyNames.enter && onTryAgainLinkClick) {
      onTryAgainLinkClick(event);
    }
  };

  const onSettingsIconClick = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>, id: string | undefined) => {
    if (tourActive) return;

    e.stopPropagation();
    history.push(`/${FIRST_DASHBOARD_PATHNAME}/${CATALOGS_PATH}/${id}/${CATALOG_SETTINGS}`);
  };

  const renderPrice = () =>
    price && (
      <Box tabIndex={-1} className={classes.priceWrapper}>
        <PriceBackground className={isSharedCatalogNotAvailable ? classes.priceBackgroundOpacity : undefined}>
          <Typography
            className={clsx(
              size === CatalogueCardSizes.small ? classes.priceTextSmall : classes.priceText,
              classes.priceCap,
            )}
          >
            {price.value}
          </Typography>

          <Typography className={size === CatalogueCardSizes.small ? classes.priceTextSmall : classes.priceText}>
            {capitalize(price.frequency)}
          </Typography>
        </PriceBackground>

        {isSharedCatalogNotAvailable && (
          <Typography variant="caption" position="absolute" top={size === CatalogueCardSizes.small ? 67 : 83}>
            {t('cataloguesPage.catalogueCard.notConfirmed')}
          </Typography>
        )}
      </Box>
    );

  const muiCardHeaderAction = () => {
    if (isSharedCatalog) {
      return (
        <Box display="flex" flexDirection="column" alignItems="center">
          {renderPrice()}
        </Box>
      );
    }
    if (!isDeletable) {
      return (
        <IconButton
          className={classes.iconsStyles}
          style={{
            marginRight: '-5px',
          }}
          edge="end"
          onClick={onDeleteIconClick}
          onKeyPress={onDeleteIconPress}
          href=""
          data-testid={`deleteCatalogButton${headerTitle}`}
        >
          <Iconography iconName="trash-fill" />
        </IconButton>
      );
    }
  };

  const renderSyncDate = (): string | null => {
    if (dataTransferEndTime) {
      const isJustNow = moment().isBetween(
        moment(dataTransferEndTime).subtract(30, 'second'),
        moment(dataTransferEndTime).add(30, 'second'),
      );

      return isJustNow
        ? t('cataloguesPage.catalogueCard.syncedNow')
        : `${t('cataloguesPage.catalogueCard.syncedOn')} ${moment(dataTransferEndTime).format('MMMM DD, YYYY')}`;
    }

    if (tourActive && size === 'small') {
      return t('cataloguesPage.catalogueCard.syncedNow');
    }

    return null;
  };

  const renderErrorHint = (errorMessage: string) => (
    <StyledTooltip
      arrow
      placement="top"
      title={
        <Typography color="error" fontSize="12px" lineHeight="14px">
          {errorMessage}
        </Typography>
      }
    >
      <Box display="flex">
        <Iconography iconName="cancel-circle" color="error" />
      </Box>
    </StyledTooltip>
  );

  const renderSyncLoadingOrErrorContent = () => {
    const isSmallSizeCard = size === CatalogueCardSizes.small;
    const showError = hasImportJobError && !isSmallSizeCard;

    const getSyncStatus = () => {
      switch (dataTransferType) {
        case CatalogJobType.Import:
          return t('cataloguesPage.catalogueCard.importProgress');

        case CatalogJobType.Export:
          return t('cataloguesPage.catalogueCard.exportProgress');

        default:
          return t('cataloguesPage.catalogueCard.syncing');
      }
    };

    return (
      <Box minHeight={isSmallSizeCard ? '50px' : '150px'}>
        <Box display="flex" alignItems="center" justifyContent="space-between" height="48px">
          <Box display="flex" alignItems="center">
            <Typography
              variant="subtitle2"
              color={showError ? 'error' : 'primary'}
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
              maxWidth="240px"
              mr="7px"
            >
              {headerTitle}
            </Typography>

            {showError && renderErrorHint(t('cataloguesPage.catalogueCard.importErrorHint'))}
          </Box>

          {showError && (
            <IconButton
              className={classes.iconsStyles}
              style={{
                marginRight: '-12px',
              }}
              edge="end"
              onClick={onDeleteIconClick}
              onKeyPress={onDeleteIconPress}
              href=""
              data-testid={`deleteCatalogueButton${headerTitle}`}
            >
              <Iconography iconName="trash-fill" />
            </IconButton>
          )}
        </Box>

        <Box display="flex" alignItems="center" flexDirection="column" mt={isSmallSizeCard ? '-10px' : '10px'}>
          {showError ? (
            <Typography fontSize="12px" maxWidth="175px" lineHeight="14px">
              {t('cataloguesPage.catalogueCard.importError1')}
              <Link
                component="button"
                color="secondary"
                onClick={onTryAgainLinkClick}
                onKeyPress={handleTryAgainLinkPress}
              >
                {t('cataloguesPage.catalogueCard.importErrorLink')}
              </Link>{' '}
              {t('cataloguesPage.catalogueCard.importError2')}
            </Typography>
          ) : (
            <>
              <Loader size={isSmallSizeCard ? 'extraSmallIntegration' : 'smallIntegration'} />

              <Typography
                color="text.secondary"
                mt="20px"
                fontSize={isSmallSizeCard ? '11px' : '12px'}
                lineHeight="14px"
              >
                {getSyncStatus()}
              </Typography>
            </>
          )}
        </Box>
      </Box>
    );
  };

  const getTitleColor = () => {
    if (isSharedCatalogNotAvailable) {
      return 'text.disabled';
    }

    if (hasExportJobError) {
      return 'error.main';
    }

    return 'text.primary';
  };

  return (
    <>
      {size === CatalogueCardSizes.large && (
        <MuiCard
          className={clsx(classes.root, classes.sizeLarge, isChecked && classes.cardChecked)}
          onClick={onCardSelect}
          tabIndex={0}
          data-testid={`largeCatalogueCard${headerTitle}`}
        >
          <MuiCardContent>{children}</MuiCardContent>
        </MuiCard>
      )}

      {size === CatalogueCardSizes.medium && (
        <MuiCard
          id="catalog-card-anchor"
          style={{ cursor: isSharedCatalogNotAvailable ? 'initial' : 'pointer' }}
          className={clsx(classes.root, classes.sizeMedium, isChecked && classes.cardChecked)}
          onClick={handleCardSelect}
          onKeyPress={onEnterPress}
          tabIndex={0}
          data-testid={`mediumCatalogueCard${headerTitle}`}
        >
          {isLoading || loadingErrors ? (
            renderSyncLoadingOrErrorContent()
          ) : (
            <>
              <MuiCardHeader
                action={muiCardHeaderAction()}
                title={
                  <Box width="auto" display="flex" maxWidth="277px" color={getTitleColor()}>
                    <Typography
                      className={classes.cardTitle}
                      height="68px"
                      paddingTop="12px"
                      maxWidth="229px"
                      color="inherit"
                    >
                      {headerTitle}
                    </Typography>

                    {hasExportJobError && (
                      <Box mt="8px" ml="5px">
                        {renderErrorHint(t('cataloguesPage.catalogueCard.exportErrorHint'))}
                      </Box>
                    )}

                    {isSharedCatalogsFeatureActive && (
                      <Box>{showSharedCatalogIcon && <SharedIcon className={classes.sharedIconMargin} />}</Box>
                    )}
                  </Box>
                }
              />
              <Grid container justifyContent="space-between" marginTop="34px" alignItems="center" height="48px">
                <Grid item>
                  <Grid container direction="column">
                    <Typography color="primary" fontSize="12px">
                      {renderSyncDate()}
                    </Typography>
                    {(isSharedCatalog && !isSharedCatalogNotAvailable && (
                      <Box
                        className={classes.subscribeButton}
                        onClick={onUnsubscribeButtonClick}
                        onKeyPress={onEnterUnsubscribePress}
                        tabIndex={0}
                      >
                        <Typography className={classes.subscribeText} data-testid={`unsubscribeButton${headerTitle}`}>
                          {t('cataloguesPage.catalogueCard.unsubscribeButton')}
                        </Typography>
                      </Box>
                    )) ||
                      (isSharedCatalog && isSharedCatalogNotAvailable && (
                        <Box className={classes.subscribeButton}>
                          <Typography
                            onClick={onUnsubscribeButtonClick}
                            onKeyPress={onEnterUnsubscribePress}
                            className={classes.subscribeText}
                            data-testid={`cancelSubscriptionRequestButton${headerTitle}`}
                            tabIndex={0}
                          >
                            {t('cataloguesPage.catalogueCard.cancelSubscriptionRequestButton')}
                          </Typography>
                        </Box>
                      )) || (
                        <CardCaption className={classes.date} top={66}>
                          {moment(createdAt).format('MMMM DD, YYYY')}
                        </CardCaption>
                      )}
                  </Grid>
                </Grid>
                <Grid item alignItems="center" display="flex">
                  {!isSharedCatalog && (
                    <>
                      {hasAttachedIntegrations && (
                        <Box onClick={(e) => e.stopPropagation()} sx={{ marginRight: '12px' }}>
                          <ErrorableSyncButton
                            catalogId={id}
                            dataTestId={`syncCatalogueButton${headerTitle}`}
                            onClick={onSyncButtonClick}
                            disabled={errorableSyncButtonDisabled}
                            errors={mappingErrors}
                            tooltipWidth={errorableSyncButtonTooltipWidth}
                            tooltipParams={{ customTooltipContent }}
                          />
                        </Box>
                      )}

                      <IconButton
                        className={clsx(classes.iconsStyles, classes.iconButtonSettings)}
                        edge="end"
                        href=""
                        onClick={(e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) =>
                          onSettingsIconClick(e, id)
                        }
                        data-testid={`settingsCatalogButton${headerTitle}`}
                      >
                        <Iconography iconName="settings" />
                      </IconButton>
                    </>
                  )}
                  {!isSharedCatalogNotAvailable && (
                    <Box onClick={(e) => e.stopPropagation()}>
                      <Hint placement="left" type={HintType.hover} title={title} tooltipWidth={hintWidth}>
                        <Box onClick={(e) => e.stopPropagation()}>
                          <IconButton
                            className={clsx(classes.iconsStyles, classes.mediumCardExportIconStyles)}
                            onClick={onExportIconClick}
                            onKeyPress={onExportIconPress}
                            href=""
                            data-testid={`exportCatalogButton${headerTitle}`}
                            disabled={isEmpty}
                          >
                            <Iconography iconName="share" />
                          </IconButton>
                        </Box>
                      </Hint>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </>
          )}
        </MuiCard>
      )}

      {size === CatalogueCardSizes.small && (
        <MuiCard
          style={{ cursor: isSharedCatalogNotAvailable ? 'initial' : 'pointer' }}
          className={clsx(classes.root, classes.sizeSmall, isChecked && classes.cardChecked)}
          onClick={handleCardSelect}
          onKeyPress={onEnterPress}
          tabIndex={0}
          data-testid={`smallCatalogueCard${headerTitle}`}
        >
          {isLoading || loadingErrors ? (
            renderSyncLoadingOrErrorContent()
          ) : (
            <>
              {!isSharedCatalog ? (
                <>
                  <MuiCardHeader
                    action={
                      <Hint placement="left" type={HintType.hover} title={title} tooltipWidth={hintWidth}>
                        <Box onClick={(e) => e.stopPropagation()}>
                          <IconButton
                            onClick={onExportIconClick}
                            onKeyPress={onExportIconPress}
                            className={clsx(classes.iconsStyles, classes.smallCardExportIconStyles)}
                            edge="end"
                            href=""
                            data-testid={`exportCatalogueButton${headerTitle}`}
                            disabled={isEmpty}
                          >
                            <Iconography id="export-btn-anchor" iconName="share" />
                          </IconButton>
                        </Box>
                      </Hint>
                    }
                    title={
                      <Box width="210px" alignItems="center" display="flex" className={classes.cardTitle}>
                        <Typography
                          maxWidth="140px"
                          className={classes.cardTitle}
                          color={hasExportJobError ? 'error' : 'primary'}
                        >
                          {headerTitle}
                        </Typography>

                        {hasExportJobError && (
                          <Box ml="7px">{renderErrorHint(t('cataloguesPage.catalogueCard.exportErrorHint'))}</Box>
                        )}

                        {isSharedCatalogsFeatureActive && (
                          <Box>{showSharedCatalogIcon && <SharedIcon className={classes.sharedIconMargin} />}</Box>
                        )}
                      </Box>
                    }
                  />

                  <MuiCardContent>
                    <Grid container justifyContent="space-between" marginTop="26px" alignItems="center">
                      <Grid item>
                        <Grid container direction="column">
                          <Typography variant="caption" color="primary">
                            {renderSyncDate()}
                          </Typography>

                          <Typography variant="caption" color="text.secondary" className={classes.date} top={56}>
                            {moment(createdAt).format('MMMM DD, YYYY')}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Box onClick={(e) => e.stopPropagation()} mr="9px" alignItems="center" display="flex">
                        {hasAttachedIntegrations && (
                          <ErrorableSyncButton
                            catalogId={id}
                            dataTestId={`syncCatalogueButton${headerTitle}`}
                            onClick={onSyncButtonClick}
                            disabled={errorableSyncButtonDisabled}
                            tooltipWidth={errorableSyncButtonTooltipWidth}
                            errors={mappingErrors}
                            tooltipParams={{ customTooltipContent }}
                          />
                        )}
                        <IconButton
                          id="settings-btn-anchor"
                          size="small"
                          className={classes.smallCardSettingsIconStyles}
                          onClick={(e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) =>
                            onSettingsIconClick(e, id)
                          }
                          data-testid={`settingsCatalogueButton${headerTitle}`}
                        >
                          <Iconography className={classes.iconsStyles} iconName="settings" />
                        </IconButton>
                      </Box>
                    </Grid>
                  </MuiCardContent>
                </>
              ) : (
                <Box display="flex" flexDirection="column" height="100%" justifyContent="space-between">
                  <Box
                    width="100%"
                    alignItems="center"
                    display="flex"
                    justifyContent="space-between"
                    color={isSharedCatalogNotAvailable ? 'text.disabled' : 'text.primary'}
                  >
                    <Typography maxWidth="140px" className={classes.cardTitle} color="inherit">
                      {headerTitle}
                    </Typography>

                    {renderPrice()}
                  </Box>

                  {isSharedCatalogNotAvailable ? (
                    <Box display="flex">
                      <Box
                        className={classes.subscribeButton}
                        onClick={onUnsubscribeButtonClick}
                        onKeyPress={onEnterUnsubscribePress}
                        tabIndex={0}
                      >
                        <Typography
                          className={classes.subscribeText}
                          data-testid={`cancelSubscriptionRequestButton${headerTitle}`}
                        >
                          {t('cataloguesPage.catalogueCard.cancelSubscriptionRequestButton')}
                        </Typography>
                      </Box>
                    </Box>
                  ) : (
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                      <Box
                        className={classes.subscribeButton}
                        onClick={onUnsubscribeButtonClick}
                        onKeyPress={onEnterUnsubscribePress}
                        tabIndex={0}
                      >
                        <Typography className={classes.subscribeText} data-testid={`unsubscribeButton${headerTitle}`}>
                          {t('cataloguesPage.catalogueCard.unsubscribeButton')}
                        </Typography>
                      </Box>

                      <IconButton
                        sx={{ padding: 0, margin: '10px 0px 0px' }}
                        onClick={onExportIconClick}
                        onKeyPress={onExportIconPress}
                        edge="end"
                        href=""
                        data-testid={`exportCatalogueButton${headerTitle}`}
                      >
                        <Iconography iconName="share" className={classes.iconsStyles} />
                      </IconButton>
                    </Box>
                  )}
                </Box>
              )}
            </>
          )}
        </MuiCard>
      )}
    </>
  );
}

export default CatalogCard;
