import React, { createRef, FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { InputAdornment, OutlinedTextFieldProps, Box, makeStyles, Theme, ClickAwayListener } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { capitalize } from 'validate.js';

import { theme } from '../theme';

import Iconography from './Iconography';
import {
  StyledClickToViewMoreTypography,
  StyledForm,
  StyledFormControl,
  StyledInputLabel,
  StyledKeywordCardWithHover,
  StyledKeywordItemTypography,
  StyledKeywordsDropdown,
  StyledOutlinedInput,
  StyledSearchButton,
  StyledTypography,
} from './styled/SearchFieldStyles';

const useStyles = makeStyles<Theme, { isHover: boolean; isActive: boolean }>(() => ({
  styledFormControl: {
    zIndex: 1,

    '& .css-xo9sbk-MuiInputBase-root-MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: ({ isHover, isActive }) =>
        isHover || isActive ? theme.palette.secondary.main : theme.palette.text.disabled,
    },
    '& .css-xo9sbk-MuiInputBase-root-MuiOutlinedInput-root .MuiInputAdornment-root button': {
      borderColor: ({ isHover, isActive }) =>
        isHover || isActive ? theme.palette.secondary.main : theme.palette.text.disabled,
    },
  },
  inputAdornment: {
    '& .css-15ft5sf': {
      display: 'flex',
      alignItems: 'center',
    },
  },
}));

interface SearchFieldProps extends OutlinedTextFieldProps {
  setIsDropDownOpen?: (state: boolean) => void;
  isDropDownOpen?: boolean;
  onSubmit?: (event: FormEvent) => void;
  setIsKeywordsSidebarOpen?: (state: boolean) => void;
  handleClickKeyword?: (keyword: string) => void;
  activeKeywords?: string[];
  keywords?: string[];
  isKeywordsSidebarOpen?: boolean;
  sharedCatalogsSearch?: boolean;
  searchPage?: boolean;
  testId?: string;
}

function SearchField({
  setIsDropDownOpen,
  isDropDownOpen,
  activeKeywords,
  handleClickKeyword,
  keywords,
  isKeywordsSidebarOpen,
  setIsKeywordsSidebarOpen,
  onChange,
  onSubmit,
  variant,
  label,
  disabled,
  value,
  searchPage,
  sharedCatalogsSearch,
  testId,
}: SearchFieldProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const inputRef = createRef<HTMLInputElement>();

  const [isHover, setIsHover] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(false);

  const classes = useStyles({ isHover, isActive });

  const filterIconColor = isHover || isActive ? theme.palette.secondary.contrastText : theme.palette.text.disabled;
  const searchIconColor = !disabled && (isHover || isActive) ? 'secondary' : undefined;

  const changeInputAppearanceStateValue = (value: boolean) => {
    setIsHover(value);
    setIsActive(value);
  };

  const onClickViewMore = () => {
    setIsKeywordsSidebarOpen?.(true);
    setIsDropDownOpen?.(false);
  };

  const onClickFilterButton = () => {
    changeInputAppearanceStateValue(!isDropDownOpen);
    setIsDropDownOpen?.(!isDropDownOpen);
  };

  const onClickAway = () => {
    if (isDropDownOpen && !isKeywordsSidebarOpen) onClickFilterButton();
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    inputRef.current?.focus();
  };

  const onMouseLeave = () => {
    if (!isDropDownOpen) setIsHover(false);
  };

  const onBlur = () => {
    if (!isDropDownOpen) setIsActive(false);
  };

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <Box
        zIndex={3}
        height={0}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={onMouseLeave}
        onBlur={onBlur}
        onFocus={() => setIsActive(true)}
      >
        <StyledForm onSubmit={onSubmit || handleSubmit} defaultChecked={searchPage}>
          <StyledFormControl className={classes.styledFormControl} variant={variant} fullWidth={searchPage}>
            {!(isActive || value) && <StyledInputLabel>{label}</StyledInputLabel>}
            <StyledOutlinedInput
              endAdornment={
                <InputAdornment className={classes.inputAdornment} position="end" disablePointerEvents={disabled}>
                  {sharedCatalogsSearch && (
                    <Box
                      onClick={onClickFilterButton}
                      mr="15px"
                      sx={{ cursor: 'pointer' }}
                      data-testid="filterButtonSharedCatalogsSearch"
                    >
                      <Iconography iconName="search-icon" htmlColor={filterIconColor} />
                    </Box>
                  )}

                  <StyledSearchButton disabled={disabled} type="submit">
                    <Iconography iconName="search" htmlColor={searchIconColor} />
                  </StyledSearchButton>
                </InputAdornment>
              }
              inputProps={{ 'data-testid': testId }}
              value={value}
              disabled={disabled}
              onChange={onChange}
              fullWidth={searchPage}
              inputRef={inputRef}
            />
          </StyledFormControl>
        </StyledForm>

        {isDropDownOpen && (
          <StyledKeywordsDropdown overflow="hidden">
            <StyledTypography>
              {t(`sharedCatalogsPage.${activeKeywords?.length ? 'keywords' : 'mostFrequentlyUsedKeywords'}`)}
            </StyledTypography>

            <Box ml="20px" mt="10px" mr="20px" columnGap="15px" rowGap="10px" display="flex" flexWrap="wrap">
              {keywords?.map((keyword, id) => (
                <StyledKeywordCardWithHover
                  sx={{ background: activeKeywords?.includes(keyword) ? theme.palette.secondary.main : undefined }}
                  onClick={() => handleClickKeyword?.(keyword)}
                  key={id}
                >
                  <StyledKeywordItemTypography>{`#${capitalize(keyword)}`}</StyledKeywordItemTypography>
                </StyledKeywordCardWithHover>
              ))}

              <Box
                onClick={onClickViewMore}
                height="24px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                data-testid="clickToViewMore"
              >
                <StyledClickToViewMoreTypography>
                  {t('sharedCatalogsPage.clickToViewMore')}
                </StyledClickToViewMoreTypography>
              </Box>
            </Box>
          </StyledKeywordsDropdown>
        )}
      </Box>
    </ClickAwayListener>
  );
}

export default SearchField;
