import React from 'react';
import { useTranslation } from 'react-i18next';

import { capitalize } from '@material-ui/core';

import { AuditLog } from 'src/utils/gql';
import { StyledDescriptionTableCell } from 'src/views/AuditLog/AuditLogTable/styled';
import { ActionType, ActionTypeLabel, EntityName, EntityType, MetadataDiff } from 'src/views/AuditLog/types';

interface DescriptionTableCellProps {
  data: AuditLog;
}

export const actionInPerfect = (action: string): string => {
  const actionString = ActionTypeLabel[action as keyof typeof ActionTypeLabel];

  if (action === ActionType.Export || action === ActionType.Import) {
    return `${actionString}ed`;
  } else {
    return `${actionString}d`;
  }
};

const DescriptionTableCell = ({ data }: DescriptionTableCellProps) => {
  const { t } = useTranslation();

  const { entity, action, metadata } = data;

  const parseDescription = () => {
    const nameField = metadata.diffs?.find(({ propertyName }: MetadataDiff) => propertyName === 'name');

    const perfectAction = t(actionInPerfect(action));

    const renderString = (): string => {
      const entityString = capitalize(t(EntityName[entity as keyof typeof EntityName]));
      const nameString = `'${metadata.name}' ${t('auditLogPage.was')}`;

      return `${entityString} ${nameString} ${perfectAction}`;
    };

    const renderRenameString = (): string => {
      const entityString = capitalize(t(EntityName[entity as keyof typeof EntityName]));
      const nameBeforeString = `'${nameField?.before}'`;
      const nameAfterString = `${t('auditLogPage.wasRenamedTo')} '${nameField?.after}'`;
      const renameString = `${entityString} ${nameBeforeString} ${nameAfterString}`;

      return metadata.diffs.filter(({ propertyName }: MetadataDiff) => propertyName !== 'settings').length > 1
        ? `${renameString} ${t('auditLogPage.and')} ${perfectAction}`
        : renameString;
    };

    const renderImportExportString = (): string => {
      const entityString = t(EntityName[entity as keyof typeof EntityName]);
      const { dataSyncProviderName, dataSyncType } = metadata;
      const catalogNameString = `'${metadata.name}'`;

      return t('auditLogPage.importExportDescription', {
        perfectAction,
        ...(action === ActionType.Import
          ? {
              sourceName: dataSyncProviderName,
              sourceType: dataSyncType,
              destinationName: catalogNameString,
              destinationType: entityString,
            }
          : {
              sourceName: catalogNameString,
              sourceType: entityString,
              destinationName: dataSyncProviderName,
              destinationType: dataSyncType,
            }),
      });
    };

    if (entity === EntityType.User) {
      switch (action) {
        case ActionType.Update:
          if (nameField) {
            return renderRenameString();
          } else {
            return renderString();
          }
      }
    }

    if (entity === EntityType.Catalog) {
      switch (action) {
        case ActionType.Create:
          return renderString();

        case ActionType.Update:
          if (nameField) {
            return renderRenameString();
          } else {
            return renderString();
          }

        case ActionType.Delete:
          return renderString();

        case ActionType.Import:
          return renderImportExportString();

        case ActionType.Export:
          return renderImportExportString();
      }
    }

    if (entity === EntityType.Product) {
      switch (action) {
        case ActionType.Create:
          return renderString();

        case ActionType.Update:
          if (nameField) {
            return renderRenameString();
          } else {
            return renderString();
          }

        case ActionType.Delete:
          return renderString();
      }
    }

    if (entity === EntityType.Item) {
      switch (action) {
        case ActionType.Create:
          return renderString();

        case ActionType.Update:
          if (nameField) {
            return renderRenameString();
          } else {
            return renderString();
          }

        case ActionType.Delete:
          return renderString();
      }
    }

    if (entity === EntityType.Integration) {
      switch (action) {
        case ActionType.Create:
          return renderString();

        case ActionType.Update:
          if (nameField) {
            return renderRenameString();
          } else {
            return renderString();
          }

        case ActionType.Delete:
          return renderString();
      }
    }

    if (entity === EntityType.PlanSubscription) {
      return '=== NOT READY ===';
    }
  };

  return <StyledDescriptionTableCell>{parseDescription()}</StyledDescriptionTableCell>;
};

export default DescriptionTableCell;
