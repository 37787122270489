import React, { ChangeEvent, createRef, useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ApolloError, useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, IconButton, InputAdornment, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { v4 as uuidv4 } from 'uuid';

import Button from 'src/components/Button';
import { userNameFormSchema } from 'src/components/Forms/validationSchemas';
import { FILE_EXTENSION } from 'src/constants';
import useUpload from 'src/hooks/useUpload';
import { useAccount } from 'src/providers/account';
import { useSnackbar } from 'src/providers/snackbar';
import { getImagePreview } from 'src/utils/imageUtils';
import resizeImage from 'src/utils/resizeImage';

import { Mutation, MutationUpdateUserInfoArgs, resetUserPassword, updateUserInfo } from '../../utils/gql';
import Avatar from '../Avatar';
import TextInput from '../Forms/FormInputs/TextInput';
import Iconography from '../Iconography';
import Popup from '../Popup';

const LONG_NAME_LENGTH = 30;

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    padding: '30px 72px',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '20px',
    boxShadow: '0 2px 10px rgba(46, 96, 170, 0.25)',

    '&:hover': {
      boxShadow: '0 2px 15px rgba(241, 97, 82, 0.35)',
    },
  },
  greyText: {
    color: theme.palette.text.disabled,
  },
  textInput: {
    width: '100%',
    maxWidth: '392px',
    marginTop: '18px',
  },
  iconButton: { padding: '0' },
  userInputButtonGroup: {
    marginRight: '12px',
    display: 'flex',
    alignItems: 'center',
  },
  userInputSaveButton: {
    display: 'flex',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  userInputCancelButton: {
    display: 'flex',
    marginLeft: '3px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  userNameButton: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    width: '100%',
    padding: '12px 0',
    marginTop: '18px',
    '&:hover': {
      cursor: 'pointer',
      borderRadius: `2px solid ${theme.palette.background.default}`,
      background: theme.palette.background.default,
    },
  },
  userNameButtonTypography: {
    maxWidth: '392px',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxHeight: '36px',
    textAlign: 'center',
    fontSize: '15px',
    lineHeight: '18px',
  },
}));

const UserDataCard = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    user: { name, email, avatar },
    update,
  } = useAccount();
  const snackbar = useSnackbar();
  const { upload } = useUpload();
  const inputFile = createRef<HTMLInputElement>();
  const userFieldRef = createRef<HTMLInputElement>();

  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [isImageLoading, setIsImageLoading] = useState<boolean>(false);
  const [userNameFieldState, setUserNameFieldState] = useState({
    userName: name,
    isEditable: false,
    revertValue: name,
  });

  const formProps = useForm({
    mode: 'onChange',
    defaultValues: {
      userName: userNameFieldState.userName,
    },
    resolver: yupResolver(userNameFormSchema),
  });

  const {
    watch,
    reset,
    formState: { isValid },
  } = formProps;

  const userNameValue = watch('userName');

  const [sendEmailToResetPasswordMutation, { loading }] = useMutation(resetUserPassword);
  const [updateUserInfoMutation] = useMutation<Pick<Mutation, 'updateUserInfo'>, MutationUpdateUserInfoArgs>(
    updateUserInfo,
  );

  const handleChangeAvatar = async (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;

    if (files?.length) {
      setIsImageLoading(true);
      const file = files[0];
      const id = uuidv4();

      const resizedImage = (await resizeImage(file)) as File;

      getImagePreview(resizedImage, async (result: string) => {
        if (result) {
          const { loadedFilesUrls } = await upload([
            {
              id,
              name: file.name,
              file: resizedImage,
              imagePreview: result,
            },
          ]);

          if (loadedFilesUrls.length) {
            try {
              await updateUserInfoMutation({
                variables: {
                  data: {
                    avatar: loadedFilesUrls[0],
                  },
                },
              });

              await update({ avatar: loadedFilesUrls[0] });
              snackbar(t('userSettingsPage.avatarUpdateSuccess'), 'success');
              setIsImageLoading(false);
            } catch (error) {
              const { graphQLErrors, message: errorText } = error as ApolloError;
              const message = graphQLErrors && graphQLErrors.length ? graphQLErrors[0].message : errorText;

              if (error) {
                setIsImageLoading(false);
                snackbar(message);
              }
            }
          } else {
            setIsImageLoading(false);
            snackbar(t('userSettingsPage.avatarUploadingError'));
          }
        }
      });
    }
  };

  const handleResetPassword = async () => {
    try {
      const {
        data: { sendEmailForResetPassword },
      } = await sendEmailToResetPasswordMutation({
        variables: {
          email,
        },
      });

      setShowPopup(sendEmailForResetPassword);
    } catch (error) {
      const { graphQLErrors, message: errorText } = error as ApolloError;
      const message = graphQLErrors && graphQLErrors.length ? graphQLErrors[0].message : errorText;

      if (error) {
        snackbar(message);
      }
    }
  };

  const handleUpdateUserName = async () => {
    const { revertValue } = userNameFieldState;
    const userNameWithoutSpaces = userNameValue.trim();

    if (userNameWithoutSpaces !== revertValue.trim()) {
      try {
        await updateUserInfoMutation({
          variables: {
            data: {
              name: userNameWithoutSpaces,
            },
          },
        });
        update({ name: userNameWithoutSpaces });
      } catch (error) {
        const { graphQLErrors, message: errorText } = error as ApolloError;
        const message = graphQLErrors && graphQLErrors.length ? graphQLErrors[0].message : errorText;

        if (error) {
          snackbar(message);
        }
      }
    }

    setUserNameFieldState((prevState) => {
      return {
        ...prevState,
        userName: userNameValue,
        isEditable: false,
        revertValue: userNameValue,
      };
    });
  };

  const handleCancelUserNameChanges = useCallback(() => {
    reset({ userName: userNameFieldState.userName.trimEnd() });

    setUserNameFieldState((prevState) => ({
      ...prevState,
      isEditable: false,
      userName: userNameFieldState.revertValue,
    }));
  }, [userNameFieldState.revertValue, reset, userNameFieldState.userName]);

  const handleShowUserInput = useCallback(() => {
    setUserNameFieldState((prevState) => {
      return {
        ...prevState,
        userName: prevState.userName.trimEnd(),
        isEditable: true,
      };
    });
  }, []);

  useEffect(() => {
    userFieldRef && userFieldRef.current?.focus();
  }, [userFieldRef]);

  const handleLongNames =
    userNameFieldState.userName.length > LONG_NAME_LENGTH
      ? userNameFieldState.userName.slice(0, LONG_NAME_LENGTH) +
        '\u200B' +
        userNameFieldState.userName.slice(LONG_NAME_LENGTH)
      : userNameFieldState.userName;

  return (
    <Box className={classes.root}>
      <input type="file" accept={FILE_EXTENSION.image} hidden ref={inputFile} onChange={handleChangeAvatar} />

      <IconButton
        onClick={() => !isImageLoading && inputFile?.current?.click()}
        className={classes.iconButton}
        tabIndex={-1}
      >
        <Avatar src={avatar || ''} loading={isImageLoading} size="large" />
      </IconButton>

      {!userNameFieldState.isEditable && (
        <Box
          className={classes.userNameButton}
          tabIndex={0}
          onClick={handleShowUserInput}
          data-testid="renameUserButton"
        >
          <Typography className={classes.userNameButtonTypography}>{handleLongNames}</Typography>
        </Box>
      )}

      {userNameFieldState.isEditable && (
        <FormProvider {...formProps}>
          <TextInput
            name="userName"
            className={classes.textInput}
            label={t('userSettingsPage.account.profile.fullNameLabel')}
            autoComplete="off"
            inputRef={userFieldRef}
            inputNameTestId="userNameInput"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Box className={classes.userInputButtonGroup}>
                    {isValid && (
                      <Box
                        className={classes.userInputSaveButton}
                        onClick={handleUpdateUserName}
                        data-testid="renameUserSaveButton"
                      >
                        <Iconography iconName="check-outline" htmlColor="secondary" />
                      </Box>
                    )}
                    <Box
                      className={classes.userInputCancelButton}
                      onClick={handleCancelUserNameChanges}
                      data-testid="renameUserCancelButton"
                    >
                      <Iconography iconName="cancel" color="disabled" />
                    </Box>
                  </Box>
                </InputAdornment>
              ),
            }}
          />
        </FormProvider>
      )}

      <Typography variant="body1" className={classes.greyText} mt="33px">
        {email}
      </Typography>

      <Box mt="30px">
        <Button variant="outlined" loading={loading} onClick={handleResetPassword}>
          {t('userSettingsPage.account.profile.button')}
        </Button>
      </Box>

      <Popup
        mainButtonText={t('userSettingsPage.account.resetPasswordPopup.button')}
        open={showPopup}
        onClose={() => setShowPopup(false)}
        mainTitle={t('userSettingsPage.account.resetPasswordPopup.mainText')}
        onMainButtonClick={() => setShowPopup(false)}
        testMainButton="closePopup"
      >
        <Box maxWidth={335} margin="0 auto">
          <Typography color="text.secondary" variant="body1">
            {t('userSettingsPage.account.resetPasswordPopup.description', { email })}
          </Typography>
        </Box>
      </Popup>
    </Box>
  );
};

export default UserDataCard;
