import { SelectOptionItemStringValue } from 'src/components/Select';
import { FieldType } from 'src/utils/gql';
import { popoverActions } from 'src/views/Catalogs/ProductTypes/ProductTypeSettings/SaveButtonWithMenu';

export interface FieldSettingsFormData {
  fieldName: string;
  required: boolean;
  showInFilters: boolean;
  showInPreview: boolean;
  type: SelectOptionItemStringValue;
  fieldSettingsDefaultValue: string;
}

export interface ProductTypeSettingsField {
  id?: string;
  name: string;
  systemName?: string;
  showInPreview: boolean;
  showInFilters: boolean;
  required: boolean;
  type: FieldType;
  defaultValue?: string | null;
  invariable?: boolean;
  isDefaultField?: boolean;
}

export interface ProductTypeSettingsState {
  productTypeName: string;
  defaultFields: ProductTypeSettingsField[];
  customFields: ProductTypeSettingsField[];
  isCreationSuccess: boolean;
  isUpdateSuccess: boolean;
  isFieldSettingsOpen: boolean;
  isDeleteFieldPopupOpen: boolean;
  hasRequiredRelations: boolean;
  action: popoverActions;
}

export interface CustomFieldsProps {
  customFieldId: string;
  hasFieldRequiredRelation: boolean;
}

export enum ProductSettingsPageTab {
  Fields,
  Mapping,
}

export enum ProductTypeSettingsPage {
  Fields,
  Mapping,
}
