import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { ApolloError, useLazyQuery } from '@apollo/client';
import { Box } from '@material-ui/core';

import DashboardBreadCrumbs from 'src/components/Breadcrumds/DashboardBreadCrumbs';
import CatalogsHeader from 'src/components/Headers/CatalogsHeader';
import MultiStepper from 'src/components/MultiStepper';
import Page from 'src/components/Page';
import DiscardChangesPopup from 'src/components/Popups/DiscardChangesPopup';
import { EXCEEDED_LIMIT_VALUE } from 'src/constants';
import { validateTextFieldValue } from 'src/helpers/validationCheck';
import useDebounceValue from 'src/hooks/useDebounceValue';
import useIntegrationHealthCheck from 'src/hooks/useIntegrationHealthCheck';
import useOnboarding from 'src/providers/OnboardingProvider/hooks/useOnboarding';
import { useSnackbar } from 'src/providers/snackbar';
import { CatalogSource, Integration, isSameName } from 'src/utils/gql';

import { useCatalogs } from '../../../providers/catalog';
import { CatalogueStateInterface, STEPS } from '../types';

import FirstStep from './FirstStep';
import SecondStep from './SecondStep';
import ThirdStep from './ThirdStep';

type CatalogueState = CatalogueStateInterface;

const steps = [STEPS.firstStep, STEPS.secondStep, STEPS.thirdStep];

export const getCatalogNameError = (name: string, touched: boolean, sameNameError: boolean) => {
  if (name.length > EXCEEDED_LIMIT_VALUE) {
    return <Trans i18nKey="exceededLimitValue">Max 255 symbols</Trans>;
  }

  if (touched) {
    if (!name) {
      return <Trans i18nKey="catalogueCreationPage.errorMessage">Please, enter catalogue name</Trans>;
    }

    if (sameNameError) {
      return <Trans i18nKey="catalogueCreationPage.sameNameErrorMessage">Current catalog name is used</Trans>;
    }
  }

  return null;
};

const CatalogueCreation = () => {
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const {
    onboardingState: { tourActive },
    getStepsByCatalogSource,
    setOnboardingState,
    goNext,
    goBack,
  } = useOnboarding();

  const { createCatalog, loadingCreate, onCreateOnboardingCatalog, loadingCreateOnboardingCatalog } = useCatalogs();

  const [completedSteps, setCompletedSteps] = useState(new Set<number>());
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isEnterActionBlocked, setIsEnterActionBlocked] = useState<boolean>(false);
  const [catalogueState, setCatalogueState] = useState<CatalogueState>({
    name: '',
    source: tourActive ? CatalogSource.Shopify : ('' as CatalogSource),
    step: STEPS.firstStep,
    touched: null,
    sameNameError: false,
    changeInProcess: false,
  });

  const debouncedSearchTerm = useDebounceValue(catalogueState.name, 500);

  const [checkIsSameName] = useLazyQuery(isSameName, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setCatalogueState((prevState: CatalogueState) => ({
        ...prevState,
        sameNameError: !data.checkNameAvailability,
        changeInProcess: false,
      }));
    },
  });

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = target;

    setCatalogueState((prevState: CatalogueState) => ({
      ...prevState,
      [name]: validateTextFieldValue(value),
      touched: true,
      changeInProcess: !!value.trimStart() && value.trimEnd() === value,
    }));
  };

  const handleSpecificStep = (specificStep: number) => {
    setCatalogueState((prevState: CatalogueState) => {
      return {
        ...prevState,
        step: specificStep,
      };
    });
  };

  const handleNextStep = (nextStep: number) => {
    setCatalogueState((prevState: CatalogueState) => {
      return {
        ...prevState,
        step: nextStep,
      };
    });

    if (tourActive) {
      goNext();
    }

    setCompletedSteps(() => completedSteps.add(catalogueState.step));
  };

  const handleBack = (currentStep: number) => {
    setCatalogueState((prevState: CatalogueState) => {
      return {
        ...prevState,
        step: currentStep - 1,
      };
    });

    if (tourActive) {
      goBack();
    }
  };

  const handleSelectedCard = (source: CatalogSource) => {
    setCatalogueState((prevState: CatalogueState) => {
      return {
        ...prevState,
        source,
      };
    });

    if (tourActive) {
      setOnboardingState((prevState) => ({ ...prevState, steps: getStepsByCatalogSource(source) }));
    }
  };

  const handleCreateCatalog = async (integrationId?: string) => {
    const { name, source } = catalogueState;
    const integrations = [{ id: '1' }] as Integration[];

    if (tourActive && [CatalogSource.Shopify, CatalogSource.Woocommerce, CatalogSource.Csv].includes(source)) {
      try {
        const data = await onCreateOnboardingCatalog(name, source);

        const createdData = data?.createOnboardingCatalog;

        if (createdData) {
          const { catalog, productType, productItems } = createdData;

          setOnboardingState((prevState) => ({
            ...prevState,
            createdOnboardingCatalog: { ...catalog, integrations },
            createdOnboardingProductType: productType,
            createdOnboardingProductItems: productItems,
          }));

          return catalog.id;
        }
      } catch (error) {
        const { graphQLErrors, message: errorText } = error as ApolloError;
        const message = graphQLErrors && graphQLErrors.length ? graphQLErrors[0].message : errorText;

        snackbar(message);

        return;
      }
    }

    try {
      const data = await createCatalog(name, source, integrationId);

      setCatalogueState((prevState: CatalogueState) => {
        return {
          ...prevState,
          name: '',
        };
      });

      source !== CatalogSource.Csv && snackbar(t('catalogueCreationPage.createCatalogSuccess'), 'success');

      if (tourActive && source === CatalogSource.Manually && data?.createCatalog) {
        setOnboardingState((prevState) => ({
          ...prevState,
          createdOnboardingCatalog: { ...data?.createCatalog, isEmpty: false, integrations },
        }));
      }

      return data?.createCatalog?.id;
    } catch (error) {
      const { graphQLErrors, message: errorText } = error as ApolloError;
      const message = graphQLErrors && graphQLErrors.length ? graphQLErrors[0].message : errorText;

      if (error) {
        setCatalogueState((prevState: CatalogueState) => ({
          ...prevState,
          step: STEPS.thirdStep,
        }));

        snackbar(message);
      }
    }
  };

  const handleDiscardChanges = () => {
    if (!catalogueState.name) {
      return false;
    }
    return !isModalOpen;
  };

  useEffect(() => {
    if (catalogueState.step === STEPS.thirdStep) {
      completedSteps.add(STEPS.thirdStep);
    }
  }, [catalogueState.step, completedSteps]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      checkIsSameName({ variables: { name: debouncedSearchTerm.trimEnd() } });
    }
  }, [debouncedSearchTerm, checkIsSameName]);

  const { checkIntegrationStatus, integrationErrors, loadingIntegrationHealthCheck } = useIntegrationHealthCheck();

  const error = useMemo(
    () => getCatalogNameError(catalogueState.name, !!catalogueState.touched, catalogueState.sameNameError),
    [catalogueState.name, catalogueState.touched, catalogueState.sameNameError],
  );
  const title = t('catalogueCreationPage.title');
  const isButtonDisabled =
    !(!error && catalogueState.touched) || isEnterActionBlocked || catalogueState.changeInProcess;

  return (
    <Page title={title} locationName={title}>
      <Box display="flex" flexDirection="column" width="100%" pl="8px">
        <Box mb="25px">
          <DashboardBreadCrumbs />
        </Box>

        <CatalogsHeader mainCataloguesPage={false} title={t('catalogueCreationPage.title')} />

        <Box display="flex" alignItems="center">
          <Box height="356px" width="52px" ml="28px" my="54px" mr="52px">
            <MultiStepper
              handleSpecificStep={handleSpecificStep}
              steps={steps}
              activeStep={steps.indexOf(catalogueState.step)}
              completedSteps={completedSteps}
            />
          </Box>

          {catalogueState.step === STEPS.firstStep && (
            <FirstStep
              handleNextStep={handleNextStep}
              handleChange={handleChange}
              name={catalogueState.name}
              error={error}
              isButtonDisabled={isButtonDisabled}
              loadingForEnterActionResult={catalogueState.changeInProcess}
              isEnterActionBlocked={isEnterActionBlocked || catalogueState.changeInProcess}
            />
          )}

          {catalogueState.step === STEPS.secondStep && (
            <SecondStep
              handleBack={handleBack}
              handleNextStep={handleNextStep}
              handleSelectedCard={handleSelectedCard}
              selectedCard={catalogueState.source}
              onCreateCatalog={handleCreateCatalog}
              setIsModalOpen={setIsModalOpen}
              isModalOpen={isModalOpen}
              loading={loadingCreate || loadingCreateOnboardingCatalog}
            />
          )}

          {catalogueState.step === STEPS.thirdStep && (
            <ThirdStep
              source={catalogueState.source}
              handleBack={handleBack}
              onCreateCatalogue={handleCreateCatalog}
              checkIntegrationStatus={checkIntegrationStatus}
              integrationErrors={integrationErrors}
              loading={loadingCreate || loadingCreateOnboardingCatalog || loadingIntegrationHealthCheck}
            />
          )}
        </Box>
      </Box>

      <DiscardChangesPopup
        isOpen={handleDiscardChanges() && !tourActive}
        setIsEnterActionBlocked={setIsEnterActionBlocked}
      />
    </Page>
  );
};

export default CatalogueCreation;
