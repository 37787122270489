import React from 'react';
import { useTranslation } from 'react-i18next';

import { TableCell, TableRow } from '@material-ui/core';

import { ProductTypeFieldTableHead } from '../styled';

const ProductTypeSettingsTableHeader = () => {
  const { t } = useTranslation();
  const tableHeaderLables: string[] = t('fieldSettings.productTypeSettingsTableHeaderLabels', {
    returnObjects: true,
  });

  return (
    <ProductTypeFieldTableHead>
      <TableRow>
        {tableHeaderLables?.map((label) => (
          <TableCell data-testid={label} key={label}>
            {label}
          </TableCell>
        ))}
      </TableRow>
    </ProductTypeFieldTableHead>
  );
};

export default ProductTypeSettingsTableHeader;
