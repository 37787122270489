import React from 'react';

import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { v4 as uuidv4 } from 'uuid';

import { MediaFilterProps } from './constants';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.palette.secondary.main,
    display: 'flex',
    marginBottom: '25px',
  },
  cursorAdd: {
    display: 'flex',
    cursor: 'pointer',
    marginRight: '30px',
  },
  active: {
    color: theme.palette.secondary.dark,
    fontWeight: 500,
  },
  default: {
    color: theme.palette.text.secondary,
  },
}));

const MediaFilter = ({ typeFilter, onFilterSelect, dataFilterOptions }: MediaFilterProps) => {
  const classes = useStyles();

  const filters = dataFilterOptions.map((nameFilter) => {
    return (
      <Box
        className={classes.cursorAdd}
        key={`${nameFilter} ${uuidv4()}`}
        onClick={() => onFilterSelect(nameFilter.value)}
        data-testid={`${nameFilter.title}MediaTypeTab`}
      >
        <Typography className={clsx(typeFilter === nameFilter.value ? [classes.active] : [classes.default])}>
          {nameFilter.title}
        </Typography>
      </Box>
    );
  });

  return <Box className={classes.root}>{filters}</Box>;
};

export default MediaFilter;
