import { Box } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';

export const SetNewPasswordContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 350px;
  min-height: 515px;
`;

export const SideContainer = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 486px;
  min-width: 486px;
  width: 654px;
  margin-left: 51px;
`;

export const StyledSetNewPasswordContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  maxWidth: '1200px',
  margin: '0 auto',
}));
