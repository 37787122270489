import { Box, FormControlLabel, List, TextField } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';

import Button from '../Button';

export const FormContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-width: 282px;

  p.Mui-error {
    margin-left: 2px;
  }
`;

export const FormField = styled(TextField)`
  .MuiInputBase-input {
    padding: 5px 2px;
  }

  .MuiInputBase-adornedEnd {
    align-items: baseline;
  }

  .MuiInputAdornment-positionEnd {
    display: block;
    margin-left: 10px;
  }

  .MuiOutlinedInput-root .MuiInputBase-inputMultiline {
    padding: 0;
    margin: 12px 20px;
    max-height: 150px;
    box-sizing: border-box;
    overflow: auto !important;
  }

  &:has(.MuiInputAdornment-positionEnd) .MuiInputBase-inputMultiline {
    margin-right: 0;
  }

  label {
    margin-left: -4px;
  }

  label[data-shrink='false'] {
    margin-left: -4px;
  }

  label[data-shrink='true'] {
    margin-left: -4px;
  }
  input::-ms-reveal {
    display: none;
  }
`;

export const SaveButton = styled(Button)`
  width: 100%;
  max-width: 112px;
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    color: ${({ theme: { palette } }) => palette.text.secondary};
  }
`;

export const StyledList = styled(List)`
  padding-top: 10px;

  .MuiListItem-root {
    padding: 0;
    margin-bottom: 2px;
  }

  .MuiListItemIcon-root {
    min-width: 0;

    svg {
      margin-right: 3px;
      width: 12px;
      height: 12px;
    }
  }
`;
