import React, { ChangeEvent, KeyboardEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';
import { Box, Checkbox, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';

import { EXCEEDED_LIMIT_VALUE } from 'src/constants';
import {
  CATALOGS_PATH,
  FIRST_DASHBOARD_PATHNAME,
  PRODUCT_ITEMS_PATH,
  PRODUCT_TYPES_PATH,
} from 'src/constants/routeSources';
import { capitalize } from 'src/helpers/capitalize';
import { handleValidateKeyPress } from 'src/helpers/validationCheck';
import { FieldType, getProductTypeById, Query, QueryGetProductTypeArgs, FieldSystemName } from 'src/utils/gql';
import { Field, Value } from 'src/views/Catalogs/ProductItems/types';

import { useEnterClick } from '../../utils/enterEffectFactory';
import { arrayToMap } from '../../utils/general';
import { ButtonWithMargin } from '../../views/Catalogs/ProductItems/styled';
import { CardIcon } from '../../views/Catalogs/ProductTypes/styled';
import DashboardBreadCrumbs from '../Breadcrumds/DashboardBreadCrumbs';
import Button from '../Button';
import WrapperTextInput from '../Forms/FormInputs/WrapperTextInput';
import Iconography from '../Iconography';
import MultiTypeInput from '../MultiTypeInput';
import Popup from '../Popup';
import UploadImageMultiple from '../UploadFiles/UploadImageMultiple';

import { ItemsSideBarContainer, StyledBox, StyledBreadCrumbsBox, StyledFormBox } from './styled';

interface StyleProps {
  isScrolled: boolean;
  checkIsCatalogShared: boolean;
}

interface ItemEditingSidebarProps {
  checkIsSpecialImportedField?: boolean;
  isShowDraftLabel?: boolean;
  setIsShowDraftLabel?: (state: boolean) => void;
  checkIsCatalogShared: boolean;
  onUpdateProductItem?: () => void;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onChangeSelect?: (id: string, value: string) => void;
  onDeleteItem?: () => void;
  productItemName?: string;
  productItemValues: Value[];
  loading: boolean;
  loadingUploadFiles?: boolean;
  isError?: boolean;
  hasBreadCrumbs?: boolean;
  handleUpdateNumberValue?: (id: string, value: string) => void;
  productTypeId: string;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  iconsStyles: {
    color: theme.palette.text.primary,
    padding: '0 10px',
    '& svg': {
      width: '20px',
      height: '20px',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '25px',
    minHeight: '82px',
    position: ({ checkIsCatalogShared }) => (checkIsCatalogShared ? 'relative' : 'sticky'),
    top: 0,
    background: ({ isScrolled }) => (isScrolled ? theme.palette.common.white : 'none'),
    padding: '0 24px',
    zIndex: 25,
    boxShadow: ({ isScrolled }) => (isScrolled ? '0 2px 10px rgba(46, 96, 170, 0.25)' : 'none'),
  },
  sharedCatalogHeader: {
    width: '880px',
    margin: '0 auto',
  },
}));

const ItemEditingSidebar = ({
  checkIsSpecialImportedField,
  setIsShowDraftLabel,
  isShowDraftLabel,
  checkIsCatalogShared,
  onUpdateProductItem,
  onChange,
  onChangeSelect,
  onDeleteItem,
  productItemName,
  productItemValues,
  loading,
  loadingUploadFiles,
  isError,
  hasBreadCrumbs,
}: ItemEditingSidebarProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [isScrolled, setIsScrolled] = useState<boolean>(false);

  const classes = useStyles({ isScrolled, checkIsCatalogShared });

  const elementSideBar = document.getElementById('sideBarId');

  const FIXED_HEADER_STARTING_POINT = 60;

  const handleFixedHeader = useCallback(
    () => setIsScrolled(Number(elementSideBar?.scrollTop) >= FIXED_HEADER_STARTING_POINT),
    [elementSideBar],
  );

  const { id, productTypeId } = useParams<{ id: string; productTypeId: string }>();

  const { data: productTypeData } = useQuery<Pick<Query, 'getProductType'>, QueryGetProductTypeArgs>(
    getProductTypeById,
    {
      fetchPolicy: 'cache-first',
      variables: {
        id: productTypeId,
      },
    },
  );

  const hasError = useMemo(
    () =>
      isError ||
      (!productItemValues.find(
        ({ value, productTypeField }) =>
          value && productTypeField.systemName === FieldSystemName.Price && Number(value) !== 0,
      ) &&
        !isShowDraftLabel),
    [isError, isShowDraftLabel, productItemValues],
  );

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const fieldsWithoutDescriptionAndImage: Field[] = useMemo(() => {
    const fieldsMap = arrayToMap(productItemValues.map(({ productTypeField }) => productTypeField));

    return (
      productTypeData?.getProductType.fields
        .map(({ id }) => fieldsMap[id])
        .filter((field) => field && !([FieldSystemName.Description] as string[]).includes(field.systemName || '')) || []
    );
  }, [productItemValues, productTypeData?.getProductType.fields]);

  const descriptionField = useMemo(
    () =>
      productItemValues.find(({ productTypeField }) => productTypeField.systemName === FieldSystemName.Description)
        ?.productTypeField,
    [productItemValues],
  );

  const findValue = useCallback(
    (id?: string) =>
      productItemValues
        .find(({ productTypeField }) => productTypeField.id === id)
        ?.value.trimStart()
        .replace(/\s\s+/g, ' '),
    [productItemValues],
  );

  const someFieldLongValue = useMemo(
    () => fieldsWithoutDescriptionAndImage.some(({ id }) => (findValue(id) || '').length > EXCEEDED_LIMIT_VALUE),
    [fieldsWithoutDescriptionAndImage, findValue],
  );

  const valueDescription = findValue(descriptionField?.id) || '';

  const isDescriptionFieldTooLong = valueDescription.length > EXCEEDED_LIMIT_VALUE;
  const helperTextDescription = isDescriptionFieldTooLong ? t('exceededLimitValue') : '';
  const helperTextError = descriptionField?.error ? t('productItemCreateEdit.sidebar.helperText') : '';

  const isSaveButtonDisabled = hasError || someFieldLongValue || isDescriptionFieldTooLong;

  useEnterClick(() => !isModalOpen && !isError && onUpdateProductItem?.());

  const formMethods = useForm({
    mode: 'onChange',
  });

  const onCancelButtonClick = useCallback(() => {
    history.push(
      `/${FIRST_DASHBOARD_PATHNAME}/${CATALOGS_PATH}/${id}/${PRODUCT_TYPES_PATH}/${productTypeId}/${PRODUCT_ITEMS_PATH}`,
    );
  }, [history, id, productTypeId]);

  useEffect(() => {
    elementSideBar?.addEventListener('scroll', handleFixedHeader);
    return () => elementSideBar?.removeEventListener('scroll', handleFixedHeader);
  }, [elementSideBar, handleFixedHeader]);

  return (
    <ItemsSideBarContainer>
      <>
        {hasBreadCrumbs && (
          <StyledBreadCrumbsBox>
            <DashboardBreadCrumbs editingName={productItemName} />
          </StyledBreadCrumbsBox>
        )}

        <StyledFormBox>
          <FormProvider {...formMethods}>
            <form aria-label="form">
              <Box display="flex" flexWrap="wrap" justifyContent="space-between">
                {fieldsWithoutDescriptionAndImage?.map(({ id, name, type, error, required }, idx) => {
                  const value = findValue(id) || '';
                  const isLongName = value?.length > EXCEEDED_LIMIT_VALUE;
                  const helperTextError = error ? t('productItemCreateEdit.sidebar.helperText') : '';
                  const helperTextErrorLongName = isLongName ? t('exceededLimitValue') : '';

                  return (
                    <Box key={id}>
                      <Box mb="32px" width="418px">
                        {type === FieldType.String ? (
                          <WrapperTextInput
                            onChange={onChange}
                            value={value?.replace(/\s+/g, ' ') || ''}
                            error={error || isLongName}
                            label={capitalize(name)}
                            disabled={checkIsCatalogShared}
                            id={id}
                            helperTextError={helperTextError || helperTextErrorLongName}
                            onKeyPress={(e: KeyboardEvent<HTMLInputElement>) => handleValidateKeyPress(e, value)}
                          />
                        ) : (
                          <MultiTypeInput
                            manualValue={value}
                            onManualChange={(newValue) => onChangeSelect?.(id, newValue)}
                            label={capitalize(name)}
                            manualError={error}
                            manualHelperText={error ? t('productItemCreateEdit.sidebar.helperText') : ''}
                            type={type as FieldType}
                            productTypeFieldId={id}
                            productTypeId={checkIsCatalogShared ? undefined : productTypeId}
                            disabled={checkIsCatalogShared}
                            shouldBeFilled={Boolean(required)}
                            emptyErrorMessage={t('productItemCreateEdit.sidebar.helperText')}
                            checkIsSpecialImportedField={checkIsSpecialImportedField}
                            inputIdentifier={`${name}_${idx}`}
                          />
                        )}
                      </Box>
                    </Box>
                  );
                })}
              </Box>

              {!checkIsCatalogShared && <UploadImageMultiple />}

              {(descriptionField || !checkIsCatalogShared) && (
                <Box mb="40px">
                  <WrapperTextInput
                    disabled={checkIsCatalogShared}
                    multiline
                    minRows="3"
                    id={descriptionField?.id}
                    label={descriptionField?.name && capitalize(descriptionField.name)}
                    onChange={onChange}
                    value={findValue(descriptionField?.id) || ''}
                    error={descriptionField?.error || isDescriptionFieldTooLong}
                    helperTextError={helperTextError || helperTextDescription}
                    inputNameTestId="descriptionEditItemField"
                  />
                </Box>
              )}
            </form>
          </FormProvider>
        </StyledFormBox>
      </>
      <StyledBox className={classes.header}>
        <Box display="flex" className={clsx(checkIsCatalogShared && classes.sharedCatalogHeader)}>
          <Typography variant="h2">{t('productItemCreateEdit.sidebar.title')}</Typography>

          {!checkIsCatalogShared && (
            <CardIcon className={classes.iconsStyles} onClick={() => setIsModalOpen(true)}>
              <Iconography iconName="trash-fill" fontSize="inherit" />
            </CardIcon>
          )}
        </Box>

        <Box display="flex" justifyContent="space-between">
          {!checkIsCatalogShared && (
            <Box display="flex" alignItems="center" justifyContent="center">
              <Checkbox
                checked={isShowDraftLabel}
                onChange={() => setIsShowDraftLabel?.(!isShowDraftLabel)}
                name="draftItem"
                inputProps={{ 'aria-label': 'checkbox' }}
                data-testid="checkboxDraftItem"
              />

              <Button variant="outlined" onClick={onCancelButtonClick}>
                {t('cancel')}
              </Button>

              <ButtonWithMargin
                disabled={isSaveButtonDisabled}
                loading={loading || loadingUploadFiles}
                onClick={onUpdateProductItem}
                variant="contained"
                data-testid="saveButtonItemEditing"
              >
                {t('productItemCreateEdit.sidebar.saveButton')}
              </ButtonWithMargin>
            </Box>
          )}
        </Box>
      </StyledBox>
      <Popup
        open={isModalOpen}
        mainTitle={[
          t('productItemCreateEdit.popupDeleteItem.title'),
          t('productItemCreateEdit.popupDeleteItem.titleContinue'),
        ]}
        onClose={() => setIsModalOpen(false)}
        mainButtonText={t('productItemCreateEdit.popupDeleteItem.mainButton')}
        secondaryButtonText={t('productItemCreateEdit.popupDeleteItem.secondaryButton')}
        onMainButtonClick={onDeleteItem}
        onSecondaryButtonClick={() => setIsModalOpen(false)}
      >
        <Box maxWidth={418} margin="0 auto">
          <Typography variant="body1">{t('productItemCreateEdit.popupDeleteItem.text')}</Typography>
        </Box>
      </Popup>
    </ItemsSideBarContainer>
  );
};

export default ItemEditingSidebar;
