import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Prompt, useHistory, useLocation } from 'react-router';

import { Box, Typography } from '@material-ui/core';
import { Location } from 'history';

import Popup from 'src/components/Popup';
import useOnboarding from 'src/providers/OnboardingProvider/hooks/useOnboarding';

interface CancelCSVSidebarUploadPopup {
  isOpen: boolean;
  isManual?: boolean;
  onMainButtonClick?: () => void;
  onSecondaryButtonClick?: () => void;
  setIsEnterActionBlocked?: (isEnterActionAvailable: boolean) => void;
}

const CancelCSVSidebarUploadPopup = ({
  isOpen,
  isManual,
  onMainButtonClick,
  onSecondaryButtonClick,
  setIsEnterActionBlocked,
}: CancelCSVSidebarUploadPopup) => {
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const {
    onboardingState: { tourActive },
    finishOnboarding,
  } = useOnboarding();

  const [lastLocation, setLastLocation] = useState<Location>(location);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [shouldUnload, setShouldUnload] = useState<boolean>(false);
  const [isDiscardConfirmed, setIsDiscardConfirmed] = useState<boolean>(false);
  const translation = {
    message: <Trans i18nKey="userConfirmation.message">Do you want to discard the changes?</Trans>,
  };

  const { message } = translation;

  const closeModal = () => {
    setIsModalOpen(false);
    setIsEnterActionBlocked?.(false);
    setShouldUnload(false);
    onSecondaryButtonClick?.();
  };

  const openModal = () => {
    setIsModalOpen(true);
    setIsEnterActionBlocked?.(true);
  };

  const showModal = (nextLocation: Location) => {
    openModal();
    setLastLocation(nextLocation);
  };

  const handleBlockRoute = (nextLocation: Location) => {
    if (!isDiscardConfirmed && isOpen) {
      showModal(nextLocation);
      return false;
    }

    return true;
  };

  const handleConfirmDiscard = () => {
    if (tourActive) {
      finishOnboarding();
    }

    closeModal();
    setIsDiscardConfirmed(true);
    onMainButtonClick?.();
  };

  useEffect(() => {
    if (isDiscardConfirmed && lastLocation) {
      setShouldUnload(true);
      history.push(lastLocation.pathname);
    }
  }, [isDiscardConfirmed, lastLocation, history]);

  useEffect(() => {
    const unload = (event: BeforeUnloadEvent) => {
      if (isOpen && shouldUnload) {
        event.returnValue = message;
      }
      if (shouldUnload) {
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', unload);

    return () => window.removeEventListener('beforeunload', unload);
  }, [isOpen, message, shouldUnload]);

  return (
    <>
      <Prompt when message={handleBlockRoute} />
      {(isModalOpen || isManual) && (
        <Popup
          mainButtonsMarginAbsence
          open
          mainTitle={t('importFromCsvPage.cancelUploadPopup.title')}
          mainButtonText={t('importFromCsvPage.cancelUploadPopup.mainButtonText')}
          secondaryButtonText={t('importFromCsvPage.cancelUploadPopup.secondaryButtonText')}
          onMainButtonClick={handleConfirmDiscard}
          onSecondaryButtonClick={closeModal}
          onClose={closeModal}
          maxWidth="sm"
        >
          <Box maxWidth="300px" width="100%" margin="0 auto">
            <Typography variant="body1" fontWeight="normal" marginBottom="40px">
              {t('importFromCsvPage.cancelUploadPopup.description')}
            </Typography>
          </Box>
        </Popup>
      )}
    </>
  );
};

export default CancelCSVSidebarUploadPopup;
