import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, IconButton, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { styled } from '@mui/material';
import moment from 'moment';

import Button from 'src/components/Button';
import { NUMBER_OF_MONTHS_PER_YEAR } from 'src/constants';
import { PlanPaymentFrequency, PlanSubscription, PlanSubscriptionStatus, PlanType } from 'src/utils/gql';
import { UserSettingsPageTab } from 'src/views/UserSettings/types';

import Hint from '../Hint';
import Iconography from '../Iconography';

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    marginBottom: '23px',
    padding: '30px 30px 53px 30px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '20px',
    boxShadow: '0 2px 10px rgba(46, 96, 170, 0.25)',

    '&:hover': {
      boxShadow: '0 2px 15px rgba(241, 97, 82, 0.35)',
    },
  },
  tag: {
    marginRight: '8px',
    padding: '8px 15px',
    background: theme.palette.secondary.main,
    borderRadius: '10px',
  },
  greyText: {
    color: theme.palette.text.disabled,
    fontSize: '18px',
    lineHeight: '21px',
  },
  whiteText: {
    color: theme.palette.common.white,
  },
  yellowText: {
    color: theme.palette.warning.main,
  },
}));

const ErrorButton = styled(IconButton)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: '8px',
  padding: '8px 15px',
  maxHeight: '34px',
  fontSize: '20px',
  color: theme.palette.common.white,
  background: theme.palette.error.main,
  borderRadius: '10px',

  '&:hover': {
    background: theme.palette.error.main,
  },
}));

interface UserPlanCardProps {
  planSubscription: PlanSubscription;
  setActiveTab: (activeTab: UserSettingsPageTab) => void;
}

enum SubscriptionStatusName {
  waitingPayment = 'Waiting Payment',
  active = 'Active',
  disabled = 'Disabled',
  incomplete = 'Incomplete',
  incompleteExpired = 'Incomplete Expired',
  trialing = 'Trialing',
  pastDue = 'Unpaid',
  canceled = 'Canceled',
  unpaid = 'Unpaid',
}

const UserPlanCard = ({ planSubscription: { plan, status, subscriptionEndsAt }, setActiveTab }: UserPlanCardProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const price = plan.price || 0;
  const isAnnual = plan.paymentFrequency === PlanPaymentFrequency.Annual;

  return (
    <Box className={classes.root}>
      <Box display="flex" justifyContent="space-between" mb="30px">
        <Box display="flex" alignItems="center">
          <Typography variant="h2" mr="15px">
            {plan.name}
          </Typography>

          {plan.type === PlanType.Free && (
            <Box className={classes.tag}>
              <Typography variant="subtitle2" className={classes.whiteText}>
                {t('userSettingsPage.account.plan.free')}
              </Typography>
            </Box>
          )}

          {status !== PlanSubscriptionStatus.Active && (
            <Hint title={`${t('userSettingsPage.account.plan.actionRequiredPlanHint')}`} placement="top" type="hover">
              <ErrorButton onClick={() => setActiveTab(UserSettingsPageTab.BILLING)}>
                <Iconography iconName="refresh" color="inherit" fontSize="inherit" />
              </ErrorButton>
            </Hint>
          )}
        </Box>

        <Button variant="outlined" onClick={() => window.gist.chat('open')} data-testid="supportButton">
          {t('userSettingsPage.account.plan.supportButton')}
        </Button>
      </Box>

      <Box display="flex" lineHeight="21px">
        <Box display="flex" flexDirection="column" mr="50px">
          <Typography variant="caption" className={classes.greyText}>
            {t('userSettingsPage.account.plan.cost')}
          </Typography>

          <Box mt="10px">
            <Typography variant="subtitle1">
              {'$'}
              {isAnnual ? price * NUMBER_OF_MONTHS_PER_YEAR : price}
              {t(
                `userSettingsPage.account.plan.paymentFrequency.${
                  isAnnual ? PlanPaymentFrequency.Annual.toLowerCase() : PlanPaymentFrequency.Monthly.toLowerCase()
                }`,
              )}
            </Typography>
          </Box>
        </Box>

        {subscriptionEndsAt && (
          <Box display="flex" flexDirection="column" mr="50px">
            <Typography variant="caption" className={classes.greyText}>
              {t('userSettingsPage.account.plan.nextBillingDate')}
            </Typography>
            <Box mt="10px">
              <Typography variant="subtitle1">{moment(subscriptionEndsAt).format('DD/MM/YYYY')}</Typography>
            </Box>
          </Box>
        )}

        <Box display="flex" flexDirection="column">
          <Typography variant="caption" className={classes.greyText}>
            {t('userSettingsPage.account.plan.status')}
          </Typography>
          <Box mt="10px">
            <Typography variant="subtitle1">{SubscriptionStatusName[status]}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default UserPlanCard;
