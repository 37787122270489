import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@material-ui/core';

import ProductTypesNotFoundIcon from 'src/components/Icon/product-types-not-found.webp';

import ProductTypesNotFoundBackground from './ProductTypesNotFoundBackground';
import { NoProductTypes } from './styled';

interface NoProductTypesFoundComponentProps {
  isImageLoading: boolean;
  setIsImageLoading: (loading: boolean) => void;
}

const NoProductTypesFoundComponent = ({ isImageLoading, setIsImageLoading }: NoProductTypesFoundComponentProps) => {
  const { t } = useTranslation();

  return (
    <NoProductTypes>
      {isImageLoading && <ProductTypesNotFoundBackground />}
      <img
        src={ProductTypesNotFoundIcon}
        width="270px"
        height="220px"
        alt="Folder"
        onLoad={() => setIsImageLoading(false)}
      />

      <Typography variant="body1" textAlign="center" mt="30px">
        {t('productTypesPage.noProductTypesFoundTop')}
      </Typography>
      <Typography variant="body1" fontWeight="normal">
        {t('productTypesPage.noProductTypesFound')}
      </Typography>
    </NoProductTypes>
  );
};

export default NoProductTypesFoundComponent;
