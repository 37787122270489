import React, { Fragment, useMemo, useState } from 'react';

import { useQuery } from '@apollo/client';
import { Collapse } from '@mui/material';

import IntegrationInfoContainer from 'src/components/Mapping/IntegrationInfoContainer';
import { SelectOptionItemStringValue } from 'src/components/Select';
import { arrayToMap } from 'src/utils/general';
import {
  getIntegrationsRequiredFields,
  Integration,
  integrationProductTypes as integrationProductTypesQuery,
  IntegrationTypes,
  ProductType,
  ProductTypeFieldIntegrationMapping,
  Query,
  QueryGetIntegrationsRequiredFieldsArgs,
  QueryIntegrationProductTypesArgs,
} from 'src/utils/gql';

import ProductTypeMapping, { getSelectValueFromExternalType } from './ProductTypeMapping';
import { ProductTypesMappingsContainer } from './styled';

interface IntegrationMappingsProps {
  integration: Integration;
  productTypes: ProductType[];
  defaultFieldMappings?: Record<string, ProductTypeFieldIntegrationMapping[]>;
}

const IntegrationMappings = ({ integration, productTypes, defaultFieldMappings }: IntegrationMappingsProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const { data: integrationProductTypesData, loading: integrationProductTypesDataLoading } = useQuery<
    Pick<Query, 'integrationProductTypes'>,
    QueryIntegrationProductTypesArgs
  >(integrationProductTypesQuery, {
    variables: {
      id: integration.id,
    },
    fetchPolicy: 'network-only',
    skip: integration.type === IntegrationTypes.Ebay,
  });

  const { data: requiredIntegrationFields } = useQuery<
    Pick<Query, 'getIntegrationsRequiredFields'>,
    QueryGetIntegrationsRequiredFieldsArgs
  >(getIntegrationsRequiredFields, {
    fetchPolicy: 'cache-first',
    variables: {
      type: integration.type,
    },
  });

  const integrationTypesMap = useMemo(
    () => (integrationProductTypesData ? arrayToMap(integrationProductTypesData.integrationProductTypes) : {}),
    [integrationProductTypesData],
  );

  const integrationTypesOptions = useMemo(
    () =>
      integrationProductTypesData
        ? integrationProductTypesData.integrationProductTypes.reduce(
            (acc: SelectOptionItemStringValue[], externalType) => {
              acc.push(getSelectValueFromExternalType(externalType));

              return acc;
            },
            [],
          )
        : [],
    [integrationProductTypesData],
  );

  const handleClick = () => setIsOpen((prev) => !prev);

  return (
    <IntegrationInfoContainer
      integrationType={integration.type}
      title={integration.settings.integrationName}
      isOpen={isOpen}
      onClick={handleClick}
      doNotUseDiscardChanges
      requiredIntegrationFields={requiredIntegrationFields?.getIntegrationsRequiredFields || []}
    >
      <Collapse in={isOpen}>
        <ProductTypesMappingsContainer>
          {productTypes.map((productType, idx) => (
            <Fragment key={productType.id}>
              <ProductTypeMapping
                loading={integrationProductTypesDataLoading}
                productType={productType}
                integration={integration}
                integrationTypesMap={integrationTypesMap}
                integrationTypesOptions={integrationTypesOptions}
                idx={idx}
                defaultFieldMappings={defaultFieldMappings?.[productType.id]}
              />
            </Fragment>
          ))}
        </ProductTypesMappingsContainer>
      </Collapse>
    </IntegrationInfoContainer>
  );
};

export default IntegrationMappings;
