import { Box } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';

import Page from 'src/components/Page';

export const PageWrapper = styled(Page)`
  height: 100%;
  margin-left: -16px;
`;

export const PageContentWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ProductItemsWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 25px 24px 40px 56px;
  margin-right: 32px;
`;

export const ItemsCardWrapper = styled(Box)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(279px, 1fr));
  grid-column-gap: 24px;
  grid-row-gap: 25px;
`;

export const MoreItemsWrapper = styled(Box)`
  margin-top: 25px;
  place-self: center;
  grid-column: 1 / -1;
`;

export const NoProductItemsWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
`;

export const ProductItemsList = styled(Box)`
  overflow-y: auto;
`;
