import React, { ReactNode, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';
import { Box, Collapse, IconButton, Typography } from '@mui/material';

import Hint from 'src/components/Hint';
import Iconography from 'src/components/Iconography';
import IntegrationInfoContainer from 'src/components/Mapping/IntegrationInfoContainer';
import { useCheckCatalogMappingErrors } from 'src/hooks/graphQLRequestsHooks';
import useDataTransferStatus from 'src/providers/catalog/hooks/useDataTransferStatus';
import { useSnackbar } from 'src/providers/snackbar';
import {
  Query,
  CatalogJobType,
  Integration,
  ProductItemMapping,
  QueryGetProductItemArgs,
  getProductItemMappingErrors,
  QueryGetIntegrationsRequiredFieldsArgs,
  getIntegrationsRequiredFields,
  QueryGetProductTypeArgs,
  getProductTypeById,
} from 'src/utils/gql';

import useItemMapping from '../context/ItemMappingProvider';

import IntegrationContent from './IntegrationContent';

export interface IntegrationInfoProps {
  integration: Integration;
  mapping?: ProductItemMapping;
  loadingCreateMapping: boolean;
  loadingCreateMappingByShopifyExternalId: boolean;
  loadingSyncItemMapping: boolean;
  isTesting?: boolean;
  syncable?: boolean;
}

export const PRODUCT_ITEM_MAPPING_EXTERNAL_PRODUCT_404 = 'External product not found';

const IntegrationInfo = ({
  syncable,
  integration,
  mapping,
  loadingCreateMapping,
  loadingCreateMappingByShopifyExternalId,
  loadingSyncItemMapping,
  isTesting = false,
}: IntegrationInfoProps) => {
  const { t } = useTranslation();
  const { catalogId, productTypeId, productItemId } = useParams<{
    catalogId: string;
    productTypeId: string;
    productItemId: string;
  }>();
  const snackbar = useSnackbar();
  const formProps = useForm({ mode: 'onChange' });
  const { id, type, settings } = integration;
  const { integrationName } = settings;

  const { data, refetch } = useQuery<Pick<Query, 'getProductItem'>, QueryGetProductItemArgs>(
    getProductItemMappingErrors,
    {
      variables: {
        id: productItemId,
      },
      fetchPolicy: 'cache-first',
    },
  );

  const { data: productTypeData } = useQuery<Pick<Query, 'getProductType'>, QueryGetProductTypeArgs>(
    getProductTypeById,
    {
      variables: {
        id: productTypeId,
      },
      fetchPolicy: 'cache-first',
    },
  );

  const { onResetSyncLoading, onRefetchItemMappings, onSyncProductItem, onOpenDetachPopup } = useItemMapping();

  const { checkCatalogMappingsErrorsQuery } = useCheckCatalogMappingErrors(catalogId);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const itemMappingErrorMessage = mapping?.errorMessage;
  const notFoundOrArchivedError = itemMappingErrorMessage === PRODUCT_ITEM_MAPPING_EXTERNAL_PRODUCT_404;

  const { inProgress: isExportLoading, errorMessage: hasExportError } = useDataTransferStatus({
    catalogId,
    integrationId: id,
    typeFilter: CatalogJobType.Export,
    onSuccess: onRefetchItemMappings,
    onFailure: (error) => {
      onResetSyncLoading();
      snackbar(error || t('mappingSidebar.itemMapping.exportItemError'), 'error');
    },
    onCompleted: () => {
      checkCatalogMappingsErrorsQuery();
      refetch();
    },
  });

  const { data: requiredIntegrationFields } = useQuery<
    Pick<Query, 'getIntegrationsRequiredFields'>,
    QueryGetIntegrationsRequiredFieldsArgs
  >(getIntegrationsRequiredFields, {
    fetchPolicy: 'cache-first',
    variables: {
      type,
    },
  });

  const handleDetach = () => {
    if (mapping) {
      onOpenDetachPopup(mapping.id);
    }
  };

  const syncItemHintText = (
    <Trans
      i18nKey="mappingSidebar.itemMapping.syncItem"
      defaults="<bold>Synс</bold> item"
      components={{ bold: <strong /> }}
    />
  ) as ReactNode;

  return (
    <FormProvider {...formProps}>
      <IntegrationInfoContainer
        key={id}
        syncable={syncable}
        integrationType={type}
        title={integrationName}
        isOpen={isOpen}
        showLoader={loadingCreateMapping || loadingCreateMappingByShopifyExternalId}
        showLastSyncButton={!!mapping && (!itemMappingErrorMessage || notFoundOrArchivedError)}
        itemMappingErrorMessage={itemMappingErrorMessage}
        lastSyncErrorMessage={
          hasExportError && !(isExportLoading || loadingSyncItemMapping)
            ? t('mappingSidebar.itemMapping.syncItemError')
            : ''
        }
        lastSync={mapping?.lastSyncedAt}
        isExportInProgress={isExportLoading || loadingSyncItemMapping}
        syncItemsHintText={isTesting ? t('mappingSidebar.itemMapping.syncItem') : (syncItemHintText as string)}
        onClick={() => setIsOpen((prev) => !prev)}
        onSyncButtonClick={() => onSyncProductItem(id)}
        requiredIntegrationFields={requiredIntegrationFields?.getIntegrationsRequiredFields || []}
        integrationProductItemMappingErrors={data?.getProductItem.mappingsErrors}
        productType={productTypeData?.getProductType}
        isInItemMappings
        integration={integration}
      >
        <Collapse in={isOpen}>
          {itemMappingErrorMessage ? (
            <Box margin="23px 0px 0px 24px" display="flex" justifyContent="space-between" alignItems="center">
              <Box whiteSpace="initial">
                {notFoundOrArchivedError ? (
                  <Typography>{t('mappingSidebar.itemMapping.notFound')}</Typography>
                ) : (
                  <Typography variant="subtitle1" color="error">
                    {t('mappingSidebar.itemMapping.error')} {itemMappingErrorMessage}
                  </Typography>
                )}
              </Box>

              <Hint
                type="hover"
                title={`${t('mappingSidebar.itemMapping.content.detachProductHint')}`}
                placement="left"
              >
                <IconButton
                  onClick={handleDetach}
                  size="small"
                  data-testid="detachProductButtonError"
                  sx={{ padding: 0, marginLeft: '17px' }}
                >
                  <Iconography iconName="detach-file" color="primary" />
                </IconButton>
              </Hint>
            </Box>
          ) : (
            <IntegrationContent
              integrationType={type}
              integrationId={id}
              mapping={mapping}
              loadingCreateMapping={loadingCreateMapping}
              loadingCreateMappingByShopifyExternalId={loadingCreateMappingByShopifyExternalId}
              loadingSyncItemMapping={loadingSyncItemMapping}
              loadingExport={isExportLoading}
              integrationName={integrationName}
            />
          )}
        </Collapse>
      </IntegrationInfoContainer>
    </FormProvider>
  );
};

export default IntegrationInfo;
