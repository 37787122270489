import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useMutation } from '@apollo/client';

import ForgotPasswordForm from 'src/components/Forms/ForgotPasswordForm';
import Page from 'src/components/Page';
import { useSnackbar } from 'src/providers/snackbar';
import { resetUserPassword } from 'src/utils/gql';

import ForgotPasswordScreen from './ForgotPasswordScreen';
import SuccessScreen from './SuccessScreen';

function ForgotPassword() {
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const [sendEmailForResetPassword, { loading }] = useMutation(resetUserPassword);
  const [emailWasSent, setEmailWasSent] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');

  const handleSubmit = async (email: string) => {
    const { data } = await sendEmailForResetPassword({
      variables: {
        email: email.trim(),
      },
    });

    if (data.sendEmailForResetPassword) {
      setEmailWasSent(data.sendEmailForResetPassword);
      setEmail(email);
    }
  };

  return (
    <Page title={t('pageTitles.resetYourPassword')}>
      {!emailWasSent ? (
        <ForgotPasswordScreen>
          <ForgotPasswordForm onSubmit={handleSubmit} loading={loading} onError={snackbar} />
        </ForgotPasswordScreen>
      ) : (
        <SuccessScreen email={email} />
      )}
    </Page>
  );
}

export default ForgotPassword;
