import { TableHead } from '@material-ui/core';
import { Box, TextField, Typography, experimentalStyled as styled } from '@mui/material';

export const StyledBox = styled(Box)`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 15px;
  margin-top: 75px;
`;

export const StyledTableHead = styled(TableHead)`
  .MuiTableCell-root {
    font-weight: 700;
    padding: 16px 30px;
    border-bottom: 1px solid;
    border-color: ${({ theme: { palette } }) => palette.background.default};
    color: ${({ theme: { palette } }) => palette.text.primary};
    background: ${({ theme: { palette } }) => palette.common.white};

    :nth-of-type(1) {
      width: 30%;
    }

    :nth-of-type(4) {
      width: 10%;
    }
  }
`;

export const StyledTextPopup = styled('span')`
  font-family: Roboto;
  font-size: 14px;
  line-height: 16px;
  width: 336px;
  text-align: center;
  color: ${({ theme: { palette } }) => palette.text.secondary};
`;

export const StyledTextField = styled(TextField)`
  &.MuiFormControl-root {
    width: 100%;
    margin: 0 0 22px;
  }

    .MuiOutlinedInput-root {
      padding-right: 10px;
      height: 42px;
    }
    .MuiIconButton-root {
      margin-right: -4px;
    }
    .MuiSvgIcon-root {
      color: ${({ theme: { palette } }) => palette.text.disabled};
    }
  }
`;

export const StyledFirstStepPopup = styled(Box)`
  display: flex;
  flex-direction: column;
  padding-top: 7px;
  max-width: 386px;
  margin: 0 auto 40px;
`;

export const StyledSecondStepPopup = styled(Box)`
  max-width: 386px;
  margin: 0 37px;
  line-height: 16px;
`;

export const PopupTextWrapper = styled(Typography)`
  display: flex;
  max-width: 376px;
  justify-content: center;
  margin: 0 auto 50px;
`;

export const NameApiKeyStyled = styled(Typography)`
  max-width: 224px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  variant: h2;
`;

export const StyledBoxButton = styled(Box)`
  display: flex;
  justify-content: space-between;
  padding: 40px 0 60px;
  width: 207px;
  margin: 0 auto;
`;
