import { useEffect } from 'react';

export const useEscapeClick = (onPressEnterKey: () => void) => {
  useEffect(() => {
    const eventFunction = (event: KeyboardEvent) => event.key === 'Escape' && onPressEnterKey();
    document.addEventListener('keydown', eventFunction);
    return () => {
      document.removeEventListener('keydown', eventFunction);
    };
  });
};
