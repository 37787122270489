import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@material-ui/core';

import Layout from './Layout';

const Error401 = () => {
  const { t } = useTranslation();

  return (
    <Layout textTitle={t('authenticateError.title')} textSubTitle={t('authenticateError.subtitle')}>
      <Typography variant="subtitle1" color="text.secondary" fontWeight="400">
        {t('authenticateError.content')}
      </Typography>
    </Layout>
  );
};

export default Error401;
