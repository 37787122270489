import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useQuery } from '@apollo/client';
import { Box, Typography } from '@mui/material';

import DashboardBreadCrumbs from 'src/components/Breadcrumds/DashboardBreadCrumbs';
import ButtonScrollToTop from 'src/components/ButtonScrollToTop';
import { ReactComponent as NoDataIcon } from 'src/components/Icon/no-data-audit-log.svg';
import Page from 'src/components/Page';
import { getAuditLog, Query, QueryGetAuditLogArgs } from 'src/utils/gql';

import AuditLogSearchForm from './AuditLogSearchForm';
import AuditLogTable from './AuditLogTable';
import { getDate } from './functions';
import { ActionType, AuditLogQueryState, Dates } from './types';

const AuditLogPage = () => {
  const { t } = useTranslation();

  const [queryState, setQueryState] = useState<AuditLogQueryState>({
    from: getDate(Dates.Today),
    to: undefined,
    action: undefined,
    entity: undefined,
  });

  const { data, loading } = useQuery<Pick<Query, 'getAuditLog'>, QueryGetAuditLogArgs>(getAuditLog, {
    fetchPolicy: 'network-only',
    variables: { ...queryState },
  });

  const hasTableData = !!data?.getAuditLog.length;

  const filteredAuditLogData = useMemo(
    () => data?.getAuditLog.filter(({ action, metadata }) => !(action == ActionType.Update && !metadata.diffs.length)),
    [data?.getAuditLog],
  );

  const title = t('pageTitles.auditLog');

  return (
    <Page title={title} locationName={title}>
      <Box display="flex" flexDirection="column" maxHeight="100vh" mr="56px">
        <Box mb="25px">
          <DashboardBreadCrumbs />
        </Box>

        <Typography variant="h2">{t('pageTitles.auditLog')}</Typography>

        <AuditLogSearchForm setQueryState={setQueryState} />

        {loading || hasTableData ? (
          <AuditLogTable data={filteredAuditLogData} loading={loading} />
        ) : (
          <Box display="flex" justifyContent="center" alignItems="center" mt="100px" flexDirection="column">
            <NoDataIcon />

            <Typography color="text.secondary" mt="34px">
              {t('auditLogPage.noLogs')}
            </Typography>
          </Box>
        )}
        <ButtonScrollToTop intersection={document.documentElement.clientHeight} />
      </Box>
    </Page>
  );
};

export default AuditLogPage;
