import React from 'react';

import SkeletonProductTypeCard from 'src/components/Skeleton/SkeletonProductTypeCard';

import { SKELETON_PRODUCT_TYPES_COUNT } from '../types';

import { ProductTypesList } from './styled';

const ProductTypesLoadingComponent = () => (
  <ProductTypesList>
    <SkeletonProductTypeCard cardsCount={SKELETON_PRODUCT_TYPES_COUNT} />
  </ProductTypesList>
);

export default ProductTypesLoadingComponent;
