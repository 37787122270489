import { createContext } from 'react';

import { LimitReachedContextType } from './types';

export const defaultValue: LimitReachedContextType = () => {
  if (process.env.NODE_ENV !== 'production') {
    console.error('Called showPopup. Did you forgot to provide a provider?');
  }
};

const LimitReachedContext = createContext<LimitReachedContextType>(defaultValue);

LimitReachedContext.displayName = 'LimitReachedContext';

export default LimitReachedContext;
