import React from 'react';
import { useTranslation } from 'react-i18next';

import { DialogProps } from '@material-ui/core';
import MuiDialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import ContactUsForm from '../ContactUs/contactUsForm';

import Close from './../Images/Close.svg';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiPaper-root, & .MuiDialogContent-root': {
      overflow: 'hidden',
      height: '625px',
      width: '640px',
      padding: '40px 26px',
      maxWidth: '640px',
    },
  },
  closeButton: {
    position: 'absolute',
    zIndex: 2,
    top: '15px',
    right: '15px',
    width: '34px',
    height: '34px',
    padding: '7px',
    borderRadius: '50%',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '0 0 15px #F16152',
    },
    '&:active': {
      boxShadow: 'none',
      outline: 'none',
    },
  },
  closeImg: {
    position: 'absolute',
    margin: 'auto',
    left: '0',
    top: '0',
    bottom: '0',
    right: '0',
  },
  popupTitle: {
    textAlign: 'center',
    fontSize: '42px',
    lineHeight: '49px',
    fontWeight: '700',
    marginBottom: '30px',
    marginTop: '20px',
  },
  popupDescription: {
    textAlign: 'center',
    marginBottom: '32px',
    marginTop: '20px',
    fontSize: '18px',
    lineHeight: '21px',
    fontWeight: 'normal',
  },
}));

interface ContactUsPopupProps extends DialogProps {
  onCloseCallback: () => void;
  onSendCallback: () => void;
}

const ContactUsPopup = ({ onCloseCallback, onSendCallback, ...props }: ContactUsPopupProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <MuiDialog {...props} className={classes.root}>
      <div className={classes.closeButton} onClick={onCloseCallback} role="button" tabIndex={0}>
        <img className={classes.closeImg} src={Close} alt="close" />
      </div>
      <Typography className={classes.popupTitle} color="text.primary" variant="h1">
        {t('contactUsPage.popup.title')}
      </Typography>

      <Typography className={classes.popupDescription} color="text.secondary" variant="h4">
        {t('contactUsPage.popup.description')}
      </Typography>

      <ContactUsForm onCompleted={onSendCallback} />
    </MuiDialog>
  );
};

export default ContactUsPopup;
