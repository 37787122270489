import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ApolloError, DocumentNode, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Box, CardContent, Typography } from '@material-ui/core';

import ExportCard from 'src/components/Card/ExportCard';
import ExportLoader from 'src/components/ExportLoader';
import Popup from 'src/components/Popup';
import { clearIntegrationsCache } from 'src/components/Popups/IntegrationPopup/functions';
import { EXPORT_CARDS_DATA } from 'src/constants';
import { download } from 'src/helpers/download';
import { useCheckCatalogMappingErrors } from 'src/hooks/graphQLRequestsHooks';
import useCheckProposedMappings from 'src/hooks/useCheckProposedMappings';
import useEbayIntegrationCreationInterruption from 'src/hooks/useEbayIntegrationCreationInterruption';
import useIntegrationHealthCheck, { IntegrationErrors } from 'src/hooks/useIntegrationHealthCheck';
import { useCatalogs } from 'src/providers/catalog';
import { useSnackbar } from 'src/providers/snackbar';
import {
  createIntegration,
  exportCatalogToIntegration,
  exportToCSVByProductTypeId,
  exportToCSVZipByCatalogId,
  getIntegrations,
  IntegrationTypes,
  Integration,
  Mutation,
  MutationCreateIntegrationArgs,
  MutationExportCatalogArgs,
  Query,
  QueryGetIntegrationsArgs,
  MutationUpdateIntegrationArgs,
  updateIntegration,
  Reason,
} from 'src/utils/gql';
import { installIntegrationApp } from 'src/views/AppInstalling/utils';
import { ChooseExportPopupState, initialChooseExportPopupState } from 'src/views/Catalogs';
import { InstallingBackdrop } from 'src/views/UserSettings/styled';
import { IntegrationItem } from 'src/views/UserSettings/types';

import SmallIntegrationCard from '../Card/SmallIntegrationCard';
import Loader from '../Loader';

import ExportToIntegrationPopup from './IntegrationPopup/ExportToIntegrationPopup';
import { ExportToIntegrationPopupState, initialExportToIntegrationPopupState } from './IntegrationPopup/types';
import {
  ExportCardDescription,
  ExportCardWrapper,
  ExportCsvStatusDialog,
  ExportPopupDialog,
  ExportPopupDivider,
  StyledExportCardItem,
} from './styled';

export enum ExportPopupQueryType {
  CATALOG = 'catalog',
  PRODUCT_TYPE = 'productType',
}

interface ExportPopupProps {
  setChooseExportPopupState: (
    value: ((prevState: ChooseExportPopupState) => ChooseExportPopupState) | ChooseExportPopupState,
  ) => void;
  chooseExportPopupState: ChooseExportPopupState;
  id: string;
  name: string;
  type: ExportPopupQueryType;
}

const queryTypes: Record<ExportPopupQueryType, DocumentNode> = {
  [ExportPopupQueryType.CATALOG]: exportToCSVZipByCatalogId,
  [ExportPopupQueryType.PRODUCT_TYPE]: exportToCSVByProductTypeId,
};

const getQqlFunctionByType = (type: ExportPopupQueryType) => queryTypes[type];

interface ExportPopupResultInterface {
  exportToCSVZipByCatalogId?: string;
  exportToCSVByProductTypeId?: string;
}

const responseFormatter = (data: ExportPopupResultInterface, type: ExportPopupQueryType) => {
  switch (type) {
    case ExportPopupQueryType.CATALOG:
      return data.exportToCSVZipByCatalogId;
    case ExportPopupQueryType.PRODUCT_TYPE:
      return data.exportToCSVByProductTypeId;
  }
};

const createdIntegrationIdLocalStorageKey = 'createdIntegrationId';

const ExportCatalogPopup = ({
  id,
  name,
  chooseExportPopupState,
  setChooseExportPopupState,
  type,
}: ExportPopupProps) => {
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const { updateCurrentExport } = useCatalogs();

  const [CSVExportStatusPopupOpen, setCSVExportStatusPopupOpen] = useState<boolean>(false);
  const [exportToIntegrationPopup, setExportToIntegrationPopup] = useState<ExportToIntegrationPopupState>(
    initialExportToIntegrationPopupState,
  );
  const [currentExportCardId, setCurrentExportCardId] = useState<string>('');
  const [createdIntegrationId, setCreatedIntegrationId] = useState<string | null>(null);
  const [isInstalling, setIsInstalling] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [isAddition, setIsAddition] = useState<boolean>(false);

  const { checkCatalogMappingsErrorsQuery, loading: loadingErrors } = useCheckCatalogMappingErrors(id);

  const [exportToCSV, { data: exportToCSVLink, error: exportToCSVError, loading: exportToCSVStatus }] = useLazyQuery(
    getQqlFunctionByType(type),
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        download(responseFormatter(data, type) || '');
      },
    },
  );
  const { data: integrations, refetch } = useQuery<Pick<Query, 'getIntegrations'>, QueryGetIntegrationsArgs>(
    getIntegrations,
    {
      fetchPolicy: 'network-only',
      variables: {
        type: '',
      },
      onCompleted: (data) =>
        setCurrentExportCardId(
          data?.getIntegrations ? (data.getIntegrations.find(({ isDefault }) => isDefault)?.id as string) : '',
        ),
    },
  );
  const [addIntegration] = useMutation<Pick<Mutation, 'createIntegration'>, MutationCreateIntegrationArgs>(
    createIntegration,
    {
      update: clearIntegrationsCache,
    },
  );
  const [updateIntegrationById] = useMutation<Pick<Mutation, 'updateIntegration'>, MutationUpdateIntegrationArgs>(
    updateIntegration,
  );
  const [exportCatalog] = useMutation<Pick<Mutation, 'exportCatalog'>, MutationExportCatalogArgs>(
    exportCatalogToIntegration,
  );

  const { handleEbayIntegrationCreationInterruption, createdIntegration, setCreatedIntegration } =
    useEbayIntegrationCreationInterruption({ refetchIntegrations: refetch, withIntegrationsRefetch: true });

  const filteredIntegrations = useMemo(
    () =>
      integrations?.getIntegrations.reduce(
        (
          acc: {
            wooCommerceIntegrations: Integration[];
            ebayIntegrations: Integration[];
            shopifyIntegrations: Integration[];
            facebookIntegrations: Integration[];
            defaultIntegration?: Integration;
          },
          integration,
        ) => {
          if (integration.isDefault) {
            acc.defaultIntegration = integration;
          }

          switch (integration.type) {
            case IntegrationTypes.Shopify:
              acc.shopifyIntegrations.push(integration);
              break;
            case IntegrationTypes.Woocommerce:
              acc.wooCommerceIntegrations.push(integration);
              break;
            case IntegrationTypes.Ebay:
              acc.ebayIntegrations.push(integration);
              break;
            case IntegrationTypes.Facebook:
              acc.facebookIntegrations.push(integration);
              break;
          }

          return acc;
        },
        {
          wooCommerceIntegrations: [],
          ebayIntegrations: [],
          shopifyIntegrations: [],
          facebookIntegrations: [],
          defaultIntegration: undefined,
        },
      ),
    [integrations?.getIntegrations],
  );

  const onExportToCsv = () => {
    exportToCSV({
      variables: {
        productTypeId: id,
        id,
      },
    });
  };

  const handleCloseExportPopup = () => {
    setChooseExportPopupState(initialChooseExportPopupState);
    setCurrentExportCardId(
      filteredIntegrations?.defaultIntegration && !hasDefaultIntegrationError
        ? filteredIntegrations.defaultIntegration.id
        : '',
    );
  };

  const handleExportToIntegration = async (integrationId: string, callback: CallableFunction) => {
    setLoading(true);

    try {
      updateCurrentExport(id, false);

      await exportCatalog({
        variables: {
          integrationId,
          id,
        },
      });

      setCreatedIntegrationId(null);
      callback();
    } catch (error) {
      const { graphQLErrors, message: errorText } = error as ApolloError;
      const message = graphQLErrors && graphQLErrors.length > 0 ? graphQLErrors[0].message : errorText;
      if (error) {
        snackbar(message);
      }
    }

    setLoading(false);
  };

  const { getListWithProposedMappings, getProposedMappingsLoading, renderMappingsSidebar } = useCheckProposedMappings({
    callbackFn: async () => {
      await handleExportToIntegration(
        filteredIntegrations?.defaultIntegration?.id || createdIntegrationId || '',
        handleCloseExportPopup,
      );
    },
    closeCallbackFn: handleCloseExportPopup,
    errorCallbackFn: checkCatalogMappingsErrorsQuery,
  });

  const { checkIntegrationStatus, integrationErrors, loadingIntegrationHealthCheck } = useIntegrationHealthCheck(
    checkCatalogMappingsErrorsQuery,
  );

  const integrationUnavailableErrorsMap = useMemo(
    () =>
      integrations?.getIntegrations.reduce((acc: IntegrationErrors, { id, mappingsErrors }) => {
        const integrationUnavailableError = mappingsErrors?.find(
          ({ reason }) => reason === Reason.IntegrationUnavailable,
        );

        if (integrationUnavailableError) {
          acc[id] = true;
        }

        return acc;
      }, {}),
    [integrations],
  );

  const integrationErrorsMap = useMemo(
    () => ({
      ...integrationErrors,
      ...integrationUnavailableErrorsMap,
    }),
    [integrationErrors, integrationUnavailableErrorsMap],
  );

  const hasDefaultIntegrationError = useMemo(
    () =>
      filteredIntegrations?.defaultIntegration && !!integrationErrorsMap?.[filteredIntegrations?.defaultIntegration.id],
    [filteredIntegrations?.defaultIntegration, integrationErrorsMap],
  );

  const addNewIntegration = async (integration: IntegrationItem, callback: CallableFunction) => {
    setLoading(true);

    const {
      type,
      settings: { integrationName, shop, url, apiKey, apiSecret, redirectUrl, clientId, clientSecret, marketplaceId },
      isDefault,
    } = integration;

    if ([IntegrationTypes.Shopify, IntegrationTypes.Facebook].includes(type)) {
      setIsInstalling(true);

      await installIntegrationApp({
        type,
        settings: {
          shop,
          integrationName,
          marketplaceId,
          clientId,
          clientSecret,
          isDefault,
          redirectUrl,
        },
        onSuccess: async (integrationId) => {
          setIsInstalling(false);
          setCreatedIntegrationId(integrationId);
          checkIntegrationStatus({
            integrationId,
            mainCallbackFn: () => getListWithProposedMappings(id, integrationId),
            additionalCallbackFn: () => {
              callback();
            },
          });
        },
        onComplete: async () => {
          setLoading(false);
          await refetch();
          setIsInstalling(false);
        },
      });
    } else if (type === IntegrationTypes.Ebay) {
      if (createdIntegration) {
        const { id: integrationId, settings } = createdIntegration;

        try {
          await updateIntegrationById({
            variables: {
              data: {
                id: integrationId,
                type,
                isDefault,
                settings: {
                  // order of destructing objects is important
                  ...settings,
                  ...integration.settings,
                },
              },
            },
          });

          localStorage.removeItem(createdIntegrationIdLocalStorageKey);
          setCreatedIntegrationId(integrationId);
          checkIntegrationStatus({
            integrationId,
            mainCallbackFn: () => getListWithProposedMappings(id, integrationId),
            additionalCallbackFn: callback,
          });
        } catch (error) {
          await Promise.all([handleEbayIntegrationCreationInterruption({}), handleCloseExportToIntegrationPopup()]);

          if (error) {
            const { graphQLErrors, message: errorText } = error as ApolloError;
            const message = graphQLErrors && graphQLErrors.length ? graphQLErrors[0].message : errorText;

            snackbar(message);
          }
        }
      } else {
        setIsInstalling(true);

        await installIntegrationApp({
          type,
          settings: {
            shop,
            integrationName,
            marketplaceId,
            clientId,
            clientSecret,
            isDefault,
            redirectUrl,
          },
          onSuccess: async (id) => {
            const { data } = await refetch();
            const targetIntegration = data.getIntegrations.find((integration) => integration.id === id);

            localStorage.setItem(createdIntegrationIdLocalStorageKey, id);
            setCreatedIntegration(targetIntegration);
            setIsAddition(true);
          },
          onFailure: () => {
            setIsAddition(false);
          },
          onComplete: async () => {
            setLoading(false);
            setIsInstalling(false);
          },
        });
      }
    } else {
      try {
        const { data } = await addIntegration({
          variables: {
            data: {
              type,
              isDefault,
              settings: { integrationName, url, apiKey, apiSecret },
            },
          },
        });

        if (data) {
          const { id: integrationId } = data?.createIntegration;

          setCreatedIntegrationId(integrationId);

          await refetch();
          checkIntegrationStatus({
            integrationId,
            mainCallbackFn: () => getListWithProposedMappings(id, integrationId),
            additionalCallbackFn: callback,
          });
        }
      } catch (error) {
        const { graphQLErrors, message: errorText } = error as ApolloError;
        const message = graphQLErrors && graphQLErrors.length > 0 ? graphQLErrors[0].message : errorText;
        if (error) {
          snackbar(message);
          setLoading(false);
        }
      }
    }
  };

  const handleMainButtonClick = () => {
    switch (currentExportCardId) {
      case EXPORT_CARDS_DATA.csv.type:
        setCSVExportStatusPopupOpen(true);
        onExportToCsv();
        handleCloseExportPopup();
        break;

      case EXPORT_CARDS_DATA.wooCommerce.type:
        setExportToIntegrationPopup({
          isOpen: true,
          integrationType: IntegrationTypes.Woocommerce,
          data: filteredIntegrations?.wooCommerceIntegrations || [],
        });
        handleCloseExportPopup();
        break;

      case EXPORT_CARDS_DATA.eBay.type:
        setExportToIntegrationPopup({
          isOpen: true,
          integrationType: IntegrationTypes.Ebay,
          data: filteredIntegrations?.ebayIntegrations || [],
        });
        handleCloseExportPopup();
        break;

      case EXPORT_CARDS_DATA.shopify.type:
        setExportToIntegrationPopup({
          isOpen: true,
          integrationType: IntegrationTypes.Shopify,
          data: filteredIntegrations?.shopifyIntegrations || [],
        });
        handleCloseExportPopup();
        break;

      case EXPORT_CARDS_DATA.facebook.type:
        setExportToIntegrationPopup({
          isOpen: true,
          integrationType: IntegrationTypes.Facebook,
          data: filteredIntegrations?.facebookIntegrations || [],
        });
        handleCloseExportPopup();
        break;

      case filteredIntegrations?.defaultIntegration && filteredIntegrations.defaultIntegration.id:
        const integrationId = filteredIntegrations?.defaultIntegration?.id || '';

        integrationId &&
          checkIntegrationStatus({
            integrationId,
            mainCallbackFn: () => getListWithProposedMappings(id, integrationId),
            additionalCallbackFn: () => setCurrentExportCardId(''),
          });
        break;

      default:
        break;
    }
  };

  const handleCloseExportToIntegrationPopup = useCallback(() => {
    setExportToIntegrationPopup((prev) => ({ ...prev, isOpen: false }));
    setCurrentExportCardId(
      filteredIntegrations?.defaultIntegration && !hasDefaultIntegrationError
        ? filteredIntegrations.defaultIntegration.id
        : '',
    );
  }, [filteredIntegrations?.defaultIntegration, hasDefaultIntegrationError]);

  const handleBack = async () => {
    setChooseExportPopupState((prev) => ({ ...prev, open: true }));
    handleCloseExportToIntegrationPopup();

    if (createdIntegration) {
      await handleEbayIntegrationCreationInterruption({});
    }

    if (isAddition) setIsAddition(false);
  };

  const handleClickSmallIntegrationCard = () => {
    if (filteredIntegrations?.defaultIntegration) {
      setCurrentExportCardId(filteredIntegrations.defaultIntegration.id);
    }
  };

  const handleErrorLinkClick = () => {
    const integrationId = filteredIntegrations?.defaultIntegration?.id || '';

    if (integrationId) {
      setCurrentExportCardId(integrationId);
      checkIntegrationStatus({
        integrationId,
        mainCallbackFn: () => getListWithProposedMappings(id, integrationId),
        additionalCallbackFn: () => setCurrentExportCardId(''),
      });
    }
  };

  const handleClickExportCard = (disabled: boolean, type: IntegrationTypes): void => {
    if (!disabled) {
      setCurrentExportCardId(type);
    }
  };

  useEffect(() => {
    if (!loadingErrors) {
      handleCloseExportToIntegrationPopup();
    }
  }, [handleCloseExportToIntegrationPopup, loadingErrors]);

  return (
    <>
      <Popup
        headerMarginAbsence
        open={chooseExportPopupState.open}
        mainTitle={
          <Box display="flex" justifyContent="center" flexDirection="column">
            <Box display="flex" justifyContent="center">
              <Typography
                maxWidth="350px"
                whiteSpace="nowrap"
                ml="20px"
                overflow="hidden"
                textOverflow="ellipsis"
                variant="h2"
              >
                {name}
              </Typography>
              &nbsp;
              <Typography variant="h2">{t('cataloguesPage.chooseExportPopup.title')}</Typography>
            </Box>
            <Typography
              color="text.secondary"
              variant="body1"
              fontSize={filteredIntegrations?.defaultIntegration ? '14px' : '18px'}
              mt="50px"
              mb="20px"
            >
              {t(
                `cataloguesPage.chooseExportPopup.${
                  filteredIntegrations?.defaultIntegration ? 'defaultIntegration' : 'selectExportMethod'
                }`,
              )}
            </Typography>
          </Box>
        }
        mainButtonText={t(
          `cataloguesPage.${
            filteredIntegrations?.defaultIntegration &&
            filteredIntegrations.defaultIntegration.id === currentExportCardId
              ? 'exportToIntegrationPopup.export'
              : 'chooseExportPopup.mainButton'
          }`,
        )}
        onClose={handleCloseExportPopup}
        onMainButtonClick={handleMainButtonClick}
        mainButtonDisabled={!currentExportCardId}
        maxWidth="sm"
        height="611px"
        actionsMarginTop="32px"
        loadingOnMainButton={loading || getProposedMappingsLoading || loadingIntegrationHealthCheck}
        marginContent
        testMainButton="nextButton"
      >
        <ExportPopupDialog>
          <ExportCardWrapper>
            {filteredIntegrations?.defaultIntegration && (
              <>
                <SmallIntegrationCard
                  type={filteredIntegrations?.defaultIntegration.type}
                  url={
                    filteredIntegrations?.defaultIntegration.settings.shop ||
                    filteredIntegrations.defaultIntegration.settings.url
                  }
                  name={filteredIntegrations?.defaultIntegration.settings.integrationName}
                  isSelected={filteredIntegrations?.defaultIntegration.id === currentExportCardId}
                  onClick={handleClickSmallIntegrationCard}
                  hasError={hasDefaultIntegrationError}
                  onErrorLinkClick={handleErrorLinkClick}
                />

                <ExportPopupDivider />
              </>
            )}

            {Object.values(EXPORT_CARDS_DATA).map(({ headerTitle, text, type, testId }) => {
              const disabled = type !== IntegrationTypes.Csv && chooseExportPopupState.isSkuDuplicated;

              return (
                <StyledExportCardItem key={type} className="export_csv_card">
                  <ExportCard
                    disabled={disabled}
                    integrationType={type}
                    headerTitle={t(headerTitle)}
                    isChecked={type === currentExportCardId}
                    onClickCard={() => handleClickExportCard(disabled, type)}
                    testId={testId}
                  >
                    <CardContent>
                      <ExportCardDescription variant="h3">{t(text)}</ExportCardDescription>
                    </CardContent>
                  </ExportCard>
                </StyledExportCardItem>
              );
            })}
          </ExportCardWrapper>
        </ExportPopupDialog>
      </Popup>

      <Popup
        open={CSVExportStatusPopupOpen}
        mainTitle={t('cataloguesPage.csvExportStatusPopup.title')}
        mainButtonText={t('cataloguesPage.csvExportStatusPopup.mainButton')}
        onMainButtonClick={() => setCSVExportStatusPopupOpen(false)}
        onClose={() => setCSVExportStatusPopupOpen(false)}
        maxWidth="sm"
        loadingOnMainButton={false}
      >
        <ExportCsvStatusDialog>
          <Typography color="text.secondary" variant="body1">
            {exportToCSVStatus && t('cataloguesPage.csvExportStatusPopup.downloadPrepare')}
            {exportToCSVLink && t('cataloguesPage.csvExportStatusPopup.downloadSuccess')}
            {exportToCSVError && t('cataloguesPage.csvExportStatusPopup.downloadError')}
          </Typography>
          <ExportLoader isSuccess={exportToCSVLink} isError={!!exportToCSVError} isLoading={exportToCSVStatus} />
        </ExportCsvStatusDialog>
      </Popup>

      <ExportToIntegrationPopup
        setExportToIntegrationPopup={setExportToIntegrationPopup}
        createdIntegrationId={createdIntegration?.id}
        isAddition={isAddition}
        setIsAddition={setIsAddition}
        popupState={exportToIntegrationPopup}
        handleEbayIntegrationCreationInterruption={handleEbayIntegrationCreationInterruption}
        onCloseExportToIntegrationPopup={handleCloseExportToIntegrationPopup}
        onBack={handleBack}
        loading={loading || loadingIntegrationHealthCheck}
        onAddNewIntegration={addNewIntegration}
        onExportToIntegration={handleExportToIntegration}
        testMainButton="exportButton"
        testSecondaryButton="backButton"
        catalogId={id}
        integrationErrors={integrationErrorsMap}
        onCheckIntegration={checkIntegrationStatus}
      />

      <InstallingBackdrop open={isInstalling}>
        <Loader size="medium" />
        <Typography mt="20px">{t('userSettingsPage.waiting')}</Typography>
      </InstallingBackdrop>

      {renderMappingsSidebar()}
    </>
  );
};

export default ExportCatalogPopup;
