import React, { createRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import NumberInput from 'src/components/Forms/FormInputs/NumberInput';
import Select, { SelectOptionItem } from 'src/components/Select';
import { PAYMENT_FREQUENCY_OPTIONS } from 'src/constants';
import { priceProps } from 'src/utils/numberFieldValidator';

import { CatalogSettingsState } from './types';

interface SharedOptionsProps {
  settingsState: CatalogSettingsState;
  translatedPaymentFrequency: { label: string; value: string }[];
  onChangeSelect: (option: SelectOptionItem) => void;
  setSettingsState: (setFunction: (prev: CatalogSettingsState) => CatalogSettingsState) => void;
  onUpdatePriceValue?: (value: string) => void;
  onUpdateExtraChargePercentageValue?: (value: string) => void;
}

enum SharedOptionsFieldsNames {
  price = 'price',
  extraChargePercentage = 'extraChargePercentage',
}

const SharedOptions = ({
  settingsState,
  translatedPaymentFrequency,
  onChangeSelect,
  setSettingsState,
}: SharedOptionsProps) => {
  const { t } = useTranslation();

  const inputRef = createRef<HTMLInputElement>();

  const [isFrequencyFieldFocused, setIsFrequencyFieldFocused] = useState<boolean>(false);

  const isPaymentFree = settingsState?.paymentFrequency.label === PAYMENT_FREQUENCY_OPTIONS[0].label;
  const priceValue = isPaymentFree ? '' : settingsState?.price;
  const showPriceError = !(priceValue.length || isPaymentFree);

  const handleChange = (value: string, name: string) => {
    setSettingsState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <Box display="flex" flexDirection="column" mt="40px">
      <Typography variant="h3" fontSize="18px" fontStyle="normal" fontWeight="500">
        {t('settingsPage.sharingOptions.headers.sharingOption')}
      </Typography>
      <Box display="flex" flexWrap="wrap">
        <Box mt="20px" mr="24px">
          <Typography variant="h3" mb="12px">
            {t('settingsPage.sharingOptions.headers.paymentFrequency')}
          </Typography>
          <Box width="288px">
            <Select
              data-testid="paymentFrequencyInput"
              onChangeSelect={onChangeSelect}
              label={t('settingsPage.sharingOptions.labelOptions.price')}
              options={translatedPaymentFrequency}
              selectedOptionItem={settingsState.paymentFrequency}
            />
          </Box>
        </Box>
        <Box mt="20px" mr="24px">
          <Typography variant="h3" mb="12px">
            {t('settingsPage.sharingOptions.headers.price')}
          </Typography>
          <Box width="288px">
            <NumberInput
              name={SharedOptionsFieldsNames.price}
              helperText={(showPriceError && t('settingsPage.errors.emptyOrIncorrectPrice')) || ''}
              label={t('settingsPage.sharingOptions.catalogOptions.price.label')}
              value={priceValue}
              disabled={isPaymentFree}
              error={showPriceError}
              onChange={(value) => handleChange(value, SharedOptionsFieldsNames.price)}
              inputIdentifier="priceInput"
              {...priceProps}
            />
          </Box>
        </Box>
        <Box mt="20px">
          <Typography variant="h3" mb="12px">
            {t('settingsPage.sharingOptions.headers.extraCharge')}
          </Typography>
          <Box width="288px">
            <NumberInput
              onClick={() => inputRef.current?.focus()}
              name={SharedOptionsFieldsNames.extraChargePercentage}
              value={settingsState?.extraChargePercentage}
              label={
                isFrequencyFieldFocused || (!isFrequencyFieldFocused && settingsState?.extraChargePercentage.length)
                  ? t('settingsPage.sharingOptions.catalogOptions.extraCharge.label')
                  : t('settingsPage.sharingOptions.catalogOptions.extraCharge.placeholder')
              }
              onFocus={() => setIsFrequencyFieldFocused(true)}
              onBlur={() => setIsFrequencyFieldFocused(false)}
              onChange={(value) => handleChange(value, SharedOptionsFieldsNames.extraChargePercentage)}
              inputIdentifier="extraChargePercentageInput"
              minValue={0}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SharedOptions;
