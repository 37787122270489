import React from 'react';

import { makeStyles } from '@material-ui/core';
import Avatar, { AvatarProps } from '@material-ui/core/Avatar';
import { alpha, Box, styled } from '@mui/material';

import Loader from './Loader';

type AvatarType = 'small' | 'large';

const LoaderWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  borderRadius: '50%',
  background: alpha(theme.palette.common.black, 0.5),
}));

const useStyles = makeStyles({
  largeAvatar: ({ src }: AvatarNewProps) => ({
    width: 126,
    height: 126,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundImage: src ? `url(${src})` : `url(${process.env.PUBLIC_URL}/images/user-avatar.svg)`,
    '&:hover': {
      transitionDuration: src ? 'none' : '0.3s',
      backgroundImage: src
        ? `url(${process.env.PUBLIC_URL}/images/user-avatar-hover.svg), url(${src})`
        : `url(${process.env.PUBLIC_URL}/images/user-avatar-hover.svg), url(${process.env.PUBLIC_URL}/images/user-avatar.svg)`,
    },
    '& svg': {
      display: 'none',
    },
  }),
  smallAvatar: ({ width, height }: AvatarNewProps) => ({
    width: width || 30,
    height: height || 30,
  }),
});

interface AvatarNewProps extends AvatarProps {
  size?: AvatarType;
  loading?: boolean;
  width?: string;
  height?: string;
}

function UserAvatar({ size, src, alt, loading, width, height }: AvatarNewProps) {
  const classes = useStyles({ src, width, height });

  if (size === 'small') {
    return <Avatar className={classes.smallAvatar} src={src} alt={alt} />;
  } else if (size === 'large') {
    return (
      <Box position="relative">
        <Avatar className={classes.largeAvatar} alt={alt} />
        {loading && (
          <LoaderWrapper>
            <Loader size="small" color="inherit" />
          </LoaderWrapper>
        )}
      </Box>
    );
  } else {
    return <Avatar src={src} alt={alt} />;
  }
}

export default UserAvatar;
