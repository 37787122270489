import { useEffect, useState } from 'react';

import { PARSING_FILE_STATE } from 'src/constants';
import useOnboarding from 'src/providers/OnboardingProvider/hooks/useOnboarding';
import { ProductItem, ProductType } from 'src/utils/gql';

import { CsvFileInfo, ParsedData } from './useImportCsv';

const useEmulateCsvParsing = () => {
  const {
    onboardingState: { createdOnboardingProductType, createdOnboardingProductItems },
  } = useOnboarding();

  const [isParsingInProgress, setIsParsingInProgress] = useState<boolean>(false);
  const [onboardingInfoValues, setOnboardingInfoValues] = useState<CsvFileInfo>({
    id: '1',
    name: 'Otomate_Collection.csv',
    uploadPercentage: 0,
    state: PARSING_FILE_STATE.Uploading,
    productItems: [],
    parsedProgressData: {
      inParsing: 15,
      parsed: 0,
      parseFailed: 0,
    },
    cancelUpload: () => '',
  });

  const emulate = () => setIsParsingInProgress(true);

  useEffect(() => {
    if (isParsingInProgress) {
      setTimeout(() => {
        setOnboardingInfoValues((prevState) => ({
          ...prevState,
          uploadPercentage: 25,
        }));
      }, 500);

      setTimeout(() => {
        setOnboardingInfoValues((prevState) => ({
          ...prevState,
          uploadPercentage: 50,
        }));
      }, 1000);

      setTimeout(() => {
        setOnboardingInfoValues((prevState) => ({
          ...prevState,
          uploadPercentage: 75,
        }));
      }, 1500);

      setTimeout(() => {
        setOnboardingInfoValues((prevState) => ({
          ...prevState,
          state: PARSING_FILE_STATE.Uploaded,
          uploadPercentage: 100,
        }));
      }, 2000);

      setTimeout(() => {
        setOnboardingInfoValues((prevState) => ({
          ...prevState,
          state: PARSING_FILE_STATE.Parsing,
          productType: createdOnboardingProductType as ProductType,
          productItems: createdOnboardingProductItems as ProductItem[],
        }));
      }, 4000);

      setTimeout(() => {
        setOnboardingInfoValues((prevState) => ({
          ...prevState,
          parsedProgressData: {
            ...prevState.parsedProgressData,
            parsed: 5,
          } as ParsedData,
        }));
      }, 4500);

      setTimeout(() => {
        setOnboardingInfoValues((prevState) => ({
          ...prevState,
          parsedProgressData: {
            ...prevState.parsedProgressData,
            parsed: 10,
          } as ParsedData,
        }));
      }, 5000);

      setTimeout(() => {
        setOnboardingInfoValues((prevState) => ({
          ...prevState,
          parsedProgressData: {
            ...prevState.parsedProgressData,
            parsed: 15,
          } as ParsedData,
        }));
      }, 5500);

      setTimeout(() => {
        setOnboardingInfoValues((prevState) => ({
          ...prevState,
          state: PARSING_FILE_STATE.Parsed,
        }));
      }, 6000);
    }
  }, [createdOnboardingProductItems, createdOnboardingProductType, isParsingInProgress]);

  return { onboardingInfoValues, emulate };
};

export default useEmulateCsvParsing;
