import React from 'react';

import { useQuery } from '@apollo/client';
import { Box } from '@material-ui/core';

import {
  Query,
  QueryGetIntegrationsSystemFieldsArgs,
  getIntegrationsSystemFields,
  ProductItemValue,
  ProductTypeFieldIntegrationMapping,
  MediaUsage,
} from 'src/utils/gql';

import Loader from '../Loader';

import { CompletenessIntegration } from './ChipsCompleteness';
import { CompletenessBody } from './CompletenessBody';
import { useCheckFields } from './useCheckFields';

interface SidebarCompletenessItemProps {
  integration: CompletenessIntegration;
  fieldsMapping: ProductTypeFieldIntegrationMapping[];
  fieldsValues: ProductItemValue[];
  isDataLoading: boolean;
  mediaUsages?: MediaUsage[];
}

export const SidebarCompletenessItem = ({
  integration,
  fieldsValues,
  fieldsMapping,
  isDataLoading,
  mediaUsages,
}: SidebarCompletenessItemProps) => {
  const { data: integrationFields, loading: integrationFieldsLoading } = useQuery<
    Pick<Query, 'getIntegrationsSystemFields'>,
    QueryGetIntegrationsSystemFieldsArgs
  >(getIntegrationsSystemFields, {
    fetchPolicy: 'cache-first',
    variables: {
      type: integration.type,
    },
  });

  const { completedFields, inCompletedFields, percentage } = useCheckFields({
    systemFields: integrationFields?.getIntegrationsSystemFields,
    fieldsValues,
    fieldsMapping,
    integration,
    mediaUsages,
  });
  return (
    <Box display="flex" justifyContent="center" alignItems="center" maxWidth="482px">
      {integrationFieldsLoading || isDataLoading ? (
        <Loader />
      ) : (
        <CompletenessBody
          type={integration.type}
          completedFields={completedFields}
          inCompletedFields={inCompletedFields}
          percentage={percentage}
          isInSidebar
        />
      )}
    </Box>
  );
};
