import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';

import { useMutation, useQuery } from '@apollo/client';
import { Link, Typography } from '@material-ui/core';

import SetNewPasswordForm from 'src/components/Forms/SetNewPasswordForm';
import { useSnackbar } from 'src/providers/snackbar';
import { UpdatePasswordInput, updatePassword, validatePasswordToken } from 'src/utils/gql';

import Loader from '../../components/Loader';
import Page from '../../components/Page';
import { HistoryState } from '../../utils/history';
import { LoaderWrapper } from '../Catalogs/styled';

import SetNewPasswordImagebackground from './SetNewPasswordImagebackground';
import { SetNewPasswordContainer, SideContainer, StyledSetNewPasswordContainer } from './styled';

function SetNewPassword() {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation<HistoryState>();
  const query = new URLSearchParams(location.search);
  const mailToken = query.get('token');
  const snackbar = useSnackbar();
  const [setNewPassword, { loading }] = useMutation(updatePassword);
  const [isImageLoading, setIsImageLoading] = useState(true);

  const { data, loading: load } = useQuery(validatePasswordToken, {
    variables: { token: mailToken },
  });
  data && !data.validatePasswordToken && history.push(location.state?.from || '/auth/login');

  const handleSubmit = useCallback(
    async (data: UpdatePasswordInput) => {
      const result = await setNewPassword({
        variables: {
          data,
        },
      });

      if (result.data) {
        snackbar(t('setNewPasswordPage.successSnackbarMessage'), 'success');
        history.push('/auth/login');
      }
    },
    [history, setNewPassword, snackbar, t],
  );

  return (
    <Page title={t('setNewPasswordPage.setNewPassword')}>
      <StyledSetNewPasswordContainer>
        {load ? (
          <LoaderWrapper>
            <Loader fullScreen={true} />
          </LoaderWrapper>
        ) : (
          <>
            <SetNewPasswordContainer>
              <Typography variant="h1" whiteSpace="nowrap">
                {t('setNewPasswordPage.setNewPassword')}
              </Typography>

              <SetNewPasswordForm onSubmit={handleSubmit} onError={snackbar} loading={loading} />

              <Link
                marginTop="40px"
                color="secondary"
                component={RouterLink}
                to="/auth/login"
                underline="hover"
                variant="h4"
              >
                {t('setNewPasswordPage.backToLogin')}
              </Link>
            </SetNewPasswordContainer>
            <SideContainer>
              {isImageLoading && <SetNewPasswordImagebackground />}
              <img
                src={`${process.env.PUBLIC_URL}/images/lock.webp`}
                alt="Lock"
                onLoad={() => setIsImageLoading(false)}
                height="425"
                width="654"
              />
            </SideContainer>
          </>
        )}
      </StyledSetNewPasswordContainer>
    </Page>
  );
}

export default SetNewPassword;
