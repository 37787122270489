import { KeyboardEvent } from 'react';

export const validateTextFieldValue = (value: string) => {
  return value.trimStart().replace(/\s\s+/g, ' ');
};

export const handleValidateKeyPress = (e: KeyboardEvent<HTMLInputElement>, value?: string) => {
  if (e.code === 'Space' && value === '') {
    e.preventDefault();
  }
};
