import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, IconButton, Link, Typography } from '@mui/material';

import Hint from 'src/components/Hint';
import Iconography from 'src/components/Iconography';
import { ExternalProductInfo, IntegrationTypes, ProductItemMapping } from 'src/utils/gql';
import { EBAY_SITES_IDS_SELECT_OPTIONS } from 'src/views/Catalogs/ProductTypes/ProductTypeSettings/MappingTabContent/constants';

interface ProductInfoProps {
  productInfo: ExternalProductInfo;
  onOpenDetachPopup: () => void;
  integrationType: IntegrationTypes;
  mapping: ProductItemMapping;
  isExportInProgress?: boolean;
}

const ProductInfo = ({
  productInfo,
  onOpenDetachPopup,
  integrationType,
  mapping,
  isExportInProgress,
}: ProductInfoProps) => {
  const { t } = useTranslation();

  const handleLinkClick = () => {
    window.open(productInfo.productPreviewUrl as string, '_blank', 'noopener, noreferrer');
  };

  if (isExportInProgress) {
    return (
      <Box display="flex" justifyContent="space-between" mb="25px" sx={{ cursor: 'default' }}>
        <Typography color="text.disabled" whiteSpace="initial">
          {t('mappingSidebar.itemMapping.content.exportInProgress')}:
        </Typography>

        <IconButton
          onClick={onOpenDetachPopup}
          id="attach-detach"
          size="small"
          data-testid="detachProductButton"
          disabled
        >
          <Iconography iconName="detach-file" color="primary" />
        </IconButton>
      </Box>
    );
  }

  return (
    <>
      <Typography mb="13px" variant="subtitle1">
        {t('mappingSidebar.itemMapping.content.name')}:
      </Typography>

      <Box display="flex" justifyContent="space-between" mb="25px">
        <Typography className="ellipsis" variant="body2" fontSize="15px" maxWidth="600px">
          {productInfo.productName}
        </Typography>

        <Hint type="hover" title={`${t('mappingSidebar.itemMapping.content.detachProductHint')}`} placement="left">
          <IconButton onClick={onOpenDetachPopup} id="attach-detach" size="small" data-testid="detachProductButton">
            <Iconography iconName="detach-file" color="primary" />
          </IconButton>
        </Hint>
      </Box>

      <Typography mb="13px" variant="subtitle1">
        {t('mappingSidebar.itemMapping.content.link')}:
      </Typography>

      <Link id="previewLink" className="ellipsis" component="button" onClick={handleLinkClick} variant="body2">
        {productInfo.productPreviewUrl}
      </Link>

      {integrationType === IntegrationTypes.Ebay && (
        <Box>
          <Box>
            <Typography mb="13px" mt="25px" variant="subtitle1">
              {t('mappingSidebar.itemMapping.content.siteIdInfo')}:
            </Typography>

            <Typography variant="body2" fontSize="15px">
              {EBAY_SITES_IDS_SELECT_OPTIONS.find(({ value }) => value === mapping?.metadata?.siteId)?.label}
            </Typography>
          </Box>

          <Box>
            <Typography mb="13px" mt="25px" variant="subtitle1">
              {t('mappingSidebar.itemMapping.content.note')}:
            </Typography>

            <Typography variant="body2" fontSize="15px">
              {t('mappingSidebar.itemMapping.content.ebayNoteInfo')}
            </Typography>
          </Box>
        </Box>
      )}

      {productInfo.productVariantName && (
        <>
          <Typography mt="25px" variant="subtitle1">
            {t('mappingSidebar.itemMapping.content.productVariant')}:
          </Typography>

          <Typography variant="body2" className="ellipsis" maxWidth="600px" fontSize="15px" mt="13px">
            {productInfo.productVariantName}
          </Typography>
        </>
      )}
    </>
  );
};

export default ProductInfo;
