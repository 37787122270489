import React from 'react';

import { Box, Skeleton } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { keyframes } from '@mui/system';

export const SkeletonSmallCatalogCardWrapper = styled(Box)`
  margin-bottom: 24px;
  overflow-y: auto;

  div:last-child {
    margin-bottom: 0;
  }
`;

const opacity = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Container = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: 290,
  height: 137,
  background: theme.palette.background.default,
  borderRadius: 4,
  boxShadow: '0 2px 10px rgba(46, 96, 170, 0.25)',
  padding: '33px 22px 22px 25px',
  animation: `${opacity} 1s ease`,
}));

interface SkeletonSmallCatalogCardProps {
  cardsCount: number;
}

const SkeletonSmallCatalogCard = ({ cardsCount }: SkeletonSmallCatalogCardProps) => (
  <>
    {new Array(cardsCount).fill('card').map((_, idx) => (
      <Container key={idx} marginBottom={cardsCount > 1 ? '20px' : '0px'} ml="14px">
        <Box display="flex" justifyContent="space-between">
          <Skeleton variant="text" width="150px" height="30px" />

          <Skeleton variant="circular" width="30px" height="30px" />
        </Box>

        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
          <Box display="flex" justifyContent="space-between" flexDirection="column" height="42px">
            <Skeleton variant="text" width="50px" height="20px" />

            <Skeleton variant="text" width="100px" height="20px" />
          </Box>

          <Box>
            <Skeleton variant="circular" width="30px" height="30px" />
          </Box>
        </Box>
      </Container>
    ))}
  </>
);

export default SkeletonSmallCatalogCard;
