import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';

import Button from 'src/components/Button';
import Birdie from 'src/components/Icon/birdie.svg';
import ChatIcon from 'src/components/Icon/chat.svg';
import { Query } from 'src/utils/gql';

import { PricingDescriptionOrListItemText, PricingPlanCardButtonText, PricingPlans } from '../constants';
import { PricingPlansCardsData } from '../types';

const useStyles = makeStyles((theme) => ({
  pricingPlanCard: {
    width: '302px',
    height: '780px',
    backgroundColor: theme.palette.common.white,
  },
  pricingPlanCardsWrapper: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    marginBottom: '100px',
    borderRadius: '20px',
    boxShadow: '0 5px 50px rgba(46, 96, 170, 0.15)',
  },
  popularPricingPlanCardHighlight: {
    width: '302px',
    height: '42px',
    backgroundColor: theme.palette.secondary.dark,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: '192px',
    borderRadius: '20px 20px 0 0',
  },
  pricingPlanCardBorders: {
    borderLeft: `1px solid ${theme.palette.background.default}`,
    borderRight: `1px solid ${theme.palette.background.default}`,
  },
  firstPricingPlanCard: {
    borderRadius: '20px 0 0 20px',
  },
  fourthPricingPlanCard: {
    borderRadius: '0 20px 20px 0',
  },
  pricingPlanTitle: {
    marginTop: '40px',
    marginLeft: '20px',
    fontWeight: 500,
    fontSize: '32px',
    lineHeight: '37px',
    color: theme.palette.primary.main,
  },
  price: {
    marginTop: '40px',
    marginLeft: '20px',
    fontWeight: 900,
    fontSize: '42px',
    lineHeight: '49px',
    color: theme.palette.text.primary,
  },
  validity: {
    marginTop: '40px',
    marginLeft: '5px',
    marginBottom: '6px',
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '21px',
    color: theme.palette.primary.contrastText,
  },
  description: {
    marginLeft: '20px',
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '18px',
    color: theme.palette.primary.contrastText,
  },
  popularPricingPlanCardHighlightBorder: {
    border: `2px solid ${theme.palette.secondary.contrastText}`,
  },
  icon: {
    marginTop: '40px',
    marginLeft: '20px',
    width: '73.6px',
    height: '49px',
  },
  button: {
    width: '260px',
    height: '42px',
  },
  popularPricingPlanCardHighlightText: {
    color: theme.palette.common.white,
    fontSize: '18px',
    lineHeight: '21px',
  },
}));

interface PricingPlansBlockProps {
  currentUserSubscriptionPlanName: PricingPlans;
  planData: Pick<Query, 'getPlans'> | undefined;
  onContactUsClick: () => void;
  onUpgradePlanClick: (planInfo: { name: string; price: string; title: string }) => void;
}

const PricingPlansBlock = ({
  currentUserSubscriptionPlanName,
  planData,
  onContactUsClick,
  onUpgradePlanClick,
}: PricingPlansBlockProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const subscriptionPlanId = +PricingPlans[currentUserSubscriptionPlanName];

  const pricingBlocksDataOptions = useMemo(
    () =>
      planData?.getPlans?.map((plan) => ({
        returnObjects: true,
        [`${plan.name}Name`]: plan.name,
        [`${plan.name}CatalogsLimit`]: plan.catalogsLimit,
        [`${plan.name}IntegrationsLimit`]: plan.integrationsLimit,
        [`${plan.name}ItemsLimit`]: plan.itemsLimit && plan?.itemsLimit / 1000,
        [`${plan.name}Price`]: plan.price,
        [`${plan.name}SharedCatalogsLimit`]: plan.sharedCatalogsLimit,
        [`${plan.name}SubscriptionsLimit`]: plan.subscriptionsLimit,
      })),
    [planData],
  );

  const pricingPlanButtonText = (pricingPlan: PricingPlans) => {
    if (pricingPlan > subscriptionPlanId && pricingPlan !== PricingPlans.Superotomator) {
      return PricingPlanCardButtonText.Upgrade;
    }

    if (pricingPlan === subscriptionPlanId) {
      return PricingPlanCardButtonText.Current;
    } else {
      return pricingPlan === PricingPlans.Superotomator
        ? PricingPlanCardButtonText.ContactUs
        : PricingPlanCardButtonText.Downgrade;
    }
  };

  const pricingBlocksData: PricingPlansCardsData[] = t(
    'upgradePricingPlanPage.pricingPlansCardsData',
    pricingBlocksDataOptions
      ? {
          ...pricingBlocksDataOptions[0],
          ...pricingBlocksDataOptions[1],
          freeButtonText: pricingPlanButtonText(PricingPlans.Basic),
          otomatorlingButtonText: pricingPlanButtonText(PricingPlans.Otomatorling),
          otomatorButtonText: pricingPlanButtonText(PricingPlans.Otomator),
          superOtomatorButtonText: pricingPlanButtonText(PricingPlans.Superotomator),
        }
      : { returnObjects: true },
  );

  const pricingCardClasses = (pricingCardId: PricingPlans) =>
    clsx(
      classes.pricingPlanCard,
      pricingCardId === PricingPlans.Basic && classes.firstPricingPlanCard,
      pricingCardId === PricingPlans.Superotomator && classes.fourthPricingPlanCard,
      pricingCardId === PricingPlans.Otomator
        ? classes.popularPricingPlanCardHighlightBorder
        : classes.pricingPlanCardBorders,
    );

  const listItemMarginTopValue = (pricingCardId: PricingPlans) =>
    pricingCardId === PricingPlans.Superotomator ? '15px' : '20px';

  const isListItemOrDescriptionTypeString = (listItemOrDescriptionText: string | string[]) =>
    typeof listItemOrDescriptionText === 'string';

  const isPricingPlanOtomator = (pricingCardId: PricingPlans) => pricingCardId === PricingPlans.Otomator;

  const onPlanButtonClick = (planTitle: string, buttonName: string, planPrice: string, pricingCardId: number) => {
    buttonName === PricingPlanCardButtonText.ContactUs
      ? onContactUsClick()
      : onUpgradePlanClick({ name: PricingPlans[pricingCardId], title: planTitle, price: planPrice });
  };

  return (
    <Box className={classes.pricingPlanCardsWrapper}>
      {pricingBlocksData.map(({ firstBlock, secondBlock }, pricingCardId) => (
        <Box key={pricingCardId} display="flex" flexDirection="column">
          {isPricingPlanOtomator(pricingCardId) && (
            <Box className={classes.popularPricingPlanCardHighlight}>
              <Typography className={classes.popularPricingPlanCardHighlightText}>
                {t('upgradePricingPlanPage.mostPopularText')}
              </Typography>
            </Box>
          )}
          <Box className={pricingCardClasses(pricingCardId)}>
            <Box height={isPricingPlanOtomator(pricingCardId) ? '216px' : '218px'}>
              <Typography className={classes.pricingPlanTitle}>{firstBlock.pricingPlanTitle}</Typography>
              <Box display="flex" alignItems="end">
                {pricingCardId === PricingPlans.Superotomator ? (
                  <img className={classes.icon} src={ChatIcon} alt="chat" />
                ) : (
                  <>
                    <Typography className={classes.price}>{firstBlock.price}</Typography>
                    <Typography className={classes.validity}>{firstBlock.validity}</Typography>
                  </>
                )}
              </Box>
              {isListItemOrDescriptionTypeString(firstBlock.description) ? (
                <Typography mt="10px" className={classes.description}>
                  {firstBlock.description}
                </Typography>
              ) : (
                <>
                  <Typography mt="10px" className={classes.description}>
                    {firstBlock.description[PricingDescriptionOrListItemText.FirstPart]}
                  </Typography>
                  <Typography mt="5px" className={classes.description}>
                    {firstBlock.description[PricingDescriptionOrListItemText.SecondPart]}
                  </Typography>
                </>
              )}
            </Box>
            <Box>
              <Box display="flex" justifyContent="center">
                <Button
                  disabled={pricingCardId <= subscriptionPlanId}
                  className={classes.button}
                  variant="contained"
                  onClick={() =>
                    onPlanButtonClick(
                      firstBlock.pricingPlanTitle,
                      secondBlock.buttonName,
                      firstBlock.price,
                      pricingCardId,
                    )
                  }
                >
                  {secondBlock.buttonName}
                </Button>
              </Box>
              <Box ml="20px" mt="30px">
                {secondBlock.listItems.map(({ listItemText }, listItemId) => (
                  <Box key={listItemId}>
                    {isListItemOrDescriptionTypeString(listItemText) ? (
                      <Box mt={listItemId ? listItemMarginTopValue(pricingCardId) : undefined} display="flex">
                        <img width="16px" height="13px" src={Birdie} alt="birdie" />
                        <Typography maxWidth="280px" ml="20px">
                          {listItemText}
                        </Typography>
                      </Box>
                    ) : (
                      <Box mt={listItemMarginTopValue(pricingCardId)} display="flex" alignItems="baseline">
                        <img width="16px" height="13px" src={Birdie} alt="birdie" />
                        <Box>
                          <Typography ml="20px">{listItemText[PricingDescriptionOrListItemText.FirstPart]}</Typography>
                          <Typography ml="20px">{listItemText[PricingDescriptionOrListItemText.SecondPart]}</Typography>
                        </Box>
                      </Box>
                    )}
                  </Box>
                ))}
              </Box>
              {secondBlock.customPlanDescription && (
                <Typography mt="40px" className={classes.description}>
                  {secondBlock.customPlanDescription}
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default PricingPlansBlock;
