import React, { FormEvent, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { Box, Link, Typography } from '@material-ui/core';

import Button from 'src/components/Button';
import SmallIntegrationCard from 'src/components/Card/SmallIntegrationCard';
import { StyledUpgradeLink } from 'src/components/Headers/styled';
import HintLimitButton from 'src/components/HintLimitButton';
import Iconography from 'src/components/Iconography';
import Loader from 'src/components/Loader';
import IntegrationsPopup from 'src/components/Popups/IntegrationPopup';
import { clearIntegrationsCache } from 'src/components/Popups/IntegrationPopup/functions';
import { initialPopupState, IntegrationsPopupState } from 'src/components/Popups/IntegrationPopup/types';
import { linkPricingPlan, ShopifyDefaultSettings, WooCommerceDefaultSettings } from 'src/constants';
import { CheckIntegrationStatusProps, IntegrationErrors } from 'src/hooks/useIntegrationHealthCheck';
import useOnboarding from 'src/providers/OnboardingProvider/hooks/useOnboarding';
import { useSnackbar } from 'src/providers/snackbar';
import { useEnterClick } from 'src/utils/enterEffectFactory';
import {
  CatalogSource,
  checkIsLimitReached,
  createIntegration,
  getIntegrations,
  Integration,
  IntegrationTypes,
  LimitType,
  Mutation,
  MutationCreateIntegrationArgs,
  Query,
  QueryGetIntegrationsArgs,
} from 'src/utils/gql';
import { installIntegrationApp } from 'src/views/AppInstalling/utils';
import { IntegrationCardsContainer, StyledForwardButton } from 'src/views/Catalogs/CatalogCreation/styled';
import { STEPS } from 'src/views/Catalogs/types';
import { InstallingBackdrop } from 'src/views/UserSettings/styled';
import { IntegrationAction, IntegrationItem } from 'src/views/UserSettings/types';

interface IntegrationThirdStepProps {
  loading: boolean;
  handleBack: (id: number) => void;
  source: CatalogSource;
  onCreateCatalog: (action: string, integrationId: string, event?: FormEvent) => void;
  integrationErrors?: IntegrationErrors;
  checkIntegrationStatus: ({
    integrationId,
    mainCallbackFn,
    additionalCallbackFn,
  }: CheckIntegrationStatusProps) => void;
}

const IntegrationThirdStep = ({
  handleBack,
  onCreateCatalog,
  loading,
  source,
  integrationErrors,
  checkIntegrationStatus,
}: IntegrationThirdStepProps) => {
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const {
    onboardingState: { tourActive },
    goNext,
  } = useOnboarding();

  const onboardingPopupDataSettings = {
    integrationName: 'Otomate shop',
    shop: 'https://otomate-shop.shopify.com',
    url: 'https://wp4.otomate-shop',
    apiKey: 'cs_e34et7r578weg57964a4d76ed8adr345',
    apiSecret: 'ck_j597310b42fbv1303cc1e5754adfg333',
  };

  const [selectedCard, setSelectedCard] = useState<string>('');
  const [integrations, setIntegrations] = useState<Integration[]>([]);
  const [popupState, setPopupState] = useState<IntegrationsPopupState>(initialPopupState);
  const [isInstalling, setIsInstalling] = useState<boolean>(false);

  const {
    data: checkIsLimitReachedData,
    loading: checkIsLimitReachedDataLoading,
    refetch: checkIsLimitReachedRefetch,
  } = useQuery(checkIsLimitReached, {
    fetchPolicy: 'network-only',
    variables: { type: LimitType.Integrations },
    skip: tourActive,
  });

  const {
    data,
    loading: getIntegrationsLoading,
    refetch,
  } = useQuery<Pick<Query, 'getIntegrations'>, QueryGetIntegrationsArgs>(getIntegrations, {
    fetchPolicy: 'cache-first',
    skip: tourActive,
  });

  const [addIntegration, { loading: loadingAddIntegration }] = useMutation<
    Pick<Mutation, 'createIntegration'>,
    MutationCreateIntegrationArgs
  >(createIntegration, {
    update: clearIntegrationsCache,
  });

  const hasIntegrations = Boolean(integrations.length);
  const isLimitReached = Boolean(checkIsLimitReachedData?.checkIsLimitReached);

  const minIntegrationCards = 2;
  const sortedData = useMemo(() => {
    return data?.getIntegrations
      ? data.getIntegrations
          .slice()
          .filter(({ type }) => type === (source.toString() as IntegrationTypes))
          .sort((a, b) => Number(b.isDefault) - Number(a.isDefault))
      : [];
  }, [data?.getIntegrations, source]);

  const handleSelectCard = (id: string) => {
    setSelectedCard(id);
  };

  const handleAddEditIntegration = async (integration: IntegrationItem) => {
    const {
      type,
      settings: { integrationName, shop, url, apiKey, apiSecret },
      isDefault,
    } = integration;

    if (tourActive) {
      const onboardingIntegration = {
        id: '1',
        type,
        settings: {
          shop,
          integrationName,
          url,
        },
      };

      setIntegrations((prevState) => [...prevState, onboardingIntegration as Integration]);

      setPopupState(initialPopupState);

      return goNext();
    }

    if (type === IntegrationTypes.Shopify) {
      await installIntegrationApp({
        type,
        settings: {
          shop,
          integrationName,
          isDefault,
        },
        onSuccess: async () => {
          await Promise.all([refetch(), checkIsLimitReachedRefetch()]);
          setPopupState(initialPopupState);
        },
        onComplete: () => {
          setIsInstalling(false);
        },
      });
    } else {
      try {
        await addIntegration({
          variables: {
            data: {
              type,
              isDefault,
              settings: { integrationName, url, apiKey, apiSecret },
            },
          },
        });

        await Promise.all([refetch(), checkIsLimitReachedRefetch()]);
        !loadingAddIntegration && setPopupState(initialPopupState);
        snackbar(t('userSettingsPage.integrations.snackbar.created'), 'success');
      } catch (error) {
        const { graphQLErrors, message: errorText } = error as ApolloError;
        const message = graphQLErrors && graphQLErrors.length ? graphQLErrors[0].message : errorText;

        if (error) {
          snackbar(message);
        }
      }
    }
  };

  const handleOpenIntegrationPopup = () => {
    const data: IntegrationItem = {
      ...initialPopupState.data,
      type: source.toString() as IntegrationTypes,
      settings: tourActive
        ? onboardingPopupDataSettings
        : source.toString() === IntegrationTypes.Shopify.toString()
        ? ShopifyDefaultSettings
        : WooCommerceDefaultSettings,
    };

    if (tourActive) {
      goNext();
    }

    setPopupState({
      isOpen: true,
      integrationType: source.toString() as IntegrationTypes,
      action: IntegrationAction.Add,
      data,
    });
  };

  const handleCloseIntegrationPopup = () => {
    setPopupState({ ...popupState, isOpen: false });
  };

  useEnterClick(() => {
    !hasIntegrations && !popupState.isOpen && handleOpenIntegrationPopup();
  });

  useEffect(() => {
    if (data?.getIntegrations) {
      const integration = data.getIntegrations.find(({ isDefault }) => isDefault);

      setIntegrations(sortedData);

      integration &&
        integration.type === (source as string) &&
        !integrationErrors?.[integration?.id] &&
        setSelectedCard(integration.id);
    }
  }, [data?.getIntegrations, integrationErrors, sortedData, source]);

  const handleSubmit = (source: CatalogSource, selectedCard: string, e?: FormEvent) => {
    e ? e.preventDefault() : setSelectedCard(selectedCard);

    if (tourActive) {
      onCreateCatalog(source, selectedCard, e);
    } else {
      checkIntegrationStatus({
        integrationId: selectedCard,
        mainCallbackFn: () => onCreateCatalog(source, selectedCard, e),
        additionalCallbackFn: () => setSelectedCard(''),
      });
    }
  };

  return (
    <>
      {getIntegrationsLoading || checkIsLimitReachedDataLoading ? (
        <Box mt="40px">
          <Loader size="medium" />
        </Box>
      ) : (
        <>
          {hasIntegrations ? (
            <Box width="400px">
              <Typography variant="subtitle1" mt="40px" mb="25px">
                {t('catalogueCreationPage.thirdStep.shopify.title')}
              </Typography>

              <form onSubmit={(e) => handleSubmit(source, selectedCard, e)}>
                <Box position="absolute" width="343px" height="190px" id="integration-list-anchor" />

                <IntegrationCardsContainer overflow={integrations.length > minIntegrationCards ? 'auto' : 'hidden'}>
                  {integrations.map(({ id, type, settings }) => (
                    <SmallIntegrationCard
                      key={id}
                      url={type === IntegrationTypes.Shopify ? settings.shop : settings.url}
                      type={type}
                      name={settings.integrationName}
                      isSelected={selectedCard === id}
                      onClick={() => handleSelectCard(id)}
                      testId={`radioButtonSmallCardIntegration${settings.integrationName}`}
                      hasError={!!integrationErrors?.[id]}
                      onErrorLinkClick={() => handleSubmit(source, id)}
                    />
                  ))}
                </IntegrationCardsContainer>

                {isLimitReached ? (
                  <HintLimitButton
                    tooltipMarginLeft="-86px"
                    tooltipWidth="362px"
                    placement="right"
                    title={
                      <Trans i18nKey="catalogueCreationPage.thirdStep.shopify.hint">
                        Integration number has reached the limit.
                        <StyledUpgradeLink href={linkPricingPlan}>Upgrade your plan</StyledUpgradeLink>
                        to create an unlimited number of integration or delete some integrations to create new ones.
                      </Trans>
                    }
                  >
                    <Typography variant="body2" component="span" color="text.disabled" ml="17px">
                      {t('catalogueCreationPage.thirdStep.shopify.createNewIntegration')}
                    </Typography>
                  </HintLimitButton>
                ) : (
                  <>
                    {!tourActive && (
                      <Box ml="17px">
                        <Link
                          component="button"
                          type="button"
                          variant="body2"
                          color="secondary"
                          onClick={handleOpenIntegrationPopup}
                        >
                          {t('catalogueCreationPage.thirdStep.shopify.createNewIntegration')}
                        </Link>
                      </Box>
                    )}
                  </>
                )}

                <Box display="flex" alignItems="center" mt={tourActive ? '20px' : '40px'}>
                  {!tourActive && (
                    <Button onClick={() => handleBack(STEPS.thirdStep)} type="button" variant="outlined">
                      {t('catalogueCreationPage.thirdStep.backButton')}
                    </Button>
                  )}

                  <StyledForwardButton variant="contained" type="submit" loading={loading} disabled={!selectedCard}>
                    {t('catalogueCreationPage.thirdStep.shopify.buttonCreateCatalog')}
                  </StyledForwardButton>
                </Box>
              </form>
            </Box>
          ) : (
            <Box width="288px">
              <Typography variant="subtitle1" my="40px">
                {t('catalogueCreationPage.thirdStep.shopify.noIntegrations')}
              </Typography>

              {isLimitReached ? (
                <HintLimitButton
                  tooltipMarginLeft="-37px"
                  tooltipWidth="362px"
                  placement="right"
                  title={
                    <Trans i18nKey="catalogueCreationPage.thirdStep.shopify.hint">
                      Integration number has reached the limit.
                      <StyledUpgradeLink href={linkPricingPlan}>Upgrade your plan</StyledUpgradeLink>
                      to create an unlimited number of integration or delete some integrations to create new ones.
                    </Trans>
                  }
                >
                  <Button
                    disabled
                    variant="contained"
                    onClick={handleOpenIntegrationPopup}
                    startIcon={<Iconography iconName="add" />}
                  >
                    {t('catalogueCreationPage.thirdStep.shopify.buttonCreateIntegration')}
                  </Button>
                </HintLimitButton>
              ) : (
                <Button
                  id="add-integration-btn-anchor"
                  variant="contained"
                  onClick={handleOpenIntegrationPopup}
                  startIcon={<Iconography iconName="add" />}
                >
                  {t('catalogueCreationPage.thirdStep.shopify.buttonCreateIntegration')}
                </Button>
              )}
            </Box>
          )}
        </>
      )}

      {popupState.isOpen && (
        <IntegrationsPopup
          popupState={popupState}
          onClosePopup={handleCloseIntegrationPopup}
          onAddEdit={handleAddEditIntegration}
          loading={loadingAddIntegration}
        />
      )}

      <InstallingBackdrop open={isInstalling}>
        <Loader size="medium" />
        <Typography mt="20px">{t('userSettingsPage.waiting')}</Typography>
      </InstallingBackdrop>
    </>
  );
};

export default IntegrationThirdStep;
