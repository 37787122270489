import React from 'react';
import { Trans } from 'react-i18next';

import { OrderBy } from '../../utils/gql';

export const SHARED_CATALOGUES_TABLE_LABELS: { [key: string]: JSX.Element } = {
  [OrderBy.Name]: <Trans i18nKey="settingsPage.sharedCataloguesTable.labels.catalogName">Catalog Name</Trans>,
  [OrderBy.Company]: <Trans i18nKey="settingsPage.sharedCataloguesTable.labels.company">Company</Trans>,
  [OrderBy.Cost]: <Trans i18nKey="settingsPage.sharedCataloguesTable.labels.cost">Cost</Trans>,
  [OrderBy.Status]: <Trans i18nKey="settingsPage.sharedCataloguesTable.labels.status">Status</Trans>,
  [OrderBy.Default]: <Trans i18nKey="settingsPage.sharedCataloguesTable.labels.actions">Actions</Trans>,
};
