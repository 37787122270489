import React, { PropsWithChildren, useState } from 'react';

import { UploadingMedia, MediaOrdersBeforeUpdate } from 'src/views/Catalogs/ProductItems/types';

import { MediaStateContext } from './context';

const MediaProvider = ({ children }: PropsWithChildren<Record<string, unknown>>) => {
  const [uploading, isUploading] = useState<boolean>(false);
  const [mediaState, setMediaState] = useState<UploadingMedia[]>([]);
  const [mediaForDetach, setMediaForDetach] = useState<string[]>([]);
  const [lastMediaOrder, setLastMediaOrder] = useState<number>(0);
  const [mediaOrdersBeforeUpdate, setMediaOrdersBeforeUpdate] = useState<MediaOrdersBeforeUpdate[]>([]);

  const addMediaToDetach = (mediaIdArray: string[]) => {
    setMediaForDetach((prevState) => [...prevState, ...mediaIdArray]);
  };

  const setMediaLastOrder = (newLastMediaOrder?: number) => {
    Number.isInteger(newLastMediaOrder) && setLastMediaOrder(newLastMediaOrder || 0);
  };

  const changeMediaOrdersBeforeUpdate = (newState: MediaOrdersBeforeUpdate[]) => {
    setMediaOrdersBeforeUpdate(newState);
  };

  const mediaStateValue = {
    mediaState,
    setMediaState,
    uploading,
    isUploading,
    mediaForDetach,
    addMediaToDetach,
    mediaLastOrder: lastMediaOrder,
    setMediaLastOrder,
    changeMediaOrdersBeforeUpdate,
    mediaOrdersBeforeUpdate,
  };

  return <MediaStateContext.Provider value={mediaStateValue}>{children}</MediaStateContext.Provider>;
};

export default MediaProvider;
