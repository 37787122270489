import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import MuiCard from '@material-ui/core/Card';
import { Box, IconButton, Link, styled, Typography } from '@mui/material';
import moment from 'moment';

import { ReactComponent as EbayIconSvg } from 'src/components/Icon/ebay-circle.svg';
import { ReactComponent as FacebookAndInstagramIcon } from 'src/components/Icon/facebook-and-instagram.svg';
import { ReactComponent as ShopifyIcon } from 'src/components/Icon/shopify-circle.svg';
import { ReactComponent as WooCommerceIcon } from 'src/components/Icon/woo-commerce-circle.svg';
import useDataTransferCompletedSnackbar from 'src/hooks/useDataTransferCompletedSnackbar';
import useDuplicatedSKUFromIntegrationMappingErrors from 'src/hooks/useDuplicatedSKUFromIntegrationMappingErrors';
import useDataTransferStatus from 'src/providers/catalog/hooks/useDataTransferStatus';
import { CatalogJobType, DuplicatedSkuInfo, IntegrationMappingError, IntegrationTypes, Reason } from 'src/utils/gql';

import BetaLabel from '../BetaLabel';
import DuplicatedSKUMessage, { DuplicatedSKUMessageType } from '../DuplicatedSKUMessage';
import ErrorableSyncButton, { TooltipPlacements } from '../ErrorableSyncButton';
import HintLimitButton from '../HintLimitButton';
import Iconography from '../Iconography';
import Loader from '../Loader';

const Card = styled(MuiCard)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '262px',
  height: '100px',
  boxShadow: '0 2px 10px rgba(46, 96, 170, 0.25)',
  borderColor: theme.palette.text.disabled,
  borderRadius: '20px',
  background: 'none',
  margin: '0 24px 10px 0',
  padding: '15px 25px',

  '& .MuiIconButton-root#close-button': {
    display: 'none',
  },

  '&:hover': {
    cursor: 'initial',

    '& .MuiIconButton-root#close-button': {
      display: 'initial',
    },
  },
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  width: 28,
  height: 28,
  top: 10,
  right: 10,

  svg: {
    height: 18,
    width: 18,
  },

  '&:hover': {
    background: theme.palette.background.default,
  },
}));

const EbayIcon = styled(EbayIconSvg)`
  min-width: 32px;
`;

interface AttachedIntegrationCardProps {
  integrationId: string;
  catalogId: string;
  type: IntegrationTypes;
  title: string;
  onSyncIntegrationClick: (id: string) => void;
  onOpenDeletePopup: (id: string) => void;
  checkMappingsLoading: boolean;
  isEmpty?: boolean;
  integrationMappingErrors?: IntegrationMappingError[];
  duplicatedSKUInfo?: DuplicatedSkuInfo;
}

const AttachedIntegrationCard = ({
  integrationId,
  catalogId,
  type,
  title,
  onSyncIntegrationClick,
  onOpenDeletePopup,
  checkMappingsLoading,
  isEmpty,
  integrationMappingErrors,
  duplicatedSKUInfo,
}: AttachedIntegrationCardProps) => {
  const { t } = useTranslation();
  const { showDataTransferCompletedSnackbar } = useDataTransferCompletedSnackbar();

  const titleHint = t('settingsPage.attachedIntegrations.hintAtAnEmptyCatalog');
  const duplicatedSKU = duplicatedSKUInfo?.isDuplicated;

  const {
    inProgress: isExportInProgress,
    errorMessage: hasExportError,
    endTime,
  } = useDataTransferStatus({
    catalogId,
    integrationId,
    typeFilter: CatalogJobType.Export,
    onCompleted: showDataTransferCompletedSnackbar,
  });

  const syncDate = useMemo(() => {
    if (endTime) {
      const isJustNow = moment().isBetween(moment(endTime).subtract(30, 'second'), moment(endTime).add(30, 'second'));

      return isJustNow
        ? t('settingsPage.attachedIntegrations.syncedNowDate')
        : `${t('settingsPage.attachedIntegrations.syncedDate')} ${moment(endTime).format('MMMM DD, YYYY')}`;
    }
  }, [endTime, t]);

  const getIcon = (type: IntegrationTypes) => {
    switch (type) {
      case IntegrationTypes.Shopify:
        return <ShopifyIcon />;
      case IntegrationTypes.Woocommerce:
        return <WooCommerceIcon />;
      case IntegrationTypes.Ebay:
        return <EbayIcon />;
      case IntegrationTypes.Facebook:
        return <FacebookAndInstagramIcon />;
    }
  };

  const hasUnSyncableErrors = useMemo(() => {
    return integrationMappingErrors?.some(
      ({ syncable, reason }) => !(syncable || reason === Reason.IntegrationUnavailable),
    );
  }, [integrationMappingErrors]);
  const syncDuplicatedSKUInfo = useDuplicatedSKUFromIntegrationMappingErrors(integrationMappingErrors);
  const isSyncButtonDisabledWithReason = useMemo(() => {
    const disabled = isEmpty || duplicatedSKU;
    const disabledText = isEmpty ? (
      titleHint
    ) : duplicatedSKU ? (
      <DuplicatedSKUMessage
        type={DuplicatedSKUMessageType.basic}
        catalogId={catalogId}
        duplicatedSKUInfo={duplicatedSKUInfo}
      />
    ) : null;
    return { disabled, disabledText };
  }, [catalogId, duplicatedSKUInfo, titleHint, isEmpty, duplicatedSKU]);

  return (
    <Card>
      <Box display="flex" alignItems="center">
        {getIcon(type)}

        <Typography
          variant="subtitle2"
          ml="10px"
          overflow="hidden"
          textOverflow="ellipsis"
          maxWidth="155px"
          whiteSpace="nowrap"
          mr="5px"
        >
          {title}
        </Typography>

        {type === IntegrationTypes.Ebay && <BetaLabel />}
      </Box>

      <Box display="flex" alignItems="center" justifyContent="space-between" height="28px">
        {isExportInProgress ? (
          <>
            <Typography variant="caption" color="text.secondary" fontWeight={700}>
              {t('settingsPage.attachedIntegrations.exportProgress')}
            </Typography>

            <Loader size="extraSmallIntegration" />
          </>
        ) : (
          <>
            <Typography variant="caption" color="primary">
              {checkMappingsLoading ? t('settingsPage.attachedIntegrations.checkMappings') : syncDate}
            </Typography>
            {(hasExportError || integrationMappingErrors?.length) && duplicatedSKU && !isEmpty ? (
              <HintLimitButton
                placement="right"
                tooltipWidth="240px"
                title={
                  <Typography fontSize="12px" lineHeight="14px" color="text.secondary">
                    {syncDuplicatedSKUInfo.isDuplicated && duplicatedSKU ? (
                      <DuplicatedSKUMessage
                        type={DuplicatedSKUMessageType.sync}
                        catalogId={catalogId}
                        duplicatedSKUInfo={syncDuplicatedSKUInfo}
                      />
                    ) : (
                      t(
                        integrationMappingErrors?.length
                          ? `productType.mapping.${integrationMappingErrors?.[0].reason}`
                          : 'settingsPage.attachedIntegrations.syncError',
                      )
                    )}

                    <br />

                    {!hasUnSyncableErrors && (
                      <Link
                        component="button"
                        fontSize="12px"
                        lineHeight="14px"
                        underline="always"
                        sx={{ verticalAlign: 'baseline', color: 'inherit' }}
                        onClick={() => onSyncIntegrationClick(integrationId)}
                      >
                        {t('settingsPage.attachedIntegrations.syncErrorLink')}
                      </Link>
                    )}
                  </Typography>
                }
              >
                <Iconography iconName="cancel-circle" color="error" />
              </HintLimitButton>
            ) : (
              <ErrorableSyncButton
                catalogId={catalogId}
                disabled={isSyncButtonDisabledWithReason.disabled}
                errors={integrationMappingErrors}
                onClick={() => onSyncIntegrationClick(integrationId)}
                dataTestId="syncButtonIntegration"
                tooltipWidth={duplicatedSKU ? '231px' : undefined}
                tooltipParams={{
                  customTooltipContent: isSyncButtonDisabledWithReason.disabledText,
                  placement: TooltipPlacements.right,
                }}
                iconParams={{ width: 18, height: 18 }}
              />
            )}
          </>
        )}
      </Box>

      {!isExportInProgress && (
        <CloseButton
          size="small"
          id="close-button"
          onClick={() => onOpenDeletePopup(integrationId)}
          data-testid="crossButtonIntegration"
        >
          <Iconography iconName="cancel" htmlColor="secondary" />
        </CloseButton>
      )}
    </Card>
  );
};

export default AttachedIntegrationCard;
