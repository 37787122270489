import React from 'react';
import { Trans } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { Link, Typography } from '@mui/material';

import { ReactComponent as NoDataIcon } from 'src/components/Icon/no-integration.svg';
import { FIRST_DASHBOARD_PATHNAME, CATALOGS_PATH, CATALOG_SETTINGS } from 'src/constants/routeSources';

import { NoIntegrationsContainer } from './styled';

const NoIntegrations = ({ onCloseSidebar }: { onCloseSidebar?: () => void }) => {
  const { id, catalogId } = useParams<{ id: string; catalogId: string }>();
  const history = useHistory();

  const goToCatalogSettings = () => {
    const closeMappingSidebar = new Promise((resolve, reject) => {
      onCloseSidebar ? resolve(onCloseSidebar()) : reject();
    });

    closeMappingSidebar.finally(() =>
      history.push(`/${FIRST_DASHBOARD_PATHNAME}/${CATALOGS_PATH}/${id || catalogId}/${CATALOG_SETTINGS}`),
    );
  };

  return (
    <NoIntegrationsContainer data-testid="noIntegrationsContainer">
      <NoDataIcon />

      <Typography data-testid="noMappingsText">
        <Trans i18nKey="productType.mapping.noIntegrations">
          Unfortunately, you don&apos;t have any attached integrations. To make the mappings you should
          <Link onClick={goToCatalogSettings} data-testid="goToCatalogSettingsLink">
            attach an integration
          </Link>
          to the catalog.
        </Trans>
      </Typography>
    </NoIntegrationsContainer>
  );
};

export default NoIntegrations;
