import { Backdrop, Box, Link, Typography, TypographyProps } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';

export const SmallDropzoneContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 392px;
  height: 96px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='2' ry='2' stroke='%23BBC9DFFF' stroke-width='2' stroke-dasharray='7' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
  border-radius: 2px;
`;

export const SmallDropzoneText = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: '15px',
  lineHeight: '17.58px',
  textAlign: 'center',
  maxWidth: '214px',

  a: {
    color: theme.palette.secondary.main,
  },
}));

export const StyledBackdrop = styled(Backdrop)`
  z-index: 1500;
  background-color: rgba(16, 16, 45, 0.7);
  padding: 56px;
`;

export const InnerBox = styled(Box)`
  width: 100%;
  height: 100%;
  border: ${({ theme: { palette } }) => `2px dashed ${palette.background.default}`};
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Browse = styled(Link)`
  :hover {
    cursor: pointer;
  }
`;

export const ErrorMessage = styled(Typography)`
  position: absolute;
`;
