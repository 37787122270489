import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  split,
  InMemoryCache,
  NextLink,
  Operation,
  Observable,
  FetchResult,
} from '@apollo/client';
import { SafeReadonly } from '@apollo/client/cache/core/types/common';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import { getMainDefinition, offsetLimitPagination } from '@apollo/client/utilities';
import { print } from 'graphql';
import { createClient, Client, ClientOptions } from 'graphql-ws';

import { NON_AUTH_QUERIES } from '../constants';

import customGraphQLErrorsHandlers from './customGraphQLErrorsHandlers';
import { getToken } from './localStorage';

class WebSocketLink extends ApolloLink {
  private client: Client;

  constructor(options: ClientOptions) {
    super();
    this.client = createClient(options);
  }

  public request(operation: Operation): Observable<FetchResult> {
    return new Observable((sink) => {
      return this.client.subscribe<FetchResult>(
        { ...operation, query: print(operation.query) },
        {
          next: sink.next.bind(sink),
          complete: sink.complete.bind(sink),
          error: (err) => {
            if (Array.isArray(err))
              // GraphQLError[]
              return sink.error(new Error(err.map(({ message }) => message).join(', ')));

            if (err instanceof CloseEvent)
              return sink.error(new Error(`Socket closed with event ${err.code} ${err.reason || ''}`));

            return sink.error(err);
          },
        },
      );
    });
  }
}

let closeWsConnection: (() => void) | undefined;

const createLink = () => {
  const httpLink = new HttpLink({
    uri: process.env.REACT_APP_GQL_ENDPOINT,
  });

  if (process.env.NODE_ENV === 'test') {
    return httpLink;
  }

  const wsLink = new WebSocketLink({
    url: process.env.REACT_APP_GQL_ENDPOINT?.replace('http', 'ws') || '',
    lazy: false,
    retryAttempts: Infinity,
    retryWait: () => {
      return new Promise((resolve) => {
        setTimeout(resolve, 1000 + Math.random() * 3000);
      });
    },
    connectionParams: () => {
      const token = getToken();

      return { Authorization: token ? `Bearer ${token}` : '' };
    },
    on: {
      connected: (sct) => {
        const socket = sct as WebSocket;

        closeWsConnection = () => {
          socket.close(4205, 'Client Restart');
        };
      },
    },
  });

  return split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    httpLink,
  );
};

const request = async (operation: Operation) => {
  const token = getToken();

  let headers = {};
  if (!NON_AUTH_QUERIES.includes(operation.operationName)) {
    headers = {
      Authorization: token ? `Bearer ${token}` : '',
    };
  }
  operation.setContext({
    headers,
  });
};

const authMiddleware = new ApolloLink((operation: Operation, forward: NextLink) => {
  return new Observable((observer) => {
    let handle: ZenObservable.Subscription;
    Promise.resolve(operation)
      .then((oper) => request(oper))
      .then(() => {
        handle = forward(operation).subscribe({
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        });
      })
      .catch(observer.error.bind(observer));

    return () => {
      if (handle) handle.unsubscribe();
    };
  });
});

const handleError = (err: ErrorResponse): Observable<FetchResult> | void => {
  const { graphQLErrors, operation, networkError, forward } = err;

  if (process.env.NODE_ENV === 'development' && networkError) {
    console.error(`GraphQL Error: ${networkError}`, operation);
  }

  graphQLErrors?.forEach((error) => {
    if (process.env.NODE_ENV === 'development') {
      console.error(`GraphQL Error: ${error.message}`, operation);
    }
  });

  for (const errorHandler of customGraphQLErrorsHandlers) {
    const { graphQLErrorMessage, graphQLErrorCode } = errorHandler;

    const graphqlError = graphQLErrors?.find((error) => {
      const matchMessage = graphQLErrorMessage.test(error.message);

      const matchCode = !(error.extensions?.code && graphQLErrorCode) || error.extensions.code === graphQLErrorCode;

      return matchMessage && matchCode;
    });

    if (graphqlError) {
      return new Observable((observer) => {
        let handle: ZenObservable.Subscription;

        Promise.resolve(operation)
          .then(() => errorHandler.handler(graphqlError.extensions))
          .then(() => {
            handle = forward(operation).subscribe({
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            });
          })
          .catch(observer.error.bind(observer));

        return () => {
          if (handle) {
            handle.unsubscribe();
          }
        };
      });
    }
  }
};

const defaultMergePolicy = {
  merge(existing: SafeReadonly<unknown>, incoming: SafeReadonly<unknown>) {
    return incoming;
  },
};

const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          getSharedCatalogs: offsetLimitPagination([
            'name',
            'status',
            'source',
            'orderBy',
            'orderDirection',
            'keywords',
          ]),
          getCatalogs: offsetLimitPagination([
            'ids',
            'name',
            'status',
            'source',
            'types',
            'orderBy',
            'orderDirection',
            'keywords',
            'limit', // 'limit' should be deleted after deleting catalog provider and checking, that pagination works well with different limits
          ]),
          getProductItemsByProductTypeId: offsetLimitPagination([
            'id',
            'filter',
            'search',
            'status',
            'limit',
            'offset',
          ]),
          getIntegrations: offsetLimitPagination(['catalogId', 'orderBy', 'orderDirection']),
          getSubscriptions: offsetLimitPagination(['catalogId', 'orderBy', 'orderDirection', 'limit']), //should be changed to cursor-based pagination
          getMedia: offsetLimitPagination(['from', 'limit', 'productItemId', 'to', 'type']),
          getEbayMerchantLocations: offsetLimitPagination(),
        },
      },
      Catalog: {
        fields: {
          subscriptions: defaultMergePolicy,
          config: defaultMergePolicy,
        },
      },
      ProductItem: {
        fields: {
          values: defaultMergePolicy,
        },
      },
      ProductItemsWithCount: {
        fields: {
          productItems: offsetLimitPagination(),
          totalCount: defaultMergePolicy,
        },
      },
      IntegrationProductType: {
        keyFields: ['integrationId', 'id'],
      },
    },
  }),
  link: ApolloLink.from([onError(handleError), authMiddleware, createLink()]),
});

export { client, closeWsConnection };
