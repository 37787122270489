import { createMuiTheme, ThemeOptions } from '@material-ui/core';

import overrides from 'src/theme/overrides';

const themeOptions = {
  components: overrides,
  palette: {
    primary: {
      main: '#23335F',
    },
    text: {
      disabled: '#BBC9DF',
    },
  },
} as ThemeOptions;

export const datePickerTheme = createMuiTheme(themeOptions);
