import { IconButton, styled } from '@mui/material';

import Select from 'src/components/Select';

export const Form = styled('form')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '32px 0 21px ',
}));

export const StyledSelect = styled(Select)(() => ({
  margin: '0 24px 10px 0',

  '& .MuiOutlinedInput-root': {
    width: 208,
  },
}));

export const AuditLogResetButton = styled(IconButton)(({ theme }) => ({
  height: 42,
  width: 42,
  padding: 3,
  color: theme.palette.text.disabled,

  ':hover': {
    color: theme.palette.primary.main,
  },
}));
