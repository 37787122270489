export enum PricingPlans {
  Basic,
  Otomatorling,
  Otomator,
  Superotomator,
}

export enum PricingPlanCardButtonText {
  ContactUs = 'Contact us',
  Upgrade = 'Upgrade',
  Downgrade = 'Downgrade',
  Current = 'Current',
}

export enum PricingDescriptionOrListItemText {
  FirstPart,
  SecondPart,
}

export const linkToPublicApiDoc = 'https://api-docs.otomate.us/';
