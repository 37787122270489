import { useEffect } from 'react';

export enum CustomEventName {
  customCloseMappingsSidebar = 'customCloseMappingsSidebar',
  checkItemMappingsChanges = 'checkItemMappingsChanges',
}

const useCustomEvent = (eventName?: CustomEventName, callback?: () => void) => {
  const dispatchCustomEvent = (dispatchEventName: string, optionObject?: CustomEventInit<unknown>) => {
    const event = new CustomEvent(dispatchEventName, optionObject);

    document.dispatchEvent(event);
  };

  useEffect(() => {
    if (callback && eventName) {
      document.addEventListener(eventName, callback);

      return () => {
        document.removeEventListener(eventName, callback);
      };
    }
  }, [eventName, callback]);

  return { dispatchCustomEvent };
};

export default useCustomEvent;
