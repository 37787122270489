import React from 'react';

import { FormControlLabel, SwitchProps } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';

import Toggle from './Toggle';

export const StyledFormControlLabel = styled(FormControlLabel)`
  margin: 0;
  min-width: 120px;

  .MuiTypography-root {
    margin-left: 10px;
    font-size: 14px;
    line-height: 16px;
  }

  .Mui-focusVisible {
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.35);
  },
`;

interface StyledToggleProps extends SwitchProps {
  primary: string;
  secondary: string;
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
  testId?: string;
}

function LabeledToggle({ labelPlacement, testId, ...props }: StyledToggleProps) {
  const { primary, secondary, checked, name } = props;

  return (
    <StyledFormControlLabel
      sx={{
        '& .MuiTypography-root': {
          marginRight: labelPlacement === 'start' ? '10px' : undefined,
        },
      }}
      control={
        <Toggle
          {...props}
          inputProps={{
            name,
          }}
        />
      }
      label={checked ? secondary : primary}
      labelPlacement={labelPlacement || 'end'}
      data-testid={testId}
    />
  );
}

export default LabeledToggle;
