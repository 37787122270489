import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@material-ui/core';

import Layout from './Layout';

const Error404 = () => {
  const { t } = useTranslation();

  return (
    <Layout textTitle={t('pageNotFound.title')} textSubTitle={t('pageNotFound.subtitle')} isNotFoundPage>
      <Typography variant="subtitle1" color="text.secondary" fontWeight="400">
        {t('pageNotFound.content')}
      </Typography>
    </Layout>
  );
};

export default Error404;
