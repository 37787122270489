import React, { ChangeEvent, FormEvent, ReactElement, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Box, makeStyles, Typography, useTheme } from '@material-ui/core';

import Button from 'src/components/Button';
import Iconography from 'src/components/Iconography';
import SearchField from 'src/components/SearchField';
import { linkPricingPlan } from 'src/constants';
import { Integration, IntegrationMappingError, LimitType, Maybe, ProductType, Reason } from 'src/utils/gql';

import DuplicatedSKUMessage, { DuplicatedSKUMessageType } from '../DuplicatedSKUMessage';
import ErrorableSyncButton, { TooltipPlacements } from '../ErrorableSyncButton';
import { ErrorableSyncButtonVariants } from '../ErrorableSyncButton/ErrorableSyncButtonChild';
import HintLimitButton from '../HintLimitButton';
import IntegrationUnavailableMessage from '../IntegrationUnavailableMessage';
import Loader from '../Loader';
import Toggle from '../Toggle/Toggle';

import StickyHeader from './StickyHeader';
import {
  SearchFieldWrapper,
  StyledUpgradeLink,
  StyledIntegrationText,
  StyledShowHideFilterWrapper,
  StyledHeaderWithoutTitle,
  StyledHeaderFixed,
  useStickyHeaderStyles,
} from './styled';

const useStyles = makeStyles(() => ({
  headerTitle: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '320px',
  },
  addButton: {
    minWidth: 'fit-content',
    height: '40px',
  },
  showFiltersButton: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    marginTop: '26px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  syncButton: {
    width: '42px',
    minWidth: '42px',
    height: '42px',
    boxSizing: 'border-box',
    padding: '12px 0',
    position: 'relative',
    '& .MuiButton-label': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      lineHeight: '42px',
    },
    '& .syncBtnText': {
      display: 'none',
    },
    '&:hover': {
      width: '271px',
      boxSizing: 'border-box',
    },
    '&:hover .syncBtnText': {
      display: 'inline-block',
      marginLeft: '25px',
    },
  },
  iconSync: {
    position: 'absolute',
    top: '8px',
    left: '8px',
  },
}));

interface ProductItemsHeaderProps {
  syncable?: boolean;
  activeJobLoading: Maybe<boolean>;
  checkIsLimitReachedLoading?: boolean;
  checkIsCatalogShared: boolean;
  title?: string;
  hasItems: boolean;
  onAddButtonClick: () => void;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onSubmit?: (event: FormEvent) => void;
  searchInputValue?: string;
  searchRequestValue?: string;
  selectedItems: string[];
  hideExportToAttachedButton: boolean;
  isSearchBarDisabled?: boolean;
  handleShowFilters: () => void;
  showFilters: boolean;
  bulkSelectComponent: ReactElement;
  checkIsLimitReachedVariables?: { type: LimitType.Items };
  integrations?: Maybe<Integration[]>;
  showItemsControlElements?: boolean;
  onOpenExportPopup: () => void;
  showCompleteness: boolean;
  onShowCompletenessChange: () => void;
  productType?: ProductType;
}

const ProductItemsHeader = ({
  activeJobLoading,
  checkIsLimitReachedLoading,
  title,
  hasItems,
  onAddButtonClick,
  onChange,
  searchInputValue,
  selectedItems,
  hideExportToAttachedButton,
  checkIsCatalogShared,
  handleShowFilters,
  showFilters,
  bulkSelectComponent,
  checkIsLimitReachedVariables,
  integrations,
  showItemsControlElements,
  onOpenExportPopup,
  showCompleteness,
  onShowCompletenessChange,
  productType,
  syncable,
}: ProductItemsHeaderProps) => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const theme = useTheme();
  const classes = useStyles();
  const customClasses = useStickyHeaderStyles();

  const showExportToAttachedIntegrationsButton = Boolean(
    integrations?.length && !(activeJobLoading || hideExportToAttachedButton),
  );
  const duplicatedSKU = productType?.duplicatedSKUInfo.isDuplicated;
  const unsyncable = !(syncable === undefined || syncable);
  const integrationUnavailableErrors = useMemo(() => {
    if (!integrations) {
      return [];
    }

    return integrations.reduce((acc: IntegrationMappingError[], { mappingsErrors }) => {
      if (mappingsErrors) {
        const integrationErrors = mappingsErrors.filter(({ reason }) => reason === Reason.IntegrationUnavailable);
        acc.push(...integrationErrors);
      }

      return acc;
    }, []);
  }, [integrations]);
  const errorableSyncButtonErrors = [...(productType?.mappingsErrors || []), ...(integrationUnavailableErrors || [])];
  const errorableSyncButtonTooltipWidth =
    duplicatedSKU || unsyncable ? '231px' : integrationUnavailableErrors?.length ? '240px' : undefined;

  const titleHint = t('settingsPage.attachedIntegrations.hintAtAnEmptyCatalog');

  return (
    <StickyHeader threshold={64} customClass={customClasses}>
      <StyledHeaderFixed>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center" flexBasis="346px">
            <Typography mr="15px" className={classes.headerTitle} variant="h2">
              {title}
            </Typography>

            {activeJobLoading && <Loader size="extraSmallIntegration" />}
          </Box>

          {showItemsControlElements && (
            <StyledHeaderWithoutTitle>
              <SearchFieldWrapper>
                <SearchField
                  variant="outlined"
                  label={t('search')}
                  onChange={onChange}
                  value={searchInputValue}
                  disabled={!(hasItems || searchInputValue?.length)}
                  className="items__search"
                  testId="searchFieldProductItems"
                />
              </SearchFieldWrapper>

              <Box display="flex">
                <Box display="flex" alignItems="center" mr={!!selectedItems.length ? '0' : '24px'} ml="24px">
                  <Typography fontSize="15px" color={theme.palette.text.secondary}>
                    {t('productItemCreateEdit.sidebar.completeness')}
                  </Typography>
                  <Box data-testid="completenessToogle" onClick={onShowCompletenessChange} sx={{ cursor: 'pointer' }}>
                    <Toggle checked={showCompleteness} sx={{ marginLeft: '10px' }} />
                  </Box>
                </Box>

                {!!selectedItems.length && (
                  <Box ml="24px" mr="24px" width="208px">
                    {bulkSelectComponent}
                  </Box>
                )}

                {!checkIsCatalogShared && (
                  <Box display="flex" justifyContent="center" alignItems="center">
                    {activeJobLoading && (
                      <StyledIntegrationText mr="15px" variant="subtitle2">
                        {`${t('export')} ${t('inProgress')}`}
                      </StyledIntegrationText>
                    )}

                    {showExportToAttachedIntegrationsButton && (
                      <Box mr="15px">
                        <ErrorableSyncButton
                          catalogId={id}
                          tooltipParams={{
                            customTooltipContent: !hasItems ? (
                              titleHint
                            ) : unsyncable ? (
                              t('cataloguesPage.catalogueCard.unsyncableErrorHint')
                            ) : duplicatedSKU ? (
                              <DuplicatedSKUMessage
                                type={DuplicatedSKUMessageType.basic}
                                catalogId={id}
                                duplicatedSKUInfo={productType?.duplicatedSKUInfo}
                              />
                            ) : integrationUnavailableErrors?.length ? (
                              <IntegrationUnavailableMessage
                                integrations={integrations}
                                integrationUnavailableErrors={integrationUnavailableErrors}
                                onTryAgainButtonClick={onOpenExportPopup}
                              />
                            ) : null,
                            placement: TooltipPlacements.bottomEnd,
                          }}
                          disabled={!hasItems || duplicatedSKU || unsyncable}
                          errors={errorableSyncButtonErrors}
                          onClick={onOpenExportPopup}
                          dataTestId="exportToAttachedIntegrationsButton"
                          tooltipWidth={errorableSyncButtonTooltipWidth}
                          iconParams={{
                            variant: ErrorableSyncButtonVariants.ORANGE,
                            orangeIconParams: {
                              orangeButtonText: t('productItemsPage.exportAttachedIntegrations'),
                              showOrangeButtonText: !activeJobLoading,
                            },
                          }}
                        />
                      </Box>
                    )}

                    {checkIsLimitReachedVariables ? (
                      <HintLimitButton
                        tooltipWidth="252px"
                        tooltipMarginLeft="0"
                        title={
                          <Trans i18nKey="productItemsPage.hint">
                            Items number has reached the limit.
                            <StyledUpgradeLink href={linkPricingPlan}>Upgrade your plan</StyledUpgradeLink>
                            to add a new one or delete some items to create new ones.
                          </Trans>
                        }
                      >
                        <Button
                          variant="contained"
                          disabled
                          startIcon={<Iconography iconName="add" />}
                          className={classes.addButton}
                        >
                          {t('productItemsPage.createItemButton')}
                        </Button>
                      </HintLimitButton>
                    ) : (
                      <Button
                        disabled={checkIsLimitReachedLoading || Boolean(activeJobLoading)}
                        onClick={onAddButtonClick}
                        variant="contained"
                        className={classes.addButton}
                        startIcon={<Iconography iconName="add" />}
                      >
                        {t('productItemsPage.createItemButton')}
                      </Button>
                    )}
                  </Box>
                )}
              </Box>
            </StyledHeaderWithoutTitle>
          )}
        </Box>

        <StyledShowHideFilterWrapper>
          <Box className={classes.showFiltersButton} onClick={handleShowFilters} data-testid="filterDisplayButton">
            <Iconography iconName="filters" htmlColor={theme.palette.text.primary} />
            <Typography variant="subtitle1" fontWeight="400" color="text.primary" ml="15px">
              {showFilters ? t('productItemsPage.hideFilters') : t('productItemsPage.showFilters')}
            </Typography>
          </Box>
        </StyledShowHideFilterWrapper>
      </StyledHeaderFixed>
    </StickyHeader>
  );
};

export default ProductItemsHeader;
