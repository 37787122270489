import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { gql, useApolloClient, useLazyQuery, useMutation } from '@apollo/client';
import axios from 'axios';

import { clearCacheFactory } from 'src/utils/cacheUtils';
import {
  checkIsCatalogEmpty,
  CsvParseStatus,
  deleteProductTypeById,
  getParsedCsvStatusByIds,
  getSignedUrlsForCsv,
  Mutation,
  MutationGetSignedUrlsForCsvArgs,
  ParsedType,
  ProductItem,
  ProductType,
  Query,
  QueryGetCatalogByIdArgs,
  Subscription,
  SubscriptionGetParseCsvStatusArgs,
} from 'src/utils/gql';
import { UploadingFile } from 'src/views/Catalogs/ProductItems/types';

import { PARSING_FILE_STATE } from '../constants';

export interface ParsedData {
  inParsing: number;
  parsed: number;
  parseFailed: number;
}

export interface CsvFileInfo {
  name: string;
  id: string;
  state: PARSING_FILE_STATE;
  uploadPercentage: number;
  parsingError?: string;
  productType?: ProductType;
  productItems: ProductItem[];
  errorMessage?: string;
  parsedProgressData?: ParsedData;
  cancelUpload: () => void;
}

export interface CsvFilesInfo {
  [id: string]: CsvFileInfo;
}

export const clearCatalogs = clearCacheFactory({ fieldNames: ['getCatalogs'] });

const useImportCsv = () => {
  const client = useApolloClient();
  const { id: catalogId } = useParams<{ id: string }>();
  const [csvFilesState, setCsvFilesInfo] = useState<CsvFilesInfo>({});
  const [ids, setIds] = useState<string[]>([]);

  const [getSignedUrls] = useMutation<Pick<Mutation, 'getSignedUrlsForCsv'>, MutationGetSignedUrlsForCsvArgs>(
    getSignedUrlsForCsv,
  );

  const [deleteProductType] = useMutation(deleteProductTypeById);

  const [checkIsCatalogEmptyQuery] = useLazyQuery<Pick<Query, 'getCatalogById'>, QueryGetCatalogByIdArgs>(
    checkIsCatalogEmpty,
    { fetchPolicy: 'network-only' },
  );

  useEffect(() => {
    let isMounted = true;

    if (ids.length) {
      const subscription = client.subscribe<Pick<Subscription, 'getParseCSVStatus'>, SubscriptionGetParseCsvStatusArgs>(
        {
          query: getParsedCsvStatusByIds,
          variables: {
            ids,
          },
        },
      );

      subscription.subscribe({
        next({ data: getParseCSVStatusResponse }) {
          if (getParseCSVStatusResponse) {
            const { id, status, error, data } = getParseCSVStatusResponse.getParseCSVStatus;

            const state =
              status === CsvParseStatus.Error
                ? PARSING_FILE_STATE.ParsingError
                : status === CsvParseStatus.InProgress
                ? PARSING_FILE_STATE.Parsing
                : status === CsvParseStatus.Completed
                ? PARSING_FILE_STATE.Parsed
                : PARSING_FILE_STATE.Uploaded;

            if (isMounted) {
              setCsvFilesInfo((prevState: CsvFilesInfo) => {
                const preparedCsvFileInfo = prevState[id];

                if (data) {
                  preparedCsvFileInfo.parsedProgressData = {
                    parsed: data.parsed,
                    inParsing: data.inParsing,
                    parseFailed: data.parseFailed,
                  };

                  if (data.updatedDataInfo) {
                    const { type, productType, productItem } = data.updatedDataInfo;

                    if (type === ParsedType.ProductType && productType) {
                      preparedCsvFileInfo.productType = productType;
                    } else if (type === ParsedType.ProductItem && productItem) {
                      preparedCsvFileInfo.productItems[data.parsed] = productItem;
                    }
                  }
                }

                return {
                  ...prevState,
                  [id]: {
                    ...preparedCsvFileInfo,
                    state,
                    errorMessage: data?.errorMessage || '',
                    parsingError: error || '',
                  },
                };
              });
            }

            if (status === CsvParseStatus.Completed && data?.parsed) {
              client.cache.writeFragment({
                id: `Catalog:${catalogId}`,
                fragment: gql`
                  fragment IsEmptyField on Catalog {
                    isEmpty
                  }
                `,
                data: {
                  isEmpty: false,
                },
              });
            }
          }
        },
      });
    }

    return () => {
      isMounted = false;
    };
  }, [checkIsCatalogEmptyQuery, client, catalogId, ids]);

  const deleteFile = (id: string) =>
    setCsvFilesInfo((prevCsvFilesState) => {
      const { state, productType, cancelUpload } = prevCsvFilesState[id];

      if (state === PARSING_FILE_STATE.Parsed && productType?.id) {
        deleteProductType({
          variables: {
            id: productType?.id || 0,
          },
          update: clearCatalogs,
        });
      } else if (state === PARSING_FILE_STATE.Uploading) {
        cancelUpload();
      }

      const newFilesUploadState = { ...prevCsvFilesState };
      delete newFilesUploadState[id];
      return newFilesUploadState;
    });

  const uploadFileToS3 = async (file: File, id: string, signedUrl: string) => {
    const cancelTokenSource = axios.CancelToken.source();

    let isUploadCanceled = false;

    const cancelUpload = () => {
      cancelTokenSource.cancel();
      isUploadCanceled = true;
    };

    setCsvFilesInfo((prevState: CsvFilesInfo) => ({
      ...prevState,
      [id]: {
        name: file.name,
        id,
        uploadPercentage: 0,
        state: PARSING_FILE_STATE.Uploading,
        cancelUpload,
        productItems: [],
      },
    }));

    const options = {
      onUploadProgress: (progressEvent: ProgressEvent) => {
        const { loaded, total } = progressEvent;
        const percent = Math.floor((loaded * 100) / total);

        setCsvFilesInfo((prevState: CsvFilesInfo) => ({
          ...prevState,
          [id]: {
            ...prevState[id],
            uploadPercentage: percent,
          },
        }));
      },
      cancelToken: cancelTokenSource.token,
      headers: {
        'Content-Type': file.type,
      },
    };

    try {
      await axios.put(signedUrl, file, options);
      setCsvFilesInfo((prevState: CsvFilesInfo) => ({
        ...prevState,
        [id]: { ...prevState[id], state: PARSING_FILE_STATE.Uploaded },
      }));
    } catch (error) {
      if (!isUploadCanceled) {
        setCsvFilesInfo((prevState: CsvFilesInfo) => ({
          ...prevState,
          [id]: { ...prevState[id], state: PARSING_FILE_STATE.UploadingError },
        }));
      }
    }
  };

  const upload = async (uploadingFiles: UploadingFile[], catalogId: string, clearPreviousIds?: boolean) => {
    const { data } = await getSignedUrls({
      variables: {
        files: uploadingFiles.map(({ id, name, file }) => ({
          filename: name || '',
          filetype: file?.type || 'text/csv',
          fileId: id,
          catalogId,
        })),
      },
    });

    if (data) {
      const signedUrls = data.getSignedUrlsForCsv;
      const idsForParsingStatus = signedUrls.map(({ id }) => id);
      const filesWithSignedUrls = uploadingFiles.map(({ file, id }, idx) => ({
        file,
        id,
        signedUrl: signedUrls[idx].url,
      }));

      await Promise.all(
        filesWithSignedUrls.map(({ file, id, signedUrl }) => {
          if (file && signedUrl) {
            return uploadFileToS3(file, id, signedUrl);
          }
        }),
      );

      setIds((prev) => (clearPreviousIds ? idsForParsingStatus : prev.concat(idsForParsingStatus)));
    }
  };

  return { upload, csvFilesState, deleteFile };
};

export default useImportCsv;
