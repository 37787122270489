import { CatalogJob } from '../utils/gql';

export enum EventName {
  FiltersReset = 'FiltersReset',
  CatalogJobUpdate = 'CatalogJobUpdate',
}

export interface EventData {
  [EventName.FiltersReset]: null;
  [EventName.CatalogJobUpdate]: CatalogJob;
}

interface Callback<Data> {
  (data: Data): void;
}

export class EventsService {
  private static callbackToEventHandlerMap = new Map();

  public static subscribe<E extends keyof EventData>(eventName: E, callback: Callback<EventData[E]>) {
    const handleUpdate = (e: CustomEvent<EventData[E]>) => {
      callback(e.detail);
    };

    this.callbackToEventHandlerMap.set(callback, handleUpdate);

    document.addEventListener(eventName, handleUpdate as EventListener);
  }

  public static unsubscribe<E extends keyof EventData>(eventName: E, callback: Callback<EventData[E]>) {
    const eventHandler = this.callbackToEventHandlerMap.get(callback);

    if (eventHandler) {
      document.removeEventListener(eventName, eventHandler as EventListener);
      this.callbackToEventHandlerMap.delete(callback);
    }
  }

  public static dispatch<E extends keyof EventData>(eventName: E, data: EventData[E]) {
    const customEvent = new CustomEvent(eventName, { detail: data });

    document.dispatchEvent(customEvent);
  }
}
