import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Checkbox, FormControlLabel } from '@material-ui/core';

interface DisplayEmptyCheckBoxProps {
  fieldName: string;
  checked: boolean;
  onChange: (value: boolean) => void;
}

const DisplayEmptyCheckBox = ({ fieldName, checked, onChange }: DisplayEmptyCheckBoxProps) => {
  const { t } = useTranslation();

  const handleChange = (_: unknown, value: boolean) => onChange(value);

  return (
    <Box mt="15px">
      <FormControlLabel
        control={
          <Checkbox
            onChange={handleChange}
            checked={checked}
            inputProps={{ 'data-testid': `${fieldName}Checkbox` } as React.InputHTMLAttributes<HTMLInputElement>}
          />
        }
        label={t('productItemsPage.displayProductsWithoutValue', { fieldName: fieldName.toLowerCase() })}
      />
    </Box>
  );
};

export default DisplayEmptyCheckBox;
