import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { TextFieldProps } from '@material-ui/core';

import { TextInputProps } from '../types';

import TextInput from './TextInput';

const WrapperTextInput = (props: TextInputProps & TextFieldProps) => {
  const formMethods = useForm({
    mode: 'onChange',
  });

  return (
    <FormProvider {...formMethods}>
      <TextInput autoComplete="off" {...props} />
    </FormProvider>
  );
};
export default WrapperTextInput;
