import { Box } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';

import Button from 'src/components/Button';

export const ImageEditorWrapper = styled(Box)`
  padding: 0px 56px 25px 30px;
`;

export const ImageEditorContainer = styled(Box)`
  height: 360px;
  border: 1px dotted black;
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const MainControlsContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 30px;
  padding: 0px 56px 0px 30px;
`;

export const CropModesContainer = styled(Box)`
  display: flex;
  border: ${({ theme: { palette } }) => `1px solid ${palette.action.disabledBackground}`};
  border-radius: 2px;
  color: ${({ theme: { palette } }) => palette.text.primary};
  font-size: 12px;
  line-height: 14px;
  max-width: 385px;

  button:nth-of-type(1) {
    border-left: none;
  }
`;

export const ControlsContainer = styled(Box)`
  display: flex;
  gap: 10px;
`;

export const CropModeBox = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: none;
  border-bottom: none;
  border-right: none;
  border-left: ${({ theme: { palette } }) => `1px solid ${palette.action.disabledBackground}`};
  padding: 8px 14px;
  min-width: inherit;
  border-radius: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;

  &:hover {
    cursor: pointer;
    box-shadow: none;
    color: ${({ theme: { palette } }) => palette.secondary.dark}};
  }

  &:disabled {
    svg {
      color: ${({ theme: { palette } }) => palette.action.disabledBackground};
    }
  }
`;

export const ControlBox = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: none;
  border-bottom: none;
  border-right: none;
  border-left: ${({ theme: { palette } }) => `1px solid ${palette.action.disabledBackground}`};
  padding: 7px 15px;
  min-width: inherit;
  border-radius: 0;

  &:hover {
    cursor: pointer;
    box-shadow: none;
  }

  &:disabled {
    svg {
      color: ${({ theme: { palette } }) => palette.action.disabledBackground};
    }
  }
`;

export const CustomPropoprtionsContainer = styled(Box)`
  display: flex;
  gap: 10px;
  width: 100%;
  align-items: center;
`;

export const CustomAttributeContainer = styled(Box)`
  display: flex;
`;

export const CustomProportionLabel = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: ${({ theme: { palette } }) => `1px solid ${palette.action.disabledBackground}`};
  padding: 8px 10px;
`;

export const ResetActionContainer = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${({ theme: { palette } }) => `1px solid ${palette.action.disabledBackground}`};
  border-radius: 2px;
  padding: 6px 10px;
  min-width: inherit;
  width: 34px;
  height: 30px;

  &:hover {
    cursor: pointer;
    box-shadow: none;
  }

  &:disabled {
    svg {
      color: ${({ theme: { palette } }) => palette.action.disabledBackground};
    }
  }
`;

export const ApplyActionContainer = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  background: ${({ theme: { palette } }) => palette.secondary.dark};
  padding: 7px 10px;
  min-width: inherit;
  width: 34px;
  height: 30px;

  &:hover {
    cursor: pointer;
    box-shadow: none;
    background: ${({ theme: { palette } }) => palette.secondary.dark};
  }

  &:disabled {
    background: ${({ theme: { palette } }) => palette.common.white};
    border: ${({ theme: { palette } }) => `1px solid ${palette.action.disabledBackground}`};
    border-radius: 2px;
    svg {
      color: ${({ theme: { palette } }) => palette.action.disabledBackground};
    }
  }
`;

export const ControlGroupContainer = styled(Box)`
  display: flex;
  border-radius: 2px;
  border: ${({ theme: { palette } }) => `1px solid ${palette.action.disabledBackground}`};

  h3:nth-of-type(1) {
    button {
      border-left: none;
    }
  }

  button {
    &:hover {
      svg {
        color: ${({ theme: { palette } }) => palette.secondary.dark};
      }
    }
  }
`;

export const MediaContentWrapper = styled(Box)`
  padding: 25px 56px 25px 30px;
`;

export const MediaContentContainer = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: start;
  max-width: 672px;
  flex-wrap: wrap;
  align-content: space-evenly;
  gap: 10px 10px;
`;

export const EditedImageLoaderWrapper = styled(Box)`
  display: flex;
  position: absolute;
  z-index: 1;
  align-items: center;
  height: 360px;
`;
