import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useQuery, useApolloClient, gql } from '@apollo/client';

import SharedCatalogPreviewHeader from 'src/components/Headers/SharedCatalogPreviewHeader';
import ItemEditingSidebar from 'src/components/SideBar/ItemEditingSidebar';
import SkeletonProductItemCard, {
  SkeletonProductItemCardWrapper,
} from 'src/components/Skeleton/SkeletonProductItemCard';
import useSharedCatalogs from 'src/providers/SharedCataloguesProvider';
import {
  getProductItemPreview,
  ProductItem,
  ProductTypeField,
  Query,
  QueryGetProductItemPreviewArgs,
  FieldSystemName,
} from 'src/utils/gql';
import { prepareData } from 'src/views/Catalogs/ProductItems/functions';
import { SKELETON_PRODUCT_ITEMS_PREVIEW_COUNT } from 'src/views/SharedCatalogPreview/types';

import { PageWrapper, PageContentWrapper } from './styled';

const PRODUCT_ITEM_FRAGMENT = gql`
  fragment ProductItemPreview on ProductItem {
    id
    values {
      id
      value
      productTypeField {
        id
        name
        systemName
        type
        required
      }
    }
  }
`;

const ProductItemPreview = () => {
  const { t } = useTranslation();
  const { productTypeId, productItemId } = useParams<{
    productTypeId: string;
    productItemId: string;
  }>();
  const { getProductName } = useSharedCatalogs();
  const client = useApolloClient();

  const productItemCacheId = client.cache.identify({
    id: productItemId,
    __typename: 'ProductItem',
  });

  const productItem = client.readFragment<ProductItem>({
    id: productItemCacheId,
    fragment: PRODUCT_ITEM_FRAGMENT,
  });

  const skipRequest = Boolean(productItem);

  const { loading } = useQuery<Pick<Query, 'getProductItemPreview'>, QueryGetProductItemPreviewArgs>(
    getProductItemPreview,
    {
      variables: {
        productTypeId: Number(productTypeId),
        productItemId: Number(productItemId),
      },
      skip: skipRequest,
    },
  );

  const productItemName = productItem?.values.find(
    ({ productTypeField }) => productTypeField.systemName === FieldSystemName.Name,
  )?.value;

  const locationName = productItemName
    ? `${t('navBar.contextMenu.recentStepsTitles.sharedCatalogProductItemPreview')} "${productItemName}"`
    : '';

  const preparedItemValues = useMemo(() => {
    if (productItem) {
      const fields = productItem.values.reduce(
        (fields: ProductTypeField[], value) => [...fields, value.productTypeField],
        [],
      );

      return prepareData(productItem, fields).values;
    }

    return [];
  }, [productItem]);

  return (
    <PageWrapper title={productItemName || ''} locationName={locationName}>
      <PageContentWrapper>
        <SharedCatalogPreviewHeader title={getProductName(productTypeId)} hasArrowBack />

        {loading && (
          <SkeletonProductItemCardWrapper>
            <SkeletonProductItemCard cardsCount={SKELETON_PRODUCT_ITEMS_PREVIEW_COUNT} />
          </SkeletonProductItemCardWrapper>
        )}

        {productItem && (
          <ItemEditingSidebar
            checkIsCatalogShared
            productItemName={productItemName}
            productItemValues={preparedItemValues}
            loading={loading}
            productTypeId={productTypeId}
          />
        )}
      </PageContentWrapper>
    </PageWrapper>
  );
};

export default ProductItemPreview;
