import React from 'react';

import { Box, Skeleton } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { keyframes } from '@mui/system';

export const SkeletonProductItemCardWrapper = styled(Box)`
  margin: 15px 46px 24px 14px;
  overflow-y: auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  grid-column-gap: 24px;
  grid-row-gap: 25px;
  padding: 10px 17px 10px 10px;
`;

const opacity = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Container = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  minWidth: 288,
  height: 351,
  background: theme.palette.background.default,
  borderRadius: 5,
  boxShadow: '0px 2px 10px rgba(46, 96, 170, 0.25)',
  animation: `${opacity} 1s ease`,

  '& .rect': {
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    width: '100%',
    height: '100%',
  },

  '& .footer': {
    width: '100%',
    height: 81,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 20px',
  },
}));

interface SkeletonProductItemCardProps {
  cardsCount: number;
}

const SkeletonProductItemCard = ({ cardsCount }: SkeletonProductItemCardProps) => (
  <>
    {new Array(cardsCount).fill('card').map((_, idx) => (
      <Container key={idx} data-testid="product-item-card-skeleton">
        <Skeleton className="rect" variant="rectangular" />

        <Box className="footer">
          <Skeleton variant="text" width="140px" height="30px" />

          <Skeleton variant="text" width="60px" height="30px" />
        </Box>
      </Container>
    ))}
  </>
);

export default SkeletonProductItemCard;
