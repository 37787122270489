import React from 'react';
import { Redirect, Switch } from 'react-router';
import { renderRoutes, RouteConfig } from 'react-router-config';

import { useFlag } from '@unleash/proxy-client-react';

import { FeatureFlag } from 'src/constants';
import { AUDIT_LOG, BILLING, CHANGELOG, ERROR_PATHS, REGISTER, SHARED_CATALOGS } from 'src/constants/routeSources';

// adopted from https://github.com/remix-run/react-router/blob/v5.1.1/packages/react-router-config/modules/renderRoutes.js
function RenderRoutes({ routes }: { routes?: RouteConfig[] }) {
  const isSharedCatalogsFeatureActive = useFlag(FeatureFlag.SHARED_CATALOGS);
  const isAuditLogFeatureActive = useFlag(FeatureFlag.AUDIT_LOG);
  const isSignUpFeatureActive = useFlag(FeatureFlag.SIGN_UP);
  const isBillingFeatureActive = useFlag(FeatureFlag.BILLING);
  const isChangelogFeatureActive = useFlag(FeatureFlag.CHANGELOG);

  return routes ? (
    <Switch>
      {renderRoutes(
        routes.map((route) => {
          if (typeof route.path === 'string') {
            const auditLogRegExp = new RegExp(AUDIT_LOG, 'gi');
            const sharedCatalogsRegExp = new RegExp(SHARED_CATALOGS, 'gi');
            const registerRegExp = new RegExp(REGISTER, 'gi');
            const billingRegExp = new RegExp(BILLING, 'gi');
            const changelogRegExp = new RegExp(CHANGELOG, 'gi');

            if (!isAuditLogFeatureActive && route.path.match(auditLogRegExp)) {
              return { path: route.path, render: () => <Redirect to={ERROR_PATHS.ERROR_404} /> };
            }
            if (!isSharedCatalogsFeatureActive && route.path.match(sharedCatalogsRegExp)) {
              return { path: route.path, render: () => <Redirect to={ERROR_PATHS.ERROR_404} /> };
            }
            if (!isSignUpFeatureActive && route.path.match(registerRegExp)) {
              return { path: route.path, render: () => <Redirect to={ERROR_PATHS.ERROR_404} /> };
            }
            if (!isBillingFeatureActive && route.path.match(billingRegExp)) {
              return { path: route.path, render: () => <Redirect to={ERROR_PATHS.ERROR_404} /> };
            }
            if (!isChangelogFeatureActive && route.path.match(changelogRegExp)) {
              return { path: route.path, render: () => <Redirect to={ERROR_PATHS.ERROR_404} /> };
            }
          }

          return route;
        }),
      )}
    </Switch>
  ) : null;
}

export default RenderRoutes;
