import React from 'react';

import { Box, Skeleton } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { keyframes } from '@mui/system';

export const SkeletonCatalogCardWrapper = styled(Box)`
  margin-right: 10px;
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(356px, 1fr));
  min-width: 100%;
  grid-gap: 24px;
`;

const opacity = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Container = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  minWidth: 356,
  height: 213,
  background: theme.palette.background.default,
  borderRadius: 4,
  boxShadow: '0 2px 10px rgba(46, 96, 170, 0.25)',
  padding: '40px 25px 30px 25px',
  animation: `${opacity} 1s ease`,
}));

interface SkeletonCatalogCardProps {
  cardsCount: number;
}

const SkeletonCatalogCard = ({ cardsCount }: SkeletonCatalogCardProps) => (
  <>
    {new Array(cardsCount).fill('card').map((_, idx) => (
      <Container key={idx}>
        <Box display="flex" justifyContent="space-between">
          <Skeleton variant="text" width="250px" height="30px" />

          <Skeleton variant="circular" width="30px" height="30px" />
        </Box>

        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" justifyContent="space-between" flexDirection="column" height="45px">
            <Skeleton variant="text" width="100px" height="20px" />

            <Skeleton variant="text" width="200px" height="20px" />
          </Box>

          <Box display="flex" flexDirection="row" justifyContent="space-between" width="70px">
            <Skeleton variant="circular" width="30px" height="30px" />

            <Skeleton variant="circular" width="30px" height="30px" />
          </Box>
        </Box>
      </Container>
    ))}
  </>
);

export default SkeletonCatalogCard;
