import { Box } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';

import Button from 'src/components/Button';

export const ModalContainer = styled(Box)`
  padding: 40px;
  width: 400px;
  min-height: 292px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${({ theme: { palette } }) => palette.action.disabled};
  border-radius: 5px;
  box-sizing: border-box;

  @media only screen and (max-width: 425px) {
    width: 90%;
  }
`;

export const CloseButtonOnError = styled(Button)`
  margin-right: 20px;
`;

export const ConnectButton = styled(Button)`
  margin-left: 15px;
`;

export const LoaderContainer = styled(Box)`
  position: relative;
  top: 14px;
  right: 44px;
  height: 20px;
`;
