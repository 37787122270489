import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, styled, Typography } from '@mui/material';
import moment, { Moment } from 'moment';

import Button from '../Button';
import MultipleDatePicker from '../DatePickers/MultipleDatePicker';
import { MultipleDatePickerState } from '../DatePickers/types';
import Popup from '../Popup';

const Wrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  alignItems: 'center',
  paddingTop: '7px',
  height: 130,

  '& .MuiButton-root': {
    position: 'absolute',
    bottom: 60,
  },
}));

const DatePickerContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',

  ':last-of-type': {
    '& .MuiTypography-root': {
      display: 'none',
    },
  },
}));

const ErrorText = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  bottom: 115,
  color: theme.palette.secondary.main,
}));

interface AuditLogCustomDatePopupProps {
  isOpen: boolean;
  customDates: MultipleDatePickerState[];
  setCustomDates: (state: MultipleDatePickerState[]) => void;
  onSelectCustomDates: (dates: (Moment | Date | null)[]) => void;
  onClose: () => void;
  isStartDateLessEndDate: boolean;
}

const AuditLogCustomDatePopup = ({
  isOpen,
  customDates,
  setCustomDates,
  onClose,
  onSelectCustomDates,
  isStartDateLessEndDate,
}: AuditLogCustomDatePopupProps) => {
  const { t } = useTranslation();

  const [showErrors, setShowErrors] = useState<boolean>(false);

  const MIN_DATE = moment().subtract(3, 'years').startOf('year');
  const MAX_DATE = moment().endOf('day');

  const hasErrors = Object.values(customDates).some(({ error }) => !!error);

  const handleSelectDate = (state: MultipleDatePickerState) => {
    const datesArray = [...customDates];
    datesArray[datesArray.findIndex(({ type }) => type === state.type)] = { ...state };
    setCustomDates(datesArray);
  };

  const handleApplyButtonClick = () => {
    if (hasErrors) {
      setShowErrors(true);
    } else {
      onSelectCustomDates(customDates.map(({ value }) => value));
      setShowErrors(false);
    }
  };

  const handleClose = () => {
    onClose();
    setShowErrors(false);
  };

  return (
    <Popup
      open={isOpen}
      mainTitle={t('auditLogPage.customDatePopup.title')}
      onClose={handleClose}
      mainButtonsMarginAbsence
    >
      <Wrapper>
        <Box display="flex" alignItems="center" maxWidth={410}>
          {customDates.map(({ label, value, type }, idx) => (
            <DatePickerContainer key={idx}>
              <MultipleDatePicker
                onSelectDate={handleSelectDate}
                showErrors={showErrors}
                label={label}
                type={type}
                value={value}
                minDate={MIN_DATE}
                maxDate={MAX_DATE}
              />

              <Typography mx="15px">{t('auditLogPage.customDatePopup.to')}</Typography>
            </DatePickerContainer>
          ))}
        </Box>

        {isStartDateLessEndDate && <ErrorText variant="caption">{t('datePicker.errorEndDateLess')}</ErrorText>}

        <Button
          variant="contained"
          onClick={handleApplyButtonClick}
          disabled={hasErrors || isStartDateLessEndDate}
          data-testid="applyButton"
        >
          {t('auditLogPage.customDatePopup.button')}
        </Button>
      </Wrapper>
    </Popup>
  );
};

export default AuditLogCustomDatePopup;
