import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { ApolloError } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Checkbox, Link } from '@material-ui/core';

import Button from 'src/components/Button';
import EmailInput from 'src/components/Forms/FormInputs/EmailInput';
import PasswordInput from 'src/components/Forms/FormInputs/PasswordInput';
import { FormContainer, StyledFormControlLabel } from 'src/components/Forms/styled';
import { SignInFormProps } from 'src/components/Forms/types';
import { signInSchema } from 'src/components/Forms/validationSchemas';
import { AuthInput } from 'src/utils/gql';

const SignInForm = ({ onSubmit, onError, loading }: SignInFormProps) => {
  const { t } = useTranslation();

  const formMethods = useForm<AuthInput>({
    mode: 'onChange',
    resolver: yupResolver(signInSchema),
  });

  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = formMethods;

  const onSubmitForm = handleSubmit(async ({ email, password, rememberMe }: AuthInput) => {
    try {
      await onSubmit({ email: email.trim(), password, rememberMe });
    } catch (error) {
      const { graphQLErrors, message: errorText } = error as ApolloError;
      const message = graphQLErrors?.length ? graphQLErrors[0].message : errorText;
      onError?.(message);
    }
  });

  return (
    <FormProvider {...formMethods}>
      <form aria-label="form" onSubmit={onSubmitForm}>
        <FormContainer>
          <Box mt="30px" mb="15px" width="100%">
            <EmailInput />
          </Box>

          <Box mb="25px" width="100%">
            <PasswordInput />
          </Box>

          <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" height="15px" mb="40px">
            <StyledFormControlLabel
              data-testid="checkboxRememberMe"
              control={
                <Checkbox {...register('rememberMe')} name="rememberMe" inputProps={{ 'aria-label': 'checkbox' }} />
              }
              label={t('rememberMe')}
            />
            <Link
              color="secondary"
              component={RouterLink}
              to="/auth/forgot-password"
              underline="hover"
              variant="h4"
              data-testid="linkForgotPassword"
            >
              {t('forgotPassword')}
            </Link>
          </Box>

          <Button
            color="primary"
            disabled={!isValid}
            type="submit"
            data-testid="submit"
            variant="contained"
            loading={loading}
          >
            {t('login')}
          </Button>
        </FormContainer>
      </form>
    </FormProvider>
  );
};

export default SignInForm;
