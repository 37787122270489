import { styled, TextField } from '@mui/material';

export const StyledDatePickerField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& .MuiButtonBase-root': {
      marginRight: 5,

      '& .MuiSvgIcon-root': {
        color: theme.palette.text.disabled,
      },

      ':hover': {
        '& .MuiSvgIcon-root': {
          color: theme.palette.text.primary,
        },
      },
    },
  },
}));
