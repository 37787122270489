import { IntegrationTypes } from 'src/utils/gql';

export const IntegrationCardsData = [
  {
    type: IntegrationTypes.Shopify,
    addNewIntegrationButton: 'shopifyAddNewIntegrationButton',
  },
  {
    type: IntegrationTypes.Woocommerce,
    addNewIntegrationButton: 'wooAddNewIntegrationButton',
  },
  {
    type: IntegrationTypes.Ebay,
    addNewIntegrationButton: 'ebayAddNewIntegrationButton',
  },
  {
    type: IntegrationTypes.Facebook,
    addNewIntegrationButton: 'facebookAddNewIntegrationButton',
  },
];
