import React from 'react';

import { Box, Skeleton, TableCell, TableRow } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { keyframes } from '@mui/system';

const opacity = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Container = styled(TableRow)({
  animation: `${opacity} 1s ease`,
});

interface SkeletonAuditLogRowProps {
  cardsCount: number;
}

const SkeletonAuditLogRow = ({ cardsCount }: SkeletonAuditLogRowProps) => (
  <>
    {new Array(cardsCount).fill('card').map((_, idx) => (
      <Container key={idx}>
        <TableCell sx={{ minWidth: 40 }}>
          <Skeleton variant="circular" width="30px" height="30px" />
        </TableCell>
        <TableCell>
          <Skeleton variant="text" width="80%" height="30px" />
        </TableCell>
        <TableCell>
          <Skeleton variant="text" width="80%" height="30px" />
        </TableCell>
        <TableCell>
          <Skeleton variant="text" width="80%" height="30px" />
        </TableCell>
        <TableCell>
          <Skeleton variant="text" width="80%" height="30px" />
        </TableCell>
        <TableCell>
          <Box display="flex" width="100%" justifyContent="center">
            <Skeleton variant="text" width="80%" height="30px" />
          </Box>
        </TableCell>
      </Container>
    ))}
  </>
);

export default SkeletonAuditLogRow;
