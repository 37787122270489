import React from 'react';

import { Box } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';

const MainBox = styled(Box)`
  position: absolute;
  width: 436px;
  height: 299px;
  left: 10px;
  opacity: 0.7;
  filter: blur(25px);

  & * {
    position: absolute;
    border-radius: 50%;
  }
`;

const DarkBlueBox = styled(Box)`
  background-color: #6c6ce5;
  box-shadow: 0 0 0 10px #6c6ce5;
`;

const LightBlueBox = styled(Box)`
  background-color: #e3e3fb;
  box-shadow: 0 0 0 10px #e3e3fb;
`;

const CSVBlurBackground = () => {
  return (
    <MainBox>
      <LightBlueBox width="115px" height="95px" top="85px" />

      <LightBlueBox width="120px" height="75px" right="245px" bottom="39px" />

      <LightBlueBox width="120px" height="50px" right="195px" bottom="0" />

      <LightBlueBox width="190px" height="85px" right="0" top="45px" />

      <LightBlueBox width="90px" height="75px" right="100px" top="105px" />

      <LightBlueBox width="80px" height="60px" right="125px" top="180px" />

      <DarkBlueBox width="65px" height="65px" right="215px" top="165px" />

      <DarkBlueBox width="135px" height="135px" top="65px" right="205px" />

      <DarkBlueBox width="120px" height="125px" right="175px" />

      <LightBlueBox width="25px" height="25px" right="238px" top="77px" />
    </MainBox>
  );
};

export default CSVBlurBackground;
