import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { useLazyQuery, useQuery } from '@apollo/client';

import { CompletenessIntegration } from 'src/components/Completeness/ChipsCompleteness';
import { SidebarCompleteness } from 'src/components/Completeness/SidebarCompleteness';
import ItemsDetailsHeader from 'src/components/Headers/ItemsDetailsHeader';
import { MediaDataType } from 'src/components/MediaGallery/constants';
import Page from 'src/components/Page';
import DiscardChangesPopup from 'src/components/Popups/DiscardChangesPopup';
import ItemDefinitionDeletePopup from 'src/components/Popups/ItemDefinitionDeletePopup';
import ItemDefinitionSavePopup from 'src/components/Popups/ItemDefinitionSavePopup';
import useDebounceValue from 'src/hooks/useDebounceValue';
import { MediaStateContext } from 'src/providers/MediaProvider/context';
import useOnboarding from 'src/providers/OnboardingProvider/hooks/useOnboarding';
import {
  DuplicatedSkuInfo,
  FieldSystemName,
  getCatalogInfo,
  getProductTypeById,
  isSKUDuplicated,
  ItemStatus,
  MediaUsage,
  ProductItem,
  ProductItemValue,
  ProductItemValueInput,
  ProductTypeField,
  Query,
  QueryGetCatalogByIdArgs,
  QueryGetProductTypeArgs,
  QueryIsSkuDuplicatedArgs,
  UpdateProductItemInput,
} from 'src/utils/gql';

import ItemDetailsBody from './ItemDetailsBody';
import { ItemDetailsContentWrapper, ItemDetailsWrapper } from './styled';
import { mockedProductTypeFields, UploadingMedia } from './types';

export interface ItemEditingFormData {
  [fieldId: string]: string | boolean;
}

export const prepareFormDataToSave = ({ draft, ...valuesData }: ItemEditingFormData): UpdateProductItemInput => ({
  status: draft ? ItemStatus.Draft : ItemStatus.Active,
  values: Object.keys(valuesData).reduce((acc: ProductItemValueInput[], fieldId) => {
    const value = valuesData[fieldId];

    if (value) {
      acc.push({
        productTypeFieldId: Number(fieldId),
        value: String(value).trim(),
      });
    }

    return acc;
  }, []),
});

interface ItemDetailsProps {
  testMode?: boolean;
  onSave: (itemData: UpdateProductItemInput) => Promise<boolean>;
  title?: string;
  locationName: string;
  parentLoading?: boolean;
  addNameInTitleAndLocationEnd?: boolean;
  initialItemEditingFormData?: ItemEditingFormData;
  currentItemId?: string;
  canBeSavedWithoutChanges?: boolean;
  productItemValues?: ProductItemValue[];
  hideGeneratingTextButton?: boolean;
}

const ItemDetails = ({
  testMode,
  onSave,
  title = '',
  locationName,
  parentLoading,
  addNameInTitleAndLocationEnd,
  initialItemEditingFormData,
  currentItemId,
  canBeSavedWithoutChanges,
  productItemValues,
  hideGeneratingTextButton,
}: ItemDetailsProps) => {
  const { catalogId, productTypeId } = useParams<{
    catalogId: string;
    productTypeId: string;
  }>();
  const {
    onboardingState: { tourActive },
    setOnboardingState,
    goNext,
  } = useOnboarding();

  const { mediaState, mediaOrdersBeforeUpdate } = useContext(MediaStateContext);

  const [deletePopupOpen, setDeletePopupOpen] = useState<boolean>(false);
  const [saveButtonLoading, setSaveButtonLoading] = useState(false);
  const [savedPopupOpen, setSavedPopupOpen] = useState(false);
  const [isCompletenessSidebarOpen, setIsCompletenessSidebarOpen] = useState(false);
  const [wasItemSaved, setWasItemSaved] = useState<boolean>(true);
  const [duplicatedSKUInfo, setDuplicatedSKUInfo] = useState<DuplicatedSkuInfo>();

  const { data: currentCatalogInfo, loading: currentCatalogLoading } = useQuery<
    Pick<Query, 'getCatalogById'>,
    QueryGetCatalogByIdArgs
  >(getCatalogInfo, {
    variables: { id: catalogId },
  });

  const { data: productTypeData, loading: productTypeDataLoading } = useQuery<
    Pick<Query, 'getProductType'>,
    QueryGetProductTypeArgs
  >(getProductTypeById, {
    variables: {
      id: productTypeId,
    },
  });

  const formProps = useForm({
    mode: 'all',
    defaultValues: initialItemEditingFormData,
  });

  const fields = (testMode ? mockedProductTypeFields : productTypeData?.getProductType.fields) as ProductTypeField[];
  const skuFieldId = useMemo(() => fields?.find(({ systemName }) => systemName === FieldSystemName.Sku)?.id, [fields]);
  const initialSkuValue = initialItemEditingFormData?.[skuFieldId || ''];
  const formSkuValue = formProps.watch(`${skuFieldId}` as const);
  const debouncedSKUValue = useDebounceValue(formSkuValue, 500);

  const [checkIsSKUDuplicated] = useLazyQuery<Pick<Query, 'isSKUDuplicated'>, QueryIsSkuDuplicatedArgs>(
    isSKUDuplicated,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: ({ isSKUDuplicated }) => {
        setDuplicatedSKUInfo(isSKUDuplicated);
      },
    },
  );

  useEffect(() => {
    if (debouncedSKUValue) {
      checkIsSKUDuplicated({
        variables: { data: { id: catalogId, value: String(debouncedSKUValue), productItemId: currentItemId } },
      });
    }
  }, [catalogId, checkIsSKUDuplicated, currentItemId, debouncedSKUValue, initialSkuValue]);

  const nameFieldId = useMemo(
    () => fields?.find(({ systemName }) => systemName === FieldSystemName.Name)?.id,
    [fields],
  );

  const itemValues = useMemo(() => {
    if (productItemValues?.length) {
      return productItemValues?.map((item) => ({ ...item, value: formProps.watch()[item.productTypeField.id] }));
    }
    return [];
  }, [productItemValues, formProps]);

  const productItemName = (nameFieldId && String(formProps.watch(`${nameFieldId}` as const))) || '';

  const itemWasChanged = JSON.stringify(initialItemEditingFormData) !== JSON.stringify(formProps.getValues());
  const mediaWasChanged = Boolean(
    mediaState.filter(({ typeAddedData }) => typeAddedData !== MediaDataType.FROM_DATABASE).length,
  );
  const mediaAltTextChanged = mediaState.find((media) => media.isChangedAltText === true);
  const mediaOrdersWereChanged = useMemo(() => {
    const changedOrders: UploadingMedia[] = mediaState.reduce((acc: UploadingMedia[], media) => {
      const changedMedia = mediaOrdersBeforeUpdate.find((changedMedia) => media.uuid === changedMedia.uuid);
      if (!!changedMedia && media.orderNumber !== changedMedia.orderNumber) {
        return [...acc, media];
      }
      return acc;
    }, []);
    return Boolean(changedOrders?.length);
  }, [mediaState, mediaOrdersBeforeUpdate]);
  const isItemCanBeSaved = useMemo(() => {
    setWasItemSaved(false);

    return (
      itemWasChanged || !canBeSavedWithoutChanges || mediaWasChanged || mediaOrdersWereChanged || mediaAltTextChanged
    );
  }, [canBeSavedWithoutChanges, itemWasChanged, mediaAltTextChanged, mediaOrdersWereChanged, mediaWasChanged]);

  const saveButtonHandler = () => {
    if (isItemCanBeSaved) {
      setSaveButtonLoading(true);

      formProps.handleSubmit(
        (data: ItemEditingFormData) =>
          onSave(prepareFormDataToSave(data))
            .then((saved) => {
              if (saved) {
                setSavedPopupOpen(true);
                setWasItemSaved(true);

                if (tourActive) {
                  setTimeout(() => {
                    goNext();
                  }, 150);
                }
              }
            })
            .finally(() => setSaveButtonLoading(false)),
        () => {
          setSaveButtonLoading(false);
        },
      )();
    } else {
      setSavedPopupOpen(true);
    }
  };

  const handleSaveItemOnOpenMappingSidebar = () => {
    if (isItemCanBeSaved) {
      setSaveButtonLoading(true);

      formProps.handleSubmit(
        (data: ItemEditingFormData) => onSave(prepareFormDataToSave(data)).finally(() => setSaveButtonLoading(false)),
        () => setSaveButtonLoading(false),
      )();
    }
  };

  const loading =
    (productTypeDataLoading || parentLoading || Boolean(initialSkuValue && !duplicatedSKUInfo)) && !testMode;
  const isDiscardChangesPopupOpen =
    (mediaWasChanged || itemWasChanged || mediaOrdersWereChanged) && !(savedPopupOpen || deletePopupOpen);

  const integrationsMap = useMemo(
    () =>
      currentCatalogInfo?.getCatalogById?.integrations?.reduce(
        (acc: Record<string, CompletenessIntegration>, integration) => {
          acc[integration.type] = { id: integration.id, type: integration.type };

          return acc;
        },
        {},
      ) || {},
    [currentCatalogInfo?.getCatalogById?.integrations],
  );

  useEffect(() => {
    if (tourActive) {
      const {
        formState: { isValid },
      } = formProps;

      setOnboardingState((prevState) => ({
        ...prevState,
        createdOnboardingProductItem: isValid
          ? ({
              id: '1',
            } as ProductItem)
          : null,
      }));
    }
  }, [formProps, formProps.formState.isValid, setOnboardingState, tourActive]);

  return (
    <Page
      title={addNameInTitleAndLocationEnd ? productItemName : title}
      locationName={`${locationName}${addNameInTitleAndLocationEnd ? ` "${productItemName}"` : ''}`}
      loading={loading}
    >
      <FormProvider {...formProps}>
        <ItemDetailsWrapper>
          <ItemsDetailsHeader
            onDelete={() => setDeletePopupOpen(true)}
            onSave={saveButtonHandler}
            saveButtonLoading={saveButtonLoading}
            editingName={addNameInTitleAndLocationEnd ? productItemName : undefined}
            onCompletenessTextClick={() => setIsCompletenessSidebarOpen(true)}
          />

          {(productTypeData || testMode) && (
            <ItemDetailsContentWrapper>
              <ItemDetailsBody
                duplicatedSKUInfo={formSkuValue ? duplicatedSKUInfo : undefined}
                saveItemOnOpenMappingSidebar={handleSaveItemOnOpenMappingSidebar}
                fields={fields}
                saveButtonLoading={saveButtonLoading}
                productItemId={currentItemId}
                hideGeneratingTextButton={hideGeneratingTextButton}
              />
            </ItemDetailsContentWrapper>
          )}
        </ItemDetailsWrapper>

        <ItemDefinitionSavePopup
          open={savedPopupOpen}
          catalogId={catalogId}
          productTypeId={productTypeId}
          onClose={() => setSavedPopupOpen(false)}
          productItemId={currentItemId}
        />

        <ItemDefinitionDeletePopup
          open={deletePopupOpen}
          onClose={() => setDeletePopupOpen(false)}
          productItemId={currentItemId}
        />

        <SidebarCompleteness
          isOpen={isCompletenessSidebarOpen}
          onClose={() => setIsCompletenessSidebarOpen(false)}
          integrationsMap={integrationsMap}
          fieldsValues={itemValues as ProductItemValue[]}
          fieldsMapping={productTypeData?.getProductType?.fieldsMappings || []}
          isDataLoading={productTypeDataLoading || currentCatalogLoading}
          mediaUsages={mediaState.map(
            (media) =>
              ({
                orderNumber: media?.orderNumber,
                media: media,
                id: media?.id,
                createdAt: media?.createdAt,
              } as MediaUsage),
          )}
        />

        <DiscardChangesPopup isOpen={isDiscardChangesPopupOpen && !wasItemSaved && !tourActive} />
      </FormProvider>
    </Page>
  );
};

export default ItemDetails;
