import React from 'react';
import { useTranslation } from 'react-i18next';

import { DialogContent, makeStyles, Theme, Typography } from '@material-ui/core';

import Popup, { PopupProps } from 'src/components/Popup';

import { ApprovePopupDescription } from './styled';

const useStyles = makeStyles((theme: Theme) => ({
  itemsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    maxHeight: '112px',
    marginBottom: '50px',
    padding: '0 24px',
    overflow: 'auto',
    scrollbarWidth: 'thin',
  },
  item: {
    background: theme.palette.background.default,
    padding: '14px 35px',
    maxWidth: '196px',
    width: '100%',
    height: 'auto',
    lineBreak: 'anywhere',
    marginBottom: '10px',
    borderRadius: '5px',
  },
}));

interface ApprovePopupProps extends PopupProps {
  updateAll: boolean;
  open: boolean;
  onClose: () => void;
  onMainButtonClick: () => void;
  fieldName: string;
  itemValue: string;
  loadingOnMainButton: boolean;
  itemsNames: string[];
}

const ApprovePopup = ({
  updateAll,
  open,
  onClose,
  onMainButtonClick,
  fieldName,
  itemValue,
  loadingOnMainButton,
  itemsNames,
  ...props
}: ApprovePopupProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Popup
      {...props}
      open={open}
      mainTitle={t('approvePopup.titleText')}
      mainButtonText={t('approvePopup.mainButtonText')}
      secondaryButtonText={t('approvePopup.secondaryButtonText')}
      loadingOnMainButton={loadingOnMainButton}
      onMainButtonClick={onMainButtonClick}
      onClose={onClose}
      maxWidth="sm"
    >
      <DialogContent>
        <ApprovePopupDescription>
          {t(`approvePopup.${updateAll ? 'titleDescriptionForTheAllItems' : 'titleDescription'}`, {
            fieldName,
            value: itemValue,
          })}
        </ApprovePopupDescription>
        {!updateAll && (
          <div className={classes.itemsContainer}>
            {itemsNames.map((name, id) => (
              <div key={id} className={classes.item}>
                {name}
              </div>
            ))}
          </div>
        )}
        <Typography variant="subtitle1" color="text.primary" marginBottom="0">
          {t('approvePopup.dialogContentText')}
        </Typography>
      </DialogContent>
    </Popup>
  );
};

export default ApprovePopup;
