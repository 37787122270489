import React, { ChangeEvent, FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useQuery } from '@apollo/client';
import { Box, Typography } from '@material-ui/core';

import useDebounceValue from 'src/hooks/useDebounceValue';
import { getMostPopularKeywords, Query, QueryGetMostPopularKeywordsArgs } from 'src/utils/gql';
import KeywordsSidebar from 'src/views/SharedCatalogs/KeywordsSidebar';
import { DEBOUNCED_SEARCH_DELAY, KEYWORDS_LIMIT } from 'src/views/types';

import SearchField from '../SearchField';

interface SearchHeaderProps {
  handleResetKeywords?: () => void;
  handleClickKeyword?: (keyword: string) => void;
  handleChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onSubmit?: (event: FormEvent) => void;
  activeKeywords?: string[];
  title?: string;
  searchFieldValue?: string;
  searchRequest?: string;
  sharedCatalogsSearch?: boolean;
}

export default function SearchHeader({
  activeKeywords,
  handleResetKeywords,
  handleClickKeyword,
  title,
  handleChange,
  sharedCatalogsSearch,
  searchFieldValue,
}: SearchHeaderProps) {
  const { t } = useTranslation();
  const [isKeywordsSidebarOpen, setIsKeywordsSidebarOpen] = useState<boolean>(false);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [searchValue, setSearchValue] = useState<string>('');

  const debouncedSearchValue = useDebounceValue(searchValue, DEBOUNCED_SEARCH_DELAY);

  const { data: mostPopularKeywordsData } = useQuery<
    Pick<Query, 'getMostPopularKeywords'>,
    QueryGetMostPopularKeywordsArgs
  >(getMostPopularKeywords, {
    fetchPolicy: 'cache-first',
    variables: {
      limit: KEYWORDS_LIMIT,
      name: debouncedSearchValue,
    },
  });

  const handleSaveKeywords = () => {
    setSearchValue('');

    setIsKeywordsSidebarOpen(false);
  };

  const handleCloseKeywordsSidebar = () => {
    setSearchValue('');
    setIsKeywordsSidebarOpen(false);

    setTimeout(() => setIsDropDownOpen(true));
  };

  return (
    <Box display="flex" flexDirection="column" ml="8px" mr="56px">
      <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
        <Typography variant="h2">{title}</Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" mt="20px">
        <Box display="flex" flexDirection="column" width="100%" height={activeKeywords?.length ? '57px' : '72px'}>
          <SearchField
            searchPage
            isDropDownOpen={isDropDownOpen}
            setIsDropDownOpen={setIsDropDownOpen}
            activeKeywords={activeKeywords}
            handleClickKeyword={handleClickKeyword}
            keywords={mostPopularKeywordsData?.getMostPopularKeywords?.slice(0, 30)}
            isKeywordsSidebarOpen={isKeywordsSidebarOpen}
            sharedCatalogsSearch={sharedCatalogsSearch}
            setIsKeywordsSidebarOpen={setIsKeywordsSidebarOpen}
            onChange={handleChange}
            variant="outlined"
            label={`${t('cataloguesHeader.searchFieldLabel')}`}
            value={searchFieldValue}
            testId="sharedCatalogsSearch"
          />
        </Box>
      </Box>

      <KeywordsSidebar
        searchValue={searchValue}
        handleResetKeywords={handleResetKeywords}
        handleClickKeyword={handleClickKeyword}
        activeKeywords={activeKeywords}
        handleChangeSearch={setSearchValue}
        keywords={mostPopularKeywordsData?.getMostPopularKeywords}
        handleSave={handleSaveKeywords}
        handleClose={handleCloseKeywordsSidebar}
        open={isKeywordsSidebarOpen}
      />
    </Box>
  );
}
