import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@material-ui/core';

import Layout from './Layout';

function Error500() {
  const { t } = useTranslation();

  return (
    <Layout
      textTitle={t('pageUnexpectedError.title')}
      textSubTitle={t('pageUnexpectedError.subtitle')}
      isUnexpectedPage
    >
      <>
        <Typography variant="subtitle1" color="text.secondary" fontWeight="400">
          {t('pageUnexpectedError.contentFirstPart')}
          <br />
          {t('pageUnexpectedError.content')}
        </Typography>
        <Typography variant="subtitle1" color="text.secondary" fontWeight="400" mt="15px">
          {t('pageUnexpectedError.contentSecondPart')}
        </Typography>
      </>
    </Layout>
  );
}

export default Error500;
