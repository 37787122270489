import React, { ReactNode, useCallback, useEffect, useState } from 'react';

import { Box, makeStyles, Theme } from '@material-ui/core';
import { ClassNameMap } from '@mui/material';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  stickyHeader: {
    zIndex: 1100,
    display: 'flex',
    background: theme.palette.common.white,
    top: 0,
    position: 'sticky',
    boxShadow: '0 2px 10px rgba(46, 96, 170, 0.25)',
  },
}));

interface StickyHeaderProps {
  customClass?: ClassNameMap;
  threshold: number;
  children: ReactNode;
}

const STICKY_HEADER_ID = 'sticky-header';

const StickyHeader = ({ threshold, customClass, children }: StickyHeaderProps) => {
  const { stickyHeader } = useStyles();
  const [fixedHeader, setFixedHeader] = useState<boolean>(false);
  const el = document.getElementById(STICKY_HEADER_ID);
  const handleFixedHeader = useCallback(() => {
    el && setFixedHeader(window.scrollY >= threshold);
  }, [threshold, el]);

  useEffect(() => {
    document.addEventListener('scroll', handleFixedHeader);
    return () => document.removeEventListener('scroll', handleFixedHeader);
  }, [handleFixedHeader]);

  return (
    <Box id={STICKY_HEADER_ID} className={clsx(fixedHeader && stickyHeader, customClass?.stickyHeader)}>
      {children}
    </Box>
  );
};

export default StickyHeader;
