import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { gql, useQuery, useApolloClient, Reference, StoreObject } from '@apollo/client';

import {
  Catalog,
  getIntegrations,
  getProductTypesByCatalogId,
  Integration,
  IntegrationWithUndefinedMappings,
  ProductType,
  ProductTypeFieldIntegrationMapping,
  ProductTypeIntegrationMapping,
  Query,
  QueryGetIntegrationsArgs,
  QueryProductTypesArgs,
} from 'src/utils/gql';

import SideBarDrawer from '../RightSidebarDrawer';

import { ItemMappingProvider } from './context/ItemMappingProvider';
import { FirstTimeExportMappingSidebar } from './FirstTimeExportMapping';
import ItemMapping from './ItemMapping';
import { MappingSidebarContent } from './styled';
import { MappingSidebarType } from './types';

interface MappingSidebarAndBodyCommonProps {
  isOpen: boolean;
  onClickExport?: () => void;
  sidebarType: MappingSidebarType;
  isDiscardChangesPopupOpen?: boolean;
  onCloseDiscardChangesPopup?: () => void;
  onClose: (withCheck?: boolean) => void;
  saveButtonLoading?: boolean;
  secondaryButtonTitle?: string;
  customSidebarTitle?: string;
}

export interface MappingSidebarBodyProps extends MappingSidebarAndBodyCommonProps {
  integrations: Integration[];
  currentCatalogId: string;
}

export interface FirstTimeExportMappingSidebarProps extends MappingSidebarBodyProps {
  proposedMappings: IntegrationWithUndefinedMappings[];
  productTypes: ProductType[];
}

export interface FirstTimeMappingsBodyProps extends FirstTimeExportMappingSidebarProps {
  existTypeMappings: ProductTypeIntegrationMapping[];
  existFieldMappings: ProductTypeFieldIntegrationMapping[];
  catalog: Catalog;
}

export interface MappingSidebarProps extends MappingSidebarAndBodyCommonProps {
  proposedMappings?: IntegrationWithUndefinedMappings[];
  selectedCatalogId?: string;
  currentIntegrationId?: string;
}

const MappingSidebarBody = ({
  isOpen,
  onClose,
  sidebarType,
  isDiscardChangesPopupOpen,
  onCloseDiscardChangesPopup,
  integrations,
  saveButtonLoading,
}: MappingSidebarBodyProps) => {
  const { t } = useTranslation();

  const getSidebarOptions = () => {
    let title: string;
    let mainButtonTitle: string;
    let dataTestId: string;

    switch (sidebarType) {
      case MappingSidebarType.ItemMapping:
        title = t('mappingSidebar.itemMapping.title');
        mainButtonTitle = t('mappingSidebar.closeButton');
        dataTestId = 'closeMappingsButton';
        break;

      case MappingSidebarType.FirstTimeMapping:
        title = t('mappingSidebar.firstTimeMapping.title');
        mainButtonTitle = t('mappingSidebar.exportButton');
        dataTestId = 'exportMappingsButton';
        break;
    }

    return { title, mainButtonTitle, dataTestId };
  };

  const getContent = () => {
    switch (sidebarType) {
      case MappingSidebarType.ItemMapping:
        return (
          <ItemMappingProvider integrations={integrations} getIntegrationsLoading={false}>
            <ItemMapping
              saveButtonLoading={saveButtonLoading}
              isDiscardChangesPopupOpen={isDiscardChangesPopupOpen}
              onCloseSidebar={onClose}
              onCloseDiscardChangesPopup={onCloseDiscardChangesPopup}
            />
          </ItemMappingProvider>
        );

      default:
        return null;
    }
  };

  return (
    <SideBarDrawer
      primaryButtonTitle={getSidebarOptions().mainButtonTitle}
      onPrimaryButtonClick={onClose}
      titleName={getSidebarOptions().title}
      openSidebar={isOpen}
      dataTestIdPrimaryButton={getSidebarOptions().dataTestId}
      dataTestId="mappingsSidebar"
      handleCloseSidebarOnClickAway={onClose}
    >
      <MappingSidebarContent>{getContent()}</MappingSidebarContent>
    </SideBarDrawer>
  );
};

const MappingSidebar = ({
  selectedCatalogId,
  proposedMappings,
  currentIntegrationId,
  onClose,
  onClickExport,
  ...rest
}: MappingSidebarProps) => {
  const { id, catalogId } = useParams<{ id: string; catalogId: string }>();
  const { cache } = useApolloClient();

  const currentCatalogId = selectedCatalogId || catalogId || id || '';

  const { isOpen } = rest;

  const updateCache = (data: Pick<Query, 'getIntegrations'>) => {
    const newIntegration = data?.getIntegrations.find(({ id }) => id === currentIntegrationId);

    if (newIntegration && currentCatalogId) {
      cache.modify({
        id: cache.identify({
          id: currentCatalogId,
          __typename: 'Catalog',
        }),
        fields: {
          integrations(existingIntegrationsRef = [], { readField }) {
            const chechIntegrations = existingIntegrationsRef.some(
              (ref: Reference | StoreObject | undefined) => readField('id', ref) === newIntegration.id,
            );

            if (!chechIntegrations) {
              const newIntegrationRef = cache.writeFragment({
                data: newIntegration,
                fragment: gql`
                  fragment NewIntegration on Integration {
                    id
                  }
                `,
              });

              return [...existingIntegrationsRef, newIntegrationRef];
            }

            return [...existingIntegrationsRef];
          },
        },
      });
    }
  };

  const { data: attachedIntegrations } = useQuery<Pick<Query, 'getIntegrations'>, QueryGetIntegrationsArgs>(
    getIntegrations,
    {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
      variables: { catalogId: currentCatalogId },
      skip: !isOpen,
      onCompleted: currentIntegrationId ? updateCache : undefined,
    },
  );

  const { data: productTypesData } = useQuery<Pick<Query, 'productTypes'>, QueryProductTypesArgs>(
    getProductTypesByCatalogId,
    {
      variables: {
        catalogId: currentCatalogId,
        // temporary limitation
        limit: 100,
      },
      skip: !isOpen,
    },
  );

  /*
    The effect is needed for the correct functioning of the "MappingSidebar",
    specifically to avoid double scrolling.
  */
  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'visible';
  }, [isOpen]);

  const handleCloseOrExport = (callback: () => void) => {
    const promise = new Promise((resolve) => resolve(callback()));

    const productTypesList = document.getElementById('productTypesList');

    promise.then(() => {
      if (!productTypesList) {
        document.body.style.overflow = 'visible';
      }
    });
  };

  const integrations = useMemo(() => {
    const allIntegrations = attachedIntegrations?.getIntegrations;

    if (allIntegrations && currentIntegrationId) {
      return allIntegrations.filter(({ id }) => String(currentIntegrationId) === String(id));
    }

    return allIntegrations;
  }, [attachedIntegrations?.getIntegrations, currentIntegrationId]);

  return integrations ? (
    rest.sidebarType === MappingSidebarType.FirstTimeMapping ? (
      productTypesData && proposedMappings ? (
        <FirstTimeExportMappingSidebar
          integrations={integrations}
          currentCatalogId={currentCatalogId}
          productTypes={productTypesData.productTypes}
          proposedMappings={proposedMappings}
          onClose={() => handleCloseOrExport(onClose)}
          onClickExport={onClickExport ? () => handleCloseOrExport(onClickExport) : undefined}
          {...rest}
        />
      ) : null
    ) : (
      <MappingSidebarBody
        onClickExport={onClickExport}
        onClose={onClose}
        integrations={integrations}
        currentCatalogId={currentCatalogId}
        {...rest}
      />
    )
  ) : null;
};

export default MappingSidebar;
