import { clearCacheFactory } from 'src/utils/cacheUtils';
import { arrayToMap } from 'src/utils/general';

export const EBAY_MARKETPLACES_IDS_SELECT_OPTIONS = [
  { label: 'United States', value: 'EBAY_US' },
  { label: 'Austria', value: 'EBAY_AT' },
  { label: 'Australia', value: 'EBAY_AU' },
  { label: 'Belgium', value: 'EBAY_BE' },
  { label: 'Canada', value: 'EBAY_CA' },
  { label: 'Switzerland', value: 'EBAY_CH' },
  { label: 'Germany', value: 'EBAY_DE' },
  { label: 'Spain', value: 'EBAY_ES' },
  { label: 'France', value: 'EBAY_FR' },
  { label: 'Great Britain', value: 'EBAY_GB' },
  { label: 'Hong Kong', value: 'EBAY_HK' },
  { label: 'Ireland', value: 'EBAY_IE' },
  { label: 'India', value: 'EBAY_IN' },
  { label: 'Italy', value: 'EBAY_IT' },
  { label: 'Malaysia', value: 'EBAY_MY' },
  { label: 'Netherlands', value: 'EBAY_NL' },
  { label: 'Philippines', value: 'EBAY_PH' },
  { label: 'Poland', value: 'EBAY_PL' },
  { label: 'Singapore', value: 'EBAY_SG' },
  { label: 'Thailand', value: 'EBAY_TH' },
  { label: 'Taiwan', value: 'EBAY_TW' },
  { label: 'Vietnam', value: 'EBAY_VN' },
  { label: 'United States motors', value: 'EBAY_MOTORS_US' },
];

export const marketplaceOptionsByValueMap = arrayToMap(EBAY_MARKETPLACES_IDS_SELECT_OPTIONS, 'value');

export const EBAY_SITES_IDS_SELECT_OPTIONS = [
  { label: 'United States', value: '0' },
  { label: 'Austria', value: '16' },
  { label: 'Australia', value: '15' },
  { label: 'Belgium (French)', value: '23' },
  { label: 'Belgium (Dutch)', value: '123' },
  { label: 'Canada (English)', value: '2' },
  { label: 'Canada (French)', value: '210' },
  { label: 'Switzerland', value: '193' },
  { label: 'Germany', value: '77' },
  { label: 'Spain', value: '186' },
  { label: 'France', value: '71' },
  { label: 'Great Britain', value: '3' },
  { label: 'Hong Kong', value: '201' },
  { label: 'Ireland', value: '205' },
  { label: 'India', value: '203' },
  { label: 'Italy', value: '101' },
  { label: 'Malaysia', value: '207' },
  { label: 'Netherlands', value: '146' },
  { label: 'Philippines', value: '211' },
  { label: 'Poland', value: '212' },
  { label: 'Singapore', value: '216' },
  { label: 'United States motors', value: '100' },
];

export const DEFAULT_MARKETPLACE_OPTION = EBAY_MARKETPLACES_IDS_SELECT_OPTIONS[0];

export const clearRequiredRelationsFields = clearCacheFactory({ fieldNames: ['checkIfFieldCanBeDeleted'] });
