import React from 'react';
import { useTranslation } from 'react-i18next';

import { DialogContent, DialogContentText } from '@material-ui/core';

import Popup from 'src/components/Popup';

interface SkipImportPopupProps {
  open: boolean;
  onClose: () => void;
  onMainButtonClick: () => void;
  onSecondaryButtonClick: () => void;
}

const SkipImportPopup = ({ open, onClose, onMainButtonClick, onSecondaryButtonClick }: SkipImportPopupProps) => {
  const { t } = useTranslation();

  return (
    <Popup
      open={open}
      mainTitle={t('skipImportPopup.title')}
      mainButtonText={t('skipImportPopup.mainButton')}
      secondaryButtonText={t('skipImportPopup.secondaryButton')}
      onClose={onClose}
      onMainButtonClick={onMainButtonClick}
      onSecondaryButtonClick={onSecondaryButtonClick}
    >
      <DialogContent>
        <DialogContentText variant="body1" gutterBottom>
          {t('skipImportPopup.text')}
        </DialogContentText>
      </DialogContent>
    </Popup>
  );
};

export default SkipImportPopup;
