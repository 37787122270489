import React from 'react';
import { Trans } from 'react-i18next';

import { ReactComponent as FacebookAndInstagramIcon } from 'src/components/Icon/facebook-and-instagram.svg';
import { ReactComponent as CsvExportIcon } from 'src/components/Icon/format-csv.svg';
import { ReactComponent as GradientCircleIcon } from 'src/components/Icon/gradient-circle.svg';
import { ReactComponent as ManuallyIcon } from 'src/components/Icon/manually.svg';
import { ReactComponent as QuestionMarkIcon } from 'src/components/Icon/question-mark.svg';
import { ReactComponent as ShopifyIcon } from 'src/components/Icon/shopify.svg';
import { ReactComponent as WooCommerceIcon } from 'src/components/Icon/woo-commerce.svg';
import { CatalogSource } from 'src/utils/gql';

import { SOURCES } from '../types';

export const CARDS = [
  {
    id: 4,
    title: <Trans i18nKey="catalogueCreationPage.secondStep.cards.manually">Manually</Trans>,
    source: CatalogSource.Manually,
    isDisabled: false,
    icon: () => <ManuallyIcon />,
    testId: 'manuallySmallCard',
  },
  {
    id: 0,
    title: <Trans i18nKey="catalogueCreationPage.secondStep.cards.shopify">Shopify</Trans>,
    source: CatalogSource.Shopify,
    isDisabled: false,
    icon: () => <ShopifyIcon />,
    testId: 'shopifySmallCard',
  },
  {
    id: 6,
    title: <Trans i18nKey="catalogueCreationPage.secondStep.cards.facebookAndInstagram">Facebook + Instagram</Trans>,
    source: CatalogSource.Facebook,
    isDisabled: true,
    icon: () => <FacebookAndInstagramIcon />,
    viewBox: '0 0 40 40',
  },
  {
    id: 5,
    title: <Trans i18nKey="catalogueCreationPage.secondStep.cards.iDontSee">I don&apos;t see proper option</Trans>,
    source: SOURCES.noOption.toString() as CatalogSource,
    isDisabled: true,
    icon: () => <QuestionMarkIcon />,
  },
  {
    id: 3,
    title: <Trans i18nKey="catalogueCreationPage.secondStep.cards.wooCommerce">WooCommerce</Trans>,
    source: CatalogSource.Woocommerce,
    isDisabled: false,
    icon: () => <WooCommerceIcon />,
    testId: 'wooCommerceSmallCard',
  },
  {
    id: 2,
    title: <Trans i18nKey="catalogueCreationPage.secondStep.cards.csvFiles">CSV files</Trans>,
    source: CatalogSource.Csv,
    isDisabled: false,
    icon: () => <CsvExportIcon />,
    testId: 'csvFilesSmallCard',
  },

  {
    id: 1,
    title: <Trans i18nKey="catalogueCreationPage.secondStep.cards.otherSystems">Other systems</Trans>,
    source: SOURCES.otherSystems.toString() as CatalogSource,
    isDisabled: true,
    icon: () => <GradientCircleIcon />,
  },
];
