import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ApolloError, gql, useMutation, useQuery } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';

import Loader from 'src/components/Loader';
import { MediaDataType } from 'src/components/MediaGallery/constants';
import { MediaForUpdateAltText } from 'src/components/SideBar/ImageEditorSidebar/constants';
import useUploadMediaDropzone from 'src/hooks/useUploadMediaDropzone';
import { MediaStateContext } from 'src/providers/MediaProvider/context';
import { useSnackbar } from 'src/providers/snackbar';
import {
  getCatalogInfo,
  getProductItemById,
  getProductTypeById,
  ItemStatus,
  MediaUsage,
  Query,
  QueryGetProductItemArgs,
  updateMediaAltText,
  updateProductItemById,
  UpdateProductItemInput,
} from 'src/utils/gql';

import ItemDetails from './ItemDetails';
import { MediaInput } from './types';

const ItemEditing = () => {
  const { catalogId, productTypeId, productItemId } = useParams<{
    catalogId: string;
    productTypeId: string;
    productItemId: string;
  }>();
  const snackbar = useSnackbar();
  const { t } = useTranslation();
  const { uploadingFiles } = useUploadMediaDropzone();

  const { isUploading, mediaState, setMediaState, mediaForDetach, setMediaLastOrder, changeMediaOrdersBeforeUpdate } =
    useContext(MediaStateContext);

  const handleGetMedia = (mediaUsages: MediaUsage[]) => {
    const sortedMedia = [...mediaUsages].sort(
      ({ orderNumber: firstOrderNumber }, { orderNumber: secondOrderNumber }) =>
        (firstOrderNumber || 0) - (secondOrderNumber || 0),
    );
    const getMedia = sortedMedia.map(({ media: { id: mediaId, url, previewUrl, type, altText }, id }) => ({
      id: mediaId,
      mediaUsageId: id,
      uuid: uuidv4(),
      url,
      previewUrl,
      type,
      typeAddedData: MediaDataType.FROM_DATABASE,
      orderNumber: sortedMedia.findIndex(({ media }) => mediaId === media.id) + 1,
      altText: altText || '',
    }));

    setMediaLastOrder((getMedia.length && getMedia[getMedia.length - 1]?.orderNumber) || 0);
    changeMediaOrdersBeforeUpdate(getMedia.map(({ uuid, orderNumber }) => ({ uuid, orderNumber })));
    setMediaState(getMedia);
  };

  const { data, loading } = useQuery<Pick<Query, 'getProductItem'>, QueryGetProductItemArgs>(getProductItemById, {
    variables: {
      id: productItemId,
    },
    onCompleted: (data) => handleGetMedia(data?.getProductItem.mediaUsages),
  });

  const [updateAltTexts] = useMutation(updateMediaAltText);
  const mediaToUpdateAltText = mediaState.reduce(
    (acc: MediaForUpdateAltText[], { typeAddedData, id, altText, isChangedAltText }) => {
      if (typeAddedData == MediaDataType.FROM_DATABASE && isChangedAltText) {
        acc.push({ id, altText });
      }
      return acc;
    },
    [],
  );

  const initialItemEditingFormData = useMemo(
    () =>
      data && {
        draft: data.getProductItem.status === ItemStatus.Draft,
        ...data.getProductItem.values.reduce(
          (formState, { productTypeField, value }) => ({
            [productTypeField.id]: value,
            ...formState,
          }),
          {},
        ),
      },
    [data],
  );

  const [updateProductItem] = useMutation(updateProductItemById, {
    notifyOnNetworkStatusChange: true,
    refetchQueries: [
      { query: getProductTypeById, variables: { id: productTypeId } },
      { query: getCatalogInfo, variables: { id: catalogId } },
    ],
  });

  const handleSave = async (itemData: UpdateProductItemInput) => {
    try {
      isUploading(true);

      let isUnloadedFiles;
      let itemMedia = mediaState;
      let media: MediaInput[] = [];

      const saveNewMedia = mediaState.find(({ typeAddedData }) =>
        [MediaDataType.FROM_FILE, MediaDataType.FROM_URL].includes(typeAddedData as MediaDataType),
      );

      if (saveNewMedia) {
        const { uploadedFilesUrls, unloadedFiles } = await uploadingFiles();

        media = [...media, ...(uploadedFilesUrls?.mediaToCreate || [])];
        itemMedia = itemMedia.filter(
          ({ typeAddedData }) =>
            ![MediaDataType.FROM_FILE, MediaDataType.FROM_URL].includes(typeAddedData as MediaDataType),
        );

        isUnloadedFiles = Boolean(unloadedFiles.length);
      }

      isUploading(false);

      if (mediaToUpdateAltText.length) {
        try {
          await updateAltTexts({
            variables: {
              data: mediaToUpdateAltText,
            },
          });
        } catch (error) {
          const { graphQLErrors, message: errorText } = error as ApolloError;
          const message = graphQLErrors && graphQLErrors.length ? graphQLErrors[0].message : errorText;

          if (error) {
            snackbar(message);
          }
        }
      }

      media = [
        ...itemMedia
          .filter(({ mediaId }) => !mediaForDetach.includes(mediaId || ''))
          .map(({ id, previewUrl, type, url, orderNumber, mediaUsageId }) => ({
            mediaId: id,
            previewUrl,
            type,
            url,
            orderNumber,
            mediaUsageId,
          })),
        ...media,
      ];

      if (isUnloadedFiles) {
        snackbar(t('uploadMediaErrors.unsupportedExtension'));
      } else {
        await updateProductItem({
          variables: {
            data: { ...itemData, media },
            id: productItemId,
          },
          update(cache, { data }) {
            const updatedItem = data.updateProductItem;

            cache.writeFragment({
              id: `ProductItem:${updatedItem.id}`,
              fragment: gql`
                fragment MyProductItem on ProductItem {
                  id
                  values
                  status
                  mediaUsages
                  integrationsSpecificItemData
                }
              `,
              data: updatedItem,
            });
          },
        }).then(
          ({
            data: {
              updateProductItem: { mediaUsages },
            },
          }) => handleGetMedia(mediaUsages),
        );

        snackbar(t('productItemCreateEdit.snackbarUpdateItemSuccess'), 'success');
      }
      return true;
    } catch (error) {
      const { graphQLErrors, message: errorText } = error as ApolloError;
      const message = graphQLErrors && graphQLErrors.length ? graphQLErrors[0].message : errorText;

      if (error) {
        snackbar(message);
      }

      return false;
    }
  };

  const locationName = t('navBar.contextMenu.recentStepsTitles.productItemEditing');
  const isLoading = loading || !initialItemEditingFormData;

  return isLoading ? (
    <Loader fullAvailableScreen />
  ) : (
    <ItemDetails
      locationName={locationName}
      onSave={handleSave}
      initialItemEditingFormData={initialItemEditingFormData}
      currentItemId={productItemId}
      addNameInTitleAndLocationEnd
      canBeSavedWithoutChanges
      productItemValues={data?.getProductItem.values}
    />
  );
};

export default ItemEditing;
