import React from 'react';
import { useTranslation } from 'react-i18next';

import { IconButton, InputAdornment, useTheme } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';

import Hint from 'src/components/Hint';
import Iconography from 'src/components/Iconography';
import Loader from 'src/components/Loader';

interface GeneratingTextInputButtonProps {
  isLoading: boolean;
  isTextGenerated: boolean;
  onClick: () => void;
}

export const InputAdornmentButton = styled(InputAdornment)`
  min-width: 40px;

  .MuiCircularProgress-root {
    right: 15px;
  }

  .MuiIconButton-root {
    padding: 0;

    :hover svg {
      color: ${({ theme }) => theme.palette.secondary.dark};
    }
  }
`;

const GeneratingTextInputButton = ({ isLoading, isTextGenerated, onClick }: GeneratingTextInputButtonProps) => {
  const { palette } = useTheme();
  const { t } = useTranslation();

  const hintTitle = isTextGenerated
    ? t('generateTextButtonHint.revertAIChanges')
    : t('generateTextButtonHint.enhanceWithAI');

  return (
    <InputAdornmentButton position="end">
      {isLoading ? (
        <Loader size="button" />
      ) : (
        <Hint placement="top" type="hover" title={hintTitle || ''}>
          <IconButton onClick={onClick}>
            <Iconography
              iconName="stars"
              htmlColor={isTextGenerated ? palette.secondary.dark : palette.action.disabled}
            />
          </IconButton>
        </Hint>
      )}
    </InputAdornmentButton>
  );
};

export default GeneratingTextInputButton;
