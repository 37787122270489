import React, { Fragment } from 'react';

import { Box, Skeleton } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { keyframes } from '@mui/system';

const opacity = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Container = styled(Box)({
  animation: `${opacity} 1s ease`,
});

export const FieldSkeleton = styled(Skeleton)({
  width: '100%',
  height: '42px',
  borderRadius: '5px',
});

interface SkeletonMappingFieldsProps {
  rowsCount: number;
}

export const SkeletonMappingFields = ({ rowsCount }: SkeletonMappingFieldsProps) => (
  <>
    {new Array(rowsCount).fill('row').map((_, idx) => (
      <Fragment key={idx}>
        <Container data-testid="skeletonMappingFieldsFirst" gridColumn="2">
          <FieldSkeleton variant="rectangular" />
        </Container>

        <Container data-testid="skeletonMappingFieldsSecond" gridColumn="4">
          <FieldSkeleton variant="rectangular" />
        </Container>
      </Fragment>
    ))}
  </>
);

export const SkeletonMappingSelectProduct = () => <FieldSkeleton variant="rectangular" />;
