import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@material-ui/core';

import Popup from 'src/components/Popup';

export interface CancelCSVUploadPopupProps {
  isOpen: boolean;
  loading?: boolean;
  setIsModalOpen: (state: boolean) => void;
  onMainButtonClick: () => void;
}

const CancelCSVUploadPopup = ({ isOpen, loading, onMainButtonClick, setIsModalOpen }: CancelCSVUploadPopupProps) => {
  const { t } = useTranslation();

  return (
    <Popup
      open={isOpen}
      mainTitle={t('importFromCsvPage.cancelUploadPopup.title')}
      mainButtonText={t('importFromCsvPage.cancelUploadPopup.mainButtonText')}
      secondaryButtonText={t('importFromCsvPage.cancelUploadPopup.secondaryButtonText')}
      onMainButtonClick={onMainButtonClick}
      onSecondaryButtonClick={() => setIsModalOpen(false)}
      onClose={() => setIsModalOpen(false)}
      loadingOnMainButton={loading}
    >
      <Box maxWidth="300px" width="100%" margin="0 auto">
        <Typography variant="subtitle1" fontWeight="normal">
          {t('importFromCsvPage.cancelUploadPopup.description')}
        </Typography>
      </Box>
    </Popup>
  );
};

export default CancelCSVUploadPopup;
