import React from 'react';

import { Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ReactPlayer from 'react-player/lazy';

import PlayVideoButton from 'src/components/Icon/play-video-button.svg';
import { VideoMethodsAndProps } from 'src/hooks/useCustomMediaPlayerButtons';
import { validateVideoLink } from 'src/views/Catalogs/ProductItems/functions';

interface VideoPropsStyle {
  showControls: boolean;
  itemState?: boolean;
  created?: boolean;
  uploadingOrDeleting?: boolean;
  playing?: boolean;
}

interface VideoProps {
  methodAndProps: VideoMethodsAndProps;
  url?: string;
  uploadingOrDeleting?: boolean;
  irreproducible?: boolean;
  itemState?: boolean;
  created?: boolean;
  video: React.RefObject<ReactPlayer>;
  setPlaying?: React.Dispatch<React.SetStateAction<boolean>>;
}

const useStyles = makeStyles<Theme, VideoPropsStyle>(() => ({
  video: {
    width: 'inherit !important',
    height: 'inherit !important',
    display: 'flex',
    transition: '0.2s',
    opacity: ({ itemState, created }: VideoPropsStyle) => (itemState ? (created ? 0.3 : 0.7) : 1),
    margin: '0 auto',

    '& .react-player__preview': {
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain !important',
    },
  },
  videoImgContainer: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: ({ showControls, uploadingOrDeleting }) => (showControls || uploadingOrDeleting ? 0 : 1),
    width: '40px',
    height: '40px',
    cursor: 'pointer',
    transition: '0.2s',
    opacity: ({ showControls, uploadingOrDeleting }) => (showControls ? 0 : uploadingOrDeleting ? 0.5 : 1),
  },
}));

const Video = ({
  video,
  url,
  methodAndProps,
  uploadingOrDeleting,
  irreproducible,
  itemState,
  created,
  setPlaying,
}: VideoProps) => {
  const { playVideo, showControls, changeControlsState } = methodAndProps;

  const youtubeOrVimeoVideo = validateVideoLink(url as string);

  const classes = useStyles({
    showControls,
    itemState,
    created,
    uploadingOrDeleting,
  });

  const handleClick =
    irreproducible || uploadingOrDeleting
      ? undefined
      : youtubeOrVimeoVideo
      ? () => window.open(url)
      : () => playVideo(video.current);

  const PlayButton = ({ handleClick }: { handleClick?: () => void }) => {
    return (
      <Box className={classes.videoImgContainer}>
        <img className="videoImg" onClick={handleClick} draggable={false} src={PlayVideoButton} alt="VideoButton" />
      </Box>
    );
  };

  return (
    <>
      <PlayButton handleClick={handleClick} />
      <Box display="flex" width="100%" height="100%" sx={{ pointerEvents: youtubeOrVimeoVideo ? 'none' : 'auto' }}>
        <ReactPlayer
          playIcon={<Box />}
          id="videoContainer"
          className={classes.video}
          url={url}
          controls={showControls && !youtubeOrVimeoVideo}
          light={youtubeOrVimeoVideo}
          previewTabIndex={0}
          ref={video}
          onEnded={changeControlsState}
          onStart={() => setPlaying?.(true)}
        />
      </Box>
    </>
  );
};

export default Video;
