import React, { HTMLAttributes, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { Link, Typography } from '@material-ui/core';

import ForgotPasswordImageBackground from './ForgotPasswordImageBackground';
import { ForgotPasswordContainer, SideContainer, StyledForgotPasswordContainer } from './styled';

const ForgotPasswordScreen = ({ children }: HTMLAttributes<HTMLDivElement>) => {
  const [isImageLoading, setIsImageLoading] = useState(true);
  const { t } = useTranslation();

  return (
    <StyledForgotPasswordContainer>
      <ForgotPasswordContainer>
        <Typography variant="h1" textAlign="center">
          {t('forgotPasswordPage.passwordScreen.resetYourPassword')}
        </Typography>

        <Typography variant="body1" color="textSecondary" textAlign="center" mt="40px" maxWidth="370px">
          {t('forgotPasswordPage.passwordScreen.description')}
        </Typography>

        {children}

        <Link
          color="secondary"
          component={RouterLink}
          to="/auth/login"
          underline="hover"
          variant="h3"
          mt="40px"
          data-testid="linkGoBackToLogin"
        >
          {t('forgotPasswordPage.link')}
        </Link>
      </ForgotPasswordContainer>

      <SideContainer>
        {isImageLoading && <ForgotPasswordImageBackground />}
        <img
          src={`${process.env.PUBLIC_URL}/images/recycle.webp`}
          alt="Recycle"
          onLoad={() => setIsImageLoading(false)}
          height="574"
          width="676"
        />
      </SideContainer>
    </StyledForgotPasswordContainer>
  );
};

export default ForgotPasswordScreen;
