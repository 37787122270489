import React, { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography, makeStyles, Theme, CardMedia, CircularProgress } from '@material-ui/core';
import clsx from 'clsx';
import ImageEditor from 'tui-image-editor';

import WrapperTextInput from 'src/components/Forms/FormInputs/WrapperTextInput';
import HintLimitButton from 'src/components/HintLimitButton';
import Iconography from 'src/components/Iconography';
import Loader from 'src/components/Loader';
import { MediaDataType } from 'src/components/MediaGallery/constants';
import Popup from 'src/components/Popup';
import SideBarDrawer from 'src/components/SideBar/RightSidebarDrawer';
import { EXCEEDED_LIMIT_VALUE } from 'src/constants';
import { validateTextFieldValue } from 'src/helpers/validationCheck';
import { MediaStateContext } from 'src/providers/MediaProvider/context';
import base64toFile from 'src/utils/base64toFile';
import { MediaType } from 'src/utils/gql';
import { disableChromeCacheForUrl } from 'src/utils/imageUtils';
import { getFileNameFromUrl } from 'src/views/Catalogs/ProductItems/functions';
import { UploadingMedia } from 'src/views/Catalogs/ProductItems/types';

import {
  CROP_PRESETS,
  FLIP_ORIENTATION,
  ImageEditorSidebarProps,
  ImageEditorState,
  IMAGE_EDITOR_CONTROLS,
} from './constants';
import {
  ControlBox,
  ControlGroupContainer,
  ControlsContainer,
  CropModeBox,
  CropModesContainer,
  EditedImageLoaderWrapper,
  ImageEditorContainer,
  ImageEditorWrapper,
  MainControlsContainer,
  MediaContentContainer,
  MediaContentWrapper,
  ResetActionContainer,
} from './styled';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '&:hover': {
      boxShadow: '0 2px 15px rgba(241, 97, 82, 0.35)',
    },
    borderRadius: '2px',
  },
  activeCropPreset: {
    background: theme.palette.background.default,
    color: theme.palette.secondary.dark,
  },
  orangeBorder: {
    border: `1px solid ${theme.palette.secondary.main}`,
  },
  standardBorder: {
    border: `1px solid ${theme.palette.action.disabled}`,
  },
  cardMediaLoading: {
    background: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  displayCardWhileLoading: {
    display: 'none',
  },
}));

export interface MediaForUpdate {
  mediaForUpdate?: UploadingMedia;
  mediaForDetach?: string;
  mediaHasChanges?: boolean;
  changedAltText?: string;
  isAltTextChanged?: boolean;
  mediaId: string;
}

const ImageEditorSidebar = ({ isOpen, onCloseSidebar, editedImage, changeMedia, onApply }: ImageEditorSidebarProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const rootEl = useRef(null);
  const editorInstance = useRef<ImageEditor | null>(null);

  const { mediaState } = useContext(MediaStateContext);

  const [mediaToChangeArray, setMediaToChangeArray] = useState<MediaForUpdate[]>([]);
  const initiaSmallImageslLoadingStatusArray = mediaState.map(() => true);
  const [mainImageLoading, setMainImageLoading] = useState(true);
  const [isSmallImageLoadingArray, setIsSmallImageLoadingArray] = useState(initiaSmallImageslLoadingStatusArray);

  const initialState = {
    resetImageChangesPopupOpen: false,
    discardChangesPopupOpen: false,
    isCropActive: false,
    isSidebarLoaded: false,
    activeCropModeIndex: null,
    isTouched: false,
    media: mediaState,
  };

  const [isSidebarLoaded, setIsSidebarLoaded] = useState<boolean>(true);
  const [imageEditorState, setImageEditorState] = useState<ImageEditorState>(initialState);

  const resetState = () => setImageEditorState(initialState);
  const resetMediaForUpdateState = (id: string) => {
    const mediaForUpdateArray = mediaToChangeArray.filter(({ mediaId }) => mediaId !== id);

    setMediaToChangeArray(mediaForUpdateArray);
  };

  const handleResetChangesForImage = (id: string) => {
    resetMediaForUpdateState(id);

    const initialImage = mediaState.find((localMedia) => localMedia.id === editedImage.id);
    const resetedImage = initialImage ? initialImage : editedImage;

    changeImage(resetedImage);
  };

  const handleChangeMediaForUpdate = (changedMedia: MediaForUpdate) => {
    let newState: MediaForUpdate[] = [];
    if (mediaToChangeArray.find(({ mediaId }) => mediaId === editedImage.id)) {
      newState = mediaToChangeArray.map((media) =>
        media.mediaId === editedImage.id ? { ...media, ...changedMedia } : media,
      );
    } else {
      newState = [...mediaToChangeArray, { ...changedMedia }];
    }

    setMediaToChangeArray(newState);
    handleChangeEditedImage(newState);
  };

  const handleCloseSidebar = () => {
    if (editorInstance.current?.isEmptyUndoStack() && !mediaToChangeArray.length) {
      onCloseSidebar();
      resetState();
    } else {
      setImageEditorState((prevState) => ({ ...prevState, discardChangesPopupOpen: true }));
    }
  };

  const handleDiscardChanges = () => {
    resetState();
    onCloseSidebar();
    setMediaToChangeArray([]);
  };

  const handleFlip = (orientation: string) => {
    return orientation === FLIP_ORIENTATION.flipX ? editorInstance.current?.flipX() : editorInstance.current?.flipY();
  };

  const handleRotateImage = (rotateDegree: string) => {
    return editorInstance.current?.rotate(Number(rotateDegree));
  };

  const handleCropImage = (modeIndex: number, presetValue?: number) => {
    if (presetValue) {
      setImageEditorState((prevState) => ({ ...prevState, activeCropModeIndex: modeIndex, isCropActive: true }));

      editorInstance.current?.startDrawingMode('CROPPER');
      editorInstance.current?.setCropzoneRect(presetValue);
    } else {
      handleCancelCrop();
      setImageEditorState((prevState) => ({ ...prevState, activeCropModeIndex: modeIndex, isCropActive: true }));

      editorInstance.current?.startDrawingMode('CROPPER');
    }
  };

  const handleCancelCrop = () => {
    editorInstance.current?.stopDrawingMode();
    setImageEditorState((prevState) => ({ ...prevState, isCropActive: false, activeCropModeIndex: null }));
  };

  const handleApplyCrop = async () => {
    const MIN_SIZE_PER_PX = 1;

    if (editorInstance.current) {
      const { width, height } = editorInstance.current.getCropzoneRect();
      const isCropAllowed = width >= MIN_SIZE_PER_PX && height >= MIN_SIZE_PER_PX;

      if (isCropAllowed) {
        await editorInstance.current?.crop(editorInstance.current?.getCropzoneRect()).then(() => {
          editorInstance.current?.stopDrawingMode();
        });
      } else {
        editorInstance.current?.stopDrawingMode();
      }

      setImageEditorState((prevState) => ({
        ...prevState,
        isCropActive: false,
        activeCropModeIndex: null,
        isTouched: isCropAllowed,
      }));
    }
    const changedMedia = { mediaHasChanges: true, mediaId: editedImage.id };

    handleChangeMediaForUpdate(changedMedia);
  };

  const handleLoadOrResetImage = () => {
    setMainImageLoading(true);

    if (editedImage.file) {
      editorInstance.current
        ?.loadImageFromFile(editedImage.file)
        .then(() => {
          editorInstance.current?.clearUndoStack();
        })
        .then(() => {
          setMainImageLoading(false);
        });
    } else if (editedImage.url) {
      editorInstance.current
        ?.loadImageFromURL(disableChromeCacheForUrl(editedImage.url), 'SampleImage')
        .then(() => {
          editorInstance.current?.clearUndoStack();
        })
        .then(() => {
          setMainImageLoading(false);
        });
    } else if (editedImage.previewUrl) {
      editorInstance.current
        ?.loadImageFromURL(disableChromeCacheForUrl(editedImage.previewUrl), 'SampleImage')
        .then(() => {
          editorInstance.current?.clearUndoStack();
        })
        .then(() => {
          setMainImageLoading(false);
        });
    }
  };

  const handleApplyControlGroupMethod = async (controlGroupId: string, controlValue: string) => {
    controlGroupId.includes('flip') ? await handleFlip(controlValue) : await handleRotateImage(controlValue);
    setImageEditorState((prevState) => ({
      ...prevState,
      isTouched: true,
    }));

    const changedMedia = { mediaHasChanges: true, mediaId: editedImage.id };

    handleChangeMediaForUpdate(changedMedia);
  };

  const handleApplyImageChanges = async () => {
    if (!editorInstance.current?.isEmptyUndoStack()) {
      const wasImageAttachedToItem = editedImage.typeAddedData === MediaDataType.FROM_DATABASE;
      const base64String = editorInstance.current?.toDataURL() as string;
      const fileExtension = '.jpeg';
      const imageName = editedImage.name ?? `edited-image-${editedImage.id}${fileExtension}`;
      const imageType = editedImage.file?.type ?? 'image/jpeg';
      const hasImageToDetach = mediaToChangeArray.find(({ mediaForDetach }) => mediaForDetach === editedImage.id);
      let imageIdForDetach;

      if (hasImageToDetach) {
        imageIdForDetach = hasImageToDetach.mediaForDetach;
      } else {
        imageIdForDetach = wasImageAttachedToItem ? editedImage.id : '';
      }
      const updatedImage = await base64toFile(base64String, imageName, imageType);

      const imageForUpload = {
        expansion: fileExtension,
        file: updatedImage,
        id: editedImage.id,
        name: imageName,
        type: MediaType.Image,
        typeAddedData: MediaDataType.FROM_FILE,
        url: base64String,
        altText: mediaState.find(({ id }) => id === editedImage.id)?.altText || '',
        canBeReset: true,
        orderNumber: editedImage.orderNumber,
        uuid: editedImage.uuid,
      };

      const changedMedia = {
        mediaForUpdate: imageForUpload,
        mediaForDetach: imageIdForDetach,
        mediaId: editedImage.id,
        mediaHasChanges: false,
      };

      handleChangeMediaForUpdate(changedMedia);
      resetState();
    }
  };

  const handleChangeEditedImage = (mediaToChangeArray: MediaForUpdate[]) => {
    if (mediaToChangeArray.find(({ mediaId, mediaHasChanges }) => mediaId === editedImage.id && mediaHasChanges)) {
      handleApplyImageChanges();
    }
    const changedMedia = mediaToChangeArray.find(({ mediaId }) => mediaId === editedImage.id)?.mediaForUpdate;
    changeMedia(changedMedia || editedImage);
  };

  useEffect(() => {
    let instance;

    if (isOpen) {
      setIsSidebarLoaded(false);

      if (!isSidebarLoaded && rootEl.current) {
        instance = new ImageEditor(rootEl.current, {
          cssMaxHeight: 500,
          cssMaxWidth: 700,
        });

        editorInstance.current = instance;
        handleLoadOrResetImage();
      }
    }

    return () => {
      editorInstance.current?.destroy();
      editorInstance.current = null;
      setIsSidebarLoaded(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rootEl.current, isOpen, isSidebarLoaded, editedImage]);

  const changeImage = (media: UploadingMedia) => {
    changeMedia(media);

    setImageEditorState((prevState) => ({ ...prevState, isCropActive: false, activeCropModeIndex: null }));
  };

  const handleClickOnImage = (media: UploadingMedia) => {
    const changedMedia = mediaToChangeArray.find(({ mediaId }) => mediaId === media.id)?.mediaForUpdate;

    changeImage(changedMedia ? changedMedia : media);
  };

  const handleChangeAltText = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    const changedMedia = {
      mediaId: editedImage.id,
      changedAltText: validateTextFieldValue(value),
      isAltTextChanged: true,
    };

    handleChangeMediaForUpdate(changedMedia);

    setImageEditorState((prevState) => ({ ...prevState, isTouched: true }));
  };

  const handleSaveChanges = () => {
    onApply(mediaToChangeArray);
    setMediaToChangeArray([]);

    onCloseSidebar();
  };

  const changedAltText = mediaToChangeArray.find(
    ({ mediaId, changedAltText }) => mediaId === editedImage.id && changedAltText,
  )?.changedAltText;

  const showChangedAltText =
    changedAltText ||
    !!mediaToChangeArray.find(({ mediaId, isAltTextChanged }) => mediaId === editedImage.id && isAltTextChanged);

  const altTextValue = showChangedAltText
    ? changedAltText || ''
    : mediaState.find(({ id }) => id === editedImage?.id)?.altText;

  const isLongAltText = altTextValue !== undefined && altTextValue?.length > EXCEEDED_LIMIT_VALUE;
  const helperTextAlt = isLongAltText ? t('exceededLimitValue') : '';
  const imageCanBeReset = mediaToChangeArray.find(({ mediaId }) => mediaId === editedImage.id);

  return (
    <SideBarDrawer
      titleName={t('productItemCreateEdit.mediaGallery.imageEditorSideBar.title')}
      onPrimaryButtonClick={handleDiscardChanges}
      handleCloseSidebarOnClickAway={handleCloseSidebar}
      onSecondaryButtonClick={() => handleSaveChanges()}
      openSidebar={isOpen}
      secondaryButtonTitle={t('productItemCreateEdit.mediaGallery.imageEditorSideBar.buttonSave')}
      primaryButtonTitle={t('productItemCreateEdit.mediaGallery.imageEditorSideBar.buttonCancel')}
      dataTestIdPrimaryButton="closeImageEditorButton"
      dataTestIdSecondaryButton="saveImageEditorChangesButton"
      disabledSecondaryButton={!mediaToChangeArray.length}
    >
      <Box>
        <ImageEditorWrapper>
          <ImageEditorContainer ref={rootEl}>
            {mainImageLoading && (
              <EditedImageLoaderWrapper>
                <CircularProgress size={76} color="inherit" />
              </EditedImageLoaderWrapper>
            )}
          </ImageEditorContainer>
        </ImageEditorWrapper>
        <MainControlsContainer>
          <CropModesContainer>
            {CROP_PRESETS.map(({ presetName, presetId, presetValue }, idx) => (
              <CropModeBox
                className={clsx(idx === imageEditorState.activeCropModeIndex && classes.activeCropPreset)}
                key={presetId}
                onClick={() => handleCropImage(idx, presetValue)}
                data-testid={`${presetName}CropButton`}
              >
                {presetName}
              </CropModeBox>
            ))}

            <CropModeBox
              onClick={handleCancelCrop}
              disabled={!imageEditorState.isCropActive}
              data-testid="cancelCropButton"
            >
              <Iconography iconName="sync" color="primary" fontSize="small" />
            </CropModeBox>

            <CropModeBox
              onClick={handleApplyCrop}
              disabled={!imageEditorState.isCropActive}
              data-testid="applyCropButton"
            >
              <Iconography iconName="check-outline" color="primary" fontSize="small" />
            </CropModeBox>
          </CropModesContainer>

          <ControlsContainer>
            {IMAGE_EDITOR_CONTROLS.map((control) => (
              <ControlGroupContainer key={control.controlGroupId} display="flex">
                {control.controlGroup.map((controlItem) => (
                  <HintLimitButton
                    key={controlItem.controlTestId}
                    placement="top"
                    title={t(controlItem.controlHint) as string}
                  >
                    <ControlBox
                      onClick={() => handleApplyControlGroupMethod(control.controlGroupId, controlItem.controlValue)}
                      id={controlItem.controlTestId}
                      data-testid={controlItem.controlTestId}
                      disabled={imageEditorState.isCropActive}
                    >
                      {controlItem.controlIcon}
                    </ControlBox>
                  </HintLimitButton>
                ))}
              </ControlGroupContainer>
            ))}
          </ControlsContainer>
          <ResetActionContainer
            disabled={!imageCanBeReset}
            onClick={() => {
              handleResetChangesForImage(editedImage.id);
            }}
            data-testid="resetChangesButton"
          >
            <Iconography iconName="sync" fontSize="small" />
          </ResetActionContainer>
        </MainControlsContainer>

        <Box padding="32px 50px 0 30px">
          <WrapperTextInput
            label={t('productItemCreateEdit.mediaGallery.imageEditorSideBar.altText')}
            value={altTextValue}
            name="altText"
            onChange={handleChangeAltText}
            error={isLongAltText}
            helperTextError={helperTextAlt}
            inputNameTestId={`altText${editedImage?.name}`}
          />
        </Box>

        <MediaContentWrapper>
          <MediaContentContainer>
            {mediaState.map((media, idx) => {
              const nameMediaFile = media.expansion === undefined ? getFileNameFromUrl(media.url) : media.name;
              const changedMediaToShow = mediaToChangeArray.find(
                ({ mediaId, mediaForUpdate }) => mediaId == media.id && mediaForUpdate,
              )?.mediaForUpdate;

              const mediaToShow = changedMediaToShow ? changedMediaToShow : media;
              const showLoader = isSmallImageLoadingArray[idx];

              return (
                media.type === MediaType.Image && (
                  <Box
                    key={media.id}
                    height="115px"
                    width="124px"
                    className={clsx(
                      classes.root,
                      editedImage && editedImage.id === media.id ? classes.orangeBorder : classes.standardBorder,
                      showLoader && classes.cardMediaLoading,
                    )}
                  >
                    {showLoader && <Loader size="extraSmallIntegration" />}
                    <CardMedia
                      src={mediaToShow.url}
                      title={media.name}
                      component="img"
                      height="100%"
                      className={clsx(showLoader && classes.displayCardWhileLoading)}
                      onClick={() => handleClickOnImage(media)}
                      onLoad={() => {
                        setIsSmallImageLoadingArray((prevState) => [
                          ...prevState.map((loading, index) => (index === idx ? false : loading)),
                        ]);
                      }}
                      data-testid={`mediaCard${nameMediaFile}`}
                    />
                  </Box>
                )
              );
            })}
          </MediaContentContainer>
        </MediaContentWrapper>

        <Popup
          mainButtonText={t('productItemCreateEdit.mediaGallery.imageEditorSideBar.discardChangesPopup.mainButtonText')}
          secondaryButtonText={t(
            'productItemCreateEdit.mediaGallery.imageEditorSideBar.discardChangesPopup.secondaryButtonText',
          )}
          onMainButtonClick={handleDiscardChanges}
          onClose={() =>
            setImageEditorState((prevState) => ({
              ...prevState,
              discardChangesPopupOpen: false,
            }))
          }
          open={imageEditorState.discardChangesPopupOpen}
          mainTitle={t('productItemCreateEdit.mediaGallery.imageEditorSideBar.discardChangesPopup.title')}
          mainTitleWidth="257px"
        >
          <Typography variant="body1" color="text.secondary" maxWidth="300px" width="100%" margin="0 auto">
            {t('productItemCreateEdit.mediaGallery.imageEditorSideBar.discardChangesPopup.descriptionText')}
          </Typography>
        </Popup>
      </Box>
    </SideBarDrawer>
  );
};

export default ImageEditorSidebar;
