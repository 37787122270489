import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography, useTheme } from '@material-ui/core';

import Iconography from 'src/components/Iconography';

import { PREVIEW_ITEMS_BOX_COUNT } from '../types';

import {
  InitialPreviewWrapper,
  InitialPreviewHeader,
  InitialPreviewItem,
  InitialPreviewInfoWrapper,
  InitialPreviewInfoContainer,
  ItemsWrapperWithHiddenScroll,
  InitialPreviewInfoContainerResult,
} from './styled';

interface InitialItemsPreviewScreenProps {
  title?: string;
  description: string;
  currentResult?: string[];
  notUploadedFiles?: string[];
  notParsedFiles?: string[];
}

const InitialItemsPreviewScreen = ({
  title,
  description,
  currentResult,
  notUploadedFiles,
  notParsedFiles,
}: InitialItemsPreviewScreenProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const previewItemsBoxes = useMemo(
    () => [...Array(PREVIEW_ITEMS_BOX_COUNT)].map((_, id) => <InitialPreviewItem key={id} />),
    [],
  );

  const notParsedFilesResult = notParsedFiles?.join('", "');
  const notUploadedFilesResult = notUploadedFiles?.join('", "');

  const { warningTranslationParams, warningTranslationKey } = useMemo(() => {
    const params = {
      file: '',
      count: 0,
    };

    let key = 'initialItemsPreviewScreen.csvInfo.csvWarningSingle';

    if (notParsedFiles?.length) {
      params.file = notParsedFiles[0];
      if (notParsedFiles.length > 1) {
        key = 'initialItemsPreviewScreen.csvInfo.csvWarningMultiple';
        params.count = notParsedFiles.length - 1;
      }
    }

    return {
      warningTranslationParams: params,
      warningTranslationKey: key,
    };
  }, [notParsedFiles]);

  return (
    <InitialPreviewWrapper>
      <InitialPreviewInfoWrapper>
        <InitialPreviewInfoContainer>
          <Typography variant="h2">{title}</Typography>
          <Typography variant="subtitle1" mt="25px" fontWeight="normal" textAlign="center">
            {description}
          </Typography>
          {currentResult &&
            currentResult.map((resultString, index) => (
              <Typography key={index} variant="subtitle1" mt="15px" fontWeight="normal">
                {resultString}
              </Typography>
            ))}
        </InitialPreviewInfoContainer>
        <InitialPreviewInfoContainerResult>
          <Box mt="100px" display="flex" flexDirection="column" width="100%">
            {notUploadedFilesResult && (
              <Box display="flex" mt="20px">
                <Iconography iconName="cancel-circle" htmlColor={theme.palette.secondary.main} />
                <Typography
                  color="text.primary"
                  variant="subtitle2"
                  maxWidth="500px"
                  ml="12px"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="normal"
                >
                  {t('initialItemsPreviewScreen.csvInfo.csvError', {
                    files: notUploadedFilesResult,
                  })}
                </Typography>
              </Box>
            )}
            {notParsedFilesResult && (
              <Box display="flex" mt="20px" zIndex="1">
                <Iconography iconName="info-fill" htmlColor={theme.palette.secondary.dark} />
                <Typography
                  color="text.primary"
                  variant="subtitle2"
                  maxWidth="500px"
                  ml="12px"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="normal"
                >
                  {t(warningTranslationKey, warningTranslationParams)}
                </Typography>
              </Box>
            )}
          </Box>
        </InitialPreviewInfoContainerResult>
      </InitialPreviewInfoWrapper>
      <ItemsWrapperWithHiddenScroll>
        <InitialPreviewHeader />
        {previewItemsBoxes}
      </ItemsWrapperWithHiddenScroll>
    </InitialPreviewWrapper>
  );
};

export default InitialItemsPreviewScreen;
