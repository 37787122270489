import React from 'react';

import { makeStyles, Step, StepLabel, Stepper, styled } from '@material-ui/core';
import StepConnector, { stepConnectorClasses } from '@material-ui/core/StepConnector';

import ColorlibStepIcon from './CustomStepIcon';

const useStyles = makeStyles({
  root: {
    '& .MuiStepLabel-iconContainer': {
      paddingRight: '0',
    },
    '& .MuiStepper-vertical': {
      width: '60px',
      alignItems: 'center',
    },
    '& .MuiStepLabel-vertical': {
      padding: 0,
    },
  },
});

const CustomConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      border: 0,
      borderLeftWidth: 0,
      height: 55,
      background: theme.palette.secondary.dark,
      width: 2,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      border: 0,
      borderLeftWidth: 0,
      height: 110,
      background: theme.palette.secondary.dark,
      width: 2,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    border: 0,
    borderLeftWidth: 0,
    height: 110,
    background: theme.palette.action.disabled,
    width: 2,
  },
}));

interface MultiStepperProps {
  activeStep: number;
  steps: Array<number>;
  handleSpecificStep: (specificStep: number) => void;
  completedSteps?: Set<number>;
}

const MultiStepper = (props: MultiStepperProps) => {
  const { activeStep, steps, handleSpecificStep, completedSteps } = props;
  const classes = useStyles();

  const realActiveStep = activeStep + 1;

  return (
    <div className={classes.root}>
      <Stepper orientation="vertical" activeStep={realActiveStep} connector={<CustomConnector />} data-testid="stepper">
        {steps.map((stepNumber: number) => (
          <Step key={stepNumber}>
            <StepLabel
              StepIconComponent={(props) => (
                <ColorlibStepIcon
                  completedSteps={completedSteps}
                  handleSpecificStep={handleSpecificStep}
                  {...props}
                  active={stepNumber === realActiveStep}
                />
              )}
            />
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

export default MultiStepper;
