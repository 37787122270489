import React from 'react';

export interface FormStateInterface<T> {
  isValid: boolean;
  isPasswordValid?: boolean;
  isLoading?: boolean;
  values: T;
  rememberMe?: boolean;
  touched: Record<string, boolean>;
  errors: Record<string, string[]>;
}

export enum SwiperSlideSpeed {
  SLOW = 300,
  FAST = 700,
}

export const KEYWORDS_LIMIT = 100;

export const CLOSE_ONBOARDING_TIMEOUT = 500;
export const SLIDER_SCROLLING_TIMEOUT = 100;

export const SEARCH_CATALOGS_OFFSET = 0;

export const DEBOUNCED_SEARCH_DELAY = 1000;
export const SHARED_CATALOGS_DELAY = 400;

export const SWIPER_SLIDES_PER_VIEW = 1;

export enum CheckPlanSubscriptionError {
  CATALOGS_LIMIT = `The limit on the number of catalogs was reached you can't create more catalogs.`,
  ITEMS_LIMIT = `The limit on the number of items was reached you can't create more items.`,
  INTEGRATIONS_LIMIT = `The limit on the number of integrations was reached you can't create more integrations.`,
  SHARED_CATALOGS_LIMIT = `The limit on the number of shared catalogs was reached, you can't make your catalog public or protected, catalog can be only private.`,
  SUBSCRIPTIONS_LIMIT = `The limit on the number of subscriptions was reached, you can't subscribe to new catalogs.`,
}

export type LastOnboardingLocationType = {
  activeMenuItem?: number;
  activeCategory?: number;
  activeSlide?: number;
  showStartOnboardingSlides?: boolean;
  isStartButtonClicked?: boolean;
};

export type SwiperSlidesCategory = {
  disableAllNavigation?: boolean;
  navigationItems?: string[];
  data: {
    img: {
      src: string;
      width: string;
      height: string;
    };
    mainTextBoxItems: { text: string; fontWeight: string; mt?: string }[];
  }[];
};

export type onClickHandler = (e: React.MouseEvent<HTMLButtonElement>) => void;
