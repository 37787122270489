import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@material-ui/core';

import Popup from 'src/components/Popup';

import { SharedCataloguesSubscriptionsActions } from '../SharedCatalogsTable/types';

type SubscriptionsPopupTextByActionTypes = {
  [key: string]: {
    popupHeaderText: () => string;
    popupContentText: () => string;
    mainButtonText: () => string;
    secondaryButtonText: () => string;
    popupHeaderTextPrefix: () => string;
  };
};

interface SubscriptionsPopupProps {
  subscriptionAction: string;
  isOpen: boolean;
  handleMainButtonClick: () => void;
  handleClosePopup: () => void;
  catalogName: string;
  catalogOwner: string;
  loadingStatus?: boolean;
}

const SubscriptionsPopup = (props: SubscriptionsPopupProps) => {
  const { t } = useTranslation();
  const {
    handleMainButtonClick,
    isOpen,
    handleClosePopup,
    catalogOwner,
    catalogName,
    subscriptionAction,
    loadingStatus,
  } = props;

  const LINE_BREAK_USERNAME_MAX_LENGTH = 8;
  const isDeleteUserAction = subscriptionAction === SharedCataloguesSubscriptionsActions.deleteUser;
  const shouldLineBreakWithUserName = catalogOwner.length > LINE_BREAK_USERNAME_MAX_LENGTH;

  const SubscriptionsPopupTextByAction: SubscriptionsPopupTextByActionTypes = {
    request: {
      popupHeaderText: () => t('subscriptionsPopup.subscribeHeaderText'),
      popupHeaderTextPrefix: () => t('subscriptionsPopup.subscribeHeaderPrefix'),
      popupContentText: () => t('subscriptionsPopup.subscribeContentText'),
      mainButtonText: () => t('subscriptionsPopup.yes'),
      secondaryButtonText: () => t('subscriptionsPopup.no'),
    },
    cancel: {
      popupHeaderText: () => t('subscriptionsPopup.unsubscribeHeaderText'),
      popupHeaderTextPrefix: () => t('subscriptionsPopup.unsubscribeHeaderPrefix'),
      popupContentText: () => t('subscriptionsPopup.unsubscribeContentText'),
      mainButtonText: () => t('subscriptionsPopup.yes'),
      secondaryButtonText: () => t('subscriptionsPopup.no'),
    },
    ['delete user']: {
      popupHeaderText: () => t('subscriptionsPopup.deleteUserHeaderText'),
      popupContentText: () => t('subscriptionsPopup.deleteUserContentText'),
      popupHeaderTextPrefix: () => t('subscriptionsPopup.deleteUserHeaderPrefix'),
      mainButtonText: () => t('subscriptionsPopup.delete'),
      secondaryButtonText: () => t('subscriptionsPopup.back'),
    },
  };

  return (
    <Popup
      mainButtonText={SubscriptionsPopupTextByAction[subscriptionAction].mainButtonText()}
      secondaryButtonText={SubscriptionsPopupTextByAction[subscriptionAction].secondaryButtonText()}
      open={isOpen}
      onSecondaryButtonClick={handleClosePopup}
      onMainButtonClick={handleMainButtonClick}
      loadingOnMainButton={loadingStatus}
      onClose={handleClosePopup}
      testMainButton="yesButtonPopup"
      testSecondaryButton="noButtonPopup"
      mainTitle={
        <Box display="flex" justifyContent="center" padding="0 16px" flexDirection="column" alignItems="center">
          {isDeleteUserAction && (
            <>
              <Box
                display="flex"
                justifyContent="center"
                flexDirection={shouldLineBreakWithUserName ? 'column' : 'row'}
                maxWidth="425px"
                width="100%"
              >
                <Typography variant="h2" whiteSpace="break-spaces">
                  {SubscriptionsPopupTextByAction[subscriptionAction].popupHeaderText()}
                </Typography>
                <Typography variant="h2" textOverflow="ellipsis" overflow="hidden" whiteSpace="pre">
                  {catalogOwner}
                </Typography>
              </Box>
              <Typography variant="h2">
                {SubscriptionsPopupTextByAction[subscriptionAction].popupHeaderTextPrefix()}
              </Typography>
            </>
          )}
          {!isDeleteUserAction && (
            <>
              <Typography variant="h2" whiteSpace="pre-line" overflow="hidden" textOverflow="ellipsis">
                {SubscriptionsPopupTextByAction[subscriptionAction].popupHeaderText()}
              </Typography>
              <Box display="flex" justifyContent="center">
                <Typography variant="h2" whiteSpace="break-spaces">
                  {SubscriptionsPopupTextByAction[subscriptionAction].popupHeaderTextPrefix()}
                </Typography>
                <Typography maxWidth="310px" whiteSpace="pre" overflow="hidden" textOverflow="ellipsis" variant="h2">
                  {catalogName}
                </Typography>
                <Typography variant="h2">?</Typography>
              </Box>
            </>
          )}
        </Box>
      }
    >
      <Typography
        variant="subtitle1"
        fontWeight="normal"
        color="text.secondary"
        maxWidth={isDeleteUserAction ? '370px' : '343px'}
        margin="0 auto"
      >
        {SubscriptionsPopupTextByAction[subscriptionAction].popupContentText()}
      </Typography>
    </Popup>
  );
};

export default SubscriptionsPopup;
