import React from 'react';

import { Box } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';

const MainBox = styled(Box)`
  position: absolute;
  width: 412px;
  height: 222px;
  opacity: 0.7;
  filter: blur(28px);

  & * {
    position: absolute;
    border-radius: 50%;
  }
`;

const BlueBox = styled(Box)`
  background-color: #152660;
`;

const OrangeBox = styled(Box)`
  background-color: #df642b;
  box-shadow: 0 0 0 20px #df642b;
`;

const RedBox = styled(Box)`
  background-color: #ff3c26;
  box-shadow: 0 0 0 20px #ff3c26;
`;

const PurpleBox = styled(Box)`
  background-color: #4752c3;
  box-shadow: 0 0 0 20px #4752c3;
`;

const DarkPurpleBox = styled(Box)`
  background-color: #484aa0;
  box-shadow: 0 0 20px 20px #484aa0;
`;

const CreateCatalogBackground = () => {
  return (
    <MainBox>
      <BlueBox width="342px" height="200px" top="8px" left="56px" />

      <BlueBox width="160px" height="106px" bottom="12px" left="18px" />

      <BlueBox width="64px" height="10px" bottom="18px" right="10px" boxShadow="0px 0px 0 20px #152660" />

      <BlueBox width="144px" height="10px" bottom="22px" left="28px" boxShadow="0px 0px 0 20px #152660" />

      <OrangeBox width="116px" height="84px" top="97px" right="80px" />

      <RedBox width="116px" height="6px" bottom="24px" right="80px" />

      <PurpleBox width="140px" height="46px" top="80px" right="60px" />

      <DarkPurpleBox width="88px" height="80px" top="82px" left="108px" />

      <OrangeBox width="84px" height="6px" bottom="40px" left="56px" />

      <PurpleBox width="80px" height="24px" bottom="22px" right="170px" />

      <PurpleBox width="80px" height="8px" bottom="16px" right="170px" />

      <OrangeBox width="32px" height="12px" bottom="38px" right="196px" />

      <OrangeBox width="36px" height="12px" bottom="80px" right="116px" />

      <BlueBox width="16px" height="68px" bottom="76px" right="190px" />
    </MainBox>
  );
};

export default CreateCatalogBackground;
